import { connect } from "react-redux";
import VendorEditFormScreen from "../../screens/VendorEditFormScreen";
import {
  addNewVendorSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  handleChangeVendor,
  vendorRequestMade,
} from "../../actions";
import { VendorAPI } from "../../api";
import {
  setVendorToEdit,
  updateVendorFailure,
} from "../../actions/VendorAction";
const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    handleChangeClient: (id, value, label) =>
      dispatch(handleChangeVendor(id, value, label)),
    editVendor: (vendorList, props) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.editVendor(vendorList, (response, error) => {
        if (response) {
          dispatch(addNewVendorSuccess(true));
          props.history.replace("/vendor");
        } else {
          if (error.msg) {
            dispatch(updateVendorFailure(error.msg));
          } else {
            dispatch(updateVendorFailure(error));
          }
        }
      });
    },
    handleChangeVendor: (id, value, label) =>
      dispatch(handleChangeVendor(id, value, label)),
    setVendorToEdit: (vendorList) => {
      dispatch(setVendorToEdit(vendorList));
    },
  };
};

export const VendorEditFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorEditFormScreen);
