import APIEndPoints from "../constants/APIConstants";
import API from "./API";
import {LocalDb} from "../api";

class ProfileAPI extends API {
    changePassword = (accountData, callback) => {
        let body = JSON.stringify(accountData);
        fetch(APIEndPoints.CHANGE_PASSWORD, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
                this.processResponse(response, callback);
            }).catch(error => {
                callback(null, "Service error, please try again.");
            });
    };

    updateProfile = (profileData, callback) => {
        let body = JSON.stringify(profileData);
        fetch(APIEndPoints.UPDATE_USER, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling patching user api", error);
            callback(null, "Service error, please try again.");
        });
    };
    fetchProfile = (userId,callback) =>{
        const userInfoFromDB = LocalDb.getSessions() ;
        const userType = userInfoFromDB ? userInfoFromDB.loginResponse.loginUser.user ?
            "EVERFUND_USER" :userInfoFromDB.loginResponse.loginUser.corporate ? "CORPORATE_USER":
                "CLIENT_USER":null
        const  urlString = userType == "EVERFUND_USER" ?
            APIEndPoints.FETCH_PROFILE_EVERFUND_USER + userId :
            userType === "CORPORATE_USER"?
            APIEndPoints.FETCH_PROFILE_CORPORATE_USER + userId :
            APIEndPoints.FETCH_PROFILE_CLIENT_USER + userId
        ;
        fetch(urlString, {
            method: 'GET',
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, error);
        })
    };

    assignProfilePicture = (profilePictureUrl, callback) => {
        let url = APIEndPoints.ASSIGN_PROFILE_PICTURE + profilePictureUrl;
        fetch( url, {
            method: "PATCH",
            headers: this.authHeaders,
            body: JSON.stringify(""),

        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling patching user api", error);
            callback(null, "Service error, please try again.");
        });
    };

    updateProfilePicture = (profilePicture, callback) => {
        fetch(APIEndPoints.UPLOAD_PROFILE_PICTURE, {
            method: "POST",
            headers: this.authHeadersForMultipartFormData,
            body: profilePicture,
            mimeType:'multipart/form-data'
    }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    };
    getAlertInfo = (id, callback) => {
        const constUrl = APIEndPoints.GET_ALERT_BY_ACCOUNT_ID + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };
}

export default new ProfileAPI();