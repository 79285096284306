import {connect} from 'react-redux';
import {DynamicActionScreen} from "../../screens";
import {
    addActionFailure,
    addDynamicActionDataFailure,
    addDynamicActionDataSuccess, caseRequestMade,
    dynamicActionRequestMade,
    handleChangeDynamicActionData,
    setCaseToEdit,
    setPreviousTrue,
} from "../../actions";
import {ActivityLogAPI, CaseAPI, ContactApi, DynamicActionAPI} from "../../api";
import {
    closeSnackBarForDynamicActionPage,
    exportActionDataFailure,
    exportActionDataSuccess,
    fetchActionInfoSuccess,
    generateLinkFailure,
    generateLinkSuccess,
    getConditionalCaseFormFailure,
    getConditionalCaseFormSuccess, handleChangeActionInputData,
    handleChangeCheckBoxValue,
    handleChangeCheckBoxValueForConditionalForm,
    handleChangeClientEmail,
    handleChangeConditionalActionData, handleChangeConditionalContactValue, handleChangeConditionalFileValue,
    handleChangeContactValue,
    handleChangeDateOfBirth,
    handleChangeFileName,
    handleChangeImage,
    handleChangeSelectedValue, handleSetSelectedOption,
    markDynamicActionDataFailure,
    markDynamicActionDataSuccess,
    setConditionalCaseFormFailure,
    setConditionalCaseFormSuccess,
    setConditionalFormDataRequest,
    setConditionalFormDataToEmpty, setConditionalFormSuccessMessage,
    updateConditionalFileFailure,
    updateConditionalFileSuccess,
    updateFileFailure,
    updateFileSuccess
} from "../../actions/DynamicPageAction";
import {
    contactRequestMade, fetchContactFailure,
    fetchContactSuccess,
    searchContactFailure,
    searchContactSuccess
} from "../../actions/ContactAction";
import {
    activityLogRequestMade,
    fetchActivityLogByIdFailure,
    fetchActivityLogByIdSuccess, handleCloseSnackBar
} from "../../actions/ActivityLogAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getActionInfo: (caseId, workflowId, actionId) => {
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.fetchDynamicActionData(caseId, workflowId, actionId, (response, error) => {
                if (response) {
                    dispatch(fetchActionInfoSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(addActionFailure(error.msg));
                    } else {
                        dispatch(addActionFailure(error));
                    }
                }
            })
        },
        getCaseInfo: (caseId, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.fetchSingleCase(caseId, (response, error) => {
                if (response) {
                    dispatch(caseRequestMade(true));
                    dispatch(setCaseToEdit(response.case));
                } else {
                    if (error.msg) {
                        dispatch(addDynamicActionDataFailure(error.msg));
                    } else {
                        dispatch(addDynamicActionDataFailure(error));
                    }
                }
            })
        },
        handleChangeDynamicActionData: (actionFormData) => dispatch(handleChangeDynamicActionData(actionFormData)),
        setPreviousTrue: (bool) => dispatch(setPreviousTrue(bool)),

        addDynamicActionData: (caseId, workflowId, actionId, actionFormInfo,conditionalFormDataRequest, props) => {
            const actionData = {
                caseId: caseId,
                workflowId: workflowId,
                actionId: actionId,
                actionFormData: actionFormInfo,
            };
            if(conditionalFormDataRequest){
                actionData.conditionalFormDataReq = conditionalFormDataRequest
            }
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.addNewAction(actionData, (response, error) => {
                if (response) {
                    dispatch(addDynamicActionDataSuccess(true));
                    props.history.replace('/case/update/' + caseId);
                } else {
                    if (error.msg) {
                        dispatch(addDynamicActionDataFailure(error.msg));
                    } else {
                        dispatch(addDynamicActionDataFailure(error));
                    }
                }
            });
        },
        editDynamicActionData: (caseId, workflowId, actionId, actionFormInfo, props) => {
            const actionData = {
                caseId: caseId,
                workflowId: workflowId,
                actionId: actionId,
                actionFormData: actionFormInfo,
            };
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.editDynamicActionData(actionData, (response, error) => {
                if (response) {
                    dispatch(addDynamicActionDataSuccess(true));
                    props.history.replace('/case/update/' + caseId);
                } else {
                    if (error.msg) {
                        dispatch(addDynamicActionDataFailure(error.msg));
                    } else {
                        dispatch(addDynamicActionDataFailure(error));
                    }
                }
            });
        },
        setCaseData: (caseFormData) => {
            dispatch(setCaseToEdit(caseFormData))
        },
        markAsDoneDynamicActionData: (caseId, workflowId, actionId, status, props) => {
            const actionData = {caseId: caseId, workFlowId: workflowId, actionId: actionId, status: status};
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.markAsDoneDynamicActionData(actionData, (response, error) => {
                if (response) {
                    dispatch(markDynamicActionDataSuccess(true));
                    props.history.replace('/case/update/' + caseId)
                } else {
                    if (error.msg) {
                        dispatch(markDynamicActionDataFailure(error.msg));
                    } else {
                        dispatch(markDynamicActionDataFailure(error));
                    }
                }
            });
        },

        uploadImageFile: (selectedFiles, actionFormId,fileName, props) => {
            dispatch(dynamicActionRequestMade(true));
            let formData = new FormData();
            formData.append("image", selectedFiles.image);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadImageFile(formData, (response, error) => {
                if (response) {
                    let data = {
                        actionFormId: actionFormId,
                        data: response.fileUrl,
                        fileName: fileName,
                    };
                    dispatch(updateFileSuccess(data))
                } else {
                    if (error.msg) {
                        dispatch(updateFileFailure(error.msg));
                    } else {
                        dispatch(updateFileFailure(error));
                    }
                }
            });
        },
        uploadDocumentFile: (selectedFiles, actionFormId, props) => {
            dispatch(dynamicActionRequestMade(true));
            let formData = new FormData();
            formData.append("doc", selectedFiles.document);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadDocumentFile(formData, (response, error) => {
                if (response) {
                    let data = {
                        actionFormId: actionFormId,
                        data: response.fileUrl,
                        fileName: selectedFiles.fileName,
                    };
                    dispatch(updateFileSuccess(data))
                } else {
                    if (error.msg) {
                        dispatch(updateFileFailure(error.msg));
                    } else {
                        dispatch(updateFileFailure(error));
                    }
                }
            });
        },
        ///CONDITIONAL FORM

        uploadConditionalImageFile: (selectedFiles,conditionalFormId,refId, fileName, props) => {
            dispatch(dynamicActionRequestMade(true));
            let formData = new FormData();
            formData.append("image", selectedFiles.image);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadImageFile(formData, (response, error) => {
                if (response) {
                    let data = {
                        conditionalFormId: conditionalFormId,
                        data: response.fileUrl,
                        fileName: fileName,
                    };
                    dispatch(updateConditionalFileSuccess(data, response.fileUrl, refId, conditionalFormId, fileName))
                } else {
                    if (error.msg) {
                        dispatch(updateConditionalFileFailure(error.msg));
                    } else {
                        dispatch(updateConditionalFileFailure(error));
                    }
                }
            });
        },

        uploadConditionalDocumentFile: (selectedFiles,conditionalFormId,refId, fileName, props) => {
            dispatch(dynamicActionRequestMade(true));
            let formData = new FormData();
            formData.append("doc", selectedFiles.document);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadDocumentFile(formData, (response, error) => {
                if (response) {
                    let data = {
                        conditionalFormId: conditionalFormId,
                        data: response.fileUrl,
                        fileName: fileName,
                    };
                    dispatch(updateConditionalFileSuccess(data, response.fileUrl, refId, conditionalFormId, fileName))
                } else {
                    if (error.msg) {
                        dispatch(updateConditionalFileFailure(error.msg));
                    } else {
                        dispatch(updateConditionalFileFailure(error));
                    }
                }
            });
        },

        generateLink: (caseId, workflowId, actionId, email, props) => {
            const contentData = {
                emailPhone: email,
                actionFormDataReq: {
                    caseId: caseId,
                    actionId: actionId,
                    workflowId: workflowId,
                },
            };
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.generateLink(contentData, (response, error) => {
                if (response) {
                    dispatch(generateLinkSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(generateLinkFailure(error.msg));
                    } else {
                        dispatch(generateLinkFailure(error));
                    }
                }
            });
        },
        setValueNull: () => dispatch(fetchActionInfoSuccess({})),
        handleSnackBarClose: () => dispatch(closeSnackBarForDynamicActionPage(true)),
        handleChangeClientEmail: (email) => dispatch(handleChangeClientEmail(email)),
        handleChangeImage : (id) => dispatch(handleChangeImage(id)),
        handleChangeFileName: (id, fileName) => dispatch(handleChangeFileName(id, fileName)),
        handleChangeSelectedValue: (id, selectedValue) => dispatch(handleChangeSelectedValue(id, selectedValue)),
        handleChangeCheckedValue: (id, isSelected, data, fullData, option) =>
            dispatch(handleChangeCheckBoxValue(id, isSelected, data, fullData, option)),
        handleChangeDob: (id, dateValue) => dispatch(handleChangeDateOfBirth(id, dateValue)),
        handleChangeContactValue: (id, contactData, selectedContact) => dispatch(handleChangeContactValue(id, contactData, selectedContact)),
        // export action data
        exportActionData: (caseId, workflowId, actionId, props) => {
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.exportActionData(caseId, workflowId, actionId, (response, error) => {
                if (response) {
                    dispatch(exportActionDataSuccess(response));
                    // window.open(response.msg, '_blank');
                    const popUp = window.open(response.msg, '_blank');
                    if (popUp == null || typeof(popUp)=='undefined') {
                        alert('Please disable your pop-up blocker and click the "Open" link again.');
                    }
                    else {
                        popUp.focus();
                    }
                } else {
                    if (error.msg) {
                        dispatch(exportActionDataFailure(error.msg));
                    } else {
                        dispatch(exportActionDataFailure(error));
                    }
                }
            })
        },

        //search contacts
        searchIndividualContact: (value, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.searchIndividualContact(value, (response, error) => {
                if (response) {
                    dispatch(searchContactSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchContactFailure(error.msg));
                    } else {
                        dispatch(searchContactFailure(error));
                    }
                }
            });
        },
        fetchContactList: () =>{
            dispatch(contactRequestMade(true));
            ContactApi.fetchContactList((response, error) => {
                if (response) {
                    dispatch(fetchContactSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchContactFailure(error.msg))
                    } else {
                        dispatch(fetchContactFailure(error));
                    }
                }
            })
        },


        //set and get for conditional data
        getConditionalDataForCase: (caseId, workflowId, refId, actionFormId, step, prevActionFormId, prevRefId, prevOptionName, props) =>{
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.getConditionalCaseFormByRefId(caseId, workflowId, refId,(response, error) => {
                if (response) {
                    dispatch(getConditionalCaseFormSuccess(response, caseId, workflowId, refId,
                        actionFormId, step, prevActionFormId, prevRefId, prevOptionName));
                    if(response.conditionalForms === undefined){
                        dispatch(setConditionalFormSuccessMessage(false))
                    }else {
                        dispatch(setConditionalFormSuccessMessage(true))
                    }
                } else {
                    if (error.msg) {
                        dispatch(getConditionalCaseFormFailure(error.msg))
                    } else {
                        dispatch(getConditionalCaseFormFailure(error));
                    }
                }
            })
        },

        setConditionalFormSuccessMessage: (bool) => dispatch(setConditionalFormSuccessMessage(bool)),

        //set and get for conditional data for case
        updateConditionalDataForCase: (conditionalDataForm, props) => {
            dispatch(dynamicActionRequestMade(true));
            DynamicActionAPI.setConditionalCaseForm(conditionalDataForm,(response, error) => {
                if (response) {
                    dispatch(setConditionalCaseFormSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(setConditionalCaseFormFailure(error.msg))
                    } else {
                        dispatch(setConditionalCaseFormFailure(error));
                    }
                }
            })
        },
        setConditionalFormDataRequest : (conditionalForm, setPrevious) =>
            dispatch(setConditionalFormDataRequest(conditionalForm, setPrevious)),
        handleChangeConditionalActionData: (data,selectedValue, refId, conditionalFormId) =>
            dispatch(handleChangeConditionalActionData(data,selectedValue, refId, conditionalFormId)),
        handleChangeCheckBoxValueForConditionalForm: (conditionalFormId , isSelected, data, refId, parentRefId, parentActionFormId,conditionalFormDataIdList) =>
            dispatch(handleChangeCheckBoxValueForConditionalForm(conditionalFormId , isSelected, data, refId, parentRefId, parentActionFormId,conditionalFormDataIdList)),
        setConditionalFormDataToEmpty: () => {
            dispatch(setConditionalFormDataToEmpty());
        },
        handleChangeConditionalFileValue : (value, refId, conditionalFormId) =>
            dispatch(handleChangeConditionalFileValue(value, refId, conditionalFormId)),
        handleSetSelectedOption : (conditionalFormId, data) => {dispatch(handleSetSelectedOption(conditionalFormId, data))},
        handleChangeConditionalContactValue: (conditionalFormId, contactData, selectedContact, refId) =>
        {dispatch(handleChangeConditionalContactValue(conditionalFormId, contactData, selectedContact, refId))},


        //new action form methods
        handleChangeActionInputData : (inputValue, actionFormId) => {
            dispatch(handleChangeActionInputData(inputValue, actionFormId))
        },

        //GET ACTION'S ACTIVITY LOG
        fetchActivityLogById: (id ,workflowId, caseId, props) =>{
            dispatch(activityLogRequestMade(true));
            ActivityLogAPI.fetchActivityLogById(id, workflowId, caseId,  (response, error) => {
                if (response) {
                    dispatch(fetchActivityLogByIdSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchActivityLogByIdFailure(error.msg))
                    } else {
                        dispatch(fetchActivityLogByIdFailure(error));
                    }
                }
            })
        },
        handleCloseSnackBar: () => {dispatch(handleCloseSnackBar())},
    };

};
export const DynamicActionContainer = connect(mapStateToProps, mapDispatchToProps)(DynamicActionScreen);