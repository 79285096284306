import { ACTION_TYPE_TRANSACTION } from "../constants/Type";

export const transactionRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.TRANSACTION_REQUEST_MADE,
    bool,
  };
};

export const exportTransactionVODRequest = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.TRANSACTION_VOD_REQUEST,
    bool,
  };
};
export const fetchTransactionSuccess = (transactionData) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_TRANSACTION_SUCCESS,
    transactionData,
  };
};

export const fetchTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_TRANSACTION_FAILURE,
    error,
  };
};

// fetch deleted transaction
export const fetchDeletedTransactionSuccess = (transactionData) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_DELETED_TRANSACTION_SUCCESS,
    transactionData,
  };
};

export const fetchDeletedTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_DELETED_TRANSACTION_FAILURE,
    error,
  };
};

export const setTransactionToViewSuccess = (trans) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_TRANSACTION_ID_TO_VIEW_SUCCESS,
    trans,
  };
};
export const setTransactionToViewFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_TRANSACTION_ID_TO_VIEW_FAILURE,
    error,
  };
};

export const searchTransactionSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_SUCCESS,
    transaction,
  };
};

export const searchTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_FAILURE,
    error,
  };
};

export const getClientTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS,
    response,
  };
};

export const getClientTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_INDIVIDUAL_CLIENT_TRANSACTION_FAILURE,
    error,
  };
};

export const closeTransactionSnackBar = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLOSE_SNACK_BAR,
    bool,
  };
};

export const closeClientTransactionSnackBar = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLOSE_CLIENT_TRANSACTION_SNACK_BAR,
    bool,
  };
};

export const searchTransactionByDateSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_DATE_SUCCESS,
    transaction,
  };
};

export const searchTransactionByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};
export const searchTransactionByMediumSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_SUCCESS,
    transaction,
  };
};

export const searchTransactionByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};
export const searchTransactionByStatusSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_SUCCESS,
    transaction,
  };
};

export const searchTransactionByStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_FAILURE,
    error,
  };
};
export const handleChangeTransactionStatusValue = (transStatus) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TRANSACTION_STATUS,
    transStatus,
  };
};

//MEDIUM AND STATUS
export const searchTransactionByMediumAndStatusSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS,
    transaction,
  };
};

export const searchTransactionByMediumAndStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE,
    error,
  };
};

//MEDIUM AND DATE
export const searchTransactionByMediumAndDateSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS,
    transaction,
  };
};

export const searchTransactionByMediumAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_AND_DATE_FAILURE,
    error,
  };
};

//STATUS AND DATE
export const searchTransactionByStatusAndDateSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS,
    transaction,
  };
};

export const searchTransactionByStatusAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_AND_DATE_FAILURE,
    error,
  };
};
//MEDIUM AND STATUS AND DATE
export const searchTransactionByMediumAndStatusAndDateSuccess = (
  transaction
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS,
    transaction,
  };
};

export const searchTransactionByMediumAndStatusAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE,
    error,
  };
};
export const searchSubmittedTransactionSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_SUBMITTED_TRANSACTION_SUCCESS,
    transaction,
  };
};

//******CLIENT TRANSACTION FILTER********//
// Search with client
export const searchClientTransactionSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_FAILURE,
    error,
  };
};

export const searchClientTransactionByDateSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_DATE_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};
//MEDIUM
export const searchClientTransactionByMediumSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};
//STATUS
export const searchClientTransactionByStatusSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_FAILURE,
    error,
  };
};
//MEDIUM AND STATUS
export const searchClientTransactionByMediumAndStatusSuccess = (
  transaction
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByMediumAndStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE,
    error,
  };
};
//MEDIUM AND DATE
export const searchClientTransactionByMediumAndDateSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByMediumAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE,
    error,
  };
};
//STATUS AND DATE
export const searchClientTransactionByStatusAndDateSuccess = (transaction) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByStatusAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_FAILURE,
    error,
  };
};
//MEDIUM AND STATUS AND DATE
export const searchClientTransactionByMediumAndStatusAndDateSuccess = (
  transaction
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS,
    transaction,
  };
};

export const searchClientTransactionByMediumAndStatusAndDateFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE,
    error,
  };
};

//HANDLE CHANGES ACTIONS
export const handleChangeDateFrom = (from) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_DATE_FROM,
    from,
  };
};

export const handleChangeDateTo = (to) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_DATE_TO,
    to,
  };
};

export const handleChangeMedium = (medium) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_MEDIUM,
    medium,
  };
};

export const handleChangeVendor = (vendor) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_VENDOR,
    vendor,
  };
};

export const handleChangeStatus = (status) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_STATUS,
    status,
  };
};

export const handleTransactionStatus = (status) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_STATUS,
    status,
  };
};
export const handleChangeTerm = (term) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TERM,
    term,
  };
};
export const handleChangeTransType = (transactionType) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TYPE,
    transactionType,
  };
};

export const handleChangeExportType = (exportType) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_EXPORT_TYPE,
    exportType,
  };
};
//*********EDIT TRANSACTION */
export const setTransactionInputDataToEdit = (selectedTransData) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_TRANSACTION_INPUT_DATA_TO_EDIT,
    selectedTransData,
  };
};

//************EXPORT PDF**************
export const exportTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_SUCCESS,
    response,
  };
};

export const exportTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_FAILURE,
    error,
  };
};

export const exportTransactionByDateSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_SUCCESS,
    response,
  };
};

export const exportTransactionByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};
export const exportTransactionByTermSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_TERM_SUCCESS,
    response,
  };
};

export const exportTransactionByTermFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_TERM_FAILURE,
    error,
  };
};

export const exportTransactionByMediumSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_MEDIUM_SUCCESS,
    response,
  };
};

export const exportTransactionByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};

export const exportTransactionByStatusSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_SUCCESS,
    response,
  };
};

export const exportTransactionByStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_FAILURE,
    error,
  };
};
export const exportTransactionByStatusAndMediumSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS,
    response,
  };
};

export const exportTransactionByStatusAndMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE,
    error,
  };
};
export const exportTransactionByDateAndMediumSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS,
    response,
  };
};

export const exportTransactionByDateAndMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE,
    error,
  };
};
export const exportTransactionByDateAndStatusSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS,
    response,
  };
};

export const exportTransactionByDateAndStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE,
    error,
  };
};
export const exportTransactionByDateAndStatusAndMediumSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS,
    response,
  };
};

export const exportTransactionByDateAndStatusAndMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE,
    error,
  };
};

// ***************EXPORT VOD*************************
export const exportTransactionVODSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_VOD_SUCCESS,
    response,
  };
};

export const exportTransactionVODFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_VOD_FAILURE,
    error,
  };
};

//************EXPORT PDF BY CLIENT ID**********
export const exportSingleClientTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_FAILURE,
    error,
  };
};

export const exportSingleClientTransactionByDateSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};
export const exportSingleClientTransactionByTermSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByTermFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_FAILURE,
    error,
  };
};

export const exportSingleClientTransactionByMediumSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};

export const exportSingleClientTransactionByStatusSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_FAILURE,
    error,
  };
};
export const exportSingleClientTransactionByStatusAndMediumSuccess = (
  response
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByStatusAndMediumFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE,
    error,
  };
};
export const exportSingleClientTransactionByDateAndMediumSuccess = (
  response
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByDateAndMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE,
    error,
  };
};
export const exportSingleClientTransactionByDateAndStatusSuccess = (
  response
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByDateAndStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE,
    error,
  };
};
export const exportSingleClientTransactionByDateAndStatusAndMediumSuccess = (
  response
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS,
    response,
  };
};

export const exportSingleClientTransactionByDateAndStatusAndMediumFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE,
    error,
  };
};

export const openSnackBar = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.OPEN_SNACK_BAR,
  };
};

// set bool to open create transaction form
export const setOpenCreateTransactionForm = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_OPEN_CREATE_TRANSACTION_MODAL,
    bool,
  };
};

// set bool to open create transaction form
export const setLedgerTxnStatusUpdateModal = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_OPEN_MODAL_TO_CHANGE_LEDGER_TXN_STATUS,
    bool,
  };
};

export const clearCreateTransactionForm = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_CREATE_TRANSACTION_MODAL,
  };
};
export const clearWithdrawalTransactionForm = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_WITHDRAWAL_TRANSACTION_MODAL,
  };
};
// ledger transaction
export const updateLedgerTxnSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_LEDGER_TRANSACTION_STATUS_SUCCESS,
    response,
  };
};

export const updateLedgerTxnFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_LEDGER_TRANSACTION_STATUS_FAILURE,
    error,
  };
};

//submit transaction
export const handleChangeTransactionStatus = (status) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_SUBMIT_TRANS_STATUS,
    status,
  };
};

export const handleChangeTransactionRemark = (remark) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_SUBMIT_TRANS_REMARK,
    remark,
  };
};
export const handleChangeSelectedData = (bool, selectedData) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_SELECTED_DATA,
    bool,
    selectedData,
  };
};

export const fetchSubmitTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_SUBMIT_TRANSACTION_SUCCESS,
    response,
  };
};
export const fetchSubmitTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_SUBMIT_TRANSACTION_FAILURE,
    error,
  };
};

export const fetchApprovedTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_APPROVED_TRANSACTION_SUCCESS,
    response,
  };
};
export const fetchApprovedTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_APPROVED_TRANSACTION_FAILURE,
    error,
  };
};

export const fetchCreatedTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_CREATED_TRANSACTION_SUCCESS,
    response,
  };
};
export const fetchCreatedTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_CREATED_TRANSACTION_FAILURE,
    error,
  };
};

// UPDATE BLOCKED TRANSACTION
export const handleChangeBlockedTransData = (id, value) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_EDIT_BLOCKED_TRANS_DATA,
    value,
    id,
  };
};
export const updateBlockedTransSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPLOAD_BLOCKED_TRANSACTION_SUCCESS,
    response,
  };
};

export const updateBlockedTransFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPLOAD_BLOCKED_TRANSACTION_FAILURE,
    error,
  };
};
export const delcineTransCheckSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.DECLINE_TRANS_CHECK_SUCCESS,
    response,
  };
};

export const delcineTransCheckFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.DECLINE_TRANS_CHECK_FAILURE,
    error,
  };
};
export const clearEditBlockedTrans = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_BLOCKED_TRANSACTION,
  };
};
export const updateSubmitTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_SUBMIT_TRANSACTION_SUCCESS,
    response,
  };
};
export const updateSubmitTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_SUBMIT_TRANSACTION_FAILURE,
    error,
  };
};

export const updateTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_TRANSACTION_SUCCESS,
    response,
  };
};
export const updateTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_TRANSACTION_FAILURE,
    error,
  };
};

export const clearCheckBoxValues = (values) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_CHECKED_AND_UNCHECKED_VALUE,
    values,
  };
};
export const clearUpdateModalData = (values) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_UPDATE_MODAL_DATA,
    values,
  };
};

//CREATE TRANSACTION

export const handleChangeTransData = (id, value) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TRANS_DATA,
    value,
    id,
  };
};
export const handleChangeDescData = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_DESC_DATA,
    response,
  };
};
export const clearAmountDetail = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_AMOUNT_DETAIL,
  };
};
export const getAmountDetailByClientIdSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.GET_AMOUNT_DETAIL_BY_CLIENT_ID_SUCCESS,
    response,
  };
};
export const getAmountDetailByClientIdFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.GET_AMOUNT_DETAIL_BY_CLIENT_ID_FAILURE,
    error,
  };
};
export const createTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CREATE_TRANSACTION_SUCCESS,
    response,
  };
};
export const createTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CREATE_TRANSACTION_FAILURE,
    error,
  };
};

// FOR CREATING CLIENT TRANSACTON
export const createClientTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CREATE_CLIENT_TRANSACTION_SUCCESS,
    response,
  };
};
export const createClientTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CREATE_CLIENT_TRANSACTION_FAILURE,
    error,
  };
};

// set data to edit transaction
export const setClientTransactionInputDataToEdit = (data) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_CLIENT_TRANSACTION_DATA_TO_EDIT,
    data,
  };
};

//IMPORT TRANSACTION
export const handleChangeCSVFile = (csvFile) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_CSV_FILE,
    csvFile,
  };
};
export const importTransactionSuccess = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.IMPORT_TRANSACTION_SUCCESS,
    bool,
  };
};
export const importTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.IMPORT_TRANSACTION_FAILURE,
    error,
  };
};

export const getBankDetailsSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.GET_BANK_DETAILS_SUCCESS,
    response,
  };
};
export const getBankDetailsFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.GET_BANK_DETAILS_FAILURE,
    error,
  };
};

//DOWNLOAD ACH MEDIUM TRANSACTION

export const downloadACHDocumentSuccess = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.DOWNLOAD_ACH_DOCUMENT_SUCCESS,
    bool,
  };
};
export const downloadACHDocumentFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.DOWNLOAD_ACH_DOCUMENT_FAILURE,
    error,
  };
};

// ***LEDGER TRANSACTION***
// FETCH LEDGER TRANSACTION
export const fetchLedgerTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_LEDGER_TRANSACTION_SUCCESS,
    response,
  };
};
export const fetchLedgerTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_SUBMIT_TRANSACTION_FAILURE,
    error,
  };
};

export const fetchWithdrawalVendorListSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_WITHDRAWAL_VENDOR_LIST_SUCCESS,
    response,
  };
};

// export const fetchWithdrawalVendorListSuccess = (response) => {
//     return {
//         type: ACTION_TYPE_TRANSACTION.FETCH_WITHDRAWAL_VENDOR_LIST_SUCCESS,
//         response,
//     };
// };
export const fetchWithdrawalVendorListFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_WITHDRAWAL_VENDOR_LIST_FAILURE,
    error,
  };
};

//EXPORT LEDGER TRANSACTION
export const exportLedgerTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_LEDGER_TRANSACTION_SUCCESS,
    response,
  };
};
export const exportLedgerTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_LEDGER_TRANSACTION_FAILURE,
    error,
  };
};

// FETCH CLIENT LEDGER TRANSACTION
export const fetchClientLedgerTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_CLIENT_LEDGER_TRANSACTION_SUCCESS,
    response,
  };
};
export const fetchClientLedgerTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.FETCH_CLIENT_LEDGER_TRANSACTION_FAILURE,
    error,
  };
};

//EXPORT LEDGER TRANSACTION
export const exportClientLedgerTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_CLIENT_LEDGER_TRANSACTION_SUCCESS,
    response,
  };
};
export const exportClientLedgerTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.EXPORT_CLIENT_LEDGER_TRANSACTION_FAILURE,
    error,
  };
};

// create from ledger transaction
export const handleOpenCreateWithdrawalVendorTransaction = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_OPEN_CREATE_WITHDRAWAL_VENDOR_TRANSACTION,
    bool,
  };
};

export const createWithdrawalVendorTxnsSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CREATE_WITHDRAWAL_VENDOR_TRANSACTION_SUCCESS,
    response,
  };
};
export const createWithdrawalVendorTxnsFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.CREATE_WITHDRAWAL_VENDOR_TRANSACTION_FAILURE,
    error,
  };
};

// notes
export const handleChangeToOpenNoteModal = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TO_OPEN_NOTE_MODAL,
    bool,
  };
};

export const handleChangeToNotes = (notes) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_NOTES,
    notes,
  };
};

export const setNotesToEdit = (notes) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SET_NOTES_TO_EDIT,
    notes,
  };
};

export const addNoteSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.ADD_NOTES_SUCCESS,
    response,
  };
};

export const addNoteFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.ADD_NOTES_FAILURE,
    error,
  };
};

export const updateNoteSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_NOTES_SUCCESS,
    response,
  };
};
export const updateNoteFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_NOTES_FAILURE,
    error,
  };
};

//ledger notes
export const handleChangeToOpenNoteModalForLedger = (bool) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_LEDGER,
    bool,
  };
};

export const updateDescriptionSuccess = (response) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_DESCRIPTION_SUCCESS,
    response,
  };
};
export const updateDescriptionError = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.UPDATE_DESCRIPTION_ERROR,
    error,
  };
};

//delete transaction
export const deleteTransactionSuccess = (transactionId) => {
  return {
    type: ACTION_TYPE_TRANSACTION.DELETE_TRANSACTION_SUCCESS,
    transactionId,
  };
};
export const deleteTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_TRANSACTION.DELETE_TRANSACTION_FAILURE,
    error,
  };
};

// new filter
export const handleTransactionFilter = (id, value) => {
  return {
    type: ACTION_TYPE_TRANSACTION.TRANSACTION_FILTER,
    id,
    value,
  };
};
export const clearTransactionFilter = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_TRANSACTIONS_FILTER,
  };
};
export const clearTransactionMainFilter = () => {
  return {
    type: ACTION_TYPE_TRANSACTION.CLEAR_TRANSACTIONS_MAIN_FILTER,
  };
};
export const saveTransConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SAVE_TRANSACTION_CONFIG_SUCCESS,
    payload,
  };
};
export const saveTransConfigFailure = (payload) => {
  return {
    type: ACTION_TYPE_TRANSACTION.SAVE_TRANSACTION_CONFIG_FAILURE,
    payload,
  };
};
export const getTransConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPE_TRANSACTION.GET_TRANSACTION_CONFIG_SUCCESS,
    payload,
  };
};
export const getTransConfigFailure = (payload) => {
  return {
    type: ACTION_TYPE_TRANSACTION.GET_TRANSACTION_CONFIG_FAILURE,
    payload,
  };
};

export const handlChangeReverseDate = (date) => {
  return {
    type: ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_REVERSED_DATE,
    date,
  };
};
