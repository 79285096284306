import {connect} from 'react-redux';
import DynamicActionForClientScreen from "../../screens/DynamicActionForClientScreen";
import {ContactApi, DynamicActionAPI, DynamicActionForClientAPI} from "../../api";
import {
    dynamicActionRequestMade,
    validateUrlFailure,
    validateUrlSuccess
} from "../../actions";
import {
    closeDynamicActionClientSnackBar,
    dynamicActionClientRequestMade, getConditionalFormByClientFailure, getConditionalFormByClientSuccess,
    handleChangeCheckBoxValueByClient,
    handleChangeClientActionData, handleChangeContactValueByClient,
    handleChangeDateOfBirthByClient,
    handleChangeFileNameByClient, handleChangeInputByClient,
    handleChangeSelectedValueByClient, handleCloseClientConditionalForm,
    sendDynamicActionDataFailure,
    sendDynamicActionDataSuccess,
    setPreviousValueTrue,
    uploadFileByClientFailure,
    uploadFileByClientSuccess,
} from "../../actions/DynamicClientPageAction";
import {
    contactRequestMade, fetchContactFailure,
    fetchContactSuccess,
    searchContactFailure,
    searchContactSuccess
} from "../../actions/ContactAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        validateLink: (linkKey, props) => {
            dispatch(dynamicActionClientRequestMade(true));
            console.log("link key", linkKey);
            DynamicActionForClientAPI.validateLink(linkKey, (response, error) => {
                if (response) {
                    dispatch(validateUrlSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(validateUrlFailure(error.msg));
                    } else {
                        dispatch(validateUrlFailure(error));
                    }
                }
            });
        },
        uploadImageFileByClient: (selectedFiles, actionFormId, previousValue, linkKey, props) => {
            dispatch(dynamicActionClientRequestMade(true));
            let formData = new FormData();
            formData.append("image", selectedFiles.image);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionForClientAPI.uploadImageFileByClient(formData, linkKey, (response, error) => {
                if (response) {
                    let data = {
                        actionFormId: actionFormId,
                        data: response.fileUrl,
                        fileName: selectedFiles.fileName,
                    };
                    // previousValue.push(data);
                    // props.handleChangeClientActionData(previousValue);
                    dispatch(uploadFileByClientSuccess(data))
                } else {
                    if (error.msg) {
                        dispatch(uploadFileByClientFailure(error.msg));
                    } else {
                        dispatch(uploadFileByClientFailure(error));
                    }
                }
            });
        },
        uploadDocFileByClient: (selectedFiles, actionFormId, previousValue, linkKey, props) => {
            dispatch(dynamicActionClientRequestMade(true));
            let formData = new FormData();
            formData.append("doc", selectedFiles.document);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionForClientAPI.uploadDocumentFileByClient(formData, linkKey, (response, error) => {
                if (response) {
                    let data = {
                        actionFormId: actionFormId,
                        data: response.fileUrl,
                        fileName: selectedFiles.fileName,
                    };
                    // previousValue.push(data);
                    // props.handleChangeClientActionData(previousValue)
                    dispatch(uploadFileByClientSuccess(data))
                } else {
                    if (error.msg) {
                        dispatch(uploadFileByClientFailure(error.msg));
                    } else {
                        dispatch(uploadFileByClientFailure(error));
                    }
                }
            });
        },
        handleChangeClientActionData: (actionFormData) => {
            dispatch(handleChangeClientActionData(actionFormData)
            )
        },
        handleDynamicClientSnackBarClose: () => dispatch(closeDynamicActionClientSnackBar(true)),
        setPreviousValueTrue: (bool) => dispatch(setPreviousValueTrue(bool)),
        handleChangeSelectedValueByClient: (id, selectedValue) =>
            dispatch(handleChangeSelectedValueByClient(id, selectedValue)),
        handleChangeCheckedValueByClient: (id, isSelected, data) =>
            dispatch(handleChangeCheckBoxValueByClient(id, isSelected, data)),
        handleChangeDobByClient: (id, dateValue) =>
            dispatch(handleChangeDateOfBirthByClient(id, dateValue)),
        handleChangeContactValueByClient: (id, contactData, selectedContact) =>
            dispatch(handleChangeContactValueByClient(id, contactData, selectedContact)),
        addDynamicActionClientData: (linkKey, actionFormInfo, props) => {
            const actionData = {
                linkKey: linkKey,
                actionFormDataReq: {
                    actionFormData: actionFormInfo,
                },
            };
            console.log("actionData", actionData)
            dispatch(dynamicActionClientRequestMade(true));
            DynamicActionForClientAPI.addNewAction(actionData, (response, error) => {
                if (response) {
                    dispatch(sendDynamicActionDataSuccess(true));
                    props.validateLink(linkKey);
                } else {
                    if (error.msg) {
                        dispatch(sendDynamicActionDataFailure(error.msg));
                    } else {
                        dispatch(sendDynamicActionDataFailure(error));
                    }
                }
            });
        },
        editDynamicActionClientData: (linkKey, actionFormInfo, props) => {
            const actionData = {
                linkKey: linkKey,
                actionFormDataReq: {
                    actionFormData: actionFormInfo,
                }
            };
            console.log("actionData", actionData)
            dispatch(dynamicActionClientRequestMade(true));
            DynamicActionForClientAPI.editDynamicActionData(actionData, (response, error) => {
                if (response) {
                    dispatch(sendDynamicActionDataSuccess(true));
                    props.validateLink(linkKey);
                } else {
                    if (error.msg) {
                        dispatch(sendDynamicActionDataFailure(error.msg));
                    } else {
                        dispatch(sendDynamicActionDataFailure(error));
                    }
                }
            });
        },
        handleChangeFileNameByClient: (id, fileName) => dispatch(handleChangeFileNameByClient(id, fileName)),
        //search contacts
        searchIndividualContact: (value, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.searchIndividualContact(value, (response, error) => {
                if (response) {
                    dispatch(searchContactSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchContactFailure(error.msg));
                    } else {
                        dispatch(searchContactFailure(error));
                    }
                }
            });
        },
        fetchContactList: () =>{
            dispatch(contactRequestMade(true));
            ContactApi.fetchContactList((response, error) => {
                if (response) {
                    dispatch(fetchContactSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchContactFailure(error.msg))
                    } else {
                        dispatch(fetchContactFailure(error));
                    }
                }
            })
        },

        handleChangeInputByClient: (actionId, inputValue) => {
            dispatch(handleChangeInputByClient(actionId, inputValue))
        },

        //set and get for conditional data
        getConditionalCaseFormWithRefIdByClient: (linkKey, refId, actionFormId, optionName, prevRefId, prevActionFormId,
                                                  prevOptionName, step, props) =>{
            dispatch(dynamicActionRequestMade(true));
            DynamicActionForClientAPI.getConditionalCaseFormWithRefIdByClient(linkKey, refId,(response, error) => {
                if (response) {
                    console.log("conditionalForms response", response.conditionalForms)
                    dispatch(getConditionalFormByClientSuccess(response,refId,actionFormId,optionName,prevRefId, prevActionFormId,
                        prevOptionName, step));
                } else {
                    if (error.msg) {
                        dispatch(getConditionalFormByClientFailure(error.msg))
                    } else {
                        dispatch(getConditionalFormByClientFailure(error));
                    }
                }
            })
        },
        handleCloseClientConditionalForm: () => {
            dispatch(handleCloseClientConditionalForm());
        }
    }
};

export const DynamicActionClientContainer = connect(mapStateToProps, mapDispatchToProps)(DynamicActionForClientScreen);