import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class ActivityLogAPI extends API {
    fetchActivityLog = callback => {
        fetch(APIEndPoints.FETCH_ACTIVITY_LOG, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    fetchACHActivityLog = callback => {
        fetch(APIEndPoints.FETCH_ACH_ACTIVITY_LOG, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    fetchActivityLogByRefId = (id, callback) => {
        fetch(APIEndPoints.FETCH_ACTIVITY_LOG_BY_ID + id, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    fetchActivityLogById =(id, refId1, refId2,  callback)  => {
        fetch(APIEndPoints.GET_ACTIVITY_LOG_BY_ID + id + "?refId1=" + refId1 + "&refId2=" + refId2, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
}

export default new ActivityLogAPI();