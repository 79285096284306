import React, { useState, useEffect } from "react";
import { Checkbox, Input, Space } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const OpenAddressFields = ({
  fieldArray,
  filterAddressData,
  handleOpenDeleteDialogue,
  filterEditAddressData,
  isEdit,
 id, setId, 
  vendors
}) => {

  // const [id, setId] = useState(fieldArray[0].addressId);
  
  // function filterDataArray(filterArray, id){
  //   console.log("filterArray is", filterArray);
  //   const arrayData = filterArray && filterArray.length>0 && filterArray.map((item)=>{
  //       if(item.addressId === id){
  //         item.isDefault = true;
  //       }
  //   })
  //   return arrayData;
  // }
  
  const handleChangePrimaryAddressValue = (key, value) => {
    fieldArray.filter((single) => single.addressId === key)[0].address1 = value;
    if (isEdit) {
      filterEditAddressData();
    } else {
      filterAddressData();
    }
  };
  const handleChangeSecondaryAddressValue = (key, value) => {
    fieldArray.filter((single) => single.addressId === key)[0].address2 = value;
    if (isEdit) {
      filterEditAddressData();
    } else {
      filterAddressData();
    }
  };
  const handleChangeCityValue = (key, value) => {
    fieldArray.filter((single) => single.addressId === key)[0].city = value;
    if (isEdit) {
      filterEditAddressData();
    } else {
      filterAddressData();
    }
  };
  const handleChangeStateValue = (key, value) => {
    fieldArray.filter((single) => single.addressId === key)[0].state = value;
    if (isEdit) {
      filterEditAddressData();
    } else {
      filterAddressData();
    }
  };
  const handleChangeZipCodeValue = (key, value) => {
    fieldArray.filter((single) => single.addressId === key)[0].zip5 = value;
    if (isEdit) {
      filterEditAddressData();
    } else {
      filterAddressData();
    }
  };

  const handleChangeDefaultId = (id,value, label) =>{
    setId(id)
    if (isEdit) {
      filterEditAddressData(id, value);
    } else {
      filterAddressData();
    }
  }

  let div = [];
  // Outer loop to create parent

  if (fieldArray.length > 0) {
    div.push(
      <>
        <h5
          style={{
            width: "100%",
            color: "rgb(204, 102, 0)",
            // paddingTop: '1rem',
            paddingBottom: "0.8rem",
            fontWeight: "550",
          }}
        >
          List of address
        </h5>
        <Space style={{ display: "flex" }} align="baseline">
          <div style={{ width: "150px" }}>
            <label htmlFor="name">
              Address1
              <span style={{ color: "red" }}> *</span>
            </label>
          </div>
          {/*150*/}
          <div style={{ width: "153px" }}>
            <label htmlFor="emailPhone">
              Address2
              {/*<span style={{color: "red"}}> *</span>*/}
            </label>
          </div>
          {/*156*/}
          <div style={{ width: "152px" }}>
            <label htmlFor="contactType">
              City
              <span style={{ color: "red" }}> *</span>
            </label>
          </div>
          <div style={{ width: "150px" }}>
            <label htmlFor="phone">
              State
              <span style={{ color: "red" }}> *</span>
            </label>
          </div>
          <div style={{ width: "150px" }}>
            <label htmlFor="phone">
              Zip Code
              <span style={{ color: "red" }}> *</span>
            </label>
          </div>
          {/* <div style={{ width: "150px" }}>
            <label>
              Default
              <span style={{ color: "red" }}> *</span>
            </label>
          </div> */}
        </Space>
      </>
    );
  }
  

  fieldArray &&
    fieldArray.map((single, index) => {
      return div.push(
        <Space
          key={single.addressId}
          style={{ display: "flex", marginBottom: 10 }}
          align="baseline"
        >
          <div id={single.addressId}>
            <Input
              value={single.address1}
              autoComplete="off"
              className="inputField"
              // placeholder="Primary Address"
              onChange={(e) => {
                e.preventDefault();
                handleChangePrimaryAddressValue(
                  single.addressId,
                  e.target.value
                );
              }}
            />
          </div>
          <div>
            <Input
              className="inputField"
              value={single.address2}
              autoComplete="off"
              // placeholder="Secondary Address"
              onChange={(e) => {
                e.preventDefault();
                handleChangeSecondaryAddressValue(
                  single.addressId,
                  e.target.value
                );
              }}
            />
          </div>
          <div>
            <Input
              value={single.city}
              className="inputField"
              autoComplete="off"
              // placeholder="City"
              onChange={(e) => {
                e.preventDefault();
                handleChangeCityValue(single.addressId, e.target.value);
              }}
            />
          </div>
          <div>
            <Input
              className="inputField"
              value={single.state}
              autoComplete="off"
              // placeholder="State"
              onChange={(e) => {
                e.preventDefault();
                handleChangeStateValue(single.addressId, e.target.value);
              }}
            />
          </div>
          <div>
            <Input
              className="inputField"
              autoComplete="off"
              // placeholder="Zip Code"
              value={single.zip5}
              onChange={(e) => {
                e.preventDefault();
                handleChangeZipCodeValue(single.addressId, e.target.value, );
              }}
            />
          </div>
          <div>
            <Checkbox
              name="isDefault"
              id="isDefault"
              checked = {single.addressId == id}
              // checked = {single.isDefault === true ? true : single.addressId == id}
              onChange={(e) => {
                // setId(single.addressId);
                handleChangeDefaultId(single.addressId, e.target.checked, "isDefault")
              }}
            />
            
          </div>
          <div>
            <MinusCircleOutlined
              onClick={(e) => {
                e.preventDefault();
                handleOpenDeleteDialogue(single.addressId);
              }}
            />
          </div>
        </Space >
      );
    });
  return div;
};

const style = {
  buttonDiv: {
    position: "absolute",
    right: "45px",
    bottom: "8rem"
  },

  defaultBtn: {
    outline: "none",
    padding: "2px 6px",
    borderRadius: "10px",
    background: "seagreen",
    color: "white",
    fontSize: "14px"
  },
  main_div: {
    borderRadius: "4px",
    width: "68rem"
  }
}

export default OpenAddressFields;
