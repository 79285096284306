import {uploadDocumentValidation} from "../components/Helper";

export const onChangeDocumentFileHandler = (eventFileHandler) => {
    let file = eventFileHandler.target.files[0];
    let type = file && file.type;
    let name = file && file.name;
    let size = file && file.size;
    let singleFile = {
        document: file,
        type: type,
        name: name,
    };
    if (uploadDocumentValidation(size, type)) {
        return singleFile;
        // this.props.uploadDocumentFile(singleFile, this.props);
    } else {
        if (size > 26214400) {
            return 'File size is too large. Maximum size allowed is 25MB';
            // this.props.handleShowFileUploadErrorMessage(
            //     "File size is too large. Maximum size allowed is 25MB."
            // );
        } else {
            return "Invalid file type. Only PDF, TXT, CSV, EXCEL, WORD types are accepted";
            // this.props.handleShowFileUploadErrorMessage(
            //     "Invalid file type. Only PDF, TXT, CSV, EXCEL, WORD types are accepted"
            // );
        }
    }
};