import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import MySnackbarContentWrapper from "../../components/Snackbar";
import { Button, Checkbox, Snackbar } from "@material-ui/core";
import { Prompt } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

class Case extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseTemplate: [],
      isBlocking: false,
      agent: [],
      filteredWorkFlow: [],
      caseTemplateId: "",
      allAgentsAssigned: [],
      openModalToAddCaseContact: false,
      selected: [],
      caseContact: [],
      openConfirmToAddContact: false,
      clicked: false,
    };
    this.props.handleChangeCaseInRegisterCase(
      "clientId",
      this.props.state.registerCaseState.clientData.clientId
    );
  }

  componentDidMount() {
    this.props.fetchCaseTemplateList();
    this.props.fetchUserList();
    this.props.fetchClientCaseContacts(
      this.props.state.registerCaseState.clientData.clientId,
      this.props
    );
  }

  componentWillUnmount() {
    this.props.setClientContent("");
    this.props.setCaseAddSucessFully(false);
    this.props.handleRegisterCaseSnackBarClose();
  }

  render() {
    const value =
      this.props.state.registerCaseState.caseData.caseTemplateId !== "";
    // &&
    // this.props.state.registerCaseState.caseData.caseName !== "";

    const clientCaseContact =
      this.props.state.registerCaseState.clientCaseContact;

    const caseTemplateState = this.props.state.caseTemplateState;
    const userState = this.props.state.userState;
    let userRoleAgent = {};
    if (userState.user && userState.user.length >= 1) {
      userRoleAgent = userState.user.filter((e) => e.userRole === "AGENT");
    }
    const handleOpenAddCaseContactModal = () => {
      this.setState({
        openModalToAddCaseContact: true,
      });
    };
    const handleCloseAddCaseContactModal = () => {
      this.setState({
        openModalToAddCaseContact: false,
      });
    };

    const handleOpenConfirmAddCaseContactModal = () => {
      this.setState({
        openConfirmToAddContact: true,
      });
    };
    const handleCloseConfirmAddCaseContactModal = () => {
      this.setState({
        openConfirmToAddContact: false,
      });
    };

    const changeAssignAgents = (workFlowId, value) => {
      let a = createObject(workFlowId, value);
      this.setState({ allAgentsAssigned: a }, () => {
        this.props.handleChangeCaseInRegisterCase(
          "caseAgents",
          this.state.allAgentsAssigned
        );
      });
    };
    const createObject = (workFlowId, value) => {
      let allAgentsAssigned = this.state.allAgentsAssigned.filter(
        (single) => single.refId !== workFlowId
      );

      let data = {
        refId: workFlowId,
        userId: value,
      };
      allAgentsAssigned.push(data);

      return allAgentsAssigned;
    };

    //check list of case contact
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = clientCaseContact.map((n) => n);
        this.setState({
          selected: newSelected,
        });
        return;
      }
      this.setState({
        selected: [],
      });
      // this.props.handleChangeCaseInRegisterCase("caseContact", newCaseContactArray);
    };

    const handleClick = (event, id) => {
      const selectedIndex = this.state.selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selected: newSelected,
      });
    };

    const isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    const newCaseContactArray =
      this.state.selected &&
      this.state.selected.map((object) => ({
        relationshipId: object.relationshipId,
        caseContactType: object.caseContactType,
        refId: object.contact
          ? object.contact.contactId
          : object.company
          ? object.company.companyId
          : "",
      }));

    return (
      <div style={style.container}>
        {this.props.state.registerCaseState.registerCaseSuccessfully === true
          ? this.props.pushToCase()
          : null}
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page?"
        />
        <div className="card">
          <div className="card-header" style={style.cardHeader}>
            <div className="row">
              <div
                className="col-xl-8 col-lg-7 col-md-6 col-sm-6"
                style={style.headerColumn1}
              >
                <h5 style={style.caseText}> Case Detail </h5>
              </div>
              {caseTemplateState &&
              caseTemplateState.caseTemplate &&
              this.state.caseTemplate &&
              caseTemplateState.caseTemplate.length > 0 &&
              this.state.caseTemplate.length !==
                caseTemplateState.caseTemplate.length
                ? this.setState({
                    caseTemplate: caseTemplateState.caseTemplate.map(
                      (template) => ({
                        key: template.caseTemplateId,
                        text: template.templateName,
                        value: template.caseTemplateId,
                      })
                    ),
                  })
                : null}
              <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-4"
                style={style.headerColumn2}
              >
                <Dropdown
                  placeholder="Select Case Template"
                  fluid
                  search
                  selection
                  clearable
                  selectOnBlur={false}
                  id="caseTemplateId"
                  options={this.state.caseTemplate}
                  onChange={(e, data) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: true,
                      caseTemplateId: data.value,
                      filteredWorkFlow: caseTemplateState.caseTemplate.filter(
                        (s) => s.caseTemplateId === data.value
                      ),
                    });
                    this.props.handleChangeCaseInRegisterCase(
                      "caseTemplateId",
                      data.value
                    );
                  }}
                  style={style.dropdownInput}
                />
              </div>
            </div>
          </div>
          <div className="card-body" style={style.cardBody}>
            <div className="row" style={style.rowFirst}></div>
            {this.state.filteredWorkFlow &&
              this.state.filteredWorkFlow.length > 0 &&
              this.state.filteredWorkFlow.map((single) => {
                return (
                  <div className="row" style={style.rowFirst}>
                    <div className={"col-8"}>
                      <h6 style={style.columnHeading}>Workflow</h6>
                    </div>
                    <div className="col-4">
                      <h6 style={style.columnHeading}>Assign to</h6>
                    </div>
                  </div>
                );
              })}
            {this.state.filteredWorkFlow &&
              this.state.filteredWorkFlow.length > 0 &&
              this.state.filteredWorkFlow.map((single) => {
                return (
                  <div>
                    {single.workflow &&
                      single.workflow.length > 0 &&
                      single.workflow.map((singleWorkflow) => {
                        return (
                          <div className="row" style={style.row}>
                            <div className={"col-8"}>
                              <label
                                style={{
                                  color: "rgb(98, 95, 95)",
                                  paddingTop: "16px",
                                  fontWeight: "550",
                                }}
                              >
                                {singleWorkflow.workflowName}
                              </label>
                            </div>
                            <div className="col-4">
                              <div style={style.select}>
                                {userRoleAgent &&
                                this.state.agent &&
                                userRoleAgent.length > 0 &&
                                this.state.agent.length !== userRoleAgent.length
                                  ? this.setState({
                                      agent: userRoleAgent.map(
                                        (singleAgent) => ({
                                          key: singleAgent.userId,
                                          text: singleAgent.fullName,
                                          value: singleAgent.userId,
                                        })
                                      ),
                                    })
                                  : null}
                                <Dropdown
                                  placeholder="Agent"
                                  fluid
                                  clearable
                                  search
                                  selection
                                  selectOnBlur={false}
                                  id={singleWorkflow.workflowId}
                                  options={this.state.agent}
                                  onChange={(e, data) => {
                                    e.preventDefault();
                                    changeAssignAgents(
                                      singleWorkflow.workflowId,
                                      data.value
                                    );
                                    this.setState({ isBlocking: true });
                                  }}
                                  onAddItem={() => {}}
                                  style={style.dropdownInput}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            <div className={"row"}>
              <div className="col-4"></div>
              <div className="col-4"></div>
              <div className="col-4">
                <button
                  disabled={!value}
                  className="btn btn-primary"
                  style={style.nextButton}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    if (clientCaseContact) {
                      handleOpenAddCaseContactModal();
                    } else {
                      this.props.registerCase(
                        this.props.state.registerCaseState.caseData
                      );
                    }
                  }}
                >
                  {" "}
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*add case contact*/}
        <Dialog
          open={this.state.openModalToAddCaseContact}
          onClose={handleCloseAddCaseContactModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <div style={{ background: "#8BC83F", borderRadius: "18px" }}>
            <DialogTitle
              className="dialogHeaderForAddUser"
              id="draggable-dialog-title"
            />
            <DialogContent className="dialogContent">
              <h3
                style={{
                  textAlign: "center",
                  color: "#8BC83F",
                  padding: "0.6rem",
                }}
              >
                Select Case Contacts
              </h3>
              <div style={{ marginLeft: "-14px" }}>
                <div
                  style={{
                    paddingLeft: "5px",
                    borderBottom: "1px solid #d3e7bd",
                  }}
                >
                  <Checkbox
                    indeterminate={
                      this.state.selected &&
                      this.state.selected.length > 0 &&
                      clientCaseContact &&
                      this.state.selected &&
                      this.state.selected.length < clientCaseContact.length
                    }
                    checked={
                      clientCaseContact &&
                      clientCaseContact.length > 0 &&
                      clientCaseContact &&
                      this.state.selected &&
                      this.state.selected.length === clientCaseContact.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all check" }}
                  />
                  <span
                    style={{
                      fontSize: "17px",
                      color: "#8BC34A",
                      fontWeight: "600",
                    }}
                  >
                    List of contacts :
                  </span>
                </div>
                <div>
                  <ul style={{ listStyleType: "none", paddingLeft: "5px" }}>
                    {clientCaseContact &&
                      clientCaseContact.map((contacts, index) => {
                        const isItemSelected = isSelected(contacts);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <li>
                            <Checkbox
                              onClick={(event) => handleClick(event, contacts)}
                              selected={isItemSelected}
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                            {contacts.company
                              ? contacts.company.companyName
                              : contacts.contact.name}{" "}
                            ({contacts.relationship.name})
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "1rem 0px 1.5rem",
                }}
              >
                <div className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseAddCaseContactModal();
                      this.props.registerCase(
                        this.props.state.registerCaseState.caseData
                      );
                    }}
                    className="cancelButton"
                  >
                    Cancel
                  </Button>
                </div>
                <div className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleChangeCaseInRegisterCase(
                        "caseContact",
                        newCaseContactArray
                      );
                      handleOpenConfirmAddCaseContactModal();
                      // this.props.registerCase(this.props.state.registerCaseState.caseData);
                      handleCloseAddCaseContactModal();
                    }}
                    className="addUserButton"
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        <Dialog
          open={this.state.openConfirmToAddContact}
          onClose={handleCloseConfirmAddCaseContactModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title">
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to register{" "}
              {this.props.state.registerCaseState.caseData.caseName}?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-3"}></span>
                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.registerCase(
                        this.props.state.registerCaseState.caseData
                      );
                      handleCloseConfirmAddCaseContactModal();
                    }}
                    className="addUserButton"
                  >
                    ok
                  </Button>
                </span>
                <span className={"col-3"}></span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            this.props.state.registerCaseState.openRegisterCaseSnackBarForCase
          }
          autoHideDuration={3000}
          onClose={this.props.handleRegisterCaseSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleRegisterCaseSnackBarClose}
            variant="error"
            message={this.props.state.registerCaseState.errorMsgForRegisterCase}
          />
        </Snackbar>
      </div>
    );
  }
}

const style = {
  container: {
    background: "#ffffff",
    marginLeft: "3% !important",
    marginRight: "3% !important",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    // position:'absolute'
  },
  cardBody: {
    paddingLeft: "4%",
    paddingRight: "2%",
    marginRight: "1rem",
  },
  cardHeader: {
    backgroundColor: "#fff",
    padding: 0,
  },
  headerColumn1: {
    paddingTop: "5px",
    flex: "68%",
    marginLeft: "10px !important",
  },
  headerColumn2: {
    flex: "27%",
    marginTop: "12px",
    paddingBottom: "10px",
    display: "inline",
    textAlign: "end",
    marginRight: "3.5rem",
    paddingLeft: "0",
    paddingRight: "0",
  },
  caseText: {
    color: "#cc6600",
    fontSize: "22px",
    fontWeight: "bolder",
    paddingTop: "15px",
    paddingLeft: "3rem",
  },
  rowFirst: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px 5px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px 5px",
    paddingBottom: "0.2rem",
  },
  columnHeading: {
    color: "#625F5F",
    fontSize: "16px",
    width: "100%",
  },
  column1: {
    flex: "60%",
    padding: "0 6px",
    paddingRight: "20px",
  },
  column2: {
    flex: "35%",
    padding: "0 6px",
  },
  select: {
    marginTop: "10px",
    position: "relative",
  },
  selectHead: {
    position: "relative",
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "30px",
    marginRight: "2rem",
  },
  selectBarHead: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    marginTop: "2px",
    height: "40px",
    fontSize: "15px",
  },
  selectBar: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "15px",
    height: "46px",
  },
  expandMoreIconHead: {
    position: "absolute",
    right: "5px",
    top: "12px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  expandMoreIcon: {
    position: "absolute",
    right: "10px",
    top: "12px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  nextButton: {
    backgroundColor: "#8BC83F",
    float: "right",
    width: "100%",
    height: "44px",
    border: "0",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "bold",
    marginTop: "31px",
    // marginRight: '10%'
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "16x",
    height: "46px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "0.8rem",
    paddingLeft: "15px",
  },
  inputFieldCaseName: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "16x",
    height: "46px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "0.8rem",
    paddingLeft: "15px",
    marginBottom: "2rem",
  },
  dropdownInput: {
    marginRight: "2rem",
    height: "46px",
    paddingTop: "15px",
  },
};
export default Case;
