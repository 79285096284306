import React, { Component } from "react";
import { InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Input } from "antd";

class GlobalSearchComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div
          className="search"
          style={{ marginTop: "8px", marginRight: "8px" }}
        >
          <div className="searchIcon">
            <SearchIcon style={{ color: "#9c9c9c" }} />
          </div>
          <InputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            className="inputBase"
            onChange={(e) => {
              //   if (!window.location.pathname.includes(`/profile`)) {
              this.props.propertyHistory.push({
                pathname: "/globalsearchresult",
              });
              //   }

              this.props.searchDocument(e.target.value, this.props);
            }}
          />
        </div>
      </>
    );
  }
}

export default GlobalSearchComponent;
