import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  Snackbar,
} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import HomeIcon from "@material-ui/icons/Home";
import { Prompt } from "react-router";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { LocalDb } from "../api";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Select } from "antd";
import { HelmetComponent } from "../components/Helmet";
import { Switch } from "antd";
import ButtonComponent from "./ButtonComponent";

const { Option } = Select;

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateProfileTab: true,
      changePasswordTab: false,
      setNotificationTab: false,
      profilePictureUrl: "",
      selectedFile: [],
      file: "",
      verifyPassword: "",
      newPassword: "",
      isBlocking: false,
      verifyPasswordError: false,
      hidden: true,
      hiddenConform: true,
      hiddenNew: true,
    };
  }

  componentWillMount() {
    // var userID = this.props.state.loginState.user
    // ? this.props.state.loginState.user.userId
    // : this.props.state.loginState.user.corporate
    // ? this.props.state.loginState.user.corporateId
    // : this.props.state.loginState.user.client
    // ? this.props.state.loginState.user.clientId
    // : this.props.state.loginState.user.contact
    // ? this.props.state.loginState.user.contactId
    // : this.props.state.loginState.user.companyId;
    // this.props.fetchProfile(userID);
    this.props.exportUserData();
  }
  componentDidMount() {
    const userInfo = LocalDb.getSessions();
    const userLoginInfo = userInfo ? userInfo : null;
    const userData = userLoginInfo
      ? userInfo.loginResponse.loginUser.user
        ? userInfo.loginResponse.loginUser.user
        : userInfo.loginResponse.loginUser.corporate
        ? userInfo.loginResponse.loginUser.corporate
        : userInfo.loginResponse.loginUser.client
        ? userInfo.loginResponse.loginUser.client
        : userInfo.loginResponse.loginUser.contact
      : null;

    const loginUser = userInfo && userInfo.loginResponse.loginUser;
    const userId =
      loginUser && loginUser.user
        ? loginUser.user.userId
        : loginUser && loginUser.client
        ? loginUser.client.clientId
        : loginUser && loginUser.corporate
        ? loginUser.corporate.corporateId
        : loginUser && loginUser.contact
        ? loginUser.contact.contactId
        : "";
    this.props.fetchProfile(userId);
  }

  componentWillUnmount() {
    this.props.handleSnackBarCloseForProfile();
  }

  fileSelectedHandler = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  onChangeFileHandler = (eventFileHandler) => {
    let file = eventFileHandler.target.files[0];
    let type = file && file.type;

    let singleFile = {
      file: file,
      type: type,
    };
    {
      this.setState(
        {
          isBlocking: true,
          selectedFile: [...this.state.selectedFile, singleFile],
        },
        () => {
          this.props.updateProfilePicture(singleFile, this.props);
        }
      );
    }
  };
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  toggleShowConform = () => {
    this.setState({ hiddenConform: !this.state.hiddenConform });
  };

  toggleShowNew = () => {
    this.setState({ hiddenNew: !this.state.hiddenNew });
  };

  render() {
    const profileState = this.props.state.profileState;
    const accountData = profileState && profileState.accountData;
    // const profileData = profileState && profileState.profileData;
    const clientState = this.props.state && this.props.state.clientState;
    const upcomingAlert = clientState && clientState.upcomingAlert;
    const holidayAlert = clientState && clientState.holidayAlert;
    const clientLaoding = clientState && clientState.loading;
    const openClientSnackBar = clientState && clientState.openClientSnackBar;
    const successMsg = clientState && clientState.successMsg;
    const openClientErrorSnackBar =
      clientState && clientState.openClientErrorSnackBar;
    const errorMsgForClient = clientState && clientState.errorMsgForClient;
    const emailAlertId = clientState && clientState.emailAlertId;

    const getDate = (dateToConvert) => {
      let dateConverted = new Date(
        parseInt(dateToConvert)
      ).toLocaleDateString();
      return moment(dateConverted).format("YYYY-MM-DD");
    };
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB.loginResponse.loginUser.corporate
        ? "CORPORATE_USER"
        : userInfoFromDB.loginResponse.loginUser.client
        ? "EVERFUND_CLIENT"
        : userInfoFromDB.loginResponse.loginUser.contact
        ? "EVERFUND_CONTACT"
        : "EVERFUND_COMPANY"
      : null;

    const clientAccountId =
      userType === "EVERFUND_CLIENT"
        ? userInfoFromDB.loginResponse.loginUser.client &&
          userInfoFromDB.loginResponse.loginUser.client.accountId
        : "";

    const value =
      this.props.state.profileState.accountData.oldPassword !== "" &&
      this.props.state.profileState.accountData.newPassword !== undefined;
    return (
      <React.Fragment>
        <HelmetComponent title="Profile" />
        {this.props.state.profileState.loading || clientLaoding ? (
          <div
            className={
              this.props.state.profileState.loading || clientLaoding
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page?"
        />

        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          {userType && userType === "CORPORATE_USER" ? (
            ""
          ) : (
            <Link color="inherit" to="/dashboard" style={style.link}>
              <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
              Home
            </Link>
          )}
          <Typography color="inherit" className="link">
            Profile
          </Typography>
        </Breadcrumbs>

        <div className={"row"} style={{ margin: "3%" }}>
          <button
            disabled={this.state.updateProfileTab}
            style={
              this.state.updateProfileTab ? style.buttonActive : style.button
            }
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                updateProfileTab: !this.state.updateProfileTab,
                changePasswordTab: false,
                setNotificationTab: false,
              });
            }}
          >
            Update Profile
          </button>

          <button
            disabled={this.state.changePasswordTab}
            style={
              this.state.changePasswordTab ? style.buttonActive : style.button
            }
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                changePasswordTab: true,
                updateProfileTab: false,
                setNotificationTab: false,
              });
            }}
          >
            Change Password
          </button>
          <button
            hidden={userType !== "EVERFUND_CLIENT"}
            disabled={this.state.setNotificationTab}
            style={
              this.state.setNotificationTab ? style.buttonActive : style.button
            }
            onClick={(e) => {
              e.preventDefault();
              this.props.getAlertInfo(clientAccountId);
              this.setState({
                setNotificationTab: !this.state.setNotificationTab,
                changePasswordTab: false,
                updateProfileTab: false,
              });
            }}
          >
            Notifications Setting
          </button>
          <div style={style.container}>
            {this.state.changePasswordTab ? (
              <>
                <div
                  className={"row"}
                  style={{ marginBottom: "2.5%", marginLeft: "2%" }}
                >
                  {/*For change password*/}
                  <div
                    className="col-5 "
                    style={{ paddingTop: "1rem", textAlign: "left !important" }}
                  >
                    <label style={style.label}>Current Password</label>
                    <div
                      className="form-group"
                      style={{ marginBottom: "0.7rem" }}
                    >
                      <input
                        type={this.state.hidden ? "password" : "text"}
                        className="form-control"
                        style={style.inputField}
                        id="oldPassword"
                        name="oldPassword"
                        value={accountData.oldPassword || ""}
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({ isBlocking: true });
                          this.props.handleChangeForPassword(
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />

                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleShow();
                        }}
                        style={style.visibilityIcon}
                      >
                        {this.state.hidden ? <VisibilityOff /> : <Visibility />}
                      </button>
                    </div>
                    <label style={style.label}>New Password</label>
                    <div
                      className="form-group"
                      style={{ marginBottom: "0.7rem" }}
                    >
                      <input
                        type={this.state.hiddenNew ? "password" : "text"}
                        className="form-control"
                        style={style.inputField}
                        id="newPassword"
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({
                            newPassword: e.target.value,
                            verifyPasswordError: false,
                            isBlocking: true,
                          });
                          this.props.handleChangeForPassword(
                            e.target.id,
                            e.target.value
                          );
                        }}
                      />
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleShowNew();
                        }}
                        style={style.visibilityIcon}
                      >
                        {this.state.hiddenNew ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </button>
                    </div>
                    <span
                      hidden={!this.state.verifyPasswordError}
                      style={{
                        color: "red",
                        float: "left !important",
                        fontSize: "13px",
                      }}
                    >
                      <ErrorOutlineIcon style={{ width: "16px" }} />
                      Password Does Not Match
                    </span>
                    <label style={style.label}>Confirm Password</label>
                    <div
                      className="form-group"
                      style={{ marginBottom: "0.7rem" }}
                    >
                      <input
                        type={this.state.hiddenConform ? "password" : "text"}
                        className="form-control"
                        style={style.inputField}
                        id="verifyPassword"
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({
                            verifyPassword: e.target.value,
                            verifyPasswordError: false,
                            isBlocking: true,
                          });
                        }}
                      />
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleShowConform();
                        }}
                        style={style.visibilityIcon}
                      >
                        {this.state.hiddenConform ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </button>
                    </div>
                    <span
                      hidden={!this.state.verifyPasswordError}
                      style={{
                        color: "red",
                        float: "left !important",
                        fontSize: "13px",
                      }}
                    >
                      <ErrorOutlineIcon style={{ width: "16px" }} />
                      Password Does Not Match
                    </span>
                    <div
                      style={{
                        paddingBottom: "2.5rem",
                        textAlign: "right",
                        maxWidth: "85%",
                      }}
                    >
                      <button
                        style={
                          !value ? style.disabledButton : style.updateButton
                        }
                        disabled={!value}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            isBlocking: false,
                            changePasswordTab: true,
                          });
                          this.state.newPassword === this.state.verifyPassword
                            ? this.props.changePassword(
                                this.props.state.profileState.accountData,
                                this.props
                              )
                            : this.setState({
                                verifyPasswordError: true,
                              });
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : this.state.updateProfileTab ? (
              <>
                {/*For update profile*/}
                {userType === "EVERFUND_USER" ? (
                  <div
                    className={"row"}
                    style={{ paddingTop: "4rem", paddingBottom: "5rem" }}
                  >
                    <div
                      className={"col-lg-2 col-md-2 col-sm-2"}
                      style={{ maxWidth: "15%" }}
                    >
                      <div
                        className="d-flex justify-content-end"
                        style={{ marginTop: "15%" }}
                      >
                        <Avatar
                          alt="Profile"
                          src={
                            this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData
                              .profilePicUrl
                          }
                          style={{
                            width: "173px",
                            height: "173px",
                            position: "absolute",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "141px",
                            right: "16x",
                            color: "#666666",
                            transition:
                              "all .3s cubic-bezier(.175, .885, .32, 1.275)",
                          }}
                        >
                          <label htmlFor="file">
                            <img
                              src={"assets/images/edit_photo.png"}
                              alt="Edit"
                              style={{
                                background: "#BCBCBC",
                                width: "35px",
                                height: "35px",
                                boxShadow:
                                  "rgba(0, 0, 0, 0) 0px 0px 1px 0px," +
                                  " rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
                                borderRadius: "50%",
                              }}
                            />
                          </label>
                          <input
                            className="file-upload"
                            type="file"
                            id="file"
                            onChange={(e) => {
                              e.preventDefault();
                              this.setState({ isBlocking: true });
                              this.onChangeFileHandler(e);
                            }}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-lg-10 col-md-10 col-sm-10"}>
                      <div className="row" style={{ marginLeft: "4%" }}>
                        <div className={"col-lg-6 col-md-6 col-sm-6"}>
                          <label style={style.label}>Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fullName"
                            value={
                              this.props.state.profileState.profileData &&
                              this.props.state.profileState.profileData.fullName
                            }
                            style={style.inputField}
                            onChange={(e) => {
                              this.setState({ isBlocking: true });
                              this.props.handleChange(
                                e.target.id,
                                e.target.value
                              );
                            }}
                          />
                          <label style={style.label}>Email</label>
                          <input
                            type="text"
                            className="form-control"
                            id="emailPhone"
                            readOnly
                            value={
                              this.props.state.profileState.profileData
                                ? this.props.state.profileState.profileData
                                    .emailPhone
                                : null
                            }
                            style={style.inputField}
                          />

                          <label style={style.label}>User Role</label>
                          <input
                            type="text"
                            className="form-control"
                            id="userRole"
                            readOnly
                            value={
                              this.props.state.profileState.profileData
                                ? this.props.state.profileState.profileData
                                    .userRole === "SUPER_ADMIN"
                                  ? "Super Admin"
                                  : this.props.state.profileState.profileData
                                      .userRole === "ADMIN"
                                  ? "Admin"
                                  : this.props.state.profileState.profileData
                                      .userRole === "AGENT"
                                  ? "Agent"
                                  : this.props.state.profileState.profileData
                                      .userRole
                                : null
                            }
                            style={style.inputField}
                          />
                        </div>
                        <div className={"col-lg-6 col-md-6 col-sm-6"}>
                          <label style={style.label}>Account Type</label>
                          <input
                            type="text"
                            className="form-control"
                            id="accountType"
                            readOnly
                            value={
                              this.props.state.profileState.profileData &&
                              this.props.state.profileState.profileData
                                .accountType === "EVERFUND_USER"
                                ? "Everfund User"
                                : null
                            }
                            style={style.inputField}
                          />
                          <label style={style.label}>Gender</label>
                          <div
                            className="select-input h40"
                            style={{ width: "85%" }}
                          >
                            <Select
                              value={
                                this.props.state.profileState.profileData &&
                                this.props.state.profileState.profileData.gender
                              }
                              onChange={(e) => {
                                this.setState({ isBlocking: true });
                                this.props.handleChange("gender", e);
                              }}
                            >
                              <Option value="-1" hidden />
                              <Option value="MALE">Male</Option>
                              <Option value="FEMALE">Female</Option>
                              <Option value="OTHER">Other</Option>
                            </Select>
                          </div>
                          {/*<ExpandMoreOutlinedIcon style={style.expandMoreIcon} />*/}
                          <div style={{ maxWidth: "85%" }}>
                            <button
                              style={style.updateButton}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ isBlocking: false });
                                this.props.updateProfile(
                                  this.props.state.profileState.profileData,
                                  this.props
                                );
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : userType === "CORPORATE_USER" ? (
                  <>
                    <div
                      className="row"
                      style={{ paddingTop: "4rem", paddingBottom: "5rem" }}
                    >
                      <div
                        className={"col-lg-2 col-md-2 col-sm-2"}
                        style={{ maxWidth: "6%" }}
                      ></div>
                      <div className={"col-lg-5 col-md-5 col-sm-6"}>
                        <label style={style.label}>Corporate Name</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="corporateName"
                          value={
                            this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData
                              .corporateName
                          }
                          style={style.inputField}
                          onChange={(e) => {
                            this.setState({ isBlocking: true });
                            this.props.handleChange(
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        <label style={style.label}>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="emailPhone"
                          readOnly
                          disabled
                          value={
                            this.props.state.profileState.profileData
                              ? this.props.state.profileState.profileData
                                  .emailPhone
                              : null
                          }
                          style={style.inputField}
                        />

                        <label style={style.label}>Tax/Vat Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="taxVatNo"
                          disabled
                          value={
                            this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData.taxVatNo
                          }
                          style={style.inputField}
                          onChange={(e) => {
                            this.setState({ isBlocking: true });
                            this.props.handleChange(
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      <div className={"col-lg-5 col-md-5 col-sm-5"}>
                        <label style={style.label}>Account Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="accountType"
                          disabled
                          readOnly
                          value={
                            this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData
                              .accountType === "CORPORATE_CLIENT"
                              ? "Corporate Client"
                              : null
                          }
                          style={style.inputField}
                        />

                        <label style={style.label}>Registration Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="regNo"
                          disabled
                          value={
                            this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData.regNo
                          }
                          style={style.inputField}
                          onChange={(e) => {
                            this.setState({ isBlocking: true });
                            this.props.handleChange(
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                        {/* <div style={{ maxWidth: "85%" }}>
                          <button
                            style={style.updateButton}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ isBlocking: false });
                              this.props.updateProfile(
                                this.props.state.profileState.profileData,
                                this.props
                              );
                            }}
                          >
                            Update
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </>
                ) : userType === "EVERFUND_CLIENT" ? (
                  <div
                    className="row"
                    style={{
                      marginLeft: "4%",
                      marginBottom: "4%",
                      paddingTop: "4rem",
                      paddingBottom: "5rem",
                    }}
                  >
                    <div
                      className={"col-lg-2 col-md-2 col-sm-2"}
                      style={{ maxWidth: "7%" }}
                    ></div>
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <label style={style.label}>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        disabled
                        value={
                          (this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData
                              .firstName) ||
                          ""
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(e.target.id, e.target.value);
                        }}
                      />
                      <label style={style.label}>Account Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="accountType"
                        readOnly
                        disabled
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData
                            .accountType === "EVERFUND_CLIENT"
                            ? "Client"
                            : null
                        }
                        style={style.inputField}
                      />

                      <label style={style.label}>Date of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        id="dob"
                        disabled
                        value={
                          this.props.state.profileState.profileData &&
                          getDate(this.props.state.profileState.profileData.dob)
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(
                            e.target.id,
                            new Date(e.target.value).getTime()
                          );
                        }}
                      />
                      <label style={style.label}>Marital Status</label>
                      <input
                        type="text"
                        className="form-control"
                        id="maritalStatus"
                        disabled
                        value={
                          (this.props.state.profileState.profileData &&
                            this.props.state.profileState.profileData
                              .maritalStatus) ||
                          ""
                        }
                        style={style.inputField}
                      />
                      {/* <select
                        className="custom-select"
                        style={style.inputField}
                        disable={true}
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData
                            .maritalStatus
                        }
                        id="maritalStatus"
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(e.target.id, e.target.value);
                        }}
                      >
                        <option value hidden />
                        <option value="SINGLE">Single</option>
                        <option value="MARRIED">Married</option>
                        <option value="DIVORCED">Divorced</option>
                      </select>
                      <ExpandMoreOutlinedIcon style={style.expandMoreIcon} /> */}
                    </div>
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <label style={style.label}>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        disabled
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData.lastName
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(e.target.id, e.target.value);
                        }}
                      />
                      <label style={style.label}>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        disabled
                        readOnly
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData
                                .emailPhone
                            : null
                        }
                        style={style.inputField}
                      />
                      <label style={style.label}>Gender</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        disabled
                        readOnly
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData.gender
                        }
                        style={style.inputField}
                      />
                      {/* <select
                        className="custom-select"
                        style={style.inputField}
                        id="gender"
                        disable={true}
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData.gender
                        }
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(e.target.id, e.target.value);
                        }}
                      >
                        <option value hidden />
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select> 
                       <ExpandMoreOutlinedIcon style={style.expandMoreIcon} /> */}

                      {/* <div style={{ maxWidth: "85%" }}>
                        <button
                          style={style.updateButtonClient}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ isBlocking: false });
                            this.props.updateProfile(
                              this.props.state.profileState.profileData,
                              this.props
                            );
                          }}
                        >
                          Update
                        </button>
                      </div> */}
                    </div>
                  </div>
                ) : userType === "EVERFUND_CONTACT" ? (
                  <div
                    className="row"
                    style={{
                      marginLeft: "4%",
                      marginBottom: "4%",
                      paddingTop: "4rem",
                      paddingBottom: "5rem",
                    }}
                  >
                    <div
                      className={"col-lg-2 col-md-2 col-sm-2"}
                      style={{ maxWidth: "7%" }}
                    ></div>
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <label style={style.label}>Contact Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData.name
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(e.target.id, e.target.value);
                        }}
                      />
                      <label style={style.label}>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        readOnly
                        // value={userInfoFromDB.loginResponse.loginUser.contact.emailPhone}
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData
                                .emailPhone
                            : null
                        }
                        style={style.inputField}
                      />
                      <label style={style.label}>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        readOnly
                        // value={userInfoFromDB.loginResponse.loginUser.contact.address}
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData.address
                            : null
                        }
                        style={style.inputField}
                      />
                    </div>
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <label style={style.label}>Account Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="accountType"
                        readOnly
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData
                            .accountType === "EVERFUND_CONTACT"
                            ? "Contact"
                            : null
                        }
                        style={style.inputField}
                      />
                      <label style={style.label}>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="text"
                        // value={userInfoFromDB.loginResponse.loginUser.contact.phone}
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData.phone
                            : null
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(
                            e.target.id,
                            new Date(e.target.value).getTime()
                          );
                        }}
                      />
                      <div style={{ maxWidth: "85%" }}>
                        <button
                          style={style.updateButtonClient}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ isBlocking: false });
                            this.props.updateProfile(
                              this.props.state.profileState.profileData,
                              this.props
                            );
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="row"
                    style={{
                      marginLeft: "4%",
                      marginBottom: "4%",
                      paddingTop: "4rem",
                      paddingBottom: "5rem",
                    }}
                  >
                    <div
                      className={"col-lg-2 col-md-2 col-sm-2"}
                      style={{ maxWidth: "7%" }}
                    ></div>
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <label style={style.label}>Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData.companyName
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(e.target.id, e.target.value);
                        }}
                      />
                      <label style={style.label}>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        readOnly
                        // value={userInfoFromDB.loginResponse.loginUser.contact.emailPhone}
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData.email
                            : null
                        }
                        style={style.inputField}
                      />
                      <label style={style.label}>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailPhone"
                        readOnly
                        // value={userInfoFromDB.loginResponse.loginUser.contact.address}
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData.address
                            : null
                        }
                        style={style.inputField}
                      />
                    </div>
                    <div className={"col-lg-4 col-md-4 col-sm-6"}>
                      <label style={style.label}>Account Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="accountType"
                        readOnly
                        value={
                          this.props.state.profileState.profileData &&
                          this.props.state.profileState.profileData
                            .accountType === "EVERFUND_COMPANY"
                            ? "Company"
                            : null
                        }
                        style={style.inputField}
                      />
                      <label style={style.label}>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="text"
                        // value={userInfoFromDB.loginResponse.loginUser.contact.phone}
                        value={
                          this.props.state.profileState.profileData
                            ? this.props.state.profileState.profileData.phone
                            : null
                        }
                        style={style.inputField}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChange(
                            e.target.id,
                            new Date(e.target.value).getTime()
                          );
                        }}
                      />
                      <div style={{ maxWidth: "85%" }}>
                        <button
                          style={style.updateButtonClient}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ isBlocking: false });
                            this.props.updateProfile(
                              this.props.state.profileState.profileData,
                              this.props
                            );
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <div
                  style={{
                    marginLeft: "422px",
                    marginRight: "85px",
                    paddingBottom: "50px",
                  }}
                >
                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <div style={{ fontSize: "18px" }}>Holiday Alert</div>
                      <div style={{ color: "#676767" }}>
                        Enable holiday alert message for schedule transaction.
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ paddingTop: "12px" }}>
                      <Switch
                        checked={holidayAlert}
                        disabled={userType === "EVERFUND_CLIENT" ? true : false}
                        onChange={(e) => {
                          const enabledData = {
                            accountId: clientAccountId,
                            upcomingTransactionAlert: upcomingAlert,
                            upcomingHolidayAlert: e,
                          };
                          if (emailAlertId !== "") {
                            enabledData.emailAlertId = emailAlertId;
                          }
                          this.props.saveNotifications(enabledData, this.props);
                          this.props.handleChangeHolidayAlert(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-6">
                      <div style={{ fontSize: "18px" }}>
                        Upcoming Transaction Alert
                      </div>
                      <div style={{ color: "#676767" }}>
                        Enable upcoming transaction alert message.
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ paddingTop: "12px" }}>
                      <Switch
                        checked={upcomingAlert}
                        disabled={userType === "EVERFUND_CLIENT" ? true : false}
                        onChange={(e) => {
                          this.props.handleChangeUpcomingAlert(e);
                          const enabledData = {
                            accountId: clientAccountId,
                            upcomingTransactionAlert: e,
                            upcomingHolidayAlert: holidayAlert,
                          };
                          if (emailAlertId !== "") {
                            enabledData.emailAlertId = emailAlertId;
                          }
                          this.props.saveNotifications(enabledData, this.props);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.profileState.openProfileUpdateSuccessSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarCloseForProfile}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarCloseForProfile}
            variant="success"
            message={"Profile Update Success"}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.profileState.openPasswordChangeSuccessSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleSnackBarCloseForProfile}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarCloseForProfile}
            variant="success"
            message={"Password Change Success"}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.profileState.openProfileSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarCloseForProfile}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarCloseForProfile}
            variant="error"
            message={this.props.state.profileState.error}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openClientSnackBar}
          autoHideDuration={4000}
          onClose={this.props.closeSnackBarForClient}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeSnackBarForClient}
            variant="success"
            message={successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openClientErrorSnackBar}
          autoHideDuration={4000}
          onClose={this.props.closeSnackBarForClient}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeSnackBarForClient}
            variant="error"
            message={errorMsgForClient}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    marginBottom: "2%",
    width: "100%",
    paddingTop: "1rem",
    paddingLeft: "2rem",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  profileDetail: {
    paddingBottom: "10px",
  },
  button: {
    marginRight: "25px",
    marginTop: "48px",
    width: "200px",
    height: "43px",
    background: "white",
    color: "#464646",
    border: "none",
    fontSize: "18px",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
    borderTop: "none",
  },
  buttonActive: {
    marginRight: "25px",
    marginTop: "48px",
    width: "200px",
    height: "43px",
    background: "white",
    color: "#464646",
    border: "none",
    fontSize: "18px",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    boxShadow:
      "rgba(77, 175, 89, 0.2) 0px -1px 1px 1px, rgba(0,0,0,0.1) 0px -1px 1px 0px",
    borderTop: "3px solid #8BC83F",
  },
  inputField: {
    width: "85%",
    border: "none",
    background: "#F4F5F9",
    height: "40px",
    marginBottom: "5px",
  },
  errorInput: {
    width: "85%",
    border: "1px solid red",
    background: "none",
    height: "40px",
    marginBottom: "5px",
  },
  label: {
    color: "#626262",
    paddingTop: "1rem",
    textAlign: "left",
    width: "100%",
    fontSize: "16px",
    marginBottom: "0.3rem",
  },
  updateButton: {
    width: "165px",
    height: "43px",
    background: "#8BC83F",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    float: "right",
    marginTop: "2.2rem",
  },
  updateButtonClient: {
    width: "165px",
    height: "43px",
    background: "#8BC83F",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    float: "right",
    marginTop: "3.2rem",
  },
  disabledButton: {
    width: "165px",
    height: "43px",
    background: "#b1d681",
    color: "white",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    float: "right",
    marginTop: "2.2rem",
  },
  select: {
    position: "relative",
  },
  selectBar: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "16px",
    height: "40px",
  },
  expandMoreIcon: {
    position: "relative",
    top: "-2px",
    color: "#C8C5C5",
    fontSize: "35px",
    marginLeft: "-40px",
  },
  icon: {
    fontSize: "17px",
  },
  visibilityIcon: {
    position: "absolute",
    bottom: "10px",
    right: "115px",
    color: "rgb(196, 189, 189)",
    fontSize: "20px",
    textDecoration: "none",
    top: "2px",
    background: "none",
    border: "none",
  },
};
export default ProfileScreen;
