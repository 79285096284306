import React, {Component} from "react";
import {
    Button,
    MenuItem,
    MenuList,
    Popover, Snackbar,
    Table,
    TableBody,
    CircularProgress,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitleComponent from "./DialogTitleComponent";
import DateConverter from "../utils/DateConverter";
import MySnackbarContentWrapper from "../components/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import Helpers from "../components/Helper";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {LocalDb} from '../api';
import { DocumentImage } from "../components/DocumentImage";
import { scheduleTransactionRequestMade } from "../actions";

class ClientDocumentScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogToAddFile : false,
            openActionMenu: false,
            popoverElement: null,
            page: 0,
            rowsPerPage: 10,
            openErrorSnackBar: false,
            isBlocking: false,
        }
    
    }
    componentDidMount() {
        const userInfoFromDB = LocalDb.getSessions();
        const client = userInfoFromDB && userInfoFromDB.loginResponse &&
        userInfoFromDB.loginResponse.loginUser &&
        userInfoFromDB.loginResponse.loginUser.client ?
        userInfoFromDB.loginResponse.loginUser.client : {};
        const clientId = client && client.clientId;
        this.props.fetchContactFilesList(clientId);
        this.props.handleChangeContactFile("refId", clientId)
    }


    render() {
          
        const contactState = this.props.state && this.props.state.contactState;
        const contactFileData = contactState && contactState.contactFileData;
        const contactFileList = contactState && contactState.contactFileList;

        const scheduleTransactionState = this.props.state && this.props.state.scheduleTransactionState;
        const openScheduleTransactionSnackBar = scheduleTransactionState && 
                    scheduleTransactionState.openScheduleTransactionSnackBar;
        const errorMsg = scheduleTransactionState && scheduleTransactionState.errorMsg;
        const scheduleLoading = scheduleTransactionState && scheduleTransactionState.loading;
        const value = contactFileData.documentUrl !== "" && contactFileData.documentName !== "";
        console.log({contactFileList});
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        };

        const handleOpenToAddFile = () => {
            this.setState({
                openDialogToAddFile: true,
            })
        }
        const handleCloseToAddFile = () => {
            this.setState({
                openDialogToAddFile: false,

            })
        }
        const onChangeDocumentFileHandler = (eventFileHandler) => {
            let file = eventFileHandler.target.files[0];
            let type = file && file.type;
                let name = file && file.name;
                let singleFile = {
                    document: file,
                    type: type,
                    name: name,
                };
                {
                    this.setState(
                        {
                            isBlocking: true,
                        },
                        () => {
                            this.props.uploadDocumentFile(
                                singleFile,
                                this.props
                            );
                        }
                    );
                }
        };
        return (
            <React.Fragment>
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Typography color="inherit" className="link">
                        Documents
                    </Typography>
                </Breadcrumbs>
                {contactState && contactState.loading ? (
                    <div
                        className={contactState && contactState.loading  ? "loader" : "loader hidden"}
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <div style={{margin: '67px 3% 2%'}}>
                    <div className={"d-flex flex-row-reverse div-flex"} style={{paddingTop:"0px"}}>
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenToAddFile();
                                    this.props.handleChangeContactFile("documentUrl","");
                                    this.props.handleChangeContactFile("documentName", "");
                                }}>
                                <AddIcon/> Add File
                            </button>
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Files
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {contactFileList && contactFileList.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                ).map(list => (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="first-tableBody" >
                                                <a href={list.documentUrl} target="_blank" className="actionsLink">
                                                  <DocumentImage documentURL={list.documentUrl}/>
                                                    {list.documentName}
                                                </a>
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                {DateConverter(list.createdAt)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>))}                             
                            </Table>
                            {contactFileList && contactFileList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            contactFileList.length > 0
                                                ? contactFileList.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        </TableContainer>
                    </div>
                </div>
                {/*add files*/}
                <Dialog
                    open={this.state.openDialogToAddFile}
                    onClose={handleCloseToAddFile}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseToAddFile}>
                            Add file
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContent">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="note" className="label">
                                        Document <span style={{color:"red"}}> *</span>
                                    </label>
                                    {
                                        contactFileData.documentUrl ?
                                            <>
                                                <div>
                                                    <DocumentImage documentURL={contactFileData.documentUrl}/>
                                                </div>
                                                <div style={{marginBottom: "-18px"}}>
                                                    <input
                                                        type='text'
                                                        id="documentName"
                                                        className="input"
                                                        name="document"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            this.props.handleChangeContactFile(e.target.id, e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        :null
                                    }
                                    <br/>
                                    <label
                                     style={{
                                        width: '85px',
                                        marginTop: '1rem',
                                        background: 'rgba(204,204,254,0.32)',
                                        border: '1px solid #7b7b7b',
                                        textAlign: 'center',
                                        padding: '4px',
                                        cursor: 'pointer'
                                    }}>
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                onChangeDocumentFileHandler(e);
                                            }}
                                            className="hidden"
                                            style={{display: 'none'}}
                                        />
                                        {
                                        scheduleLoading ?
                                            "Loading..." : "Select File"
                                        }
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div
                                    className={"col-6"}
                                    style={{paddingLeft: "142px"}}>
                                    <Button
                                        onClick={e => {
                                            e.preventDefault();
                                            handleCloseToAddFile();
                                            console.log({contactFileData})
                                            this.props.addContactFiles(contactFileData, this.props);
                                        }}
                                        style={{textTransform: "none"}}
                                        className={!value ? "disabledAddButton" : "addButton"}
                                        disabled={!value}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={openScheduleTransactionSnackBar}
                    autoHideDuration={4000}
                    onClose={this.props.closeScheduleTransactionSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeScheduleTransactionSnackBar}
                        variant="error"
                        message={errorMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={contactState.openSuccessContactFileSnackBar}
                    autoHideDuration={5000}
                    onClose={this.props.closeContactSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeContactSnackBar}
                        variant="success"
                        message={contactState && contactState.successMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={contactState.openErrorContactSnackBar}
                    autoHideDuration={5000}
                    onClose={this.props.closeContactSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeContactSnackBar}
                        variant="error"
                        message={contactState.errorMsg}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
}

const style = {
    card: {
        background: "rgb(255, 255, 255)",
        border: "1px solid rgb(233, 233, 233)",
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "1rem",
    }
}

export default ClientDocumentScreen;