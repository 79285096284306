import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MySnackbarContentWrapper from "../components/Snackbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Select, Tag, Tooltip, Popover, Tabs } from "antd";
import { finalAmount, naturalRound } from "../utils/currencyConvertor";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import DialogTitleComponent from "./DialogTitleComponent";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import PaperComponent from "./PaperComponent";
import { Avatar } from "antd";
import ExportButton from "../components/ExportButton";
import NotesPopover from "../components/NotesPopover";
import CreateTransactionForm from "./Transaction/CreateTransactionForm";
import EditOutlined from "@material-ui/icons/EditOutlined";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import SnackBarComponent from "../components/SnackBarComponenet";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { LocalDb } from "../api";
import {
  FilterStatusData,
  FilterMediumData,
  FilterTypeData,
  FilterUPTypeData,
  allTypeArray,
} from "../components/FilterOptionList";
// import MultipleSelectCard from "../components/MultipleSelectCard";
import {
  transactionStatusHelper,
  transactionMediumHelper,
  transactionTypeHelper,
  getRangeType,
  showGreaterThanSign,
} from "./TransactionHelper";
import CheckboxFilterPopover from "../components/CheckboxFilterPopover";
import { arraysEqual } from "../components/Helper";
import { getDateWithStartTimeValue } from "../utils/DateConverter";
import ChangedLogPopover from "../components/ChangedLogPopover";
const { Option } = Select;
const { TabPane } = Tabs;

class IndividualClientTransactionScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.rangeRef = React.createRef();
    var pageURL = window.location.href;
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openSearchDialogue: false,
      openModalToChangeTransStatus: false,
      setValue: "",
      clientId: pageURL.substr(pageURL.lastIndexOf("/") + 1),
      openModalToDeclineTransaction: false,
      openModalToUndoReverse: false,
      openModalToEditBlockedTransaction: false,
      openModalToApproveTransaction: false,
      openModalToDeclineCheck: false,
      isReverse: false,
      openActivityLogModal: false,
      isUpdateNote: false,
      trans: {},
      openDropDown: false,
      tempRowId: "",
      openModalToDeleteTransaction: false,
      visible: false,
      isALlChecked: true,
      isAllMediumChecked: true,
      isAllTypeChecked: true,
      isAllUPTypeCheked: true,
      isStatusCheck: FilterStatusData.map((status) => status.name),
      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      isTypeCheck: FilterTypeData.map((type) => type.name),
      // isTypeCheck: allTypeArray.map((type) => type.name),
      // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      isUPTypeCheck: allTypeArray.map((type) => type.name),
      prevAmount: "",
      order: "desc",
      orderBy: "transactionTimestamp",
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,
      openRangeBar: false,
      configName: "",
      openRangeBar: false,
      isUpdate: false,
      selectedTransData: {},
      openTrnxInfoDialogue: false,
      openConfirmationToUndoReverse: false,
    };
  }
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    const userInfoFromDB = LocalDb.getSessions();
    this.props.fetchDescriptionList();
    this.props.getClientTransaction(this.state.clientId, this.props);
    this.props.fetchSingleClient(this.state.clientId, this.props);
    this.props.clearTransactionFilter();
  }

  handleClickOutside(event) {
    if (this.rangeRef && !this.rangeRef.current.contains(event.target)) {
      this.setState({ openRangeBar: false });
    }
  }
  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleAllCheckbox = () => {
    this.setState({ isALlChecked: !this.state.isALlChecked });
    this.setState({ isStatusCheck: FilterStatusData.map((li) => li.name) });
    if (this.state.isALlChecked) {
      this.setState({ isStatusCheck: [] });
    }
  };

  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };

  handleAllTypeCheckbox = () => {
    this.setState({ isAllTypeChecked: !this.state.isAllTypeChecked });
    this.setState({ isTypeCheck: FilterTypeData.map((li) => li.name) });
    if (this.state.isAllTypeChecked) {
      this.setState({ isTypeCheck: [] });
    }
  };

  handleAllUPTypeCheckbox = () => {
    this.setState({ isAllUPTypeCheked: !this.state.isAllUPTypeCheked });
    this.setState({ isUPTypeCheck: allTypeArray.map((li) => li.name) });
    if (this.state.isAllUPTypeCheked) {
      this.setState({ isUPTypeCheck: [] });
    }
  };

  handleSelectedChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isStatusCheck: [...this.state.isStatusCheck, value] });
    if (!checked) {
      this.setState({
        isStatusCheck: this.state.isStatusCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isTypeCheck: [...this.state.isTypeCheck, value] });
    if (!checked) {
      this.setState({
        isTypeCheck: this.state.isTypeCheck.filter((item) => item !== value),
      });
    }
  };

  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedUPTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isUPTypeCheck: [...this.state.isUPTypeCheck, value] });
    if (!checked) {
      this.setState({
        isUPTypeCheck: this.state.isUPTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };
  render() {
    const userInfoFromDB = LocalDb.getSessions();
    const userRole =
      userInfoFromDB.loginResponse.loginUser.user &&
      userInfoFromDB.loginResponse.loginUser.user.userRole;
    const accountType =
      userInfoFromDB.loginResponse.loginUser.user &&
      userInfoFromDB.loginResponse.loginUser.user.accountType;
    const transactionState = this.props && this.props.transactionState;
    const transStatus = transactionState && transactionState.transStatus;
    const declineRemark = transactionState && transactionState.declineRemark;
    const clientTransaction =
      transactionState && transactionState.clientTransaction;
    const totalAmount = transactionState && transactionState.totalBalance;
    const clientId = transactionState && transactionState.clientId;
    const totalTransaction =
      transactionState && transactionState.totalTransaction;
    const startDate = transactionState && transactionState.from;
    const endDate = transactionState && transactionState.to;
    // const mediumType = transactionState && transactionState.mediumType;
    // const status = transactionState && transactionState.transactionStatus;
    // const transactionType = transactionState && transactionState.transType;
    const blockedData = transactionState && transactionState.blockedTransData;
    const term = transactionState && transactionState.term;
    const vendorToFilter = transactionState && transactionState.vendorToFilter;
    const openNotesModal = transactionState && transactionState.openNotesModal;

    const clientState = this.props && this.props.clientState;
    const clientData = clientState && clientState.clientData;
    const selectedClientName = clientData.firstName && clientData.firstName;

    const vendorList = this.props.vendorState.vendorAccount;
    const notes = transactionState && transactionState.notes;
    const reversedDate = transactionState && transactionState.reverseDate;
    const notesValue = notes !== "";

    // const filteredValue =
    //   startDate !== 0 ||
    //   endDate !== 0 ||
    //   mediumType !== "UNKNOWN_TRANSACTION_MEDIUM" ||
    //   status !== "UNKNOWN_TRANSACTION_STATUS" ||
    //   transactionType !== "UNKNOWN_TRANSACTION_TYPE" ||
    //   vendorToFilter !== "";

    const declineStatus = declineRemark !== "";

    const settingState = this.props && this.props.settingState;
    const descriptionList = settingState && settingState.descriptionList;
    // new filter states
    const transDate = transactionState && transactionState.filterDate;
    const desc = transactionState && transactionState.filterDesc;
    const amount = transactionState && transactionState.filterAmount;
    const checkNo = transactionState && transactionState.filterCheckNo;
    const user = transactionState && transactionState.filterUser;
    const type = transactionState && transactionState.filterType;
    const stat = transactionState && transactionState.filtetStatus;
    const vendor = transactionState && transactionState.filterVendor;
    const medium = transactionState && transactionState.filterMedium;
    const configlist = transactionState && transactionState.configlist;
    const compareType = transactionState && transactionState.compareType;
    const filterDebitAmount =
      transactionState && transactionState.filterDebitAmount;
    const filterCreditAmount =
      transactionState && transactionState.filterCreditAmount;

    // activity states
    const activityState = this.props && this.props.activityLogState;
    const activityLogValue = activityState && activityState.activityLogValue;
    const remarkValue = declineRemark !== "";
    const value =
      transStatus === "TRANSACTION_APPROVED" ? true : declineRemark !== "";
    const blockedStatus =
      blockedData.amount !== this.state.prevAmount || blockedData.date !== null;

    const handleOpenActivityLogModal = () => {
      this.setState({
        openActivityLogModal: true,
      });
    };
    const handleCloseActivityLogModal = () => {
      this.setState({
        openActivityLogModal: false,
      });
    };

    const handleModalToEditBlockedTransaction = () => {
      this.setState({
        openModalToEditBlockedTransaction: true,
      });
    };
    const handleCloseToEditBlockedTransaction = () => {
      this.setState({
        openModalToEditBlockedTransaction: false,
        transactionId: "",
      });
      this.props.clearEditBlockedTrans();
    };
    const handleChangeBlockedTransaction = () => {
      if (blockedData.date === null) {
        const data = {
          transactionId: this.state.transactionId,
          amount: blockedData.amount,
          // transactionTimestamp: blockedData.date === null ? 0 : blockedData.date,
        };

        this.props.editBlockedTransaction(data, clientId, this.props);
      } else {
        const data = {
          transactionId: this.state.transactionId,
          amount: blockedData.amount,
          transactionTimestamp:
            blockedData.date === null ? 0 : blockedData.date,
        };

        this.props.editBlockedTransaction(data, clientId, this.props);
      }
    };
    const handleChangeOpenModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: true,
      });
    };

    const handleChangeCloseModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: false,
        transactionId: "",
      });
      this.props.handleChangeRemark("");
    };
    const handleChangeOpenModalToUndoReverse = () => {
      this.setState({
        openModalToUndoReverse: true,
      });
    };
    const handleChangeCloseModalToUndoReverse = () => {
      this.setState({
        openModalToUndoReverse: false,
        // transactionId: "",
      });
      this.props.handleChangeRemark("");
    };
    const handleUpdateCheckStatus = () => {
      const checkData = {
        transactionId: this.state.transactionId,
        description: declineRemark,
      };
      this.props.updateCheckStatus(checkData, clientId, this.props);
    };
    const setOpenCreateTransaction = (bool) => {
      this.props.setOpenCreateTransaction(bool);
    };
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
    };

    const handleInitialCheckboxValue = () => {
      this.setState({
        isALlChecked: true,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isAllUPTypeCheked: true,
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
      });
    };

    const handleChangeOpenSearchDialogue = () => {
      handleInitialCheckboxValue();
      this.setState({
        openSearchDialogue: true,
      });
      this.props.clearSnackBar("", "", "", "");
      this.props.clearTransactionFilter();
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
      });
    };
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    // handle delete modal
    const handleDeleteModalOpen = () => {
      this.setState({
        openModalToDeleteTransaction: true,
      });
    };
    const handleDeleteModalClose = () => {
      this.setState({
        openModalToDeleteTransaction: false,
      });
    };

    // delete status handling
    const handleDeleteStatus = () => {
      const data = this.state.transactionId;
      this.props.deleteTransData(data, this.state.clientId, this.props);
    };

    //handle modal to change transaction status
    const handleOpenChangeTransStatusModal = (item) => {
      if (item && item.transactionStatus === "TRANSACTION_APPROVED") {
        const data = {
          transaction: [
            {
              transactionId: this.state.transactionId,
              transactionStatus: "TRANSACTION_SUBMITTED",
              // remark: declineRemark,
            },
          ],
        };
        this.props.updateSubmitTransactionList(
          data,
          this.state.clientId,
          this.props
        );
      } else {
        this.setState({
          openModalToChangeTransStatus: true,
        });
      }
    };

    const handleCloseChangeTransStatusModal = () => {
      this.setState({
        openModalToChangeTransStatus: false,
      });
      this.props.handlChangeReverseDate(null);
    };
    const handleStatusToApprove = () => {
      const data = {
        transaction: [
          {
            transactionId: this.state.transactionId,
            transactionStatus: "TRANSACTION_APPROVED",
          },
        ],
      };
      this.props.updateSubmitTransactionList(
        data,
        this.state.clientId,
        this.props
      );
    };

    const handleUpdateStatus = () => {
      this.setState({
        openModalToChangeTransStatus: false,
      });
      if (declineRemark === "") {
        const data = {
          transaction: [
            {
              transactionId: this.state.transactionId,
              transactionStatus: transStatus,
            },
          ],
        };
        this.props.updateSubmitTransactionList(
          data,
          this.state.clientId,
          this.props
        );
      } else {
        if (this.state.openModalToUndoReverse) {
          const data = {
            transactionId: this.state.transactionId,
            remark: declineRemark,
          };
          this.props.undoReverseClientTranx(
            data,
            this.state.clientId,
            this.props
          );
        } else {
          const remarkData = {
            transaction: [
              {
                transactionId: this.state.transactionId,
                // transactionStatus: transStatus,
                // transactionStatus: "TRANSACTION_APPROVED",
                transactionStatus: "TRANSACTION_REVERSED",
                transactionTimestamp: reversedDate === null ? 0 : reversedDate,
                remark: declineRemark,
              },
            ],
          };
          this.props.updateSubmitTransactionList(
            remarkData,
            this.state.clientId,
            this.props
          );
        }

        handleCloseChangeTransStatusModal();
      }
    };
    const transactionClientDetail =
      clientTransaction && clientTransaction[0].Client;

    //handle modal to change transaction status to approve
    const handleOpenToApproveTransaction = () => {
      this.setState({
        openModalToApproveTransaction: true,
      });
    };

    const handleCloseToApproveTransaction = () => {
      this.setState({
        openModalToApproveTransaction: false,
      });
    };

    const uploadDocumentFile = (singleFile) => {
      this.props.uploadDocumentFile(singleFile, this.props);
    };

    const handleChangeDropDown = (id) => {
      this.setState({
        openDropDown: !this.state.openDropDown,
        tempRowId: id,
      });
    };

    function descendingComparator(a, b, orderBy) {
      if (orderBy === "balance") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "cases") {
        let first = b.client[orderBy] ? b.client[orderBy].length : 0;
        let second = a.client[orderBy] ? a.client[orderBy].length : 0;
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return null;
      } else if (orderBy === "refId") {
        return a[orderBy]
          .toString()
          .localeCompare(b[orderBy].toString(), "eng", {
            numeric: true,
          });
      } else if (orderBy === "firstName") {
        let first = a.Client && a.Client[orderBy] ? a.Client[orderBy] : "z";
        let second = b.Client && b.Client[orderBy] ? b.Client[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        this.props.clearTransactionFilter();
        return null;
      } else if (orderBy === "firstNameUP") {
        let first = a.client && a.client.firstName ? a.client.firstName : "z";
        let second = b.client && b.client.firstName ? b.client.firstName : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "vendorName") {
        let first =
          a.vendorAccount && a.vendorAccount[orderBy]
            ? a.vendorAccount[orderBy]
            : "z";
        let second =
          b.vendorAccount && b.vendorAccount[orderBy]
            ? b.vendorAccount[orderBy]
            : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "checkPrintDate") {
        let first =
          a.checkPrint && a.checkPrint[orderBy] ? a.checkPrint[orderBy] : "z";
        let second =
          b.checkPrint && b.checkPrint[orderBy] ? b.checkPrint[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "scheduleDate ") {
        let first =
          a.transactionDetails && a.transactionDetails[orderBy]
            ? a.transactionDetails[orderBy]
            : "0";
        let second =
          b.transactionDetails && b.transactionDetails[orderBy]
            ? b.transactionDetails[orderBy]
            : "0";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "scheduledDate") {
        let first =
          a.scheduleDetail && a.scheduleDetail[orderBy]
            ? a.scheduleDetail[orderBy]
            : "0";
        let second =
          b.scheduleDetail && b.scheduleDetail[orderBy]
            ? b.scheduleDetail[orderBy]
            : "0";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return null;
      }
    }

    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }

    const sortedTransactionClientList = getComparator(
      clientTransaction,
      this.state.order,
      this.state.orderBy
    );
    // const sortedTransactionClientList = clientTransaction
    // ? clientTransaction.sort((a, b) => {
    //     const data = this.state.sortType === "asc" ? 1 : -1;
    //     return data * a.createdAt.localeCompare(b.createdAt);
    //   })
    // : null;
    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
      });
    };
    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        description: desc,
        balance: amount,
        checkNo: checkNo,
        userName: user,
        vendorName: vendor,
        transactionMedium: medium,
        transactionType: type,
        transactionStatus: stat,
        compareType: compareType,
        transactionDate: transDate ? transDate : "0",
        configType: 8,

        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };
      this.props.getClientTransaction(this.state.clientId, this.props);
      this.props.saveInConfig(data);
      this.props.clearTransactionFilter();
    };

    const filterStatus =
      transDate !== null ||
      desc !== "" ||
      amount !== "" ||
      checkNo !== "" ||
      user !== "" ||
      medium !== "" ||
      type !== "" ||
      stat !== "" ||
      filterDebitAmount !== "" ||
      filterCreditAmount !== "" ||
      vendor !== "";

    const configStatus = this.state.configName !== "" && filterStatus;

    const handleUseConfigFilter = (config) => {
      this.props.clearTransactionFilter();

      if (
        config.transactionFilter.transactionDate &&
        config.transactionFilter.transactionDate !== "0"
      ) {
        this.props.handleTransactionFilter(
          "filterDate",
          config.transactionFilter.transactionDate
        );
      }
      if (config.transactionFilter.description) {
        this.props.handleTransactionFilter(
          "filterDesc",
          config.transactionFilter.description
        );
      }
      if (config.transactionFilter.balance) {
        this.props.handleTransactionFilter(
          "filterAmount",
          config.transactionFilter.balance
        );
      }
      if (config.transactionFilter.checkNo) {
        this.props.handleTransactionFilter(
          "filterCheckNo",
          config.transactionFilter.checkNo
        );
      }
      if (config.transactionFilter.vendorName) {
        this.props.handleTransactionFilter(
          "filterVendor",
          config.transactionFilter.vendorName
        );
      }
      if (config.transactionFilter.transactionMedium) {
        this.props.handleTransactionFilter(
          "filterMedium",
          config.transactionFilter.transactionMedium
        );
      }
      if (config.transactionFilter.transactionType) {
        this.props.handleTransactionFilter(
          "filterType",
          config.transactionFilter.transactionType
        );
      }
      if (config.transactionFilter.transactionStatus) {
        this.props.handleTransactionFilter(
          "filtetStatus",
          config.transactionFilter.transactionStatus
        );
      }
      if (config.transactionFilter.comparator) {
        this.props.handleTransactionFilter(
          "compareType",
          getRangeType(config.transactionFilter.comparator)
        );
      }

      this.props.filterTransactionByAll(
        clientId,
        config.transactionFilter.description
          ? config.transactionFilter.description
          : "",
        config.transactionFilter.checkNo
          ? config.transactionFilter.checkNo
          : "",
        config.transactionFilter.vendorName
          ? config.transactionFilter.vendorName
          : "",
        config.transactionFilter.transactionMedium
          ? config.transactionFilter.transactionMedium
          : "",
        config.transactionFilter.transactionType
          ? config.transactionFilter.transactionType
          : "",
        config.transactionFilter.transactionStatus
          ? transactionStatusHelper([
              config.transactionFilter.transactionStatus,
            ])
          : "",
        config.transactionFilter.transactionDate
          ? config.transactionFilter.transactionDate
          : 0,
        config.transactionFilter.balance
          ? config.transactionFilter.balance
          : "",
        "",
        config.transactionFilter.comparator
          ? getRangeType(config.transactionFilter.comparator)
          : "0"
      );
    };

    // handle undo a reversed transaction
    const handleUndoReversedTrans = () => {
      this.setState({
        openConfirmationToUndoReverse: true,
      });
    };
    const handleCloseUndoReverse = () => {
      this.setState({
        openConfirmationToUndoReverse: false,
      });
    };

    // open info dialog for transaction
    const handleOpenDialogue = () => {
      this.setState({
        openTrnxInfoDialogue: true,
      });
    };

    const handleCloseDialogue = () => {
      this.setState({
        openTrnxInfoDialogue: false,
      });
    };

    // function to set the initaial value of the main filters
    const setInitialValueofMainFilters = () => {
      this.setState({
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
      });
    };
    return (
      <React.Fragment>
        <HelmetComponent title="Transaction" />
        {this.props.transactionState.loading ? (
          <div
            className={
              this.props.transactionState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {/* {this.props.transactionState.success === true
          ? this.setState(
            {
              openModalToDeleteTransaction: false,
            }
          )
          : null} */}
        <Breadcrumbs
          aria-label="breadcrumb"
          className="breadcrumb"
          style={style.breadcrumb}
        >
          <Link
            color="inherit"
            to="/dashboard"
            className="link"
            style={style.link}
          >
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>

          <Link
            color="inherit"
            to="/transaction/list"
            className="link"
            style={style.link}
          >
            Transaction
          </Link>
          {clientTransaction ? (
            <Typography color="inherit" className="link">
              {clientTransaction &&
                clientTransaction.map((name) => (
                  <span>
                    {name.Client.lastName === undefined
                      ? name.Client.firstName
                      : name.Client.firstName + " " + name.Client.lastName}
                  </span>
                )) &&
                clientTransaction
                  .map((name) => (
                    <span>
                      {name.Client.lastName === undefined
                        ? name.Client.firstName
                        : name.Client.firstName + " " + name.Client.lastName}
                    </span>
                  ))
                  .reduce((named) => {
                    return <span>{named}</span>;
                  })}
            </Typography>
          ) : clientData ? (
            <Typography color="inherit" className="link">
              {clientData.firstName + " " + clientData.lastName}
            </Typography>
          ) : null}
        </Breadcrumbs>

        <div
          style={{
            paddingTop: "58px",
            margin: "0 3%",
          }}
        >
          <Tabs
            defaultActiveKey="list"
            onChange={(key) => {
              this.props.history.push(`/transaction/${key}`);
            }}
            className="fixed-tab"
          >
            <TabPane tab="Transactions" key="list" />
            <TabPane tab="Schedule Transaction" key="schedule" />
            <TabPane tab="Upcoming Transaction" key="upcoming" />
            <TabPane tab="Annual Transaction" key="annual" />
            <TabPane tab="Ledger Transaction" key="ledger" />
            <TabPane tab="Deleted Transaction" key="delete" />
          </Tabs>
        </div>

        <div style={style.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ marginBottom: "8px" }}>
                <span style={{ marginRight: "4px", fontSize: "16px" }}>
                  Total Balance :
                </span>
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {totalAmount ? formatter.format(totalAmount / 100) : "$0.00"}
                </span>
              </div>
              <div style={{ marginBottom: "18px" }}>
                <span style={{ marginRight: "4px", fontSize: "16px" }}>
                  Total Transaction :
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {totalTransaction ? totalTransaction : "0"}
                </span>
              </div>
            </div>
            <div className={"d-flex flex-row-reverse"} style={style.flex}>
              <div>
                <button
                  className="filterButton"
                  onClick={() => {
                    this.props.getClientTransaction(clientId);
                    this.props.clearTransactionFilter();
                    this.props.handleChangeDateFrom(null);
                    this.props.handleChangeDateTo(null);
                  }}
                >
                  <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
                </button>
              </div>
              {/****** Filter ******/}
              <div>
                <button
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchVendor();
                    handleChangeOpenSearchDialogue();
                  }}
                >
                  <FilterListIcon
                    style={{ color: "#fff", marginTop: "-2px" }}
                  />
                </button>
              </div>
              {/****** Export ******/}
              {(startDate === null || startDate === 0) &&
              (endDate === null || endDate === 0) &&
              term === "" &&
              vendorToFilter === "" &&
              arraysEqual(
                this.state.isStatusCheck,
                FilterStatusData.map((status) => status.name)
              ) &&
              arraysEqual(
                this.state.isUPTypeCheck,
                FilterUPTypeData.map((type) => type.name)
              ) &&
              arraysEqual(
                this.state.isMediumCheck,
                FilterMediumData.map((medium) => medium.name)
              ) &&
              !filterStatus ? (
                <ExportButton
                  name={"VOD"}
                  onClickPDF={(e) => {
                    e.preventDefault();
                    this.props.exportSingleClientTransactionList(
                      clientId,
                      "PDF_EXPORT"
                    );
                  }}
                  onClickCSV={(e) => {
                    e.preventDefault();
                    this.props.exportSingleClientTransactionList(
                      clientId,
                      "CSV_EXPORT"
                    );
                  }}
                  onClickVOD={(e) => {
                    e.preventDefault();

                    this.props.exportTransactionVOD(
                      clientId,
                      startDate,
                      endDate,
                      "PDF_EXPORT"
                    );
                  }}
                />
              ) : term !== "" ? (
                <ExportButton
                  name={"VOD"}
                  onClickPDF={(e) => {
                    e.preventDefault();
                    this.props.exportSingleClientTransactionByTerm(
                      term,
                      clientId,
                      "PDF_EXPORT",
                      this.props
                    );
                  }}
                  onClickCSV={(e) => {
                    e.preventDefault();
                    this.props.exportSingleClientTransactionByTerm(
                      term,
                      clientId,
                      "CSV_EXPORT",
                      this.props
                    );
                  }}
                  onClickVOD={(e) => {
                    e.preventDefault();
                    // alert("heello world")
                    this.props.exportTransactionVOD(
                      clientId,
                      startDate,
                      endDate,
                      "PDF_EXPORT"
                    );
                  }}
                />
              ) : filterStatus ? (
                <ExportButton
                  name={"VOD"}
                  onClickPDF={(e) => {
                    e.preventDefault();
                    this.props.exportTransactionByColumnFilter(
                      clientId,
                      medium,
                      type,
                      stat,
                      "PDF_EXPORT",
                      desc,
                      checkNo,
                      user,
                      vendor,
                      amount,
                      compareType,
                      transDate,
                      filterDebitAmount !== ""
                        ? -filterDebitAmount
                        : filterCreditAmount
                    );
                  }}
                  onClickCSV={(e) => {
                    e.preventDefault();
                    this.props.exportTransactionByColumnFilter(
                      clientId,
                      medium,
                      type,
                      stat,
                      "CSV_EXPORT",
                      desc,
                      checkNo,
                      user,
                      vendor,
                      amount,
                      compareType,
                      transDate,
                      filterDebitAmount !== ""
                        ? -filterDebitAmount
                        : filterCreditAmount
                    );
                  }}
                  onClickVOD={(e) => {
                    e.preventDefault();
                    // alert("heello world")
                    this.props.exportTransactionVOD(
                      clientId,
                      startDate,
                      endDate,
                      "PDF_EXPORT"
                    );
                  }}
                />
              ) : (
                <ExportButton
                  name={"VOD"}
                  onClickPDF={(e) => {
                    e.preventDefault();
                    this.props.exportTransactionByAll(
                      transactionMediumHelper(this.state.isMediumCheck),
                      transactionStatusHelper(this.state.isStatusCheck),
                      transactionTypeHelper(this.state.isUPTypeCheck, "type"),
                      // transactionMediumHelper(this.state.isMediumCheck),
                      // transactionStatusHelper(this.state.isStatusCheck),
                      // transactionTypeHelper(this.state.isUPTypeCheck, "type"),
                      startDate,
                      endDate,
                      vendorToFilter,
                      clientId,
                      "PDF_EXPORT"
                    );
                  }}
                  onClickCSV={(e) => {
                    e.preventDefault();
                    this.props.exportTransactionByAll(
                      transactionMediumHelper(this.state.isMediumCheck),
                      transactionStatusHelper(this.state.isStatusCheck),
                      transactionTypeHelper(this.state.isUPTypeCheck, "type"),

                      startDate,
                      endDate,
                      vendorToFilter,
                      clientId,
                      "CSV_EXPORT"
                    );
                  }}
                  onClickVOD={(e) => {
                    e.preventDefault();
                    // alert("heello world")
                    this.props.exportTransactionVOD(
                      clientId,
                      startDate,
                      endDate,
                      "PDF_EXPORT"
                    );
                  }}
                />
              )}
              <div>
                <button
                  className="button-css"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenCreateTransaction(true);
                    this.props.handleChangeTransData("bankDetailId", "");
                    this.props.clearTransactionForm();
                    this.props.fetchVendor();
                    this.props.fetchDescriptionList();
                    this.props.fetchVendorClientById(clientId);
                    this.props.getAmountDetailByClientId(clientId);
                    this.props.clearFileData();
                    this.setState({
                      isUpdate: false,
                    });
                  }}
                >
                  Create Transaction
                </button>
              </div>
              <div className="search">
                <div style={style.searchIcon}>
                  <SearchIcon style={{ color: "#9c9c9c" }} />
                </div>
                <InputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  style={style.inputBase}
                  onChange={(e) => {
                    this.setState({
                      page: 0,
                    });
                    e.preventDefault();
                    if (e.target.value === "" || e.target.value === " ") {
                      this.props.getClientTransaction(clientId);
                    } else {
                      this.props.searchClientTransaction(
                        e.target.value,
                        clientId
                      );
                      this.props.handleChangeTerm(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header-second">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell style={style.tableHeader}>#</TableCell>
                    <TableCell
                      className="first-tableHeader"
                      key="transactionTimestamp"
                      sortDirection={
                        this.state.orderBy === "transactionTimestamp"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "transactionTimestamp"}
                        direction={
                          this.state.orderBy === "transactionTimestamp"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionTimestamp")}
                      >
                        Transaction Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={style.tableHeader} />
                    <TableCell
                      className="first-tableHeader"
                      key="description"
                      sortDirection={
                        this.state.orderBy === "description"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "description"}
                        direction={
                          this.state.orderBy === "description"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("description")}
                      >
                        Description
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={style.tableHeader}>Debits</TableCell>
                    <TableCell style={style.tableHeader}>Credits</TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="balance"
                      sortDirection={
                        this.state.orderBy === "balance"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "balance"}
                        direction={
                          this.state.orderBy === "balance"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("balance")}
                      >
                        Balance
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="tableHeader">Tracking Id</TableCell>
                    <TableCell
                      className="tableHeader"
                      key="refId"
                      sortDirection={
                        this.state.orderBy === "refId"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "refId"}
                        direction={
                          this.state.orderBy === "refId"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("refId")}
                      >
                        Ref / Check No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="vendorName"
                      sortDirection={
                        this.state.orderBy === "vendorName"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "vendorName"}
                        direction={
                          this.state.orderBy === "vendorName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("vendorName")}
                      >
                        Vendor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="transactionMedium"
                      sortDirection={
                        this.state.orderBy === "transactionMedium"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionMedium"}
                        direction={
                          this.state.orderBy === "transactionMedium"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionMedium")}
                      >
                        Medium
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="transactionType"
                      sortDirection={
                        this.state.orderBy === "transactionType"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionType"}
                        direction={
                          this.state.orderBy === "transactionType"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionType")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell style={style.tableHeader}>Status</TableCell>
                    <TableCell className="tableHeader">
                      ACH Submitted Date
                    </TableCell>
                    <TableCell className="tableHeader">
                      Check Print Date
                    </TableCell>
                    <TableCell style={style.tableHeader}>Posted Date</TableCell>
                    <TableCell style={style.tableHeader}>
                      Scheduled Date/By/Type
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="sticky-search-bar-second">
                    <TableCell className="first-tableBody" />
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={transDate === null ? "" : transDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterDate",
                              e.target.value
                            );
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              e.target.value,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell />
                    <TableCell className="first-tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={desc}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter("filterDesc", e);
                            this.setState({
                              page: 0,
                            });
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.props.filterTransactionByAll(
                              clientId,
                              e,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        >
                          {descriptionList &&
                            descriptionList.map((list) => (
                              <Option value={list.description}>
                                {list.description}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterDebitAmount}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterDebitAmount",
                              e.target.value
                            );
                            this.props.handleTransactionFilter(
                              "filterCreditAmount",
                              ""
                            );
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();

                            this.setState({
                              page: 0,
                            });
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              -e.target.value,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterCreditAmount}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterCreditAmount",
                              e.target.value
                            );
                            this.props.handleTransactionFilter(
                              "filterDebitAmount",
                              ""
                            );
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();

                            this.setState({
                              page: 0,
                            });
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              e.target.value,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell className="tableBody" ref={this.rangeRef}>
                      <div
                        className="sm-search"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span className="show-range-sign">
                          {showGreaterThanSign(compareType)}
                        </span>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          className="show-range"
                          value={amount}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterAmount",
                              e.target.value
                            );
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              e.target.value,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        />
                        <Popover
                          placement="bottom"
                          trigger="click"
                          // visible={this.state.openRangeBar}
                          content={
                            <>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    page: 0,
                                    openRangeBar: false,
                                  });
                                  this.props.handleTransactionFilter(
                                    "compareType",
                                    "2"
                                  );
                                  this.props.clearTransactionMainFilter();
                                  setInitialValueofMainFilters();
                                  this.props.filterTransactionByAll(
                                    clientId,
                                    desc,
                                    checkNo,
                                    // selectedClientName,
                                    vendor,
                                    medium,
                                    type,
                                    transactionStatusHelper([stat]),
                                    transDate,
                                    amount,
                                    filterDebitAmount !== ""
                                      ? -filterDebitAmount
                                      : filterCreditAmount,
                                    "2"
                                  );
                                }}
                                className="cursorP"
                              >
                                Lesser than {`(<)`}
                              </div>

                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.setState({
                                    page: 0,
                                    openRangeBar: false,
                                  });
                                  this.props.handleTransactionFilter(
                                    "compareType",
                                    "1"
                                  );
                                  this.props.clearTransactionMainFilter();
                                  setInitialValueofMainFilters();
                                  this.props.filterTransactionByAll(
                                    clientId,
                                    desc,
                                    checkNo,
                                    // selectedClientName,
                                    vendor,
                                    medium,
                                    type,
                                    transactionStatusHelper([stat]),
                                    transDate,
                                    amount,
                                    filterDebitAmount !== ""
                                      ? -filterDebitAmount
                                      : filterCreditAmount,
                                    "1"
                                  );
                                }}
                                className="cursorP"
                              >
                                Greater than {`(>)`}
                              </div>
                              <div
                                role="presentation"
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.setState({
                                    page: 0,
                                    openRangeBar: false,
                                  });
                                  this.props.handleTransactionFilter(
                                    "compareType",
                                    "0"
                                  );
                                  this.props.clearTransactionMainFilter();
                                  setInitialValueofMainFilters();
                                  this.props.filterTransactionByAll(
                                    clientId,
                                    desc,
                                    checkNo,
                                    // selectedClientName,
                                    vendor,
                                    medium,
                                    type,
                                    transactionStatusHelper([stat]),
                                    transDate,
                                    amount,
                                    filterDebitAmount !== ""
                                      ? -filterDebitAmount
                                      : filterCreditAmount,
                                    "0"
                                  );
                                }}
                                className="cursorP"
                              >
                                None
                              </div>
                            </>
                          }
                        >
                          <ExpandMoreIcon
                            className="expand-more-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                openRangeBar: true,
                              });
                            }}
                          />
                        </Popover>
                      </div>
                    </TableCell>
                    <TableCell />
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={checkNo}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterCheckNo",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              e.target.value,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    {/* <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={user}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterUser",
                              e.target.value
                            );

                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              stat,
                              transDate,
                              amount
                            );
                          }}
                        />
                      </div>
                    </TableCell> */}
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={vendor}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterVendor",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              e.target.value,
                              medium,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={medium}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter(
                              "filterMedium",
                              e
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              e,
                              type,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        >
                          <Option value="CHECK">Check</Option>
                          <Option value="CASH">Cash</Option>
                          <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                          <Option value="ACH">ACH</Option>
                          <Option value="CARD">Card</Option>
                          <Option value="LEDGER">Ledger</Option>
                          <Option value="OTHER_MEDIUM">Other medium</Option>
                        </Select>
                      </div>
                    </TableCell>

                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={type}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter("filterType", e);
                            this.setState({
                              page: 0,
                            });
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              e,
                              transactionStatusHelper([stat]),
                              transDate,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        >
                          {allTypeArray &&
                            allTypeArray.map((list) => (
                              <Option value={list.val}>{list.name}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={stat}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter(
                              "filtetStatus",
                              e
                            );
                            this.props.clearTransactionMainFilter();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterTransactionByAll(
                              clientId,
                              desc,
                              checkNo,
                              // selectedClientName,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([e]),
                              transDate,
                              amount,
                              filterDebitAmount !== ""
                                ? -filterDebitAmount
                                : filterCreditAmount,
                              compareType
                            );
                          }}
                        >
                          {FilterStatusData &&
                            FilterStatusData.map((list) => (
                              <Option value={list.name}>{list.name}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                  
                                  />
                                </div> */}
                    </TableCell>
                    <TableCell className="tableBody">
                      {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={clientNo}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleChangeFilterClientno(
                                        e.target.value
                                      );

                                      this.props.filterClient(
                                        name,
                                        e.target.value,
                                        caseCount,
                                        date,
                                        balance,
                                        term
                                      );
                                    }}
                                  />
                                </div> */}
                    </TableCell>
                    <TableCell className="tableBody">
                      {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}

                                  />
                                </div> */}
                    </TableCell>
                    <TableCell className="tableBody">
                      {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    
                                  />
                                </div> */}
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0", cursor: "pointer" }}>
                      <Popover
                        placement="bottom"
                        trigger="click"
                        content={
                          <>
                            <div
                              role="presentation"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  OpenSaveFilterInConfigmenu: true,
                                  openConfigMenu: false,
                                });
                              }}
                              className="cursorP"
                            >
                              Save In Config
                            </div>

                            <div
                              role="presentation"
                              style={{
                                padding: "0.2rem",
                              }}
                              onClick={(e) => {
                                e.preventDefault();

                                this.props.getConfigList("8");
                                this.setState({
                                  OpenViewFilterInConfig: true,
                                  openConfigMenu: false,
                                });
                              }}
                              className="cursorP"
                            >
                              View Config
                            </div>
                          </>
                        }
                      >
                        <MoreHorizIcon
                          onClick={(e) => {
                            e.preventDefault();

                            this.setState({
                              popoverElement: e.currentTarget,

                              openConfigMenu: true,
                            });
                          }}
                        />
                      </Popover>
                    </TableCell>

                    <TableCell />
                  </TableRow>
                </TableBody>
                {sortedTransactionClientList &&
                  sortedTransactionClientList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((clientTrans, index) => (
                      <TableBody key={clientTrans.transactionId}>
                        <TableRow
                          style={
                            clientTrans.transactionStatus ===
                              "TRANSACTION_ON_HOLD" ||
                            clientTrans.transactionStatus ===
                              "TRANSACTION_BLOCKED"
                              ? { cursor: "pointer", background: "#fff1f0" }
                              : { cursor: "pointer" }
                          }
                        >
                          <TableCell>
                            {" "}
                            {(this.state.page + 1 - 1) *
                              this.state.rowsPerPage +
                              (index + 1)}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="first-tableBody"
                          >
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(clientTrans.transactionTimestamp || "-")}
                          </TableCell>
                          <TableCell style={{ paddingRight: "0px" }}>
                            {clientTrans.note ? (
                              <NotesPopover
                                content={
                                  <>
                                    <span>{clientTrans.note}</span>
                                    <Tooltip
                                      placement="bottom"
                                      title="Edit Note"
                                    >
                                      <EditOutlined
                                        onClick={() => {
                                          this.setState({
                                            isUpdateNote: true,
                                            trans: clientTrans,
                                          });
                                          this.props.handleChangeToNotes(
                                            clientTrans.note
                                          );
                                          this.props.handleChangeToOpenNoteModal(
                                            true
                                          );
                                        }}
                                        style={{
                                          marginLeft: "4px",
                                          width: "15px",
                                          paddingBottom: "2px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                }
                              />
                            ) : (
                              <Tooltip placement="bottom" title="Add Note">
                                <NoteAddIcon
                                  onClick={() => {
                                    this.setState({
                                      isUpdateNote: false,
                                      trans: clientTrans,
                                    });
                                    this.props.handleChangeToNotes("");
                                    this.props.handleChangeToOpenNoteModal(
                                      true
                                    );
                                  }}
                                  style={{
                                    color: "rgb(126, 126, 126)",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="first-tableBody"
                          >
                            {/* {clientTrans.description
                              ? clientTrans.description
                              : "-"} */}
                            <div
                              style={{
                                position: "relative",
                                top: "10px",
                                cursor: "pointer",
                              }}
                            >
                              {clientTrans.description}
                            </div>
                            <Popover
                              visible={
                                clientTrans.transactionId ===
                                  this.state.tempRowId && this.state.visible
                              }
                              onVisibleChange={this.handleVisibleChange}
                              onClick={() => {
                                this.setState({
                                  trans: clientTrans,
                                });
                              }}
                              trigger={"click"}
                              placement="bottomLeft"
                              content={
                                <>
                                  {this.props.settingState &&
                                    this.props.settingState.descriptionList.map(
                                      (list) => (
                                        <div
                                          value={list.descriptionId}
                                          className="listHover hideScrollBar"
                                          onClick={(e) => {
                                            const selectedData =
                                              this.props.settingState &&
                                              this.props.settingState.descriptionList.find(
                                                (elem) =>
                                                  elem.descriptionId ===
                                                  list.descriptionId
                                              );
                                            const descriptionData = {
                                              transactionId:
                                                this.state.trans.transactionId,
                                              description:
                                                selectedData &&
                                                selectedData.description,
                                            };
                                            this.props.addDescription(
                                              descriptionData,
                                              this.state.clientId,
                                              this.props
                                            );
                                            this.handleVisibleChange(false);
                                          }}
                                        >
                                          {list.description}
                                          <hr style={{ borderTop: "none" }} />
                                        </div>
                                      )
                                    )}
                                </>
                              }
                            >
                              <span
                                id="dropDownToggler"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleChangeDropDown(
                                    clientTrans.transactionId
                                  );
                                }}
                                style={style.editDesc}
                              >
                                <i
                                  class="fa-solid fa-pen fa-xs"
                                  id="checkDropDown"
                                />
                              </span>
                            </Popover>
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.amount
                              ? clientTrans.amount &&
                                clientTrans.amount.charAt(0) === "-"
                                ? formatter
                                    .format(clientTrans.amount / 100)
                                    .substring(1)
                                : "-"
                              : formatter.format(0)}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.amount
                              ? clientTrans.amount &&
                                clientTrans.amount.charAt(0) === "-"
                                ? "-"
                                : formatter.format(clientTrans.amount / 100)
                              : formatter.format(0)}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.balance
                              ? formatter.format(clientTrans.balance / 100)
                              : formatter.format(0)}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.trackingId}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.refId}
                            <span>
                              {clientTrans.checkNo
                                ? " / " + clientTrans.checkNo + " "
                                : ""}
                            </span>
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.vendorAccount.vendorName
                              ? clientTrans.vendorAccount.vendorName
                              : "-"}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.transactionMedium}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.transactionType.replace("_", " ")}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            <>
                              <Tag
                                style={style.colors}
                                color={
                                  clientTrans.transactionStatus ===
                                  "TRANSACTION_APPROVED"
                                    ? "green"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_REVERSED"
                                    ? "red"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_BLOCKED"
                                    ? "yellow"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_CREATED"
                                    ? "purple"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_SUBMITTED"
                                    ? "blue"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_RETURNED"
                                    ? "geekblue"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_POSTED"
                                    ? "cyan"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_SKIPPED"
                                    ? "orange"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_ON_HOLD"
                                    ? "volcano"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_CANCELLED"
                                    ? "gold"
                                    : clientTrans.transactionStatus ===
                                      "TRANSACTION_CLOSED"
                                    ? "magenta"
                                    : "lime"
                                }
                              >
                                {clientTrans.transactionStatus
                                  .replace("TRANSACTION_", " ")
                                  .replace("_", " ")}
                              </Tag>
                              {clientTrans.remark !== undefined ? (
                                <NotesPopover
                                  isRemark
                                  content={clientTrans.remark}
                                />
                              ) : null}
                            </>
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="first-tableBody"
                          >
                            {clientTrans.achLog &&
                            clientTrans.achLog.achSubmittedDate &&
                            clientTrans.achLog.achSubmittedDate
                              ? new Intl.DateTimeFormat("default", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(
                                  clientTrans.achLog.achSubmittedDate
                                    ? clientTrans.achLog.achSubmittedDate
                                    : "-"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="first-tableBody"
                          >
                            {clientTrans.checkPrint &&
                            clientTrans.checkPrint.checkPrintDate &&
                            clientTrans.checkPrint.checkPrintDate
                              ? new Intl.DateTimeFormat("default", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(
                                  clientTrans.checkPrint.checkPrintDate
                                    ? clientTrans.checkPrint.checkPrintDate
                                    : "-"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            className="tableBody"
                          >
                            {clientTrans.postedAt
                              ? new Intl.DateTimeFormat("default", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(
                                  clientTrans.postedAt
                                    ? clientTrans.postedAt
                                    : "-"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogue();
                              this.setState({
                                selectedTransData: clientTrans,
                              });
                            }}
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                              width: "225px",
                            }}
                          >
                            {clientTrans.transactionDetails ? (
                              <div>
                                <div>
                                  {clientTrans.transactionDetails &&
                                    clientTrans.transactionDetails
                                      .scheduleDate &&
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(
                                      clientTrans.transactionDetails
                                        .scheduleDate
                                        ? clientTrans.transactionDetails
                                            .scheduleDate
                                        : "-"
                                    )}
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  {clientTrans.transactionDetails.scheduleBy ? (
                                    <>
                                      <Avatar
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                          marginRight: "8px",
                                        }}
                                        src={
                                          clientTrans.transactionDetails
                                            .profilePic
                                            ? clientTrans.transactionDetails
                                                .profilePic
                                            : "/assets/images/profile.svg"
                                        }
                                      />
                                      <span>
                                        {
                                          clientTrans.transactionDetails
                                            .scheduleBy
                                        }
                                      </span>
                                      <span> / </span>
                                      <span>
                                        {clientTrans.transactionDetails
                                          .scheduleType &&
                                          clientTrans.transactionDetails.scheduleType.replace(
                                            "_",
                                            " "
                                          )}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell style={{ paddingLeft: "0px" }}>
                            {clientTrans.transactionStatus ===
                              "TRANSACTION_ON_HOLD" ||
                            clientTrans.transactionStatus ===
                              "TRANSACTION_BLOCKED" ? (
                              <>
                                <Popover
                                  placement="bottom"
                                  trigger="click"
                                  content={
                                    <>
                                      <div
                                        role="presentation"
                                        onClick={() => {
                                          this.setState({
                                            openActionMenu: false,
                                            setValue: "changeStatus",
                                            isReverse: false,
                                          });
                                          this.props.handleChangeRemark("");
                                          handleOpenToApproveTransaction();
                                          // handleOpenChangeTransStatusModal();
                                        }}
                                        className="cursorP"
                                      >
                                        Approve
                                      </div>
                                      {clientTrans.transactionStatus ===
                                        "TRANSACTION_BLOCKED" && (
                                        <>
                                          <hr />
                                          <div
                                            role="presentation"
                                            style={{
                                              padding: "0.2rem",
                                            }}
                                            onClick={() => {
                                              handleModalToEditBlockedTransaction();
                                              this.props.handleChangeBlockedTransData(
                                                "amount",
                                                clientTrans.amount.substring(1)
                                              );
                                              this.setState({
                                                prevAmount: clientTrans.amount,
                                              });
                                              // this.props.handleChangeBlockedTransData("date", new Date().getTime());
                                            }}
                                            className="cursorP"
                                          >
                                            Edit
                                          </div>
                                          <hr />
                                          <div
                                            role="presentation"
                                            onClick={() => {
                                              handleDeleteModalOpen();
                                              this.props.handleChangeRemark("");
                                              this.setState({
                                                openActionMenu: false,
                                              });
                                            }}
                                            className="cursorP"
                                          >
                                            Delete
                                          </div>
                                        </>
                                      )}
                                    </>
                                  }
                                >
                                  <MoreHorizIcon
                                    id="Popover1"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        popoverElement: e.currentTarget,
                                        openActionMenu: true,
                                        transactionId:
                                          clientTrans.transactionId,
                                      });
                                    }}
                                  />
                                </Popover>
                                {/*<Popover*/}
                                {/*  open={this.state.openActionMenu}*/}
                                {/*  anchorEl={this.state.popoverElement}*/}
                                {/*  onClose={(e) => {*/}
                                {/*    e.preventDefault();*/}
                                {/*    this.setState({ openActionMenu: false });*/}
                                {/*  }}*/}
                                {/*  anchorOrigin={{*/}
                                {/*    vertical: "top",*/}
                                {/*    horizontal: "right",*/}
                                {/*  }}*/}
                                {/*  transformOrigin={{*/}
                                {/*    vertical: "top",*/}
                                {/*    horizontal: "left",*/}
                                {/*  }}*/}
                                {/*  style={{ boxShadow: "none" }}*/}
                                {/*>*/}
                                {/*  <MenuList*/}
                                {/*    id="popover-list"*/}
                                {/*    style={{*/}
                                {/*      color: "#4d4d4d",*/}
                                {/*      padding: "1rem !important",*/}
                                {/*      cursor: "pointer",*/}
                                {/*    }}*/}
                                {/*  >*/}
                                {/*    <MenuItem*/}
                                {/*      onClick={() => {*/}
                                {/*        this.setState({*/}
                                {/*          openActionMenu: false,*/}
                                {/*          setValue: "changeStatus",*/}
                                {/*          isReverse: false,*/}
                                {/*        });*/}
                                {/*        handleOpenChangeTransStatusModal();*/}
                                {/*      }}*/}
                                {/*    >*/}
                                {/*      Accept/Close*/}
                                {/*    </MenuItem>*/}
                                {/*  </MenuList>*/}
                                {/*</Popover>*/}
                              </>
                            ) : clientTrans.transactionStatus ===
                                "TRANSACTION_SUBMITTED" &&
                              clientTrans.transactionMedium === "CHECK" &&
                              clientTrans.transactionType === "DISBURSEMENT" ? (
                              <div>
                                <>
                                  <Popover
                                    placement="bottom"
                                    trigger="click"
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              transactionId:
                                                clientTrans.transactionId,
                                              openActionMenu: false,
                                            });
                                            this.props.handleChangeRemark("");
                                            handleChangeOpenModalToDecline();
                                          }}
                                          className="cursorP"
                                        >
                                          Decline
                                        </div>
                                      </>
                                    }
                                  >
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleChangeTransStatus(
                                          "TRANSACTION_REVERSED"
                                        );
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          isReverse: true,
                                          selectedTransData: clientTrans,
                                          transactionId:
                                            clientTrans.transactionId,
                                        });
                                      }}
                                    />
                                  </Popover>
                                </>
                              </div>
                            ) : clientTrans.transactionStatus ===
                              "TRANSACTION_REVERSED" ? (
                              <div>
                                <>
                                  <Popover
                                    placement="bottom"
                                    trigger="click"
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              transactionId:
                                                clientTrans.transactionId,
                                              openActionMenu: false,
                                            });
                                            this.props.handleChangeRemark("");

                                            handleChangeOpenModalToUndoReverse();
                                          }}
                                          className="cursorP"
                                        >
                                          Undo
                                        </div>
                                      </>
                                    }
                                  >
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // this.props.handleChangeTransStatus(
                                        //   "TRANSACTION_REVERSED"
                                        // );
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          // isReverse: true,
                                          // selectedTransData: clientTrans,
                                          // transactionId:
                                          //   clientTrans.transactionId,
                                        });
                                      }}
                                    />
                                  </Popover>
                                </>
                              </div>
                            ) : clientTrans.transactionStatus ===
                              "TRANSACTION_POSTED" ? (
                              <div>
                                <>
                                  <Popover
                                    placement="bottom"
                                    trigger="click"
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={() => {
                                            handleOpenChangeTransStatusModal();
                                            this.props.handleChangeRemark("");
                                            this.setState({
                                              openActionMenu: false,
                                            });
                                          }}
                                          className="cursorP"
                                        >
                                          Reverse
                                        </div>
                                      </>
                                    }
                                  >
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleChangeTransStatus(
                                          "TRANSACTION_REVERSED"
                                        );
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          isReverse: true,
                                          transactionId:
                                            clientTrans.transactionId,
                                        });
                                      }}
                                    />
                                  </Popover>
                                </>
                              </div>
                            ) : clientTrans.transactionStatus ===
                              "TRANSACTION_SUBMITTED" ? (
                              // clientTrans.transactionMedium === "LEDGER" ||
                              // (clientTrans.transactionMedium === "CHECK" &&
                              //   clientTrans.transactionType ===
                              //     "DISBURSEMENT") ||
                              // clientTrans.transactionMedium === "ACH" ? (
                              //   <></>
                              // ) : (
                              <div>
                                <>
                                  <Popover
                                    placement="bottom"
                                    trigger="click"
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={() => {
                                            handleOpenChangeTransStatusModal();
                                            this.props.handleChangeRemark("");
                                            this.setState({
                                              openActionMenu: false,
                                            });
                                          }}
                                          className="cursorP"
                                        >
                                          Reverse
                                        </div>
                                        {clientTrans.transactionStatus ===
                                          "TRANSACTION_CREATED" ||
                                        clientTrans.transactionStatus ===
                                          "TRANSACTION_APPROVED" ? (
                                          <>
                                            <hr />
                                            <div
                                              role="presentation"
                                              // style={{
                                              //   padding: "0.2rem",
                                              // }}
                                              onClick={() => {
                                                this.setState({
                                                  isUpdate: true,
                                                  openActionMenu: false,
                                                });
                                                setOpenCreateTransaction(true);
                                                this.props.setClientTransactionInputDataToEdit(
                                                  this.state.selectedTransData
                                                );
                                                this.props.getAmountDetailByClientId(
                                                  clientId
                                                );
                                                this.props.fetchVendor();
                                                this.props.fetchDescriptionList();
                                                this.props.fetchVendorClientById(
                                                  clientId
                                                );
                                                // this.props.fetchSingleClient(
                                                //   this.state
                                                //     .selectedTransData
                                                //     .clientId,
                                                //   this.props
                                                // );
                                                // this.setState({
                                                //   clearBankDetail: false,
                                                // });
                                              }}
                                              className="cursorP"
                                            >
                                              Edit
                                            </div>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    }
                                  >
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleChangeTransStatus(
                                          "TRANSACTION_REVERSED"
                                        );
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          isReverse: true,
                                          transactionId:
                                            clientTrans.transactionId,
                                        });
                                      }}
                                    />
                                  </Popover>
                                  {/*<Popover*/}
                                  {/*    open={this.state.openActionMenu}*/}
                                  {/*    anchorEl={this.state.popoverElement}*/}
                                  {/*    onClose={(e) => {*/}
                                  {/*      e.preventDefault();*/}
                                  {/*      this.setState({*/}
                                  {/*        openActionMenu: false,*/}
                                  {/*      });*/}
                                  {/*    }}*/}
                                  {/*    anchorOrigin={{*/}
                                  {/*      vertical: "top",*/}
                                  {/*      horizontal: "right",*/}
                                  {/*    }}*/}
                                  {/*    transformOrigin={{*/}
                                  {/*      vertical: "top",*/}
                                  {/*      horizontal: "left",*/}
                                  {/*    }}*/}
                                  {/*    style={{ boxShadow: "none" }}*/}
                                  {/*>*/}
                                  {/*  <MenuList*/}
                                  {/*      id="popover-list"*/}
                                  {/*      style={{*/}
                                  {/*        color: "#4d4d4d",*/}
                                  {/*        padding: "1rem !important",*/}
                                  {/*        cursor: "pointer",*/}
                                  {/*      }}*/}
                                  {/*  >*/}
                                  {/*    <MenuItem*/}
                                  {/*        onClick={() => {*/}
                                  {/*          handleOpenChangeTransStatusModal();*/}
                                  {/*          this.props.handleChangeRemark('');*/}
                                  {/*          this.setState({*/}
                                  {/*            openActionMenu: false,*/}
                                  {/*          });*/}
                                  {/*        }}*/}
                                  {/*    >*/}
                                  {/*      Reverse*/}
                                  {/*    </MenuItem>*/}
                                  {/*  </MenuList>*/}
                                  {/*</Popover>*/}
                                </>
                              </div>
                            ) : // )
                            clientTrans.transactionStatus ===
                                "TRANSACTION_APPROVED" &&
                              clientTrans.transactionMedium === "ACH" ? (
                              <div>
                                <>
                                  <Popover
                                    style={{ padding: "10px 12px" }}
                                    placement="bottom"
                                    trigger="click"
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={() => {
                                            handleOpenChangeTransStatusModal(
                                              clientTrans
                                            );
                                            this.props.handleChangeRemark("");
                                            this.setState({
                                              openActionMenu: false,
                                            });
                                          }}
                                          className="cursorP"
                                        >
                                          Complete
                                        </div>
                                        {userRole === "SUPER_ADMIN" &&
                                          accountType === "EVERFUND_USER" && (
                                            <>
                                              <hr />
                                              <div
                                                role="presentation"
                                                onClick={() => {
                                                  handleDeleteModalOpen();
                                                  this.props.handleChangeRemark(
                                                    ""
                                                  );
                                                  this.setState({
                                                    openActionMenu: false,
                                                  });
                                                }}
                                                className="cursorP"
                                              >
                                                Delete
                                              </div>
                                              <hr />
                                              <div
                                                role="presentation"
                                                // style={{
                                                //   padding: "0.2rem",
                                                // }}
                                                onClick={() => {
                                                  this.setState({
                                                    isUpdate: true,
                                                    openActionMenu: false,
                                                  });
                                                  setOpenCreateTransaction(
                                                    true
                                                  );
                                                  this.props.setClientTransactionInputDataToEdit(
                                                    this.state.selectedTransData
                                                  );
                                                  this.props.getAmountDetailByClientId(
                                                    clientId
                                                  );
                                                  this.props.fetchVendor();
                                                  this.props.fetchDescriptionList();
                                                  this.props.fetchVendorClientById(
                                                    clientId
                                                  );
                                                }}
                                                className="cursorP"
                                              >
                                                Edit
                                              </div>
                                            </>
                                          )}
                                      </>
                                    }
                                  >
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleChangeTransStatus(
                                          "TRANSACTION_REVERSED"
                                        );
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          isReverse: true,
                                          selectedTransData: clientTrans,
                                          transactionId:
                                            clientTrans.transactionId,
                                        });
                                      }}
                                    />
                                  </Popover>
                                </>
                              </div>
                            ) : userRole === "SUPER_ADMIN" &&
                              accountType === "EVERFUND_USER" &&
                              (clientTrans.transactionStatus ===
                                "TRANSACTION_APPROVED" ||
                                clientTrans.transactionStatus ===
                                  "TRANSACTION_CREATED") ? (
                              <div>
                                <>
                                  <Popover
                                    placement="bottom"
                                    trigger="click"
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={() => {
                                            this.setState({
                                              openActionMenu: false,
                                              setValue: "changeStatus",
                                            });
                                            this.props.handleChangeRemark("");
                                            this.props.handleChangeTransStatus(
                                              ""
                                            );
                                            handleDeleteModalOpen();
                                          }}
                                          className="cursorP"
                                        >
                                          Delete
                                        </div>

                                        {clientTrans.transactionStatus ===
                                          "TRANSACTION_APPROVED" &&
                                          clientTrans.transactionType !==
                                            "DEPOSIT" &&
                                          clientTrans.transactionMedium !==
                                            "LEDGER" && (
                                            <>
                                              <hr />
                                              <div
                                                role="presentation"
                                                // style={{
                                                //   padding: "0.2rem",
                                                // }}
                                                onClick={() => {
                                                  this.setState({
                                                    openActionMenu: false,
                                                    isUpdate: true,
                                                  });
                                                  setOpenCreateTransaction(
                                                    true
                                                  );
                                                  this.props.setClientTransactionInputDataToEdit(
                                                    this.state.selectedTransData
                                                  );
                                                  this.props.getAmountDetailByClientId(
                                                    clientId
                                                  );
                                                  this.props.fetchVendor();
                                                  this.props.fetchDescriptionList();
                                                  this.props.fetchVendorClientById(
                                                    clientId
                                                  );
                                                }}
                                                className="cursorP"
                                              >
                                                Edit
                                              </div>
                                            </>
                                          )}
                                      </>
                                    }
                                  >
                                    <MoreHorizIcon
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          selectedTransData: clientTrans,
                                          transactionId:
                                            clientTrans.transactionId,
                                        });
                                      }}
                                    />
                                  </Popover>
                                </>
                              </div>
                            ) : (
                              <div />
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              paddingLeft: "0px",
                              fontSize: "15px",
                              color: "rgb(98, 98, 98)",
                              textAlign: "left",
                              height: "4.5rem",
                              paddingRight: "0px",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.fetchActivityLogByRefId(
                                clientTrans.transactionId
                              );
                              handleOpenActivityLogModal();
                            }}
                          >
                            <Tooltip title="View Activity Log">
                              <img
                                width={22}
                                src={"/assets/images/activityLog1.svg"}
                                alt=""
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {sortedTransactionClientList &&
            sortedTransactionClientList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedTransactionClientList.length > 0
                      ? sortedTransactionClientList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>

        {/******* CREATE TRANSACTION ******/}
        <CreateTransactionForm
          setOpenCreateTransaction={setOpenCreateTransaction}
          transactionState={transactionState}
          clientData={clientData}
          transactionClientDetail={transactionClientDetail}
          handleChangeTransData={(id, value) => {
            this.props.handleChangeTransData(id, value);
          }}
          vendorAccount={
            this.props &&
            this.props.vendorState &&
            this.props.vendorState.vendorAccount
          }
          vendorState={this.props && this.props.vendorState}
          settingState={this.props && this.props.settingState}
          scheduleTransactionState={
            this.props && this.props.scheduleTransactionState
          }
          uploadDocumentFile={uploadDocumentFile}
          createTransData={(data) => {
            this.props.createTransData(data, this.props);
          }}
          updateClientTransData={(data) => {
            this.props.updateClientTransData(data, this.props);
          }}
          fetchSingleClient={() => {
            this.props.fetchSingleClient(this.state.clientId, this.props);
          }}
          isUpdate={this.state.isUpdate}
        />
        {/*add/update notes modal*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={openNotesModal}
          maxWidth="lg"
          onClose={() => {
            this.props.handleChangeToOpenNoteModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              this.props.handleChangeToOpenNoteModal(false);
            }}
          >
            {!this.state.isUpdateNote ? "Add Note" : "Update Note"}
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div>
              <div className="row">
                <div className="col-12 mt-4">
                  <label htmlFor="amount" style={style.columnHeading}>
                    Note
                    <span style={{ color: "red" }}> *</span>{" "}
                  </label>
                  <input
                    id="remark"
                    name="remark"
                    autoComplete="off"
                    className="inputField"
                    value={notes}
                    onChange={(e) => {
                      this.setState({
                        isBlocking: true,
                      });
                      this.props.handleChangeToNotes(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const noteData = {
                        transactionId: this.state.trans.transactionId,
                        note: notes,
                      };
                      this.props.addNoteForTransaction(
                        noteData,
                        clientId,
                        this.props
                      );
                    }}
                    loading={transactionState && transactionState.loading}
                    disabled={!notesValue}
                    className={notesValue ? "addButton" : "disabledAddButton"}
                  >
                    {!this.state.isUpdateNote ? "Add" : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openSearchDialogue}
          maxWidth="lg"
          onClose={handleChangeCloseSearchDialogue}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseSearchDialogue}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeDateFrom(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeDateTo(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeDateFrom(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeDateTo(date);
              }}
            />
            <label style={style.label}> Medium </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterMediumData}
              isItemCheck={this.state.isMediumCheck}
              isAllCheck={this.state.isAllMediumChecked}
              handleAllCheckbox={this.handleAllMediumCheckbox}
              handleSelectedChange={this.handleSelectedMediumChange}
            />
            <label style={style.label}> Status </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterStatusData}
              isItemCheck={this.state.isStatusCheck}
              isAllCheck={this.state.isALlChecked}
              handleAllCheckbox={this.handleAllCheckbox}
              handleSelectedChange={this.handleSelectedChange}
            />
            <label style={style.label}> Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={allTypeArray}
              isItemCheck={this.state.isUPTypeCheck}
              isAllCheck={this.state.isAllUPTypeCheked}
              handleAllCheckbox={this.handleAllUPTypeCheckbox}
              handleSelectedChange={this.handleSelectedUPTypeChange}
            />
            <label style={style.label}> Vendor </label>
            <div className="select-input h40">
              <Select
                className="inputField"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  this.props.handleChangeVendor(e);
                }}
              >
                {vendorList &&
                  vendorList.map((list) => (
                    <Option value={list.vendorId}>{list.vendorName}</Option>
                  ))}
              </Select>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6" style={{ paddingTop: "15px" }}>
                {(startDate === null || startDate === 0) &&
                (endDate === null || endDate === 0) &&
                term === "" &&
                vendorToFilter === "" &&
                arraysEqual(
                  this.state.isStatusCheck,
                  FilterStatusData.map((status) => status.name)
                ) &&
                arraysEqual(
                  this.state.isUPTypeCheck,
                  FilterUPTypeData.map((type) => type.name)
                ) &&
                arraysEqual(
                  this.state.isMediumCheck,
                  FilterMediumData.map((medium) => medium.name)
                ) ? (
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        page: 0,
                      });
                      this.props.getClientTransaction(clientId);
                      handleChangeCloseSearchDialogue();
                    }}
                    // disabled={!filteredValue}
                    // className={filteredValue ? "addButton" : "disabledAddButton"}
                    className="addButton"
                  >
                    Filter
                  </Button>
                ) : (
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        page: 0,
                      });
                      this.props.searchClientTransactionByAll(
                        // mediumType,
                        // status,
                        transactionMediumHelper(this.state.isMediumCheck),
                        transactionStatusHelper(this.state.isStatusCheck),
                        // transactionType,
                        transactionTypeHelper(this.state.isUPTypeCheck, "type"),
                        startDate,
                        endDate,
                        vendorToFilter,
                        clientId
                      );
                      handleChangeCloseSearchDialogue();
                    }}
                    // disabled={!filteredValue}
                    // className={filteredValue ? "addButton" : "disabledAddButton"}
                    className="addButton"
                  >
                    Filter
                  </Button>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*ACTIVITY LOG*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openActivityLogModal}
          onClose={handleCloseActivityLogModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseActivityLogModal}
          >
            Changelog Info
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{ width: "726px", maxHeight: "450px", paddingTop: "25px" }}
          >
            {activityLogValue && activityLogValue.length > 0 ? (
              activityLogValue.map((logValue) => (
                <div className="changeLogOuterDiv">
                  <div>
                    <Avatar
                      className="changeLogAvatar"
                      src={
                        logValue.profilePic
                          ? logValue.profilePic
                          : "/assets/images/profile.svg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "25px", fontSize: "16px" }}>
                    <span style={{ fontWeight: 600 }}>
                      {logValue.userName ? logValue.userName : "User"}
                    </span>{" "}
                    {logValue.activity === "transaction.updated"
                      ? "updated"
                      : logValue.activity === "transaction.added"
                      ? "added"
                      : "changed"}{" "}
                    <span style={{ fontWeight: 600 }}>
                      {logValue.activity === "transaction.note.updated"
                        ? "transaction note as"
                        : logValue.activity === "transaction.updated" ||
                          logValue.activity === "transaction.added"
                        ? "transaction"
                        : "transaction status to"}
                    </span>{" "}
                    <span>
                      {logValue.activity ===
                      "transaction.status.updated.to APPROVED"
                        ? `'APPROVED'`
                        : logValue.activity ===
                          "transaction.status.updated.to REVERSED"
                        ? "REVERSED"
                        : logValue.refValue &&
                          logValue.refValue.charAt(0) === "{"
                        ? ""
                        : `"${logValue.refValue}"`}{" "}
                      {/* {logValue.activity ===
                      "transaction.status.updated.to APPROVED"
                        ? `from "${logValue.refValue}"`
                        : ""} */}
                    </span>
                    {logValue.refValue &&
                      logValue.refValue.charAt(0) === "{" &&
                      logValue.activity === "transaction.updated" && (
                        <>
                          <br />
                          <ChangedLogPopover
                            refValue={logValue.refValue}
                            formatter={formatter}
                          />
                        </>
                      )}
                    <div style={{ fontSize: "14px" }}>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(logValue.createdAt)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>
                  <img src="/assets/images/activityLog1.svg" alt="" />
                </div>
                <div>No changes till date !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        {/**** Update status to approved******/}
        <Dialog
          open={this.state.openModalToApproveTransaction}
          onClose={handleOpenToApproveTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to approve transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseToApproveTransaction}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseToApproveTransaction();
                      handleStatusToApprove();
                      // handleOkTransaction(this.state.upcomingTrans);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/* Delete transaction  */}
        <Dialog
          open={this.state.openModalToDeleteTransaction}
          onClose={handleCloseToApproveTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to Delete transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleDeleteModalClose}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteModalClose();
                      handleDeleteStatus();
                      // handleOkTransaction(this.state.upcomingTrans);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/******* Change status modal ******/}
        <Dialog
          open={this.state.openModalToChangeTransStatus}
          onClose={handleCloseChangeTransStatusModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseChangeTransStatusModal}
            >
              Update Transaction Status
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-lg-12" hidden={this.state.isReverse}>
                  <label className="label">
                    Status <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input selects h40">
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ value: e });
                        this.props.handleChangeTransStatus(e);
                      }}
                    >
                      <Option hidden />
                      <Option value="TRANSACTION_APPROVED">Approve</Option>
                      <Option value="TRANSACTION_CLOSED">Close</Option>
                    </Select>
                  </div>
                </div>
                {transStatus === "TRANSACTION_CLOSED" ||
                this.state.isReverse ? (
                  <>
                    <div className="col-lg-12">
                      <label className="label">
                        Remark <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="text"
                        id="remark"
                        className="input"
                        onChange={(e) => {
                          e.preventDefault();
                          this.props.handleChangeRemark(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-lg-12">
                      <label className="label">New Date</label>
                      <div>
                        <KeyBoardDatePickerComponent
                          dateTimeFormat
                          isCalendarDate
                          dateValue={
                            reversedDate &&
                            getDateWithStartTimeValue(reversedDate)
                          }
                          onChangeDate={(date) => {
                            this.props.handlChangeReverseDate(date);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={this.state.isReverse ? remarkValue : value}
                    buttonName="Update"
                    loading={this.props.transactionState.loading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdateStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*modal to edit blocked transaction */}
        <Dialog
          open={this.state.openModalToEditBlockedTransaction}
          onClose={handleCloseToEditBlockedTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseToEditBlockedTransaction}
            >
              Update
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Amount (Dollar) <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  autoComplete="off"
                  className="inputField"
                  value={
                    blockedData.amount === 0 ? "" : blockedData.amount / 100
                  }
                  onKeyPress={(e) => {
                    naturalRound(e);
                  }}
                  onChange={(e) => {
                    this.props.handleChangeBlockedTransData(
                      e.target.id,
                      finalAmount(e)
                    );
                    // Helpers.handleValidationOnChange(e, errors);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">New Date</label>
                  <div>
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={
                        blockedData.date &&
                        getDateWithStartTimeValue(blockedData.date)
                      }
                      onChangeDate={(date) => {
                        this.props.handleChangeBlockedTransData("date", date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={blockedStatus}
                    buttonName="Update"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseToEditBlockedTransaction();
                      handleChangeBlockedTransaction();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*modal to undo reverse */}
        <Dialog
          open={this.state.openModalToUndoReverse}
          onClose={handleChangeCloseModalToUndoReverse}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseModalToUndoReverse}
            >
              Undo Reverse
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Remark <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeRemark(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={declineStatus}
                    buttonName="Decline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseModalToUndoReverse();
                      handleUpdateStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*modal to decline check*/}
        <Dialog
          open={this.state.openModalToDeclineCheck}
          onClose={handleChangeCloseModalToDecline}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseModalToDecline}
            >
              Decline Check
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Remark <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeRemark(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={declineStatus}
                    buttonName="Decline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseModalToDecline();
                      handleUpdateCheckStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/* Trnx Info Model */}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openTrnxInfoDialogue}
          onClose={handleCloseDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogue}
          >
            Transaction Detail
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              paddingTop: "25px",
              paddingBottom: "25px",
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Reference Id
                  </label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.refId}
                  </label>
                </span>
                <span className="col-4">
                  <label style={style.columnHeading}>Debits (Dollar)</label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                    this.state.selectedTransData.amount
                      ? this.state.selectedTransData.amount.charAt(0)
                        ? formatter
                            .format(this.state.selectedTransData.amount / 100)
                            .substring(1)
                        : "-"
                      : formatter.format(0)}
                  </label>
                </span>
                <span className="col-4">
                  <label style={style.columnHeading}>Credit (Dollar)</label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                    this.state.selectedTransData.amount
                      ? this.state.selectedTransData.amount.charAt(0)
                        ? "-"
                        : formatter.format(
                            this.state.selectedTransData.amount / 100
                          )
                      : formatter.format(0)}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Client Name
                  </label>
                  <label style={style.extraData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.Client &&
                      this.state.selectedTransData.Client.firstName +
                        " " +
                        this.state.selectedTransData.Client.lastName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Vendor Name
                  </label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                    this.state.selectedTransData.vendorAccount &&
                    this.state.selectedTransData.vendorAccount.vendorName
                      ? this.state.selectedTransData.vendorAccount.vendorName
                      : "-"}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Transaction Medium
                  </label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.transactionMedium &&
                      this.state.selectedTransData.transactionMedium.replace(
                        "_",
                        " "
                      )}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span
                  className="col-4"
                  hidden={
                    this.state.selectedTransData &&
                    this.state.selectedTransData.checkNo === undefined
                  }
                >
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Check No.
                  </label>
                  <label style={style.extraData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.checkNo}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Transaction Type
                  </label>
                  <label style={style.extraData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.transactionType}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Created Date
                  </label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.createdAt &&
                      new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(this.state.selectedTransData.createdAt || "-")}
                  </label>
                </span>
                <span
                  className={
                    this.state.selectedTransData &&
                    this.state.selectedTransData.checkNo
                      ? "col-4 mt-3"
                      : "col-4"
                  }
                >
                  <label htmlFor="dob" style={style.columnHeading}>
                    Transaction Date
                  </label>
                  <label style={style.clientData}>
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.transactionTimestamp &&
                      new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(
                        this.state.selectedTransData.transactionTimestamp || "-"
                      )}
                  </label>
                </span>
                <span className="col-4 mt-3">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Description
                  </label>
                  <label style={style.extraData}>
                    {this.state.selectedTransData &&
                    this.state.selectedTransData.description
                      ? this.state.selectedTransData.description
                      : "-"}
                  </label>
                </span>
              </span>
              <span
                className="row"
                hidden={
                  !(
                    this.state.selectedTransData &&
                    this.state.selectedTransData.note
                  )
                }
              >
                <div
                  style={{
                    marginLeft: " 240px",
                    marginTop: "20px",
                    fontSize: "17px",
                  }}
                >
                  <span>
                    Note :
                    {this.state.selectedTransData &&
                      this.state.selectedTransData.note}{" "}
                  </span>
                </div>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/* dialog to undo reverse transaction */}
        <Dialog
          open={this.state.openConfirmationToUndoReverse}
          onClose={handleCloseUndoReverse}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to undo this reversed transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUndoReverse}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // handleCloseChangeTransStatusModal();
                      handleCloseUndoReverse();
                      handleUpdateStatus();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config.configFilters);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.transactionState.openTransactionSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={this.props.transactionState.errorMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.transactionState.openUpdateSuccessSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="success"
            message="Successfully Updated"
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.transactionState.openUpdateErrorSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={this.props.transactionState.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.transactionState.openTransactionSuccessSnackbar}
          autoHideDuration={4000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="success"
            message={
              transactionState && transactionState.successMsg
                ? transactionState.successMsg
                : "Successfully Downloaded"
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.transactionState.openTransactionErrorSnackbar}
          autoHideDuration={4000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={this.props.transactionState.error}
          />
        </Snackbar>
        {/******* Success/Error Message For Create Txn Form ********/}
        <SnackBarComponent
          onClose={this.props.closeTransactionSnackBar}
          variant="success"
          open={
            transactionState &&
            transactionState.openModalCreateTransactionSuccess
          }
          message={transactionState && transactionState.successMsg}
        />
        <SnackBarComponent
          onClose={this.props.closeTransactionSnackBar}
          variant="error"
          open={
            transactionState && transactionState.openModalCreateTransactionError
          }
          message={transactionState && transactionState.errorMsg}
        />
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    margin: "5px 3% 1% 2%",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "23%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    marginTop: "20px",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "180px",
    height: "40px",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
    marginTopL: "48px",
  },
  icon: {
    position: "absolute",
    top: "56px",
    right: "30px",
    color: " rgb(200, 197, 197)",
    fontSize: "32px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  filterButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
    marginRight: "12px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    // top: 0,
    // position: "inherit",
  },
  tableHeaderAmount: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "right",
    height: "3rem",
    // top: 0,
    // position: "inherit",
    paddingRight: "48px",
  },
  tableBodyAmount: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "right",
    height: "4.5rem",
    paddingRight: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  exportButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "100px",
    height: "35px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "190px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
    height: "40px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  editDesc: {
    marginLeft: "118px",
    top: "-10px",
    position: "relative",
    padding: "20px",
  },
};

export default IndividualClientTransactionScreen;
