import {connect} from 'react-redux';
import CalendarViewOfAgentScreen from "../../screens/CalendarViewOfAgentScreen";
import {
    clientRequestMade,
    detailsForCalendarRequestMade,
    fetchDataForCalendarByAgentFailure,
    fetchDataForCalendarByAgentSuccess
} from "../../actions";
import CalendarApi from "../../api/CalendarApi";
import {
    searchCalendarDataByAgentFailure,
    searchCalendarDataByAgentSuccess,
} from "../../actions/CalendarAction";
import {ClientAPI, SettingsAPI} from "../../api";
import {
    getAssignedEventForClientCalendarFailure,
    getAssignedEventForClientCalendarSuccess
} from "../../actions/ClientAction";
import {
    addHolidaysFailure,
    addHolidaysSuccess, clearModalData, fetchHolidaysFailure, fetchHolidaysSuccess, handleChangeCalendarType,
    handleChangeHolidayName, makeSuccessToFalse,
    requestInCalendarSetting
} from "../../actions/SettingsAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return{
        fetchDataListForCalendarByAgentId: (id) => {
            dispatch(detailsForCalendarRequestMade(true));
            CalendarApi.fetchDataListForCalendarById(id,(response, error) => {
                if (response) {
                    dispatch(fetchDataForCalendarByAgentSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchDataForCalendarByAgentFailure(error.msg))
                    } else {
                        dispatch(fetchDataForCalendarByAgentFailure(error));
                    }
                }
            })
        },
        searchCalendarDataByAgentId: (value, id, props) =>{
            dispatch(detailsForCalendarRequestMade(true));
            CalendarApi.searchCalendarByAgentId(value, id,(response, error) => {
                if (response) {
                    dispatch(searchCalendarDataByAgentSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchCalendarDataByAgentFailure(error.msg))
                    } else {
                        dispatch(searchCalendarDataByAgentFailure(error));
                    }
                }
            })
        },
        getClientAssignedEvent: (clientId , props) =>{
            dispatch(clientRequestMade(true));
            ClientAPI.getClientAssignedEvent(clientId, (response, error) => {
                if (response) {
                    dispatch(getAssignedEventForClientCalendarSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(getAssignedEventForClientCalendarFailure(error.msg))
                    } else {
                        dispatch(getAssignedEventForClientCalendarFailure(error));
                    }
                }
            })
        },
        // add holiday
        handleChangeHolidayName: (holidayName) =>{dispatch(handleChangeHolidayName(holidayName))},
        handleChangeCalendarType: (calendarType) => {
            dispatch(handleChangeCalendarType(calendarType));
        },
        addHolidays : (holidayData,userId, props) => {
            dispatch(requestInCalendarSetting(true));
            SettingsAPI.addHolidays(holidayData, (response, error) => {
                if (response) {
                    props.fetchHolidaysList();
                    props.fetchDataListForCalendarByAgentId(userId);
                    props.getClientAssignedEvent(userId);
                    dispatch(addHolidaysSuccess(true, response));

                } else {
                    if (error.msg) {
                        dispatch(addHolidaysFailure(error.msg));
                    } else {
                        dispatch(addHolidaysFailure(false));
                    }
                }
            });
        },
        setToFalse : () => {dispatch(makeSuccessToFalse(false))},
        clearModalData: () => {dispatch(clearModalData())},
        fetchHolidaysList: () => {
            dispatch(requestInCalendarSetting(true));
            SettingsAPI.fetchHolidayDetails((response, error) => {
                if (response) {
                    dispatch(fetchHolidaysSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchHolidaysFailure(error.msg))
                    } else {
                        dispatch(fetchHolidaysFailure(error));
                    }
                }
            })
        },
    }
}

export const CalendarViewForAgentContainer =
    connect(mapStateToProps, mapDispatchToProps) (CalendarViewOfAgentScreen);