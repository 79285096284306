import { connect } from 'react-redux';
import { ActionEditFormScreen } from "../../screens";
import {
    actionRequestMade,
    addActionFailure,
    addActionSuccess,
    closeSnackBarForAction,
    handleChangeAction,
    setActionToEdit
} from "../../actions";
import { ActionAPI } from "../../api";
import {
    getConditionalFormFailure,
    getConditionalFormSuccess,
    searchExistingOptionValueFailure,
    searchExistingOptionValueSuccess,
    setConditionalActionFormFailure,
    setConditionalActionFormSuccess,
    setEditedConditionalForm,
    setPreviousValue
} from "../../actions/ActionTemplateAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleChangeAction: (id, value) => dispatch(handleChangeAction(id, value)),
        editAction: (actionFormData, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.editAction(actionFormData, (response, error) => {
                if (response) {
                    dispatch(addActionSuccess(true));
                    props.history.replace('/action');
                } else {
                    if (error.msg) {
                        dispatch(addActionFailure(error.msg));
                    } else {
                        dispatch(addActionFailure(error));
                    }
                }
            });
        },

        getActionInfo: actionId => {
            dispatch(actionRequestMade(true));
            ActionAPI.fetchSingleAction(actionId, (response, error) => {
                if (response) {
                    dispatch(setActionToEdit(response));
                } else {
                    if (error.msg) {
                        dispatch(addActionFailure(error.msg));
                    } else {
                        dispatch(addActionFailure(error));
                    }
                }
            })
        },
        setActionData: (actionFormData) => {
            dispatch(setActionToEdit(actionFormData))
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForAction(true)),
        searchExistingOptionValue: (term, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.searchOptionValue(term, (response, error) => {
                if (response) {
                    dispatch(searchExistingOptionValueSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchExistingOptionValueFailure(error.msg));
                    } else {
                        dispatch(searchExistingOptionValueFailure(error));
                    }
                }
            });
        },

        getConditionalFormByRefId: (refId,optionId, actionId,step,prevOptionId, prevActionId, prevRefId, prevOptionName, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.getConditionalFormByRefId(refId, (response, error) => {
                if (response) {
                    dispatch(getConditionalFormSuccess(response, refId, optionId, actionId, step,prevOptionId, prevActionId, prevRefId, prevOptionName));
                } else {
                    if (error.msg) {
                        dispatch(getConditionalFormFailure(error.msg));
                    } else {
                        dispatch(getConditionalFormFailure(error));
                    }
                }
            });
        },

        setConditionalByRefId : (conditionalData, props) =>{
            dispatch(actionRequestMade(true));
            ActionAPI.setConditionalActionForm(conditionalData,(response, error) => {
                if (response) {
                    dispatch(setConditionalActionFormSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(setConditionalActionFormFailure(error.msg));
                    } else {
                        dispatch(setConditionalActionFormFailure(error));
                    }
                }
            });
        },
        setPreviousValue: (optionId, actionId, refId, optionName) => {dispatch(setPreviousValue(optionId, actionId, refId, optionName))},
        setEditedConditionalForm: (currentActionId, currentOptionId,conditionalActionForm) =>
        {dispatch(setEditedConditionalForm(currentActionId, currentOptionId,conditionalActionForm))}
    };
};


export const ActionEditFormContainer = connect(mapStateToProps, mapDispatchToProps)(ActionEditFormScreen);