import React from "react";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "../PaperComponent";
import DialogTitleComponent from "../DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import { Select } from "antd";
import KeyBoardDatePickerComponent from "../DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../../utils/DateConverter";
import ButtonComponent from "../ButtonComponent";
import CheckboxFilterPopover from "../../components/CheckboxFilterPopover";
import {
  transactionMediumHelper,
  transactionTypeHelper,
} from "../TransactionHelper";
const { Option } = Select;

const TransactionFilterForm = (props) => {
  const vendorState = props && props.vendorState;
  const vendorList = vendorState.vendorAccount;

  const transactionState = props && props.transactionState;
  const startDate = transactionState && transactionState.from;
  const endDate = transactionState && transactionState.to;
  const vendorToFilter = transactionState && transactionState.vendorToFilter;
  const mediumType = transactionState && transactionState.mediumType;

  const transactionType = transactionState && transactionState.transType;

  return (
    <Dialog
      id="viewScheduleTransactionDetails"
      open={props.openFilterModal}
      maxWidth="lg"
      onClose={props.handleChangeCloseSearchDialogue}
      PaperComponent={PaperComponent}
      aria-labelledby="#draggable-dialog-title"
    >
      <DialogTitleComponent
        id="draggable-dialog-title"
        onClose={props.handleChangeCloseSearchDialogue}
      >
        Filter
      </DialogTitleComponent>
      <DialogContent
        dividers
        style={{
          background: "white",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          paddingLeft: "1.5rem !important",
          paddingRight: "1.5rem !important",
          width: "450px",
          paddingTop: "5px",
        }}
      >
        <div className="row">
          <div className="col-lg-6">
            <label>Start Date</label>
            <div className="select-input">
              <KeyBoardDatePickerComponent
                dateValue={startDate && getFormattedDateValue(startDate)}
                // dateValue={null}
                isFilterFiled
                onChangeDate={(data) => {
                  props.handleChangeDateFrom(new Date(data).setHours(0, 0, 0));
                }}
              />
              {/*<DatePicker*/}
              {/*    style={style.rangeBase}*/}
              {/*    value={startDate && getPickerDate(startDate)}*/}
              {/*    getPopupContainer={(trigger) => trigger.parentNode}*/}
              {/*    // onChange={handleChangeDateFrom}*/}
              {/*/>*/}
            </div>
          </div>
          <div className="col-lg-6">
            <label>End Date</label>
            <div className="select-input">
              <KeyBoardDatePickerComponent
                dateValue={endDate && getFormattedDateValue(endDate)}
                isFilterFiled
                onChangeDate={(data) => {
                  props.handleChangeDateTo(new Date(data).setHours(0, 0, 0));
                }}
              />
              {/*<DatePicker*/}
              {/*    style={style.rangeBase}*/}
              {/*    value={endDate && getPickerDate(endDate)}*/}
              {/*    getPopupContainer={(trigger) => trigger.parentNode}*/}
              {/*    onChange={handleChangeDateTo}*/}
              {/*/>*/}
            </div>
          </div>
        </div>
        {/* <div className="mt-4">
					<label> Medium </label>
					<CheckboxFilterPopover
						forMappingFilterData={props.FilterMediumData}
						isItemCheck={props.isMediumCheck}
						isAllCheck={props.isAllMediumChecked}
						handleAllCheckbox={props.handleAllMediumCheckbox}
						handleSelectedChange={props.handleSelectedMediumChange}
					/>
				</div>
				<div className="mt-4">
					<label> Type </label>
					<CheckboxFilterPopover
						forMappingFilterData={props.FilterTypeData}
						isItemCheck={props.isTypeCheck}
						isAllCheck={props.isAllTypeChecked}
						handleAllCheckbox={props.handleAllTypeCheckbox}
						handleSelectedChange={props.handleSelectedTypeChange}
					/>
				</div> */}
        <div className="mt-4">
          <label> Vendor </label>
          <div className="select-input h40">
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(node) => node.parentNode}
              style={{ width: 200 }}
              onChange={(e) => {
                props.handleChangeVendor(e);
              }}
            >
              {vendorList &&
                vendorList.map((list) => (
                  <Option value={list.vendorId}>{list.vendorName}</Option>
                ))}
            </Select>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-6" />
            <div className="col-6" style={{ paddingTop: "15px" }}>
              <ButtonComponent
                style={{ float: "right" }}
                buttonName="Filter"
                noValue
                onClick={(e) => {
                  e.preventDefault();

                  // this.setState({
                  //   page: 0,
                  // // });
                  props.searchTransactionByAll(
                    // transactionMediumHelper(props.isMediumCheck),
                    "CHECK",
                    "TRANSACTION_APPROVED",
                    // transactionTypeHelper(props.isTypeCheck, 'type'),
                    "DEPOSIT",
                    startDate,
                    endDate,
                    vendorToFilter
                  );
                  props.handleCloseSearchDialogue();
                }}
              >
                Filter
              </ButtonComponent>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default TransactionFilterForm;
