import { ACTION_TYPE_LOGIN } from "../constants/Type"

export const loginRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_LOGIN.LOGIN_REQUEST_MADE,
        bool
    };
};

export const handleChangeLogin = (id, value) => {
    return {
        type: ACTION_TYPE_LOGIN.HANDLE_LOGIN_CHANGE,
        id, value
    };
};

export const loginSuccess = (userData) => {
    return {
        type: ACTION_TYPE_LOGIN.LOGIN_SUCCESS,
        userData
    };
};

export const loginFailure = (error, form) => {
    return {
        type: ACTION_TYPE_LOGIN.LOGIN_FAILURE,
        error, form
    };
};

export const closeSnackBar = (bool) => {
    return {
        type: ACTION_TYPE_LOGIN.CLOSE_SNACK_BAR_LOGIN_PAGE,
        bool
    };
};
