import { connect } from 'react-redux';
import ClientCaseRegisterFormScreen from "../../screens/ClientCaseRegisterFormScreen";
import {
    caseTemplateRequestMade, closeSnackBarForRegisterCase,
    fetchCaseTemplateFailure,
    fetchCaseTemplateSuccess,
    fetchUserFailure,
    fetchUserSuccess,
    handleChangeCaseInRegisterCase, registerCaseFailure,
    registerCaseRequestMade, registerCaseSuccess,
    userRequestMade
} from "../../actions";
import {CaseTemplateAPI, RegisterCaseAPI, UserAPI} from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
   return{
       fetchCaseTemplateList: () => {
           dispatch(caseTemplateRequestMade(true));
           CaseTemplateAPI.fetchCaseTemplateList((response, error) => {
               if (response) {
                   dispatch(fetchCaseTemplateSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchCaseTemplateFailure(error.msg))
                   } else {
                       dispatch(fetchCaseTemplateFailure(error));
                   }
               }
           })
       },
       fetchUserList: () => {
           dispatch(userRequestMade(true));
           UserAPI.fetchUserList((response, error) => {
               if (response) {
                   dispatch(fetchUserSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchUserFailure(error.msg))
                   } else {
                       dispatch(fetchUserFailure(error));
                   }
               }
           })
       },
       handleChangeCaseInRegisterCase: (id, value) => dispatch(handleChangeCaseInRegisterCase(id, value)),
       registerCase: (caseData,props) => {
           dispatch(registerCaseRequestMade(true));
           RegisterCaseAPI.registerCase(caseData, (response, error) => {
               if (response) {
                   localStorage.setItem("clientDetailTabs","3");
                   props.history.push({
                       pathname: '/client/details/'+ caseData.clientId
                   })
                   dispatch(registerCaseSuccess(true));
               } else {
                   if (error.msg) {
                       dispatch(registerCaseFailure(error.msg));
                   } else {
                       dispatch(registerCaseFailure(error));
                   }
               }
           });
       },
       handleRegisterCaseSnackBarClose: () => dispatch(closeSnackBarForRegisterCase(true)),
   }
};


export const ClientCaseRegisterContainer = connect(mapStateToProps, mapDispatchToProps)(ClientCaseRegisterFormScreen);