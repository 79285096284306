import {connect} from 'react-redux';
import {
    addClientSuccess,
    clientRequestMade,
    closeSnackBarForClient,
    fetchClientFailure,
    fetchClientSuccess,
    fetchSingleClientFailure,
    handleChangeClient,
    searchClientFailInClientPage,
    searchClientSuccessInClientPage,
    setClientToEdit,
    updateClientFailure
} from "../../actions";
import {ClientAPI} from "../../api";
import ClientEditFormScreen from "../../screens/ClientEditFormScreen";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchClientList: () => {
            dispatch(clientRequestMade(true));
            ClientAPI.fetchClientList((response, error) => {
                if (response) {
                    dispatch(fetchClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientFailure(error.msg))
                    } else {
                        dispatch(fetchClientFailure(error));
                    }
                }
            })
        },
        getClientInfo: (clientId) => {
            dispatch(clientRequestMade(true));
            ClientAPI.fetchSingleClient(clientId, (response, error) => {
                if (response) {
                    dispatch(setClientToEdit(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchSingleClientFailure(error.msg));
                    } else {
                        dispatch(fetchSingleClientFailure(error));
                    }
                }
            })
        },
        searchClient: (value) => {
            ClientAPI.searchClient(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchClientFailInClientPage(error.msg))
                    } else {
                        dispatch(searchClientFailInClientPage(error));
                    }
                } else {
                    dispatch(searchClientSuccessInClientPage(response));
                }
            })
        },
        handleChangeClient: (id, value) => dispatch(handleChangeClient(id, value)),
        editClient: (clientData, props) => {
            dispatch(clientRequestMade(true));
            ClientAPI.editClient(clientData, (response, error) => {
                if (response) {
                    dispatch(addClientSuccess(true));
                    props.history.replace('/client')
                } else {
                    if (error.msg) {
                        dispatch(updateClientFailure(error.msg));
                    } else {
                        dispatch(updateClientFailure(error));
                    }
                }
            });
        },
        setClientData: (clientData) => {
            dispatch(setClientToEdit(clientData))
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForClient(true)),
    };
};


export const ClientEditFormContainer = connect(mapStateToProps, mapDispatchToProps)(ClientEditFormScreen);