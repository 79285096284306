import React, { Component } from "react";
import {Link} from "react-router-dom";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
// import CreditCardInput from 'react-credit-card-input';

class Payment extends Component {
    componentWillUnmount(){
        this.props.setClientContent("")
    }

    render() {
        return (
            <div style={style.container}>
                <div className="card">

                    <div className="card-header" style={style.cardHeader}>
                        <div style={style.headerColumn1}>
                            <h5 style={style.paymentText}> Payment </h5>
                        </div>
                    </div>

                    <div className="card-body" style={style.cardBody}>
                        <div className="row" style={style.row}>
                            <div className="column" style={style.column1}>

                                <div>
                                    <h6 style={style.columnHeading}>Add credit card details</h6>
                                </div>

                                <div className={"row"}>
                                        <label htmlFor="name" style={style.label}>Name On Card</label>
                                        <input
                                            type='text'
                                            id="text"
                                            style={style.inputField}
                                        />
                                </div>
                                {/*<CreditCardInput*/}
                                {/*/>*/}
                                <div className={"row"} style={{marginTop:'10px',marginBottom:'10px'}}>
                                <label htmlFor="name" style={style.label}>Card Number</label>
                                    <input
                                        type='number'
                                        placeholder='0000 0000 0000 0000'
                                        id="text"
                                        style={style.inputField}
                                    />
                                </div>
                                <div className={"row"} style={{width:'73.5%'}}>
                                    <div className='col-6' style={{padding:0}}>
                                        <label style={style.label}>Expiry Date <span style={{color:'#a2a2a2', fontSize:'14px', paddingLeft:'3px'}}>(month - year)</span></label>
                                        <div className="row" style={{paddingLeft:'15px'}}>
                                            <div className="col-3" style={style.select}>
                                                <select
                                                    placeholder="mm"
                                                    className="custom-select"
                                                    style={style.dropdownInput}
                                                >
                                                    <option value hidden />
                                                    <option value = '01'> 01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                    <option>06</option>
                                                    <option>07</option>
                                                    <option>08</option>
                                                    <option>09</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </select>
                                                <ExpandMoreOutlinedIcon style={style.expandMoreIcon} />
                                            </div>
                                            <span style={{padding:'0.5rem'}} />
                                            <div className="col-7" style={style.selectYear}>
                                                <select
                                                    className="custom-select"
                                                    style={style.dropdownInput}
                                                >
                                                    <option value hidden />
                                                    <option>2010</option>
                                                    <option>2011</option>
                                                    <option>2012</option>
                                                    <option>2013</option>
                                                    <option>2014</option>
                                                    <option>2015</option>
                                                    <option>2016</option>
                                                    <option>2017</option>
                                                    <option>2018</option>
                                                    <option>2019</option>
                                                    <option>2020</option>
                                                    <option>2021</option>
                                                    <option>2022</option>
                                                    <option>2023</option>
                                                    <option>2024</option>
                                                    <option>2025</option>
                                                    <option>2026</option>
                                                    <option>2027</option>
                                                    <option>2028</option>
                                                    <option>2029</option>
                                                    <option>2030</option>
                                                </select>
                                                <ExpandMoreOutlinedIcon style={style.expandMoreIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6' style={{padding:0}}>
                                        <label style={style.label}>CVC/CCV</label>
                                        <input type='number'
                                               placeholder='000'
                                               style={style.inputFieldCCv} />
                                    </div>
                                </div>
                                <div className={"row"} style={{justifyContent:'flex-end',width:'62%',paddingBottom:0}}>
                                    <div>
                                        <Link to={'/case'}>
                                        <button
                                            className="btn btn-primary"
                                            style={style.skipButton}
                                        >Skip
                                        </button></Link>
                                    </div>
                                    <div>
                                    <button
                                        className="btn btn-primary"
                                        style={style.nextButton}
                                        onClick={this.props.handleNext}>Save
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const style = {
    container: {
        background: '#ffffff',
        marginLeft: '3% !important',
        marginRight: '3% !important',
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    },
    cardBody: {
        paddingLeft: '4%',
        paddingRight: '2%',
        paddingBottom: '1rem'
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '4%',
        height: '64px'
    },
    headerColumn1: {
        paddingTop: '5px',
        flex: '68%',
        marginleft: '10px !important'
    },
    paymentText: {
        color: '#cc6600',
        fontSize: '22px',
        fontWeight: 'bolder',
        paddingTop: '15px'
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '5px 5px'
    },
    columnHeading: {
        color: '#625F5F',
        fontSize: '18px',
        fontWeight: '550'
    },
    column1: {
        flex: '60%',
        padding: '0 6px',
        paddingRight: '20px'
    },
    column2: {
        flex: '35%',
        padding: '0 6px',
        marginRight: '1.5rem'
    },
    label: {
        color: '#625F5F',
        marginBottom: '10px',
        fontSize: '16px',
        width: '100%',
        marginLeft: '1rem',
        marginTop: '1rem'
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '60%',
        paddingTop: '0px',
        marginTop: '0px',
        paddingLeft: '15px',
        marginLeft: '1rem',
    },
    inputFieldCCv: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '67%',
        paddingTop: '0px',
        marginTop: '0px',
        paddingLeft: '15px',
        marginLeft: '1rem',
    },
    inputFieldDate: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        paddingTop: '0px',
        marginTop: '0px',
        paddingLeft: '15px',
        marginLeft: '1rem',
    },
    paymentMethod: {
        padding: '1rem',
        marginLeft: '1rem',
        fontSize: '18px',
        color: '#696767'
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        float: 'right',
        width: '120px',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '31px'
    },
    skipButton: {
        float: 'right',
        color: '#8BC83F',
        border: 'none',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '31px',
        marginRight: '40px',
        background: 'none',
        height: '44px',
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    expandMoreIcon: {
        position: "absolute",
        right: "20px",
        top: "8px",
        color: "#C8C5C5",
        fontSize: "35px"
    },
    select: {
        position: "relative",
        height: "40px",
        width:'40%',
    },
    selectYear: {
        position: "relative",
        height: "40px",
        width:'50%',
    },
};

export default Payment;