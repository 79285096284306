import React, {Component} from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Button, DialogActions, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {AutoComplete, DatePicker, Select, Space} from "antd";
import TablePagination from "@material-ui/core/TablePagination";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DialogContentText from "@material-ui/core/DialogContentText";
import MySnackbarContentWrapper from "../components/Snackbar";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import OpenAddressFields from "../components/DynamicAddressField";
import {MinusCircleOutlined} from "@ant-design/icons";
import Helper from "../components/Helper";
import Helpers from "../components/Helper";
import {HelmetComponent} from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";

const {Option, OptGroup} = Select;

class CaseContactScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeleteContactDialogue: false,
            openDropdownForContact: false,
            showDropdown: false,
            contacts: [],
            visible: false,
            selectedData: {},
            contactNameValue: "",
            singleId: '',
            optionsArr: [],
            selectedId: "",
            clicked: false,
            address:[],
            updatedAddress: [],
            addressId: "",
            openDeleteModal: false,
            errors:{
                name: "",
                emailPhone: "",
                dob:"",
                contactNumber:"",
                fullName:'',
            }
        }
    }

    componentDidMount() {
        this.props.fetchCaseContact(this.props.caseId);
        this.props.fetchRelationshipList();
        this.props.fetchCompanyType();
    }

    componentWillUnmount() {
        this.setState({
            changed: false,
        })
    }

    render() {
        const {errors}  = this.state;
        const caseState = this.props.propsState && this.props.propsState.caseState;
        const loading = caseState && caseState.loading;
        //*****************address data******************** //
        const showInputField = () => {
            let a = createAddressObject();
            this.setState({
                address: [...this.state.address, a]
            });
        };
        const createAddressObject = () => {
            return {
                addressId: String(Math.random()),
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip5: "",
            };
        };

        const filterAddressData = () => {
            let addressForm = [];
            this.state.address.map((single) => {
                const data = {
                    address1: single.address1,
                    address2: single.address2,
                    city: single.city,
                    state: single.state,
                    zip5: single.zip5,
                }
                return (
                    addressForm.push(data)
                )
            });
            this.props.handleChangeCaseContactInput("address", addressForm);
        };
        const filterAddressDataForCompany = () => {
            let addressForm = [];
            this.state.address.map((single) => {
                const data = {
                    address1: single.address1,
                    address2: single.address2,
                    city: single.city,
                    state: single.state,
                    zip5: single.zip5,
                }
                return (
                    addressForm.push(data)
                )
            });
            this.props.handleChangeCaseCompanyInput("address", addressForm);
        };


        const handleOpenDeleteDialogue = addressId => {
            this.setState({
                openDeleteModal: true,
                addressId: addressId
            });
        };
        const handleCloseDeleteDialogue = () => {
            this.setState({
                openDeleteModal: false,
                addressId: ""
            });
        };
        const handleOkDeleteDialogue = () => {
            closeFieldSection(this.state.addressId);
            this.setState({
                openDeleteModal: false,
                addressId: ""
            });
        };
        const closeFieldSection = key => {
            var removedActionData = this.state.address.filter(single => single.addressId !== key);
            this.setState({
                address: removedActionData
            }, () => {
                filterAddressData()
            })
        };
        const handleChangeDateOfBirth = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                const eventValue = {
                    target : {
                        name : "dob",
                        value: new Date(valueOfInput1).getTime(),
                    }
                }
                Helpers.handleValidationOnChange(eventValue, errors);
                this.props.handleChangeCaseContactInput("dob", new Date(valueOfInput1).getTime());
            } else if (range === null) {
                this.props.handleChangeCaseContactInput("dob", new Date(0).getTime());
            }
        }
        const handleClickOpenDeleteContactDialogue = contactId => {
            this.setState({
                openDeleteContactDialogue: true,
                contactId: contactId
            });
        };
        const handleCloseDeleteContactDialogue = () => {
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };
        const handleOkDeleteContactDialogue = () => {
            closeContactFieldSection(this.state.contactId);
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };

        const createAddContactObject = () => {
            let formData = {
                id: String(Math.random()),
                value: {
                    name: '',
                    emailPhone: '',
                    contactType: 'UNKNOWN_CONTACT_TYPE',
                    address: '',
                    phone: '',
                }
            };
            return formData;
        }
        const showInputForField = () => {
            let a = createAddContactObject();
            this.setState({
                openDropdownForContact: true,
                contacts: [...this.state.contacts, a],
            });
        };

        const changeContactFieldNameValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.name = value;
            filterContactData()
        };

        const changeContactFieldEmailValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.emailPhone = value;
            filterContactData()
        };

        const changeContactFieldTypeValue = (key, value) => {
            if (value === "") {
                this.state.contacts.filter(single => single.id === key)[0].value.contactType = "UNKNOWN_CONTACT_TYPE";
            } else {
                this.state.contacts.filter(single => single.id === key)[0].value.contactType = value;
            }
            filterContactData()
        };
        const changeContactFieldAddressValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.address = value;
            filterContactData()
        };
        const changeContactFieldPhoneValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.phone = value;
            filterContactData()
        };
        //optional
        const changeContactFieldId = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.contactId = value;
            filterContactData()
        };

        const filterContactData = () => {
            let contactForm = [];
            this.state.contacts.map((single) => {
                return (
                    contactForm.push(single.value)
                )
            });
            this.props.handleChangeCaseCompanyInput("contact", contactForm)
        };

        const closeContactFieldSection = key => {
            var removedActionData = this.state.contacts.filter(single => single.id !== key);
            this.setState({
                contacts: removedActionData
            }, () => {
                filterContactData()
            })
        };

        const onSearchFunction = (event) => {
            if (!event) {
                this.setState({
                    optionsArr: []
                })
            } else {
                this.props.searchContact(event);
                this.setState({
                    optionsArr:
                        contactsList && contactsList.map(single => {
                            const value = {
                                value: single.name + " (" + single.emailPhone + " )",
                                single: single,
                            }
                            return value
                        })
                })
            }
        }
        const openFieldForContact = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.contacts.length > 0) {
                div.push(<>
                    <h5 style={{
                        width: '100%',
                        color: '#8BC83F',
                        fontWeight: '550'
                    }}>List of contacts</h5>
                    <Space
                        style={{ display: "flex" }}
                        align="baseline"
                    >
                        <div style={{width: "200px"}}>
                            <label htmlFor="name" style={{marginBottom: "0px" }}>Name
                                <span style={{color: "red"}} > *</span></label>
                        </div>
                        <div style={{width: "182px"}}>
                            <label htmlFor="emailPhone" style={{marginBottom: "0px" }}>Email
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div style={{width: "200px"}} >
                            <label htmlFor="contactType" style={{marginBottom: "0px" }}>Contact Type
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div >
                            <label htmlFor="phone" style={{marginBottom: "0px" }}>Phone Number</label>
                        </div>
                    </Space>
                </>)
            }
            this.state.contacts.map(single => {
                return div.push(
                    <>
                        <Space
                            key={single.id}
                            style={{ display: "flex" }}
                            align="baseline"
                        >
                            <div style={{width: "200px"}}>
                                <div className="auto-comp-select-contact h40">
                                    <div>
                                        <AutoComplete
                                            style={{marginTop: "8px"}}
                                            getPopupContainer={node => node.parentNode}
                                            dropdownClassName="drop"
                                            dropdownMatchSelectWidth
                                            onSearch={onSearchFunction}
                                            options={this.state.optionsArr}
                                            value={this.state.contactNameValue !== ''
                                            && this.state.selectedId === single.id ?
                                                this.state.contactNameValue
                                                : single.value.name}
                                            onSelect={(e, options) => {
                                                this.setState({
                                                    contactNameValue: e.split('(')[0],
                                                    selectedData: options.single,
                                                    selectedId: single.id,
                                                })
                                                // changeContactFieldNameValue(single.id, e);
                                                changeContactFieldEmailValue(single.id, options.single.emailPhone);
                                                changeContactFieldId(single.id, options.single.contactId);
                                                changeContactFieldAddressValue(single.id, options.single.address);
                                                changeContactFieldPhoneValue(single.id, options.single.phone);
                                                changeContactFieldTypeValue(single.id, options.single.contactType);
                                                changeContactFieldNameValue(single.id, options.single.name);
                                            }}
                                            allowClear
                                            filterOption={(inputValue, option) =>
                                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            onChange={(e) => {
                                                changeContactFieldNameValue(single.id, e);
                                                this.setState({
                                                    contactNameValue: "",
                                                }, () => {
                                                    if (this.state.contactNameValue === "") {
                                                        changeContactFieldEmailValue(single.id, "");
                                                        changeContactFieldAddressValue(single.id, "");
                                                        changeContactFieldPhoneValue(single.id, "");
                                                        changeContactFieldTypeValue(single.id, "");
                                                    }
                                                })

                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <input
                                    id={single.id}
                                    className="inputField"
                                    value={this.state.contactNameValue !== ''
                                    && this.state.selectedId === single.id ? this.state.selectedData.emailPhone
                                        : single.value.emailPhone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldEmailValue(e.target.id, e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <div style={{width: "200px"}}>
                                    <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                        <Select
                                            id={single.id}
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            value={this.state.contactNameValue !== '' &&
                                            this.state.selectedId === single.id
                                                ? this.state.selectedData.contactType
                                                === "UNKNOWN_CONTACT_TYPE" ? " " :
                                                    this.state.selectedData.contactType
                                                : single.value.contactType === "UNKNOWN_CONTACT_TYPE" ?
                                                 " " : single.value.contactType}
                                            onChange={e => {
                                                this.setState({isBlocking: true});
                                                changeContactFieldTypeValue(single.id, e)
                                            }}
                                        >
                                            <Option value="UNKNOWN_CONTACT_TYPE" hidden/>
                                            <Option value="EVERFUND_USER_CONTACT">Everfund user</Option>
                                            <Option value="CORPORATE_CONTACT">Corporate</Option>
                                            <Option value="CLIENT_CONTACT">Client</Option>
                                            <Option value="CLIENT_RELATIVE_CONTACT">Client Relative</Option>
                                            <Option value="OTHER_CONTACT_TYPE">Other contact type</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <input
                                    id={single.id}
                                    value={this.state.contactNameValue !== ''
                                    && this.state.selectedData.contactId === single.value.contactId ? this.state.selectedData.phone
                                        : single.value.phone}
                                    className="inputField"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldPhoneValue(e.target.id, e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <MinusCircleOutlined
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        handleClickOpenDeleteContactDialogue(single.id);
                                    }}
                                />
                            </div>
                        </Space>
                    </>
                );
            });
            return div;
        };

        const handleChangeSelect = (value) => {
            this.props.handleChangeCaseContactInput("contactType", value)
        }

        const contactState = this.props.propsState && this.props.propsState.contactState;
        const caseContactState = this.props.propsState && this.props.propsState.caseState;
        const caseContactList = caseContactState && caseContactState.caseContactList;
        const individualContact = caseContactState && caseContactState.individualContact;
        const relationshipId = caseContactState && caseContactState.relationshipId;
        const contactsList = contactState && contactState.contactList;
        const companyTypeList = contactState && contactState.companyTypeList;
        const relationShipList = contactState && contactState.relationShipList;
        const caseContactData = {
            caseId: this.props.caseId,
            relationshipId: relationshipId,
            contact: individualContact
        }
        const contactValue =  caseContactData.relationshipId !== "" && caseContactData.contact ? caseContactData.contact.name !== "" &&
            caseContactData.contact.emailPhone !== "" &&  caseContactData.contact.contactType !== "" : null;


        const companyData = caseContactState && caseContactState.caseCompanyData;
        const caseCompanyData = {
            caseId: this.props.caseId,
            relationshipId: relationshipId,
            company: companyData
        }

        const companyDataValue = relationshipId !== "" && companyData &&  companyData.companyName !== "" && companyData.email !== ""
            && companyData.phone !== "" && companyData.companyTypeId !== "" ;
        const existingContacts = caseContactState && caseContactState.existingContacts;
        const refId = caseContactState && caseContactState.refId;

        const existingContactData = {
            caseId: this.props.caseId,
            relationshipId: relationshipId,
            refId: refId
        }
        const existingValue = relationshipId !== "" && refId !== "";
        const addCaseContact = () => {
            if (!this.state.clicked) {
                this.setState({
                    clicked: true
                })
                this.props.addIndividualCaseContact(caseContactData);
                setTimeout(() => {
                    this.setState({
                        clicked: false
                    })
                }, 3000);
            }
        }
        const addCaseCompanyContact = () => {
            if (!this.state.clicked) {
                this.setState({
                    clicked: true
                })
                this.props.addCaseCompany(caseCompanyData);
                setTimeout(() => {
                    this.setState({
                        clicked: false
                    })
                }, 3000);
            }
        }
        const addCaseExistingContact = () => {
            if (!this.state.clicked) {
                this.setState({
                    clicked: true
                })
                this.props.addExistingCaseContact(existingContactData);
                setTimeout(() => {
                    this.setState({
                        clicked: false
                    })
                }, 3000);
            }
        }

        return (
            <React.Fragment>
                <HelmetComponent title="Case Contact"/>
                <div>
                    <div className={"d-flex flex-row-reverse div-flex"} style={{paddingTop: "0px"}}>
                        <div>
                            <button
                                className="addButton"
                                style={{width: '140px'}}
                                onClick={(e) => {
                                    this.setState({
                                        openDropdownForContact: false,
                                        contacts: [],
                                        errors:{
                                            name: "",
                                            emailPhone: "",
                                            dob:"",
                                            contactNumber:"",
                                        }
                                    })
                                    e.preventDefault();
                                    this.props.handleOpenContactModal();
                                }}>
                                <AddIcon/> Add Contact
                            </button>
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader" style={{width: "410px"}}>
                                            Full Name
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Group
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Relationship
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Email
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Phone Number
                                        </TableCell>
                                        {/*<TableCell className="tableHeader">*/}
                                        {/*    Address*/}
                                        {/*</TableCell>*/}
                                    </TableRow>
                                </TableHead>
                                {
                                    caseContactList && caseContactList.slice(
                                        this.props.page * this.props.rowsPerPage,
                                        this.props.page * this.props.rowsPerPage +
                                        this.props.rowsPerPage
                                    ).map(list => (

                                        <TableBody key={list.caseContactId}>
                                            <TableRow>
                                                <TableCell className="first-tableBody">
                                                    {list.company.companyName || list.contact.name}
                                                </TableCell>
                                                <TableCell className="tableBody">
                                                    {list.caseContactType}
                                                </TableCell>
                                                <TableCell className="tableBody">
                                                    {list.relationship
                                                    && list.relationship.name }
                                                </TableCell>
                                                <TableCell className="tableBody">
                                                    {list.contact.emailPhone || list.company.email}
                                                </TableCell>
                                                <TableCell className="tableBody">
                                                    {list.contact.phone || list.company.phone   ? list.contact.phone || list.company.phone : '-'}
                                                </TableCell>
                                                {/*<TableCell className="tableBody">*/}
                                                {/*    {list.contact ? list.contact.address ? list.contact.address : "-" :*/}
                                                {/*        list.company.address ? list.company.address : "-"}*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                        </TableBody>))
                                }
                            </Table>
                            {caseContactList && caseContactList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            caseContactList.length > 0
                                                ? caseContactList.length
                                                : 100
                                        }
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={this.props.handleChangePage}
                                        onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        </TableContainer>
                    </div>
                </div>
                {/*modal to add contact*/}
                <Dialog
                    open={this.props.openModalToAddContact}
                    onClose={this.props.handleChangeCloseModalToAddContact}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToAddContact}>
                            Add Contact
                        </DialogTitleComponent>
                        <DialogContent dividers style={{paddingTop: "5px"}} className="dialogContentWidth850">
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Full Name <span style={{color: "red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="name"
                                        name="name"
                                        className="inputField"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helper.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCaseContactInput(e.target.id, e.target.value)
                                        }}
                                    />
                                    {errors.name.length > 0 &&
                                    <span className="error">{errors.name}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Email <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="emailPhone"
                                        name="emailPhone"
                                        className="inputField"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helper.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCaseContactInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.emailPhone.length > 0 &&
                                    <span className="error">{errors.emailPhone}</span>}
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: "10px"}}>
                                <div className="col-6">
                                    <label className="label">Relationship <span style={{color: "red"}}>*</span></label>
                                    <div className="selects h40">
                                        <Select
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.props.handleChangeRelationshipName(e);
                                            }}
                                        >
                                            {
                                                relationShipList && relationShipList.map(relationship =>
                                                    <Option
                                                        value={relationship.relationshipId}>{relationship.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className="label">Contact Type <span style={{color: "red"}}>*</span></label>
                                    <div className="selects h40">
                                        <Select
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                handleChangeSelect(e);
                                            }}
                                        >
                                            <Option value="EVERFUND_USER_CONTACT">Everfund user</Option>
                                            <Option value="CORPORATE_CONTACT">Corporate</Option>
                                            <Option value="CLIENT_CONTACT">Client</Option>
                                            <Option value="CLIENT_RELATIVE_CONTACT">Client Relative</Option>
                                            <Option value="OTHER_CONTACT_TYPE">Other contact type</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Phone number</label>
                                    <input
                                        id="phone"
                                        className="inputField"
                                        autoComplete='off'
                                        name="contactNumber"
                                        onChange={(e) => {
                                            Helper.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCaseContactInput(e.target.id, e.target.value)
                                        }}
                                    />
                                    {errors.contactNumber.length > 0 &&
                                    <span className="error">{errors.contactNumber}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Date-of-birth</label>
                                    <div className="select-contact-date-of-birth" style={style.searchDate}>
                                        <DatePicker
                                            className="rangeBase"
                                            format="MM-DD-YYYY"
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={handleChangeDateOfBirth}
                                        />
                                    </div>
                                    {errors.dob.length > 0 && <span className="error">{errors.dob}</span>}
                                </div>
                            </div>
                            {!(this.state.address.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '15px'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showInputField();
                                     }}>
                                    <img src="/assets/images/add-button-inside-circle.svg"
                                         alt="Add Button"
                                         className="addButtonIcon"/>
                                    <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Address</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    <OpenAddressFields
                                        fieldArray={this.state.address}
                                        filterAddressData={filterAddressData}
                                        handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                                    />
                                    <div style={{
                                        display: 'block',
                                        marginTop: '1rem'
                                    }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  this.setState({isBlocking: false});
                                                  showInputField();

                                              }}>
                                            {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                                            {/*     alt="Add Button"*/}
                                            {/*     className="addButtonIcon"/>*/}
                                            <span
                                                // style={{fontSize: '14px', paddingLeft: '4px'}}
                                                style={{ fontSize: '16px',
                                                    color: "#81b447"}}>+ Add More</span>
                                        </span>
                                    </div>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 10px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    addCaseContact();
                                }}
                                value={contactValue}
                                loading={loading}
                                buttonName="Add"
                            />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*DELETE CONTACT/COMPANY ADDRESS*/}
                <Dialog
                    open={this.state.openDeleteModal}
                    onClose={handleCloseDeleteDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent className="dialogContentForDeleteModal">
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete address?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteDialogue}
                                            className="cancelButton"
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteDialogue();
                                            }}
                                            className="deleteOkButton"
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*modal to add company*/}
                <Dialog
                    open={this.props.openModalToAddCompany}
                    onClose={this.props.handleChangeCloseModalToAddCompany}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToAddCompany}>
                            Add Company
                        </DialogTitleComponent>
                        <DialogContent dividers
                                       style={{paddingTop: "5px", maxHeight: "575px", borderBottom: "none"}}
                                       className="dialogContentWidth850">
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Name <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="companyName"
                                        name="name"
                                        className="inputField"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helper.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCaseCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.name.length > 0 && <span className="error">{errors.name}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Email <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="email"
                                        name="emailPhone"
                                        className="inputField"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helper.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCaseCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.emailPhone.length > 0 && <span className="error">{errors.emailPhone}</span>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Phone number <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="phone"
                                        className="inputField"
                                        name="contactNumber"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helper.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCaseCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.contactNumber.length > 0 && <span className="error">{errors.contactNumber}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Relationship <span style={{color: "red"}}>*</span></label>
                                    <div className="selects h40">
                                        <Select
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.props.handleChangeRelationshipName(e);
                                            }}
                                        >
                                            {
                                                relationShipList && relationShipList.map(relationship =>
                                                    <Option value={relationship.relationshipId}>
                                                        {relationship.name}
                                                    </Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label className="label">Company Type
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="selects h40">
                                        <Select
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.props.handleChangeCaseCompanyInput("companyTypeId", e);
                                            }}
                                        >
                                            {
                                                companyTypeList && companyTypeList.map(companyType => (
                                                    <Option
                                                        value={companyType.companyTypeId}>{companyType.companyTypeName}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                </div>
                                {/*<div className="col-6">*/}
                                {/*    <label className="label">Address </label>*/}
                                {/*    <input*/}
                                {/*        type='text'*/}
                                {/*        id="address"*/}
                                {/*        className="input"*/}
                                {/*        autoComplete='off'*/}
                                {/*        onChange={(e) => {*/}
                                {/*            this.props.handleChangeCaseCompanyInput(e.target.id, e.target.value);*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                            {/* add address*/}
                            {!(this.state.address.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '15px'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showInputField();
                                     }}>
                                    <img src="/assets/images/add-button-inside-circle.svg"
                                         alt="Add Button"
                                         className="addButtonIcon"/>
                                    <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Address</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    <OpenAddressFields
                                        fieldArray={this.state.address}
                                        filterAddressData={filterAddressDataForCompany}
                                        handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                                    />
                                    <div style={{
                                        display: 'block',
                                        marginTop: '1rem'
                                    }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  this.setState({isBlocking: false});
                                                  showInputField();

                                              }}>
                                            {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                                            {/*     alt="Add Button"*/}
                                            {/*     className="addButtonIcon"/>*/}
                                            <span
                                                // style={{fontSize: '14px', paddingLeft: '4px'}}
                                                style={{ fontSize: '16px',
                                                    color: "#81b447"}}>+ Add More</span>
                                        </span>
                                    </div>
                                </div>
                            }
                            {!(this.state.contacts.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '1.5rem'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showInputForField();
                                     }}>
                                    <img
                                        src="/assets/images/add-button-inside-circle.svg"
                                        alt="Add Button"
                                        className="addButtonIcon"
                                    />
                                    <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Contact</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    {openFieldForContact()}
                                    <div style={{marginRight: "12px", marginTop: "1rem"}}>
                                            <span style={{cursor: 'pointer'}}
                                                  onClick={e => {
                                                      e.preventDefault();
                                                      this.setState({isBlocking: false, contactNameValue: ''});
                                                      showInputForField();


                                                  }}>
                                 {/*<img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"*/}
                                 {/*     className="addButtonIcon"/>*/}
                                    <span style={{ fontSize: '16px',
                                        color: "#81b447"}}>+ Add More</span>
                                </span>
                                    </div>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 10px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    addCaseCompanyContact();
                                }}
                                loading={loading}
                                value={companyDataValue}
                                buttonName="Add"
                            />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*modal to add existing contact*/}
                <Dialog
                    open={this.props.openModalToAddExistingContact}
                    onClose={this.props.handleChangeCloseModalToAddExistingContact}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToAddExistingContact}>
                            Add Existing Contact
                        </DialogTitleComponent>
                        <DialogContent dividers style={{paddingTop: "5px", minHeight: "180px"}} className="dialogContentBig">
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Name <span style={{color: "red"}}>*</span></label>
                                    <div className="select-input h40">
                                        <Select
                                            showSearch
                                            getPopupContainer={node => node.parentNode}
                                            style={{width: 200}}
                                            onSearch={(value) => {
                                                this.props.searchExistingContact(value);
                                            }}
                                            onInputKeyDown={() => {
                                                this.setState({
                                                    showDropdown: true,
                                                })
                                            }}
                                            className="drop"
                                            onChange={(e) => {
                                                this.props.handleChangeExistingContact(e)
                                            }}
                                            onSelect={() => {
                                                this.setState({
                                                    showDropdown: false,
                                                })
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    showDropdown: false,
                                                })
                                            }}
                                            filterOption={(input, option) =>
                                                option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            defaultOpen={false}
                                            dropdownStyle={this.state.showDropdown?
                                                {display: 'block',maxHeight: "70px"}
                                                :{display:'none'}}
                                        >
                                            {
                                                (existingContacts && existingContacts.contacts !== undefined
                                                    || existingContacts && existingContacts.companies !== undefined) ?
                                                    <>
                                                        {
                                                            <OptGroup label="Contacts">
                                                                {
                                                                    existingContacts && existingContacts.contacts &&
                                                                    existingContacts.contacts.map(contact =>
                                                                        <Option
                                                                            value={contact.contactId}>{contact.name}</Option>
                                                                    )
                                                                }
                                                            </OptGroup>
                                                        }
                                                        {
                                                            <OptGroup label="Companies" style={{background: "red"}}>
                                                                {existingContacts && existingContacts.companies &&
                                                                existingContacts.companies.map(company =>
                                                                    <Option
                                                                        value={company.companyId}>{company.companyName}</Option>)}
                                                            </OptGroup>
                                                        }
                                                    </> : null
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            {refId ? <div className="row">
                                <div className="col-12">
                                    <label className="label">Relationship <span style={{color: "red"}}>*</span></label>
                                    <div className="selects h40">
                                        <Select
                                            showSearch={false}
                                            className="down"
                                            dropdownStyle={{maxHeight: '70px'}}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.props.handleChangeRelationshipName(e);
                                            }}
                                        >
                                            {
                                                relationShipList && relationShipList.map(relationship =>
                                                    <Option
                                                        value={relationship.relationshipId}>{relationship.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div> : null}
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "28px"}}>
                                    <ButtonComponent
                                        style={{float: "right"}}
                                        onClick={e => {
                                            e.preventDefault();
                                            addCaseExistingContact();
                                            // this.props.handleChangeCloseModalToAddExistingContact();
                                            // this.props.addExistingCaseContact(existingContactData);
                                        }}
                                        value={existingValue}
                                        loading={loading}
                                        buttonName="Add"
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*modal to show add contact option  */}
                <Dialog
                    open={this.props.openContactModal}
                    onClose={this.props.handleCloseContactModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            style={{textAlign: "center"}}
                            onClose={this.props.handleCloseContactModal}>
                            Add Contacts
                        </DialogTitleComponent>
                        <DialogContent className="dialogContentBig">
                            <div className="row" style={{margin: "20px -4px"}}>
                                <div className="col-4">
                                    <div className="modal-div" onClick={() => {
                                        this.props.handleChangeOpenModalToAddContact();
                                        this.props.handleCloseContactModal();
                                        this.setState({
                                            address: [],
                                        })
                                    }}>
                                        <img src="/assets/images/individual.png" style={{width: "56px"}}/>
                                        <div style={{textAlign: "center", marginTop: "6px"}}>Individual Contact</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="modal-div"
                                         onClick={() => {
                                             this.props.handleChangeOpenModalToAddCompany();
                                             this.props.handleCloseContactModal();
                                             this.setState({
                                                 address: [],
                                             })
                                         }}>
                                        <img src="/assets/images/company.png" style={{width: "60px"}}/>
                                        <div style={{textAlign: "center"}}>Add Company</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="modal-div"
                                         onClick={() => {
                                             this.props.handleChangeOpenModalToAddExistingContact();
                                             this.props.handleCloseContactModal();
                                         }}>
                                        <img src="/assets/images/contact.png" style={{width: "60px"}}/>
                                        <div style={{textAlign: "center"}}>Existing Contact</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row"/>
                            <div
                                hidden
                                className="row"
                                style={{
                                    padding: '2.5rem 0px 1.5rem',
                                    width: "65%",
                                    float: "right",
                                }}
                            >
                                <div className="col-6">
                                </div>
                                <div className={"col-6"}>
                                    <Button
                                        onClick={e => {
                                            e.preventDefault();
                                            this.props.handleCloseContactModal()
                                        }}
                                        className="cancelButton"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*DELETE COMPANY's CONTACT*/}
                <Dialog
                    open={this.state.openDeleteContactDialogue}
                    onClose={handleCloseDeleteContactDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{background: "#ffffff", borderRadius: "18px"}}>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px'
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete contact?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteContactDialogue}
                                            className="cancelButton"
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteContactDialogue();
                                            }}
                                            className="addUserButton"
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={caseContactState.openCaseContactSuccess}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="success"
                        message={caseContactState && caseContactState.successMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={caseContactState.openCaseContactError}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={caseContactState.errorMsgForCase}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
}

const style = {
    card: {
        background: "rgb(255, 255, 255)",
        border: "1px solid rgb(233, 233, 233)",
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "1rem",
    },
    inputFieldContact: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '10px',
        marginBottom: '15px',
        paddingLeft: '13px'
    },
    columnHeadingContact: {
        color: '#625F5F',
        fontSize: '16px',
        width: '100%',
        marginBottom: 0
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    select: {
        position: "relative",
        height: "40px",
        marginTop: '6px',
    },
    expandMoreIconContact: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    searchDate: {
        position: 'relative',
        backgroundColor: "#F4F5F9",
        marginRight: '1.2rem',
        marginTop: '4px',
        width: '213px',
        height: '40px',
    },
}
export default CaseContactScreen;
