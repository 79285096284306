import {ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE} from "../constants/Type";

const initialState = {
    previousValue: false,
    loading: false,
    error: "",
    actions: {},
    dynamicActionData:[],
    openDynamicActionClientSnackBar:false,
    openDynamicActionClientSnackBarForSuccess: false,
    hasError: false,
    fileName:'',
    showSuccessMessage: false,
    previousActionDataByClient: false,
    conditionalFormDataArray: [],
    conditionalFormSuccessForFirst: false,
    conditionalFormSuccess:false,
    conditionalFormCaseData:[],
    openClientConditionalFormModal: false,
    previousActionData : true,
};

const dynamicActionClientState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.DYNAMIC_ACTION_CLIENT_PAGE_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.VALIDATE_URL_LINK_KEY_SUCCESS:
            const formData = action.response.action && action.response.action.actionForms;
            return {
                ...state,
                linkKey: action.response.shareLink.linkKey,
                actions: action.response.action,
                loading: false,
                hasError: false,
                previousActionDataByClient: formData && formData.some(option => option.actionFormData),
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.VALIDATE_URL_LINK_KEY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                hasError: true,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.SET_PREVIOUS_VALUE_TRUE:
            return {
                ...state,
                previousValue: action.bool
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_CLIENT_ACTION_DATA:
            return {
                ...state,
                dynamicActionData: action.actionFormData,
                loading: false
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.CLOSE_SNACK_BAR_DYNAMIC_ACTION_CLIENT_PAGE:
            return {
                ...state,
                openDynamicActionClientSnackBar: false,
                openDynamicActionClientSnackBarForSuccess: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.SEND_ACTION_DATA_SUCCESS:
            return {
                ...state,
                addDynamicActionSuccess: action.bool,
                loading: false,
                openDynamicActionClientSnackBar: false,
                dynamicActionData: [],
                openDynamicActionClientSnackBarForSuccess: true,
                showSuccessMessage: true,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.SEND_ACTION_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                openDynamicActionClientSnackBarForSuccess: false,
                openDynamicActionClientSnackBar: true,
                showSuccessMessage: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.UPLOAD_FILE_SUCCESS:
            const id = action.file.actionFormId;
            const data = action.file.data;
            const newData = state.actions.actionForms.filter(action => (action.actionFormId === id ));
            if(newData && newData.length > 0 && newData[0].actionFormData
                && newData[0].actionFormData[0].actionFormDataId){
                newData[0].actionFormData[0].actionFormId = id;
                newData[0].actionFormData[0].data = data
            }else{
                newData[0].actionFormData = [{
                    actionFormId : id,
                    data : data
                }]}
            return {
                ...state,
                loading: false,
                openDynamicActionClientSnackBar: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                openDynamicActionClientSnackBar: true
            };
        // case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_SELECTED_VALUE_BY_CLIENT:
        //     const actionFormId = action.id;
        //     const selectedValue = action.selectedValue;
        //     const newSelectedData = state.actions.actionForms &&
        //         state.actions.actionForms.filter(action => (action.actionFormId === actionFormId ));
        //     if(newSelectedData[0].actionFormData) {
        //         newSelectedData[0].actionFormData[0].data = selectedValue
        //     }else{
        //         newSelectedData[0].actionFormData = [{
        //             data : selectedValue
        //         }]}
        //     return {
        //         ...state,
        //         id: action.id,
        //         loading: false,
        //     }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_INPUT_BY_CLIENT:
            const singleId = action.actionId;
            const selectedInput = action.inputValue;
            const newSelectedInput = state.actions.actionForms &&
                state.actions.actionForms.filter(action => (action.actionFormId === singleId ));
            if(newSelectedInput && newSelectedInput.length > 0 && newSelectedInput[0].actionFormData
                && newSelectedInput[0].actionFormData[0].actionFormDataId){
                newSelectedInput[0].actionFormData[0].actionFormId = singleId;
                newSelectedInput[0].actionFormData[0].data = selectedInput
            }else{
                newSelectedInput[0].actionFormData = [{
                    actionFormId : singleId,
                    data : selectedInput
                }]}
            console.log("newSelected data", newSelectedInput)
            return {
                ...state,
                id: action.id,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_FILE_NAME_BY_CLIENT:
            const actionId = action.id;
            const fileName = action.fileName;
            const newFileNameData = state.actions.actionForms &&
                state.actions.actionForms.filter(action => (action.actionFormId === actionId ));
            if(newFileNameData && newFileNameData.length > 0 && newFileNameData[0].actionFormData
                && newFileNameData[0].actionFormData[0].actionFormDataId){
                newFileNameData[0].actionFormData[0].actionFormId = actionId;
                newFileNameData[0].actionFormData[0].fileName = fileName
            }else{
                newFileNameData[0].actionFormData = [{
                    actionFormId : actionId,
                    fileName : fileName
                }]}
            return {
                ...state,
                id: action.id,
                fileName: action.fileName,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_CHECKBOX_VALUE_BY_CLIENT:
            const actionFormIdCheckBox = action.id;
            const isSelected = action.isSelected;
            const optionValueId = action.data;
            const newSelected = state.actions.actionForms.filter(action => (action.actionFormId === actionFormIdCheckBox));
            const checkedValue = newSelected[0].optionValue.filter(option => (option.optionValueId === optionValueId));
            if(newSelected && newSelected.length > 0 && newSelected[0].actionFormData
                && newSelected[0].actionFormData.length > 0){
                newSelected[0].actionFormData[0].actionFormId = actionFormIdCheckBox;
                newSelected[0].actionFormData[0].data = optionValueId;
            }else{
                newSelected[0].actionFormData = [{
                    actionFormId : actionFormIdCheckBox,
                    data : optionValueId,
                }]}
            if(checkedValue){
                checkedValue[0].isSelected = isSelected;
                const isSelectedDataArray = newSelected[0] && newSelected[0].optionValue.filter(option => (option.isSelected === true));
                const final = isSelectedDataArray.map(array => {
                    const data = {
                        actionFormId: actionFormIdCheckBox,
                        data: array.optionValueId
                    }
                    return data;
                })
                // state.dynamicActionData = final;
                newSelected[0].actionFormData = final;
            }
            return {
                ...state,
                id: action.id,
                selectedValue: action.selectedValue,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_CONTACT_VALUE_BY_CLIENT:
            const actionFormIdForContact = action.id;
            const selectedContactValue = action.selectedContact;
            const contactData = action.contactData
            const newSelectedContactData = state.actions.actionForms.filter(action =>
                (action.actionFormId === actionFormIdForContact ));
            if(newSelectedContactData[0].actionFormData) {
                newSelectedContactData[0].actionFormData[0].data = selectedContactValue
                if( newSelectedContactData[0].contact){
                    newSelectedContactData[0].contact.emailPhone = contactData[0].emailPhone
                    !== undefined ?contactData[0].emailPhone : ""
                    newSelectedContactData[0].contact.phone = contactData[0].phone
                    !== undefined ? contactData[0].phone : ""
                    newSelectedContactData[0].contact.address = contactData[0].address
                    !== undefined ? contactData[0].address : ""
                    newSelectedContactData[0].contact.contactType = contactData[0].contactType
                    !== undefined ? contactData[0].contactType : ""
                }
            } else {
                newSelectedContactData[0].actionFormData = [{
                    data : selectedContactValue
                }]
                newSelectedContactData[0].contact = {
                    emailPhone: contactData[0].emailPhone,
                    phone: contactData[0].phone,
                    address: contactData[0].address,
                    contactType: contactData[0].contactType,
                }
            }
            return {
                ...state,
                id: action.id,
                // selectedValue: action.selectedValue,
                loading: false,
            }

            //CONDITIONAL FORM
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_SUCCESS:
            const conditionalData = {
                refId : action.refId,
                step: action.step,
                actionFormId: action.actionFormId,
                optionName: action.optionName,
                prevActionFormId: action.prevActionFormId,
                prevRefId: action.prevRefId,
                prevOptionName: action.prevOptionName,
                conditionalForms: action.response.conditionalForms
            }
            console.log({conditionalData})
            if(state.conditionalFormDataArray && state.conditionalFormDataArray.filter(data =>
                data.refId === conditionalData.refId).length > 0){
                console.log("already set")
            }else {
                state.conditionalFormDataArray = [...state.conditionalFormDataArray, conditionalData]
            }

            const newSelectedAction = state.actions.actionForms.filter(form =>
                (form.actionFormId === action.actionFormId ));
            console.log({newSelectedAction});
            return {
                ...state,
                conditionalFormCaseData: action.response.conditionalForms,
                openClientConditionalFormModal: true,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_FAILURE:
            return {
                ...state,
                loading: false,
                openClientConditionalFormModal: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CLOSE_CLIENT_CONDITIONAL_FORM:
            return {
                ...state,
                loading: false,
                openClientConditionalFormModal: false,
            }

        default:
            return state;
    }
};

export default dynamicActionClientState;