import React from 'react';
import TablePagination from "@material-ui/core/TablePagination";

const TablePaginationComponent = ({tableList, rowsPerPage, page, setPage, setRowPerPage}) => {
    return(
        <>
            {tableList && tableList.length > 0 ? (
                <div
                    style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}
                >
                    <TablePagination
                        labelRowsPerPage={false}
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={
                            tableList.length > 0
                                ? tableList.length
                                : 100
                        }
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "previous page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "next page",
                        }}
                        onChangePage={(event, page) => {setPage(event,page)}}
                        onChangeRowsPerPage={
                            (event => {setRowPerPage(event)})
                        }
                    />
                </div>
            ) : (
                <div style={{     
                    display: "flex", 
                    width: "100%",
                    justifyContent:"center"
                    }}>
                    <h3
                        style={{
                            textAlign: "center",
                            fontSize: "14px",
                            padding: "1rem",
                            color: "#606060",
                        }}
                    >
                        {" "}
                        No data to show
                    </h3>
                </div>
            )}
        </>
    )
};

export default TablePaginationComponent;