import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuList,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Popover from "@material-ui/core/Popover";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MySnackbarContentWrapper from "../components/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import { AutoComplete, Checkbox, DatePicker, Select } from "antd";
import DialogTitleComponent from "./DialogTitleComponent";
import OpenContactFields from "../components/DynamicContactField";
import Helpers from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import SearchIcon from "@material-ui/icons/Search";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";

const { Option } = Select;

class CorporateClientScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogueForAddUser: false,
      openDialogueForEditUser: false,
      openDeleteUserDialogue: false,
      popoverElement: null,
      openActionMenu: false,
      openDialogueToAddClient: false,
      openDialogueToEditClient: false,
      page: 0,
      rowsPerPage: 10,
      sortType: "asc",
      corporateId: props.location.pathname.replace("/corporate/client/", ""),
      corporateName: props.location.dataToPass,
      contacts: [],
      updatedContact: [],
      contactId: "",
      openDeleteContactDialogue: false,
      clicked: false,
      selectedClientId: "",
      selectedClientName: "",
      selectedClientData: {},
      //for validation
      errors: {
        clientId: "",
        ssn: "",
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        maritalStatus: "",
      },
    };

    if (props.location.pathname.replace("/corporate/client/", "").length > 0) {
      this.props.getCorporateInfo(
        props.location.pathname.replace("/corporate/client/", "")
      );
    }
  }

  componentWillUnmount() {
    this.props.handleSnackBarClose();
    this.props.setCorporateClientToNull([]);
    this.props.fetchClientList();
  }

  render() {
    const { errors } = this.state;
    const clientState = this.props.state.clientState;
    const clientList = clientState && clientState.client;
    const corporateClientState =
      this.props.state && this.props.state.corporateClientState;
    const corporateClientData =
      corporateClientState && corporateClientState.clientData;
    const corporateClientList =
      corporateClientState && corporateClientState.corporate;
    const loading = corporateClientState && corporateClientState.loading;
    const corporateClientValue =
      corporateClientData.dob !== "" &&
      corporateClientData.firstName !== "" &&
      corporateClientData.gender !== "" &&
      corporateClientData.lastName !== "" &&
      corporateClientData.maritalStatus !== "";
    const sortedcorporateClientList = corporateClientList
      ? corporateClientList.sort((a, b) => {
          const data = this.state.sortType === "asc" ? 1 : -1;
          return data * a.firstName.localeCompare(b.firstName);
        })
      : null;
    var clientData = this.props.state.corporateClientState.clientData;

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
    };

    const handleCloseDialogueForDeleteClient = () => {
      this.setState({
        openDeleteClientDialogue: false,
      });
    };

    const handleOpenDialogueForDeleteClient = () => {
      this.setState({
        openDeleteClientDialogue: true,
      });
    };

    const handleOkDeleteClientDialogue = () => {
      this.props.deleteCorporateClient(this.state.client.clientId, this.props);
      this.setState({
        openDeleteClientDialogue: false,
        clientId: "",
      });
    };

    //add client for corporate
    const handleCloseDialogueToAddClient = () => {
      this.setState({
        openDialogueToAddClient: false,
      });
    };

    const handleOpenDialogueToAddClient = () => {
      this.setState({
        openDialogueToAddClient: true,
        contacts: [],
        errors: {
          clientId: "",
          ssn: "",
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          maritalStatus: "",
        },
      });
    };

    //edit client for corporate
    const handleOpenDialogueToEditClient = () => {
      this.setState({
        openDialogueToEditClient: true,
        errors: {
          clientId: "",
          ssn: "",
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          maritalStatus: "",
        },
      });
    };
    const handleCloseDialogueToEditClient = () => {
      this.setState({
        openDialogueToEditClient: false,
      });
    };
    //add contacts

    const showInputForContactField = () => {
      let a = createContactObject();
      this.setState({
        contacts: [...this.state.contacts, a],
      });
    };
    const createContactObject = () => {
      return {
        contactId: String(Math.random()),
        name: "",
        emailPhone: "",
        contactType: "UNKNOWN_CONTACT_TYPE",
        phone: "",
        dob: 0,
      };
    };

    const filterContactsData = () => {
      let contactForm = [];
      this.state.contacts.map((single) => {
        const data = {
          name: single.name,
          emailPhone: single.emailPhone,
          contactType: single.contactType,
          phone: single.phone,
          dob: single.dob,
        };
        return contactForm.push(data);
      });
      this.props.handleChangeCorporateClient("contacts", contactForm);
    };
    const filterEditContactData = () => {
      let resultA = this.state.updatedContact.filter((elm) =>
        this.state.contacts
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
      );
      let resultB = this.state.contacts
        .filter(
          (elm) =>
            !this.state.updatedContact
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        )
        .map((single) => {
          const data = {
            name: single.name,
            emailPhone: single.emailPhone,
            contactType: single.contactType,
            phone: single.phone,
            dob: single.dob,
            DatePicker,
          };
          return data;
        });
      let contactForm = [...resultA, ...resultB];
      this.props.handleChangeCorporateClient("contacts", contactForm);
    };

    const handleChangeClientData = (data) => {
      this.props.handleChangeCorporateClient("clientId", data.clientId);
      this.props.handleChangeCorporateClient("firstName", data.firstName);
      this.props.handleChangeCorporateClient("lastName", data.lastName);
      this.props.handleChangeCorporateClient("dob", data.dob);
      this.props.handleChangeCorporateClient("gender", data.gender);
      this.props.handleChangeCorporateClient(
        "maritalStatus",
        data.maritalStatus
      );
    };

    const handleValidationOnChange = (events, error) => {
      Helpers.handleValidationOnChange(events, error);
    };

    //to delete field
    const closeContactsFieldSection = (key) => {
      var removedActionData = this.state.contacts.filter(
        (single) => single.contactId !== key
      );
      this.setState(
        {
          contacts: removedActionData,
        },
        () => {
          filterContactsData();
        }
      );
    };
    const handleOpenDeleteContactsDialogue = (contactId) => {
      this.setState({
        openDeleteContactDialogue: true,
        contactId: contactId,
      });
    };
    const handleCloseDeleteContactsDialogue = () => {
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };
    const handleOkDeleteContactsDialogue = () => {
      closeContactsFieldSection(this.state.contactId);
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };
    const clientListOption =
      clientList &&
      clientList.map((list) => {
        return {
          value:
            list.firstName + " " + list.lastName + " (" + list.clientNo + ") ",
          clients: list,
        };
      });
    return (
      <React.Fragment>
        <HelmetComponent title="Corporate Client" />
        {this.props.state.corporateClientState.loading ? (
          <div
            className={
              this.props.state.corporateClientState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {this.props.state.corporateClientState.addClientSuccess === true
          ? this.setState(
              {
                openDialogueToAddClient: false,
                openDialogueToEditClient: false,
              },
              () => {
                this.props.setAddCorporateClientSuccessToFalse();
              }
            )
          : null}
        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/corporate" style={style.link}>
            Corporate
          </Link>
          <Typography color="inherit" className="link">
            Client
          </Typography>
        </Breadcrumbs>

        <div
          style={{
            marginTop: "67px",
            marginRight: "3%",
            marginLeft: "3%",
            fontSize: "16px",
            color: "#5d5d5d",
            position: "absolute",
          }}
        >
          <b>{this.state.corporateName}</b>
        </div>
        <div className={"d-flex flex-row-reverse"} style={style.flex}>
          <div>
            <button
              style={style.settingButton}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                });
              }}
            >
              <img
                alt={"setting"}
                src={"/assets/images/sort.png"}
                style={{ width: "18px", height: "18px" }}
              />
            </button>
          </div>
          <div>
            <button
              style={style.addButton}
              onClick={(e) => {
                e.preventDefault();
                this.props.handleChangeCorporateClient("firstName", "");
                this.props.handleChangeCorporateClient("lastName", "");
                this.props.handleChangeCorporateClient("dob", "");
                this.props.handleChangeCorporateClient("gender", "");
                this.props.handleChangeCorporateClient("maritalStatus", "");
                handleOpenDialogueToAddClient();
                this.props.fetchClientList();
                this.setState({
                  selectedClientData: {},
                  selectedClientId: "",
                });
              }}
            >
              <AddIcon />
              Add Client
            </button>
          </div>
        </div>
        <div style={style.container}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead style={{ padding: "1rem" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#8BC83F",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "left",
                      height: "3rem",
                      paddingLeft: "3rem",
                      width: "30%",
                    }}
                  >
                    Client Id
                  </TableCell>
                  <TableCell style={style.tableHeader}>Client</TableCell>
                  <TableCell style={style.tableHeader}>Register Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              {sortedcorporateClientList &&
                sortedcorporateClientList.length > 0 &&
                sortedcorporateClientList
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((client) => {
                    return (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              height: "62px",
                              width: "30%",
                              paddingLeft: "3rem",
                            }}
                          >
                            {client.clientNo ? client.clientNo : client.ssn}
                          </TableCell>
                          <TableCell style={style.tableBody}>
                            <span
                              style={{
                                textTransform: "none",
                                fontSize: "15px",
                                color: "#626262",
                                padding: 0,
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    client: client,
                                  },
                                  () => {
                                    // handleOpenDialogueToEditClient()
                                    // this.props.history.push({
                                    //     pathname: "/corporate/" + this.state.corporateId +
                                    //         "/clientupdate/" + this.state.client.clientId
                                    // });
                                  }
                                );
                              }}
                            >
                              {client.firstName} {client.lastName}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              height: "62px",
                              width: "30%",
                            }}
                          >
                            {getFormattedDateValue(client.createdAt)}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "center",
                              width: "30%",
                            }}
                          >
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.setState(
                                  {
                                    popoverElement: e.currentTarget,
                                    client: client,
                                    openActionMenu: true,
                                  },
                                  () => {
                                    this.props.setCorporateClientToEdit(
                                      this.state.client
                                    );
                                  }
                                );
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.fetchClientList();
                                  this.props.setCorporateClientToEdit(
                                    this.state.client
                                  );
                                  if (
                                    corporateClientData &&
                                    corporateClientData.contacts !== undefined
                                  ) {
                                    this.setState({
                                      contacts: corporateClientData.contacts,
                                      updatedContact:
                                        corporateClientData.contacts,
                                      openActionMenu: false,
                                      selectedClientId: "",
                                      selectedClientName: "",
                                      selectedClientData: {},
                                    });
                                  } else {
                                    this.setState({
                                      contacts: [],
                                      openActionMenu: false,
                                    });
                                  }
                                  handleOpenDialogueToEditClient();
                                }}
                              >
                                <EditOutlinedIcon />
                                Edit
                              </MenuList>
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogueForDeleteClient();
                                  this.setState({ openActionMenu: false });
                                }}
                              >
                                <DeleteOutlinedIcon
                                  style={{ paddingBottom: 0 }}
                                />{" "}
                                Remove
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
            </Table>
            {sortedcorporateClientList &&
            sortedcorporateClientList.length > 0 ? (
              <div style={{ justifyContent: "flex-start", width: "100%" }}>
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedcorporateClientList.length > 0
                      ? sortedcorporateClientList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
          </TableContainer>
        </div>

        {/*ADD CORPORATE CLIENT*/}
        <Dialog
          open={this.state.openDialogueToAddClient}
          onClose={handleCloseDialogueToAddClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToAddClient}
            >
              Add Client
            </DialogTitleComponent>
            <DialogContent
              dividers
              className="dialogContentWidth850"
              style={{ paddingTop: "5px" }}
            >
              <div className="row" style={style.row}>
                <div className="col-lg-4">
                  <label htmlFor="firstName" className="label">
                    First Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="auto-comp-select">
                    <AutoComplete
                      className="auto-comp"
                      listHeight={150}
                      getPopupContainer={(node) => node.parentNode}
                      value={
                        this.state.selectedClientId
                          ? this.state.selectedClientData.firstName
                          : clientData.firstName
                      }
                      dropdownMatchSelectWidth
                      options={clientListOption}
                      onSelect={(e, options) => {
                        handleChangeClientData(options.clients);
                        this.setState({
                          selectedClientName: e,
                          selectedClientId: options.clients.clientId,
                          selectedClientData: options.clients,
                        });
                      }}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(e) => {
                        this.props.handleChangeCorporateClient("firstName", e);
                        this.setState({
                          selectedClientId: "",
                        });
                      }}
                    />
                    <SearchIcon
                      style={{
                        position: "absolute",
                        left: "232px",
                        top: "51px",
                        color: "#babbbe",
                        width: "18px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="lastName" className="label">
                    Last Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="lastName"
                    className="inputField"
                    autoComplete="off"
                    name="lastName"
                    disabled={this.state.selectedClientId}
                    value={
                      this.state.selectedClientId
                        ? this.state.selectedClientData.lastName
                        : clientData.lastName
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.lastName.length > 0 && (
                    <span className="error">{errors.lastName}</span>
                  )}
                </div>
                <div className="col-4" style={{ position: "inherit" }}>
                  <label className="label">
                    Date-of-birth
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-add-date">
                    <KeyBoardDatePickerComponent
                      disabled={this.state.selectedClientId}
                      dateValue={
                        this.state.selectedClientId
                          ? getFormattedDateValue(
                              this.state.selectedClientData.dob
                            )
                          : clientData.dob !== ""
                          ? getFormattedDateValue(clientData.dob)
                          : null
                      }
                      onChangeDate={(date) => {
                        this.props.handleChangeCorporateClient("dob", date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label htmlFor="gender" className="label">
                    Gender
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      disabled={this.state.selectedClientId}
                      value={
                        this.state.selectedClientId
                          ? this.state.selectedClientData.gender
                          : clientData.gender
                      }
                      id="contactType"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeCorporateClient("gender", e);
                      }}
                    >
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" className="label">
                    Marital Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      disabled={this.state.selectedClientId}
                      value={
                        this.state.selectedClientId
                          ? this.state.selectedClientData.maritalStatus
                          : clientData.maritalStatus
                      }
                      id="maritalStatus"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeCorporateClient(
                          "maritalStatus",
                          e
                        );
                      }}
                    >
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" className="label">
                    County
                  </label>
                  <input
                    disabled={this.state.selectedClientId}
                    type="text"
                    id="county"
                    value={
                      this.state.selectedClientId
                        ? this.state.selectedClientData.county
                        : clientData.county
                    }
                    className="inputField"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4" style={{ position: "inherit" }}>
                  <Checkbox
                    id="isEnableTransaction"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.checked
                      );
                    }}
                    className="label"
                  >
                    Enable Transaction
                  </Checkbox>
                </div>
              </div>
              {!(this.state.contacts.length > 0) ? (
                <div
                  className="addFieldOuterDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForContactField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span className="addFieldSpan">Add Contact</span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenContactFields
                    fieldArray={this.state.contacts}
                    filterContactData={filterContactsData}
                    handleValidationOnChange={handleValidationOnChange}
                    handleOpenDeleteDialogue={handleOpenDeleteContactsDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      className="cursorP"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForContactField();
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions style={{ padding: "10px 30px 15px 0px" }}>
              <div>
                <ButtonComponent
                  value={corporateClientValue}
                  loading={loading}
                  buttonName="Add"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: false });
                    var clientData =
                      this.props.state.corporateClientState.clientData;
                    clientData.ssn =
                      clientData.ssn && clientData.ssn.replace(/-/g, "");
                    if (!this.state.clicked) {
                      this.setState({
                        clicked: true,
                      });
                      if (this.state.selectedClientId) {
                        handleCloseDialogueToAddClient();
                        this.props.assignClientToContainer(
                          this.state.corporateId,
                          this.state.selectedClientId,
                          this.props
                        );
                      } else {
                        this.props.addNewCorporateClient(
                          this.state.corporateId,
                          clientData,
                          this.props
                        );
                      }
                      setTimeout(() => {
                        this.setState({
                          clicked: false,
                        });
                      }, 3000);
                    }
                  }}
                />
              </div>
            </DialogActions>
          </div>
        </Dialog>
        {/*UPDATE CORPORATE CLIENT*/}
        <Dialog
          open={this.state.openDialogueToEditClient}
          onClose={handleCloseDialogueToEditClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToEditClient}
            >
              Update Client
            </DialogTitleComponent>
            <DialogContent
              dividers
              className="dialogContentWidth850"
              style={{ paddingTop: "5px" }}
            >
              <div className="row" style={style.row}>
                <div className="col-4">
                  <label htmlFor="firstName" className="label">
                    First Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="firstName"
                    value={corporateClientData.firstName}
                    autoComplete="off"
                    className="inputField"
                    name="firstName"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.firstName.length > 0 && (
                    <span className="error">{errors.firstName}</span>
                  )}
                </div>
                <div className="col-4">
                  <label htmlFor="lastName" className="label">
                    Last Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="lastName"
                    value={corporateClientData.lastName}
                    className="inputField"
                    autoComplete="off"
                    name="lastName"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.lastName.length > 0 && (
                    <span className="error">{errors.lastName}</span>
                  )}
                </div>
                <div className="col-4" style={{ position: "inherit" }}>
                  <label className="label">
                    Date-of-birth
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-add-date">
                    <KeyBoardDatePickerComponent
                      dateValue={
                        corporateClientData &&
                        getFormattedDateValue(corporateClientData.dob)
                      }
                      onChangeDate={(date) => {
                        this.props.handleChangeCorporateClient("dob", date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label htmlFor="gender" className="label">
                    Gender
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="contactType"
                      showSearch={false}
                      value={corporateClientData.gender}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeCorporateClient("gender", e);
                      }}
                    >
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" className="label">
                    Marital Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="maritalStatus"
                      showSearch={false}
                      value={corporateClientData.maritalStatus}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeCorporateClient(
                          "maritalStatus",
                          e
                        );
                      }}
                    >
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" className="label">
                    County
                  </label>
                  <input
                    type="text"
                    id="county"
                    value={corporateClientData.county}
                    className="inputField"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4" style={{ position: "inherit" }}>
                  <Checkbox
                    id="isEnableTransaction"
                    checked={
                      corporateClientData &&
                      corporateClientData.isEnableTransaction
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCorporateClient(
                        e.target.id,
                        e.target.checked
                      );
                    }}
                    className="label"
                  >
                    Enable Transaction
                  </Checkbox>
                </div>
              </div>
              {!(this.state.contacts.length > 0) ? (
                <div
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForContactField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    Add Contact
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenContactFields
                    fieldArray={this.state.contacts}
                    isEdit={true}
                    filterEditContactData={filterEditContactData}
                    handleOpenDeleteDialogue={handleOpenDeleteContactsDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForContactField();
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions style={{ padding: "10px 30px 15px 0px" }}>
              <ButtonComponent
                value={corporateClientValue}
                loading={loading}
                buttonName="Update"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: false });
                  if (!this.state.clicked) {
                    this.setState({
                      clicked: true,
                      setValue: "add",
                    });
                    this.props.editClient(
                      corporateClientData,
                      this.state.corporateId,
                      this.props
                    );
                    setTimeout(() => {
                      this.setState({
                        clicked: false,
                      });
                    }, 3000);
                  }
                }}
              />
            </DialogActions>
          </div>
        </Dialog>
        {/*Delete corporate client*/}
        <Dialog
          open={this.state.openDeleteClientDialogue}
          onClose={handleCloseDialogueForDeleteClient}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title">
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to remove this client?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForDeleteClient}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteClientDialogue();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*DELETE CLIENT CONTACT*/}
        <Dialog
          open={this.state.openDeleteContactDialogue}
          onClose={handleCloseDeleteContactsDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete contact?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteContactsDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteContactsDialogue();
                      }}
                      style={style.deleteCaseTemplateButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            this.props.state.corporateClientState.openCorporateClientSnackBar
          }
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={
              this.props.state.corporateClientState.errorMsgForCorporateClient
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={corporateClientState && corporateClientState.openErrorSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={
              corporateClientState &&
              corporateClientState.errorMsgForCorporateClient
            }
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  flex: {
    marginTop: "60px",
    marginLeft: "3%",
    marginRight: "3%",
    paddingRight: 0,
    marginBottom: "12px",
  },
  // dialogHeaderForAddUser: {
  //     cursor: "move",
  //     // background: "#8BC83F",
  //     color: "white",
  //     paddingTop: "0px",
  //     paddingRight: "1rem",
  //     paddingLeft: "1rem"
  // },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    height: "40px",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    // width: "175px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  container: {
    background: "#ffffff",
    margin: "8px 3% 3% 3%",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "62px",
    width: "30%",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    // background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  dialogueContent: {
    background: "white",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    paddingRight: "1rem !important",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  disabledLabel: {
    color: "#a8a8a8",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
};

export default CorporateClientScreen;
