import { connect } from "react-redux";
import ClientInfo from "../../../screens/registerCase/ClientInfoScreen";
import {
    handleChangeClientInRegisterCase,
    registerCaseRequestMade,
    addClientSuccess,
    addClientFailure,
    closeSnackBarForRegisterCase,
    searchClientFail,
    searchClientSuccess,
    fetchClientSuccessInRegisterCase,
    fetchClientFailureInRegisterCase,
    setAddSucessFullToFalse,
    handleChangeCaseInRegisterCase
} from "../../../actions";
import { RegisterCaseAPI } from "../../../api";
import {clearClientFormData} from "../../../actions/RegisterCaseAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleChangeClientInRegisterCase: (id, value) => dispatch(handleChangeClientInRegisterCase(id, value)),
        handleChangeCaseInRegisterCase: (id, value) => dispatch(handleChangeCaseInRegisterCase(id, value)),
        addClient: (clientData) => {
            dispatch(registerCaseRequestMade(true));
            RegisterCaseAPI.addNewClient(clientData, (response, error) => {
                if (response) {
                    dispatch(addClientSuccess(response.client));
                } else {
                    if (error.msg) {
                        dispatch(addClientFailure(error.msg));
                    } else {
                        dispatch(addClientFailure(error));
                    }
                }
            });
        },
        fetchClientList: () => {
            dispatch(registerCaseRequestMade(true));
            RegisterCaseAPI.fetchClientList((response, error) => {
                if (response) {
                    dispatch(fetchClientSuccessInRegisterCase(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientFailureInRegisterCase(error.msg))
                    } else {
                        dispatch(fetchClientFailureInRegisterCase(error));
                    }
                }
            })
        },
        searchClient: (value) => {
            RegisterCaseAPI.searchClient(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchClientFail(error.msg))
                    } else {
                        dispatch(searchClientFail(error));
                    }
                } else {
                    dispatch(searchClientSuccess(response));
                }
            })
        },
        setSearchedValue: (value) => {
            dispatch(fetchClientSuccessInRegisterCase(value));
        },
        setAddSucessfullFalse: (value) => {
            dispatch(setAddSucessFullToFalse(value));
        },
        setClientContent: (response) => {
            dispatch(addClientSuccess(response));
        },
        handleRegisterCaseSnackBarClose: () => dispatch(closeSnackBarForRegisterCase(true)),
        clearClientFormData: () => dispatch(clearClientFormData()),
    };
};
export const ClientInfoContainer = connect(mapStateToProps, mapDispatchToProps)(ClientInfo);