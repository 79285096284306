import React, { Component } from "react";
import {
    Button,
    DialogActions,
    MenuItem,
    MenuList,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import {Select} from "antd";
import TablePagination from "@material-ui/core/TablePagination";
import ButtonComponent from "./ButtonComponent";

const {Option} = Select;

class EligibilityScreen extends Component{

    constructor(props) {
        super(props);
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            page: 0,
            rowsPerPage: 10,
            eligibilityList:{},
            disable: false,
        }
    }

    componentDidMount() {
        this.props.fetchEligibilityData();
    }

    render() {
        const settingState = this.props.settingState;
        const eligibilityData = settingState && settingState.eligibilityData;
        const eligibilityList = settingState && settingState.eligibilityList;
        const addValue = eligibilityData.maxLimit !== "" &&
            eligibilityData.familyType !== "" && eligibilityData.memberNo !== "";
        const editValue =  eligibilityData.maxLimit !== "";
        const loading = this.props.loading;
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        };
        return(
            <React.Fragment>
                <div>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleOpenModalToAddEligibility();
                                }}>
                                <AddIcon/> Add Eligibility
                            </button>
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header-second">
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader" style={{width: "230px"}}>
                                            Family Type
                                        </TableCell>
                                        <TableCell className="tableHeader" style={{width: "170px"}}>
                                            Members
                                        </TableCell>
                                        <TableCell className="tableHeader" style={{width: "782px"}}>
                                            Max Limit
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    eligibilityList && eligibilityList.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                    ).map(list =>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="first-tableBody" style={{width: '25%'}}>
                                                    {list.familyType && list.familyType.replace("_FAMILY", " ")}
                                                </TableCell>
                                                <TableCell className="tableBody" style={{width: '25%'}}>
                                                    {list.memberNo}
                                                </TableCell>
                                                <TableCell className="tableBody" style={{width: '25%'}}>
                                                    {list.maxLimit && list.maxLimit/100}
                                                </TableCell>
                                                <TableCell>
                                                    <MoreHorizIcon
                                                        id="Popover1"
                                                        style={{cursor: 'pointer'}}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                popoverElement: e.currentTarget,
                                                                openActionMenu: true,
                                                                eligibilityList: list,
                                                            })
                                                        }}
                                                    />
                                                    <Popover
                                                        open={this.state.openActionMenu}
                                                        anchorEl={this.state.popoverElement}
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            this.setState({openActionMenu: false})
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        style={{boxShadow: 'none'}}
                                                    >
                                                        <MenuList id="popover-list" style={{
                                                            color: '#4d4d4d',
                                                            padding: '1rem !important',
                                                            cursor: 'pointer'
                                                        }}>
                                                            <MenuItem
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    const eligibilityData = {
                                                                        eligibilitySettingId: this.state.eligibilityList.eligibilitySettingId,
                                                                        maxLimit: this.state.eligibilityList.maxLimit,
                                                                        familyType: this.state.eligibilityList.familyType,
                                                                        memberNo: this.state.eligibilityList.memberNo,
                                                                    }
                                                                    this.props.setEligibilityDataToEdit(eligibilityData)
                                                                    this.props.handleOpenModalToUpdateEligibility();
                                                                    this.setState({openActionMenu: false,}
                                                                    );
                                                                }}>
                                                                <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Popover>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                }
                            </Table>
                            </TableContainer>
                            {eligibilityList && eligibilityList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            eligibilityList.length > 0
                                                ? eligibilityList.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>
                {/*add eligibility*/}
                <Dialog
                    open={this.props.openModalToAddEligibilityData}
                    onClose={this.props.handleCloseModalToAddEligibility}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleCloseModalToAddEligibility}>
                            Add Eligibility
                        </DialogTitleComponent>
                        <DialogContent
                            dividers
                            style={{borderBottom: "none", paddingTop:"0px", paddingBottom:"0px", height:"270px"}}
                            className="dialogContent">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="lastName" className="label">Maximum Limit
                                        <span style={{color: "red"}}> *</span></label>
                                    <input
                                        type="number"
                                        className="inputField"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeEligibilityData("maxLimit", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="maritalStatus" className="label">Family Type
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="select-input selects h40" style={{width: "100%"}}>
                                        <Select
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.setState({isBlocking: true});
                                                if(e === "SINGLE_FAMILY"){
                                                    this.props.handleChangeEligibilityData("memberNo", 1);
                                                    this.setState({
                                                        disable: true,
                                                    })
                                                }else if(e === "MARRIED_FAMILY"){
                                                     this.props.handleChangeEligibilityData("memberNo", 2);
                                                    this.setState({
                                                        disable: true
                                                    })
                                                }else{
                                                     this.props.handleChangeEligibilityData("memberNo", "");
                                                    this.setState({
                                                        disable: false
                                                    })
                                                }
                                                this.props.handleChangeEligibilityData("familyType", e);
                                            }}
                                        >
                                            <Option value="SINGLE_FAMILY">Single</Option>
                                            <Option value="MARRIED_FAMILY">Married</Option>
                                            <Option value="FAMILY">Family</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="maritalStatus" className="label">Member
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="select-input selects h40" style={{width: "100%"}}>
                                        <Select
                                            showSearch={false}
                                            value={eligibilityData.memberNo}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.setState({isBlocking: true});
                                                this.props.handleChangeEligibilityData("memberNo", e);
                                            }}
                                            disabled={this.state.disable}
                                        >
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 12px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    const data = {
                                        maxLimit: eligibilityData.maxLimit * 100,
                                        familyType: eligibilityData.familyType,
                                        memberNo: eligibilityData.memberNo,
                                    }
                                    this.props.addEligibility(data);
                                }}
                                loading={loading}
                                value={addValue}
                                buttonName="Add"
                            />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*edit eligibility*/}
                <Dialog
                    open={this.props.openModalToUpdateEligibilityData}
                    onClose={this.props.handleCloseModalToUpdateEligibility}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleCloseModalToUpdateEligibility}>
                            Update Eligibility
                        </DialogTitleComponent>
                        <DialogContent
                            dividers
                            style={{borderBottom: "none", paddingTop:"0px", paddingBottom:"0px", height:"270px"}}
                            className="dialogContent">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="lastName" className="label">Maximum Limit
                                        <span style={{color: "red"}}> *</span></label>
                                    <input
                                        type="number"
                                        value={eligibilityData.maxLimit}
                                        className="inputField"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeEligibilityData("maxLimit", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="maritalStatus" className="label">Family Type
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="select-input selects h40" style={{width: "100%"}}>
                                        <Select
                                            disabled
                                            showSearch={false}
                                            value={eligibilityData.familyType}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.setState({isBlocking: true});
                                                this.props.handleChangeEligibilityData("familyType", e);
                                            }}
                                        >
                                            <Option value="SINGLE_FAMILY">Single</Option>
                                            <Option value="MARRIED_FAMILY">Married</Option>
                                            <Option value="FAMILY">Family</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="maritalStatus" className="label">Member
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="select-input selects h40" style={{width: "100%"}}>
                                        <Select
                                            disabled
                                            showSearch={false}
                                            value={eligibilityData.memberNo}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.setState({isBlocking: true});
                                                this.props.handleChangeEligibilityData("memberNo", e);
                                            }}
                                        >
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 12px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    const data = {
                                        eligibilitySettingId: eligibilityData.eligibilitySettingId,
                                        maxLimit: eligibilityData.maxLimit * 100,
                                        familyType: eligibilityData.familyType,
                                        memberNo: eligibilityData.memberNo,
                                    }
                                    this.props.updateEligibility(data);
                                }}
                                value= {editValue}
                                loading={loading}
                                buttonName="Update"
                          />
                        </DialogActions>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}
const style = {
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        margin: "0px 1px 1rem 1px",

    },
}
export default EligibilityScreen;