import { connect } from "react-redux";
import { IndividualClientTransactionScreen } from "../../screens";
import {
  closeTransactionSnackBar,
  downloadACHDocumentFailure,
  downloadACHDocumentSuccess,
  exportSingleClientTransactionByDateAndMediumFailure,
  exportSingleClientTransactionByDateAndMediumSuccess,
  exportSingleClientTransactionByDateAndStatusAndMediumFailure,
  exportSingleClientTransactionByDateAndStatusAndMediumSuccess,
  exportSingleClientTransactionByDateAndStatusFailure,
  exportSingleClientTransactionByDateAndStatusSuccess,
  exportSingleClientTransactionByDateFailure,
  exportSingleClientTransactionByDateSuccess,
  exportSingleClientTransactionByMediumFailure,
  exportSingleClientTransactionByMediumSuccess,
  exportSingleClientTransactionByStatusAndMediumFailure,
  exportSingleClientTransactionByStatusAndMediumSuccess,
  exportSingleClientTransactionByStatusFailure,
  exportSingleClientTransactionByStatusSuccess,
  exportSingleClientTransactionByTermFailure,
  exportSingleClientTransactionByTermSuccess,
  exportSingleClientTransactionFailure,
  exportSingleClientTransactionSuccess,
  exportTransactionByDateFailure,
  exportTransactionByDateSuccess,
  exportTransactionByStatusAndMediumFailure,
  exportTransactionByStatusAndMediumSuccess,
  fetchTransactionFailure,
  fetchTransactionSuccess,
  getClientTransactionFailure,
  getClientTransactionSuccess,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleChangeMedium,
  handleChangeStatus,
  handleChangeTerm,
  handleChangeTransactionRemark,
  handleChangeTransactionStatus,
  handleChangeTransType,
  openSnackBar,
  searchClientTransactionByDateFailure,
  searchClientTransactionByDateSuccess,
  searchClientTransactionByMediumAndDateFailure,
  searchClientTransactionByMediumAndDateSuccess,
  searchClientTransactionByMediumAndStatusAndDateFailure,
  searchClientTransactionByMediumAndStatusAndDateSuccess,
  searchClientTransactionByMediumAndStatusFailure,
  searchClientTransactionByMediumAndStatusSuccess,
  searchClientTransactionByMediumFailure,
  searchClientTransactionByMediumSuccess,
  searchClientTransactionByStatusAndDateFailure,
  searchClientTransactionByStatusAndDateSuccess,
  searchClientTransactionByStatusFailure,
  searchClientTransactionByStatusSuccess,
  searchClientTransactionFailure,
  searchClientTransactionSuccess,
  searchTransactionFailure,
  searchTransactionSuccess,
  transactionRequestMade,
  updateSubmitTransactionFailure,
  updateSubmitTransactionSuccess,
  handleChangeVendor,
  setOpenCreateTransactionForm,
  handleChangeTransData,
  createTransactionFailure,
  createTransactionSuccess,
  handleChangeToOpenNoteModal,
  handleChangeToNotes,
  addNoteSuccess,
  addNoteFailure,
  closeClientTransactionSnackBar,
  getAmountDetailByClientIdSuccess,
  getAmountDetailByClientIdFailure,
  clearAmountDetail,
  clearCreateTransactionForm,
  handleChangeDescData,
  deleteTransactionFailure,
  deleteTransactionSuccess,
  exportTransactionVODRequest,
  exportTransactionVODSuccess,
  exportTransactionVODFailure,
  handleChangeBlockedTransData,
  clearEditBlockedTrans,
  updateBlockedTransSuccess,
  handleTransactionFilter,
  clearTransactionFilter,
  getTransConfigFailure,
  getTransConfigSuccess,
  saveTransConfigFailure,
  saveTransConfigSuccess,
  setClientTransactionInputDataToEdit,
  setTransactionInputDataToEdit,
  updateTransactionSuccess,
  updateTransactionFailure,
  handlChangeReverseDate,
  clearTransactionMainFilter,
} from "../../actions/TransactionAction";
import {
  ActivityLogAPI,
  ClientAPI,
  DynamicActionAPI,
  SettingsAPI,
  TransactionAPI,
  VendorAPI,
} from "../../api";
import {
  clientRequestMade,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  scheduleTransactionRequestMade,
  vendorRequestMade,
} from "../../actions";
import {
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import {
  clearFileData,
  uploadDocFileFailure,
  uploadDocFileSuccess,
} from "../../actions/ScheduleTransactionAction";
import {
  activityLogRequestMade,
  fetchActivityLogByIdFailure,
  fetchActivityLogByIdSuccess,
} from "../../actions/ActivityLogAction";
import { updateDescriptionSuccess } from "../../actions/ClientAction";
import {
  fetchVendorClientByIdFailure,
  fetchVendorClientByIdSuccess,
} from "../../actions/VendorAction";
const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactionList: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchTransactionList((response, error) => {
        if (response) {
          dispatch(fetchTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchTransactionFailure(error.msg));
          } else {
            dispatch(fetchTransactionFailure(error));
          }
        }
      });
    },
    getClientTransaction: (clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.getClientTransaction(clientId, (response, error) => {
        if (response) {
          dispatch(getClientTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getClientTransactionFailure(error.msg));
          } else {
            dispatch(getClientTransactionFailure(error));
          }
        }
      });
    },
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
          if (response.client && response.client.bankDetail.length === 1) {
            const e =
              response.client.bankDetail[0] &&
              response.client.bankDetail[0].bankDetailId;
            props.handleChangeTransData("bankDetailId", e);
          }
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    fetchVendorClientById: (id) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorClientById(id, (response, error) => {
        if (response) {
          dispatch(fetchVendorClientByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorClientByIdFailure(error.msg));
          } else {
            dispatch(fetchVendorClientByIdFailure(error));
          }
        }
      });
    },
    exportTransactionByDate: (to, from) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByDate(to, from, (response, error) => {
        if (response) {
          window.open(response.transactionExportResponse.url, "_blank");
          dispatch(exportTransactionByDateSuccess(response));
        } else {
          if (error.msg) {
            dispatch(exportTransactionByDateFailure(error.msg));
          } else {
            dispatch(exportTransactionByDateFailure(error));
          }
        }
      });
    },
    //EXPORT BY CLIENT ID
    exportSingleClientTransactionList: (clientId, exportType) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransaction(
        clientId,
        exportType,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportSingleClientTransactionFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionFailure(error));
            }
          }
        }
      );
    },
    exportSingleClientTransactionByDate: (from, to, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByDate(
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(exportSingleClientTransactionByDateFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionByDateFailure(error));
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionByDateSuccess(response));
          }
        }
      );
    },
    exportSingleClientTransactionByTerm: (term, clientId, exportType) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByTerm(
        term,
        clientId,
        exportType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(exportSingleClientTransactionByTermFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionByTermFailure(error));
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionByTermSuccess(response));
          }
        }
      );
    },
    exportSingleClientTransactionByMedium: (medium, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByMedium(
        medium,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(exportSingleClientTransactionByMediumFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionByMediumFailure(error));
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionByMediumSuccess(response));
          }
        }
      );
    },
    exportSingleClientTransactionByStatus: (status, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByStatus(
        status,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(exportSingleClientTransactionByStatusFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionByStatusFailure(error));
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionByStatusSuccess(response));
          }
        }
      );
    },
    exportSingleClientTransactionByStatusAndMedium: (
      status,
      medium,
      clientId
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByStatusAndMedium(
        status,
        medium,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                exportSingleClientTransactionByStatusAndMediumFailure(error.msg)
              );
            } else {
              dispatch(
                exportSingleClientTransactionByStatusAndMediumFailure(error)
              );
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(
              exportSingleClientTransactionByStatusAndMediumSuccess(response)
            );
          }
        }
      );
    },
    exportSingleClientTransactionByDateAndMedium: (
      from,
      to,
      medium,
      clientId
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByDateAndMedium(
        from,
        to,
        medium,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                exportSingleClientTransactionByDateAndMediumFailure(error.msg)
              );
            } else {
              dispatch(
                exportSingleClientTransactionByDateAndMediumFailure(error)
              );
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(
              exportSingleClientTransactionByDateAndMediumSuccess(response)
            );
          }
        }
      );
    },
    exportSingleClientTransactionByDateAndStatus: (
      from,
      to,
      status,
      clientId
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByDateAndStatus(
        from,
        to,
        status,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                exportSingleClientTransactionByDateAndStatusFailure(error.msg)
              );
            } else {
              dispatch(
                exportSingleClientTransactionByDateAndStatusFailure(error)
              );
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(
              exportSingleClientTransactionByDateAndStatusSuccess(response)
            );
          }
        }
      );
    },
    exportSingleClientTransactionByDateAndStatusAndMedium: (
      from,
      to,
      status,
      medium,
      clientId
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByDateAndStatusAndMedium(
        from,
        to,
        status,
        medium,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                exportSingleClientTransactionByDateAndStatusAndMediumFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                exportSingleClientTransactionByDateAndStatusAndMediumFailure(
                  error
                )
              );
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(
              exportSingleClientTransactionByDateAndStatusAndMediumSuccess(
                response
              )
            );
          }
        }
      );
    },
    //SEARCH WITH CLIENT ID
    searchClientTransactionByDate: (to, from, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByDate(
        to,
        from,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionByDateFailure(error.msg));
            } else {
              dispatch(searchClientTransactionByDateFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByDateSuccess(response));
          }
        }
      );
    },
    searchClientTransaction: (value, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransaction(
        value,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionFailure(error.msg));
            } else {
              dispatch(searchClientTransactionFailure(error));
            }
          } else {
            dispatch(searchClientTransactionSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByMedium: (medium, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByMedium(
        medium,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionByMediumFailure(error.msg));
            } else {
              dispatch(searchClientTransactionByMediumFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByMediumSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByStatus: (status, clientId) => {
      TransactionAPI.searchClientTransactionByStatus(
        status,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionByStatusFailure(error.msg));
            } else {
              dispatch(searchClientTransactionByStatusFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByStatusSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByMediumAndStatus: (medium, status, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByMediumAndStatus(
        medium,
        status,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByMediumAndStatusFailure(error.msg)
              );
            } else {
              dispatch(searchClientTransactionByMediumAndStatusFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByMediumAndStatusSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByMediumAndDate: (medium, from, to, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByMediumAndDate(
        medium,
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByMediumAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchClientTransactionByMediumAndDateFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByMediumAndDateSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByStatusAndDate: (status, from, to, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByStatusAndDate(
        status,
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchClientTransactionByStatusAndDateFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByStatusAndDateSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      clientId
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },

    filterTransactionByAll: (
      id,
      description,
      checkNo,
      // userName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      balance,
      debitCreditAmount,
      compareType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.filterTransactionByClient(
        id,
        description,
        checkNo,
        // userName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        balance,
        debitCreditAmount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },

    searchTransaction: (value) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionFailure(error.msg));
          } else {
            dispatch(searchTransactionFailure(error));
          }
        } else {
          dispatch(searchTransactionSuccess(response));
        }
      });
    },

    handleTransactionSnackBarClose: () =>
      dispatch(closeTransactionSnackBar(true)),
    handleChangeDateFrom: (from) => dispatch(handleChangeDateFrom(from)),
    handleChangeDateTo: (to) => dispatch(handleChangeDateTo(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),
    handleChangeStatus: (status) => dispatch(handleChangeStatus(status)),
    handleChangeTerm: (term) => dispatch(handleChangeTerm(term)),
    handleChangeTransType: (transType) =>
      dispatch(handleChangeTransType(transType)),
    clearSnackBar: (from, to, medium, status) =>
      dispatch(openSnackBar(from, to, medium, status)),
    //by all
    exportTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      clientId,
      exportType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        clientId,
        exportType,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },

    //export transaction by column filter
    exportTransactionByColumnFilter: (
      clientId,
      transactionMedium,
      transactionType,

      transactionStatus,

      exportType,
      description,
      checkNo,
      userName,
      vendorName,
      balance,
      compareType,
      transDate,
      filterDebitAmount
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByColumnFilter(
        clientId,
        transactionMedium,
        transactionType,

        transactionStatus,

        exportType,
        description,
        checkNo,
        userName,
        vendorName,
        balance,
        compareType,
        transDate === null ? 0 : transDate,
        filterDebitAmount,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },

    // EXPORING VOD
    exportTransactionVOD: (ClientID, from, to, exportType) => {
      dispatch(exportTransactionVODRequest(true));
      TransactionAPI.exportTransactionVOD(
        ClientID,
        from === null ? 0 : from,
        to === null ? 0 : to,
        exportType,
        (response, error) => {
          if (response && !response.msg) {
            window.open(response.url, "_blank");
            dispatch(exportTransactionVODSuccess(response));
          } else if (response && response.msg) {
            dispatch(exportTransactionVODFailure(response.msg));
          } else {
            if (error.msg) {
              dispatch(exportTransactionVODFailure(error.msg));
            } else {
              dispatch(exportTransactionVODFailure(error));
            }
          }
        }
      );
    },

    //download ACH file
    downloadACHDoc: (transactionId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.downloadACHDoc(transactionId, (response, error) => {
        if (response) {
          window.open(response.msg, "_blank");
          dispatch(downloadACHDocumentSuccess(response));
        } else {
          if (error.msg) {
            dispatch(downloadACHDocumentFailure(error.msg));
          } else {
            dispatch(downloadACHDocumentFailure(error));
          }
        }
      });
    },
    // change trans status
    handleChangeTransStatus: (status) =>
      dispatch(handleChangeTransactionStatus(status)),
    handleChangeRemark: (remark) =>
      dispatch(handleChangeTransactionRemark(remark)),
    updateSubmitTransactionList: (transData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateTransactionStatus(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },
    undoReverseClientTranx: (transData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.undoReverseTranx(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },
    setChangeTransactionStatusSuccessToFalse: () => {
      dispatch(updateSubmitTransactionSuccess(false));
    },
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    handleChangeVendor: (vendor) => dispatch(handleChangeVendor(vendor)),

    // create transaction
    setOpenCreateTransaction: (bool) => {
      dispatch(setOpenCreateTransactionForm(bool));
    },
    handleChangeTransData: (id, value) => {
      dispatch(handleChangeTransData(id, value));
    },
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },
    uploadDocumentFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formData = new FormData();
      formData.append("doc", selectedFiles.document);
      formData.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadDocumentFile(formData, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
          props.handleChangeContactFile("documentUrl", response.fileUrl);
          props.handleChangeTransData("document", response.fileUrl);
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },
    createTransData: (value, props) => {
      TransactionAPI.createTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(createTransactionFailure(error.msg));
          } else {
            dispatch(createTransactionFailure(error));
          }
        } else {
          dispatch(createTransactionSuccess(true));
          props.getClientTransaction(value.clientId);
        }
      });
    },
    updateClientTransData: (value, props) => {
      TransactionAPI.updateTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(updateTransactionFailure(error.msg));
          } else {
            dispatch(updateTransactionFailure(error));
          }
        } else {
          dispatch(updateTransactionSuccess(true));
          props.getClientTransaction(value.clientId);
        }
      });
    },

    setClientTransactionInputDataToEdit: (selectedTransData) =>
      dispatch(setTransactionInputDataToEdit(selectedTransData)),

    // delete transaction
    deleteTransData: (transactionId, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.deleteTransaction(transactionId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteTransactionFailure(error.msg));
          } else {
            dispatch(deleteTransactionFailure(error));
          }
        } else {
          props.getClientTransaction(clientId);
          dispatch(deleteTransactionSuccess(transactionId));
        }
      });
    },

    fetchActivityLogByRefId: (id) => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLogByRefId(id, (response, error) => {
        if (response) {
          dispatch(fetchActivityLogByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchActivityLogByIdFailure(error.msg));
          } else {
            dispatch(fetchActivityLogByIdFailure(error));
          }
        }
      });
    },
    // NOTES
    handleChangeToOpenNoteModal: (bool) => {
      dispatch(handleChangeToOpenNoteModal(bool));
    },
    handleChangeToNotes: (notes) => {
      dispatch(handleChangeToNotes(notes));
    },

    addNoteForTransaction: (notesData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.addTransactionNotes(notesData, (response, error) => {
        if (response) {
          dispatch(addNoteSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(addNoteFailure(error.msg));
          } else {
            dispatch(addNoteFailure(error));
          }
        }
      });
    },

    //description
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },

    //DESCRIPTION
    addDescription: (description, clientId, props) => {
      dispatch(transactionRequestMade(true));
      // dispatch(clientRequestMade(true));
      TransactionAPI.addDescription(description, (response, error) => {
        if (response) {
          dispatch(updateDescriptionSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(addNoteFailure(error.msg));
          } else {
            dispatch(addNoteFailure(error));
          }
        }
      });
    },

    //DESCRIPTION
    handleChangeDescData: (id, value) => {
      dispatch(handleChangeDescData(id, value));
    },

    closeTransactionSnackBar: (bool) => {
      dispatch(closeTransactionSnackBar(bool));
    },
    //schedule transaction
    getAmountDetailByClientId: (clientId) => {
      TransactionAPI.getAmountDetailByClientId(clientId, (response, error) => {
        if (response) {
          dispatch(getAmountDetailByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAmountDetailByClientIdFailure(error.msg));
          } else {
            dispatch(getAmountDetailByClientIdFailure(error));
          }
        }
      });
    },
    clearAmountDetail: () => {
      dispatch(clearAmountDetail());
    },
    clearTransactionForm: () => {
      dispatch(clearCreateTransactionForm());
    },
    clearFileData: () => {
      dispatch(clearFileData());
    },
    handleChangeBlockedTransData: (id, value) => {
      dispatch(handleChangeBlockedTransData(id, value));
    },
    clearEditBlockedTrans: () => {
      dispatch(clearEditBlockedTrans());
    },
    // edit blocked transaction
    editBlockedTransaction: (data, clientId, props) => {
      TransactionAPI.updateBlockedTrans(data, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },
    updateCheckStatus: (checkData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateCheck(checkData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },

    // filter transaction
    handleTransactionFilter: (id, value) => {
      dispatch(handleTransactionFilter(id, value));
    },

    clearTransactionFilter: () => {
      dispatch(clearTransactionFilter());
    },
    clearTransactionMainFilter: () => {
      dispatch(clearTransactionMainFilter());
    },
    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getTransConfigFailure(error.msg));
          } else {
            dispatch(getTransConfigFailure(error));
          }
        } else {
          dispatch(getTransConfigSuccess(response.configs));
        }
      });
    },
    saveInConfig: (data) => {
      TransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveTransConfigFailure(error.msg));
          } else {
            dispatch(saveTransConfigFailure(error));
          }
        } else {
          dispatch(saveTransConfigSuccess(response));
        }
      });
    },

    handlChangeReverseDate: (date) => {
      dispatch(handlChangeReverseDate(date));
    },
  };
};

export const IndividualClientTransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualClientTransactionScreen);
