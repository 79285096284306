import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuList,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Popover from "@material-ui/core/Popover";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import MySnackbarContentWrapper from "../components/Snackbar";
import HomeIcon from "@material-ui/icons/Home";
import { DateRangeIcon } from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";

class UserScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogueForAddUser: false,
      openDialogueForEditUser: false,
      openDeleteUserDialogue: false,
      openDialogueForResetPassword: false,
      openDialogueForUpdateUserRole: false,
      popoverElement: null,
      superAdminTab: true,
      adminTab: false,
      agentTab: false,
      corporateTab: false,
      isFirstSetting: true,
      dataToDisplay: [],
      user: "",
      userId: "",
      openUserRoleDropdown: false,
      openActionMenu: false,
      accountType: "",
      userRole: -1,
      page: 0,
      rowsPerPage: 10,
      sortType: "asc",
      isBlocking: false,
      clicked: false,
      setValue: false,
      errors: {
        fullName: "",
        emailPhone: "",
      },
    };
  }

  componentDidMount() {
    this.props.fetchUserList();
    this.props.handleChangeAddUser("userRole", "1");
  }

  componentWillMount() {
    this.setState({
      superAdminTab: true,
      adminTab: false,
      agentTab: false,
      corporateTab: false,
    });
  }

  componentWillUnmount() {
    this.props.handleSnackBarClose();
    this.props.handleChangeAddUser("userRole", "1");
  }

  render() {
    const { errors } = this.state;
    const loading =
      this.props.state.userState && this.props.state.userState.loading;
    const handleOpenDialogueForAddUser = () => {
      this.setState(
        {
          openDialogueForAddUser: true,
          accountType: "",
          errors: {
            fullName: "",
            emailPhone: "",
          },
        },
        () => {
          this.props.handleChangeAddUser("fullName", "");
          this.props.handleChangeAddUser("emailPhone", "");
        }
      );
    };

    const handleCloseDialogueForAddUser = () => {
      this.setState({
        openDialogueForAddUser: false,
      });
    };

    const handleConfirmationSaveForAddUser = () => {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
        });
        this.props.addNewUser(this.props.state.userState.userData, this.props);
        setTimeout(() => {
          this.setState({
            clicked: false,
          });
        }, 3000);
      }
    };

    const handleClickOpenDeleteUserDialogue = (userId) => {
      this.setState({
        openDeleteUserDialogue: true,
        userId: userId,
      });
    };

    const handleCloseDeleteUserDialogue = () => {
      this.setState({
        openDeleteUserDialogue: false,
        userId: "",
      });
    };

    const handleOkDeleteUserDialogue = () => {
      this.props.deleteUser(this.state.user.userId, this.props);
      this.setState({
        openDeleteUserDialogue: false,
        userId: "",
      });
    };

    //user role
    const handleOpenUpdateUserRoleDialogue = () => {
      this.setState({ openDialogueForUpdateUserRole: true });
    };
    const handleCloseUpdateUserRoleDialogue = () => {
      this.setState({ openDialogueForUpdateUserRole: false });
    };

    //reset password
    const handleOpenResetPasswordDialogue = () => {
      this.setState({
        openDialogueForResetPassword: true,
        // user: user
      });
    };
    const handleCloseResetPasswordDialogue = () => {
      this.setState({ openDialogueForResetPassword: false });
    };

    const userState = this.props.state.userState;
    let userRoleSuperAdmin = {};
    let userRoleAdmin = {};
    let userRoleAgent = {};

    if (userState.user && userState.user.length >= 1) {
      userRoleSuperAdmin = userState.user.filter(
        (e) => e.userRole === "SUPER_ADMIN"
      );
      userRoleAdmin = userState.user.filter((e) => e.userRole === "ADMIN");
      userRoleAgent = userState.user.filter((e) => e.userRole === "AGENT");
    }

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
    };

    const value =
      this.props.state.userState.userData.user.fullName !== "" &&
      this.props.state.userState.userData.user.emailPhone !== "";

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
    };

    // const sortedUserList = this.props.state.userState.dataToDisplay !== "" ?
    // this.props.state.userState.dataToDisplay &&
    //     this.props.state.userState.dataToDisplay.sort((a, b) => {
    //         const data = (this.state.sortType === 'asc') ? 1 : -1;
    //         return data * a.fullName.localeCompare(b.fullName)
    //     }) : [];
    const sortedUserList = this.props.state.userState.dataToDisplay !== "" ?
    this.props.state.userState.dataToDisplay : [];

    return (
      <React.Fragment>
        <HelmetComponent title="User" />
        {this.props.state.userState.loading ? (
          <div
            className={
              this.props.state.userState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {this.props.state.userState.addUserSuccess === true
          ? this.setState(
              {
                openDialogueForAddUser: false,
                openDialogueForEditUser: false,
              },
              () => {
                this.props.setAddUserSuccessToFalse();
                this.props.setUpdateUserSuccessToFalse();
              }
            )
          : null}
        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            User
          </Typography>
        </Breadcrumbs>

        <div className={"d-flex flex-row-reverse"} style={style.flex}>
          <div>
            <button
              style={style.settingButton}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                });
              }}
            >
              <img
                alt={"setting"}
                src={"assets/images/sort.png"}
                style={{ width: "18px", height: "18px" }}
              />
            </button>
          </div>
          <div>
            <button
              style={style.addButton}
              onClick={handleOpenDialogueForAddUser}
              hidden={
                this.props.state.profileState.profileData &&
                this.props.state.profileState.profileData.userRole ===
                  "ADMIN" &&
                this.state.superAdminTab
              }
            >
              <AddIcon />
              Add User
            </button>
          </div>

          <div className={"row w-100"} style={style.tab}>
            <button
              id={"buttonTab"}
              disabled={this.state.superAdminTab}
              style={
                this.state.superAdminTab ? style.buttonActive : style.button
              }
              onClick={(e) => {
                e.preventDefault();
                this.props.setUserRoleTabToEdit(userRoleSuperAdmin);
                this.setState({
                  superAdminTab: !this.state.superAdminTab,
                  adminTab: false,
                  agentTab: false,
                  corporateTab: false,
                  userRole: "1",
                });
                this.props.handleChangeAddUser("userRole", "1");
              }}
            >
              Super Admin
            </button>
            <button
              id={"buttonTab"}
              disabled={this.state.adminTab}
              style={this.state.adminTab ? style.buttonActive : style.button}
              onClick={(e) => {
                e.preventDefault();
                this.props.setUserRoleTabToEdit(userRoleAdmin);
                this.setState({
                  superAdminTab: false,
                  adminTab: !this.state.adminTab,
                  agentTab: false,
                  corporateTab: false,
                  userRole: "2",
                });
                this.props.handleChangeAddUser("userRole", "2");
              }}
            >
              Admin
            </button>
            <button
              id={"buttonTab"}
              disabled={this.state.agentTab}
              style={this.state.agentTab ? style.buttonActive : style.button}
              onClick={(e) => {
                e.preventDefault();
                this.props.setUserRoleTabToEdit(userRoleAgent);
                this.setState({
                  superAdminTab: false,
                  adminTab: false,
                  agentTab: !this.state.agentTab,
                  corporateTab: false,
                  userRole: "3",
                });
                this.props.handleChangeAddUser("userRole", "3");
              }}
            >
              Agent
            </button>
          </div>
        </div>
        <div style={style.container}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead style={{ padding: "1rem" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#8BC83F",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "left",
                      height: "48px",
                      width: "100px",
                    }}
                  />
                  <TableCell style={style.tableHeader}>Name</TableCell>
                  <TableCell style={style.tableHeader}>Email</TableCell>
                  <TableCell style={style.tableHeader}>Account Type</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              {sortedUserList === {} ? null :
               sortedUserList.length > 0 &&
                sortedUserList
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((user) => {
                    return (
                      <TableBody key={user.userId}>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              height: "62px",
                              width: "100px",
                            }}
                          >
                            <Avatar
                              alt="Profile"
                              src={user.profilePicUrl}
                              style={{
                                width: "39px",
                                height: "39px",
                                marginLeft: "28px",
                              }}
                            />
                          </TableCell>
                          <TableCell style={style.tableBody}>
                            {user.fullName}
                          </TableCell>
                          <TableCell style={style.tableBody}>
                            {user.emailPhone}
                          </TableCell>
                          <TableCell style={style.tableBody}>
                            {user.accountType === "EVERFUND_USER"
                              ? "Everfund User"
                              : user.accountType === "Super_Admin"
                              ? "Super Admin"
                              : user.accountType}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "center",
                              width: "130px",
                            }}
                          >
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  user: user,
                                  userRole: user.userRole,
                                  openActionMenu: true,
                                  userId: user.userId,
                                });
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              {user.userRole === "AGENT" ? (
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const accountId = this.state.user.accountId;
                                    this.props.history.push({
                                      pathname:
                                        "/agent/calendar/" + this.state.userId,
                                      state: { accountId },
                                    });
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DateRangeIcon />
                                  View Calendar
                                </MenuList>
                              ) : null}
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenUpdateUserRoleDialogue();
                                  this.setState({ openActionMenu: false });
                                }}
                              >
                                <EditOutlinedIcon />
                                Edit Role
                              </MenuList>
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenResetPasswordDialogue();
                                  this.setState({ openActionMenu: false });
                                }}
                              >
                                <img
                                  src="/assets/images/reset_password.svg"
                                  alt="reset"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "4px",
                                    color: "red",
                                  }}
                                />
                                Reset
                              </MenuList>
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleClickOpenDeleteUserDialogue();
                                  this.setState({ openActionMenu: false });
                                }}
                              >
                                <DeleteOutlinedIcon
                                  style={{ paddingBottom: 0 }}
                                />{" "}
                                Delete
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
            </Table>
            {sortedUserList && sortedUserList.length > 0 ? (
              <div style={{ justifyContent: "flex-start", width: "100%" }}>
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedUserList.length > 0 ? sortedUserList.length : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
          </TableContainer>
        </div>

        {/*Add new user*/}
        <Dialog
          open={this.state.openDialogueForAddUser}
          onClose={handleCloseDialogueForAddUser}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForAddUser}
          >
            Add User
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              width: "450px",
              borderBottom: "none",
              paddingTop: "5px",
            }}
          >
            <DialogContentText>
              <div
                className={"row"}
                style={{ paddingRight: "12px", paddingLeft: "12px" }}
              >
                <label className="label">
                  Full Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  id="fullName"
                  name="fullName"
                  onChange={(e) => {
                    this.setState({ isBlocking: true });
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeAddUser(e.target.id, e.target.value);
                  }}
                />
                {errors.fullName.length > 0 && (
                  <span className="error">{errors.fullName}</span>
                )}
              </div>
              <div
                className="row"
                style={{ paddingRight: "12px", paddingLeft: "12px" }}
              >
                <label className="label">
                  Email
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  className="inputField"
                  autoComplete="off"
                  id="emailPhone"
                  name="emailPhone"
                  onChange={(e) => {
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeAddUser(e.target.id, e.target.value);
                  }}
                />
                {errors.emailPhone.length > 0 && (
                  <span className="error">{errors.emailPhone}</span>
                )}
              </div>
              <div className="row mt-2">
                <div className={"col-6"}>
                  <label className="label">
                    Account Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div style={style.select}>
                    <select
                      disabled={true}
                      className="custom-select"
                      style={style.selectBar}
                      value={"EVERFUND_USER"}
                    >
                      <option selected disabled hidden />
                      <option value="EVERFUND_USER">Everfund User</option>
                    </select>
                    {/*<ExpandMoreOutlinedIcon style={style.expandMoreIcon}/>*/}
                  </div>
                </div>
                <div className="col-6">
                  <label className="label">
                    User Role<span style={{ color: "red" }}> *</span>
                  </label>
                  <div style={style.select}>
                    <select
                      className="custom-select"
                      style={style.selectBar}
                      disabled={true}
                      value={
                        this.state.superAdminTab
                          ? 1
                          : this.state.adminTab
                          ? 2
                          : this.state.agentTab
                          ? 3
                          : ""
                      }
                    >
                      <option selected disabled hidden />
                      <option value="1">Super Admin</option>
                      <option value="2">Admin</option>
                      <option value="3">Agent</option>
                    </select>
                    {/*<ExpandMoreOutlinedIcon style={style.expandMoreIcon}/>*/}
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
            <ButtonComponent
              value={value}
              loading={loading}
              onClick={(e) => {
                e.preventDefault();
                handleConfirmationSaveForAddUser();
              }}
              buttonName={" Add user"}
            />
          </DialogActions>
        </Dialog>

        {/*Reset Password*/}
        <Dialog
          open={this.state.openDialogueForResetPassword}
          onClose={handleCloseResetPasswordDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitle
              id="draggable-dialog-title"
              style={{ cursor: "move", height: "64px" }}
            >
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.6rem",
                  textAlign: "center",
                }}
              >
                Reset Password
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to reset password?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "0.8rem",
                    paddingTop: "2rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseResetPasswordDialogue();
                      }}
                      style={style.cancelButton}
                    >
                      Cancel
                    </Button>
                  </div>

                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseResetPasswordDialogue();

                        this.props.resetPasswordBySuperAdmin(
                          this.state.user.emailPhone
                        );
                      }}
                      style={style.addUserButton}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*Delete user*/}
        <Dialog
          open={this.state.openDeleteUserDialogue}
          onClose={handleCloseDeleteUserDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitle
              id="draggable-dialog-title"
              style={{ cursor: "move", height: "64px" }}
            >
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.6rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to delete user?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "0.8rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteUserDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteUserDialogue();
                      }}
                      style={style.addUserButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*Update user role*/}
        <Dialog
          open={this.state.openDialogueForUpdateUserRole}
          onClose={handleCloseUpdateUserRoleDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseUpdateUserRoleDialogue}
            >
              Update User
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                width: "450px",
                borderBottom: "none",
              }}
            >
              <DialogContentText>
                <div className={"row"} style={style.rowContent}>
                  <label style={style.label}>
                    Email
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    id="emailPhone"
                    readOnly
                    value={this.state.user.emailPhone}
                    style={style.input}
                  />
                </div>

                <div className={"row"} style={style.rowContent}>
                  <label style={style.label}>
                    User Role
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div style={{ position: "relative", width: "100%" }}>
                    <select
                      className="custom-select"
                      style={{
                        border: "0",
                        backgroundColor: "#F4F5F9",
                        color: "rgb(130, 132, 129)",
                        fontSize: "16px",
                        height: "42px",
                        width: "100%",
                      }}
                      value={this.state.userRole}
                      onChange={(e) => {
                        this.setState({
                          userRole: e.target.value,
                        });
                        this.props.handleChangeAddUser(
                          "userRole",
                          e.target.value
                        );
                      }}
                    >
                      <option selected disabled hidden />
                      <option value="SUPER_ADMIN">Super Admin</option>
                      <option value="ADMIN">Admin</option>
                      <option value="AGENT">Agent</option>
                    </select>
                    <ExpandMoreOutlinedIcon style={style.expandMoreIcon} />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
              <ButtonComponent
                noValue
                loading={loading}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    openDialogueForUpdateUserRole: false,
                    superAdminTab: this.state.userRole === "SUPER_ADMIN",
                    adminTab: this.state.userRole === "ADMIN",
                    agentTab: this.state.userRole === "AGENT",
                    userRole: this.state.userRole,
                  });
                  this.props.handleChangeAddUser(
                    "userRole",
                    this.state.userRole
                  );
                  this.props.updateUserRole(
                    this.state.user.userId,
                    this.state.userRole,
                    this.props
                  );
                }}
                buttonName="Update"
              />
            </DialogActions>
          </div>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.userState.openUserSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.state.userState.errorMsgForUser}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.userState.openPasswordResetSuccessSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="success"
            message={"Password Reset Success"}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  flex: {
    marginTop: "62px",
    marginLeft: "3%",
    marginRight: "3%",
    paddingRight: 0,
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "145px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  tab: {
    marginTop: "1%",
    marginRight: "3%",
    marginLeft: "0",
  },
  container: {
    background: "#ffffff",
    marginLeft: "3%",
    marginRight: "3%",
    // outline:'1px solid #E9E9E9',
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  button: {
    marginRight: "25px",
    // width: "177px",
    width: "18%",
    height: "43px",
    background: "white",
    color: "#464646",
    border: "none",
    fontSize: "18px",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
    borderTop: "none",
    // borderBottom:'none'
  },
  buttonActive: {
    marginRight: "25px",
    width: "18%",
    height: "43px",
    background: "white",
    color: "#464646",
    border: "none",
    fontSize: "18px",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    boxShadow:
      "rgba(77, 175, 89, 0.2) 0px -1px 1px 1px, rgba(0,0,0,0.1) 0px -1px 1px 0px",
    borderTop: "3px solid #8BC83F",
    // borderLeft:'1px solid #8BC83F',
    // borderRight:'1px solid #8BC83F'
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "62px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  rowContent: {
    paddingBottom: "12px",
    paddingLeft: "12px",
    paddingRight: "12px",
  },
  dialogueContent: {
    background: "white",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    paddingRight: "1rem !important",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  disabledLabel: {
    color: "#a8a8a8",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  select: {
    position: "relative",
    height: "40px",
  },
  selectBar: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "16px",
    height: "40px",
  },
  expandMoreIconHead: {
    position: "absolute",
    right: "5px",
    top: "5px",
    color: "#C8C5C5",
    fontSize: "25px",
  },
  expandMoreIcon: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  link: {
    color: "#8BC83F",
  },
};

export default UserScreen;
