import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "./calendarStyle.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { Button, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import { HelmetComponent } from "../components/Helmet";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {getFormattedDateTimeValue} from "../utils/DateConverter";
import {Select} from "antd";

const {Option} = Select;


moment.locale("en-GB");
const localizer = momentLocalizer(moment);

class CalendarViewOfAgentScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalToAddEvent: false,
      end: "",
      start: "",
      currentView: "month",
      value: moment(),
      text: '',
      datestate: 0,
      formatState: 'DD/'
    };
  }

  componentDidMount() {
    const userId = this.props.match.params.userId;
    const accountId = this.props.state.accountId;
    this.props.fetchDataListForCalendarByAgentId(userId);
    this.props.fetchHolidaysList();
    if (accountId === undefined) {
      this.props.getClientAssignedEvent(userId);
    } else {
      this.props.getClientAssignedEvent(accountId);
    }
  }

  render() {
    const DateTimeString = (dateToConvert) => {
      return new Date(parseInt(dateToConvert));
    };

    const userId = this.props.match.params.userId;
    const calendarState = this.props.state.calendarState;
    const calendarDetails = calendarState && calendarState.details;

    const settingsState = this.props && this.props.state.settingState;
    const holidayName = settingsState && settingsState.eventName;
    const calendarType = settingsState && settingsState.calendarType;
    const value = holidayName !== "";
    const holidayDetails = settingsState && settingsState.holidayDetails;
    const eventStyleGetter = (event) => {
      var backgroundColor =
        this.state.currentView === "agenda" ? "#ffffff" : event.hexColor;
      var color = this.state.currentView === "agenda" ? "#000000" : "#ffffff";
      var style = {
        backgroundColor: backgroundColor,
        color: color,
        borderRadius: "5px",
        minHeight: "5%",
      };
      return {
        style: style,
      };
    };

    const allHolidayEventsList = holidayDetails
      ? holidayDetails.map((item) => {
          const list = {};
          if (this.state.currentView === "month") {
            list.title =
              new Intl.DateTimeFormat("default", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(item.startDate) !==
              new Intl.DateTimeFormat("default", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(item.endDate)
                ? `
            ${new Intl.DateTimeFormat("default", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(item.startDate)} -
                    ${new Intl.DateTimeFormat("default", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(item.endDate)} ${item.eventName}`
                : ` ${item.eventName}`;
          } else {
            list.title = `${item.eventName}`;
          }
          list.start = DateTimeString(item.startDate);
          list.end = DateTimeString(item.endDate);
          list.hexColor = "#98ca69";
          list.id = item.calendarSettingId;
          return list;
        })
      : [
          {
            title: "",
            start: Date.now(),
            end: Date.now(),
            hexColor: "#EAF6FE",
            allDay: true,
          },
        ];
    const eventList = calendarDetails
      ? calendarDetails.map((item) => {
          const list = {};
          list.title = `${item.workFlowName}`;
          list.start = DateTimeString(item.scheduledDate);
          list.end = DateTimeString(item.scheduledDate);
          list.id = item.caseId;
          list.hexColor = "#69abca";
          list.workflowName = item.workFlowName;
          return list;
        })
      : [
          {
            title: "",
            start: Date.now(),
            end: Date.now(),
            hexColor: "#EAF6FE",
            allDay: true,
          },
        ];
    const allEvents = [...eventList, ...allHolidayEventsList];
    const handleOpenModalToAddEvent = ({ start, end }) => {
      this.props.clearModalData();
      this.setState({
          openModalToAddEvent: true,
          start: new Date(start).setHours(0, 0, 0),
          end: new Date(end).setHours(23, 59, 59, ),
      });
    };
    const handleCloseModalToAddEvent = () => {
      this.setState({
        openModalToAddEvent: false,
      });
    };

    return (
      <>
        <HelmetComponent title="Calendar" />
        {settingsState && settingsState.holidayAddSuccess === true
          ? this.setState(
              {
                openModalToAddEvent: false,
              },
              () => {
                this.props.setToFalse();
              }
            )
          : null}
        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/user" style={style.link}>
            Users
          </Link>
          <Typography color="inherit" className="link">
            Calendar
          </Typography>
        </Breadcrumbs>
        {this.props.state.calendarState.loading ? (
          <div
            className={
              this.props.state.calendarState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <div className="search-block">
          <div className="search">
            <div className="searchIcon">
              <SearchIcon style={{ color: "#9c9c9c" }} />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              className="inputBase"
              onChange={(e) => {
                e.preventDefault();
                if (e.target.value === "" || e.target.value === " ") {
                  this.props.fetchDataListForCalendarByAgentId(userId);
                } else {
                  this.props.searchCalendarDataByAgentId(
                    e.target.value,
                    userId,
                    this.props
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="calendar-wrapper">
          <Calendar
            localizer={localizer}
            events={allEvents}
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectSlot={handleOpenModalToAddEvent}
            messages={{ agenda: "Scheduled Task" }}
            onView={(e) => {
              this.setState({
                currentView: e,
              });
            }}
            popup
            eventPropGetter={eventStyleGetter}
          />
        </div>
        {/*add holiday*/}
        <Dialog
          open={this.state.openModalToAddEvent}
          onClose={handleCloseModalToAddEvent}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseModalToAddEvent}
            >
              Add Holiday/ Event
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                paddingTop: "0px",
                borderBottom: "none",
              }}
              className="dialogContent"
            >
                <div>
                    <label className="label">
                        Calendar Type
                        <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="select-input h40">
                        <Select
                            className="inputField"
                            showSearch={false}
                            getPopupContainer={(node) => node.parentNode}
                            onChange={(e) => {
                                this.props.handleChangeCalendarType(e);
                            }}
                        >
                            <Option value="HOLIDAY">Holiday</Option>
                            <Option value="EVENT">Event</Option>
                        </Select>
                    </div>
                </div>
                <div style={{marginTop: "12px"}}>
                    <label className="label">Holiday/ Event Name
                        <span style={{color: "red"}}> *</span>
                    </label>
                    <input
                        type='text'
                        id="holiday"
                        className="input"
                        autoComplete='off'
                        onChange={(e) => {
                            this.props.handleChangeHolidayName(e.target.value);
                        }}
                    />
                </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Start Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                        dateTimeFormat
                        isCalendarDate
                        dateValue={this.state.start && getFormattedDateTimeValue(this.state.start)}
                        onChangeDate={(date) => {
                          this.setState({
                            start: date,
                          })
                        }}
                    />
                  </div>
                </div>
                <div className="col-lg-12" style={{ marginTop: "12px" }}>
                  <label className="label">
                    End Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                        dateTimeFormat
                        isCalendarDate
                        dateValue={this.state.end && getFormattedDateTimeValue(this.state.end)}
                        onChangeDate={(date) => {
                          this.setState({
                            end: date,
                          })
                        }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "14px" }}>
                  <Button
                    style={{ float: "right" }}
                    disabled={!value}
                    className={value ? "addButton" : "disabledAddButton"}
                    onClick={(e) => {
                      e.preventDefault();
                      const data = {
                        calendarType: calendarType,
                        eventName: holidayName,
                        startDate: this.state.start,
                        endDate: this.state.end,
                      };
                      this.props.addHolidays(data, userId, this.props);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      </>
    );
  }
}
const style = {
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "100%",
    height: "40px",
  },
};
export default CalendarViewOfAgentScreen;
