import { connect } from 'react-redux';
import ManualCheckScreen from "../../screens/ManualCheckScreen";
import {checkPrintRequestMade, filterByBankFailure, filterByBankSuccess} from "../../actions/CheckPrintAction";
import {CheckPrintAPI} from "../../api";
import {setCheckPrintFailure, setCheckPrintSuccess} from "../../actions/ClientAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
  return{
      setCheckPrint : (checkPrintData) => {
          console.log({checkPrintData})
          dispatch(checkPrintRequestMade(true))
          CheckPrintAPI.setCheckPrint(checkPrintData, (response, error) => {
              if (error) {
                  if (error.msg) {
                      dispatch(setCheckPrintFailure(error.msg))
                  } else {
                      dispatch(setCheckPrintFailure(error));
                  }
              } else {
                  dispatch(setCheckPrintSuccess(response));
              }
          })
      },
  }
};

export const ManualCheckPrintScreenContainer = connect(mapStateToProps, mapDispatchToProps)(ManualCheckScreen);