import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import '../styles/styles.css';
import {
    Button, CircularProgress,
    MenuItem,
    MenuList,
    Popover, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MySnackbarContentWrapper from "../components/Snackbar";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {HelmetComponent} from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
class ShippingLabelScreen extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            openModalToAdd:false,
            openModalToUpdate:false,
            openModalToDelete:false,
            openDetailModal:false,
            page: 0,
            rowsPerPage: 10,
            shippingAddress: {},
            addressId: "",
            setValue: "",
            clicked: false,
        }
    }
    componentDidMount() {
        this.props.fetchShippingList()
    }

    render() {
        const shippingAddress = this.state.shippingAddress;
        const shippingState = this.props.state && this.props.state.shippingState;
        const shippingAddressList = shippingState && shippingState.shippingAddressList;
        const shippingData = shippingState && shippingState.shippingData;
        const loading = shippingState && shippingState.loading;
        const shippingValue = shippingData.address1 !== "" && shippingData.city !== "" && shippingData.state !== "" &&
            shippingData.zip5 !== "";
        const sortedAddressList = shippingAddressList ? shippingAddressList.sort((a, b) => {
            const data = (this.state.sortType === 'asc') ? 1 : -1;
            return data * a.createdAt.localeCompare(b.createdAt)
        }) : null;
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleOpenModalToAdd = () =>{
            this.setState({
                openModalToAdd: true,
            }, () => {
                this.props.setSettingAddressToEmpty();
            })
        }
        const handleCloseModalToAdd = () =>{
            this.setState({
                openModalToAdd: false,
            })
        }
        const handleOpenModalToUpdate = () =>{
            this.setState({
                openModalToUpdate: true,
            })
        }
        const handleCloseModalToUpdate= () =>{
            this.setState({
                openModalToUpdate: false,
            })
        }
        // const handleOpenModalToDelete = () =>{
        //     this.setState({
        //         openModalToDelete: true,
        //     })
        // }
        const handleCloseModalToDelete= () =>{
            this.setState({
                openModalToDelete: false,
            })
        }
        const handleOkToDeleteShippingAddress = () => {
            this.props.deleteShippingAddress(this.state.addressId, this.props);
        } ;

        const handleOpenDetailModal = () =>{
            this.setState({
                openDetailModal: true,
            })
        }
        const handleCloseDetailModal= () =>{
            this.setState({
                openDetailModal: false,
            })
        }

        const openDetail =  (list) => {
            handleOpenDetailModal();
            this.setState({
                shippingAddress: list,
            })
        }
        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString();
            return dateConverted;
        }
        return(
            <React.Fragment>
                <HelmetComponent title="Shipping"/>
                {shippingState && shippingState.loading ? (
                    <div
                        className={
                            shippingState && shippingState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                {shippingState && shippingState.success === true ? this.setState({
                    openModalToAdd: false,
                    openModalToUpdate: false
                }, ()=>{
                    if(this.state.setValue === "add"){
                        this.props.setAddShippingAddressToFalse();
                    }else if(this.state.setValue === "edit"){
                        this.props.setUpdateShippingAddressToFalse();
                    }

                }): null}

                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Typography color="inherit" className="link">
                        Address
                    </Typography>
                </Breadcrumbs>
                <div style={{margin: "48px 3% 1.5% 3%"}}>
                    <div className={"d-flex flex-row-reverse"} style={style.flex}>
                        <div>
                            <button className="printButton" style={{width: "156px"}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.push({
                                            pathname: "/address/shippinglabel"
                                        })
                                    }}>
                                Shipping Label
                            </button>
                        </div>
                        <div>
                            <button className="printButton" style={{width: "132px"}} onClick={(e) => {
                                e.preventDefault();
                                handleOpenModalToAdd();
                            }}>
                                + Add address
                            </button>
                        </div>
                        <div className="search">
                            <div className="searchIcon">
                                <SearchIcon style={{color: '#9c9c9c'}}/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                className="inputBase"
                                onChange={(e)=>{
                                    e.preventDefault();
                                    this.props.searchShipping(e.target.value);
                                }}/>
                        </div>
                    </div>

                    {/* <div style={{paddingTop: "70px"}}> */}
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header">
                            <Table size="small" stickyHeader aria-label="sticky table" ref={this.tableRef}>
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Address1
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Address2
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            City
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                           State
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                           Zip Code
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {sortedAddressList && sortedAddressList.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                ).map(list => (
                                    <TableBody key={list.addressId}>
                                        <TableRow>
                                            <TableCell className="first-tableBody cp" onClick={()=>{openDetail(list)}}>
                                                {list.address1}
                                            </TableCell>
                                            <TableCell className="tableBody cp" onClick={()=>{openDetail(list)}}>
                                                {list.address2}
                                            </TableCell>
                                            <TableCell className="tableBody cp" onClick={()=>{openDetail(list)}}>
                                                {list.city}
                                            </TableCell>
                                            <TableCell className="tableBody cp" onClick={()=>{openDetail(list)}}>
                                                {list.state}
                                            </TableCell>
                                            <TableCell className="tableBody cp" onClick={()=>{openDetail(list)}}>
                                                {list.zip5}
                                            </TableCell>
                                            <TableCell>
                                                <MoreHorizIcon
                                                    id="Popover1"
                                                    style={{cursor: 'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            popoverElement: e.currentTarget,
                                                            openActionMenu: true,
                                                            addressId: list.addressId,
                                                            shippingAddress: list,
                                                        })
                                                    }}
                                                />
                                                <Popover
                                                    open={this.state.openActionMenu}
                                                    anchorEl={this.state.popoverElement}
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        this.setState({openActionMenu: false})
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    style={{boxShadow: 'none'}}
                                                >
                                                    <MenuList id="popover-list" style={{
                                                        color: '#4d4d4d',
                                                        padding: '1rem !important',
                                                        cursor: 'pointer'
                                                    }}>
                                                        <MenuItem
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                handleOpenModalToUpdate();
                                                                this.props.setShippingAddrToEdit(this.state.shippingAddress)
                                                                this.setState({openActionMenu: false,}
                                                                );
                                                            }}>
                                                            <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                        </MenuItem>
                                                        {/*<MenuItem*/}
                                                        {/*    onClick={e => {*/}
                                                        {/*        e.preventDefault();*/}
                                                        {/*        handleOpenModalToDelete();*/}
                                                        {/*        // handleOpenDialogueToDeleteHoliday();*/}
                                                        {/*        this.setState({openActionMenu: false,}*/}
                                                        {/*        );*/}
                                                        {/*    }}>*/}
                                                        {/*    <DeleteOutlinedIcon style={{paddingBottom: 0}}/> Delete*/}
                                                        {/*</MenuItem>*/}
                                                    </MenuList>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>))}
                            </Table>
                            </TableContainer>
                            {sortedAddressList && sortedAddressList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            sortedAddressList.length > 0
                                                ? sortedAddressList.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                    {/* </div> */}
                </div>
                {/*add address*/}
                <Dialog
                    open={this.state.openModalToAdd}
                    onClose={handleCloseModalToAdd}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <DialogTitleComponent
                        id="draggable-dialog-title"
                        onClose={handleCloseModalToAdd}>
                        Add Address
                    </DialogTitleComponent>
                    {/*<DialogTitle id="draggable-dialog-title" style={{cursor: "move", height: "58px"}}>*/}
                    {/*    <h3 style={{color: '#8BC83F', padding: '0.6rem', textAlign: 'center'}}>*/}
                    {/*        Add address*/}
                    {/*    </h3>*/}
                    {/*</DialogTitle>*/}
                    <div>
                        <DialogContent dividers style={{paddingTop: "5px"}} className="dialogContent">
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">Address1<span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="address1"
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                                <div  className="col-lg-6">
                                    <label className="label">Address2</label>
                                    <input
                                        type='text'
                                        id="address2"
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">City <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="city"
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                                <div  className="col-lg-6">
                                    <label className="label">State <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="state"
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">Zip Code <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='number'
                                        id="zip5"
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="row"
                            >
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingLeft: "140px"}}>
                                    <ButtonComponent
                                        value={shippingValue}
                                        loading={loading}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({
                                                setValue: "add",
                                            })
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.addShippingAddress(shippingData, this.props);
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        buttonName="Add"
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*update address*/}
                <Dialog
                    open={this.state.openModalToUpdate}
                    onClose={handleCloseModalToUpdate}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseModalToUpdate}>
                            Update address
                        </DialogTitleComponent>
                        <DialogContent dividers style={{paddingTop: "5px"}} className="dialogContent">
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">Address1
                                        <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="address1"
                                        value={shippingData.address1}
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                                <div  className="col-lg-6">
                                    <label className="label">Address2</label>
                                    <input
                                        type='text'
                                        id="address2"
                                        value={shippingData.address2}
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">City
                                        <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="city"
                                        className="input"
                                        value={shippingData.city}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                                <div  className="col-lg-6">
                                    <label className="label">State
                                        <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="state"
                                        value={shippingData.state}
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">Zip Code <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='number'
                                        id="zip5"
                                        value={shippingData.zip5}
                                        className="input"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{
                                    padding: '1rem 0px 0px'
                                }}
                            >
                                <div className={"col-6"}/>
                                <div className={"col-6"}>
                                    <ButtonComponent
                                        loading={loading}
                                        value={shippingValue}
                                        onClick={e => {
                                            e.preventDefault();
                                            // handleCloseModalToUpdate();
                                            this.setState({
                                                setValue: "edit"
                                            })
                                            this.props.updateShippingAddress(shippingData, this.props);
                                        }}
                                        buttonName="Update"
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*DELETE*/}
                <Dialog
                    open={this.state.openModalToDelete}
                    onClose={handleCloseModalToDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <DialogTitle id="draggable-dialog-title">
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation</h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px'
                        }}>
                        <DialogContentText>
                            Do you want to delete this address?

                            <span className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '1rem',
                                paddingTop: '1.6rem'
                            }}>
                                <span className={"col-6"}>
                                    <Button autoFocus onClick={handleCloseModalToDelete}
                                            className="cancelButton">
                                        cancel
                                    </Button>
                                </span>

                                <span className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        handleOkToDeleteShippingAddress()
                                        handleCloseModalToDelete()
                                    }} className="addUserButton">
                                        ok
                                    </Button>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                {/*details*/}
                <Dialog
                    id='viewCheckDetails'
                    maxWidth="lg"
                    open={this.state.openDetailModal}
                    onClose={handleCloseDetailModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitleComponent
                        id="draggable-dialog-title"
                        onClose={handleCloseDetailModal}>
                        Shipping Address Details
                    </DialogTitleComponent>
                    <DialogContent
                        dividers
                        style={{
                            background: 'white',
                            textAlign: 'left',
                            // marginLeft: '1rem'
                        }}>
                        <DialogContentText>
                             <span className="row span-row mt-2">
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">Address1</label>
                                    <label className="data">
                                        {shippingAddress && shippingAddress.address1}
                                    </label>
                                </span>
                                  <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">Address2</label>
                                    <label className="data">
                                        {shippingAddress && shippingAddress.address2}
                                    </label>
                                </span>
                                <span className="col-4" >
                                    <label htmlFor="caseId" className="columnHeading">Address Type</label>
                                     <label  className="data">
                                         {shippingAddress && shippingAddress.addressType &&
                                         shippingAddress.addressType.replace("_", " ")}
                                     </label>
                                </span>
                            </span>
                             <span className="row span-row mt-4">
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">City</label>
                                    <label className="data">
                                        {shippingAddress && shippingAddress.city}
                                    </label>
                                </span>
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">State</label>
                                    <label className="data">
                                        {shippingAddress && shippingAddress.state}
                                    </label>
                                </span>
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">Zip5</label>
                                    <label className="data">
                                        {shippingAddress && shippingAddress.zip5}
                                    </label>
                                </span>
                          </span>
                             <span className="row span-row mt-4">
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">Careated Date</label>
                                    <label className="data">
                                        {shippingAddress && getDate(shippingAddress.createdAt)}
                                    </label>
                                </span>
                          </span>
                             <div hidden style={{marginTop:"20px", marginBottom: "12px"}}>
                                 <span className="row span-row">
                                            <span className={"col-4"}></span>
                                            <span className={"col-4"}></span>
                                            <span className={"col-4"}>
                                                <Button autoFocus
                                                        className="cancelButton"
                                                        onClick={() => {handleCloseDetailModal()}}>
                                                    cancel
                                                </Button>
                                            </span>
                                 </span>
                             </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={shippingState && shippingState.openShippingSuccessSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="success"
                        message={shippingState && shippingState.successMessage}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={shippingState && shippingState.openShippingErrorSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={shippingState && shippingState.error}
                    />
                </Snackbar>

            </React.Fragment>
        )
    }
}
const style = {
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        margin:"0 0px 1rem",
    },
    extraData: {
        color: '#625F5F',
        marginBottom: '0px',
        fontSize: '16px',
        textAlign: 'left !important',
        marginTop: '10px',
        fontWeight: '550',
        whiteSpace: "nowrap",
        width: "180px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    flex: {
        paddingBottom: "1rem",
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        // position: "fixed",
        // width: "100%",
        // background: "white",
        // zIndex: 1,
        // right: "2.5%",
    },
}
export default ShippingLabelScreen;