import {
  Button,
  CircularProgress,
  MenuList,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Popover from "@material-ui/core/Popover";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import HomeIcon from "@material-ui/icons/Home";
import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MySnackbarContentWrapper from "../components/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Helpers from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";

class CorporateScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openDialogueForAddUser: false,
      openDialogueForUpdateCorporateUser: false,
      openDeleteUserDialogue: false,
      popoverElement: null,
      openActionMenu: false,
      page: 0,
      rowsPerPage: 10,
      sortType: "asc",
      corporate: "",
      corporateId: "",
      errors: {
        taxVatNumber: "",
        registerNumber: "",
        name: "",
        emailPhone: "",
      },
    };
  }

  componentDidMount() {
    this.props.fetchCorporate();
  }

  componentWillUnmount() {
    this.props.handleSnackBarClose();
  }

  render() {
    const corporateState = this.props.state && this.props.state.corporateState;
    const loading = corporateState && corporateState.loading;
    const corporateData =
      corporateState && corporateState.corporateData.corporate;
    const corporateValue =
      corporateData.corporateName !== "" && corporateData.emailPhone !== "";
    const corporateList = corporateState && corporateState.corporate;
    const sortedCorporateList = corporateList
      ? corporateList.sort((a, b) => {
          const data = this.state.sortType === "asc" ? 1 : -1;
          return data * a.corporateName.localeCompare(b.corporateName);
        })
      : null;

    const { errors } = this.state;
    const handleClickOpenDeleteCorporateDialogue = (corporateId) => {
      this.setState({
        openDeleteUserDialogue: true,
        corporateId: corporateId,
      });
    };
    const handleCloseDeleteCorporateDialogue = () => {
      this.setState({
        openDeleteUserDialogue: false,
        corporateId: "",
      });
    };

    const handleOkDeleteCorporateDialogue = () => {
      this.props.deleteCorporate(this.state.corporate.corporateId, this.props);
      this.setState({
        openDeleteUserDialogue: false,
        corporateId: "",
      });
    };

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0
      });
    };

    const handleOpenDialogueForAddCorporateUser = () => {
      this.setState({
        openDialogueForAddUser: true,
        accountType: "",
        errors: {
          taxVatNumber: "",
          registerNumber: "",
          name: "",
          emailPhone: "",
        },
      });
    };

    const handleCloseDialogueForAddCorporateUser = () => {
      this.setState(
        {
          openDialogueForAddUser: false,
        },
        (e) => {
          this.props.setCoporateDataToAddEmpty();
        }
      );
    };

    const handleConfirmationSaveForAddCorporateUser = () => {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
        });
        console.log(
          "this.props.state.corporateState.corporateData,",
          this.props.state.corporateState.corporateData
        );
        this.props.addNewCorporate(
          this.props.state.corporateState.corporateData,
          this.props
        );
        setTimeout(() => {
          this.setState({
            clicked: false,
          });
        }, 3000);
      }
    };

    const handleOpenDialogueForUpdateCorporateUser = () => {
      this.setState({
        openDialogueForUpdateCorporateUser: true,
        errors: {
          taxVatNumber: "",
          registerNumber: "",
          name: "",
          emailPhone: "",
        },
      });
    };

    const handleCloseDialogueForUpdateCorporateUser = () => {
      this.setState({
        openDialogueForUpdateCorporateUser: false,
      });
    };

    const handleConfirmationSaveForUpdateCorporateUser = () => {
      this.props.updateCorporate(
        this.props.state.corporateState.corporateDataToEdit,
        this.props
      );
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Corporate" />
        {this.props.state.corporateState.loading ? (
          <div
            className={
              this.props.state.corporateState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        {this.props.state.corporateState.addCorporateSuccess === true
          ? this.setState(
              {
                openDialogueForAddUser: false,
                openDialogueForUpdateCorporateUser: false,
              },
              (e) => {
                this.props.fetchCorporate();
                this.props.setAddSucessToFalse();
              }
            )
          : null}
        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Corporate
          </Typography>
        </Breadcrumbs>

        <div className={"d-flex flex-row-reverse"} style={style.flex}>
          <div>
            <button
              style={style.settingButton}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                });
              }}
            >
              <img
                alt={"setting"}
                src={"assets/images/sort.png"}
                style={{ width: "18px", height: "18px" }}
              />
            </button>
          </div>
          <div>
            <button
              style={style.addButton}
              onClick={handleOpenDialogueForAddCorporateUser}
            >
              <AddIcon />
              Add Corporate User
            </button>
          </div>
          <div className="search">
            <div className="searchIcon">
              <SearchIcon style={{ color: "#9c9c9c" }} />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              className="inputBase"
              onChange={(e) => {
                e.preventDefault();
                this.props.searchCorporate(e.target.value);
              }}
            />
          </div>
        </div>

        <div style={style.container}>
          <TableContainer className="fixed-table-header">
            <Table size="small" stickyHeader aria-label="a dense table" ref={this.tableRef}>
              <TableHead style={{ padding: "1rem" }}>
                <TableRow>
                  <TableCell style={style.tableFirstHeader}>Name</TableCell>
                  <TableCell style={style.tableHeader}>Email</TableCell>
                  <TableCell
                    style={{
                      color: "#8BC83F",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "left",
                      height: "48px",
                      width: "200px",
                    }}
                  >
                    Account Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#8BC83F",
                      fontWeight: "bold",
                      fontSize: "15px",
                      textAlign: "center",
                      width: "130px",
                      height: "3rem",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>

              {sortedCorporateList &&
                sortedCorporateList
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((corporate) => {
                    return (
                      <TableBody>
                        <TableRow>
                          <TableCell style={style.tableFirstBody}>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push({
                                  pathname:
                                    "/corporate/client/" +
                                    corporate.corporateId,
                                  dataToPass: corporate.corporateName,
                                });
                              }}
                              style={{
                                border: "none",
                                background: "none",
                                color: "#626262",
                                padding: 0,
                                textDecoration: "underline",
                              }}
                            >
                              {corporate.corporateName}
                            </button>
                          </TableCell>
                          <TableCell style={style.tableBody}>
                            {corporate.emailPhone}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              width: "30%",
                              height: "62px",
                            }}
                          >
                            {corporate.accountType === "CORPORATE_CLIENT"
                              ? "Corporate User"
                              : corporate.accountType}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "center",
                              width: "30%px",
                            }}
                          >
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                this.setState(
                                  {
                                    popoverElement: e.currentTarget,
                                    corporate: corporate,
                                    openActionMenu: true,
                                  },
                                  () => {
                                    this.props.setCoporateToEdit(
                                      this.state.corporate
                                    );
                                  }
                                );
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogueForUpdateCorporateUser();
                                  this.setState({ openActionMenu: false });
                                }}
                              >
                                <EditOutlinedIcon />
                                Edit
                              </MenuList>
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleClickOpenDeleteCorporateDialogue();
                                  this.setState({ openActionMenu: false });
                                }}
                              >
                                <DeleteOutlinedIcon
                                  style={{ paddingBottom: 0 }}
                                />{" "}
                                Delete
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
            </Table>
            </TableContainer>
            {sortedCorporateList && sortedCorporateList.length > 0 ? (
              <div style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedCorporateList.length > 0
                      ? sortedCorporateList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div style={{     
                display: "flex", 
                width: "100%",
                justifyContent:"center"
                }}>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
          {/* </TableContainer> */}
        </div>

        {/*Add new Corporate User*/}
        <Dialog
          open={this.state.openDialogueForAddUser}
          onClose={handleCloseDialogueForAddCorporateUser}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueForAddCorporateUser}
            >
              New Corporate User
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                width: "450px",
                paddingTop: "5px",
              }}
            >
              <DialogContentText>
                <div className={"row"}>
                  <div className="col-12">
                    <label className="label">
                      Corporate Name
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      id="corporateName"
                      name="name"
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        Helpers.handleValidationOnChange(e, errors);
                        this.props.handleChangeCorporateToAdd(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    {errors.name.length > 0 && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className={"row mt-2"}>
                  <div className="col-12">
                    <label className="label">
                      Email
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      id="emailPhone"
                      name="emailPhone"
                      onChange={(e) => {
                        Helpers.handleValidationOnChange(e, errors);
                        this.props.handleChangeCorporateToAdd(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    {errors.emailPhone.length > 0 && (
                      <span className="error">{errors.emailPhone}</span>
                    )}
                  </div>
                </div>

                {/* <div className={"row mt-2"}>
                                    <div className={"col-6"}>
                                        <label className="label">Registration Number
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type="text"
                                            className="inputField"
                                            id="regNo"
                                            name="registerNumber"
                                            onChange={e =>
                                            {
                                                Helpers.handleValidationOnChange(e, errors);
                                                this.props.handleChangeCorporateToAdd(e.target.id, e.target.value)
                                            }}
                                        />
                                        {errors.registerNumber.length > 0 &&
                                        <span className="error">{errors.registerNumber}</span>}
                                    </div>
                                    <div className="col-6">
                                        <label className="label">
                                            Tax / Vat Number
                                            <span style={{color:"red"}}> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="inputField"
                                            id="taxVatNo"
                                            name="taxVatNumber"
                                            onChange={e =>{
                                                Helpers.handleValidationOnChange(e, errors);
                                                this.props.handleChangeCorporateToAdd(e.target.id, e.target.value)
                                            }}
                                        />
                                        {errors.taxVatNumber.length > 0 &&
                                        <span className="error">{errors.taxVatNumber}</span>}
                                    </div>
                                </div> */}

                <div className="row">
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingTop: "15px" }}>
                    <ButtonComponent
                      loading={loading}
                      value={corporateValue}
                      buttonName="Add user"
                      onClick={(e) => {
                        e.preventDefault();
                        handleConfirmationSaveForAddCorporateUser();
                      }}
                    />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*Update Corporate User*/}
        <Dialog
          open={this.state.openDialogueForUpdateCorporateUser}
          onClose={handleCloseDialogueForUpdateCorporateUser}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueForUpdateCorporateUser}
            >
              Update Corporate User
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                width: "450px",
                paddingTop: "5px",
              }}
            >
              <DialogContentText>
                <div className={"row"}>
                  <div className="col-12">
                    <label className="label">
                      Corporate Name
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="name"
                      value={
                        this.props.state.corporateState.corporateDataToEdit
                          ? this.props.state.corporateState.corporateDataToEdit
                              .corporateName
                          : null
                      }
                      id="corporateName"
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        Helpers.handleValidationOnChange(e, errors);
                        this.props.handleChangeCorporateToEdit(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    {errors.name.length > 0 && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div className={"row mt-2"}>
                  <div className="col-12">
                    <label className="label">
                      Email
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      disabled
                      className="inputField"
                      id="emailPhone"
                      name="emailPhone"
                      value={
                        this.props.state.corporateState.corporateDataToEdit
                          ? this.props.state.corporateState.corporateDataToEdit
                              .emailPhone
                          : null
                      }
                      onChange={(e) => {
                        Helpers.handleValidationOnChange(e, errors);
                        this.props.handleChangeCorporateToEdit(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    {errors.emailPhone.length > 0 && (
                      <span className="error">{errors.emailPhone}</span>
                    )}
                  </div>
                </div>
                {/* 
                <div className={"row mt-2"}>
                  <div className={"col-6"}>
                    <label className="label">
                      Registration Number
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      id="regNo"
                      name="registerNumber"
                      value={
                        this.props.state.corporateState.corporateDataToEdit
                          ? this.props.state.corporateState.corporateDataToEdit
                              .regNo
                          : null
                      }
                      onChange={(e) => {
                        Helpers.handleValidationOnChange(e, errors);
                        this.props.handleChangeCorporateToEdit(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    {errors.registerNumber.length > 0 && (
                      <span className="error">{errors.registerNumber}</span>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="label">
                      Tax / Vat Number
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      id="taxVatNo"
                      name="taxVatNumber"
                      value={
                        this.props.state.corporateState.corporateDataToEdit
                          ? this.props.state.corporateState.corporateDataToEdit
                              .taxVatNo
                          : null
                      }
                      onChange={(e) => {
                        Helpers.handleValidationOnChange(e, errors);
                        this.props.handleChangeCorporateToEdit(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                    {errors.taxVatNumber.length > 0 && (
                      <span className="error">{errors.taxVatNumber}</span>
                    )}
                  </div>
                </div> */}

                <div className="row">
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingTop: "15px" }}>
                    <ButtonComponent
                      loading={loading}
                      noValue
                      buttonName="Update"
                      onClick={(e) => {
                        e.preventDefault();
                        handleConfirmationSaveForUpdateCorporateUser();
                      }}
                    />
                  </div>
                </div>
              </DialogContentText>
              {/*<DialogContentText>*/}
              {/*    <div className={"row"} style={style.rowContent}>*/}
              {/*        <label style={style.label}>Corporate Name</label>*/}
              {/*        <input*/}
              {/*            type="text"*/}
              {/*            className="form-control"*/}
              {/*            id="corporateName"*/}
              {/*            value={this.props.state.corporateState.corporateDataToEdit ? this.props.state.corporateState.corporateDataToEdit.corporateName: null}*/}
              {/*            onChange={e => {*/}
              {/*                this.setState({isBlocking: true});*/}
              {/*                this.props.handleChangeCorporateToEdit(*/}
              {/*                    e.target.id,*/}
              {/*                    e.target.value*/}
              {/*                )*/}
              {/*            }*/}
              {/*            }*/}
              {/*            style={style.input}*/}
              {/*        />*/}
              {/*    </div>*/}
              {/*    <div className={"row"} style={style.rowContent}>*/}
              {/*        <label style={style.label}>Email</label>*/}
              {/*        <input*/}
              {/*            type="text"*/}
              {/*            className="form-control"*/}
              {/*            disabled={true}*/}
              {/*            value={this.props.state.corporateState.corporateDataToEdit ?*/}
              {/*                this.props.state.corporateState.corporateDataToEdit.emailPhone: null}*/}
              {/*            id="emailPhone"*/}
              {/*             onChange={e =>*/}
              {/*                 this.props.handleChangeCorporateToEdit(*/}
              {/*                     e.target.id,*/}
              {/*                     e.target.value*/}
              {/*                 )*/}
              {/*             }*/}
              {/*            style={style.input}*/}
              {/*        />*/}
              {/*    </div>*/}

              {/*    <div*/}
              {/*        className={"row"}*/}
              {/*        style={{paddingLeft: "10px", paddingRight: "10px"}}*/}
              {/*    >*/}
              {/*        <div className={"col-6"}>*/}
              {/*            <label style={style.label}>Registration Number</label>*/}
              {/*            <input*/}
              {/*                type="text"*/}
              {/*                className="form-control"*/}
              {/*                value={this.props.state.corporateState.corporateDataToEdit ? this.props.state.corporateState.corporateDataToEdit.regNo: null}*/}
              {/*                id="regNo"*/}
              {/*                onChange={e =>*/}
              {/*                    this.props.handleChangeCorporateToEdit(*/}
              {/*                        e.target.id,*/}
              {/*                        e.target.value*/}
              {/*                    )*/}
              {/*                }*/}
              {/*                style={style.input}*/}
              {/*            />*/}
              {/*        </div>*/}
              {/*        <div className="col-6">*/}
              {/*            <label*/}
              {/*                style={style.label}>*/}
              {/*                Tax / Vat Number*/}
              {/*            </label>*/}
              {/*            <input*/}
              {/*                type="text"*/}
              {/*                className="form-control"*/}
              {/*                id="taxVatNo"*/}
              {/*                value={this.props.state.corporateState.corporateDataToEdit ? this.props.state.corporateState.corporateDataToEdit.taxVatNo: null}*/}
              {/*                onChange={e =>*/}
              {/*                    this.props.handleChangeCorporateToEdit(*/}
              {/*                        e.target.id,*/}
              {/*                        e.target.value*/}
              {/*                    )*/}
              {/*                }*/}
              {/*                style={style.input}*/}
              {/*            />*/}
              {/*        </div>*/}
              {/*    </div>*/}

              {/*    <div*/}
              {/*        className="row"*/}
              {/*        style={{*/}
              {/*            padding: '2.5rem 10px 1.5rem'*/}
              {/*        }}*/}
              {/*    >*/}
              {/*        <div className={"col-6"}>*/}
              {/*            <Button*/}
              {/*                onClick={e => {*/}
              {/*                    e.preventDefault();*/}
              {/*                    handleCloseDialogueForUpdateCorporateUser();*/}
              {/*                }}*/}
              {/*                style={style.cancelButton}*/}
              {/*            >*/}
              {/*                Cancel*/}
              {/*            </Button>*/}
              {/*        </div>*/}
              {/*        <div className={"col-6"}>*/}
              {/*            <Button*/}
              {/*                // disabled={!value}*/}
              {/*                onClick={e => {*/}
              {/*                    e.preventDefault();*/}
              {/*                    handleConfirmationSaveForUpdateCorporateUser();*/}
              {/*                }}*/}
              {/*                style={style.addUserButton}*/}
              {/*                // style={!value ? style.addUserDisableButton : style.addUserButton}*/}
              {/*            >*/}
              {/*                Update*/}
              {/*            </Button>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</DialogContentText>*/}
            </DialogContent>
          </div>
        </Dialog>

        {/*Delete user*/}
        <Dialog
          open={this.state.openDeleteUserDialogue}
          onClose={handleCloseDeleteCorporateDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitle
              id="draggable-dialog-title"
              style={{ cursor: "move", height: "64px" }}
            >
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.6rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to delete corporate user?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "0.8rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteCorporateDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteCorporateDialogue();
                      }}
                      style={style.addUserButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.corporateState.openUserSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.state.corporateState.errorMsgForCorporate}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  flex: {
    marginTop: "60px",
    marginLeft: "3%",
    marginRight: "3%",
    paddingRight: 0,
    marginBottom: "12px",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "195px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  tab: {
    marginTop: "1%",
    marginRight: "3%",
    marginLeft: "0",
  },
  container: {
    background: "#ffffff",
    marginLeft: "3%",
    marginRight: "3%",
    // outline:'1px solid #E9E9E9',
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "62px",
    width: "30%",
  },
  tableFirstHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "48px",
    paddingLeft: "3rem",
  },
  tableFirstBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "62px",
    paddingLeft: "3rem",
    width: "30%",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  dialogueContent: {
    background: "white",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    paddingRight: "1rem !important",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  disabledLabel: {
    color: "#a8a8a8",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
};

export default CorporateScreen;
