import { combineReducers } from "redux";
import loginState from "./LoginReducer";
import clientState from './ClientReducer';
import caseState from "./CaseReducer";
import userState from "./UserReducer";
import workflowState from "./WorkflowReducer";
import profileState from "./ProfileReducer";
import workflowFormState from "./WorkflowFormReducer";
import caseTemplateState from "./CaseTemplateReducer";
import resetPasswordState from "./ResetPasswordReducer"
import actionState from "./ActionReducer";
import registerCaseState from "./RegisterCaseReducer";
import dynamicActionState from "./DynamicActionReducer";
import corporateState from "./CorporateReducer";
import corporateClientState from "./CorporateClientReducer";
import scheduleTransactionState from "./ScheduleTransactionReducer";
import vendorState from "./VendorReducer";
import cardState from "./CardReducer";
import transactionState from "./TransactionReducer";
import dynamicActionClientState from "./DynamicActionClientPageReducer";
import activityLogState from "./ActivityLogReducer";
import calendarState from "./CalendarReducer";
import upcomingTransactionState from "./UpComingTransactionReducer";
import settingState from "./SettingReducer";
import checkPrintState from "./CheckPrintReducer";
import shippingState from "./ShippingLabelReducer";
import contactState from "./ContactReducer";
import dashBoardState from "./DashboardReducer";
import emailTemplateState from "./EmailTemplateReducer";
import documentState from "./DocumentsReducer";

export default combineReducers({
    loginState,
    caseState,
    clientState,
    userState,
    workflowState,
    profileState,
    workflowFormState,
    caseTemplateState,
    resetPasswordState,
    actionState,
    registerCaseState,
    dynamicActionState,
    corporateState,
    corporateClientState,
    scheduleTransactionState,
    vendorState,
    cardState,
    transactionState,
    dynamicActionClientState,
    activityLogState,
    calendarState,
    upcomingTransactionState,
    settingState,
    checkPrintState,
    shippingState,
    contactState,
    dashBoardState,
    emailTemplateState,
    documentState,
})