import { ACTION_TYPE_RESET_PASSWORD } from "../constants/Type";

export const handleChangeForResetPassword = (id, value) => {
    return {
        type: ACTION_TYPE_RESET_PASSWORD.HANDLE_CHANGE_FOR_RESET_PASSWORD_REQUEST,
        id, value
    };
};

export const fetchUserInfoSuccess = (userInfo) => {
    return {
        type: ACTION_TYPE_RESET_PASSWORD.FETCH_USER_INFO_SUCCESS,
        userInfo
    };
};

export const changeFirstPasswordSuccess = (bool) => {
    return {
        type: ACTION_TYPE_RESET_PASSWORD.RESET_FIRST_PASSWORD_SUCCESS,
        bool
    };
};

export const changeFirstPasswordFailure = (error) => {
    return {
        type: ACTION_TYPE_RESET_PASSWORD.RESET_FIRST_PASSWORD_FAILURE,
        error
    };
};

export const apiRequestMadeForResetPassword = (bool) => {
    return {
        type: ACTION_TYPE_RESET_PASSWORD.REQUEST_MADE_FOR_REQUEST_PASSWORD_RESET,
        bool
    };
};

export const closeSnackBarResetPasswordScreen = (bool) => {
    return {
        type: ACTION_TYPE_RESET_PASSWORD.CLOSE_SNACK_BAR_RESET_PASSWORD_PAGE,
        bool
    };
};