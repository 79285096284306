import React, {Component} from "react";
import {Select} from "antd";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {Checkbox} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const {Option} = Select;

class ActionEditFormFieldsComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeConditionalId: "",
            activeOptionName: "",
            activeRefId: "",
            conditionalForm: this.props.conditionalActionForm &&
            this.props.conditionalActionForm.conditionalForms ?   this.props.conditionalActionForm.conditionalForms :[] ,
            updatedConditionalForm:  this.props.conditionalActionForm &&
            this.props.conditionalActionForm.conditionalForms ?   this.props.conditionalActionForm.conditionalForms :[]
        }
    }

    showInputForField = () => {
        let a = this.createAddFormObject();
        this.setState({
            conditionalForm: [...this.state.conditionalForm, a]
        });
    };


    createAddFormObject = () => {
        return {
            conditionalFormId: String(Math.random()),
            fieldName: '',
            fieldType: '',
            isRequired: false,
            orderNo: this.state.conditionalForm.length + 1
        };
    };

    render() {
        // const conditionalFormByRefId = this.props.conditionalFormByRefId;
        const optionValues = this.props.optionValues;
        const conditionalActionForm = this.props.conditionalActionForm;
        const currentRefId = this.props.activeRefId;
        const currentActionId = this.props.activeActionFormId;
        const currentOptionId= this.props.activeOptionId;
        const currentOptionName = this.props.activeOptionName;

        const openDropdownForTextBox = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.conditionalForm.length > 0) {
                div.push(<div className="row" style={style.fieldLabel}>
                    <div className="col-4" style={{position: 'inherit'}}>
                        Field Title
                    </div>
                    <div className="col-4" style={{position: 'inherit', paddingLeft: '20px'}}>
                        Field Type
                    </div>
                    <div className="col-3" style={{position: 'inherit'}}>
                        Is Required
                    </div>
                </div>);
            }
            if (this.state.conditionalForm[0] !== undefined && this.state.conditionalForm[0].conditionalFormId) {
                this.state.conditionalForm.map(single => {
                    const value = single.isRequired ?? false;
                    return (
                        div.push(<>
                            <div className="row">
                                <div className="col-4" style={{position: 'inherit'}}>
                                    <input type="text"
                                           id={single.conditionalFormId}
                                           value={single.fieldName}
                                           onChange={(e) => {
                                               e.preventDefault();
                                               this.setState({isBlocking: true});
                                               changeFieldNameValue(single.conditionalFormId, e.target.value);
                                               changeConditionalFormId(single.conditionalFormId);
                                           }}
                                           style={style.dropdownInput}/>
                                </div>
                                <div className="col-4" style={{position: 'inherit'}}>
                                    <div style={style.select}>
                                        <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                            <Select
                                                showSearch
                                                getPopupContainer={node => node.parentNode}
                                                id={single.conditionalFormId}
                                                className="drop"
                                                value={single.fieldType}
                                                onChange={e => {
                                                    this.setState({isBlocking: true});
                                                    changeFieldTypeValue(single.conditionalFormId, e, single.refId);
                                                    changeConditionalFormId(single.conditionalFormId);
                                                }}
                                                dropdownStyle={{maxHeight: "100px"}}
                                                filterOption={(input, option) =>
                                                    option.children &&
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="TEXT_FIELD">Text Field</Option>
                                                <Option value="EMAIL">Email</Option>
                                                <Option value="PHONE">Phone</Option>
                                                <Option value="PHOTO">Photo</Option>
                                                <Option value="DOCUMENT">Document</Option>
                                                <Option value="DROPDOWN">Dropdown</Option>
                                                <Option value="CHECKBOX">Checkbox</Option>
                                                <Option value="RADIO_BUTTON">Radio Button</Option>
                                                <Option value="SSN">SSN</Option>
                                                <Option value="DOB">Date-of-birth</Option>
                                                <Option value="CONTACT">Contact</Option>
                                                <Option value="CURRENCY">Currency</Option>
                                                <Option value="AMOUNT">Amount</Option>
                                                <Option value="CONTENT">Content</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"
                                     style={{
                                         textAlign: 'center',
                                         fontSize: '16px',
                                         position: 'inherit',
                                         paddingRight: '35px'
                                     }}>
                                    <input type="checkbox" className="form-check-input" id={single.conditionalFormId}
                                           checked={value}
                                           hidden={single.fieldType === 'CONTENT'}
                                           onChange={() => {
                                               this.setState({isBlocking: true});
                                               changeFieldRequiredValue(single.conditionalFormId, !value)
                                               changeConditionalFormId(single.conditionalFormId);
                                           }}
                                           style={{
                                               fontSize: '16px',
                                               marginTop: '10px',
                                               marginLeft: '0',
                                               position: 'inherit'
                                           }}/>
                                </div>
                                <div className="col-2">
                                    <RemoveCircleOutlineIcon
                                        style={{color: 'grey', width: '24px', height: '24px', cursor: 'pointer'}}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            closeFieldSection(single.conditionalFormId);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mb-4" style={{marginTop: "-12px"}}
                                 id={single.id}
                                 hidden={single.fieldType !== 'AMOUNT'}
                            >
                                <div className="col-8">
                                    <div className="multiple-select h40">
                                        <Select
                                            showArrow
                                            size="default"
                                            placeholder="Select amount type"
                                            filterOption={(input, option) =>
                                                option.children &&
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={single.amountType}
                                            onChange={(e)=>{
                                                changeAmountTypeValue(single.conditionalFormId,e)
                                            }}
                                            getPopupContainer={node => node.parentNode}
                                            style={{width: '100%'}}
                                        >
                                            <Option value="INCOME">Income</Option>
                                            <Option value="ASSET">Asset</Option>
                                            <Option value="EXPENSE">Expense</Option>
                                            <Option value="IGNORE">Ignore</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4"
                                 id={single.id}
                                 hidden={single.fieldType !== 'CONTENT'}
                            >
                                <div className="col-8">
                                    <div className="multiple-select">
                                    <textarea
                                        className="inputField"
                                        value={single.content}
                                        onChange={(e)=>{
                                            changeContentValue(single.conditionalFormId, e.target.value)
                                        }}
                                        style={{minHeight: '150px', padding:'15px'}}/>
                                    </div>
                                </div>
                            </div>
                            <div id={single.conditionalFormId} hidden={single.fieldType !== "DROPDOWN"}
                                 className="row mb-4"
                                 style={{marginTop: "-12px"}}>
                                <div className="col-8">
                                    <div className="multiple-select">
                                        <Select
                                            mode="tags"
                                            size="default"
                                            placeholder="Add options"
                                            getPopupContainer={node => node.parentNode}
                                            value={single.optionValue &&
                                            single.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                            onChange={(e) => {
                                                const optionValue = e.map(el => {
                                                    const name =   optionValues && optionValues.filter(value=>
                                                        value.optionValueId === el)
                                                    if(name.length === 0){
                                                        return{
                                                            optionValueName: el
                                                        }
                                                    }else{
                                                        return{
                                                            optionValueId: el
                                                        }
                                                    }

                                                })
                                                changeFieldOptionValue(single.conditionalFormId, optionValue);
                                                changeConditionalFormId(single.conditionalFormId);
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                optionValues && optionValues.map(value =>
                                                    <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div id={single.conditionalFormId} hidden={single.fieldType !== "RADIO_BUTTON"}
                                 className="row mb-4"
                                 style={{marginTop: "-12px"}}>
                                <div className="col-8">
                                    <div className="multiple-select">
                                        <Select
                                            mode="tags"
                                            size="default"
                                            placeholder="Add options"
                                            getPopupContainer={node => node.parentNode}
                                            value={single.optionValue &&
                                            single.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                            onChange={(e) => {
                                                const optionValue = e.map(el => {
                                                    const name =   optionValues && optionValues.filter(value=>
                                                        value.optionValueId === el)
                                                    if(name.length === 0){
                                                        return{
                                                            optionValueName: el
                                                        }
                                                    }else{
                                                        return{
                                                            optionValueId: el
                                                        }
                                                    }

                                                })
                                                changeFieldOptionValue(single.conditionalFormId, optionValue);
                                                changeConditionalFormId(single.conditionalFormId);
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                optionValues && optionValues.map(value =>
                                                    <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div id={single.conditionalFormId} hidden={single.fieldType !== "CHECKBOX"}
                                 className="row mb-4"
                                 style={{marginTop: "-12px"}}>
                                <div className="col-8">
                                    <div className="multiple-select">
                                        <Select
                                            mode="tags"
                                            size="default"
                                            placeholder="Add options"
                                            getPopupContainer={node => node.parentNode}
                                            value={single.optionValue &&
                                            single.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                            onChange={(e, option) => {
                                                const optionValue = e.map(el => {
                                                    const ids = optionValues && optionValues.filter(value =>
                                                        value.optionValueId === el)
                                                    if (ids.length === 0) {
                                                        return {
                                                            optionValueId: String(Math.random()),
                                                            optionValueName: el,
                                                        }
                                                    } else {
                                                        return {
                                                            optionValueId: el,
                                                            optionValueName: ids[0].optionValueName,
                                                        }
                                                    }
                                                })
                                                changeFieldOptionValue(single.conditionalFormId, optionValue);
                                                changeConditionalFormId(single.conditionalFormId);
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                optionValues && optionValues.map(value =>
                                                    <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            {conditionalActionForm && conditionalActionForm.step === "thirdStep"
                            || single.conditionalFormId.substring(0,2) === "0."
                                ? null :
                                <div  id={single.conditionalFormId} className="mt-3 mb-4" hidden={single.fieldType !== 'CHECKBOX'}>
                                    <ul style={{listStyleType: "none", paddingLeft: "0px", marginLeft: "-10px"}}>
                                        {single.optionValue && single.optionValue.map(option =>
                                                <li id={option.optionValueId}>
                                                    <Checkbox
                                                        onChange={(event) =>
                                                        {
                                                            this.setState({
                                                                optionId: option.optionValueId,
                                                                activeConditionalId: single.conditionalFormId,
                                                                activeOptionName: option.optionValueName,
                                                                checkedValue: event.target.checked,
                                                                activeRefId: option.refId
                                                            }, () => {
                                                                if(this.state.checkedValue){
                                                                    if(conditionalActionForm && conditionalActionForm.step === "firstStep"){
                                                                        this.props.getConditionalFormByRefId(option.refId, option.optionValueId,
                                                                            single.conditionalFormId, "secondStep", currentOptionId,
                                                                            currentActionId, currentRefId, currentOptionName);
                                                                    }else if(conditionalActionForm && conditionalActionForm.step === "secondStep"){
                                                                        this.props.getConditionalFormByRefId(option.refId, option.optionValueId,
                                                                            single.conditionalFormId, "thirdStep", currentOptionId,
                                                                            currentActionId, currentRefId, currentOptionName);
                                                                    }
                                                                    this.props.changeActiveValues(option.optionValueId,single.conditionalFormId, option.refId,
                                                                        option.optionValueName )
                                                                    // this.props.setPreviousValue(currentOptionId, currentActionId, currentRefId, currentOptionName)
                                                                }
                                                            })
                                                        }}
                                                    />
                                                    {option.optionValueName}
                                                    <span onClick={() => {
                                                        this.setState({
                                                            optionId: option.optionValueId,
                                                            conditionalFormId: single.conditionalFormId,
                                                            singleFieldObject: single,
                                                        })
                                                        // handleOpenDialogueForNewActionForm()
                                                    }}  hidden={!option.checkedValue}>
                                            <EditOutlinedIcon
                                                style={{
                                                    paddingLeft:"12px",
                                                    width: "30px",
                                                    paddingBottom: "4px",
                                                    cursor:"pointer"}}
                                            />
                                        </span>
                                                </li>
                                        )}
                                    </ul>
                                </div>
                            }
                        </>)
                    )
                });
            }

            return div
        };
        const changeFieldNameValue = (key, value, refId) => {
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.fieldName = value;
            if(refId){
                newAction.refId = refId;
            }
            filteredConditionalForm();
        };

        const changeConditionalFormId = (key, refId) =>{
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.conditionalFormId = key;
            if(refId){
                newAction.refId = refId;
            }
            filteredConditionalForm();
        }
        const filteredConditionalForm = () =>{
            let resultA = this.state.updatedConditionalForm.filter(
                elm => this.state.conditionalForm.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));
            let resultB = this.state.conditionalForm.filter(
                elm => !this.state.updatedConditionalForm.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm))).map(
                final => {
                    if(final.optionValue !== undefined){
                        const optionValue = final.optionValue && final.optionValue.length >0  && final.optionValue.map(
                            option => {
                                return{
                                    optionValueId : option.optionValueId
                                }
                            }
                        )
                        return {
                            conditionalFormId: final.conditionalFormId,
                            fieldName: final.fieldName,
                            fieldType: final.fieldType,
                            orderNo: final.orderNo,
                            optionValue: optionValue,
                            isRequired: !!final.isRequired,
                        }
                    }else{
                        return {
                            conditionalFormId: final.conditionalFormId,
                            fieldName: final.fieldName,
                            fieldType: final.fieldType,
                            orderNo: final.orderNo,
                            isRequired: !!final.isRequired,
                        }
                    }
                });
            this.setState({
                conditionalActionForm: [...resultA, ...resultB]
            },()=>{
                this.props.setEditedConditionalForm(currentActionId, currentOptionId, this.state.conditionalActionForm)
            })
        }
        const changeFieldTypeValue = (key, value, refId) => {
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.fieldType = value;
            if(refId){
                newAction.refId = refId;
            }
            filteredConditionalForm();
        };
        const changeAmountTypeValue = (key, value, refId) => {
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.amountType = value;
            if(refId){
                newAction.refId = refId;
            }
            filteredConditionalForm();
        };
        const changeContentValue = (key, value, refId) => {
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.content = value;
            if(refId){
                newAction.refId = refId;
            }
            filteredConditionalForm();
        };
        const changeFieldRequiredValue = (key, value, refId) => {
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.isRequired = value;
            if(refId){
                newAction.refId = refId;
            }
            filteredConditionalForm();
        };
        const changeFieldOptionValue = (key, value, refId) => {
            let newAction = this.state.conditionalForm.filter(single => single.conditionalFormId === key)[0];
            newAction.optionValue = value;
            if(this.props.conditionalActionForm.refId){
                newAction.refId = this.props.conditionalActionForm.refId;
            }
            filteredConditionalForm();
        }
        const closeFieldSection = (key) => {
            var previousOrder = this.state.conditionalForm.filter(
                single => single.conditionalFormId === key
            )[0].orderNo;
            var removedActionData = this.state.conditionalForm.filter(x => x.id !== key)
            var lessThanOrder = removedActionData.filter(x => x.orderNo < previousOrder)
            var orderFiltered = removedActionData.filter(x => x.orderNo > previousOrder)
            orderFiltered.map(single => {
                single.orderNo = single.orderNo - 1
            })
            lessThanOrder = [...lessThanOrder, ...orderFiltered]
            this.setState({
                conditionalForm: lessThanOrder
            }, () => {
                // this.props.handleChangeAction("conditionalForm", this.state.conditionalForm)
            })

        };

        return(
            <React.Fragment>
                <div style={style.container}>
                    <div className={"row"}>
                        <div className="col-12" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            {openDropdownForTextBox()}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <div style={{cursor: 'pointer'}}
                                 onClick={(e) => {
                                     this.showInputForField();
                                 }}>
                                <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                     style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Field</span>
                            </div>
                        </div>
                    </div>
                    <div className={"row"} style={{
                        justifyContent: 'flex-end',
                        padding: '0 0 1rem 0',
                        marginLeft: 0,
                        width: '100%',
                    }}>
                        <div className="col-4"/>
                        <div className="col-4"/>
                        <div className="col-4">
                            {conditionalActionForm && conditionalActionForm.step === "secondStep" ||
                            conditionalActionForm.step === "thirdStep"?
                                    <div style={{position: "absolute", left: "65px"}}>
                                        <button
                                            style={style.backButton}
                                            onClick={()=>{
                                                this.props.changeActiveValues(
                                                    conditionalActionForm.prevOptionId,
                                                    conditionalActionForm.prevActionId,
                                                    conditionalActionForm.prevRefId,
                                                    conditionalActionForm.prevOptionName)
                                            }}
                                        >
                                            BACK
                                        </button>
                                    </div>: null}
                                    <button
                                        onClick={()=>{
                                            const conditionalData = {
                                                refId: conditionalActionForm.refId,
                                                conditionalForm: this.state.conditionalForm
                                            }
                                            this.props.setConditionalByRefId(conditionalData);
                                            this.props.handleCloseDialogueForNewActionForm();
                                        }}
                                        disabled={this.state.conditionalForm.length === 0}
                                        style={this.state.conditionalForm.length === 0 ? style.disableButton
                                            : style.button}
                                    >
                                       UPDATE
                                    </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const style = {
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
        marginTop: '4px'
    },
    container: {
        background: "#ffffff",
        // marginTop: "78px",
        marginLeft: '3.5%',
        marginRight: '3.5%'
    },
    label: {
        color: '#606060',
        marginBottom: '0',
        fontSize: '16px',
        paddingBottom: "0.5rem"
    },
    input: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        color: "#606060",
        width: '100%',
        marginBottom: '1.2rem'
    },
    button: {
        background: "#8BC83F",
        color: "white",
        border: "none",
        width: "42%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
        float: "right",
        marginRight: "-32px",
    },
    disableButton:{
        background: "#a8d77c",
        color: "white",
        border: "none",
        width: "42%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
        float: "right",
        marginRight: "-32px",
    },
    backButton: {
        background: "#8BC83F",
        color: "white",
        border: "none",
        width: "130%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
    },
};

export default ActionEditFormFieldsComponent;