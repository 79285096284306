import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class ActionAPI extends API {
    fetchActionList = callback => {
        fetch(APIEndPoints.FETCH_ACTION, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchSingleAction = (id,callback) => {
        var constUrl = APIEndPoints.FETCH_SINGLE_ACTION + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                callback(null, "Service error, please try again.");
            });
    };

    addNewAction = (actionData, callback) => {
        let body = JSON.stringify(actionData);
        fetch(APIEndPoints.ADD_ACTION, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteAction = (actionId, callback) => {
        const urlString = APIEndPoints.DELETE_ACTION + actionId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    editAction = ( actionFormData,callback) => {
        fetch( APIEndPoints.EDIT_ACTION ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(actionFormData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    searchOptionValue = (term,callback) => {
        var constUrl = APIEndPoints.SEARCH_EXISTING_OPTION_VALUE + term;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                callback(null, "Service error, please try again.");
            });
    };

    getConditionalFormByRefId = (id, callback) => {
        var constUrl = APIEndPoints.GET_CONDITIONAL_ACTION_FORM_BY_REF_ID + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                callback(null, "Service error, please try again.");
            });
    };

    setConditionalActionForm = (conditionalActionForm,callback) => {
        fetch( APIEndPoints.SET_CONDITIONAL_ACTION_FORM ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(conditionalActionForm)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
}

export default new ActionAPI();