import {APIHeaders, APIHeadersForMultipartFormData} from '../constants/APIHeader';
import LocalDb from "./LocalStorage";

export default class API {
    static token = null;
    static user = null;
    loginResponse;
    constructor() {
        API.token = null;
        API.user = null;
        this.setToken();
    }
    setToken() {
        let response = LocalDb.getSessions();
        if (null !== response) {
            API.token = response.loginResponse.token;
            API.user = response.loginResponse.user;
        }
    }
    removeTokens() {
        API.token = null;
        API.user = null;
    }
    resetToken() {
        this.removeTokens();
        this.setToken();
    }
    processResponse(response, callback) {
        response.json().then(parsedResponse => {
            if (parsedResponse.error) {
                const error = {
                    error: true,
                    msg: parsedResponse.msg,
                    errorCode: parsedResponse.errorCode
                };
                if(error.errorCode === "AUTH_ERROR"){
                    LocalDb.removeSession();
                    localStorage.setItem('route', window.location.pathname)
                    // this.props.history.push('/')
                    window.location.reload();
                }else{
                    callback(null, error);
                }
            }else{
                callback(parsedResponse, null);
            }
        }).catch(() => {
            callback(null,  "Exception on server");
        })
    }
    getUser() {
        return API.user;
    }
    get headers() {
        return { ...APIHeaders }
    }
    get multiPartHeader(){
        return { ...APIHeadersForMultipartFormData}
    }
    get authHeaders() {
        return { ...APIHeaders, 'Authorization': API.token }
    }
    get authHeadersForMultipartFormData() {
        return { ...APIHeadersForMultipartFormData, 'Authorization': API.token }
    }
}