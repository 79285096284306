import { ACTION_TYPE_TRANSACTION } from "../constants/Type";
import React from "react";

const initialState = {
  loading: false,
  openTransactionSnackBar: false,
  openClientTransactionSnackBar: false,
  transactionData: [],
  createdTransactionList: [],
  approvedTransactionList: [],
  ledgerTransactionList: [],
  // clientTransaction: [],
  clientLedgerTransaction: [],
  clientUpcomingTransaction: [],
  transaction: {},

  deletedTransaction: [],
  errorMsg: "",
  to: null,
  from: null,
  term: "",
  mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
  transactionStatus: "UNKNOWN_TRANSACTION_STATUS",
  transType: "UNKNOWN_TRANSACTION_TYPE",
  vendorToFilter: "",
  exportType: "",
  totalBalance: 0,
  totalTransaction: "",
  declineRemark: "",
  transStatus: "",
  submitTransactionList: [],
  checkedValue: [],
  uncheckedValue: [],
  selectedData: {},
  checkBoxValue: false,
  openUpdateSuccessSnackBar: false,
  openUpdateErrorSnackBar: false,
  successMsg: "",
  transData: {
    clientId: "",
    vendorId: "",
    amount: 0,
    note: "",
    transactionType: "",
    transactionMedium: "",
    description: "",
    isLedger: false,
    transactionStatus: "",
  },
  success: false,
  runningAmount: 0,
  openModalCreateTransactionSuccess: false,
  openModalCreateTransactionError: false,
  csvFile: "",
  openTransactionSuccessSnackbar: false,
  openTransactionErrorSnackbar: false,
  openNotesModal: false,
  notes: "",
  openCreateWithdrawVendorTransaction: false,
  withdrawalVendorList: [],
  amountDetails: {},
  bankDetails: {},
  openCreateTransactionModal: false,
  openLedgerTxnUpdateModal: false,
  openNotesModalForLedger: false,
  // edit blocked trans
  blockedTransData: {
    transactionId: "",
    amount: 0,
    date: null,
  },

  // reverseing date
  reverseDate: null,
  // filter status
  filterDate: null,
  filterDesc: "",
  filterAmount: "",
  filterCheckNo: "",
  filterUser: "",
  filterMedium: "",
  filterType: "",
  filtetStatus: "",
  filterVendor: "",
  filterSchDate: null,
  filterCPDate: null,
  filterPosDate: null,
  filterACHDAte: null,
  filterDebitAmount: "",
  filterCreditAmount: "",
  compareType: "0",

  // config
  configlist: [],
};
const transactionState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_TRANSACTION.TRANSACTION_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_TRANSACTION.TRANSACTION_VOD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_TRANSACTION.SET_OPEN_CREATE_TRANSACTION_MODAL:
      return {
        ...state,
        loading: false,
        openCreateTransactionModal: action.bool,
      };
    case ACTION_TYPE_TRANSACTION.SET_OPEN_MODAL_TO_CHANGE_LEDGER_TXN_STATUS:
      return {
        ...state,
        loading: false,
        openLedgerTxnUpdateModal: action.bool,
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_CREATE_TRANSACTION_MODAL:
      return {
        ...state,
        transData: {
          vendorId: "",
          amount: 0,
          note: "",
          transactionType: "",
          transactionMedium: "",
          description: "",
          isLedger: false,
        },
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_WITHDRAWAL_TRANSACTION_MODAL:
      return {
        ...state,
        transData: {
          vendorId: "",
          amount: "",
          note: "",
          transactionType: "WITHDRAWAL",
          transactionMedium: "",
          description: "",
        },
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_LEDGER_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        openLedgerTxnUpdateModal: false,
        openUpdateSuccessSnackBar: true,
        openUpdateErrorSnackBar: false,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_LEDGER_TRANSACTION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        openLedgerTxnUpdateModal: true,
        openUpdateErrorSnackBar: true,
        error: action.error,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transactionData.transactions,
        totalBalance: action.transactionData.totalAmount,
        totalTransaction: action.transactionData.totalCount,
        from: null,
        to: null,
        mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        transactionStatus: "UNKNOWN_TRANSACTION_STATUS",
        transType: "UNKNOWN_TRANSACTION_TYPE",
        term: "",
        runningAmount: 0,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    // fetch deleted transaction
    case ACTION_TYPE_TRANSACTION.FETCH_DELETED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedTransaction: action.transactionData.deletedTransactions,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_DELETED_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        // openTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.SET_TRANSACTION_ID_TO_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        trans: action.trans.transaction,
      };
    case ACTION_TYPE_TRANSACTION.SET_TRANSACTION_ID_TO_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        clientTransaction: action.transaction.transactions,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };

    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.response.transactions,
        clientTransaction: action.response.transactions,
        totalBalance: action.response.totalAmount,
        clientId: action.response.clientId,
        totalTransaction: action.response.totalCount,
        runningAmount: 0,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_INDIVIDUAL_CLIENT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openClientTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //MEDIUM AND STATUS
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //MEDIUM AND DATE
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //STATUS AND DATE
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //MEDIUM AND STATUS AND DATE
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createdTransactionList: action.transaction.transactions,
        transactionData: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    // search submitted transaction
    case ACTION_TYPE_TRANSACTION.SEARCH_SUBMITTED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedTransactionList: action.transaction.transactions,
      };

    //SNACK BAR
    case ACTION_TYPE_TRANSACTION.CLOSE_SNACK_BAR:
      return {
        ...state,
        loading: false,
        openTransactionSnackBar: false,
        openUpdateSuccessSnackBar: false,
        openUpdateErrorSnackBar: false,
        openModalCreateTransactionSuccess: false,
        openModalCreateTransactionError: false,
        openTransactionSuccessSnackbar: false,
        openTransactionErrorSnackbar: false,
      };
    case ACTION_TYPE_TRANSACTION.CLOSE_CLIENT_TRANSACTION_SNACK_BAR:
      return {
        ...state,
        loading: false,
        openClientTransactionSnackBar: false,
        openModalCreateTransactionSuccess: false,
        openModalCreateTransactionError: false,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_DATE_FROM:
      return {
        ...state,
        loading: false,
        from: action.from,
        term: "",
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_DATE_TO:
      return {
        ...state,
        loading: false,
        to: action.to,
        term: "",
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_MEDIUM:
      return {
        ...state,
        loading: false,
        mediumType: action.medium,
        term: "",
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_VENDOR:
      return {
        ...state,
        vendorToFilter: action.vendor,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_EXPORT_TYPE:
      return {
        ...state,
        loading: false,
        exportType: action.exportType,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_STATUS:
      return {
        ...state,
        loading: false,
        transactionStatus: action.status,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TERM:
      return {
        ...state,
        loading: false,
        term: action.term,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TYPE:
      return {
        ...state,
        loading: false,
        transType: action.transactionType,
        term: "",
      };
    //******INDIVIDUAL CLIENT TRANSACTION SEARCH*****
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //MEDIUM
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //STATUS
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //MEDIUM AND STATUS
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //MEDIUM AND DATE
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //STATUS AND DATE
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transaction.transactions,
        clientTransaction: action.transaction.transactions,
        totalBalance: action.transaction.totalAmount,
        totalTransaction: action.transaction.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };

    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //DATE
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        startDateToExport: "",
        endDateToExport: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //TERM
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        term: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_TERM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        mediumTypeToExport: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //STATUS
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        transactionStatusToExport: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //STATUS AND MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionErrorSnackbar: true,
      };
    //DATE AND MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //DATE AND STATUS
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //DATE AND STATUS AND MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };

    // EXPORT VOD
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_VOD_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_TRANSACTION_VOD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };

    //-----------EXPORT BY CLIENT ID-------------
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //TERM
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        term: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        mediumTypeToExport: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //STATUS
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
        transactionStatusToExport: "",
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //STATUS AND MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //DATE AND MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //DATE AND STATUS
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    //DATE AND STATUS AND MEDIUM
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.OPEN_SNACK_BAR:
      return {
        ...state,
        from: null,
        to: null,
        mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        transactionStatus: "UNKNOWN_TRANSACTION_STATUS",
        transType: "UNKNOWN_TRANSACTION_TYPE",
        vendorToFilter: "",
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_SUBMIT_TRANS_REMARK:
      return {
        ...state,
        declineRemark: action.remark,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_SUBMIT_TRANS_STATUS:
      return {
        ...state,
        transStatus: action.status,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_SELECTED_DATA:
      if (action.bool === true) {
        state.checkedValue = [...state.checkedValue, action.selectedData];
      } else {
        state.uncheckedValue = [...state.uncheckedValue, action.selectedData];
      }
      return {
        ...state,
        loading: false,
        selectedData: action.selectedData,
        checkBoxValue: action.bool,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_SUBMIT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        submitTransactionList: action.response.transactions,
        success: false,
        runningAmount: 0,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_SUBMIT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_APPROVED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedTransactionList: action.response.transactions,
        success: false,
        runningAmount: 0,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_APPROVED_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_CREATED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        createdTransactionList: action.response.transactions,
        success: false,
        runningAmount: 0,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_CREATED_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_SUBMIT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpdateSuccessSnackBar: true,
        openUpdateErrorSnackBar: false,
        successMsg: "Successfully Updated",
        checkedValue: [],
        uncheckedValue: [],
        success: action.response,
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_SUBMIT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        openUpdateErrorSnackBar: true,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.UPLOAD_BLOCKED_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpdateSuccessSnackBar: true,
        openUpdateErrorSnackBar: false,
        successMsg: "Successfully Updated",
        success: action.response,
      };
    case ACTION_TYPE_TRANSACTION.UPLOAD_BLOCKED_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        openUpdateErrorSnackBar: true,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.DECLINE_TRANS_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        openUpdateSuccessSnackBar: true,
        openUpdateErrorSnackBar: false,
        successMsg: "Success",
        success: action.response,
      };
    case ACTION_TYPE_TRANSACTION.DECLINE_TRANS_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        openUpdateErrorSnackBar: true,
        error: action.error,
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_CHECKED_AND_UNCHECKED_VALUE:
      return {
        ...state,
        checkedValue: [],
        uncheckedValue: [],
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_UPDATE_MODAL_DATA:
      return {
        ...state,
        declineRemark: "",
        transStatus: "",
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TRANS_DATA:
      const newTransDetails = { ...state.transData };
      newTransDetails[action.id] = action.value;
      return {
        ...state,
        transData: newTransDetails,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_EDIT_BLOCKED_TRANS_DATA:
      const newBlockedTransData = { ...state.blockedTransData };

      newBlockedTransData[action.id] = action.value;

      return {
        ...state,
        blockedTransData: newBlockedTransData,
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_BLOCKED_TRANSACTION:
      return {
        ...state,
        blockedTransData: {
          transactionId: "",
          amount: 0,
          date: null,
        },
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_AMOUNT_DETAIL:
      return {
        ...state,
        laoding: false,
        amountDetails: {},
      };
    case ACTION_TYPE_TRANSACTION.GET_AMOUNT_DETAIL_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        laoding: false,
        amountDetails: action.response.balance,
      };
    case ACTION_TYPE_TRANSACTION.GET_AMOUNT_DETAIL_BY_CLIENT_ID_FAILURE:
      return {
        ...state,
        laoding: false,
      };
    case ACTION_TYPE_TRANSACTION.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.response,
        successMsg: "Successfully created",
        openModalCreateTransactionSuccess: true,
        openCreateTransactionModal: false,
      };
    case ACTION_TYPE_TRANSACTION.DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.response,
        successMsg: "Successfully deleted",
        openModalCreateTransactionSuccess: true,
        openCreateTransactionModal: false,
      };
    case ACTION_TYPE_TRANSACTION.DELETE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openModalCreateTransactionError: true,
      };
    case ACTION_TYPE_TRANSACTION.CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openModalCreateTransactionError: true,
        openCreateTransactionModal: true,
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.response,
        successMsg: "Successfully updated",
        openModalCreateTransactionSuccess: true,
        openCreateTransactionModal: false,
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openModalCreateTransactionError: true,
        openCreateTransactionModal: true,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_CSV_FILE:
      return {
        ...state,
        loading: false,
        csvFile: action.csvFile,
      };
    case ACTION_TYPE_TRANSACTION.IMPORT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openTransactionSuccessSnackbar: true,
        successMsg: "Successfully Imported",
      };
    case ACTION_TYPE_TRANSACTION.IMPORT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionErrorSnackbar: true,
      };
    case ACTION_TYPE_TRANSACTION.GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        bankDetails: action.response.totalAmount,
      };
    case ACTION_TYPE_TRANSACTION.GET_BANK_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_TRANSACTION.DOWNLOAD_ACH_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openTransactionSuccessSnackbar: true,
        successMsg: "Successfully Downloaded",
      };
    case ACTION_TYPE_TRANSACTION.DOWNLOAD_ACH_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionErrorSnackbar: true,
      };
    // ledger transaction
    case ACTION_TYPE_TRANSACTION.FETCH_LEDGER_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        ledgerTransactionList: action.response,
        totalTransaction: action.response.totalCount,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_LEDGER_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_LEDGER_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_LEDGER_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    //cleint ledger transaction
    case ACTION_TYPE_TRANSACTION.FETCH_CLIENT_LEDGER_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        clientLedgerTransaction: action.response.transactions,
        totalTransaction: action.response.totalCount,
        totalAmount: action.response.totalAmount,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_CLIENT_LEDGER_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_CLIENT_LEDGER_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_TRANSACTION.EXPORT_CLIENT_LEDGER_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    // CREATE TRANSACTION FROM LEDGER
    case ACTION_TYPE_TRANSACTION.HANDLE_OPEN_CREATE_WITHDRAWAL_VENDOR_TRANSACTION:
      return {
        ...state,
        openCreateWithdrawVendorTransaction: action.bool,
      };
    case ACTION_TYPE_TRANSACTION.CREATE_WITHDRAWAL_VENDOR_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: "Successfully created",
        openModalCreateTransactionSuccess: true,
        openCreateWithdrawVendorTransaction: false,
      };
    case ACTION_TYPE_TRANSACTION.CREATE_WITHDRAWAL_VENDOR_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        openCreateWithdrawVendorTransaction: true,
        errorMsg: action.error,
        openModalCreateTransactionError: true,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_WITHDRAWAL_VENDOR_LIST_SUCCESS:
      return {
        ...state,
        withdrawalVendorList: action.response.vendorAccounts,
        loading: false,
      };
    case ACTION_TYPE_TRANSACTION.FETCH_WITHDRAWAL_VENDOR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    // NOTES
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TO_OPEN_NOTE_MODAL:
      return {
        ...state,
        openNotesModal: action.bool,
        loading: false,
      };

    // For LEDGER
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_LEDGER:
      return {
        ...state,
        openNotesModalForLedger: action.bool,
        loading: false,
      };

    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_NOTES:
      return {
        ...state,
        notes: action.notes,
        loading: false,
      };
    // case ACTION_TYPE_TRANSACTION.SET_NOTES_TO_EDIT:
    //   return {
    //     ...state,
    //     notes: action.notes,
    //     loading: false,
    //   };
    case ACTION_TYPE_TRANSACTION.ADD_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        openNotesModal: false,
        openModalCreateTransactionSuccess: true,
        successMsg: "Sucessfully Updated",
      };
    case ACTION_TYPE_TRANSACTION.ADD_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        openNotesModal: true,
        openModalCreateTransactionError: true,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.ADD_NOTES_SUCCESS_FOR_LEDGER:
      return {
        ...state,
        loading: false,
        openNotesModalForLedger: false,
        openModalCreateTransactionSuccess: true,
        successMsg: "Sucessfully Updated",
      };
    case ACTION_TYPE_TRANSACTION.ADD_NOTES_FAILURE_FOR_LEDGER:
      return {
        ...state,
        loading: false,
        openNotesModalForLedger: true,
        openModalCreateTransactionError: true,
        errorMsg: action.error,
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_DESC_DATA:
      return {
        ...state,
        loading: false,
        successMsg: "Sucessfully Updated",
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: "Sucessfully Updated",
        openUpdateSuccessSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.SET_TRANSACTION_INPUT_DATA_TO_EDIT:
      const selectedTransData = action.selectedTransData;

      return {
        ...state,
        transData: {
          transactionId: selectedTransData && selectedTransData.transactionId,
          clientId:
            selectedTransData &&
            selectedTransData.Client &&
            selectedTransData.Client.clientId,
          clientName:
            selectedTransData &&
            selectedTransData.Client &&
            selectedTransData.Client.firstName +
              " " +
              selectedTransData.Client.lastName,

          amount:
            selectedTransData &&
            selectedTransData.amount &&
            selectedTransData.amount.charAt(0) === "-"
              ? selectedTransData.amount.substring(1)
              : selectedTransData.amount,
          bankDetailId: selectedTransData && selectedTransData.bankDetailId,
          //     scheduleType:
          //       selectedTransData &&
          //       selectedTransData.scheduleDetail &&
          //       selectedTransData.scheduleDetail.scheduleType,
          //     scheduledDate:
          //       scheduledData &&
          //       scheduledData.scheduleDetail &&
          //       scheduledData.scheduleDetail.scheduledDate,
          //     endDate:
          //       scheduledData &&
          //       scheduledData.scheduleDetail &&
          //       scheduledData.scheduleDetail.endDate,
          transactionMedium:
            selectedTransData && selectedTransData.transactionMedium,
          transactionType:
            selectedTransData && selectedTransData.transactionType,
          transactionStatus:
            selectedTransData && selectedTransData.transactionStatus,
          vendorId:
            selectedTransData &&
            selectedTransData.vendorAccount &&
            selectedTransData.vendorAccount.vendorId,
          vendorName:
            selectedTransData &&
            selectedTransData.vendorAccount &&
            selectedTransData.vendorAccount.vendorName,
          description: selectedTransData && selectedTransData.description,
          note: selectedTransData && selectedTransData.note,
          isLedger: selectedTransData && selectedTransData.isLedger,
          //     bankId: scheduledData && scheduledData.bankDetailId,
        },
        //   fileUrl: scheduledData && scheduledData.document ? scheduledData.document : "",
      };
    case ACTION_TYPE_TRANSACTION.UPDATE_DESCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openUpdateErrorSnackBar: true,
      };

    // new filter
    case ACTION_TYPE_TRANSACTION.CLEAR_TRANSACTIONS_FILTER:
      return {
        ...state,
        filterDate: null,
        filterDesc: "",
        filterAmount: "",
        filterCheckNo: "",
        filterUser: "",
        filterMedium: "",
        filterType: "",
        filtetStatus: "",
        filterVendor: "",
        filterSchDate: null,
        filterCPDate: null,
        filterPosDate: null,
        filterACHDAte: null,
        filterDebitAmount: "",
        filterCreditAmount: "",
        compareType: "0",
      };
    case ACTION_TYPE_TRANSACTION.CLEAR_TRANSACTIONS_MAIN_FILTER:
      return {
        ...state,
        to: null,
        from: null,
        mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        transactionStatus: "UNKNOWN_TRANSACTION_STATUS",
        transType: "UNKNOWN_TRANSACTION_TYPE",
        vendorToFilter: "",
      };
    case ACTION_TYPE_TRANSACTION.TRANSACTION_FILTER:
      return {
        ...state,
        loading: false,
        [action.id]: action.value,
      };
    case ACTION_TYPE_TRANSACTION.GET_TRANSACTION_CONFIG_SUCCESS:
      return {
        ...state,
        configlist: action.payload,
      };
    case ACTION_TYPE_TRANSACTION.GET_TRANSACTION_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openUpdateErrorSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.SAVE_TRANSACTION_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        openTransactionSuccessSnackbar: true,
        successMsg: "Sucessfully Saved",
      };
    case ACTION_TYPE_TRANSACTION.SAVE_TRANSACTION_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openUpdateErrorSnackBar: true,
      };
    case ACTION_TYPE_TRANSACTION.HANDLE_CHANGE_REVERSED_DATE:
      return {
        ...state,
        loading: false,
        reverseDate: action.date,
      };
    default:
      return state;
  }
};
export default transactionState;
