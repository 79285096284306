import { connect } from 'react-redux';
import VendorFormScreen from "../../screens/VendorFormScreen";
import {
  addNewVendorFailure,
  addNewVendorSuccess,
  handleChangeVendor,
  vendorRequestMade
} from "../../actions";
import {VendorAPI} from "../../api";
import {closeVendorSnackBar} from "../../actions/VendorAction";

const mapStateToProps = state => (
    state
);

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeVendor: (id, value, label) => dispatch(handleChangeVendor(id, value, label)),
    handleSnackBarClose: () => dispatch(closeVendorSnackBar(true)),

    // addNewVendor: (vendorData, props)=>{
    //   dispatch(vendorRequestMade(true));
    //   VendorAPI.addNewVendor(vendorData, (response, error) => {
    //     if (response) {
    //       dispatch(addNewVendorSuccess(true));
    //       props.history.push('/vendor')
    //     } else {
    //           if (error.msg) {
    //             dispatch(addNewVendorFailure(error.msg));
    //           } else {
    //             dispatch(addNewVendorFailure(error));
    //       }
    //     }
    //   })
    // }
  }

};


export const VendorFormContainer = connect(mapStateToProps, mapDispatchToProps)(VendorFormScreen);