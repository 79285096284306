import React, {Component} from "react";
import {CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import ErrorOutlineIcon from "@material-ui/core/SvgIcon/SvgIcon";

class ResetPasswordScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            verifyPasswordError:false,
            verifyPassword: '',
            newPassword: '',
        };
    }

    handleCloseSnackbar = () => {
        this.props.handleResetPasswordSnackBarClose();
    };

    render() {

        return (
            <React.Fragment>
                {this.props.state.resetPasswordState.loading ? (
                    <div
                        className={
                            this.props.state.resetPasswordState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: '#8BC83F'}}/>
                    </div>
                ) : null}

                <div className="flex-container" style={style.flexContainer}>
                    <div className="card-background" style={style.cardBackground}>
                        <div className="card loginCard" style={style.loginCard}>
                            <div className="card-body" style={style.cardBody}>
                                <div className="image" style={style.image}>
                                    <img
                                        src="/assets/images/everfund_logo.png"
                                        alt="EverFund Logo"
                                        height="110px"
                                        style={style.img}
                                    />
                                </div>
                                <h1 style={style.h1}>Update Password</h1>
                                <form>
                                    <div>
                                        <label style={style.label}>Current Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="oldPassword"
                                            onChange={e =>
                                                this.props.handleChangeForResetFirstPassword(
                                                    "oldPassword",
                                                    e.target.value
                                                )
                                            }
                                            style={style.input}
                                        />
                                        <label style={style.label}>New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="newPassword"
                                            onChange={e =>{
                                                this.setState({
                                                    newPassword: e.target.value,
                                                    isBlocking: true
                                                });
                                                this.props.handleChangeForResetFirstPassword(
                                                    "newPassword",
                                                    e.target.value
                                                )}
                                            }
                                            style={!this.state.verifyPasswordError ? style.input : style.errorInput}
                                        />
                                        <span hidden={!this.state.verifyPasswordError}
                                              style={{color: 'red', textDecoration:'none', float: 'left', fontSize: '13px',paddingBottom:'10px'}}>
                                            <ErrorOutlineIcon style={{width: '16px'}}/>Password Does Not Match</span>

                                        <label style={style.label}>Confirm Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="verifyPassword"
                                            onChange={(e) => {
                                                this.setState({
                                                    verifyPassword: e.target.value,
                                                    isBlocking: true
                                                });
                                            }}
                                            style={!this.state.verifyPasswordError ? style.input : style.errorInput}
                                        />
                                        <span hidden={!this.state.verifyPasswordError}
                                              style={{color: 'red', textDecoration:'none', float: 'left', fontSize: '13px'}}>
                                            <ErrorOutlineIcon style={{width: '16px'}}/>Password Does Not Match</span>

                                    </div>

                                    {/*<div>*/}
                                    {/*    <label style={style.label}>Email</label>*/}
                                    {/*    <input*/}
                                    {/*        type="email"*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="emailPhone"*/}
                                    {/*        value={this.props.state.resetPasswordState.resetInfo.emailPhone}*/}
                                    {/*        readOnly*/}
                                    {/*        style={style.input}*/}
                                    {/*    />*/}
                                    {/*    <label style={style.label}>New Password</label>*/}
                                    {/*    <input*/}
                                    {/*        type="password"*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="newPassword"*/}
                                    {/*        onChange={e =>*/}
                                    {/*            this.props.handleChangeForResetPassword(*/}
                                    {/*                "newPassword",*/}
                                    {/*                e.target.value*/}
                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*        style={style.input}*/}
                                    {/*    />*/}

                                    {/*    <label style={style.label}>Code</label>*/}
                                    {/*    <input*/}
                                    {/*        type="text"*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="code"*/}
                                    {/*        onChange={e =>*/}
                                    {/*            this.props.handleChangeForResetPassword(*/}
                                    {/*                "code",*/}
                                    {/*                e.target.value*/}
                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*        style={style.input}*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </form>

                                <button
                                    className="btn btn-primary"
                                    disabled={this.props.state.resetPasswordState.loading}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: false});
                                        this.state.newPassword === this.state.verifyPassword ?
                                            this.props.passwordReset(
                                                this.props.state.resetPasswordState.accountData,
                                                this.props
                                            )
                                            : this.setState({
                                                verifyPasswordError: true
                                            })
                                    }}
                                    style={style.btn}
                                >
                                    UPDATE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.resetPasswordState.openPasswordResetSuccessSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleCloseSnackbar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleCloseSnackbar}
                        variant="success"
                        message={"Password Reset Success"}
                    />
                </Snackbar>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.resetPasswordState.openResetSnackBarError}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.handleCloseSnackbar}
                        variant="error"
                        message={this.props.state.resetPasswordState.error}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#F8F8F8"
    },
    cardBackground: {
        width: "350px",
        backgroundColor: "#8BC846",
        borderRadius: "8px"
    },
    loginCard: {
        // alignItems: "center",
        borderRadius: "10px",
        marginTop: "10px"
    },
    cardBody: {
        width: "350px",
        // alignItems: "center",
        boxShadow: "-2px 1px 13px 0px rgba(0, 0, 0, 0.3)",
        borderRadius: "5px",
        textAlign: "center",
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingBottom: "30px"
    },
    image: {
        borderRadius: "5%",
        borderColor: "#fff",
        borderWidth: "15px",
        marginTop: "-78px",
        textAlign: "center !important"
    },
    img: {
        borderRadius: "50%",
        border: "7px solid #fff",
        backgroundColor: "#fff"
    },
    btn: {
        backgroundColor: "#8BC83F",
        marginTop: "10px",
        marginBottom: "8px",
        width: "100%",
        height: "40px",
        fontSize: "18px",
        fontWeight: 550,
        border: 0
    },
    h1: {
        color: "#8BC846",
        fontSize: "30px",
        marginBottom: '1rem',
        textAlign: "center",
    },
    formCheck: {
        float: "left",
        marginRight: "0px",
        marginTop: "0.5rem"
    },
    formCheckLabel: {
        fontSize: "15px",
        color: "#8BC846",
        paddingLeft: 0,
        marginLeft: 0
    },
    inputEmail: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        marginBottom: '1.5rem'
    },
    input: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        marginBottom: '1rem'
    },
    icon: {
        fontSize: "17px"
    },
    visibilityIcon: {
        position: "absolute",
        bottom: "10px",
        right: 0,
        color: "rgb(196, 189, 189)",
        fontSize: "20px",
        textDecoration: "none",
        top: "2px",
        background: "none",
        border: "none"
    },
    errorMessage: {
        color: "#ed4956",
        fontSize: "13px",
        marginTop: "-5px"
    },
    label: {
        textAlign: 'left',
        width: '100%',
        marginBottom: 0,
        color: '#626262',
        paddingBottom: '6px'
    },
    errorInput: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: '1px solid red',
    },
};

export default ResetPasswordScreen;
