import {connect} from 'react-redux';
import {CorporateClientFormScreen} from "../../screens";
import {
    addCorporateClientFailure,
    addCorporateClientSuccess,
    assignClientToCorporateFailure,
    assignClientToCorporateSuccess,
    closeSnackBarForCorporateClient,
    corporateClientRequestMade,
    fetchClientFailureInCorporateClient,
    fetchClientSuccessInCorporateClient,
    fetchCorporateInfoFailure,
    fetchCorporateInfoSuccess,
    handleChangeCorporateClient,
    searchClientBySsnFail,
    searchClientBySsnSuccess
} from "../../actions";
import CorporateAPI from "../../api/CorporateAPI";
import CorporateClientAPI from "../../api/CorporateClientAPI";
import {LocalDb, RegisterCaseAPI} from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCorporateInfo: corporateId => {
            dispatch(corporateClientRequestMade(true));
            CorporateAPI.fetchSingleCorporate(corporateId, (response, error) => {
                if (response) {
                    dispatch(fetchCorporateInfoSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCorporateInfoFailure(error.msg));
                    } else {
                        dispatch(fetchCorporateInfoFailure(error));
                    }
                }
            })
        },
        fetchClientList: () => {
            dispatch(corporateClientRequestMade(true));
            RegisterCaseAPI.fetchClientList((response, error) => {
                if (response) {
                    dispatch(fetchClientSuccessInCorporateClient(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientFailureInCorporateClient(error.msg))
                    } else {
                        dispatch(fetchClientFailureInCorporateClient(error));
                    }
                }
            })
        },

        handleChangeCorporateClient: (id, value) => dispatch(handleChangeCorporateClient(id, value)),

        addNewCorporateClient: (corporateId, clientData, props) => {
            dispatch(corporateClientRequestMade(true));
            CorporateClientAPI.addNewCorporateClient(corporateId, clientData, (response, error) => {
                if (response) {
                    dispatch(addCorporateClientSuccess(true));
                    if(LocalDb.getSessions() && LocalDb.getSessions().loginResponse.loginUser.corporate){
                        props.history.replace('/client');
                    }else{
                        props.history.replace('/corporate/client/' + corporateId);
                    }
                } else {
                    if (error.msg) {
                        dispatch(addCorporateClientFailure(error.msg));
                    } else {
                        dispatch(addCorporateClientFailure(error));
                    }
                }
            });
        },
        assignClientToContainer: (corporateId, clientId, props) => {
            dispatch(corporateClientRequestMade(true));
            CorporateClientAPI.assignClientToCorporate(corporateId, clientId, (response, error) => {
                if (response) {
                    dispatch(assignClientToCorporateSuccess(true));
                    props.history.replace('/corporate/client/' + corporateId);
                } else {
                    if (error.msg) {
                        dispatch(assignClientToCorporateFailure(error.msg));
                    } else {
                        dispatch(assignClientToCorporateFailure(error));
                    }
                }
            });
        },
        searchClient: (value) => {
            RegisterCaseAPI.searchClient(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchClientBySsnFail(error.msg))
                    } else {
                        dispatch(searchClientBySsnFail(error));
                    }
                } else {
                    dispatch(searchClientBySsnSuccess(response));
                }
            })
        },
        setClientContent: (response) => {
            dispatch(addCorporateClientSuccess(response));
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForCorporateClient(true)),

    };
};


export const CorporateClientFormContainer = connect(mapStateToProps, mapDispatchToProps)(CorporateClientFormScreen);