import React, {Component} from 'react';
import {Button, CircularProgress} from "@material-ui/core";

class ButtonComponent extends Component{
    render() {
        return (
            <>
                {!this.props.noValue ?
                    <Button
                        style={{float: 'right'}}
                        onClick={this.props.onClick}
                        disabled={!this.props.value || this.props.loading}
                        className={this.props.value  && !this.props.loading ? "addButton" : "disabledAddButton"}
                    >
                        <CircularProgress style={{
                            color: '#a7aaaa',
                            width: '15px',
                            height: '15px',
                            marginRight: '10px'
                        }} hidden={!this.props.loading}/>
                        {this.props.buttonName}
                    </Button>:
                    <Button
                        style={{float: 'right'}}
                        onClick={this.props.onClick}
                        disabled={this.props.loading}
                        className={!this.props.loading ? "addButton" : "disabledAddButton"}
                    >
                        <CircularProgress style={{
                            color: '#a7aaaa',
                            width: '15px',
                            height: '15px',
                            marginRight: '10px'
                        }} hidden={!this.props.loading}/>
                        {this.props.buttonName}
                    </Button>
                }
            </>

        );
    }
}

export default ButtonComponent;