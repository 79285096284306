import {connect} from 'react-redux'
import {LoginScreen} from '../../screens';
import {LocalDb, LoginAPI} from "../../api";
import {loginRequestMade, loginSuccess, loginFailure, handleChangeLogin, closeSnackBar} from '../../actions';
import Helpers from "../Helper";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (userData, props) => {
            if (Helpers.checkValidityEmail(userData.email) || Helpers.checkValidityPhone(userData.email)) {
                if ((Helpers.checkValidityPassword(userData.password))) {
                    dispatch(loginRequestMade(true));
                    LoginAPI.onLogin(userData, (response, error) => {
                        if (response) {
                            dispatch(loginRequestMade(false));
                            LocalDb.setSession(response, (err) => {
                                if (err === false) {
                                    LoginAPI.resetToken();
                                    if (response && response.loginResponse && response.loginResponse.loginUser
                                        && response.loginResponse.loginUser.user) {
                                        if (response.loginResponse.loginUser.user.isFirstLogin &&
                                            response.loginResponse.loginUser.user.isFirstLogin === true) {
                                            props.history.push("/reset/password")
                                        } else {
                                            if(localStorage.getItem('route') === '' ||
                                                localStorage.getItem('route') === null){
                                                props.history.push("/dashboard")
                                            } else {
                                                props.history.push(localStorage.getItem('route'))

                                            }
                                        }

                                    } else if (response && response.loginResponse && response.loginResponse.loginUser
                                        && response.loginResponse.loginUser.corporate) {
                                        if (response.loginResponse.loginUser.corporate.isFirstLogin &&
                                            response.loginResponse.loginUser.corporate.isFirstLogin === true) {
                                            // props.history.push("/reset/password")
                                            props.history.push("/client")
                                        } else {
                                            if(localStorage.getItem('route') === '' ||
                                                localStorage.getItem('route') === null){
                                                // props.history.push("/dashboard")
                                                props.history.push("/client")
                                            }  else {
                                                // props.history.push(localStorage.getItem('route'));
                                                props.history.replace("/client")
                                            }
                                        }
                                    } else if (response && response.loginResponse && response.loginResponse.loginUser
                                        && response.loginResponse.loginUser.client) {
                                        if (response.loginResponse.loginUser.client.isFirstLogin &&
                                            response.loginResponse.loginUser.client.isFirstLogin === true) {
                                            props.history.push("/reset/password")
                                        } else {
                                            if(localStorage.getItem('route') === '' ||
                                                localStorage.getItem('route') === null){
                                                props.history.push("/dashboard")
                                            } else {
                                                props.history.push(localStorage.getItem('route'))

                                            }
                                        }
                                    } else if (response && response.loginResponse && response.loginResponse.loginUser
                                        && response.loginResponse.loginUser.contact) {
                                        if (response.loginResponse.loginUser.contact.isFirstLogin &&
                                            response.loginResponse.loginUser.contact.isFirstLogin === true) {
                                            props.history.push("/reset/password")
                                        } else {
                                            if(localStorage.getItem('route') === '' ||
                                                localStorage.getItem('route') === null){
                                                props.history.push("/dashboard")
                                            } else {
                                                props.history.push(localStorage.getItem('route'))

                                            }
                                        }
                                    } else if (response && response.loginResponse && response.loginResponse.loginUser
                                        && response.loginResponse.loginUser.company) {
                                        if (response.loginResponse.loginUser.company.isFirstLogin &&
                                            response.loginResponse.loginUser.company.isFirstLogin === true) {
                                            props.history.push("/reset/password")
                                        } else {
                                            if(localStorage.getItem('route') === '' ||
                                                localStorage.getItem('route') === null){
                                                props.history.push("/dashboard")
                                            } else {
                                                props.history.push(localStorage.getItem('route'))

                                            }
                                        }
                                    }
                                    dispatch(loginSuccess(response.loginResponse));
                                } else {
                                    dispatch(loginFailure(error, "rest"));
                                }
                            });
                        } else {
                            if (error.msg) {
                                dispatch(loginFailure(error.msg, "rest"))
                            } else {
                                dispatch(loginFailure(error, "rest"))
                            }
                        }
                    });
                } else {
                    dispatch(loginFailure("Invalid password format.", "password"));
                }
            }
            // else if(localStorage.getItem('session') !== {}){
            //     const response = localStorage.getItem('session')
            //     console.log({response})
            //     LocalDb.setSession(response, (err) => {
            //         if (err === false) {
            //             if(localStorage.getItem('route')===''){
            //                 props.history.push("/dashboard")
            //             } else {
            //                 props.history.push(localStorage.getItem('route'))
            //
            //             }
            //         } else {
            //             dispatch(loginFailure(err, "rest"));
            //         }
            //     });
            // }
            else {
                dispatch(loginFailure("Invalid email address format.", "email"));
            }
        },
        handleChange: (id, value) => dispatch(handleChangeLogin(id, value)),
        handleSnackBarClose: () => dispatch(closeSnackBar(true)),

    }
};

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginScreen);