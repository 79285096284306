import { ACTION_TYPE_PROFILE } from "../constants/Type";
import { LocalDb } from "../api";

const userInfo = LocalDb.getSessions();
const userLoginInfo = userInfo ? userInfo : null;
const userData = userLoginInfo
  ? userInfo.loginResponse.loginUser.user
    ? userInfo.loginResponse.loginUser.user
    : userInfo.loginResponse.loginUser.corporate
    ? userInfo.loginResponse.loginUser.corporate
    : userInfo.loginResponse.loginUser.client
    ? userInfo.loginResponse.loginUser.client
    : userInfo.loginResponse.loginUser.contact
  : null;

const loginUser = userInfo && userInfo.loginResponse.loginUser;
const userIdValue =
  loginUser && loginUser.user
    ? loginUser.user.userId
    : loginUser && loginUser.client
    ? loginUser.client.clientId
    : loginUser && loginUser.corporate
    ? loginUser.corporate.corporateId
    : loginUser && loginUser.contact
    ? loginUser.contact.contactId
    : "";

const initialState = {
  loading: false,
  profileData: userData,
  accountData: {
    oldPassword: null,
    newPassword: "",
  },
  openProfileUpdateSuccessSnackBar: false,
  openPasswordChangeSuccessSnackBar: false,
  openProfileSnackBar: false,
  profilePicture: [],
  profilePictureUrl: "",
  userId: userIdValue,
};

const profileState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_PROFILE.HANDLE_CHANGE:
      const newForm = { ...state.profileData };
      newForm[action.id] = action.value;
      return {
        ...state,
        profileData: newForm,
        loading: false,
      };

    case ACTION_TYPE_PROFILE.HANDLE_CHANGE_FOR_CHANGE_PASSWORD:
      const newPasswordForm = { ...state.accountData };
      newPasswordForm[action.id] = action.value;
      return {
        ...state,
        accountData: newPasswordForm,
        loading: false,
      };
    case ACTION_TYPE_PROFILE.FETCH_USER_DATA:
      const userInfo = LocalDb.getSessions();
      const userLoginInfo = userInfo ? userInfo : null;
      const userData = userLoginInfo
        ? userInfo.loginResponse.loginUser.user
          ? userInfo.loginResponse.loginUser.user
          : userInfo.loginResponse.loginUser.corporate
          ? userInfo.loginResponse.loginUser.corporate
          : userInfo.loginResponse.loginUser.client
          ? userInfo.loginResponse.loginUser.client
          : userInfo.loginResponse.loginUser.contact
          ? userInfo.loginResponse.loginUser.contact
          : userInfo.loginResponse.loginUser.company
        : null;
      return {
        ...state,
        loading: false,
        profileData: userData,
        openProfileUpdateSuccessSnackBar: false,
      };
    case ACTION_TYPE_PROFILE.PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.profile.user
          ? action.profile.user
          : action.profile.corporate
          ? action.profile.corporate
          : action.profile.client
          ? action.profile.client
          : action.profile.contact
          ? action.profile.contact
          : action.profile.company,
        openProfileUpdateSuccessSnackBar: false,
      };
    case ACTION_TYPE_PROFILE.PROFILE_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_PROFILE.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        bool: action.bool,
        loading: false,
        openPasswordChangeSuccessSnackBar: true,
        openProfileSnackBar: false,
        accountData: {},
      };
    case ACTION_TYPE_PROFILE.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        openPasswordChangeSuccessSnackBar: false,
        openProfileSnackBar: true,
      };

    case ACTION_TYPE_PROFILE.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profileData: action.profilePicture,
        openProfileUpdateSuccessSnackBar: true,
        loading: false,
      };

    case ACTION_TYPE_PROFILE.CLOSE_SNACK_BAR_PROFILE_PAGE:
      return {
        ...state,
        openProfileUpdateSuccessSnackBar: false,
        openPasswordChangeSuccessSnackBar: false,
        openProfileSnackBar: false,
        loading: false,
      };

    case ACTION_TYPE_PROFILE.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error,
        openProfileUpdateSuccessSnackBar: false,
        loading: false,
        openProfileSnackBar: true,
      };

    case ACTION_TYPE_PROFILE.UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        profilePicture: action.profilePicture,
        openProfileUpdateSuccessSnackBar: false,
        loading: false,
      };

    case ACTION_TYPE_PROFILE.UPDATE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        openProfileSnackBar: true,
        openProfileUpdateSuccessSnackBar: false,
      };

    case ACTION_TYPE_PROFILE.ASSIGN_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        profileData: action.userProfile.user,
        loading: false,
      };

    case ACTION_TYPE_PROFILE.ASSIGN_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        openProfileSnackBar: true,
      };

    default:
      return state;
  }
};

export default profileState;
