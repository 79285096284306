import React, {Component} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import DateConverter from "../utils/DateConverter";

class TableComponent extends Component{
    constructor(props) {
        super(props);
        this.state={
            page: 0,
            rowsPerPage: 10,
        }
    }

    render() {
        const {tableFirstHeader, title, title1 , title2, title3,
            globalList, isCase, isClient, isContact, isCompany, isAddress, isCorporate, properties} = this.props;
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        };
        return (
            <div className={"row"} style={style.card}>
                  <TableContainer>
                <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead style={{padding: "1rem"}}>
                        <TableRow>
                            <TableCell className="first-tableHeader"
                                       style={{width: "300px"}}
                            >
                                {tableFirstHeader}
                            </TableCell>
                            {
                                isAddress ?
                                    <TableCell className="tableHeader"
                                               style={{width: "300px"}}
                                    >
                                        {title}
                                    </TableCell>  : null
                            }
                            <TableCell className="tableHeader"
                            style={{width: "300px"}}
                            >
                                {title1}
                            </TableCell>
                            <TableCell className="tableHeader" style={isAddress ? {width: "230px"} : null}>
                                {title2}
                            </TableCell>
                            {
                                isAddress || isContact || isCompany ?
                                <TableCell className="tableHeader" style={{width: "1050px"}}>
                                    {title3}
                                </TableCell> : null
                            }
                        </TableRow>
                    </TableHead>
                    {
                        globalList && globalList.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        ).map(list =>
                            (
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="first-tableBody"
                                                   onClick={()=>{
                                                       if(isCase){
                                                           properties.history.push({
                                                               pathname: "/case/update/" + list.caseId,
                                                           })
                                                       }else if(isClient){
                                                           properties.history.push({
                                                               pathname: "/client/details/" + list.clientId,
                                                           })
                                                       }}}
                                        >
                                           <span style={isCase || isClient ? {textDecoration :"underline", cursor: "pointer"} : null}>
                                               {isCase ? list.caseId : isClient ? list.ssn : isContact ?
                                                   list.name : isCompany ? list.companyName : isAddress ?
                                                       list.address2 : isCorporate ? list.corporateName
                                                           : null}
                                           </span>
                                        </TableCell>
                                        {
                                            isAddress ?
                                            <TableCell className="tableBody"  >
                                                {list.address1}
                                            </TableCell> : null
                                        }
                                        <TableCell className="tableBody">
                                            {isCase ? list.clientName : isClient ? list.firstName + " "
                                                + list.lastName : isContact  ? list.emailPhone ? list.emailPhone : "-" :
                                                    isCompany ? list.email ? list.email : "-"
                                                : isAddress ? list.city : isCorporate ? list.emailPhone : null}
                                        </TableCell>
                                        <TableCell className="tableBody">
                                            {isCase ? DateConverter(list.createdAt) :
                                                isClient ? DateConverter(list.createdAt) :
                                                    isContact ? list.phone ? list.phone : "-" :
                                            isCompany ? list.phone ? list.phone : "-" : isAddress ? list.state :
                                                isCorporate ? DateConverter(list.createdAt) : null}
                                        </TableCell>
                                        {
                                            isAddress || isContact || isCompany ?
                                                <TableCell className="tableBody">
                                                    { isContact ? list.address ? list.address : "-" :
                                                            isCompany ? list.address ? list.address : "-" :
                                                                isAddress ? list.zip5 : null}
                                                </TableCell> : null
                                        }
                                    </TableRow>
                                </TableBody>
                            )
                        )
                    }
                </Table>
                  {globalList && globalList.length > 0 ? (
                      <div style={{justifyContent: "flex-start", width: "100%"}}>
                          <TablePagination
                              labelRowsPerPage={false}
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={
                                  globalList.length > 0
                                      ? globalList.length
                                      : 100
                              }
                              rowsPerPage={this.state.rowsPerPage}
                              page={this.state.page}
                              backIconButtonProps={{
                                  "aria-label": "previous page"
                              }}
                              nextIconButtonProps={{
                                  "aria-label": "next page"
                              }}
                              onChangePage={handleChangePage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                      </div>
                  ) : (
                      <div>
                          <h3
                              style={{
                                  textAlign: "center",
                                  fontSize: "14px",
                                  padding: "1rem",
                                  color: "#606060"
                              }}
                          >
                              {" "}
                              No data to show
                          </h3>
                      </div>
                  )}
            </TableContainer>
            </div>
        )
    }
};
const style = {
    card: {
        background: "#ffffff",
        border: "1px solid rgb(224, 224, 224)",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginLeft: 0,
        marginBottom: "1rem",
    },
}

export default TableComponent;
