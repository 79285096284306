import { ACTION_TYPE_CHECK_PRINT, ACTION_TYPE_CLIENT } from "../constants/Type";

export const checkPrintRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.REQUEST_CHECK_PRINT,
    bool,
  };
};
export const closeSnackBarForCheckPrint = (bool) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.CLOSE_CHECK_PRINT_SNACKBAR,
    bool,
  };
};
export const closeModalForCheckPrint = (
  bankName,
  amount,
  startDate,
  endDate
) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.CLOSE_FILTER_MODAL,
    bankName,
    amount,
    startDate,
    endDate,
  };
};

export const fetchCheckPrintSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FETCH_CHECK_PRINT_SUCCESS,
    response,
  };
};
export const fetchCheckPrintFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FETCH_CHECK_PRINT_FAILURE,
    error,
  };
};

export const handleChangeMemo = (review) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_MEMO,
    review,
  };
};
export const handleChangeAddress1 = (addressFirst) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ADDRESS1,
    addressFirst,
  };
};
export const handleChangeAddress2 = (addressSecond) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ADDRESS2,
    addressSecond,
  };
};
export const handleChangeCity = (city) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_CITY,
    city,
  };
};
export const handleChangeState = (state) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_STATE,
    state,
  };
};
export const handleChangeZipCode = (zipCode) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ZIPCODE,
    zipCode,
  };
};
export const handleChangePayTo = (payTo) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_PAY_TO,
    payTo,
  };
};
export const handleChangeSelectBank = (bankName) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_SELECT_BANK,
    bankName,
  };
};
export const handleChangeDate = (date) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_DATE,
    date,
  };
};

export const handleChangeAmount = (amount) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_AMOUNT,
    amount,
  };
};
export const handleChangeStartDate = (startDate) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_START_DATE,
    startDate,
  };
};
export const handleChangeEndDate = (endDate) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_END_DATE,
    endDate,
  };
};

export const handleChangeAttachment = (attachment) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ATTACHEMENT,
    attachment,
  };
};

export const handleChangeDescription = (description) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_DESCRIPTION,
    description,
  };
};

export const handleChangeClientIdForCheck = (value) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_CLIENT,
    value,
  };
};

export const fetchBulkCheckPrintSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FETCH_BULK_CHECK_PRINT_SUCCESS,
    response,
  };
};
export const fetchBulkCheckPrintFailure = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FETCH_BULK_CHECK_PRINT_FAILURE,
    response,
  };
};
export const manualCheckPrintSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.PRINT_MANUAL_CHECK_SUCCESS,
    response,
  };
};
export const manualCheckPrintFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.PRINT_MANUAL_CHECK_FAILURE,
    error,
  };
};
export const bulkCheckPrintSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.PRINT_BULK_CHECK_SUCCESS,
    response,
  };
};
export const bulkCheckPrintFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.PRINT_BULK_CHECK_FAILURE,
    error,
  };
};

export const delcineCheckSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.DECLINE_CHECK_SUCCESS,
    response,
  };
};
export const delcineCheckFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.DECLINE_CHECK_FAILURE,
    error,
  };
};

// FILTER BY SINGLE TERM
export const filterByDateSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_SUCCESS,
    response,
  };
};
export const filterByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_FAILURE,
    error,
  };
};

export const filterByBankSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_SUCCESS,
    response,
  };
};
export const filterByBankFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_FAILURE,
    error,
  };
};

export const filterByAmountSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_AMOUNT_SUCCESS,
    response,
  };
};
export const filterByAmountFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_AMOUNT_FAILURE,
    error,
  };
};

//filter by combing two
export const filterByBankAndDateSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_SUCCESS,
    response,
  };
};
export const filterByBankAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_FAILURE,
    error,
  };
};
export const filterByBankAndAmountSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_SUCCESS,
    response,
  };
};
export const filterByBankAndAmountFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_FAILURE,
    error,
  };
};
export const filterByDateAmountSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_SUCCESS,
    response,
  };
};
export const filterByDateAmountFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_FAILURE,
    error,
  };
};

//filter by all
export const filterByBankAndDateAndAmountSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_SUCCESS,
    response,
  };
};
export const filterByBankAndDateAndAmountFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_FAILURE,
    error,
  };
};

export const clearCheckedValue = (checkedValue) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.CLEAR_CHECKED_VALUE,
    checkedValue,
  };
};

export const setManualCheckDataEmpty = () => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.SET_MANUAL_CHECK_DATA_TO_EMPTY,
  };
};

// EXPORT
export const exportRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.EXPORT_CHECK_REQUEST_MADE,
    bool,
  };
};
export const exportCheckSuccess = (response) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.EXPORT_CHECK_SUCCESS,
    response,
  };
};

export const exportCheckFailure = (error) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.EXPORT_CHECK_FAILURE,
    error,
  };
};

// filter checkprint
export const handleCheckPrintFilter = (id, value) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.CHECK_PRINT_FILTER,
    id,
    value,
  };
};
export const clearFilterData = () => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.CLEAR_CHECK_PRINT_FILTER,
  };
};
export const saveCheckPrintConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.SAVE_CHECK_PRINT_CONFIG_SUCCESS,
    payload,
  };
};
export const saveCheckPrintConfigFailure = (payload) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.SAVE_CHECK_PRINT_CONFIG_FAILURE,
    payload,
  };
};
export const getCheckPrintConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.GET_CHECK_PRINT_CONFIG_SUCCESS,
    payload,
  };
};
export const getCheckPrintConfigFailure = (payload) => {
  return {
    type: ACTION_TYPE_CHECK_PRINT.GET_CHECK_PRINT_CONFIG_FAILURE,
    payload,
  };
};
