import { ACTION_TYPE_CORPORATE } from "../constants/Type";

const initialState = {
    loading: false,
    openUserSnackBar: false,
    errorMsgForCorporate: '',
    corporateData: {
        corporate: {
            corporateName: '',
            emailPhone: '',
            // regNo: '',
            // taxVatNo: ''
        },
        accountType: 2
    }
};

const corporateState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_CORPORATE.CORPORATE_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_CORPORATE.CORPORATE_FETCH_SUCCESS:
            return {
                ...state,
                corporate: action.corporate.corporates,
                addCorporateSuccess:false,
                loading: false,
                openUserSnackBar: false
            };
        case ACTION_TYPE_CORPORATE.CORPORATE_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForCorporate: action.error,
                loading: false,
                openUserSnackBar: true
            };
        case ACTION_TYPE_CORPORATE.HANDLE_CHANGE_CORPORATE_TO_ADD:
            const newForm = { ...state.corporateData };
            newForm.corporate[action.id] = action.value
            return {
                ...state,
                corporateData: newForm,
                loading: false
            };
        case ACTION_TYPE_CORPORATE.HANDLE_CHANGE_CORPORATE_TO_EDIT:
            const newForm2 = { ...state.corporateDataToEdit };
            newForm2[action.id] = action.value
            return {
                ...state,
                corporateDataToEdit: newForm2,
                loading: false
            };
        case ACTION_TYPE_CORPORATE.ADD_CORPORATE_SUCCESS:
            return {
                ...state,
                addCorporateSuccess: action.bool,
                corporateData: {
                    corporate: {
                        corporateName: '',
                        emailPhone: '',
                        // regNo: '',
                        // taxVatNo: ''
                    },
                    accountType: 2
                },
                loading: false,
                openUserSnackBar: false
            };
        case ACTION_TYPE_CORPORATE.ADD_CORPORATE_FAILURE:
            return {
                ...state,
                errorMsgForCorporate: action.error,
                loading: false,
                openUserSnackBar: true
            };
        case ACTION_TYPE_CORPORATE.CLOSE_SNACK_BAR_CORPORATE_PAGE:
            return {
                ...state,
                openUserSnackBar: false,
            };
        case ACTION_TYPE_CORPORATE.SET_CORPORATE_TO_EDIT:
            return {
                ...state,
                corporateDataToEdit: action.corporateData,
                loading: false
            };
        case ACTION_TYPE_CORPORATE.SET_CORPORATE_TO_ADD_NULL:
            return {
                ...state,
                corporateData: {
                    corporate: {
                        corporateName: '',
                        emailPhone: '',
                        // regNo: '',
                        // taxVatNo: ''
                    },
                    accountType: 2
                },
                loading: false
            };

        case ACTION_TYPE_CORPORATE.DELETE_CORPORATE_SUCCESS:
            const oldUserList = state.user;
            let newUsers = [];
            for (let i = 0; i < oldUserList.length; i++) {
                if (oldUserList[i].userId !== action.userId) {
                    newUsers.push(oldUserList[i]);
                }
            }
            return {
                ...state,
                newUser: newUsers,
                loading: false,
                openUserSnackBar: false
            };
        case ACTION_TYPE_CORPORATE.DELETE_CORPORATE_FAILURE:
            return {
                ...state,
                deleteFailMsg: action.error,
                loading: false,
                openUserSnackBar: true,
                errorMsgForCorporate: action.error
            };
        case ACTION_TYPE_CORPORATE.SEARCH_CORPORATE_SUCCESS:
            return {
                ...state,
                corporate: action.response.corporates,
                loading: false,
            }
        case ACTION_TYPE_CORPORATE.SEARCH_CORPORATE_FAILURE:
            return {
                ...state,
                errorMsgForCorporate: action.error,
                loading: false,
            }
        default:
            return state;
    }
};

export default corporateState;