import { connect } from "react-redux";
import { TransactionScreenScreen } from "../../screens";
import {
  closeTransactionSnackBar,
  createTransactionFailure,
  createTransactionSuccess,
  downloadACHDocumentFailure,
  downloadACHDocumentSuccess,
  exportLedgerTransactionFailure,
  exportLedgerTransactionSuccess,
  exportSingleClientTransactionByDateFailure,
  exportSingleClientTransactionByDateSuccess,
  exportSingleClientTransactionFailure,
  exportSingleClientTransactionSuccess,
  exportTransactionByDateAndMediumFailure,
  exportTransactionByDateAndMediumSuccess,
  exportTransactionByDateAndStatusAndMediumFailure,
  exportTransactionByDateAndStatusAndMediumSuccess,
  exportTransactionByDateAndStatusFailure,
  exportTransactionByDateAndStatusSuccess,
  exportTransactionByDateFailure,
  exportTransactionByDateSuccess,
  exportTransactionByMediumFailure,
  exportTransactionByMediumSuccess,
  exportTransactionByStatusAndMediumFailure,
  exportTransactionByStatusAndMediumSuccess,
  exportTransactionByStatusFailure,
  exportTransactionByStatusSuccess,
  exportTransactionByTermFailure,
  exportTransactionByTermSuccess,
  exportTransactionFailure,
  exportTransactionSuccess,
  fetchLedgerTransactionFailure,
  fetchLedgerTransactionSuccess,
  fetchTransactionFailure,
  fetchTransactionSuccess,
  getClientTransactionFailure,
  getClientTransactionSuccess,
  handleChangeCSVFile,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleChangeExportType,
  handleChangeMedium,
  handleChangeStatus,
  handleChangeTerm,
  handleChangeTransactionRemark,
  handleChangeTransactionStatus,
  handleChangeTransData,
  handleChangeTransType,
  handleChangeVendor,
  importTransactionFailure,
  importTransactionSuccess,
  openSnackBar,
  searchClientTransactionByDateFailure,
  searchClientTransactionByDateSuccess,
  searchClientTransactionFailure,
  searchClientTransactionSuccess,
  searchTransactionByDateFailure,
  searchTransactionByDateSuccess,
  searchTransactionByMediumAndDateFailure,
  searchTransactionByMediumAndDateSuccess,
  searchTransactionByMediumAndStatusAndDateFailure,
  searchTransactionByMediumAndStatusAndDateSuccess,
  searchTransactionByMediumAndStatusFailure,
  searchTransactionByMediumAndStatusSuccess,
  searchTransactionByMediumFailure,
  searchTransactionByMediumSuccess,
  searchTransactionByStatusAndDateFailure,
  searchTransactionByStatusAndDateSuccess,
  searchTransactionByStatusFailure,
  searchTransactionByStatusSuccess,
  searchTransactionFailure,
  searchTransactionSuccess,
  setTransactionToViewFailure,
  setTransactionToViewSuccess,
  transactionRequestMade,
  updateSubmitTransactionFailure,
  updateSubmitTransactionSuccess,
  handleChangeToOpenNoteModal,
  handleChangeToNotes,
  addNoteSuccess,
  addNoteFailure,
  handleOpenCreateWithdrawalVendorTransaction,
  createWithdrawalVendorTxnsSuccess,
  createWithdrawalVendorTxnsFailure,
  fetchWithdrawalVendorListSuccess,
  fetchWithdrawalVendorListFailure,
  getAmountDetailByClientIdSuccess,
  getAmountDetailByClientIdFailure,
  clearAmountDetail,
  getBankDetailsSuccess,
  getBankDetailsFailure,
  updateLedgerTxnSuccess,
  updateLedgerTxnFailure,
  setLedgerTxnStatusUpdateModal,
  clearWithdrawalTransactionForm,
  clearCreateTransactionForm,
  handleChangeToOpenNoteModalForLedger,
  handleChangeDescData,
  deleteTransactionSuccess,
  deleteTransactionFailure,
  updateDescriptionSuccess,
  updateDescriptionError,
  setTransactionInputDataToEdit,
  updateBlockedTransSuccess,
  updateBlockedTransFailure,
  handleChangeBlockedTransData,
  clearEditBlockedTrans,
  delcineTransCheckSuccess,
  delcineTransCheckFailure,
  handleTransactionFilter,
  transConfigFailure,
  transConfigSuccess,
  clearTransactionFilter,
  saveTransConfigSuccess,
  saveTransConfigFailure,
  getTransConfigFailure,
  getTransConfigSuccess,
  updateTransactionSuccess,
  updateTransactionFailure,
  handlChangeReverseDate,
  clearTransactionMainFilter,
  fetchDeletedTransactionSuccess,
  fetchDeletedTransactionFailure,
  exportTransactionVODRequest,
  exportTransactionVODSuccess,
  exportTransactionVODFailure,
} from "../../actions/TransactionAction";
import {
  ActivityLogAPI,
  // CheckPrintAPI,
  ClientAPI,
  DynamicActionAPI,
  SettingsAPI,
  TransactionAPI,
  UpcomingTransactionAPI,
  VendorAPI,
} from "../../api";
import {
  clientRequestMade,
  fetchScheduleTransactionFailure,
  fetchScheduleTransactionSuccess,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchUpcomingTransactionFailure,
  fetchUpcomingTransactionSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  processTransactionFailure,
  processTransactionSuccess,
  scheduleTransactionRequestMade,
  searchClientListFailure,
  searchClientListSuccess,
  upComingTransactionRequestMade,
  vendorRequestMade,
} from "../../actions";
import {
  clearUpTransFilter,
  closeUpcomingTransSnackBar,
  fetchAnnualUpcomingTransactionFailure,
  fetchAnnualUpcomingTransactionSuccess,
  handlechangeRemarkToSkip,
  handleChangeToOpenSkipModal,
  handleUpTransFilter,
  searchAnnualUpcomingTransByTermFailure,
  searchAnnualUpcomingTransByTermSuccess,
  searchUpcomingTransByDateFailure,
  searchUpcomingTransByDateSuccess,
  searchUpcomingTransByMediumAndDateFailure,
  searchUpcomingTransByMediumAndDateSuccess,
  searchUpcomingTransByMediumFailure,
  searchUpcomingTransByMediumSuccess,
  searchUpcomingTransByTermFailure,
  searchUpcomingTransByTermSuccess,
} from "../../actions/UpComingTransactionAction";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
  clearFileData,
  // setScheduleTransactionInputDataToEdit,
  uploadDocFileFailure,
  uploadDocFileSuccess,
} from "../../actions/ScheduleTransactionAction";
import {
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import {
  fetchVendorClientByIdFailure,
  fetchVendorClientByIdSuccess,
} from "../../actions/VendorAction";
import { closeScheduleTransactionSnackBar } from "../../actions/ScheduleTransactionAction";
import {
  activityLogRequestMade,
  fetchActivityLogByIdFailure,
  fetchActivityLogByIdSuccess,
} from "../../actions/ActivityLogAction";
import { clearClientSingle } from "../../actions/ClientAction";
// import { checkPrintRequestMade, delcineCheckFailure, delcineCheckSuccess } from "../../actions/CheckPrintAction";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactionList: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchTransactionList((response, error) => {
        if (response) {
          dispatch(fetchTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchTransactionFailure(error.msg));
          } else {
            dispatch(fetchTransactionFailure(error));
          }
        }
      });
    },

    // fetch deleted transaction
    fetchDeletedTransaction: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchTransactionDeleted((response, error) => {
        if (response) {
          dispatch(fetchDeletedTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDeletedTransactionFailure(error.msg));
          } else {
            dispatch(fetchDeletedTransactionFailure(error));
          }
        }
      });
    },

    getTransactionInfo: (transactionId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchSingleTransaction(
        transactionId,
        (response, error) => {
          if (response) {
            dispatch(setTransactionToViewSuccess(response));
          } else {
            if (error.msg) {
              dispatch(setTransactionToViewFailure(error.msg));
            } else {
              dispatch(setTransactionToViewFailure(error));
            }
          }
        }
      );
    },
    getClientTransaction: (clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.getClientTransaction(clientId, (response, error) => {
        if (response) {
          dispatch(getClientTransactionSuccess(response));
          props.history.push("/transaction/client");
        } else {
          if (error.msg) {
            dispatch(getClientTransactionFailure(error.msg));
          } else {
            dispatch(getClientTransactionFailure(error));
          }
        }
      });
    },
    searchTransaction: (value) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionFailure(error.msg));
          } else {
            dispatch(searchTransactionFailure(error));
          }
        } else {
          dispatch(searchTransactionSuccess(response));
        }
      });
    },
    searchDeletedTransaction: (value) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionFailure(error.msg));
          } else {
            dispatch(searchTransactionFailure(error));
          }
        } else {
          dispatch(searchTransactionSuccess(response));
        }
      });
    },
    searchTransactionByMedium: (medium) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByMedium(medium, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionByMediumFailure(error.msg));
          } else {
            dispatch(searchTransactionByMediumFailure(error));
          }
        } else {
          dispatch(searchTransactionByMediumSuccess(response));
        }
      });
    },
    searchTransactionByStatus: (status) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByStatus(status, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionByStatusFailure(error.msg));
          } else {
            dispatch(searchTransactionByStatusFailure(error));
          }
        } else {
          dispatch(searchTransactionByStatusSuccess(response));
        }
      });
    },
    searchTransactionByDate: (from, to) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByDate(from, to, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionByDateFailure(error.msg));
          } else {
            dispatch(searchTransactionByDateFailure(error));
          }
        } else {
          dispatch(searchTransactionByDateSuccess(response));
        }
      });
    },
    searchTransactionByMediumAndStatus: (medium, status) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByMediumAndStatus(
        medium,
        status,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchTransactionByMediumAndStatusFailure(error.msg));
            } else {
              dispatch(searchTransactionByMediumAndStatusFailure(error));
            }
          } else {
            dispatch(searchTransactionByMediumAndStatusSuccess(response));
          }
        }
      );
    },
    searchTransactionByMediumAndDate: (medium, from, to) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByMediumAndDate(
        medium,
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchTransactionByMediumAndDateFailure(error.msg));
            } else {
              dispatch(searchTransactionByMediumAndDateFailure(error));
            }
          } else {
            dispatch(searchTransactionByMediumAndDateSuccess(response));
          }
        }
      );
    },
    searchTransactionByStatusAndDate: (status, from, to) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByStatusAndDate(
        status,
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchTransactionByStatusAndDateFailure(error.msg));
            } else {
              dispatch(searchTransactionByStatusAndDateFailure(error));
            }
          } else {
            dispatch(searchTransactionByStatusAndDateSuccess(response));
          }
        }
      );
    },
    searchTransactionByAll: (medium, status, type, from, to, vendor) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchTransactionByMediumAndStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchTransactionByMediumAndStatusAndDateFailure(error));
            }
          } else {
            dispatch(
              searchTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },

    filterTransactionByAll: (
      description,
      checkNo,
      userName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      balance,
      debitCreditAmount,
      compareType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.filterTransactionByAll(
        description,
        checkNo,
        userName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        balance,
        debitCreditAmount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchTransactionByMediumAndStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchTransactionByMediumAndStatusAndDateFailure(error));
            }
          } else {
            dispatch(
              searchTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },

    filterLedgerByAll: (
      description,
      checkNo,
      userName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      balance,
      compareType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.filterLedgerByAll(
        description,
        checkNo,
        userName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        balance,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(fetchLedgerTransactionFailure(error.msg));
            } else {
              dispatch(fetchLedgerTransactionFailure(error));
            }
          } else {
            dispatch(fetchLedgerTransactionSuccess(response));
          }
        }
      );
    },
    // filterLedgerClientByAll: (
    //   id,
    //   description,
    //   checkNo,
    //   userName,
    //   vendorName,
    //   transactionMedium,
    //   transactionType,
    //   transactionStatus,
    //   transactionDate,
    //   balance,
    //   compareType
    // ) => {
    //   dispatch(transactionRequestMade(true));
    //   TransactionAPI.filterLedgerByClient(
    //     id,
    //     description,
    //     checkNo,
    //     userName,
    //     vendorName,
    //     transactionMedium,
    //     transactionType,
    //     transactionStatus,
    //     transactionDate === null ? "" : transactionDate,
    //     balance,
    //     compareType,
    //     (response, error) => {
    //       if (error) {
    //         if (error.msg) {
    //           dispatch(fetchLedgerTransactionFailure(error.msg));
    //         } else {
    //           dispatch(fetchLedgerTransactionFailure(error));
    //         }
    //       } else {
    //         dispatch(fetchLedgerTransactionSuccess(response));
    //       }
    //     }
    //   );
    // },

    // CLIENT
    searchClientTransaction: (value, clientId) => {
      TransactionAPI.searchClientTransaction(
        value,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionFailure(error.msg));
            } else {
              dispatch(searchClientTransactionFailure(error));
            }
          } else {
            dispatch(searchClientTransactionSuccess(response));
          }
        }
      );
    },
    searchClientTransactionByDate: (from, to, clientId) => {
      TransactionAPI.searchClientTransactionByDate(
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionByDateFailure(error.msg));
            } else {
              dispatch(searchClientTransactionByDateFailure(error));
            }
          } else {
            dispatch(searchClientTransactionByDateSuccess(response));
          }
        }
      );
    },

    //EXPORT PDF (OVERALL TRANSACTION)
    exportTransactionList: (exportType) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransaction(exportType, (response, error) => {
        if (response) {
          window.open(response.transactionExportResponse.url, "_blank");
          dispatch(exportTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(exportTransactionFailure(error.msg));
          } else {
            dispatch(exportTransactionFailure(error));
          }
        }
      });
    },

    exportTransactionByDate: (from, to, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByDate(from, to, (response, error) => {
        if (response) {
          props.fetchTransactionList();
          window.open(response.transactionExportResponse.url, "_blank");
          dispatch(exportTransactionByDateSuccess(response));
        } else {
          if (error.msg) {
            dispatch(exportTransactionByDateFailure(error.msg));
          } else {
            dispatch(exportTransactionByDateFailure(error));
          }
        }
      });
    },
    exportTransactionByTerm: (term, exportType, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByTerm(
        term,
        exportType,
        (response, error) => {
          if (response) {
            props.fetchTransactionList();
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByTermSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByTermFailure(error.msg));
            } else {
              dispatch(exportTransactionByTermFailure(error));
            }
          }
        }
      );
    },
    exportTransactionByMedium: (medium, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByMedium(medium, (response, error) => {
        if (response) {
          props.fetchTransactionList();
          var popUp = window.open(
            response.transactionExportResponse.url,
            "_blank"
          );
          if (popUp == null || typeof popUp == "undefined") {
            alert(
              'Please disable your pop-up blocker and click the "Open" link again.'
            );
          } else {
            popUp.focus();
          }
          // window.open(response.transactionExportResponse.url, '_blank');
          dispatch(exportTransactionByMediumSuccess(response));
        } else {
          if (error.msg) {
            dispatch(exportTransactionByMediumFailure(error.msg));
          } else {
            dispatch(exportTransactionByMediumFailure(error));
          }
        }
      });
    },
    exportTransactionByStatus: (status, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByStatus(status, (response, error) => {
        if (response) {
          props.fetchTransactionList();
          window.open(response.transactionExportResponse.url, "_blank");
          dispatch(exportTransactionByStatusSuccess(response));
        } else {
          if (error.msg) {
            dispatch(exportTransactionByStatusFailure(error.msg));
          } else {
            dispatch(exportTransactionByStatusFailure(error));
          }
        }
      });
    },
    exportTransactionByStatusAndMedium: (status, medium, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByStatusAndMedium(
        status,
        medium,
        (response, error) => {
          if (response) {
            props.fetchTransactionList();
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },
    exportTransactionByDateAndMedium: (from, to, medium, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByDateAndMedium(
        from,
        to,
        medium,
        (response, error) => {
          if (response) {
            props.fetchTransactionList();
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByDateAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByDateAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByDateAndMediumFailure(error));
            }
          }
        }
      );
    },
    exportTransactionByDateAndStatus: (from, to, status, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByDateAndStatus(
        from,
        to,
        status,
        (response, error) => {
          if (response) {
            props.fetchTransactionList();
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByDateAndStatusSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByDateAndStatusFailure(error.msg));
            } else {
              dispatch(exportTransactionByDateAndStatusFailure(error));
            }
          }
        }
      );
    },
    exportTransactionByDateAndStatusAndMedium: (
      from,
      to,
      status,
      medium,
      props
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByDateAndStatusAndMedium(
        from,
        to,
        status,
        medium,
        (response, error) => {
          if (response) {
            props.fetchTransactionList();
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(
              exportTransactionByDateAndStatusAndMediumSuccess(response)
            );
          } else {
            if (error.msg) {
              dispatch(
                exportTransactionByDateAndStatusAndMediumFailure(error.msg)
              );
            } else {
              dispatch(exportTransactionByDateAndStatusAndMediumFailure(error));
            }
          }
        }
      );
    },
    //by all
    exportTransactionByAll: (
      medium,
      status,
      type,

      from,
      to,
      vendor,
      exportType,

      props
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByAll(
        medium,
        status,
        type,

        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        exportType,

        (response, error) => {
          if (response) {
            var popUp = window.open(
              response.transactionExportResponse.url,
              "_blank"
            );
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },
    // EXPORING VOD
    exportTransactionVOD: (ClientID, from, to, exportType) => {
      dispatch(exportTransactionVODRequest(true));
      TransactionAPI.exportTransactionVOD(
        ClientID,
        from === null ? 0 : from,
        to === null ? 0 : to,
        exportType,
        (response, error) => {
          if (response && !response.msg) {
            window.open(response.url, "_blank");
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else if (response && response.msg) {
            dispatch(exportTransactionByStatusAndMediumFailure(response.msg));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },

    //by column filter
    exportTransactionByColumnFilter: (
      transactionMedium,
      transactionType,

      transactionStatus,

      exportType,
      description,
      checkNo,
      userName,
      vendorName,
      balance,
      compareType,
      transDate,
      filterDebitAmount,
      props
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportTransactionByColumnFilter(
        transactionMedium,
        transactionType,

        transactionStatus,

        exportType,
        description,
        checkNo,
        userName,
        vendorName,
        balance,
        compareType,
        transDate === null ? 0 : transDate,
        filterDebitAmount,
        (response, error) => {
          if (response) {
            var popUp = window.open(
              response.transactionExportResponse.url,
              "_blank"
            );
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },
    //exporting pdf
    exportSingleClientTransactionList: (clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransaction(
        clientId,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportSingleClientTransactionFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionFailure(error));
            }
          }
        }
      );
    },

    exportSingleClientTransactionByDate: (from, to, clientId) => {
      TransactionAPI.exportSingleClientTransactionByDate(
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(exportSingleClientTransactionByDateFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionByDateFailure(error));
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionByDateSuccess(response));
          }
        }
      );
    },

    handleTransactionSnackBarClose: () =>
      dispatch(closeTransactionSnackBar(true)),
    handleChangeUpcomingTransSnackBar: () =>
      dispatch(closeUpcomingTransSnackBar(true)),
    handleChangeDateFrom: (from) => dispatch(handleChangeDateFrom(from)),
    handleChangeDateTo: (to) => dispatch(handleChangeDateTo(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),
    handleChangeStatus: (status) => dispatch(handleChangeStatus(status)),
    handleChangeTerm: (term) => dispatch(handleChangeTerm(term)),
    handleChangeVendor: (vendor) => dispatch(handleChangeVendor(vendor)),
    handleChangeTransType: (transType) =>
      dispatch(handleChangeTransType(transType)),
    handleChangeExportType: (exportType) =>
      dispatch(handleChangeExportType(exportType)),
    clearSnackBar: () => dispatch(openSnackBar()),

    // UPCOMING TRANSACTION
    fetchUpcomingTransactionList: () => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.fetchUpcomingTransactionList((response, error) => {
        if (response) {
          dispatch(fetchUpcomingTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchUpcomingTransactionFailure(error.msg));
          } else {
            dispatch(fetchUpcomingTransactionFailure(error));
          }
        }
      });
    },
    fetchAnnualUpcomingTransactionList: () => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.fetchAnnualUpcomingTransactionList(
        (response, error) => {
          if (response) {
            dispatch(fetchAnnualUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchAnnualUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchAnnualUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },
    processUpcomingTransaction: (data, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.processUpcomingTransaction(
        data,
        (response, error) => {
          if (response) {
            dispatch(processTransactionSuccess(response));
            props.fetchUpcomingTransactionList();
            props.fetchTransactionList();
          } else {
            if (error.msg) {
              dispatch(processTransactionFailure(error.msg));
            } else {
              dispatch(processTransactionFailure(error));
            }
          }
        }
      );
    },
    skipUpcomingTransaction: (data, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.skipUpcomingTransaction(
        data,
        (response, error) => {
          if (response) {
            dispatch(processTransactionSuccess(response));
            props.fetchUpcomingTransactionList();
            props.fetchTransactionList();
            props.handleChangeToOpenSkipModal(false);
          } else {
            if (error.msg) {
              dispatch(processTransactionFailure(error.msg));
              props.handleChangeToOpenSkipModal(true);
            } else {
              dispatch(processTransactionFailure(error));
              props.handleChangeToOpenSkipModal(true);
            }
          }
        }
      );
    },
    handleUpcomingTransactionSnackBarClose: () =>
      dispatch(closeUpcomingTransSnackBar(true)),
    //search for upcoming transaction
    searchUpcomingTransactionByTerm: (value, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByTerm(
        value,
        (response, error) => {
          if (response) {
            dispatch(searchUpcomingTransByTermSuccess(response));
          } else {
            if (error.msg) {
              dispatch(searchUpcomingTransByTermFailure(error.msg));
            } else {
              dispatch(searchUpcomingTransByTermFailure(error));
            }
          }
        }
      );
    },
    searchAnnualUpcomingTransactionByTerm: (value, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchAnnualUpcomingTransactionByTerm(
        value,
        (response, error) => {
          if (response) {
            dispatch(searchAnnualUpcomingTransByTermSuccess(response));
          } else {
            if (error.msg) {
              dispatch(searchAnnualUpcomingTransByTermFailure(error.msg));
            } else {
              dispatch(searchAnnualUpcomingTransByTermFailure(error));
            }
          }
        }
      );
    },
    searchUpcomingTransactionByMedium: (medium, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByMedium(
        medium,
        (response, error) => {
          if (response) {
            dispatch(searchUpcomingTransByMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(searchUpcomingTransByMediumFailure(error.msg));
            } else {
              dispatch(searchUpcomingTransByMediumFailure(error));
            }
          }
        }
      );
    },
    searchUpcomingTransactionByDate: (from, to, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByDate(
        from,
        to,
        (response, error) => {
          if (response) {
            dispatch(searchUpcomingTransByDateSuccess(response));
          } else {
            if (error.msg) {
              dispatch(searchUpcomingTransByDateFailure(error.msg));
            } else {
              dispatch(searchUpcomingTransByDateFailure(error));
            }
          }
        }
      );
    },
    searchUpcomingTransactionByMediumAndDate: (
      medium,
      from,
      to,
      vendor,
      type,
      props
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByMediumAndDate(
        medium,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        type,
        (response, error) => {
          if (response) {
            dispatch(searchUpcomingTransByMediumAndDateSuccess(response));
          } else {
            if (error.msg) {
              dispatch(searchUpcomingTransByMediumAndDateFailure(error.msg));
            } else {
              dispatch(searchUpcomingTransByMediumAndDateFailure(error));
            }
          }
        }
      );
    },

    searchUpcomingTransactionByAll: (
      // startDate,
      // endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      // transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByAll(
        // startDate === null ? "" : startDate,
        // endDate === null ? "" : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        // transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (response) {
            dispatch(searchUpcomingTransByMediumAndDateSuccess(response));
          } else {
            if (error.msg) {
              dispatch(searchUpcomingTransByMediumAndDateFailure(error.msg));
            } else {
              dispatch(searchUpcomingTransByMediumAndDateFailure(error));
            }
          }
        }
      );
    },

    //fetch transaction
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },

    //get amount details by client id
    getAmountDetailByClientId: (clientId) => {
      TransactionAPI.getAmountDetailByClientId(clientId, (response, error) => {
        if (response) {
          dispatch(getAmountDetailByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAmountDetailByClientIdFailure(error.msg));
          } else {
            dispatch(getAmountDetailByClientIdFailure(error));
          }
        }
      });
    },

    clearAmountDetail: () => {
      dispatch(clearAmountDetail());
    },
    clearTransactionForm: () => {
      dispatch(clearCreateTransactionForm());
    },
    clearWithdrawalTransactionForm: () => {
      dispatch(clearWithdrawalTransactionForm());
    },

    fetchScheduleTransactionList: () => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchScheduleTransactionList((response, error) => {
        if (response) {
          dispatch(fetchScheduleTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchScheduleTransactionFailure(error.msg));
          } else {
            dispatch(fetchScheduleTransactionFailure(error));
          }
        }
      });
    },
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
          if (response.client && response.client.bankDetail.length === 1) {
            const e =
              response.client.bankDetail[0] &&
              response.client.bankDetail[0].bankDetailId;
            props.handleChangeTransData("bankDetailId", e);
          }
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    clearClientSingle: () => {
      dispatch(clearClientSingle());
    },
    uploadDocumentFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formdata = new FormData();
      formdata.append("doc", selectedFiles.document);
      formdata.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadDocumentFile(formdata, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
          props.handleChangeTransData("document", response.fileUrl);
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },
    searchClientList: (value) => {
      ScheduleTransactionAPI.searchClientList(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientListFailure(error.msg));
          } else {
            dispatch(searchClientListFailure(error));
          }
        } else {
          dispatch(searchClientListSuccess(response));
        }
      });
    },
    handleChangeTransData: (id, value) => {
      dispatch(handleChangeTransData(id, value));
    },
    handleChangeBlockedTransData: (id, value) => {
      dispatch(handleChangeBlockedTransData(id, value));
    },
    createTransData: (value, props) => {
      TransactionAPI.createTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(createTransactionFailure(error.msg));
          } else {
            dispatch(createTransactionFailure(error));
          }
        } else {
          dispatch(createTransactionSuccess(true));
          props.fetchTransactionList();
          props.fetchLedgerTransactionList(
            0,
            0,
            "UNKNOWN_TRANSACTION_STATUS",
            "UNKNOWN_TRANSACTION_TYPE",
            ""
          );
        }
      });
    },

    // update transaction data
    updateTransData: (value, props) => {
      TransactionAPI.updateTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(updateTransactionFailure(error.msg));
          } else {
            dispatch(updateTransactionFailure(error));
          }
        } else {
          dispatch(updateTransactionSuccess(true));
          props.fetchTransactionList();
        }
      });
    },

    // delete transaction
    deleteTransData: (transactionId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.deleteTransaction(transactionId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteTransactionFailure(error.msg));
          } else {
            dispatch(deleteTransactionFailure(error));
          }
        } else {
          props.fetchTransactionList();
          dispatch(deleteTransactionSuccess(transactionId));
        }
      });
    },
    clearFileData: () => {
      dispatch(clearFileData());
    },
    setCreateTransactionSuccessToFalse: () =>
      dispatch(createTransactionSuccess(false)),
    setUpdateTransactionSuccessToFalse: () =>
      dispatch(updateTransactionSuccess(false)),
    uploadCSVFileToImport: (csvFile, props) => {
      dispatch(transactionRequestMade(true));
      let formData = new FormData();
      formData.append("doc", csvFile.document);
      TransactionAPI.uploadCSVFileToImport(formData, (response, error) => {
        if (response) {
          dispatch(importTransactionSuccess(true));
        } else {
          if (error.msg) {
            dispatch(importTransactionFailure(error.msg));
          } else {
            dispatch(importTransactionFailure(error));
          }
        }
      });
    },
    getBankDetails: (props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.getBankDetails((response, error) => {
        if (response) {
          dispatch(getBankDetailsSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getBankDetailsFailure(error.msg));
          } else {
            dispatch(getBankDetailsFailure(error));
          }
        }
      });
    },
    importTransactionSuccessToFalse: () => {
      dispatch(importTransactionSuccess(false));
    },
    handleChangeCSVFile: (csvFile) => {
      dispatch(handleChangeCSVFile(csvFile));
    },

    //download ACH
    downloadACHDoc: (transactionId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.downloadACHDoc(transactionId, (response, error) => {
        if (response) {
          window.open(response.msg, "_blank");
          dispatch(downloadACHDocumentSuccess(response));
        } else {
          if (error.msg) {
            dispatch(downloadACHDocumentFailure(error.msg));
          } else {
            dispatch(downloadACHDocumentFailure(error));
          }
        }
      });
    },
    //description
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },

    //DESCRIPTION
    addDescription: (description, props, fetchLedgerTransactionList) => {
      dispatch(transactionRequestMade(true));
      // dispatch(clientRequestMade(true));
      TransactionAPI.addDescription(description, (response, error) => {
        if (response) {
          dispatch(updateDescriptionSuccess(response));
          // props.getClientTransaction(clientId);
          props.fetchTransactionList();
          props.fetchLedgerTransactionList(
            0,
            0,
            "UNKNOWN_TRANSACTION_STATUS",
            "UNKNOWN_TRANSACTION_TYPE",
            ""
          );
        } else {
          if (error.msg) {
            dispatch(updateDescriptionError(error.msg));
          } else {
            dispatch(updateDescriptionError(error));
          }
        }
      });
    },
    // edit transactions

    setTransactionInputDataToEdit: (selectedTransData) =>
      dispatch(setTransactionInputDataToEdit(selectedTransData)),
    // approve/decline transaction
    handleChangeTransStatus: (status) =>
      dispatch(handleChangeTransactionStatus(status)),
    handleChangeRemark: (remark) =>
      dispatch(handleChangeTransactionRemark(remark)),
    updateSubmitTransactionList: (transData, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateTransactionStatus(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.fetchTransactionList();
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },
    undoReverseTranx: (transData, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.undoReverseTranx(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.fetchTransactionList();
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },
    setChangeTransactionStatusSuccessToFalse: () => {
      dispatch(updateSubmitTransactionSuccess(false));
    },

    // client vendor
    fetchVendorClientById: (id) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorClientById(id, (response, error) => {
        if (response) {
          dispatch(fetchVendorClientByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorClientByIdFailure(error.msg));
          } else {
            dispatch(fetchVendorClientByIdFailure(error));
          }
        }
      });
    },
    // fetch ledger transaction
    fetchLedgerTransactionList: (from, to, status, type, term) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchLedgerTransactionList(
        from === null ? 0 : from,
        to === null ? 0 : to,
        status,
        type,
        term,
        (response, error) => {
          if (response) {
            dispatch(fetchLedgerTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchLedgerTransactionFailure(error.msg));
            } else {
              dispatch(fetchLedgerTransactionFailure(error));
            }
          }
        }
      );
    },
    // export ledger transaction
    exportLedgerTransactionList: (from, to, status, type, term, exportType) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportLedgerTransactionList(
        from === null ? 0 : from,
        to === null ? 0 : to,
        status,
        type,
        term,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportLedgerTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportLedgerTransactionFailure(error.msg));
            } else {
              dispatch(exportLedgerTransactionFailure(error));
            }
          }
        }
      );
    },

    // export ledger transaction by column filter
    exportLedgerTransactionListByColumnFilter: (
      desc,
      checkNo,
      user,
      vendor,
      medium,
      type,
      stat,
      transDate,
      amount,
      compareType,
      exportType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportLedgerTransactionListBYColumnFilter(
        desc,
        checkNo,
        user,
        vendor,
        medium,
        type,
        stat,
        transDate === null ? 0 : transDate,
        amount,
        compareType,
        exportType,

        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportLedgerTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportLedgerTransactionFailure(error.msg));
            } else {
              dispatch(exportLedgerTransactionFailure(error));
            }
          }
        }
      );
    },

    // create txn from ledger table
    handleOpenCreateWithdrawalVendorTransaction: (bool) => {
      dispatch(handleOpenCreateWithdrawalVendorTransaction(bool));
    },

    createWithdrawVendorTransaction: (transactionData, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.createTransaction(transactionData, (response, error) => {
        if (response) {
          dispatch(createWithdrawalVendorTxnsSuccess(response));
          props.fetchTransactionList();
          props.fetchLedgerTransactionList(
            0,
            0,
            "UNKNOWN_TRANSACTION_STATUS",
            "UNKNOWN_TRANSACTION_TYPE",
            ""
          );
        } else {
          if (error.msg) {
            dispatch(createWithdrawalVendorTxnsFailure(error.msg));
          } else {
            dispatch(createWithdrawalVendorTxnsFailure(error));
          }
        }
      });
    },

    closeScheduleTransactionSnackBar: () => {
      dispatch(closeScheduleTransactionSnackBar());
    },
    handleChangeToOpenSkipModal: (bool) => {
      dispatch(handleChangeToOpenSkipModal(bool));
    },
    handleChangeRemarkToSkip: (remarkToSkip) => {
      dispatch(handlechangeRemarkToSkip(remarkToSkip));
    },

    // NOTES
    handleChangeToOpenNoteModal: (bool) => {
      dispatch(handleChangeToOpenNoteModal(bool));
    },
    handleChangeToNotes: (notes) => {
      dispatch(handleChangeToNotes(notes));
    },

    //DESCRIPTION
    handleChangeDescData: (id, value) => {
      dispatch(handleChangeDescData(id, value));
    },

    // FOR LEDGER NOTES
    handleChangeToOpenNoteModalForLedger: (bool) => {
      dispatch(handleChangeToOpenNoteModalForLedger(bool));
    },

    addNoteForTransaction: (
      notesData,
      props,
      handleChangeToOpenNoteModalForLedger,
      fetchLedgerTransactionList
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.addTransactionNotes(notesData, (response, error) => {
        if (response) {
          dispatch(addNoteSuccess(response));
          if (handleChangeToOpenNoteModalForLedger) {
            handleChangeToOpenNoteModalForLedger(false);
            fetchLedgerTransactionList(
              0,
              0,
              "UNKNOWN_TRANSACTION_STATUS",
              "UNKNOWN_TRANSACTION_TYPE",
              ""
            );
          } else {
            props.fetchTransactionList();
          }
        } else {
          if (error.msg) {
            dispatch(addNoteFailure(error.msg));
          } else {
            dispatch(addNoteFailure(error));
          }
        }
      });
    },
    fetchWithdrawalVendorList: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchWithdrawalVendorList((response, error) => {
        if (response) {
          dispatch(fetchWithdrawalVendorListSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchWithdrawalVendorListFailure(error.msg));
          } else {
            dispatch(fetchWithdrawalVendorListFailure(error));
          }
        }
      });
    },
    fetchActivityLogByRefId: (id) => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLogByRefId(id, (response, error) => {
        if (response) {
          dispatch(fetchActivityLogByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchActivityLogByIdFailure(error.msg));
          } else {
            dispatch(fetchActivityLogByIdFailure(error));
          }
        }
      });
    },
    fetchSingleClientSuccess: () => {
      const response = {
        client: [],
      };
      dispatch(fetchSingleClientSuccess(response));
    },

    updateCheckStatus: (checkData, props) => {
      // dispatch(checkPrintRequestMade(true));
      TransactionAPI.updateCheck(checkData, (response, error) => {
        if (response) {
          dispatch(delcineTransCheckSuccess(response));
          props.fetchTransactionList();
        } else {
          if (error.msg) {
            dispatch(delcineTransCheckFailure(error.msg));
          } else {
            dispatch(delcineTransCheckFailure(error));
          }
        }
      });
    },
    // edit blocked transaction
    editBlockedTransaction: (data, props) => {
      TransactionAPI.updateBlockedTrans(data, (response, error) => {
        if (response) {
          dispatch(updateBlockedTransSuccess(response));
          props.fetchTransactionList();
        } else {
          if (error.msg) {
            dispatch(updateBlockedTransFailure(error.msg));
          } else {
            dispatch(updateBlockedTransFailure(error));
          }
        }
      });
    },
    clearEditBlockedTrans: () => {
      dispatch(clearEditBlockedTrans());
    },

    // LEDGER TRANSACTION UPDATE
    setOpenLedgerTxnUpdate: (bool) => {
      dispatch(setLedgerTxnStatusUpdateModal(bool));
    },
    updateLedgerTransaction: (transData, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateLedgerTransactionStatus(
        transData,
        (response, error) => {
          if (response) {
            dispatch(updateLedgerTxnSuccess(true));
            props.fetchLedgerTransactionList(
              0,
              0,
              "UNKNOWN_TRANSACTION_STATUS",
              "UNKNOWN_TRANSACTION_TYPE",
              ""
            );
          } else {
            if (error.msg) {
              dispatch(updateLedgerTxnFailure(error.msg));
            } else {
              dispatch(updateLedgerTxnFailure(error));
            }
          }
        }
      );
    },

    // filter transaction
    handleTransactionFilter: (id, value) => {
      dispatch(handleTransactionFilter(id, value));
    },
    handleUpTransFilter: (id, value) => {
      dispatch(handleUpTransFilter(id, value));
    },
    clearTransactionFilter: () => {
      dispatch(clearTransactionFilter());
    },
    clearTransactionMainFilter: () => {
      dispatch(clearTransactionMainFilter());
    },
    clearUpTransFilter: () => {
      dispatch(clearUpTransFilter());
    },

    handlChangeReverseDate: (date) => {
      dispatch(handlChangeReverseDate(date));
    },

    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getTransConfigFailure(error.msg));
          } else {
            dispatch(getTransConfigFailure(error));
          }
        } else {
          dispatch(getTransConfigSuccess(response.configs));
        }
      });
    },
    saveInConfig: (data) => {
      TransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveTransConfigFailure(error.msg));
          } else {
            dispatch(saveTransConfigFailure(error));
          }
        } else {
          dispatch(saveTransConfigSuccess(response));
        }
      });
    },

    saveInConfigforUP: (data) => {
      UpcomingTransactionAPI.saveConfigUP(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveTransConfigFailure(error.msg));
          } else {
            dispatch(saveTransConfigFailure(error));
          }
        } else {
          dispatch(saveTransConfigSuccess(response));
        }
      });
    },
  };
};
export const TransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionScreenScreen);
