import {ACTION_TYPE_CARD, ACTION_TYPE_SCHEDULE_TRANSACTION,} from "../constants/Type";
import React from "react";

const initialState = {
    loading: false,
    cards:[],
    errorMsg: '',
    successMsg: '',
    clientId: '',
    success: '',
    openCardSuccessSnackBar: false,
    openCardErrorSnackBar: false,
}

const cardState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_CARD.CARD_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            }
        case ACTION_TYPE_CARD.CLOSE_SNACK_BAR_CARD_PAGE:
            return {
                ...state,
                openCardSuccessSnackBar: false,
                openCardErrorSnackBar: false,
            }
        case ACTION_TYPE_CARD.FETCH_CARD_SUCCESS:
            return{
                ...state,
                loading: false,
                cards: action.cards.orderRequests,
                success: false,
            }
        case ACTION_TYPE_CARD.FETCH_CARD_FAILURE:
            return{
                ...state,
                loading: false,
                errorMsg: action.error,
            }
        case ACTION_TYPE_CARD.ORDER_CARD_SUCCESS:
            return{
                ...state,
                loading: false,
                success :action.bool,
                successMsg: "Successfully Ordered",
                openCardSuccessSnackBar: true,
            }
        case ACTION_TYPE_CARD.ORDER_CARD_FAILURE:
            return{
                ...state,
                loading: false,
                errorMsg: action.error,
                openCardErrorSnackBar: true,
            }
        case ACTION_TYPE_CARD.SEARCH_CARD_SUCCESS:
            return{
                ...state,
                loading: false,
                cards:action.card.orderRequests,
            }
        case ACTION_TYPE_CARD.SEARCH_CARD_FAILURE:
            return{
                ...state,
                loading: false,
                errorMsg: action.error,
            }
        case ACTION_TYPE_CARD.HANDLE_CHANGE_CARD_INPUT:
            return{
                ...state,
                loading: false,
                clientId: action.clientId,
            }
        case ACTION_TYPE_CARD.HANDLE_CHANGE_ORDER_STATUS:
            return {
                ...state,
                orderStatus: action.orderStatus,
                orderId: action.id,
            }
        case ACTION_TYPE_CARD.HANDLE_CHANGE_CARD_STATUS:
            return {
                ...state,
                cardStatus: action.cardStatus,
                orderId: action.id,
            }
        case ACTION_TYPE_CARD.UPDATE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                orderStatusRes: action.orderStatusRes,
            }
        case ACTION_TYPE_CARD.UPDATE_ORDER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
            }
        case ACTION_TYPE_CARD.UPDATE_CARD_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                cardStatusRes: action.cardStatusRes,
            }
        case ACTION_TYPE_CARD.UPDATE_CARD_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
            }
        case ACTION_TYPE_CARD.SET_CARD_TO_VIEW:
            return {
                ...state,
                orderCards: action.orderCards.orderRequest,
                loading:false,
            };
        case ACTION_TYPE_CARD.SET_ORDER_CARD_TO_EMPTY:
            return {
                ...state,
                clientId:"",
            };
        default:
            return state;
    }

};
export default cardState;