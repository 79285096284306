import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import {Link, Switch, Redirect} from "react-router-dom";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {DashboardAPI, LocalDb, LoginAPI} from "../api";
import PrivateRoute from "./PrivateRoute";
import { useHistory } from "react-router-dom";
import {
    ActionContainer,
    ActionEditFormContainer,
    ActionFormContainer,
    CaseContainer,
    CaseTemplateContainer,
    CaseTemplateEditFormContainer,
    CaseTemplateFormContainer,
    CaseUpdateContainer,
    ClientContainer,
    ClientEditFormContainer,
    ClientFormContainer,
    CorporateContainer,
    DynamicActionContainer,
    ProfileContainer,
    RegisterCaseContainer,
    UserContainer,
    WorkflowContainer,
    WorkflowEditFormContainer,
    WorkflowFormContainer,
    ScheduleTransactionContainer,
    CardContainer,
    VendorContainer,
    VendorEditFormContainer,
    VendorFormContainer,
    ScheduleTransactionFormContainer,
    TransactionContainer,
    IndividualClientTransactionContainer,
    IndividualClientScheduleTransactionContainer,
    CalendarViewContainer,
    CaseFileDetailsContainer,
    ClientFileDetailsContainer,
    ActivityLogContainer,
    CalendarViewOfClientContainer,
    ClientDetailPageContainer,
    CalendarViewForAgentContainer,
    SettingContainer,
    CheckPrintContainer,
    BulkCheckPrintContainer,
    ShippingLabelContainer,
    PrintShippingContainer,
    ApproveTransactionContainer,
    ContactContainer,
    CreateTransContainer,
    ClientPaymentSettingContainer,
    VendorClientContainer,
    IndividualContactFileContainer,
    ClientCaseRegisterContainer,
    DashboardContainer,
    EmailTemplateContainer,
    GlobalContainer,
} from "./container";
import Avatar from "@material-ui/core/Avatar";
import {CorporateClientContainer} from "./container/CorporateClientContainer";
import {CorporateClientFormContainer} from "./container/CorporateClientFormContainer";
import {CorporateClientEditContainer} from "./container/CorporateClientEditFormContainer";
import {CompanyFilesContainer} from "./container/CompanyFileContainer";
import {exportUserData} from "../actions/ProfileAction";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import GlobalSearchComponent from "./GlobalSearchComponent";
import {DocumentsContainer} from "./container/DocumentsContainer";
import {globalSearchFailure, globalSearchSuccess, requestForDashboard} from "../actions/DashboardAction";
import GlobalNavigationTable from "../screens/GlobalNavigationTablesScreen";
import {GlobalNavigationTableContainer} from "./container/GlobalNavigationTableContainer";
import {SubmitTransactionContainer} from "./container/SubmitTransactionContainer";
import DocumentsScreen from '../screens/DocumentsScreen';
import { ClientDocumentPageContainer } from './container/ClientDocumentContainer';
import { IndividualClientUpcomingTxnContainer } from './container/IndividualClientUpcomingTxnContainer';
import IndividualClientLedgerTxnScreen from '../screens/IndividualClientLedgerTxnScreen';
import { IndividualClientLedgerTxnContainer } from './container/IndividualClientLedgerTxnContainer';
import CheckingAccountScreen from '../screens/CheckingAccountScreen';
const drawerWidth = 205;

export const NavBar = (props) => {
    let history = useHistory();
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB ?
        (userInfoFromDB.loginResponse.loginUser.user ? "EVERFUND_USER" :
            userInfoFromDB.loginResponse.loginUser.corporate ? "CORPORATE_USER" :
                userInfoFromDB.loginResponse.loginUser.client ? "CLIENT_USER" :
                    userInfoFromDB.loginResponse.loginUser.contact ? "EVERFUND_CONTACT" :
                        "EVERFUND_COMPANY")
        : null;

    const userRole = (userType === "EVERFUND_USER") ? LocalDb.getSessions().loginResponse.loginUser.user.userRole : null;

    const accountType = (userType === "EVERFUND_USER") ? LocalDb.getSessions().loginResponse.loginUser.user.accountType :
        (userType === "CORPORATE_USER") ? LocalDb.getSessions().loginResponse.loginUser.corporate.accountType
            : (userType === "CLIENT_USER") ? LocalDb.getSessions().loginResponse.loginUser.client.accountType
                : (userType === "EVERFUND_CONTACT") ? LocalDb.getSessions().loginResponse.loginUser.contact.accountType
                    : (userType === "EVERFUND_COMPANY") ? LocalDb.getSessions().loginResponse.loginUser.company.accountType : null;

    const classes = useStyles();
    const [open, setOpen] = React.useState(localStorage.getItem("drawerOpen") ? JSON.parse(localStorage.getItem("drawerOpen")) : false);
    const [reload, setReload] = React.useState(false);
    const [home, setHome] = React.useState(false);
    const [user, setUser] = React.useState(false);
    const [card, setCard] = React.useState(false);
    const [group, setGroup] = React.useState(false);
    const [site, setSite] = React.useState(false);
    const [transaction, setTransaction] = React.useState(false);
    const [scheduleTrans, setScheduleTrans] = React.useState(false);
    const [app, setApp] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const [contact, setContact] = React.useState(false);
    const [corporate, setCorporate] = React.useState(false);
    const [vendor, setVendor] = React.useState(false);
    const [workflow, setWorkflow] = React.useState(false);
    const [caseTemplate, setCaseTemplate] = React.useState(false);
    const [activityLog, setActivityLog] = React.useState(false);
    const [setting, setSetting] = React.useState(false);
    const [shippingLabel, setShippingLabel] = React.useState(false);
    const [action, setAction] = React.useState(false);
    const [emailTemplate, setEmailTemplate] = React.useState(false);
    const [documentHub, setDocumentHub] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(!open);
        localStorage.setItem("drawerOpen", JSON.stringify(!open));
        const drawerOpen = document.getElementById('submenu1');
        const expandIcon = document.getElementById('expandIcon');
        if (userRole === 'SUPER_ADMIN' || userRole === 'ADMIN') {
            if (open === false) {
                drawerOpen.style.marginLeft = "20px";
                expandIcon.style.marginRight = "-6px";
            } else {
                drawerOpen.style.marginLeft = "-2px";
                expandIcon.style.marginLeft = "-30px !important";
            }
        }
    };

    //account menu
    const [open1, setOpen1] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen1(prevOpen1 => !prevOpen1);

    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen1(false);
        exportUserData();
    };
    const handleLogout = () => {
        setOpen1(false);
        setOpen2(true);
    };
    const handleCloseConfirmationLogout = () => {
        setOpen2(false);
    };
    const handleConfirmationForLogout = () => {
        setOpen1(false);
        setOpen2(false);
        localStorage.setItem('route', '');
        props.history.push('/');
        LoginAPI.logout((response) => {
            if (response) {
                LocalDb.removeSession();
                window.location.reload();
            } else {
                console.log("Logout fail");
            }
        })
    };

    const onHomeClick = () => {
        setHome(true);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onGroupClick = () => {
        setHome(false);
        setUser(false);
        setGroup(true);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onUserClick = () => {
        setHome(false);
        setUser(true);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onSiteClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(true);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onAppClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(true);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onCardClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(true);
        setTransaction(false);
        setScheduleTrans(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onTransactionClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(true);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onScheduleTransactionClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(true);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onCheckClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(true);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onShippingLabelClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(true);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onContactClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(true);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onCorporateClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(true);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onVendorClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(true);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onActionClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(true);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onWorkFlowClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(true);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onCaseTemplateClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(true);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onActivityLogClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(true);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onSettingClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(true);
        setEmailTemplate(false);
        setDocumentHub(false);
    };
    const onEmailTemplateClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(true);
        setDocumentHub(false);
    };
    const onDocumentHubClick = () => {
        setHome(false);
        setUser(false);
        setGroup(false);
        setSite(false);
        setCheck(false);
        setApp(false);
        setCard(false);
        setTransaction(false);
        setScheduleTrans(false);
        setShippingLabel(false);
        setContact(false);
        setCorporate(false);
        setVendor(false);
        setAction(false);
        setWorkflow(false);
        setCaseTemplate(false);
        setActivityLog(false);
        setSetting(false);
        setEmailTemplate(false);
        setDocumentHub(true);
    };


    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen1(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen1 = React.useRef(open1);
    React.useEffect(() => {
        if (prevOpen1.current === true && open1 === false) {
            anchorRef.current.focus();
        }
        prevOpen1.current = open1;
    }, [open1]);

    const [open2, setOpen2] = React.useState(false);

    const setValue = (path) => {
        switch (path) {
            case '/dashboard':
                setHome(true);
                setReload(true);
                break;
            case '/user':
                setUser(true);
                setReload(true);
                break;
            case '/client':
                setSite(true);
                setReload(true);
                break;
            case '/case':
                setApp(true);
                setReload(true);
                break;
            case '/card':
                setCard(true);
                setReload(true);
                break;
            case '/transaction':
                setTransaction(true);
                setReload(true);
                break;
            case '/schedule':
                setScheduleTrans(true);
                setReload(true);
                break;
            case '/checkprint':
                setCheck(true);
                setReload(true);
                break;
            case '/address':
                setShippingLabel(true);
                setReload(true);
                break;
            case '/contact':
                setContact(true);
                setReload(true);
                break;
            case '/corporate':
                setCorporate(true);
                setReload(true);
                break;
            case '/vendor':
                setVendor(true);
                setReload(true);
                break;
            case '/action':
                setAction(true);
                setReload(true);
                break;
            case '/workflow':
                setWorkflow(true);
                setReload(true);
                break;
            case '/casetemplate':
                setCaseTemplate(true);
                setReload(true);
                break;
            case '/activitylog':
                setActivityLog(true);
                setReload(true);
                break;
            case '/setting':
                setSetting(true);
                setReload(true);
                break;
            case '/emailtemplate':
                setEmailTemplate(true);
                setReload(true);
                break;
            default:
                console.log("")
        }
    };

    return (
        <div className={classes.root}>{reload === false && setValue(props.x)}
            <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <Tooltip title={"Menu"}><MenuIcon
                            style={{margin: '2px', padding: '0', color: 'grey'}}/></Tooltip>
                    </IconButton>
                    <Divider/>  
                    {userType && userType === "CORPORATE_USER" ? 
                    <img src="/assets/images/everfund.png" alt="logo"
                             style={{width: '8.8rem', height: '2.4rem', marginLeft: '0.8rem'}}/> :
                    <Link to={'/dashboard'}>
                        <img src="/assets/images/everfund.png" alt="logo"
                             style={{width: '8.8rem', height: '2.4rem', marginLeft: '0.8rem'}}/>
                    </Link>
                    }
                    {/*<div className={classes.search} style={{width: '400px'}}>*/}
                    {/*    <div className={classes.searchIcon}>*/}
                    {/*        <SearchIcon style={{color: '#9c9c9c'}}/>*/}
                    {/*    </div>*/}
                    {/*    <InputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        classes={{*/}
                    {/*            root: classes.inputRoot,*/}
                    {/*            input: classes.inputInput,*/}
                    {/*        }}*/}
                    {/*        style={{marginLeft: "12px"}}*/}
                    {/*        inputProps={{'aria-label': 'search'}}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<GlobalSearchComponent />*/}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    </Typography>

                    <div>
                        {/*<div className="search">*/}
                        {/*    <div className="searchIcon">*/}
                        {/*        <SearchIcon style={{color: '#9c9c9c'}}/>*/}
                        {/*    </div>*/}
                        {/*    <InputBase*/}
                        {/*        placeholder="Search…"*/}
                        {/*        inputProps={{'aria-label': 'search'}}*/}
                        {/*        className="inputBase"*/}
                        {/*        // style={{background: "#f4f5f9"}}*/}
                        {/*        onChange={(e)=>{*/}
                        {/*            e.preventDefault();*/}
                        {/*            // this.props.searchVendor(e.target.value);*/}
                        {/*        }}/>*/}
                        {/*</div>*/}
                        {/*<GlobalSearchComponent />*/}
                        <IconButton hidden aria-label="show new notifications" color="inherit">
                            {/*<Badge badgeContent color="secondary" style={{fontSize:'0.1rem'}}>*/}
                            {/*<NotificationsNoneOutlinedIcon style={{ color: 'grey', fontSize: '1.5rem' }} />*/}
                            {/*</Badge>*/}
                        </IconButton>
                        <div style={{display: "flex"}}>
                            {/*<div className="search" style={{marginTop: "8px", marginRight: "8px"}}>*/}
                            {/*    <div className="searchIcon">*/}
                            {/*        <SearchIcon style={{color: '#9c9c9c'}}/>*/}
                            {/*    </div>*/}
                            {/*    <InputBase*/}
                            {/*        placeholder="Search…"*/}
                            {/*        inputProps={{'aria-label': 'search'}}*/}
                            {/*        className="inputBase"*/}
                            {/*        onChange={(e)=>{*/}
                            {/*            e.preventDefault();*/}

                            {/*            onChangeValue();*/}
                            {/*            searchDocument(e.target.value);*/}
                            {/*        }}/>*/}
                            {/*</div>*/}
                            <GlobalContainer propertyHistory={history}/>
                            <IconButton color="inherit"
                                        ref={anchorRef}
                                        aria-controls={open1 ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        className="topNavIcon"
                            >
                                <Avatar
                                    alt="Profile"
                                    src={userType == "EVERFUND_USER" ? userInfoFromDB &&
                                        userInfoFromDB.loginResponse.loginUser.user.profilePicUrl : null}
                                    style={{width: "30px", height: "30px", marginRight: '0.6rem'}}
                                />
                                {/*<Tooltip title={"Profile"}>*/}
                                {/*    <img alt={'profile'} src={'/assets/images/profile.svg'}*/}
                                {/*         className={classes.svgIcon}*/}
                                {/*    />*/}
                                {/*    /!*<AccountCircleIcon style={{width:'25px', fontSize: '1.7rem', color: '#8BC83F' }} />*!/*/}
                                {/*</Tooltip>*/}
                            </IconButton>
                        </div>

                        <Popper open={open1} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({TransitionProps, placement}) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={open1} id="menu-list-grow"
                                                      onKeyDown={handleListKeyDown} style={{padding: '0.5rem'}}>
                                                <MenuItem onClick={handleClose}>
                                                    <Link to={'/profile'}
                                                          style={{
                                                              color: 'black',
                                                              textDecoration: 'none'
                                                          }}>
                                                        {userInfoFromDB && userInfoFromDB.loginResponse.loginUser.user ?
                                                            userInfoFromDB.loginResponse.loginUser.user.fullName :
                                                            userInfoFromDB && userInfoFromDB.loginResponse.loginUser.corporate ?
                                                                userInfoFromDB.loginResponse.loginUser.corporate.corporateName :
                                                                userInfoFromDB && userInfoFromDB.loginResponse.loginUser.client ?
                                                                    userInfoFromDB.loginResponse.loginUser.client.firstName:
                                                                    userInfoFromDB && userInfoFromDB.loginResponse.loginUser.contact?
                                                                        userInfoFromDB && userInfoFromDB.loginResponse.loginUser.contact.name:
                                                                        userInfoFromDB && userInfoFromDB.loginResponse.loginUser.company.companyName
                                                        }
                                                    </Link></MenuItem>
                                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                className='drawer'
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}>
                <div className={classes.toolbarIcon}>
                </div>
                <div>
                    {accountType === 'EVERFUND_USER' ?
                        (userRole === "SUPER_ADMIN" || userRole === "ADMIN") ?
                            <List>
                                <Link to='/dashboard' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onHomeClick} selected={home}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Home'}><img alt={'home'} src={'/assets/images/home-01.svg'}
                                                                         className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Home" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/case' style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                    <ListItem button onClick={onAppClick} selected={app}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Cases'}><img alt={'case'}
                                                                          src={'/assets/images/case-01.svg'}
                                                                          className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Cases" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/client' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onSiteClick} selected={site}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Clients'}><img alt={'client'}
                                                                            src={'/assets/images/client-01.svg'}
                                                                            className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Clients" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/card' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onCardClick} selected={card}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Cards'}><img alt={'card'}
                                                                          src={'/assets/images/card.svg'}
                                                                          className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Cards" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/transaction/list' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onTransactionClick} selected={transaction}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Transactions'}><img alt={'transaction'}
                                                                                 src={'/assets/images/transaction.svg'}
                                                                                 className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Transactions" className="itemText"/>
                                    </ListItem>
                                </Link>
                                {/*<Link to='/schedule' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>*/}
                                {/*    <ListItem button onClick={onScheduleTransactionClick} selected={scheduleTrans}>*/}
                                {/*        <ListItemIcon style={{paddingLeft: '6px'}}>*/}
                                {/*            <Tooltip title={'Schedule Transactions'}><img alt={'setting'}*/}
                                {/*                                                          src={'/assets/images/schedule_transaction.svg'}*/}
                                {/*                                                          className={classes.svgIcon}*/}
                                {/*            /></Tooltip>*/}
                                {/*        </ListItemIcon>*/}
                                {/*        <ListItemText primary="Schedule Transactions" className="itemText"/>*/}
                                {/*    </ListItem>*/}
                                {/*</Link>*/}
                                <Link to='/checkprint' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onCheckClick} selected={check}>
                                        <ListItemIcon style={{paddingLeft: '8px'}}>
                                            <Tooltip title={'Check Print'}><img alt={'setting'}
                                                                                src={'/assets/images/printer1.svg'}
                                                                                className={classes.svgIconForPrint}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Check print" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/address' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onShippingLabelClick} selected={shippingLabel}>
                                        <ListItemIcon style={{paddingLeft: '8px'}}>
                                            <Tooltip title={'Shipping Label'}><img alt={'setting'}
                                                                                   src={'/assets/images/shippingIcon1.svg'}
                                                                                   className={classes.svgIconForShipping}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Shipping Label" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/contact' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onContactClick} selected={contact}>
                                        <ListItemIcon style={{paddingLeft: '8px'}}>
                                            <Tooltip title={'Contacts'}><img alt={'setting'}
                                                                             src={'/assets/images/contact.svg'}
                                                                             className={classes.svgContactIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Contacts" className="itemText"/>
                                    </ListItem>
                                </Link>
                                {/*--------submenu for admin----------*/}
                                <ul className="nav flex-column flex-nowrap overflow-hidden">
                                    <li className="nav-item ">
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a className="nav-link  text-truncate navbar-collapse collapsed" href="#"
                                           data-toggle="collapse"
                                           data-target="#submenu1" style={{padding: 0, color: '#535353'}}
                                           aria-expanded="false">
                                            <ListItem button onClick={onGroupClick} selected={group}>
                                                <ListItemIcon style={{paddingLeft: '6px'}}>
                                                    <Tooltip title={'Admin'}><img alt={'admin'}
                                                                                  src={'/assets/images/admin_sec-01.svg'}
                                                                                  className={classes.svgIcon}/></Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary="Admin Section" className="itemText"/>
                                                <ExpandMoreOutlinedIcon id="expandIcon" className={classes.expandIcon}
                                                                        style={{color: '#8BC83F'}}/>
                                            </ListItem>
                                        </a>
                                        <div className="collapse" id="submenu1" style={{marginLeft: '-2px'}}
                                             aria-expanded="false">
                                            <Link to='/user'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onUserClick} selected={user}>
                                                    <ListItemIcon className={classes.userIcon}>
                                                        <Tooltip title={'Users'}><img alt={'user'}
                                                                                      src={'/assets/images/user-01.svg'}
                                                                                      className={classes.svgIcon}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Users" className="itemText"
                                                                  style={{paddingLeft: '2px'}}/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/corporate'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onCorporateClick} selected={corporate}>
                                                    <ListItemIcon className={classes.corporateIcon}>
                                                        <Tooltip title={'Corporates'}><img alt={'Corporate'}
                                                                                           src={'/assets/images/coperate.svg'}
                                                                                           className={classes.svgIcon}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Corporates" className="itemText"
                                                                  style={{paddingLeft: '2px'}}/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/vendor'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onVendorClick} selected={vendor}>
                                                    <ListItemIcon className={classes.corporateIcon}>
                                                        <Tooltip title={'Vendors'}><img alt={'vendor'}
                                                                                        src={'/assets/images/vendor.svg'}
                                                                                        className={classes.svgIcon}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Vendors" className="itemText"/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/action'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onActionClick} selected={action}>
                                                    <ListItemIcon className={classes.actionIcon}>
                                                        <Tooltip title={'Actions'}><img alt={'Action'}
                                                                                        src={'/assets/images/action1.svg'}
                                                                                        className={classes.svgIcon}
                                                        />
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Actions" className="itemText"
                                                                  style={{paddingLeft: '2px'}}/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/workflow'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onWorkFlowClick} selected={workflow}>
                                                    <ListItemIcon className={classes.workflowIcon}>
                                                        <Tooltip title={'Workflows'}><img alt={'workflow'}
                                                                                          src={'/assets/images/workflow-01.svg'}
                                                                                          className={classes.svgIcon}
                                                        />
                                                        </Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Workflows" className="itemText"
                                                                  style={{paddingLeft: '2px'}}/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/casetemplate'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onCaseTemplateClick} selected={caseTemplate}>
                                                    <ListItemIcon className={classes.corporateIcon} >
                                                        <Tooltip title={'Case Templates'}><img alt={'case template'}
                                                                                               src={'/assets/images/case_temp-01.svg'}
                                                                                               className={classes.svgIconCaseTemplate}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Case Templates" className="itemText"/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/documents'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onDocumentHubClick} selected={documentHub}>
                                                    <ListItemIcon className={classes.corporateIcon}>
                                                        <Tooltip title={'Documents Hub'}><img alt={'document-hub'}
                                                                                              src={'/assets/images/documentHub.svg'}
                                                                                              className={classes.svgIcon}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Documents Hub" className="itemText"/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/emailtemplate'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onEmailTemplateClick} selected={emailTemplate}>
                                                    <ListItemIcon className={classes.corporateIcon}>
                                                        <Tooltip title={'Email Templates'}><img alt={'activity-log'}
                                                                                                src={'/assets/images/Email Template.svg'}
                                                                                                className={classes.svgIcon}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Email Templates" className="itemText"/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/activitylog'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                                <ListItem button onClick={onActivityLogClick} selected={activityLog}>
                                                    <ListItemIcon className={classes.corporateIcon}>
                                                        <Tooltip title={'Activity Log'}><img alt={'activity-log'}
                                                                                             src={'/assets/images/activity_log.svg'}
                                                                                             className={classes.svgIcon}
                                                        /></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Activity Log" className="itemText"/>
                                                </ListItem>
                                            </Link>
                                            <Link to='/setting'
                                                  style={{textDecoration: 'none', width: '100%', color: '#6d6d6d', paddingTop: "15px"}}>
                                                <ListItem button onClick={onSettingClick} selected={setting}>
                                                    <ListItemIcon className={classes.caseIcon}>
                                                        <Tooltip title={'Settings'}><img alt={'settings'}
                                                                                         src={'/assets/images/setting-01.png'}
                                                                                         className={classes.svgIcon}/></Tooltip>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Settings" className="itemText"
                                                                  style={{paddingLeft: '2px'}}/>
                                                </ListItem>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </List>
                            :
                            //agent login
                            <List>
                                <Link to='/dashboard' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onHomeClick} selected={home}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Home'}><img alt={'home'}
                                                                         src={'/assets/images/home-01.svg'}
                                                                         className={classes.svgIcon}/></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Home" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/case' style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                    <ListItem button onClick={onAppClick} selected={app}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Cases'}><img alt={'case'}
                                                                          src={'/assets/images/case-01.svg'}
                                                                          className={classes.svgIcon}/></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Cases" className="itemText"/>
                                    </ListItem>
                                </Link>
                                <Link to='/client' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onSiteClick} selected={site}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Clients'}><img alt={'client'}
                                                                            src={'/assets/images/client-01.svg'}
                                                                            className={classes.svgIcon}/></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Clients" className="itemText"/>
                                    </ListItem>
                                </Link>
                            </List>
                        : (accountType === "CORPORATE_CLIENT") ?
                            //corporate login
                            <List>
                                {/* <Link to='/dashboard' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onHomeClick} selected={home}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Home'}><img alt={'home'} src={'/assets/images/home-01.svg'}
                                                                         className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Home" className="itemText"/>
                                    </ListItem>
                                </Link> */}
                                {/* <Link to='/case' style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                    <ListItem button onClick={onAppClick} selected={app}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Cases'}><img alt={'case'}
                                                                          src={'/assets/images/case-01.svg'}
                                                                          className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Cases" className="itemText"/>
                                    </ListItem>
                                </Link> */}
                                <Link to='/client' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onSiteClick} selected={site}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Clients'}><img alt={'client'}
                                                                            src={'/assets/images/client-01.svg'}
                                                                            className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Clients" className="itemText"/>
                                    </ListItem>
                                </Link>
                                {/* <Link to='/transaction/list' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                    <ListItem button onClick={onTransactionClick} selected={transaction}>
                                        <ListItemIcon style={{paddingLeft: '6px'}}>
                                            <Tooltip title={'Transactions'}><img alt={'transaction'}
                                                                                 src={'/assets/images/transaction.svg'}
                                                                                 className={classes.svgIcon}
                                            /></Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Transactions" className="itemText"/>
                                    </ListItem>
                                </Link> */}
                                {/*<Link to='/schedule' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>*/}
                                {/*    <ListItem button onClick={onScheduleTransactionClick} selected={scheduleTrans}>*/}
                                {/*        <ListItemIcon style={{paddingLeft: '6px'}}>*/}
                                {/*            <Tooltip title={'Schedule Transactions'}><img alt={'setting'}*/}
                                {/*                                                          src={'/assets/images/schedule_transaction.svg'}*/}
                                {/*                                                          className={classes.svgIcon}*/}
                                {/*            /></Tooltip>*/}
                                {/*        </ListItemIcon>*/}
                                {/*        <ListItemText primary="Schedule Transactions" className="itemText"/>*/}
                                {/*    </ListItem>*/}
                                {/*</Link>*/}
                            </List>
                            : (accountType === "EVERFUND_CLIENT") ?
                                //client login
                                <List>
                                    <Link to='/dashboard' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                        <ListItem button onClick={onHomeClick} selected={home}>
                                            <ListItemIcon style={{paddingLeft: '6px'}}>
                                                <Tooltip title={'Home'}><img alt={'home'} src={'/assets/images/home-01.svg'}
                                                                             className={classes.svgIcon}
                                                /></Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary="Home" className="itemText"/>
                                        </ListItem>
                                    </Link>
                                    {/* <Link to='/case' style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                         <ListItem button onClick={onAppClick} selected={app}>
                                             <ListItemIcon style={{paddingLeft: '6px'}}>
                                                 <Tooltip title={'Case'}><img alt={'case'}
                                                                              src={'/assets/images/case-01.svg'}
                                                                              className={classes.svgIcon}
                                                 /></Tooltip>
                                             </ListItemIcon>
                                             <ListItemText primary="Case" className="itemText"/>
                                         </ListItem>
                                     </Link> */}
                                    <Link to='/transaction/list' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                        <ListItem button onClick={onTransactionClick} selected={transaction}>
                                            <ListItemIcon style={{paddingLeft: '6px'}}>
                                                <Tooltip title={'Transactions'}><img alt={'transaction'}
                                                                                     src={'/assets/images/transaction.svg'}
                                                                                     className={classes.svgIcon}
                                                /></Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary="Transactions" className="itemText"/>
                                        </ListItem>
                                    </Link>
                                    {/*<Link to='/schedule' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>*/}
                                    {/*    <ListItem button onClick={onScheduleTransactionClick} selected={scheduleTrans}>*/}
                                    {/*        <ListItemIcon style={{paddingLeft: '6px'}}>*/}
                                    {/*            <Tooltip title={'Schedule Transactions'}><img alt={'setting'}*/}
                                    {/*                                                          src={'/assets/images/schedule_transaction.svg'}*/}
                                    {/*                                                          className={classes.svgIcon}*/}
                                    {/*            /></Tooltip>*/}
                                    {/*        </ListItemIcon>*/}
                                    {/*        <ListItemText primary="Schedule Transactions" className="itemText"/>*/}
                                    {/*    </ListItem>*/}
                                    {/*</Link>*/}
                                    <Link to='/clientDocument' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                        <ListItem button onClick={onDocumentHubClick} selected={documentHub}>
                                            <ListItemIcon style={{paddingLeft: '6px'}}>
                                                <Tooltip title={'Documents Hub'}>
                                                    <img alt={'document-hub'}
                                                         src={'/assets/images/documentHub.svg'}
                                                         className={classes.svgIcon}
                                                    /></Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary="Documents Hub" className="itemText"/>
                                        </ListItem>
                                    </Link>
                                </List>
                                : (accountType === "EVERFUND_CONTACT") ?
                                    <List>
                                        <Link to='/dashboard' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                            <ListItem button onClick={onHomeClick} selected={home}>
                                                <ListItemIcon style={{paddingLeft: '6px'}}>
                                                    <Tooltip title={'Home'}><img alt={'home'} src={'/assets/images/home-01.svg'}
                                                                                 className={classes.svgIcon}
                                                    /></Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary="Home" className="itemText"/>
                                            </ListItem>
                                        </Link>
                                        <Link to='/case' style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                            <ListItem button onClick={onAppClick} selected={app}>
                                                <ListItemIcon style={{paddingLeft: '6px'}}>
                                                    <Tooltip title={'Cases'}><img alt={'case'}
                                                                                  src={'/assets/images/case-01.svg'}
                                                                                  className={classes.svgIcon}
                                                    /></Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary="Cases" className="itemText"/>
                                            </ListItem>
                                        </Link>
                                    </List> :
                                    <List>
                                        <Link to='/dashboard' style={{textDecoration: 'none', width: '100%', color: '#535353'}}>
                                            <ListItem button onClick={onHomeClick} selected={home}>
                                                <ListItemIcon style={{paddingLeft: '6px'}}>
                                                    <Tooltip title={'Home'}><img alt={'home'} src={'/assets/images/home-01.svg'}
                                                                                 className={classes.svgIcon}
                                                    /></Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary="Home" className="itemText"/>
                                            </ListItem>
                                        </Link>
                                        <Link to='/case' style={{textDecoration: 'none', width: '100%', color: '#6d6d6d'}}>
                                            <ListItem button onClick={onAppClick} selected={app}>
                                                <ListItemIcon style={{paddingLeft: '6px'}}>
                                                    <Tooltip title={'Cases'}><img alt={'case'}
                                                                                  src={'/assets/images/case-01.svg'}
                                                                                  className={classes.svgIcon}
                                                    /></Tooltip>
                                                </ListItemIcon>
                                                <ListItemText primary="Cases" className="itemText"/>
                                            </ListItem>
                                        </Link>
                                    </List>

                    }
                </div>

            </Drawer>

            {/*-------for routing container---------*/}
            <main className={classes.content} style={{backgroundColor: "#ffffff", margin: '0'}}>
                <div className={classes.appBarSpacer}/>
                <Switch>
                    <PrivateRoute exact path='/dashboard' from component={DashboardContainer}/>
                    <PrivateRoute path='/action/data' from component={DynamicActionContainer}/>
                    <PrivateRoute exact path='/user' from component={UserContainer}/>
                    <PrivateRoute exact path='/workflow' from component={WorkflowContainer}/>
                    <PrivateRoute exact path='/workflowform' from component={WorkflowFormContainer}/>
                    <PrivateRoute exact path='/case' from component={CaseContainer}/>
                    <PrivateRoute exact path='/client' from component={ClientContainer}/>
                    <PrivateRoute path='/client/paymentsetting' from component={ClientPaymentSettingContainer}/>
                    <PrivateRoute path='/client/edit' from component={ClientEditFormContainer}/>
                    <PrivateRoute path='/client/clientform' from component={ClientFormContainer}/>
                    <PrivateRoute exact path='/setting' from component={SettingContainer}/>
                    {/* <PrivateRoute exact path='/setting/checking_account' from component={CheckingAccountScreen}/> */}
                    <PrivateRoute exact path='/casetemplate' from component={CaseTemplateContainer}/>
                    <PrivateRoute exact path='/casetemplate/create' from component={CaseTemplateFormContainer}/>
                    <PrivateRoute exact path='/registercase' from component={RegisterCaseContainer}/>
                    <PrivateRoute exact path='/profile' from component={ProfileContainer}/>
                    <PrivateRoute exact path='/action' from component={ActionContainer}/>
                    <PrivateRoute exact path='/action/create' from component={ActionFormContainer}/>
                    <PrivateRoute path='/action/edit' from component={ActionEditFormContainer}/>
                    <PrivateRoute path='/workflow/edit' from component={WorkflowEditFormContainer}/>
                    <PrivateRoute path='/casetemplate/edit' from component={CaseTemplateEditFormContainer}/>
                    <PrivateRoute path='/case/update' from component={CaseUpdateContainer}/>
                    <PrivateRoute exact path='/corporate' from component={CorporateContainer}/>
                    <PrivateRoute path='/corporate/client' from component={CorporateClientContainer}/>
                    <PrivateRoute path='/corporate/clientform' from component={CorporateClientFormContainer}/>
                    <PrivateRoute path='/corporate/:coporateId/clientupdate/:clientId' from
                                  component={CorporateClientEditContainer}/>
                    {/*<PrivateRoute exact path='/schedule' from*/}
                    {/*              component={ScheduleTransactionContainer}/>*/}
                    <PrivateRoute path='/schedule/client' from component={IndividualClientScheduleTransactionContainer}/>
                    <PrivateRoute path='/scheduletransaction' from component={ScheduleTransactionFormContainer}/>
                    <PrivateRoute exact path='/vendor' from
                                  component={VendorContainer}/>
                    <PrivateRoute exact path='/vendorform' from
                                  component={VendorFormContainer}/>
                    <PrivateRoute path='/vendor/edit' from
                                  component={VendorEditFormContainer}/>
                    <PrivateRoute  path='/vendor/:id/clients' from
                                   component={VendorClientContainer}/>
                    <PrivateRoute path='/card' from
                                  component={CardContainer}/>
                    <PrivateRoute exact path='/transaction/:tab' from component={TransactionContainer}/>
                    <PrivateRoute path='/transaction/createtrans' from component={CreateTransContainer}/>
                    <PrivateRoute exact path='/transaction/client/:clientId' from component={IndividualClientTransactionContainer}/>
                    <PrivateRoute path='/transaction/client/upcoming_transaction/:clientId' from component={IndividualClientUpcomingTxnContainer}/>
                    <PrivateRoute path='/transaction/client/ledger_transaction/:clientId' from component={IndividualClientLedgerTxnContainer}/>
                    <PrivateRoute path='/case/calendar' from component={CalendarViewContainer}/>
                    <PrivateRoute path='/filedetails' from component={CaseFileDetailsContainer}/>
                    <PrivateRoute path='/client/filedetails' from component={ClientFileDetailsContainer}/>
                    <PrivateRoute exact path='/activitylog' from component={ActivityLogContainer}/>
                    <PrivateRoute path='/client/calendar/:clientId' from component={CalendarViewOfClientContainer}/>
                    <PrivateRoute path='/client/details/:clientId' from component={ClientDetailPageContainer}/>
                    <PrivateRoute path='/client/registercase/:clientId' from component={ClientCaseRegisterContainer}/>
                    <PrivateRoute path='/agent/calendar/:userId' from component={CalendarViewForAgentContainer}/>
                    <PrivateRoute exact path='/checkprint' from component={CheckPrintContainer}/>
                    <PrivateRoute path='/checkprint/bulkprint' from component={BulkCheckPrintContainer}/>
                    <PrivateRoute exact path='/address' from component={ShippingLabelContainer}/>
                    <PrivateRoute exact path='/address/shippinglabel' from component={PrintShippingContainer}/>
                    <PrivateRoute exact path='/transaction/approved/list' from component={ApproveTransactionContainer}/>
                    <PrivateRoute exact path='/contact' from component={ContactContainer}/>
                    <PrivateRoute exact path='/contact/:id/files' from component={IndividualContactFileContainer}/>
                    <PrivateRoute exact path='/company/:id/files' from component={CompanyFilesContainer}/>
                    <PrivateRoute exact path='/emailtemplate' from component={EmailTemplateContainer}/>
                    <PrivateRoute exact path='/documents' from component={DocumentsContainer}/>
                    <PrivateRoute exact path='/clientDocument' from component={ClientDocumentPageContainer}/>
                    <PrivateRoute exact path='/globalSearch' from component={GlobalContainer}/>
                    <PrivateRoute exact path='/globalsearchresult' from component={GlobalNavigationTableContainer}/>
                    <PrivateRoute exact path='/transaction/submitted/list' from component={SubmitTransactionContainer}/>
                    <Redirect from="*" to="/notfound" />
                </Switch>
            </main>

            {/*------conforming before logout-------*/}
            <Dialog
                open={open2}
                onClose={handleCloseConfirmationLogout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    style={{color: '#8BC83F', textAlign: 'center', fontSize: '1.75rem !important', width: '450px'}}
                    id="draggable-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{textAlign: 'center', padding: '1rem'}}>
                        Are you sure, you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{justifyContent: 'center', marginBottom: '0.85rem'}}>
                    <Button autoFocus onClick={handleCloseConfirmationLogout}
                            style={{
                                border: "1px solid #8BC83F",
                                color: "#8BC83F",
                                width: '40%',
                                padding: '0.5rem',
                                marginRight: '0.5rem'
                            }}>
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        e.preventDefault();
                        handleConfirmationForLogout()
                    }} style={{
                        background: '#8BC83F',
                        color: 'white',
                        width: '40%',
                        padding: '0.5rem',
                        marginLeft: '0.5rem'
                    }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    typography: {
        // fontFamily: 'Mukta'
    },
    root: {
        display: 'flex',
    },
    // toolbar: {
    //     paddingRight: 4, // keep right padding when drawer closed
    // },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'flex-end',
        // padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: 'white',
        borderBottom: '1px solid #dddddd',
        boxShadow: 'none',
    },
    menuButton: {
        // marginRight: 36,
    },
    menuButtonHidden: {
        // display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    search: {
        position: 'relative',
        borderRadius: '8px',
        backgroundColor: "#F8F8F8",
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '400px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'grey',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '335px',

    },
    expandIcon: {
        marginLeft: '-20px'
    },
    userIcon: {
        borderTop: '1px solid #ABC83F',
        borderLeft: '1px solid #ABC83F',
        borderRight: '1px solid #ABC83F',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        marginBottom: '-20px',
        paddingBottom: '10px',
        backgroundColor: '#F8F8F8',
        justifyContent: 'center',
        marginRight: '10px',
        minWidth: '46px',
        paddingTop: '5px',
        marginTop: "-16px"
    },
    corporateIcon: {
        marginTop: '-27px',
        borderLeft: '1px solid #ABC83F',
        borderRight: '1px solid #ABC83F',
        paddingBottom: '32px',
        marginBottom: '-17px',
        width: '24px',
        height: '24px',
        backgroundColor: '#F8F8F8',
        minWidth: '46px',
        justifyContent: 'center',
        marginRight: '10px',
        paddingTop: '14px'
    },
    workflowIcon: {
        borderLeft: '1px solid #ABC83F',
        borderRight: '1px solid #ABC83F',
        paddingBottom: '25px',
        marginBottom: '-17px',
        backgroundColor: '#F8F8F8',
        minWidth: '46px',
        justifyContent: 'center',
        marginRight: '10px'
    },
    caseIcon: {
        borderLeft: '1px solid #ABC83F',
        borderRight: '1px solid #ABC83F',
        borderBottom: '1px solid #ABC83F',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        paddingTop: '16px',
        marginTop: '-40px',
        backgroundColor: '#F8F8F8',
        justifyContent: 'center',
        marginRight: '10px',
        paddingBottom: '5px',
        minWidth: '46px',
        marginBottom: '-19px'
    },
    actionIcon: {
        borderLeft: '1px solid #ABC83F',
        borderRight: '1px solid #ABC83F',
        paddingBottom: '35px',
        marginBottom: '-32px',
        backgroundColor: '#F8F8F8',
        minWidth: '46px',
        justifyContent: 'center',
        marginRight: '10px',
        marginTop: '-16px',
        paddingTop: '16px'
    },
    svgIcon: {
        width: '24px',
        height: '24px'
    },
    svgContactIcon: {
        width: '26px',
        height: '21px',
        marginLeft: '-3px',
    },
    svgIconForPrint: {
        width: '20px',
        height: '24px'
    },
    svgIconForShipping: {
        width: '20px',
        height: '21px'
    },
    svgIconCase: {
        width: '22px',
        height: '23px'
    },
    svgIconCaseTemplate: {
        width: '25px',
        height: '25px'
    }
}));