import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class RegisterCaseAPI extends API {
    addNewClient = (clientData, callback) => {
        let body = JSON.stringify(clientData);
        fetch(APIEndPoints.ADD_CLIENT, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add client api", error);
            callback(null, "Service error, please try again.");
        });
    };

    registerCase = (caseData, callback) => {
        let body = JSON.stringify(caseData);
        fetch(APIEndPoints.REGISTER_CASE, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling register case api", error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchClientList = callback => {
        fetch(APIEndPoints.FETCH_CLIENT, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling client api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    searchClient = (value, callback) => {
        const urlString = APIEndPoints.SEARCH_BY_SSN + value;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };
    //client's case contact
    fetchClientCaseContact= (id,callback) => {
        var constUrl = APIEndPoints.FETCH_CLIENT_CASE_CONTACT + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                console.log(error);
                callback(null, "Service error, please try again.");
            });
    };
}

export default new RegisterCaseAPI();