import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  DialogActions,
  MenuItem,
  MenuList,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Select } from "antd";
import TablePagination from "@material-ui/core/TablePagination";
import ButtonComponent from "./ButtonComponent";

const { Option } = Select;

class EmailTypeTable extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openActionMenu: false,
      popoverElement: null,
      page: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.props.fetchEmailTemplate();
    this.props.fetchAssignedTemplate();
  }

  render() {
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };

    const emailTemplateState = this.props.emaileTemplateState;
    const templateAssign =
      emailTemplateState && emailTemplateState.templateAssign;
    const templateAssignList =
      emailTemplateState && emailTemplateState.templateAssignList;
    const emailTemplateList =
      emailTemplateState && emailTemplateState.emailTemplateList;
    const value =
      templateAssign.emailTemplatedId !== "" && templateAssign.emailType !== "";
    const loading = this.props.loading;
    return (
      <React.Fragment>
        <div>
          <div className={"d-flex flex-row-reverse div-flex"}>
            <div>
              <button
                className="addButton"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleOpenToAddEmailTypeModal();
                }}
              >
                <AddIcon /> Assign Email Type
              </button>
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header-second">
              <Table size="small" stickyHeader aria-label="sticky table" ref={this.tableRef}>
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell
                      className="first-tableHeader"
                      style={{ width: "410px" }}
                    >
                      Template Name
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      style={{ width: "410px" }}
                    >
                      Email Template Type
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {templateAssignList &&
                  templateAssignList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            {list &&
                              list.emailTemplate &&
                              list.emailTemplate.templateName}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list &&
                              list.emailType &&
                              list.emailType.replace(/_/g, " ")}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "290px" }}
                          >
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.setAssignedEmailTemplateToEdit(list);
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  openActionMenu: true,
                                });
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleOpenToEditEmailTypeModal();
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
              </TableContainer>
              {templateAssignList && templateAssignList.length > 0 ? (
                <div style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={
                      templateAssignList.length > 0
                        ? templateAssignList.length
                        : 100
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div style={{     
                  display: "flex", 
                  width: "100%",
                  justifyContent:"center"
                  }}>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "1rem",
                      color: "#606060",
                    }}
                  >
                    {" "}
                    No data to show
                  </h3>
                </div>
              )}
            {/* </TableContainer> */}
          </div>
        </div>
        {/*assign email type*/}
        <Dialog
          open={this.props.openModalToAddEmailType}
          onClose={this.props.handleCloseToAddEmailTypeModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={this.props.handleCloseToAddEmailTypeModal}
            >
              Assign Email Type
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ borderBottom: "0px", paddingTop: "5px" }}
              className="dialogContent"
            >
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Email Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="contactType"
                      showSearch
                      className="drop"
                      listHeight={100}
                      getPopupContainer={(node) => node.parentNode}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeAssignTemplateData(
                          "emailType",
                          e
                        );
                      }}
                    >
                      <Option value="EMAIL_VERIFICATION">
                        Email Verification
                      </Option>
                      <Option value="FORGOT_PASSWORD">Forgot Password</Option>
                      <Option value="USER_WELCOME_EMAIL">User Welcome Email</Option>
                      <Option value="RESET_PASSWORD_CONFIRMATION">
                        Reset Password Confirmation
                      </Option>
                      <Option value="PHONE_VERIFICATION">
                        Phone Verification
                      </Option>
                      <Option value="SHARE_LINK">Share Link</Option>
                      <Option value="ALERT_EMAIL">Alert Email</Option>
                      <Option value="HOLIDAY_ALERT">Holiday Alert</Option>
                      <Option value="CORPORATE_WELCOME_EMAIL">
                        Corporate Welcome Email
                      </Option>
                      <Option value="CLIENT_WELCOME_EMAIL">
                        Client Welcome Email
                      </Option>
                      <Option value="CONTACT_WELCOME_EMAIL">
                        Contact Welcome Email
                      </Option>
                      <Option value="COMPANY_WELCOME_EMAIL">
                        Company Welcome Email
                      </Option>
                      <Option value="UPCOMING_TRANSACTION_EMAIL">
                        Upcoming Transaction Email
                      </Option>
                      <Option value="TRANSACTION_EMAIL">
                        Transaction Email
                      </Option>
                      <Option value="CASE_HOLIDAY_EMAIL">
                        Case Holiday Email
                      </Option>
                      <Option value="ACH_GENERATED_EMAIL">
                        ACH Generated Email
                      </Option>
                      <Option value="CHECK_AVAILABLE_EMAIL">
                        Check Available Email
                      </Option>
                      <Option value="BLOCKED_TRANSACTION_EMAIL">
                        Blocked Transaction Email 
                      </Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Template Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%", marginBottom: "12px" }}
                  >
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeAssignTemplateData(
                          "emailTemplatedId",
                          e
                        );
                      }}
                    >
                      {emailTemplateList &&
                        emailTemplateList.map((template) => (
                          <Option value={template.emailTemplateId}>
                            {template.templateName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
              <ButtonComponent
                loading={loading}
                buttonName="Assign"
                value={value}
                onClick={() => {
                  this.props.assignEmailType(templateAssign);
                }}
              />
            </DialogActions>
          </div>
        </Dialog>
        {/*edit assigned email type*/}
        <Dialog
          open={this.props.openModalToUpdateEmailType}
          onClose={this.props.handleCloseToEditEmailTypeModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={this.props.handleCloseToEditEmailTypeModal}
            >
              Update Email Type
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ borderBottom: "0px", paddingTop: "5px" }}
              className="dialogContent"
            >
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Email Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="contactType"
                      value={templateAssign.emailType}
                      showSearch
                      className="drop"
                      listHeight={100}
                      getPopupContainer={(node) => node.parentNode}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(e) => {
                        this.props.handleChangeAssignTemplateData(
                          "emailType",
                          e
                        );
                        this.setState({ isBlocking: true });
                      }}
                    >
                      <Option value="EMAIL_VERIFICATION">
                        Email Verification
                      </Option>
                      <Option value="FORGOT_PASSWORD">Forgot Password</Option>
                      <Option value="USER_WELCOME_EMAIL">User Welcome Email</Option>
                      <Option value="RESET_PASSWORD_CONFIRMATION">
                        Reset Password Confirmation
                      </Option>
                      <Option value="PHONE_VERIFICATION">
                        Phone Verification
                      </Option>
                      <Option value="SHARE_LINK">Share Link</Option>
                      <Option value="ALERT_EMAIL">Alert Email</Option>
                      <Option value="HOLIDAY_ALERT">Holiday Alert</Option>
                      <Option value="CORPORATE_WELCOME_EMAIL">
                        Corporate Welcome Email
                      </Option>
                      <Option value="CLIENT_WELCOME_EMAIL">
                        Client Welcome Email
                      </Option>
                      <Option value="CONTACT_WELCOME_EMAIL">
                        Contact Welcome Email
                      </Option>
                      <Option value="COMPANY_WELCOME_EMAIL">
                        Company Welcome Email
                      </Option>
                      <Option value="UPCOMING_TRANSACTION_EMAIL">
                        Upcoming Transaction Email
                      </Option>
                      <Option value="TRANSACTION_EMAIL">
                        Transaction Email
                      </Option>
                      <Option value="CASE_HOLIDAY_EMAIL">
                        Case Holiday Email
                      </Option>
                      <Option value="ACH_GENERATED_EMAIL">
                        ACH Generated Email
                      </Option>
                      <Option value="CHECK_AVAILABLE_EMAIL">
                        Check Available Email
                      </Option>
                      <Option value="BLOCKED_TRANSACTION_EMAIL">
                        Blocked Transaction Email
                      </Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Template Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%", marginBottom: "12px" }}
                  >
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      value={
                        templateAssign.emailTemplate &&
                        templateAssign.emailTemplate.templateName
                      }
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeAssignTemplateData(
                          "emailTemplatedId",
                          e
                        );
                      }}
                    >
                      {emailTemplateList &&
                        emailTemplateList.map((template) => (
                          <Option value={template.emailTemplateId}>
                            {template.templateName}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
              <ButtonComponent
                loading={loading}
                buttonName="Update"
                value={value}
                onClick={() => {
                  const dataToSend = {
                    templateAssignId: templateAssign.templateAssignId,
                    emailTemplatedId:
                      templateAssign.emailTemplate &&
                      templateAssign.emailTemplate.emailTemplateId,
                    emailType: templateAssign.emailType,
                  };
                  this.props.updateEmailType(dataToSend);
                }}
              />
            </DialogActions>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    border: "1px solid rgb(224, 224, 224)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "1rem",
  },
};

export default EmailTypeTable;
