import { connect } from 'react-redux';
import { WorkflowScreen } from "../../screens";
import { closeSnackBarForWorkflow, fetchWorkflowFailure, fetchWorkflowSuccess } from "../../actions";
import { WorkflowAPI } from "../../api";
import { deleteWorkflowFailure, deleteWorkflowSuccess, workflowRequestMade } from "../../actions/WorkflowAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchWorkflowList: () => {
            dispatch(workflowRequestMade(true));
            WorkflowAPI.fetchWorkflowList((response, error) => {
                if (response) {
                    dispatch(fetchWorkflowSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchWorkflowFailure(error.msg))
                    } else {
                        dispatch(fetchWorkflowFailure(error));
                    }
                }
            })
        },
        deleteWorkflow: (workflowId, props) => {
            dispatch(workflowRequestMade(true));
            WorkflowAPI.deleteWorkflow(workflowId, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(deleteWorkflowFailure(error.msg))
                    } else {
                        dispatch(deleteWorkflowFailure(error));
                    }
                } else {
                    props.fetchWorkflowList();
                    dispatch(deleteWorkflowSuccess(workflowId));
                }
            })
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForWorkflow(true)),
    };
};


export const WorkflowContainer = connect(mapStateToProps, mapDispatchToProps)(WorkflowScreen);