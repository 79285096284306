import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import Typography from "@material-ui/core/Typography";
import {Select} from "antd";
import {Prompt} from "react-router";

const {Option} = Select;

class CreateTransactionFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDropdown: false,
            isBlocking: false,
            clicked: false,
        }

    }

    componentDidMount() {
        this.props.fetchVendor();
        this.props.fetchScheduleTransactionList();
    }

    componentWillUnmount() {
        this.props.clearFileData();
        this.props.handleChangeTransData("transactionMedium", "");
        this.props.handleChangeTransData("transactionType", "");
        this.props.handleChangeTransData("clientId", "");
        this.props.handleChangeTransData("amount", "");
        this.props.handleChangeTransData("bankDetailId", "");
        this.props.handleChangeTransData("note", "");
    }

    render() {
        const scheduleTransactionState = this.props && this.props.state.scheduleTransactionState;
        const clients = scheduleTransactionState && scheduleTransactionState.clientList;
        const clientRes = scheduleTransactionState && scheduleTransactionState.clientRes;
        const fileUrl = scheduleTransactionState && scheduleTransactionState.fileUrl;
        const transactionState = this.props && this.props.state.transactionState;
        const transData = transactionState && transactionState.transData;
        const transactionMedium = transData.transactionMedium;
        const transactionType = transData.transactionType;
        const vendorList = this.props.state.vendorState.vendorAccount;
        const vendorData = vendorList && vendorList.filter(vendor =>
            vendor.vendorType === "EVERFUND_VENDOR");
        const vendorDataForPayment = vendorList && vendorList.filter(vendor =>
            vendor.vendorType !== "EVERFUND_VENDOR");

        const onChangeDocumentFileHandler = (eventFileHandler) => {
            let file = eventFileHandler.target.files[0];
            if (file && file.type === "application/pdf"
                || file && file.type === "text/plain") {
                let type = file.type;
                let name = file.name;
                let singleFile = {
                    document: file,
                    type: type,
                    name: name,
                };
                {
                    this.setState(
                        {
                            isBlocking: true,
                        },
                        () => {
                            this.props.uploadDocumentFile(
                                singleFile,
                                this.props
                            );
                        }
                    );
                }
            } else {
                this.setState({
                    openSnackBar: true,
                })
            }
        };
        return (
            <React.Fragment>
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: "#8BC83F", paddingBottom: "2px"}}/>
                        Home
                    </Link>
                    <Link color="inherit" to="/transaction" className="links">
                        Transaction
                    </Link>
                    <Typography color="inherit" className="link">
                        Create Transaction
                    </Typography>
                </Breadcrumbs>
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />
                <div style={style.container}>
                    <div className="card" style={style.card}>
                        <div className="form">
                            <div className="card-header" style={style.cardHeader}>
                                <h5 style={style.clientText}> Create Transaction </h5>
                            </div>
                            <div className="card-body" style={style.cardBody}>
                                <div className="row mt-3">
                                    <div className="col-lg-4 select-input h40">
                                        <label htmlFor="clientName" style={style.columnHeading}>Client Name
                                            <span style={{color: "red"}}> *</span></label>
                                        <Select
                                            showSearch
                                            style={{width: 200, marginTop: "12px"}}
                                            onSearch={(value) => {
                                                this.props.searchClientList(value);
                                            }}
                                            onInputKeyDown={() => {
                                                this.setState({
                                                    showDropdown: true,
                                                })
                                            }}
                                            onChange={(e) => {
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.fetchSingleClient(e);
                                                this.props.handleChangeTransData("clientId", e);
                                            }}
                                            onSelect={() => {
                                                this.setState({
                                                    showDropdown: false,
                                                })
                                            }}
                                            onBlur={() => {
                                                this.setState({
                                                    showDropdown: false,
                                                })
                                            }}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            defaultOpen={false}
                                            dropdownStyle={this.state.showDropdown ? {display: 'block'} : {display: 'none'}}
                                        >
                                            {clients && clients.map(res =>
                                                res.isEnableTransaction ?
                                                    <>
                                                        <Option key={res.lastName} value={res.clientId}>
                                                            {res.lastName}
                                                        </Option>
                                                        <Option key={res.ssn} value={res.clientId}>
                                                            {res.ssn}
                                                        </Option>
                                                        <Option key={res.clientId} value={res.clientId}>
                                                            {res.firstName}
                                                        </Option>
                                                    </>
                                                    : null
                                            )
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="amount" style={style.columnHeading}>Amount (Dollar)
                                            <span style={{color: "red"}}> *</span> </label>
                                        <input
                                            type='number'
                                            id="amount"
                                            autoComplete='off'
                                            style={style.inputField}
                                            onChange={(e) => {
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeTransData(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="transMedium" style={style.columnHeading}>
                                            Transaction Medium <span style={{color: "red"}}> *</span></label>
                                        <div className="select-input selects h40"
                                             style={{marginTop: "12px", width: "100%"}}>
                                            <Select
                                                id="transactionMedium"
                                                onChange={(e) => {
                                                    this.setState({
                                                        isBlocking: true,
                                                    })
                                                    this.props.handleChangeTransData("transactionMedium", e)
                                                }}
                                            >
                                                <Option value="CHECK">Check</Option>
                                                <Option value="CASH">Cash</Option>
                                                <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                                                <Option value="ACH">ACH</Option>
                                                <Option value="CARD">Card</Option>
                                                <Option value="OTHER_MEDIUM">Other medium</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-4">
                                        <label htmlFor="transType" style={style.columnHeading}>Transaction Type
                                            <span style={{color: "red"}}> *</span></label>
                                        <div className="select-input selects h40"
                                             style={{marginTop: "12px", width: "100%"}}>
                                            <Select
                                                onChange={(e) => {
                                                    this.setState({
                                                        isBlocking: true,
                                                    })
                                                    this.props.handleChangeTransData("transactionType", e);
                                                }}
                                            >
                                                <Option value="PAYMENT">Payment</Option>
                                                <Option value="COLLECTION">Collection</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="vendor" style={style.columnHeading}>Vendor
                                            <span style={{color: "red"}}> *</span></label>
                                        <div className="select-input selects h40"
                                             style={{marginTop: "12px", width: "100%"}}>
                                            <Select
                                                onChange={(e) => {
                                                    this.setState({
                                                        isBlocking: true,
                                                    })
                                                    this.props.handleChangeTransData("vendorId", e)
                                                }}
                                            >
                                                {
                                                    transactionType === "COLLECTION" ?
                                                        <Option
                                                            value={vendorData[0].vendorId}>{vendorData[0].vendorName}</Option>
                                                        :
                                                        vendorDataForPayment && vendorDataForPayment.map(vendor => (
                                                            <Option value={vendor.vendorId}>{vendor.vendorName}</Option>
                                                        ))
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="note" style={style.columnHeading}>
                                            Note
                                        </label>
                                        <input style={style.inputTextField}
                                               id="note"
                                               type="text"
                                               autoComplete='off'
                                               onChange={(e) => {
                                                   this.setState({
                                                       isBlocking: true,
                                                   })
                                                   this.props.handleChangeTransData(
                                                       e.target.id,
                                                       e.target.value
                                                   )
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className={'row mt-5'}
                                     hidden={!(transactionMedium === "CHECK" || transactionType === "COLLECTION")}>
                                    <div className="col-lg-4"
                                         hidden={!(transactionMedium === "CHECK" && transactionType === "COLLECTION"
                                             || transactionType === "COLLECTION" &&
                                             transactionMedium !== "CHECK")}>
                                        <label htmlFor="note" style={style.columnHeading}>
                                            Bank details<span style={{color: "red"}}> *</span>
                                        </label>
                                        <div className="select-input  selects h40">
                                            <Select
                                                showSearch={false}
                                                getPopupContainer={node => node.parentNode}
                                                onChange={(e) => {
                                                    this.setState({
                                                        isBlocking: true,
                                                    })
                                                    this.props.handleChangeTransData("bankDetailId", e)
                                                }}
                                            >
                                                {clientRes && clientRes.bankDetail && clientRes.bankDetail.map(detail => (
                                                    <Option value={detail.bankDetailId}>{detail.accountName}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" hidden={transactionMedium !== "CHECK"}>
                                        <label htmlFor="note" style={style.columnHeading}>
                                            Print Document <span style={{color: "red"}}> *</span>
                                        </label>
                                        {
                                            fileUrl ?
                                                <div>
                                                    {
                                                        fileUrl.split(".").pop() === "pdf" ?
                                                            <img
                                                                src="/assets/images/pdf.svg"
                                                                alt="pdf"
                                                                style={{
                                                                    width: '40px',
                                                                    marginRight: '12px',
                                                                    marginTop: "12px"
                                                                }}/> :
                                                            <img
                                                                src="/assets/images/text_logo.png"
                                                                alt="pdf"
                                                                style={{
                                                                    width: '40px',
                                                                    marginRight: '12px',
                                                                    marginTop: "12px"
                                                                }}/>
                                                    }</div> :
                                                null
                                        }
                                        <label style={{
                                            width: '115px',
                                            marginTop: '0.5rem',
                                            marginBottom: '1.5rem',
                                            background: 'rgba(204,204,254,0.32)',
                                            border: '1px solid #7b7b7b',
                                            textAlign: 'center',
                                            padding: '4px',
                                            cursor: 'pointer'
                                        }}>
                                            <input
                                                type="file"
                                                onChange={(e) => {
                                                    this.setState({
                                                        isBlocking: true,
                                                    })
                                                    e.preventDefault();
                                                    onChangeDocumentFileHandler(e);
                                                }}
                                                className="hidden"
                                                style={{display: 'none'}}
                                            />
                                            Select file
                                        </label>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-4"/>
                                    <div className="col-4"/>
                                    <div className="col-4">
                                        <button
                                            className="btn btn-primary"
                                            style={style.nextButton}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    isBlocking: false,
                                                })
                                                if (!this.state.clicked) {
                                                    this.setState({
                                                        clicked: true
                                                    });
                                                    this.props.createTransData(transData, this.props)
                                                    setTimeout(() => {
                                                        this.setState({
                                                            clicked: false
                                                        })
                                                    }, 3000);
                                                }
                                            }}
                                        >
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const style = {
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    container: {
        marginLeft: "3%",
        marginRight: "3%",
        marginBottom: "2%",
        marginTop: "75px"
    },
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: '335px',
        marginRight: 0,
        marginBottom: "1rem",
        width: '60%',
    },
    addUserButton: {
        background: "#8BC83F",
        color: "white",
        width: "100%",
        padding: "0.5rem",
        border: "1px solid #8BC83F"
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '4%',
        height: '64px'
    },
    cardBody: {
        paddingLeft: '4%',
        paddingRight: '4%'
    },
    clientText: {
        color: '#cc6600',
        fontSize: '20px',
        fontWeight: 'bolder',
        paddingTop: '15px',
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    inputTextField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    columnHeading: {
        color: '#625F5F',
        marginBottom: '0px',
        fontSize: '16px',
        width: '100%',
        textAlign: 'left !important'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    selectHead: {
        position: 'relative',
    },
    selectBarHead: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        marginTop: '5px',
        height: '40px',
        fontSize: '15px'
    },
    select: {
        position: "relative",
        height: "40px",
        marginTop: '10px',
    },
    icon: {
        position: 'absolute',
        top: '34px',
        right: '25px',
        color: '#C8C5C5',
        fontSize: '32px',
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        float: 'right',
        width: '40%',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '16px',
        marginRight: 0,
        marginBottom: '5px'
    },
    addUserDisableButton: {
        background: "#b1d681",
        float: 'right',
        width: '40%',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '16px',
        marginRight: 0,
        marginBottom: '5px'
    },
}
export default CreateTransactionFormScreen;
