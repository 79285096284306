import {connect} from 'react-redux';
import GlobalNavigationTable from "../../screens/GlobalNavigationTablesScreen";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = () => {
    return {
    };
};


export const GlobalNavigationTableContainer = connect(mapStateToProps, mapDispatchToProps)(GlobalNavigationTable);