import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { Tabs } from "antd";
import "../styles/styles.css";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
// import Avatar from "@material-ui/core/Avatar";
import { Avatar } from "antd";
import { HelmetComponent } from "../components/Helmet";
import AchActivityLog from "./AchActivityLog";
import ChangedLogPopover from "../components/ChangedLogPopover";
import { formatter } from "../utils/currencyConvertor";

const { TabPane } = Tabs;

class ActivityLogScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
      tabValue: 1,
    };
  }
  componentDidMount() {
    this.props.fetchActivityLog();
  }

  render() {
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const activityLogState =
      this.props.state && this.props.state.activityLogState;
    const activityLogs = activityLogState && activityLogState.activities;
    const achActivityLogs =
      activityLogState && activityLogState.achActivityLogs;

    const callback = (key) => {
      localStorage.setItem("activityTabKey", key);
    };
    return (
      <React.Fragment>
        <HelmetComponent title="Activity Log" />
        {this.props.state.activityLogState.loading ? (
          <div
            className={
              this.props.state.activityLogState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Activity Log
          </Typography>
        </Breadcrumbs>
        <div style={{ margin: "48px 3% 1% 2%" }}>
          <Tabs defaultActiveKey={this.state.tabValue} onChange={callback}>
            <TabPane tab="Activity Log" key="1">
              <div className={"row"} style={style.card}>
                <TableContainer className="fixed-table-header">
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                    ref={this.tableRef}
                  >
                    <TableHead style={{ padding: "1rem" }}>
                      <TableRow>
                        <TableCell
                          className="first-tableHeader"
                          style={{ paddingLeft: "60px" }}
                        >
                          User
                        </TableCell>
                        <TableCell className="tableHeader">Activity</TableCell>
                        <TableCell className="tableHeader">Date/Time</TableCell>
                        <TableCell className="tableHeader">
                          Activity Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {activityLogs &&
                      activityLogs
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((activity) => (
                          <TableBody key={activity.activityLogId}>
                            <TableRow>
                              <TableCell
                                className="first-tableBody"
                                style={{ width: "25%" }}
                              >
                                <Avatar
                                  alt="Profile"
                                  src={
                                    activity.profilePic
                                      ? activity.profilePic
                                      : "/assets/images/profile.svg"
                                  }
                                  // style={{width: "39px", height: "39px", marginLeft: "28px"}}
                                />
                                <span style={{ marginLeft: "12px" }}>
                                  {activity.userName !== undefined
                                    ? activity.userName
                                    : "System"}
                                </span>
                              </TableCell>
                              <TableCell
                                className="tableBody"
                                style={{ width: "30%" }}
                              >
                                <span
                                  style={{
                                    fontWeight: "600",
                                    marginRight: "4px",
                                  }}
                                >
                                  {activity.refValue &&
                                  activity.refValue.charAt(0) === "{"
                                    ? ""
                                    : activity.refValue &&
                                      activity.refValue.replace("_", " ")}
                                </span>
                                {activity.activity &&
                                  activity.activity !== "transaction.deleted" &&
                                  activity.activity
                                    .replace(".", " ")
                                    .replace(".", " ")
                                    .replace(".", " ")
                                    .replace(".", " ")}{" "}
                                {activity.refValue &&
                                activity.refValue.charAt(0) === "{" &&
                                (activity.activity ===
                                  "vendor.account.updated" ||
                                  activity.activity ===
                                    "schedule.transaction.updated" ||
                                  "transaction.updated") ? (
                                  <>
                                    <br />
                                    <ChangedLogPopover
                                      refValue={activity.refValue}
                                      formatter={formatter}
                                    />
                                  </>
                                ) : activity.activity ===
                                  "transaction.deleted" ? (
                                  <>
                                    <br />

                                    <ChangedLogPopover
                                      // refValue={activity.refValue}
                                      formatter={formatter}
                                      isTrnxDeleted={true}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell className="tableBody">
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(activity.createdAt)}
                              </TableCell>
                              <TableCell className="tableBody">
                                {activity.activityType &&
                                  activity.activityType.replace("_", " ")}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))}
                  </Table>
                </TableContainer>
                {activityLogs && activityLogs.length > 0 ? (
                  <div
                    style={{
                      justifyContent: "flex-start",
                      width: "100%",
                      borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TablePagination
                      labelRowsPerPage={false}
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={
                        activityLogs.length > 0 ? activityLogs.length : 100
                      }
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "1rem",
                        color: "#606060",
                      }}
                    >
                      {" "}
                      No data to show
                    </h3>
                  </div>
                )}
                {/* </TableContainer> */}
              </div>
            </TabPane>
            <TabPane tab="ACH Activity Log" key="2">
              <AchActivityLog
                fetchACHACtivity={this.props.fetchACHActivityLog}
                achActivityLogs={achActivityLogs}
              />
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    border: "1px solid rgb(224, 224, 224)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "1rem",
  },
};
export default ActivityLogScreen;
