import React from "react";
import "./App.css";
import './styles/styles.css';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import AuthRequiredRoute from "./components/AuthRequiredRoute";
import {
    DynamicActionClientContainer,
    LoginContainer,
    PrintBulkCheckScreenContainer,
    ResetPasswordContainer
} from "./components/container";
import {ManualCheckPrintScreenContainer} from "./components/container/ManualCheckPrintScreenContainer";
import {NavBar} from "./components/Navbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import ComponentToPrint from "./screens/ManualCheckScreen";
import PageNotFound from "./screens/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";


function App() {
    return (
        <BrowserRouter>
            <Switch>
                <AuthRequiredRoute exact path="/" from component={LoginContainer}/>
                <AuthRequiredRoute exact path='/reset/password' from component={ResetPasswordContainer}/>
                <Route exact path="/request/action/form/:linkKey" component = {DynamicActionClientContainer} />
                <PrivateRoute path="/checkToPrint" component={ManualCheckPrintScreenContainer}/>
                <PrivateRoute component={NavBar}/>
            </Switch>
            <Route exact path="/notfound" from component={PageNotFound} />
        </BrowserRouter>
    );
}

export default App;
