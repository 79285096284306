import {connect} from 'react-redux';
import {CorporateScreen} from "../../screens";
import {
    addCorporateFailure,
    addCorporateSuccess,
    closeSnackBarForCorporate,
    corporateRequestMade,
    deleteCorporateFailure,
    fetchCorporateFailure,
    fetchCorporateSuccess,
    handleChangeCorporateToAdd,
    handleChangeCorporateToEdit,
    setCorporateToEdit,
    setCorporateToAddNull,
} from "../../actions";
import CorporateAPI from "../../api/CorporateAPI";
import {searchCorporateFailure, searchCorporateSuccess} from "../../actions/CorporateAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCorporate: () => {
            dispatch(corporateRequestMade(true));
            CorporateAPI.fetchCorporateList((response, error) => {
                if (response) {
                    dispatch(fetchCorporateSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCorporateFailure(error.msg))
                    } else {
                        dispatch(fetchCorporateFailure(error));
                    }
                }
            })
        },
        deleteCorporate: (corporateId, props) => {
            dispatch(corporateRequestMade(true));
            CorporateAPI.deleteCorporate(corporateId, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(deleteCorporateFailure(error.msg))
                    } else {
                        dispatch(deleteCorporateFailure(error));
                    }
                } else {
                    window.location.reload(false)
                }
            })
        },
        addNewCorporate: (corporateData, props) => {
            dispatch(corporateRequestMade(true));
            CorporateAPI.addNewCorporate(corporateData, (response, error) => {
                if (response) {
                    dispatch(addCorporateSuccess(true));
                } else {
                    if (error.msg) {
                        dispatch(addCorporateFailure(error.msg));
                    } else {
                        dispatch(addCorporateFailure(error));
                    }
                }
            });
        },
        setCoporateToEdit :(corporateData) => {
            dispatch(setCorporateToEdit(corporateData))
        },
        setCoporateDataToAddEmpty:(corporateData) => {
            dispatch(setCorporateToAddNull())
        },
        setAddSucessToFalse:() => {
            dispatch(addCorporateSuccess(false));
        },
        updateCorporate: (corporateData, props) => {
            dispatch(corporateRequestMade(true));
            CorporateAPI.editCorporate(corporateData, (response, error) => {
                if (response) {
                    dispatch(addCorporateSuccess(true))
                     // props.fetchCorporate();

                } else {
                    if (error.msg) {
                        dispatch(addCorporateFailure(error.msg));
                    } else {
                        dispatch(addCorporateFailure(error));
                    }
                }
            });
        },
        handleChangeCorporateToAdd: (id, value) => dispatch(handleChangeCorporateToAdd(id, value)),
        handleChangeCorporateToEdit: (id, value) => dispatch(handleChangeCorporateToEdit(id, value)),
        handleSnackBarClose: () => dispatch(closeSnackBarForCorporate(true)),

        //search
        searchCorporate: (value) => {
            CorporateAPI.searchCorporate(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchCorporateFailure(error.msg))
                    } else {
                        dispatch(searchCorporateFailure(error));
                    }
                } else {
                    dispatch(searchCorporateSuccess(response));
                }
            })
        },
    };
};


export const CorporateContainer = connect(mapStateToProps, mapDispatchToProps)(CorporateScreen);