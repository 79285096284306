import { connect } from 'react-redux';
import {
	clearCheckBoxValues,
	clearUpdateModalData,
	closeTransactionSnackBar,
	fetchCreatedTransactionFailure,
	fetchCreatedTransactionSuccess,
	handleChangeSelectedData,
	handleChangeTransactionRemark,
	handleChangeTransactionStatus,
	searchTransactionByMediumAndStatusAndDateFailure,
	searchTransactionByMediumAndStatusAndDateSuccess,
	transactionRequestMade,
	updateSubmitTransactionFailure,
	updateSubmitTransactionSuccess,
	openSnackBar,
	handleChangeDateFrom,
	handleChangeDateTo,
	handleChangeTerm,
	handleChangeVendor,
	searchTransactionFailure,
	searchTransactionSuccess,
} from '../../actions/TransactionAction';
import {
	fetchVendorFailure,
	fetchVendorSuccess,
	vendorRequestMade,
} from '../../actions';
import { TransactionAPI, VendorAPI } from '../../api';
import ApproveTransactionScreen from '../../screens/ApproveTransactionScreen';

const mapStateToProps = state => {
	return {
		state,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		searchTransactionByAll: (medium, status, type, from, to) => {
			dispatch(transactionRequestMade(true));
			TransactionAPI.searchTransactionByAll(
				medium,
				status,
				type,
        from === null ? 0 : from,
				to === null ? 0 : to,
				'',
				(response, error) => {
					if (error) {
						if (error.msg) {
							dispatch(
								searchTransactionByMediumAndStatusAndDateFailure(error.msg)
							);
						} else {
							dispatch(searchTransactionByMediumAndStatusAndDateFailure(error));
						}
					} else {
						dispatch(
							searchTransactionByMediumAndStatusAndDateSuccess(response)
						);
					}
				}
			);
		},
		fetchCreatedTransactionList: () => {
			dispatch(transactionRequestMade(true));
			TransactionAPI.fetchCreatedTransactionList((response, error) => {
				if (response) {
					dispatch(fetchCreatedTransactionSuccess(response));
				} else {
					if (error.msg) {
						dispatch(fetchCreatedTransactionFailure(error.msg));
					} else {
						dispatch(fetchCreatedTransactionFailure(error));
					}
				}
			});
		},
			// for filter
			searchTransaction: (value) => {
				dispatch(transactionRequestMade(true));
				TransactionAPI.searchTransaction(value, (response, error) => {
				  if (error) {
					if (error.msg) {
					  dispatch(searchTransactionFailure(error.msg));
					} else {
					  dispatch(searchTransactionFailure(error));
					}
				  } else {
					dispatch(searchTransactionSuccess(response));
				  }
				});
			  },
		fetchVendor: () => {
			dispatch(vendorRequestMade(true));
			VendorAPI.fetchVendorList((response, error) => {
				if (response) {
					dispatch(fetchVendorSuccess(response));
				} else {
					if (error.msg) {
						dispatch(fetchVendorFailure(error.msg));
					} else {
						dispatch(fetchVendorFailure(error));
					}
				}
			});
		},
		updateSubmitTransactionList: (transData, props) => {
			dispatch(transactionRequestMade(true));
			TransactionAPI.updateTransactionStatus(transData, (response, error) => {
				if (response) {
					dispatch(updateSubmitTransactionSuccess(true));
					props.searchTransactionByAll(
						'UNKNOWN_TRANSACTION_MEDIUM',
						'TRANSACTION_CREATED',
						'UNKNOWN_TRANSACTION_TYPE',
						0,
						0,
						''
					);
				} else {
					if (error.msg) {
						dispatch(updateSubmitTransactionFailure(error.msg));
					} else {
						dispatch(updateSubmitTransactionFailure(error));
					}
				}
			});
		},
		setSubmitTransactionSuccessToFalse: () => {
			dispatch(updateSubmitTransactionSuccess(false));
		},
		handleTransactionSnackBarClose: () =>
			dispatch(closeTransactionSnackBar(true)),
		handleChangeTransStatus: status =>
			dispatch(handleChangeTransactionStatus(status)),
		handleChangeRemark: remark =>
			dispatch(handleChangeTransactionRemark(remark)),
		handleChangeSelectedData: (bool, selectedData) =>
			dispatch(handleChangeSelectedData(bool, selectedData)),
		handleChangeDateFrom: from => dispatch(handleChangeDateFrom(from)),
		handleChangeDateTo: to => dispatch(handleChangeDateTo(to)),

		handleChangeTerm: term => dispatch(handleChangeTerm(term)),
		handleChangeVendor: vendor => dispatch(handleChangeVendor(vendor)),
		clearCheckBoxValues: values => dispatch(clearCheckBoxValues(values)),
		clearUpdateModalData: values => dispatch(clearUpdateModalData(values)),
		clearSnackBar: () => dispatch(openSnackBar()),
	};
};

export const ApproveTransactionContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ApproveTransactionScreen);
