import React, {Component} from "react";
import {DatePicker, Radio, Select} from "antd";
import {Checkbox} from "@material-ui/core";
import currencies from "../currencies";
import moment from "moment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
const {Option} = Select;

class ClientDynamicConditionalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        const {
            conditionalFormCaseData,
            contactList,
            searchIndividualContact,
            previousActionData,
            getConditionalCaseFormWithRefIdByClient,
        } = this.props;
        const currentActionFormId = this.props.activeActionFormId;
        const currentRefId = this.props.activeRefId;
        const currentOptionValue = this.props.activeOptionValue;

        console.log("props", this.props);

        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
            return moment(dateConverted,"YYYY-MM-DD");
        };
        return (
            <React.Fragment>
                <div className="dynamicContainer">
                    {conditionalFormCaseData && conditionalFormCaseData.conditionalForms
                    && conditionalFormCaseData.conditionalForms.length > 0
                    && conditionalFormCaseData.conditionalForms.map(singleConditionalData => {
                        return(
                            <>
                            <div className="row" key={singleConditionalData.conditionalFormId}>
                                <div className="col-12">
                                    <div className="label">
                                        <label className="fieldLabel">
                                            {singleConditionalData.fieldName}{" "}
                                            <span
                                                hidden={singleConditionalData.required !== true}
                                                style={{
                                                    fontSize: "20px",
                                                    color: "red",
                                                    paddingLeft: "3px",
                                                }}
                                            >
                                              *
                                            </span>
                                        </label>
                                    </div>
                                    {
                                        singleConditionalData.fieldType === "TEXT_FIELD" ? (
                                            <>
                                                <input
                                                    className="inputField"
                                                    type="text"
                                                    autoComplete="off"
                                                    required={singleConditionalData.required === true}
                                                    id={singleConditionalData.conditionalFormId}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    // onChange={(e) => {
                                                    //     const conditionalFormDataId =
                                                    //         singleConditionalData.conditionalFormData &&
                                                    //         singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                    //             singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                    //     this.props.changeConditionalFormValues(
                                                    //         singleConditionalData.conditionalFormId,
                                                    //         e.target.value, this.props.activeRefId, conditionalFormDataId)
                                                    // }}
                                                />
                                            </>
                                        ) : singleConditionalData.fieldType === "CONTENT"  ? (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div style={{
                                                        marginTop: '5px',
                                                        marginBottom:'5px',
                                                        color: '#434343',
                                                        fontSize: '14px',
                                                    }}>{singleConditionalData.content}</div>
                                                </div>
                                            </div>
                                        ) : singleConditionalData.fieldType === "PHONE"?(
                                            <>
                                                <input
                                                    className="inputField"
                                                    type="text"
                                                    required={singleConditionalData.required === true}
                                                    id={singleConditionalData.conditionalFormId}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    onChange={(e) => {
                                                        const conditionalFormDataId =
                                                            singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId,
                                                        //     e.target.value, this.props.activeRefId,conditionalFormDataId)
                                                    }}
                                                />
                                            </>
                                        ) : singleConditionalData.fieldType === "EMAIL" ?(
                                            <>
                                                <input
                                                    className="inputField"
                                                    autoComplete="off"
                                                    required={singleConditionalData.required === true}
                                                    type="text"
                                                    id={singleConditionalData.conditionalFormId}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    onChange={(e) => {
                                                        const conditionalFormDataId =
                                                            singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId,
                                                        //     e.target.value, this.props.activeRefId, conditionalFormDataId)
                                                    }}
                                                />
                                            </>
                                        ) : singleConditionalData.fieldType === "SSN" ?(
                                            <>
                                                <input
                                                    className="inputField"
                                                    autoComplete="off"
                                                    required={singleConditionalData.required === true}
                                                    type="number"
                                                    id={singleConditionalData.conditionalFormId}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    onChange={(e) => {
                                                        const conditionalFormDataId =
                                                            singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId,
                                                        //     e.target.value,this.props.activeRefId,conditionalFormDataId)
                                                    }}
                                                />
                                            </>
                                        ) : singleConditionalData.fieldType === "CURRENCY" ? (
                                            <div className="selects h40 mb-3">
                                                <Select
                                                    required={singleConditionalData.required === true}
                                                    id={singleConditionalData.conditionalFormId}
                                                    showSearch={false}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    getPopupContainer={node => node.parentNode}
                                                    onChange={(e) => {
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId, e)
                                                        const conditionalFormDataId =
                                                            singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId,
                                                        //     e, this.props.activeRefId, conditionalFormDataId)
                                                    }}
                                                >
                                                    {
                                                        currencies && currencies.map(
                                                            option =>
                                                                <Option value={option.code} >
                                                                    {option.code} ( {option.symbol} )
                                                                </Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        ) : singleConditionalData.fieldType === "AMOUNT" ? (
                                            <>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            required={singleConditionalData.required === true}
                                                            id={singleConditionalData.conditionalFormId}
                                                            value={singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].data/100}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                this.setState({isBlocking: true});
                                                                // this.props.handleChangeActionInputData(e.target.value * 100, singleAction.actionFormId)
                                                            }}
                                                            className="fileNameInput outerFormDiv mb"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : singleConditionalData.fieldType === "DOB" ? (
                                            <>
                                                <div className="select-dob mb-3" onClick={()=>{
                                                    this.setState({isBlocking: true})
                                                }}>
                                                    <DatePicker
                                                        getPopupContainer={node => node.parentNode}
                                                        required={singleConditionalData.required === true}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data &&
                                                        getDate(singleConditionalData.conditionalFormData[0].data)}
                                                        format="MM-DD-YYYY"
                                                        onChange={(event)=>{
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                    singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            // if(event) {
                                                            //     const valueOfInput1 = event.format();
                                                            //     this.props.changeConditionalFormValues(
                                                            //         singleConditionalData.conditionalFormId,
                                                            //         new Date(valueOfInput1).getTime(),
                                                            //         this.props.activeRefId, conditionalFormDataId)
                                                            // }
                                                        }}
                                                        onPanelChange={(event) => {
                                                            // if(event) {
                                                            //     const conditionalFormDataId =
                                                            //         singleConditionalData.conditionalFormData &&
                                                            //         singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                            //             singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            //     const valueOfInput1 = event.format();
                                                            //     this.props.changeConditionalFormValues(
                                                            //         singleConditionalData.conditionalFormId,
                                                            //         new Date(valueOfInput1).getTime(),
                                                            //         this.props.activeRefId, conditionalFormDataId)
                                                            // }
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : singleConditionalData.fieldType === "DROPDOWN" ? (
                                            <div className="selects h40 mb-3">
                                                <Select
                                                    required={singleConditionalData.required === true}
                                                    id={singleConditionalData.conditionalFormId}
                                                    showSearch={false}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    getPopupContainer={node => node.parentNode}
                                                    onChange={(e) => {
                                                        const conditionalFormDataId =
                                                            singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId,
                                                        //     e, this.props.activeRefId, conditionalFormDataId)
                                                    }}
                                                >
                                                    {
                                                        singleConditionalData.optionValue &&
                                                        singleConditionalData.optionValue.map(
                                                            option =>
                                                                <Option value={option.optionValueId}>
                                                                    {option.optionValueName}
                                                                </Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        ) : singleConditionalData.fieldType === "RADIO_BUTTON" ? (
                                            <div style={{marginBottom: "12px", marginTop: "10px"}}>
                                                <Radio.Group
                                                    required={singleConditionalData.required === true}
                                                    id={singleConditionalData.conditionalFormId}
                                                    value={singleConditionalData.conditionalFormData &&
                                                    singleConditionalData.conditionalFormData[0].data}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        const conditionalFormDataId =
                                                            singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        // this.props.changeConditionalFormValues(
                                                        //     singleConditionalData.conditionalFormId,
                                                        //     e.target.value, this.props.activeRefId, conditionalFormDataId)
                                                    }}>
                                                    {
                                                        singleConditionalData.optionValue &&
                                                        singleConditionalData.optionValue.map(
                                                            option =>
                                                                <Radio
                                                                    style={{fontSize: "18px"}}
                                                                    value={option.optionValueId}>
                                                                        <span style={{fontSize: "16px"}}>
                                                                            {option.optionValueName}
                                                                        </span>
                                                                </Radio>
                                                        )
                                                    }
                                                </Radio.Group>
                                            </div>
                                        ) : singleConditionalData.fieldType === "CHECKBOX" ?(
                                            <div>
                                                <ul style={{listStyleType: "none", paddingLeft: "5px"}}>
                                                    {
                                                        singleConditionalData.optionValue &&
                                                        singleConditionalData.optionValue.map(
                                                            (option, index) => {
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                return(
                                                                    <li>
                                                                        <Checkbox
                                                                            // onClick={(event)=>{
                                                                            //     this.props.handleClick(event, option)
                                                                            // }}
                                                                            // checked={!!option.isSelected}
                                                                            value={singleConditionalData.conditionalFormData &&
                                                                            singleConditionalData.conditionalFormData.map(action => action.data)}
                                                                            onChange={(event) => {
                                                                                this.setState({
                                                                                    // checked : event.target.checked,
                                                                                    // activeRefId: option.refId,
                                                                                    // activeConditionalFormId:  singleConditionalData.conditionalFormId,
                                                                                })
                                                                                //FOR GET
                                                                                const conditionalFormDataIdList =
                                                                                    singleConditionalData.conditionalFormData && singleConditionalData.conditionalFormData.map(
                                                                                    conditionalForm => {
                                                                                        return{
                                                                                            conditionalFormDataId: conditionalForm.conditionalFormDataId,
                                                                                            data: conditionalForm.data,
                                                                                        }
                                                                                    }
                                                                                    )
                                                                                if(conditionalFormCaseData
                                                                                    && conditionalFormCaseData.step === "firstStep" && event.target.checked){
                                                                                    // this.props.handleSetSelectedOption(singleConditionalData.conditionalFormId, option.optionValueId)
                                                                                    this.props.getConditionalCaseFormWithRefIdByClient(this.props.linkKey ,option.refId,
                                                                                        singleConditionalData.conditionalFormId, "secondStep", currentActionFormId, currentRefId, currentOptionValue)
                                                                                }else if(conditionalFormCaseData
                                                                                    && conditionalFormCaseData.step === "secondStep" && event.target.checked){
                                                                                    // this.props.handleSetSelectedOption(singleConditionalData.conditionalFormId, option.optionValueId)
                                                                                    this.props.getConditionalCaseFormWithRefIdByClient( this.props.linkKey ,option.refId,
                                                                                        singleConditionalData.conditionalFormId, "thirdStep", currentActionFormId, currentRefId, currentOptionValue);
                                                                                }
                                                                            }}
                                                                            inputProps={{'aria-labelledby': labelId}}
                                                                        />
                                                                        <span style={{fontSize: "14px", color: "#434343"}}>
                                                                                {option.optionValueName}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        ) : singleConditionalData.fieldType === "CONTACT" ? (
                                            <>
                                                <div className="select-input h40">
                                                    <Select
                                                        className="drop"
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        getPopupContainer={node => node.parentNode}
                                                        onChange={(e)=>{
                                                            // handleChangeContactValue(singleConditionalData.conditionalFormId, e,this.props.activeRefId );
                                                            // this.props.handleChangeConditionalContactValue(value, refId, conditionalFormId)
                                                        }}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        onSearch={(value)=>{
                                                            searchIndividualContact(value);
                                                        }}
                                                    >
                                                        {contactList && contactList.map(list =>
                                                            <Option value={list.contactId}>{list.name}</Option>
                                                        )}
                                                    </Select>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <label>Email</label>
                                                        <div>
                                                            <input
                                                                disabled
                                                                id={singleConditionalData.conditionalFormId}
                                                                value={singleConditionalData.contact &&
                                                                singleConditionalData.contact.emailPhone}
                                                                className="fileNameInput outerFormDiv"
                                                                // style={style.inputField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Phone</label>
                                                        <input
                                                            disabled
                                                            id={singleConditionalData.conditionalFormId}
                                                            value={singleConditionalData.contact &&
                                                            singleConditionalData.contact.phone}
                                                            className="fileNameInput outerFormDiv"
                                                        />
                                                    </div>
                                                    <div className="col-6" hidden>
                                                        <label>Address</label>
                                                        <div>
                                                            <input
                                                                disabled
                                                                id={singleConditionalData.conditionalFormId}
                                                                value={singleConditionalData.contact &&
                                                                singleConditionalData.contact.address}
                                                                className="fileNameInput outerFormDiv"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <label>Contact Type</label>
                                                        <div>
                                                            <input
                                                                disabled
                                                                id={singleConditionalData.conditionalFormId}
                                                                value={singleConditionalData.contact &&
                                                                singleConditionalData.contact.contactType.replace("_"," ")}
                                                                className="fileNameInput outerFormDiv"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : singleConditionalData.fieldType === "PHOTO" ? (
                                            <>
                                                <div style={{minWidth: '20px !important'}}>
                                                    {
                                                        singleConditionalData.conditionalFormData ?
                                                            <div>
                                                                <div className="extra">
                                                                    <img className='dataImage'
                                                                         src={singleConditionalData.conditionalFormData &&
                                                                         singleConditionalData.conditionalFormData.length > 0 &&
                                                                         singleConditionalData.conditionalFormData[0].data}
                                                                         style={{minWidth: '100px !important'}}/>
                                                                </div>
                                                                <div>
                                                                    <input type="text"
                                                                           className="fileNameInput outerFormDiv"
                                                                           id={singleConditionalData.conditionalFormId}
                                                                           value={singleConditionalData.conditionalFormData &&
                                                                           singleConditionalData.conditionalFormData[0].fileName}
                                                                           placeholder="Image Name"
                                                                           onChange={(e) => {
                                                                               e.preventDefault();
                                                                               const conditionalFormDataId =
                                                                                   singleConditionalData.conditionalFormData &&
                                                                                   singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                                       singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                               // this.props.handleChangeConditionalFileValue(
                                                                               //     e.target.value, this.props.activeRefId, singleConditionalData.conditionalFormId,)
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div> : null
                                                    }

                                                    <div style={{width: '100%'}} className="non-printable">
                                                        <label className="fieldLabel12">
                                                            <input
                                                                id={singleConditionalData.conditionalFormId}
                                                                type="file"
                                                                required={singleConditionalData.required === true}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({isBlocking: true});
                                                                }}
                                                                className="hidden" style={{display: 'none'}}
                                                            />
                                                            Select file
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        ) : singleConditionalData.fieldType === "DOCUMENT" ? (
                                            <>
                                                <div style={{minWidth: '20px !important'}}>
                                                    {
                                                        singleConditionalData.conditionalFormData
                                                        && singleConditionalData.conditionalFormData[0]?
                                                            <>
                                                                {singleConditionalData.conditionalFormData[0].data.split(".").pop() === "pdf" ?
                                                                    <div className="extra">
                                                                        <img
                                                                            src="/assets/images/pdf.svg"
                                                                            alt="pdf"
                                                                            style={{
                                                                                width: '40px',
                                                                                marginRight: '12px'
                                                                            }}/>
                                                                    </div>
                                                                    :
                                                                    <div className="extra">
                                                                        <img
                                                                            src="/assets/images/text_logo.png"
                                                                            alt="pdf"
                                                                            style={{
                                                                                width: '40px',
                                                                                marginRight: '12px'
                                                                            }}/>
                                                                    </div>
                                                                }
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        id={singleConditionalData.conditionalFormId}
                                                                        value={singleConditionalData.conditionalFormData &&
                                                                        singleConditionalData.conditionalFormData[0].fileName}
                                                                        className="fileNameInput outerFormDiv"
                                                                        placeholder="Document Name"
                                                                        onChange={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    />
                                                                </div>
                                                            </> : null
                                                    }
                                                    <div style={{width: '100%'}} className="non-printable">
                                                        <label className="fieldLabel12">
                                                            <input
                                                                id={singleConditionalData.conditionalFormId}
                                                                type="file"
                                                                required={singleConditionalData.required === true}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({isBlocking: true});
                                                                }}
                                                                className="hidden" style={{display: 'none'}}
                                                            />
                                                            Select file
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null }
                                        </div>
                            </div>
                            </>
                        )
                    })}
                </div>
            </React.Fragment>
        );
    }
}

export default ClientDynamicConditionalForm;