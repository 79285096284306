import {connect} from 'react-redux';
import {EmailTemplateAPI} from "../../api";
import {
    addEmailTemplatesFailure,
    addEmailTemplatesSuccess, addEmailTypeFailure, addEmailTypeSuccess,
    clearTemplateModal,
    closeSnackbarForEmailTemplate,
    deleteEmailTemplatesFailure,
    deleteEmailTemplatesSuccess,
    fetchEmailTemplatesFailure,
    fetchEmailTemplatesSuccess, fetchEmailTypeFailure, fetchEmailTypeSuccess, handleChangeAssignTemplateData,
    handleChangeEmailTemplateData,
    handleChangeHtmlPart,
    requestMadeForEmailTemplate, setAssignedEmailTemplateToEdit,
    setEmailTemplateToEdit,
    updateEmailTemplatesFailure,
    updateEmailTemplatesSuccess, updateEmailTypeFailure, updateEmailTypeSuccess
} from "../../actions/EmailTemplateAction";
import {EmailTemplateScreen} from "../../screens";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return{
        closeSnackbarForEmailTemplate : () => {
            dispatch(closeSnackbarForEmailTemplate())
        },
        handleChangeEmailTemplateData : (id, value) => {
            dispatch(handleChangeEmailTemplateData(id, value))
        },
        handleChangeHtmlPart : htmlPart => {
            dispatch(handleChangeHtmlPart(htmlPart))
        },
        fetchEmailTemplate: () => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.fetchEmailTemplate((response, error) => {
                if (response) {
                    dispatch(fetchEmailTemplatesSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchEmailTemplatesFailure(error.msg))
                    } else {
                        dispatch(fetchEmailTemplatesFailure(error));
                    }
                }
            })
        },
        addEmailTemplate : (emailTemplateData, props) => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.addEmailTemplate(emailTemplateData, (response, error) => {
                if (response) {
                    dispatch(addEmailTemplatesSuccess(true));
                    props.fetchEmailTemplate();
                } else {
                    if (error.msg) {
                        dispatch(addEmailTemplatesFailure(error.msg));
                    } else {
                        dispatch(addEmailTemplatesFailure(false));
                    }
                }
            });
        },
        setAddEmailTemplateSuccessToFalse : () => {dispatch(addEmailTemplatesSuccess(false))},
        updateEmailTemplate : (emailTemplateData, props) => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.editEmailTemplate(emailTemplateData, (response, error) => {
                if (response) {
                    dispatch(updateEmailTemplatesSuccess(true));
                    props.fetchEmailTemplate();
                } else {
                    if (error.msg) {
                        dispatch(updateEmailTemplatesFailure(error.msg));
                    } else {
                        dispatch(updateEmailTemplatesFailure(error));
                    }
                }
            });
        },
        setUpdateEmailTemplateSuccessToFalse : () => {dispatch(updateEmailTemplatesSuccess(false))},
        deleteEmailTemplate : (id, props) => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.deleteEmailTemplate(id, (response, error) => {
                if (response) {
                    dispatch(deleteEmailTemplatesSuccess(response));
                    props.fetchEmailTemplate();
                } else {
                    if (error.msg) {
                        dispatch(deleteEmailTemplatesFailure(error.msg));
                    } else {
                        dispatch(deleteEmailTemplatesFailure(error));
                    }
                }
            });
        },
        setEmailTemplateToEdit : (templateData) => {dispatch(setEmailTemplateToEdit(templateData))},
        clearTemplateModal: () => {dispatch(clearTemplateModal())},
        fetchAssignedTemplate: () => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.fetchEmailType((response, error) => {
                if (response) {
                    dispatch(fetchEmailTypeSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchEmailTypeFailure(error.msg))
                    } else {
                        dispatch(fetchEmailTypeFailure(error));
                    }
                }
            })
        },
        assignEmailType : (templateTypeData, props) => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.addEmailType(templateTypeData, (response, error) => {
                if (response) {
                    dispatch(addEmailTypeSuccess(true));
                    props.fetchAssignedTemplate();
                } else {
                    if (error.msg) {
                        dispatch(addEmailTypeFailure(error.msg));
                    } else {
                        dispatch(addEmailTypeFailure(false));
                    }
                }
            });
        },
        setAssignEmailTemplateSuccessToFalse : () => {dispatch(addEmailTypeSuccess(false))},
        updateEmailType : (templateTypeData, props) => {
            dispatch(requestMadeForEmailTemplate(true));
            EmailTemplateAPI.editEmailType(templateTypeData, (response, error) => {
                if (response) {
                    dispatch(updateEmailTypeSuccess(true));
                    props.fetchAssignedTemplate();
                } else {
                    if (error.msg) {
                        dispatch(updateEmailTypeFailure(error.msg));
                    } else {
                        dispatch(updateEmailTypeFailure(error));
                    }
                }
            });
        },
        setUpdateAssignedEmailTemplateSuccessToFalse : () => {dispatch(updateEmailTypeSuccess(false))},
        handleChangeAssignTemplateData : (id, value) => {
            dispatch(handleChangeAssignTemplateData(id, value))
        },
        setAssignedEmailTemplateToEdit: (templateAssign) => {
            dispatch(setAssignedEmailTemplateToEdit(templateAssign))
        },
    }
}

export const EmailTemplateContainer = connect(mapStateToProps, mapDispatchToProps)(EmailTemplateScreen);