import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import store from "./store/store";
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import "antd/dist/antd.css";
import "react-big-calendar";
import {HelmetComponent} from "./components/Helmet";

ReactDOM.render(
    <Provider store={store}>
        <HelmetComponent title=""/>
        <BrowserRouter>
            <Route component={App} />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));
