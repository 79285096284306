import { connect } from 'react-redux';
import { CaseTemplateFormScreen } from "../../screens";
import {
    addCaseTemplateFailure,
    addCaseTemplateSuccess,
    caseTemplateRequestMade,
    closeSnackBarForCaseTemplate,
    fetchWorkflowFailure,
    fetchWorkflowSuccess,
    handleChangeCaseTemplate
} from "../../actions";
import { CaseTemplateAPI, WorkflowAPI } from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchWorkflowList: () => {
            dispatch(caseTemplateRequestMade(true));
            WorkflowAPI.fetchWorkflowList((response, error) => {
                if (response) {
                    dispatch(fetchWorkflowSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchWorkflowFailure(error.msg))
                    } else {
                        dispatch(fetchWorkflowFailure(error));
                    }
                }
            })
        },
        handleChangeCaseTemplate: (id, value) => dispatch(handleChangeCaseTemplate(id, value)),
        addNewCaseTemplate: (caseTemplateData, props) => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.addNewCaseTemplate(caseTemplateData, (response, error) => {
                if (response) {
                    dispatch(addCaseTemplateSuccess(true));
                    props.history.replace('/caseTemplate');
                } else {
                    if (error.msg) {
                        dispatch(addCaseTemplateFailure(error.msg));
                    } else {
                        dispatch(addCaseTemplateFailure(error));
                    }
                }
            });
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForCaseTemplate(true)),
    };
};


export const CaseTemplateFormContainer = connect(mapStateToProps, mapDispatchToProps)(CaseTemplateFormScreen);