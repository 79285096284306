import APIEndPoints from "../constants/APIConstants";
import API from "./API";

class CaseAPI extends API {
  fetchCaseList = (callback) => {
    fetch(APIEndPoints.FETCH_CASE, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  // pagination
  fetchPaginatedCaseList = (nextUrl, callback) => {
    const urlString = nextUrl;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  deleteCase = (caseId, callback) => {
    const urlString = APIEndPoints.DELETE_CASE + caseId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  editCase = (caseData, callback) => {
    fetch(APIEndPoints.UPDATE_CASE, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(caseData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchSingleCase = (id, callback) => {
    var constUrl = APIEndPoints.FETCH_SINGLE_CASE + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  // searchCase = (value, callback) => {
  //     const urlString = APIEndPoints.SEARCH_CASE + value;
  //     fetch(urlString, {
  //         method: "GET",
  //         headers: this.authHeaders
  //     })
  //         .then(response => {
  //             this.processResponse(response, callback);
  //         })
  //         .catch(error => {
  //             console.log("Error in calling search api.", error);
  //             callback(null, "Service error, please try again.");
  //         });
  // };
  scheduleDateByAgent = (data, callback) => {
    fetch(APIEndPoints.SCHEDULE_DATE_BY_AGENT, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchDataListForCalendar = (callback) => {
    fetch(APIEndPoints.FETCH_CALENDAR_LIST, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchFileDetailsByCase = (id, callback) => {
    var constUrl = APIEndPoints.FETCH_FILE_DETAILS_BY_CASE + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  //case contact
  fetchCaseContact = (id, callback) => {
    var constUrl = APIEndPoints.FETCH_CASE_CONTACTS + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  addCaseIndividualContact = (contactData, callback) => {
    fetch(APIEndPoints.ADD_INDIVIDUAL_CONTACT, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(contactData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  addCaseCompanyContact = (companyData, callback) => {
    fetch(APIEndPoints.ADD_COMPANY_CONTACT, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(companyData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  addCaseExistingContact = (existingContactData, callback) => {
    fetch(APIEndPoints.ADD_EXISTING_CONTACT, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(existingContactData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  searchExistingContact = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_CASE_CONTACT + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCase = (value, from, to, status, callback) => {
    const urlString =
      APIEndPoints.FILTER_CASES +
      value +
      "&from=" +
      from +
      "&to=" +
      to +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  exportCasesList = (term, from, to, status, callback) => {
    const urlString =
      APIEndPoints.EXPORT_CASES +
      term +
      "&from=" +
      from +
      "&to=" +
      to +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
}

export default new CaseAPI();
