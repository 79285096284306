import React from "react";
import {Helmet} from "react-helmet";

export const HelmetComponent = ({ title, content }) => (
    <Helmet>
        {
            title === '' ?
                <title> Everfund Pooled Trust</title>:
                <title>{title} |  Everfund Pooled Trust</title>
        }
        <meta name="description" content={content} />
    </Helmet>
);