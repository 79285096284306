import { ACTION_TYPE_FOR_ACTION_PAGE } from "../constants/Type";

const initialState = {
    loading: false,
    openActionSnackBar: false,
    errorMsgForAction: '',
    actionData: {
        actionName: '',
        actionForms: []
    },
    optionValues: [],
    firstConditionalForm: [],
    conditionalFormByRefId: [],
    conditionalActionForm: [],
    checkedValue: false,
};

const actionState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_FOR_ACTION_PAGE.ACTION_FETCH_SUCCESS:
            return {
                ...state,
                action: action.action.actions,
                loading: false,
                openActionSnackBar: false
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.ACTION_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForAction: action.error,
                loading: false,
                openActionSnackBar: true
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.ACTION_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.HANDLE_CHANGE_ACTION:
            const newForm = { ...state.actionData };
            newForm[action.id] = action.value;
            return {
                ...state,
                actionData: newForm,
                loading: false
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.ADD_ACTION_SUCCESS:
            return {
                ...state,
                addActionSuccess: action.bool,
                loading: false,
                openActionSnackBar: false
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.ADD_ACTION_FAILURE:
            return {
                ...state,
                errorMsgForAction: action.error,
                loading: false,
                openActionSnackBar: true
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.SET_ACTION_TO_EDIT:
            return {
                ...state,
                actionData: action.actionData.action,
                loading: false,
                openActionSnackBar: false
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.DELETE_ACTION_SUCCESS:
            const oldActionList = state.action;
            let newActions = [];
            for (let i = 0; i < oldActionList.length; i++) {
                if (oldActionList[i].actionId !== action.actionId) {
                    newActions.push(oldActionList[i]);
                }
            }
            return {
                ...state,
                newAction: newActions,
                loading: false,
                openActionSnackBar: false
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.DELETE_ACTION_FAILURE:
            return {
                ...state,
                errorMsgForAction: action.error,
                loading: false,
                openActionSnackBar: true
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.CLOSE_SNACK_BAR_ACTION_PAGE:
            return {
                ...state,
                openActionSnackBar: false,
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.SEARCH_EXISTING_OPTION_SUCCESS:
            return {
                ...state,
                optionValues: action.optionValues.optionValues,
                loading: false,
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.SEARCH_EXISTING_OPTION_FAILURE:
            return {
                ...state,
                errorMsgForAction: action.error,
                loading: false,
                openActionSnackBar: true
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.HANDLE_CHANGE_FIRST_CONDITIONAL_FORM:
            console.log("first conditional form in action", action.conditionalForm)
            return {
                ...state,
                firstConditionalForm: action.conditionalForm,
                loading: false,
            }
        case ACTION_TYPE_FOR_ACTION_PAGE.SET_CHECKED_VALUE:
            return {
                ...state,
                checkedValue: action.checkedValue,
                loading: false,
            }
        case ACTION_TYPE_FOR_ACTION_PAGE.GET_CONDITIONAL_FORM_BY_REFERENCE_ID_SUCCESS:
            console.log("action.conditionalForm.conditionalForms",action.conditionalForm.conditionalForms)
            const conditionalData = {
                refId : action.id,
                optionId: action.optionId,
                actionId: action.actionId,
                step: action.step,
                conditionalForms: action.conditionalForm &&
                action.conditionalForm.conditionalForms ? action.conditionalForm.conditionalForms : [],
                prevOptionId : action.prevOptionId,
                prevActionId : action.prevActionId,
                prevRefId : action.prevRefId,
                prevOptionName : action.prevOptionName,
            }
            console.log("conditionalData object", conditionalData)
            if(!state.checkedValue && state.conditionalActionForm &&
                state.conditionalActionForm.filter(item => item.optionId === conditionalData.optionId).length > 0  ){
                console.log("already")
            }else{
                state.conditionalActionForm = [...state.conditionalActionForm, conditionalData]
            }
            console.log("hello get reference", state.conditionalActionForm);
            return {
                ...state,
                conditionalFormByRefId: action.conditionalForm.conditionalForms,
                loading: false,
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.SET_EDITED_CONDITIONAL_FORM:
            if(  state.conditionalActionForm && state.conditionalActionForm.length > 0){
                state.conditionalActionForm.filter(single => single.actionId === action.currentActionId
                    && single.optionId === action.currentOptionId)[0].conditionalForms = action.conditionalActionForm;
            }
            return {
                ...state,
                loading: false,
            }
        case ACTION_TYPE_FOR_ACTION_PAGE.GET_CONDITIONAL_FORM_BY_REFERENCE_ID_FAILURE:
            return {
                ...state,
                errorMsgForAction: action.error,
                loading: false,
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
            };
        case ACTION_TYPE_FOR_ACTION_PAGE.SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_FAILURE:
            return {
                ...state,
                errorMsgForAction: action.error,
                loading: false,
            };
        // case ACTION_TYPE_FOR_ACTION_PAGE.SET_PREVIOUS_ID_VALUE:
        //     return {
        //         ...state,
        //         previousOptionId: action.optionId,
        //         previousActionId: action.actionId,
        //         previous
        //     }
        default:
            return state;
    }
};

export default actionState;