import {ACTION_TYPE_CASE} from "../constants/Type";

const initialState = {
    loading: false,
    details: [],
    error: '',
    calendarData: [],
};

const calendarState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_CASE.DETAIL_FOR_CALENDAR_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_CASE.FETCH_CALENDAR_BY_CLIENT_ID_SUCCESS:
            return {
                ...state,
                details: action.response.agentAssigns,
                loading: false,
            }
        case ACTION_TYPE_CASE.FETCH_CALENDAR_BY_CLIENT_ID_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        case ACTION_TYPE_CASE.FETCH_CALENDAR_BY_AGENT_ID_SUCCESS:
            return {
                ...state,
                details: action.response.agentAssigns,
                loading: false,
            }
        case ACTION_TYPE_CASE.FETCH_CALENDAR_BY_AGENT_ID_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        case ACTION_TYPE_CASE.FETCH_DATA_FOR_CALENDAR_SUCCESS:
            return {
                ...state,
                openCaseUpdateSnackBar: false,
                calendarData: action.response.agentAssigns,
                loading:false,
            }
        case ACTION_TYPE_CASE.FETCH_DATA_FOR_CALENDAR_FAILURE:
            return {
                ...state,
                openCaseUpdateSnackBar: true,
                errorMsgForCase: action.error,
                loading:false,
            }
        case ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_SUCCESS:
            return {
                ...state,
                calendarData: action.searchData.agentAssigns,
                loading: false,
            }
        case ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        case ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_CLIENT_ID_SUCCESS:
            return {
                ...state,
                details: action.searchData.agentAssigns,
                loading: false,
            }
        case ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_CLIENT_ID_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        case ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_AGENT_ID_SUCCESS:
            return {
                ...state,
                details: action.searchData.agentAssigns,
                loading: false,
            }
        case ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_AGENT_ID_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        default:
            return state;
    }
};
export default calendarState;