import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class VendorAPI extends API {
  addNewVendor = (vendorData, callback) => {
    let body = JSON.stringify(vendorData);
    fetch(APIEndPoints.ADD_VENDOR, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  fetchVendorList = (callback) => {
    fetch(APIEndPoints.FETCH_VENDOR, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  searchVendor = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_VENDOR + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchSingleVendor = (id, callback) => {
    console.log("fetchSingleVendor id", id);
    const constUrl = APIEndPoints.FETCH_VENDOR_BY_ID + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  deleteVendor = (vendorId, callback) => {
    const urlString = APIEndPoints.DELETE_VENDOR + vendorId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  editVendor = (vendorDate, callback) => {
    const urlString = APIEndPoints.UPDATE_VENDOR;
    fetch(urlString, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(vendorDate),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  //vendor client
  addNewVendorClient = (vendorClientData, callback) => {
    let body = JSON.stringify(vendorClientData);
    fetch(APIEndPoints.ADD_VENDOR_CLIENT, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  editVendorClient = (vendorClientData, callback) => {
    let body = JSON.stringify(vendorClientData);
    fetch(APIEndPoints.UPDATE_VENDOR_CLIENT, {
      method: "PATCH",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  deleteVendorClient = (clientId, callback) => {
    fetch(APIEndPoints.DELETE_VENDOR_CLIENT + clientId, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  fetchVendorClientList = (id, callback) => {
    fetch(APIEndPoints.FETCH_VENDOR_CLIENTS_LIST + id, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchVendorClientById = (id, callback) => {
    fetch(APIEndPoints.FETCH_VENDOR_CLIENT_BY_CLIENT_ID + id, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
}
export default new VendorAPI();
