import React, {Component} from "react";
import '../styles/styles.css';
import {HelmetComponent} from "../components/Helmet";

class PageNotFound extends Component {
    render() {
    return (
            <React.Fragment>
                <HelmetComponent title="Page Not found"/>
                <div className="page-not-found">
                    <span className="top-span">404</span>
                    <span className="inner-span">|</span>
                    <span className="bottom-span">Page Not found</span>
                </div>
            </React.Fragment>
        )}
}

export default PageNotFound;