import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import '../styles/styles.css';
import {
	CircularProgress,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Select, Tag } from 'antd';
import Checkbox from '@material-ui/core/Checkbox';
import MySnackbarContentWrapper from '../components/Snackbar';
import Typography from '@material-ui/core/Typography';
import PaperComponent from './PaperComponent';
import DialogTitleComponent from './DialogTitleComponent';
import { HelmetComponent } from '../components/Helmet';
import ButtonComponent from './ButtonComponent';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyBoardDatePickerComponent from './DatePicker/DatePickerComponent';
import CheckboxFilterPopover from '../components/CheckboxFilterPopover';
import { getFormattedDateValue } from '../utils/DateConverter';
import {
	FilterMediumData,
	FilterTypeData,
} from '../components/FilterOptionList';
import {
	transactionMediumHelper,
	transactionTypeHelper,
} from './TransactionHelper';
const Option = Select;

class ApproveTransactionScreen extends Component {
	constructor(props) {
		super(props);
		this.tableRef = React.createRef();
		this.state = {
			page: 0,
			rowsPerPage: 10,
			openModalToUpdateState: false,
			selected: [],
			clearSelect: false,
			sortType: 'asc',
			openSearchDialogue: false,
			isAllMediumChecked: true,
			isAllTypeChecked: true,

			isMediumCheck: FilterMediumData.map(medium => medium.name),
			isTypeCheck: FilterTypeData.map(type => type.name),
		};
	}
	componentDidMount() {
		this.props.fetchCreatedTransactionList();
		this.props.clearCheckBoxValues();
	}

	componentWillUnmount() {
		this.props.handleTransactionSnackBarClose();
	}
	handleAllMediumCheckbox = () => {
		this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
		this.setState({ isMediumCheck: FilterMediumData.map(li => li.name) });
		if (this.state.isAllMediumChecked) {
			this.setState({ isMediumCheck: [] });
		}
	};
	handleSelectedMediumChange = e => {
		const { value, checked } = e.target;
		this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
		if (!checked) {
			this.setState({
				isMediumCheck: this.state.isMediumCheck.filter(item => item !== value),
			});
		}
	};
	handleAllTypeCheckbox = () => {
		this.setState({ isAllTypeChecked: !this.state.isAllTypeChecked });
		this.setState({ isTypeCheck: FilterTypeData.map(li => li.name) });
		if (this.state.isAllTypeChecked) {
			this.setState({ isTypeCheck: [] });
		}
	};
	handleSelectedTypeChange = e => {
		const { value, checked } = e.target;
		this.setState({ isTypeCheck: [...this.state.isTypeCheck, value] });
		if (!checked) {
			this.setState({
				isTypeCheck: this.state.isTypeCheck.filter(item => item !== value),
			});
		}
	};

	render() {
		const transactionState = this.props && this.props.state.transactionState;
		const vendorState = this.props && this.props.state.vendorState;
		const vendorList = vendorState.vendorAccount;
		const startDate = transactionState && transactionState.from;
		const endDate = transactionState && transactionState.to;
		const vendorToFilter = transactionState && transactionState.vendorToFilter;
		const createdTransactionList =
			transactionState && transactionState.createdTransactionList;
		const filteredCreatedTransactionList =
			createdTransactionList &&
			createdTransactionList.filter(
				list =>
					list.transactionMedium !== 'LEDGER' || list.transactionType !== 'FEE'
			);
		const transStatus = transactionState && transactionState.transStatus;
		const cancelledRemark = transactionState && transactionState.declineRemark;
		const sortedApprovedTransactionList =
			filteredCreatedTransactionList &&
			filteredCreatedTransactionList.sort(function (a, b) {
				return a.transactionTimestamp > b.transactionTimestamp ? -1 : 1;
			});

		const handleSelectAllClick = event => {
			if (event.target.checked) {
				const newSelecteds =
					sortedApprovedTransactionList &&
					sortedApprovedTransactionList.map(n => n.transactionId);
				this.setState({
					selected: newSelecteds,
					clearSelect: false,
				});
				return;
			}
			this.setState({
				selected: [],
			});
		};
		const handleClick = (event, id) => {
			const selectedIndex = this.state.selected.indexOf(id);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(this.state.selected, id);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(this.state.selected.slice(1));
			} else if (selectedIndex === this.state.selected.length - 1) {
				newSelected = newSelected.concat(this.state.selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					this.state.selected.slice(0, selectedIndex),
					this.state.selected.slice(selectedIndex + 1)
				);
			}
			this.setState({
				selected: newSelected,
				clearSelect: false,
			});
		};
		const isSelected = id => this.state.selected.indexOf(id) !== -1;

		const handleChangeOpenModal = () => {
			this.setState({
				openModalToUpdateState: true,
			});
			this.props.clearUpdateModalData();
		};
		const handleChangeCloseModal = () => {
			this.setState({
				openModalToUpdateState: false,
				openSearchDialogue: false,
			});
		};
		const handleChangePage = (event, newPage) => {
			this.setState({
				page: newPage,
			});
			if (this.tableRef.current) {
				this.tableRef.current.scrollIntoView();
			}
		};
		const handleChangeRowsPerPage = event => {
			this.setState({
				rowsPerPage: parseInt(event.target.value, 10),
			});
			if (this.tableRef.current) {
				this.tableRef.current.scrollIntoView();
			}
		};
		const handleChangeOpenSearchDialogue = () => {
			this.setState({
				openSearchDialogue: true,
				// page: 0,
			});
			this.props.clearSnackBar();
			clearCheckboxFilterData();
		};
		const clearCheckboxFilterData = () => {
			this.setState({
				isAllMediumChecked: true,
				isAllTypeChecked: true,
				isMediumCheck: FilterMediumData.map(medium => medium.name),
				isTypeCheck: FilterTypeData.map(type => type.name),
			});
		};

		const handleUpdateStatus = () => {
			var mappedData = this.state.selected.map(function (elem) {
				if (transStatus === 'TRANSACTION_APPROVED') {
					return {
						transactionId: elem,
						transactionStatus: transStatus,
					};
				} else {
					return {
						transactionId: elem,
						transactionStatus: transStatus,
						remark: cancelledRemark,
					};
				}
			});
			if (mappedData.length !== 0) {
				const selectedData = {
					transaction: mappedData,
				};
				this.props.updateSubmitTransactionList(selectedData, this.props);
			} else {
				this.setState({
					openErrorSnackBar: true,
				});
			}
			this.setState({
				selected: [],
			});
		};
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
		});

		const loading = transactionState && transactionState.loading;

		return (
			<React.Fragment>
				<HelmetComponent title="Approve Transaction" />
				{transactionState && transactionState.loading ? (
					<div
						className={
							transactionState && transactionState.loading
								? 'loader'
								: 'loader hidden'
						}
						id="loader">
						<CircularProgress style={{ color: '#8BC83F' }} />
					</div>
				) : null}
				{transactionState && transactionState.success
					? this.setState(
							{
								openModalToUpdateState: false,
							},
							() => {
								this.props.setSubmitTransactionSuccessToFalse();
							}
					  )
					: null}
				<Breadcrumbs aria-label="breadcrumb" className="bc">
					<Link color="inherit" to="/dashboard" className="links">
						<HomeIcon style={{ color: '#8BC83F', paddingBottom: '2px' }} />
						Home
					</Link>
					<Link color="inherit" to="/transaction/list" className="links">
						Transaction
					</Link>
					<Typography color="inherit" className="link">
						Approve Transaction
					</Typography>
				</Breadcrumbs>
				<div style={{ margin: '80px 3% 2%' }}>
					<div className={'d-flex flex-row-reverse'} style={style.flex}>
						<div>
							<button
								// hidden
								style={{ marginLeft: '0px' }}
								className="filterButton"
								onClick={() => {
									this.props.fetchVendor();
									handleChangeOpenSearchDialogue();
								}}>
								<FilterListIcon style={{ color: '#fff', marginTop: '-2px' }} />
							</button>
						</div>
						<div>
							{this.state.selected &&
							this.state.selected.length > 0 &&
							this.state.clearSelect === false ? (
								<button
									className="printButton"
									style={{ width: '132px' }}
									onClick={e => {
										e.preventDefault();
										handleChangeOpenModal();
									}}>
									Change Status
								</button>
							) : (
								<button
									className="printButton"
									style={{ width: '132px', background: 'rgb(177, 214, 129)' }}
									disabled
									onClick={e => {
										e.preventDefault();
										handleChangeOpenModal();
									}}>
									Change Status
								</button>
							)}
						</div>
						<div className="search">
							<div style={style.searchIcon}>
								<SearchIcon style={{ color: '#9c9c9c' }} />
							</div>
							<InputBase
								placeholder="Search…"
								inputProps={{ 'aria-label': 'search' }}
								style={style.inputBase}
								onChange={e => {
									e.preventDefault();
									if (e.target.value === '' || e.target.value === ' ') {
										this.props.fetchCreatedTransactionList();
									} else {
										this.props.searchTransaction(e.target.value);
									}
								}}
							/>
						</div>
					</div>
					<div className={'row'} style={style.card}>
						<TableContainer className="fixed-table-header">
							<Table
								size="small"
								stickyHeader
								aria-label="sticky table"
								ref={this.tableRef}>
								<TableHead style={{ padding: '1rem' }}>
									<TableRow>
										<TableCell>
											<Checkbox
												indeterminate={
													this.state.selected &&
													this.state.selected.length > 0 &&
													sortedApprovedTransactionList &&
													this.state.selected &&
													this.state.selected.length <
														sortedApprovedTransactionList.length
												}
												checked={
													sortedApprovedTransactionList &&
													sortedApprovedTransactionList.length > 0 &&
													sortedApprovedTransactionList &&
													this.state.selected &&
													this.state.selected.length ===
														sortedApprovedTransactionList.length
												}
												onChange={handleSelectAllClick}
												inputProps={{ 'aria-label': 'select all transaction' }}
											/>
										</TableCell>
										<TableCell className="first-tableHeader">
											Transaction Date
										</TableCell>
										<TableCell className="tableHeaderAmount">Debits</TableCell>
										<TableCell className="tableHeaderAmount">Credits</TableCell>
										<TableCell className="tableHeaderAmount">Balance</TableCell>
										<TableCell className="tableHeader">Reference Id</TableCell>
										<TableCell className="tableHeader">User</TableCell>
										<TableCell className="tableHeader">Vendor</TableCell>
										<TableCell className="tableHeader">Medium</TableCell>
										<TableCell className="tableHeader">
											Transaction Type
										</TableCell>
										<TableCell className="tableHeader">
											Transaction Status
										</TableCell>
									</TableRow>
								</TableHead>
								{sortedApprovedTransactionList &&
									sortedApprovedTransactionList
										.slice(
											this.state.page * this.state.rowsPerPage,
											this.state.page * this.state.rowsPerPage +
												this.state.rowsPerPage
										)
										.map((list, index) => {
											const isItemSelected = isSelected(list.transactionId);
											const labelId = `enhanced-table-checkbox-${index}`;
											return (
												<TableBody key={list.transactionId}>
													<TableRow
														onClick={event =>
															handleClick(event, list.transactionId)
														}
														selected={isItemSelected}
														style={{ cursor: 'pointer' }}>
														<TableCell>
															<Checkbox
																checked={isItemSelected}
																inputProps={{ 'aria-labelledby': labelId }}
															/>
														</TableCell>
														<TableCell className="first-tableBody">
															{new Intl.DateTimeFormat('default', {
																year: 'numeric',
																month: '2-digit',
																day: '2-digit',
																hour: '2-digit',
																minute: '2-digit',
																second: '2-digit',
															}).format(list.createdAt ? list.createdAt : '-')}
														</TableCell>
														<TableCell className="tableAmountBody">
															{list.amount
																? list.amount.charAt(0) === '-'
																	? formatter
																			.format(list.amount / 100)
																			.substring(1)
																	: '-'
																: formatter.format(0)}
														</TableCell>
														<TableCell className="tableAmountBody">
															{list.amount
																? list.amount.charAt(0) === '-'
																	? '-'
																	: formatter.format(list.amount / 100)
																: formatter.format(0)}
														</TableCell>
														<TableCell className="tableAmountBody">
															{list.balance
																? formatter.format(list.balance / 100)
																: formatter.format(0)}
															{/*{formatter.format*/}
															{/*(runningAmount += Number(list.amount/100))}*/}
														</TableCell>
														<TableCell className="tableBody">
															{list.refId}
														</TableCell>
														<TableCell className="tableBody">
															{list.Client.lastName
																? list.Client.firstName +
																  ' ' +
																  list.Client.lastName
																: list.Client.firstName}
														</TableCell>
														<TableCell className="tableBody">
															{list.vendorAccount.vendorName
																? list.vendorAccount.vendorName
																: '-'}
														</TableCell>
														<TableCell className="tableBody">
															{list.transactionMedium.replace('_', ' ')}
														</TableCell>
														<TableCell className="tableBody">
															{list.transactionType.replace('_', ' ')}
														</TableCell>
														<TableCell
															style={{
																fontSize: '15px',
																color: '#626262',
																textAlign: 'left',
																paddingLeft: '18px',
																height: '4.5rem',
																cursor: 'auto',
															}}>
															<Tag
																style={style.colors}
																color={
																	list.transactionStatus ===
																	'TRANSACTION_CREATED'
																		? 'purple'
																		: list.transactionStatus ===
																		  'TRANSACTION_CANCELLED'
																		? 'red'
																		: 'lime'
																}>
																{list.transactionStatus.replace(
																	'TRANSACTION_',
																	' '
																)}
															</Tag>
														</TableCell>
													</TableRow>
												</TableBody>
											);
										})}
							</Table>
						</TableContainer>
						{sortedApprovedTransactionList &&
						sortedApprovedTransactionList.length > 0 ? (
							<div style={{ justifyContent: 'flex-start', width: '100%' }}>
								<TablePagination
									labelRowsPerPage={false}
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={
										sortedApprovedTransactionList.length > 0
											? sortedApprovedTransactionList.length
											: 100
									}
									rowsPerPage={this.state.rowsPerPage}
									page={this.state.page}
									backIconButtonProps={{
										'aria-label': 'previous page',
									}}
									nextIconButtonProps={{
										'aria-label': 'next page',
									}}
									onChangePage={handleChangePage}
									onChangeRowsPerPage={handleChangeRowsPerPage}
								/>
							</div>
						) : (
							<div style={{ justifyContent: 'center', width: '100%' }}>
								<h3
									style={{
										textAlign: 'center',
										fontSize: '14px',
										padding: '1rem',
										color: '#606060',
									}}>
									{' '}
									No data to show
								</h3>
							</div>
						)}
					</div>
				</div>
				<Dialog
					open={this.state.openModalToUpdateState}
					onClose={handleChangeCloseModal}
					PaperComponent={PaperComponent}
					aria-labelledby="#draggable-dialog-title"
					maxWidth="xl">
					<div>
						<DialogTitleComponent
							id="draggable-dialog-title"
							onClose={handleChangeCloseModal}>
							Update Status
						</DialogTitleComponent>
						<DialogContent dividers className="dialogContent">
							<div className="row">
								<div className="col-lg-12">
									<label className="label">
										Status <span style={{ color: 'red' }}> *</span>
									</label>
									<div className="select-input selects h40">
										<Select
											showSearch={false}
											getPopupContainer={node => node.parentNode}
											onChange={e => {
												this.setState({ value: e });
												this.props.handleChangeTransStatus(e);
											}}>
											<Option hidden />
											<Option value="TRANSACTION_APPROVED">Approve</Option>
											<Option value="TRANSACTION_CLOSED">Closed</Option>
											<Option value="TRANSACTION_ON_HOLD">On Hold</Option>
										</Select>
									</div>
								</div>
								{transStatus === 'TRANSACTION_CLOSED' ||
								transStatus === 'TRANSACTION_ON_HOLD' ? (
									<div className="col-lg-12">
										<label className="label">
											Remark <span style={{ color: 'red' }}> *</span>
										</label>
										<input
											type="text"
											id="remark"
											className="input"
											onChange={e => {
												e.preventDefault();
												this.props.handleChangeRemark(e.target.value);
											}}
										/>
									</div>
								) : null}
							</div>
							<div
								className="row"
								style={{
									padding: '1.5rem 0px 0.5rem',
								}}>
								<div className={'col-6'} />
								<div className={'col-6'} style={{ paddingLeft: '126px' }}>
									<ButtonComponent
										noValue
										buttonName="Update"
										loading={loading}
										onClick={e => {
											e.preventDefault();
											handleUpdateStatus();
											this.setState({
												selected: this.state.selected,
												clearSelect: true,
											});
										}}
									/>
								</div>
							</div>
						</DialogContent>
					</div>
				</Dialog>

				{/* filter dialog box */}
				<Dialog
					open={this.state.openSearchDialogue}
					maxWidth="lg"
					onClose={handleChangeCloseModal}
					PaperComponent={PaperComponent}
					aria-labelledby="#draggable-dialog-title">
					<DialogTitleComponent
						id="draggable-dialog-title"
						onClose={handleChangeCloseModal}>
						Filter
					</DialogTitleComponent>
					<DialogContent
						dividers
						style={{
							background: 'white',
							borderTopLeftRadius: '8px',
							borderTopRightRadius: '8px',
							paddingLeft: '1.5rem !important',
							paddingRight: '1.5rem !important',
							width: '450px',
							paddingTop: '5px',
						}}>
						<div className="row">
							<div className="col-lg-6">
								<label>Start Date</label>
								<div className="select-input">
									<KeyBoardDatePickerComponent
										dateValue={startDate && getFormattedDateValue(startDate)}
										// dateValue={null}
										isFilterFiled
										onChangeDate={data => {
											this.props.handleChangeDateFrom(
												new Date(data).setHours(0, 0, 0)
											);
										}}
									/>
									{/*<DatePicker*/}
									{/*    style={style.rangeBase}*/}
									{/*    value={startDate && getPickerDate(startDate)}*/}
									{/*    getPopupContainer={(trigger) => trigger.parentNode}*/}
									{/*    // onChange={handleChangeDateFrom}*/}
									{/*/>*/}
								</div>
							</div>
							<div className="col-lg-6">
								<label>End Date</label>
								<div className="select-input">
									<KeyBoardDatePickerComponent
										dateValue={endDate && getFormattedDateValue(endDate)}
										isFilterFiled
										onChangeDate={data => {
											this.props.handleChangeDateTo(
												new Date(data).setHours(0, 0, 0)
											);
										}}
									/>
									{/*<DatePicker*/}
									{/*    style={style.rangeBase}*/}
									{/*    value={endDate && getPickerDate(endDate)}*/}
									{/*    getPopupContainer={(trigger) => trigger.parentNode}*/}
									{/*    onChange={handleChangeDateTo}*/}
									{/*/>*/}
								</div>
							</div>
						</div>
						<div className="mt-4">
							<label> Medium </label>
							<CheckboxFilterPopover
								forMappingFilterData={FilterMediumData}
								isItemCheck={this.state.isMediumCheck}
								isAllCheck={this.state.isAllMediumChecked}
								handleAllCheckbox={this.handleAllMediumCheckbox}
								handleSelectedChange={this.handleSelectedMediumChange}
							/>
						</div>
						<div className="mt-4">
							<label> Type </label>
							<CheckboxFilterPopover
								forMappingFilterData={FilterTypeData}
								isItemCheck={this.state.isTypeCheck}
								isAllCheck={this.state.isAllTypeChecked}
								handleAllCheckbox={this.handleAllTypeCheckbox}
								handleSelectedChange={this.handleSelectedTypeChange}
							/>
						</div>
						<div className="mt-4">
							<label> Vendor </label>
							<div className="select-input h40">
								<Select
									showSearch
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									getPopupContainer={node => node.parentNode}
									style={{ width: 200 }}
									onChange={e => {
										this.props.handleChangeVendor(e);
									}}>
									{vendorList &&
										vendorList.map(list => (
											<Option value={list.vendorId}>{list.vendorName}</Option>
										))}
								</Select>
							</div>
						</div>
						<div>
							<div className="row">
								<div className="col-6" />
								<div className="col-6" style={{ paddingTop: '15px' }}>
									<ButtonComponent
										style={{ float: 'right' }}
										buttonName="Filter"
										noValue
										onClick={e => {
											e.preventDefault();

											// this.setState({
											//   page: 0,
											// // });
											this.props.searchTransactionByAll(
												transactionMediumHelper(this.state.isMediumCheck),
												'TRANSACTION_CREATED',
												transactionTypeHelper(this.state.isTypeCheck, 'type'),
												startDate,
												endDate,
												vendorToFilter
											);
											handleChangeCloseModal();
										}}>
										Filter
									</ButtonComponent>
								</div>
							</div>
						</div>
					</DialogContent>
				</Dialog>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={this.state.openErrorSnackBar}
					autoHideDuration={4000}
					onClose={() => {
						this.setState({
							openErrorSnackBar: false,
						});
					}}>
					<MySnackbarContentWrapper
						onClose={() => {
							this.setState({
								openErrorSnackBar: false,
							});
						}}
						variant="error"
						message="No data selected"
					/>
				</Snackbar>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={transactionState && transactionState.openUpdateErrorSnackBar}
					autoHideDuration={3000}
					onClose={this.props.handleTransactionSnackBarClose}>
					<MySnackbarContentWrapper
						onClose={this.props.handleTransactionSnackBarClose}
						variant="error"
						message={transactionState && transactionState.error}
					/>
				</Snackbar>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={transactionState && transactionState.openUpdateSuccessSnackBar}
					autoHideDuration={3000}
					onClose={this.props.handleTransactionSnackBarClose}>
					<MySnackbarContentWrapper
						onClose={this.props.handleTransactionSnackBarClose}
						variant="success"
						message={transactionState && transactionState.successMsg}
					/>
				</Snackbar>
			</React.Fragment>
		);
	}
}
const style = {
	card: {
		background: '#ffffff',
		boxShadow:
			'rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px',
		margin: '12px 0px 1rem',
	},
	searchIcon: {
		width: 7,
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: '2rem !important',
		marginLeft: '1rem',
	},
	filterButton: {
		border: 'none',
		background: '#8BC83F',
		color: 'white',
		padding: '4px',
		fontSize: '16px',
		borderRadius: '2px',
		width: '35px',
		height: '35px',
	},
	inputBase: {
		// paddingLeft:'2.3rem',
		width: '270px',
		// heigth: '26px'
	},
};

export default ApproveTransactionScreen;
