import React from 'react';
import {Popover} from "antd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const PopoverForMenu = ({content, onClick}) => {
    return(
      <Popover
          placement="bottom"
          trigger="click"
          content={content}
      >
          <MoreHorizIcon
              id="Popover1"
              style={{ cursor: "pointer" }}
              onClick={onClick}
          />
      </Popover>
    )
};

export default PopoverForMenu;