import React from "react";
import {Input, Select, Space} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import {getFormattedDateValue} from "../utils/DateConverter";
import KeyBoardDatePickerComponent from "../screens/DatePicker/DatePickerComponent";
const {Option} = Select;

const OpenContactFields = ({fieldArray,
                               filterContactData,
                               filterEditContactData,
                               isEdit,
                               handleOpenDeleteDialogue
}) => {
    const handleChangeNameField = (key, value) => {
        fieldArray.filter(single => single.contactId === key)[0].name = value;
        if(isEdit){
            filterEditContactData()
        }else{
            filterContactData()
        }
    };
    const handleChangeEmailField = (key, value) => {
        fieldArray.filter(single => single.contactId === key)[0].emailPhone = value;
        if(isEdit){
            filterEditContactData()
        }else{
            filterContactData()
        }
    };
    const handleChangeContactTypeField = (key, value) => {
        if(value === ""){
            fieldArray.filter(single => single.contactId === key)[0].contactType = "UNKNOWN_CONTACT_TYPE";
        }else{
            fieldArray.filter(single => single.contactId === key)[0].contactType = value;
        }
        if(isEdit){
            filterEditContactData()
        }else{
            filterContactData()
        }
    };
    const handleChangePhoneField = (key, value) => {
        fieldArray.filter(single => single.contactId === key)[0].phone = value;
        if(isEdit){
            filterEditContactData()
        }else{
            filterContactData()
        }
    };
    const handleChangeDobField = (key, value) => {
        if(value === ""){
            fieldArray.filter(single => single.contactId === key)[0].dob = 0;
        }else{
            fieldArray.filter(single => single.contactId === key)[0].dob = value;
        }
        if(isEdit){
            filterEditContactData()
        }else{
            filterContactData()
        }
    };
    const getDateValue = (dateToConvert) => {
        let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
        return moment(dateConverted, "YYYY-MM-DD");
    };
    let div = [];
    // Outer loop to create parent
    if (fieldArray.length > 0) {
        div.push(<>
            <h5 className="openFieldTitle">List of contacts</h5>
            <Space
                style={{ display: "flex" }}
                align="baseline"
            >
                <div style={{width: "143px"}}>
                    <label htmlFor="name">Name
                        <span style={{color: "red"}} > *</span></label>
                </div>
                {/*150*/}
                <div style={{width: "142px"}}>
                    <label htmlFor="emailPhone">Email
                        <span style={{color: "red"}}> *</span>
                    </label>
                </div>
                {/*156*/}
                <div style={{width: "162px"}}>
                    <label htmlFor="contactType">Contact Type
                        <span style={{color: "red"}}> *</span>
                    </label>
                </div>
                <div style={{width: "140px"}}>
                    <label htmlFor="phone">Phone Number</label>
                </div>
                <div>
                    <label htmlFor="phone">Date-of-birth</label>
                </div>
            </Space>
        </>)
    }

    fieldArray && fieldArray[0].contactId &&
    fieldArray.map(single => {
        return div.push(
            <Space
                key={single.contactId}
                style={{ display: "flex", marginBottom: 8 }}
                align="baseline"
            >
                <div id={single.contactId} >
                    <Input
                        value={single.name}
                        autoComplete="off"
                        className="inputField"
                        name="firstName"
                        onChange={(e) => {
                            e.preventDefault();
                            handleChangeNameField(single.contactId, e.target.value);
                            // handleValidationOnChange(e, errors)
                        }}
                    />
                    {/*{errors.firstName.length > 0 &&*/}
                    {/*<span className='error' style={{width: "158px"}}>{errors.firstName}</span>}*/}
                </div>
                <div>
                    <Input
                        className="inputField"
                        value={single.emailPhone}
                        autoComplete="off"
                        onChange={(e) => {
                            e.preventDefault();
                            handleChangeEmailField(single.contactId, e.target.value);
                        }}
                    />
                </div>
                <div>
                    <div style={{width: "160px"}}>
                         <div className="select-input selects h40">
                            <Select
                                value={single.contactType === "UNKNOWN_CONTACT_TYPE" ? "" : single.contactType}
                                getPopupContainer={node => node.parentNode}
                                onChange={e => {
                                    handleChangeContactTypeField(single.contactId, e)
                                }}
                            >
                                <Option value="UNKNOWN_CONTACT_TYPE" hidden/>
                                <Option value="EVERFUND_USER_CONTACT">Everfund user</Option>
                                <Option value="CORPORATE_CONTACT">Corporate</Option>
                                <Option value="CLIENT_CONTACT">Client</Option>
                                <Option value="CLIENT_RELATIVE_CONTACT">Client Relative</Option>
                                <Option value="OTHER_CONTACT_TYPE">Other contact type</Option>
                            </Select>
                         </div>
                    </div>
                </div>
                <div>
                    <Input
                        className="inputField"
                        value={single.phone}
                        autoComplete="off"
                        onChange={(e) => {
                            e.preventDefault();
                            handleChangePhoneField(single.contactId, e.target.value);
                        }}
                    />
                </div>
                <div>
                    <div className="select-add-date">
                        <KeyBoardDatePickerComponent
                            marginTop
                            dateValue={single.dob && getFormattedDateValue(single.dob)}
                            onChangeDate={(data) => {handleChangeDobField(single.contactId, data);}}
                        />
                    </div>
                </div>
                <div >
                    <MinusCircleOutlined
                        onClick={e => {
                            e.preventDefault();
                            handleOpenDeleteDialogue(single.contactId);
                        }}
                    />
                </div>
            </Space>
        );
    });
    return div;

};

export default OpenContactFields;

