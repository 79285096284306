import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import '../styles/styles.css';
import PrintIcon from "@material-ui/icons/Print";
import {
    Button,
    CircularProgress,
    DialogActions,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MySnackbarContentWrapper from "../components/Snackbar";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import {DatePicker, Select} from "antd";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers from "../components/Helper";
import {HelmetComponent} from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";

const {Option} = Select;

class PrintShippingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            openModalToAdd:false,
            openModalToUpdate:false,
            openModalToDelete:false,
            openDetailModal: false,
            openTrackShipping : false,
            openTrackShippingWarningModal: false,
            page: 0,
            rowsPerPage: 10,
            shippingLabel: {},
            errors:{
                emailPhone:"",
                shippingDate:"",
                weightLb: "",
                description:"",
                fromName:"",
                fromFirm: "",
                fromAddressId: "",
                toName:"",
                toFirm: "",
                toAddressId: "",
            }
        }
    }
    componentDidMount() {
        this.props.fetchShippingLabelList();
        this.props.fetchShippingList();
    }

    render() {
        const shippingLabel = this.state.shippingLabel;
        const shippingState = this.props.state && this.props.state.shippingState;
        const shippingAddressList = shippingState && shippingState.shippingAddressList;
        const shippingLabelData = shippingState && shippingState.shippingLabelData;
        const shippingLabels = shippingState && shippingState.shippingLabels;
        const loading = shippingState && shippingState.loading;
        const shippingLabelValue = shippingLabelData.description !== "" &&
        shippingLabelData.email !== "" &&
        shippingLabelData.fromAddressId !== "" &&
        shippingLabelData.fromFirm !== "" &&
        shippingLabelData.fromName !== "" &&
        shippingLabelData.shipDate !== "" &&
        shippingLabelData.toAddressId !== "" &&
        shippingLabelData.toFirm !== "" &&
        shippingLabelData.toName !== "" &&
        shippingLabelData.weightLb !== "" ;

        const trackShipments = shippingState && shippingState.trackShipments;
        const error = shippingState && shippingState.error;
        const showWarning = shippingState && shippingState.showWarning;

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        }
        const getDate = (dateToConvert) => {
            return new Date(parseInt(dateToConvert)).toLocaleDateString();
        }
        const handleOpenModalToPrint = () =>{
            this.setState({
                openModalToAdd: true,
                errors:{
                    emailPhone:"",
                    shippingDate:"",
                    weightLb: "",
                    description:"",
                    fromName:"",
                    fromFirm: "",
                    fromAddressId: "",
                    toName:"",
                    toFirm: "",
                    toAddressId: "",
                }
            },()=>{
                this.props.setSettingLabelToEmpty();
            })
        }
        const handleCloseModalToPrint = () =>{
            this.setState({
                openModalToAdd: false,
            })
        }

        const handleOpenDetailModal = () =>{
            this.setState({
                openDetailModal: true,
            })
        }
        const handleCloseDetailModal= () =>{
            this.setState({
                openDetailModal: false,
            })
        }

        const handleOpenTrackShipWarningModal = () =>{
            this.setState({
                openTrackShippingWarningModal: true,
            })
        }
        const handleCloseTrackShipWarningModal= () =>{
            this.setState({
                openTrackShippingWarningModal: false,
            })
        }

        const handleOpenTrackShippingDetailModal = () =>{
            this.setState({
                openTrackShipping: true,
            })
        }
        const handleCloseTrackShippingDetailModal = () =>{
            this.setState({
                openTrackShipping: false,
            },()=>{
                this.props.handleClearTrackingReport();
            })
        }

        const openDetail =  (list) => {
            handleOpenDetailModal();
            this.setState({
                shippingLabel: list,
            })
        }

        const handleChangeDate = (range) => {
            if(range) {
                const valueOfInput1 = range.format();
                const eventValue = {
                    target : {
                        name : "shippingDate",
                        value: new Date(valueOfInput1).getTime()
                    }
                }
                this.props.handleChangeShippingValue("shipDate", new Date(valueOfInput1).getTime());
                Helpers.handleValidationOnChange(eventValue, errors);
            }
        }
        const {errors} = this.state;
        return(
            <React.Fragment>
                <HelmetComponent title="Print Shipping"/>
                {shippingState && shippingState.loading ? (
                    <div
                        className={
                            shippingState && shippingState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                {shippingState && shippingState.success === true ? this.setState({
                    openModalToAdd: false,
                }, ()=>{
                    this.props.setAddShippingLabelToFalse();
                }): null}
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to='/address'  className="links">
                        Address
                    </Link>
                    <Typography color="inherit" className="link">
                        Shipping Label
                    </Typography>
                </Breadcrumbs>
                <div style={{margin: "80px 3% 2%"}}>
                    <div className={"d-flex flex-row-reverse"} style={style.flex}>
                        <div>
                            <button className="printButton"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenModalToPrint();
                                    }}>
                                <PrintIcon   style={{
                                    width: '26px',
                                    height: '30px',
                                    marginRight: '7px',
                                    marginBottom: '4px'
                                }}/>
                                Print Shipping Label
                            </button>
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            From Address
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            To Address
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Name
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Date
                                        </TableCell>
                                        <TableCell className="tableHeader" onClick={()=>{console.log("clicked")}}>
                                            Shipping Description
                                        </TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                {shippingLabels && shippingLabels.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                ).map(list => (
                                <TableBody key={list.shippingLabelId}>
                                    <TableRow className="cp">
                                        <TableCell className="first-tableBody" style={{width: "20%"}} onClick={()=>{openDetail(list)}}>
                                            {list.fromAddress && list.fromAddress.address2 }
                                        </TableCell>
                                        <TableCell className="tableBody" style={{width: "20%"}} onClick={()=>{openDetail(list)}}>
                                            {list.toAddress && list.toAddress.address2}
                                        </TableCell>
                                        <TableCell className="tableBody" style={{width: "10%"}} onClick={()=>{openDetail(list)}}>
                                            {list.toName}
                                        </TableCell>
                                        <TableCell className="tableBody" style={{width: "8%"}} onClick={()=>{openDetail(list)}}>
                                            { list.createdDate && getDate(list.createdDate)}
                                        </TableCell>
                                        <TableCell className="tableBody" style={{width: "20%"}} onClick={()=>{openDetail(list)}}>
                                            {list.description}
                                        </TableCell>
                                        <TableCell
                                            // style={{textDecoration: "underline"}}
                                            onClick={()=>{
                                                this.props.trackShippingInfo(list.shippingLabelId)
                                                if( trackShipments && trackShipments[0]){
                                                    handleOpenTrackShippingDetailModal();
                                                }else{
                                                    handleOpenTrackShipWarningModal();
                                                }
                                            }}>
                                            <Button
                                            style={{
                                                border: "1px solid #8bc34a87",
                                                background: "white",
                                                color: "#8BC34A",
                                                height: "28px",
                                                fontSize: "13px",
                                                borderRadius:" 2px",
                                                textTransform: "capitalize",
                                            }}
                                            >
                                                Track Shipping
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>))}
                            </Table>
                            {shippingLabels && shippingLabels.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            shippingLabels.length > 0
                                                ? shippingLabels.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        </TableContainer>
                    </div>
                </div>
                {/*shipping label form*/}
                <Dialog
                    open={this.state.openModalToAdd}
                    onClose={handleCloseModalToPrint}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseModalToPrint}
                        >
                            Shipping Label Form
                        </DialogTitleComponent>
                        <DialogContent dividers style={{paddingTop: "5px"}} className="printShippingDialogContent">
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">Email <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='email'
                                        id="email"
                                        name="emailPhone"
                                        autoComplete='off'
                                        className="inputField"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                            Helpers.handleValidationOnChange(e, errors);
                                        }}
                                    />
                                    {errors.emailPhone.length > 0 && <span className="error">{errors.emailPhone}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="label">Date <span style={{color:"red"}}> *</span></label>
                                    <div className="select-modal-date">
                                        <DatePicker
                                            className="dateBase"
                                            name="shippingDate"
                                            getPopupContainer={trigger => trigger.parentNode}
                                            onChange={handleChangeDate}
                                        />
                                        {errors.shippingDate.length > 0 && <span className="error">{errors.shippingDate}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label className="label">Weight <span style={{color:"red"}}> *</span> </label>
                                    <input
                                        type='number'
                                        id="weightLb"
                                        name="weightLb"
                                        className="inputField"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                            Helpers.handleValidationOnChange(e, errors);
                                        }}
                                    />
                                    {errors.weightLb.length > 0 && <span className="error">{errors.weightLb}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="label">Description <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        autoComplete='off'
                                        id="description"
                                        name="description"
                                        className="inputField"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                            Helpers.handleValidationOnChange(e, errors);
                                        }}
                                    />
                                    {errors.description.length > 0 && <span className="error">{errors.description}</span>}
                                </div>
                            </div>
                            <div className="upperTitle">From:</div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <label className="label">Name <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        autoComplete='off'
                                        id="fromName"
                                        name="fromName"
                                        className="inputField"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                            Helpers.handleValidationOnChange(e, errors);
                                        }}
                                    />
                                    {errors.fromName.length > 0 && <span className="error">{errors.fromName}</span>}
                                </div>
                                <div  className="col-lg-6">
                                    <label className="label">Address <span style={{color:"red"}}> *</span></label>
                                    <div className="select-input selects h40">
                                        <Select
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            name="fromAddressId"
                                            onChange={(e) => {
                                                // Helpers.handleValidationOnChange(e, errors);
                                                this.props.handleChangeShippingValue("fromAddressId",e)
                                            }}

                                        >
                                            {
                                                shippingAddressList && shippingAddressList.map(list => (
                                                    <Option value={list.addressId}>{list.address2}</Option>
                                                ))
                                            }
                                        </Select>
                                        {errors.fromAddressId.length > 0 && <span className="error">{errors.fromAddressId}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label className="label">Firm <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="fromFirm"
                                        name="fromFirm"
                                        autoComplete='off'
                                        className="inputField"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                    {errors.fromFirm.length > 0 && <span className="error">{errors.fromFirm}</span>}
                                </div>
                            </div>
                            <div className="upperTitle">To:</div>
                            <div className="row">

                                <div className="col-lg-6">
                                    <label className="label">Name <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        id="toName"
                                        autoComplete='off'
                                        className="inputField"
                                        name="toName"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                        }}
                                    />
                                    {errors.toName.length > 0 && <span className="error">{errors.toName}</span>}
                                </div>
                                <div  className="col-lg-6">
                                    <label className="label">Address <span style={{color:"red"}}> *</span></label>
                                    <div className="select-input selects h40">
                                        <Select
                                            name="toAddressId"
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                // Helpers.handleValidationOnChange(e, errors);
                                                this.props.handleChangeShippingValue("toAddressId", e)
                                            }}
                                        >
                                            {
                                                shippingAddressList && shippingAddressList.map(list => (
                                                    <Option value={list.addressId}>{list.address2}</Option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    {errors.toAddressId.length > 0 && <span className="error">{errors.toAddressId}</span>}
                                </div>
                                <div className="col-lg-6">
                                    <label className="label">Firm <span style={{color:"red"}}> *</span></label>
                                    <input
                                        type='text'
                                        autoComplete='off'
                                        id="toFirm"
                                        name="toFirm"
                                        className="inputField"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.props.handleChangeShippingValue(e.target.id,e.target.value);
                                            Helpers.handleValidationOnChange(e, errors);
                                        }}
                                    />
                                    {errors.toFirm.length > 0 && <span className="error">{errors.toFirm}</span>}
                                </div>
                            </div>

                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingLeft: "220px"}}>
                                    <ButtonComponent
                                        loading={loading}
                                        value={shippingLabelValue}
                                        onClick={e => {
                                            e.preventDefault();
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.printShippingLabel(shippingLabelData, this.props);
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        buttonName="Print"
                                   />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*details*/}
                <Dialog
                    id='viewCheckDetails'
                    maxWidth="lg"
                    open={this.state.openDetailModal}
                    onClose={handleCloseDetailModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitleComponent
                        id="draggable-dialog-title"
                        onClose={handleCloseDetailModal}>
                        Shipping Label Details
                    </DialogTitleComponent>
                    <DialogContent
                        dividers
                        style={{
                            background: 'white',
                            textAlign: 'left',
                            // marginLeft: '1rem'
                        }}>
                        <DialogContentText>
                            <h4 style={{color: "#8BC83F"}}>From</h4>
                                <span className="row span-row">
                                    <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">Address 1</label>
                                        <label className="data">
                                            {shippingLabel && shippingLabel.fromAddress
                                            && shippingLabel.fromAddress.address1}
                                        </label>
                                    </span>
                                      <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">Address 2</label>
                                        <label className="data">
                                            {shippingLabel && shippingLabel.fromAddress
                                            && shippingLabel.fromAddress.address2}
                                        </label>
                                    </span>
                                         <span className="col-4" >
                                        <label htmlFor="caseId" className="columnHeading">City</label>
                                         <label  className="data">
                                            {shippingLabel && shippingLabel.fromAddress
                                            && shippingLabel.fromAddress.city}
                                         </label>
                                    </span>
                                </span>
                                <span className="row span-row">
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">State</label>
                                    <label className="data">
                                       {shippingLabel && shippingLabel.fromAddress
                                       && shippingLabel.fromAddress.state}
                                    </label>
                                </span>
                                {/*<span className="col-4" >*/}
                                {/*    <label htmlFor="caseId" className="columnHeading">Zip4</label>*/}
                                {/*     <label  className="data">*/}
                                {/*         {shippingLabel && shippingLabel.fromAddress*/}
                                {/*         && shippingLabel.fromAddress.zip4}*/}
                                {/*     </label>*/}
                                {/*</span>*/}
                                <span className="col-4">
                                    <label htmlFor="firstName" className="columnHeading">Zip5</label>
                                    <label className="data">
                                      {shippingLabel && shippingLabel.fromAddress
                                      && shippingLabel.fromAddress.zip5}
                                    </label>
                                </span>
                            </span>
                            <h4 style={{color: "#8BC83F", marginTop: "12px"}}>To</h4>
                                <span className="row span-row">
                                    <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">Address 1</label>
                                        <label className="data">
                                            {shippingLabel && shippingLabel.toAddress
                                            && shippingLabel.toAddress.address1}
                                        </label>
                                    </span>
                                      <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">Address 2</label>
                                        <label className="data">
                                            {shippingLabel && shippingLabel.toAddress
                                            && shippingLabel.toAddress.address2}
                                        </label>
                                    </span>
                                         <span className="col-4" >
                                        <label htmlFor="caseId" className="columnHeading">City</label>
                                         <label  className="data">
                                            {shippingLabel && shippingLabel.toAddress
                                            && shippingLabel.toAddress.city}
                                         </label>
                                    </span>
                                </span>
                                <span className="row span-row mt-3">
                                    <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">State</label>
                                        <label className="data">
                                           {shippingLabel && shippingLabel.toAddress
                                           && shippingLabel.toAddress.state}
                                        </label>
                                    </span>
                                    {/*<span className="col-4" >*/}
                                    {/*    <label htmlFor="caseId" className="columnHeading">Zip4</label>*/}
                                    {/*     <label  className="data">*/}
                                    {/*         {shippingLabel && shippingLabel.toAddress*/}
                                    {/*         && shippingLabel.toAddress.zip4}*/}
                                    {/*     </label>*/}
                                    {/*</span>*/}
                                    <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">Zip5</label>
                                        <label className="data">
                                          {shippingLabel && shippingLabel.toAddress
                                          && shippingLabel.toAddress.zip5}
                                        </label>
                                    </span>
                                   <span className="col-4">
                                        <label htmlFor="firstName" className="columnHeading">Name</label>
                                        <label className="data">
                                           {shippingLabel
                                               && shippingLabel.toName}
                                        </label>
                                   </span>
                                </span>
                                <span className="row span-row mt-3">
                                <span className="col-4" >
                                    <label htmlFor="caseId" className="columnHeading">Firm</label>
                                     <label  className="data">
                                         {shippingLabel && shippingLabel.toFirm}
                                     </label>
                                </span>
                            </span>
                            <h4 style={{color: "#8BC83F",marginTop: "12px"}}>Other</h4>
                                <span className="row span-row">
                                <span className="col-4" >
                                    <label htmlFor="caseId" className="columnHeading">Created Date</label>
                                     <label  className="data">
                                         {shippingLabel && getDate(shippingLabel.createdDate)}
                                     </label>
                                </span>
                                  <span className="col-4" >
                                    <label htmlFor="caseId" className="columnHeading">Description</label>
                                     <label  className="data" style={style.extraData}>
                                         {shippingLabel && shippingLabel.description}
                                     </label>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/*tracking details*/}
                <Dialog
                    id='viewCheckDetails'
                    maxWidth="lg"
                    open={this.state.openTrackShipping}
                    onClose={handleCloseTrackShippingDetailModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitleComponent
                        id="draggable-dialog-title"
                        onClose={handleCloseTrackShippingDetailModal}>
                        Tracked Shipment Detail
                    </DialogTitleComponent>
                    <DialogContent
                        dividers
                        style={{
                            background: 'white',
                            textAlign: 'left',
                            minHeight: "82px",
                        }}>
                        <DialogContentText>
                            {
                                trackShipments && trackShipments[0] ?
                                    <div>
                                        <span className="row span-row mt-2">
                                            <span className="col-4">
                                                <label htmlFor="firstName" className="columnHeading">Event</label>
                                                <label className="data">
                                                    {trackShipments && trackShipments[0].event}
                                                </label>
                                            </span>
                                              <span className="col-4">
                                                <label htmlFor="firstName" className="columnHeading">Tracking Event Type</label>
                                                <label className="data">
                                                    {trackShipments && trackShipments[0].trackingEventType}
                                                </label>
                                            </span>
                                            <span className="col-4">
                                                <label htmlFor="firstName" className="columnHeading">Tracking Number</label>
                                                <label className="data">
                                                    {trackShipments && trackShipments[0].trackingNumber}
                                                </label>
                                            </span>
                                        </span>
                                        <span className="row span-row mt-4">
                                             <span className="col-4" >
                                                <label htmlFor="caseId" className="columnHeading">Country</label>
                                                 <label  className="data">
                                                     {trackShipments && trackShipments[0].country}
                                                 </label>
                                            </span>
                                             <span className="col-4">
                                                <label htmlFor="firstName" className="columnHeading">Zip</label>
                                                <label className="data">
                                                    {trackShipments && trackShipments[0].zip}
                                                </label>
                                            </span>
                                             <span className="col-4">
                                                <label htmlFor="firstName" className="columnHeading">Date</label>
                                                <label className="data">
                                                    {trackShipments && getDate(trackShipments[0].timestamp)}
                                                </label>
                                            </span>
                                        </span>
                                    </div>:
                                    <div style={{display: "flex"}}>
                                        {
                                            showWarning ?
                                            <div>
                                                <img
                                                    alt="warning"
                                                    style={{width: "36px",
                                                    marginTop: "12px",
                                                    marginRight: "14px",}} src="/assets/images/warning.png"/>
                                            </div>
                                            : null
                                        }
                                        <div style={{marginTop: "5px", marginLeft: "5px", width: "280px"}}>{error}</div>
                                    </div>
                            }
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/*tracking error*/}
                <Dialog
                    id='viewCheckDetails'
                    maxWidth="lg"
                    open={this.state.openTrackShippingWarningModal}
                    onClose={handleCloseTrackShipWarningModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitleComponent
                        id="draggable-dialog-title"
                        onClose={handleCloseTrackShipWarningModal}>
                    </DialogTitleComponent>
                    <DialogContent
                        dividers
                        style={{
                            background: 'white',
                            textAlign: 'left',
                            minHeight: "82px",
                            borderTop: "none",
                            width:"462px"
                        }}>
                        <DialogContentText>
                                <div>
                                    <div>
                                        <img
                                            alt="warning"
                                            style={{marginLeft: "166px",marginBottom: "12px"}}
                                            src="/assets/images/warning.svg"/>
                                    </div>
                                    <div className="warningMsg">{error}</div>
                                </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{padding: "10px 0px 10px 0px"}}>
                        <Button
                            onClick={e => {
                                e.preventDefault();
                                handleCloseTrackShipWarningModal();
                            }}
                            className="addButton"
                            style={{margin: "auto"}}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={shippingState && shippingState.openShippingErrorSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={shippingState && shippingState.error}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={shippingState && shippingState.openShippingSuccessSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="success"
                        message={shippingState && shippingState.successMessage}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
}
const style = {
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        margin:"12px 0px 1rem",
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '4px',
        paddingLeft: '15px'
    },
    icon: {
        position: 'absolute',
        top: '34px',
        right: '25px',
        color: '#C8C5C5',
        fontSize: '32px',
    },
    extraData: {
        color: '#625F5F',
        marginBottom: '0px',
        fontSize: '16px',
        textAlign: 'left !important',
        marginTop: '10px',
        fontWeight: '550',
        whiteSpace: "nowrap",
        width: "180px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }
}
export default PrintShippingScreen;