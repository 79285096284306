import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import { Prompt } from "react-router";
import CheckIcon from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";
import "../styles/styles.css";
import PrintIcon from "@material-ui/icons/Print";
import Typography from "@material-ui/core/Typography";
import { DatePicker, Radio } from "antd";
import { Select } from "antd";
import moment from "moment";
import currencies from "../currencies";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DynamicActionConditionalForm from "./DynamicActionConditionalForm";
import DialogueForLogInfo from "../components/DialogueForLogInfo";
import { HelmetComponent } from "../components/Helmet";
import {finalAmount} from "../utils/currencyConvertor";
const { Option } = Select;

class DynamicActionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocking: false,
      action: "",
      selectedFile: [],
      selectedFileForDoc: [],
      assigned: false,
      selectImage: "",
      image: "",
      pdf: true,
      text: false,
      openModalForConditionalData: false,
      caseId: props.location.pathname
        .replace("/action/data/", "")
        .substring(
          0,
          props.location.pathname.replace("/action/data/", "").indexOf("/")
        ),
      workflowId: props.location.pathname
        .replace("/action/data/", "")
        .slice(
          props.location.pathname
            .replace("/action/data/", "")
            .substring(
              0,
              props.location.pathname.replace("/action/data/", "").indexOf("/")
            ).length + 1,
          -props.location.pathname.substring(
            props.location.pathname.length,
            props.location.pathname.lastIndexOf("/") + 1
          ).length - 1
        ),
      actionId: props.location.pathname.substring(
        props.location.pathname.length,
        props.location.pathname.lastIndexOf("/") + 1
      ),
      actionFormInfo: [],
      conditionalFormInfo: [],
      openDialogueForDone: false,
      openDialogueForSendEmail: false,
      openSnackBar: false,
      selected: [],
      checkList: [],
      optionsArr: [],
      optionsData: {},
      selectedContactId: "",
      showDropdown: false,
      isChecked: "",
      singleActionFormId: "",
      optionValueName: "",
      activeActionFormId: "",
      activeRefId: "",
      conditionalCaseData: [],
      openModalForLogInfo: false,
      singleActionState: {},
    };
    const caseId = this.state.caseId;
    const workflowId = this.state.workflowId;
    const actionId = this.state.actionId;
    console.log({ caseId, workflowId, actionId });
    if (props.location.pathname.replace("/action/data/", "").length > 0) {
      this.props.getActionInfo(
        this.state.caseId,
        this.state.workflowId,
        this.state.actionId
      );
    }
    this.props.getCaseInfo(this.state.caseId);
  }
  componentDidMount() {
    this.props.fetchContactList();
    this.props.handleCloseSnackBar();
  }
  componentWillUnmount() {
    this.props.setValueNull();
    this.props.handleSnackBarClose();
    this.props.setConditionalFormDataToEmpty();
  }

  render() {
    const dynamicActionState = this.props.state.dynamicActionState;
    const selectedCheckboxOptions =
      dynamicActionState && dynamicActionState.selectedCheckboxOptions;
    const successMessageForConditional =
      dynamicActionState && dynamicActionState.successMessageForConditional;
    const isSubmitted = dynamicActionState && dynamicActionState.isSubmitted;
    const contactState = this.props.state && this.props.state.contactState;
    const contactsList = contactState && contactState.contactList;
    const setPrevious = dynamicActionState && dynamicActionState.setPrevious;
    const emailPhone = dynamicActionState && dynamicActionState.emailPhone;
    const profileState =
      this.props.state.loginState.user &&
      this.props.state.loginState.user.userId;
    const caseState =
      this.props.state.caseState.caseData &&
      this.props.state.caseState.caseData.workflow &&
      this.props.state.caseState.caseData.workflow.filter(
        (single) => single.workflowId === this.state.workflowId
      )[0];
    const userRole =
      this.props.state.loginState.user &&
      this.props.state.loginState.user.userRole;
    const accountType =
      this.props.state.loginState.user &&
      this.props.state.loginState.user.accountType;
    const permission = !!(
      (caseState &&
        caseState.workflowAgents &&
        caseState.workflowAgents[0].user &&
        caseState.workflowAgents[0].user.userId === profileState) ||
      userRole === "SUPER_ADMIN" ||
      userRole === "ADMIN" ||
      accountType === "EVERFUND_CONTACT"
    );
    const value = emailPhone && emailPhone !== "";

    const handleChangeContactValue = (id, event) => {
      this.setState(
        {
          selectedContactId: event,
        },
        () => {
          const selectedContact =
            contactsList &&
            contactsList.filter(
              (list) => list.contactId === this.state.selectedContactId
            );
          this.props.handleChangeContactValue(id, selectedContact, event);
        }
      );
    };
    const handleChangeConditionalContactValue = (
      conditionalFormId,
      contactData,
      selectedContact,
      refId
    ) => {
      this.props.handleChangeConditionalContactValue(
        conditionalFormId,
        contactData,
        selectedContact,
        refId
      );
    };
    const getDate = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };
    const generateLink = (caseId, workFlowId, actionId, email) => {
      this.props.generateLink(caseId, workFlowId, actionId, email, this.props);
    };
    const onChangeImageFileHandler = (
      eventFileHandler,
      actionFormId,
      fileName
    ) => {
      let image =
        eventFileHandler &&
        eventFileHandler.target &&
        eventFileHandler.target.files[0];
      if (
        (image && image.type === "image/png") ||
        (image && image.type === "image/jpeg") ||
        (image && image.type === "image/jpg")
      ) {
        let type = image.type;
        let singleFile = {
          image: image,
          type: type,
        };
        {
          this.setState(
            {
              isBlocking: true,
              selectedFile: [...this.state.selectedFile, singleFile],
            },
            () => {
              this.props.uploadImageFile(
                singleFile,
                actionFormId,
                fileName,
                this.props
              );
            }
          );
        }
      } else {
        this.setState({
          openSnackBar: true,
        });
      }
    };
    const onChangeDocumentFileHandler = (
      eventFileHandler,
      actionFormId,
      fileName
    ) => {
      let file =
        eventFileHandler &&
        eventFileHandler.target &&
        eventFileHandler.target.files[0];
      // if (file && file.type === "application/pdf"
      //     || file && file.type === "text/plain") {
      let type = file && file.type;
      let singleFile = {
        document: file,
        type: type,
        fileName: fileName,
      };
      {
        this.setState(
          {
            isBlocking: true,
          },
          () => {
            this.props.uploadDocumentFile(singleFile, actionFormId, this.props);
          }
        );
      }
      // } else {
      //     this.setState({
      //         openSnackBar: true,
      //     })
      // }
    };

    const handleClickOpenDialogueForDone = () => {
      this.setState({
        openDialogueForDone: true,
      });
    };
    const handleCloseDialogueForDone = () => {
      this.setState({
        openDialogueForDone: false,
      });
    };
    const handleClickOpenDialogueToGenerate = () => {
      this.setState({
        openDialogueForSendEmail: true,
      });
    };
    const handleClickCloseDialogueToGenerate = () => {
      this.setState({
        openDialogueForSendEmail: false,
      });
    };
    const handleOkDialogueForDone = () => {
      this.props.markAsDoneDynamicActionData(
        this.state.caseId,
        this.state.workflowId,
        this.state.actionId,
        3,
        this.props
      );
      this.setState({
        openDialogueForDone: false,
      });
    };

    const handleSetSelectedOption = (conditionalFormId, data) => {
      this.props.handleSetSelectedOption(conditionalFormId, data);
    };
    const searchIndividualContact = (value) => {
      this.props.searchIndividualContact(value);
    };
    const exportActionData = () => {
      this.props.exportActionData(
        this.state.caseId,
        this.state.workflowId,
        this.state.actionId,
        this.props
      );
    };
    const validateFields = (actionFormDataArray) => {
      if (dynamicActionState.previousActionData === true) {
        this.props.editDynamicActionData(
          this.state.caseId,
          this.state.workflowId,
          this.state.actionId,
          actionFormDataArray,
          this.props
        );
      } else {
        if (this.props.state.dynamicActionState.conditionalFormDataRequest) {
          this.props.addDynamicActionData(
            this.state.caseId,
            this.state.workflowId,
            this.state.actionId,
            actionFormDataArray,
            this.props.state.dynamicActionState.conditionalFormDataRequest,
            this.props
          );
        } else {
          this.props.addDynamicActionData(
            this.state.caseId,
            this.state.workflowId,
            this.state.actionId,
            actionFormDataArray,
            this.props
          );
        }
      }
    };

    const setConditionalFormSuccessMessage = (bool) => {
      this.props.setConditionalFormSuccessMessage(bool);
    };

    function printMultipleDiv() {
      var divsToPrint = document.getElementsByClassName("printArea");
      var printContents = "";
      for (var n = 0; n < divsToPrint.length; n++) {
        printContents += divsToPrint[n].innerHTML + "<br>";
      }
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }

    window.onafterprint = () => {
      window.location.reload(true);
    };

    const isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    // CONDITIONAL FORM DATA
    const dynamicConditionFormsData =
      dynamicActionState && dynamicActionState.dynamicConditionFormsData;
    const conditionalFormData =
      dynamicActionState && dynamicActionState.conditionalFormData;
    const conditionalFormSuccess =
      dynamicActionState && dynamicActionState.conditionalFormSuccess;
    const conditionalFormSuccessForFirst =
      dynamicActionState && dynamicActionState.conditionalFormSuccessForFirst;

    // CONDITIONAL FORM FUNCTIONS    //HANDLE FOR CONDITIONAL DATA
    const handleOpenModalFormConditionalData = () => {
      this.setState({
        openModalForConditionalData: true,
      });
    };
    const handleCloseModalFormConditionalData = () => {
      this.setState({
        openModalForConditionalData: false,
      });
    };
    const changeActiveValue = (actionFormId, refId, optionValueName) => {
      this.setState({
        activeActionFormId: actionFormId,
        activeRefId: refId,
        optionValueName: optionValueName,
      });
    };
    const handleChangeIds = (optionName, optionRefId) => {
      this.setState({
        optionValueName: optionName,
        activeRefId: optionRefId,
      });
    };
    const handleClick = (event, option) => {
      const selectedIndex = this.state.selected.indexOf(option);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, option);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selected: newSelected,
      });
    };
    const handleChangeConditionalFileValue = (
      value,
      refId,
      conditionalFormId
    ) => {
      this.props.handleChangeConditionalFileValue(
        value,
        refId,
        conditionalFormId
      );
    };
    const onChangeImageFileHandlerInConditional = (
      eventFileHandler,
      conditionalFormId,
      refId,
      fileName
    ) => {
      let image = eventFileHandler.target.files[0];
      let type = image.type;
      let singleFile = {
        image: image,
        type: type,
      };
      {
        this.setState(
          {
            isBlocking: true,
          },
          () => {
            this.props.uploadConditionalImageFile(
              singleFile,
              conditionalFormId,
              refId,
              fileName,
              this.props
            );
          }
        );
      }
    };
    const onChangeDocumentFileHandlerInConditional = (
      eventFileHandler,
      conditionalFormId,
      refId,
      fileName
    ) => {
      let file = eventFileHandler.target.files[0];
      let type = file && file.type;
      let singleFile = {
        document: file,
        type: type,
        fileName: fileName,
      };
      {
        this.setState(
          {
            isBlocking: true,
          },
          () => {
            this.props.uploadConditionalDocumentFile(
              singleFile,
              conditionalFormId,
              refId,
              fileName,
              this.props
            );
          }
        );
      }
    };
    const createObjectForConditionalData = (
      conditionalFormId,
      value,
      conditionalFormDataId,
      type
    ) => {
      let previousValue = dynamicActionState.dynamicConditionFormsData.filter(
        (single) => single.conditionalFormId !== conditionalFormId
      );
      if (conditionalFormDataId === "" || conditionalFormDataId === undefined) {
        let data = {
          conditionalFormId: conditionalFormId,
          data: value,
        };
        previousValue.push(data);
        return previousValue;
      } else {
        let data = {
          conditionalFormDataId: conditionalFormDataId,
          conditionalFormId: conditionalFormId,
          data: value,
        };
        previousValue.push(data);
        return previousValue;
      }
    };
    const changeConditionalFormValues = (
      conditionalFormId,
      value,
      refId,
      conditionalFormDataId,
      type
    ) => {
      let a = createObjectForConditionalData(
        conditionalFormId,
        value,
        conditionalFormDataId,
        type
      );
      this.setState({ conditionalFormInfo: a }, () => {
        this.props.handleChangeConditionalActionData(
          this.state.conditionalFormInfo,
          value,
          refId,
          conditionalFormId
        );
      });
    };
    const setConditionalFormDataRequest = (
      conditionalFormData,
      setPrevious
    ) => {
      this.props.setConditionalFormDataRequest(
        conditionalFormData,
        setPrevious
      );
    };
    const updateConditionalDataForCase = (conditionalFormData) => {
      this.props.updateConditionalDataForCase(conditionalFormData, this.props);
    };
    const getConditionalDataForCase = (
      refId,
      actionFormId,
      step,
      prevActionFormId,
      prevRefId,
      prevOptionName
    ) => {
      this.props.getConditionalDataForCase(
        this.state.caseId,
        this.state.workflowId,
        refId,
        actionFormId,
        step,
        prevActionFormId,
        prevRefId,
        prevOptionName
      );
    };
    const handleChangeCheckBoxValueForConditionalForm = (
      conditionalFormId,
      isSelected,
      data,
      refId,
      parentRefId,
      parentActionFormId,
      conditionalFormDataIdList
    ) => {
      this.props.handleChangeCheckBoxValueForConditionalForm(
        conditionalFormId,
        isSelected,
        data,
        refId,
        parentRefId,
        parentActionFormId,
        conditionalFormDataIdList
      );
    };

    const activityState = this.props.state && this.props.state.activityLogState;
    const activityLogValue = activityState && activityState.activityLogValue;

    const fetchActivityLogById = (id, workflowId, caseId) => {
      this.props.fetchActivityLogById(id, workflowId, caseId);
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Case" />
        {this.props.state.dynamicActionState.loading ||
        (activityState && activityState.loading) ? (
          <div
            className={
              this.props.state.userState.loading ||
              (activityState && activityState.loading)
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page?"
        />
        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/case" style={style.link}>
            Case
          </Link>
          <Link
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push({
                pathname: "/case/update/" + this.state.caseId,
              });
            }}
            style={style.link}
          >
            Case Detail
          </Link>
          <Typography color="inherit" className="link">
            Action Data
          </Typography>
        </Breadcrumbs>
        <div style={style.container}>
          {dynamicActionState && dynamicActionState.actionInfo ? (
            <div className="row">
              <div className="col-5">
                <div className="printArea">
                  <label style={style.labelTitle} className="labelAction">
                    <b>
                      {dynamicActionState.actionInfo &&
                        dynamicActionState.actionInfo.actionName}
                    </b>
                  </label>
                </div>
                {(permission &&
                  dynamicActionState.previousActionData === true) ||
                (permission &&
                  dynamicActionState.actionInfo.actionForms === undefined) ? (
                  <button
                    style={
                      dynamicActionState.actionInfo &&
                      dynamicActionState.actionInfo.status !== "COMPLETED"
                        ? style.doneButton
                        : style.markButton
                    }
                    disabled={
                      dynamicActionState.actionInfo &&
                      dynamicActionState.actionInfo.status === "COMPLETED"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: false });
                      handleClickOpenDialogueForDone();
                    }}
                  >
                    <Tooltip
                      title={
                        dynamicActionState.actionInfo &&
                        dynamicActionState.actionInfo.status === "COMPLETED"
                          ? "This action has already been marked as done"
                          : "Mark as done"
                      }
                    >
                      <CheckIcon />
                    </Tooltip>{" "}
                    Done
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="row" style={{ paddingTop: "0.1rem" }}></div>
          {dynamicActionState &&
            dynamicActionState.actionInfo &&
            dynamicActionState.actionInfo.actionForms &&
            dynamicActionState.actionInfo.actionForms.map((singleAction) => {
              return (
                <>
                  <div className="row printArea footer">
                    <div
                      className={"col-5 printableArea"}
                      style={{ marginBottom: "14px" }}
                    >
                      <div style={style.label} className="outerFormDiv">
                        <label style={style.fieldLabel} className="labelDiv">
                          {singleAction.fieldName}{" "}
                          <span
                            hidden={singleAction.required !== true}
                            style={{
                              fontSize: "20px",
                              color: "red",
                              paddingLeft: "3px",
                            }}
                          >
                            *
                          </span>
                        </label>
                        {singleAction.content !== undefined ? (
                          <></>
                        ) : (
                          <span
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={() => {
                              this.setState(
                                {
                                  singleActionState: singleAction,
                                },
                                () => {
                                  this.props.fetchActivityLogById(
                                    singleAction.actionFormId,
                                    this.state.workflowId,
                                    this.state.caseId
                                  );
                                }
                              );
                            }}
                          >
                            <img
                              style={{ width: "15px", cursor: "pointer" }}
                              src="/assets/images/logInfo.svg"
                              alt="logInfo"
                            />
                          </span>
                        )}
                      </div>
                      {singleAction.fieldType === "PHOTO" ? (
                        permission ? (
                          <div
                            className="fileClass"
                            style={{ minWidth: "20px !important" }}
                          >
                            {singleAction.actionFormData ? (
                              <div>
                                <div className="extra">
                                  <img
                                    className="dataImage"
                                    src={
                                      singleAction.actionFormData &&
                                      singleAction.actionFormData[0].data
                                    }
                                    style={{ minWidth: "100px !important" }}
                                  />
                                </div>
                                <div>
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className="fileNameInput fileNameWidth outerFormDiv"
                                    id={singleAction.actionFormId}
                                    value={
                                      singleAction.actionFormData &&
                                      singleAction.actionFormData[0].fileName
                                    }
                                    placeholder="Image Name"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.setState({ isBlocking: true });
                                      this.props.handleChangeFileName(
                                        singleAction.actionFormId,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div
                              style={{ width: "100%" }}
                              className="non-printable"
                            >
                              <label
                                style={{
                                  width: "115px",
                                  marginTop: "0.5rem",
                                  marginBottom: "1.5rem",
                                  background: "rgba(204,204,254,0.32)",
                                  border: "1px solid #7b7b7b",
                                  textAlign: "center",
                                  padding: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  id={singleAction.actionFormId}
                                  type="file"
                                  required={singleAction.required === true}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({ isBlocking: true });
                                    onChangeImageFileHandler(
                                      e,
                                      singleAction.actionFormId,
                                      singleAction.actionFormData &&
                                        singleAction.actionFormData[0].fileName
                                    );
                                  }}
                                  className="hidden"
                                  style={{ display: "none" }}
                                />
                                Select file
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData.data ? (
                              <img
                                className="dataImage"
                                src={singleAction.actionFormData.data}
                                style={{ minWidth: "100px !important" }}
                              />
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </div>
                        )
                      ) : singleAction.fieldType === "DOCUMENT" ? (
                        permission ? (
                          <div>
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              <div className="fileClass">
                                {singleAction.actionFormData[0].data
                                  .split(".")
                                  .pop() === "pdf" ? (
                                  <div className="extra">
                                    <img
                                      src="/assets/images/pdf.svg"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  </div>
                                ) : singleAction.actionFormData[0].data
                                    .split(".")
                                    .pop() === "txt" ? (
                                  <div className="extra">
                                    <img
                                      src="/assets/images/text_logo.png"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  </div>
                                ) : singleAction.actionFormData[0].data
                                    .split(".")
                                    .pop() === "csv" ? (
                                  <div className="extra">
                                    <img
                                      src="/assets/images/csv.png"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="extra">
                                    <img
                                      src="/assets/images/fileIcon.png"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  </div>
                                )}
                                <div>
                                  <input
                                    type="text"
                                    autoComplete="off"
                                    id={singleAction.actionFormId}
                                    value={
                                      singleAction.actionFormData &&
                                      singleAction.actionFormData[0].fileName
                                    }
                                    className="fileNameInput fileNameWidth outerFormDiv"
                                    placeholder="Document Name"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleChangeFileName(
                                        singleAction.actionFormId,
                                        e.target.value
                                      );
                                      this.setState({ isBlocking: true });
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div
                              style={{ width: "100%" }}
                              className="non-printable"
                            >
                              <label
                                style={{
                                  width: "115px",
                                  marginTop: "0.5rem",
                                  marginBottom: "1.5rem",
                                  background: "rgba(204,204,254,0.32)",
                                  border: "1px solid #7b7b7b",
                                  textAlign: "center",
                                  padding: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                <input
                                  id={singleAction.actionFormId}
                                  type="file"
                                  required={singleAction.required === true}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({ isBlocking: true });
                                    onChangeDocumentFileHandler(
                                      e,
                                      singleAction.actionFormId,
                                      singleAction.actionFormData &&
                                        singleAction.actionFormData[0].fileName
                                    );
                                  }}
                                  className="hidden"
                                  style={{ display: "none" }}
                                />
                                Select file
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData.data ? (
                              <>
                                {singleAction.actionFormData.data
                                  .split(".")
                                  .pop() === "pdf" ? (
                                  <img
                                    src="/assets/images/pdf.svg"
                                    alt="pdf"
                                    style={{
                                      width: "40px",
                                      marginRight: "12px",
                                    }}
                                  />
                                ) : singleAction.actionFormData.data
                                    .split(".")
                                    .pop() === "txt" ? (
                                  <img
                                    src="/assets/images/text_logo.png"
                                    alt="pdf"
                                    style={{
                                      width: "40px",
                                      marginRight: "12px",
                                    }}
                                  />
                                ) : singleAction.actionFormData.data
                                    .split(".")
                                    .pop() === "csv" ? (
                                  <img
                                    src="/assets/images/csv.png"
                                    alt="pdf"
                                    style={{
                                      width: "40px",
                                      marginRight: "12px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/fileIcon.png"
                                    alt="pdf"
                                    style={{
                                      width: "40px",
                                      marginRight: "12px",
                                    }}
                                  />
                                )}
                                <p>
                                  {singleAction.actionFormData.data
                                    ? singleAction.actionFormData.data.replace(
                                        "https://storage.googleapis.com/" +
                                          "everfund_resources/",
                                        ""
                                      )
                                    : null}
                                </p>
                              </>
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </div>
                        )
                      ) : singleAction.fieldType === "TEXT_FIELD" ? (
                        permission ? (
                          <>
                            <input
                              type="text"
                              autoComplete="off"
                              required={singleAction.required === true}
                              id={singleAction.actionFormId}
                              value={
                                singleAction.actionFormData &&
                                singleAction.actionFormData[0].data
                              }
                              onChange={(e) => {
                                e.preventDefault();
                                this.setState({ isBlocking: true });
                                this.props.handleChangeActionInputData(
                                  e.target.value,
                                  singleAction.actionFormId
                                );
                              }}
                              className="fileNameInput outerFormDiv mb"
                              style={style.inputField}
                            />
                          </>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              singleAction.actionFormData[0].data
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "CONTENT" ? (
                        permission ? (
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="content-div"
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                  color: "#434343",
                                  fontSize: "14px",
                                }}
                              >
                                {singleAction.content}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction && singleAction.content ? (
                              singleAction.content
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "AMOUNT" ? (
                        permission ? (
                          <div className="row">
                            <div className="col-12">
                              <input
                                type="number"
                                autoComplete="off"
                                required={singleAction.required === true}
                                id={singleAction.actionFormId}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data / 100
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({ isBlocking: true });
                                  this.props.handleChangeActionInputData(
                                    finalAmount(e),
                                    singleAction.actionFormId
                                  );
                                }}
                                className="fileNameInput outerFormDiv mb"
                                style={style.inputField}
                              />
                            </div>
                          </div>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              singleAction.actionFormData[0].data
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "DOB" ? (
                        permission ? (
                          <>
                            <div
                              className="select-dob mb-3"
                              onClick={() => {
                                this.setState({ isBlocking: true });
                              }}
                            >
                              <DatePicker
                                className="outerFormDiv mb"
                                required={singleAction.required === true}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data &&
                                  getDate(singleAction.actionFormData[0].data)
                                }
                                format="MM-DD-YYYY"
                                onChange={(event) => {
                                  if (event) {
                                    const valueOfInput1 = event.format();
                                    this.props.handleChangeActionInputData(
                                      new Date(valueOfInput1).getTime(),
                                      singleAction.actionFormId
                                    );
                                  }
                                }}
                                onPanelChange={(event) => {
                                  if (event) {
                                    const valueOfInput1 = event.format();
                                    this.props.handleChangeActionInputData(
                                      new Date(valueOfInput1).getTime(),
                                      singleAction.actionFormId
                                    );
                                  }
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              singleAction.actionFormData[0].data
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "SSN" ? (
                        permission ? (
                          <>
                            <input
                              type="number"
                              required={singleAction.required === true}
                              id={singleAction.actionFormId}
                              value={
                                singleAction.actionFormData &&
                                singleAction.actionFormData[0].data
                              }
                              onChange={(e) => {
                                e.preventDefault();
                                this.setState({ isBlocking: true });
                                this.props.handleChangeActionInputData(
                                  e.target.value,
                                  singleAction.actionFormId
                                );
                              }}
                              className="fileNameInput outerFormDiv mb"
                              style={style.inputField}
                            />
                          </>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              singleAction.actionFormData[0].data
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "PHONE" ? (
                        permission ? (
                          <>
                            <input
                              autoComplete="off"
                              id={singleAction.actionFormId}
                              value={
                                singleAction.actionFormData &&
                                singleAction.actionFormData[0].data
                              }
                              className="fileNameInput outerFormDiv styled mb"
                              required={singleAction.required === true}
                              onChange={(e) => {
                                this.props.handleChangeActionInputData(
                                  e.target.value,
                                  singleAction.actionFormId
                                );
                                e.preventDefault();
                                this.setState({ isBlocking: true });
                              }}
                              style={style.inputField}
                            />
                          </>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              singleAction.actionFormData[0].data
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "RADIO_BUTTON" ? (
                        permission ? (
                          <div
                            style={{ marginBottom: "12px", marginTop: "10px" }}
                            className="print-radio-button"
                          >
                            <Radio.Group
                              id={singleAction.actionFormId}
                              value={
                                singleAction.actionFormData &&
                                singleAction.actionFormData[0].data
                              }
                              required={singleAction.required === true}
                              onChange={(e) => {
                                e.preventDefault();
                                this.setState({ isBlocking: true });
                                this.props.handleChangeActionInputData(
                                  e.target.value,
                                  singleAction.actionFormId
                                );
                              }}
                            >
                              {singleAction.optionValue &&
                                singleAction.optionValue.map((option) => (
                                  <Radio
                                    className="print-radio-button mb"
                                    style={{ fontSize: "18px" }}
                                    value={option.optionValueId}
                                  >
                                    <span style={{ fontSize: "16px" }}>
                                      {option.optionValueName}
                                    </span>
                                  </Radio>
                                ))}
                            </Radio.Group>
                          </div>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.optionValue &&
                            singleAction.optionValue.filter(
                              (option) => option.isSelected
                            ).length > 0 ? (
                              singleAction.optionValue.filter(
                                (option) => option.isSelected
                              )[0].optionValueName
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "CHECKBOX" ? (
                        permission ? (
                          <div className="print-checkbox">
                            <ul
                              style={{
                                listStyleType: "none",
                                paddingLeft: "5px",
                              }}
                            >
                              {singleAction.optionValue &&
                                singleAction.optionValue.map(
                                  (option, index) => {
                                    const isItemSelected = isSelected(option);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                      <li>
                                        <Checkbox
                                          required={
                                            singleAction.required === true
                                          }
                                          onChange={(event) => {
                                            this.setState(
                                              {
                                                isChecked: event.target.checked,
                                                optionValueName:
                                                  option.optionValueName,
                                                activeActionFormId:
                                                  singleAction.actionFormId,
                                                activeRefId: option.refId,
                                              },
                                              () => {
                                                if (this.state.isChecked) {
                                                  getConditionalDataForCase(
                                                    this.state.activeRefId,
                                                    singleAction.actionFormId,
                                                    "firstStep",
                                                    "",
                                                    "",
                                                    ""
                                                  );
                                                }
                                                this.props.handleChangeCheckedValue(
                                                  singleAction.actionFormId,
                                                  this.state.isChecked,
                                                  option.optionValueId,
                                                  singleAction,
                                                  option
                                                );
                                              }
                                            );
                                          }}
                                          selected={isItemSelected}
                                          value={
                                            singleAction.actionFormData &&
                                            singleAction.actionFormData.map(
                                              (action) => action.data
                                            )
                                          }
                                          checked={!!option.isSelected}
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                        />
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            color: "#434343",
                                          }}
                                        >
                                          {option.optionValueName}
                                        </span>
                                        {option.isSelected ? (
                                          <span
                                            className="non-printable"
                                            onClick={() => {
                                              handleOpenModalFormConditionalData();
                                              this.setState(
                                                {
                                                  optionValueName:
                                                    option.optionValueName,
                                                  activeActionFormId:
                                                    singleAction.actionFormId,
                                                  activeRefId: option.refId,
                                                },
                                                () => {
                                                  getConditionalDataForCase(
                                                    this.state.activeRefId,
                                                    singleAction.actionFormId,
                                                    "firstStep",
                                                    "",
                                                    "",
                                                    ""
                                                  );
                                                }
                                              );
                                            }}
                                            hidden={!option.isConditionalForm}
                                          >
                                            <EditOutlinedIcon
                                              className="non-printable"
                                              style={{
                                                paddingLeft: "12px",
                                                width: "30px",
                                                paddingBottom: "4px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </span>
                                        ) : null}
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </div>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.optionValue &&
                            singleAction.optionValue.filter(
                              (option) => option.isSelected
                            ).length > 0 ? (
                              singleAction.optionValue
                                .filter((option) => option.isSelected)
                                .map((filterOption) => (
                                  <div> - {filterOption.optionValueName}</div>
                                ))
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "CONTACT" ? (
                        permission ? (
                          <div className="main-div-contact">
                            <div className="select-input h40">
                              <Select
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data
                                }
                                onChange={(e) => {
                                  handleChangeContactValue(
                                    singleAction.actionFormId,
                                    e
                                  );
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onSearch={(value) => {
                                  this.props.searchIndividualContact(value);
                                }}
                              >
                                {contactsList &&
                                  contactsList.map((list) => (
                                    <Option value={list.contactId}>
                                      {list.name}
                                    </Option>
                                  ))}
                              </Select>
                            </div>
                            <div className="row mt-3">
                              <div className="col-6">
                                <label>Email</label>
                                <div>
                                  <input
                                    disabled
                                    id={singleAction.actionFormId}
                                    value={
                                      singleAction.contact &&
                                      singleAction.contact.emailPhone
                                    }
                                    className="fileNameInput"
                                    style={style.inputField}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <label>Phone</label>
                                <input
                                  disabled
                                  id={singleAction.actionFormId}
                                  value={
                                    singleAction.contact &&
                                    singleAction.contact.phone
                                  }
                                  className="fileNameInput"
                                  style={style.inputField}
                                />
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-6" hidden>
                                <label>Address</label>
                                <div>
                                  <input
                                    disabled
                                    id={singleAction.actionFormId}
                                    value={
                                      singleAction.contact &&
                                      singleAction.contact.address
                                    }
                                    className="fileNameInput"
                                    style={style.inputField}
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <label>Contact Type</label>
                                <div>
                                  <input
                                    disabled
                                    id={singleAction.actionFormId}
                                    value={
                                      singleAction.contact &&
                                      singleAction.contact.contactType
                                        .replace("_", " ")
                                        .replace("_", " ")
                                    }
                                    className="fileNameInput"
                                    style={style.inputField}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <label
                              style={{
                                fontWeight: 550,
                                color: "#343434",
                                paddingBottom: "1rem",
                              }}
                            >
                              {singleAction.contact ? (
                                <>
                                  <div className="row">
                                    <div className="col-6">
                                      <label>Name:</label>
                                      <br />
                                      {singleAction.contact.name}
                                    </div>
                                    <div className="col-6">
                                      <label>Phone:</label>
                                      <br />
                                      {singleAction.contact.phone}
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-6">
                                      <label>Email:</label>
                                      <br />
                                      {singleAction.contact.emailPhone}
                                    </div>
                                    <div className="col-6">
                                      <label>Contact Type:</label>
                                      <br />
                                      {singleAction.contact.contactType}
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <label>Address:</label>
                                      <br />
                                      {singleAction.contact.emailPhone}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p style={{ color: "#a8a8a8" }}>
                                  (Data not available)
                                </p>
                              )}
                            </label>
                          </>
                        )
                      ) : singleAction.fieldType === "DROPDOWN" ? (
                        permission ? (
                          <div className="selects h40 mb-3">
                            <Select
                              className="outerFormDiv mb"
                              required={singleAction.required === true}
                              id={singleAction.actionFormId}
                              showSearch={false}
                              value={
                                singleAction.actionFormData &&
                                singleAction.actionFormData[0].data
                              }
                              getPopupContainer={(node) => node.parentNode}
                              onChange={(e) => {
                                this.props.handleChangeActionInputData(
                                  e,
                                  singleAction.actionFormId
                                );
                                this.setState({ isBlocking: true });
                              }}
                            >
                              {singleAction.optionValue &&
                                singleAction.optionValue.map((option) => (
                                  <Option value={option.optionValueId}>
                                    {option.optionValueName}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.optionValue &&
                            singleAction.optionValue.filter(
                              (option) => option.isSelected
                            ).length > 0 ? (
                              singleAction.optionValue.filter(
                                (option) => option.isSelected
                              )[0].optionValueName
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : singleAction.fieldType === "CURRENCY" ? (
                        permission ? (
                          <div className="selects h40 mb-3">
                            <Select
                              className="outerFormDiv mb"
                              required={singleAction.required === true}
                              id={singleAction.actionFormId}
                              showSearch={false}
                              value={
                                singleAction.actionFormData &&
                                singleAction.actionFormData[0].data
                              }
                              getPopupContainer={(node) => node.parentNode}
                              onChange={(e) => {
                                this.setState({ isBlocking: true });
                                this.props.handleChangeSelectedValue(
                                  singleAction.actionFormId,
                                  e
                                );
                              }}
                            >
                              {currencies &&
                                currencies.map((option) => (
                                  <Option value={option.code}>
                                    {option.code} ( {option.symbol} )
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        ) : (
                          <label
                            style={{
                              fontWeight: 550,
                              color: "#343434",
                              paddingBottom: "1rem",
                            }}
                          >
                            {singleAction.actionFormData &&
                            singleAction.actionFormData[0].data ? (
                              singleAction.actionFormData[0].data
                            ) : (
                              <p style={{ color: "#a8a8a8" }}>
                                (Data not available)
                              </p>
                            )}
                          </label>
                        )
                      ) : permission ? (
                        <input
                          type="email"
                          required={singleAction.required === true}
                          value={
                            singleAction.actionFormData
                              ? dynamicActionState.previousValue === false
                                ? this.props.setPreviousTrue(true)
                                  ? singleAction.actionFormData[0].data
                                  : null
                                : null
                              : null
                          }
                          id={singleAction.actionFormId}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ isBlocking: true });
                            this.props.handleChangeSelectedValue(
                              singleAction.actionFormId,
                              e.target.value
                            );
                          }}
                          className="fileNameInput outerFormDiv mb"
                          style={style.inputField}
                        />
                      ) : (
                        <label
                          style={{
                            fontWeight: 550,
                            color: "#343434",
                            paddingBottom: "1rem",
                          }}
                        >
                          {singleAction.actionFormData &&
                          singleAction.actionFormData.data ? (
                            singleAction.actionFormData.data
                          ) : (
                            <p style={{ color: "#a8a8a8" }}>
                              (Data not available)
                            </p>
                          )}
                        </label>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          <div
            className="row"
            style={{ marginRight: "0rem", marginTop: "2rem" }}
          >
            <div className="col-5">
              {permission &&
              dynamicActionState &&
              dynamicActionState.actionInfo &&
              dynamicActionState.actionInfo.actionForms ? (
                <>
                  <div>
                    <button
                      style={style.printButton}
                      onClick={(e) => {
                        const actionInfo =
                          dynamicActionState && dynamicActionState.actionInfo;
                        console.log("actionInfo in screen", actionInfo);
                        const filteredActionFormData =
                          actionInfo &&
                          actionInfo.actionForms &&
                          actionInfo.actionForms.filter(
                            (action) => action.actionFormData
                          );
                        let actionFormDataArray = [];
                        filteredActionFormData &&
                          filteredActionFormData.map((form) => {
                            form.actionFormData &&
                              form.actionFormData.length > 0 &&
                              form.actionFormData.map((formData) => {
                                if (formData.fileName) {
                                  if (formData.actionFormDataId) {
                                    let actionObject = {
                                      actionFormDataId:
                                        formData.actionFormDataId
                                          ? formData.actionFormDataId
                                          : "",
                                      data: formData.data,
                                      actionFormId: form.actionFormId,
                                      fileName: formData.fileName,
                                    };
                                    actionFormDataArray.push(actionObject);
                                  } else {
                                    let actionObject = {
                                      data: formData.data,
                                      actionFormId: form.actionFormId,
                                      fileName: formData.fileName,
                                    };
                                    actionFormDataArray.push(actionObject);
                                  }
                                } else {
                                  if (formData.actionFormDataId) {
                                    let actionObject = {
                                      actionFormDataId:
                                        formData.actionFormDataId,
                                      data: formData.data,
                                      actionFormId: form.actionFormId,
                                    };
                                    actionFormDataArray.push(actionObject);
                                  } else {
                                    let actionObject = {
                                      data: formData.data,
                                      actionFormId: form.actionFormId,
                                    };
                                    actionFormDataArray.push(actionObject);
                                  }
                                }
                              });
                          });
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        validateFields(actionFormDataArray);
                      }}
                    >
                      {" "}
                      {dynamicActionState.previousActionData === true
                        ? "Update"
                        : "Submit"}
                    </button>
                  </div>
                  <button
                    hidden
                    onClick={() => {
                      const actionInfo =
                        dynamicActionState && dynamicActionState.actionInfo;
                      console.log("actionInfo in screen", actionInfo);
                      const filteredActionFormData =
                        actionInfo &&
                        actionInfo.actionForms &&
                        actionInfo.actionForms.filter(
                          (action) => action.actionFormData
                        );
                      console.log(
                        "filteredActionFormData",
                        filteredActionFormData
                      );
                      let actionFormDataArray = [];
                      filteredActionFormData &&
                        filteredActionFormData.map((form) => {
                          form.actionFormData &&
                            form.actionFormData.length > 0 &&
                            form.actionFormData.map((formData) => {
                              if (formData.fileName) {
                                if (formData.actionFormDataId) {
                                  let actionObject = {
                                    actionFormDataId: formData.actionFormDataId
                                      ? formData.actionFormDataId
                                      : "",
                                    data: formData.data,
                                    actionFormId: form.actionFormId,
                                    fileName: formData.fileName,
                                  };
                                  actionFormDataArray.push(actionObject);
                                } else {
                                  let actionObject = {
                                    data: formData.data,
                                    actionFormId: form.actionFormId,
                                    fileName: formData.fileName,
                                  };
                                  actionFormDataArray.push(actionObject);
                                }
                              } else {
                                if (formData.actionFormDataId) {
                                  let actionObject = {
                                    actionFormDataId: formData.actionFormDataId,
                                    data: formData.data,
                                    actionFormId: form.actionFormId,
                                  };
                                  actionFormDataArray.push(actionObject);
                                } else {
                                  let actionObject = {
                                    data: formData.data,
                                    actionFormId: form.actionFormId,
                                  };
                                  actionFormDataArray.push(actionObject);
                                }
                              }
                            });
                        });
                      console.log("actionFormDataArray", actionFormDataArray);
                      //
                      // this.props.addDynamicActionData(this.state.caseId, this.state.workflowId,
                      //     this.state.actionId,actionFormDataArray, this.props);
                    }}
                  >
                    Save
                  </button>
                </>
              ) : null}
              {permission &&
              dynamicActionState &&
              dynamicActionState.actionInfo &&
              dynamicActionState.actionInfo.actionForms ? (
                <>
                  <div>
                    <button
                      style={style.button}
                      onClick={() => {
                        handleClickOpenDialogueToGenerate();
                      }}
                    >
                      Request Client
                    </button>
                  </div>
                  <div>
                    <button
                      style={style.printButton}
                      onClick={(e) => {
                        e.preventDefault();
                        exportActionData();
                      }}
                    >
                      <img
                        src="/assets/images/export.svg"
                        style={{
                          width: "26px",
                          height: "18px",
                          marginRight: "7px",
                          marginBottom: "4px",
                        }}
                      />
                      Export
                    </button>
                  </div>
                  <div>
                    <button
                      style={style.printButton}
                      onClick={() => {
                        printMultipleDiv();
                      }}
                    >
                      <PrintIcon
                        style={{
                          width: "22px",
                          height: "27px",
                          marginRight: "7px",
                          marginBottom: "4px",
                        }}
                      />
                      Print
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <DialogueForLogInfo
          activityState={activityState}
          handleCloseSnackBar={this.props.handleCloseSnackBar}
          activityLogValue={activityLogValue}
          singleActionState={this.state.singleActionState}
        />

        {/* ************ CONDITIONAL FORM MODAL************ */}
        <Dialog
          open={
            this.state.openModalForConditionalData &&
            conditionalFormSuccessForFirst
          }
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
          PaperComponent={PaperComponent}
          onClose={handleCloseModalFormConditionalData}
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseModalFormConditionalData}
            >
              {this.state.optionValueName}
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                width: "700px",
                maxHeight: "500px",
                padding: "12px 2px",
              }}
            >
              {conditionalFormData &&
                conditionalFormData.length > 0 &&
                conditionalFormData.map(
                  (item) =>
                    item.actionFormId === this.state.activeActionFormId &&
                    item.refId === this.state.activeRefId && (
                      <div>
                        <DynamicActionConditionalForm
                          item={item}
                          contactsList={contactsList}
                          setPrevious={setPrevious}
                          handleChangeIds={handleChangeIds}
                          searchIndividualContact={searchIndividualContact}
                          updateConditionalDataForCase={
                            updateConditionalDataForCase
                          }
                          getConditionalDataForCase={getConditionalDataForCase}
                          activeActionFormId={this.state.activeActionFormId}
                          activeRefId={this.state.activeRefId}
                          activeOptionName={this.state.optionValueName}
                          caseId={this.state.caseId}
                          workflowId={this.state.workflowId}
                          conditionalFormSuccess={conditionalFormSuccess}
                          changeActiveValue={changeActiveValue}
                          changeConditionalFormValues={
                            changeConditionalFormValues
                          }
                          dynamicConditionFormsData={dynamicConditionFormsData}
                          setConditionalFormDataRequest={
                            setConditionalFormDataRequest
                          }
                          handleCloseModalFormConditionalData={
                            handleCloseModalFormConditionalData
                          }
                          handleChangeCheckBoxValueForConditional={
                            handleChangeCheckBoxValueForConditionalForm
                          }
                          onChangeImageFileHandlerInConditional={
                            onChangeImageFileHandlerInConditional
                          }
                          onChangeDocumentFileHandlerInConditional={
                            onChangeDocumentFileHandlerInConditional
                          }
                          conditionalFormData={
                            this.props.state.dynamicActionState
                              .conditionalFormData
                          }
                          isSubmitted={isSubmitted}
                          successMessageForConditional={
                            successMessageForConditional
                          }
                          setConditionalFormSuccessMessage={
                            setConditionalFormSuccessMessage
                          }
                          handleClick={handleClick}
                          selectedCheckboxOptions={selectedCheckboxOptions}
                          handleSetSelectedOption={handleSetSelectedOption}
                          handleChangeConditionalFileValue={
                            handleChangeConditionalFileValue
                          }
                          fetchContactList={this.props.fetchContactList}
                          handleChangeConditionalContactValue={
                            handleChangeConditionalContactValue
                          }
                          previousActionData={
                            dynamicActionState.previousActionData
                          }
                          activityState={activityState}
                          handleCloseSnackBar={this.props.handleCloseSnackBar}
                          activityLogValue={activityLogValue}
                          fetchActivityLogById={fetchActivityLogById}
                          // singleActionState={this.state.singleActionState}
                          // setConditionalFormDataToEmpty={this.props.setConditionalFormDataToEmpty}
                        />
                      </div>
                    )
                )}
            </DialogContent>
          </div>
        </Dialog>

        {/* ********** DONE CONFORMATION MODAL ************ */}
        <Dialog
          open={this.state.openDialogueForDone}
          onClose={handleCloseDialogueForDone}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            {/*<DialogTitleComponent*/}
            {/*    id="draggable-dialog-title"*/}
            {/*    onClose={handleCloseDialogueForDone}*/}
            {/*    Confirmation*/}
            {/*</DialogTitleComponent>*/}
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Mark action as done?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "0.8rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDialogueForDone}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDialogueForDone();
                      }}
                      style={style.addUserButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/* **************** GENERATE LINK **************** */}
        <Dialog
          open={this.state.openDialogueForSendEmail}
          onClose={handleClickCloseDialogueToGenerate}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleClickCloseDialogueToGenerate}
            >
              Request Client
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContent"
            >
              <label className="label">Client Email</label>
              <input
                autoComplete="off"
                type="email"
                id="emailPhone"
                value={emailPhone && emailPhone}
                style={style.inputField}
                onChange={(e) => {
                  this.props.handleChangeClientEmail(e.target.value);
                }}
              />
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "8px" }}>
                  <Button
                    style={{ float: "right" }}
                    disabled={!value}
                    className={value ? "addButton" : "disabledAddButton"}
                    onClick={(e) => {
                      e.preventDefault();
                      generateLink(
                        this.state.caseId,
                        this.state.workflowId,
                        this.state.actionId,
                        emailPhone
                      );
                      handleClickCloseDialogueToGenerate();
                    }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.dynamicActionState.openDynamicActionSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={
              this.props.state.dynamicActionState.errorMsgForDynamicActionData
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openSnackBar}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({
              openSnackBar: false,
            });
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.setState({
                openSnackBar: false,
              });
            }}
            variant="error"
            message="Invalid file type"
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            this.props.state.dynamicActionState.openDynamicActionSuccessSnackBar
          }
          autoHideDuration={4000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="success"
            message={`Link send to ${emailPhone}`}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    background: "#ffffff",
    margin: "78px 3% 3% 3%",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "18px",
    width: "100%",
    position: "inherit",
    border: "none",
  },
  fieldLabel: {
    color: "#606060",
    fontWeight: 600,
    // coloe: '#000000'
    marginBottom: "0",
    fontSize: "16px",
    paddingBottom: "0rem",
    position: "inherit",
    border: "none",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "30%",
    position: "inherit",
    marginBottom: "1.5rem",
    color: "#606060",
  },
  inputField: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "100%",
    position: "inherit",
    marginBottom: "0.5rem",
    color: "#606060",
    paddingLeft: "10px",
    marginTop: "0.5rem",
    paddingTop: "6px",
  },
  button: {
    background: "#8BC83F",
    color: "white",
    border: "none",
    // width: "18%",
    fontSize: "17px",
    padding: "0.4rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
  },
  printButton: {
    background: "#8BC83F",
    color: "white",
    border: "none",
    width: "14%",
    fontSize: "17px",
    padding: "0.4rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
    height: "38px",
  },
  doneButton: {
    background: "none",
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "90px",
    fontSize: "17px",
    padding: "0.3rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
  },
  markButton: {
    background: "#8BC83F",
    border: "1px solid #8BC83F",
    color: "white",
    width: "90px",
    fontSize: "17px",
    padding: "0.3rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
  },
  labelTitle: {
    // color: "#353334",
    color: "#000000",
    fontSize: "19px",
    border: "none",
    paddingTop: "0px",
    paddingBottom: "15px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    // background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  dialogueContent: {
    background: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    paddingRight: "1rem !important",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    // width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    float: "right",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    // width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    float: "right",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
};
export default DynamicActionScreen;
