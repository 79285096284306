import {ACTION_TYPE_SHIPPING_LABEL} from "../constants/Type";
import React from "react";

const initialState = {
    loading: false,
    error: '',
    successMessage: '',
    shippingData: {
        address1 : "",
        address2 : "",
        city : "",
        state : "",
        zip5: "",
    },
    shippingLabelData:{
        fromAddressId: "",
        toAddressId: "",
        fromName: "",
        fromFirm: "",
        toName: "",
        toFirm: "",
        weightLb: "",
        email:"",
        shipDate:"",
        description: "",
    },
    success: false,
    shippingAddressList:[],
    shippingLabels:[],
    openShippingErrorSnackBar:false,
    openShippingSuccessSnackBar: false,
    trackShipments:[],
    showWarning: false,
}

const shippingState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_SHIPPING_LABEL.REQUEST_SHIPPING_LABEL:
            return{
                ...state,
                loading:true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.CLOSE_SHIPPING_SNACKBAR:
            return{
                ...state,
               openShippingErrorSnackBar: false,
                openShippingSuccessSnackBar: false,
            }
        case ACTION_TYPE_SHIPPING_LABEL.HANDLE_CHANGE_SHIPPING_VALUE:
            const newForm = {...state.shippingData};
            newForm[action.id] = action.value;
            return {
                ...state,
                shippingData:newForm,
            };
        case ACTION_TYPE_SHIPPING_LABEL.SET_SHIPPING_TO_EDIT:
            return {
                ...state,
                shippingData: action.shippingData,
            }
        case ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                shippingAddressList: action.response.addresses,
                success: false,
            }
        case ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                shippingListById: action.response,
            }
        case ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                openShippingSuccessSnackBar: true,
                successMessage: "Successfully added",
                success: action.response,
            }
        case ACTION_TYPE_SHIPPING_LABEL.ADD_ADDRESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.UPDATE_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                openShippingSuccessSnackBar: true,
                successMessage: "Successfully updated",
                success: action.response,
            }
        case ACTION_TYPE_SHIPPING_LABEL.UPDATE_SHIPPING_ADDRESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.DELETE_SHIPPING_SUCCESS:
            return {
                ...state,
                loading: false,
                shippingList: action.response,
                openShippingSuccessSnackBar: true,
                successMessage: "Successfully deleted"
            }
        case ACTION_TYPE_SHIPPING_LABEL.DELETE_SHIPPING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                shippingLabels: action.response.shippingLabels,
                success: false,
            }
        case ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.PRINT_SHIPPING_LABEL_SUCCESS:
            return {
                ...state,
                loading: false,
                openShippingSuccessSnackBar: true,
                successMessage: "Successfully send",
                success: action.response,
            }
        case ACTION_TYPE_SHIPPING_LABEL.PRINT_SHIPPING_LABEL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.HANDLE_CHANGE_SHIPPING_FORM_VALUE:
            const newData = {...state.shippingLabelData};
            newData[action.id] = action.value;
            return {
                ...state,
                shippingLabelData:newData,
            };
        case ACTION_TYPE_SHIPPING_LABEL.SET_SHIPPING_ADDRESS_TO_EMPTY:
            return {
                ...state,
                loading: false,
                shippingData: {
                    address1 : "",
                    address2 : "",
                    city : "",
                    state : "",
                    zip5: "",
                },
            }
        case ACTION_TYPE_SHIPPING_LABEL.SET_SHIPPING_LABEL_TO_EMPTY:
            return {
                ...state,
                loading: false,
                shippingLabelData: {
                    fromAddressId: "",
                    toAddressId: "",
                    fromName: "",
                    fromFirm: "",
                    toName: "",
                    toFirm: "",
                    weightLb: "",
                    email:"",
                    shipDate:"",
                    description: ""
                },
            }
        case ACTION_TYPE_SHIPPING_LABEL.TRACK_SHIPMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                trackShipments: action.response.trackShipments
            }
        case ACTION_TYPE_SHIPPING_LABEL.TRACK_SHIPMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                showWarning: true,
                // openShippingErrorSnackBar: true,
            }
        case ACTION_TYPE_SHIPPING_LABEL.HANDLE_CLEAR_TRACK_REPORT:
            return {
                ...state,
                loading: false,
                trackShipments: [],
                error: "",
                showWarning: false,
            }
        case ACTION_TYPE_SHIPPING_LABEL.SEARCH_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                shippingAddressList: action.response.addresses
            }
        case ACTION_TYPE_SHIPPING_LABEL.SEARCH_SHIPPING_ADDRESS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        default:
            return state;
    };
};

export default shippingState;