import React, { Component } from "react";
import { Popover } from "antd";
import { CheckOutlined } from "@ant-design/icons";

class ActiveStatusPopover extends Component {
  render() {
    const { status } = this.props;
    return (
      <Popover
        overlayStyle={{
          width: "195px",
        }}
        getPopupContainer={(node) => node.parentNode}
        placement="right"
        trigger="hover"
        content={this.props.content}
      >
        <span
          className={
            this.props.status === "Active" ? "active-color" : "inactive-color"
          }
        >
          {this.props.status}
        </span>
      </Popover>
    );
  }
}

export default ActiveStatusPopover;
