import { connect } from "react-redux";
import {
    addClientFailure,
    addClientSuccess,
    closeSnackBarForRegisterCase, handleChangeCaseInRegisterCase,
    handleChangeClientInRegisterCase,
    registerCaseRequestMade, userRequestMade
} from "../../actions";
import { RegisterCaseAPI } from "../../api";
import { RegisterCaseScreen } from "../../screens";
import {
    clearClientFormData,
    fetchClientCaseContactsFailure,
    fetchClientCaseContactsSuccess
} from "../../actions/RegisterCaseAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleChangeClientInRegisterCase: (id, value) => dispatch(handleChangeClientInRegisterCase(id, value)),
        handleChangeCaseInRegisterCase: (id, value) => dispatch(handleChangeCaseInRegisterCase(id, value)),
        addClient: (clientData) => {
            dispatch(registerCaseRequestMade(true));
            RegisterCaseAPI.addNewClient(clientData, (response, error) => {
                if (response) {
                    dispatch(addClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(addClientFailure(error.msg));
                    } else {
                        dispatch(addClientFailure(error));
                    }
                }
            });
        },
        handleRegisterCaseSnackBarClose: () => dispatch(closeSnackBarForRegisterCase(true)),
        fetchClientCaseContacts: (id, props) => {
            dispatch(userRequestMade(true));
            RegisterCaseAPI.fetchClientCaseContact(id,(response, error) => {
                if (response) {
                    dispatch(fetchClientCaseContactsSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientCaseContactsFailure(error.msg))
                    } else {
                        dispatch(fetchClientCaseContactsFailure(error));
                    }
                }
            })
        },
        clearClientFormData: () => dispatch(clearClientFormData()),
    };
};
export const RegisterCaseContainer = connect(mapStateToProps, mapDispatchToProps)(RegisterCaseScreen);