import React, {Component} from "react";
import MySnackbarContentWrapper from "../components/Snackbar";
import {Button, Snackbar} from "@material-ui/core";
import {Prompt} from "react-router";
import 'moment/locale/it.js';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import '../styles/styles.css';
import {Checkbox, DatePicker, Select} from "antd";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const {Option} = Select;

class ClientAddFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBlocking: false,
            selectedSsnValue: '',
            ssn: [],
            selectedData: [],
            visible: true,
            valid: false,
            changed: false,
            isChangedFirst: false,
            fromBack: false,
            contactVisible: false,
            contacts: [],
            contactData: {},
            selectedContactData: {},
            contactNameValue: "",
            id: "",
            clicked: "",
        };
    }

    componentDidMount() {
        this.props.fetchClientList();
    }

    componentWillUnmount() {
        this.props.setSearchedValue({});
        this.props.setAddSucessfullFalse(false);
        this.props.handleRegisterCaseSnackBarClose();
        this.setState({changed: false})
    }


    keyPressed = (event) => {
        if (event.key === 'Tab') {
            console.log("here")
        }
    };

    render() {
        const handleChangeDate = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                this.props.handleChangeClientInRegisterCase("dob", new Date(valueOfInput1).getTime());
            }
        }

        //DELETE CONTACT FIELD
        const handleClickOpenDeleteContactDialogue = contactId => {
            this.setState({
                openDeleteContactDialogue: true,
                contactId: contactId
            });
        };
        const handleCloseDeleteContactDialogue = () => {
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };

        const handleOkDeleteContactDialogue = () => {
            closeContactFieldSection(this.state.contactId);
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };

        //EXTRA FIELD

        const showInputForField = () => {
            let a = createAddContactObject();
            this.setState({
                openDropdownForContact: true,
                contacts: [...this.state.contacts, a]
            });
        };

        const createAddContactObject = () => {
            let formData = {
                id: String(Math.random()),
                value: {
                    name: '',
                    emailPhone: '',
                    contactType: 'UNKNOWN_CONTACT_TYPE',
                    phone: '',
                    address: '',
                    dob: 0,
                }
            };
            return formData;
        };

        const changeContactFieldNameValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.name = value;
            filterContactData()
        };
        const changeContactFieldEmailValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.emailPhone = value;
            filterContactData()
        };
        const changeContactFieldTypeValue = (key, value) => {
            if (value === "") {
                this.state.contacts.filter(single => single.id === key)[0].value.contactType = "UNKNOWN_CONTACT_TYPE";
            } else {
                this.state.contacts.filter(single => single.id === key)[0].value.contactType = value;
            }
            filterContactData()
        };
        const changeContactFieldPhoneValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.phone = value;
            filterContactData()
        };
        const changeContactFieldAddressValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.address = value;
            filterContactData()
        };
        const changeContactFieldDobValue = (key, value) => {
            if (value === "") {
                this.state.contacts.filter(single => single.id === key)[0].value.dob = 0;
            } else {
                this.state.contacts.filter(single => single.id === key)[0].value.dob = value;
            }
            filterContactData()
        };
        const handleChangeDateContact = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                const id = this.state.id;
                changeContactFieldDobValue(id, new Date(valueOfInput1).getTime());
            }
        }
        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
            return moment(dateConverted, "YYYY-MM-DD");
        };
        const filterContactData = () => {
            let contactForm = [];
            this.state.contacts.map((single) => {
                return (
                    contactForm.push(single.value)
                )
            });
            this.props.handleChangeClientInRegisterCase("contacts", contactForm)
        };
        const closeContactFieldSection = key => {
            var removedActionData = this.state.contacts.filter(single => single.id !== key);
            this.setState({
                contacts: removedActionData
            }, () => {
                filterContactData()
            })
        };
        const openFieldForContact = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.contacts.length > 0) {
                div.push(<>
                    <h5 style={{
                        width: '100%',
                        color: '#8BC83F',
                        paddingTop: '1rem',
                        paddingBottom: '0.8rem',
                        fontWeight: '550'
                    }}>List of contacts</h5>
                    <div className='row'>
                        <div className="col-2">
                            <label htmlFor="contact" className="columnHeadingContactName">Name
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2" style={{paddingLeft: 0}}>
                            <label htmlFor="emailPhone" className="columnHeadingContactEmail">Email
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="emailPhone" className="columnHeadingContactType">Contact Type
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="phone" className="columnHeadingContactPhone">Phone Number</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="address" className="columnHeadingContactAddress">Address</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="dob" className="columnHeadingContactDob">Date-of-birth</label>
                        </div>
                    </div>
                </>)
            }

            this.state.contacts.map(single => {
                return div.push(
                    <div className='row'>
                        <div className="col-2">
                            <input
                                id={single.id}
                                className="inputFieldContactName"
                                value={single.value.name}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    changeContactFieldNameValue(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2" style={{paddingRight: 0, paddingLeft: 0}}>
                            <input
                                id={single.id}
                                className="inputFieldContactEmail"
                                value={single.value.emailPhone}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true, id: single.id});
                                    changeContactFieldEmailValue(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2" style={{paddingRight: 0, maxWidth: '20%'}}>
                            <div style={style.select}>
                                <select
                                    id={single.id}
                                    value={single.value.contactType}
                                    className="inputFieldContactType"
                                    // style={style.dropdownInput}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldTypeValue(e.target.id, e.target.value)
                                    }}
                                >
                                    <option value hidden/>
                                    <option value="EVERFUND_USER_CONTACT">Everfund user</option>
                                    <option value="CORPORATE_CONTACT">Corporate</option>
                                    <option value="CLIENT_CONTACT">Client</option>
                                    <option value="CLIENT_RELATIVE_CONTACT">Client Relative</option>
                                    <option value="OTHER_CONTACT_TYPE">Other contact type</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-2" style={{paddingRight: 0, maxWidth: '35%'}}>
                            <input
                                id={single.id}
                                className="inputFieldContactPhone"
                                value={single.value.phone}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    changeContactFieldPhoneValue(single.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <input
                                id={single.id}
                                className="inputFieldContactAddress"
                                value={single.value.address}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true, id: single.id});
                                    changeContactFieldAddressValue(single.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-1">
                            <div className="select-add-date-contact" onClick={() => {
                                this.setState({isBlocking: true, id: single.id})
                            }}>
                                <DatePicker
                                    value={single.value.dob && getDate(single.value.dob)}
                                    format="MM-DD-YYYY"
                                    onChange={handleChangeDateContact}
                                />
                            </div>
                        </div>
                        <div className="col-1" style={{paddingLeft: '30px', position: 'inherit'}}>
                            <RemoveCircleOutlineIcon
                                id={single.id}
                                style={{
                                    color: "grey",
                                    width: "24px",
                                    height: "24px",
                                    marginTop: "1.3rem",
                                    cursor: 'pointer'
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    handleClickOpenDeleteContactDialogue(single.id);
                                }}
                            />
                        </div>
                    </div>
                );
            });
            return div;
        };

        const clientState = this.props.state.clientState;
        const addClientForm = () => {
            if (!this.state.clicked) {
                this.setState({
                    clicked: true
                });

                setTimeout(() => {
                    this.setState({
                        clicked: false
                    })
                }, 3000);
            }
        }
        return (
            <React.Fragment>
                {clientState &&
                clientState.client &&
                this.state.ssn &&
                clientState.client.length > 0 &&
                this.state.ssn.length !==
                clientState.client.length ? this.setState({
                    ssn: clientState.client.map(singleClient => ({
                        key: singleClient.clientId,
                        text: singleClient.ssn,
                        value: singleClient.ssn
                    }))
                }) : null}
                {this.state.changed === false && this.props.state.registerCaseState.clientData ? this.setState({
                    selectedData: this.props.state.registerCaseState.clientData,
                    selectedSsnValue: this.props.state.registerCaseState.clientData.ssn,
                    changed: true,
                    fromBack: true
                }) : null}
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />
                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/client" style={style.link}>
                        Client
                    </Link>
                    <Typography color="inherit" className="link">
                        Add Client
                    </Typography>
                </Breadcrumbs>

                <div style={style.container}>
                    {/*<div className="card" style={style.card}>*/}
                    <div className="form">
                        <div className="card-header" style={style.cardHeader}>
                            <h5 style={style.clientText}> Add Client </h5>
                        </div>
                        <div className="card-body" style={style.cardBody}>
                            <div className="row" style={style.row}>
                                <div className="col-4" style={style.column1}>
                                    <label htmlFor="ssn" style={style.columnHeading}>Client Id <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='number'
                                        id="ssn"
                                        style={style.inputField}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeClientInRegisterCase(e.target.id, e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="firstName" style={style.columnHeading}>First Name
                                        <span style={{color: "red"}}> *</span></label>
                                    <input
                                        type='name'
                                        id="firstName"
                                        autocomplete='off'
                                        style={style.inputField}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeClientInRegisterCase(e.target.id, e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="lastName" style={style.columnHeading}>Last Name
                                        <span style={{color: "red"}}> *</span></label>
                                    <input
                                        id="lastName"
                                        style={style.inputField}
                                        autocomplete='off'
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeClientInRegisterCase(e.target.id, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '2rem'}}>
                                <div className="col-4" style={{position: 'inherit'}}>
                                    <label style={style.columnHeading}>Date-of-birth
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="select-add-date">
                                        <DatePicker
                                            format="MM-DD-YYYY"
                                            onChange={handleChangeDate}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="gender" style={style.columnHeading}>Gender
                                        <span style={{color: "red"}}> *</span></label>
                                    <div className="select-input selects h40" style={{marginTop: "12px", width: "90%"}}>
                                        <Select
                                            id="contactType"
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.setState({isBlocking: true});
                                                this.props.handleChangeClientInRegisterCase("gender", e);
                                            }}
                                        >
                                            <Option value="MALE">Male</Option>
                                            <Option value="FEMALE">Female</Option>
                                            <Option value="OTHER">Other</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="maritalStatus" style={style.columnHeading}>Marital
                                        Status <span style={{color: "red"}}>*</span></label>
                                    <div className="select-input selects h40" style={{marginTop: "12px", width: "90%"}}>
                                        <Select
                                            id="maritalStatus"
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                this.setState({isBlocking: true});
                                                this.props.handleChangeClientInRegisterCase("maritalStatus", e);
                                            }}
                                        >
                                            <Option value="SINGLE">Single</Option>
                                            <Option value="MARRIED">Married</Option>
                                            <Option value="DIVORCED">Divorced</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '2rem'}}>
                                <div className="col-4">
                                    <label htmlFor="maritalStatus" style={style.columnHeading}>
                                        County
                                    </label>
                                    <input
                                        type='text'
                                        id="county"
                                        style={style.countyInputField}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeClientInRegisterCase(e.target.id, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '2rem'}}>
                                <div className="col-4" style={{position: 'inherit'}}>
                                    <Checkbox
                                        id="isEnableTransaction"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            this.props.handleChangeClientInRegisterCase(e.target.id, e.target.checked);
                                        }}
                                        style={style.columnHeading}>
                                        Enable Transaction
                                    </Checkbox>
                                </div>
                            </div>
                            {!(this.state.contacts.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '1.5rem'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showInputForField();
                                     }}>
                                    <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                         style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                    <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Contact</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    {openFieldForContact()}
                                    <div style={{
                                        // width: '8.5%',
                                        display: 'block',
                                        // textAlign: 'end',
                                        marginTop: '1rem'
                                    }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  this.setState({isBlocking: false});
                                                  showInputForField();

                                              }}>
                                            <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                                 style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                            <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add More</span>
                                        </span>
                                    </div>

                                </div>}
                            <div className='row'>
                                <div className="col-4"/>
                                <div className="col-4"/>
                                <div className="col-4">
                                    <button
                                        // disabled={!value}
                                        className="btn btn-primary"
                                        style={style.nextButton}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: false});
                                            var clientData = this.props.state.registerCaseState.clientData;
                                            clientData.ssn = clientData.ssn.replace(/-/g, '');
                                            if (!this.state.clicked) {
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.addClient(clientData, this.props);
                                                setTimeout(() => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*</div>*/}
                </div>

                <Dialog
                    open={this.state.openDeleteContactDialogue}
                    onClose={handleCloseDeleteContactDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{background: "#ffffff", borderRadius: "18px"}}>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px'
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete contact?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteContactDialogue}
                                            style={style.cancelButton}
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteContactDialogue();
                                            }}
                                            style={style.addButton}
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.registerCaseState.openRegisterCaseSnackBarForClient}
                    autoHideDuration={6000}
                    onClose={this.props.handleRegisterCaseSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleRegisterCaseSnackBarClose}
                        variant="error"
                        message={this.props.state.registerCaseState.errorMsgForRegisterCase}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}


const style = {
    container: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        width: "60%",
        marginLeft: "20%",
        marginRight: "13%",
        marginTop: "7%",
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },

    cardBody: {
        paddingLeft: '4%',
        paddingRight: '2%'
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '4%',
        height: '64px'
    },
    headerColumn1: {
        flex: '68%',
        paddingTop: '5px',
        marginleft: '10px !important',
    },
    headerColumn2: {
        flex: '27%',
        marginTop: '10px',
        paddingBottom: '10px',
        paddingTop: '5px',
        display: 'inline',
        textAlign: 'end',
        marginRight: '3%'
    },
    headerColumn3: {
        flex: '5%',
        marginTop: '10px',
        paddingTop: '5px',
        float: 'right',
        textAlign: 'end'
    },
    clientText: {
        color: '#cc6600',
        fontSize: '22px',
        fontWeight: 'bolder',
        paddingTop: '15px',
    },
    ssnText: {
        color: '#625F5F',
        fontSize: '14px',
        paddingTop: '9px',
        paddingRight: '0.8rem'
    },
    ssnInput: {
        border: '0',
        backgroundColor: '#F4F5F9',
        height: '36px',
        borderRadius: '4px',
        marginRight: '11px',
        paddingLeft: '12px'
    },
    searchButton: {
        backgroundColor: '#8BC83F',
        width: '100px',
        height: '36px',
        color: '#fff',
        fontWeight: 'bold',
        border: '0',
        // marginRight:'4rem'
        marginRight: '1rem',
        float: 'right',
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '5px 5px'
    },
    column1: {
        flex: '33%',
        padding: '0 6px',
    },
    column2: {
        flex: '34%',
        padding: '0 6px',
    },
    column3: {
        flex: '33%'
    },
    formGroupIcon: {
        marginTop: '20px',
        position: 'initial',
        display: 'block',

    },
    selectHead: {
        position: 'relative',
    },
    selectBarHead: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        marginTop: '5px',
        height: '40px',
        fontSize: '15px'
    },
    columnHeading: {
        color: '#625F5F',
        marginBottom: '2px',
        fontSize: '16px',
        width: '100%'
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    countyInputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '93%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    expandMoreIcon: {
        position: 'absolute',
        right: '50px',
        top: '50px',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        float: 'right',
        width: '40%',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '31px',
        marginRight: '10%',
        marginBottom: '1.5rem'
    },
    selectPlaceholder: {
        fontSize: '13px !important'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
        marginLeft: "-23px"
    },
    select: {
        position: "relative",
        height: "40px",
        marginTop: '10px',
    },
    expandMoreIconContact: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    inputFieldContact: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '10px',
        marginBottom: '15px',
        paddingLeft: '13px'
    },
    columnHeadingContact: {
        color: '#625F5F',
        fontSize: '16px',
        width: '100%',
        marginBottom: 0
    },
    addButton: {
        background: "#8BC83F",
        color: "white",
        width: "100%",
        padding: "0.5rem",
        border: "1px solid #8BC83F"
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
};

export default ClientAddFormScreen;