import {ACTION_TYPE_CORPORATE} from "../constants/Type";

export const corporateRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_CORPORATE.CORPORATE_REQUEST_MADE,
        bool
    };
};

export const fetchCorporateSuccess = corporate => {
    return {
        type: ACTION_TYPE_CORPORATE.CORPORATE_FETCH_SUCCESS,
        corporate
    };
};

export const fetchCorporateFailure = error => {
    return {
        type: ACTION_TYPE_CORPORATE.CORPORATE_FETCH_FAILURE,
        error
    };
};

export const addCorporateSuccess = bool => {
    return {
        type: ACTION_TYPE_CORPORATE.ADD_CORPORATE_SUCCESS,
        bool
    };
};

export const addCorporateFailure = error => {
    return {
        type: ACTION_TYPE_CORPORATE.ADD_CORPORATE_FAILURE,
        error
    };
};

export const handleChangeCorporateToAdd = (id, value) => {
    return {
        type: ACTION_TYPE_CORPORATE.HANDLE_CHANGE_CORPORATE_TO_ADD,
        id,
        value
    };
};
export const handleChangeCorporateToEdit = (id, value) => {
    return {
        type: ACTION_TYPE_CORPORATE.HANDLE_CHANGE_CORPORATE_TO_EDIT,
        id,
        value
    };
};

export const deleteCorporateSuccess = (corporateId) => {
    return {
        type: ACTION_TYPE_CORPORATE.DELETE_CORPORATE_SUCCESS,
        corporateId
    }
};

export const deleteCorporateFailure = (error) => {
    return {
        type: ACTION_TYPE_CORPORATE.DELETE_CORPORATE_FAILURE,
        error
    }
};

export const setCorporateToEdit = (corporateData) => {
    return {
        type: ACTION_TYPE_CORPORATE.SET_CORPORATE_TO_EDIT,
        corporateData
    }
};
export const setCorporateToAddNull = () => {
    return {
        type: ACTION_TYPE_CORPORATE.SET_CORPORATE_TO_ADD_NULL,
        
    }
};

export const closeSnackBarForCorporate = (bool) => {
    return {
        type: ACTION_TYPE_CORPORATE.CLOSE_SNACK_BAR_CORPORATE_PAGE,
        bool
    };
};

//SEARCH CORPORATE
export const searchCorporateSuccess = (response) => {
    return {
        type: ACTION_TYPE_CORPORATE.SEARCH_CORPORATE_SUCCESS,
        response
    }
};

export const searchCorporateFailure = (error) => {
    return {
        type: ACTION_TYPE_CORPORATE.SEARCH_CORPORATE_FAILURE,
        error
    }
};