import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import {Button, CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import {LocalDb} from "../api";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import {AutoComplete, Checkbox, DatePicker, Select} from "antd";
import Typography from "@material-ui/core/Typography";

const {Option} = Select;

class CorporateClientFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            corporateId: props.location.pathname.substring
            (props.location.pathname.length, props.location.pathname.lastIndexOf('/') + 1),
            fromBack: false,
            selectedSsnValue: '',
            ssn: [],
            selectedData: [],
            visible: true, valid: false,
            changed: false,
            isChangedFirst: false,
            contactVisible: false,
            contacts: [],
            setValue: false,
            openDeleteContactDialogue: false,
            optionsArr: [],
            id: "",
            clicked: false,
        };

        if (props.location.pathname.replace('/corporate/clientform/', '').length > 0) {
            this.props.getCorporateInfo(props.location.pathname.replace('/corporate/clientform/', ''))
        }
    }

    componentWillMount() {
        this.props.fetchClientList();
    }

    componentWillUnmount() {
        this.props.handleSnackBarClose();
    }

    render() {
        const clientData = this.props.state.corporateClientState.searchedClient;

        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
            return moment(dateConverted, "YYYY-MM-DD");
        };

        const handleClickOpenDeleteContactDialogue = contactId => {
            this.setState({
                openDeleteContactDialogue: true,
                contactId: contactId
            });
        };
        const handleCloseDeleteContactDialogue = () => {
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };

        const handleOkDeleteContactDialogue = () => {
            closeContactFieldSection(this.state.contactId);
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };

        const showInputForField = () => {
            let a = createAddContactObject();
            this.setState({
                openDropdownForContact: true,
                contacts: [...this.state.contacts, a]
            });
        };

        const createAddContactObject = () => {
            let formData = {
                id: String(Math.random()),
                value: {
                    name: '',
                    emailPhone: '',
                    contactType: -1,
                    phone: '',
                    address: '',
                    dob: '',
                }
            };
            return formData;
        };

        const changeContactFieldNameValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.name = value;
            filterContactData()
        };

        const changeContactFieldEmailValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.emailPhone = value;
            filterContactData()
        };

        const changeContactFieldTypeValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.contactType = value;
            filterContactData()
        };

        const changeContactFieldPhoneValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.phone = value;
            filterContactData()
        };
        const changeContactFieldAddressValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.address = value;
            filterContactData()
        };
        const changeContactFieldDobValue = (key, value) => {
            console.log("value", value)
            this.state.contacts.filter(single => single.id === key)[0].value.dob = value;
            filterContactData()
        };
        const handleChangeDateContact = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                console.log("valueOfInput1", valueOfInput1)
                const id = this.state.id;
                changeContactFieldDobValue(id, new Date(valueOfInput1).getTime());
            }
        }

        const filterContactData = () => {
            let contactForm = [];
            this.state.contacts.map((single) => {
                return (
                    contactForm.push(single.value)
                )
            });
            this.props.handleChangeCorporateClient("contacts", contactForm)
        };

        const closeContactFieldSection = key => {
            var removedActionData = this.state.contacts.filter(single => single.id !== key);
            this.setState({
                contacts: removedActionData
            }, () => {
                filterContactData()
            })
        };

        const openFieldForContact = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.contacts.length > 0) {
                div.push(<>
                    <h5 style={{
                        width: '100%',
                        color: '#8BC83F',
                        paddingTop: '1rem',
                        paddingBottom: '0.8rem',
                        fontWeight: '550'
                    }}>List of contacts</h5>
                    <div className='row'>
                        <div className="col-2">
                            <label htmlFor="contact" className="columnHeadingContactName">Name
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2" style={{paddingLeft: 0}}>
                            <label htmlFor="emailPhone" className="columnHeadingContactEmail">Email
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="emailPhone" className="columnHeadingContactType">Contact Type
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="phone" className="columnHeadingContactPhone">Phone Number</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="address" className="columnHeadingContactAddress">Address</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="dob" className="columnHeadingContactDob">Date-of-birth</label>
                        </div>
                    </div>
                </>)
            }

            this.state.contacts.map(single => {
                return div.push(
                    <div className='row'>
                        <div className="col-2">
                            <input
                                id={single.id}
                                className="inputFieldContactName"
                                value={single.value.name}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    changeContactFieldNameValue(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2" style={{paddingRight: 0, paddingLeft: 0}}>
                            <input
                                id={single.id}
                                className="inputFieldContactEmail"
                                value={single.value.emailPhone}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    changeContactFieldEmailValue(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2" style={{paddingRight: 0, maxWidth: '20%'}}>
                            <div style={style.select}>
                                <select
                                    id={single.id}
                                    value={single.value.contactType}
                                    className="inputFieldContactType"
                                    onChange={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldTypeValue(e.target.id, e.target.value)
                                    }}
                                >
                                    <option value hidden/>
                                    <option value="1">Everfund User</option>
                                    <option value="2">Corporate</option>
                                    <option value="3">Client</option>
                                    <option value="4">Client Relative</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-2" style={{paddingRight: 0, maxWidth: '35%'}}>
                            <input
                                id={single.id}
                                className="inputFieldContactPhone"
                                value={single.value.phone}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    changeContactFieldPhoneValue(single.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-2">
                            <input
                                id={single.id}
                                className="inputFieldContactAddress"
                                value={single.value.address}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true, id: single.id});
                                    changeContactFieldAddressValue(single.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-1">
                            <div className="select-add-date-contact" onClick={() => {
                                this.setState({isBlocking: true, id: single.id})
                            }}>
                                <DatePicker
                                    value={single.value.dob && getDate(single.value.dob)}
                                    format="MM-DD-YYYY"
                                    onChange={handleChangeDateContact}
                                />
                            </div>
                        </div>
                        <div className="col-1" style={{paddingLeft: '88px', position: 'inherit'}}>
                            <RemoveCircleOutlineIcon
                                id={single.id}
                                style={{
                                    color: "grey",
                                    width: "24px",
                                    height: "24px",
                                    marginTop: "1.3rem",
                                    cursor: 'pointer'
                                }}
                                onClick={e => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    handleClickOpenDeleteContactDialogue(single.id);
                                    // closeContactFieldSection(single.id);
                                }}
                            />
                        </div>
                    </div>
                );
            });
            return div;
        };

        const handleChangeDate = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                this.props.handleChangeCorporateClient("dob", new Date(valueOfInput1).getTime());
            }
        }

        const userInfoFromDB = LocalDb.getSessions();
        const userType = userInfoFromDB ? userInfoFromDB.loginResponse.loginUser.user ? "EVERFUND_USER" : "CORPORATE_USER" : null;

        //searching ssn value
        const onSearchFunction = (event) => {
            if (!event) {
                this.setState({
                    optionsArr: []
                })
            } else {
                this.props.searchClient(event);
                this.setState({
                    optionsArr:
                        clientData && clientData.map(single => {
                            const value = {
                                value: single.ssn,
                                single: single,
                            }
                            return value
                        })
                })
            }
            this.props.handleChangeCorporateClient("", event)
        }
        return (
            <React.Fragment>
                {this.state.changed === false && this.props.state.corporateClientState.clientData ? this.setState({
                    selectedData: this.props.state.corporateClientState.clientData,
                    selectedSsnValue: this.props.state.corporateClientState.clientData.ssn,
                    changed: true,
                    fromBack: true
                }) : null}

                {this.props.state.corporateClientState.loading ? (
                    <div
                        className={
                            this.props.state.corporateClientState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                {userType === 'CORPORATE_USER' ?
                    <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                        <Link color="inherit" to="/dashboard" style={style.link}>
                            <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                        </Link>
                        <Link color="inherit" to="/client" style={style.link}>
                            Client
                        </Link>
                        <Typography color="inherit" className="link">
                            Add Client
                        </Typography>
                    </Breadcrumbs>
                    :
                    <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                        <Link color="inherit" to="/dashboard" style={style.link}>
                            <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                        </Link>
                        <Link color="inherit" to="/corporate" style={style.link}>
                            Corporate
                        </Link>
                        <Link color="inherit" onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push("/corporate/client/" + this.state.corporateId)
                        }}
                              style={style.link}>
                            Client
                        </Link>
                        <Typography color="inherit" className="link">
                            Add Client
                        </Typography>
                    </Breadcrumbs>
                }
                <div style={style.container}>
                    <div className="row" style={style.row}>
                        <div className="col-4" style={style.column1}>

                            <label htmlFor="ssn" style={style.columnHeading}>Social Security
                                Number <span style={{color: "red"}}> *</span></label>
                            <div className="auto-comp-select">
                                <AutoComplete
                                    className="auto-comp"
                                    dropdownClassName="drop"
                                    dropdownMatchSelectWidth
                                    onSearch={onSearchFunction}
                                    options={this.state.optionsArr}
                                    onSelect={(e, options) => {
                                        this.setState({
                                            selectedSsnValue: e,
                                            selectedData: options.single
                                        })
                                    }}
                                    allowClear
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={(e) => {
                                        this.setState({
                                            selectedSsnValue: "",
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <label htmlFor="firstName" style={style.columnHeading}>First Name
                                <span style={{color: "red"}}> *</span></label>
                            <input
                                type='name'
                                id="firstName"
                                autoComplete="off"
                                style={style.inputField}
                                value={this.state.selectedSsnValue !== '' ? this.state.selectedData.firstName :
                                    this.props.state.corporateClientState.clientData.firstName}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeCorporateClient(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="lastName" style={style.columnHeading}>Last Name
                                <span style={{color: "red"}}> *</span></label>
                            <input
                                id="lastName"
                                autoComplete="off"
                                style={style.inputField}
                                value={this.state.selectedSsnValue !== '' ? this.state.selectedData.lastName :
                                    this.props.state.corporateClientState.clientData.lastName}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeCorporateClient(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '2rem'}}>
                        <div className="col-4" style={{position: 'inherit'}}>
                            <label htmlFor="dob" style={style.columnHeading}>Date-Of-Birth
                                <span style={{color: "red"}}> *</span></label>
                            <div className="select-edit-date">
                                <DatePicker
                                    value={this.state.selectedSsnValue !== '' ? getDate(this.state.selectedData.dob) :
                                        this.props.state.corporateClientState.clientData.dob ?
                                            getDate(this.props.state.corporateClientState.clientData.dob) : null}
                                    format="MM-DD-YYYY"
                                    onChange={handleChangeDate}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <label htmlFor="gender" style={style.columnHeading}>Gender
                                <span style={{color: "red"}}> *</span></label>
                            <div className="select-input selects h40" style={{marginTop: "12px", width: "90%"}}>
                                <Select
                                    id="gender"
                                    value={this.state.selectedSsnValue !== '' ? this.state.selectedData.gender :
                                        this.props.state.corporateClientState.clientData.gender}
                                    onChange={(e) => {
                                        this.setState({isBlocking: true});
                                        this.props.handleChangeCorporateClient("gender", e);
                                    }}
                                >
                                    <Option value="MALE">Male</Option>
                                    <Option value="FEMALE">Female</Option>
                                    <Option value="OTHER">Other</Option>
                                </Select>
                            </div>
                        </div>

                        <div className="col-4">
                            <label htmlFor="maritalStatus" style={style.columnHeading}>Marital
                                Status <span style={{color: "red"}}> *</span></label>
                            <div className="select-input selects h40" style={{marginTop: "12px", width: "90%"}}>
                                <Select
                                    value={this.state.selectedSsnValue !== '' ? this.state.selectedData.maritalStatus
                                        : this.props.state.corporateClientState.clientData.maritalStatus}
                                    id="maritalStatus"
                                    onChange={(e) => {
                                        this.setState({isBlocking: true});
                                        this.props.handleChangeCorporateClient("maritalStatus", e);
                                    }}
                                >
                                    <Option value="SINGLE">Single</Option>
                                    <Option value="MARRIED">Married</Option>
                                    <Option value="DIVORCED">Divorced</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "2rem"}}>
                        <div className="col-4">
                            <label htmlFor="county" style={style.columnHeading}>County</label>
                            <input
                                type='name'
                                id="county"
                                autoComplete="off"
                                style={style.inputField}
                                value={this.state.selectedSsnValue !== '' ? this.state.selectedData.county :
                                    this.props.state.corporateClientState.clientData.county}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeCorporateClient(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '2rem'}}>
                        <div className="col-4" style={{position: 'inherit'}}>
                            <Checkbox
                                id="isEnableTransaction"
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeCorporateClient(e.target.id, e.target.checked);
                                }}
                                checked={!!((this.state.selectedSsnValue !== '' && this.state.selectedData.isEnableTransaction) ||
                                    this.props.state.corporateClientState.clientData.isEnableTransaction)}
                                value={this.state.selectedSsnValue !== '' ? this.state.selectedData.isEnableTransaction
                                    : this.props.state.corporateClientState.clientData.isEnableTransaction}
                                style={style.columnHeading}>
                                Enable Transaction
                            </Checkbox>
                        </div>
                    </div>

                    {!(this.state.contacts.length > 0) ?
                        <div style={{cursor: 'pointer', marginTop: '1.5rem', width: '14%'}}
                             onClick={e => {
                                 e.preventDefault();
                                 this.setState({
                                     isBlocking: false
                                 });
                                 showInputForField();
                             }}>
                            <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                 style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                            <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Contact</span>
                        </div>
                        :
                        <div style={{marginTop: '2rem'}}>
                            {openFieldForContact()}
                            <div style={{
                                display: 'block',
                                marginTop: '1rem'
                            }}>
                            <span style={{cursor: 'pointer'}}
                                  onClick={e => {
                                      e.preventDefault();
                                      this.setState({isBlocking: false});
                                      showInputForField();

                                  }}>
                             <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                  style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add More</span>
                            </span>
                            </div>

                        </div>}

                    <div className='row'>
                        <div className="col-4"/>
                        <div className="col-4"/>
                        <div className="col-4">
                            <button
                                // disabled={!value}
                                className="btn btn-primary"
                                style={style.nextButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: false});
                                    if (this.state.selectedData && this.state.selectedData.clientId &&
                                        this.props.state.registerCaseState.clientData) {
                                        this.props.setClientContent(this.state.selectedData);
                                        this.props.assignClientToContainer(this.state.corporateId,
                                            this.state.selectedData.clientId, this.props);
                                    } else {
                                        var clientData = this.props.state.corporateClientState.clientData;
                                        clientData.ssn = clientData.ssn.replace(/-/g, '');
                                        if (!this.state.clicked) {
                                            this.setState({
                                                clicked: true
                                            });
                                            this.props.addNewCorporateClient(this.state.corporateId,
                                                clientData, this.props);
                                            setTimeout(() => {
                                                this.setState({
                                                    clicked: false
                                                })
                                            }, 3000);
                                        }
                                    }
                                }}
                            > Add
                            </button>
                        </div>
                    </div>
                </div>

                <Dialog
                    open={this.state.openDeleteContactDialogue}
                    onClose={handleCloseDeleteContactDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{background: "#ffffff", borderRadius: "18px"}}>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px'
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete contact?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteContactDialogue}
                                            style={style.cancelButton}
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteContactDialogue();
                                            }}
                                            style={style.addButton}
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.corporateClientState.openCorporateClientFormSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.corporateClientState.errorMsgForCorporateClient}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    container: {
        background: "#ffffff",
        margin: "78px 3% 3% 3%",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        paddingLeft: '3rem',
        paddingTop: '2rem'
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    columnHeading: {
        color: '#625F5F',
        marginBottom: '2px',
        fontSize: '16px',
        width: '100%'
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    expandMoreIcon: {
        position: 'absolute',
        right: '75px',
        top: '50px',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        float: 'right',
        width: '50%',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '31px',
        marginRight: '10%',
        marginBottom: '1.5rem'
    },
    selectPlaceholder: {
        fontSize: '13px !important'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    select: {
        position: "relative",
        height: "40px",
        marginTop: '10px',
    },
    expandMoreIconContact: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    inputFieldContact: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '10px',
        marginBottom: '15px',
        paddingLeft: '13px'
    },
    columnHeadingContact: {
        color: '#625F5F',
        fontSize: '16px',
        width: '100%',
        marginBottom: 0
    },
    addButton: {
        background: "#8BC83F",
        color: "white",
        width: "100%",
        padding: "0.5rem",
        border: "1px solid #8BC83F"
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
};

export default CorporateClientFormScreen;
