import { ACTION_TYPE_ACTIVITY_LOG } from "../constants/Type";

const initialState = {
  loading: false,
  openActionSnackBar: false,
  activities: [],
  activityLogValue: [],
  achActivityLogs: [],
  openModal: false,
  error: "",
};

const activityLogState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_ACTIVITY_LOG.ACTIVITY_LOG_REQUEST_MADE:
      return {
        ...state,
        loading: true,
        activityLogValue: [],
      };
    case ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activities: action.activities.activityLogs,
        openModal: false,
        loading: false,
      };
    case ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        error: action.error,
        openModal: false,
        loading: false,
      };
    case ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_BY_ID_SUCCESS:
      return {
        ...state,
        activityLogValue: action.activities.activityLogs,
        openModal: true,
        loading: false,
      };
    case ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        openModal: false,
        loading: false,
      };
    case ACTION_TYPE_ACTIVITY_LOG.HANDLE_CLOSE_LOG_SNACKBAR:
      return {
        ...state,
        loading: false,
        openModal: false,
      };
    case ACTION_TYPE_ACTIVITY_LOG.FETCH_ACH_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        achActivityLogs: action.achActivityLogs && action.achActivityLogs.achActivityLogs,
      };
    case ACTION_TYPE_ACTIVITY_LOG.FETCH_ACH_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default activityLogState;
