import { ACTION_TYPE_WORKFLOW } from "../constants/Type";

export const workflowRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_WORKFLOW.WORKFLOW_REQUEST_MADE,
        bool
    };
};

export const fetchWorkflowSuccess = workflow => {
    return {
        type: ACTION_TYPE_WORKFLOW.WORKFLOW_FETCH_SUCCESS,
        workflow
    };
};

export const fetchWorkflowFailure = error => {
    return {
        type: ACTION_TYPE_WORKFLOW.WORKFLOW_FETCH_FAILURE,
        error
    };
};

export const deleteWorkflowFailure = (error) => {
    return {
        type: ACTION_TYPE_WORKFLOW.DELETE_WORKFLOW_FAILURE,
        error
    }
};

export const deleteWorkflowSuccess = (workflowId) => {
    return {
        type: ACTION_TYPE_WORKFLOW.DELETE_WORKFLOW_SUCCESS,
        workflowId
    }
};

export const closeSnackBarForWorkflow = (bool) => {
    return {
        type: ACTION_TYPE_WORKFLOW.CLOSE_SNACK_BAR_WORKFLOW_PAGE,
        bool
    };
};