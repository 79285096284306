import { ACTION_TYPE_SCHEDULE_TRANSACTION } from "../constants/Type";

export const scheduleTransactionRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SCHEDULE_TRANSACTION_REQUEST_MADE,
    bool,
  };
};
export const closeScheduleTransactionSnackBar = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.CLOSE_SNACK_BAR_SCHEDULE_PAGE,
    bool,
  };
};

export const fetchScheduleTransactionSuccess = (scheduleTransaction) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_SUCCESS,
    scheduleTransaction,
  };
};

export const fetchScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};

export const fetchScheduleTransactionByClientSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS,
    scheduleTransaction,
  };
};

export const fetchScheduleTransactionByClientFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE,
    error,
  };
};

export const addNewScheduleTransactionSuccess = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.ADD_SCHEDULE_TRANSACTION_SUCCESS,
    bool,
  };
};

export const addNewScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.ADD_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};
export const updateScheduleTransactionSuccess = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.UPDATE_SCHEDULE_TRANSACTION_SUCCESS,
    bool,
  };
};

export const updateScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.UPDATE_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};

// EXPORT
export const exportScheduleTransactionSuccess = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.EXPORT_SCHEDULE_TRANSACTION_SUCCESS,
    bool,
  };
};

export const exportScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.EXPORT_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};

// REQUEST SCHEDULE TRANSACTION
export const requestScheduleTransactionSuccess = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS,
    bool,
  };
};
export const requestScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE,
    error,
  };
};

export const deleteScheduleTransactionSuccess = (scheduleTransactionId) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.DELETE_SCHEDULE_TRANSACTION_SUCCESS,
    scheduleTransactionId,
  };
};
export const deleteScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.DELETE_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};
export const editScheduleTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.UPDATE_SCHEDULE_TRANSACTION_SUCCESS,
    response,
  };
};
export const editScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.UPDATE_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};

export const setScheduleTransactionToEdit = (schedule) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SET_SCHEDULE_TRANSACTION_TO_EDIT,
    schedule,
  };
};

export const searchClientListSuccess = (clientList) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_LIST_SUCCESS,
    clientList,
  };
};
export const searchClientListFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_LIST_FAILURE,
    error,
  };
};

//HANDLE CHANGES ACTION
export const handleChangeScheduleDetails = (id, value) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_DETAILS,
    id,
    value,
  };
};
export const handleChangeOtherScheduleTransaction = (id, value) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_OTHER_SCHEDULE_TRANSACTION,
    id,
    value,
  };
};
export const handleChangeClientName = (clientId) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_CLIENT_NAME,
    clientId,
  };
};
export const handleChangeStatus = (status, id) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_STATUS,
    status,
    id,
  };
};
export const handleChangeTransactionMedium = (transMediumType) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_TRANSACTION_MEDIUM,
    transMediumType,
  };
};
export const handleChangeTransactionType = (transType) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_TRANSACTION_TYPE,
    transType,
  };
};
export const handleChangeScheduleTerm = (term) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_TERM,
    term,
  };
};
export const handleChangeScheduleTransactionInputData = (id, input) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_TRANSACTION_INPUT_DATA,
    id,
    input,
  };
};
export const clearScheduleTransactionInputData = () => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_SCHEDULE_TRANSACTION_INPUT_DATA,
  };
};
export const setScheduleTransactionInputDataToEdit = (scheduledData) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SET_SCHEDULE_TRANSACTION_INPUT_DATA_TO_EDIT,
    scheduledData,
  };
};

//handleChange to filter
export const handleChangeFromDate = (from) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_FROM_DATE,
    from,
  };
};
export const handleChangeToDate = (to) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_TO_DATE,
    to,
  };
};
export const handleChangeMedium = (medium) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_MEDIUM,
    medium,
  };
};
export const handleChangeStatusToFilter = (status) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_STATUS_TO_FILTER,
    status,
  };
};
export const handleChangeScheduleTypeFilter = (scheduleType, id) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_TYPE,
    scheduleType,
    id,
  };
};
export const handleChangeVendorToFilter = (vendorToFilter) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_VENDOR_TO_FILTER,
    vendorToFilter,
  };
};

// **********************SCHEDULE TRANSACTION FILTER
export const searchScheduleTransactionSuccess = (scheduleTransaction) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};
export const searchScheduleTransactionByDateSuccess = (scheduleTransaction) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};
export const searchScheduleTransactionByMediumSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};
export const searchScheduleTransactionByStatusSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE,
    error,
  };
};
//MEDIUM AND STATUS
export const searchScheduleTransactionByMediumAndStatusSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByMediumAndStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE,
    error,
  };
};
//MEDIUM AND DATE
export const searchScheduleTransactionByMediumAndDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByMediumAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE,
    error,
  };
};
//STATUS AND DATE
export const searchScheduleTransactionByStatusAndDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByStatusAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE,
    error,
  };
};
//MEDIUM, STATUS , DATE
export const searchScheduleTransactionByMediumAndStatusAndDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_ALL_SUCCESS,
    scheduleTransaction,
  };
};

export const searchScheduleTransactionByMediumAndStatusAndDateFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_ALL_FAILURE,
    error,
  };
};

export const clearScheduleTransactionFilterForm = () => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_SCHEDULE_TRANSACTION_FORM,
  };
};

// ***************CLIENT'S SCHEDULE TRANSACTION FILTER
export const searchClientScheduleTransactionSuccess = (scheduleTransaction) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_FAILURE,
    error,
  };
};

// DATE
export const searchClientScheduleTransactionByDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};

//Medium
export const searchClientScheduleTransactionByMediumSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};
//Status
export const searchClientScheduleTransactionByStatusSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByStatusFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE,
    error,
  };
};
//Medium and status
export const searchClientScheduleTransactionByMediumAndStatusSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByMediumAndStatusFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE,
    error,
  };
};
//Medium and status
export const searchClientScheduleTransactionByMediumAndDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByMediumAndDateFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE,
    error,
  };
};
//Status and Date
export const searchClientScheduleTransactionByStatusAndDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByStatusAndDateFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE,
    error,
  };
};
//Medium and status
export const searchClientScheduleTransactionByMediumAndStatusAndDateSuccess = (
  scheduleTransaction
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS,
    scheduleTransaction,
  };
};

export const searchClientScheduleTransactionByMediumAndStatusAndDateFailure = (
  error
) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE,
    error,
  };
};

//UPLOAD DOCUMENT FILE
export const uploadDocFileSuccess = (response) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.UPLOAD_DOCUMENT_FILE_SUCCESS,
    response,
  };
};

export const uploadDocFileFailure = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.UPLOAD_DOCUMENT_FILE_FAILURE,
    error,
  };
};

export const handleShowFileUploadErrorMessage = (error) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_SHOW_FILE_UPLOAD_FAILURE_MESSAGE,
    error,
  };
};

export const handleChangeBankDetailName = (name) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_BANK_DETAIL_NAME,
    name,
  };
};

export const clearFileData = () => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_FILE_DATA,
  };
};

export const setOpenModalToScheduleTransaction = (bool) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SET_OPEN_MODAL_TO_SCHEDULE_TRANSACTION,
    bool,
  };
};
export const handleScheduleTransFilter = (id, value) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_SCH_TRANS_FILTER,
    id,
    value,
  };
};
export const clearSchTransFilterData = () => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_SCH_TRANS_FILTER,
  };
};

// save config
export const saveSchTransConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SAVE_SCH_TRANS_CONFIG_SUCCESS,
    payload,
  };
};
export const saveSchTransConfigFailure = (payload) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.SAVE_SCH_TRANS_CONFIG_FAILURE,
    payload,
  };
};
export const getSchTransConfigSuccess = (payload) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.GET_SCH_TRANS_CONFIG_SUCCESS,
    payload,
  };
};
export const getSchTransConfigFailure = (payload) => {
  return {
    type: ACTION_TYPE_SCHEDULE_TRANSACTION.GET_SCH_TRANS_CONFIG_FAILURE,
    payload,
  };
};
