import { connect } from "react-redux";
import { ClientScreen } from "../../screens";
import {
  clientRequestMade,
  closeSnackBarForClient,
  deleteClientFailure,
  deleteClientSuccess,
  fetchClientFailure,
  fetchClientSuccess,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  profileRequestMade,
  searchClientFailInClientPage,
  searchClientSuccessInClientPage,
  setClientToEdit,
  setPasswordFailure,
  setPasswordSuccess,
  handleChangeEmail,
  handleChangeClientInRegisterCase,
  registerCaseRequestMade,
  closeSnackBarForRegisterCase,
  handleChangeClient,
  addClientSuccess,
  updateClientFailure,
} from "../../actions";
import {
  assignClientToCorporateFailure,
  assignClientToCorporateSuccess,
} from "../../actions/CorporateClientAction";
import {
  ClientAPI,
  ProfileAPI,
  RegisterCaseAPI,
  TransactionAPI,
} from "../../api";
import {
  closeClientTransactionSnackBar,
  getClientTransactionFailure,
  getClientTransactionSuccess,
  transactionRequestMade,
  // addNoteSuccess,
  // addNoteFailure,
  // handleChangeToOpenNoteModal
} from "../../actions/TransactionAction";
import {
  addPaymentDetailFailure,
  addPaymentDetailSuccess,
  calculateEligibilityFailure,
  calculateEligibilitySuccess,
  clearActions,
  clearEligibilitySetting,
  clearPaymentValues,
  clearWorkflow,
  clientOnHoldFailure,
  clientOnHoldSuccess,
  clientProfilePictureRequestMade,
  fetchCaseByClientIdFailure,
  fetchCaseByClientIdSuccess,
  fetchClientPaymentDetailFailure,
  fetchClientPaymentDetailSuccess,
  fetchFiledDetailByClientFailure,
  fetchFiledDetailByClientSuccess,
  handleChangeAction,
  handleChangeAdmissionDate,
  handleChangeClientAchDelayThreshold,
  handleChangeClientAnnualFee,
  handleChangeClientFee,
  handleChangeClientInstallment,
  handleChangeClientMonthlyFee,
  handleChangeClientSpendDown,
  handleChangeEligibilitySetting,
  handleChangeIdsValue,
  handleChangeToOpenSetNotificationModal,
  handleChangeWorkflow,
  setUploadToFalse,
  updateClientProfilePictureFailure,
  updateClientProfilePictureSuccess,
  uploadClientProfilePictureFailure,
  uploadClientProfilePictureSuccess,
  saveEnabledNotificationSuccess,
  saveEnabledNotificationFailure,
  getAlertByAccountSuccess,
  getAlertByAccountFailure,
  handleChangeHolidayAlert,
  handleChangeUpcomingAlert,
  handleChangeToOpenClientFormModal,
  clientOnDischargeSuccess,
  clientOnDischargeFailure,
  handleChangeFilterName,
  handleChangeFilterClientno,
  handleChangeFilterCaseCount,
  handleChangeFilterDate,
  fetchClientWithBalanceSuccess,
  handleChangeFilterBalance,
  handleChangeFilterTerm,
  // handleChangeBlockedTransData,
  clearClientList,
  searchClientWithBalanceFailInClientPage,
  searchClientWithBalanceSuccessInClientPage,
  clearFilterData,
  getConfigSuccess,
  getConfigFailure,
  saveConfigSuccess,
  saveConfigFailure,
  handleChangeUserStatus,
  handleChangeFetchAllStatus,
} from "../../actions/ClientAction";
import {
  addClientFormDataFailure,
  addClientFormDataSuccess,
  clearClientFormData,
} from "../../actions/RegisterCaseAction";
import {
  handleChangeCorporateClientEdit,
  corporateClientRequestMade,
  addCorporateClientSuccess,
  addCorporateClientFailure,
} from "../../actions/CorporateClientAction";
import CorporateClientAPI from "../../api/CorporateClientAPI";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientList: () => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientList((response, error) => {
        if (response) {
          dispatch(fetchClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientFailure(error.msg));
          } else {
            dispatch(fetchClientFailure(error));
          }
        }
      });
    },
    clearClientList: () => {
      dispatch(clearClientList());
    },
    fetchClientWithBalance: () => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientWithBalance((response, error) => {
        if (response) {
          dispatch(fetchClientWithBalanceSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientFailure(error.msg));
          } else {
            dispatch(fetchClientFailure(error));
          }
        }
      });
    },

    // pagination
    // fetchPaginatedClientList: () => {

    // }
    // fetch corporate client list
    fetchCorporateClientList: (clientId) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchCorporateClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchClientWithBalanceSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientFailure(error.msg));
          } else {
            dispatch(fetchClientFailure(error));
          }
        }
      });
    },

    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    deleteClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.deleteClient(clientId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteClientFailure(error.msg));
          } else {
            dispatch(deleteClientFailure(error));
          }
        } else {
          props.fetchClientList();
          dispatch(deleteClientSuccess(clientId));
        }
      });
    },

    // delete client in corporate only
    deleteClientInCorporate: (clientId, corporateId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.deleteClientInCorporate(
        clientId,
        corporateId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(deleteClientFailure(error.msg));
            } else {
              dispatch(deleteClientFailure(error));
            }
          } else {
            // props.fetchCorporateClientList(corporateId);
            dispatch(deleteClientSuccess(clientId));
          }
        }
      );
    },
    searchClient: (value) => {
      ClientAPI.searchClient(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientFailInClientPage(error.msg));
          } else {
            dispatch(searchClientFailInClientPage(error));
          }
        } else {
          dispatch(searchClientSuccessInClientPage(response));
        }
      });
    },
    filterClient: (
      name,
      clientNo,
      caseCount,
      date,
      balance,
      isGreaterThan,
      isHold,
      isDischarged,
      fetchAll,
      term
    ) => {
      ClientAPI.filterClient(
        name,
        clientNo,
        caseCount,
        date === null ? "" : date,
        balance,
        isGreaterThan,
        isHold,
        isDischarged,
        fetchAll,
        term,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientWithBalanceFailInClientPage(error.msg));
            } else {
              dispatch(searchClientWithBalanceFailInClientPage(error));
            }
          } else {
            dispatch(searchClientWithBalanceSuccessInClientPage(response));
          }
        }
      );
    },

    clearFilterData: () => dispatch(clearFilterData(true)),

    saveInConfig: (data) => {
      ClientAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveConfigFailure(error.msg));
          } else {
            dispatch(saveConfigFailure(error));
          }
        } else {
          dispatch(saveConfigSuccess(response));
        }
      });
    },

    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getConfigFailure(error.msg));
          } else {
            dispatch(getConfigFailure(error));
          }
        } else {
          dispatch(getConfigSuccess(response.configs));
        }
      });
    },
    getClientTransaction: (clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.getClientTransaction(clientId, (response, error) => {
        if (response) {
          dispatch(getClientTransactionSuccess(response));
          props.history.push("/transaction/client");
        } else {
          if (error.msg) {
            dispatch(getClientTransactionFailure(error.msg));
          } else {
            dispatch(getClientTransactionFailure(error));
          }
        }
      });
    },
    handleTransactionSnackBarClose: () =>
      dispatch(closeClientTransactionSnackBar(true)),
    setClientData: (clientData) => {
      dispatch(setClientToEdit(clientData));
    },
    handleSnackBarClose: () => dispatch(closeSnackBarForClient(true)),
    //set client password
    handleChangeEmail: (id, value) => dispatch(handleChangeEmail(id, value)),
    setClientPassword: (data, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.setClientPassword(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(setPasswordFailure(error.msg));
          } else {
            dispatch(setPasswordFailure(error));
          }
        } else {
          dispatch(setPasswordSuccess(true));
          props.fetchClientList();
        }
      });
    },

    setPasswordSuccessToFalse: () => {
      dispatch(setPasswordSuccess(false));
    },

    fetchFileListByClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchFileListByClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchFiledDetailByClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchFiledDetailByClientFailure(error.msg));
          } else {
            dispatch(fetchFiledDetailByClientFailure(error));
          }
        }
      });
    },
    updateProfilePicture: (selectedFiles, clientId, props) => {
      dispatch(profileRequestMade(true));
      let formdata = new FormData();
      formdata.append("image", selectedFiles.file);
      formdata.append("mimeType", selectedFiles.type);
      ProfileAPI.updateProfilePicture(formdata, (response, error) => {
        if (response) {
          var data = {
            clientId: clientId,
            profilePicUrl: response.fileUrl,
          };
          dispatch(uploadClientProfilePictureSuccess(data, props));
          props.updateClientProfilePicture(data);
        } else {
          if (error.msg) {
            dispatch(uploadClientProfilePictureFailure(error.msg));
          } else {
            dispatch(uploadClientProfilePictureFailure(error));
          }
        }
      });
    },
    setUploadToFalse: () => {
      dispatch(setUploadToFalse());
    },
    updateClientProfilePicture: (data, props) => {
      dispatch(clientProfilePictureRequestMade(true));
      ClientAPI.updateClientProfilePicture(data, (response, error) => {
        if (response) {
          // props.history.push('/client')
          dispatch(updateClientProfilePictureSuccess(response));
        } else {
          if (error.msg) {
            dispatch(updateClientProfilePictureFailure(error.msg));
          } else {
            dispatch(updateClientProfilePictureFailure(error));
          }
        }
      });
    },
    clientOnHold: (bool, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.clientOnHold(bool, (response, error) => {
        if (response) {
          dispatch(clientOnHoldSuccess(response));
          props.fetchClientList();
        } else {
          if (error.msg) {
            dispatch(clientOnHoldFailure(error.msg));
          } else {
            dispatch(clientOnHoldFailure(error));
          }
        }
      });
    },
    clientOnDischarge: (bool, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.clientOnDischarge(bool, (response, error) => {
        if (response) {
          dispatch(clientOnDischargeSuccess(response));
          props.fetchClientList();
        } else {
          if (error.msg) {
            dispatch(clientOnDischargeFailure(error.msg));
          } else {
            dispatch(clientOnDischargeFailure(error));
          }
        }
      });
    },
    // ADD CLIENT
    handleChangeClientInRegisterCase: (id, value) =>
      dispatch(handleChangeClientInRegisterCase(id, value)),
    addClient: (clientData, props) => {
      dispatch(registerCaseRequestMade(true));
      RegisterCaseAPI.addNewClient(clientData, (response, error) => {
        if (response) {
          dispatch(addClientFormDataSuccess(true));
          // props.fetchClientList();
          props.fetchClientWithBalance();
        } else {
          if (error.msg) {
            dispatch(addClientFormDataFailure(error.msg));
          } else {
            dispatch(addClientFormDataFailure(error));
          }
        }
      });
    },
    assignClientToContainer: (corporateId, clientId, props) => {
      dispatch(corporateClientRequestMade(true));
      CorporateClientAPI.assignClientToCorporate(
        corporateId,
        clientId,
        (response, error) => {
          if (response) {
            dispatch(assignClientToCorporateSuccess(true));
            props.fetchClientList();
          } else {
            if (error.msg) {
              dispatch(assignClientToCorporateFailure(error.msg));
            } else {
              dispatch(assignClientToCorporateFailure(error));
            }
          }
        }
      );
    },
    addNewCorporateClient: (corporateId, clientData, props) => {
      dispatch(corporateClientRequestMade(true));
      CorporateClientAPI.addNewCorporateClient(
        corporateId,
        clientData,
        (response, error) => {
          if (response) {
            dispatch(addCorporateClientSuccess(true));
            props.fetchClientList();
            // if(LocalDb.getSessions() && LocalDb.getSessions().loginResponse.loginUser.corporate){
            //     props.history.replace('/client');
            // }else{
            //     props.history.replace('/corporate/client/' + corporateId);
            // }
          } else {
            if (error.msg) {
              dispatch(addCorporateClientFailure(error.msg));
            } else {
              dispatch(addCorporateClientFailure(error));
            }
          }
        }
      );
    },
    setAddClientSuccessToFalse: () => dispatch(addClientFormDataSuccess(false)),
    closeSnackBarForRegisterCase: () =>
      dispatch(closeSnackBarForRegisterCase()),
    clearClientFormData: () => dispatch(clearClientFormData()),
    // UPDATE CLIENT
    handleChangeClient: (id, value) => dispatch(handleChangeClient(id, value)),
    editClient: (clientData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.editClient(clientData, (response, error) => {
        if (response) {
          dispatch(addClientSuccess(true));
          props.fetchClientList();
        } else {
          if (error.msg) {
            dispatch(updateClientFailure(error.msg));
          } else {
            dispatch(updateClientFailure(error));
          }
        }
      });
    },
    //for payment
    fetchClientPayment: (id, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientPayment(id, (response, error) => {
        if (response) {
          dispatch(fetchClientPaymentDetailSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientPaymentDetailFailure(error.msg));
          } else {
            dispatch(fetchClientPaymentDetailFailure(error));
          }
        }
      });
    },
    handleChangeClientFee: (fee) => {
      dispatch(handleChangeClientFee(fee));
    },
    handleChangeClientAnnualFee: (annualFee) => {
      dispatch(handleChangeClientAnnualFee(annualFee));
    },
    handleChangeClientMonthlyFeeValue: (monthlyFee) => {
      dispatch(handleChangeClientMonthlyFee(monthlyFee));
    },
    handleChangeClientSpendDown: (spendDownThreshold) => {
      dispatch(handleChangeClientSpendDown(spendDownThreshold));
    },
    handleChangeAdmissionDate: (admissionDate) => {
      dispatch(handleChangeAdmissionDate(admissionDate));
    },
    handleChangeClientAchDelayThreshold: (achDelayThreshold) => {
      dispatch(handleChangeClientAchDelayThreshold(achDelayThreshold));
    },
    handleChangeClientInstallment: (installmentMonth) => {
      dispatch(handleChangeClientInstallment(installmentMonth));
    },

    // filter client
    handleChangeFilterName: (clientname) => {
      dispatch(handleChangeFilterName(clientname));
    },
    handleChangeFilterClientno: (clientNo) => {
      dispatch(handleChangeFilterClientno(clientNo));
    },
    handleChangeFilterCaseCount: (caseCount) => {
      dispatch(handleChangeFilterCaseCount(caseCount));
    },
    handleChangeFilterDate: (status) => {
      dispatch(handleChangeFilterDate(status));
    },
    handleChangeUserStatus: (status) => {
      dispatch(handleChangeUserStatus(status));
    },
    handleChangeFetchAllStatus: (bool) => {
      dispatch(handleChangeFetchAllStatus(bool));
    },
    handleChangeFilterBalance: (balance) => {
      dispatch(handleChangeFilterBalance(balance));
    },
    handleChangeFilterTerm: (term) => {
      dispatch(handleChangeFilterTerm(term));
    },
    addClientPayment: (paymentData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.addClientPayment(paymentData, (response, error) => {
        if (response) {
          dispatch(addPaymentDetailSuccess(response));
          // props.history.push({pathname: '/client'})
        } else {
          if (error.msg) {
            dispatch(addPaymentDetailFailure(error.msg));
          } else {
            dispatch(addPaymentDetailFailure(error));
          }
        }
      });
    },
    // setPaymentSuccessToFalse: () => {dispatch(addPaymentDetailSuccess(false))},
    //CALCULATE ELIGIBILITY
    calculateEligibility: (eligibilityData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.calculateEligibility(eligibilityData, (response, error) => {
        if (response) {
          dispatch(calculateEligibilitySuccess(response));
        } else {
          if (error.msg) {
            dispatch(calculateEligibilityFailure(error.msg));
          } else {
            dispatch(calculateEligibilityFailure(error));
          }
        }
      });
    },

    fetchCaseByClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchCaseByClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchCaseByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCaseByClientIdFailure(error.msg));
          } else {
            dispatch(fetchCaseByClientIdFailure(error));
          }
        }
      });
    },
    handleChangeEligibilityData: (id, value) => {
      dispatch(handleChangeEligibilitySetting(id, value));
    },
    handleChangeIdsValue: (id, value) => {
      dispatch(handleChangeIdsValue(id, value));
    },
    handleChangeCloseSnackBar: () => {
      dispatch(closeSnackBarForClient(true));
    },
    handleChangeWorkflow: (workflowId) => {
      dispatch(handleChangeWorkflow(workflowId));
    },
    clearWorkflow: () => {
      dispatch(clearWorkflow());
    },
    handleChangeAction: (actionId) => {
      dispatch(handleChangeAction(actionId));
    },
    clearAction: () => {
      dispatch(clearActions());
    },
    clearEligibilitySetting: () => {
      dispatch(clearEligibilitySetting());
    },
    clearPaymentValues: () => {
      dispatch(clearPaymentValues());
    },

    //update
    handleChangeCorporateClientEdit: (id, value) =>
      dispatch(handleChangeCorporateClientEdit(id, value)),

    // CLIENT'S NOTIFICATION SETTING
    handleChangeToOpenSetNotificationModal: (bool) =>
      dispatch(handleChangeToOpenSetNotificationModal(bool)),

    // handleChangeEnableNotification: (id, value) => {
    //     dispatch(handleChangeEnableNotifications(id, value))
    // },
    saveNotifications: (enabledData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.saveEnabledNotification(enabledData, (response, error) => {
        if (response) {
          dispatch(saveEnabledNotificationSuccess(response));
          props.fetchClientList();
        } else {
          if (error.msg) {
            dispatch(saveEnabledNotificationFailure(error.msg));
          } else {
            dispatch(saveEnabledNotificationFailure(error));
          }
        }
      });
    },
    getAlertInfo: (accountId) => {
      dispatch(clientRequestMade(true));
      ProfileAPI.getAlertInfo(accountId, (response, error) => {
        if (response) {
          dispatch(getAlertByAccountSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAlertByAccountFailure(error.msg));
          } else {
            dispatch(getAlertByAccountFailure(error));
          }
        }
      });
    },
    handleChangeHolidayAlert: (bool) => {
      dispatch(handleChangeHolidayAlert(bool));
    },
    handleChangeUpcomingAlert: (bool) => {
      dispatch(handleChangeUpcomingAlert(bool));
    },
    handleChangeToOpenClientFormModal: (bool) => {
      dispatch(handleChangeToOpenClientFormModal(bool));
    },
  };
};

export const ClientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientScreen);
