import React, { Component } from "react";
import MySnackbarContentWrapper from "../../components/Snackbar";
import { Button, Snackbar } from "@material-ui/core";
import { Prompt } from "react-router";
import "moment/locale/it.js";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import "date-fns";
import moment from "moment";
import "../../styles/styles.css";
import { Checkbox, DatePicker, Select } from "antd";
import { AutoComplete } from "antd";

const { Option } = Select;

class ClientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocking: false,
      selectedSsnValue: "",
      ssn: [],
      selectedData: {},
      valid: false,
      changed: false,
      isChangedFirst: false,
      fromBack: false,
      contactVisible: false,
      contacts: [],
      selectedDate: new Date("2014-08-18T21:11:54"),
      openContactListConformationModal: false,
      openContactListModal: false,
      optionsArr: [],
      id: "",
    };
  }

  componentDidMount() {
    this.props.fetchClientList();
    this.props.clearClientFormData();
  }

  componentWillUnmount() {
    this.props.setSearchedValue({});
    this.props.setAddSucessfullFalse(false);
    this.props.handleRegisterCaseSnackBarClose();
    this.setState({ changed: false });
  }

  render() {
    const clientData = this.props.state.registerCaseState.searchedClient;

    const getDate = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };
    const handleChangeDateContact = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        const id = this.state.id;
        changeContactFieldDobValue(id, new Date(valueOfInput1).getTime());
      }
    };

    const handleOpenContactListModal = () => {
      this.setState({
        openContactListConformationModal: true,
      });
    };

    const handleCloseContactListModal = () => {
      this.setState({
        openContactListConformationModal: false,
      });
    };

    const handleOpenContactListToSendModal = () => {
      this.setState({
        openContactListModal: true,
      });
    };
    const handleCloseContactListToSendModal = () => {
      this.setState({
        openContactListModal: false,
      });
    };

    const handleClickOpenDeleteContactDialogue = (contactId) => {
      this.setState({
        openDeleteContactDialogue: true,
        contactId: contactId,
      });
    };
    const handleCloseDeleteContactDialogue = () => {
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };

    const handleOkDeleteContactDialogue = () => {
      closeContactFieldSection(this.state.contactId);
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };

    const showInputForField = () => {
      let a = createAddContactObject();
      this.setState({
        openDropdownForContact: true,
        contacts: [...this.state.contacts, a],
      });
    };

    const createAddContactObject = () => {
      let formData = {
        id: Math.random(),
        value: {
          name: "",
          emailPhone: "",
          contactType: "UNKNOWN_CONTACT_TYPE",
          phone: "",
          address: "",
          dob: 0,
        },
      };

      return formData;
    };

    const changeContactFieldNameValue = (key, value) => {
      this.state.contacts.filter((single) => single.id == key)[0].value.name =
        value;
      filterContactData();
    };

    const changeContactFieldEmailValue = (key, value) => {
      this.state.contacts.filter(
        (single) => single.id == key
      )[0].value.emailPhone = value;
      filterContactData();
    };

    const changeContactFieldTypeValue = (key, value) => {
      if (value === "") {
        this.state.contacts.filter(
          (single) => single.id === key
        )[0].value.contactType = "UNKNOWN_CONTACT_TYPE";
      } else {
        this.state.contacts.filter(
          (single) => single.id === key
        )[0].value.contactType = value;
      }

      filterContactData();
    };

    const changeContactFieldPhoneValue = (key, value) => {
      this.state.contacts.filter((single) => single.id == key)[0].value.phone =
        value;
      filterContactData();
    };
    const changeContactFieldAddressValue = (key, value) => {
      this.state.contacts.filter(
        (single) => single.id == key
      )[0].value.address = value;
      filterContactData();
    };

    const changeContactFieldDobValue = (key, value) => {
      if (value === "") {
        this.state.contacts.filter(
          (single) => single.id === key
        )[0].value.dob = 0;
      } else {
        this.state.contacts.filter((single) => single.id === key)[0].value.dob =
          value;
      }
      filterContactData();
    };
    const filterContactData = () => {
      let contactForm = [];
      this.state.contacts.map((single) => {
        return contactForm.push(single.value);
      });
      this.props.handleChangeClientInRegisterCase("contacts", contactForm);
    };

    const closeContactFieldSection = (key) => {
      var removedActionData = this.state.contacts.filter(
        (single) => single.id != key
      );
      this.setState(
        {
          contacts: removedActionData,
        },
        () => {
          filterContactData();
        }
      );
    };

    const handleChangeDate = (range) => {
      this.setState({
        isBlocking: true,
      });
      if (range) {
        const valueOfInput1 = range.format();
        this.props.handleChangeClientInRegisterCase(
          "dob",
          new Date(valueOfInput1).getTime()
        );
      }
    };

    const openFieldForContact = () => {
      let div = [];
      if (this.state.contacts.length > 0) {
        div.push(
          <>
            <h5
              style={{
                width: "100%",
                color: "#8BC83F",
                paddingTop: "1rem",
                paddingBottom: "0.8rem",
                fontWeight: "550",
              }}
            >
              List of contacts
            </h5>
            <div className="row">
              <div className="col-2">
                <label htmlFor="contact" className="columnHeadingContactName">
                  Name
                  <span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col-2" style={{ paddingLeft: 0 }}>
                <label
                  htmlFor="emailPhone"
                  className="columnHeadingContactEmail"
                >
                  Email
                  <span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col-2">
                <label
                  htmlFor="emailPhone"
                  className="columnHeadingContactType"
                >
                  Contact Type
                  <span style={{ color: "red" }}> *</span>
                </label>
              </div>
              <div className="col-2">
                <label htmlFor="phone" className="columnHeadingContactPhone">
                  Phone Number
                </label>
              </div>
              <div className="col-2">
                <label
                  htmlFor="address"
                  className="columnHeadingContactAddress"
                >
                  Address
                </label>
              </div>
              <div className="col-2">
                <label htmlFor="dob" className="columnHeadingContactDob">
                  Date-of-birth
                </label>
              </div>
            </div>
          </>
        );
      }

      this.state.contacts.map((single) => {
        return div.push(
          <div className="row">
            <div className="col-2">
              <input
                id={single.id}
                className="inputFieldContactName"
                value={single.value.name}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: true });
                  changeContactFieldNameValue(e.target.id, e.target.value);
                }}
              />
            </div>
            <div className="col-2" style={{ paddingRight: 0, paddingLeft: 0 }}>
              <input
                id={single.id}
                className="inputFieldContactEmail"
                value={single.value.emailPhone}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: true });
                  changeContactFieldEmailValue(e.target.id, e.target.value);
                }}
              />
            </div>
            <div className="col-2" style={{ paddingRight: 0, maxWidth: "20%" }}>
              <div style={style.select}>
                <select
                  id={single.id}
                  value={single.value.contactType}
                  className="inputFieldContactType"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    console.log("e.target.value", e.target.value);
                    changeContactFieldTypeValue(single.id, e.target.value);
                  }}
                >
                  <option value="UNKNOWN_CONTACT_TYPE" hidden />
                  <option value="EVERFUND_USER_CONTACT">Everfund user</option>
                  <option value="CORPORATE_CONTACT">Corporate</option>
                  <option value="CLIENT_CONTACT">Client</option>
                  <option value="CLIENT_RELATIVE_CONTACT">
                    Client Relative
                  </option>
                  <option value="OTHER_CONTACT_TYPE">Other contact type</option>
                </select>
              </div>
            </div>
            <div className="col-2" style={{ paddingRight: 0, maxWidth: "35%" }}>
              <input
                id={single.id}
                className="inputFieldContactPhone"
                value={single.value.phone}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: true });
                  changeContactFieldPhoneValue(single.id, e.target.value);
                }}
              />
            </div>
            <div className="col-2">
              <input
                id={single.id}
                className="inputFieldContactAddress"
                value={single.value.address}
                onChange={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: true, id: single.id });
                  changeContactFieldAddressValue(single.id, e.target.value);
                }}
              />
            </div>
            <div className="col-1">
              <div
                className="select-add-date-contact"
                onClick={() => {
                  this.setState({ isBlocking: true, id: single.id });
                }}
              >
                <DatePicker
                  value={single.value.dob && getDate(single.value.dob)}
                  format="MM-DD-YYYY"
                  onChange={handleChangeDateContact}
                />
              </div>
            </div>
            <div
              className="col-1"
              style={{ paddingLeft: "50px", position: "inherit" }}
            >
              <RemoveCircleOutlineIcon
                id={single.id}
                style={{
                  color: "grey",
                  width: "24px",
                  height: "24px",
                  marginTop: "1.3rem",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: true });
                  handleClickOpenDeleteContactDialogue(single.id);
                }}
              />
            </div>
          </div>
        );
      });
      return div;
    };

    const onSearchFunction = (event) => {
      if (!event) {
        this.setState({
          optionsArr: [],
        });
      } else {
        this.props.searchClient(event);
        this.setState({
          optionsArr:
            clientData &&
            clientData.map((single) => {
              const value = {
                value: single.ssn,
                single: single,
              };
              return value;
            }),
        });
        this.props.handleChangeClientInRegisterCase("ssn", event);
      }
    };

    const clientState = this.props.state.clientState;
    console.log(
      "this.props.state.registerCaseState.clientData",
      this.props.state.registerCaseState.clientData
    );
    return (
      <React.Fragment>
        {this.props.state.registerCaseState.addClientSuccessfull === true
          ? this.props.handleNext()
          : null}
        {clientState &&
        clientState.client &&
        this.state.ssn &&
        clientState.client.length > 0 &&
        this.state.ssn.length !== clientState.client.length
          ? this.setState({
              ssn: clientState.client.map((singleClient) => ({
                key: singleClient.clientId,
                text: singleClient.ssn,
                value: singleClient.ssn,
              })),
            })
          : null}
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page?"
        />
        <div style={style.container}>
          <div className="card" style={style.card}>
            <div className="form">
              <div className="card-header" style={style.cardHeader}>
                <h5 style={style.clientText}> Client Detail </h5>
              </div>
              <div className="card-body" style={style.cardBody}>
                <div className="row" style={style.row}>
                  <div className="col-4" style={style.column1}>
                    <label htmlFor="ssn" style={style.columnHeading}>
                      Social Security Number{" "}
                      <span style={{ color: "red" }}> *</span>{" "}
                    </label>
                    <div className="auto-comp-select">
                      <AutoComplete
                        className="auto-comp"
                        dropdownClassName="drop"
                        dropdownMatchSelectWidth
                        onSearch={onSearchFunction}
                        options={this.state.optionsArr}
                        onSelect={(e, options) => {
                          this.setState({
                            selectedSsnValue: e,
                            selectedData: options.single,
                          });
                        }}
                        allowClear
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onChange={(e) => {
                          this.setState({
                            selectedSsnValue: "",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <label htmlFor="firstName" style={style.columnHeading}>
                      First Name
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="name"
                      id="firstName"
                      autocomplete="off"
                      style={style.inputField}
                      value={
                        this.state.selectedSsnValue != ""
                          ? this.state.selectedData.firstName
                          : this.props.state.registerCaseState.clientData
                              .firstName
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClientInRegisterCase(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor="lastName" style={style.columnHeading}>
                      Last Name
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      id="lastName"
                      style={style.inputField}
                      autocomplete="off"
                      value={
                        this.state.selectedSsnValue != ""
                          ? this.state.selectedData.lastName
                          : this.props.state.registerCaseState.clientData
                              .lastName
                      }
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClientInRegisterCase(
                          e.target.id,
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-5" style={style.row}>
                  <div className="col-4" style={style.column1}>
                    <label style={style.columnHeading}>
                      Date-Of-Birth
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="select-input-date">
                      <DatePicker
                        value={
                          this.state.selectedSsnValue != ""
                            ? getDate(this.state.selectedData.dob)
                            : this.props.state.registerCaseState.clientData.dob
                            ? getDate(
                                this.props.state.registerCaseState.clientData
                                  .dob
                              )
                            : null
                        }
                        format="MM-DD-YYYY"
                        onChange={handleChangeDate}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <label htmlFor="gender" style={style.columnHeading}>
                      Gender
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ marginTop: "12px", width: "90%" }}
                    >
                      <Select
                        id="gender"
                        value={
                          this.state.selectedSsnValue != ""
                            ? this.state.selectedData.gender
                            : this.props.state.registerCaseState.clientData
                                .gender
                        }
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChangeClientInRegisterCase(
                            "gender",
                            e
                          );
                        }}
                      >
                        <Option value="MALE">Male</Option>
                        <Option value="FEMALE">Female</Option>
                        <Option value="OTHER">Other</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-4">
                    <label htmlFor="maritalStatus" style={style.columnHeading}>
                      Marital Status <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ marginTop: "12px", width: "90%" }}
                    >
                      <Select
                        id="maritalStatus"
                        value={
                          this.state.selectedSsnValue != ""
                            ? this.state.selectedData.maritalStatus
                            : this.props.state.registerCaseState.clientData
                                .maritalStatus
                        }
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChangeClientInRegisterCase(
                            "maritalStatus",
                            e
                          );
                        }}
                      >
                        <Option value="SINGLE">Single</Option>
                        <Option value="MARRIED">Married</Option>
                        <Option value="DIVORCED">Divorced</Option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "2rem" }}>
                  <div className="col-4" style={{ position: "inherit" }}>
                    <Checkbox
                      id="isEnableTransaction"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClientInRegisterCase(
                          e.target.id,
                          e.target.checked
                        );
                      }}
                      checked={
                        (this.state.selectedSsnValue != "" &&
                          this.state.selectedData.isEnableTransaction) ||
                        this.props.state.registerCaseState.clientData
                          .isEnableTransaction
                          ? true
                          : false
                      }
                      value={
                        this.state.selectedSsnValue != ""
                          ? this.state.selectedData.isEnableTransaction
                          : this.props.state.registerCaseState.clientData
                              .isEnableTransaction
                      }
                      style={style.columnHeading}
                    >
                      Enable Transaction
                    </Checkbox>
                  </div>
                </div>
                {!(this.state.contacts.length > 0) ? (
                  <div
                    style={{ cursor: "pointer", marginTop: "1.5rem" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        isBlocking: true,
                      });
                      showInputForField();
                    }}
                  >
                    <img
                      src="/assets/images/add-button-inside-circle.svg"
                      alt="Add Button"
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    />
                    <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                      Add Contact
                    </span>
                  </div>
                ) : (
                  <div style={{ marginTop: "2rem" }}>
                    {openFieldForContact()}
                    <div
                      style={{
                        display: "block",
                        marginTop: "1rem",
                      }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ isBlocking: false });
                          showInputForField();
                        }}
                      >
                        <img
                          src="/assets/images/add-button-inside-circle.svg"
                          alt="Add Button"
                          style={{
                            width: "22px",
                            height: "22px",
                            cursor: "pointer",
                          }}
                        />
                        <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                          Add More
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-4" />
                  <div className="col-4" />
                  <div className="col-4">
                    <button
                      // disabled={!value}
                      className="btn btn-primary"
                      style={style.nextButton}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        if (
                          this.state.selectedData &&
                          this.state.selectedData.clientId
                          // && this.props.state.registerCaseState.clientData
                        ) {
                          this.props.setClientContent(this.state.selectedData);
                          this.props.handleNext();
                        } else {
                          var clientData =
                            this.props.state.registerCaseState.clientData;
                          clientData.ssn = clientData.ssn.replace(/-/g, "");
                          this.props.addClient(clientData);
                        }
                        this.props.handleChangeCaseInRegisterCase(
                          "caseName",
                          ""
                        );
                        this.props.handleChangeCaseInRegisterCase(
                          "caseTemplateId",
                          ""
                        );
                      }}
                    >
                      {" "}
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.openDeleteContactDialogue}
          onClose={handleCloseDeleteContactDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ background: "#ffffff", borderRadius: "18px" }}>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete contact?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteContactDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteContactDialogue();
                        // handleOpenContactListToSendModal();
                      }}
                      style={style.addButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        {/*add previous contact conformation modal*/}
        <Dialog
          open={this.state.openContactListConformationModal}
          onClose={handleCloseContactListModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title">
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to add previous contacts?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseContactListModal}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // handleOkToDeleteHoliday()
                      handleCloseContactListModal();
                      handleOpenContactListToSendModal();
                    }}
                    className="addUserButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*list of previous contact*/}
        <Dialog
          open={this.state.openContactListModal}
          onClose={handleCloseContactListToSendModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title">
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              List
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              List of contacts.
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseContactListToSendModal}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // handleOkToDeleteHoliday()
                      handleCloseContactListToSendModal();
                    }}
                    className="addUserButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            this.props.state.registerCaseState.openRegisterCaseSnackBarForClient
          }
          autoHideDuration={6000}
          onClose={this.props.handleRegisterCaseSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleRegisterCaseSnackBarClose}
            variant="error"
            message={this.props.state.registerCaseState.errorMsgForRegisterCase}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    background: "#ffffff",
  },
  cardBody: {
    paddingLeft: "4%",
    paddingRight: "2%",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    paddingRight: "8px",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "4%",
    height: "64px",
  },
  headerColumn1: {
    flex: "68%",
    paddingTop: "5px",
    marginleft: "10px !important",
  },
  headerColumn2: {
    flex: "27%",
    marginTop: "10px",
    paddingBottom: "10px",
    paddingTop: "5px",
    display: "inline",
    textAlign: "end",
    marginRight: "3%",
  },
  headerColumn3: {
    flex: "5%",
    marginTop: "10px",
    paddingTop: "5px",
    float: "right",
    textAlign: "end",
  },
  clientText: {
    color: "#cc6600",
    fontSize: "22px",
    fontWeight: "bolder",
    paddingTop: "15px",
  },
  ssnText: {
    color: "#625F5F",
    fontSize: "14px",
    paddingTop: "9px",
    paddingRight: "0.8rem",
  },
  ssnInput: {
    border: "0",
    backgroundColor: "#F4F5F9",
    height: "36px",
    borderRadius: "4px",
    marginRight: "11px",
    paddingLeft: "12px",
  },
  searchButton: {
    backgroundColor: "#8BC83F",
    width: "100px",
    height: "36px",
    color: "#fff",
    fontWeight: "bold",
    border: "0",
    // marginRight:'4rem'
    marginRight: "1rem",
    float: "right",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px 5px",
  },
  column1: {
    flex: "33%",
    padding: "0 6px",
  },
  column2: {
    flex: "34%",
    padding: "0 6px",
  },
  column3: {
    flex: "33%",
  },
  formGroupIcon: {
    marginTop: "20px",
    position: "initial",
    display: "block",
  },
  selectHead: {
    position: "relative",
  },
  selectBarHead: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    marginTop: "5px",
    height: "40px",
    fontSize: "15px",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "2px",
    fontSize: "16px",
    width: "100%",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "90%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  expandMoreIcon: {
    position: "absolute",
    right: "58px",
    top: "50px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  nextButton: {
    backgroundColor: "#8BC83F",
    float: "right",
    width: "90%",
    height: "40px",
    border: "0",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "bold",
    marginTop: "31px",
    marginRight: "10%",
    marginBottom: "1.5rem",
  },
  selectPlaceholder: {
    fontSize: "13px !important",
  },
  dropdownInput: {
    paddingLeft: "8px",
    height: "40px",
    color: "#606060",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "100%",
    fontSize: "15px",
    marginBottom: "1.5rem",
  },
  select: {
    position: "relative",
    height: "40px",
    marginTop: "10px",
  },
  expandMoreIconContact: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  inputFieldContact: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "90%",
    paddingTop: "0px",
    marginTop: "10px",
    marginBottom: "15px",
    paddingLeft: "13px",
  },
  columnHeadingContact: {
    color: "#625F5F",
    fontSize: "16px",
    width: "100%",
    marginBottom: 0,
  },
  addButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
};

export default ClientInfo;
