import { connect } from 'react-redux';
import { CaseTemplateScreen } from "../../screens";
import {
    caseTemplateRequestMade,
    closeSnackBarForCaseTemplate,
    deleteCaseTemplateFailure,
    deleteCaseTemplateSuccess,
    fetchCaseTemplateFailure,
    fetchCaseTemplateSuccess
} from "../../actions";
import { CaseTemplateAPI } from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCaseTemplateList: () => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.fetchCaseTemplateList((response, error) => {
                if (response) {
                    dispatch(fetchCaseTemplateSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCaseTemplateFailure(error.msg))
                    } else {
                        dispatch(fetchCaseTemplateFailure(error));
                    }
                }
            })
        },
        deleteCaseTemplate: (templateId, props) => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.deleteCaseTemplate(templateId, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(deleteCaseTemplateFailure(error.msg))
                    } else {
                        dispatch(deleteCaseTemplateFailure(error));
                    }
                } else {
                    props.fetchCaseTemplateList();
                    dispatch(deleteCaseTemplateSuccess(templateId));
                }
            })
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForCaseTemplate(true)),
    };
};


export const CaseTemplateContainer = connect(mapStateToProps, mapDispatchToProps)(CaseTemplateScreen);