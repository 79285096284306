import { connect } from "react-redux";
import ScheduleTransactionScreen from "../../screens/scheduleTransaction/ScheduleTransactionScreen";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
  addNewScheduleTransactionFailure,
  addNewScheduleTransactionSuccess,
  clientRequestMade,
  closeScheduleTransactionSnackBar,
  editScheduleTransactionFailure,
  editScheduleTransactionSuccess,
  fetchScheduleTransactionFailure,
  fetchScheduleTransactionSuccess,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  handleChangeOtherScheduleTransaction,
  handleChangeScheduleDetails,
  handleChangeStatus,
  scheduleTransactionRequestMade,
  searchClientListFailure,
  searchClientListSuccess,
  searchScheduleTransactionFailure,
  searchScheduleTransactionSuccess,
  setScheduleTransactionToEdit,
  vendorRequestMade,
} from "../../actions";
import {
  ActivityLogAPI,
  ClientAPI,
  DynamicActionAPI,
  SettingsAPI,
  TransactionAPI,
  VendorAPI,
} from "../../api";
import {
  clearFileData,
  clearScheduleTransactionFilterForm,
  clearScheduleTransactionInputData,
  clearSchTransFilterData,
  deleteScheduleTransactionFailure,
  deleteScheduleTransactionSuccess,
  exportScheduleTransactionFailure,
  exportScheduleTransactionSuccess,
  fetchScheduleTransactionByClientFailure,
  fetchScheduleTransactionByClientSuccess,
  getSchTransConfigFailure,
  getSchTransConfigSuccess,
  handleChangeBankDetailName,
  handleChangeClientName,
  handleChangeFromDate,
  handleChangeMedium,
  handleChangeScheduleTerm,
  handleChangeScheduleTransactionInputData,
  handleChangeScheduleTypeFilter,
  handleChangeStatusToFilter,
  handleChangeToDate,
  handleChangeTransactionMedium,
  handleChangeTransactionType,
  handleChangeVendorToFilter,
  handleScheduleTransFilter,
  saveSchTransConfigFailure,
  saveSchTransConfigSuccess,
  searchScheduleTransactionByDateFailure,
  searchScheduleTransactionByDateSuccess,
  searchScheduleTransactionByMediumAndDateFailure,
  searchScheduleTransactionByMediumAndDateSuccess,
  searchScheduleTransactionByMediumAndStatusAndDateFailure,
  searchScheduleTransactionByMediumAndStatusAndDateSuccess,
  searchScheduleTransactionByMediumAndStatusFailure,
  searchScheduleTransactionByMediumAndStatusSuccess,
  searchScheduleTransactionByMediumFailure,
  searchScheduleTransactionByMediumSuccess,
  searchScheduleTransactionByStatusAndDateFailure,
  searchScheduleTransactionByStatusAndDateSuccess,
  searchScheduleTransactionByStatusFailure,
  searchScheduleTransactionByStatusSuccess,
  setScheduleTransactionInputDataToEdit,
  updateScheduleTransactionFailure,
  updateScheduleTransactionSuccess,
  uploadDocFileFailure,
  uploadDocFileSuccess,
} from "../../actions/ScheduleTransactionAction";
import {
  getAmountDetailByClientIdSuccess,
  getAmountDetailByClientIdFailure,
  clearAmountDetail,
} from "../../actions/TransactionAction";
import {
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import {
  fetchVendorClientByIdFailure,
  fetchVendorClientByIdSuccess,
} from "../../actions/VendorAction";
import {
  activityLogRequestMade,
  fetchActivityLogByIdFailure,
  fetchActivityLogByIdSuccess,
} from "../../actions/ActivityLogAction";
import { clearClientSingle } from "../../actions/ClientAction";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchScheduleTransactionList: () => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchScheduleTransactionList((response, error) => {
        if (response) {
          dispatch(fetchScheduleTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchScheduleTransactionFailure(error.msg));
          } else {
            dispatch(fetchScheduleTransactionFailure(error));
          }
        }
      });
    },
    fetchScheduleTransactionListByClient: (id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchScheduleTransactionListByClient(
        id,
        (response, error) => {
          if (response) {
            dispatch(fetchScheduleTransactionByClientSuccess(response));
            props.history.push("/schedule/client");
          } else {
            if (error.msg) {
              dispatch(fetchScheduleTransactionByClientFailure(error.msg));
            } else {
              dispatch(fetchScheduleTransactionByClientFailure(error));
            }
          }
        }
      );
    },
    getScheduleTransactionInfo: (scheduleTransactionId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchSingleScheduleTransaction(
        scheduleTransactionId,
        (response, error) => {
          if (response) {
            dispatch(setScheduleTransactionToEdit(response));
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    searchScheduleTransaction: (value) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransaction(
        value,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchScheduleTransactionFailure(error.msg));
            } else {
              dispatch(searchScheduleTransactionFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionSuccess(response));
          }
        }
      );
    },
    searchScheduleTransactionByDate: (from, to) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByDate(
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchScheduleTransactionByDateFailure(error.msg));
            } else {
              dispatch(searchScheduleTransactionByDateFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionByDateSuccess(response));
          }
        }
      );
    },
    searchScheduleTransactionByMedium: (medium) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByMedium(
        medium,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchScheduleTransactionByMediumFailure(error.msg));
            } else {
              dispatch(searchScheduleTransactionByMediumFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionByMediumSuccess(response));
          }
        }
      );
    },
    searchScheduleTransactionByStatus: (status) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByStatus(
        status,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchScheduleTransactionByStatusFailure(error.msg));
            } else {
              dispatch(searchScheduleTransactionByStatusFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionByStatusSuccess(response));
          }
        }
      );
    },
    searchScheduleTransactionByMediumAndStatus: (medium, status) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByMediumAndStatus(
        medium,
        status,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchScheduleTransactionByMediumAndStatusFailure(error.msg)
              );
            } else {
              dispatch(
                searchScheduleTransactionByMediumAndStatusFailure(error)
              );
            }
          } else {
            dispatch(
              searchScheduleTransactionByMediumAndStatusSuccess(response)
            );
          }
        }
      );
    },
    searchScheduleTransactionByMediumAndDate: (medium, from, to) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByMediumAndDate(
        medium,
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchScheduleTransactionByMediumAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchScheduleTransactionByMediumAndDateFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionByMediumAndDateSuccess(response));
          }
        }
      );
    },
    searchScheduleTransactionByStatusAndDate: (status, from, to) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByStatusAndDate(
        status,
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchScheduleTransactionByStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchScheduleTransactionByStatusAndDateFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionByStatusAndDateSuccess(response));
          }
        }
      );
    },

    searchScheduleTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      transactionType
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchScheduleTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        transactionType === "" ? "UNKNOWN_TRANSACTION_TYPE" : transactionType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchScheduleTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchScheduleTransactionByMediumAndStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchScheduleTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },

    filterScheduleTransactionByAll: (
      startDate,
      endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.filterScheduleTransactionByAll(
        startDate === null ? "" : startDate,
        endDate === null ? "" : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchScheduleTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchScheduleTransactionByMediumAndStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchScheduleTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },

    // **************************************
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    deleteScheduleTransaction: (scheduleTransactionId, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.deleteScheduleTransaction(
        scheduleTransactionId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(deleteScheduleTransactionFailure(error.msg));
            } else {
              dispatch(deleteScheduleTransactionFailure(error));
            }
          } else {
            props.fetchScheduleTransactionList();
            dispatch(deleteScheduleTransactionSuccess(scheduleTransactionId));
          }
        }
      );
    },

    //to schedule transaction
    handleChangeClientName: (clientId) =>
      dispatch(handleChangeClientName(clientId)),
    handleChangeScheduleTransaction: (id, value) =>
      dispatch(handleChangeOtherScheduleTransaction(id, value)),
    handleChangeTransactionMedium: (transMediumType) =>
      dispatch(handleChangeTransactionMedium(transMediumType)),
    handleChangeBankDetailName: (name) =>
      dispatch(handleChangeBankDetailName(name)),
    uploadDocumentFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formdata = new FormData();
      formdata.append("doc", selectedFiles.document);
      formdata.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadDocumentFile(formdata, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },

    handleChangeStatus: (status, id) =>
      dispatch(handleChangeStatus(status, id)),
    handleChangeFromDate: (from) => dispatch(handleChangeFromDate(from)),
    handleChangeToDate: (to) => dispatch(handleChangeToDate(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),
    handleChangeStatusToFilter: (status) =>
      dispatch(handleChangeStatusToFilter(status)),
    handleChangeScheduleTypeFilter: (status) =>
      dispatch(handleChangeScheduleTypeFilter(status)),
    handleChangeVendorToFilter: (vendorToFilter) =>
      dispatch(handleChangeVendorToFilter(vendorToFilter)),
    handleChangeTransactionType: (transType) =>
      dispatch(handleChangeTransactionType(transType)),
    handleChangeScheduleTerm: (transType) =>
      dispatch(handleChangeScheduleTerm(transType)),
    clearScheduleTransactionFilterForm: () =>
      dispatch(clearScheduleTransactionFilterForm()),
    handleChangeAddScheduleDetails: (id, value) =>
      dispatch(handleChangeScheduleDetails(id, value)),
    handleChangeScheduleTransactionInputData: (id, input) =>
      dispatch(handleChangeScheduleTransactionInputData(id, input)),
    clearScheduleTransactionInputData: () =>
      dispatch(clearScheduleTransactionInputData()),
    setScheduleTransactionInputDataToEdit: (scheduleTransData) =>
      dispatch(setScheduleTransactionInputDataToEdit(scheduleTransData)),
    updateScheduleTransactionStatus: (statusData, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.updateScheduleTransactionStatus(
        statusData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionList();
            dispatch(editScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(editScheduleTransactionFailure(error.msg));
            } else {
              dispatch(editScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    handleChangeSnackBar: () => {
      dispatch(closeScheduleTransactionSnackBar(true));
    },
    handleSnackBarClose: () => dispatch(closeScheduleTransactionSnackBar(true)),
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
          if (response.client && response.client.bankDetail.length === 1) {
            const e =
              response.client.bankDetail[0] &&
              response.client.bankDetail[0].bankDetailId;
            props.handleChangeScheduleTransactionInputData("bankId", e);
          }
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    clearClientSingle: () => {
      dispatch(clearClientSingle());
    },
    searchClientList: (value) => {
      ScheduleTransactionAPI.searchClientList(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientListFailure(error.msg));
          } else {
            dispatch(searchClientListFailure(error));
          }
        } else {
          dispatch(searchClientListSuccess(response));
        }
      });
    },
    addNewScheduleTransaction: (scheduleTransData, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.addNewScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionList();
            dispatch(addNewScheduleTransactionSuccess(true));
            // dispatch(s)
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    updateScheduleTransaction: (scheduleTransData, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.updateScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionList();
            dispatch(updateScheduleTransactionSuccess(true));
          } else {
            if (error.msg) {
              dispatch(updateScheduleTransactionFailure(error.msg));
            } else {
              dispatch(updateScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    setAddNewScheduleTransactionSuccessToFalse: () =>
      dispatch(addNewScheduleTransactionSuccess(false)),
    clearFileData: () => {
      dispatch(clearFileData());
    },
    //description
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },
    //request transaction by client
    requestScheduleTransaction: (scheduleTransData, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.requestScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionList();
            dispatch(addNewScheduleTransactionSuccess(true));
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
          // if (response) {
          //     props.fetchScheduleTransactionList();
          //     dispatch(requestScheduleTransactionSuccess(true));
          // } else {
          //     if (error.msg) {
          //         dispatch(requestScheduleTransactionFailure(error.msg));
          //     } else {
          //         dispatch(requestScheduleTransactionFailure(error));
          //     }
          // }
        }
      );
    },

    // client vendor
    fetchVendorClientById: (id) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorClientById(id, (response, error) => {
        if (response) {
          dispatch(fetchVendorClientByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorClientByIdFailure(error.msg));
          } else {
            dispatch(fetchVendorClientByIdFailure(error));
          }
        }
      });
    },

    //get amount details by client id
    getAmountDetailByClientId: (clientId) => {
      TransactionAPI.getAmountDetailByClientId(clientId, (response, error) => {
        if (response) {
          dispatch(getAmountDetailByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAmountDetailByClientIdFailure(error.msg));
          } else {
            dispatch(getAmountDetailByClientIdFailure(error));
          }
        }
      });
    },
    clearAmountDetail: () => {
      dispatch(clearAmountDetail());
    },

    fetchActivityLogByRefId: (id) => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLogByRefId(id, (response, error) => {
        if (response) {
          dispatch(fetchActivityLogByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchActivityLogByIdFailure(error.msg));
          } else {
            dispatch(fetchActivityLogByIdFailure(error));
          }
        }
      });
    },
    fetchSingleClientSuccess: () => {
      const response = {
        client: [],
      };
      dispatch(fetchSingleClientSuccess(response));
    },

    // filter sch trans
    handleScheduleTransFilter: (id, value) => {
      dispatch(handleScheduleTransFilter(id, value));
    },
    clearSchTransFilterData: () => {
      dispatch(clearSchTransFilterData());
    },

    // config
    saveInConfig: (data) => {
      ScheduleTransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveSchTransConfigFailure(error.msg));
          } else {
            dispatch(saveSchTransConfigFailure(error));
          }
        } else {
          dispatch(saveSchTransConfigSuccess(response));
        }
      });
    },
    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getSchTransConfigFailure(error.msg));
          } else {
            dispatch(getSchTransConfigFailure(error));
          }
        } else {
          dispatch(getSchTransConfigSuccess(response.configs));
        }
      });
    },

    // Export schedule transaction

    // exportScheduleTransactionList: (exportType) => {
    //   dispatch(transactionRequestMade(true));
    //   TransactionAPI.exportTransaction(exportType, (response, error) => {
    //     if (response) {
    //       window.open(response.transactionExportResponse.url, "_blank");
    //       dispatch(exportTransactionSuccess(response));
    // console.log(response)
    //     } else {
    //       if (error.msg) {
    //         dispatch(exportTransactionFailure(error.msg));
    // console.log(error)
    //       } else {
    //         dispatch(exportTransactionFailure(error));
    // console.log(error)
    //       }
    //     }
    //   });
    // },

    // export schedule transaction by colun filter
    exportScheduleTransactionByColumnFilter: (
      startDate,
      endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType,
      exportType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportScheduleTransactionByColumnFilter(
        startDate === null ? 0 : startDate,
        endDate === null ? 0 : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate,
        scheduleDate === null ? 0 : scheduleDate,
        amount,
        compareType,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    exportScheduleTransactionByAll: (
      term,
      medium,
      status,
      type,
      from,
      to,
      transactionType,
      vendor,
      exportType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportScheduleTransactionByAll(
        term,
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        transactionType,
        vendor,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
  };
};

export const ScheduleTransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleTransactionScreen);
