import {ACTION_TYPE_CONTACT} from "../constants/Type";

export const contactRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_CONTACT.REQUEST_CONTACT,
        bool
    };
};
export const closeSnackBarForContact = (bool) => {
    return {
        type: ACTION_TYPE_CONTACT.CLOSE_CONTACT_SNACKBAR,
        bool
    };
};

//CONTACT
export const handleChangeContactInput = (id, value) =>{
    return{
        type: ACTION_TYPE_CONTACT.HANDLE_CHANGE_CONTACT_INPUT,
        id,
        value,
    }
}
export const fetchContactSuccess = contactList => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_LIST_SUCCESS,
        contactList
    };
};
export const fetchContactFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_LIST_FAILURE,
        error
    };
};

export const addContactSuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_SUCCESS,
        bool,
    };
};
export const addContactFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_FAILURE,
        error
    };
};

export const setContactInputToEdit = contactData => {
    return {
        type: ACTION_TYPE_CONTACT.SET_CONTACT_INPUT_TO_EDIT,
        contactData,
    };
};

export const updateContactSuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_SUCCESS,
        bool
    };
};
export const updateContactFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_FAILURE,
        error
    };
};

export const deleteContactSuccess = contactList => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_CONTACT_SUCCESS,
        contactList
    };
};
export const deleteContactFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_CONTACT_FAILURE,
        error
    };
};

export const setContactDataToEmpty = () => {
    return {
        type: ACTION_TYPE_CONTACT.SET_CONTACT_DATA_TO_EMPTY,
    }
};


//COMPANY
export const handleChangeCompanyInput = (id, value) =>{
    return{
        type: ACTION_TYPE_CONTACT.HANDLE_CHANGE_COMPANY_INPUT,
        id,
        value,
    }
}

export const fetchCompanySuccess = companyList => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_COMPANY_LIST_SUCCESS,
        companyList
    };
};
export const fetchCompanyFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_COMPANY_LIST_FAILURE,
        error
    };
};

export const addCompanySuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_COMPANY_SUCCESS,
        bool
    };
};
export const addCompanyFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_COMPANY_FAILURE,
        error
    };
};

export const setCompanyInputToEdit = companyData => {
    return {
        type: ACTION_TYPE_CONTACT.SET_COMPANY_INPUT_TO_EDIT,
        companyData,
    };
};

export const updateCompanySuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_COMPANY_SUCCESS,
        bool
    };
};
export const updateCompanyFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_COMPANY_FAILURE,
        error
    };
};

export const deleteCompanySuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_COMPANY_SUCCESS,
        response
    };
};
export const deleteCompanyFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_COMPANY_FAILURE,
        error
    };
};

export const deleteCompanyContactSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_COMPANY_CONTACT_SUCCESS,
        response
    };
};
export const deleteCompanyContactFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_COMPANY_CONTACT_FAILURE,
        error
    };
};

export const searchCompanySuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.SEARCH_COMPANY_SUCCESS,
        response
    };
};
export const searchCompanyFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.SEARCH_COMPANY_FAILURE,
        error
    };
};

export const searchContactSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.SEARCH_CONTACT_SUCCESS,
        response
    };
};
export const searchContactFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.SEARCH_CONTACT_FAILURE,
        error
    };
};

export const setContactAccountSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.SET_ACCOUNT_FOR_CONTACT_SUCCESS,
        response
    };
};
export const setContactAccountFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.SET_ACCOUNT_FOR_CONTACT_FAILURE,
        error
    };
};

//RELATIONSHIP
export const handleChangeRelationshipName = ( name) =>{
    return{
        type: ACTION_TYPE_CONTACT.HANDLE_CHANGE_RELATIONSHIP_NAME,
        name,
    }
};
export const addRelationshipSuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_RELATIONSHIP_SUCCESS,
        bool
    };
};
export const addRelationshipFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_RELATIONSHIP_FAILURE,
        error
    };
};
export const updateRelationshipSuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_RELATIONSHIP_SUCCESS,
        bool
    };
};
export const updateRelationshipFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_RELATIONSHIP_FAILURE,
        error
    };
};
export const fetchRelationshipSuccess = relationshipList => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_RELATIONSHIP_SUCCESS,
        relationshipList
    };
};
export const fetchRelationshipFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_RELATIONSHIP_FAILURE,
        error
    };
};
export const setRelationshipToEdit = relationship => {
    return {
        type: ACTION_TYPE_CONTACT.SET_RELATIONSHIP_TO_EDIT,
        relationship
    };
};
export const getRelationshipPermissionSuccess = permissionList => {
    return {
        type: ACTION_TYPE_CONTACT.GET_PERMISSION_FOR_RELATIONSHIP_SUCCESS,
        permissionList
    };
};

export const getRelationshipPermissionFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.GET_PERMISSION_FOR_RELATIONSHIP_FAILURE,
        error
    };
};
export const setRelationshipPermissionSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.SET_PERMISSION_FOR_RELATIONSHIP_SUCCESS,
        response,
    };
};

export const setRelationshipPermissionFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.SET_PERMISSION_FOR_RELATIONSHIP_FAILURE,
        error
    };
};

export const handleChangePermissionName = (permissionName) => {
    return{
        type: ACTION_TYPE_CONTACT.HANDLE_CHANGE_PERMISSION_NAME,
        permissionName,
    }
}


//CONTACTS FILES
export const handleChangeContactFiles = (id, value) =>{
    return{
        type: ACTION_TYPE_CONTACT.HANDLE_CHANGE_CONTACT_FILE,
        id,
        value,
    }
};
export const addContactFileSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_FILE_SUCCESS,
        response
    };
};
export const addContactFileFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_FILE_FAILURE,
        error
    };
};
export const updateContactFileSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_FILE_SUCCESS,
        response
    };
};
export const updateContactFileFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_FILE_FAILURE,
        error
    };
};
export const deleteContactFileSuccess = response => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_CONTACT_FILE_SUCCESS,
        response
    };
};
export const deleteContactFileFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.DELETE_CONTACT_FILE_FAILURE,
        error
    };
};
export const fetchContactFileSuccess = contactFilesList=> {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_FILE_SUCCESS,
        contactFilesList
    };
};
export const fetchContactFileFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_FILE_FAILURE,
        error
    };
};
export const setContactFileToEdit = files => {
    return {
        type: ACTION_TYPE_CONTACT.SET_CONTACT_FILE_TO_EDIT,
        files
    };
};

//COMPANY TYPE
export const handleChangeCompanyType = ( name) =>{
    return{
        type: ACTION_TYPE_CONTACT.HANDLE_CHANGE_COMPANY_TYPE_NAME,
        name,
    }
};
export const addCompanyTypeSuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_COMPANY_TYPE_SUCCESS,
        bool
    };
};
export const addCompanyTypeFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.ADD_CONTACT_COMPANY_TYPE_FAILURE,
        error
    };
};
export const updateCompanyTypeSuccess = bool => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_COMPANY_TYPE_SUCCESS,
        bool
    };
};
export const updateCompanyTypeFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.UPDATE_CONTACT_COMPANY_TYPE_FAILURE,
        error
    };
};
export const fetchCompanyTypeSuccess = companyTypeList => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_COMPANY_TYPE_SUCCESS,
        companyTypeList
    };
};
export const fetchCompanyTypeFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.FETCH_CONTACT_COMPANY_TYPE_FAILURE,
        error
    };
};
export const searchCompanyTypeSuccess = companyTypeList => {
    return {
        type: ACTION_TYPE_CONTACT.SEARCH_CONTACT_COMPANY_TYPE_SUCCESS,
        companyTypeList
    };
};
export const searchCompanyTypeFailure = error => {
    return {
        type: ACTION_TYPE_CONTACT.SEARCH_CONTACT_COMPANY_TYPE_FAILURE,
        error
    };
};
export const setCompanyTypeToEdit = companyType => {
    return {
        type: ACTION_TYPE_CONTACT.SET_COMPANY_TYPE_TO_EDIT,
        companyType
    };
};



