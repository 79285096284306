import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class ScheduleTransactionAPI extends API {
  fetchScheduleTransactionList = (callback) => {
    fetch(APIEndPoints.FETCH_SCHEDULE_TRANSACTION, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  fetchScheduleTransactionListByClient = (id, callback) => {
    fetch(APIEndPoints.FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_ID + id, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  addNewScheduleTransaction = (scheduleTransData, callback) => {
    let body = JSON.stringify(scheduleTransData);
    console.log("scheduleTransData", scheduleTransData);
    fetch(APIEndPoints.ADD_SCHEDULE_TRANSACTION, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add User api", error);
        callback(null, "Service error, please try again.");
      });
  };
  updateScheduleTransaction = (scheduleTransData, callback) => {
    let body = JSON.stringify(scheduleTransData);
    fetch(APIEndPoints.UPDATE_SCHEDULE_TRANSACTION_FORM, {
      method: "PATCH",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add User api", error);
        callback(null, "Service error, please try again.");
      });
  };

  requestScheduleTransaction = (scheduleTransData, callback) => {
    let body = JSON.stringify(scheduleTransData);
    fetch(APIEndPoints.REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add User api", error);
        callback(null, "Service error, please try again.");
      });
  };
  updateScheduleTransactionStatus = (statusData, callback) => {
    let body = JSON.stringify(statusData);
    console.log("scheduleTransData", statusData);
    fetch(APIEndPoints.UPDATE_SCHEDULE_TRANSACTION, {
      method: "PATCH",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add User api", error);
        callback(null, "Service error, please try again.");
      });
  };

  fetchSingleScheduleTransaction = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_SCHEDULE_TRANSACTION_BY_ID + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  deleteScheduleTransaction = (scheduleTransactionId, callback) => {
    const urlString =
      APIEndPoints.DELETE_SCHEDULE_TRANSACTION + scheduleTransactionId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  searchScheduleTransaction = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_SCHEDULE_TRANSACTION + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // search by date
  searchScheduleTransactionByDate = (from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_FROM_DATE + from + "&to=" + to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // search by medium
  searchScheduleTransactionByMedium = (medium, callback) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM + medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // search by STATUS
  searchScheduleTransactionByStatus = (status, callback) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS + status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // search by medium and status
  searchScheduleTransactionByMediumAndStatus = (medium, status, callback) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM +
      medium +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // search by medium and date
  searchScheduleTransactionByMediumAndDate = (medium, from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM +
      medium +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // search by STATUS and DATE
  searchScheduleTransactionByStatusAndDate = (status, from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS +
      status +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // search by medium and status
  searchScheduleTransactionByAll = (
    medium,
    status,
    type,
    from,
    to,
    vendor,
    transactionType,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&transactionType=" +
      transactionType +
      "&vendor=" +
      vendor;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // filter by medium and status
  filterScheduleTransactionByAll = (
    startDate,
    endDate,
    clientName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    scheduleDate,
    amount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FILTER_BY_ALL +
      "startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&clientName=" +
      clientName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      // "&transactionType=" +
      "&txnType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&scheduleDate=" +
      scheduleDate +
      "&amount=" +
      amount +
      "&compareType=" +
      compareType;

    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  filterScheduleTransactionByClient = (
    id,
    startDate,
    endDate,
    clientName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    scheduleDate,
    amount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_ID +
      id +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&clientName=" +
      clientName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      // "&transactionType=" +
      "&txnType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&scheduleDate=" +
      scheduleDate +
      "&amount=" +
      amount +
      "&compareType=" +
      compareType;

    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  //******SEARCH WITH CLIENT ID
  searchClientScheduleTransaction = (value, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      " ?term=" +
      value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  //BY DATE
  searchClientScheduleTransactionByDate = (from, to, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION_FROM_DATE +
      clientId +
      "?from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //BY MEDIUM
  searchClientScheduleTransactionByMedium = (medium, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //BY STATUS
  searchClientScheduleTransactionByStatus = (status, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //BY MEDIUM AND STATUS
  searchClientScheduleTransactionByMediumAndStatus = (
    medium,
    status,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?medium=" +
      medium +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //BY MEDIUM AND DATE
  searchClientScheduleTransactionByMediumAndDate = (
    medium,
    from,
    to,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?medium=" +
      medium +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //BY STATUS AND DATE
  searchClientScheduleTransactionByStatusAndDate = (
    status,
    from,
    to,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?status=" +
      status +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //BY MEDIUM AND STATUS AND DATE
  searchClientScheduleTransactionByAll = (
    medium,
    status,
    type,
    from,
    to,
    vendor,
    clientId,
    transactionType,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?medium=" +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&transactionType=" +
      transactionType +
      "&vendor=" +
      vendor;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientList = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_CLIENT_LIST + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // EXPORT TRANSACTION BY ALL
  exportScheduleTransactionByAll = (
    term,
    medium,
    status,
    type,
    from,
    to,
    transactionType,
    vendor,
    exportType,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SCHEDULE_TRANSACTION +
      term +
      "&medium=" +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&transactionType=" +
      transactionType +
      "&vendor=" +
      vendor +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // EXPORT TRANSACTION BY COLUMN FILTER
  exportScheduleTransactionByColumnFilter = (
    startDate,
    endDate,
    clientName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    scheduleDate,
    amount,
    compareType,
    exportType,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SCHEDULE_TRANSACTION +
      "" +
      // "&startDate=" +
      // startDate +
      // "&endDate=" +
      // endDate +
      "&clientName=" +
      clientName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      // "&transactionType=" +
      "&txnType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      // "&transactionDate=" +
      // transactionDate +
      // "&scheduleDate=" +
      // scheduleDate +
      "&amount=" +
      amount +
      "&compareType=" +
      compareType +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // EXPORT TRANSACTION BY Client id

  exportAllClientScheduleTransaction = (clientId, exportType, callback) => {
    const urlString =
      APIEndPoints.EXPORT_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  exportClientScheduleTransaction = (
    clientId,
    term,
    medium,
    status,
    type,
    from,
    to,
    transactionType,
    vendor,
    exportType,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      "?term=" +
      term +
      "&medium=" +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&transactionType=" +
      transactionType +
      "&vendor=" +
      vendor +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // export by column filter
  exportClientScheduleTransactionByColumnFilter = (
    clientId,
    startDate,
    endDate,
    clientName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    scheduleDate,
    amount,
    compareType,
    exportType,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_CLIENT_SCHEDULE_TRANSACTION +
      clientId +
      // "?startDate=" +
      // startDate +
      // "&endDate=" +
      // endDate +
      "?clientName=" +
      clientName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      // "&transactionType=" +
      "&txnType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      // "&transactionDate=" +
      // transactionDate +
      // "&scheduleDate=" +
      // scheduleDate +
      "&amount=" +
      amount +
      "&compareType=" +
      compareType +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // save config
  saveConfig = (data, callback) => {
    const urlString =
      APIEndPoints.SAVE_FILTER_CONFIG +
      "startDate=" +
      data.startDate +
      "&endDate=" +
      data.endDate +
      "&clientName=" +
      data.clientName +
      "&vendorName=" +
      data.vendorName +
      "&amount=" +
      data.amount +
      "&transactionMedium=" +
      data.transactionMedium +
      "&transactionType=" +
      data.transactionType +
      "&transactionStatus=" +
      data.transactionStatus +
      "&scheduleDate=" +
      data.scheduleDate +
      // "&transactionDate=" +
      // data.transactionDate +
      "&configName=" +
      data.configName +
      "&type=" +
      data.type +
      "&compareType=" +
      data.compareType +
      "&sortBy=" +
      data.sortBy;
    fetch(urlString, {
      method: "POST",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
}

export default new ScheduleTransactionAPI();
