import { ACTION_TYPE_RESET_PASSWORD } from "../constants/Type";

const initialState = {
    loading: false,
    user: [],
    error: "",
    resetInfo: {},
    accountData: '',
    openResetSnackBarError: false,
    fetchUserInfoSuccess: false,
    openPasswordResetSuccessSnackBar: false
};

const resetPasswordState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_RESET_PASSWORD.HANDLE_CHANGE_FOR_RESET_PASSWORD_REQUEST:
            const newForm = { ...state.accountData };
            newForm[action.id] = action.value;
            return {
                ...state,
                accountData: newForm,
                loading: false
            };
        case ACTION_TYPE_RESET_PASSWORD.FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.userInfo.user,
                fetchUserInfoSuccess: true,
                loading: false,
                openResetSnackBarError: false,
                error: ""
            };
        case ACTION_TYPE_RESET_PASSWORD.CLOSE_SNACK_BAR_RESET_PASSWORD_PAGE:
            return {
                ...state,
                error: "",
                loading: false,
                openResetSnackBarError: false,
                openPasswordResetSuccessSnackBar: false,
            };

        case ACTION_TYPE_RESET_PASSWORD.RESET_FIRST_PASSWORD_SUCCESS:
            return {
                ...state,
                bool: action.bool,
                openPasswordResetSuccessSnackBar: true,
                openResetSnackBarError: false,
                loading: false,
            };

        case ACTION_TYPE_RESET_PASSWORD.RESET_FIRST_PASSWORD_FAILURE:
            return {
                ...state,
                openResetSnackBarError: true,
                loading: false,
                error: action.error,
                openPasswordResetSuccessSnackBar: false,
            };
        case ACTION_TYPE_RESET_PASSWORD.REQUEST_MADE_FOR_REQUEST_PASSWORD_RESET:
            return {
                ...state,
                openResetSnackBarError: false,
                openPasswordResetSuccessSnackBar: false,
                loading: true,
            };
        default:
            return state;
    }
};

export default resetPasswordState;
