import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class CorporateAPI extends API {
    fetchCorporateList = callback => {
        fetch(APIEndPoints.FETCH_CORPORATE, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchSingleCorporate = (id,callback) => {
        var constUrl = APIEndPoints.FETCH_CORPORATE_BY_ID + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                console.log(error);
                callback(null, "Service error, please try again.");
            });
    };

    addNewCorporate = (corporateData, callback) => {
        let body = JSON.stringify(corporateData);
        fetch(APIEndPoints.ADD_CORPORATE, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteCorporate = (corporateId, callback) => {
        const urlString = APIEndPoints.DELETE_USER + corporateId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    editCorporate = ( corporateFormData,callback) => {
        fetch( APIEndPoints.UPDATE_CORPORATE ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(corporateFormData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    //search corporate
    searchCorporate = (value, callback) => {
        const urlString = APIEndPoints.SEARCH_CORPORATE + value;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };
}

export default new CorporateAPI();