import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import {Checkbox, CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {Prompt} from "react-router";
import Typography from "@material-ui/core/Typography";
import {Select} from "antd";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import ActionEditFormFieldsComponent from "./ActionEditFormFieldsComponent";
import {HelmetComponent} from "../components/Helmet";

const {Option} = Select;

class ActionEditFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownCount: 0,
            openDropdownForAction: false,
            fieldId: '',
            actionForms: [],
            updatedActionForm:[],
            fieldName: '',
            fieldType: '',
            actionToEdit: props.location.dataToPass,
            setValue: false,
            isBlocking: false,
            openDialogueToAddOptions:false,
            activeOptionId: '',
            activeRefId:'',
            activeActionId: "",
            activeOptionName: "",
            openDialogueForNewEditActionForm: false,
        };

        if (props.location.pathname.replace('/action/edit/', '').length > 0) {
            this.props.getActionInfo(props.location.pathname.replace('/action/edit/', ''))
        }
    };

    componentDidMount() {
        this.props.searchExistingOptionValue("");
    }
    componentWillUnmount() {
        this.props.handleSnackBarClose();
        this.props.setActionData({
            actionName: '',
            actionForms: []
        })
    }
    render() {
        const actionState = this.props.state.actionState.actionData;
        const optionValues = this.props.state && this.props.state.actionState.optionValues;
        const conditionalFormByRefId = this.props.state &&
            this.props.state.actionState.conditionalFormByRefId;
        const conditionalActionForm = this.props.state &&
            this.props.state.actionState.conditionalActionForm;

        const setConditionalByRefId  = (conditionalData) => {
            this.props.setConditionalByRefId(conditionalData, this.props)
        }

        const changeActiveValues = (optionId, actionId, refId, activeOptionName) => {
            this.setState({
                activeOptionName: activeOptionName,
                activeOptionId: optionId,
                activeRefId: refId,
                activeActionId: actionId,
            })
        }

        const setPreviousValue = (optionId, actionId, refId, optionName) =>{
            this.props.setPreviousValue(optionId, actionId, refId, optionName)
        }

        const getConditionalFormByRefId = (refId, optionValueId, actionId, step,
                                           prevOptionId, prevActionId, prevRefId, prevOptionName) => {
            this.props.getConditionalFormByRefId(refId, optionValueId, actionId, step, prevOptionId,
                prevActionId, prevRefId, prevOptionName);
        }

        const openDropdownForTextBox = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.actionForms.length > 0) {
                div.push(<div className="row" style={style.fieldLabel}>
                    <div className="col-4" style={{position: 'inherit'}}>
                        Field Title
                    </div>
                    <div className="col-4" style={{position: 'inherit', paddingLeft: '20px'}}>
                        Field Type
                    </div>
                    <div className="col-3" style={{position: 'inherit'}}>
                        Is Required
                    </div>
                </div>);
            }
            if (this.state.actionForms) {
                this.state.actionForms.map(single => {
                    const value = single.required ?? false;
                    return (
                        div.push(<>
                            <div className="row">
                                <div className="col-4" style={{position: 'inherit'}}>
                                    <input type="text"
                                           id={single.actionFormId}
                                           value={single.fieldName}
                                           onChange={(e) => {
                                               e.preventDefault();
                                               this.setState({isBlocking: true});
                                               changeFieldNameValue(single.actionFormId, e.target.value);
                                               changeActionFormId(single.actionFormId)
                                           }}
                                           style={style.dropdownInput}/>
                                </div>
                                <div className="col-4" style={{position: 'inherit'}}>
                                    <div style={style.select}>
                                        <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                            <Select
                                                id={single.actionFormId}
                                                value={single.fieldType}
                                                onChange={e => {
                                                    this.setState({isBlocking: true});
                                                    changeFieldTypeValue(single.actionFormId, e)
                                                    changeActionFormId(single.actionFormId)
                                                }}>
                                                <Option value="TEXT_FIELD">Text Field</Option>
                                                <Option value="EMAIL">Email</Option>
                                                <Option value="PHONE">Phone</Option>
                                                <Option value="PHOTO">Photo</Option>
                                                <Option value="DOCUMENT">Document</Option>
                                                <Option value="DROPDOWN">Dropdown</Option>
                                                <Option value="CHECKBOX">Checkbox</Option>
                                                <Option value="RADIO_BUTTON">Radio Button</Option>
                                                <Option value="SSN">SSN</Option>
                                                <Option value="DOB">Date-of-birth</Option>
                                                <Option value="CONTACT">Contact</Option>
                                                <Option value="CURRENCY">Currency</Option>
                                                <Option value="AMOUNT">Amount</Option>
                                                <Option value="CONTENT">Content</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2"
                                     style={{
                                         textAlign: 'center',
                                         fontSize: '16px',
                                         position: 'inherit',
                                         paddingRight: '35px'
                                     }}>
                                    <input type="checkbox" className="form-check-input" id={single.actionFormId}
                                           checked={value}
                                           hidden={single.fieldType === 'CONTENT'}
                                           onChange={() => {
                                               this.setState({isBlocking: true});
                                               changeFieldRequiredValue(single.actionFormId, !value)
                                               changeActionFormId(single.actionFormId)
                                           }}
                                           style={{
                                               fontSize: '16px',
                                               marginTop: '10px',
                                               marginLeft: '0',
                                               position: 'inherit'
                                           }}/>
                                </div>
                                <div className="col-2">
                                    <RemoveCircleOutlineIcon
                                        style={{color: 'grey', width: '24px', height: '24px', cursor: 'pointer'}}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            closeFieldSection(single.actionFormId);
                                        }}
                                    />
                                </div>
                            </div>
                            <div id={single.id} hidden={single.fieldType !== "DROPDOWN"}
                                 className="row mb-4"
                                 style={{marginTop: "-12px"}}>
                                <div className="col-8">
                                    <div className="multiple-select">
                                        <Select
                                            mode="tags"
                                            size="default"
                                            placeholder="Add options"
                                            getPopupContainer={node => node.parentNode}
                                            value={single.optionValue &&
                                            single.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                            onChange={(e) => {
                                                const optionValue = e.map(el => {
                                                    const name =   optionValues && optionValues.filter(value=>
                                                        value.optionValueId === el)
                                                    if(name.length === 0){
                                                        return{
                                                            optionValueName: el
                                                        }
                                                    }else{
                                                        return{
                                                            optionValueId: el,
                                                            optionValueName: name[0].optionValueName
                                                        }
                                                    }

                                                })
                                                changeFieldOptionValue(single.actionFormId,optionValue);
                                                changeActionFormId(single.actionFormId)
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                optionValues && optionValues.map(value =>
                                                    <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div id={single.id} hidden={single.fieldType !== "RADIO_BUTTON"}
                                 className="row mb-4"
                                 style={{marginTop: "-12px"}}>
                                <div className="col-8">
                                    <div className="multiple-select">
                                        <Select
                                            mode="tags"
                                            size="default"
                                            placeholder="Add options"
                                            getPopupContainer={node => node.parentNode}
                                            value={single.optionValue &&
                                            single.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                            onChange={(e) => {
                                                const optionValue = e.map(el => {
                                                    const name = optionValues && optionValues.filter(value=>
                                                        value.optionValueId === el)
                                                    if(name.length === 0){
                                                        return{
                                                            optionValueName: el
                                                        }
                                                    }else{
                                                        return{
                                                            optionValueId: el,
                                                            optionValueName: name[0].optionValueName
                                                        }
                                                    }

                                                })
                                                changeFieldOptionValue(single.actionFormId, optionValue);
                                                changeActionFormId(single.actionFormId)
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                optionValues && optionValues.map(value =>
                                                    <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div id={single.id} hidden={single.fieldType !== "CHECKBOX"}
                                 className="row mb-4"
                                 style={{marginTop: "-12px"}}>
                                <div className="col-8">
                                    <div className="multiple-select">
                                        <Select
                                            mode="tags"
                                            size="default"
                                            placeholder="Add options"
                                            getPopupContainer={node => node.parentNode}
                                            value={single.optionValue && single.optionValue.map(value=>
                                                value.optionValueId || value.optionValueName)}
                                            onChange={(e) => {
                                                const optionValue = e.map(el => {
                                                    const name = optionValues && optionValues.filter(value =>
                                                        value.optionValueId === el)
                                                    if (name.length === 0) {
                                                        return {
                                                            // optionValueId: String(Math.random()),
                                                            optionValueName: el,
                                                        }
                                                    } else {
                                                        return {
                                                            optionValueId: el,
                                                            optionValueName: name[0].optionValueName,
                                                        }
                                                    }
                                                })
                                                changeFieldOptionValue(single.actionFormId, optionValue);
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {
                                                optionValues && optionValues.map(value =>
                                                    <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            { single.actionFormId && single.actionFormId.substring(0,2) === "0." ? null :
                            <div  id={single.id} className="mt-3 mb-4" hidden={single.fieldType !== 'CHECKBOX'}>
                                <ul style={{listStyleType: "none", paddingLeft: "0px", marginLeft: "-10px"}}>
                                    {single.optionValue && single.optionValue.map(option =>
                                        <li id={option.optionValueId}>
                                            <Checkbox
                                                onChange={(event) =>
                                                {
                                                    this.setState({
                                                        activeOptionId: option.optionValueId,
                                                        activeActionId: single.actionFormId,
                                                        activeOptionName: option.optionValueName,
                                                        checkedValue: event.target.checked,
                                                        activeRefId: option.refId,
                                                    }, () => {
                                                        handleOpenDialogueForNewEditActionForm();
                                                        if(this.state.checkedValue){
                                                            getConditionalFormByRefId(option.refId,
                                                                option.optionValueId, single.actionFormId,
                                                                "firstStep", "","",
                                                                "","");
                                                        }
                                                    })
                                                    changeFieldTypeCheckedValue(single.actionFormId ,option.optionValueId, event.target.checked)
                                                }}
                                            />
                                            {option.optionValueName}
                                            <span onClick={() => {
                                                this.setState({
                                                    activeOptionId: option.optionValueId,
                                                    activeActionId: single.actionFormId,
                                                    activeRefId : option.refId,
                                                    singleFieldObject: single,
                                                })
                                                getConditionalFormByRefId(option.refId,
                                                    option.optionValueId, single.actionFormId, "firstStep");
                                                handleOpenDialogueForNewEditActionForm()
                                            }}  hidden={!option.checkedValue}>
                                            <EditOutlinedIcon
                                                style={{
                                                    paddingLeft:"12px",
                                                    width: "30px",
                                                    paddingBottom: "4px",
                                                    cursor:"pointer"}}
                                            />
                                        </span>
                                        </li>
                                    )}
                                </ul>
                            </div>}
                            <div className="row mb-4" style={{marginTop: "-12px"}}
                                 id={single.id}
                                 hidden={single.fieldType !== 'AMOUNT'}
                            >
                                <div className="col-8">
                                    <div className="multiple-select h40">
                                        <Select
                                            showArrow
                                            size="default"
                                            placeholder="Select amount type"
                                            value={single.amountType}
                                            filterOption={(input, option) =>
                                                option.children &&
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(e)=>{
                                                changeFieldAmountTypeValue(single.actionFormId, e)
                                            }}
                                            getPopupContainer={node => node.parentNode}
                                            style={{width: '100%'}}
                                        >
                                            <Option value="INCOME">Income</Option>
                                            <Option value="ASSET">Asset</Option>
                                            <Option value="EXPENSE">Expense</Option>
                                            <Option value="IGNORE">Ignore</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3" style={{marginTop: "-12px"}}
                                 id={single.id}
                                 hidden={single.fieldType !== 'CONTENT'}
                            >
                                <div className="col-8">
                                    <div className="multiple-select">
                                    <textarea
                                        className="inputField"
                                        value={single.content}
                                        onChange={(e)=>{
                                            changeFieldContentValue(single.actionFormId, e.target.value)
                                        }}
                                        style={{minHeight: '150px', padding:'15px'}}/>
                                    </div>
                                </div>
                            </div>
                        </>)
                    )
                });
            }
            return div
        };
        const changeFieldNameValue = (key, value) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            newAction.fieldName = value;
            filterFieldType();
        };
        const changeFieldTypeValue = (key, value) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            newAction.fieldType = value;
            filterFieldType();
        };
        //change amount type
        const changeFieldAmountTypeValue = (key, value) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            newAction.amountType = value;
            filterFieldType();
        };
        //change content type
        const changeFieldContentValue = (key, value) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            newAction.content = value;
            filterFieldType();
        };
        const changeFieldRequiredValue = (key, value) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            newAction.required = value;
            filterFieldType();
        };
        const changeActionFormId= (key) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            newAction.actionFormId = key;
            filterFieldType();
        };
        const changeFieldOptionValue = (key, value) => {
            let newAction = this.state.actionForms.filter(single => single.actionFormId === key)[0];
            if(value && value.length > 0){
                newAction.optionValue = value && value.map(opt => {
                    const data = {
                        optionValueName: opt.optionValueName,
                    }
                    if(opt.refId !== undefined){
                        data.refId = opt.refId
                    }else if(opt.optionValueId !== undefined){
                        data.optionValueId = opt.optionValueId
                    }
                    return data;
                });
            }
            filterFieldType();
        };
        const filterFieldType = () =>{
            let resultA = this.state.updatedActionForm.filter(elm =>
                this.state.actionForms.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm))).map(final => {
                const data = {
                    actionFormId: final.actionFormId,
                    fieldName: final.fieldName,
                    fieldType: final.fieldType,
                    orderNo: final.orderNo,
                    required: !!final.required,
                }
                if(final.amountType !== undefined){
                    data.amountType= final.amountType
                }else if(final.optionValue !== undefined){
                    data.optionValue = final.optionValue && final.optionValue.map(opt => {
                        return {
                            optionValueId: opt.optionValueId,
                            optionValueName: opt.optionValueName,
                            refId: opt.refId ? opt.refId : '',
                        }
                    })
                }else if(final.content !== undefined){
                    data.content= final.content
                }
                return data
            });
            let resultB = this.state.actionForms.filter(elm =>
                !this.state.updatedActionForm.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm))).map(
                final => {
                    const data = {
                        fieldName: final.fieldName,
                        fieldType: final.fieldType,
                        orderNo: final.orderNo,
                        required: !!final.required,
                    }
                    if(final.amountType !== undefined){
                        data.amountType= final.amountType
                    }else if(final.optionValue !== undefined){
                        data.optionValue = final.optionValue && final.optionValue.map(opt => {
                            return {
                                optionValueId: opt.optionValueId,
                                optionValueName: opt.optionValueName,
                                refId: opt.refId ? opt.refId : '',
                            }
                        })
                    }else if(final.content !== undefined){
                        data.content= final.content
                    }
                    return data
                });
            let actionEditForm = [...resultA, ...resultB];
            this.props.handleChangeAction("actionForms", actionEditForm)
        }
        const changeFieldTypeCheckedValue = (key, activeOptionId, checkedValue) => {
            if( this.state.actionForms.filter(single => single.actionFormId === key)[0] &&
                this.state.actionForms.filter(single => single.actionFormId === key)[0].optionValue.
            filter(obj => obj.optionValueId === activeOptionId)[0]){
                this.state.actionForms.filter(single => single.actionFormId === key)[0].optionValue.
                filter(obj => obj.optionValueId === activeOptionId)[0].checkedValue = checkedValue;
                filterFieldType();
            }
        };
        const createAddFormObject = () => {
            return {
                actionFormId: String(Math.random()),
                fieldName: '',
                fieldType: '',
                orderNo: this.state.actionForms.length + 1,
            }
        };
        const showInputForField = () => {
            let a = createAddFormObject();
            this.setState({
                actionForms: [...this.state.actionForms, a]
            });
        };
        const editActionAPICALL = () => {
            this.props.editAction(this.props.state.actionState.actionData, this.props)
        };
        const closeFieldSection = (key) => {
            var previousOrder = this.state.actionForms.filter(
                single => single.actionFormId === key
            )[0].orderNo;
            var removedActionData = this.state.actionForms.filter(x => x.id !== key)
            var lessThanOrder = removedActionData.filter(x => x.orderNo < previousOrder)
            var orderFiltered = removedActionData.filter(x => x.orderNo > previousOrder)
            orderFiltered.map(single => {
                single.orderNo = single.orderNo - 1
            })
            lessThanOrder = [...lessThanOrder, ...orderFiltered]
            this.setState({
                actionForms: lessThanOrder
            }, () => {
                this.props.handleChangeAction("actionForms", this.state.actionForms)
            })

        };

        const handleCloseDialogueForNewEditActionForm = () =>{
            this.setState({
                openDialogueForNewEditActionForm: false
            })
        }
        const handleOpenDialogueForNewEditActionForm = () =>{
            if(this.state.checkedValue){
                this.setState({
                    openDialogueForNewEditActionForm: true,
                })
            }
        }
        const setEditedConditionalForm = (currentActionId, currentOptionId,conditionalActionForm) =>{
            this.props.setEditedConditionalForm(currentActionId, currentOptionId,conditionalActionForm)
        }

        return (
            <React.Fragment>
                <HelmetComponent title="Action"/>
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />
                {this.props.state.actionState.loading ?
                    <div className={this.props.state.actionState.loading ? 'loader' : 'loader hidden'} id="loader">
                        <CircularProgress style={{color: '#8BC83F'}}/>
                    </div> : null
                }
                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/action" style={style.link}>
                        Action
                    </Link>
                    <Typography color="inherit" className="link">
                        Edit Action
                    </Typography>
                </Breadcrumbs>

                <div style={style.container}>
                    {this.state.setValue === false && actionState && actionState.actionForms
                    && actionState.actionForms.length > 0 ?
                        this.setState({
                            actionForms: actionState.actionForms,
                            updatedActionForm: actionState.actionForms,
                            setValue: true
                        }) : null}

                    <div className={"row"}>
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <label style={style.label}>Action Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id='actionName'
                                value={this.props.state.actionState.actionData ?
                                    this.props.state.actionState.actionData.actionName :
                                    null}
                                onChange={e => {
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeAction(e.target.id, e.target.value)
                                }}
                                style={style.input}
                            />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-5" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            {openDropdownForTextBox()}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <div style={{float: 'right', cursor: 'pointer'}} onClick={(e) => {
                                e.preventDefault();
                                showInputForField();
                            }}>
                                <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                     style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Field</span>
                            </div>
                            <div className={"row"} style={{
                                justifyContent: 'flex-end',
                                padding: '2rem 0 1rem 0',
                                marginLeft: 0,
                                width: '100%'
                            }}>
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: false});
                                        editActionAPICALL();
                                    }}
                                    style={style.button}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ************ CONDITIONAL FORM MODAL************ */}
                <Dialog
                    id={this.state.indexId}
                    open={this.state.openDialogueForNewEditActionForm}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                    PaperComponent={PaperComponent}
                    onClose={handleCloseDialogueForNewEditActionForm}
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseDialogueForNewEditActionForm}>
                            {this.state.activeOptionName}
                        </DialogTitleComponent>
                        <DialogContent dividers style={{ width: "700px", maxHeight: "500px"}}>
                            {conditionalActionForm && conditionalActionForm.length > 0
                            && conditionalActionForm.map(item =>
                                item.actionId === this.state.activeActionId &&
                                (item.optionId === this.state.activeOptionId || item.refId === this.state.activeRefId) && (
                                    <div>
                                        <ActionEditFormFieldsComponent
                                            setConditionalByRefId={setConditionalByRefId}
                                            conditionalFormByRefId={conditionalFormByRefId}
                                            conditionalActionForm={item}
                                            getConditionalFormByRefId={getConditionalFormByRefId}
                                            handleCloseDialogueForNewActionForm={handleCloseDialogueForNewEditActionForm}
                                            optionValues={optionValues}
                                            activeOptionId={this.state.activeOptionId}
                                            activeActionFormId={this.state.activeActionId}
                                            activeOptionName={this.state.activeOptionName}
                                            activeRefId={this.state.activeRefId}
                                            changeActiveValues={changeActiveValues}
                                            setPreviousValue={setPreviousValue}
                                            setEditedConditionalForm={setEditedConditionalForm}
                                        />
                                    </div>
                                ))}
                        </DialogContent>
                    </div>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.actionState.openActionSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.actionState.errorMsgForAction}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    container: {
        background: "#ffffff",
        marginTop: "78px",
        marginLeft: '3.5%',
        marginRight: '3.5%'
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    label: {
        color: '#606060',
        marginBottom: '0',
        fontSize: '16px',
        paddingBottom: "0.5rem"
    },
    input: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        color: "#606060",
        width: '100%',
        marginBottom: '1.2rem'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
        marginTop: '4px'
    },
    select: {
        position: "relative",
        height: "40px"
    },
    selectedAction: {
        border: '1px solid #DCDCDC',
        padding: '1rem',
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        fontSize: '14px',
        marginBottom: '0.5rem',
        width: '80%'
    },
    button: {
        height: '35px',
        background: "#8BC83F",
        color: 'white',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        width: '100px'
    },
    fieldLabel: {
        color: '#606060',
        fontSize: '15px',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        paddingRight: '1rem'
    },
    expandMoreIcon: {
        position: "absolute",
        right: "8px",
        top: "8px",
        color: "#C8C5C5",
        fontSize: "35px"
    }
};

export default ActionEditFormScreen;