import { connect } from 'react-redux';
import CardScreen from "../../screens/cardScreen/CardScreen";
import {
    addNewScheduleTransactionFailure,
    cardRequestMade,
    clientRequestMade,
    fetchCardFailure,
    fetchCardSuccess,
    fetchClientFailure,
    fetchClientSuccess,
    fetchScheduleTransactionFailure,
    fetchScheduleTransactionSuccess,
    scheduleTransactionRequestMade,
    searchClientListFailure,
    searchClientListSuccess,
    handleChangeOrderStatus,
    handleChangeCardStatus,
    updateOrderStatusSuccess,
    updateOrderStatusFailure,
    updateCardStatusSuccess, updateCardStatusFailure,setCardToView,
} from "../../actions";
import {CardAPI, ClientAPI} from "../../api";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
    closeCardSnackBar,
    handleChangeClientId,
    orderCardFailure,
    orderCardSuccess,
    searchCardFailure,
    searchCardSuccess, setCardDataToEmpty
} from "../../actions/CardAction";
import {handleChangeClientName} from "../../actions/ScheduleTransactionAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) =>{
    return {
        fetchCard: () => {
            dispatch(cardRequestMade(true));
            CardAPI.fetchCard((response, error) => {
                if (response) {
                    dispatch(fetchCardSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCardFailure(error.msg))
                    } else {
                        dispatch(fetchCardFailure(error));
                    }
                }
            });
        },
        fetchScheduleTransactionList: () => {
            dispatch(scheduleTransactionRequestMade(true));
            ScheduleTransactionAPI.fetchScheduleTransactionList((response, error) => {
                if (response) {

                    dispatch(fetchScheduleTransactionSuccess(response));

                } else {
                    if (error.msg) {
                        dispatch(fetchScheduleTransactionFailure(error.msg))
                    } else {
                        dispatch(fetchScheduleTransactionFailure(error));
                    }
                }
            });
        },
        orderCard : (client, props) => {
            dispatch(cardRequestMade(true));
            CardAPI.orderCard(client, (response, error) => {
                if (response) {
                    props.fetchCard();
                    dispatch(orderCardSuccess(true));
                    props.history.push('/card')
                } else {
                    if (error.msg) {
                        dispatch(orderCardFailure(error.msg));
                    } else {
                        dispatch(addNewScheduleTransactionFailure(error));
                    }
                }
            });
        },
        searchCard: (value) => {
            CardAPI.searchCard(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchCardFailure(error.msg))
                    } else {
                        dispatch(searchCardFailure(error));
                    }
                } else {
                    dispatch(searchCardSuccess(response));
                }
            })
        },
        fetchClientList: () => {
            dispatch(clientRequestMade(true));
            ClientAPI.fetchClientList((response, error) => {
                if (response) {
                    dispatch(fetchClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientFailure(error.msg))
                    } else {
                        dispatch(fetchClientFailure(error));
                    }
                }
            })
        },

        handleChangeCardStatus: (cardStatus, id) => dispatch(handleChangeCardStatus(cardStatus, id)),
        handleChangeOrderStatus: (orderStatus, id) => dispatch(handleChangeOrderStatus(orderStatus, id)),
        handleChangeClientId: (clientId) => dispatch(handleChangeClientName(clientId)),
        searchClientList: (value) => {
            ScheduleTransactionAPI.searchClientList(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchClientListFailure(error.msg))
                    } else {
                        dispatch(searchClientListFailure(error));
                    }
                } else {
                    dispatch(searchClientListSuccess(response));
                }
            })
        },


        updateOrderStatus : (statusData, props) => {
            dispatch(cardRequestMade(true));

            CardAPI.updateOrderStatus(statusData, (response, error) => {
                if (response) {
                    props.fetchCard();
                    dispatch(updateOrderStatusSuccess(response));

                } else {
                    if (error.msg) {
                        dispatch(updateOrderStatusFailure(error.msg));
                    } else {
                        dispatch(updateOrderStatusFailure(error));
                    }
                }
            });
        },


        updateCardStatus : (statusData, props) => {
            dispatch(cardRequestMade(true));
            CardAPI.updateCardStatus(statusData, (response, error) => {
                if (response) {
                    props.fetchCard();
                    dispatch(updateCardStatusSuccess(response));

                } else {
                    if (error.msg) {
                        dispatch(updateCardStatusFailure(error.msg));
                    } else {
                        dispatch(updateCardStatusFailure(error));
                    }
                }
            });
        },

        getOrderCardInfo: (orderId) => {
            dispatch(cardRequestMade(true));
            CardAPI.fetchSingleCardOrder(orderId, (response, error) => {
                if (response) {
                    dispatch(setCardToView(response));
                } else {
                    if (error.msg) {
                        dispatch(orderCardFailure(error.msg));
                    } else {
                        dispatch(orderCardFailure(error));
                    }
                }
            })
        },
        setOrderCardSuccessToFalse : () =>{dispatch(orderCardSuccess(false))},
        setOrderCardDataToEmpty: () => {dispatch(setCardDataToEmpty())},
        closeCardSnackBar: () => {dispatch(closeCardSnackBar(true))},
    }
}


export const CardContainer = connect(mapStateToProps, mapDispatchToProps)(CardScreen);