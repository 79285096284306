import React, {Component} from "react";
import {Button, CircularProgress, Snackbar} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import {DatePicker, Tabs} from "antd";
import ImageDocumentsComponent from "../components/ImageDocumentsComponent";
import MySnackbarContentWrapper from "../components/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import {HelmetComponent} from "../components/Helmet";
import {LocalDb} from "../api";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {getFormattedDateValue} from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
const { TabPane } = Tabs;

class DocumentHub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModalToFilterDocuments: false,
            tabValue: false,
        }
    }

    componentDidMount() {
        this.props.fetchDocuments();
    }

    render() {
        const userInfoFromDB = LocalDb.getSessions();
        const userType = userInfoFromDB ? userInfoFromDB.loginResponse.loginUser.user ? "EVERFUND_USER" :
            userInfoFromDB ? userInfoFromDB.loginResponse.loginUser.corporate ? "CORPORATE_USER" : "CLIENT_USER"
                : null : null;
        const isClient = userType === "CLIENT_USER" && userInfoFromDB &&
            userInfoFromDB.loginResponse.loginUser.client.isEnableTransaction;
        const documentState = this.props.state && this.props.state.documentState;
        const documentHub = documentState && documentState.documentsHub;
        const loading = documentState && documentState.loading;

        const fileName = documentState && documentState.fileName;
        const fromDate = documentState && documentState.fromDate === 0 ? null : documentState.fromDate ;
        const toDate = documentState && documentState.toDate === 0 ? null : documentState.toDate;
        const value = fileName !== "" || fromDate !== 0 && toDate !== 0;

        const imageList = documentHub && documentHub.filter(doc => doc.url.split(".").pop() === "png" || doc.url.split(".").pop() === "jpg");
        const documentsList = documentHub && documentHub.filter(doc => doc.url.split(".").pop() === "pdf"
            || doc.url.split(".").pop() === "txt" );

        const handleOpenToFilterDocument = () => {
            this.props.clearFilterModal();
            this.setState({
                openModalToFilterDocuments: true,
            })
        }
        const handleCloseToFilterDocument = () => {
            this.setState({
                openModalToFilterDocuments: false,
            })
        }
        const filterDocuments = (fileName, from, to) => {
            this.props.searchDocuments(fileName, from, to, this.props)
        }
        const handleChangeFileName = (fileName) => {
            this.props.handleChangeFileName(fileName)
        }
        function callback(key) {
            localStorage.setItem("tabKey", key);
        }

        const handleChangeFromDate = (range) => {
            if(range){
                const valueOfInput1 = range.format();
                this.props.handleChangeFromDate(new Date(valueOfInput1).getTime());
            }else if(range===null) {
                this.props.handleChangeFromDate(new Date(0).getTime(), new Date(0).getTime())
            }
        }
        const handleChangeToDate = (range) => {
            if(range){
                const valueOfInput1 = range.format();
                this.props.handleChangeToDate(new Date(valueOfInput1).getTime());
            }else if(range===null) {
                this.props.handleChangeToDate(new Date(0).getTime(), new Date(0).getTime())
            }
        }
        return (
            <React.Fragment>
                <HelmetComponent title="Document Hub"/>
                {
                    loading ?
                    <div className={loading ? 'loader' : 'loader hidden'} id="loader">
                        <CircularProgress style={{color: '#8BC83F'}}/>
                    </div> : null
                }
                {
                    documentState && documentState.success ?
                        this.setState({
                            openModalToFilterDocuments: false,
                        }, () => {
                            this.props.setFilterSuccessToFalse();
                        }): null
                }
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{ color: '#8BC83F', paddingBottom: '2px' }} />Home
                    </Link>
                    <Typography color="inherit" className="link">
                       Document Hub
                    </Typography>
                </Breadcrumbs>
                <div style={{ paddingTop: '48px', margin: "0 3%"}}>
                    <Tabs defaultActiveKey={this.state.tabValue}
                          onChange={(event) => {
                              callback(event);}}>
                        <TabPane tab="Images" key="1">
                           <ImageDocumentsComponent
                               isClient={isClient}
                               handleOpenToFilterDocument={handleOpenToFilterDocument}
                               handleCloseToFilterDocument={handleCloseToFilterDocument}
                               openModalToFilterDocuments={this.state.openModalToFilterDocuments}
                               filterDocuments={filterDocuments}
                               fetchDocument={this.props.fetchDocuments}
                               documentHubList={imageList}
                               handleChangeFileName={handleChangeFileName}
                               handleChangeFromDate={handleChangeFromDate}
                               handleChangeToDate={handleChangeToDate}
                               documentState={documentState}
                               isImage={true}
                           />
                        </TabPane>
                        <TabPane tab="Documents" key="2" >
                            <ImageDocumentsComponent
                                isClient={isClient}
                                handleOpenToFilterDocument={handleOpenToFilterDocument}
                                handleCloseToFilterDocument={handleCloseToFilterDocument}
                                openModalToFilterDocuments={this.state.openModalToFilterDocuments}
                                filterDocuments={filterDocuments}
                                fetchDocument={this.props.fetchDocuments}
                                documentHubList={documentsList}
                                handleChangeFileName={handleChangeFileName}
                                handleChangeFromDate={handleChangeFromDate}
                                handleChangeToDate={handleChangeToDate}
                                documentState={documentState}
                                isDocument={true}
                            />
                        </TabPane>
                    </Tabs>
                </div>
                {/*Filter document Modal*/}
                <Dialog
                    open={this.state.openModalToFilterDocuments}
                    onClose={handleCloseToFilterDocument}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseToFilterDocument}>
                            Filter
                        </DialogTitleComponent>
                        <DialogContent
                            dividers
                            style={{
                                paddingTop: "5px",
                                borderBottom: "none",
                                height:"335px"}}
                            className="dialogContent"
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <label>From Date</label>
                                    <div className="select-input">
                                        <KeyBoardDatePickerComponent
                                            dateValue={fromDate && getFormattedDateValue(fromDate)}
                                            isFilterFiled
                                            onChangeDate={(data) => {
                                                this.props.handleChangeFromDate(new Date(data).setHours(0,0,0));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label>To Date</label>
                                    <div className="select-input">
                                        <KeyBoardDatePickerComponent
                                            dateValue={toDate && getFormattedDateValue(toDate)}
                                            isFilterFiled
                                            onChangeDate={(data) => {
                                                this.props.handleChangeToDate(new Date(data).setHours(23,29,29));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <SelectiveDateForFilter
                                handleChangeFromDate={(date) => {this.props.handleChangeFromDate(date)}}
                                handleChangeToDate={(date) => {this.props.handleChangeToDate(date)}}
                            />
                            <div style={{marginTop: "12px"}}>
                                <label className="label">File Name
                                </label>
                                <input
                                    type='text'
                                    id="holiday"
                                    className="input"
                                    autoComplete='off'
                                    onChange={(e) => {
                                        handleChangeFileName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-6"/>
                                <div className="col-6" style={{paddingTop: "14px"}}>
                                    <Button
                                        style={{float: "right"}}
                                        onClick={e => {
                                            e.preventDefault();
                                            filterDocuments(fileName, toDate, fromDate);
                                        }}
                                        disabled={!value}
                                        className={value ? "addButton" : "disabledAddButton"}
                                    >
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={documentState && documentState.openErrorSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={documentState && documentState.errorMsg}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
};

export default DocumentHub;