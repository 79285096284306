import {connect} from 'react-redux';
import ClientAddFormScreen from "../../screens/ClientAddFormScreen";
import {
    addClientSuccess, closeSnackBarForRegisterCase, fetchClientFailureInRegisterCase,
    fetchClientSuccessInRegisterCase, handleChangeClientInRegisterCase,
    registerCaseRequestMade, searchClientFail, searchClientSuccess, setAddSucessFullToFalse
} from "../../actions";
import {ContactApi, RegisterCaseAPI} from "../../api";
import {contactRequestMade, searchContactFailure, searchContactSuccess} from "../../actions/ContactAction";
import {addClientFormDataFailure, addClientFormDataSuccess} from "../../actions/RegisterCaseAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addClient: (clientData,props) => {
            dispatch(registerCaseRequestMade(true));
            RegisterCaseAPI.addNewClient(clientData, (response, error) => {
                if (response) {
                    props.history.push('/client')
                    dispatch(addClientFormDataSuccess(response.client));
                } else {
                    if (error.msg) {
                        dispatch(addClientFormDataFailure(error.msg));
                    } else {
                        dispatch(addClientFormDataFailure(error));
                    }
                }
            });
        },
        fetchClientList: () => {
            dispatch(registerCaseRequestMade(true));
            RegisterCaseAPI.fetchClientList((response, error) => {
                if (response) {
                    dispatch(fetchClientSuccessInRegisterCase(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientFailureInRegisterCase(error.msg))
                    } else {
                        dispatch(fetchClientFailureInRegisterCase(error));
                    }
                }
            })
        },
        searchClient: (value) => {
            RegisterCaseAPI.searchClient(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchClientFail(error.msg))
                    } else {
                        dispatch(searchClientFail(error));
                    }
                } else {
                    dispatch(searchClientSuccess(response));
                }
            })
        },
        searchIndividualContact: (value, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.searchIndividualContact(value, (response, error) => {
                if (response) {
                    dispatch(searchContactSuccess(response));
                    // props.fetchCompanyList();
                } else {
                    if (error.msg) {
                        dispatch(searchContactFailure(error.msg));
                    } else {
                        dispatch(searchContactFailure(error));
                    }
                }
            });
        },
        setSearchedValue: (value) => {
            dispatch(fetchClientSuccessInRegisterCase(value));
        },
        setAddSucessfullFalse: (value) => {
            dispatch(setAddSucessFullToFalse(value));
        },
        setClientContent: (response) => {
            dispatch(addClientSuccess(response));
        },
        handleChangeClientInRegisterCase: (id, value) => dispatch(handleChangeClientInRegisterCase(id, value)),
        handleRegisterCaseSnackBarClose: () => dispatch(closeSnackBarForRegisterCase(true)),
    }
}

export const ClientFormContainer = connect(mapStateToProps, mapDispatchToProps)(ClientAddFormScreen);