import React from 'react'

const MultipleSelectCard = ({ value }) => {
  return (
     
            <div className='multiple-select-card'>
                {value} 
            </div>
    
  )
}

export default MultipleSelectCard