import { connect } from "react-redux";
import { CaseScreen } from "../../screens";
import {
  addClientSuccess,
  addCorporateClientFailure,
  caseTemplateRequestMade,
  clientRequestMade,
  closeSnackBarForCase,
  closeSnackBarForClient,
  corporateClientRequestMade,
  detailsForCalendarRequestMade,
  fetchCaseFailure,
  fetchCaseSuccess,
  fetchCaseTemplateFailure,
  fetchCaseTemplateSuccess,
  fetchClientFailure,
  fetchClientSuccess,
  fetchDataForCalendarFailure,
  fetchDataForCalendarSuccess,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchUserFailure,
  fetchUserSuccess,
  handleChangeCaseInRegisterCase,
  handleChangeClient,
  registerCaseFailure,
  registerCaseRequestMade,
  registerCaseSuccess,
  searchCaseFail,
  searchCaseSuccess,
  searchClientListFailure,
  searchClientListSuccess,
  setClientToEdit,
  updateClientFailure,
  userRequestMade,
} from "../../actions";
import {
  CaseAPI,
  CaseTemplateAPI,
  ClientAPI,
  RegisterCaseAPI,
  UserAPI,
} from "../../api";
import {
  caseRequestMade,
  clearFilterData,
  clearNextUrl,
  deleteCaseFailure,
  deleteCaseSuccess,
  exportCaseFailure,
  exportCaseSuccess,
  fetchFiledDetailByCaseFailure,
  fetchFiledDetailByCaseSuccess,
  fetchPaginatedCaseFailure,
  fetchPaginatedCaseSuccess,
  handleChangeCaseEndDate,
  handleChangeCaseStartDate,
  handleChangeCaseStatus,
} from "../../actions/CaseAction";
import { setCorporateClientToEdit } from "../../actions/CorporateClientAction";
import {
  clearClientFormData,
  fetchClientCaseContactsFailure,
  fetchClientCaseContactsSuccess,
} from "../../actions/RegisterCaseAction";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
  addNewClientFailure,
  addNewClientSuccess,
  clearClientInputData,
  handleChangeAddedClientId,
  handleChangeClientNameId,
  setAddClientSuccessToTrueOrFalse,
} from "../../actions/ClientAction";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCaseList: () => {
      dispatch(caseRequestMade(true));
      CaseAPI.fetchCaseList((response, error) => {
        if (response) {
          dispatch(fetchCaseSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCaseFailure(error.msg));
          } else {
            dispatch(fetchCaseFailure(error));
          }
        }
      });
    },
    // pagination
    fetchPaginatedCaseList: (nextUrl) => {
      CaseAPI.fetchPaginatedCaseList(nextUrl, (response, error) => {
        if (response) {
          dispatch(fetchPaginatedCaseSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchPaginatedCaseFailure(error.msg));
          } else {
            dispatch(fetchPaginatedCaseFailure(error));
          }
        }
      });
    },
    clearNextUrl: () => {
      dispatch(clearNextUrl());
    },
    getClientInfo: (clientId) => {
      dispatch(corporateClientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(setCorporateClientToEdit(response));
        } else {
          if (error.msg) {
            dispatch(addCorporateClientFailure(error.msg));
          } else {
            dispatch(addCorporateClientFailure(error));
          }
        }
      });
    },
    deleteCase: (caseId, props) => {
      dispatch(caseRequestMade(true));
      CaseAPI.deleteCase(caseId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteCaseFailure(error.msg));
          } else {
            dispatch(deleteCaseFailure(error));
          }
        } else {
          props.fetchCaseList();
          dispatch(deleteCaseSuccess(caseId));
        }
      });
    },
    editClient: (clientData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.editClient(clientData, (response, error) => {
        if (response) {
          props.fetchClientList();
          dispatch(addClientSuccess(true));
        } else {
          if (error.msg) {
            dispatch(updateClientFailure(error.msg));
          } else {
            dispatch(updateClientFailure(error));
          }
        }
      });
    },
    setClientData: (clientData) => {
      dispatch(setClientToEdit(clientData));
    },
    fetchFileListByCase: (caseId) => {
      dispatch(corporateClientRequestMade(true));
      CaseAPI.fetchFileDetailsByCase(caseId, (response, error) => {
        if (response) {
          dispatch(fetchFiledDetailByCaseSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchFiledDetailByCaseFailure(error.msg));
          } else {
            dispatch(fetchFiledDetailByCaseFailure(error));
          }
        }
      });
    },
    handleSnackBarClose: () => dispatch(closeSnackBarForCase(true)),
    clearClientFormData: () => dispatch(clearClientFormData()),
    fetchClientList: () => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientList((response, error) => {
        if (response) {
          dispatch(fetchClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientFailure(error.msg));
          } else {
            dispatch(fetchClientFailure(error));
          }
        }
      });
    },
    searchClientList: (value) => {
      ScheduleTransactionAPI.searchClientList(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientListFailure(error.msg));
          } else {
            dispatch(searchClientListFailure(error));
          }
        } else {
          dispatch(searchClientListSuccess(response));
        }
      });
    },
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    handleChangeClientName: (clientId) =>
      dispatch(handleChangeClientNameId(clientId)),
    handleChangeClient: (id, value) => dispatch(handleChangeClient(id, value)),
    addClient: (clientData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.addNewClient(clientData, (response, error) => {
        if (response) {
          dispatch(addNewClientSuccess(response));
          dispatch(setAddClientSuccessToTrueOrFalse(true));
        } else {
          if (error.msg) {
            dispatch(addNewClientFailure(error.msg));
          } else {
            dispatch(addNewClientFailure(error));
          }
        }
      });
    },
    setAddClientSuccessToTrueOrFalse: () =>
      dispatch(setAddClientSuccessToTrueOrFalse(false)),
    clearClientInputData: () => dispatch(clearClientInputData()),
    //case detail data
    fetchCaseTemplateList: () => {
      dispatch(caseTemplateRequestMade(true));
      CaseTemplateAPI.fetchCaseTemplateList((response, error) => {
        if (response) {
          dispatch(fetchCaseTemplateSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCaseTemplateFailure(error.msg));
          } else {
            dispatch(fetchCaseTemplateFailure(error));
          }
        }
      });
    },
    fetchUserList: () => {
      dispatch(userRequestMade(true));
      UserAPI.fetchUserList((response, error) => {
        if (response) {
          dispatch(fetchUserSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchUserFailure(error.msg));
          } else {
            dispatch(fetchUserFailure(error));
          }
        }
      });
    },
    handleChangeCaseInRegisterCase: (id, value) =>
      dispatch(handleChangeCaseInRegisterCase(id, value)),
    registerCase: (caseData, props) => {
      dispatch(registerCaseRequestMade(true));
      RegisterCaseAPI.registerCase(caseData, (response, error) => {
        if (response) {
          dispatch(registerCaseSuccess(true));
          props.fetchCaseList();
        } else {
          if (error.msg) {
            dispatch(registerCaseFailure(error.msg));
          } else {
            dispatch(registerCaseFailure(error));
          }
        }
      });
    },
    handleCloseSnackBarForClient: () => dispatch(closeSnackBarForClient()),
    handleChangeAddedClientId: (value) =>
      dispatch(handleChangeAddedClientId(value)),
    fetchClientCaseContacts: (id, props) => {
      dispatch(userRequestMade(true));
      RegisterCaseAPI.fetchClientCaseContact(id, (response, error) => {
        if (response) {
          dispatch(fetchClientCaseContactsSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientCaseContactsFailure(error.msg));
          } else {
            dispatch(fetchClientCaseContactsFailure(error));
          }
        }
      });
    },

    //****CASE FILTER/EXPORT****
    handleChangeCaseStartDate: (caseStartDate) => {
      dispatch(handleChangeCaseStartDate(caseStartDate));
    },
    handleChangeCaseEndDate: (caseEndDate) => {
      dispatch(handleChangeCaseEndDate(caseEndDate));
    },
    handleChangeCaseStatus: (caseStatus) => {
      dispatch(handleChangeCaseStatus(caseStatus));
    },
    clearFilterData: () => {
      dispatch(clearFilterData());
    },
    filterCase: (value, from, to, status) => {
      dispatch(caseRequestMade(true));
      CaseAPI.filterCase(
        value,
        from === null ? 0 : from,
        to === null ? 0 : to,
        status,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchCaseFail(error.msg));
            } else {
              dispatch(searchCaseFail(error));
            }
          } else {
            dispatch(searchCaseSuccess(response, true));
          }
        }
      );
    },
    setFilterSuccessToFalse: () => {
      dispatch(searchCaseSuccess([], false));
    },
    exportCases: (term, from, to, status, props) => {
      dispatch(caseRequestMade(true));
      CaseAPI.exportCasesList(
        term,
        from === null ? 0 : from,
        to === null ? 0 : to,
        status,
        (response, error) => {
          if (response) {
            window.open(response.msg, "_blank");
            dispatch(exportCaseSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportCaseFailure(error.msg));
            } else {
              dispatch(exportCaseFailure(error));
            }
          }
        }
      );
    },
    //fetch calendar
    fetchDataListForCalendar: () => {
      dispatch(detailsForCalendarRequestMade(true));
      CaseAPI.fetchDataListForCalendar((response, error) => {
        if (response) {
          dispatch(fetchDataForCalendarSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDataForCalendarFailure(error.msg));
          } else {
            dispatch(fetchDataForCalendarFailure(error));
          }
        }
      });
    },
  };
};

export const CaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseScreen);
