import { connect } from 'react-redux';
import CaseFilesDetailScreen from "../../screens/CaseFilesDetailScreen";
import { corporateClientRequestMade} from "../../actions";
import CaseAPI from "../../api/CaseAPI";
import {fetchFiledDetailByCaseFailure, fetchFiledDetailByCaseSuccess} from "../../actions/CaseAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
  return{
      fetchFileListByCase: (caseId) => {
          dispatch(corporateClientRequestMade(true));
          CaseAPI.fetchFileDetailsByCase(caseId, (response, error) => {
              if (response) {
                  dispatch(fetchFiledDetailByCaseSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(fetchFiledDetailByCaseFailure(error.msg));
                  } else {
                      dispatch(fetchFiledDetailByCaseFailure(error));
                  }
              }
          })
      },
  }
}

export const CaseFileDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(CaseFilesDetailScreen);