import { connect } from "react-redux";
import IndividualClientScheduleTransactionScreen from "../../screens/IdividualClientScheduleTransactionScreen";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
  addNewScheduleTransactionFailure,
  addNewScheduleTransactionSuccess,
  clearFileData,
  clearScheduleTransactionFilterForm,
  clearScheduleTransactionInputData,
  clearSchTransFilterData,
  closeScheduleTransactionSnackBar,
  deleteScheduleTransactionFailure,
  deleteScheduleTransactionSuccess,
  editScheduleTransactionFailure,
  editScheduleTransactionSuccess,
  exportScheduleTransactionFailure,
  exportScheduleTransactionSuccess,
  fetchScheduleTransactionByClientFailure,
  fetchScheduleTransactionByClientSuccess,
  fetchScheduleTransactionFailure,
  fetchScheduleTransactionSuccess,
  getSchTransConfigFailure,
  getSchTransConfigSuccess,
  handleChangeFromDate,
  handleChangeMedium,
  handleChangeScheduleTerm,
  handleChangeScheduleTransactionInputData,
  handleChangeScheduleTypeFilter,
  handleChangeStatus,
  handleChangeStatusToFilter,
  handleChangeToDate,
  handleChangeTransactionMedium,
  handleChangeTransactionType,
  handleChangeVendorToFilter,
  handleScheduleTransFilter,
  handleShowFileUploadErrorMessage,
  saveSchTransConfigFailure,
  saveSchTransConfigSuccess,
  scheduleTransactionRequestMade,
  searchClientScheduleTransactionByDateFailure,
  searchClientScheduleTransactionByDateSuccess,
  searchClientScheduleTransactionByMediumAndDateFailure,
  searchClientScheduleTransactionByMediumAndDateSuccess,
  searchClientScheduleTransactionByMediumAndStatusAndDateFailure,
  searchClientScheduleTransactionByMediumAndStatusAndDateSuccess,
  searchClientScheduleTransactionByMediumAndStatusFailure,
  searchClientScheduleTransactionByMediumAndStatusSuccess,
  searchClientScheduleTransactionByMediumFailure,
  searchClientScheduleTransactionByMediumSuccess,
  searchClientScheduleTransactionByStatusAndDateFailure,
  searchClientScheduleTransactionByStatusAndDateSuccess,
  searchClientScheduleTransactionByStatusFailure,
  searchClientScheduleTransactionByStatusSuccess,
  searchClientScheduleTransactionFailure,
  searchClientScheduleTransactionSuccess,
  searchScheduleTransactionByDateFailure,
  searchScheduleTransactionByDateSuccess,
  setOpenModalToScheduleTransaction,
  setScheduleTransactionInputDataToEdit,
  setScheduleTransactionToEdit,
  uploadDocFileFailure,
  uploadDocFileSuccess,
} from "../../actions/ScheduleTransactionAction";
import {
  clientRequestMade,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  vendorRequestMade,
} from "../../actions";
import {
  ActivityLogAPI,
  ClientAPI,
  DynamicActionAPI,
  SettingsAPI,
  TransactionAPI,
  VendorAPI,
} from "../../api";
import {
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import {
  clearAmountDetail,
  getAmountDetailByClientIdFailure,
  getAmountDetailByClientIdSuccess,
} from "../../actions/TransactionAction";
import {
  fetchVendorClientByIdFailure,
  fetchVendorClientByIdSuccess,
} from "../../actions/VendorAction";
import {
  activityLogRequestMade,
  fetchActivityLogByIdFailure,
  fetchActivityLogByIdSuccess,
} from "../../actions/ActivityLogAction";

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
  return {
    fetchScheduleTransactionList: () => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchScheduleTransactionList((response, error) => {
        if (response) {
          dispatch(fetchScheduleTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchScheduleTransactionFailure(error.msg));
          } else {
            dispatch(fetchScheduleTransactionFailure(error));
          }
        }
      });
    },
    getScheduleTransactionInfo: (scheduleTransactionId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchSingleScheduleTransaction(
        scheduleTransactionId,
        (response, error) => {
          if (response) {
            dispatch(setScheduleTransactionToEdit(response));
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    updateScheduleTransactionStatus: (statusData, id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.updateScheduleTransactionStatus(
        statusData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionListByClient(id);
            dispatch(editScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(editScheduleTransactionFailure(error.msg));
            } else {
              dispatch(editScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    deleteScheduleTransaction: (scheduleTransactionId, clientId, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.deleteScheduleTransaction(
        scheduleTransactionId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(deleteScheduleTransactionFailure(error.msg));
            } else {
              dispatch(deleteScheduleTransactionFailure(error));
            }
          } else {
            props.fetchScheduleTransactionListByClient(clientId);
            dispatch(deleteScheduleTransactionSuccess(scheduleTransactionId));
          }
        }
      );
    },
    handleChangeFromDate: (from) => dispatch(handleChangeFromDate(from)),
    handleChangeToDate: (to) => dispatch(handleChangeToDate(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),
    handleChangeStatusToFilter: (status) =>
      dispatch(handleChangeStatusToFilter(status)),
    handleChangeStatus: (status, id) =>
      dispatch(handleChangeStatus(status, id)),
    handleChangeScheduleTypeFilter: (status) =>
      dispatch(handleChangeScheduleTypeFilter(status)),
    searchScheduleTransactionByDate: (from, to) => {
      ScheduleTransactionAPI.searchScheduleTransactionByDate(
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchScheduleTransactionByDateFailure(error.msg));
            } else {
              dispatch(searchScheduleTransactionByDateFailure(error));
            }
          } else {
            dispatch(searchScheduleTransactionByDateSuccess(response));
          }
        }
      );
    },
    searchClientScheduleTransaction: (value, clientId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransaction(
        value,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientScheduleTransactionFailure(error.msg));
            } else {
              dispatch(searchClientScheduleTransactionFailure(error));
            }
          } else {
            dispatch(searchClientScheduleTransactionSuccess(response));
          }
        }
      );
    },
    searchClientScheduleTransactionByDate: (from, to, clientId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByDate(
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientScheduleTransactionByDateFailure(error.msg));
            } else {
              dispatch(searchClientScheduleTransactionByDateFailure(error));
            }
          } else {
            dispatch(searchClientScheduleTransactionByDateSuccess(response));
          }
        }
      );
    },
    searchClientScheduleTransactionByMedium: (medium, clientId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByMedium(
        medium,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumFailure(error.msg)
              );
            } else {
              dispatch(searchClientScheduleTransactionByMediumFailure(error));
            }
          } else {
            dispatch(searchClientScheduleTransactionByMediumSuccess(response));
          }
        }
      );
    },
    searchClientScheduleTransactionByStatus: (status, clientId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByStatus(
        status,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByStatusFailure(error.msg)
              );
            } else {
              dispatch(searchClientScheduleTransactionByStatusFailure(error));
            }
          } else {
            dispatch(searchClientScheduleTransactionByStatusSuccess(response));
          }
        }
      );
    },
    searchClientScheduleTransactionByMediumAndStatus: (
      medium,
      status,
      clientId
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByMediumAndStatus(
        medium,
        status,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientScheduleTransactionByMediumAndStatusSuccess(response)
            );
          }
        }
      );
    },
    searchClientScheduleTransactionByMediumAndDate: (
      medium,
      from,
      to,
      clientId
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByMediumAndDate(
        medium,
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumAndDateFailure(error.msg)
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByMediumAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientScheduleTransactionByMediumAndDateSuccess(response)
            );
          }
        }
      );
    },
    searchClientScheduleTransactionByStatusAndDate: (
      status,
      from,
      to,
      clientId
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByStatusAndDate(
        status,
        from,
        to,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientScheduleTransactionByStatusAndDateSuccess(response)
            );
          }
        }
      );
    },
    searchClientScheduleTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      clientId,
      transactionType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        clientId,
        transactionType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error
                )
              );
            }
          } else {
            // props.fetchScheduleTransactionListByClient(clientId);
            dispatch(
              searchClientScheduleTransactionByMediumAndStatusAndDateSuccess(
                response
              )
            );
          }
        }
      );
    },

    fetchScheduleTransactionListByClient: (id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchScheduleTransactionListByClient(
        id,
        (response, error) => {
          if (response) {
            dispatch(fetchScheduleTransactionByClientSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchScheduleTransactionByClientFailure(error.msg));
            } else {
              dispatch(fetchScheduleTransactionByClientFailure(error));
            }
          }
        }
      );
    },
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    handleChangeVendorToFilter: (vendorToFilter) => {
      dispatch(handleChangeVendorToFilter(vendorToFilter));
    },
    setOpenModalToScheduleTransaction: (bool) => {
      dispatch(setOpenModalToScheduleTransaction(bool));
    },
    setScheduleTransactionInputDataToEdit: (scheduleTransData) => {
      dispatch(setScheduleTransactionInputDataToEdit(scheduleTransData));
    },
    handleChangeScheduleTransactionInputData: (id, input) =>
      dispatch(handleChangeScheduleTransactionInputData(id, input)),

    uploadDocumentFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formdata = new FormData();
      formdata.append("doc", selectedFiles.document);
      formdata.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadDocumentFile(formdata, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },
    //description
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
          if (response.client && response.client.bankDetail.length === 1) {
            const e =
              response.client.bankDetail[0] &&
              response.client.bankDetail[0].bankDetailId;
            props.handleChangeScheduleTransactionInputData("bankId", e);
          }
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    updateScheduleTransaction: (scheduleTransData, id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.updateScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionListByClient(id);
            dispatch(addNewScheduleTransactionSuccess(true));
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    addNewScheduleTransaction: (scheduleTransData, id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.addNewScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionListByClient(id);
            dispatch(addNewScheduleTransactionSuccess(true));
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    closeScheduleTransactionSnackBar: (bool) => {
      dispatch(closeScheduleTransactionSnackBar(bool));
    },
    getAmountDetailByClientId: (clientId) => {
      TransactionAPI.getAmountDetailByClientId(clientId, (response, error) => {
        if (response) {
          dispatch(getAmountDetailByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAmountDetailByClientIdFailure(error.msg));
          } else {
            dispatch(getAmountDetailByClientIdFailure(error));
          }
        }
      });
    },
    clearAmountDetail: () => {
      dispatch(clearAmountDetail());
    },
    handleChangeTransactionMedium: (transMediumType) =>
      dispatch(handleChangeTransactionMedium(transMediumType)),
    handleChangeTransactionType: (transType) =>
      dispatch(handleChangeTransactionType(transType)),
    fetchVendorClientById: (id) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorClientById(id, (response, error) => {
        if (response) {
          dispatch(fetchVendorClientByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorClientByIdFailure(error.msg));
          } else {
            dispatch(fetchVendorClientByIdFailure(error));
          }
        }
      });
    },
    clearScheduleTransactionInputData: () =>
      dispatch(clearScheduleTransactionInputData()),
    clearFileData: () => {
      dispatch(clearFileData());
    },
    handleShowFileUploadErrorMessage: (error) => {
      dispatch(handleShowFileUploadErrorMessage(error));
    },
    fetchActivityLogByRefId: (id) => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLogByRefId(id, (response, error) => {
        if (response) {
          dispatch(fetchActivityLogByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchActivityLogByIdFailure(error.msg));
          } else {
            dispatch(fetchActivityLogByIdFailure(error));
          }
        }
      });
    },

    // Export client schedule transaction
    exportClientScheduleTransactionByAll: (
      clientId,
      term,
      medium,
      status,
      type,
      from,
      to,
      transactionType,
      vendor,
      exportType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportClientScheduleTransaction(
        clientId,
        term,
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        transactionType,
        vendor,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    // Export client schedule transaction by column filter
    exportClientScheduleTransactionByColumnFilter: (
      clientId,
      startDate,
      endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType,
      exportType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportClientScheduleTransactionByColumnFilter(
        clientId,
        startDate === null ? 0 : startDate,
        endDate === null ? 0 : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate,
        scheduleDate === null ? 0 : scheduleDate,
        amount,
        compareType,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    handleChangeScheduleTerm: (term) => {
      dispatch(handleChangeScheduleTerm(term));
    },
    clearScheduleTransactionFilterForm: () => {
      dispatch(clearScheduleTransactionFilterForm());
    },

    handleScheduleTransFilter: (id, value) => {
      dispatch(handleScheduleTransFilter(id, value));
    },
    clearSchTransFilterData: () => {
      dispatch(clearSchTransFilterData());
    },
    saveInConfig: (data) => {
      ScheduleTransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveSchTransConfigFailure(error.msg));
          } else {
            dispatch(saveSchTransConfigFailure(error));
          }
        } else {
          dispatch(saveSchTransConfigSuccess(response));
        }
      });
    },
    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getSchTransConfigFailure(error.msg));
          } else {
            dispatch(getSchTransConfigFailure(error));
          }
        } else {
          dispatch(getSchTransConfigSuccess(response.configs));
        }
      });
    },
    filterScheduleTransactionByAll: (
      id,
      startDate,
      endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.filterScheduleTransactionByClient(
        id,
        startDate === null ? "" : startDate,
        endDate === null ? "" : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error
                )
              );
            }
          } else {
            dispatch(
              searchClientScheduleTransactionByMediumAndStatusAndDateSuccess(
                response
              )
            );
          }
        }
      );
    },
  };
};

export const IndividualClientScheduleTransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualClientScheduleTransactionScreen);
