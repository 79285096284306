import React from 'react';
import {DonutChart} from "bizcharts";

function PieChartComponent({data, title, statisticTitle}){
    return (
        <DonutChart
            data={data}
            title={{
                visible: true,
                text:title,
                style:{
                    color: "#707070",
                    fontWeight: "600",
                    padding: "15px 0px 0px 15px"
                }
            }}
            autoFit
            height={350}
            radius={0.8}
            padding='auto'
            angleField='value'
            colorField='type'
            statistic={{
                visible: false,
                title:{
                    customHtml:()=> statisticTitle
                },
                style: {
                    fontSize: '12px'
                }
            }}
            pieStyle={{ stroke: "white", lineWidth: 5 }}
        />
    );
}

export default PieChartComponent;