import { ACTION_TYPE_WORKFLOW } from "../constants/Type";

const initialState = {
    loading: false,
    openWorkflowSnackBar: false,
    errorMsgForWorkflow: ''
};

const workflowState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_WORKFLOW.WORKFLOW_FETCH_SUCCESS:
            return {
                ...state,
                workflow: action.workflow.workflows,
                loading: false,
                openWorkflowSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW.WORKFLOW_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForWorkflow: action.error,
                loading: false,
                openWorkflowSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW.WORKFLOW_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_WORKFLOW.DELETE_WORKFLOW_SUCCESS:
            const oldWorkflowList = state.workflow;
            let newWorkflow = [];
            for (let i = 0; i < oldWorkflowList.length; i++) {
                if (oldWorkflowList[i].workflowId !== action.workflowId) {
                    newWorkflow.push(oldWorkflowList[i]);
                }
            }
            return {
                ...state,
                newWorkflow: newWorkflow,
                loading: false,
                openWorkflowSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW.DELETE_WORKFLOW_FAILURE:
            return {
                ...state,
                deleteFailMsg: action.error,
                loading: false,
                openWorkflowSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW.CLOSE_SNACK_BAR_WORKFLOW_PAGE:
            return {
                ...state,
                openWorkflowSnackBar: false,
            };
        default:
            return state;
    }

};

export default workflowState;