import React, {Component} from "react";
import {Button, Checkbox} from "@material-ui/core";
import {DatePicker, Select} from "antd";
import InfoPopover from "./InfoPopoverComponent";
import DetailPopover from "./DetailPopoverComponent";
import ButtonComponent from "./ButtonComponent";
import Helpers from "../components/Helper";
import moment from "moment";
import {finalAmount} from "../utils/currencyConvertor";
const {Option} = Select;
class ClientPaymentSettingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedValue: false,
            setValue: false,
        }
    }
    componentDidMount() {
        this.setState({
            checkedValue: !!(this.props.clientState && this.props.clientState.installmentMonth)
        })
    }

    render() {
        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
            return moment(dateConverted,"YYYY-MM-DD");
        };
        const clientState = this.props.clientState;
        const feePercentage = clientState && clientState.feePercentage;
        const monthlyFee = clientState && clientState.monthlyFee;
        const annualFee = clientState && clientState.annualFee;
        const isUpdate = clientState && clientState.isUpdate;
        const minMonthlyFee = clientState && clientState.minMonthlyFee;
        const maxMonthlyFee = clientState && clientState.maxMonthlyFee;
        const admissionDate = clientState && clientState.admissionDate;
        const paymentSettingId = clientState && clientState.paymentSettingId;
        const spendDownThreshold = clientState && clientState.spendDownThreshold;
        const installmentMonth = clientState && clientState.installmentMonth;
        const achDelayThreshold = clientState && clientState.achDelayThreshold;
        const clientId = this.props.clientId;
        const modalContent = this.props.modalContent;

       
        const handleChangeCheck = (e) => {
            this.setState({
                checkedValue: e.target.checked
            })
        }

        const handleChangeAdmissionsDate = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                this.props.handleChangeAdmissionDate(new Date(valueOfInput1).getTime());
            }
        }

       var calcPercent = ((feePercentage /100) * (spendDownThreshold/100)).toFixed(2);
       var calcPercentMonthly = (feePercentage /100) * (monthlyFee/100);

        return(
            <React.Fragment>
                {
                    (this.state.setValue === false && installmentMonth )?
                    this.setState({
                        checkedValue: true,
                        setValue: true
                    })  : null
                }

                           
                <div className={!modalContent ? "scrollable-div" : "ml-0"}>
                    <div className="row">
                        <div className={modalContent ? "col-12" :"col-4"}>
                            <label className="label" style={{textAlign: "left"}}>Fee Percentage <span style={{color:"red"}}>*</span>
                                <InfoPopover
                                    content={
                                        <div>
                                            Fee % that will be applied in transaction amount (eg: 2, 2.5, 10).
                                        </div>}
                                />
                            </label>
                            <input
                                type='number'
                                id="fee"
                                value={feePercentage}
                                className="input"
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeClientFee(e.target.value);
                                }}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className={modalContent ? "col-12" :"col-4"}>
                            <label className="label" style={{textAlign: "left"}}>Monthly Fee
                                <InfoPopover
                                    content={
                                        <div>
                                            The fee that will be charged to the client on an monthly basis (eg: 100, 200).
                                        </div>}
                                />
                            </label>
                          
                                <input
                                    type='number'
                                    id="fee"
                                    value={monthlyFee === 0 ? "" : monthlyFee /100}
                                    className="input"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.props.handleChangeClientMonthlyFee(finalAmount(e));
                                    }}
                                />
                            
                        </div>
      
                    </div>
                    <div className="row">
                        <div className={modalContent ? "col-12" :"col-4"}>
                            <label className="label" style={{textAlign: "left"}}>Annual Fee <span style={{color:"red"}}>*</span>
                                <InfoPopover
                                    content={
                                        <div>
                                            The fee that will be charged to the client on an annual basis (eg: 100, 200).
                                        </div>}
                                />
                            </label>
                            <input
                                type='number'
                                id="annualFee"
                                value={annualFee === 0 ? "" : annualFee / 100}
                                className="input"
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeClientAnnualFee(finalAmount(e));
                                }}
                            />
                            <div style={{textAlign: "left", marginLeft: "-9px"}}>
                                <Checkbox
                                    checked={this.state.checkedValue}
                                    onChange={handleChangeCheck}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />Installment
                            </div>
                        </div>

                        
                    </div>
                    {this.state.checkedValue ?
                            <div className="row">
                                <div className={modalContent ? "col-12" :"col-4"}>
                                    <label className="label" style={{textAlign: "left"}}>Installment Period
                                        <InfoPopover
                                            content={
                                                <div>
                                                    Will schedule annual fee transaction.
                                                </div>}
                                        />
                                    </label>
                                    <div className="selects h40" style={{textAlign: "left"}}>
                                        <Select
                                            id="installmentPeriod"
                                            showSearch={false}
                                            value={installmentMonth}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e)=>{
                                                this.props.handleChangeClientInstallment(e);
                                            }}
                                            dropdownStyle={{textAlign: "left"}}
                                        >
                                            <Option value="1">One Month</Option>
                                            <Option value="2">Two Month</Option>
                                            <Option value="3">Three Month</Option>
                                            <Option value="4">Four Month</Option>
                                            <Option value="6">Six Month</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        : null}
                    <div className="row">
                        <div className={modalContent ? "col-12" :"col-4"}>
                            <label className="label" style={{textAlign: "left"}}>Spend Down <span style={{color:"red"}}>*</span>
                                <InfoPopover
                                    content={
                                        <div>
                                            Amount in which fee % will be applied (eg: 150, 250).
                                        </div>}
                                />
                            </label>
                            <div style={{display: "flex"}}>
                            <input
                                type='number'
                                id="spendDownThreshold"
                                value={spendDownThreshold === 0 ? "" : spendDownThreshold / 100}
                                className="input"
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeClientSpendDown(finalAmount(e));
                                   
                                }}
                            />
                            {monthlyFee > 0 ? <DetailPopover 
                                content={
                                    <div className="d-flex flex-column justify-content-around align-items-center">
                                    <ol>
                                      <li>Based on the fee percentage, your monthly charged amount will be <span style={{fontWeight: "600", textDecoration: "underline"}}>  ${monthlyFee/100}</span>.</li>
                                    
                                  
                                
                                <li> You will be able to use  <span style={{fontWeight: "600", textDecoration: "underline"}}>${(monthlyFee/100) - calcPercentMonthly}</span> for paying bills.</li>
                                
                              </ol> 
                               
                            </div>    }/> : 
                             <>   
                             {spendDownThreshold > 0 &&
                           
                             <DetailPopover 
                               content ={
                                <div className="d-flex  flex-column justify-content-around align-items-center">
                                <ul>  
                                    <li>Based on the fee percentage, your monthly charged amount will be <span style={{fontWeight: "600", textDecoration: "underline"}}>  ${calcPercent >= maxMonthlyFee/100 ? "250" : calcPercent <=  minMonthlyFee/100 ? "30" : calcPercent}
                                    </span>.
                                   </li>
                                     
                                     <li>   You will be able to use <span style={{fontWeight: "600", textDecoration: "underline"}}> ${(spendDownThreshold/100) -  (calcPercent >= 250 ? "250" : calcPercent <= 30 ? "30" : calcPercent)}
                                    </span> for paying bills.
                                    </li>
                                    </ul>
                                </div> 
                               }/>
                             } 
                         </>
                            }
                            </div>
                        </div>

                       

                    </div>
                    <div className="row">
                        <div className={modalContent ? "col-12" :"col-4"}>
                            <label className="label" style={{textAlign: "left"}}>
                                Admission Date <span style={{color:"red"}}>*</span>
                                <InfoPopover
                                    content={
                                        <div>
                                            Admission date.
                                        </div>}
                                />
                            </label>
                            <div className="select-add-date">
                                <DatePicker
                                    getPopupContainer={node => node.parentNode}
                                    value={admissionDate && getDate(admissionDate)}
                                    format="MM-DD-YYYY"
                                    onChange={handleChangeAdmissionsDate}
                                    // onPanelChange={handleChangeEditDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={modalContent ? "col-12" :"col-4"}>
                            <label className="label" style={{textAlign: "left"}}>
                                ACH Delay Threshold <span style={{color:"red"}}>*</span>
                                <InfoPopover
                                    content={
                                        <div>
                                            Estimated ACH return days (eg: 2, 3, 4).
                                        </div>}
                                />
                            </label>
                            <input
                                type='number'
                                id="spendDownThreshold"
                                value={achDelayThreshold}
                                className="input"
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeClientAchDelayThreshold(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{padding: '0.5rem 0px 0.5rem'}}>
                        <div className={modalContent ? "col-4" : "col-2"}/>
                        {
                            modalContent ?
                                <div className={"col-4"}/>: null
                        }
                        <div className={modalContent ? "col-4" : "col-2"}>
                            <ButtonComponent
                                loading={this.props.loading}
                                onClick={e => {
                                    e.preventDefault();
                                    const data = {
                                        feePercentage : feePercentage,
                                        monthlyFee: monthlyFee,
                                        annualFee: annualFee,
                                        spendDownThreshold: spendDownThreshold,
                                        achDelayThreshold: achDelayThreshold,
                                        clientId : clientId,
                                        admissionDate: admissionDate,
                                    }
                                    const instData = {
                                        feePercentage : feePercentage,
                                        monthlyFee: monthlyFee,
                                        annualFee: annualFee,
                                        spendDownThreshold: spendDownThreshold,
                                        achDelayThreshold: achDelayThreshold,
                                        installmentMonth: installmentMonth,
                                        clientId : clientId,
                                        admissionDate: admissionDate,
                                    }
                                    if(isUpdate){
                                        instData.paymentSettingId = paymentSettingId;
                                        data.paymentSettingId= paymentSettingId;
                                    }
                                    if(installmentMonth && this.state.checkedValue){
                                        this.props.addClientPayment(instData);
                                    }else {
                                        this.props.addClientPayment(data);
                                    }
                                    if(modalContent){
                                        this.props.handleClosePaymentSettingModal();
                                    }
                                }}
                                noValue
                                buttonName={'Save'}
                                className="addFeeButton"
                           />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default ClientPaymentSettingScreen;