import React, {Component} from "react";
import {Popover} from "antd";


class InfoPopover extends Component {
    render() {
        return (
                <Popover
                    overlayStyle={{
                        width: "295px"
                    }}
                    getPopupContainer={node => node.parentNode}
                    placement="right"
                    trigger="hover"
                    content={this.props.content}
                >
                    <img style={{marginLeft: '8px', width: '12px'}} alt="image" src={'/assets/images/logInfo.svg'} />
                </Popover>
        );
    }
};

export default InfoPopover;

