import {connect} from 'react-redux';
import BulkCheckPrintScreen from "../../screens/BulkCheckPrintScreen";
import {
    bulkCheckPrintFailure,
    bulkCheckPrintSuccess,
    checkPrintRequestMade, clearCheckedValue, closeSnackBarForCheckPrint,
    fetchBulkCheckPrintFailure,
    fetchBulkCheckPrintSuccess,
    handleChangeSelectBank,
} from "../../actions/CheckPrintAction";
import {CheckPrintAPI, SettingsAPI, VendorAPI} from "../../api";
import {fetchVendorFailure, fetchVendorSuccess, vendorRequestMade} from "../../actions";
import {
    fetchCheckingAccountFailure,
    fetchCheckingAccountSuccess,
    requestInCalendarSetting
} from "../../actions/SettingsAction";
const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
 return{
     fetchBulkCheckPrintList: () => {
         dispatch(checkPrintRequestMade(true));
         CheckPrintAPI.fetchBulkCheckPrintList( (response, error) => {
             if (response) {
                 dispatch(fetchBulkCheckPrintSuccess(response));
             } else {
                 if (error.msg) {
                     dispatch(fetchBulkCheckPrintFailure(error.msg));
                 } else {
                     dispatch(fetchBulkCheckPrintFailure(error));
                 }
             }
         })
     },
     fetchVendor: () => {
         dispatch(vendorRequestMade(true));
         VendorAPI.fetchVendorList((response, error) => {
             if (response) {
                 dispatch(fetchVendorSuccess(response));
             } else {
                 if (error.msg) {
                     dispatch(fetchVendorFailure(error.msg))
                 } else {
                     dispatch(fetchVendorFailure(error));
                 }
             }
         })
     },
     printBulkCheck: (bulkCheckData,accountNumber, routingNumber, props) => {
         dispatch(checkPrintRequestMade(true));
         CheckPrintAPI.printBulkCheck(bulkCheckData, (response, error) => {
             if (response) {
                 dispatch(bulkCheckPrintSuccess(response));
                 props.history.push({
                     pathname: "/checkToPrint",
                     state: { response , accountNumber, routingNumber},
                 });
             } else {
                 if (error.msg) {
                     dispatch(bulkCheckPrintFailure(error.msg));
                 } else {
                     dispatch(bulkCheckPrintFailure(error));
                 }
             }
         });
     },
     handleChangeBankName : (bankName) => {dispatch(handleChangeSelectBank(bankName))},
     clearCheckedValue: (checkedPrint) => dispatch(clearCheckedValue(checkedPrint)),
     handleClosePrintSnackBar: () => dispatch(closeSnackBarForCheckPrint(true)),
     fetchCheckingAccountsList: (props) => {
         dispatch(requestInCalendarSetting(true));
         SettingsAPI.fetchCheckingAccount((response, error) => {
             if (response) {
                 dispatch(fetchCheckingAccountSuccess(response));
             } else {
                 if (error.msg) {
                     dispatch(fetchCheckingAccountFailure(error.msg));
                 } else {
                     dispatch(fetchCheckingAccountFailure(error));
                 }
             }
         });
     },

 }
}

export const BulkCheckPrintContainer = connect(mapStateToProps, mapDispatchToProps)(BulkCheckPrintScreen);