import { connect } from "react-redux";
import VendorClientScreen from "../../screens/VendorClientScreen";
import {
  addNewVendorFailure,
  closeVendorSnackBar,
  scheduleTransactionRequestMade,
  searchClientListFailure,
  searchClientListSuccess,
  vendorRequestMade,
} from "../../actions";
import { VendorAPI } from "../../api";
import {
  addVendorClientFailure,
  addVendorClientSuccess,
  deleteVendorClientFailure,
  deleteVendorClientSuccess,
  fetchVendorClientFailure,
  fetchVendorClientSuccess,
  handleChangeVendorClient,
  setVendorClientToEdit,
  setVendorToEdit,
  updateVendorClientFailure,
  updateVendorClientSuccess,
} from "../../actions/VendorAction";
import { handleChangeVendorClientVendorData } from "../../actions/ClientAction";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorInfo: (vendorId) => {
      dispatch(scheduleTransactionRequestMade(true));
      VendorAPI.fetchSingleVendor(vendorId, (response, error) => {
        if (response) {
          dispatch(setVendorToEdit(response));
        } else {
          if (error.msg) {
            dispatch(addNewVendorFailure(error.msg));
          } else {
            dispatch(addNewVendorFailure(error));
          }
        }
      });
    },
    searchClientList: (value) => {
      ScheduleTransactionAPI.searchClientList(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientListFailure(error.msg));
          } else {
            dispatch(searchClientListFailure(error));
          }
        } else {
          dispatch(searchClientListSuccess(response));
        }
      });
    },
    fetchVendorClient: (id) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorClientList(id, (response, error) => {
        if (response) {
          dispatch(fetchVendorClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorClientFailure(error.msg));
          } else {
            dispatch(fetchVendorClientFailure(error));
          }
        }
      });
    },
    editVendorClient: (vendorClientList, props) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.editVendorClient(vendorClientList, (response, error) => {
        if (response) {
          dispatch(updateVendorClientSuccess(true));
          {
            props.location.pathname.includes("/client/details/") &&
              props.fetchVendorClientById(vendorClientList.clientId);
          }
          {
            !props.location.pathname.includes("/client/details/") &&
              props.fetchVendorClient(vendorClientList.vendorId);
          }
        } else {
          if (error.msg) {
            dispatch(updateVendorClientFailure(error.msg));
          } else {
            dispatch(updateVendorClientFailure(error));
          }
        }
      });
    },
    addVendorClient: (vendorClientList, props) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.addNewVendorClient(vendorClientList, (response, error) => {
        if (response) {
          dispatch(addVendorClientSuccess(true));
          {
            props.location.pathname.includes("/client/details/") &&
              props.fetchVendorClientById(vendorClientList.clientId);
          }
          {
            !props.location.pathname.includes("/client/details/") &&
              props.fetchVendorClient(vendorClientList.vendorId);
          }
        } else {
          if (error.msg) {
            dispatch(addVendorClientFailure(error.msg));
          } else {
            dispatch(addVendorClientFailure(error));
          }
        }
      });
    },
    deleteVendorClient: (clientId, vendorClientId, vendorId, props) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.deleteVendorClient(vendorClientId, (response, error) => {
        if (response) {
          dispatch(deleteVendorClientSuccess(true));
          {
            props.location.pathname.includes("/client/details/") &&
              props.fetchVendorClientById(clientId);
          }
          {
            {
              !props.location.pathname.includes("/client/details/") &&
                props.fetchVendorClient(vendorId);
            }
          }
        } else {
          if (error.msg) {
            dispatch(deleteVendorClientFailure(error.msg));
          } else {
            dispatch(deleteVendorClientFailure(error));
          }
        }
      });
    },
    setAddVendorClientSuccessToFalse: () => {
      dispatch(addVendorClientSuccess(false));
    },
    setUpdateVendorClientSuccessToFalse: () => {
      dispatch(updateVendorClientSuccess(false));
    },
    handleChangeVendorClient: (id, value) => {
      dispatch(handleChangeVendorClient(id, value));
    },
    handleCloseVendorSnackBar: () => dispatch(closeVendorSnackBar(true)),
    setVendorToEdit: (vendorList) => {
      dispatch(setVendorClientToEdit(vendorList));
    },
    handleChangeVendorClientVendorData: (id, value) => {
      dispatch(handleChangeVendorClientVendorData(id, value));
    },
  };
};

export const VendorClientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorClientScreen);
