import React, {Component} from "react";
import {CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import styles from "./loginScreenStyle.module.scss"

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            hidden: true
        };
    }

    componentWillUnmount() {
        this.props.handleSnackBarClose();
    }

    componentDidMount() {
        if (this.props.password) {
            this.setState({password: this.props.password});
        }
    }

    toggleShow = () => {
        this.setState({hidden: !this.state.hidden});
    };

    keyPressed = (event) => {
        if (event.key === 'Enter') {
            this.props.onLogin(this.props.state.loginState.userData, this.props);
        }
    };

    render() {
        const errorMessageEmail =
            this.props.state.loginState.localErrorEmail !== "" ? (
                <span className={styles.errorMessage}>
                    {" "}
                    {this.props.state.loginState.localErrorEmail}
                    <br/>
                </span>
            ) : null;

        return (
            <React.Fragment>
                {this.props.state.loginState.loading ? (
                    <div
                        className={
                            this.props.state.loginState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                <div className={`${styles.flexContainer} flex-container`}>
                    <div className={`${styles.cardBackground} card-background`}>
                        <div className={`${styles.loginCard} card loginCard`}>
                            <div className={`${styles.cardBody} card-body`}>
                                <div className={`${styles.image} image`}>
                                    <img
                                        src="assets/images/everfund_logo.png"
                                        alt="EverFund Logo"
                                        height="110px"
                                        className={styles.img}
                                    />
                                </div>
                                <h1 className={styles.h1}>Login</h1>
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <div className={"form-group"} id="emailDiv">
                                        <input
                                            type="email"
                                            className={`${styles.input} form-control`}
                                            id="email"
                                            placeholder="E-mail"
                                            onChange={e => {
                                                e.preventDefault();
                                                this.props.handleChange(e.target.id, e.target.value)
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            id="emailIcon"
                                            icon={faEnvelope}
                                            style={style.icon}
                                        />
                                    </div>
                                    <div className="form-group"
                                         style={{marginBottom: "0.7rem"}}
                                    >
                                        <input
                                            onKeyPress={this.keyPressed}
                                            type={this.state.hidden ? "password" : "text"}
                                            className={`${styles.input} form-control`}
                                            id="password"
                                            placeholder="Password"
                                            onChange={e => {
                                                e.preventDefault();
                                                this.props.handleChange(e.target.id, e.target.value)
                                            }}

                                        />
                                        <FontAwesomeIcon
                                            id="passwordIcon"
                                            icon={faLock}
                                            style={style.icon}
                                        />
                                        <button type='button'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.toggleShow();
                                                }}
                                                className={styles.visibilityIcon}
                                        >
                                            {this.state.hidden ?
                                                <VisibilityOff/> :
                                                <Visibility/>}
                                        </button>
                                    </div>

                                    <span>
                                        {errorMessageEmail}
                                        {/*{errorMessagePassword}*/}
                                    </span>

                                    {/*<div*/}
                                    {/*    className={`${styles.formCheck} form-check`}*/}
                                    {/*    id="formCheck"*/}

                                    {/*>*/}
                                    {/*<input*/}
                                    {/*    type="checkbox"*/}
                                    {/*    className="form-check-input"*/}
                                    {/*    id="formCheckInput"*/}
                                    {/*    style={{ marginRight: 0 }}*/}
                                    {/*/>*/}
                                    {/*<label*/}
                                    {/*    className={`${styles.formCheckLabel} form-check-label`}*/}
                                    {/*    id="form-check-label"*/}
                                    {/*    htmlFor="formCheck"*/}

                                    {/*>*/}
                                    {/*    Remember Me*/}
                                    {/*</label>*/}
                                    {/*</div>*/}
                                </form>
                                <button
                                    className={`${styles.btn} btn btn-primary`}
                                    disabled={this.props.state.loginState.loading}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.onLogin(this.props.state.loginState.userData, this.props);
                                    }}
                                >
                                    LOGIN
                                </button>
                                {/*<Link to='/reset/password' style={{color:'#8bc83f',fontSize: '15px'}}>Forgot password?</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.loginState.openSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.loginState.errorMessageSnackBar}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    icon: {
        fontSize: "17px"
    },

};

export default LoginScreen;
