import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import ButtonComponent from "./ButtonComponent";
import MySnackbarContentWrapper from "../components/Snackbar";

class CheckingAccountScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      isUpdate: false,
      checkingAccount: {},
      openActionMenu: false,
      popoverElement: null,
    };
  }
  componentDidMount() {
    this.props.fetchCheckingAccountsList(this.props);
  }

  // handleChange(e) {
  //   this.props.handleChangeCheckingData("isBalanced", e.target.checked);
  // }

  render() {
    const settingsState = this.props.settingsState;
    const openCheckingAccountFormModal =
      settingsState && settingsState.openCheckingAccountFormModal;
    const checkingAccountData =
      settingsState && settingsState.checkingAccountData;
    const checkingAccountAddress = settingsState && settingsState.checkingAccountAddress;
    const loading = settingsState && settingsState.loading;
    const openSettingErrorSnackBar =
      settingsState && settingsState.openSettingErrorSnackBar;
    const error = settingsState && settingsState.error;
    const openSettingSuccessSnackBar =
      settingsState && settingsState.openSettingSuccessSnackBar;
    const successMsg = settingsState && settingsState.successMsg;

    const value =
      checkingAccountData.immediateDestination !== "" &&
      checkingAccountData.destinationName !== "" &&
      checkingAccountData.depositId !== "" &&
      checkingAccountData.disbursementId !== "" &&
      checkingAccountData.originName !== "" &&
      checkingAccountData.accountNumber !== "";

    return (
      <React.Fragment>
        <div className="row scrollable-div">
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    Immediate Destination
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="immediateDestination"
                    autoComplete="off"
                    name="immediateDestination"
                    className="inputField"
                    value={
                      checkingAccountData &&
                      checkingAccountData.immediateDestination
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCheckingData(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    Disbursement Id
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="disbursementId"
                    className="inputField"
                    autoComplete="off"
                    name="disbursementId"
                    value={
                      checkingAccountData && checkingAccountData.disbursementId
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeCheckingData(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    Deposit Id
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="depositId"
                    className="inputField"
                    autoComplete="off"
                    name="depositId"
                    value={checkingAccountData && checkingAccountData.depositId}
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeCheckingData(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    Account Number
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="accountNumber"
                    className="inputField"
                    autoComplete="off"
                    name="accountNumber"
                    value={
                      checkingAccountData && checkingAccountData.accountNumber
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeCheckingData(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    Destination Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="destinationName"
                    className="inputField"
                    autoComplete="off"
                    name="destinationName"
                    value={
                      checkingAccountData && checkingAccountData.destinationName
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeCheckingData(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    Origin Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="originName"
                    className="inputField"
                    autoComplete="off"
                    name="originName"
                    value={checkingAccountData && checkingAccountData.originName}
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeCheckingData(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* for isBalanced checkbox */}
            {checkingAccountData.isBalanced?<div className="row mt-4">
              <div style={style.checkBoxStyle}>
                <input type="checkbox" checked name="isBalanced" id="isBalanced" onChange={(e) => this.props.handleChangeCheckingData("isBalanced", e.target.checked)} value={
                  checkingAccountData && checkingAccountData.isBalanced
                } />
                <label style={style.balancedLabelStyle}>Balanced</label>
              </div>
            </div>:
            <div className="row mt-4">
              <div style={style.checkBoxStyle}>
                <input type="checkbox" name="isBalanced" id="isBalanced" onChange={(e) => this.props.handleChangeCheckingData("isBalanced", e.target.checked)} value={
                  checkingAccountData && checkingAccountData.isBalanced
                } />
                <label style={style.balancedLabelStyle}>Balanced</label>
              </div>
            </div>
            }
            <div className="row mt-4" style={style.balancedCheckboxStyle}>
              <div className="col-6">
                <div style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#676767',
                  marginBottom: '-8px'
                }}>
                  Address:
                </div>
              </div>
              <div className="col-6" />
              <div className="col-6">
                <div>
                  <label className="label">
                    Address1
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="address1"
                    autoComplete="off"
                    name="address1"
                    className="inputField"
                    value={
                      checkingAccountAddress &&
                      checkingAccountAddress.address1
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCheckingAccountAddress(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label className="label">
                    Address2
                  </label>
                  <input
                    type="name"
                    id="address2"
                    autoComplete="off"
                    name="address2"
                    className="inputField"
                    value={
                      checkingAccountAddress &&
                      checkingAccountAddress.address2
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCheckingAccountAddress(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="mt-3">
                  <label className="label">
                    City
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="city"
                    autoComplete="off"
                    name="city"
                    className="inputField"
                    value={
                      checkingAccountAddress &&
                      checkingAccountAddress.city
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCheckingAccountAddress(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="mt-3">
                  <label className="label">
                    State
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="state"
                    autoComplete="off"
                    name="state"
                    className="inputField"
                    value={
                      checkingAccountAddress &&
                      checkingAccountAddress.state
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCheckingAccountAddress(
                        e.target.id,
                        e.target.value
                      );
                      // Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="mt-3">
                  <label className="label">
                    Zip Code
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    id="zip5"
                    autoComplete="off"
                    name="zip5"
                    className="inputField"
                    value={
                      checkingAccountAddress &&
                      checkingAccountAddress.zip5
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeCheckingAccountAddress(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="mt-3">
                <ButtonComponent
                  loading={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: false });
                    checkingAccountData.address = checkingAccountAddress;
                    this.props.addCheckingAccount(checkingAccountData);
                  }}
                  value={value}
                  buttonName={openCheckingAccountFormModal ? "Update" : "Add"}
                />
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSettingErrorSnackBar}
          autoHideDuration={5000}
          onClose={() => {
            this.props.handleSettingSnackBarClose();
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.props.handleSettingSnackBarClose();
            }}
            variant="error"
            message={error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSettingSuccessSnackBar}
          autoHideDuration={5000}
          onClose={() => {
            this.props.handleSettingSnackBarClose();
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.props.handleSettingSnackBarClose();
            }}
            variant="success"
            message={successMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  checkBoxStyle: {
    position: "absolute",
    top: "51%",
    right: "88%"
  },
  balancedLabelStyle: {
    color: "rgb(98, 95, 95)",
    fontSize: "16px",
    padding: "5px"
  },
  balancedCheckboxStyle: {
    marginTop: "1.5rem !important"
  }
}

export default CheckingAccountScreen;
