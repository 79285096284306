export const transactionStatusHelper = (status) => {
  const getString = [...status];
  const array = [];

  {
    getString &&
      getString.map((single) => {
        switch (single) {
          case "Created":
            array.push("TRANSACTION_CREATED");
            break;

          case "Approved":
            array.push("TRANSACTION_APPROVED");
            break;
          case "Submitted":
            array.push("TRANSACTION_SUBMITTED");
            break;

          case "Posted":
            array.push("TRANSACTION_POSTED");
            break;
          case "Reversed":
            array.push("TRANSACTION_REVERSED");
            break;

          case "Skipped":
            array.push("TRANSACTION_SKIPPED");
            break;
          case "On hold":
            array.push("TRANSACTION_ON_HOLD");
            break;

          case "Blocked":
            array.push("TRANSACTION_BLOCKED");
            break;
          case "Returned":
            array.push("TRANSACTION_RETURNED");
            break;

          case "Canceled":
            array.push("TRANSACTION_CANCELLED");
            break;
          case "Scheduled":
            array.push("TRANSACTION_SCHEDULED");
            break;
          case "Closed":
            array.push("TRANSACTION_CLOSED");
          default:
            break;
        }
      });
  }

  let string = "";
  let lastItem = "";

  array && array.length > 1
    ? (lastItem = array[array.length - 1])
    : (lastItem = array[0]);

  array &&
    array.length > 0 &&
    array.map((single) => {
      single === lastItem
        ? (string += `${single}`)
        : (string += `${single}&status=`);
    });

  return string;
};

export const transactionMediumHelper = (medium) => {
  const getString = [...medium];
  const array = [];

  {
    getString &&
      getString.map((single) => {
        switch (single.toUpperCase()) {
          case "CHECK":
            array.push("CHECK");
            break;

          case "CASH":
            array.push("CASH");
            break;

          case "WIRE TRANSFER":
            array.push("WIRE_TRANSFER");
            break;
          case "WIRE_TRANSFER":
            array.push("WIRE_TRANSFER");
            break;
          case "ACH":
            array.push("ACH");
            break;

          case "CARD":
            array.push("CARD");
            break;
          case "LEDGER":
            array.push("LEDGER");
            break;

          case "OTHER MEDIUM":
            array.push("OTHER_MEDIUM");
            break;

          default:
            break;
        }
      });
  }

  let string = "";
  let lastItem = "";

  array && array.length > 1
    ? (lastItem = array[array.length - 1])
    : (lastItem = array[0]);
  array &&
    array.length > 0 &&
    array.map((single) => {
      single === lastItem
        ? (string += `${single}`)
        : (string += `${single}&medium=`);
    });
  return string;
};

export const ClientSheduleStatusHelper = (medium) => {
  const getString = [...medium];
  const array = [];

  {
    getString &&
      getString.map((single) => {
        switch (single.toUpperCase()) {
          case "PENDING":
            array.push("SCHEDULE_PENDING");
            break;

          case "APPROVED":
            array.push("APPROVED");
            break;

          case "SCHEDULED":
            array.push("SCHEDULED");
            break;
          case "CLOSED":
            array.push("CLOSED");
            break;

          case "DISABLED":
            array.push("DISABLED");
            break;
          case "CANCELLED":
            array.push("CANCELLED");
            break;

          default:
            break;
        }
      });
  }

  let string = "";
  let lastItem = "";

  array && array.length > 1
    ? (lastItem = array[array.length - 1])
    : (lastItem = array[0]);
  array &&
    array.length > 0 &&
    array.map((single) => {
      single === lastItem
        ? (string += `${single}`)
        : (string += `${single}&status=`);
    });
  return string;
};

export const transactionTypeHelper = (list, type) => {
  const getString = [...list];
  const array = [];
  let apiString = type;

  {
    getString &&
      getString.map((single) => {
        switch (single.toUpperCase()) {
          case "DISBURSEMENT":
            array.push("DISBURSEMENT");
            break;

          case "DEPOSIT":
            array.push("DEPOSIT");
            break;

          case "WITHDRAWAL":
            array.push("WITHDRAWAL");
            break;
          case "FEE":
            array.push("FEE");
            break;

          case "REFUND":
            array.push("REFUND");
            break;

          case "ANNUAL FEE":
            array.push("ANNUAL_FEE");
            break;
          case "FEE RETURNED":
            array.push("FEE_RETURNED");
            break;
          default:
            break;
        }
      });
  }

  let string = "";
  let lastItem = "";

  array && array.length > 1
    ? (lastItem = array[array.length - 1])
    : (lastItem = array[0]);
  array &&
    array.length > 0 &&
    array.map((single) => {
      single === lastItem
        ? (string += `${single}`)
        : (string += `${single}&${apiString}=`);
    });
  return string;
};

export const scheduleTimeHelper = (type) => {
  const getString = [...type];
  const array = [];

  {
    getString &&
      getString.map((single) => {
        switch (single.toUpperCase()) {
          case "ONE TIME":
            array.push("ONE_TIME");
            break;

          case "DAILY":
            array.push("DAILY");
            break;

          case "WEEKLY":
            array.push("WEEKLY");
            break;
          case "MONTHLY":
            array.push("MONTHLY");
            break;

          case "QUARTERLY":
            array.push("QUARTERLY");
            break;

          case "YEARLY":
            array.push("YEARLY");
            break;
          case "NTH DAY":
            array.push("NTH_DAY");
            break;
          default:
            break;
        }
      });
  }

  let string = "";
  let lastItem = "";

  array && array.length > 1
    ? (lastItem = array[array.length - 1])
    : (lastItem = array[0]);
  array &&
    array.length > 0 &&
    array.map((single) => {
      single === lastItem
        ? (string += `${single}`)
        : (string += `${single}&type=`);
    });
  return string;
};

export function descendingComparatorTrans(a, b, orderBy) {
  if (orderBy === "balance" || orderBy === "amount") {
    let Abalance = a[orderBy] ? a[orderBy] : "0";
    let Bbalance = b[orderBy] ? b[orderBy] : "0";

    return Abalance - Bbalance;
  } else if (orderBy === "cases") {
    let first = b.client[orderBy] ? b.client[orderBy].length : 0;
    let second = a.client[orderBy] ? a.client[orderBy].length : 0;
    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return null;
  } else if (orderBy === "refId") {
    let first = a[orderBy] ? a[orderBy] : "0";
    let second = b[orderBy] ? b[orderBy] : "0";
    return first.toString().localeCompare(second.toString(), "eng", {
      numeric: true,
    });
  } else if (orderBy === "firstName") {
    let first = a.Client && a.Client[orderBy] ? a.Client[orderBy] : "z";
    let second = b.Client && b.Client[orderBy] ? b.Client[orderBy] : "z";
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return null;
  } else if (orderBy === "firstNameUP") {
    let first = a.client && a.client.firstName ? a.client.firstName : "z";
    let second = b.client && b.client.firstName ? b.client.firstName : "z";
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return null;
  } else if (orderBy === "vendorName") {
    let first =
      a.vendorAccount && a.vendorAccount[orderBy]
        ? a.vendorAccount[orderBy]
        : "z";
    let second =
      b.vendorAccount && b.vendorAccount[orderBy]
        ? b.vendorAccount[orderBy]
        : "z";
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return null;
  } else if (orderBy === "checkPrintDate") {
    let first =
      a.checkPrint && a.checkPrint[orderBy] ? a.checkPrint[orderBy] : "z";
    let second =
      b.checkPrint && b.checkPrint[orderBy] ? b.checkPrint[orderBy] : "z";
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return null;
  } else if (orderBy === "scheduleDate ") {
    let first =
      a.transactionDetails && a.transactionDetails[orderBy]
        ? a.transactionDetails[orderBy]
        : "0";
    let second =
      b.transactionDetails && b.transactionDetails[orderBy]
        ? b.transactionDetails[orderBy]
        : "0";
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return null;
  } else if (orderBy === "scheduledDate" || orderBy === "endDate") {
    let first =
      a.scheduleDetail && a.scheduleDetail[orderBy]
        ? a.scheduleDetail[orderBy]
        : "0";
    let second =
      b.scheduleDetail && b.scheduleDetail[orderBy]
        ? b.scheduleDetail[orderBy]
        : "0";
    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return null;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return null;
  }
}

export const rangeComparator = (val) => {
  switch (val) {
    case "GREATER_THAN":
      return "1";
      break;

    case "LESSER_THAN":
      return "2";
      break;
    default:
      return "0";
      break;
  }
};

export const showGreaterThanSign = (val) => {
  if (val === "1") {
    return ">";
  } else if (val === "2") {
    return "<";
  } else {
    return "";
  }
};

export const getRangeType = (val) => {
  if (val === "GREATER_THAN") {
    return "1";
  } else if (val === "LESS_THEN") {
    return "2";
  } else {
    return "0";
  }
};

export const getAllUserStatus = [
  {
    id: 1,
    status: "Active",
  },
  {
    id: 2,
    status: "On Hold",
  },
  {
    id: 3,
    status: "Discharged",
  },
  {
    id: 4,
    status: "All",
  },
];
