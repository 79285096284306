import {ACTION_TYPE_EMAIL_TEMPLATE} from "../constants/Type";

export const requestMadeForEmailTemplate = (bool) => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.REQUEST_MADE_IN_EMAIL_TEMPLATE,
        bool
    };
};

export const closeSnackbarForEmailTemplate = (bool) => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.CLOSE_SNACK_BAR,
        bool
    };
};

export const handleChangeEmailTemplateData = (id, value) => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.HANDLE_CHANGE_EMAIL_TEMPLATE_DATA,
        id, value,
    };
};

export const handleChangeHtmlPart = (htmlPart) => {
    return{
        type: ACTION_TYPE_EMAIL_TEMPLATE.HANDLE_CHANGE_HTML_PART,
        htmlPart,
    }
}

export const setEmailTemplateToEdit = (templateData) => {
    return{
        type: ACTION_TYPE_EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_TO_EDIT,
        templateData,
    }
}

export const fetchEmailTemplatesSuccess = response => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TEMPLATE_SUCCESS,
        response
    };
};
export const fetchEmailTemplatesFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TEMPLATE_FAILURE,
        error
    };
};

export const addEmailTemplatesSuccess = bool => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.POST_EMAIL_TEMPLATE_SUCCESS,
        bool
    };
};
export const addEmailTemplatesFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.POST_EMAIL_TEMPLATE_FAILURE,
        error
    };
};
export const updateEmailTemplatesSuccess = bool => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_SUCCESS,
        bool
    };
};
export const updateEmailTemplatesFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_FAILURE,
        error
    };
};
export const deleteEmailTemplatesSuccess = bool => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.DELETE_EMAIL_TEMPLATE_SUCCESS,
        bool
    };
};
export const deleteEmailTemplatesFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.DELETE_EMAIL_TEMPLATE_FAILURE,
        error
    };
};

export const clearTemplateModal = () => {
    return{
        type: ACTION_TYPE_EMAIL_TEMPLATE.CLEAR_TEMPLATE_MODAL,
    }
}

//assign email type
export const handleChangeAssignTemplateData = (id, value) => {
    return{
        type: ACTION_TYPE_EMAIL_TEMPLATE.HANDLE_CHANGE_ASSIGN_TEMPLATE_DATA,
        id, value,
    }
}

export  const setAssignedEmailTemplateToEdit = (templateAssign) => {
    return{
        type : ACTION_TYPE_EMAIL_TEMPLATE.SET_ASSIGNED_EMAIL_TEMPLATE_TO_EDIT,
        templateAssign
    }
}
export const addEmailTypeSuccess = bool => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS,
        bool
    };
};
export const addEmailTypeFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE,
        error
    };
};
export const updateEmailTypeSuccess = bool => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS,
        bool
    };
};
export const updateEmailTypeFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE,
        error
    };
};
export const fetchEmailTypeSuccess = response => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TYPE_SUCCESS,
        response
    };
};
export const fetchEmailTypeFailure = error => {
    return {
        type: ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TYPE_FAILURE,
        error
    };
};