import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import "../styles/styles.css";
import { DatePicker, Radio, Select } from "antd";
import currencies from "../currencies";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import ClientDynamicConditionalForm from "./DynamicClientActionConditionalForm";
const { Option } = Select;

class DynamicActionForClientScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBlocking: false,
      selectedFile: [],
      selectedFileForDoc: [],
      assigned: false,
      openSnackBar: false,
      actionFormInfo: [],
      selected: [],
      selectedContactId: "",
      optionValueId: "",
      optionValueName: "",
      activeActionFormId: "",
      activeRefId: "",
      prevActionFormId: "",
      prevRefId: "",
    };
    const linkKey = this.props.match.params.linkKey;
    console.log(linkKey)
    this.props.validateLink(linkKey);
  }

  componentDidMount() {
    const linkKey = this.props.match.params.linkKey;
    console.log("this is link key", linkKey)
    this.props.validateLink(linkKey);
    this.props.fetchContactList();
  }

  render() {
    const dynamicActionClient =
      this.props.state && this.props.state.dynamicActionClientState;
    const actionInfo = dynamicActionClient && dynamicActionClient.actions;
    const linkKey = dynamicActionClient && dynamicActionClient.linkKey;
    // console.log("this is linkkey", linkKey)
    const showSuccessMessage =
      dynamicActionClient && dynamicActionClient.showSuccessMessage;
    const previousActionData =
      dynamicActionClient && dynamicActionClient.previousActionDataByClient;
    const contactState = this.props.state && this.props.state.contactState;
    const contactsList = contactState && contactState.contactList;
    const isSelected = (id) => this.state.selected.indexOf(id) !== -1;
    const openClientConditionalFormModal =
      dynamicActionClient && dynamicActionClient.openClientConditionalFormModal;
    const handleCloseClientConditionalForm =
      this.props.handleCloseClientConditionalForm;
    const conditionalFormCaseData =
      dynamicActionClient && dynamicActionClient.conditionalFormCaseData;
    const conditionalFormDataArray =
      dynamicActionClient && dynamicActionClient.conditionalFormDataArray;

    const handleChangeContactValue = (id, event) => {
      this.setState(
        {
          selectedContactId: event,
        },
        () => {
          const selectedContact =
            contactsList &&
            contactsList.filter(
              (list) => list.contactId === this.state.selectedContactId
            );
          this.props.handleChangeContactValueByClient(
            id,
            selectedContact,
            event
          );
        }
      );
    };

    const onChangeImageFileHandler = (
      eventFileHandler,
      actionFormId,
      fileName
    ) => {
      let previousValue =
        dynamicActionClient.dynamicActionData &&
        dynamicActionClient.dynamicActionData.filter(
          (single) => single.actionFormId !== actionFormId
        );
      let image = eventFileHandler.target.files[0];
      if (
        (image && image.type === "image/png") ||
        (image && image.type === "image/jpeg") ||
        (image && image.type === "image/jpg")
      ) {
        let type = image.type;
        let singleFile = {
          image: image,
          type: type,
          fileName: fileName,
        };
        {
          this.setState(
            {
              isBlocking: true,
              selectedFile: [...this.state.selectedFile, singleFile],
            },
            () => {
              this.props.uploadImageFileByClient(
                singleFile,
                actionFormId,
                previousValue,
                linkKey,
                this.props
              );
            }
          );
        }
      } else {
        this.setState({
          openSnackBar: true,
        });
      }
    };
    const onChangeDocumentFileHandler = (
      eventFileHandler,
      actionFormId,
      fileName
    ) => {
      let previousValue =
        dynamicActionClient.dynamicActionData &&
        dynamicActionClient.dynamicActionData.filter(
          (single) => single.actionFormId !== actionFormId
        );
      let file = eventFileHandler.target.files[0];
      // if (file && file.type === "application/pdf"
      //     || file && file.type === "text/plain") {
      let type = file && file.type;
      let singleFile = {
        document: file,
        type: type,
        fileName: fileName,
      };
      {
        this.setState(
          {
            isBlocking: true,
            selectedFileForDoc: [...this.state.selectedFileForDoc, singleFile],
          },
          () => {
            this.props.uploadDocFileByClient(
              singleFile,
              actionFormId,
              previousValue,
              linkKey,
              this.props
            );
          }
        );
      }
      // } else {
      //     this.setState({
      //         openSnackBar: true,
      //     })
      // }
    };
    const validateFields = (actionFormDataArray) => {
      if (previousActionData === true) {
        this.props.editDynamicActionClientData(
          linkKey,
          actionFormDataArray,
          this.props
        );
      } else {
        this.props.addDynamicActionClientData(
          linkKey,
          actionFormDataArray,
          this.props
        );
      }
    };
    const getDate = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };

    const searchIndividualContact = (value) => {
      this.props.searchIndividualContact(value);
    };

    const getConditionalCaseFormWithRefIdByClient = (
      linkKey,
      refId,
      actionFormId,
      optionName,
      prevRefId,
      prevActionFormId,
      prevOptionName,
      step
    ) => {
      this.props.getConditionalCaseFormWithRefIdByClient(
        linkKey,
        refId,
        actionFormId,
        optionName,
        prevRefId,
        prevActionFormId,
        prevOptionName,
        step
      );
    };

    return (
      <React.Fragment>
        {this.props.state.dynamicActionClientState.loading ? (
          <div
            className={
              this.props.state.dynamicActionClientState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {dynamicActionClient && !dynamicActionClient.hasError ? (
          !showSuccessMessage ? (
            <div>
              <div>
                <img
                  src="/assets/images/everfund.png"
                  alt="logo"
                  style={{
                    width: "17.8rem",
                    height: "4.4rem",
                    marginLeft: "35.8rem",
                    marginTop: "22px",
                  }}
                />
              </div>
              <div className="container">
                <div>
                  <h2 style={{ color: "#8ac83f", marginBottom: "25px" }}>
                    Fill up the form
                  </h2>
                </div>
                <div className="row">
                  <div className="col-5">
                    <label style={style.labelTitle}>
                      <b>action name</b>
                    </label>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "0.1rem" }}></div>
                {actionInfo &&
                  actionInfo.actionForms &&
                  actionInfo.actionForms.map((singleAction) => {
                    return (
                      <div className="row">
                        <div className={"col-8"}>
                          <div style={style.label}>
                            <label style={style.fieldLabel}>
                              {singleAction.fieldName}{" "}
                              <span
                                hidden={singleAction.required !== true}
                                style={{
                                  fontSize: "20px",
                                  color: "red",
                                  paddingLeft: "3px",
                                }}
                              >
                                *
                              </span>
                            </label>
                          </div>
                          {singleAction.fieldType === "PHOTO" ? (
                            <div style={{ minWidth: "20px !important" }}>
                              {singleAction.actionFormData ? (
                                <>
                                  <img
                                    className="dataImage"
                                    src={
                                      singleAction.actionFormData &&
                                      singleAction.actionFormData[0].data
                                    }
                                    style={{ minWidth: "100px !important" }}
                                  />
                                  <div>
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      className="fileNameInput"
                                      id={singleAction.actionFormId}
                                      value={
                                        singleAction.actionFormData &&
                                        singleAction.actionFormData[0].fileName
                                      }
                                      placeholder="Image Name"
                                      onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({ isBlocking: true });
                                        this.props.handleChangeFileNameByClient(
                                          singleAction.actionFormId,
                                          e.target.value
                                        );
                                        // changeActionFormValues(e.target.id, e.target.value, "file");
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}
                              <div style={{ width: "100%" }}>
                                <label
                                  style={{
                                    width: "115px",
                                    marginTop: "0.5rem",
                                    marginBottom: "1.5rem",
                                    background: "rgba(204,204,254,0.32)",
                                    border: "1px solid #7b7b7b",
                                    textAlign: "center",
                                    padding: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input
                                    type="file"
                                    id={singleAction.actionFormId}
                                    required={singleAction.required === true}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.setState({ isBlocking: true });
                                      onChangeImageFileHandler(
                                        e,
                                        singleAction.actionFormId,
                                        singleAction.actionFormData &&
                                          singleAction.actionFormData[0]
                                            .fileName
                                      );
                                    }}
                                    className="hidden"
                                    style={{ display: "none" }}
                                  />
                                  Select file
                                </label>
                              </div>
                            </div>
                          ) : singleAction.fieldType === "DOCUMENT" ? (
                            <div style={{ minWidth: "20px !important" }}>
                              {singleAction.actionFormData &&
                              singleAction.actionFormData[0].data ? (
                                <>
                                  {singleAction.actionFormData[0].data
                                    .split(".")
                                    .pop() === "pdf" ? (
                                    <img
                                      src="/assets/images/pdf.svg"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  ) : singleAction.actionFormData[0].data
                                      .split(".")
                                      .pop() === "txt" ? (
                                    <img
                                      src="/assets/images/text_logo.png"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  ) : singleAction.actionFormData[0].data
                                      .split(".")
                                      .pop() === "csv" ? (
                                    <img
                                      src="/assets/images/csv.png"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/fileIcon.png"
                                      alt="pdf"
                                      style={{
                                        width: "40px",
                                        marginRight: "12px",
                                      }}
                                    />
                                  )}
                                  <div>
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      id={singleAction.actionFormId}
                                      value={
                                        singleAction.actionFormData &&
                                        singleAction.actionFormData[0].fileName
                                      }
                                      className="fileNameInput"
                                      placeholder="Document Name"
                                      onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({ isBlocking: true });
                                        this.props.handleChangeFileNameByClient(
                                          singleAction.actionFormId,
                                          e.target.value
                                        );
                                        // changeActionFormValues(e.target.id, e.target.value, "file");
                                      }}
                                    />
                                  </div>
                                </>
                              ) : null}
                              <div style={{ width: "100%" }}>
                                <label
                                  style={{
                                    width: "115px",
                                    marginTop: "0.5rem",
                                    marginBottom: "1.5rem",
                                    background: "rgba(204,204,254,0.32)",
                                    border: "1px solid #7b7b7b",
                                    textAlign: "center",
                                    padding: "4px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input
                                    id={singleAction.actionFormId}
                                    type="file"
                                    required={singleAction.required === true}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.setState({ isBlocking: true });
                                      onChangeDocumentFileHandler(
                                        e,
                                        singleAction.actionFormId,
                                        singleAction.actionFormData &&
                                          singleAction.actionFormData.fileName
                                      );
                                    }}
                                    className="hidden"
                                    style={{ display: "none" }}
                                  />
                                  Select file
                                </label>
                              </div>
                            </div>
                          ) : singleAction.fieldType === "TEXT_FIELD" ? (
                            <>
                              <input
                                type="text"
                                required={singleAction.required === true}
                                autoComplete="off"
                                id={singleAction.actionFormId}
                                value={
                                  singleAction.actionFormData
                                    ? dynamicActionClient.previousValue ===
                                      false
                                      ? this.props.setPreviousValueTrue(true) &&
                                        singleAction.actionFormData[0].data
                                      : null
                                    : null
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({ isBlocking: true });
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e.target.value
                                  );
                                  // changeActionFormValues(e.target.id, e.target.value);
                                }}
                                style={style.inputField}
                              />
                            </>
                          ) : singleAction.fieldType === "PHONE" ? (
                            <>
                              <input
                                required={singleAction.required === true}
                                id={singleAction.actionFormId}
                                value={
                                  singleAction.actionFormData
                                    ? dynamicActionClient.previousValue ===
                                      false
                                      ? this.props.setPreviousValueTrue(true) &&
                                        singleAction.actionFormData[0].data
                                      : null
                                    : null
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({ isBlocking: true });
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e.target.value
                                  );
                                  // changeActionFormValues(e.target.id, e.target.value);
                                }}
                                style={style.inputField}
                              />
                            </>
                          ) : singleAction.fieldType === "SSN" ? (
                            <>
                              <input
                                type="number"
                                required={singleAction.required === true}
                                id={singleAction.actionFormId}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({ isBlocking: true });
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e.target.value
                                  );
                                  // changeActionFormValues(e.target.id, e.target.value);
                                }}
                                className=" fileNameInput outerFormDiv"
                                style={style.inputField}
                              />
                            </>
                          ) : singleAction.fieldType === "DOB" ? (
                            <>
                              <div
                                className="select-dob mb-3"
                                onClick={() => {
                                  this.setState({ isBlocking: true });
                                }}
                              >
                                <DatePicker
                                  required={singleAction.required === true}
                                  value={
                                    singleAction.actionFormData &&
                                    singleAction.actionFormData[0].data &&
                                    getDate(singleAction.actionFormData[0].data)
                                  }
                                  format="MM-DD-YYYY"
                                  onChange={(event) => {
                                    if (event) {
                                      const valueOfInput1 = event.format();
                                      this.props.handleChangeInputByClient(
                                        singleAction.actionFormId,
                                        new Date(valueOfInput1).getTime()
                                      );
                                      // changeActionFormValues(singleAction.actionFormId,
                                      //     new Date(valueOfInput1).getTime());
                                      // this.props.handleChangeDobByClient(singleAction.actionFormId
                                      //     ,new Date(valueOfInput1).getTime());
                                    }
                                  }}
                                  onPanelChange={(event) => {
                                    if (event) {
                                      const valueOfInput1 = event.format();
                                      this.props.handleChangeInputByClient(
                                        singleAction.actionFormId,
                                        new Date(valueOfInput1).getTime()
                                      );
                                      // this.props.handleChangeDobByClient(singleAction.actionFormId
                                      //     ,new Date(valueOfInput1).getTime());
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : singleAction.fieldType === "CONTENT" ? (
                            <div className="row">
                              <div className="col-12">
                                <div
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    color: "#434343",
                                    fontSize: "14px",
                                  }}
                                >
                                  {singleAction.content}
                                </div>
                              </div>
                            </div>
                          ) : singleAction.fieldType === "DROPDOWN" ? (
                            <div className="selects h40 mb-3">
                              <Select
                                required={singleAction.required === true}
                                id={singleAction.actionFormId}
                                showSearch={false}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data
                                }
                                getPopupContainer={(node) => node.parentNode}
                                onChange={(e) => {
                                  this.setState({ isBlocking: true });
                                  // changeActionFormValues(singleAction.actionFormId, e);
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e
                                  );
                                  // this.props.handleChangeSelectedValueByClient(singleAction.actionFormId, e);
                                }}
                              >
                                {singleAction.optionValue &&
                                  singleAction.optionValue.map((option) => (
                                    <Option value={option.optionValueId}>
                                      {option.optionValueName}
                                    </Option>
                                  ))}
                              </Select>
                            </div>
                          ) : singleAction.fieldType === "RADIO_BUTTON" ? (
                            <div
                              style={{
                                marginBottom: "12px",
                                marginTop: "10px",
                              }}
                            >
                              <Radio.Group
                                id={singleAction.actionFormId}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data
                                }
                                required={singleAction.required === true}
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({ isBlocking: true });
                                  // changeActionFormValues(singleAction.actionFormId,
                                  //     e.target.value)
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e.target.value
                                  );
                                  // this.props.handleChangeSelectedValueByClient(singleAction.actionFormId, e.target.value);
                                }}
                              >
                                {singleAction.optionValue &&
                                  singleAction.optionValue.map((option) => (
                                    <Radio
                                      style={{ fontSize: "18px" }}
                                      value={option.optionValueId}
                                    >
                                      <span style={{ fontSize: "16px" }}>
                                        {option.optionValueName}
                                      </span>
                                    </Radio>
                                  ))}
                              </Radio.Group>
                            </div>
                          ) : singleAction.fieldType === "CURRENCY" ? (
                            <div className="selects h40 mb-3">
                              <Select
                                required={singleAction.required === true}
                                id={singleAction.actionFormId}
                                showSearch={false}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0].data
                                }
                                getPopupContainer={(node) => node.parentNode}
                                onChange={(e) => {
                                  this.setState({ isBlocking: true });
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e
                                  );
                                }}
                              >
                                {currencies &&
                                  currencies.map((option) => (
                                    <Option value={option.code}>
                                      {option.code} ( {option.symbol} )
                                    </Option>
                                  ))}
                              </Select>
                            </div>
                          ) : singleAction.fieldType === "CHECKBOX" ? (
                            <div>
                              <ul
                                style={{
                                  listStyleType: "none",
                                  paddingLeft: "5px",
                                }}
                              >
                                {singleAction.optionValue &&
                                  singleAction.optionValue.map(
                                    (option, index) => {
                                      const isItemSelected = isSelected(option);
                                      const labelId = `enhanced-table-checkbox-${index}`;
                                      return (
                                        <li>
                                          <Checkbox
                                            required={
                                              singleAction.required === true
                                            }
                                            onChange={(event) => {
                                              this.setState(
                                                {
                                                  isChecked:
                                                    event.target.checked,
                                                  activeActionFormId:
                                                    singleAction.actionFormId,
                                                  activeRefId: option.refId,
                                                  optionValueName:
                                                    option.optionValueName,
                                                },
                                                () => {
                                                  this.props.handleChangeCheckedValueByClient(
                                                    singleAction.actionFormId,
                                                    this.state.isChecked,
                                                    option.optionValueId
                                                  );
                                                }
                                              );
                                            }}
                                            selected={isItemSelected}
                                            value={
                                              singleAction.actionFormData &&
                                              singleAction.actionFormData.map(
                                                (action) => action.data
                                              )
                                            }
                                            checked={!!option.isSelected}
                                            inputProps={{
                                              "aria-labelledby": labelId,
                                            }}
                                          />
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              color: "#434343",
                                            }}
                                          >
                                            {option.optionValueName}
                                          </span>
                                          {option.isSelected ? (
                                            <span
                                              className="non-printable"
                                              onClick={() => {
                                                this.props.getConditionalCaseFormWithRefIdByClient(
                                                  linkKey,
                                                  option.refId,
                                                  singleAction.actionFormId,
                                                  option.optionValueName,
                                                  "",
                                                  "",
                                                  "",
                                                  "firstStep"
                                                );
                                              }}
                                              hidden={!option.isConditionalForm}
                                            >
                                              <EditOutlinedIcon
                                                className="non-printable"
                                                style={{
                                                  paddingLeft: "12px",
                                                  width: "30px",
                                                  paddingBottom: "4px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </span>
                                          ) : null}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </div>
                          ) : singleAction.fieldType === "CONTACT" ? (
                            <>
                              <div className="select-input h40">
                                <Select
                                  value={
                                    singleAction.actionFormData &&
                                    singleAction.actionFormData[0].data
                                  }
                                  onChange={(e) => {
                                    handleChangeContactValue(
                                      singleAction.actionFormId,
                                      e
                                    );
                                  }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onSearch={(value) => {
                                    this.props.searchIndividualContact(value);
                                  }}
                                >
                                  {contactsList &&
                                    contactsList.map((list) => (
                                      <Option value={list.contactId}>
                                        {list.name}
                                      </Option>
                                    ))}
                                </Select>
                              </div>
                              <div className="row mt-3">
                                <div className="col-6">
                                  <label>Email</label>
                                  <div>
                                    <input
                                      disabled
                                      id={singleAction.actionFormId}
                                      value={
                                        singleAction.contact &&
                                        singleAction.contact.emailPhone
                                      }
                                      className="fileNameInput outerFormDiv"
                                      style={style.inputField}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <label>Phone</label>
                                  <input
                                    disabled
                                    id={singleAction.actionFormId}
                                    value={
                                      singleAction.contact &&
                                      singleAction.contact.phone
                                    }
                                    className="fileNameInput outerFormDiv"
                                    style={style.inputField}
                                  />
                                </div>
                                <div className="col-6" hidden>
                                  <label>Address</label>
                                  <div>
                                    <input
                                      disabled
                                      id={singleAction.actionFormId}
                                      value={
                                        singleAction.contact &&
                                        singleAction.contact.address
                                      }
                                      className="fileNameInput outerFormDiv"
                                      style={style.inputField}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <label>Contact Type</label>
                                  <div>
                                    <input
                                      disabled
                                      id={singleAction.actionFormId}
                                      value={
                                        singleAction.contact &&
                                        singleAction.contact.contactType
                                          .replace("_", " ")
                                          .replace("_", " ")
                                      }
                                      className="fileNameInput outerFormDiv"
                                      style={style.inputField}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <input
                                type="email"
                                autoComplete="off"
                                required={singleAction.required === true}
                                id={singleAction.actionFormId}
                                value={
                                  singleAction.actionFormData &&
                                  singleAction.actionFormData[0]
                                }
                                onChange={(e) => {
                                  e.preventDefault();
                                  this.setState({ isBlocking: true });
                                  this.props.handleChangeInputByClient(
                                    singleAction.actionFormId,
                                    e
                                  );
                                  // changeActionFormValues(e.target.id, e.target.value);
                                }}
                                style={style.inputField}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                <div
                  className="row"
                  style={{ marginRight: "0rem", marginTop: "1rem" }}
                >
                  <div className="col-8" style={{ paddingRight: "10px" }}>
                    {actionInfo && actionInfo.actionForms ? (
                      <>
                        <button
                          hidden
                          onClick={() => {
                            const actionInfo =
                              dynamicActionClient &&
                              dynamicActionClient.actions;
                            const filteredActionFormData =
                              actionInfo &&
                              actionInfo.actionForms &&
                              actionInfo.actionForms.filter(
                                (action) => action.actionFormData
                              );
                            let actionFormDataArray = [];
                            filteredActionFormData &&
                              filteredActionFormData.map((form) => {
                                form.actionFormData &&
                                  form.actionFormData.length > 0 &&
                                  form.actionFormData.map((formData) => {
                                    if (formData.fileName) {
                                      if (formData.actionFormDataId) {
                                        let actionObject = {
                                          actionFormDataId:
                                            formData.actionFormDataId
                                              ? formData.actionFormDataId
                                              : "",
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                          fileName: formData.fileName,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      } else {
                                        let actionObject = {
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                          fileName: formData.fileName,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      }
                                    } else {
                                      if (formData.actionFormDataId) {
                                        let actionObject = {
                                          actionFormDataId:
                                            formData.actionFormDataId,
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      } else {
                                        let actionObject = {
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      }
                                    }
                                  });
                              });
                            console.log(
                              "actionFormDataArray",
                              actionFormDataArray
                            );
                            //
                            // this.props.addDynamicActionData(this.state.caseId, this.state.workflowId,
                            //     this.state.actionId,actionFormDataArray, this.props);
                          }}
                        >
                          Save
                        </button>
                        <button
                          style={style.button}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ isBlocking: false });
                            const actionInfo =
                              dynamicActionClient &&
                              dynamicActionClient.actions;
                            const filteredActionFormData =
                              actionInfo &&
                              actionInfo.actionForms &&
                              actionInfo.actionForms.filter(
                                (action) => action.actionFormData
                              );
                            let actionFormDataArray = [];
                            filteredActionFormData &&
                              filteredActionFormData.map((form) => {
                                form.actionFormData &&
                                  form.actionFormData.length > 0 &&
                                  form.actionFormData.map((formData) => {
                                    if (formData.fileName) {
                                      if (formData.actionFormDataId) {
                                        let actionObject = {
                                          actionFormDataId:
                                            formData.actionFormDataId
                                              ? formData.actionFormDataId
                                              : "",
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                          fileName: formData.fileName,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      } else {
                                        let actionObject = {
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                          fileName: formData.fileName,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      }
                                    } else {
                                      if (formData.actionFormDataId) {
                                        let actionObject = {
                                          actionFormDataId:
                                            formData.actionFormDataId,
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      } else {
                                        let actionObject = {
                                          data: formData.data,
                                          actionFormId: form.actionFormId,
                                        };
                                        actionFormDataArray.push(actionObject);
                                      }
                                    }
                                  });
                              });
                            validateFields(actionFormDataArray);
                          }}
                        >
                          {previousActionData === true ? "Update" : "Submit"}
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ position: "absolute", left: "40%", top: "40%" }}>
              <div>
                <img
                  src="/assets/images/everfund.png"
                  alt="logo"
                  style={{
                    width: "17.8rem",
                    height: "4.4rem",
                    marginLeft: "67px",
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  color: "#8ac83f",
                }}
              >
                Thank you !
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  color: "#8ac83f",
                }}
              >
                Your form has been submitted .
              </div>
            </div>
          )
        ) : (
          <div className="page-not-found">
            <span className="top-span">404</span>
            <span className="inner-span">|</span>
            <span className="bottom-span">Page Not found</span>
          </div>
        )}
        {/*// CLIENT'S CONDITIONAL FORM*/}
        <Dialog
          open={openClientConditionalFormModal}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
          PaperComponent={PaperComponent}
          onClose={handleCloseClientConditionalForm}
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseClientConditionalForm}
            >
              {this.state.optionValueName}
            </DialogTitleComponent>
            {conditionalFormDataArray &&
              conditionalFormDataArray.length > 0 &&
              conditionalFormDataArray.map(
                (item) =>
                  item.actionFormId === this.state.activeActionFormId &&
                  item.refId === this.state.activeRefId && (
                    <>
                      <DialogContent
                        dividers
                        style={{
                          width: "700px",
                          maxHeight: "500px",
                          padding: "0px 2px 25px 2px",
                        }}
                      >
                        <>
                          <ClientDynamicConditionalForm
                            conditionalFormCaseData={item}
                            linkKey={linkKey}
                            contactList={contactsList}
                            searchIndividualContact={searchIndividualContact}
                            previousActionData={previousActionData}
                            activeActionFormId={this.state.activeActionFormId}
                            activeRefId={this.state.activeRefId}
                            activeOptionValue={this.state.optionValueName}
                            getConditionalCaseFormWithRefIdByClient={
                              getConditionalCaseFormWithRefIdByClient
                            }
                          />
                        </>
                      </DialogContent>
                      <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
                        <div style={{ display: "flex" }}>
                          <div>
                            {(item && item.step === "secondStep") ||
                            item.step === "thirdStep" ? (
                              <div
                                style={{ position: "absolute", left: "534px" }}
                              >
                                <button
                                  className="backButton"
                                  onClick={() => {
                                    // this.props.changeActiveIdsValue( conditionalFormDataArray.prevActionFormId,
                                    //     conditionalFormDataArray.prevRefId, conditionalFormDataArray.prevOptionName)
                                  }}
                                >
                                  BACK
                                </button>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            {item &&
                            item.conditionalForms &&
                            item.conditionalForms.length > 0 ? (
                              <>
                                <button
                                  style={{
                                    background: "#8BC34A",
                                    color: " white",
                                    padding: "0.5rem",
                                    border: "none",
                                    height: "37px",
                                  }}
                                >
                                  {previousActionData === false
                                    ? "SUBMIT"
                                    : "UPDATE"}
                                </button>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </DialogActions>
                    </>
                  )
              )}
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={dynamicActionClient.openDynamicActionClientSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleDynamicClientSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleDynamicClientSnackBarClose}
            variant="error"
            message={dynamicActionClient.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openSnackBar}
          autoHideDuration={5000}
          onClose={() => {
            this.setState({
              openSnackBar: false,
            });
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.setState({
                openSnackBar: false,
              });
            }}
            variant="error"
            message="Invalid file type"
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    background: "#ffffff",
    margin: "78px 3% 3% 3%",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "18px",
    width: "100%",
    position: "inherit",
    border: "none",
  },
  fieldLabel: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    fontWeight: 600,
    paddingBottom: "0rem",
    position: "inherit",
    border: "none",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "30%",
    position: "inherit",
    marginBottom: "1.5rem",
    color: "#606060",
  },
  inputField: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "100%",
    position: "inherit",
    marginBottom: "1.5rem",
    color: "#606060",
    paddingLeft: "10px",
    marginTop: "0.5rem",
    paddingTop: "6px",
  },
  button: {
    background: "#8BC83F",
    color: "white",
    border: "none",
    width: "20%",
    fontSize: "17px",
    padding: "0.4rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
  },
  doneButton: {
    background: "none",
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "90px",
    fontSize: "17px",
    padding: "0.3rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
  },
  markButton: {
    background: "#8BC83F",
    border: "1px solid #8BC83F",
    color: "white",
    width: "90px",
    fontSize: "17px",
    padding: "0.3rem",
    borderRadius: "2px",
    float: "right",
    marginLeft: "1rem",
  },
  labelTitle: {
    color: "#353334",
    fontSize: "19px",
    border: "none",
    paddingTop: "0px",
    paddingBottom: "15px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  dialogueContent: {
    background: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    paddingRight: "1rem !important",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
};
export default DynamicActionForClientScreen;
