import { connect } from 'react-redux';
import ContactScreen from "../../screens/ContactScreen";
import {
    addCompanyFailure,
    addCompanySuccess,
    addContactFailure,
    addContactSuccess,
    closeSnackBarForContact,
    contactRequestMade,
    deleteCompanyContactFailure,
    deleteCompanyContactSuccess,
    deleteCompanyFailure,
    deleteCompanySuccess,
    deleteContactFailure,
    deleteContactSuccess,
    fetchCompanyFailure,
    fetchCompanySuccess,
    fetchCompanyTypeFailure,
    fetchCompanyTypeSuccess,
    fetchContactFailure,
    fetchContactSuccess,
    handleChangeCompanyInput,
    handleChangeContactInput,
    searchCompanyFailure,
    searchCompanySuccess,
    searchContactFailure,
    searchContactSuccess,
    setCompanyInputToEdit, setContactAccountFailure, setContactAccountSuccess,
    setContactDataToEmpty,
    setContactInputToEdit,
    updateCompanyFailure,
    updateCompanySuccess,
    updateContactFailure,
    updateContactSuccess,
} from "../../actions/ContactAction";
import {ContactApi} from "../../api";
import {handleChangeTerm} from "../../actions/TransactionAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
   return{
       fetchContactList: () =>{
           dispatch(contactRequestMade(true));
           ContactApi.fetchContactList((response, error) => {
               if (response) {
                   dispatch(fetchContactSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchContactFailure(error.msg))
                   } else {
                       dispatch(fetchContactFailure(error));
                   }
               }
           })
       },
       setAddContactToFalse : () => {dispatch(addContactSuccess(false))},
       setUpdateContactToFalse : () => {dispatch(updateContactSuccess(false))},

       setContactDataToEmpty: () => {
           dispatch(setContactDataToEmpty())
       },

       addContact: (contactData, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.addContact(contactData, (response, error) => {
               if (response) {
                   dispatch(addContactSuccess(true));
                   props.fetchContactList();
               } else {
                   if (error.msg) {
                       dispatch(addContactFailure(error.msg));
                   } else {
                       dispatch(addContactFailure(error));
                   }
               }
           });
       },
       editContact: (contactData, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.editContact(contactData, (response, error) => {
               if (response) {
                   dispatch(updateContactSuccess(true));
                   props.fetchContactList();
               } else {
                   if (error.msg) {
                       dispatch(updateContactFailure(error.msg));
                   } else {
                       dispatch(updateContactFailure(error));
                   }
               }
           });
       },
       deleteContact: (contactId, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.deleteContact(contactId, (response, error) => {
               if (response) {
                   dispatch(deleteContactSuccess(response));
                   props.fetchContactList();
               } else {
                   if (error.msg) {
                       dispatch(deleteContactFailure(error.msg));
                   } else {
                       dispatch(deleteContactFailure(error));
                   }
               }
           });
       },
       handleChangeContactInput : ( id, value) =>{
           dispatch(handleChangeContactInput(id, value))
       },
       closeContactSnackBar: () => {
           dispatch(closeSnackBarForContact(true))
       },
       setContactInputToEdit: (contactData) => {
           dispatch(setContactInputToEdit(contactData))
       },
       setCompanyInputToEdit: (companyData) => {
           dispatch(setCompanyInputToEdit(companyData))
       },
       handleChangeCompanyInput : ( id, value) =>{
           dispatch(handleChangeCompanyInput(id, value))
       },
       fetchCompanyList: () =>{
           dispatch(contactRequestMade(true));
           // setInterval(dispatch(contactRequestMade(false)), 5000)
           ContactApi.fetchCompanyList((response, error) => {
               if (response) {
                   dispatch(fetchCompanySuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchCompanyFailure(error.msg))
                   } else {
                       dispatch(fetchCompanyFailure(error));
                   }
               }
           })
       },

       setAddCompanyToFalse : () => {dispatch(addCompanySuccess(false))},
       setUpdateCompanyToFalse : () => {dispatch(updateCompanySuccess(false))},
       addCompany: (companyData, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.addCompany(companyData, (response, error) => {
               if (response) {
                   dispatch(addCompanySuccess(true));
                   props.fetchCompanyList();
               } else {
                   if (error.msg) {
                       dispatch(addCompanyFailure(error.msg));
                   } else {
                       dispatch(addCompanyFailure(error));
                   }
               }
           });
       },
       editCompany: (companyData, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.editCompany(companyData, (response, error) => {
               if (response) {
                   dispatch(updateCompanySuccess(true));
                   props.fetchCompanyList();
               } else {
                   if (error.msg) {
                       dispatch(updateCompanyFailure(error.msg));
                   } else {
                       dispatch(updateCompanyFailure(error));
                   }
               }
           });
       },
       deleteCompany: (companyId, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.deleteCompany(companyId, (response, error) => {
               if (response) {
                   dispatch(deleteCompanySuccess(response));
                   props.fetchCompanyList();
               } else {
                   if (error.msg) {
                       dispatch(deleteCompanyFailure(error.msg));
                   } else {
                       dispatch(deleteCompanyFailure(error));
                   }
               }
           });
       },
       deleteCompanyContact: (companyId,contactId, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.deleteCompanyContact(companyId, contactId,(response, error) => {
               if (response) {
                   dispatch(deleteCompanyContactSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(deleteCompanyContactFailure(error.msg));
                   } else {
                       dispatch(deleteCompanyContactFailure(error));
                   }
               }
           });
       },
       handleChangeTerm: (term) => {dispatch(handleChangeTerm(term))},
       searchIndividualContact: (value, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.searchIndividualContact(value, (response, error) => {
               if (response) {
                   dispatch(searchContactSuccess(response));
                   // props.fetchCompanyList();
               } else {
                   if (error.msg) {
                       dispatch(searchContactFailure(error.msg));
                   } else {
                       dispatch(searchContactFailure(error));
                   }
               }
           });
       },
       searchCompanyContact: (value, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.searchCompanyContact(value, (response, error) => {
               if (response) {
                   dispatch(searchCompanySuccess(response));
                   // props.fetchCompanyList();
               } else {
                   if (error.msg) {
                       dispatch(searchCompanyFailure(error.msg));
                   } else {
                       dispatch(searchCompanyFailure(error));
                   }
               }
           });
       },
       fetchCompanyType: () => {
           dispatch(contactRequestMade(true));
           ContactApi.fetchCompanyType((response, error) => {
               if (response) {
                   dispatch(fetchCompanyTypeSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchCompanyTypeFailure(error.msg));
                   } else {
                       dispatch(fetchCompanyTypeFailure(error));
                   }
               }
           });
       },
       //set company or contact accounts
       setContactsAccount: (accountDetails, props) => {
           dispatch(contactRequestMade(true));
           ContactApi.setContactsAccount(accountDetails, (response, error) => {
               if (response) {
                   dispatch(setContactAccountSuccess(response));
                   // props.fetchContactList();
               } else {
                   if (error.msg) {
                       dispatch(setContactAccountFailure(error.msg));
                   } else {
                       dispatch(setContactAccountFailure(error));
                   }
               }
           });
       },
   }
};

export const ContactContainer = connect(mapStateToProps, mapDispatchToProps)(ContactScreen);