import { connect } from "react-redux";
import { CorporateClientScreen } from "../../screens";
import {
  addClientSuccess,
  addCorporateClientFailure,
  addCorporateClientSuccess,
  clientRequestMade,
  closeSnackBarForCorporateClient,
  corporateClientRequestMade,
  deleteCorporateClientFailure,
  fetchClientFailure,
  fetchClientSuccess,
  fetchCorporateInfoFailure,
  fetchCorporateInfoSuccess,
  handleChangeCorporateClient,
  searchClientBySsnFail,
  searchClientBySsnSuccess,
  updateClientFailure,
} from "../../actions";
import CorporateAPI from "../../api/CorporateAPI";
import CorporateClientAPI from "../../api/CorporateClientAPI";
import { ClientAPI, LocalDb, RegisterCaseAPI } from "../../api";
import {
  setCorporateClientToEdit,
  assignClientToCorporateSuccess,
  assignClientToCorporateFailure,
} from "../../actions/CorporateClientAction";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientList: () => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientList((response, error) => {
        if (response) {
          dispatch(fetchClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientFailure(error.msg));
          } else {
            dispatch(fetchClientFailure(error));
          }
        }
      });
    },
    getCorporateInfo: (corporateId) => {
      dispatch(corporateClientRequestMade(true));
      CorporateAPI.fetchSingleCorporate(corporateId, (response, error) => {
        if (response) {
          dispatch(fetchCorporateInfoSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCorporateInfoFailure(error.msg));
          } else {
            dispatch(fetchCorporateInfoFailure(error));
          }
        }
      });
    },
    setCorporateClientToNull: (response) => {
      dispatch(fetchCorporateInfoSuccess(response));
    },
    deleteCorporateClient: (corporateClientId, props) => {
      dispatch(corporateClientRequestMade(true));
      const corporateid = window.location.pathname.split("/")[3];

      // CorporateClientAPI.deleteCorporateClient
      ClientAPI.deleteClientInCorporate(
        corporateClientId,
        corporateid,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(deleteCorporateClientFailure(error.msg));
            } else {
              dispatch(deleteCorporateClientFailure(error));
            }
          } else {
            props.getCorporateInfo(corporateid);
            // window.location.reload(false);
          }
        }
      );
    },
    handleSnackBarClose: () => dispatch(closeSnackBarForCorporateClient(true)),
    handleChangeCorporateClient: (id, value) =>
      dispatch(handleChangeCorporateClient(id, value)),
    addNewCorporateClient: (corporateId, clientData, props) => {
      dispatch(corporateClientRequestMade(true));
      CorporateClientAPI.addNewCorporateClient(
        corporateId,
        clientData,
        (response, error) => {
          if (response) {
            dispatch(addCorporateClientSuccess(true));
            props.getCorporateInfo(corporateId);
            if (
              LocalDb.getSessions() &&
              LocalDb.getSessions().loginResponse.loginUser.corporate
            ) {
              props.history.replace("/client");
            } else {
              props.history.replace("/corporate/client/" + corporateId);
            }
          } else {
            if (error.msg) {
              dispatch(addCorporateClientFailure(error.msg));
            } else {
              dispatch(addCorporateClientFailure(error));
            }
          }
        }
      );
    },
    setAddCorporateClientSuccessToFalse: () =>
      dispatch(addCorporateClientSuccess(false)),
    setCorporateClientToEdit: (corporateClientData) =>
      dispatch(setCorporateClientToEdit(corporateClientData)),
    searchClient: (value) => {
      RegisterCaseAPI.searchClient(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientBySsnFail(error.msg));
          } else {
            dispatch(searchClientBySsnFail(error));
          }
        } else {
          dispatch(searchClientBySsnSuccess(response));
        }
      });
    },
    editClient: (clientData, corporateId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.editClient(clientData, (response, error) => {
        if (response) {
          dispatch(addCorporateClientSuccess(true));
          props.getCorporateInfo(corporateId);
        } else {
          if (error.msg) {
            dispatch(addCorporateClientFailure(error.msg));
          } else {
            dispatch(addCorporateClientFailure(error));
          }
        }
      });
    },
    assignClientToContainer: (corporateId, clientId, props) => {
      dispatch(corporateClientRequestMade(true));
      CorporateClientAPI.assignClientToCorporate(
        corporateId,
        clientId,
        (response, error) => {
          if (response) {
            dispatch(assignClientToCorporateSuccess(true));
            props.getCorporateInfo(corporateId);
          } else {
            if (error.msg) {
              dispatch(assignClientToCorporateFailure(error.msg));
            } else {
              dispatch(assignClientToCorporateFailure(error));
            }
          }
        }
      );
    },
  };
};

export const CorporateClientContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateClientScreen);
