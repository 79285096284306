import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// import { TextField } from '@material-ui/core';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function KeyBoardDatePickerComponent({
  dateTimeFormat,
  onChangeDate,
  dateValue,
  disabled,
  isCalendarDate,
  isFilterFiled,
  onClick,
  disablePast,
  minDateMessage,
  maxDateMessage,
  shouldDisableDate,
  isContacts,
  marginTop,
  disableMessage,
  defaultTime,
}) {
  const handleDateChange = (date) => {
    if (date) {
      const dateTimeStamp = new Date(date).getTime();
      onChangeDate(dateTimeStamp);
    }
  };
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {dateTimeFormat ? (
          <KeyboardDateTimePicker
            shouldDisableDate={shouldDisableDate}
            onClick={onClick}
            minDateMessage={minDateMessage}
            maxDateMessage={maxDateMessage}
            className={
              disableMessage
                ? "keyboard-picker disableMessage"
                : "keyboard-picker"
            }
            value={dateValue !== undefined ? dateValue : null}
            autoOk={false}
            inputVariant="outlined"
            variant="inline"
            disabled={disabled}
            ampm={true}
            disablePast={disablePast}
            onChange={handleDateChange}
            placeholder="MM/dd/yyyy hh:mm:ss"
            format="MM/dd/yyyy hh:mm:ss aa"
          />
        ) : (
          <KeyboardDatePicker
            className={
              marginTop ? "keyboard-picker margin-top" : "keyboard-picker"
            }
            autoOk
            placeholder="MM/dd/yyyy"
            format="MM/dd/yyyy"
            disabled={disabled}
            value={dateValue !== undefined ? dateValue : null}
            onChange={(date) => handleDateChange(date)}
            inputVariant="outlined"
            variant="inline"
            disableFuture={!isFilterFiled}
          />
        )}
      </MuiPickersUtilsProvider>
    </>
  );
}

export default KeyBoardDatePickerComponent;
