import React, { Component } from "react";
import "../styles/styles.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { HelmetComponent } from "../components/Helmet";
import TablePagination from "@material-ui/core/TablePagination";
import { DocumentImage } from "../components/DocumentImage";

class AchActivityLog extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.props.fetchACHACtivity();
  }

  render() {
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };
    const achActivityLogs = this.props.achActivityLogs;
    const getDate = (dateToConvert) => {
      let dateConverted = new Date(
        parseInt(dateToConvert)
      ).toLocaleDateString();
      return dateConverted;
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Activity Log" />
        <div className={"row"} style={style.card}>
          <TableContainer className="fixed-table-header">
            <Table size="small" stickyHeader aria-label="sticky table" ref={this.tableRef}>
              <TableHead style={{ padding: "1rem" }}>
                <TableCell className="first-tableHeader">Files</TableCell>
                <TableCell className="tableHeader">Date/Time</TableCell>
              </TableHead>
              {achActivityLogs &&
                achActivityLogs
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((list) => (
                    <TableBody>
                      <TableRow>
                        <TableCell className="first-tableBody">
                          <a
                            href={list.fileUrl}
                            target="_blank"
                            className="actionsLink"
                          >
                            <DocumentImage documentURL={list.fileUrl} />
                            {list.fileName}
                          </a>
                        </TableCell>
                        <TableCell className="tableBody">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(list.createdAt)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
            </Table>
            </TableContainer>
            {achActivityLogs && achActivityLogs.length > 0 ? (
              <div style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    achActivityLogs.length > 0 ? achActivityLogs.length : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div style={{     
                display: "flex", 
                width: "100%",
                justifyContent:"center"
                }}>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
          {/* </TableContainer> */}
        </div>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    border: "1px solid rgb(224, 224, 224)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "1rem",
  },
};
export default AchActivityLog;
