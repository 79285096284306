import React, {Component} from "react";
import {Popover} from "antd";
import { UnorderedListOutlined } from '@ant-design/icons';


class DetailPopover extends Component {
    render() {
        return (
                <Popover
                    overlayStyle={{
                        width: "395px"
                    }}
                    getPopupContainer={node => node.parentNode}
                    placement="right"
                    trigger="hover"
                    content={this.props.content}
                >
                   <UnorderedListOutlined style={{marginLeft: '8px', width: '12px', color: '#8ec944', marginTop: '14px'}}/>
                </Popover>
        );
    }
};

export default DetailPopover;

