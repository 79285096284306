import React from 'react';
import {LineChart} from 'bizcharts';

const data = [
    { day: '1', value: 3},
    { day: '2', value: 4},
    { day: '3', value: 3.5 },
    { day: '4', value: "" },
    { day: '5', value: 4.9 },
    { day: '6', value: 6 },
    { day: '7', value: 17 },
    { day: '8', value: 9 },
    { day: '9', value: 13 },
    { day: '10', value: 14 },
    { day: '11', value: 14.6 },
    { day: '12', value: 25 },
    { day: '13', value: 16.5 },
    { day: '14', value: 20 },
    { day: '15', value: "" },
    { day: '16', value: 26 },
    { day: '17', value: 27 },
    { day: '18', value: 20 },
    { day: '19', value: 25},
    { day: '20', value: 24.6 },
    { day: '21', value: 24.6 },
    { day: '22', value: 26 },
    { day: '23', value: 29.5 },
    { day: '24', value: 30 },
    { day: '25', value: 14.9 },
    { day: '26', value: 16 },
    { day: '27', value: 37 },
    { day: '28', value: 28.5 },
    { day: '29', value: 35 },
    { day: '30', value: 40},
];

function LineChartTransaction({lineChartData}){
    return (
        <>
            <LineChart
                data={lineChartData}
                title={{
                    visible: true,
                    text: 'Transaction Summary of Last 30 days',
                    style:{
                        color: "#707070",
                        fontWeight: "600",
                        padding: "0px 0px 20px 0px"
                    }
                }}
                tooltip={{
                    visible: true,
                    shared: true,
                    showCrosshairs: true,
                    }}
                xAxis={{
                    label: {
                        style: {
                            color: "#000000"
                        },
                        // suffix: "$"
                        // stroke: "#000000",
                    },
                }}
                yAxis={{
                    label: {
                        style: {
                            color: "#000000"
                        },
                        // stroke: "#000000",
                    },
                }}
                // smooth={true}
                lineStyle={{stroke: "#D2F3A8"}}
                point={{
                    visible: true,
                    style: {
                        stroke: '#8bc83f',
                        fill: '#ffffff',
                    },

                }}
                xField='day'
                yField='value'
            />
        </>

    );
}

export default LineChartTransaction;
