import { ACTION_TYPE_VENDOR } from "../constants/Type";

const initialState = {
  loading: false,
  openVendorSnackBar: false,
  errorMsgForVendor: "",
  openVendorFormSnackBar: false,
  vendorData: {
    vendorName: "",
    vendorTypeId: "",
    address: [],
    vendorContactNumber: "",
    accountName: "",
    accountNumber: "",
    routingNumber: "",
    bankName: "",
    bankAccountType: "",
    isWithdrawal: false,
  },
  vendors: {},
  vendorAccount: [],
  vendorClientData: {
    clientId: "",
    clientRefId: "",
    vendorId: "",
  },
  openVendorSuccessSnackBar: false,
  openVendorErrorSnackBar: false,
  openAddVendorSuccessSnackBar: false,
  vendorClientList: [],
  successMsg: "",
  success: false,
  addressData: {
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip5: "",
  },
  addressArray: [],
  isDefault: false,
  clientVendor: [],
};

const vendorState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_VENDOR.VENDOR_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_VENDOR.CLEAR_VENDOR_INPUT_DATA:
      return {
        ...state,
        vendorData: {
          vendorName: "",
          vendorTypeId: "",
          address: [],
          vendorContactNumber: "",
          accountName: "",
          accountNumber: "",
          routingNumber: "",
          bankName: "",
          bankAccountType: "",
          isWithdrawal: false,
          // vendorId: '',
        },
      };
    case ACTION_TYPE_VENDOR.CLOSE_SNACK_BAR_VENDOR_PAGE:
      return {
        ...state,
        openVendorFormSnackBar: false,
        openVendorSnackBar: false,
        openVendorSuccessSnackBar: false,
        openVendorErrorSnackBar: false,
        openAddVendorSuccessSnackBar: false,
      };
    case ACTION_TYPE_VENDOR.ADD_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        addVendorSuccess: action.bool,
        openVendorFormSnackBar: false,
        openAddVendorSuccessSnackBar: true,
        successMsg: "Successfully added",
      };
    case ACTION_TYPE_VENDOR.ADD_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
        openVendorFormSnackBar: true,
      };
    case ACTION_TYPE_VENDOR.HANDLE_CHANGE_VENDOR_INPUT:
      const newVendorForm = { ...state.vendorData };
      newVendorForm[action.id] = action.value;
      return {
        ...state,
        vendorData: newVendorForm,
      };
    case ACTION_TYPE_VENDOR.FETCH_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorLength: action.vendorAccount && action.vendorAccount.length,
        vendorAccount: action.vendorAccount.vendorAccounts,
        routingNumber: action.vendorAccount.vendorAccount,
        openVendorSnackBar: false,
      };
    case ACTION_TYPE_VENDOR.FETCH_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
        openVendorSnackBar: false,
      };
    case ACTION_TYPE_VENDOR.SEARCH_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorAccount: action.vendorList.vendorAccounts,
      };
    case ACTION_TYPE_VENDOR.SEARCH_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
      };
    case ACTION_TYPE_VENDOR.SET_VENDOR_TO_EDIT:
      const vendors = action.vendors;
      return {
        ...state,
        loading: false,
        vendors: action.vendors,
        vendorData: {
          vendorName: vendors.vendorName,
          vendorId: vendors.vendorId,
          vendorTypeId: vendors.vendorType && vendors.vendorType.vendorTypeId,
          vendorType: vendors.vendorType && vendors.vendorType.vendorTypeName,
          address: vendors.address,
          vendorContactNumber: vendors.vendorContactNumber,
          accountName: vendors.accountName,
          accountNumber: vendors.accountNumber,
          routingNumber: vendors.routingNumber,
          bankName: vendors.bankName,
          bankAccountType: vendors.bankAccountType,
          isWithdrawal: vendors.isWithdrawal,
        },
        isDefault: vendors.vendorType && vendors.vendorType.isDefault,
      };
    case ACTION_TYPE_VENDOR.UPDATE_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        addVendorSuccess: action.bool,
        openAddVendorSuccessSnackBar: true,
        successMsg: "Successfully updated",
      };
    case ACTION_TYPE_VENDOR.UPDATE_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
        openVendorFormSnackBar: true,
      };
    case ACTION_TYPE_VENDOR.DELETE_VENDOR_SUCCESS:
      const oldVendorList = state.vendorData;
      let newVendor = [];
      for (let i = 0; i < oldVendorList.length; i++) {
        if (oldVendorList[i].vendorId !== action.vendorId) {
          newVendor.push(oldVendorList[i]);
        }
      }
      return {
        ...state,
        newVendor: newVendor,
        loading: false,
      };
    case ACTION_TYPE_VENDOR.DELETE_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
      };
    //vendor client
    case ACTION_TYPE_VENDOR.HANDLE_CHANGE_VENDOR_CLIENT_DATA:
      const newVendorClient = { ...state.vendorClientData };
      newVendorClient[action.id] = action.value;

      return {
        ...state,
        vendorClientData: newVendorClient,
      };
    case ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorClientList: action.vendorClient.vendorClients,
      };
    case ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
      };
    case ACTION_TYPE_VENDOR.ADD_VENDOR_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.response,
        openVendorSuccessSnackBar: true,
        successMsg: "Successfully added",
      };
    case ACTION_TYPE_VENDOR.ADD_VENDOR_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
        openVendorErrorSnackBar: true,
      };
    case ACTION_TYPE_VENDOR.UPDATE_VENDOR_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.response,
        openVendorSuccessSnackBar: true,
        successMsg: "Successfully updated",
      };
    case ACTION_TYPE_VENDOR.UPDATE_VENDOR_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForVendor: action.error,
        openVendorErrorSnackBar: true,
      };
    case ACTION_TYPE_VENDOR.SET_VENDOR_CLIENT_TO_EDIT:
      return {
        ...state,
        loading: false,
        vendorClientData: action.vendorClient,
      };
    case ACTION_TYPE_VENDOR.HANDLE_CHANGE_ADDRESS_DATA:
      const newAddressData = { ...state.addressData };
      newAddressData[action.id] = action.value;
      return {
        ...state,
        addressData: newAddressData,
      };
    case ACTION_TYPE_VENDOR.HANDLE_CHANGE_ADDRESS_ARRAY:
      return {
        ...state,
        addressArray: [...state.addressArray, state.addressData],
      };
    case ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENT_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        clientVendor: action.clientVendor.vendorClients,
        loading: false,
      };
    case ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENT_BY_CLIENT_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default vendorState;
