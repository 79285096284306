import React, {Component} from "react";
import {
    Button,
    DialogActions, MenuItem, MenuList, Popover
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "../screens/PaperComponent";
import DialogTitleComponent from "../screens/DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Avatar} from "antd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {DeleteOutline} from "@material-ui/icons";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ButtonComponent from "../screens/ButtonComponent";

class NoteComponent extends Component {
    constructor(props) {
        super(props);
        this.state={
            openAddNoteModal: false,
            openUpdateNoteModal: false,
            openDeleteNoteModal: false,
            openActionMenu: false,
            popoverElement: null,
            page: 0,
            rowsPerPage: 4,
            stateNote: {},
            noteId: '',

        }
    }
    componentDidMount() {
        if(this.props.isClient){
            this.props.fetchNoteById(this.props.clientId);
        }else {
            this.props.fetchNoteById(this.props.caseId);
        }
    }

    render() {
        const handleOpenAddNoteModal = () => {
            this.props.clearNoteDescription();
            this.setState({
                openAddNoteModal: true,
            })
        }
        const handleCloseAddNoteModal = () => {
            this.setState({
                openAddNoteModal: false,
            })
        }
        const handleOpenUpdateNoteModal = () => {
            this.setState({
                openUpdateNoteModal: true,
            })
        }
        const handleCloseUpdateNoteModal = () => {
            this.setState({
                openUpdateNoteModal: false,
            })
        }
        const handleOpenDeleteNoteModal = () => {
            this.setState({
                openDeleteNoteModal: true,
            })
        }
        const handleCloseDeleteNoteModal = () => {
            this.setState({
                openDeleteNoteModal: false,
            })
        }
        const noteData = this.props.noteData;
        const notes = this.props.notes;
        const value = noteData.description !== "";

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        };

        return (
            <React.Fragment>
                <div>
                    <div 
                    className={"d-flex flex-row-reverse div-flex"} 
                    style={{paddingTop: "0px"}}
                    >
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenAddNoteModal();
                                }}>
                                <AddIcon/> Add Notes
                            </button>
                        </div>
                    </div>
                    <div
                        // className="row"
                        className="noteOuterDiv"
                        style={style.card}
                    >
                        {
                            notes && notes.map(note =>
                                <>
                                    <div
                                        // className="col-6 noteDiv"
                                        className="noteDiv"
                                    >
                                        <Avatar
                                            src={note.profilePicture ? note.profilePicture : null}
                                            alt="profile"
                                        />
                                        <span className="noteAddedName">
                                            {note.userName}
                                        </span>
                                        <span className="createdAt">
                                              {/*<span style={{color: "#000000e0"}}>Created At : </span>*/}
                                                {
                                                    new Intl.DateTimeFormat('default', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit'
                                                    }).format(note.createdAt)
                                                }
                                        </span>
                                        <span style={{
                                            marginLeft: "2%",
                                            // marginLeft: "52%"
                                        }}
                                        >
                                           <MoreHorizIcon
                                               id="Popover1"
                                               style={{cursor: 'pointer', color: "#808080d6"}}
                                               onClick={(e) => {
                                                   e.preventDefault();
                                                   this.setState({
                                                       popoverElement: e.currentTarget,
                                                       openActionMenu: true,
                                                       stateNote: note,
                                                       noteId: note.noteId,
                                                   })
                                               }}
                                           />
                                            <Popover
                                                open={this.state.openActionMenu}
                                                anchorEl={this.state.popoverElement}
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    this.setState({openActionMenu: false})
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                style={{boxShadow: 'none'}}
                                            >
                                                <MenuList id="popover-list" style={{
                                                    color: '#4d4d4d',
                                                    padding: '1rem !important',
                                                    cursor: 'pointer'
                                                }}>
                                                    <MenuItem
                                                        onClick={e => {
                                                            // e.preventDefault();
                                                            handleOpenUpdateNoteModal();
                                                            this.props.setNotesToEdit(this.state.stateNote);
                                                            this.setState({openActionMenu: false,}
                                                            );
                                                        }}>
                                                        <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={e => {
                                                            // e.preventDefault();
                                                            this.setState({openActionMenu: false,});
                                                            handleOpenDeleteNoteModal();
                                                        }}>
                                                        <DeleteOutline style={{paddingBottom: 0}}/> Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </Popover>
                                        </span>
                                        <p className="noteDescription">
                                            {note.description}
                                        </p>
                                        <div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                {/*modal to add notes*/}
                <Dialog
                    open={this.state.openAddNoteModal}
                    onClose={handleCloseAddNoteModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseAddNoteModal}>
                            Add Notes
                        </DialogTitleComponent>
                        <DialogContent dividers style={{paddingTop: "5px", borderBottom: "none"}} className="dialogContentBig">
                            <div className="row">
                                <div className="col-lg-12">
                                    <label className="label">Note
                                        <span style={{color: "red"}}> *</span>
                                    </label>
                                    <textarea
                                        id="description"
                                        style={{minHeight: "200px"}}
                                        className="textAreaDiv"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.props.handleChangeNoteDescription("description", e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 10px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    if(this.props.isClient){
                                        const data = {
                                            description: noteData.description,
                                            refId: this.props.clientId,
                                        }
                                        this.props.addClientNotes(data);
                                    }else{
                                        const data = {
                                            description: noteData.description,
                                            refId: this.props.caseId,
                                        }
                                        this.props.addCaseNotes(data);
                                    }
                                    handleCloseAddNoteModal();
                                }}
                                value={value}
                                loading={this.props.loading}
                                buttonName="Add"
                            />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*modal to update notes*/}
                <Dialog
                    open={this.state.openUpdateNoteModal}
                    onClose={handleCloseUpdateNoteModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseUpdateNoteModal}>
                            Update Notes
                        </DialogTitleComponent>
                        <DialogContent dividers style={{paddingTop: "5px", borderBottom: "none"}} className="dialogContentBig">
                            <div className="row">
                                <div className="col-lg-12">
                                    <label className="label">Note
                                        <span style={{color: "red"}}> *</span>
                                    </label>
                                    <textarea
                                        id="description"
                                        value={noteData.description}
                                        style={{minHeight: "200px"}}
                                        className="textAreaDiv"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.props.handleChangeNoteDescription("description", e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 10px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    if(this.props.isClient){
                                        const data = {
                                            description: noteData.description,
                                            refId: this.props.clientId,
                                            noteId: noteData.noteId,
                                        }
                                        this.props.updateClientNotes(data);
                                    }else{
                                        const data = {
                                            description: noteData.description,
                                            refId: this.props.caseId,
                                            noteId: noteData.noteId,
                                        }
                                        this.props.updateCaseNotes(data);
                                    }
                                    handleCloseUpdateNoteModal();
                                }}
                                value={value}
                                loading={this.props.loading}
                                buttonName="Update"
                            />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*DELETE CLIENT CONTACT*/}
                <Dialog
                    open={this.state.openDeleteNoteModal}
                    onClose={handleCloseDeleteNoteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px'
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete note?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteNoteModal}
                                            style={style.cancelButton}
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleCloseDeleteNoteModal();
                                                this.props.deleteNotes(this.state.noteId);
                                            }}
                                            style={style.deleteCaseTemplateButton}
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
};


const style = {
    card: {
        background: "rgb(255, 255, 255)",
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "1rem",
        maxHeight:"382px",
        overflow:"auto",
    },
    deleteCaseTemplateButton: {
        background: '#8BC83F',
        color: 'white',
        width: '100%',
        padding: '0.5rem'
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
}

export default NoteComponent;