import React, {Component} from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Button,
    CircularProgress,
    DialogActions,
    MenuItem,
    MenuList,
    Popover,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TablePagination from "@material-ui/core/TablePagination";
import DialogContentText from "@material-ui/core/DialogContentText";
import MySnackbarContentWrapper from "../components/Snackbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import DescriptionIcon from "@material-ui/icons/Description";
import {AutoComplete, Select, Space} from "antd";
import PaperComponent from "./PaperComponent";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DialogTitleComponent from "./DialogTitleComponent";
import OpenAddressFields from "../components/DynamicAddressField";
import {MinusCircleOutlined} from "@ant-design/icons";
import Helpers from "../components/Helper";
import {HelmetComponent} from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";

const {Option} = Select;

class CompanyScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDropdownForContact: false,
            openDeleteContactDialogue: false,
            contacts: [],
            editContacts: [],
            updatedContacts: [],
            openActionMenu: false,
            popoverElement: null,
            companyData: {},
            setValue: false,
            contactData: {},
            selectedData: {},
            contactNameValue: "",
            optionsArr: [],
            clicked: false,
            existingContact: false,
            openFields: false,
            address:[],
            updatedAddress: [],
            addressId:"",
            openDeleteModal: false,
            errors: {
                name: '',
                contactNumber: '',
                emailPhone:'',
            }
        }
    }

    componentDidMount() {
        this.props.fetchCompanyList();
        this.props.fetchCompanyType();
    }

    componentWillUnmount() {
        this.setState({
            changed: false,
        })
    }

    keyPressed = (event) => {
        if (event.key === 'Tab') {
            console.log("here")
        }
    };

    render() {
        const {errors} = this.state;
        const companyState = this.props && this.props.propsState.contactState;
        const companyData = companyState && companyState.companyData;
        const companyList = companyState && companyState.companyList;
        const contactsList = companyState && companyState.contactList;
        const companyTypeList = companyState && companyState.companyTypeList;
        const companyValue = companyData.email !== "" && companyData.companyName !== "" &&
        companyData.companyTypeId !== "" && companyData.phone !== "";
        const loading =  companyState && companyState.loading;

        //ADDRESS FOR COMPANY
        const showInputField = () => {
            let a = createAddressObject();
            this.setState({
                address: [...this.state.address, a]
            });
        };
        const createAddressObject = () => {
            return {
                addressId: String(Math.random()),
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip5: "",
            };
        };
        const filterAddressData = () => {
            let addressForm = [];
            this.state.address.map((single) => {
                const data = {
                    address1: single.address1,
                    address2: single.address2,
                    city: single.city,
                    state: single.state,
                    zip5: single.zip5,
                }
                return (
                    addressForm.push(data)
                )
            });
            this.props.handleChangeCompanyInput("address", addressForm);
        };
        const filterEditAddressData = () => {
            let resultA = this.state.updatedAddress.filter(elm =>
                this.state.address.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));
            let resultB = this.state.address.filter(elm =>
                !this.state.updatedAddress.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm))).map(
                single => {
                    const data = {
                        address1: single.address1,
                        address2: single.address2,
                        city: single.city,
                        state: single.state,
                        zip5: single.zip5,
                    }
                    return data
                });
            let addressForm = [...resultA, ...resultB];
            this.props.handleChangeCompanyInput("address", addressForm);
        };
        const handleOpenDeleteDialogue = addressId => {
            this.setState({
                openDeleteModal: true,
                addressId: addressId
            });
        };
        const handleCloseDeleteDialogue = () => {
            this.setState({
                openDeleteModal: false,
                addressId: ""
            });
        };
        const handleOkDeleteDialogue = () => {
            closeFieldSection(this.state.addressId);
            this.setState({
                openDeleteModal: false,
                addressId: ""
            });
        };
        const closeFieldSection = key => {
            var removedActionData = this.state.address.filter(single => single.addressId !== key);
            this.setState({
                address: removedActionData
            }, () => {
                filterAddressData()
            })
        };

        //adding contacts while adding company
        const handleClickOpenDeleteContactDialogue = contactId => {
            this.setState({
                openDeleteContactDialogue: true,
                contactId: contactId
            });
        };
        const handleCloseDeleteContactDialogue = () => {
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };
        const handleOkDeleteContactDialogue = () => {
            closeContactFieldSection(this.state.contactId);
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };
        const createAddContactObject = () => {
            return {
                id: Math.random(),
                value: {
                    name: '',
                    emailPhone: '',
                    contactType: 'UNKNOWN_CONTACT_TYPE',
                    address: '',
                    phone: '',
                }
            };
        }
        const showInputForField = () => {
            let a = createAddContactObject();
            this.setState({
                openDropdownForContact: true,
                contacts: [...this.state.contacts, a],
            });
        };
        const changeContactFieldNameValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.name = value;
            filterContactData()
        };
        const changeContactFieldEmailValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.emailPhone = value;
            filterContactData()
        };
        const changeContactFieldTypeValue = (key, value) => {
            if (value === "") {
                this.state.contacts.filter(single => single.id === key)[0].value.contactType = "UNKNOWN_CONTACT_TYPE";
            } else {
                this.state.contacts.filter(single => single.id === key)[0].value.contactType = value;
            }
            filterContactData()
        };
        const changeContactFieldAddressValue = (key, value) => {
            let addressValue =  this.state.contacts.filter(single => single.id === key)[0] &&
                this.state.contacts.filter(single => single.id === key)[0].value && this.state.contacts.filter(single => single.id === key)[0].value
            if(addressValue !== undefined){
                addressValue.address = value;
                filterContactData()
            }

        };
        const changeContactFieldPhoneValue = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.phone = value;
            filterContactData()
        };
        //optional
        const changeContactFieldId = (key, value) => {
            this.state.contacts.filter(single => single.id === key)[0].value.contactId = value;
            filterContactData()
        };
        const filterContactData = () => {
            let contactForm = [];
            this.state.contacts.map((single) => {
                return (
                    contactForm.push(single.value)
                )
            });
            this.props.handleChangeCompanyInput("contact", contactForm)
        };

        const closeContactFieldSection = key => {
            var removedActionData = this.state.contacts.filter(single => single.id !== key);
            this.setState({
                contacts: removedActionData
            }, () => {
                filterContactData()
            })
        };
        const openFieldForContact = () => {
            let div = [];
            if (this.state.contacts.length > 0) {
                div.push(<>
                    <h5 style={{
                        width: '100%',
                        color: '#8BC83F',
                        paddingBottom: '0.8rem',
                        fontWeight: '550'
                    }}>List of contacts</h5>
                    <Space
                        style={{ display: "flex" }}
                        align="baseline"
                    >
                        <div style={{width: "200px"}}>
                            <label htmlFor="name" style={{marginBottom: "0px" }}>Name
                                <span style={{color: "red"}} > *</span></label>
                        </div>
                        <div style={{width: "182px"}}>
                            <label htmlFor="emailPhone" style={{marginBottom: "0px" }}>Email
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div style={{width: "200px"}} >
                            <label htmlFor="contactType" style={{marginBottom: "0px" }}>Contact Type
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div >
                            <label htmlFor="phone" style={{marginBottom: "0px" }}>Phone Number</label>
                        </div>
                    </Space>
                </>)
            }

            this.state.contacts.map(single => {
                return div.push(
                    <>
                        <Space
                            key={single.id}
                            style={{ display: "flex" }}
                            align="baseline"
                        >
                            <div style={{width: "200px"}}>
                                <div className="auto-comp-select-contact h40">
                                    <div>
                                    <AutoComplete
                                        key="Autocomplete"
                                        style={{marginTop: "6px"}}
                                        getPopupContainer={node => node.parentNode}
                                        dropdownClassName="drop"
                                        dropdownMatchSelectWidth
                                        onSearch={onSearchFunction}
                                        options={this.state.optionsArr}
                                        value={this.state.contactNameValue !== ''
                                        && this.state.selectedId === single.id ?
                                            this.state.contactNameValue
                                            : single.value.name}
                                        onSelect={(e, options) => {
                                            this.setState({
                                                contactNameValue: e.split('(')[0],
                                                selectedData: options.single,
                                                selectedId: single.id,
                                            }, () => {
                                                changeContactFieldEmailValue(single.id, options.single.emailPhone);
                                                changeContactFieldId(single.id, options.single.contactId);
                                                changeContactFieldAddressValue(single.id, options.single.address);
                                                changeContactFieldPhoneValue(single.id, options.single.phone);
                                                changeContactFieldTypeValue(single.id, options.single.contactType);
                                                changeContactFieldNameValue(single.id, options.single.name);
                                            })
                                        }}
                                        allowClear
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={(e) => {
                                            changeContactFieldNameValue(single.id, e);
                                            this.setState({
                                                contactNameValue: "",
                                            }, () => {
                                                if (this.state.contactNameValue === "") {
                                                    changeContactFieldEmailValue(single.id, "");
                                                    changeContactFieldAddressValue(single.id, "");
                                                    changeContactFieldPhoneValue(single.id, "");
                                                    changeContactFieldTypeValue(single.id, "");
                                                }
                                            })

                                        }}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <input
                                    id={single.id}
                                    className="inputField"
                                    // style={style.inputFieldContact}
                                    value={this.state.contactNameValue !== '' &&
                                    this.state.selectedId === single.id
                                        ? this.state.selectedData.emailPhone
                                        : single.value.emailPhone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldEmailValue(single.id, e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <div style={{width: "200px"}}>
                                    <div className="select-input selects h40" style={{marginTop: "6px"}}>
                                        <Select
                                            id={single.id}
                                            value={this.state.contactNameValue !== '' &&
                                            this.state.selectedId === single.id
                                                ? this.state.selectedData.contactType
                                                === "UNKNOWN_CONTACT_TYPE" ? "" :
                                                    this.state.selectedData.contactType
                                                : single.value.contactType === "UNKNOWN_CONTACT_TYPE" ?
                                        " " : single.value.contactType}
                                            showSearch={false}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={e => {
                                                this.setState({isBlocking: true});
                                                changeContactFieldTypeValue(single.id, e)
                                            }}
                                            dropdownStyle={{height: "100px", overflowY:"auto"}}
                                        >
                                            <Option value="UNKNOWN_CONTACT_TYPE" hidden/>
                                            <Option value="EVERFUND_USER_CONTACT">Everfund user</Option>
                                            <Option value="CORPORATE_CONTACT">Corporate</Option>
                                            <Option value="CLIENT_CONTACT">Client</Option>
                                            <Option value="CLIENT_RELATIVE_CONTACT">Client Relative</Option>
                                            <Option value="OTHER_CONTACT_TYPE">Other contact type</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <input
                                    id={single.id}
                                    className="inputField"
                                    // style={style.inputFieldContact}
                                    value={this.state.contactNameValue !== '' &&
                                    this.state.selectedId === single.id
                                        ? this.state.selectedData.phone
                                        : single.value.phone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldPhoneValue(single.id, e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                <MinusCircleOutlined
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        handleClickOpenDeleteContactDialogue(single.id);
                                    }}
                                />
                            </div>
                        </Space>
                    </>
                );
            });
            return div;
        };
        const onSearchFunction = (event) => {
            if (!event) {
                this.setState({
                    optionsArr: []
                })
            } else {
                this.props.searchContact(event);
                this.setState({
                    optionsArr:
                        contactsList && contactsList.map(single => {
                            const value = {
                                value: single.name + "(" + single.emailPhone + " )",
                                single: single,
                            }
                            return value
                        })
                })
            }
        }

        //adding contacts while editing company
        const handleClickOpenDeleteContactDialogueInEdit = contactId => {
            this.setState({
                openDeleteContactDialogueInEdit: true,
                contactId: contactId
            });
        };
        const handleCloseDeleteContactDialogueInEdit = () => {
            this.setState({
                openDeleteContactDialogueInEdit: false,
                contactId: ""
            });
        };
        const handleOkDeleteContactDialogueWhileUpdate = () => {
            closeContactEditFieldSection(this.state.contactId);
            this.setState({
                openDeleteContactDialogueInEdit: false,
                contactId: ""
            });
        };

        const showEditInputForField = () => {
            let a = createAddContactObjectInEdit();
            this.setState({
                openDropdownForContact: true,
                editContacts: [...this.state.editContacts, a],
            });
        };

        const createAddContactObjectInEdit = () => {
            let formData = {
                contactId: String(Math.random()),
                name: '',
                emailPhone: '',
                contactType: 'UNKNOWN_CONTACT_TYPE',
                address: '',
                phone: '',
            };
            return formData;
        };
        const changeEditContactFieldNameValue = (key, value) => {
            let newContact = this.state.editContacts.filter(single => single.contactId === key)[0];
            newContact.name = value;
            let otherContacts = this.state.editContacts.filter(single => single.contactId != key);
            otherContacts.splice(this.state.editContacts.findIndex(single => single.contactId == key), 0, newContact);
            this.setState({editContacts: otherContacts});
            this.props.handleChangeCompanyInput("contact", this.state.editContacts)
        };
        const changeEditContactFieldEmailValue = (key, value) => {
            let newContact = this.state.editContacts.filter(single => single.contactId === key)[0];
            newContact.emailPhone = value;
            let otherContacts = this.state.editContacts.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContacts.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContacts: otherContacts});
            this.props.handleChangeCompanyInput("contact", this.state.editContacts)
        };
        const changeEditContactFieldTypeValue = (key, value) => {
            let newContact = this.state.editContacts.filter(single => single.contactId === key)[0];
            newContact.contactType = value;
            let otherContacts = this.state.editContacts.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContacts.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContacts: otherContacts});
            this.props.handleChangeCompanyInput("contact", this.state.editContacts)
        };
        const changeEditContactFieldAddressValue = (key, value) => {
            let newContact = this.state.editContacts.filter(single => single.contactId === key)[0];
            newContact.address = value;
            let otherContacts = this.state.editContacts.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContacts.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContacts: otherContacts});
            this.props.handleChangeCompanyInput("contact", this.state.editContacts)
        };
        const changeEditContactFieldPhoneValue = (key, value) => {
            let newContact = this.state.editContacts.filter(single => single.contactId === key)[0];
            newContact.phone = value;
            let otherContacts = this.state.editContacts.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContacts.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContacts: otherContacts});
            this.props.handleChangeCompanyInput("contact", this.state.editContacts)
        };
        const changeEditContactFieldContactValue = (key, value) => {
            let newContact = this.state.editContacts.filter(single => single.contactId === key)[0];
            newContact.contactId = value;
            let otherContacts = this.state.editContacts.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContacts.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContacts: otherContacts});
            this.props.handleChangeCompanyInput("contact", this.state.editContacts)
        };
        const closeContactEditFieldSection = key => {
            var removedActionData = this.state.editContacts.filter(single => single.contactId !== key);
            this.setState({
                editContacts: removedActionData,
            }, () => {
                this.props.handleChangeCompanyInput("contact", this.state.editContacts)
            })
        };
        const openEditFieldForContact = () => {
            let div = [];
            if (this.state.editContacts.length > 0) {
                div.push(<>
                    <h5 style={{
                        width: '100%',
                        color: '#8BC83F',
                        paddingBottom: '0.8rem',
                        fontWeight: '550'
                    }}>List of contacts</h5>
                    <Space
                        style={{ display: "flex" }}
                        align="baseline"
                    >
                        <div style={{width: "200px"}}>
                            <label htmlFor="name" style={{marginBottom: "0px" }}>Name
                                <span style={{color: "red"}} > *</span></label>
                        </div>
                        <div style={{width: "182px"}}>
                            <label htmlFor="emailPhone" style={{marginBottom: "0px" }}>Email
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div style={{width: "200px"}} >
                            <label htmlFor="contactType" style={{marginBottom: "0px" }}>Contact Type
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div >
                            <label htmlFor="phone" style={{marginBottom: "0px" }}>Phone Number</label>
                        </div>
                    </Space>
                </>)
            }
            if (this.state.editContacts[0] != undefined && this.state.editContacts[0].contactId) {
                this.state.editContacts.map(single => {
                    return div.push(
                        <>
                            <Space
                                key={single.id}
                                style={{ display: "flex" }}
                                align="baseline"
                            >
                                <div style={{width: "200px"}}>
                                    <div className="auto-comp-select-contact h40">
                                        <div>
                                            <AutoComplete
                                                style={{marginTop: "6px"}}
                                                getPopupContainer={node => node.parentNode}
                                                dropdownClassName="drop"
                                                dropdownMatchSelectWidth
                                                onSearch={onSearchFunction}
                                                options={this.state.optionsArr}
                                                value={single.name}
                                                onSelect={(e, options) => {
                                                    this.setState({
                                                        contactNameValue: e.split('(')[0],
                                                        selectedData: options.single,
                                                        selectedId: single.id,
                                                    })
                                                    changeEditContactFieldContactValue(single.contactId, options.single.contactId);
                                                    changeEditContactFieldEmailValue(single.contactId, options.single.emailPhone);
                                                    changeEditContactFieldAddressValue(single.contactId, options.single.address);
                                                    changeEditContactFieldPhoneValue(single.contactId, options.single.phone);
                                                    changeEditContactFieldTypeValue(single.contactId, options.single.contactType);
                                                    changeEditContactFieldNameValue(single.contactId, options.single.name);
                                                }}
                                                allowClear
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={(e) => {
                                                    changeEditContactFieldNameValue(single.contactId, e);
                                                    this.setState({
                                                        contactNameValue: "",
                                                    },)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        id={single.id}
                                        className="inputField"
                                        value={single.emailPhone}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            changeEditContactFieldEmailValue(single.contactId, e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <div style={{width: "200px"}}>
                                        <div className="select-input selects h40" style={{marginTop: "6px"}}>
                                            <Select
                                                id={single.id}
                                                value={single.contactType === "UNKNOWN_CONTACT_TYPE" ?
                                                "" : single.contactType }
                                                showSearch={false}
                                                getPopupContainer={node => node.parentNode}
                                                onChange={e => {
                                                    this.setState({isBlocking: true});
                                                    changeEditContactFieldTypeValue(single.contactId, e)
                                                }}
                                                dropdownStyle={{height: "100px", overflowY: "scroll"}}
                                            >
                                                <Option value="EVERFUND_USER_CONTACT">Everfund user</Option>
                                                <Option value="CORPORATE_CONTACT">Corporate</Option>
                                                <Option value="CLIENT_CONTACT">Client</Option>
                                                <Option value="CLIENT_RELATIVE_CONTACT">Client Relative</Option>
                                                <Option value="OTHER_CONTACT_TYPE">Other contact type</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        id={single.id}
                                        className="inputField"
                                        // style={style.inputFieldContact}
                                        value={single.phone}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            this.setState({isBlocking: true});
                                            changeEditContactFieldPhoneValue(single.contactId, e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <MinusCircleOutlined
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({
                                                isBlocking: true,
                                                contactData: single,
                                            });
                                            handleClickOpenDeleteContactDialogueInEdit(single.contactId);
                                        }}
                                    />
                                </div>
                            </Space>
                        </>
                    );
                });
            }
            return div;
        };

        const addCompanyForm = () => {
            if (!this.state.clicked) {
                this.setState({
                    clicked: true
                })
                this.props.addCompany(companyData);
                setTimeout(() => {
                    this.setState({
                        clicked: false
                    })
                }, 3000);
            }
        }
        return (
            <React.Fragment>
                <HelmetComponent title="Contact"/>
                {this.state.setValue === false &&
                companyData && companyData.contact
                && companyData.contact.length > 0 ?
                    this.setState({
                        editContacts: companyData.contact,
                        setValue: true
                    }) : null}
                {this.state.changed === false && companyData ? this.setState({
                    contactData: companyData,
                    name: companyData.name,
                    changed: true,
                    fromBack: true
                }) : null}
                {companyState && companyState.loading ? (
                    <div
                        className={
                            companyState && companyState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <div style={{margin: "0 3% 2%"}}>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                // style={{width: '155px'}}
                                onClick={(e) => {
                                    this.setState({
                                        openDropdownForContact: false,
                                        contacts: [],
                                        address:[],
                                        errors: {
                                            name: '',
                                            contactNumber: '',
                                            emailPhone:'',
                                        }
                                    })
                                    e.preventDefault();
                                    this.props.handleChangeOpenModalToAddCompany()
                                }}>
                                <AddIcon/> Add Company
                            </button>
                        </div>
                        <div className="search">
                            <div className="searchIcon">
                                <SearchIcon style={{color: '#9c9c9c'}}/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                className="inputBase"
                                onChange={(e) => {
                                    e.preventDefault();
                                    if (e.target.value === "" || e.target.value === " ") {
                                        this.props.fetchCompanyList();
                                    } else {
                                        this.props.searchCompany(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header-second">
                            <Table size="small" stickyHeader aria-label="sticky table" ref={this.props.tableRef}> 
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader" style={{width: "410px"}}>
                                            Name
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Email
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Phone number
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    companyList && companyList.slice(
                                        this.props.page * this.props.rowsPerPage,
                                        this.props.page * this.props.rowsPerPage +
                                        this.props.rowsPerPage
                                    ).map(list => (
                                        <TableBody key={list.companyId}>
                                            <TableRow>
                                                <TableCell className="first-tableBody">
                                                    {list.companyName}
                                                </TableCell>
                                                <TableCell className="tableBody" style={{width: "510px"}}>
                                                    {list.email}
                                                </TableCell>
                                                <TableCell className="tableBody">
                                                    {list.phone ? list.phone : "-"}
                                                </TableCell>
                                                <TableCell>
                                                    <MoreHorizIcon
                                                        id="Popover1"
                                                        style={{cursor: 'pointer'}}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                    popoverElement: e.currentTarget,
                                                                    openActionMenu: true,
                                                                    companyData: list,
                                                                },
                                                                () => {
                                                                    this.props.setCompanyInputToEdit(this.state.companyData)
                                                                })
                                                        }}
                                                    />
                                                    <Popover
                                                        open={this.state.openActionMenu}
                                                        anchorEl={this.state.popoverElement}
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            this.setState({openActionMenu: false})
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        style={{boxShadow: 'none'}}
                                                    >
                                                        <MenuList id="popover-list" style={{
                                                            color: '#4d4d4d',
                                                            // padding: '1rem !important',
                                                            cursor: 'pointer'
                                                        }}>
                                                            <MenuItem
                                                                style={{padding : "5px"}}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.handleChangeOpenModalToSetCompanyAccount();
                                                                    this.setState({openActionMenu: false,});
                                                                }}>
                                                                <AccountBoxIcon style={{marginRight:"8px"}}/> Set Account
                                                            </MenuItem>
                                                            <MenuItem
                                                                style={{padding : "5px"}}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.property.history.push({
                                                                        pathname: "/company/" + this.state.companyData.companyId + '/files'
                                                                    });
                                                                    this.setState({openActionMenu: false,}
                                                                    );
                                                                }}>
                                                                <DescriptionIcon style={{marginRight:"8px"}}/> Files
                                                            </MenuItem>
                                                            <MenuItem
                                                                style={{padding : "5px"}}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.handleChangeOpenModalToEditCompany();
                                                                    // this.props.setCompanyInputToEdit(this.state.companyData)
                                                                    this.setState({
                                                                            openActionMenu: false,
                                                                        }
                                                                    );
                                                                    if (companyData.contact === undefined) {
                                                                        this.setState({
                                                                            editContacts: [],
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            editContacts: companyData.contact,
                                                                            updatedContacts: companyData.contact,
                                                                        })
                                                                    }
                                                                    if(companyData && companyData.address !== undefined){
                                                                        this.setState({
                                                                            address: companyData.address,
                                                                            editAddress: companyData.address,
                                                                            updatedAddress: companyData.address,
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            address: [],
                                                                            editAddress: [],
                                                                        })
                                                                    }
                                                                    this.setState({openActionMenu: false,
                                                                        errors: {
                                                                            name: '',
                                                                            contactNumber: '',
                                                                            emailPhone:'',
                                                                        },}
                                                                    );
                                                                }}>
                                                                <EditOutlinedIcon style={{marginRight:"8px"}}/> Edit
                                                            </MenuItem>
                                                            <MenuItem
                                                                style={{padding : "5px"}}
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.handleChangeOpenModalToDeleteCompany();
                                                                    this.setState({openActionMenu: false,});
                                                                }}>
                                                                <DeleteOutlinedIcon style={{marginRight:"8px"}}/> Delete
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Popover>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))
                                }
                            </Table>
                            </TableContainer>
                            {companyList && companyList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            companyList.length > 0
                                                ? companyList.length
                                                : 100
                                        }
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={this.props.handleChangePage}
                                        onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>
                {/*add company modal*/}
                <Dialog
                    open={this.props.openModalToAddCompany}
                    onClose={this.props.handleChangeCloseModalToAddCompany}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={()=>{
                                this.props.handleChangeCloseModalToAddCompany()
                                this.setState({
                                    errors: {
                                        name: '',
                                        contactNumber: '',
                                        emailPhone:'',
                                    }
                                })
                            }}>
                            Add Company
                        </DialogTitleComponent>
                        <DialogContent dividers
                                       style={{
                                           maxHeight: "575px",
                                           paddingTop: "5px",
                                           borderBottom: "none"
                                       }}
                                       className="dialogContentWidth850">
                            <div className="row" >
                                <div className="col-6">
                                    <label className="label">Name <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="companyName"
                                        className="input"
                                        autoComplete='off'
                                        name="name"
                                        onChange={(e) => {
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.name.length > 0 &&
                                    <span className="error">{errors.name}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Email <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="email"
                                        className="input"
                                        name="emailPhone"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.emailPhone.length > 0 &&
                                    <span className="error">{errors.emailPhone}</span>}
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-6">
                                    <label className="label">Phone number <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="phone"
                                        name="contactNumber"
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.contactNumber.length > 0 &&
                                    <span className="error">{errors.contactNumber}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Company Type <span style={{color: "red"}}> *</span>
                                    </label>
                                    <div style={style.select}>
                                        <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                            <Select
                                                showSearch={false}
                                                getPopupContainer={node => node.parentNode}
                                                onChange={e => {
                                                    this.setState({isBlocking: true});
                                                    this.props.handleChangeCompanyInput("companyTypeId", e)
                                                }}
                                                dropdownStyle={{height: "205px"}}
                                            >
                                                {
                                                    companyTypeList && companyTypeList.map(companyType => (
                                                        <Option
                                                            value={companyType.companyTypeId}>{companyType.companyTypeName}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!(this.state.address.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '15px'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showInputField();
                                     }}>
                                    <img src="/assets/images/add-button-inside-circle.svg"
                                         alt="Add Button"
                                         className="addButtonIcon"/>
                                    <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Address</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    <OpenAddressFields
                                        fieldArray={this.state.address}
                                        filterAddressData={filterAddressData}
                                        handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                                    />
                                    <div style={{
                                        display: 'block',
                                        marginTop: '1rem'
                                    }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  this.setState({isBlocking: false});
                                                  showInputField();

                                              }}>
                                            {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                                            {/*     alt="Add Button"*/}
                                            {/*     className="addButtonIcon"/>*/}
                                            <span style={{ fontSize: '16px', color: "#81b447"}}>
                                                <span style={{fontSize: "18px"}}>+</span> Add More</span>
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                !(this.state.contacts.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '15px'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showInputForField();
                                     }}>
                                    <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                         className="addButtonIcon"/>
                                    <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Contact</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    {openFieldForContact()}
                                    <div style={{
                                        textAlign: "start",
                                        marginTop: "10px",
                                        // marginLeft: "12px",
                                    }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  this.setState({isBlocking: false});
                                                  showInputForField();

                                              }}>
                                             {/*<img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"*/}
                                             {/*     className="addButtonIcon"/>*/}
                                            <span style={{ fontSize: '16px',
                                                color: "#81b447"}}>+ Add More</span>
                                        </span>
                                    </div>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 10px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    addCompanyForm();
                                    // this.props.addCompany(companyData);
                                }}
                                loading={loading}
                                value={companyValue}
                                buttonName="Add"
                           />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*edit company modal*/}
                <Dialog
                    open={this.props.openModalToEditCompany}
                    onClose={this.props.handleChangeCloseModalToEditCompany}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={()=>{
                                this.setState({
                                errors: {
                                    name: '',
                                    contactNumber: '',
                                    emailPhone:'',
                                }
                            })
                                this.props.handleChangeCloseModalToEditCompany()
                            }}>
                            Update Company
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentWidth850"
                                       style={{maxHeight: "575px", paddingTop: "0px", borderBottom: "none"}}>
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Name <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="companyName"
                                        name="name"
                                        value={companyData && companyData.companyName}
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.name.length > 0 &&
                                    <span className="error">{errors.name}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Email <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="email"
                                        name="emailPhone"
                                        value={companyData && companyData.email}
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.emailPhone.length > 0 &&
                                    <span className="error">{errors.emailPhone}</span>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Phone number <span style={{color: "red"}}>*</span></label>
                                    <input
                                        type='text'
                                        id="phone"
                                        value={companyData && companyData.phone}
                                        className="input"
                                        autoComplete='off'
                                        name="contactNumber"
                                        onChange={(e) => {
                                            Helpers.handleValidationOnChange(e, errors);
                                            this.props.handleChangeCompanyInput(e.target.id, e.target.value);
                                        }}
                                    />
                                    {errors.contactNumber.length > 0 &&
                                    <span className="error">{errors.contactNumber}</span>}
                                </div>
                                <div className="col-6">
                                    <label className="label">Company Type
                                        <span style={{color: "red"}}> *</span></label>
                                    <div style={style.select}>
                                        <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                            <Select
                                                showSearch={false}
                                                getPopupContainer={node => node.parentNode}
                                                value={companyData && companyData.companyTypeId}
                                                onChange={e => {
                                                    this.setState({isBlocking: true});
                                                    this.props.handleChangeCompanyInput("companyTypeId", e)
                                                }}
                                                dropdownStyle={{height: "205px"}}
                                            >
                                                {
                                                    companyTypeList && companyTypeList.map(companyType => (
                                                        <Option
                                                            value={companyType.companyTypeId}>{companyType.companyTypeName}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                !(this.state.address.length > 0) ?
                                    <div style={{cursor: 'pointer', marginTop: '15px'}}
                                         onClick={e => {
                                             e.preventDefault();
                                             this.setState({
                                                 isBlocking: false
                                             });
                                             showInputField();
                                         }}>
                                        <img src="/assets/images/add-button-inside-circle.svg"
                                             alt="Add Button"
                                             className="addButtonIcon"/>
                                        <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Address</span>
                                    </div>
                                    :
                                    <div style={{marginTop: '2rem'}}>
                                        <OpenAddressFields
                                            fieldArray={this.state.address}
                                            isEdit={true}
                                            filterEditAddressData={filterEditAddressData}
                                            handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                                        />
                                        <div style={{
                                            display: 'block',
                                            marginTop: '1rem'
                                        }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  this.setState({isBlocking: false});
                                                  showInputField();

                                              }}>
                                            {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                                            {/*     alt="Add Button"*/}
                                            {/*     className="addButtonIcon"/>*/}
                                            <span style={{ fontSize: '16px',
                                                color: "#81b447"}}>+ Add More</span>
                                        </span>
                                        </div>
                                    </div>
                            }
                            {!(this.state.editContacts.length > 0) ?
                                <div style={{cursor: 'pointer', marginTop: '15px'}}
                                     onClick={e => {
                                         e.preventDefault();
                                         this.setState({
                                             isBlocking: false
                                         });
                                         showEditInputForField();
                                     }}>
                                    <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                         className="addButtonIcon"/>
                                    <span style={{fontSize: '14px', marginLeft: "4px"}}>Add Contact</span>
                                </div>
                                :
                                <div style={{marginTop: '2rem'}}>
                                    {openEditFieldForContact()}
                                    <div style={{
                                        textAlign: "start",
                                        marginTop: "10px",
                                        // marginLeft: "12px",
                                    }}>
                                        <span style={{cursor: 'pointer'}}
                                              onClick={e => {
                                                  e.preventDefault();
                                                  showEditInputForField();
                                              }}>
                                             <span style={{
                                                 fontSize: '16px',
                                                 color: "#81b447"
                                             }}> + Add More</span>
                                        </span>
                                    </div>
                                </div>}
                        </DialogContent>
                        <DialogActions style={{padding: "10px 20px 10px 0px"}}>
                            <ButtonComponent
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.editCompany(companyData);
                                }}
                                value={companyValue}
                                loading={loading}
                                buttonName="Update"
                           />
                        </DialogActions>
                    </div>
                </Dialog>
                {/*DELETE COMPANY ADDRESS*/}
                <Dialog
                    open={this.state.openDeleteModal}
                    onClose={handleCloseDeleteDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent className="dialogContentForDeleteModal">
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete address?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteDialogue}
                                            className="cancelButton"
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteDialogue();
                                            }}
                                            className="deleteOkButton"
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*delete company*/}
                <Dialog
                    open={this.props.openModalToDeleteCompany}
                    onClose={this.props.handleChangeCloseModalToDeleteCompany}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <DialogTitle id="draggable-dialog-title" style={{cursor: "move", paddingTop: "0px"}}>
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation
                        </h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px',
                        }}>
                        <DialogContentText>
                            Do you want to delete this company?

                            <span className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '1rem',
                                paddingTop: '1.6rem'
                            }}>
                                <span className={"col-6"}>
                                    <Button autoFocus onClick={this.props.handleChangeCloseModalToDeleteCompany}
                                            className="cancelButton">
                                        cancel
                                    </Button>
                                </span>

                                <span className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        this.props.deleteCompany(this.state.companyData.companyId);
                                        this.props.handleChangeCloseModalToDeleteCompany()
                                    }} className="addUserButton">
                                        ok
                                    </Button>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/*delete contact */}
                <Dialog
                    open={this.state.openDeleteContactDialogue}
                    onClose={handleCloseDeleteContactDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{background: "#ffffff", borderRadius: "18px"}}>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px'
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete contact?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteContactDialogue}
                                            className="cancelButton"
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteContactDialogue();
                                            }}
                                            className="addUserButton"
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>

                {/*delete company contact */}
                <Dialog
                    open={this.state.openDeleteContactDialogueInEdit}
                    onClose={handleCloseDeleteContactDialogueInEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{background: "#ffffff", borderRadius: "18px"}}>
                        <DialogTitle
                            style={{paddingTop: "0px"}}
                            // style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px'
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete contact?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteContactDialogueInEdit}
                                            className="cancelButton"
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteContactDialogueWhileUpdate();
                                                this.props.deleteCompanyContact(this.state.companyData.companyId,
                                                    this.state.contactData.contactId);
                                            }}
                                            className="addUserButton"
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>

                {/*set company account*/}
                <Dialog
                    open={this.props.openModalToSetCompanyAccount}
                    onClose={this.props.handleChangeCloseModalToSetCompanyAccount}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <DialogTitle id="draggable-dialog-title" style={{cursor: "move"}}>
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation</h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px'
                        }}>
                        <DialogContentText>
                            Do you want to set account for {this.state.companyData && this.state.companyData.companyName}?
                            <span className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '1rem',
                                paddingTop: '1.6rem'
                            }}>
                                <span className={"col-6"}>
                                    <Button autoFocus onClick={this.props.handleChangeCloseModalToSetCompanyAccount}
                                            className="cancelButton">
                                        cancel
                                    </Button>
                                </span>

                                <span className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        const accountDetails = {
                                            accountType: "EVERFUND_COMPANY",
                                            company:{
                                                companyId: this.state.companyData && this.state.companyData.companyId,
                                                email: this.state.companyData && this.state.companyData.email,
                                            }
                                        }
                                        this.props.setContactsAccount(accountDetails);
                                        this.props.handleChangeCloseModalToSetCompanyAccount()
                                    }} className="addUserButton">
                                        ok
                                    </Button>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={companyState.openSuccessContactSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="success"
                        message={companyState.successMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={companyState.openErrorContactSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={companyState.errorMsg}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
}

const style = {
    card: {
        background: "#ffffff",
        border: "1px solid rgb(224, 224, 224)",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginLeft: 0,
        marginBottom: "1rem",
    },
    inputFieldContact: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '10px',
        marginBottom: '15px',
        paddingLeft: '13px'
    },
    columnHeadingContact: {
        color: '#625F5F',
        fontSize: '16px',
        width: '100%',
        marginBottom: 0
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    select: {
        position: "relative",
        height: "40px",
        // marginTop: '10px',
    },
    expandMoreIconContact: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: '#C8C5C5',
        fontSize: '35px',
    },

}
export default CompanyScreen;
