import React, {Component} from "react";
import '../styles/styles.css'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import {HelmetComponent} from "../components/Helmet";

class CaseFilesDetailScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 10,
            openActionMenu: false,
        }
    }

    render() {
        const fileResponse = this.props && this.props.fileResponse;

        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString();
            return dateConverted;
        }
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };

        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        };
        return (
            <React.Fragment>
                <HelmetComponent title="Case File"/>
                <div>
                    <div className={"row"} style={style.card}>
                        <TableContainer>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader" style={{paddingLeft: "60px"}}>
                                            Files
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {fileResponse && fileResponse.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                ).map(files => (
                                    <TableBody key={files.actionId}>
                                        <TableRow>
                                            <TableCell className="first-tableBody" style={{width: "50%"}}>
                                                <a href={files.actionForms.map(
                                                    forms => forms.actionFormData[0].data
                                                )} target="_blank" className="actionsLink">
                                                    {files.actionForms.map(
                                                        forms => forms.fieldType === "PHOTO" ?
                                                            <img src={files.actionForms.map(
                                                                forms => forms.actionFormData[0].data
                                                            )} alt="pdf" style={{width: '30px', marginRight: '12px'}}/>
                                                            :
                                                            files.actionForms.map(
                                                                forms => forms.actionFormData[0].data &&
                                                                forms.actionFormData[0].data.split(".").pop() === "pdf" ?
                                                            <img src="/assets/images/pdf.svg" alt="pdf"
                                                                 style={{width: '30px', marginRight: '12px'}}/>:
                                                                    <img src="/assets/images/text_logo.png" alt="txt"
                                                                         style={{width: '30px', marginRight: '12px'}}/>
                                                            ))

                                                    }
                                                    {files.actionForms.map(
                                                        forms => forms.actionFormData[0].fileName ?
                                                            forms.actionFormData[0].fileName
                                                            : null
                                                    )}
                                                </a>
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                {getDate(files.createdAt)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))
                                }
                            </Table>
                            {fileResponse && fileResponse.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            fileResponse.length > 0
                                                ? fileResponse.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        </TableContainer>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const style = {
    // card: {
    //     background: "#ffffff",
    //     boxShadow:
    //         "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    //     marginRight: 0,
    //     marginBottom: "1rem",
    // },
    card: {
        background: "rgb(255, 255, 255)",
        border: "1px solid rgb(233, 233, 233)",
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "1rem",
    },
}

export default CaseFilesDetailScreen;