import { connect } from 'react-redux';
import { UserScreen } from "../../screens";
import {
    addUserFailure,
    addUserSuccess,
    apiRequestMadeForResetPassword,
    closeSnackBarForUser,
    deleteUserFailure,
    deleteUserSuccess,
    fetchUserFailure,
    fetchUserSuccess,
    setUserRoleTabToEdit,
    updateUserRoleFailure, updateUserRoleSuccess,
    userRequestMade
} from "../../actions";
import { UserAPI } from "../../api";
import {
    handleChangePassword,
    handleChangeUser,
    resetPasswordFailure,
    resetPasswordSuccess, setUserDataToDisplayEmpty, setUserRoleToEmpty
} from "../../actions/UserAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUserList: () => {
            dispatch(userRequestMade(true));
            UserAPI.fetchUserList((response, error) => {
                if (response) {
                    dispatch(fetchUserSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchUserFailure(error.msg))
                    } else {
                        dispatch(fetchUserFailure(error));
                    }
                }
            })
        },
        handleChangeAddUser: (id, value) => dispatch(handleChangeUser(id, value)),
        setUserRoleDataToEmpty: () => dispatch(setUserRoleToEmpty()),
        addNewUser: (userData, props) => {
            dispatch(userRequestMade(true));
            UserAPI.addNewUser(userData, (response, error) => {
                if (response) {
                    props.fetchUserList();
                    dispatch(addUserSuccess(true));
                } else {
                    if (error.msg) {
                        dispatch(addUserFailure(error.msg));
                    } else {
                        dispatch(addUserFailure(error));
                    }
                }
            });
        },
        setAddUserSuccessToFalse : () => {dispatch(addUserSuccess(false))},
        setUpdateUserSuccessToFalse : () => {dispatch(updateUserRoleSuccess(false))},
        updateUserRole: (userId, userRole, props) => {
            dispatch(userRequestMade(true));
            var userData = {
                refId: userId,
                userRole: userRole
            };
            UserAPI.updateUserRole(userData, (response, error) => {
                if (response) {
                    props.fetchUserList();
                    dispatch(updateUserRoleSuccess(true));
                } else {
                    if (error.msg) {
                        dispatch(updateUserRoleFailure(error.msg));
                    } else {
                        dispatch(updateUserRoleFailure(error));
                    }
                }
            });
        },
        deleteUser: (userId, props) => {
            dispatch(userRequestMade(true));
            UserAPI.deleteUser(userId, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(deleteUserFailure(error.msg))
                    } else {
                        dispatch(deleteUserFailure(error));
                    }
                } else {
                    props.fetchUserList();
                    dispatch(deleteUserSuccess(userId));
                }
            })
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForUser(true)),
        handleChangePassword: (id, value) => dispatch(handleChangePassword(id, value)),
        resetPasswordBySuperAdmin: (emailPhone) => {
            dispatch(apiRequestMadeForResetPassword(true));
            UserAPI.resetPasswordBySuperAdmin(emailPhone, (response, error) => {
                if (response) {
                    dispatch(resetPasswordSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(resetPasswordFailure(error.msg));
                    } else {
                        dispatch(resetPasswordFailure(error));
                    }
                }
            });
        },
        setUserRoleTabToEdit: (dataToDisplay) => {
            dispatch(setUserRoleTabToEdit(dataToDisplay))
        },

        setUserDataToDisplayEmpty: (data) => {
            dispatch(setUserDataToDisplayEmpty(data))
        },
    };
};

export const UserContainer = connect(mapStateToProps, mapDispatchToProps)(UserScreen);