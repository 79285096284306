import { connect } from 'react-redux';
import { ActionScreen } from "../../screens";
import {
    actionRequestMade,
    closeSnackBarForAction,
    deleteActionFailure,
    deleteActionSuccess,
    fetchActionListFailure,
    fetchActionListSuccess
} from "../../actions";
import { ActionAPI } from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchActionList: () => {
            dispatch(actionRequestMade(true));
            ActionAPI.fetchActionList((response, error) => {
                if (response) {
                    dispatch(fetchActionListSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchActionListFailure(error.msg))
                    } else {
                        dispatch(fetchActionListFailure(error));
                    }
                }
            })
        },
        deleteAction: (actionId, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.deleteAction(actionId, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(deleteActionFailure(error.msg))
                    } else {
                        dispatch(deleteActionFailure(error));
                    }
                } else {
                    props.fetchActionList();
                    dispatch(deleteActionSuccess(actionId));
                }
            })
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForAction(true)),
    };
};


export const ActionContainer = connect(mapStateToProps, mapDispatchToProps)(ActionScreen);