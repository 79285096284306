import { ACTION_TYPE_FOR_ACTION_PAGE } from "../constants/Type";

export const actionRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.ACTION_REQUEST_MADE,
        bool
    };
};

export const fetchActionListSuccess = action => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.ACTION_FETCH_SUCCESS,
        action
    };
};

export const fetchActionListFailure = error => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.ACTION_FETCH_FAILURE,
        error
    };
};

export const addActionSuccess = bool => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.ADD_ACTION_SUCCESS,
        bool
    };
};

export const addActionFailure = error => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.ADD_ACTION_FAILURE,
        error
    };
};

export const handleChangeAction = (id, value) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.HANDLE_CHANGE_ACTION,
        id,
        value
    };
};

export const deleteActionSuccess = (actionId) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.DELETE_ACTION_SUCCESS,
        actionId
    }
};

export const deleteActionFailure = (error) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.DELETE_ACTION_FAILURE,
        error
    }
};

export const setActionToEdit = (actionData) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.SET_ACTION_TO_EDIT,
        actionData
    }
};

export const closeSnackBarForAction = (bool) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.CLOSE_SNACK_BAR_ACTION_PAGE,
        bool
    };
};

export const searchExistingOptionValueSuccess = optionValues => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.SEARCH_EXISTING_OPTION_SUCCESS,
        optionValues,
    }
};

export const searchExistingOptionValueFailure = (error) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.DELETE_ACTION_FAILURE,
        error
    }
};

export const handleChangeFirstConditionalForm = (conditionalForm) => {
    return{
        type: ACTION_TYPE_FOR_ACTION_PAGE.HANDLE_CHANGE_FIRST_CONDITIONAL_FORM,
        conditionalForm,
    }
}

export const getConditionalFormSuccess = (conditionalForm, id, optionId, actionId, step, prevOptionId, prevActionId, prevRefId, prevOptionName) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.GET_CONDITIONAL_FORM_BY_REFERENCE_ID_SUCCESS,
        conditionalForm,
        id,
        optionId, actionId, step,prevOptionId, prevActionId, prevRefId, prevOptionName,
    }
};

export const getConditionalFormFailure = (error) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.GET_CONDITIONAL_FORM_BY_REFERENCE_ID_FAILURE,
        error
    }
};

export const setConditionalActionFormSuccess = response => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_SUCCESS,
        response,
    }
};

export const setConditionalActionFormFailure = (error) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_FAILURE,
        error
    }
};

export const setCheckedValue= (checkedValue) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.SET_CHECKED_VALUE,
        checkedValue
    }
};
export const setPreviousValue = (previousOptionId, previousActionId ,previousRefId, previousOptionName) => {
    return {
        type: ACTION_TYPE_FOR_ACTION_PAGE.SET_PREVIOUS_ID_VALUE,
        previousOptionId, previousActionId ,previousRefId, previousOptionName
    }
};

export const setEditedConditionalForm = (currentActionId, currentOptionId,conditionalActionForm) => {
    return{
        type: ACTION_TYPE_FOR_ACTION_PAGE.SET_EDITED_CONDITIONAL_FORM,
        currentActionId,
        currentOptionId,
        conditionalActionForm
    }
}