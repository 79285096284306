import React, { Component } from "react";
import { Popover } from "antd";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";


class NotesPopover extends Component {
  render() {
    return (
      <Popover
        getPopupContainer={(node) => node.parentNode}
        placement="right"
        trigger="hover"
        content={this.props.content}
      >
        {
          this.props.isRemark ?
          <img src="/assets/images/remark.png" alt="remark" style={{width: '28px'}}/> :
          <SpeakerNotesIcon style={{ color: "#7e7e7e", marginTop: '5px'}} />
        }        
      </Popover>
    );
  }
}

export default NotesPopover;
