import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { DatePicker, Select } from "antd";
import { Prompt } from "react-router";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import { LocalDb } from "../api";
import Typography from "@material-ui/core/Typography";

const { Option } = Select;

class ScheduleTransactionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      isBlocking: false,
      clicked: false,
    };
  }

  componentDidMount() {
    this.props.fetchVendor();
    this.props.fetchScheduleTransactionList();
    this.props.handleChangeTransactionType("");
    this.props.handleChangeTransactionMedium("");
    this.props.handleChangeBankDetailName("");
    this.props.handleChangeScheduleTransaction("scheduleType", "");
    this.props.handleChangeScheduleTransaction("amount", "");
    this.props.handleChangeScheduleTransaction("vendor", "");
    this.props.handleChangeClientName("");
  }
  componentWillUnmount() {
    this.props.clearFileData();
  }

  render() {
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;

    const userClientId = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.client
        ? userInfoFromDB.loginResponse.loginUser.client.clientId
        : null
      : null;
    const vendorList = this.props.vendorState.vendorAccount;
    const scheduleTransactionState =
      this.props && this.props.scheduleTransactionState;
    const scheduleTransactionList =
      scheduleTransactionState && scheduleTransactionState.clientList;
    const transMedium =
      scheduleTransactionState && scheduleTransactionState.transMediumType;
    const transType =
      scheduleTransactionState && scheduleTransactionState.transType;
    const clientId =
      scheduleTransactionState && scheduleTransactionState.clientId;
    const fileUrl =
      scheduleTransactionState && scheduleTransactionState.fileUrl;
    const clientRes =
      scheduleTransactionState && scheduleTransactionState.clientRes;
    const bankDetailId =
      scheduleTransactionState && scheduleTransactionState.bankDetailId;
    const otherScheduleTransaction =
      scheduleTransactionState &&
      scheduleTransactionState.otherScheduleTransaction;

    const vendorData =
      vendorList &&
      vendorList.filter((vendor) => vendor.vendorType === "EVERFUND_VENDOR");
    const vendorDataForPayment =
      vendorList &&
      vendorList.filter((vendor) => vendor.vendorType !== "EVERFUND_VENDOR");

    const value =
      this.props.scheduleTransactionState.clientId !== "" &&
      this.props.scheduleTransactionState.otherScheduleTransaction &&
      this.props.scheduleTransactionState.otherScheduleTransaction.vendorId !==
        "" &&
      this.props.scheduleTransactionState.otherScheduleTransaction &&
      this.props.scheduleTransactionState.otherScheduleTransaction.amount !==
        "" &&
      this.props.scheduleTransactionState.otherScheduleTransaction &&
      this.props.scheduleTransactionState.otherScheduleTransaction.note !==
        "" &&
      this.props.scheduleTransactionState.scheduleDetail &&
      this.props.scheduleTransactionState.scheduleDetail.scheduledDate !== "" &&
      this.props.scheduleTransactionState.scheduleDetail &&
      this.props.scheduleTransactionState.scheduleDetail.endDate !== "" &&
      this.props.scheduleTransactionState.scheduleDetail &&
      this.props.scheduleTransactionState.scheduleDetail.scheduleType !== "" &&
      this.props.scheduleTransactionState.otherScheduleTransaction &&
      this.props.scheduleTransactionState.otherScheduleTransaction
        .transactionType !== "" &&
      this.props.scheduleTransactionState.otherScheduleTransaction &&
      this.props.scheduleTransactionState.otherScheduleTransaction
        .transactionMedium !== "";

    const onChangeDocumentFileHandler = (eventFileHandler) => {
      let file = eventFileHandler.target.files[0];
      if (
        (file && file.type === "application/pdf") ||
        (file && file.type === "text/plain")
      ) {
        let type = file.type;
        let name = file.name;
        let singleFile = {
          document: file,
          type: type,
          name: name,
        };
        {
          this.setState(
            {
              isBlocking: true,
            },
            () => {
              this.props.uploadDocumentFile(singleFile, this.props);
            }
          );
        }
      } else {
        this.setState({
          openSnackBar: true,
        });
      }
    };

    const handleChangeDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        this.setState({ isBlocking: true });
        this.props.handleChangeAddScheduleDetails(
          "scheduledDate",
          new Date(valueOfInput1).getTime()
        );
      }
    };
    const handleChangeEndDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        this.setState({ isBlocking: true });
        this.props.handleChangeAddScheduleDetails(
          "endDate",
          new Date(valueOfInput1).getTime()
        );
      }
    };
    const dataForClient = {
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
    };
    const dataForClientCollection = {
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
      bankDetailId: bankDetailId,
    };
    const dataForClientCheque = {
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
      document: fileUrl,
    };
    const dataForClientChequeAndCollection = {
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
      document: fileUrl,
      bankDetailId: bankDetailId,
    };

    const data = {
      client: {
        clientId:
          this.props.scheduleTransactionState &&
          this.props.scheduleTransactionState.clientId,
      },
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
    };
    const collectionData = {
      client: {
        clientId:
          this.props.scheduleTransactionState &&
          this.props.scheduleTransactionState.clientId,
      },
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
      bankDetailId: bankDetailId,
    };
    const chequeData = {
      client: {
        clientId:
          this.props.scheduleTransactionState &&
          this.props.scheduleTransactionState.clientId,
      },
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
      document: fileUrl,
    };
    const chequeAndCollectionData = {
      client: {
        clientId:
          this.props.scheduleTransactionState &&
          this.props.scheduleTransactionState.clientId,
      },
      vendorAccount: {
        vendorId:
          this.props.scheduleTransactionState.otherScheduleTransaction &&
          this.props.scheduleTransactionState.otherScheduleTransaction.vendorId,
      },
      amount:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        parseFloat(
          this.props.scheduleTransactionState.otherScheduleTransaction.amount
        ).toFixed(2) * 100,
      note:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction.note,
      scheduleDetail: {
        scheduledDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduledDate,
        endDate:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.endDate,
        scheduleType:
          this.props.scheduleTransactionState.scheduleDetail &&
          this.props.scheduleTransactionState.scheduleDetail.scheduleType,
      },
      transactionType:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionType,
      transactionMedium:
        this.props.scheduleTransactionState.otherScheduleTransaction &&
        this.props.scheduleTransactionState.otherScheduleTransaction
          .transactionMedium,
      bankDetailId: bankDetailId,
      document: fileUrl,
    };
    return (
      <React.Fragment>
        {this.props.scheduleTransactionState.loading ? (
          <div
            className={
              this.props.scheduleTransactionState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page?"
        />
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/schedule" className="links">
            Schedule Transaction
          </Link>
          <Typography color="inherit" className="link">
            Schedule New Transaction
          </Typography>
        </Breadcrumbs>
        <div style={style.container}>
          <div className="card" style={style.card}>
            <div className="form">
              <div className="card-header" style={style.cardHeader}>
                {userType === "CLIENT_USER" ? (
                  <h5 style={style.clientText}> Request Transaction </h5>
                ) : (
                  <h5 style={style.clientText}> Schedule New Transaction </h5>
                )}
              </div>
              <div className="card-body" style={style.cardBody}>
                <div className="row mt-3">
                  {userType === "CLIENT_USER" ? (
                    <div className="col-lg-4 select-input h40">
                      <label htmlFor="clientName" style={style.columnHeading}>
                        Client Name
                      </label>
                      <input
                        type="name"
                        id="amount"
                        disabled
                        value={
                          userInfoFromDB.loginResponse.loginUser.client
                            ? userInfoFromDB.loginResponse.loginUser.client
                                .firstName
                            : ""
                        }
                        style={style.inputField}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-4 select-input h40">
                      <label htmlFor="clientName" style={style.columnHeading}>
                        Client Name
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <Select
                        showSearch
                        style={{ width: 200, marginTop: "12px" }}
                        onSearch={(value) => {
                          this.props.searchClientList(value);
                        }}
                        onInputKeyDown={() => {
                          this.setState({
                            showDropdown: true,
                          });
                        }}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.fetchSingleClient(e);
                          this.props.handleChangeClientName(e);
                        }}
                        onSelect={() => {
                          this.setState({
                            showDropdown: false,
                          });
                        }}
                        onBlur={() => {
                          this.setState({
                            showDropdown: false,
                          });
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        defaultOpen={false}
                        dropdownStyle={
                          this.state.showDropdown
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {scheduleTransactionList &&
                          scheduleTransactionList.map((res) =>
                            res.isEnableTransaction && !res.isHold ? (
                              <>
                                <Option key={res.lastName} value={res.clientId}>
                                  {res.lastName}
                                </Option>
                                <Option key={res.ssn} value={res.clientId}>
                                  {res.ssn}
                                </Option>
                                <Option key={res.clientId} value={res.clientId}>
                                  {res.firstName}
                                </Option>
                              </>
                            ) : null
                          )}
                      </Select>
                    </div>
                  )}
                  <div className="col-lg-4">
                    <label htmlFor="amount" style={style.columnHeading}>
                      Amount (Dollar)
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="number"
                      id="amount"
                      autocomplete="off"
                      style={style.inputField}
                      onChange={(e) => {
                        this.props.handleChangeScheduleTransaction(
                          e.target.id,
                          e.target.value
                        );
                        this.setState({ isBlocking: true });
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="scheduleType" style={style.columnHeading}>
                      Schedule Type
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ marginTop: "12px", width: "100%" }}
                    >
                      <Select
                        onChange={(e) => {
                          this.props.handleChangeAddScheduleDetails(
                            "scheduleType",
                            e
                          );
                          this.setState({ isBlocking: true });
                        }}
                      >
                        <Option value="ONE_TIME">One Time</Option>
                        <Option value="DAILY">Daily</Option>
                        <Option value="WEEKLY">Weekly</Option>
                        <Option value="MONTHLY"> Monthly</Option>
                        <Option value="QUARTERLY">Quarterly</Option>
                        <Option value="YEARLY">Yearly</Option>
                        <Option value="NTH_DAY">nth Day</Option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <label htmlFor="startDate" style={style.columnHeading}>
                      Schedule Start Date
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="select-dob" style={{ marginTop: "6px" }}>
                      <DatePicker
                        format="MM-DD-YYYY"
                        onChange={handleChangeDate}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="endDate" style={style.columnHeading}>
                      Schedule End Date{" "}
                    </label>
                    <div className="select-dob" style={{ marginTop: "6px" }}>
                      <DatePicker
                        format="MM-DD-YYYY"
                        onChange={handleChangeEndDate}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="transMedium" style={style.columnHeading}>
                      Transaction Medium
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ marginTop: "12px", width: "100%" }}
                    >
                      <Select
                        id="transactionMedium"
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChangeScheduleTransaction(
                            "transactionMedium",
                            e
                          );
                          this.props.handleChangeTransactionMedium(e);
                        }}
                      >
                        <Option value="CHECK">Check</Option>
                        <Option value="CASH">Cash</Option>
                        <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                        <Option value="ACH">ACH</Option>
                        <Option value="CARD">Card</Option>
                        <Option value="OTHER_MEDIUM">Other medium</Option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <label htmlFor="transType" style={style.columnHeading}>
                      Transaction Type
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ marginTop: "12px", width: "100%" }}
                    >
                      <Select
                        onClick={() => {
                          if (userType === "CLIENT_USER") {
                            this.props.fetchSingleClient(userClientId);
                          } else {
                            this.props.fetchSingleClient(clientId);
                          }
                        }}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChangeScheduleTransaction(
                            "transactionType",
                            e
                          );
                          this.props.handleChangeTransactionType(e);
                        }}
                      >
                        <Option value="PAYMENT">Payment</Option>
                        <Option value="COLLECTION">Collection</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="vendor" style={style.columnHeading}>
                      Vendor
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ marginTop: "12px", width: "100%" }}
                    >
                      <Select
                        onChange={(e) => {
                          this.props.handleChangeScheduleTransaction(
                            "vendorId",
                            e
                          );
                          this.setState({ isBlocking: true });
                        }}
                      >
                        {transType === "COLLECTION" ? (
                          <Option value={vendorData[0].vendorId}>
                            {vendorData[0].vendorName}
                          </Option>
                        ) : (
                          vendorDataForPayment &&
                          vendorDataForPayment.map((vendor) => (
                            <Option value={vendor.vendorId}>
                              {vendor.vendorName}
                            </Option>
                          ))
                        )}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="note" style={style.columnHeading}>
                      Note
                    </label>
                    <input
                      style={style.inputTextField}
                      id="note"
                      type="text"
                      autocomplete="off"
                      onChange={(e) => {
                        this.props.handleChangeScheduleTransaction(
                          e.target.id,
                          e.target.value
                        );
                        this.setState({ isBlocking: true });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={"row mt-5"}
                  hidden={
                    !(transMedium === "CHECK" || transType === "COLLECTION")
                  }
                >
                  <div
                    className="col-lg-4"
                    hidden={
                      !(
                        (transMedium === "CHECK" &&
                          transType === "COLLECTION") ||
                        (transType === "COLLECTION" && transMedium !== "CHECK")
                      )
                    }
                  >
                    <label htmlFor="note" style={style.columnHeading}>
                      Bank details <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="select-input selects h40">
                      <Select
                        showSearch={false}
                        getPopupContainer={(node) => node.parentNode}
                        onChange={(e) => {
                          this.setState({ isBlocking: true });
                          this.props.handleChangeBankDetailName(e);
                        }}
                      >
                        {clientRes &&
                          clientRes.bankDetail &&
                          clientRes.bankDetail.map((detail) => (
                            <Option value={detail.bankDetailId}>
                              {detail.accountName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4" hidden={transMedium !== "CHECK"}>
                    <label htmlFor="note" style={style.columnHeading}>
                      Print Document <span style={{ color: "red" }}> *</span>
                    </label>
                    {fileUrl ? (
                      <div>
                        {fileUrl.split(".").pop() === "pdf" ? (
                          <img
                            src="/assets/images/pdf.svg"
                            alt="pdf"
                            style={{
                              width: "40px",
                              marginRight: "12px",
                              marginTop: "12px",
                            }}
                          />
                        ) : (
                          <img
                            src="/assets/images/text_logo.png"
                            alt="pdf"
                            style={{
                              width: "40px",
                              marginRight: "12px",
                              marginTop: "12px",
                            }}
                          />
                        )}
                      </div>
                    ) : null}
                    <label
                      style={{
                        width: "115px",
                        marginTop: "0.5rem",
                        marginBottom: "1.5rem",
                        background: "rgba(204,204,254,0.32)",
                        border: "1px solid #7b7b7b",
                        textAlign: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({ isBlocking: true });
                          onChangeDocumentFileHandler(e);
                        }}
                        className="hidden"
                        style={{ display: "none" }}
                      />
                      Select file
                    </label>
                  </div>
                </div>
                {userType === "CLIENT_USER" ? (
                  <div className="row">
                    <div className="col-4" />
                    <div className="col-4" />
                    <div className="col-4">
                      <button
                        className="btn btn-primary nextButton"
                        // style={style.nextButton}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ isBlocking: false });
                          if (
                            transType === "COLLECTION" &&
                            transMedium === "CHECK"
                          ) {
                            this.props.requestScheduleTransaction(
                              dataForClientChequeAndCollection,
                              this.props
                            );
                          } else if (
                            transType === "COLLECTION" &&
                            transMedium !== "CHECK"
                          ) {
                            this.props.requestScheduleTransaction(
                              dataForClientCollection,
                              this.props
                            );
                          } else if (
                            transType !== "COLLECTION" &&
                            transMedium === "CHECK"
                          ) {
                            this.props.requestScheduleTransaction(
                              dataForClientCheque,
                              this.props
                            );
                          } else {
                            this.props.requestScheduleTransaction(
                              dataForClient,
                              this.props
                            );
                          }
                        }}
                      >
                        Request
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-4" />
                    <div className="col-4" />
                    <div className="col-4">
                      <button
                        disabled={!value}
                        className={`!value ? addUserDisableButton ? nextButton`}
                        // className="btn btn-primary"
                        style={
                          !value ? style.addUserDisableButton : style.nextButton
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ isBlocking: false });
                          if (
                            transType === "COLLECTION" &&
                            transMedium === "CHECK"
                          ) {
                            if (!this.state.clicked) {
                              this.setState({
                                clicked: true,
                              });
                              this.props.addNewScheduleTransaction(
                                chequeAndCollectionData,
                                this.props
                              );
                              setTimeout(() => {
                                this.setState({
                                  clicked: false,
                                });
                              }, 3000);
                            }
                          } else if (
                            transType === "COLLECTION" &&
                            transMedium !== "CHECK"
                          ) {
                            if (!this.state.clicked) {
                              this.setState({
                                clicked: true,
                              });
                              this.props.addNewScheduleTransaction(
                                collectionData,
                                this.props
                              );
                              setTimeout(() => {
                                this.setState({
                                  clicked: false,
                                });
                              }, 3000);
                            }
                          } else if (
                            transType !== "COLLECTION" &&
                            transMedium === "CHECK"
                          ) {
                            if (!this.state.clicked) {
                              this.setState({
                                clicked: true,
                              });
                              this.props.addNewScheduleTransaction(
                                chequeData,
                                this.props
                              );
                              setTimeout(() => {
                                this.setState({
                                  clicked: false,
                                });
                              }, 3000);
                            }
                          } else {
                            if (!this.state.clicked) {
                              this.setState({
                                clicked: true,
                              });
                              this.props.addNewScheduleTransaction(
                                data,
                                this.props
                              );
                              setTimeout(() => {
                                this.setState({
                                  clicked: false,
                                });
                              }, 3000);
                            }
                          }
                        }}
                      >
                        Schedule
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            this.props.scheduleTransactionState.openScheduleTransactionSnackBar
          }
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.scheduleTransactionState.errorMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "2%",
    marginTop: "75px",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: "240px",
    marginRight: 0,
    marginBottom: "1rem",
    width: "70%",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    paddingRight: "8px",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "4%",
    height: "64px",
  },
  cardBody: {
    paddingLeft: "4%",
    paddingRight: "4%",
  },
  clientText: {
    color: "#cc6600",
    fontSize: "20px",
    fontWeight: "bolder",
    paddingTop: "15px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  inputTextField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  dropdownInput: {
    paddingLeft: "8px",
    height: "40px",
    color: "#606060",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "100%",
    fontSize: "15px",
    marginBottom: "1.5rem",
  },
  selectHead: {
    position: "relative",
  },
  selectBarHead: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    marginTop: "5px",
    height: "40px",
    fontSize: "15px",
  },
  select: {
    position: "relative",
    height: "40px",
    marginTop: "10px",
  },
  icon: {
    position: "absolute",
    top: "34px",
    right: "25px",
    color: "#C8C5C5",
    fontSize: "32px",
  },
  nextButton: {
    backgroundColor: "#8BC83F",
    float: "right",
    width: "40%",
    height: "40px",
    border: "0",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "bold",
    marginTop: "16px",
    marginRight: 0,
    marginBottom: "5px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    float: "right",
    width: "40%",
    height: "40px",
    border: "0",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "bold",
    marginTop: "16px",
    marginRight: 0,
    marginBottom: "5px",
  },
};

export default ScheduleTransactionForm;
