import { connect } from "react-redux";
import {
    addCaseTemplateFailure, addClientSuccess,
    caseTemplateRequestMade,
    closeSnackBarForRegisterCase,
    fetchCaseTemplateFailure,
    fetchCaseTemplateSuccess,
    fetchUserFailure,
    fetchUserSuccess,
    handleChangeCaseInRegisterCase,
    registerCaseFailure,
    registerCaseRequestMade,
    registerCaseSuccess,
    userRequestMade
} from "../../../actions";
import Case from "../../../screens/registerCase/CaseScreen";
import { CaseTemplateAPI, RegisterCaseAPI, UserAPI } from "../../../api";
import {
    clearClientFormData,
    fetchClientCaseContactsFailure,
    fetchClientCaseContactsSuccess
} from "../../../actions/RegisterCaseAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleChangeCaseInRegisterCase: (id, value) => dispatch(handleChangeCaseInRegisterCase(id, value)),
        registerCase: (caseData,props) => {
            dispatch(registerCaseRequestMade(true));
            RegisterCaseAPI.registerCase(caseData, (response, error) => {
                if (response) {
                    dispatch(registerCaseSuccess(true));
                } else {
                    if (error.msg) {
                        dispatch(registerCaseFailure(error.msg));
                    } else {
                        dispatch(registerCaseFailure(error));
                    }
                }
            });
        },
        fetchCaseTemplateList: () => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.fetchCaseTemplateList((response, error) => {
                if (response) {
                    dispatch(fetchCaseTemplateSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCaseTemplateFailure(error.msg))
                    } else {
                        dispatch(fetchCaseTemplateFailure(error));
                    }
                }
            })
        },
        getCaseTemplateInfo: templateId => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.fetchSingleCaseTemplate(templateId, (response, error) => {
                if (response) {
                    // dispatch(setCaseTemplateToEdit(response));
                } else {
                    if (error.msg) {
                        dispatch(addCaseTemplateFailure(error.msg));
                    } else {
                        dispatch(addCaseTemplateFailure(error));
                    }
                }
            })
        },

        fetchUserList: () => {
            dispatch(userRequestMade(true));
            UserAPI.fetchUserList((response, error) => {
                if (response) {
                    dispatch(fetchUserSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchUserFailure(error.msg))
                    } else {
                        dispatch(fetchUserFailure(error));
                    }
                }
            })
        },
        setClientContent: (response) => {
            dispatch(addClientSuccess(response));
        },
        setCaseAddSucessFully: (bool) => dispatch(registerCaseSuccess(bool)),
        handleRegisterCaseSnackBarClose: () => dispatch(closeSnackBarForRegisterCase(true)),


        fetchClientCaseContacts: (id, props) => {
            dispatch(userRequestMade(true));
            RegisterCaseAPI.fetchClientCaseContact(id,(response, error) => {
                if (response) {
                    dispatch(fetchClientCaseContactsSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientCaseContactsFailure(error.msg))
                    } else {
                        dispatch(fetchClientCaseContactsFailure(error));
                    }
                }
            })
        },



    };
};
export const CaseContainer = connect(mapStateToProps, mapDispatchToProps)(Case);