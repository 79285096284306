import { ACTION_TYPE_ACTIVITY_LOG } from "../constants/Type";

export const activityLogRequestMade = bool =>{
    return{
        type: ACTION_TYPE_ACTIVITY_LOG.ACTIVITY_LOG_REQUEST_MADE,
        bool
    }
}

export const fetchActivityLogSuccess = activities => {
    return {
        type: ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_SUCCESS,
        activities
    };
};

export const fetchActivityLogFailure = error => {
    return {
        type: ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_FAILURE,
        error
    };
};

export const fetchActivityLogByIdSuccess = activities => {
    return {
        type: ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_BY_ID_SUCCESS,
        activities
    };
};

export const fetchActivityLogByIdFailure = error => {
    return {
        type: ACTION_TYPE_ACTIVITY_LOG.FETCH_ACTIVITY_LOG_BY_ID_FAILURE,
        error
    };
};

export const handleCloseSnackBar = () => {
    return{
        type: ACTION_TYPE_ACTIVITY_LOG.HANDLE_CLOSE_LOG_SNACKBAR,
    }
}

export const fetchACHActivityLogSuccess = achActivityLogs => {
    return {
        type: ACTION_TYPE_ACTIVITY_LOG.FETCH_ACH_ACTIVITY_LOG_SUCCESS,
        achActivityLogs
    };
};

export const fetchACHActivityLogFailure = error => {
    return {
        type: ACTION_TYPE_ACTIVITY_LOG.FETCH_ACH_ACTIVITY_LOG_FAILURE,
        error
    };
};

