import { connect } from "react-redux";
import {
  clientRequestMade,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  handleChangeStatus,
} from "../../actions";
import {
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import {
  clearTransactionFilter,
  closeTransactionSnackBar,
  exportClientLedgerTransactionFailure,
  exportClientLedgerTransactionSuccess,
  fetchClientLedgerTransactionFailure,
  fetchClientLedgerTransactionSuccess,
  getTransConfigFailure,
  getTransConfigSuccess,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleChangeTransactionRemark,
  handleChangeTransactionStatus,
  handleChangeTransType,
  handleTransactionFilter,
  saveTransConfigFailure,
  saveTransConfigSuccess,
  setLedgerTxnStatusUpdateModal,
  transactionRequestMade,
  updateLedgerTxnFailure,
  updateLedgerTxnSuccess,
} from "../../actions/TransactionAction";
import { ClientAPI, TransactionAPI, SettingsAPI } from "../../api";
import IndividualClientLedgerTxnScreen from "../../screens/IndividualClientLedgerTxnScreen";

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
  return {
    // fetch client ledger transaction
    fetchCleintLedgerTransactionList: (
      clientId,
      from,
      to,
      status,
      type,
      term
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchClientLedgerTransactionList(
        clientId,
        from === null ? 0 : from,
        to === null ? 0 : to,
        status,
        type,
        term,
        (response, error) => {
          if (response) {
            dispatch(fetchClientLedgerTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientLedgerTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientLedgerTransactionFailure(error));
            }
          }
        }
      );
    },
    // export client ledger transaction
    exportClientLedgerTransactionList: (
      clientId,
      from,
      to,
      status,
      type,
      term,
      exportType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportCleintLedgerTransactionList(
        clientId,
        from === null ? 0 : from,
        to === null ? 0 : to,
        status,
        type,
        term,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportClientLedgerTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportClientLedgerTransactionFailure(error.msg));
            } else {
              dispatch(exportClientLedgerTransactionFailure(error));
            }
          }
        }
      );
    },

    // export client ledger by column filter
    exportClientLedgerTransactionListByColumnFilter: (
      clientId,
      desc,
      checkNo,
      user,
      vendor,
      medium,
      type,
      stat,
      transDate,
      amount,
      compareType,
      exportType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportCleintLedgerTransactionListByColumnFilter(
        clientId,
        desc,
        checkNo,
        user,
        vendor,
        medium,
        type,
        stat,
        transDate === null ? 0 : transDate,
        amount,
        compareType,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportClientLedgerTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportClientLedgerTransactionFailure(error.msg));
            } else {
              dispatch(exportClientLedgerTransactionFailure(error));
            }
          }
        }
      );
    },
    handleChangeDateFrom: (from) => dispatch(handleChangeDateFrom(from)),
    handleChangeDateTo: (to) => dispatch(handleChangeDateTo(to)),
    handleChangeStatus: (status) => dispatch(handleChangeStatus(status)),
    handleChangeTransType: (transType) =>
      dispatch(handleChangeTransType(transType)),
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    // LEDGER TRANSACTION UPDATE
    setOpenLedgerTxnUpdate: (bool) => {
      dispatch(setLedgerTxnStatusUpdateModal(bool));
    },
    updateLedgerTransaction: (transData, id, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateLedgerTransactionStatus(
        transData,
        (response, error) => {
          if (response) {
            dispatch(updateLedgerTxnSuccess(true));
            props.fetchCleintLedgerTransactionList(
              id,
              0,
              0,
              "UNKNOWN_TRANSACTION_STATUS",
              "" + "UNKNOWN_TRANSACTION_TYPE",
              ""
            );
          } else {
            if (error.msg) {
              dispatch(updateLedgerTxnFailure(error.msg));
            } else {
              dispatch(updateLedgerTxnFailure(error));
            }
          }
        }
      );
    },
    //description
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },
    handleChangeTransStatus: (status) =>
      dispatch(handleChangeTransactionStatus(status)),
    handleChangeRemark: (remark) =>
      dispatch(handleChangeTransactionRemark(remark)),

    // filter transaction
    handleTransactionFilter: (id, value) => {
      dispatch(handleTransactionFilter(id, value));
    },

    clearTransactionFilter: () => {
      dispatch(clearTransactionFilter());
    },

    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getTransConfigFailure(error.msg));
          } else {
            dispatch(getTransConfigFailure(error));
          }
        } else {
          dispatch(getTransConfigSuccess(response.configs));
        }
      });
    },
    saveInConfig: (data) => {
      TransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveTransConfigFailure(error.msg));
          } else {
            dispatch(saveTransConfigFailure(error));
          }
        } else {
          dispatch(saveTransConfigSuccess(response));
        }
      });
    },
    filterLedgerClientByAll: (
      id,
      description,
      checkNo,
      userName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      balance,
      compareType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.filterLedgerByClient(
        id,
        description,
        checkNo,
        userName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        balance,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(fetchClientLedgerTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientLedgerTransactionFailure(error));
            }
          } else {
            dispatch(fetchClientLedgerTransactionSuccess(response));
          }
        }
      );
    },

    handleTransactionSnackBarClose: () =>
      dispatch(closeTransactionSnackBar(true)),
  };
};

export const IndividualClientLedgerTxnContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualClientLedgerTxnScreen);
