import {connect} from "react-redux";
import {
    apiRequestMadeForResetPassword,
    changeFirstPasswordFailure,
    changeFirstPasswordSuccess,
    closeSnackBarResetPasswordScreen,
    handleChangeForResetPassword
} from "../../actions";
import {ProfileAPI} from "../../api";
import {ResetPasswordScreen} from "../../screens";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleChangeForResetFirstPassword: (id, value) => dispatch(handleChangeForResetPassword(id, value)),
        passwordReset: (accountData, props) => {
            dispatch(apiRequestMadeForResetPassword(true));
            ProfileAPI.changePassword(accountData, (response, error) => {
                if (response) {
                    dispatch(changeFirstPasswordSuccess(true));
                    props.history.replace('/dashboard');
                } else {
                    if (error.msg) {
                        dispatch(changeFirstPasswordFailure(error.msg));
                    } else {
                        dispatch(changeFirstPasswordFailure(error));
                    }
                }
            });
        },
        handleResetPasswordSnackBarClose: () => dispatch(closeSnackBarResetPasswordScreen(true)),
    };
};
export const ResetPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen);