import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import {Prompt} from "react-router";
import {Dropdown} from "semantic-ui-react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import {LocalDb} from "../api";
import '../index.css'
import moment from "moment";
import { Tabs} from "antd";
import CaseFilesDetailScreen from "./CaseFilesDetailScreen";
import CaseContactScreen from "./CaseContactScreen";
import Avatar from "@material-ui/core/Avatar";
import NoteComponent from "../components/NoteComponent";
import ActivityLogTable from "./ActivityLogTable";
import {HelmetComponent} from "../components/Helmet";
import {getFormattedDateValue} from "../utils/DateConverter";
const { TabPane } = Tabs;


class CaseUpdateFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDropdownForCaseUpdate: false,
            isBlocking: false,
            agent: [],
            action: [],
            allAgentsAssigned: [],
            agentAssign: [],
            caseData: [],
            dateChange: false,
            id: '',
            openModalToAddCompany: false,
            openModalToAddContact: false,
            openModalToAddExistingContact: false,
            openContactModal: false,
            caseId:props.location.pathname.substring(
                props.location.pathname.length,
                props.location.pathname.lastIndexOf("/") + 1
            ),
            page:0,
            rowsPerPage:10,
            caseTab: localStorage.getItem("caseTab"),
        };
        if (props.location.pathname.replace('/case/update/', '').length > 0) {
            this.props.getCaseInfo(props.location.pathname.replace('/case/update/', ''), this.props)
        }
    };

    componentDidMount() {
        this.props.fetchUserList();
        this.props.fetchFileListByCase(this.state.caseId, this.props);
        this.props.fetchNoteById(this.state.caseId);
        this.props.fetchActivityLogByCaseId(this.state.caseId, "", "");
    }

    componentWillUnmount() {
        this.props.setCaseData([]);
        this.props.setClienData([]);
        this.props.handleSnackBarClose();
        localStorage.setItem("caseTab","1");
    }

    render() {
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        }
        //handle change to open option to add
        const handleOpenContactModal= () => {
            this.setState({
                openContactModal: true,
            });
        };
        const handleCloseContactModal = () => {
            this.setState({
                openContactModal: false,
            });
        };

        //handle change to add individual contact
        const handleChangeOpenModalToAddContact = () => {
            this.setState({
                openModalToAddContact: true,
            },()=>{
                this.props.setCaseIndividualContactToEmpty();
            });
        };
        const handleChangeCloseModalToAddContact = () => {
            this.setState({
                openModalToAddContact: false,
            });
        };

        //handle change to add company contact
        const handleChangeOpenModalToAddCompany = () => {
            this.setState({
                openModalToAddCompany: true,
            },()=>{
                this.props.setCaseCompanyContactToEmpty();
            });
        };
        const handleChangeCloseModalToAddCompany = () => {
            this.props.setCaseCompanyContactToEmpty();
            this.setState({
                openModalToAddCompany: false,
            });
        };

        //handle change to add existing contact
        const handleChangeOpenModalToAddExistingContact = () => {
            this.setState({
                openModalToAddExistingContact: true,
            }, ()=>{
                this.props.handleChangeExistingContact("");
            });
        };
        const handleChangeCloseModalToAddExistingContact = () => {
            this.setState({
                openModalToAddExistingContact: false,
            });
        };
        let createObject;
        let createObjectForSchedulingDate;
            const fileResponse = this.props.state.caseState && this.props.state.caseState.fileResponse;

        const getDateField = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString();
            return moment(dateConverted).format("YYYY-MM-DD");
        };
        const userState = this.props.state.userState;
        const caseState = this.props.state.caseState;
        const client = caseState && caseState.client;
        const profilePicUrl = client && client.profilePicUrl;

        let userRoleAgent = {};
        if (userState.user && userState.user.length >= 1) {
            userRoleAgent = userState.user.filter(
                e => e.userRole === "AGENT"
            );
        }
        const userInfoFromDB = LocalDb.getSessions();
        const userType = userInfoFromDB ? userInfoFromDB.loginResponse.loginUser.user ?
            "EVERFUND_USER" : userInfoFromDB.loginResponse.loginUser.corporate? "CORPORATE_USER" :
                userInfoFromDB.loginResponse.loginUser.client ? "CLIENT_USER": "EVERFUND_CONTACT" : null;

        const apiCallEditPage = () => {
            if (this.props.state.caseState.caseData.caseAgents !== undefined) {
                var caseDataManipulated = {
                    caseId: this.props.state.caseState.caseData.caseId,
                    caseName: this.props.state.caseState.caseData.caseName,
                    caseAgents: this.props.state.caseState.caseData.caseAgents,
                };
                this.props.editCase(caseDataManipulated, this.props)
            } else {
                this.props.editCase(this.props.state.caseState.caseData, this.props)
            }
        };
        const scheduleDateByAgent = () => {
            const agent = {
                agentAssign: this.props.state.caseState.caseData.agentAssign
            };
            this.props.scheduleDateAgent(agent, this.props)
        };

        const changeAssignAgents = (workFlowId, value, workflowAgents) => {
            let a = createObject(workFlowId, value, workflowAgents);
            this.setState({allAgentsAssigned: a}, () => {
                this.props.handleChangeCaseAgent('caseAgents', this.state.allAgentsAssigned);
            })
        };
        createObject = (workFlowId, value) => {
            let allAgentsAssigned = this.state.allAgentsAssigned.filter(single => single.agentAssignId !== workFlowId)
            let data = {
                refId: workFlowId,
                userId: value,
                status: "AGENT_ACTIVE"
            };
            allAgentsAssigned.push(data);
            return allAgentsAssigned;
        };

        // ...date
        const changeAssignAgentDate = (date, id) => {
            let a = createObjectForSchedulingDate(date, id);
            this.setState({agentAssign: a}, () => {
                this.props.handleChangeDate('agentAssign', this.state.agentAssign);
            })
        };

        const fetchCaseContact = (id) => {
            this.props.fetchCaseContact(id) ;
        }
        createObjectForSchedulingDate = (date, id) => {
            let agentAssign = this.state.agentAssign.filter(single => single.agentAssignId !== id)
            let data = {
                agentAssignId: id,
                scheduledDate: date,
            };
            agentAssign.push(data);
            return agentAssign;
        };
        function callback(key) {
            localStorage.setItem("caseTab", key);
        }

        //for individual contact
        const handleChangeCaseContactInput = (id, value) => {
            this.props.handleChangeCaseContactInput(id, value);
        }
        const addIndividualCaseContact = (caseContactData) => {
            this.props.addCaseContact(caseContactData, this.props);
        }
        //for company
        const handleChangeCaseCompanyInput = (id, value) => {
            this.props.handleChangeCaseCompanyInput(id, value);
        }
        const addCaseCompany= (caseCompanyData) => {
            this.props.addCaseCompanyContact(caseCompanyData, this.props);
        }
        const searchContact = (term) => {
            this.props.searchIndividualContact(term, this.props);
        }
        //for existing contact
        const handleChangeExistingContact = (value) => {
            this.props.handleChangeExistingContact(value);
        }
        const searchExistingContact= (existingContacts) => {
            this.props.searchExistingContact(existingContacts, this.props);
        }
        const addExistingCaseContact= (existingCaseContactData) => {
            this.props.addExistingCaseContact(existingCaseContactData, this.props);
        }

        const handleChangeRelationshipName = (value) => {
            this.props.handleChangeRelationshipName(value);
        }
        //notes implementation

        const handleChangeNoteDescription = (id, value) => {
            this.props.handleChangeNoteDescription(id , value);
        }
        const addCaseNotes = (noteData) => {
            this.props.addCaseNotes(noteData, this.props);
        }
        const updateCaseNotes = (noteData) => {
            this.props.updateCaseNotes(noteData, this.props);
        }
        const deleteNotes = (noteId ) => {
            this.props.deleteCaseNote(noteId,this.state.caseId, this.props);
        }
        const fetchNoteById = (id) => {
            this.props.fetchNoteById(id, this.props);
        }
        const setNotesToEdit = (noteData) => {
            this.props.setNotesToEdit(noteData, this.props);
        }
        const clientState = this.props.state && this.props.state.clientState;
        const noteData = clientState && clientState.noteData;
        const notes = clientState && clientState.notes;
        const loading = this.props.state.caseState && this.props.state.caseState.loading;

        //ACTIVITY LOG
        const fetchActivityLogByCaseId = (id) =>{
            this.props.fetchActivityLogByCaseId(id);
        }

        const activityState = this.props.state && this.props.state.activityLogState;
        const activityLogValue = activityState && activityState.activityLogValue;

        return (
            <React.Fragment>
                <HelmetComponent title="Case Detail"/>
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />
                {this.props.state.caseState.loading || this.props.state.clientState.loading ? (
                    <div
                        className={
                            this.props.state.caseState.loading || this.props.state.clientState.loading
                                ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                {
                    caseState && caseState.success ? this.setState({
                        openModalToAddContact: false,
                        openModalToAddCompany: false,
                        openModalToAddExistingContact: false,
                    }, () =>{
                        this.props.setCaseContactSuccessToFalse();
                        this.props.setCaseCompanySuccessToFalse();
                        this.props.setCaseExistingContactSuccessToFalse();
                    }): null
                }

                {userRoleAgent &&
                this.state.agent &&
                userRoleAgent.length > 0 &&
                this.state.agent.length !==
                userRoleAgent.length ? this.setState({
                    agent: userRoleAgent.map(singleAgent => ({
                        key: singleAgent.userId,
                        text: singleAgent.fullName,
                        value: singleAgent.userId
                    }))
                }) : null}
                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/case" style={style.link}>
                        Case
                    </Link>
                    <Typography color="inherit" className="link">
                        Case Detail
                    </Typography>
                </Breadcrumbs>
                <div>
                    <div style={style.containerClient}>
                        <div className="row" style={style.header}>
                            <h5 style={style.headerText}> Client Detail </h5>
                        </div>
                        <div className="row" style={style.row}>
                            <div className="col-2">
                                <Avatar
                                    alt="Profile"
                                    src={profilePicUrl}
                                    style={{
                                        width: "150px",
                                        height: "150px",
                                    }}
                                />
                            </div>
                            <div className="col-8" style={{marginTop: "18px"}}>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="ssn" style={style.columnHeading}>Client Id</label>
                                        <label style={style.span}>
                                            {caseState && caseState.client && caseState.client.clientNo}
                                        </label>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="firstName" style={style.columnHeading}>First Name</label>
                                        <label style={style.span}>
                                            {caseState && caseState.client && caseState.client.firstName}
                                        </label>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="lastName" style={style.columnHeading}>Last Name</label>
                                        <label style={style.span}>
                                            {caseState && caseState.client && caseState.client.lastName}
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="dob" style={style.columnHeading}>Date-Of-Birth</label>
                                        <label style={style.span}>
                                            {caseState && caseState.client && getFormattedDateValue(caseState.client.dob)}
                                        </label>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="gender" style={style.columnHeading}>Gender</label>
                                        <label style={style.span}>
                                            {caseState && caseState.client &&
                                            caseState.client.gender === 'MALE' ? 'Male' :
                                                caseState && caseState.client && caseState.client.gender === 'FEMALE' ?
                                                    'Female' : caseState && caseState.client && caseState.client.gender}
                                        </label>
                                    </div>
                                    <div className="col-4">
                                        <label htmlFor="maritalStatus" style={style.columnHeading}>Marital
                                            Status</label>
                                        <label
                                            style={style.span}>
                                            {caseState && caseState.client &&
                                            caseState.client.maritalStatus === 'SINGLE' ? 'Single' :
                                                caseState && caseState.client && caseState.client.maritalStatus === 'MARRIED' ?
                                                    'Married' :
                                                    caseState && caseState.client && caseState.client.maritalStatus === 'DIVORCED' ?
                                                        'Divorced' :
                                                        caseState && caseState.client && caseState.client.maritalStatus}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{marginLeft: '3%', marginTop: '3%', marginRight: '3%'}}>
                     <Tabs defaultActiveKey={this.state.caseTab} onChange={callback}>
                    <TabPane tab="Case" key="1">
                        <div style={style.containerCase}>
                            <div className="row" style={style.header}>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <h5 style={style.headerText}> Case Detail </h5>
                                </div>
                            </div>
                            <div className="row" style={style.rowCase}>
                                <div className={"col-5"} style={{paddingTop: 0, paddingBottom: 0, paddingLeft: '4px'}}>
                                    <h6 style={style.columnCaseHeading}>Workflow</h6>
                                </div>

                                {this.props.state.loginState.user && this.props.state.loginState.user.userRole === 'AGENT' ?
                                    <>
                                        <div className="col-2" style={{paddingTop: 0, paddingBottom: '4px'}}>
                                            <h6 style={style.columnCaseHeading}>Schedule Date</h6>
                                        </div>
                                        <div className="col-3"
                                             style={{paddingTop: 0, paddingBottom: '4px', marginLeft: '0.5rem'}}>
                                            <h6 style={style.columnCaseHeading}>Assign to</h6>
                                        </div>
                                    </>
                                    :
                                    <div className="col-3" style={{paddingTop: 0, paddingBottom: '4px', marginLeft: '0.5rem'}}>
                                        <h6 style={style.columnCaseHeading}>Assign to</h6>
                                    </div>}
                            </div>

                            {caseState && caseState.caseData && caseState.caseData && caseState.caseData.workflow
                            && caseState.caseData.workflow.map(single => {
                                return (
                                    <div className="row" style={style.rowCaseField}>
                                        <div className={"col-5"} style={{paddingLeft: '4px'}}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography> {single.workflowName}
                                                        <Tooltip title={'Progress'}>
                                                    <span style={{
                                                        color: '#8BC83F',
                                                        paddingLeft: '10px',
                                                        fontSize: '15px'
                                                    }}>
                                                       <b>({single.completed ? Number.isInteger(single.completed) ?
                                                           single.completed :
                                                           single.completed.toFixed(2) : 0}%)</b>
                                                </span>
                                                        </Tooltip>
                                                    </Typography>
                                                </ExpansionPanelSummary>

                                                {single.action && single.action.map(singleAction => {
                                                    return (
                                                        <ExpansionPanelDetails onClick={() => {
                                                            this.props.history.push({
                                                                pathname: "/action/data/" +
                                                                    this.props.location.pathname.replace('/case/update/', '') +
                                                                    '/' + single.workflowId + '/' + singleAction.actionId
                                                            })
                                                        }} style={{cursor: 'pointer'}}>
                                                            <Typography>
                                                                {singleAction.actionName}
                                                            </Typography>
                                                        </ExpansionPanelDetails>
                                                    )
                                                })}
                                            </ExpansionPanel>
                                        </div>

                                        {this.props.state.loginState.user && this.props.state.loginState.user.userRole === 'AGENT' ?
                                            <div className={"col-2"}>
                                                <input type="date"
                                                       name="setTodayDate"
                                                       value={single.workflowAgents[0] && getDateField(single.workflowAgents[0].scheduledDate)}
                                                       onChange={(e) => {
                                                           const agentAssignId = single.workflowAgents[0]
                                                               && single.workflowAgents[0].agentAssignId;
                                                           changeAssignAgentDate(new Date(e.target.value).getTime(),
                                                               agentAssignId);
                                                           this.props.handleChangeDateOnly(agentAssignId, new Date(e.target.value).getTime())
                                                       }} style={style.inputFieldDate}
                                                />
                                            </div> : null}

                                        <div className="col-3"
                                             style={{marginLeft: '1.5rem', paddingTop: '0px', minWidth: '29%'}}>
                                            {userType === 'CORPORATE_USER' || userType === "CLIENT_USER" ?
                                                <label style={{
                                                    color: '#606060',
                                                    marginBottom: '0',
                                                    fontSize: '16px',
                                                    paddingTop: '10px',
                                                    width: '100%',
                                                    position: 'inherit',
                                                    fontWeight: '550'
                                                }}>
                                                    {single.workflowAgents && single.workflowAgents.map(singleAgent => {
                                                    return (
                                                        singleAgent.user.fullName
                                                    )
                                                })}</label>
                                                :
                                                this.props.state.loginState.user &&
                                                this.props.state.loginState.user.userRole === 'AGENT' ?
                                                    <div style={style.select}>
                                                        {this.props.state.caseState.caseData.addedBy ===
                                                        this.props.state.loginState.user.userId ?
                                                            <>
                                                                <Dropdown
                                                                    placeholder="Agent"
                                                                    clearable
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    selectOnBlur={false}
                                                                    value={single.workflowAgents && single.workflowAgents[0].user
                                                                    && single.workflowAgents[0].user.userId}
                                                                    options={this.state.agent}
                                                                    onChange={(e, data) => {
                                                                        changeAssignAgents(single.workflowId, data.value,
                                                                            single.workflowAgents);
                                                                        this.props.changeWorkFlowAgents(single.workflowId,
                                                                            data.value);
                                                                        this.setState({isBlocking: false});
                                                                    }}
                                                                    onAddItem={() => {
                                                                    }}
                                                                    style={style.dropdownInput}
                                                                />
                                                            </>
                                                            :
                                                            <label style={{
                                                                color: '#606060',
                                                                marginBottom: '0',
                                                                fontSize: '16px',
                                                                paddingTop: '10px',
                                                                width: '100%',
                                                                position: 'inherit',
                                                                fontWeight: '550'
                                                            }}>
                                                                {
                                                                    userInfoFromDB && userInfoFromDB.loginResponse.loginUser.user ?
                                                                        userInfoFromDB.loginResponse.loginUser.user.fullName : null
                                                                }
                                                            </label>}
                                                    </div>
                                                    :
                                                    <div style={style.select}>
                                                        <>
                                                            <Dropdown
                                                                placeholder="Agent"
                                                                clearable
                                                                fluid
                                                                search
                                                                selection
                                                                selectOnBlur={false}
                                                                value={single.workflowAgents && single.workflowAgents[0].user
                                                                && single.workflowAgents[0].user.userId}
                                                                options={this.state.agent}
                                                                onChange={(e, data) => {
                                                                    changeAssignAgents(single.workflowId, data.value, single.workflowAgents);
                                                                    this.props.changeWorkFlowAgents(single.workflowId, data.value);
                                                                    this.setState({isBlocking: false});
                                                                }}
                                                                onAddItem={() => {
                                                                }}
                                                                style={style.dropdownInput}
                                                            />
                                                        </>

                                                    </div>
                                            }


                                        </div>
                                    </div>
                                )
                            })}
                            {this.props.state.loginState.user && this.props.state.loginState.user.userRole === 'AGENT' ?
                                <div className="row" style={style.buttonRow}>
                                    <div className='col-5'>
                                    </div>
                                    <div className='col-3'
                                         style={{
                                             textAlign: 'right',
                                             paddingRight: '3.2rem',
                                             minWidth: '29%',
                                             marginRight: "12px"
                                         }}>
                                        {(this.props.state.caseState.caseData && this.props.state.caseState.caseData.addedBy ===
                                            this.props.state.loginState.user.userId) ||
                                        this.props.state.loginState.user.userRole === 'AGENT' ?
                                            <button
                                                className="btn btn-primary"
                                                style={style.nextButton}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({isBlocking: false});
                                                    this.props.state.loginState.user.userRole === 'AGENT' ?
                                                        scheduleDateByAgent() :
                                                        apiCallEditPage()
                                                }}>
                                                Update
                                            </button> : null}</div>
                                    <div className='col-3'>
                                    </div>
                                </div> :
                                <div className="row" style={style.buttonRow}>
                                    <div className='col-5'>
                                    </div>
                                    <div className='col-3'
                                         style={{
                                             textAlign: 'right',
                                             paddingRight: '3.2rem',
                                             minWidth: '21%',
                                             marginRight: "12px"
                                         }}>
                                        <button
                                            className="btn btn-primary"
                                            hidden={userType === 'CORPORATE_USER' || userType === "CLIENT_USER"}
                                            style={style.nextButton}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({isBlocking: false});
                                                apiCallEditPage()
                                            }}> Update

                                        </button>
                                    </div>
                                    <div className='col-3'>
                                    </div>
                                </div>}
                        </div>
                    </TabPane>
                    {
                        userType !== "EVERFUND_CONTACT" ?
                            <TabPane tab="Contact" key="2" className="secondTab">
                                <CaseContactScreen
                                    handleChangeOpenModalToAddContact={handleChangeOpenModalToAddContact}
                                    handleChangeCloseModalToAddContact = {handleChangeCloseModalToAddContact}
                                    openModalToAddContact={this.state.openModalToAddContact}
                                    handleCloseContactModal={handleCloseContactModal}
                                    handleOpenContactModal={handleOpenContactModal}
                                    openContactModal={this.state.openContactModal}
                                    handleChangeCloseModalToAddCompany={handleChangeCloseModalToAddCompany}
                                    handleChangeOpenModalToAddCompany = {handleChangeOpenModalToAddCompany}
                                    openModalToAddCompany={this.state.openModalToAddCompany}
                                    handleChangeOpenModalToAddExistingContact={handleChangeOpenModalToAddExistingContact}
                                    handleChangeCloseModalToAddExistingContact={handleChangeCloseModalToAddExistingContact}
                                    openModalToAddExistingContact={this.state.openModalToAddExistingContact}
                                    fetchCaseContact={fetchCaseContact}
                                    caseId = {this.state.caseId}
                                    propsState = {this.props.state}
                                    handleChangePage={handleChangePage}
                                    page={this.state.page}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPage={this.state.rowsPerPage}
                                    //apis
                                    handleChangeCaseContactInput={handleChangeCaseContactInput}
                                    addIndividualCaseContact={addIndividualCaseContact}
                                    handleChangeCaseCompanyInput={handleChangeCaseCompanyInput}
                                    addCaseCompany={addCaseCompany}
                                    closeSnackBar={this.props.handleSnackBarClose}
                                    handleChangeExistingContact={handleChangeExistingContact}
                                    searchExistingContact={searchExistingContact}
                                    addExistingCaseContact={addExistingCaseContact}
                                    fetchRelationshipList={this.props.fetchRelationshipList}
                                    handleChangeRelationshipName={handleChangeRelationshipName}
                                    searchContact={searchContact}
                                    fetchCompanyType={this.props.fetchCompanyType}
                                />
                            </TabPane>
                            : null
                    }
                    <TabPane tab="Files" key="3">
                        <CaseFilesDetailScreen fileResponse={fileResponse}/>
                    </TabPane>
                     <TabPane tab="Notes" key="4">
                         <NoteComponent
                             handleChangeNoteDescription={handleChangeNoteDescription}
                             addCaseNotes={addCaseNotes}
                             updateCaseNotes={updateCaseNotes}
                             deleteNotes={deleteNotes}
                             fetchNoteById={fetchNoteById}
                             setNotesToEdit={setNotesToEdit}
                             noteData={noteData}
                             loading={loading}
                             notes={notes}
                             clearNoteDescription={this.props.clearNoteDescription}
                             caseId={this.state.caseId}
                         />
                     </TabPane>
                         <TabPane tab="Activity Log" key="5">
                             <ActivityLogTable
                                 fetchActivityLogById={fetchActivityLogByCaseId}
                                 clientId={this.state.caseId}
                                 activityLogs={activityLogValue}
                             />
                         </TabPane>
                </Tabs>
                </div>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.caseState.openCaseUpdateSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.caseState.errorMsgForUpdateCase}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.userState.openUserSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarCloseForUser}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarCloseForUser}
                        variant="error"
                        message={this.props.state.userState.errorMsgForUser}
                    />
                </Snackbar>
                {/*client success/error message*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.clientState.openSnackBarForSuccessMessage}
                    autoHideDuration={5000}
                    onClose={this.props.handleChangeCloseSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleChangeCloseSnackBar}
                        variant="success"
                        message={this.props.state.clientState.successMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.clientState.openSnackBarForErrorMessage}
                    autoHideDuration={6000}
                    onClose={this.props.handleChangeCloseSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleChangeCloseSnackBar}
                        variant="error"
                        message={this.props.state.clientState.errorMsgForClient}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    containerClient: {
        background: "#ffffff",
        marginTop: "38px",
        marginLeft: '3%',
        marginRight: '3%',
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        position: 'inherit',
        borderBottom: "1px solid #8080804f",
    },
    containerCase: {
        background: "#ffffff",
        border: "1px solid rgb(233, 233, 233)",
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        position: 'inherit',
        paddingBottom: '1rem',
        marginBottom: '1rem'
    },
    card: {
        background: "rgb(255, 255, 255)",
        border: "1px solid rgb(233, 233, 233)",
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "1rem",
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        // position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    label: {
        color: '#606060',
        marginBottom: '0',
        fontSize: '16px',
        paddingBottom: "0.5rem",
        width: '100%',
        position: 'inherit'
    },
    header: {
        borderBottom: '1px solid #E9E9E9',
        marginLeft: '2px',
        marginRight: '2px'
    },
    headerText: {
        color: '#cc6600',
        fontSize: '22px',
        fontWeight: 'bolder',
        paddingTop: '10px',
        marginLeft: '2%',
        paddingBottom: '10px'
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '40px',
        paddingLeft: '40px',
        paddingBottom: '40px',
        position: 'inherit'
    },
    rowCase: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '20px',
        // paddingLeft: '42px',
        paddingBottom: '0px',
        paddingRight: '40px',
        position: 'inherit',
        paddingLeft: '40px',
        marginTop: "50px"
    },
    progressData: {
        marginBottom: '0',
        paddingBottom: '0'
    },
    progressDiv: {
        paddingTop: '8px',
        paddingLeft: '2rem',
        width: '90%'
    },
    rowCaseField: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingLeft: '40px',
        paddingBottom: '18px',
        paddingRight: '40px',
        position: 'inherit'
    },
    buttonRow: {
        paddingTop: '0px',
        paddingLeft: '40px',
        paddingBottom: '20px',
        paddingRight: '55px',
        justifyContent: 'flex-end'
    },
    column1: {
        flex: '33%',
        padding: '0 6px',
    },
    column2: {
        flex: '34%',
        padding: '0 6px',
        position: 'inherit'
    },
    column3: {
        flex: '33%',
        position: 'inherit'
    },
    formGroupIcon: {
        marginTop: '20px',
        position: 'relative',
        display: 'block'
    },
    columnHeading: {
        color: '#6b6868',
        marginBottom: '0px',
        fontSize: '16px',
        width: '100%',
        position: 'inherit'
    },
    inputField: {
        border: '0',
        backgroundColor: '#ffffff',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '10px',
        paddingLeft: '15px',
        position: 'inherit'
    },
    span: {
        color: 'rgb(99,97,92)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0.4rem',
        fontWeight: 550
    },
    columnCaseHeading: {
        color: '#5e5b5b',
        fontSize: '16px',
        width: '100%',
        position: 'inherit'
    },
    inputFieldCaseName: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: '#5e5b5b',
        fontSize: '18x',
        height: '46px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '0.8rem',
        paddingLeft: '15px',
        marginBottom: '1rem',
        position: 'inherit'
    },
    inputFieldDate: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: '#5e5b5b',
        fontSize: '18x',
        height: '46px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '0px',
        paddingLeft: '15px',
        marginBottom: '1rem',
        position: 'inherit',
        paddingRight: '12px'
    },
    inputFieldWorkflow: {
        border: '0',
        backgroundColor: '#ffffff',
        color: 'rgb(130, 132, 129)',
        fontSize: '16x',
        height: '46px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '0.8rem',
        paddingLeft: '15px',
        marginBottom: '1rem',
        position: 'inherit'
    },
    dropdownInput: {
        // marginTop: '1rem',
        marginRight: '2rem',
        height: '46px',
        paddingTop: '15px'
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        width: '220px',
        height: '44px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '31px',
    },
};

export default CaseUpdateFormScreen;
