import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class CardAPI extends API {
  fetchCard = (callback) => {
    fetch(APIEndPoints.FETCH_CARD, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  orderCard = (clientId, callback) => {
    let body = JSON.stringify(clientId);

    fetch(APIEndPoints.ADD_CARD, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  searchCard = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_CARD + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  updateOrderStatus = (statusData, callback) => {
    let body = JSON.stringify(statusData);

    fetch(APIEndPoints.UPDATE_ORDER_STATUS, {
      method: "PATCH",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  updateCardStatus = (statusData, callback) => {
    let body = JSON.stringify(statusData);
    fetch(APIEndPoints.UPDATE_CARD_STATUS, {
      method: "PATCH",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchSingleCardOrder = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_CARD_BY_ORDER_ID + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
}
export default new CardAPI();
