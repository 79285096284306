const Helpers = {
  checkValidityEmail: function (email) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  // checkValidityPhone: function (phone) {
  //     let phone_number = /^\(?(\+[0-9]+)\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //     return phone_number.test(phone);
  // },
  checkValidityPhone: function (phone) {
    let phone_number = /[^\d]/g;
    return phone_number.test(phone);
  },

  checkValidityPassword: function (password) {
    return !(password.value === "" || password.length <= 4);
  },

  handleValidationOnChange: (event, error) => {
    //REGEX to validate
    const validateNameRegex = RegExp(/^[a-z ,.'-]+$/i);
    const validateNumberOnly = RegExp(/^[0-9]*$/);
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const checkValidityPhone = RegExp(/[^\d]/g);
    const checkContactNumber = RegExp(
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
    );
    const checkValidityPassword = function (password) {
      return !(password.value === "" || password.length <= 4);
    };
    const { name, value } = event.target;
    const todayDate = new Date();
    const today = new Date(todayDate).getTime();
    const dateAfterSevenDay = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    let errors = error;

    switch (name) {
      case "firstName":
        errors.firstName =
          value === ""
            ? "First Name must not be empty"
            : !validateNameRegex.test(value)
            ? "First Name must contain letters only!"
            : value.length > 30
            ? "First Name must be at most 30 characters long!"
            : value.length < 3
            ? "First Name must be at least 3 characters long!"
            : "";
        break;
      case "lastName":
        errors.lastName =
          value === ""
            ? "Last Name must not be empty"
            : !validateNameRegex.test(value)
            ? "Last Name must contain letters only!"
            : value.length > 30
            ? "Last Name must be at most 30 characters long!"
            : value.length < 3
            ? "Last Name must be at least 3 characters long!"
            : "";
        break;
      case "emailPhone":
        errors.emailPhone =
          value === ""
            ? "Email must not be empty!"
            : !validEmailRegex.test(value)
            ? "Email is not valid!"
            : "";
        break;
      case "clientId":
        errors.clientId =
          value === ""
            ? "Client Id must not be empty!"
            : value.length < 9 || value.length > 9
            ? "Enter nine-digit client id!"
            : " ";
        break;
      case "clientName":
        errors.clientName = value === "" ? "Client Id must not be empty!" : "";
        break;
      case "vendorName":
        errors.vendorName =
          value === "" ? "Vendor name must not be empty!" : "";
        break;
      case "dob":
        errors.dob =
          value === ""
            ? "Date-of-birth must not be empty!"
            : value > today
            ? "You cannot enter a date in the future!"
            : "";
        break;
      case "amount":
        errors.amount =
          value === ""
            ? "Amount must not be empty!"
            : value < 0.01
            ? "Amount must be greater than 0.01!"
            : "";
        break;
      case "note":
        errors.note =
          value.length > 130 ? "Note must be at most 130 characters long!" : "";
        break;
      case "memo":
        errors.memo =
          value.length > 130
            ? "Memo must be at most 130 characters long!"
            : value.length === ""
            ? "Memo must not be empty!"
            : "";
        break;
      case "document":
        errors.document =
          value === "application/pdf" || value === "text/plain"
            ? ""
            : "Document should be pdf or text file only!";
      case "payTo":
        errors.payTo =
          value === ""
            ? "Pay to must not be empty!"
            : !validateNameRegex.test(value)
            ? "Pay To must contain letters only!"
            : "";
        break;
      case "shippingDate":
        errors.shippingDate =
          value === ""
            ? "Shipping date must not be empty!"
            : value > dateAfterSevenDay
            ? "Shipping date is more than 7 days in the future!"
            : value < today
            ? "Shipping date is not future date!"
            : "";
        break;
      case "fromName":
        errors.fromName =
          value === ""
            ? "Name must not be empty"
            : !validateNameRegex.test(value)
            ? "Name must contain letters only!"
            : value.length > 30
            ? "Name must be at most 30 characters long!"
            : value.length < 3
            ? "Name must be at least 3 characters long!"
            : "";
        break;
      case "toName":
        errors.toName =
          value === ""
            ? "Name must not be empty"
            : !validateNameRegex.test(value)
            ? "Name must contain letters only!"
            : value.length > 30
            ? "Name must be at most 30 characters long!"
            : value.length < 3
            ? "Name must be at least 3 characters long!"
            : "";
        break;
      case "fromFirm":
        errors.fromFirm =
          value === ""
            ? "Firm must not be empty!"
            : // : !validateNameRegex.test(value)
            //     ? 'Name must contain letters only!'
            value.length > 30
            ? "Firm must be at most 30 characters long!"
            : value.length < 3
            ? "Firm must be at least 3 characters long!"
            : "";
        break;
      case "toFirm":
        errors.toFirm =
          value === ""
            ? "Firm must not be empty!"
            : // : !validateNameRegex.test(value)
            //     ? 'Name must contain letters only!'
            value.length > 30
            ? "Firm must be at most 30 characters long!"
            : value.length < 3
            ? "Firm must be at least 3 characters long"
            : "";
        break;
      case "scheduledDate":
        errors.scheduledDate =
          value === ""
            ? "Scheduled date must not be empty!"
            : value < today
            ? "Schedule date is not future date!"
            : "";
        break;
      case "endDate":
        errors.endDate =
          value === ""
            ? "End date must not be empty!"
            : value < today
            ? "End date is not future date!"
            : "";
        break;
      case "name":
        errors.name =
          value === ""
            ? "Name must not be empty!"
            : !validateNameRegex.test(value)
            ? "Name must contain letters only!"
            : value.length > 30
            ? "Name must be at most 30 characters long!"
            : value.length < 3
            ? "Name must be at least 3 characters long!"
            : "";
        break;
      case "fullName":
        errors.fullName =
          value === ""
            ? "Full Name must not be empty!"
            : !validateNameRegex.test(value)
            ? "Full Name must contain letters only!"
            : value.length > 30
            ? "Full Name must be at most 30 characters long!"
            : value.length < 3
            ? "Full Name must be at least 3 characters long!"
            : "";
        break;
      case "userName":
        errors.userName =
          value === ""
            ? "User Name must not be empty!"
            : value.length > 30
            ? "User Name must be at most 30 characters long!"
            : value.length < 3
            ? "User Name must be at least 3 characters long!"
            : "";
        break;
      case "address1":
        errors.address1 =
          value === ""
            ? "Address must not be empty!"
            : value.length > 30
            ? "Address must be at most 30 characters long!"
            : "";
        break;
      case "address2":
        errors.address2 =
          value.length > 30
            ? "Address must be at most 30 characters long!"
            : "";
        break;
      case "state":
        errors.state =
          value === ""
            ? "State must not be empty!"
            : value.length > 20
            ? "State must be at most 20 characters long!"
            : "";
        break;
      case "city":
        errors.city =
          value === ""
            ? "City must not be empty!"
            : value.length > 20
            ? "City must be at most 20 characters long!"
            : "";
        break;

      case "contactNumber":
        errors.contactNumber =
          value === ""
            ? "Contact Number must not be empty!"
            : !checkContactNumber.test(value)
            ? "Invalid Phone number!"
            : "";
        break;
      case "routingNumber":
        errors.routingNumber =
          value === ""
            ? "Routing Number must not be empty!"
            : value.length > 9 || value.length < 9
            ? "Enter 9 digit routing number!"
            : "";
        break;
      case "routingNumberInVendor":
        errors.routingNumberInVendor =
          value === ""
            ? " "
            : value.length > 9 || value.length < 9
            ? "Enter 9 digit routing number!"
            : "";
        break;
      case "bankAccountNumber":
        errors.bankAccountNumber =
          value === "" ? "Bank Account Number must not be empty!" : "";
        break;
      case "bankAccountNumberInVendor":
        errors.bankAccountNumber = value === "" ? " " : "";
        break;
      case "accountName":
        errors.accountName =
          value === ""
            ? "Bank Account Name must not be empty!"
            : !validateNameRegex.test(value)
            ? "Account Name must contain letters only!"
            : "";
        break;
      case "accountHolderName":
        errors.accountHolderName =
          value === ""
            ? " "
            : !validateNameRegex.test(value)
            ? "Account Holder Name must contain letters only!"
            : "";
        break;
      case "number":
        errors.number =
          value === ""
            ? "Reference Id must not be empty!"
            : !validateNumberOnly.test(value)
            ? "Reference Id must contain numbers only!"
            : "";
        break;
      case "taxVatNumber":
        errors.taxVatNumber =
          value === ""
            ? "Tax/Vat Number must not be empty!"
            : !validateNumberOnly.test(value)
            ? "Tax/Vat Number must contain numbers only!"
            : "";
        break;
      case "registerNumber":
        errors.registerNumber =
          value === ""
            ? "Register Number must not be empty!"
            : !validateNumberOnly.test(value)
            ? "Register Number must contain numbers only!"
            : "";
        break;
      default:
        break;
    }
  },
};
export default Helpers;

export const uploadDocumentValidation = (size, type) =>
  (size <= 26214400 && type === "application/pdf") ||
  type === "text/plain" ||
  type === "text/csv" ||
  type ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
  type === "application/vnd.ms-excel" ||
  type === "application/msword" ||
  type ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const uploadImageValidation = (size, type) =>
  (size <= 26214400 && type === "image/png") ||
  type === "image/jpeg" ||
  type === "text/jpg";

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const getBankType = (type) => {
  if (type === 1) {
    return "Checking Account";
  } else {
    return "Saving account";
  }
};
