import { ACTION_TYPE_CASE_TEMPLATE } from "../constants/Type";

export const caseTemplateRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.CASE_TEMPLATE_REQUEST_MADE,
        bool
    };
};

export const fetchCaseTemplateSuccess = caseTemplate => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.CASE_TEMPLATE_FETCH_SUCCESS,
        caseTemplate
    };
};

export const fetchCaseTemplateFailure = error => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.CASE_TEMPLATE_FETCH_FAILURE,
        error
    };
};

export const deleteCaseTemplateSuccess = (caseTemplateId) => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.DELETE_CASE_TEMPLATE_SUCCESS,
        caseTemplateId
    }
};

export const deleteCaseTemplateFailure = (error) => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.DELETE_CASE_TEMPLATE_FAILURE,
        error
    }
};

export const handleChangeCaseTemplate = (id, value) => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.HANDLE_CHANGE_CASE_TEMPLATE,
        id,
        value
    };
};


export const addCaseTemplateSuccess = bool => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.ADD_CASE_TEMPLATE_SUCCESS,
        bool
    };
};

export const addCaseTemplateFailure = error => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.ADD_CASE_TEMPLATE_FAILURE,
        error
    };
};

export const setCaseTemplateToEdit = (caseTemplateData) => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.SET_CASE_TEMPLATE_TO_EDIT,
        caseTemplateData
    }
};


export const closeSnackBarForCaseTemplate = (bool) => {
    return {
        type: ACTION_TYPE_CASE_TEMPLATE.CLOSE_SNACK_BAR_CASE_TEMPLATE_PAGE,
        bool
    };
};