import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import "../styles/styles.css";
import "./calendarStyle.css";
import "./cardScreen/cardScreen.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Button,
  CircularProgress,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { finalAmount, naturalRound } from "../utils/currencyConvertor";
import TablePagination from "@material-ui/core/TablePagination";
import FilterListIcon from "@material-ui/icons/FilterList";
import PrintIcon from "@material-ui/icons/Print";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { DatePicker, Select, Tag, Tooltip } from "antd";
import moment from "moment";
import DialogContentText from "@material-ui/core/DialogContentText";
import MySnackbarContentWrapper from "../components/Snackbar";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import { DocumentImage } from "../components/DocumentImage";
import CancelIcon from "@material-ui/icons/Cancel";
import NotesPopover from "../components/NotesPopover";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import ExportButton from "../components/ExportButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { rangeComparator, showGreaterThanSign } from "./TransactionHelper";

const { Option } = Select;

class CheckPrintScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openActionMenu: false,
      openSearchDialogue: false,
      openPrintDialogue: false,
      openDialogue: false,
      checkPrintData: {},
      showDropdown: false,
      openErrorSnackBar: false,
      clicked: false,
      errors: {
        payTo: "",
        amount: "",
        clientId: "",
        bankName: "",
        memo: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip5: "",
      },
      openModalToDeclineCheck: false,
      checkId: "",
      // sorting table column
      order: "desc",
      orderBy: "createdDate",
      // config menu
      openConfigMenu: false,
      OpenViewFilterInConfig: false,
      OpenSaveFilterInConfigmenu: false,
      configName: "",
      openRangeBar: false,
    };
  }
  componentDidMount() {
    this.props.fetchCheckPrintList();
    this.props.fetchVendor();
    this.props.fetchDescriptionList();
  }

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };

  render() {
    const checkPrintData = this.state.checkPrintData;
    const checkPrintState = this.props && this.props.state.checkPrintState;
    const checkPrintList = checkPrintState && checkPrintState.checkPrintList;
    const attachment = checkPrintState && checkPrintState.attachement;
    const bankName = checkPrintState && checkPrintState.bankName;
    const amount = checkPrintState && checkPrintState.amount;
    const review = checkPrintState && checkPrintState.review;
    const payTo = checkPrintState && checkPrintState.payTo;
    const address1 = checkPrintState && checkPrintState.address1;
    const address2 = checkPrintState && checkPrintState.address2;
    const city = checkPrintState && checkPrintState.city;
    const state = checkPrintState && checkPrintState.state;
    const zip5 = checkPrintState && checkPrintState.zip5;
    const startDate =
      checkPrintState && checkPrintState.startDate === ""
        ? null
        : checkPrintState.startDate;
    const endDate =
      checkPrintState && checkPrintState.endDate === ""
        ? null
        : checkPrintState.endDate;
    const description = checkPrintState && checkPrintState.description;
    const clientId = checkPrintState && checkPrintState.clientId;
    const scheduleTransactionState =
      this.props.state && this.props.state.scheduleTransactionState;
    const clients =
      scheduleTransactionState && scheduleTransactionState.clientList;
    const scheduleLoading =
      scheduleTransactionState && scheduleTransactionState.loading;
    const openScheduleTransactionSnackBar =
      scheduleTransactionState &&
      scheduleTransactionState.openScheduleTransactionSnackBar;
    const errorMsg =
      scheduleTransactionState && scheduleTransactionState.errorMsg;
    const loading = checkPrintState && checkPrintState.loading;

    // filter config data
    const filterDate = checkPrintState && checkPrintState.filterDate;
    const filterCheckNo = checkPrintState && checkPrintState.filterCheckNo;
    const filterBankName = checkPrintState && checkPrintState.filterBankName;
    const filterVendor = checkPrintState && checkPrintState.filterVendor;
    const filterAmount = checkPrintState && checkPrintState.filterAmount;
    const compareType = checkPrintState && checkPrintState.compareType;
    const configlist = checkPrintState && checkPrintState.configList;
    // setting states
    const settingState =
      this.props && this.props.state && this.props.state.settingState;
    const descriptionList = settingState && settingState.descriptionList;

    const filterForValue =
      bankName !== "" ||
      amount !== "" ||
      startDate !== null ||
      endDate !== null;
    const manualCheck =
      amount !== "" && payTo !== "" && review !== "" && clientId !== "";

    const filterStatus =
      filterDate !== null ||
      filterCheckNo !== "" ||
      filterBankName !== "" ||
      filterVendor !== "" ||
      filterAmount !== "";

    const configStatus = this.state.configName !== "" && filterStatus;

    const settingsState = this.props && this.props.state.settingState;
    const checkingAccountData =
      settingsState && settingsState.checkingAccountData;
    const destinationName =
      checkingAccountData && checkingAccountData.destinationName;

    const vendorState = this.props && this.props.state.vendorState;
    const vendorAccount = vendorState && vendorState.vendorAccount;
    const systemVendor =
      vendorAccount &&
      vendorAccount.filter(
        (vendorList) => vendorList.vendorType && vendorList.vendorType.isDefault
      );
    const vendorToShow =
      systemVendor && systemVendor.accountName ? systemVendor : [];

    const accountNumber =
      vendorToShow && vendorToShow.map((vendor) => vendor.accountNumber)[0];
    const routingNumber =
      vendorToShow && vendorToShow.map((vendor) => vendor.routingNumber)[0];

    const transactionStat = this.props && this.props.state.transactionState;
    const declineRemark = transactionStat && transactionStat.declineRemark;
    const declineStatus = declineRemark !== "";

    const handleChangeOpenModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: true,
      });
    };
    const handleChangeCloseModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: false,
      });
    };

    const getDate = (dateToConvert) => {
      return new Date(parseInt(dateToConvert)).toLocaleDateString();
    };
    const getPickerDate = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };
    const handleChangeStartDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        this.props.handleChangeStartDate(new Date(valueOfInput1).getTime());
      } else if (range === null) {
        this.props.handleChangeStartDate(
          new Date(0).getTime(),
          new Date(0).getTime()
        );
      }
    };
    const handleChangeEndDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        this.props.handleChangeEndDate(new Date(valueOfInput1).getTime());
      } else if (range === null) {
        this.props.handleChangeEndDate(
          new Date(0).getTime(),
          new Date(0).getTime()
        );
      }
    };
    function convert(str) {
      var dateStr = new Date(str),
        mnth = ("0" + (dateStr.getMonth() + 1)).slice(-2),
        day = ("0" + dateStr.getDate()).slice(-2);
      return [mnth, day, dateStr.getFullYear()].join("/");
    }
    var newDate = new Date();
    var firstDayOfMonth = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      1
    );
    var lastDayOfMonth = new Date(
      newDate.getFullYear(),
      newDate.getMonth() + 1,
      0
    );

    //this week
    var first = newDate.getDate() - newDate.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var weekFirstDay = new Date(newDate.setDate(first)).toLocaleString();
    var weekLastDay = new Date(newDate.setDate(last)).toLocaleString();

    const { errors } = this.state;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleOpenErrorSnackBar = () => {
      this.setState({
        openErrorSnackBar: true,
      });
    };
    const handleCloseErrorSnackBar = () => {
      this.setState({
        openErrorSnackBar: false,
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeOpenSearchDialogue = () => {
      this.setState({
        openSearchDialogue: true,
      });
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
      });
      // this.props.clearFilterModal("", "", "", "");
    };
    const handleChangeOpenPrintDialogue = () => {
      this.props.handleChangeAttachment("");
      this.setState(
        {
          openPrintDialogue: true,
          errors: {
            payTo: "",
            amount: "",
            clientId: "",
            bankName: "",
            memo: "",
            address1: "",
            address2: "",
            state: "",
            city: "",
            zip5: "",
          },
        },
        () => {
          this.props.setManualCheckPrintDataToEmpty();
        }
      );
    };
    const handleChangeClosePrintDialogue = () => {
      this.setState({
        openPrintDialogue: false,
      });
    };

    const handleOpenDetailDialogue = () => {
      this.setState({
        openDialogue: true,
      });
    };
    const handleCloseDetailDialogue = () => {
      this.setState({
        openDialogue: false,
      });
    };

    const onChangeDocumentFileHandler = (eventFileHandler) => {
      let file = eventFileHandler.target.files[0];
      let type = file && file.type;
      let name = file && file.name;
      let singleFile = {
        document: file,
        type: type,
        name: name,
      };
      {
        this.setState(
          {
            isBlocking: true,
          },
          () => {
            this.props.uploadDocumentFile(singleFile, this.props);
          }
        );
      }
    };

    const handleUpdateStatus = () => {
      const checkData = {
        checkPrintId: this.state.checkId,
        description: declineRemark,
      };
      this.props.updateCheckStatus(checkData, this.props);
    };

    const printReprintCheck = (checkData) => {
      const isReprint = true;
      if (checkData && checkData.url) {
        const response = checkData;
        this.props.history.push({
          pathname: "/checkToPrint",
          state: { response, isReprint },
        });
      } else {
        alert("No check available to print!");
      }
    };

    function descendingComparator(a, b, orderBy) {
      if (orderBy === "amount") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "cases") {
        let first = b.client[orderBy] ? b.client[orderBy].length : 0;
        let second = a.client[orderBy] ? a.client[orderBy].length : 0;
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return null;
      } else if (orderBy === "checkNo") {
        return a[orderBy]
          .toString()
          .localeCompare(b[orderBy].toString(), "eng", {
            numeric: true,
          });
      } else if (orderBy === "vendorName") {
        let second =
          b.transaction &&
          b.transaction.vendorAccount &&
          b.transaction.vendorAccount &&
          b.transaction.vendorAccount.vendorName
            ? b.transaction.vendorAccount.vendorName
            : "z";
        let first =
          a.transaction &&
          a.transaction.vendorAccount &&
          a.transaction.vendorAccount &&
          a.transaction.vendorAccount.vendorName
            ? a.transaction.vendorAccount.vendorName
            : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return null;
      }
    }

    function getComparator(checkList, order, orderBy) {
      if (order === "asc") {
        const list =
          checkList &&
          checkList.length > 0 &&
          checkList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          checkList &&
          checkList.length > 0 &&
          checkList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }
    const sortedCheckPrintList = getComparator(
      checkPrintList,
      this.state.order,
      this.state.orderBy
    );

    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
      });
    };

    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        date: filterDate === null ? "" : filterDate,
        checkNo: filterCheckNo,
        amount: filterAmount,
        bankName: filterBankName,
        vendorName: filterVendor,
        compareType: compareType,
        type: 7,

        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };
      this.props.fetchCheckPrintList();
      this.props.clearFilterData();
      this.props.saveInConfig(data);
    };

    const handleUseConfigFilter = (config) => {
      this.props.clearFilterData();

      if (config.date !== "0") {
        this.props.handleCheckPrintFilter("filterDate", config.date);
      }
      if (config.checkNo) {
        this.props.handleCheckPrintFilter("filterCheckNo", config.checkNo);
      }
      if (config.amount) {
        this.props.handleCheckPrintFilter("filterAmount", config.amount);
      }
      if (config.bankName) {
        this.props.handleCheckPrintFilter("filterBankName", config.bankName);
      }
      if (config.vendorName) {
        this.props.handleCheckPrintFilter("filterVendor", config.vendorName);
      }
      if (config.comparator) {
        this.props.handleCheckPrintFilter(
          "compareType",
          rangeComparator(config.comparator)
        );
      }
      this.props.filterCheckPrintByAll(
        config.checkNo ? config.checkNo : "",
        config.bankName ? config.bankName : "",
        config.vendorName ? config.vendorName : "",
        config.date ? config.date : "0",
        config.amount ? config.amount : "",
        config.comparator ? rangeComparator(config.comparator) : "0"
      );
    };
    return (
      <React.Fragment>
        <HelmetComponent title="Check Print" />
        {checkPrintState && checkPrintState.loading ? (
          <div
            className={
              checkPrintState && checkPrintState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        {checkPrintState && checkPrintState.success === true
          ? this.setState({ openPrintDialogue: false }, () => {
              this.props.setManualCheckPrintSuccessToFalse();
            })
          : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Check Print
          </Typography>
        </Breadcrumbs>
        <div style={{ margin: "48px 3% 1.5% 3%" }}>
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            <div>
              <button
                className="settingButton"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                  });
                }}
              >
                <img
                  alt={"setting"}
                  src={"/assets/images/sort.png"}
                  style={{ width: "18px", height: "18px" }}
                />
              </button>
            </div>
            <div>
              <button
                className="filterButton"
                onClick={() => {
                  handleChangeOpenSearchDialogue();
                }}
              >
                <FilterListIcon style={{ color: "#fff", marginTop: "-2px" }} />
              </button>
            </div>

            {/* Export button */}

            <button
              className="exportButton"
              onClick={(e) => {
                e.preventDefault();
                this.props.exportCheckPrint(startDate, endDate);
              }}
            >
              <img
                alt={"setting"}
                src={"/assets/images/export.svg"}
                style={{
                  width: "26px",
                  height: "18px",
                  marginRight: "7px",
                  marginBottom: "4px",
                }}
              />
              Export
            </button>

            <div>
              <button
                className="printButton"
                // style={{ marginRight: "0px" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleChangeOpenPrintDialogue();
                  this.props.handleChangeBankName(destinationName);
                  this.props.fetchCheckingAccountsList();
                }}
              >
                <PrintIcon
                  style={{
                    width: "26px",
                    height: "30px",
                    marginRight: "7px",
                    marginBottom: "4px",
                  }}
                />
                Print
              </button>
            </div>
            <div>
              <button
                className="printButton"
                style={{ width: "132px" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: "/checkprint/bulkprint",
                  });
                }}
              >
                <PrintIcon
                  style={{
                    width: "26px",
                    height: "30px",
                    marginRight: "7px",
                    marginBottom: "4px",
                  }}
                />
                Bulk Print
              </button>
            </div>
            <div className="search">
              <div style={style.searchIcon}>
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={style.inputBase}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value === "" || e.target.value === " ") {
                    this.props.fetchCheckPrintList();
                  } else {
                    this.props.filterCheckPrintByTerm(e.target.value);
                  }
                }}
              />
            </div>
          </div>

          {/* <div style={{paddingTop: "70px"}}> */}
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell
                      className="first-tableHeader override-mui-table"
                      key="createdDate"
                      sortDirection={
                        this.state.orderBy === "createdDate"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "createdDate"}
                        direction={
                          this.state.orderBy === "createdDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("createdDate")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="checkNo"
                      sortDirection={
                        this.state.orderBy === "checkNo"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "checkNo"}
                        direction={
                          this.state.orderBy === "checkNo"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("checkNo")}
                      >
                        Check Number
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="bankName"
                      sortDirection={
                        this.state.orderBy === "bankName"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "bankName"}
                        direction={
                          this.state.orderBy === "bankName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("bankName")}
                      >
                        Bank Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="vendorName"
                      sortDirection={
                        this.state.orderBy === "vendorName"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "vendorName"}
                        direction={
                          this.state.orderBy === "vendorName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("vendorName")}
                      >
                        Vendor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="amount"
                      sortDirection={
                        this.state.orderBy === "amount"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "amount"}
                        direction={
                          this.state.orderBy === "amount"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("amount")}
                      >
                        Amount
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      style={{ paddingLeft: "80px" }}
                    >
                      Attached Transaction
                    </TableCell>
                    <TableCell className="tableHeader">Check Status</TableCell>
                    <TableCell className="tableHeader">Export Status</TableCell>
                    <TableCell className="tableHeader">Print Status</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="sticky-search-bar">
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterDate === null ? "" : filterDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleCheckPrintFilter(
                              "filterDate",
                              e.target.value
                            );
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchCheckPrintList();
                              this.props.handleCheckPrintFilter(
                                "filterDate",
                                null
                              );
                            } else {
                              this.props.filterCheckPrintByAll(
                                filterCheckNo,
                                filterBankName,
                                filterVendor,
                                e.target.value,
                                filterAmount,
                                compareType
                              );
                            }
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterCheckNo}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleCheckPrintFilter(
                              "filterCheckNo",
                              e.target.value
                            );
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchCheckPrintList();
                            } else {
                              this.props.filterCheckPrintByAll(
                                e.target.value,
                                filterBankName,
                                filterVendor,
                                filterDate,
                                filterAmount,
                                compareType
                              );
                            }
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterBankName}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleCheckPrintFilter(
                              "filterBankName",
                              e.target.value
                            );
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchCheckPrintList();
                            } else {
                              this.props.filterCheckPrintByAll(
                                filterCheckNo,
                                e.target.value,
                                filterVendor,
                                filterDate,
                                filterAmount,
                                compareType
                              );
                            }
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell sclassName="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterVendor}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleCheckPrintFilter(
                              "filterVendor",
                              e.target.value
                            );
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchCheckPrintList();
                            } else {
                              this.props.filterCheckPrintByAll(
                                filterCheckNo,
                                filterBankName,
                                e.target.value,
                                filterDate,
                                filterAmount,
                                compareType
                              );
                            }
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      {" "}
                      <div
                        className="sm-search"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="show-range-sign">
                          {showGreaterThanSign(compareType)}
                        </span>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterAmount}
                          className="show-range"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleCheckPrintFilter(
                              "filterAmount",
                              e.target.value
                            );
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchCheckPrintList();
                            } else {
                              this.props.filterCheckPrintByAll(
                                filterCheckNo,
                                filterBankName,
                                filterVendor,
                                filterDate,
                                e.target.value,
                                compareType
                              );
                            }
                          }}
                        />
                        <ExpandMoreIcon
                          id="Popover1"
                          className="expand-more-icon"
                          onClick={(e) => {
                            this.setState({
                              popoverElement: e.currentTarget,
                              // client: client.client,
                              openRangeBar: true,
                            });
                          }}
                        />
                        <Popover
                          open={this.state.openRangeBar}
                          anchorEl={this.state.popoverElement}
                          onClose={(e) => {
                            e.preventDefault();
                            this.setState({ openRangeBar: false });
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          style={{ boxShadow: "none" }}
                        >
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                });
                                this.props.handleCheckPrintFilter(
                                  "compareType",
                                  "2"
                                );
                                this.props.filterCheckPrintByAll(
                                  filterCheckNo,
                                  filterBankName,
                                  filterVendor,
                                  filterDate,
                                  filterAmount,
                                  "2"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Lesser than {`(<)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();

                                this.props.getConfigList("1");
                                this.setState({
                                  openRangeBar: false,
                                });
                                this.props.handleCheckPrintFilter(
                                  "compareType",
                                  "1"
                                );
                                this.props.filterCheckPrintByAll(
                                  filterCheckNo,
                                  filterBankName,
                                  filterVendor,
                                  filterDate,
                                  filterAmount,
                                  "1"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Greater than {`(>)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                });
                                this.props.handleCheckPrintFilter(
                                  "compareType",
                                  "0"
                                );
                                this.props.filterCheckPrintByAll(
                                  filterCheckNo,
                                  filterBankName,
                                  filterVendor,
                                  filterDate,
                                  filterAmount,
                                  "0"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>None</span>
                            </span>
                          </MenuList>
                        </Popover>
                      </div>
                    </TableCell>
                    <TableCell style={style.tableHeader} />
                    <TableCell style={style.tableHeader} />
                    <TableCell style={style.tableHeader} />
                    <TableCell style={style.tableHeader} />
                    {/* <TableCell style={style.tableHeader} /> */}
                    <TableCell className="tableCol">
                      <MoreHorizIcon
                        id="Popover1"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          this.setState({
                            popoverElement: e.currentTarget,

                            openConfigMenu: true,
                          });
                        }}
                      />

                      <Popover
                        open={this.state.openConfigMenu}
                        anchorEl={this.state.popoverElement}
                        onClose={(e) => {
                          e.preventDefault();
                          this.setState({ openConfigMenu: false });
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        style={{ boxShadow: "none" }}
                      >
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                OpenSaveFilterInConfigmenu: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              Save In Config
                            </span>
                          </span>
                        </MenuList>
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.getConfigList("7");
                              this.setState({
                                OpenViewFilterInConfig: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              View Config
                            </span>
                          </span>
                        </MenuList>
                      </Popover>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {sortedCheckPrintList &&
                  sortedCheckPrintList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody key={list.checkPrintId}>
                        <TableRow>
                          <TableCell
                            className="first-tableBody"
                            style={{ width: "13%" }}
                          >
                            {getDate(list.createdDate)}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "13%" }}
                          >
                            {list.checkNo}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "13%" }}
                          >
                            {list.bankName}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "13%" }}
                          >
                            {list.transaction &&
                            list.transaction.vendorAccount &&
                            list.transaction.vendorAccount.vendorName
                              ? list.transaction.vendorAccount.vendorName
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "2%", textAlign: "left" }}
                          >
                            {list.amount
                              ? formatter.format(list.amount / 100)
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              width: "25%",
                              paddingLeft: "80px",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDetailDialogue();
                              this.setState({
                                checkPrintData: list,
                              });
                            }}
                          >
                            {list.transactionId ? list.transactionId : "_"}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "12%" }}
                          >
                            {list.checkStatus ? (
                              list.transaction ? (
                                <>
                                  <Tag
                                    color={
                                      list.checkStatus === "CHECK_SUBMITTED"
                                        ? "blue"
                                        : list.checkStatus === "CHECK_RETURNED"
                                        ? "geekblue"
                                        : list.checkStatus === "CHECK_REVERSED"
                                        ? "red"
                                        : list.checkStatus === "CHECK_CREATED"
                                        ? "purple"
                                        : list.checkStatus === "CHECK_CANCELED"
                                        ? "gold"
                                        : "lime"
                                    }
                                  >
                                    {list.checkStatus &&
                                      list.checkStatus.replace("CHECK_", " ")}
                                  </Tag>
                                  {list.transaction &&
                                  list.transaction.remark !== undefined ? (
                                    <NotesPopover
                                      content={
                                        list.transaction &&
                                        list.transaction.remark
                                      }
                                      isRemark
                                    />
                                  ) : null}
                                </>
                              ) : (
                                <div>-</div>
                              )
                            ) : (
                              <div>-</div>
                            )}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.isExported ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="orange">No</Tag>
                            )}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.isPrinted ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="orange">No</Tag>
                            )}
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ cursor: "pointer", width: "32%" }}
                          >
                            <div>
                              {list.isPrinted ? (
                                <>
                                  <Tooltip placement="bottom" title="Reprint">
                                    <PrintIcon
                                      onClick={() => {
                                        printReprintCheck(list);
                                      }}
                                      className="printIcon"
                                    />
                                  </Tooltip>
                                  <Tooltip placement="bottom" title="Decline">
                                    <CancelIcon
                                      hidden={
                                        list.checkStatus !== "CHECK_SUBMITTED"
                                      }
                                      onClick={() => {
                                        this.setState({
                                          checkId: list.checkPrintId,
                                        });
                                        handleChangeOpenModalToDecline();
                                        this.props.handleChangeRemark("");
                                      }}
                                      className="cancelIcon"
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip placement="bottom" title="Print">
                                    <PrintIcon
                                      onClick={() => {
                                        printReprintCheck(list);
                                      }}
                                      className="printIcon"
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {sortedCheckPrintList && sortedCheckPrintList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[8, 10, 25, 100]}
                  component="div"
                  count={
                    sortedCheckPrintList.length > 0
                      ? sortedCheckPrintList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
          {/* </div> */}
        </div>

        {/*modal to decline check*/}
        <Dialog
          open={this.state.openModalToDeclineCheck}
          onClose={handleChangeCloseModalToDecline}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseModalToDecline}
            >
              Decline Check
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Remark <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeRemark(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={declineStatus}
                    buttonName="Decline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseModalToDecline();
                      handleUpdateStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        <Dialog
          id="searchCheck"
          open={this.state.openSearchDialogue}
          maxWidth="lg"
          onClose={handleChangeCloseSearchDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseSearchDialogue}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent
            dividers
            className="filterDialog"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeStartDate(data);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeEndDate(data);
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeStartDate(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeEndDate(date);
              }}
            />
            <label className="label">Select Bank</label>
            <div className="select-input selects h40">
              <Select
                showSearch={false}
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  this.props.handleChangeBankName(e);
                }}
              >
                {vendorToShow &&
                  vendorToShow.map((vendor) => (
                    <Option value={vendor.accountName}>
                      {vendor.accountName}
                    </Option>
                  ))}
              </Select>
            </div>
            <label className="label">Amount(Dollar)</label>
            <input
              type="number"
              id="amount"
              autoComplete="off"
              className="input"
              style={{ marginBottom: "0px !important" }}
              onKeyPress={(e) => {
                naturalRound(e);
              }}
              onChange={(e) => {
                e.preventDefault();
                this.props.handleChangeAmount(finalAmount(e));
              }}
            />
            <div className="row">
              <div className={"col-6"} />
              <div className={"col-6"} style={{ paddingTop: "15px" }}>
                <Button
                  style={{ float: "right" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      page: 0,
                    });
                    if (bankName === "" && amount === "") {
                      this.props.filterCheckPrintByDate(startDate, endDate);
                    } else if (
                      startDate === null &&
                      endDate === null &&
                      amount === ""
                    ) {
                      this.props.filterCheckPrintByBank(bankName);
                    } else if (
                      startDate === null &&
                      endDate === null &&
                      bankName === ""
                    ) {
                      this.props.filterCheckPrintByAmount(amount);
                    } else if (
                      startDate !== null &&
                      endDate !== null &&
                      bankName !== "" &&
                      amount === ""
                    ) {
                      this.props.filterCheckPrintByBankAndDate(
                        bankName,
                        startDate,
                        endDate
                      );
                    } else if (
                      startDate !== null &&
                      endDate !== null &&
                      bankName === "" &&
                      amount !== ""
                    ) {
                      this.props.filterCheckPrintByDateAndAmount(
                        startDate,
                        endDate,
                        amount
                      );
                    } else if (
                      startDate === null &&
                      endDate === null &&
                      bankName !== "" &&
                      amount !== ""
                    ) {
                      this.props.filterCheckPrintByBankAndAmount(
                        bankName,
                        amount
                      );
                    } else {
                      this.props.filterCheckPrintByBankAndDateAndAmount(
                        bankName,
                        startDate,
                        endDate,
                        amount
                      );
                    }
                    handleChangeCloseSearchDialogue();
                  }}
                  disabled={!filterForValue}
                  className={filterForValue ? "addButton" : "disabledAddButton"}
                >
                  Filter
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*add manual check*/}
        <Dialog
          id="openPrintCheck"
          open={this.state.openPrintDialogue}
          maxWidth="lg"
          onClose={handleChangeClosePrintDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeClosePrintDialogue}
          >
            Manual check
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              paddingLeft: "1.5rem !important",
              paddingRight: "1.5rem !important",
              width: "530px",
              paddingTop: "5px",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <label className="label" style={{ marginTop: "0px" }}>
                  Select Bank <span style={{ color: "red" }}> *</span>
                </label>
                <input className="inputField" value={destinationName} />
              </div>
              <div className="col-lg-6">
                <label className="label" style={{ marginTop: "0px" }}>
                  Pay To <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  id="payTo"
                  className="inputField"
                  name="payTo"
                  value={payTo}
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangePayTo(e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.payTo.length > 0 && (
                  <span className="error">{errors.payTo}</span>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-6">
                <label className="label">
                  Amount(Dollar) <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  id="amount"
                  name="amount"
                  className="inputField"
                  onKeyPress={(e) => {
                    naturalRound(e);
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeAmount(finalAmount(e));
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.amount.length > 0 && (
                  <span className="error">{errors.amount}</span>
                )}
              </div>
              <div className="col-lg-6">
                <label className="label">
                  Client Name <span style={{ color: "red" }}> *</span>
                </label>
                <div className="select-input selects h40">
                  <Select
                    showSearch
                    getPopupContainer={(node) => node.parentNode}
                    style={{ width: 200 }}
                    className="drop"
                    onSearch={(value) => {
                      this.props.searchClientList(value);
                    }}
                    onInputKeyDown={() => {
                      this.setState({
                        showDropdown: true,
                      });
                    }}
                    onChange={(e) => {
                      this.props.handleChangeClientId(e);
                    }}
                    onSelect={() => {
                      this.setState({
                        showDropdown: false,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        showDropdown: false,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultOpen={false}
                    dropdownStyle={
                      this.state.showDropdown
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    {clients &&
                      clients.map((res) => (
                        <Option key={res.clientId} value={res.clientId}>
                          {res.firstName +
                            " " +
                            res.lastName +
                            " (" +
                            res.clientNo +
                            " )"}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 select-input h40">
                <label className="label">
                  Description <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  className="drop"
                  dropdownStyle={{ maxHeight: "110px", overflowY: "scroll" }}
                  // value={transData.description}
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(e) => {
                    this.setState({
                      isBlocking: true,
                    });
                    this.props.handleChangeDescription(e);
                  }}
                >
                  {descriptionList &&
                    descriptionList.map((list) => (
                      <Option value={list.description}>
                        {list.description}
                      </Option>
                    ))}
                </Select>
              </div>
              <div className="col-6">
                <label className="label">
                  Memo <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="review"
                  name="memo"
                  autoComplete="off"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeMemo(e.target.value);
                  }}
                />
                {errors.memo.length > 0 && (
                  <span className="error">{errors.memo}</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label className="label">
                  Address1 <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="address1"
                  autoComplete="off"
                  name="address1"
                  className="inputField"
                  onChange={(e) => {
                    e.preventDefault();
                    // this.setState({ isBlocking: true });
                    this.props.handleChangeAddress1(e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.address1.length > 0 && (
                  <span className="error">{errors.address1}</span>
                )}
              </div>
              <div className="col-6">
                <label className="label">Address2</label>
                <input
                  type="name"
                  id="address2"
                  autoComplete="off"
                  name="address2"
                  className="inputField"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeAddress2(e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.address2.length > 0 && (
                  <span className="error">{errors.address2}</span>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <label>
                  City <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="name"
                  id="city"
                  autoComplete="off"
                  name="city"
                  className="inputField"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeCity(e.target.value);
                  }}
                />
                {errors.city.length > 0 && (
                  <span className="error">{errors.city}</span>
                )}
              </div>
              <div className="col-4">
                <label>
                  State <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="name"
                  id="state"
                  autoComplete="off"
                  name="state"
                  className="inputField"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeState(e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.state.length > 0 && (
                  <span className="error">{errors.state}</span>
                )}
              </div>
              <div className="col-4">
                <label>
                  Zip Code <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  id="zip5"
                  autoComplete="off"
                  name="zip5"
                  className="inputField"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeZipCode(e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="note" className="label">
                  Document
                </label>
                {attachment ? (
                  <div>
                    <DocumentImage documentURL={attachment} />
                  </div>
                ) : null}
                <label
                  style={{
                    width: "115px",
                    marginTop: "0.5rem",
                    marginBottom: "1.5rem",
                    background: "rgba(204,204,254,0.32)",
                    border: "1px solid #7b7b7b",
                    textAlign: "center",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      e.preventDefault();
                      onChangeDocumentFileHandler(e);
                    }}
                    className="hidden"
                    style={{ display: "none" }}
                  />
                  {scheduleLoading ? "Loading..." : "Select file"}
                </label>
              </div>
            </div>
            <div className="row">
              <div className={"col-6"} />
              <div className={"col-6"} style={{ paddingTop: "8px" }}>
                <ButtonComponent
                  onClick={(e) => {
                    e.preventDefault();
                    const checkData = {
                      bankName: destinationName,
                      amount: amount,
                      payTo: payTo,
                      memo: review,
                      clientId: clientId,
                      attachment: attachment,
                      description: description,
                      address: {
                        address1: address1,
                        address2: address2,
                        city: city,
                        state: state,
                        zip5: zip5,
                      },
                    };
                    if (!this.state.clicked) {
                      this.setState({
                        clicked: true,
                      });
                      this.props.printManualCheck(
                        checkData,
                        accountNumber,
                        routingNumber,
                        this.props
                      );
                      setTimeout(() => {
                        this.setState({
                          clicked: false,
                        });
                      }, 3000);
                    }
                  }}
                  value={manualCheck}
                  loading={loading}
                  buttonName="Print"
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*check print details*/}
        <Dialog
          id="viewCheckDetails"
          maxWidth="lg"
          open={this.state.openDialogue}
          onClose={handleCloseDetailDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDetailDialogue}
          >
            Check Print Details
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              borderBottom: "none",
              // marginLeft: '1rem'
            }}
          >
            <DialogContentText>
              <span className="row span-row">
                <span className="col-4">
                  <label htmlFor="caseId" className="columnHeading">
                    Bank Name
                  </label>
                  <label className="data">
                    {checkPrintData && checkPrintData.bankName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" className="columnHeading">
                    Check Number
                  </label>
                  <label className="data">
                    {checkPrintData && checkPrintData.checkNo}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" className="columnHeading">
                    Check Type
                  </label>
                  <label className="data">
                    {checkPrintData &&
                      checkPrintData.checkType &&
                      checkPrintData.checkType.replace("_", " ")}
                  </label>
                </span>
              </span>
              <span className="row span-row">
                <span className="col-4">
                  <label htmlFor="caseId" className="columnHeading">
                    Amount
                  </label>
                  <label className="data">
                    {checkPrintData &&
                      formatter.format(checkPrintData.amount / 100)}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" className="columnHeading">
                    Date
                  </label>
                  <label className="data">
                    {checkPrintData && getDate(checkPrintData.createdDate)}
                  </label>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
          {checkPrintData && checkPrintData.transaction ? (
            <>
              <DialogTitleComponent
                id="draggable-dialog-title"
                // onClose={handleCloseDetailDialogue}
              >
                Transaction Details
              </DialogTitleComponent>

              <DialogContent
                dividers
                style={{
                  background: "white",
                  textAlign: "left",
                  paddingBottom: "35px",
                }}
              >
                <DialogContentText>
                  <span className="row span-row">
                    <span className="col-4">
                      <label htmlFor="firstName" className="columnHeading">
                        Client Name
                      </label>
                      <label className="data">
                        {checkPrintData &&
                          checkPrintData.transaction.Client.firstName +
                            " " +
                            checkPrintData.transaction.Client.lastName}
                      </label>
                    </span>
                    <span className="col-4">
                      <label htmlFor="firstName" className="columnHeading">
                        Vendor Name
                      </label>
                      <label className="data">
                        {checkPrintData &&
                        checkPrintData.transaction.vendorAccount.vendorName
                          ? checkPrintData.transaction.vendorAccount.vendorName
                          : "-"}
                      </label>
                    </span>
                    <span className="col-4">
                      <label htmlFor="caseId" className="columnHeading">
                        Transaction Medium
                      </label>
                      <label className="data">
                        {checkPrintData &&
                          checkPrintData.transaction.transactionMedium &&
                          checkPrintData.transaction.transactionMedium.replace(
                            "_",
                            " "
                          )}
                      </label>
                    </span>
                  </span>
                  <span className="row span-row">
                    <span className="col-4">
                      <label htmlFor="firstName" className="columnHeading">
                        Transaction Type
                      </label>
                      <label className="data">
                        {checkPrintData &&
                          checkPrintData.transaction.transactionType}
                      </label>
                    </span>
                    <span className="col-4">
                      <label htmlFor="firstName" className="columnHeading">
                        Transaction Date
                      </label>
                      <label className="data">
                        {checkPrintData &&
                          new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }).format(
                            checkPrintData.transaction.transactionTimestamp
                          )}
                      </label>
                    </span>
                  </span>
                </DialogContentText>
              </DialogContent>
            </>
          ) : null}
        </Dialog>

        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(
                        config.configFilters.checkPrintFilter
                      );
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.checkPrintState.openFailureSnackbar}
          autoHideDuration={3000}
          onClose={this.props.handleClosePrintSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleClosePrintSnackBar}
            variant="error"
            message={this.props.state.checkPrintState.errorMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.checkPrintState.openSuccessSnackbar}
          autoHideDuration={3000}
          onClose={this.props.handleClosePrintSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleClosePrintSnackBar}
            variant="success"
            message={this.props.state.checkPrintState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.checkPrintState.openCheckPrintSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleClosePrintSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleClosePrintSnackBar}
            variant="error"
            message={this.props.state.checkPrintState.errorMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openErrorSnackBar}
          autoHideDuration={5000}
          onClose={handleCloseErrorSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={handleCloseErrorSnackBar}
            variant="error"
            message="Please add Everfund bank account first!"
          />
        </Snackbar>
        {/* upload doc error snackbar */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openScheduleTransactionSnackBar}
          autoHideDuration={5000}
          onClose={this.props.closeScheduleTransactionSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeScheduleTransactionSnackBar}
            variant="error"
            message={errorMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    margin: "0 0px 1rem",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "4px",
    width: "180px",
    height: "40px",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    // position: "fixed",
    // width: "100%",
    // background: "white",
    // zIndex: 1,
    // right: "2.5%",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    // paddingLeft:'2.3rem',
    width: "270px",
    // heigth: '26px'
  },
};

export default CheckPrintScreen;
