import { connect } from 'react-redux';
import IndividualContactFilesScreen from "../../screens/IndividualContactFilesScreen";
import {
    addContactFileFailure,
    addContactFileSuccess,
    contactRequestMade, fetchContactFileFailure,
    fetchContactFileSuccess,
    handleChangeContactFiles,
    setContactFileToEdit, updateContactFileFailure, updateContactFileSuccess
} from "../../actions/ContactAction";
import {ContactApi, DynamicActionAPI} from "../../api";
import {scheduleTransactionRequestMade} from "../../actions";
import {handleShowFileUploadErrorMessage, uploadDocFileFailure, uploadDocFileSuccess} from "../../actions/ScheduleTransactionAction";
import {closeScheduleTransactionSnackBar} from '../../actions/ScheduleTransactionAction';

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return{
        //contact files
        handleChangeContactFile: (id, value) => {dispatch(handleChangeContactFiles(id, value))},
        setContactFileToEdit: (files) => {dispatch(setContactFileToEdit(files))},
        fetchContactFilesList: (contactId, props) =>{
            dispatch(contactRequestMade(true));
            ContactApi.fetchContactFiles(contactId,(response, error) => {
                if (response) {
                    dispatch(fetchContactFileSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchContactFileFailure(error.msg))
                    } else {
                        dispatch(fetchContactFileFailure(error));
                    }
                }
            })
        },
        addContactFiles: (contactFiles, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.addContactFiles(contactFiles, (response, error) => {
                if (response) {
                    dispatch(addContactFileSuccess(response));
                    props.fetchContactFilesList(contactFiles.refId);
                } else {
                    if (error.msg) {
                        dispatch(addContactFileFailure(error.msg));
                    } else {
                        dispatch(addContactFileFailure(error));
                    }
                }
            });
        },
        updateContactFiles: (contactFiles, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.editContactFiles(contactFiles, (response, error) => {
                if (response) {
                    dispatch(updateContactFileSuccess(response));
                    props.fetchContactFilesList(contactFiles.refId);
                } else {
                    if (error.msg) {
                        dispatch(updateContactFileFailure(error.msg));
                    } else {
                        dispatch(updateContactFileFailure(error));
                    }
                }
            });
        },
        uploadDocumentFile: (selectedFiles, props) => {
            dispatch(scheduleTransactionRequestMade(true));
            let formdata = new FormData();
            formdata.append("doc", selectedFiles.document);
            formdata.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadDocumentFile(formdata, (response, error) => {
                if (response) {
                    dispatch(uploadDocFileSuccess(response))
                    props.handleChangeContactFile("documentUrl", response.fileUrl)
                } else {
                    if (error.msg) {
                        dispatch(uploadDocFileFailure(error.msg));
                    } else {
                        dispatch(uploadDocFileFailure(error));
                    }
                }
            });
        },
        uploadImageFile: (selectedFiles, props) => {
            dispatch(scheduleTransactionRequestMade(true));
            let formData = new FormData();
            formData.append("image", selectedFiles.image);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadImageFile(formData, (response, error) => {
                if (response) {
                    dispatch(uploadDocFileSuccess(response))
                    props.handleChangeContactFile("documentUrl", response.fileUrl)
                } else {
                    if (error.msg) {
                        dispatch(uploadDocFileFailure(error.msg));
                    } else {
                        dispatch(uploadDocFileFailure(error));
                    }
                }
            });
        },
        handleShowFileUploadErrorMessage: error => {
            dispatch(handleShowFileUploadErrorMessage(error))
        },
        closeScheduleTransactionSnackBar: () => {
            dispatch(closeScheduleTransactionSnackBar());
        },
    }
};

export const IndividualContactFileContainer = connect(mapStateToProps, mapDispatchToProps)(IndividualContactFilesScreen);