import {
  Button,
  CircularProgress,
  DialogActions,
  MenuList,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
} from "@material-ui/core";
import { UserDeleteOutlined } from "@ant-design/icons";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { Component } from "react";
import MySnackbarContentWrapper from "../components/Snackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import Popover from "@material-ui/core/Popover";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HomeIcon from "@material-ui/icons/Home";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AddIcon from "@material-ui/icons/Add";
import { LocalDb } from "../api";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { DateRangeIcon } from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import { Checkbox, Select, Switch } from "antd";
import DialogTitleComponent from "./DialogTitleComponent";
import {
  convertTimeStampDateToLocalDate,
  getFormattedDateValue,
} from "../utils/DateConverter";
import ClientPaymentSettingScreen from "./ClientPaymentSettingScreen";
import EligibilityCalculationComponent from "../components/EligibilityCalculationComponent";
import Helpers from "../components/Helper";
import OpenContactFields from "../components/DynamicContactField";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import ActiveStatusPopover from "./ActiveStatusPopover";
import {
  getAllUserStatus,
  getRangeType,
  showGreaterThanSign,
} from "./TransactionHelper";

const { Option } = Select;

class ClientScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openActionMenu: false,
      popoverElement: null,
      page: 0,
      rowsPerPage: 10,
      sortType: "asc",
      date: new Date(),
      openDeleteClientDialogue: false,
      openClientDetailsDialogue: false,
      openDialogueForSetPassword: false,
      openDialogueForUpdateProfile: false,
      openDialogueToHoldTransaction: false,
      openDialogueToUnHoldTransaction: false,
      openDialogueToDischargeTransaction: false,
      openDialogueToUnDischargeTransaction: false,
      openDeleteContactDialogue: false,
      openModalForPaymentSetting: false,
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,
      openModalForEligibilityCalculation: false,
      client: {},
      selectedFile: [],
      configName: "",
      openDialogueToAddClients: false,
      openDialogueToUpdateClients: false,
      contacts: [],
      editContact: [],
      updatedContact: [],
      id: "",
      setValue: "",
      setInitialValue: false,
      value:
        this.props.state.registerCaseState &&
        this.props.state.registerCaseState.clientData,
      touched: {},
      selectedClientName: "",
      selectedClientId: "",
      selectedClientData: {},
      //for validation
      errors: {
        clientId: "",
        ssn: "",
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        maritalStatus: "",
      },
      // sorting table column
      order: "asc",
      orderBy: "firstName",
      // sortedClientList: [],
      openRangeBar: false,
      isGreaterThan: 0,
      // client delete for corporate user
      openDeleteConfirmation: false,
    };
  }
  componentDidMount() {
    const accountType = LocalDb.getSessions().loginResponse.loginUser;
    this.props.clearClientList();
    this.props.clearFilterData();
    if (accountType.corporate) {
      this.props.fetchCorporateClientList(accountType.corporate.corporateId);
      this.props.filterClient("", "", "", "", "", 0, false, false, true, "");
    } else {
      this.props.fetchClientWithBalance();
    }
  }
  componentWillUnmount() {
    this.props.handleSnackBarClose();
  }

  onChangeFileHandler = (eventFileHandler, clientId) => {
    let file = eventFileHandler.target.files[0];
    let type = file.type;

    let singleFile = {
      file: file,
      type: type,
    };
    {
      this.setState(
        {
          isBlocking: true,
          selectedFile: [...this.state.selectedFile, singleFile],
        },
        () => {
          this.props.updateProfilePicture(singleFile, clientId, this.props);
        }
      );
    }
  };
  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };

  render() {
    //handle change for validation
    const getAccountType = LocalDb.getSessions().loginResponse.loginUser;
    const registerCaseState =
      this.props.state && this.props.state.registerCaseState;
    var clientRegisterData = this.props.state.registerCaseState.clientData;
    const registerClientData =
      registerCaseState && registerCaseState.clientData;
    const clientState = this.props.state.clientState;
    const clientData = clientState && clientState.clientData;
    const openSetNotificationModal =
      clientState && clientState.openSetNotificationModal;
    const upcomingAlert = clientState && clientState.upcomingAlert;
    const holidayAlert = clientState && clientState.holidayAlert;
    const emailAlertId = clientState && clientState.emailAlertId;
    const clientDataValue =
      registerClientData.firstName !== "" &&
      registerClientData.lastName !== "" &&
      registerClientData.gender !== -1 &&
      registerClientData.maritalStatus !== -1 &&
      registerClientData.dob !== null;

    const openSnackBar =
      this.props.state.transactionState &&
      this.props.state.transactionState.openClientTransactionSnackBar;

    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : "CORPORATE_USER"
      : null;

    // pagination
    const nextUrl = clientState && clientState.nextUrl;
    // const clientList = clientState && clientState.client;
    const clientWithBalance = clientState && clientState.clientWithBalance;
    const email = clientState && clientState.email;
    const clientRes = clientState && clientState.clientRes;

    const profile = clientState && clientState.profilePicUrl;
    const caseDetails = clientState && clientState.caseDetails;

    const eligibilityData = clientState && clientState.eligibilityData;
    const workflowId = clientState && clientState.workflowId;
    const actionId = clientState && clientState.actionId;
    const requestData = {
      clientId: this.state.client.clientId,
      caseId: eligibilityData.caseId,
      eligibilitySetting: {
        familyType: eligibilityData.familyType,
        memberNo: eligibilityData.memberNo,
      },
    };

    // client filter
    const name = clientState && clientState.name;
    const clientNo = clientState && clientState.clientNo;
    const caseCount = clientState && clientState.caseCount;
    const date = clientState && clientState.date;
    const balance = clientState && clientState.balance;
    const term = clientState && clientState.term;
    const configlist = clientState && clientState.configlist;
    const userstatus = clientState && clientState.userstatus;
    const fetchAll = clientState && clientState.fetchAll;

    // const getCorporateClientId = () => {
    // 	alert('this is a alert');
    // };
    const value =
      eligibilityData.caseId !== "" &&
      eligibilityData.familyType !== "" &&
      eligibilityData.memberNo !== "";

    // const sortedClientList = clientList
    // 	? clientList.sort((a, b) => {
    // 			const data = this.state.sortType === 'asc' ? -1 : 1;
    // 			return data * a.createdAt.localeCompare(b.createdAt);
    // 	  })
    // 	: null;
    const filterStatus =
      name !== "" ||
      clientNo !== "" ||
      caseCount !== "" ||
      date !== null ||
      balance !== "";
    const configStatus = this.state.configName !== "" && filterStatus;

    const handleChangePage = (event, newPage) => {
      // this.props.fetchPaginatedClientList(nextUrl);
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    //ELIGIBILITY CALCULATION
    const handleOpenEligibilityCalculationModal = () => {
      this.props.clearEligibilitySetting();
      this.setState({
        openModalForEligibilityCalculation: true,
      });
    };
    const handleCloseEligibilityCalculationModal = () => {
      this.setState({
        openModalForEligibilityCalculation: false,
      });
    };
    const calculateEligibility = (eligibilityData) => {
      this.props.calculateEligibility(eligibilityData);
    };
    const handleChangeEligibilityData = (id, value) => {
      this.props.handleChangeEligibilityData(id, value);
    };
    const handleChangeFamilyType = (familyType) => {
      this.props.handleChangeFamilyType(familyType);
    };
    const handleChangeMembers = (members) => {
      this.props.handleChangeMembers(members);
    };
    const handleChangeWorkFlow = (workflowId) => {
      this.props.handleChangeWorkflow(workflowId);
    };
    const handleChangeAction = (actionId) => {
      this.props.handleChangeAction(actionId);
    };

    //client payment setting

    const handleOpenPaymentSettingModal = () => {
      this.setState({
        openModalForPaymentSetting: true,
      });
    };
    const handleClosePaymentSettingModal = () => {
      this.setState({
        openModalForPaymentSetting: false,
      });
    };
    //client transaction discharge and indischarge handle
    const handleOpenDialogueToDischargeTransaction = () => {
      this.setState({
        openDialogueToDischargeTransaction: true,
      });
    };

    const handleCloseDialogueToDischargeTransaction = () => {
      this.setState({
        openDialogueToDischargeTransaction: false,
      });
    };
    const handleOpenDialogueToUnDischargeTransaction = () => {
      this.setState({
        openDialogueToUnDischargeTransaction: true,
      });
    };

    const handleCloseDialogueToUnDischargeTransaction = () => {
      this.setState({
        openDialogueToUnDischargeTransaction: false,
      });
    };

    //client transaction hold and un-hold handle
    const handleOpenDialogueToHoldClient = () => {
      this.setState({
        openDialogueToHoldTransaction: true,
      });
    };
    const handleCloseDialogueToHoldClient = () => {
      this.setState({
        openDialogueToHoldTransaction: false,
      });
    };

    const handleOpenDialogueToUnHoldClient = () => {
      this.setState({
        openDialogueToUnHoldTransaction: true,
      });
    };
    const handleCloseDialogueToUnHoldClient = () => {
      this.setState({
        openDialogueToUnHoldTransaction: false,
      });
    };

    const handleCloseDialogueForClientDetails = () => {
      this.setState({
        openClientDetailsDialogue: false,
      });
    };

    const handleOpenDialogueForClientDetails = () => {
      this.setState({
        openClientDetailsDialogue: true,
        // workflowId: workflowId
      });
    };
    const handleCloseDialogueForUpdateProfile = () => {
      this.setState({
        openDialogueForUpdateProfile: false,
      });
    };

    const handleOpenDialogueForUpdateProfile = () => {
      this.setState({
        openDialogueForUpdateProfile: true,
      });
    };
    // const handleOkUpdateClientDialogue = () => {
    // 	this.props.editClient(
    // 		this.props.state.clientState.clientData,
    // 		this.props
    // 	);
    // 	this.setState({
    // 		openUpdateClientDialogue: false,
    // 		clientId: '',
    // 	});
    // };

    const handleCloseDialogueForDeleteClient = () => {
      this.setState({
        openDeleteClientDialogue: false,
      });
    };

    const handleOpenDialogueForDeleteClient = () => {
      this.setState({
        openDeleteClientDialogue: true,
      });
    };

    const handleOkDeleteClientDialogue = () => {
      this.props.deleteClient(this.state.client.clientId, this.props);
      this.setState({
        openDeleteClientDialogue: false,
        clientId: "",
      });
    };

    //reset password
    const handleOpenSetPasswordDialogue = () => {
      this.setState({
        openDialogueForSetPassword: true,
        // user: user
      });
    };
    const handleCloseSetPasswordDialogue = () => {
      this.setState({ openDialogueForSetPassword: false });
    };
    //add clients
    const handleOpenDialogueToAddClients = () => {
      this.setState({
        // openDialogueToAddClients: true,
        contacts: [],
        errors: {
          clientId: "",
          ssn: "",
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          maritalStatus: "",
        },
      });
      this.props.clearClientFormData();
    };
    const handleCloseDialogueToAddClients = () => {
      this.setState({
        openDialogueToAddClients: false,
      });
    };

    //add contacts
    const showInputForContactField = () => {
      let a = createContactObject();
      this.setState({
        contacts: [...this.state.contacts, a],
      });
    };
    const createContactObject = () => {
      return {
        contactId: String(Math.random()),
        name: "",
        emailPhone: "",
        contactType: "UNKNOWN_CONTACT_TYPE",
        phone: "",
        dob: null,
      };
    };
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    const filterContactsData = () => {
      let contactForm = [];
      this.state.contacts.map((single) => {
        const data = {
          name: single.name,
          emailPhone: single.emailPhone,
          contactType: single.contactType,
          phone: single.phone,
          dob: single.dob,
        };
        return contactForm.push(data);
      });
      this.props.handleChangeClientInRegisterCase("contacts", contactForm);
    };
    const filterEditContactData = () => {
      let resultA = this.state.updatedContact.filter((elm) =>
        this.state.contacts
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
      );
      let resultB = this.state.contacts
        .filter(
          (elm) =>
            !this.state.updatedContact
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        )
        .map((single) => {
          return {
            name: single.name,
            emailPhone: single.emailPhone,
            contactType: single.contactType,
            phone: single.phone,
            dob: single.dob,
          };
        });
      let contactForm = [...resultA, ...resultB];
      this.props.handleChangeClient("contacts", contactForm);
    };

    //to delete field
    const closeContactsFieldSection = (key) => {
      var removedActionData = this.state.contacts.filter(
        (single) => single.contactId !== key
      );
      this.setState(
        {
          contacts: removedActionData,
        },
        () => {
          filterContactsData();
        }
      );
    };
    const handleOpenDeleteContactsDialogue = (contactId) => {
      this.setState({
        openDeleteContactDialogue: true,
        contactId: contactId,
      });
    };
    const handleCloseDeleteContactsDialogue = () => {
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };
    const handleCloseDeleteClientInCorporate = () => {
      this.setState({
        openDeleteConfirmation: false,
        client: "",
      });
    };
    const handleOkDeleteContactsDialogue = () => {
      closeContactsFieldSection(this.state.contactId);
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };

    const filterContactData = () => {
      let contactForm = [];
      this.state.contacts.map((single) => {
        return contactForm.push(single.value);
      });
      this.props.handleChangeClientInRegisterCase("contacts", contactForm);
    };

    //to delete field
    const closeContactFieldSection = (key) => {
      var removedActionData = this.state.contacts.filter(
        (single) => single.id !== key
      );
      this.setState(
        {
          contacts: removedActionData,
        },
        () => {
          filterContactData();
        }
      );
    };

    //edit clients
    const handleOpenDialogueToEditClient = () => {
      this.setState({
        openDialogueToUpdateClients: true,
      });
    };
    const handleCloseDialogueToEditClient = () => {
      this.setState({
        openDialogueToUpdateClients: false,
      });
    };

    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
      });
    };
    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        name: name,
        clientNo: clientNo,
        caseCount: caseCount,
        date: date === null ? 0 : date,
        balance: balance,
        type: 1,
        compareType: this.state.isGreaterThan,

        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };
      this.setState({ isGreaterThan: "0" });
      this.props.fetchClientWithBalance();
      this.props.clearFilterData();
      this.props.saveInConfig(data);
    };

    // const handleClickOpenDeleteEditContactDialogue = contactId => {
    // 	this.setState({
    // 		openDeleteEditContactDialogue: true,
    // 		contactId: contactId,
    // 	});
    // };
    const handleCloseDeleteEditContactDialogue = () => {
      this.setState({
        openDeleteEditContactDialogue: false,
        contactId: "",
      });
    };
    const handleOkDeleteEditContactDialogue = () => {
      closeContactFieldSection(this.state.contactId);
      this.setState({
        openDeleteEditContactDialogue: false,
        contactId: "",
      });
    };

    const handleChangeClientFee = (fee) => {
      this.props.handleChangeClientFee(fee);
    };
    const handleChangeClientMonthlyFee = (monthlyFee) => {
      this.props.handleChangeClientMonthlyFeeValue(monthlyFee);
    };
    const handleChangeClientAnnualFee = (annualFee) => {
      this.props.handleChangeClientAnnualFee(annualFee);
    };
    const handleChangeClientSpendDown = (spendDownThreshold) => {
      this.props.handleChangeClientSpendDown(spendDownThreshold);
    };
    const handleChangeAdmissionDate = (admissionDate) => {
      this.props.handleChangeAdmissionDate(admissionDate);
    };
    const handleChangeClientAchDelayThreshold = (achDelayThreshold) => {
      this.props.handleChangeClientAchDelayThreshold(achDelayThreshold);
    };
    const handleChangeClientInstallment = (installmentMonth) => {
      this.props.handleChangeClientInstallment(installmentMonth);
    };

    const addClientPayment = (paymentData) => {
      this.props.addClientPayment(paymentData, this.props);
    };

    const { errors } = this.state;
    const isCorporate = userType === "CORPORATE_USER";

    function descendingComparator(a, b, orderBy) {
      if (orderBy === "balance") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "cases") {
        let first = b.client[orderBy] ? b.client[orderBy].length : 0;
        let second = a.client[orderBy] ? a.client[orderBy].length : 0;
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return null;
      } else if (orderBy === "clientNo") {
        return a.client[orderBy]
          .toString()
          .localeCompare(b.client[orderBy].toString(), "eng", {
            numeric: true,
          });
      } else {
        if (b.client[orderBy] < a.client[orderBy]) {
          return -1;
        }
        if (b.client[orderBy] > a.client[orderBy]) {
          return 1;
        }
        return null;
      }
    }

    function getComparator(clientList, order, orderBy) {
      if (order === "asc") {
        const list =
          clientList &&
          clientList.length > 0 &&
          clientList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          clientList &&
          clientList.length > 0 &&
          clientList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }

    const sortedClientList = getComparator(
      clientWithBalance,
      this.state.order,
      this.state.orderBy
    );

    const handleUseConfigFilter = (config) => {
      if (config.clientName) {
        this.props.handleChangeFilterName(config.clientName);
      }
      if (config.clientNo) {
        this.props.handleChangeFilterClientno(config.clientNo);
      }
      if (config.caseCount) {
        this.props.handleChangeFilterCaseCount(config.caseCount);
      }
      if (config.registeredDate !== "0") {
        this.props.handleChangeFilterDate(config.registeredDate);
      }
      if (config.amount) {
        this.props.handleChangeFilterBalance(config.amount);
      }
      if (config.comparator) {
        this.setState({
          isGreaterThan: getRangeType(config.comparator),
        });
      }
      this.props.filterClient(
        config.clientName ? config.clientName : "",
        config.clientNo ? config.clientNo : "",
        config.caseCount ? config.caseCount : "",
        config.registeredDate ? config.registeredDate : 0,
        config.amount ? config.amount : "",
        config.comparator ? getRangeType(config.comparator) : "0",
        userstatus === 2 ? true : false,
        userstatus === 3 ? true : false,
        fetchAll,
        term
      );
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Client" />
        {this.props.state.clientState.loading ? (
          <div
            className={
              this.props.state.clientState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        {this.props.state.clientState.success === true ||
        this.props.state.clientState.successPayment === true ||
        this.props.state.registerCaseState.success === true ||
        this.props.state.clientState.resetSuccess === true
          ? this.setState(
              {
                // openDialogueForUpdateProfile: false,
                openDialogueToAddClients: false,
                openDialogueToUpdateClients: false,
                openDialogueForSetPassword: false,
                openModalForPaymentSetting: false,
              },
              () => {
                if (this.state.setValue === "add") {
                  this.props.setAddClientSuccessToFalse();
                } else {
                  this.props.setUploadToFalse();
                  this.props.setPasswordSuccessToFalse();
                }
              }
            )
          : null}

        {this.state.setInitialValue === false &&
        clientData &&
        clientData.editContact &&
        clientData.editContact.length > 0
          ? this.setState({
              editContact: clientData.contacts,
              setInitialValue: true,
            })
          : null}

        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          {userType && userType === "CORPORATE_USER" ? (
            ""
          ) : (
            <Link color="inherit" to="/dashboard" style={style.link}>
              <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
              Home
            </Link>
          )}
          <Typography color="inherit" className="link">
            Client
          </Typography>
        </Breadcrumbs>

        <div style={style.container}>
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            {/*<div>*/}
            {/*  <button*/}
            {/*    style={style.settingButton}*/}
            {/*    onClick={(e) => {*/}
            {/*      e.preventDefault();*/}
            {/*      this.setState({*/}
            {/*        sortType: this.state.sortType === "asc" ? "dsc" : "asc",*/}
            {/*      });*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <img*/}
            {/*      alt={"setting"}*/}
            {/*      src={"/assets/images/sort.png"}*/}
            {/*      style={{ width: "18px", height: "18px" }}*/}
            {/*    />*/}
            {/*  </button>*/}
            {/*</div>*/}
            <div>
              {isCorporate ? (
                <button
                  className="filterButton"
                  onClick={() => {
                    // this.props.fetchCorporateClientList(
                    //   getAccountType.corporate.corporateId
                    // );
                    this.props.filterClient(
                      "",
                      "",
                      "",
                      "",
                      "",
                      0,
                      false,
                      false,
                      true,
                      ""
                    );
                  }}
                >
                  <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
                </button>
              ) : // <button
              //   style={style.addButton}
              //   onClick={(e) => {
              //     e.preventDefault();
              //     this.props.handleChangeToOpenClientFormModal(true)
              //     handleOpenDialogueToAddClients();
              //     this.setState({
              //       selectedClientData: {},
              //       selectedClientId: "",
              //     });
              //   }}
              // >
              //   <AddIcon />
              //   Add Client
              // </button>
              userType === "EVERFUND_USER" &&
                userInfoFromDB.loginResponse.loginUser.user &&
                userInfoFromDB.loginResponse.loginUser.user.userRole !==
                  "AGENT" ? (
                <button
                  style={style.addButton}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.handleChangeToOpenClientFormModal(true);
                    handleOpenDialogueToAddClients();
                  }}
                >
                  <AddIcon />
                  Add Client
                </button>
              ) : null}
            </div>
            <div className="search">
              <div style={style.searchIcon}>
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={style.inputBase}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value === "" || e.target.value === " ") {
                    if (
                      LocalDb.getSessions().loginResponse.loginUser.corporate
                    ) {
                      this.props.handleChangeFilterTerm(e.target.value);
                      this.props.filterClient(
                        "",
                        "",
                        "",
                        "",
                        "",
                        0,
                        false,
                        false,
                        true,
                        ""
                      );
                    } else {
                      this.props.fetchClientWithBalance();
                      this.props.handleChangeFilterTerm(e.target.value);
                    }
                  } else {
                    this.props.filterClient(
                      name,
                      clientNo,
                      caseCount,
                      date,
                      balance,
                      this.state.isGreaterThan,
                      userstatus === 2 ? true : false,
                      userstatus === 3 ? true : false,
                      fetchAll,
                      e.target.value
                    );

                    this.props.handleChangeFilterTerm(e.target.value);
                  }
                }}
              />
            </div>
          </div>

          {/* <div style={{paddingTop: "70px"}}> */}
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="a dense table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell style={style.tableHeader}>#</TableCell>
                    <TableCell
                      style={style.tableFirstHeader}
                      key="clientNo"
                      className="override-mui-table"
                      sortDirection={
                        this.state.orderBy === "clientNo"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "clientNo"}
                        direction={
                          this.state.orderBy === "clientNo"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("clientNo")}
                      >
                        Client Id
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="firstName"
                      className="override-mui-table"
                      sortDirection={
                        this.state.orderBy === "firstName"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "firstName"}
                        direction={
                          this.state.orderBy === "firstName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("firstName")}
                      >
                        Client
                      </TableSortLabel>
                    </TableCell>

                    <TableCell
                      style={style.tableFirstHeader}
                      key="balance"
                      className="override-mui-table"
                      sortDirection={
                        this.state.orderBy === "balance"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "balance"}
                        direction={
                          this.state.orderBy === "balance"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("balance")}
                      >
                        Balance
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#8BC83F",
                        fontWeight: "bold",
                        fontSize: "15px",
                        textAlign: "left",
                        height: "3rem",
                        paddingLeft: "2rem",
                      }}
                      key="cases"
                      className="override-mui-table"
                      sortDirection={
                        this.state.orderBy === "cases"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "cases"}
                        direction={
                          this.state.orderBy === "cases"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("cases")}
                      >
                        Case Counts
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="createdAt"
                      className="override-mui-table"
                      sortDirection={
                        this.state.orderBy === "createdAt"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "createdAt"}
                        direction={
                          this.state.orderBy === "createdAt"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("createdAt")}
                      >
                        Register Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={style.tableHeader}
                      key="status"
                      className="override-mui-table"
                    >
                      Status
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    className="sticky-search-bar"
                    style={{ top: "42px" }}
                  >
                    <TableCell style={style.tableHeader} />
                    <TableCell style={style.tableHeader}>
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={clientNo}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeFilterClientno(
                              e.target.value
                            );

                            this.setState({
                              page: 0,
                            });
                            this.props.filterClient(
                              name,
                              e.target.value,
                              caseCount,
                              date,
                              balance,
                              this.state.isGreaterThan,
                              userstatus === 2 ? true : false,
                              userstatus === 3 ? true : false,
                              fetchAll,
                              term
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={style.tableHeader}>
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={name}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeFilterName(e.target.value);
                            this.setState({
                              page: 0,
                            });
                            this.props.filterClient(
                              e.target.value,
                              clientNo,
                              caseCount,
                              date,
                              balance,
                              this.state.isGreaterThan,
                              userstatus === 2 ? true : false,
                              userstatus === 3 ? true : false,
                              fetchAll,
                              term
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={style.tableHeader}>
                      <div
                        className="sm-search"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="show-range-sign">
                          {showGreaterThanSign(this.state.isGreaterThan)}
                        </span>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={balance}
                          className="show-range"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeFilterBalance(
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.filterClient(
                              name,
                              clientNo,
                              caseCount,
                              date,
                              e.target.value,
                              this.state.isGreaterThan,
                              userstatus === 2 ? true : false,
                              userstatus === 3 ? true : false,
                              fetchAll,
                              term
                            );
                          }}
                        />
                        <ExpandMoreIcon
                          id="Popover1"
                          style={{ cursor: "pointer" }}
                          className="expand-more-icon"
                          onClick={(e) => {
                            this.setState({
                              popoverElement: e.currentTarget,
                              // client: client.client,
                              openRangeBar: true,
                            });
                          }}
                        />
                        <Popover
                          open={this.state.openRangeBar}
                          anchorEl={this.state.popoverElement}
                          onClose={(e) => {
                            e.preventDefault();
                            this.setState({
                              openRangeBar: false,
                            });
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          style={{ boxShadow: "none" }}
                        >
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                  isGreaterThan: "2",
                                  page: 0,
                                });
                                this.props.filterClient(
                                  name,
                                  clientNo,
                                  caseCount,
                                  date,
                                  balance,
                                  "2",
                                  userstatus === 2 ? true : false,
                                  userstatus === 3 ? true : false,
                                  fetchAll,
                                  term
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Lesser than {`(<)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();

                                this.setState({
                                  openRangeBar: false,
                                  isGreaterThan: "1",
                                  page: 0,
                                });
                                this.props.filterClient(
                                  name,
                                  clientNo,
                                  caseCount,
                                  date,
                                  balance,
                                  "1",
                                  userstatus === 2 ? true : false,
                                  userstatus === 3 ? true : false,
                                  fetchAll,
                                  term
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Greater than {`(>)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();

                                this.setState({
                                  openRangeBar: false,
                                  isGreaterThan: "0",
                                  page: 0,
                                });
                                this.props.filterClient(
                                  name,
                                  clientNo,
                                  caseCount,
                                  date,
                                  balance,
                                  "0",
                                  userstatus === 2 ? true : false,
                                  userstatus === 3 ? true : false,
                                  fetchAll,
                                  term
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>None</span>
                            </span>
                          </MenuList>
                        </Popover>
                      </div>
                    </TableCell>
                    <TableCell style={style.tableHeader}>
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={caseCount}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeFilterCaseCount(
                              e.target.value
                            );

                            this.setState({
                              page: 0,
                            });
                            this.props.filterClient(
                              name,
                              clientNo,
                              e.target.value,
                              date,
                              balance,
                              this.state.isGreaterThan,
                              userstatus === 2 ? true : false,
                              userstatus === 3 ? true : false,
                              fetchAll,
                              term
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell style={style.tableHeader}>
                      {" "}
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={date === null ? "" : date}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeFilterDate(e.target.value);
                            this.setState({
                              page: 0,
                            });
                            this.props.filterClient(
                              name,
                              clientNo,
                              caseCount,
                              e.target.value,
                              balance,
                              this.state.isGreaterThan,
                              userstatus === 2 ? true : false,
                              userstatus === 3 ? true : false,
                              fetchAll,
                              term
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={style.tableHeader}>
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "610px",
                            overflowY: "scroll",
                          }}
                          value={userstatus}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleChangeUserStatus(e);
                            if (e === 4) {
                              this.props.handleChangeFetchAllStatus(true);
                            } else {
                              this.props.handleChangeFetchAllStatus(false);
                            }

                            this.setState({ page: 0 });
                            this.props.filterClient(
                              name,
                              clientNo,
                              caseCount,
                              date,
                              balance,
                              this.state.isGreaterThan,
                              e === 2 ? true : false,
                              e === 3 ? true : false,
                              e === 4 ? true : false,
                              term
                            );
                          }}
                        >
                          {getAllUserStatus &&
                            getAllUserStatus.map((list) => (
                              <Option value={list.id}>{list.status}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableCol">
                      {userInfoFromDB.loginResponse.loginUser.user &&
                      userInfoFromDB.loginResponse.loginUser.user.userRole ===
                        "AGENT" ? (
                        <MoreHorizIcon style={{ display: "none" }} />
                      ) : userType && userType === "CORPORATE_USER" ? (
                        <MoreHorizIcon style={{ display: "none" }} />
                      ) : (
                        <MoreHorizIcon
                          id="Popover1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            this.setState({
                              popoverElement: e.currentTarget,
                              // client: client.client,
                              openConfigMenu: true,
                            });
                          }}
                        />
                      )}
                      <Popover
                        open={this.state.openConfigMenu}
                        anchorEl={this.state.popoverElement}
                        onClose={(e) => {
                          e.preventDefault();
                          this.setState({ openConfigMenu: false });
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        style={{ boxShadow: "none" }}
                      >
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                OpenSaveFilterInConfigmenu: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              Save In Config
                            </span>
                          </span>
                        </MenuList>
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.getConfigList("1");
                              this.setState({
                                OpenViewFilterInConfig: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              View Config
                            </span>
                          </span>
                        </MenuList>
                      </Popover>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableBody>
                {sortedClientList &&
                  sortedClientList.length > 0 &&
                  sortedClientList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((client, index) => {
                      return (
                        <TableBody>
                          <TableRow key={index}>
                            <TableCell>
                              {(this.state.page + 1 - 1) *
                                this.state.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell
                              style={style.tableFirstBody}
                              onClick={(e) => {
                                e.preventDefault();
                                {
                                  userInfoFromDB.loginResponse.loginUser.user &&
                                  userInfoFromDB.loginResponse.loginUser.user
                                    .userRole === "AGENT"
                                    ? this.setState(
                                        {
                                          client: client.client,
                                        },
                                        () => {
                                          this.props.setClientData(
                                            client.client
                                          );
                                          this.props.fetchSingleClient(
                                            client.client.clientId
                                          );
                                          handleOpenDialogueForClientDetails();
                                        }
                                      )
                                    : this.setState(
                                        {
                                          client: client.client,
                                        },
                                        () => {
                                          // localStorage.setItem("clientId",  this.state.client.clientId)
                                          this.props.history.push({
                                            pathname:
                                              "/client/details/" +
                                              this.state.client.clientId,
                                          });
                                          localStorage.setItem(
                                            "clientId",
                                            this.state.client.clientId
                                          );
                                        }
                                      );
                                }
                              }}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {client && client.client.clientNo
                                  ? client.client.clientNo
                                  : client.client.ssn}
                              </span>
                            </TableCell>
                            <TableCell style={style.tableBody}>
                              <Button
                                style={{
                                  textTransform: "none",
                                  fontSize: "15px",
                                  color: "#626262",
                                  padding: 0,
                                  textDecoration: "underline",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    client: client.client,
                                    openActionMenu: false,
                                  });
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    {
                                      userType === "EVERFUND_USER" &&
                                      userInfoFromDB.loginResponse.loginUser
                                        .user &&
                                      userInfoFromDB.loginResponse.loginUser
                                        .user.userRole === "AGENT"
                                        ? this.setState(
                                            {
                                              client: client.client,
                                            },
                                            () => {
                                              this.props.setClientData(
                                                client.client
                                              );
                                              this.props.fetchSingleClient(
                                                client.client.clientId
                                              );
                                              handleOpenDialogueForClientDetails();
                                            }
                                          )
                                        : this.setState(
                                            {
                                              client: client.client,
                                            },
                                            () => {
                                              const accountId =
                                                this.state.client.accountId;
                                              this.props.history.push({
                                                pathname:
                                                  "/client/details/" +
                                                  this.state.client.clientId,
                                                state: { accountId },
                                              });
                                              localStorage.setItem(
                                                "clientId",
                                                this.state.client.clientId
                                              );
                                            }
                                          );
                                    }
                                  }}
                                >
                                  {client.client.firstName}{" "}
                                  {client.client.lastName}{" "}
                                </span>
                              </Button>
                            </TableCell>

                            <TableCell
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                              }}
                            >
                              <div
                                style={{
                                  justifyContent: "left",
                                  borderRadius: "2px",
                                  paddingLeft: "4px",
                                  paddingRight: "4px",
                                  minWidth: "22px",
                                  marginLeft: "48px",
                                  cursor: "auto",
                                }}
                              >
                                {client.balance
                                  ? formatter.format(client.balance / 100)
                                  : "$0.00"}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                              }}
                            >
                              <div
                                style={{
                                  justifyContent: "left",
                                  borderRadius: "2px",
                                  paddingLeft: "4px",
                                  paddingRight: "4px",
                                  minWidth: "22px",
                                  marginLeft: "48px",
                                  cursor: "auto",
                                }}
                                disabled={!client.client.cases}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    client: client.client,
                                  });
                                }}
                              >
                                {client.client.cases
                                  ? client.client.cases.length
                                  : 0}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                              }}
                            >
                              {convertTimeStampDateToLocalDate(
                                client.client.createdAt
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                              }}
                            >
                              {client.client.isEnableTransaction &&
                              client.client.isHold === undefined &&
                              client.client.isDischarged === undefined ? (
                                <ActiveStatusPopover
                                  status={"Active"}
                                  content={
                                    <div>
                                      Active. You can now make transactions.
                                    </div>
                                  }
                                />
                              ) : client.client.isHold ? (
                                <ActiveStatusPopover
                                  status={"On Hold"}
                                  content={
                                    <div>
                                      Inactive. Cannot make any transactions.
                                    </div>
                                  }
                                />
                              ) : (
                                <ActiveStatusPopover
                                  status={"Discharged"}
                                  content={
                                    <div>
                                      Inactive. Cannot make any transactions.
                                    </div>
                                  }
                                />
                              )}
                            </TableCell>

                            <TableCell className="tableCol">
                              {userInfoFromDB.loginResponse.loginUser.user &&
                              userInfoFromDB.loginResponse.loginUser.user
                                .userRole === "AGENT" ? (
                                <MoreHorizIcon style={{ display: "none" }} />
                              ) : userType && userType === "CORPORATE_USER" ? (
                                // <DeleteIcon
                                //   style={{ cursor: "pointer" }}
                                //   onClick={() =>
                                //     this.setState({
                                //       openDeleteConfirmation: true,
                                //       client: client.client,
                                //     })
                                //   }
                                // />
                                ""
                              ) : (
                                <MoreHorizIcon
                                  id="Popover1"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    this.setState(
                                      {
                                        popoverElement: e.currentTarget,
                                        client: client.client,
                                        openActionMenu: true,
                                        errors: {
                                          clientId: "",
                                          ssn: "",
                                          firstName: "",
                                          lastName: "",
                                          dob: "",
                                          gender: "",
                                          maritalStatus: "",
                                        },
                                      },
                                      () => {
                                        this.props.setClientData(client.client);
                                        this.props.clearPaymentValues();
                                        if (client.client.accountId) {
                                          this.props.getAlertInfo(
                                            client.client.accountId
                                          );
                                        }
                                        // this.props.fetchSingleClient(client.clientId)
                                      }
                                    );
                                  }}
                                />
                              )}
                              <Popover
                                open={this.state.openActionMenu}
                                anchorEl={this.state.popoverElement}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.setState({ openActionMenu: false });
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                style={{ boxShadow: "none" }}
                              >
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="/assets/images/popout.png"
                                    alt="detail"
                                    className="iconImage"
                                  />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(
                                        "/client/details/" +
                                          this.state.client.clientId,
                                        "_blank"
                                      );
                                    }}
                                    className="iconImage"
                                  >
                                    <span style={{ marginLeft: "6px" }}>
                                      View Client Detail
                                    </span>
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="/assets/images/view transaction.svg"
                                    alt="transaction"
                                    className="iconImage"
                                  />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.history.push({
                                        pathname:
                                          "/transaction/client/" +
                                          this.state.client.clientId,
                                      });
                                    }}
                                    style={{ marginLeft: "5.5px" }}
                                  >
                                    View Transaction
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <DateRangeIcon />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const accountId =
                                        this.state.client.accountId;
                                      this.props.history.push({
                                        pathname:
                                          "/client/calendar/" +
                                          this.state.client.clientId,
                                        state: { accountId },
                                      });
                                    }}
                                    style={{ marginLeft: "5px" }}
                                  >
                                    View Calendar
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="/assets/images/view files.svg"
                                    alt="transaction"
                                    className="iconImage"
                                  />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // this.props.fetchFileListByClient(this.state.client.clientId, this.props);
                                      this.props.history.push({
                                        pathname:
                                          "/client/filedetails/" +
                                          this.state.client.clientId,
                                      });
                                    }}
                                    style={{ marginLeft: "5.5px" }}
                                  >
                                    View Files
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="/assets/images/calculate.svg"
                                    alt="calculate"
                                    style={{ width: "20px" }}
                                  />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ openActionMenu: false });
                                      handleOpenEligibilityCalculationModal();
                                      this.props.fetchCaseByClient(
                                        this.state.client.clientId
                                      );
                                    }}
                                    style={{ marginLeft: "7.5px" }}
                                  >
                                    Eligibility Calculation
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDialogueForUpdateProfile();
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <img
                                    src="/assets/images/update profile.svg"
                                    alt="profile"
                                    className="iconImage"
                                  />
                                  <span style={{ marginLeft: "5.5px" }}>
                                    Update Profile Picture
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ openActionMenu: false });
                                    this.props.setClientData(client.client);
                                    handleOpenPaymentSettingModal();
                                    this.props.fetchClientPayment(
                                      this.state.client.clientId
                                    );
                                    // this.props.history.push({
                                    //     pathname: "/client/paymentsetting/" + this.state.client.clientId
                                    // });
                                  }}
                                >
                                  <img
                                    src="/assets/images/payment setting.svg"
                                    alt="payment"
                                    className="iconImage"
                                  />
                                  <span style={{ marginLeft: "5.5px" }}>
                                    Payment Setting
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="/assets/images/customer.svg"
                                    alt="customer"
                                    className="iconImage"
                                  />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (this.state.client.isHold) {
                                        handleOpenDialogueToUnHoldClient();
                                      } else {
                                        handleOpenDialogueToHoldClient();
                                      }
                                      this.setState({ openActionMenu: false });
                                    }}
                                    style={{ marginLeft: "5.5px" }}
                                  >
                                    Client On Hold
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <UserDeleteOutlined className="iconImage" />
                                  {/* <img
                                    src="/assets/images/customer.svg"
                                    alt="customer"
                                    className="iconImage"
                                  /> */}
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (this.state.client.isDischarged) {
                                        handleOpenDialogueToUnDischargeTransaction();
                                      } else {
                                        handleOpenDialogueToDischargeTransaction();
                                      }
                                      this.setState({ openActionMenu: false });
                                    }}
                                    style={{ marginLeft: "5.5px" }}
                                  >
                                    Client On Discharge
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenSetPasswordDialogue();
                                    this.props.handleChangeEmail(
                                      "emailPhone",
                                      ""
                                    );
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <img
                                    src="/assets/images/set password.svg"
                                    alt="profile"
                                    className="iconImage"
                                  />
                                  <span style={{ marginLeft: "5.5px" }}>
                                    Set Password
                                  </span>
                                </MenuList>
                                <MenuList
                                  hidden={
                                    this.state.client.accountId === undefined
                                  }
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeToOpenSetNotificationModal(
                                      true
                                    );
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <NotificationsIcon className="iconImage" />
                                  <span style={{ marginLeft: "5.5px" }}>
                                    Set Notification
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDialogueToEditClient();
                                    if (
                                      clientData &&
                                      clientData.contacts !== undefined
                                    ) {
                                      this.setState({
                                        editContact: clientData.contacts,
                                        contacts: clientData.contacts,
                                        updatedContact: clientData.contacts,
                                      });
                                    } else {
                                      this.setState({
                                        editContact: [],
                                        contacts: [],
                                      });
                                    }
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon className="iconImage" />{" "}
                                  Edit
                                </MenuList>
                                <MenuList
                                  hidden
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ openActionMenu: false });
                                    handleOpenDialogueForDeleteClient();
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                    className="iconImage"
                                  />{" "}
                                  Delete
                                </MenuList>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
              </Table>
            </TableContainer>
            {sortedClientList && sortedClientList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 20]}
                  component="div"
                  count={
                    sortedClientList.length > 0 ? sortedClientList.length : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
          {/* </div> */}
        </div>

        {/*SET CLIENTS NOTIFICATION*/}
        <Dialog
          open={openSetNotificationModal}
          onClose={() => {
            this.props.handleChangeToOpenSetNotificationModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                this.props.handleChangeToOpenSetNotificationModal(false);
              }}
            >
              Notification Setting
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ maxHeight: "630px", borderBottom: "none" }}
              className="dialogContentWidth850"
            >
              <div className="row">
                <div className="col-lg-10">
                  <div style={{ fontSize: "18px" }}>Holiday Alert</div>
                  <div style={{ color: "#676767" }}>
                    Enable holiday alert message for schedule transaction.
                  </div>
                </div>
                <div className="col-lg-2" style={{ paddingTop: "12px" }}>
                  <Switch
                    checked={holidayAlert}
                    onChange={(e) => {
                      const enabledData = {
                        accountId: this.state.client.accountId,
                        upcomingTransactionAlert: upcomingAlert,
                        upcomingHolidayAlert: e,
                      };
                      if (emailAlertId !== "") {
                        enabledData.emailAlertId = emailAlertId;
                      }
                      this.props.saveNotifications(enabledData);
                      this.props.handleChangeHolidayAlert(e);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-10">
                  <div style={{ fontSize: "18px" }}>
                    Upcoming Transaction Alert
                  </div>
                  <div style={{ color: "#676767" }}>
                    Enable upcoming transaction alert message.
                  </div>
                </div>
                <div className="col-lg-2" style={{ paddingTop: "12px" }}>
                  <Switch
                    checked={upcomingAlert}
                    onChange={(e) => {
                      const enabledData = {
                        accountId: this.state.client.accountId,
                        upcomingTransactionAlert: e,
                        upcomingHolidayAlert: holidayAlert,
                      };
                      if (emailAlertId !== "") {
                        enabledData.emailAlertId = emailAlertId;
                      }
                      this.props.handleChangeUpcomingAlert(e);
                      this.props.saveNotifications(enabledData);
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*ADD CLIENTS*/}
        <Dialog
          open={clientState && clientState.openClientFormModal}
          onClose={() => {
            this.props.handleChangeToOpenClientFormModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                this.props.handleChangeToOpenClientFormModal(false);
              }}
            >
              Add Client
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ maxHeight: "630px", borderBottom: "none" }}
              className="dialogContentWidth850"
            >
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    First Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    <input
                      type="name"
                      id="firstName"
                      autoComplete="off"
                      name="firstName"
                      className="inputField"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClientInRegisterCase(
                          e.target.id,
                          e.target.value
                        );
                        Helpers.handleValidationOnChange(e, errors);
                      }}
                    />
                    {errors.firstName.length > 0 && (
                      <span className="error">{errors.firstName}</span>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="lastName" style={style.columnHeading}>
                    Last Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="lastName"
                    className="inputField"
                    autoComplete="off"
                    name="lastName"
                    disabled={this.state.selectedClientId}
                    value={
                      isCorporate
                        ? this.state.selectedClientId
                          ? this.state.selectedClientData.lastName
                          : clientRegisterData.lastName
                        : clientRegisterData.lastName
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClientInRegisterCase(
                        e.target.id,
                        e.target.value
                      );
                      Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                  {errors.lastName.length > 0 && (
                    <span className="error">{errors.lastName}</span>
                  )}
                </div>
                <div className="col-4" style={{ position: "inherit" }}>
                  <label style={style.columnHeading}>
                    Date-of-birth
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-add-date">
                    {isCorporate ? (
                      <>
                        <KeyBoardDatePickerComponent
                          dateValue={
                            this.state.selectedClientId
                              ? getFormattedDateValue(
                                  this.state.selectedClientData.dob
                                )
                              : clientRegisterData.dob !== ""
                              ? getFormattedDateValue(clientRegisterData.dob)
                              : null
                          }
                          onChangeDate={(data) => {
                            this.props.handleChangeClientInRegisterCase(
                              "dob",
                              data
                            );
                          }}
                        />
                      </>
                    ) : (
                      <KeyBoardDatePickerComponent
                        dateValue={
                          clientRegisterData &&
                          getFormattedDateValue(clientRegisterData.dob)
                        }
                        onChangeDate={(data) => {
                          this.props.handleChangeClientInRegisterCase(
                            "dob",
                            data
                          );
                        }}
                      />
                    )}
                  </div>
                  {errors.dob.length > 0 && (
                    <span className="error">{errors.dob}</span>
                  )}
                </div>
              </div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4">
                  <label htmlFor="gender" style={style.columnHeading}>
                    Gender
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      disabled={this.state.selectedClientId}
                      id="contactType"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClientInRegisterCase(
                          "gender",
                          e
                        );
                      }}
                      value={
                        isCorporate
                          ? this.state.selectedClientId
                            ? this.state.selectedClientData.gender
                            : clientRegisterData.gender
                          : clientRegisterData.gender
                      }
                    >
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Marital Status <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      disabled={this.state.selectedClientId}
                      id="maritalStatus"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClientInRegisterCase(
                          "maritalStatus",
                          e
                        );
                      }}
                      value={
                        isCorporate
                          ? this.state.selectedClientId
                            ? this.state.selectedClientData.maritalStatus
                            : clientRegisterData.maritalStatus
                          : clientRegisterData.maritalStatus
                      }
                    >
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    County
                  </label>
                  <input
                    disabled={this.state.selectedClientId}
                    type="text"
                    id="county"
                    className="inputField"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClientInRegisterCase(
                        e.target.id,
                        e.target.value
                      );
                    }}
                    value={
                      isCorporate
                        ? this.state.selectedClientId
                          ? this.state.selectedClientData.county
                          : clientRegisterData.county
                        : clientRegisterData.county
                    }
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4" style={{ position: "inherit" }}>
                  <Checkbox
                    id="isEnableTransaction"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClientInRegisterCase(
                        e.target.id,
                        e.target.checked
                      );
                    }}
                    style={style.columnHeading}
                  >
                    Enable Transaction
                  </Checkbox>
                </div>
              </div>
              {!(this.state.contacts.length > 0) ? (
                <div
                  className="addFieldOuterDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForContactField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span className="addFieldSpan">Add Contact</span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenContactFields
                    fieldArray={this.state.contacts}
                    filterContactData={filterContactsData}
                    handleOpenDeleteDialogue={handleOpenDeleteContactsDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      className="cursorP"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForContactField();
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions style={{ padding: "10px 30px 15px 0px" }}>
              <ButtonComponent
                loading={this.props.state.clientState.loading}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: false });
                  var clientData =
                    this.props.state.registerCaseState.clientData;
                  clientData.ssn = clientData.ssn.replace(/-/g, "");
                  handleCloseDialogueToAddClients();
                  if (!this.state.clicked) {
                    this.setState({
                      clicked: true,
                      setValue: "add",
                    });
                    if (isCorporate) {
                      this.props.addNewCorporateClient(
                        this.props.state.loginState.user.corporateId,
                        clientData,
                        this.props
                      );
                    } else {
                      this.props.addClient(clientData, this.props);
                    }
                    setTimeout(() => {
                      this.setState({
                        clicked: false,
                      });
                    }, 3000);
                  }
                }}
                value={clientDataValue}
                buttonName={"Add"}
              />
            </DialogActions>
          </div>
        </Dialog>

        {/*DELETE CLIENT CONTACT*/}
        <Dialog
          open={this.state.openDeleteContactDialogue}
          onClose={handleCloseDeleteContactsDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete contact?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteContactsDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteContactsDialogue();
                      }}
                      style={style.deleteCaseTemplateButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*DELETE CLIENT IN CORPORATE*/}
        <Dialog
          open={this.state.openDeleteConfirmation}
          onClose={handleCloseDeleteClientInCorporate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to remove this client?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      onClick={handleCloseDeleteClientInCorporate}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ openDeleteConfirmation: false });
                        this.props.deleteClientInCorporate(
                          this.state.client.clientId,
                          getAccountType.corporate.corporateId
                        );
                      }}
                      style={style.deleteCaseTemplateButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*UPDATE CLIENTS*/}
        <Dialog
          open={this.state.openDialogueToUpdateClients}
          onClose={handleCloseDialogueToEditClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToEditClient}
            >
              Update Client
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ maxHeight: "630px" }}
              className="dialogContentWidth850"
            >
              <div className="row mt-3" style={style.row}>
                <div className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    First Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="firstName"
                    autoComplete="off"
                    className="inputField"
                    value={clientData && clientData.firstName}
                    name="firstName"
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.firstName.length > 0 && (
                    <span className="error">{errors.firstName}</span>
                  )}
                </div>
                <div className="col-4">
                  <label htmlFor="lastName" style={style.columnHeading}>
                    Last Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    value={clientData && clientData.lastName}
                    className="inputField"
                    autoComplete="off"
                    onChange={(e) => {
                      Helpers.handleValidationOnChange(e, errors);
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.lastName.length > 0 && (
                    <span className="error">{errors.lastName}</span>
                  )}
                </div>
                <div className="col-4" style={{ position: "inherit" }}>
                  <label style={style.columnHeading}>
                    Date-of-birth
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-add-date">
                    <KeyBoardDatePickerComponent
                      dateValue={
                        clientData && getFormattedDateValue(clientData.dob)
                      }
                      onChangeDate={(data) => {
                        this.props.handleChangeClient("dob", data);
                      }}
                    />
                  </div>
                  {errors.dob.length > 0 && (
                    <span className="error">{errors.dob}</span>
                  )}
                </div>
              </div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4">
                  <label htmlFor="gender" style={style.columnHeading}>
                    Gender
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="contactType"
                      showSearch={false}
                      value={clientData && clientData.gender}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClient("gender", e);
                      }}
                    >
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Marital Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="maritalStatus"
                      showSearch={false}
                      value={clientData && clientData.maritalStatus}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClient("maritalStatus", e);
                      }}
                    >
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    County
                  </label>
                  <input
                    type="text"
                    id="county"
                    className="inputField"
                    value={clientData && clientData.county}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4" style={{ position: "inherit" }}>
                  <Checkbox
                    id="isEnableTransaction"
                    checked={!!(clientData && clientData.isEnableTransaction)}
                    value={clientData && clientData.isEnableTransaction}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.checked
                      );
                    }}
                    style={style.columnHeading}
                  >
                    Enable Transaction
                  </Checkbox>
                </div>
              </div>
              {!(this.state.contacts.length > 0) ? (
                <div
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForContactField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    Add Contact
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenContactFields
                    fieldArray={this.state.contacts}
                    isEdit={true}
                    filterEditContactData={filterEditContactData}
                    handleOpenDeleteDialogue={handleOpenDeleteContactsDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForContactField();
                      }}
                    >
                      {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                      {/*     alt="Add Button"*/}
                      {/*     className="addButtonIcon"/>*/}
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  padding: "0.5rem 0px 0.5rem",
                  float: "right",
                  marginRight: "7px",
                }}
              >
                <div></div>
                <div>
                  <ButtonComponent
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: false });
                      var clientData = this.props.state.clientState.clientData;
                      if (!this.state.clicked) {
                        this.setState({
                          clicked: true,
                          setValue: "add",
                        });
                        this.props.editClient(clientData, this.props);
                        setTimeout(() => {
                          this.setState({
                            clicked: false,
                          });
                        }, 3000);
                      }
                    }}
                    buttonName="Update"
                    noValue
                    loading={this.props.state.clientState.loading}
                    // disabled={!clientDataValue}
                    // className={clientDataValue ? "addButton" : "disabledAddButton"}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*DELETE UPDATE CLIENT CONTACT*/}
        <Dialog
          open={this.state.openDeleteEditContactDialogue}
          onClose={handleCloseDeleteEditContactDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
                paddingTop: 0,
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete contact?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteEditContactDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteEditContactDialogue();
                      }}
                      style={style.deleteCaseTemplateButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*Delete client*/}
        <Dialog
          open={this.state.openDeleteClientDialogue}
          onClose={handleCloseDialogueForDeleteClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete client?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForDeleteClient}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteClientDialogue();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/* Client discharge */}
        <Dialog
          open={this.state.openDialogueToDischargeTransaction}
          onClose={handleCloseDialogueToDischargeTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to discharge this client?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToHoldClient}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToDischargeTransaction();
                      const data = {
                        clientId: this.state.client.clientId,
                        isDischarged: true,
                      };
                      this.props.clientOnDischarge(data, this.props);
                      // handleOpenDialogueToUnHoldClient()
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*hold client*/}
        <Dialog
          open={this.state.openDialogueToHoldTransaction}
          onClose={handleCloseDialogueToHoldClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to hold this client?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToHoldClient}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToHoldClient();
                      const data = {
                        clientId: this.state.client.clientId,
                        isHold: true,
                      };
                      this.props.clientOnHold(data, this.props);
                      // handleOpenDialogueToUnHoldClient()
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*rejects client schedule transaction*/}
        <Dialog
          open={this.state.openDialogueToUnHoldTransaction}
          onClose={handleCloseDialogueToUnHoldClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to resume schedule transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={() => {
                      handleCloseDialogueToUnHoldClient();
                      const data = {
                        clientId: this.state.client.clientId,
                        isHold: false,
                        isResumeTxn: false,
                      };
                      this.props.clientOnHold(data, this.props);
                    }}
                    style={style.cancelButton}
                  >
                    No
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToUnHoldClient();
                      const data = {
                        clientId: this.state.client.clientId,
                        isHold: false,
                        isResumeTxn: true,
                      };
                      this.props.clientOnHold(data, this.props);
                    }}
                    style={style.deleteClientButton}
                  >
                    yes
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*Reverting the discharge */}
        <Dialog
          open={this.state.openDialogueToUnDischargeTransaction}
          onClose={handleCloseDialogueToUnDischargeTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to resume schedule transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={() => {
                      handleCloseDialogueToUnDischargeTransaction();
                      // const data = {
                      //   clientId: this.state.client.clientId,
                      //   isDischarged: true,
                      // };
                      // this.props.clientOnDischarge(data, this.props);
                    }}
                    style={style.cancelButton}
                  >
                    No
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToUnDischargeTransaction();
                      const data = {
                        clientId: this.state.client.clientId,
                        isDischarged: false,
                      };
                      this.props.clientOnDischarge(data, this.props);
                    }}
                    style={style.deleteClientButton}
                  >
                    yes
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*Reset Password*/}
        <Dialog
          open={this.state.openDialogueForSetPassword}
          onClose={handleCloseSetPasswordDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseSetPasswordDialogue}
            >
              Set Password
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <label style={style.label}>Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="emailPhone"
                  autoComplete="off"
                  style={style.input}
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeEmail(e.target.id, e.target.value);
                  }}
                />
                <div className="row">
                  <div className={"col-6"} />
                  <div
                    className={"col-6"}
                    style={{ paddingLeft: "72px", paddingTop: "18px" }}
                  >
                    <ButtonComponent
                      loading={this.props.state.clientState.loading}
                      onClick={(e) => {
                        e.preventDefault();
                        // handleCloseSetPasswordDialogue();
                        const data = {
                          accountType: "EVERFUND_CLIENT",
                          client: {
                            clientId: this.state.client.clientId,
                            emailPhone: email,
                          },
                        };
                        this.props.setClientPassword(data, this.props);
                      }}
                      value={email}
                      buttonName="Set Password"
                    />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/*Payment Setting Modal*/}
        <Dialog
          open={this.state.openModalForPaymentSetting}
          onClose={handleClosePaymentSettingModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleClosePaymentSettingModal}
            >
              Payment Setting
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                padding: "8px 22px",
                textAlign: "center",
                width: "470px",
              }}
            >
              <ClientPaymentSettingScreen
                clientId={this.state.client.clientId}
                modalContent={true}
                loading={this.props.state.clientState.loading}
                clientState={clientState}
                handleChangeClientFee={handleChangeClientFee}
                handleChangeClientMonthlyFee={handleChangeClientMonthlyFee}
                handleChangeClientAnnualFee={handleChangeClientAnnualFee}
                handleChangeClientSpendDown={handleChangeClientSpendDown}
                handleChangeClientAchDelayThreshold={
                  handleChangeClientAchDelayThreshold
                }
                handleChangeClientInstallment={handleChangeClientInstallment}
                handleChangeAdmissionDate={handleChangeAdmissionDate}
                addClientPayment={addClientPayment}
                handleClosePaymentSettingModal={handleClosePaymentSettingModal}
              />
            </DialogContent>
          </div>
        </Dialog>

        {/*Eligibility calculation Modal*/}
        <Dialog
          open={this.state.openModalForEligibilityCalculation}
          onClose={handleCloseEligibilityCalculationModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseEligibilityCalculationModal}
            >
              Eligibility Calculation
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                padding: "8px 22px",
                width: "500px",
                borderBottom: "none",
              }}
            >
              <div>
                <EligibilityCalculationComponent
                  caseDetails={caseDetails}
                  proprties={this.props}
                  isInsideClientDetail={false}
                  calculateEligibility={calculateEligibility}
                  handleChangeEligibilityData={handleChangeEligibilityData}
                  eligibilityData={eligibilityData}
                  requestData={requestData}
                  handleChangeWorkFlow={handleChangeWorkFlow}
                  handleChangeAction={handleChangeAction}
                  clearWorkflow={this.props.clearWorkflow}
                  clearAction={this.props.clearAction}
                  workflowId={workflowId}
                  actionId={actionId}
                  handleChangeFamilyType={handleChangeFamilyType}
                  handleChangeMembers={handleChangeMembers}
                />
              </div>
            </DialogContent>
            {caseDetails && caseDetails.length > 0 ? (
              <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
                <ButtonComponent
                  onClick={(e) => {
                    e.preventDefault();
                    calculateEligibility(requestData);
                  }}
                  value={value}
                  loading={this.props.state.clientState.loading}
                  buttonName="Calculate"
                />
              </DialogActions>
            ) : (
              <div style={{ marginTop: "12px" }}></div>
            )}
          </div>
        </Dialog>

        {/*Update Profile Picture*/}
        <Dialog
          open={this.state.openDialogueForUpdateProfile}
          onClose={handleCloseDialogueForUpdateProfile}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueForUpdateProfile}
            >
              Update Profile Picture
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
                height: "260px",
              }}
            >
              <DialogContentText>
                {this.props.state.clientState.loading ? (
                  <div
                    id="loader"
                    style={{
                      position: "absolute",
                      left: "214px",
                      zIndex: "12",
                      top: "132px",
                      height: "63px",
                    }}
                  >
                    <CircularProgress style={{ color: "#8BC83F" }} />
                  </div>
                ) : null}
                <div className="d-flex justify-content-end">
                  <Avatar
                    alt="Profile"
                    src={profile}
                    style={{
                      width: "173px",
                      height: "173px",
                      position: "absolute",
                      right: "30%",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "195px",
                      right: "30%",
                      color: "#666666",
                      transition:
                        "all .3s cubic-bezier(.175, .885, .32, 1.275)",
                    }}
                  >
                    <label htmlFor="file">
                      <img
                        src={"assets/images/edit_photo.png"}
                        alt="Edit"
                        style={{
                          background: "#BCBCBC",
                          width: "35px",
                          height: "35px",
                          boxShadow:
                            "rgba(0, 0, 0, 0) 0px 0px 1px 0px," +
                            " rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </label>
                    <input
                      className="file-upload"
                      type="file"
                      id="file"
                      onChange={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: true });
                        this.onChangeFileHandler(e, this.state.client.clientId);
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        {/*Clients Details*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openClientDetailsDialogue}
          onClose={handleCloseDialogueForClientDetails}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForClientDetails}
          >
            Clients Details
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              // marginLeft: '1rem'
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Client Id
                  </label>
                  <label style={style.clientData}>
                    {clientRes && clientRes.clientNo}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Client Name
                  </label>
                  <label style={style.clientData}>
                    {clientRes &&
                      clientRes.firstName + " " + clientRes.lastName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Gender
                  </label>
                  <label style={style.clientData}>
                    {clientRes && clientRes.gender}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Date Of Birth
                  </label>
                  <label style={style.clientData}>
                    {clientRes &&
                      convertTimeStampDateToLocalDate(clientRes.dob)}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Marital Status
                  </label>
                  <label style={style.clientData}>
                    {clientRes && clientRes.maritalStatus}
                  </label>
                </span>
                <span className="col-4" style={{ position: "initial" }}>
                  <label htmlFor="gender" style={style.columnHeading}>
                    Case Count
                  </label>
                  <label style={style.clientData}>
                    {clientRes && clientRes.cases ? clientRes.cases.length : 0}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Register Date
                  </label>
                  <label style={style.clientData}>
                    {clientRes &&
                      convertTimeStampDateToLocalDate(clientRes.createdAt)}
                  </label>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(
                        config.configFilters.clientSearchFilter
                      );
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openClientDeleteSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.state.clientState.errorMsgForClient}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={
              this.props.state.transactionState &&
              this.props.state.transactionState.errorMsg
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openUpdateProfilePictureSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="success"
            message={"Profile Picture Updated"}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openClientDeleteSuccess}
          autoHideDuration={4000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="success"
            message={"Client Successfully Deleted"}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openUploadErrorSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={
              this.props.state.clientState &&
              this.props.state.clientState.errorMsgForClient
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openClientSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.state.clientState.errorMsgForClient}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openClientErrorSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.state.clientState.errorMsgForClient}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.registerCaseState.openClientSuccessSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeSnackBarForRegisterCase}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeSnackBarForRegisterCase}
            variant="success"
            message={this.props.state.registerCaseState.successMsg}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.registerCaseState.openClientErrorSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeSnackBarForRegisterCase}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeSnackBarForRegisterCase}
            variant="error"
            message={this.props.state.registerCaseState.errorMsgForRegisterCase}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={clientState && clientState.openSnackBarForErrorMessage}
          autoHideDuration={6000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="error"
            message={clientState && clientState.errorMsgForClient}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openClientSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="success"
            message={this.props.state.clientState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.clientState.openClientFailureSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="error"
            message={this.props.state.clientState.errorMsgForClient}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "1.5%",
    marginTop: "48px",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
  },
  countyInputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    height: "40px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    // width: "175px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
    marginRight: "1rem",
    // position: "fixed",
    // zIndex: 1,
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  tableHeader: {
    color: "#8BC83F ",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
  },
  tableFirstHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    paddingLeft: "50px",
  },
  tableBody: {
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
  },
  tableFirstBody: {
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    paddingLeft: "50px",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    // position: "fixed",
    // width: "100%",
    // background: "white",
    // zIndex: 1,
    // right: "2.5%",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "12px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    textAlign: "left",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "29px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.6rem",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    // background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    // paddingLeft:'2.3rem',
    width: "270px",
    // heigth: '26px'
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
};

export default ClientScreen;
