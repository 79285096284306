import {connect} from 'react-redux';
import CalendarViewOfClientScreen from "../../screens/CalendarViewOfClientScreen";
import {
    clientRequestMade,
    detailsForCalendarRequestMade,
    fetchDataForCalendarByClientFailure,
    fetchDataForCalendarByClientSuccess, fetchSingleClientFailure, fetchSingleClientSuccess
} from "../../actions";
import CalendarApi from "../../api/CalendarApi";
import {
    searchCalendarDataByClientFailure,
    searchCalendarDataByClientSuccess
} from "../../actions/CalendarAction";
import {ClientAPI, SettingsAPI} from "../../api";
import {
    getAssignedEventForClientCalendarFailure,
    getAssignedEventForClientCalendarSuccess
} from "../../actions/ClientAction";
import {
    addHolidaysFailure,
    addHolidaysSuccess, clearModalData, fetchHolidaysFailure, fetchHolidaysSuccess, handleChangeCalendarType,
    handleChangeHolidayName, makeSuccessToFalse,
    requestInCalendarSetting
} from "../../actions/SettingsAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return{
        fetchDataListForCalendarByClientId: (id) => {
            dispatch(detailsForCalendarRequestMade(true));
            CalendarApi.fetchDataListForCalendarById(id,(response, error) => {
                if (response) {
                    dispatch(fetchDataForCalendarByClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchDataForCalendarByClientFailure(error.msg))
                    } else {
                        dispatch(fetchDataForCalendarByClientFailure(error));
                    }
                }
            })
        },
        searchCalendarDataByClientId: (value,id, props) =>{
            dispatch(detailsForCalendarRequestMade(true));
            CalendarApi.searchCalendarByClientId(value,id,(response, error) => {
                if (response) {
                    dispatch(searchCalendarDataByClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchCalendarDataByClientFailure(error.msg))
                    } else {
                        dispatch(searchCalendarDataByClientFailure(error));
                    }
                }
            })
        },
        getClientAssignedEvent: (clientId , props) =>{
            dispatch(clientRequestMade(true));
            ClientAPI.getClientAssignedEvent(clientId, (response, error) => {
                if (response) {
                    dispatch(getAssignedEventForClientCalendarSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(getAssignedEventForClientCalendarFailure(error.msg))
                    } else {
                        dispatch(getAssignedEventForClientCalendarFailure(error));
                    }
                }
            })
        },
        fetchSingleClient: (clientId , props) =>{
            dispatch(clientRequestMade(true));
            ClientAPI.fetchSingleClient(clientId, (response, error) => {
                if (response) {
                    dispatch(fetchSingleClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchSingleClientFailure(error.msg))
                    } else {
                        dispatch(fetchSingleClientFailure(error));
                    }
                }
            })
        },
        // add holiday
        handleChangeHolidayName: (holidayName) =>{dispatch(handleChangeHolidayName(holidayName))},
        handleChangeCalendarType: (calendarType) => {
            dispatch(handleChangeCalendarType(calendarType));
        },
        addHolidays : (holidayData,clientId, props) => {
            dispatch(requestInCalendarSetting(true));
            SettingsAPI.addHolidays(holidayData, (response, error) => {
                if (response) {
                    props.fetchDataListForCalendarByClientId(clientId);
                    props.getClientAssignedEvent(clientId);
                    props.fetchHolidaysList();
                    dispatch(addHolidaysSuccess(true, response));

                } else {
                    if (error.msg) {
                        dispatch(addHolidaysFailure(error.msg));
                    } else {
                        dispatch(addHolidaysFailure(false));
                    }
                }
            });
        },
        setToFalse : () => {dispatch(makeSuccessToFalse(false))},
        clearModalData: () => {dispatch(clearModalData())},
        fetchHolidaysList: () => {
            dispatch(requestInCalendarSetting(true));
            SettingsAPI.fetchHolidayDetails((response, error) => {
                if (response) {
                    dispatch(fetchHolidaysSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchHolidaysFailure(error.msg))
                    } else {
                        dispatch(fetchHolidaysFailure(error));
                    }
                }
            })
        },
    }
}

export const CalendarViewOfClientContainer =
    connect(mapStateToProps, mapDispatchToProps) (CalendarViewOfClientScreen);