import { ACTION_TYPE_CASE } from "../constants/Type";

export const caseRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_CASE.CASE_REQUEST_MADE,
    bool,
  };
};

export const makeChangeWorkFlowAgents = (workFlowID, value) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_WORKFLOW_AGENT,
    workFlowID,
    value,
  };
};
export const fetchCaseSuccess = (cases) => {
  return {
    type: ACTION_TYPE_CASE.CASE_FETCH_SUCCESS,
    cases,
  };
};
export const getSingleClientInfo = (clientInfo) => {
  return {
    type: ACTION_TYPE_CASE.FETCH_CLIENT_INFO,
    clientInfo,
  };
};
export const fetchCaseFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.CASE_FETCH_FAILURE,
    error,
  };
};

// pagination
export const fetchPaginatedCaseSuccess = (cases) => {
  return {
    type: ACTION_TYPE_CASE.CASE_PAGINATED_FETCH_SUCCESS,
    cases,
  };
};
export const fetchPaginatedCaseFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.CASE_PAGINATED_FETCH_FAILURE,
    error,
  };
};
export const clearNextUrl = () => {
  return {
    type: ACTION_TYPE_CASE.CLEAR_NEXT_URL,
  };
};
export const closeSnackBarForCase = (bool) => {
  return {
    type: ACTION_TYPE_CASE.CLOSE_SNACK_BAR_CASE_PAGE,
    bool,
  };
};
export const handleChangeCase = (id, value) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE,
    id,
    value,
  };
};
export const deleteCaseSuccess = (caseId) => {
  return {
    type: ACTION_TYPE_CASE.DELETE_CASE_SUCCESS,
    caseId,
  };
};
export const deleteCaseFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.DELETE_CASE_SUCCESS,
    error,
  };
};
export const setCaseToEdit = (caseData) => {
  return {
    type: ACTION_TYPE_CASE.SET_CASE_TO_EDIT,
    caseData,
  };
};

export const handleChangeCaseAgent = (id, value) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_AGENT,
    id,
    value,
  };
};

export const handleChangeDateByAgent = (id, arrayValue) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_DATE,
    id,
    arrayValue,
  };
};

export const updateCaseSuccess = (response) => {
  return {
    type: ACTION_TYPE_CASE.UPDATE_CASE_SUCCESS,
    response,
  };
};

export const updateCaseFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.UPDATE_CASE_FAILURE,
    error,
  };
};

export const scheduleDateByAgentSuccess = (response) => {
  return {
    type: ACTION_TYPE_CASE.SCHEDULE_DATE_BY_AGENT_SUCCESS,
    response,
  };
};

export const scheduleDateByAgentFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.SCHEDULE_DATE_BY_AGENT_FAILURE,
    error,
  };
};

// export const fetchDataForCalendarSuccess = response => {
//     return {
//         type: ACTION_TYPE_CASE.FETCH_DATA_FOR_CALENDAR_SUCCESS,
//         response
//     };
// };
//
// export const fetchDataForCalendarFailure = error => {
//     return {
//         type: ACTION_TYPE_CASE.FETCH_DATA_FOR_CALENDAR_FAILURE,
//         error
//     };
// };

export const getWorkflow = (workflowName) => {
  return {
    type: ACTION_TYPE_CASE.GET_WORKFLOW,
    workflowName,
  };
};

export const handleChangeDateOnly = (id, date) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_DATE_ONLY,
    id,
    date,
  };
};
export const handleClick = (id) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CLICK,
    id,
  };
};

export const fetchFiledDetailByCaseSuccess = (fileResponse) => {
  return {
    type: ACTION_TYPE_CASE.FETCH_FILE_DETAILS_FOR_CASE_SUCCESS,
    fileResponse,
  };
};

export const fetchFiledDetailByCaseFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.FETCH_FILE_DETAILS_FOR_CASE_FAILURE,
    error,
  };
};

//CASE CONTACT

export const fetchCaseContactSuccess = (caseContactList) => {
  return {
    type: ACTION_TYPE_CASE.FETCH_CASE_CONTACT_SUCCESS,
    caseContactList,
  };
};

export const fetchCaseContactFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.FETCH_CASE_CONTACT_FAILURE,
    error,
  };
};

export const handleChangeCaseContactInput = (id, value) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_CONTACT_INPUT,
    id,
    value,
  };
};

export const addCaseContactSuccess = (response) => {
  return {
    type: ACTION_TYPE_CASE.ADD_CASE_INDIVIDUAL_CONTACT_SUCCESS,
    response,
  };
};

export const addCaseContactFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.ADD_CASE_INDIVIDUAL_CONTACT_FAILURE,
    error,
  };
};

export const handleChangeCaseCompanyInput = (id, value) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_COMPANY_INPUT,
    id,
    value,
  };
};

export const addCaseCompanySuccess = (response) => {
  return {
    type: ACTION_TYPE_CASE.ADD_CASE_COMPANY_CONTACT_SUCCESS,
    response,
  };
};

export const addCaseCompanyFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.ADD_CASE_COMPANY_CONTACT_FAILURE,
    error,
  };
};

export const searchExistingContactSuccess = (existingList) => {
  return {
    type: ACTION_TYPE_CASE.SEARCH_EXISTING_CONTACT_SUCCESS,
    existingList,
  };
};
export const searchExistingContactFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.SEARCH_EXISTING_CONTACT_FAILURE,
    error,
  };
};

export const handleChangeExistingCompanyInput = (value) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_EXISTING_CONTACT,
    value,
  };
};

export const addCaseExistingContactSuccess = (response) => {
  return {
    type: ACTION_TYPE_CASE.ADD_CASE_EXISTING_CONTACT_SUCCESS,
    response,
  };
};

export const addCaseExistingContactFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.ADD_CASE_EXISTING_CONTACT_FAILURE,
    error,
  };
};
export const handleChangeRelationshipNameInCase = (relationship) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_RELATIONSHIP,
    relationship,
  };
};

export const setCaseIndividualContactToEmpty = () => {
  return {
    type: ACTION_TYPE_CASE.SET_INDIVIDUAL_CASE_CONTACT_TO_EMPTY,
  };
};

export const setCaseCompanyContactToEmpty = () => {
  return {
    type: ACTION_TYPE_CASE.SET_CASE_COMPANY_CONTACT_TO_EMPTY,
  };
};

//FILTER AND EXPORT

export const handleChangeCaseStartDate = (caseStartDate) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_START_DATE,
    caseStartDate,
  };
};

export const handleChangeCaseEndDate = (caseEndDate) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_END_DATE,
    caseEndDate,
  };
};

export const handleChangeCaseStatus = (caseStatus) => {
  return {
    type: ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_STATUS,
    caseStatus,
  };
};
export const searchCaseSuccess = (cases, bool) => {
  return {
    type: ACTION_TYPE_CASE.SEARCH_CASE_SUCCESS,
    cases,
    bool,
  };
};

export const searchCaseFail = (error) => {
  return {
    type: ACTION_TYPE_CASE.SEARCH_CLIENT_FAILURE,
    error,
  };
};
export const exportCaseSuccess = (response) => {
  return {
    type: ACTION_TYPE_CASE.EXPORT_CASE_LIST_SUCCESS,
    response,
  };
};
export const exportCaseFailure = (error) => {
  return {
    type: ACTION_TYPE_CASE.EXPORT_CASE_LIST_FAILURE,
    error,
  };
};
export const clearFilterData = () => {
  return {
    type: ACTION_TYPE_CASE.CLEAR_FILTER_DATA,
  };
};
