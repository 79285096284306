import moment from 'moment'

const DateString = (dateToConvert) => {
        return new Date(parseInt(dateToConvert)).toLocaleDateString();
}
export default DateString;

export const getDateValue = (dateToConvert) => {
        let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
        return moment(dateConverted, "YYYY-MM-DD");
};

export const timestampToMomentFormat = dateToConvert => {
        let dateConverted = new Date(parseInt(dateToConvert));
        return moment(dateConverted, "YYYY-MM-DD HH:mm:ss");
}

export const getFormattedDateValue = (dateToConvert) => {
        if(dateToConvert !== null){
                return new Date(parseInt(dateToConvert)).toLocaleDateString("en-US");
        }else{
                return null
        }
}
export const getFormattedDateTimeValue = (dateToConvert) => {
        if(dateToConvert){
               return new Date(parseInt(dateToConvert)).toLocaleString("en-US");
        }
}
// export const getDateWithStartTimeValue = (dateToConvert) => {
//         if(dateToConvert){
//                 const startDate = new Date(dateToConvert).setHours(0,0,0);
//                 console.log("new Date(parseInt(startDate)).toLocaleString(\"en-US\");",
//                     new Date(parseInt(startDate)).toLocaleString("en-US"))
//                 return new Date(parseInt(startDate)).toLocaleString("en-US");
//         }
// }
export const getDateWithStartTimeValue = (startDate) => {
        // if(dateToConvert){
        //         const startDate = new Date(dateToConvert).setHours(0,0,0);
        //         console.log("new Date(parseInt(startDate)).toLocaleString(\"en-US\");",
                    new Date(parseInt(startDate)).toLocaleString("en-US")
                return new Date(parseInt(startDate)).toLocaleString("en-US");
        // }
}
export const getDateWithEndTimeValue = (dateToConvert) => {
        if(dateToConvert){
                // console.log(dateToConvert,'date to convert')
                // const endDate = new Date(dateToConvert)
                // console.log(endDate,'date to convert');
                return new Date(parseInt(dateToConvert)).toLocaleString("en-US");
               
        }
}
export function DateTimeFormat(UNIX_timestamp) {

        const a = new Date(UNIX_timestamp);
        
        const months = [
        
        'Jan',
        
        'Feb',
        
        'Mar',
        
        'Apr',
        
        'May',
        
        'Jun',
        
        'Jul',
        
        'Aug',
        
        'Sep',
        
        'Oct',
        
        'Nov',
        
        'Dec',
        
        ];
        
        const year = a.getFullYear();
        
        const month = months[a.getMonth()];
        
        const date = a.getDate();
        
        let hour = a.getHours();
        
        let min = a.getMinutes();
        
        const ampm = hour >= 12 ? 'PM' : 'AM';
        
        hour %= 12;
        
        hour = hour || 12; // the hour '0' should be '12'
        
        min = min < 10 ? `0${min}` : min;
        
        const formattedMinuteValue = `0${min}`.slice(-2);
        
        return `${date}, ${month} ${year} ${hour}:${formattedMinuteValue} ${ampm}`;
        
        }
export const convertTimeStampDateToLocalDate = (dateToConvert) => {
        return new Date(parseInt(dateToConvert)).toLocaleDateString();
};

// this month
export const convertDate = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
}

var date = new Date();
export const getNewDate = () =>{
        return new Date();
}
export const getWeekFirstDay = () =>{
        var first = date.getDate() - date.getDay();
        return new Date(date.setDate(first)).toLocaleString();
}
export const getWeekLastDay = () =>{
        var first = date.getDate() - date.getDay();
        var last = first + 6;
        return new Date(date.setDate(last)).toLocaleString();
}
export const getFirstDayOfMonth = () =>{
        return new Date(date.getFullYear(), date.getMonth(), 1);
}
export const getLastDayOfMonth = () =>{
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

