import {ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE} from "../constants/Type";

const initialState = {
    previousValue: false,
    loading: false,
    openDynamicActionSnackBar: false,
    errorMsgForDynamicActionData: '',
    openDynamicActionSuccessSnackBar: false,
    dynamicActionData: [],
    emailPhone: '',
    fileName:'',
    photo:{},
    conditionalFormCaseData:[],
    conditionalFormSuccess: false,
    conditionalFormSuccessForFirst:false,
    conditionalFormData:[],
    conditionalFormDataRequest:[],
    dynamicConditionFormsData:[],
    setPrevious: false,
    isSubmitted: false,
    successMessageForConditional: false,
    newFinalObjectArray:[],
    selectedCheckboxOptions:[],
    previousActionData : true,
    actionFormRequestBody:[],
};
const dynamicActionState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.DYNAMIC_ACTION_DATA_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.FETCH_ACTION_INFO_SUCCESS:
            const formData = action.actionInfo.action && action.actionInfo.action.actionForms;
            console.log("formData", formData && formData.some(option => option.actionFormData))
            return {
                ...state,
                previousValue: false,
                actionInfo: action.actionInfo.action,
                emailPhone: action.actionInfo.action && action.actionInfo.action.emailPhone,
                loading: false,
                openDynamicActionSnackBar: false,
                previousActionData: formData && formData.some(option => option.actionFormData),
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_PREVIOUS_VALUE_TO_TRUE:
            return {
                ...state,
                previousValue: action.bool
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.ADD_DATA_SUCCESS:
            return {
                ...state,
                addDynamicActionSuccess: action.bool,
                loading: false,
                openDynamicActionSnackBar: false,
                dynamicActionData: [],
                photo: {},
                previousValue: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_DYNAMIC_ACTION_DATA:
            return {
                ...state,
                dynamicActionData: action.actionFormData,
                loading: false
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.ADD_DATA_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
                openDynamicActionSnackBar: true
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.MARK_DYNAMIC_ACTION_DATA_SUCCESS:
            return {
                ...state,
                markDynamicActionSuccess: action.bool,
                loading: false,
                openDynamicActionSnackBar: false
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.MARK_DYNAMIC_ACTION_DATA_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
                openDynamicActionSnackBar: true
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_DYNAMIC_ACTION_DATA_TO_EDIT:
            return {
                ...state,
                dynamicActionData: action.actionFormData,
                loading: false,
                openDynamicActionSnackBar: false
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_PHOTO_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
                openDynamicActionSnackBar: true
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.CLOSE_SNACK_BAR_DYNAMIC_ACTION_PAGE:
            return {
                ...state,
                openDynamicActionSnackBar: false,
                openDynamicActionSuccessSnackBar: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CLIENT_EMAIL:
            return {
                ...state,
                emailPhone: action.email,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GENERATE_LINK_SUCCESS:
            return {
                ...state,
                key: action.response.shareLink.linkKey,
                openDynamicActionSuccessSnackBar: true,
                loading: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GENERATE_LINK_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
                openDynamicActionSuccessSnackBar: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_IMAGE:
                return {
                    ...state,
                    actionFormId: action.id,
                    loading: false
                };

        //EXPORT ACTION DATA
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.EXPORT_ACTION_DATA_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.EXPORT_ACTION_DATA_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
            };

      //****************HANDLE CHANGE FOR ACTION FORM DATA****************
            //input data
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_ACTION_INPUT_DATA:
            const inputValue = action.inputtedData;
            const inputActionFormId = action.actionFormId;
            const filterActionFormInfo = state.actionInfo.actionForms.filter(action => (action.actionFormId === inputActionFormId));
            if(filterActionFormInfo && filterActionFormInfo.length > 0 && filterActionFormInfo[0].actionFormData
                && filterActionFormInfo[0].actionFormData[0].actionFormDataId){
                filterActionFormInfo[0].actionFormData[0].actionFormId = inputActionFormId;
                filterActionFormInfo[0].actionFormData[0].data = inputValue;
            }else{
                filterActionFormInfo[0].actionFormData = [{
                    actionFormId : inputActionFormId,
                    data : inputValue
                }]}
            return {
                ...state,
            }
            //upload photo
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_PHOTO_SUCCESS:
            const id = action.data.actionFormId;
            const data = action.data.data;
            const newData = state.actionInfo.actionForms.filter(action =>
                (action.actionFormId === id ));
            if(newData && newData.length > 0 && newData[0].actionFormData
                && newData[0].actionFormData[0].actionFormDataId){
                newData[0].actionFormData[0].actionFormId = id;
                newData[0].actionFormData[0].data = data;
            }else{
                newData[0].actionFormData = [{
                    actionFormId : id,
                    data : data,
                    fileName: action.data.fileName,
                }]}
            console.log("newData data", newData)
            return {
                ...state,
                photo: action.data,
                openDynamicActionSnackBar: false,
                loading: false
            };
            //file name
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_FILE_NAME:
            const actionId = action.id;
            const fileName = action.fileName;
            const newFileNameData = state.actionInfo && state.actionInfo.actionForms &&
                state.actionInfo.actionForms.filter(action => (action.actionFormId === actionId ));
            if(newFileNameData[0].actionFormData) {
                newFileNameData[0].actionFormData[0].actionFormId = actionId;
                newFileNameData[0].actionFormData[0].fileName = fileName;
            }else{
                newFileNameData[0].actionFormData = [{
                    actionFormId: actionId,
                    fileName : fileName,
                }]}
            console.log("newFileNameData file", newFileNameData)
            return {
                ...state,
                id: action.id,
                fileName: action.fileName,
                loading: false,
            }

            //contact value
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONTACT_VALUE:
            const actionFormIdForContact = action.id;
            const selectedContactValue = action.selectedContact;
            const contactData = action.contactData;

            const newSelectedContactData = state.actionInfo &&
                state.actionInfo.actionForms && state.actionInfo.actionForms.filter(action =>
                    (action.actionFormId === actionFormIdForContact ));

            if(newSelectedContactData[0].actionFormData) {
                newSelectedContactData[0].actionFormData[0].data = selectedContactValue
                if( newSelectedContactData[0].contact){
                    newSelectedContactData[0].contact.emailPhone = contactData[0].emailPhone
                    !== undefined ?contactData[0].emailPhone : ""
                    newSelectedContactData[0].contact.phone = contactData[0].phone
                    !== undefined ? contactData[0].phone : ""
                    newSelectedContactData[0].contact.address = contactData[0].address
                    !== undefined ? contactData[0].address : ""
                    newSelectedContactData[0].contact.contactType = contactData[0].contactType
                    !== undefined ? contactData[0].contactType : ""
                }
            }else{
                newSelectedContactData[0].actionFormData = [{
                    actionFormId: actionFormIdForContact,
                    data : selectedContactValue
                }]
                newSelectedContactData[0].contact = {
                    emailPhone: contactData[0].emailPhone ? contactData[0].emailPhone  : "",
                    phone: contactData[0].phone ? contactData[0].phone : "",
                    address: contactData[0].address ? contactData[0].address : "",
                    contactType: contactData[0].contactType ? contactData[0].contactType : "",
                }
            }
            return {
                ...state,
                id: action.id,
                loading: false,
            }
            //checkbox values
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CHECKBOX_VALUE:
            const actionFormIdCheckBox = action.id;
            const isSelected = action.isSelected;
            const optionValueId = action.data;

            const newSelected = state.actionInfo &&
                state.actionInfo.actionForms &&
                state.actionInfo.actionForms.filter(action => (action.actionFormId === actionFormIdCheckBox));
            const checkValue = newSelected[0].optionValue &&
                newSelected[0].optionValue.filter(option => (option.optionValueId === optionValueId));
            if(newSelected && newSelected.length > 0
                && newSelected[0].actionFormData && newSelected[0].actionFormData.length > 0){
                if(newSelected[0].actionFormData[0].actionFormId){
                    newSelected[0].actionFormData[0].actionFormId = actionFormIdCheckBox;
                    newSelected[0].actionFormData[0].data = optionValueId;
                }
            }else{
                newSelected[0].actionFormData = [{
                    actionFormId : actionFormIdCheckBox,
                    data : optionValueId,
                }]}
            if(checkValue){
                checkValue[0].isSelected = isSelected;
                const isSelectedDataArray = newSelected[0] &&
                    newSelected[0].optionValue.filter(option => (option.isSelected === true));
                // state.dynamicActionData = final;
                newSelected[0].actionFormData = isSelectedDataArray.map(array => {
                    return {
                        actionFormId: actionFormIdCheckBox,
                        data: array.optionValueId
                    };
                });
                console.log("state.actionInfo", newSelected)
            }
            return {
                ...state,
                id: action.id,
                selectedValue: action.selectedValue,
                loading: false,
            }

        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_SELECTED_VALUE:
            const actionFormId = action.id;
            const selectedValue = action.selectedValue;
            const newSelectedData = state.actionInfo.actionForms.filter(action => (action.actionFormId === actionFormId));
            if(newSelectedData[0].actionFormData) {
                newSelectedData[0].actionFormData[0].actionFormId = actionFormId
                newSelectedData[0].actionFormData[0].data = selectedValue
            }else{
                newSelectedData[0].actionFormData = [{
                    data : selectedValue
                }]}
            return {
                ...state,
                id: action.id,
                selectedValue: action.selectedValue,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_DOB:
            const singleId = action.id;
            const selectedDate = action.dateValue;
            const newSelectedDate =
                state.actionInfo.actionForms.filter(action => (action.actionFormId === singleId ));
            if(newSelectedDate[0].actionFormData) {
                newSelectedDate[0].actionFormData[0].data = selectedDate
            }else{
                newSelectedDate[0].actionFormData = [{
                    data : selectedDate
                }]}
            return {
                ...state,
                id: action.id,
                loading: false,
            }


                //****** CONDITIONAL FORM *******
            //get conditional data from ref id
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_SUCCESS:
            const conditionalData = {
                refId : action.refId,
                step: action.step,
                actionFormId: action.actionFormId,
                caseId: action.caseId,
                workflowId: action.workflowId,
                prevActionFormId: action.prevActionFormId,
                prevRefId: action.prevRefId,
                prevOptionName: action.prevOptionName,
                conditionalForms: action.conditionalCaseData.conditionalForms
            }
            const toUpdate = action.conditionalCaseData.conditionalForms && action.conditionalCaseData.conditionalForms.length > 0
                && action.conditionalCaseData.conditionalForms.filter(form => form.conditionalFormData);
            if(toUpdate && toUpdate.length > 0){
                conditionalData.isSubmitted = true
            }else{
                conditionalData.isSubmitted = false
            }
            if(state.conditionalFormData && state.conditionalFormData.filter(data =>
                data.refId === conditionalData.refId).length > 0){
               console.log("already set")
            }else {
                state.conditionalFormData = [...state.conditionalFormData, conditionalData]
            }

            const newSelectedAction = state.actionInfo.actionForms.filter(form =>
                (form.actionFormId === action.actionFormId ));

            if(action.conditionalCaseData &&
                action.conditionalCaseData.conditionalForms === undefined ||
                action.conditionalCaseData.conditionalForms.length === 0){
                if(conditionalData.step === "firstStep"){
                    state.conditionalFormSuccessForFirst= false;
                }
                state.conditionalFormSuccess = false;
                if(newSelectedAction.length > 0){
                    const optionValueArray = newSelectedAction && newSelectedAction[0].optionValue
                    const finalOptionList = optionValueArray && optionValueArray.filter
                    (option => option.refId === action.refId)
                    finalOptionList[0].conditionalFormSuccess = false;
                }
            }else if(action.conditionalCaseData && action.conditionalCaseData.conditionalForms.length > 0) {
                if(conditionalData.step === "firstStep"){
                    state.conditionalFormSuccessForFirst= true;
                }
                state.conditionalFormSuccess = true;
                if(newSelectedAction.length > 0){
                    const optionValueArray = newSelectedAction && newSelectedAction[0].optionValue
                    const finalOptionList = optionValueArray && optionValueArray.filter
                    (option => option.refId === action.refId)
                    finalOptionList[0].conditionalFormSuccess = true;
                }
                const toUpdate = action.conditionalCaseData.conditionalForms.length > 0
                    && action.conditionalCaseData.conditionalForms.filter(form => form.conditionalFormData);
                if(toUpdate && toUpdate.length > 0){
                    state.isSubmitted = true
                }else{
                    state.isSubmitted = false
                }
            }
            return {
                ...state,
                conditionalFormCaseData: action.conditionalCaseData.conditionalForms,
                loading: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
            };

        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_SUCCESS:
            return {
                ...state,
                successMessageForConditional : action.bool,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_FORM_DATA_TO_EMPTY:
            return {
                ...state,
                conditionalFormData: [],
            }


            //set conditional data from ref id
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_DATA_FOR_CASE_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_DATA_FOR_CASE_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_FORM_DATA_REQUEST:
            if(action.conditionalFormDataRequest){
                state.conditionalFormDataRequest = [...state.conditionalFormDataRequest, action.conditionalFormDataRequest]
            }else {
                state.conditionalFormDataRequest = []
            }
            return {
                ...state,
                loading: false,
                setPrevious: action.setPrevious,
            };

        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_FORM_DATA:
            const filteredValue = state.conditionalFormData && state.conditionalFormData.length > 0
                && state.conditionalFormData.filter(data => data.refId === action.refId)
            const condition = filteredValue && filteredValue.length > 0 && filteredValue[0].conditionalForms;
            const final = condition && condition.length > 0 && condition.filter(conditions =>
                conditions.conditionalFormId === action.conditionalFormId)
            if(final && final.length > 0 && final[0].conditionalFormData
                && final[0].conditionalFormData[0].conditionalFormId){
                final[0].conditionalFormData[0].conditionalFormId = action.conditionalFormId;
                final[0].conditionalFormData[0].data = action.selectedValue;
            }else{
                final[0].conditionalFormData = [{
                conditionalFormId : action.conditionalFormId,
                data : action.selectedValue
            }]}
            return {
                ...state,
                loading: false,
                dynamicConditionFormsData: action.data
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_CONDITIONAL_PHOTO_SUCCESS:
            const filteredFileValue = state.conditionalFormData && state.conditionalFormData.length > 0
                && state.conditionalFormData.filter(data => data.refId === action.refId);
            const conditionDats = filteredFileValue && filteredFileValue.length > 0 && filteredFileValue[0].conditionalForms;
            const finalData = conditionDats && conditionDats.length > 0 && conditionDats.filter(conditions =>
                conditions.conditionalFormId === action.conditionalFormId);
            if(finalData && finalData.length > 0 && finalData[0].conditionalFormData){
                finalData[0].conditionalFormData[0].conditionalFormId = action.conditionalFormId;
                finalData[0].conditionalFormData[0].data = action.selectedValue;
            }else{
                finalData[0].conditionalFormData = [{
                    conditionalFormId : action.conditionalFormId,
                    data : action.selectedValue,
                }]}
            console.log("final data", finalData)
            return {
                ...state,
                photo: action.photo,
                loading: false
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_CONDITIONAL_PHOTO_FAILURE:
            return {
                ...state,
                errorMsgForDynamicActionData: action.error,
                loading: false,
            };
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_FORM_FILE_NAME:
            const filteredFileValueName = state.conditionalFormData && state.conditionalFormData.length > 0
                && state.conditionalFormData.filter(data => data.refId === action.refId);
            const conditionDataForm = filteredFileValueName && filteredFileValueName.length > 0 && filteredFileValueName[0].conditionalForms;
            const finalDataArray = conditionDataForm && conditionDataForm.length > 0 && conditionDataForm.filter(conditions =>
                conditions.conditionalFormId === action.conditionalFormId);
            if(finalDataArray && finalDataArray.length > 0 && finalDataArray[0].conditionalFormData){
                finalDataArray[0].conditionalFormData[0].conditionalFormId = action.conditionalFormId;
                finalDataArray[0].conditionalFormData[0].fileName = action.value;
            }else{
                finalDataArray[0].conditionalFormData = [{
                    conditionalFormId : action.conditionalFormId,
                    fileName : action.value,
                }]}
            console.log("finalDataArray data", finalDataArray)
            return {
                ...state,
                loading: false,
            }

        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_FORM_CHECKBOX_DATA:
            const checkboxConditionalFormId = action.conditionalFormId;
            const isSelectedValue = action.isSelected;
            const optionValue = action.data;

            const selectedByRefAndActionId = state.conditionalFormData && state.conditionalFormData.length > 0
                && state.conditionalFormData.filter(data => data.actionFormId === action.parentActionFormId &&
                    data.refId === action.parentRefId);

            const newSelectedCheckedValued = selectedByRefAndActionId && selectedByRefAndActionId.length > 0
                && selectedByRefAndActionId[0].conditionalForms.filter(conditions =>
                    conditions.conditionalFormId === checkboxConditionalFormId)

            if(newSelectedCheckedValued && newSelectedCheckedValued.length > 0 && newSelectedCheckedValued[0].conditionalFormData){
                newSelectedCheckedValued[0].conditionalFormData[0].conditionalFormId = action.conditionalFormId;
                newSelectedCheckedValued[0].conditionalFormData[0].data = optionValue;
            }else{
                newSelectedCheckedValued[0].conditionalFormData = [{
                    conditionalFormId : action.conditionalFormId,
                    fileName : action.value,
                }]}

            const checkValueForm = newSelectedCheckedValued && newSelectedCheckedValued.length > 0 &&
                newSelectedCheckedValued[0].optionValue.filter(option => (option.optionValueId === optionValue));

            if(checkValueForm && checkValueForm.length > 0){
                checkValueForm[0].isSelected = isSelectedValue;
                const isSelectedDataArray = newSelectedCheckedValued &&  newSelectedCheckedValued.length >0 &&
                    newSelectedCheckedValued[0].optionValue.filter(option => (option.isSelected === true));
                
                // let final =[];
                // let hel = isSelectedDataArray &&
                // isSelectedDataArray.length > 0 && isSelectedDataArray.map(array => {
                //     conditionalFormDataIdList && conditionalFormDataIdList.length >0 && conditionalFormDataIdList.map(
                //         list => {
                //             if(list.data === array.optionValueId){
                //                 const data = {
                //                     conditionalFormDataId : list.conditionalFormDataId,
                //                     conditionalFormId: checkboxConditionalFormId,
                //                     data: array.optionValueId
                //                 }
                //                 final.push(data);
                //             }else{
                //                 const data = {
                //                     // conditionalFormDataId : list.conditionalFormDataId,
                //                     conditionalFormId: checkboxConditionalFormId,
                //                     data: array.optionValueId
                //                 }
                //                 final.push(data);
                //             }
                //         }
                //     )})
                const final = isSelectedDataArray &&
                    isSelectedDataArray.length > 0 && isSelectedDataArray.map(array => {
                        return{
                            conditionalFormId: checkboxConditionalFormId,
                            data: array.optionValueId
                        }
                       })
                newSelectedCheckedValued[0].conditionalFormData = final;
                // state.selectedCheckboxOptions = final
            }
            return {
                ...state,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_CONTACT_VALUE:
            const conditionalFormId = action.conditionalFormId;
            const conditionalSelectedContactValue = action.selectedContact;
            const conditionalContactData = action.contactData

            const newSelectedConditionalContactData = state.conditionalFormData && state.conditionalFormData.length > 0
                && state.conditionalFormData.filter(data => data.refId === action.refId)[0].
                conditionalForms.filter(form => form.conditionalFormId === conditionalFormId)

            if(newSelectedConditionalContactData[0].conditionalFormData) {
                newSelectedConditionalContactData[0].conditionalFormData[0].data = conditionalSelectedContactValue
                if( newSelectedConditionalContactData[0].contact){
                    newSelectedConditionalContactData[0].contact.emailPhone = conditionalContactData[0].emailPhone
                    !== undefined ?conditionalContactData[0].emailPhone : ""
                    newSelectedConditionalContactData[0].contact.phone = conditionalContactData[0].phone
                    !== undefined ? conditionalContactData[0].phone : ""
                    newSelectedConditionalContactData[0].contact.address = conditionalContactData[0].address
                    !== undefined ? conditionalContactData[0].address : ""
                    newSelectedConditionalContactData[0].contact.contactType = conditionalContactData[0].contactType
                    !== undefined ? conditionalContactData[0].contactType : ""
                }
            }else{
                newSelectedConditionalContactData[0].conditionalFormData = [{
                    conditionalFormId: conditionalFormId,
                    data : conditionalSelectedContactValue
                }]
                newSelectedConditionalContactData[0].contact = {
                    emailPhone: conditionalContactData[0].emailPhone ? conditionalContactData[0].emailPhone  : "" ,
                    phone: conditionalContactData[0].phone ? conditionalContactData[0].phone : "",
                    address: conditionalContactData[0].address? conditionalContactData[0].address : "",
                    contactType: conditionalContactData[0].contactType? conditionalContactData[0].contactType : "",
                }
            }
            return {
                ...state,
                id: action.id,
                loading: false,
            }
        case ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_SET_SELECTED_OPTION:
            const selected = {
                conditionalFormId: action.conditionalFormId,
                data: action.data,
            }
            state.selectedCheckboxOptions = [...state.selectedCheckboxOptions, selected];
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};


export default dynamicActionState;