import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class TransactionAPI extends API {
  fetchTransactionList = (callback) => {
    fetch(APIEndPoints.FETCH_TRANSACTION, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchTransactionDeleted = (callback) => {
    fetch(APIEndPoints.FETCH_TRANSACTION_DELETED, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchSingleTransaction = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_TRANSACTION_BY_ID + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransaction = (exportType, callback) => {
    const urlString = APIEndPoints.EXPORT_TRANSACTION + exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // *****export by date
  exportTransactionByDate = (from, to, callback) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_WITH_DATE + from + "&to=" + to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByTerm = (term, exportType, callback) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_WITH_TERM + term + "&e=" + exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByMedium = (medium, callback) => {
    const urlString = APIEndPoints.EXPORT_TRANSACTION_WITH_MEDIUM + medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByStatus = (status, callback) => {
    const urlString = APIEndPoints.EXPORT_TRANSACTION_WITH_STATUS + status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByStatusAndMedium = (status, medium, callback) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_WITH_STATUS +
      status +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByDateAndMedium = (from, to, medium, callback) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_WITH_DATE +
      from +
      "&to=" +
      to +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByDateAndStatus = (from, to, status, callback) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_WITH_DATE +
      from +
      "&to=" +
      to +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportTransactionByDateAndStatusAndMedium = (
    from,
    to,
    status,
    medium,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_WITH_DATE +
      from +
      "&to=" +
      to +
      "&status=" +
      status +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // EXPORT TRANSACTION BY ALL
  exportTransactionByAll = (
    medium,
    status,
    type,

    from,
    to,
    vendor,
    exportType,

    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_BY_ALL +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&vendor=" +
      vendor +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // EXPORT TRANSACTION BY COLUMN FILTER
  exportTransactionByColumnFilter = (
    transactionMedium,
    transactionType,

    transactionStatus,

    exportType,
    description,
    checkNo,
    userName,
    vendorName,
    balance,
    compareType,
    transDate,
    filterDebitAmount,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_BY_COLUMN_FILTER +
      "transactionType=" +
      transactionType +
      "&transactionMedium=" +
      transactionMedium +
      "&transactionStatus=" +
      transactionStatus +
      "&e=" +
      exportType +
      "&description=" +
      description +
      "&checkNo=" +
      checkNo +
      "&userName=" +
      userName +
      "&vendorName=" +
      vendorName +
      "&balance=" +
      balance +
      "&compareType=" +
      compareType +
      // "&transactionDate=" +
      // transDate +
      "&amount=" +
      filterDebitAmount;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // EXPORT TRANSACTION VOD
  exportTransactionVOD = (ClientID, from, to, exportType, callback) => {
    const urlString =
      APIEndPoints.EXPORT_TRANSACTION_VOD +
      ClientID +
      "?from=" +
      from +
      "&to=" +
      to +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  //EXPORT BY CLIENT ID
  exportSingleClientTransaction = (id, exportType, callback) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION + id + "?e=" + exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByDate = (from, to, clientId, callback) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByTerm = (
    term,
    clientId,
    exportType,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&term=" +
      term +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByMedium = (medium, clientId, callback) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByStatus = (status, clientId, callback) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByDateAndMedium = (
    from,
    to,
    medium,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&from=" +
      from +
      "&to=" +
      to +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByDateAndStatus = (
    from,
    to,
    status,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&from=" +
      from +
      "&to=" +
      to +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByStatusAndMedium = (
    status,
    medium,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&status=" +
      status +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportSingleClientTransactionByDateAndStatusAndMedium = (
    from,
    to,
    status,
    medium,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&from=" +
      from +
      "&to=" +
      to +
      "&status=" +
      status +
      "&medium=" +
      medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  getClientTransaction = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_INDIVIDUAL_CLIENT_TRANSACTION + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  // SEARCH IN TRANSACTION PAGE
  searchTransaction = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_TRANSACTION + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByMedium = (medium, callback) => {
    const urlString = APIEndPoints.SEARCH_TRANSACTION_BY_MEDIUM + medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByStatus = (status, callback) => {
    const urlString = APIEndPoints.SEARCH_TRANSACTION_BY_STATUS + status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByDate = (from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_TRANSACTION_BY_DATE + from + "&to=" + to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByMediumAndStatus = (medium, status, callback) => {
    const urlString =
      APIEndPoints.SEARCH_TRANSACTION_BY_MEDIUM + medium + "&status=" + status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByMediumAndDate = (medium, from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_TRANSACTION_BY_MEDIUM +
      medium +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByStatusAndDate = (status, from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_TRANSACTION_BY_STATUS +
      status +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchTransactionByAll = (
    medium,
    status,
    type,
    from,
    to,
    vendor,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_TRANSACTION_BY_ALL +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&vendor=" +
      vendor;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  filterTransactionByAll = (
    description,
    checkNo,
    userName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    balance,
    debitCreditAmount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FETCH_TRANSACTION +
      "?balance=" +
      balance +
      "&description=" +
      description +
      "&checkNo=" +
      checkNo +
      "&referenceId=" +
      checkNo +
      "&userName=" +
      userName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      "&transactionType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&amount=" +
      debitCreditAmount +
      "&compareType=" +
      compareType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  filterTransactionByClient = (
    id,
    description,
    checkNo,
    // userName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    balance,
    debitCreditAmount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FETCH_INDIVIDUAL_CLIENT_TRANSACTION +
      id +
      "?balance=" +
      balance +
      "&description=" +
      description +
      "&checkNo=" +
      checkNo +
      "&referenceId=" +
      checkNo +
      // "&userName=" +
      // userName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      "&transactionType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&amount=" +
      debitCreditAmount +
      "&compareType=" +
      compareType;

    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  filterLedgerByAll = (
    description,
    checkNo,
    userName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    balance,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FETCH_LEDGER_TRANSACTION +
      "balance=" +
      balance +
      "&description=" +
      description +
      "&referenceId=" +
      checkNo +
      "&userName=" +
      userName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      "&transactionType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&compareType=" +
      compareType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  filterLedgerByClient = (
    id,
    description,
    checkNo,
    userName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    balance,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FETCH_CLIENT_LEDGER_TRANSACTION +
      id +
      "?balance=" +
      balance +
      "&description=" +
      description +
      "&referenceId=" +
      checkNo +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      "&transactionType=" +
      transactionType +
      "&transactionStatus=" +
      transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&compareType=" +
      compareType;

    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // CLIENT SEARCH
  searchClientTransaction = (value, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION + clientId + "?term=" + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByDate = (from, to, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION_BY_DATE +
      clientId +
      "?from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByMedium = (medium, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION + clientId + "?medium=" + medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByStatus = (status, clientId, callback) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION + clientId + "?status=" + status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByMediumAndStatus = (
    medium,
    status,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION +
      clientId +
      "?medium=" +
      medium +
      "&status=" +
      status;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByMediumAndDate = (
    medium,
    from,
    to,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION +
      clientId +
      "?medium=" +
      medium +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByStatusAndDate = (
    status,
    from,
    to,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION +
      clientId +
      "?status=" +
      status +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchClientTransactionByAll = (
    medium,
    status,
    type,
    from,
    to,
    vendor,
    clientId,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_CLIENT_TRANSACTION +
      clientId +
      "?medium=" +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&vendor=" +
      vendor;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //submit transaction
  fetchApprovedTransactionList = (callback) => {
    fetch(APIEndPoints.FETCH_APPROVED_TRANSACTIONS, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //fetch amount details transaction by client id
  getAmountDetailByClientId = (clientId, callback) => {
    fetch(APIEndPoints.GET_AMOUNT_DETAIL_BY_CLIENT_ID + clientId, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //fetch created transaction
  fetchCreatedTransactionList = (callback) => {
    fetch(APIEndPoints.FETCH_CREATED_TRANSACTION, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //withdrawal vendor list
  fetchWithdrawalVendorList = (callback) => {
    fetch(APIEndPoints.FETCH_WITHDRAWAL_TRANSACTION, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  updateTransactionStatus = (transData, callback) => {
    fetch(APIEndPoints.UPDATE_TRANSACTION_STATUS, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(transData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  undoReverseTranx = (transData, callback) => {
    fetch(APIEndPoints.UNDO_REVERSE_TRANX, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(transData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  updateLedgerTransactionStatus = (transData, callback) => {
    fetch(APIEndPoints.UPDATE_TRANSACTION_STATUS, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(transData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  //create transaction
  createTransaction = (transData, callback) => {
    fetch(APIEndPoints.CREATE_TRANSACTION, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(transData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  // update transaction
  updateTransaction = (transData, callback) => {
    fetch(APIEndPoints.CREATE_TRANSACTION, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(transData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  //delete transaction
  deleteTransaction = (transactionId, callback) => {
    const urlString = APIEndPoints.DELETE_TRANSACTION_BY_ID + transactionId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  // add trasaction note
  addTransactionNotes = (noteData, callback) => {
    fetch(APIEndPoints.ADD_TRANSACTION_NOTES, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(noteData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  // update description
  addDescription = (description, callback) => {
    fetch(APIEndPoints.UPDATE_TRANSACTION_DESCRIPTION, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(description),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  // update Check
  updateCheck = (checkData, callback) => {
    fetch(APIEndPoints.CHECK_TRANSACTION_DECLINE, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(checkData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  //export individual transaction by all
  exportSingleClientTransactionByAll = (
    medium,
    status,
    type,
    from,
    to,
    vendor,
    clientId,
    exportType,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER +
      clientId +
      "&medium=" +
      medium +
      "&status=" +
      status +
      "&type=" +
      type +
      "&from=" +
      from +
      "&to=" +
      to +
      "&vendor=" +
      vendor +
      "&e=" +
      exportType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //export client transaction by column filter
  exportSingleClientTransactionByColumnFilter = (
    clientId,
    transactionMedium,
    transactionType,

    transactionStatus,

    exportType,
    description,
    checkNo,
    userName,
    vendorName,
    balance,
    compareType,
    transDate,
    debitCreditAmount,
    callback
  ) => {
    const urlString =
      APIEndPoints.EXPORT_SINGLE_CLIENT_TRANSACTION +
      clientId +
      "?transactionType=" +
      transactionType +
      "&transactionMedium=" +
      transactionMedium +
      "&transactionStatus=" +
      transactionStatus +
      "&e=" +
      exportType +
      "&description=" +
      description +
      "&checkNo=" +
      checkNo +
      "&userName=" +
      userName +
      "&vendorName=" +
      vendorName +
      "&balance=" +
      balance +
      "&compareType=" +
      compareType +
      // "&transactionDate=" +
      // transDate +
      "&amount=" +
      debitCreditAmount;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  uploadCSVFileToImport = (csvFile, callback) => {
    fetch(APIEndPoints.IMPORT_TRANSACTION, {
      method: "POST",
      headers: this.authHeadersForMultipartFormData,
      body: csvFile,
      mimeType: "multipart/form-data",
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling update picture api", error);
        callback(null, "Service error, please try again.");
      });
  };

  getBankDetails = (callback) => {
    fetch(APIEndPoints.GET_BANK_DETAILS, {
      method: "get",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling update picture api", error);
        callback(null, "Service error, please try again.");
      });
  };

  //download ach doc
  downloadACHDoc = (id, callback) => {
    const constUrl = APIEndPoints.DOWNLOAD_ACH_DOCUMENT + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  //ledger transaction
  fetchLedgerTransactionList = (from, to, status, type, term, callback) => {
    fetch(
      APIEndPoints.FETCH_LEDGER_TRANSACTION +
        "from=" +
        from +
        "&to=" +
        to +
        "&status=" +
        status +
        "&type=" +
        type +
        "&term=" +
        term,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  exportLedgerTransactionList = (
    from,
    to,
    status,
    type,
    term,
    exportType,
    callback
  ) => {
    fetch(
      APIEndPoints.EXPORT_LEDGER_TRANSACTION +
        "from=" +
        from +
        "&to=" +
        to +
        "&status=" +
        status +
        "&type=" +
        type +
        "&term=" +
        term +
        "&e=" +
        exportType,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // export by column filter in ledger
  exportLedgerTransactionListBYColumnFilter = (
    description,
    checkNo,
    userName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    balance,
    compareType,
    exportType,
    callback
  ) => {
    fetch(
      APIEndPoints.EXPORT_LEDGER_TRANSACTION +
        "balance=" +
        balance +
        "&description=" +
        description +
        "&referenceId=" +
        checkNo +
        "&userName=" +
        userName +
        "&vendorName=" +
        vendorName +
        "&transactionMedium=" +
        transactionMedium +
        "&transactionType=" +
        transactionType +
        "&transactionStatus=" +
        transactionStatus +
        // "&transactionDate=" +
        // transactionDate +
        "&compareType=" +
        compareType +
        "&e=" +
        exportType,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  //cleint ledger transaction
  fetchClientLedgerTransactionList = (
    clientId,
    from,
    to,
    status,
    type,
    term,
    callback
  ) => {
    fetch(
      APIEndPoints.FETCH_CLIENT_LEDGER_TRANSACTION +
        clientId +
        "?from=" +
        from +
        "&to=" +
        to +
        "&status=" +
        status +
        "&type=" +
        type +
        "&term=" +
        term,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  updateBlockedTrans = (blockedData, callback) => {
    fetch(APIEndPoints.UPDATE_BLOCKED_TRANSACTION, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(blockedData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
  exportCleintLedgerTransactionList = (
    clientId,
    from,
    to,
    status,
    type,
    term,
    exportType,
    callback
  ) => {
    fetch(
      APIEndPoints.EXPORT_CLIENT_LEDGER_TRANSACTION +
        clientId +
        "?from=" +
        from +
        "&to=" +
        to +
        "&status=" +
        status +
        "&type=" +
        type +
        "&term=" +
        term +
        "&e=" +
        exportType,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // export by column filter
  exportCleintLedgerTransactionListByColumnFilter = (
    clientId,
    description,
    checkNo,
    userName,
    vendorName,
    transactionMedium,
    transactionType,
    transactionStatus,
    transactionDate,
    balance,
    compareType,
    exportType,
    callback
  ) => {
    fetch(
      APIEndPoints.EXPORT_CLIENT_LEDGER_TRANSACTION +
        clientId +
        "?balance=" +
        balance +
        "&description=" +
        description +
        "&referenceId=" +
        checkNo +
        "&userName=" +
        userName +
        "&vendorName=" +
        vendorName +
        "&transactionMedium=" +
        transactionMedium +
        "&transactionType=" +
        transactionType +
        "&transactionStatus=" +
        transactionStatus +
        // "&transactionDate=" +
        // transactionDate +
        "&compareType=" +
        compareType +
        "&e=" +
        exportType,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  // save config
  saveConfig = (data, callback) => {
    const urlString =
      APIEndPoints.SAVE_FILTER_CONFIG +
      "description=" +
      data.description +
      "&balance=" +
      data.balance +
      "&checkNo=" +
      data.checkNo +
      "&userName=" +
      data.userName +
      "&vendorName=" +
      data.vendorName +
      "&transactionMedium=" +
      data.transactionMedium +
      "&transactionType=" +
      data.transactionType +
      "&transactionStatus=" +
      data.transactionStatus +
      "&transactionDate=" +
      data.transactionDate +
      "&configName=" +
      data.configName +
      "&type=" +
      data.configType +
      "&compareType=" +
      data.compareType +
      "&sortBy=" +
      data.sortBy;
    fetch(urlString, {
      method: "POST",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
}
export default new TransactionAPI();
