import {connect} from 'react-redux';
import CalendarViewScreen from "../../screens/CalendarViewScreen";
import {
    caseRequestMade,
    closeSnackBarForCase,
    detailsForCalendarRequestMade, fetchDataForCalendarSuccess,
    fetchDataForCalendarFailure,
    registerCaseFailure,
    setCaseToEdit
} from "../../actions";
import {CaseAPI, SettingsAPI} from "../../api";
import {getWorkflow} from "../../actions/CaseAction";
import CalendarApi from "../../api/CalendarApi";
import {searchCalendarDataFailure, searchCalendarDataSuccess} from "../../actions/CalendarAction";
import {
    addHolidaysFailure,
    addHolidaysSuccess, clearModalData, fetchHolidaysFailure, fetchHolidaysSuccess, handleChangeCalendarType,
    handleChangeHolidayName, makeSuccessToFalse,
    requestInCalendarSetting
} from "../../actions/SettingsAction";
const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataListForCalendar: () => {
            dispatch(detailsForCalendarRequestMade(true));
            CaseAPI.fetchDataListForCalendar((response, error) => {
                if (response) {
                    dispatch(fetchDataForCalendarSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchDataForCalendarFailure(error.msg))
                    } else {
                        dispatch(fetchDataForCalendarFailure(error));
                    }
                }
            })
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForCase(true)),
        getWorkflowName: (workflowName) => dispatch(getWorkflow(workflowName)),
        getCaseInfo: (caseId, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.fetchSingleCase(caseId, (response, error) => {
                if (response) {
                    dispatch(caseRequestMade(true));
                    dispatch(setCaseToEdit(response.case));
                } else {
                    if (error.msg) {
                        dispatch(registerCaseFailure(error.msg));
                    } else {
                        dispatch(registerCaseFailure(error));
                    }
                }
            })
        },
        searchCalendarData: (value, props) =>{
            dispatch(detailsForCalendarRequestMade(true));
            CalendarApi.searchCalendar(value,(response, error) => {
                if (response) {
                    dispatch(searchCalendarDataSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchCalendarDataFailure(error.msg))
                    } else {
                        dispatch(searchCalendarDataFailure(error));
                    }
                }
            })
        },
        // add events
        handleChangeHolidayName: (holidayName) =>{dispatch(handleChangeHolidayName(holidayName))},
        handleChangeCalendarType: (calendarType) => {
            dispatch(handleChangeCalendarType(calendarType));
        },
        addHolidays : (holidayData, props) => {
            dispatch(requestInCalendarSetting(true));
            SettingsAPI.addHolidays(holidayData, (response, error) => {
                if (response) {
                    props.fetchDataListForCalendar();
                    props.fetchHolidaysList();
                    dispatch(addHolidaysSuccess(true, response));

                } else {
                    if (error.msg) {
                        dispatch(addHolidaysFailure(error.msg));
                    } else {
                        dispatch(addHolidaysFailure(false));
                    }
                }
            });
        },
        setToFalse : () => {dispatch(makeSuccessToFalse(false))},
        clearModalData: () => {dispatch(clearModalData())},
        fetchHolidaysList: () => {
            dispatch(requestInCalendarSetting(true));
            SettingsAPI.fetchHolidayDetails((response, error) => {
                if (response) {
                    dispatch(fetchHolidaysSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchHolidaysFailure(error.msg))
                    } else {
                        dispatch(fetchHolidaysFailure(error));
                    }
                }
            })
        },
    }
}
export const CalendarViewContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarViewScreen);