import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import LineChartTransaction from "./LineChart";
import PieChartComponent from "./PieChart";
import { HelmetComponent } from "../components/Helmet";
import { LocalDb } from "../api";

class DashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortType: "asc",
    };
  }
  convert = (str) => {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [month, day, date.getFullYear()].join("/");
  };
  componentDidMount() {
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;
    const isClient = userType === "CLIENT_USER";
    this.props.fetchOtherStatForDashboard();
    this.props.fetchDataForDashboard();
    this.props.fetchTransactionDataForDashboard();

    let now = new Date();
    let last30days = new Date(now.setDate(now.getDate() - 30));
    // this.props.searchTransactionByAll(
    //   "UNKNOWN_TRANSACTION_MEDIUM",
    //   "UNKNOWN_TRANSACTION_STATUS",
    //   "UNKNOWN_TRANSACTION_TYPE",
    //   new Date(this.convert(last30days)).getTime(),
    //   new Date(this.convert(new Date())).getTime(),
    //   ""
    // );
    this.props.fetchTransactionList();
  }

  render() {
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;
    const isClient =
      userType === "CLIENT_USER" &&
      userInfoFromDB &&
      userInfoFromDB.loginResponse.loginUser.client.isEnableTransaction;
    const dashboardState = this.props.state && this.props.state.dashBoardState;
    const dashboardData = dashboardState && dashboardState.dashboardData;
    const dashboardStatus = dashboardState && dashboardState.dashboardStatus;

    // const dashboardState = this.props && this.props.state.dashBoardState;
    const globalNavigation = dashboardState && dashboardState.globalNavigation;
    const transactionState =
      this.props.state && this.props.state.transactionState;
    const transactionList =
      transactionState && transactionState.transactionData;
    const transactionStat = dashboardState && dashboardState.transactionStat;

    const clientState = this.props.state && this.props.state.clientState;
    const clientList = clientState && clientState.client;
    const clientLength = clientList && clientList.length;

    const corporateState = this.props.state && this.props.state.corporateState;
    const corporateList = corporateState && corporateState.corporate;
    const corporateLength = corporateList && corporateList.length;

    const vendorState = this.props.state && this.props.state.vendorState;
    const vendorList = vendorState && vendorState.vendorAccount;
    const vendorLength = vendorList && vendorList.length;

    const lineChartData =
      transactionList &&
      transactionList.map((list) => {
        let unixTime = parseInt(list.createdAt);
        let amount = list.amount ? list.amount.replace("-", "") : "";
        return {
          day: new Date(unixTime).getDate(),
          value: amount / 100,
        };
      });

    const sorted = lineChartData
      ? lineChartData.sort(function (a, b) {
          return new Date(a.day) - new Date(b.day);
        })
      : [];

    const values = [
      { id: 10, name: "someName1" },
      { id: 10, name: "someName2" },
      { id: 11, name: "someName3" },
      { id: 11, name: "someNam4e3" },
      { id: 12, name: "someName4" },
    ];

    const lookup = values.reduce((a, e) => {
      a[e.id] = ++a[e.id] || 0;
      return a;
    }, {});

    const caseData = [
      {
        type: "Pending Cases",
        value: dashboardData.pendingCases ? dashboardData.pendingCases : 0,
      },
      {
        type: "Completed Cases",
        value: dashboardData.completedCases ? dashboardData.completedCases : 0,
      },
      {
        type: "In-progress Cases",
        value: dashboardData.inProgressCases
          ? dashboardData.inProgressCases
          : 0,
      },
      {
        type: "Blocked Cases",
        value: dashboardData.blockedCases ? dashboardData.blockedCases : 0,
      },
    ];

    const transactionData = [
      {
        type: "Created",
        value: transactionStat.pendingTransaction
          ? transactionStat.pendingTransaction
          : 0,
      },
      {
        type: "Approved",
        value: transactionStat.approvedTransaction
          ? transactionStat.approvedTransaction
          : 0,
      },
      {
        type: "Submitted",
        value: transactionStat.submittedTransaction
          ? transactionStat.submittedTransaction
          : 0,
      },
      {
        type: "Posted",
        value: transactionStat.postedTransaction
          ? transactionStat.postedTransaction
          : 0,
      },
      {
        type: "Declined",
        value: transactionStat.declinedTransaction
          ? transactionStat.declinedTransaction
          : 0,
      },
    ];
    const dataList = [
      {
        name: "Total Cases",
        src: "/assets/images/TotalCases.svg",
        alt: "cases",
        totalNum: dashboardData.totalCases ? dashboardData.totalCases : 0,
      },
      {
        name: "Total Clients",
        src: "/assets/images/Total client.svg",
        alt: "client",
        totalNum: dashboardStatus.totalClient ? dashboardStatus.totalClient : 0,
        hide: isClient,
      },
      {
        name: "Total Transactions",
        src: "/assets/images/TotalTransaction.svg",
        alt: "transaction",
        totalNum: transactionStat.totalTransaction
          ? transactionStat.totalTransaction
          : 0,
      },
      {
        name: "Total Corporate",
        src: "/assets/images/TotalCorporate.svg",
        alt: "corporate",
        totalNum: dashboardStatus.totalCorporate
          ? dashboardStatus.totalCorporate
          : 0,
        hide: isClient,
      },
      {
        name: "Total Vendors",
        src: "/assets/images/TotalVendor.svg",
        alt: "vendor",
        totalNum: dashboardStatus.totalVendor ? dashboardStatus.totalVendor : 0,
        hide: isClient,
      },
    ];

    return (
      <React.Fragment>
        <HelmetComponent title="Dashboard" />
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Typography color="inherit" className="link">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Typography>
        </Breadcrumbs>
        <div className="container-fluid">
          <div className="total-div row">
            {dataList &&
              dataList.map((data) => (
                <div
                  hidden={data.hide}
                  className="dashboard-box col-lg-2 col-md-4 col-sm-6"
                  style={{ marginTop: "12px", marginLeft: "12px" }}
                >
                  <div className="image-div">
                    <img src={data.src} alt={data.alt} />
                  </div>
                  <div className="ml-12">
                    <div className="define-number">{data.totalNum}</div>
                    <div className="define-title">{data.name}</div>
                  </div>
                </div>
              ))}
          </div>
          {/*<div className="total-div">*/}
          {/*    {*/}
          {/*        dataList && dataList.map(data =>*/}
          {/*        <div className="dashboard-box">*/}
          {/*                <div className="image-div">*/}
          {/*                    <img src={data.src} alt={data.alt}/>*/}
          {/*                </div>*/}
          {/*                <div className="ml-12">*/}
          {/*                    <div className="define-number">{data.totalNum}</div>*/}
          {/*                    <div className="define-title">{data.name}</div>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        )*/}
          {/*    }*/}
          {/*</div>*/}
          <div className="line-chart-div">
            <LineChartTransaction lineChartData={sorted} />
          </div>
          <div className="row">
            <div className="pie-div col-5">
              <PieChartComponent
                data={caseData ? caseData : []}
                title="Cases Overview"
                statisticTitle="Total Cases"
              />
            </div>
            <div className="pie-div col-6" style={{ maxWidth: "47.8%" }}>
              <PieChartComponent
                data={transactionData}
                title="Transactions Overview"
                statisticTitle="Total Transaction"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardScreen;
