import { ACTION_TYPE_WORKFLOW_FORM } from "../constants/Type";

export const workflowFormRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.WORKFLOW_FORM_REQUEST_MADE,
        bool
    };
};

export const fetchActionSuccess = action => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.ACTION_FETCH_SUCCESS,
        action
    };
};

export const fetchActionFailure = error => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.ACTION_FETCH_FAILURE,
        error
    };
};

export const addActionSuccess = bool => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.ADD_ACTION_SUCCESS,
        bool
    };
};

export const handleChangeAction = (id, value) => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.HANDLE_CHANGE_ACTION,
        id,
        value
    };
};

export const handleChangeWorkflow = (id, value) => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.HANDLE_CHANGE_WORKFLOW,
        id,
        value
    };
};

export const addWorkflowSuccess = bool => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.WORKFLOW_ADD_SUCCESS,
        bool
    };
};

export const addWorkflowFailure = error => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.WORKFLOW_ADD_FAILURE,
        error
    };
};

export const setWorkflowToEdit = (workflowData) => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.SET_WORKFLOW_TO_EDIT,
        workflowData
    }
};

export const closeSnackBarForWorkflowForm = (bool) => {
    return {
        type: ACTION_TYPE_WORKFLOW_FORM.CLOSE_SNACK_BAR_WORKFLOW_FORM_PAGE,
        bool
    };
};