import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {Button, CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Checkbox, DatePicker, Select} from "antd";
import moment from "moment";
import {Prompt} from "react-router";
import Typography from "@material-ui/core/Typography";

const {Option} = Select;

class ClientEditFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: props.location.pathname.replace('/client/edit/', ''),
            fromBack: false,
            setValue: false,
            selectedSsnValue: '',
            ssn: [],
            selectedData: [],
            visible: true,
            valid: false,
            changed: false,
            isChangedFirst: false,
            contactVisible: false,
            editContact: [],
            openDeleteContactDialogue: false,
            isBlocking: false,
            id: "",
        };
        if (props.location.pathname.replace('/client/edit/', '').length > 0) {
            this.props.getClientInfo(this.state.clientId)
        }
    }

    componentWillMount() {
        this.props.fetchClientList();
    }

    componentWillUnmount() {
        this.props.handleSnackBarClose();
    }


    render() {
        const clientData = this.props.state.clientState.clientData;
        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
            return moment(dateConverted, "YYYY-MM-DD");
        };
        const handleChangeDate = (range) => {
            this.setState({
                isBlocking: true,
            })
            if (range) {
                const valueOfInput1 = range.format();
                this.props.handleChangeClient("dob", new Date(valueOfInput1).getTime());
            }
        }
        const handleChangeDateContact = (range) => {
            if (range) {
                const valueOfInput1 = range.format();
                const id = this.state.id;
                changeContactFieldDobValue(id, new Date(valueOfInput1).getTime());
            }
        }

        const handleClickOpenDeleteContactDialogue = contactId => {
            this.setState({
                openDeleteContactDialogue: true,
                contactId: contactId
            });
        };
        const handleCloseDeleteContactDialogue = () => {
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };
        const handleOkDeleteContactDialogue = () => {
            closeContactFieldSection(this.state.contactId);
            this.setState({
                openDeleteContactDialogue: false,
                contactId: ""
            });
        };
        const showInputForField = () => {
            let a = createAddContactObject();
            this.setState({
                openDropdownForContact: true,
                editContact: [...this.state.editContact, a]
            });
        };
        const createAddContactObject = () => {
            let formData = {
                contactId: String(Math.random()),
                name: '',
                emailPhone: '',
                contactType: 'UNKNOWN_CONTACT_TYPE',
                phone: '',
                address: '',
                dob: 0,
            };
            return formData;
        };
        const changeContactFieldNameValue = (key, value) => {
            let newContact = this.state.editContact.filter(single => single.contactId === key)[0];
            newContact.name = value;
            let otherContacts = this.state.editContact.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContact.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContact: otherContacts});
            this.props.handleChangeClient("editContact", this.state.editContact)

        };
        const changeContactFieldEmailValue = (key, value) => {
            let newContact = this.state.editContact.filter(single => single.contactId === key)[0];
            newContact.emailPhone = value;
            let otherContacts = this.state.editContact.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContact.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContact: otherContacts});
            this.props.handleChangeClient("editContact", this.state.editContact)
        };
        const changeContactFieldTypeValue = (key, value) => {
            let newContact = this.state.editContact.filter(single => single.contactId === key)[0];
            newContact.contactType = value;
            let otherContacts = this.state.editContact.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContact.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContact: otherContacts});
            this.props.handleChangeClient("editContact", this.state.editContact)
        };
        const changeContactFieldPhoneValue = (key, value) => {
            let newContact = this.state.editContact.filter(single => single.contactId === key)[0];
            newContact.phone = value;
            let otherContacts = this.state.editContact.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContact.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContact: otherContacts});
            this.props.handleChangeClient("editContact", this.state.editContact)
        };
        const changeContactFieldAddressValue = (key, value) => {
            let newContact = this.state.editContact.filter(single => single.contactId === key)[0];
            newContact.address = value;
            let otherContacts = this.state.editContact.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContact.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContact: otherContacts});
            this.props.handleChangeClient("editContact", this.state.editContact)
        };
        const changeContactFieldDobValue = (key, value) => {
            let newContact = this.state.editContact.filter(single => single.contactId === key)[0];
            newContact.dob = value;
            let otherContacts = this.state.editContact.filter(single => single.contactId !== key);
            otherContacts.splice(this.state.editContact.findIndex(single => single.contactId === key), 0, newContact);
            this.setState({editContact: otherContacts});
            this.props.handleChangeClient("editContact", this.state.editContact)
        };
        const closeContactFieldSection = key => {
            var removedActionData = this.state.editContact.filter(single => single.contactId !== key);
            this.setState({
                editContact: removedActionData
            }, () => {
                this.props.handleChangeClient("editContact", this.state.editContact)
            })
        };
        const openFieldForContact = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.editContact.length > 0) {
                div.push(<>
                    <h5 style={{
                        width: '100%',
                        color: '#8BC83F',
                        paddingTop: '1rem',
                        paddingBottom: '0.8rem',
                        fontWeight: '550'
                    }}>List of editContact</h5>
                    <div className='row'>
                        <div className="col-2">
                            <label htmlFor="contact" className="columnHeadingContactName">Name
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2" style={{paddingLeft: 0}}>
                            <label htmlFor="emailPhone" className="columnHeadingContactEmail">Email
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="emailPhone" className="columnHeadingContactType">Contact Type
                                <span style={{color: "red"}}> *</span></label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="phone" className="columnHeadingContactPhone">Phone Number</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="address" className="columnHeadingContactAddress">Address</label>
                        </div>
                        <div className="col-2">
                            <label htmlFor="dob" className="columnHeadingContactDob">Date-of-birth</label>
                        </div>
                    </div>
                </>)
            }

            if (this.state.editContact[0] != undefined && this.state.editContact[0].contactId) {
                this.state.editContact.map(single => {
                    return div.push(
                        <div className='row'>
                            <div className="col-2">
                                <input
                                    id={single.id}
                                    className="inputFieldContactName"
                                    value={single.name}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldNameValue(single.contactId, e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-2" style={{paddingRight: 0, paddingLeft: 0}}>
                                <input
                                    id={single.id}
                                    className="inputFieldContactEmail"
                                    value={single.emailPhone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldEmailValue(single.contactId, e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-2" style={{paddingRight: 0, maxWidth: '20%'}}>
                                <div  className="select-input selects h40" style={{width: "92%"}}>
                                    <Select
                                        id={single.id}
                                        value={single.contactType}
                                        style={{
                                            marginLeft: "-23px",
                                            marginTop: "6px",
                                        }}
                                        getPopupContainer={node => node.parentNode}
                                        onChange={e => {
                                            this.setState({isBlocking: true});
                                            changeContactFieldTypeValue(single.contactId, e.target.value)
                                        }}
                                        dropdownStyle={{height: "92px"}}
                                    >
                                        <option value hidden/>
                                        <option value="EVERFUND_USER_CONTACT">Everfund user</option>
                                        <option value="CORPORATE_CONTACT">Corporate</option>
                                        <option value="CLIENT_CONTACT">Client</option>
                                        <option value="CLIENT_RELATIVE_CONTACT">Client Relative</option>
                                        <option value="OTHER_CONTACT_TYPE">Other contact type</option>
                                    </Select>
                                    {/*<ExpandMoreOutlinedIcon style={style.expandMoreIconContact}/>*/}
                                </div>
                            </div>
                            <div className="col-2" style={{paddingRight: 0, maxWidth: '35%'}}>
                                <input
                                    id={single.id}
                                    className="inputFieldContactPhone"
                                    value={single.phone}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldPhoneValue(single.contactId, e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-2">
                                <input
                                    id={single.id}
                                    className="inputFieldContactAddress"
                                    value={single.address}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeContactFieldAddressValue(single.contactId, e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-1">
                                <div className="select-add-date-contact" onClick={() => {
                                    this.setState({isBlocking: true, id: single.contactId})
                                }}>
                                    <DatePicker
                                        value={single.dob && getDate(single.dob)}
                                        format="MM-DD-YYYY"
                                        onChange={handleChangeDateContact}
                                    />
                                </div>
                            </div>
                            <div className="col-1" style={{paddingLeft: '86px', position: 'inherit'}}>
                                <RemoveCircleOutlineIcon
                                    // id={single.id}
                                    style={{
                                        color: "grey",
                                        width: "24px",
                                        height: "24px",
                                        marginTop: "1.3rem",
                                        cursor: 'pointer'
                                    }}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        handleClickOpenDeleteContactDialogue(single.contactId);
                                        // closeContactFieldSection(single.contactId);
                                    }}
                                />
                            </div>
                        </div>
                    );
                });
            }
            return div;
        };
        const editClientAPICall = () => {
            this.props.editClient(this.props.state.clientState.clientData, this.props)
        };

        return (
            <React.Fragment>
                {this.state.setValue === false && clientData && clientData.editContact && clientData.editContact.length > 0 ?
                    this.setState({
                        editContact: clientData.editContact,
                        setValue: true
                    }) : null}

                {this.props.state.clientState.loading ? (
                    <div
                        className={
                            this.props.state.clientState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />
                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/client" style={style.link}>
                        Client
                    </Link>
                    <Typography color="inherit" className="link">
                        Update Client
                    </Typography>
                </Breadcrumbs>

                <div style={style.container}>
                    <div className="row" style={style.row}>
                        <div className="col-4" style={style.column1}>
                            <label htmlFor="ssn" style={style.columnHeading}>Social Security
                                Number <span style={{color: "red"}}> *</span></label>
                            <input
                                value={clientData && clientData.ssn}
                                readOnly
                                data-target="#ssn1" style={{
                                border: '0',
                                backgroundColor: 'rgb(244 245 249)',
                                color: 'rgb(130, 132, 129)',
                                fontSize: '14x',
                                height: '40px',
                                width: '90%',
                                paddingTop: '0px',
                                marginTop: '16px',
                                paddingLeft: '15px',
                            }}/>
                        </div>
                        <div className="col-4">
                            <label htmlFor="firstName" style={style.columnHeading}>First Name
                                <span style={{color: "red"}}> *</span></label>
                            <input
                                type='name'
                                id="firstName"

                                style={style.inputField}
                                value={clientData && clientData.firstName}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeClient(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-4">
                            <label htmlFor="lastName" style={style.columnHeading}>Last Name
                                <span style={{color: "red"}}> *</span></label>
                            <input
                                id="lastName"
                                autocomplete='off'
                                style={style.inputField}
                                value={clientData && clientData.lastName}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeClient(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className="row" style={{marginTop: '2rem'}}>
                        <div className="col-4" style={{position: 'inherit'}}>
                            <label style={style.columnHeading}>Date-Of-Birth
                                <span style={{color: "red"}}> *</span></label>
                            <div className="select-edit-date">
                                <DatePicker
                                    value={clientData && getDate(clientData.dob)}
                                    format="MM-DD-YYYY"
                                    onChange={handleChangeDate}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <label htmlFor="gender" style={style.columnHeading}>Gender
                                <span style={{color: "red"}}> *</span></label>
                            <div className="select-input selects h40" style={{marginTop: "12px", width: "90%"}}>
                                <Select
                                    id="gender"
                                    value={clientData && clientData.gender}
                                    onChange={(e) => {
                                        this.setState({isBlocking: true});
                                        this.props.handleChangeClient("gender", e);
                                    }}
                                >
                                    <Option value="MALE">Male</Option>
                                    <Option value="FEMALE">Female</Option>
                                    <Option value="OTHER">Other</Option>
                                </Select>
                            </div>
                        </div>

                        <div className="col-4">
                            <label htmlFor="maritalStatus" style={style.columnHeading}>Marital
                                Status <span style={{color: "red"}}> *</span></label>
                            <div className="select-input selects h40" style={{marginTop: "12px", width: "90%"}}>
                                <Select
                                    // className="custom-select"
                                    // style={style.inputField}
                                    value={clientData && clientData.maritalStatus}
                                    id="maritalStatus"
                                    onChange={(e) => {
                                        this.setState({isBlocking: true});
                                        this.props.handleChangeClient("maritalStatus", e);
                                    }}
                                >
                                    <Option value="SINGLE">Single</Option>
                                    <Option value="MARRIED">Married</Option>
                                    <Option value="DIVORCED">Divorced</Option>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '2rem'}}>
                        <div className="col-4">
                            <label style={style.columnHeading}>County</label>
                            <input
                                type='name'
                                id="county"
                                style={style.inputField}
                                value={clientData && clientData.county}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeClient(e.target.id, e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '2rem'}}>
                        <div className="col-4" style={{position: 'inherit'}}>
                            <Checkbox
                                id="isEnableTransaction"
                                checked={!!(clientData && clientData.isEnableTransaction)}
                                value={clientData && clientData.isEnableTransaction}
                                onChange={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeClient(e.target.id, e.target.checked);
                                }}
                                style={style.columnHeading}>
                                Enable Transaction
                            </Checkbox>
                        </div>
                    </div>

                    {!(this.state.editContact.length > 0) ?
                        <div style={{cursor: 'pointer', marginTop: '1.5rem', width: '12%'}}
                             onClick={e => {
                                 e.preventDefault();
                                 this.setState({
                                     isBlocking: false
                                 });
                                 showInputForField();
                             }}>
                            <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                 style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                            <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Contact</span>
                        </div>
                        :
                        <div style={{marginTop: '2rem'}}>
                            {openFieldForContact()}
                            <div style={{display: 'block', marginTop: '1rem'}}>
                            <span style={{cursor: 'pointer'}}
                                  onClick={e => {
                                      e.preventDefault();
                                      this.setState({isBlocking: false});
                                      showInputForField();
                                  }}>
                             <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                  style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add More</span>
                            </span>
                            </div>

                        </div>}


                    <div className='row'>
                        <div className="col-4"/>
                        <div className="col-4"/>
                        <div className="col-4">
                            <button
                                // disabled={!value}
                                className="btn btn-primary"
                                style={style.nextButton}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({isBlocking: false});
                                    editClientAPICall();
                                    // this.props.editClient(clientData, this.props)
                                }}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>

                <Dialog
                    open={this.state.openDeleteContactDialogue}
                    onClose={handleCloseDeleteContactDialogue}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{background: "#ffffff", borderRadius: "18px"}}>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                textAlign: "center",
                                width: '450px',
                                paddingTop: 0
                            }}
                        >
                            <DialogContentText>
                                <h3 style={{color: "#8BC83F", padding: "0.8rem"}}>
                                    Confirmation
                                </h3>
                                Do you want to delete contact?
                                <div
                                    className="row"
                                    style={{
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingBottom: "1rem",
                                        paddingTop: "1.6rem"
                                    }}
                                >
                                    <div className={"col-6"}>
                                        <Button
                                            autoFocus
                                            onClick={handleCloseDeleteContactDialogue}
                                            style={style.cancelButton}
                                        >
                                            cancel
                                        </Button>
                                    </div>
                                    <div className={"col-6"}>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                handleOkDeleteContactDialogue();
                                            }}
                                            style={style.addButton}
                                        >
                                            ok
                                        </Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </div>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.clientState.openClientSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.clientState.errorMsgForClient}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    container: {
        background: "#ffffff",
        margin: "78px 3% 3% 3%",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        paddingLeft: '3rem',
        paddingTop: '2rem'
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    columnHeading: {
        color: '#625F5F',
        marginBottom: '2px',
        fontSize: '16px',
        width: '100%'
    },
    columnHeadingContact: {
        color: '#625F5F',
        fontSize: '16px',
        width: '100%',
        marginBottom: 0
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    inputFieldContact: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '90%',
        paddingTop: '0px',
        marginTop: '10px',
        marginBottom: '15px',
        paddingLeft: '13px'
    },
    expandMoreIcon: {
        position: 'absolute',
        right: '14%',
        top: '63%',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        float: 'right',
        width: '50%',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '31px',
        marginRight: '10%',
        marginBottom: '1.5rem'
    },
    selectPlaceholder: {
        fontSize: '13px !important'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    select: {
        position: "relative",
        height: "40px",
        marginTop: '10px',
    },
    expandMoreIconContact: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: '#C8C5C5',
        fontSize: '35px',
    },
    addButton: {
        background: "#8BC83F",
        color: "white",
        width: "100%",
        padding: "0.5rem",
        border: "1px solid #8BC83F"
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
};

export default ClientEditFormScreen;
