import { ACTION_TYPE_CASE_TEMPLATE, ACTION_TYPE_WORKFLOW } from "../constants/Type";

const initialState = {
    loading: false,
    openCaseTemplateSnackBar: false,
    errorMsgForCaseTemplate: '',
    caseTemplateData: {
        templateName: '',
        workflow: [
            {
                workflowId: ''
            }
        ]
    }
};

const caseTemplateState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_CASE_TEMPLATE.CASE_TEMPLATE_FETCH_SUCCESS:
            return {
                ...state,
                caseTemplate: action.caseTemplate.caseTemplates,
                loading: false,
                openCaseTemplateSnackBar: false
            };
        case ACTION_TYPE_CASE_TEMPLATE.CASE_TEMPLATE_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForCaseTemplate: action.error,
                loading: false,
                openCaseTemplateSnackBar: true
            };
        case ACTION_TYPE_CASE_TEMPLATE.CASE_TEMPLATE_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_CASE_TEMPLATE.DELETE_CASE_TEMPLATE_SUCCESS:
            const oldTemplateList = state.caseTemplate;
            let newTemplate = [];
            for (let i = 0; i < oldTemplateList.length; i++) {
                if (oldTemplateList[i].templateId !== action.templateId) {
                    newTemplate.push(oldTemplateList[i]);
                }
            }
            return {
                ...state,
                newTemplate: newTemplate,
                loading: false,
                openCaseTemplateSnackBar: false
            };
        case ACTION_TYPE_CASE_TEMPLATE.DELETE_CASE_TEMPLATE_FAILURE:
            return {
                ...state,
                errorMsgForCaseTemplate: action.error,
                loading: false,
                openCaseTemplateSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW.WORKFLOW_FETCH_SUCCESS:
            return {
                ...state,
                workflow: action.workflow.workflows,
                loading: false,
                openCaseTemplateSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW.WORKFLOW_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForCaseTemplate: action.error,
                loading: false,
                openCaseTemplateSnackBar: true
            };
        case ACTION_TYPE_CASE_TEMPLATE.HANDLE_CHANGE_CASE_TEMPLATE:
            const newForm = { ...state.caseTemplateData };
            newForm[action.id] = action.value;
            return {
                ...state,
                caseTemplateData: newForm,
                loading: false
            };
        case ACTION_TYPE_CASE_TEMPLATE.ADD_CASE_TEMPLATE_SUCCESS:
            return {
                ...state,
                addCaseTemplateSuccess: action.bool,
                loading: false,
                openCaseTemplateSnackBar: false
            };
        case ACTION_TYPE_CASE_TEMPLATE.ADD_CASE_TEMPLATE_FAILURE:
            return {
                ...state,
                errorMsgForCaseTemplate: action.error,
                loading: false,
                openCaseTemplateSnackBar: true
            };
        case ACTION_TYPE_CASE_TEMPLATE.SET_CASE_TEMPLATE_TO_EDIT:
            return {
                ...state,
                caseTemplateData: action.caseTemplateData.caseTemplate,
                loading: false,
                openCaseTemplateSnackBar: false
            };
        case ACTION_TYPE_CASE_TEMPLATE.CLOSE_SNACK_BAR_CASE_TEMPLATE_PAGE:
            return {
                ...state,
                openCaseTemplateSnackBar: false,
            };
        default:
            return state;
    }

};

export default caseTemplateState;