import React, {useEffect} from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {formatter} from "../../utils/currencyConvertor";
import TablePaginationComponent from "../../components/TablePaginationComponent";

const AnnualTransactionTable = ({
                                    fetchAnnualTransaction,
                                    annualUpcomingTrans,
                                    searchAnnualUpcomingTransactionByTerm,
                                    page,
                                    rowsPerPage,
                                    setPage,
                                    tableRef,
                                    setRowPerPage}) => {
    useEffect(() => {
        fetchAnnualTransaction();
    }, []);
    return(
        <>
            <div className={"d-flex flex-row-reverse flexStyle"}>
                <div style={{ display: "flex" }}>
                    <div className="search">
                        <div className={ 'searchIcon'}>
                            <SearchIcon style={{ color: "#9c9c9c" }} />
                        </div>
                        <InputBase
                            className="inputBase"
                            placeholder="Search…"
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => {
                                e.preventDefault();
                                if (e.target.value === "" || e.target.value === " ") {
                                    fetchAnnualTransaction();
                                } else {
                                    searchAnnualUpcomingTransactionByTerm(
                                        e.target.value
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={"row table-card"}>
                <TableContainer className="fixed-table-header-second">
                    <Table size="small" stickyHeader aria-label="sticky table" ref={tableRef}>
                        <TableHead style={{ padding: "1rem" }} >
                            <TableRow>
                                <TableCell className="tableHeader">#</TableCell>
                                <TableCell
                                    className="first-tableHeader"
                                    style={{ paddingLeft: "60px" }}
                                >
                                    User
                                </TableCell>
                                <TableCell className="tableHeader">Medium</TableCell>
                                <TableCell className="tableHeaderAmount">
                                    Amount
                                </TableCell>
                                <TableCell
                                    className="tableHeader"
                                    style={{ paddingLeft: "150px", width: "450px" }}
                                >
                                    Transaction Type
                                </TableCell>
                                <TableCell className="tableHeader">
                                    Upcoming Transaction Date/Time
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {annualUpcomingTrans &&
                            annualUpcomingTrans
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((list, index) => (
                                    <TableBody key={list.scheduleTransactionId} >
                                        <TableRow>
                                            <TableCell>{((page + 1) - 1) * rowsPerPage + (index + 1)}</TableCell>
                                            <TableCell
                                                className="first-tableBody"
                                                style={{ width: "346px" }}
                                            >
                                                {list.client.firstName +
                                                    " " +
                                                    list.client.lastName}
                                            </TableCell>
                                            <TableCell
                                                className="tableBody"
                                                style={{ width: "260px" }}
                                            >
                                                {list.transactionMedium}
                                            </TableCell>
                                            <TableCell
                                                className="tableAmountBody"
                                                style={{ width: "60px" }}
                                            >
                                                {list.amount &&
                                                    formatter.format(list.amount / 100)}
                                            </TableCell>
                                            <TableCell
                                                className="tableBody"
                                                style={{ paddingLeft: "150px" }}
                                            >
                                                {list.transactionType &&
                                                    list.transactionType.replace("_", " ")}
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                {new Intl.DateTimeFormat("default", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    second: "2-digit",
                                                }).format(list.upcomingTxnDate)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                    </Table>
                    </TableContainer>
                    <TablePaginationComponent tableList={annualUpcomingTrans}
                                              rowsPerPage={rowsPerPage}
                                              page={page}
                                              setPage={setPage}
                                              setRowPerPage={setRowPerPage}
                    />
                {/* </TableContainer> */}
            </div>
        </>
    )
};

export default AnnualTransactionTable;