import APIEndPoints from "../constants/APIConstants";
import {API} from "./index";
class EmailTemplateAPI extends API {
    fetchEmailTemplate = callback => {
        const constURL = APIEndPoints.FETCH_EMAIL_TEMPLATE;
        fetch(constURL, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback)
        }).catch(() => {
            // console.log(error)
            callback(null, "Service error, please try again.")
        });
    };
    addEmailTemplate = (emailTemplateData, callback) => {
        let body = JSON.stringify(emailTemplateData);
        console.log('emailTemplateData', emailTemplateData);
        fetch(APIEndPoints.POST_EMAIL_TEMPLATE, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(() => {
            callback(null, "Service error, please try again.");
        });
    };
    editEmailTemplate = (emailTemplateData, callback) => {
        let body = JSON.stringify(emailTemplateData);
        fetch(APIEndPoints.UPDATE_EMAIL_TEMPLATE, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(() => {
            callback(null, "Service error, please try again.");
        });
    };
    deleteEmailTemplate = (id, callback) => {
        fetch(APIEndPoints.DELETE_EMAIL_TEMPLATE + id, {
            method: "DELETE",
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    //assign email type

    fetchEmailType = callback => {
        const constURL = APIEndPoints.FETCH_EMAIL_TYPE;
        fetch(constURL, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback)
        }).catch(() => {
            callback(null, "Service error, please try again.")
        });
    };
    addEmailType = (emailTypeData, callback) => {
        let body = JSON.stringify(emailTypeData);
        console.log('emailTemplateData', emailTypeData);
        fetch(APIEndPoints.ASSIGN_EMAIL_TYPE, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(() => {
            callback(null, "Service error, please try again.");
        });
    };
    editEmailType = (emailTypeData, callback) => {
        let body = JSON.stringify(emailTypeData);
        fetch(APIEndPoints.UPDATE_EMAIL_TYPE, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(() => {
            callback(null, "Service error, please try again.");
        });
    };
}

export default new EmailTemplateAPI();