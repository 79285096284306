import { connect } from "react-redux";
import ClientDetailPageScreen from "../../screens/ClientDetailPageScreen";
import {
  addNewScheduleTransactionFailure,
  addNewScheduleTransactionSuccess,
  caseTemplateRequestMade,
  clientRequestMade,
  closeSnackBarForClient,
  detailsForCalendarRequestMade,
  editScheduleTransactionFailure,
  editScheduleTransactionSuccess,
  fetchCaseTemplateFailure,
  fetchCaseTemplateSuccess,
  fetchDataForCalendarByClientFailure,
  fetchDataForCalendarByClientSuccess,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchUserFailure,
  fetchUserSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  handleChangeCaseInRegisterCase,
  handleChangeEmail,
  handleChangeStatus,
  profileRequestMade,
  registerCaseFailure,
  registerCaseRequestMade,
  registerCaseSuccess,
  scheduleTransactionRequestMade,
  setPasswordFailure,
  setPasswordSuccess,
  upComingTransactionRequestMade,
  userRequestMade,
  vendorRequestMade,
} from "../../actions";
import {
  ActivityLogAPI,
  CaseAPI,
  CaseTemplateAPI,
  ClientAPI,
  ContactApi,
  DynamicActionAPI,
  ProfileAPI,
  RegisterCaseAPI,
  SettingsAPI,
  TransactionAPI,
  UpcomingTransactionAPI,
  UserAPI,
  VendorAPI,
} from "../../api";
import {
  getClientTransactionFailure,
  getClientTransactionSuccess,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleChangeMedium,
  handleTransactionStatus,
  handleChangeTerm,
  handleChangeTransType,
  handleChangeVendor,
  searchClientTransactionByMediumAndStatusAndDateFailure,
  searchClientTransactionByMediumAndStatusAndDateSuccess,
  searchClientTransactionFailure,
  searchClientTransactionSuccess,
  transactionRequestMade,
  openSnackBar,
  exportSingleClientTransactionFailure,
  exportSingleClientTransactionSuccess,
  exportTransactionByStatusAndMediumFailure,
  exportTransactionByStatusAndMediumSuccess,
  exportSingleClientTransactionByTermFailure,
  exportSingleClientTransactionByTermSuccess,
  handleChangeTransactionStatus,
  handleChangeTransactionRemark,
  updateSubmitTransactionSuccess,
  updateSubmitTransactionFailure,
  setOpenCreateTransactionForm,
  handleChangeTransData,
  createTransactionFailure,
  createTransactionSuccess,
  getAmountDetailByClientIdSuccess,
  getAmountDetailByClientIdFailure,
  clearAmountDetail,
  clearCreateTransactionForm,
  closeClientTransactionSnackBar,
  // closeTransactionSnackBar,
  deleteTransactionSuccess,
  deleteTransactionFailure,
  exportTransactionVODRequest,
  exportTransactionVODSuccess,
  exportTransactionVODFailure,
  delcineTransCheckSuccess,
  delcineTransCheckFailure,
  updateBlockedTransSuccess,
  updateBlockedTransFailure,
  getTransConfigSuccess,
  getTransConfigFailure,
  handleTransactionFilter,
  clearTransactionFilter,
  saveTransConfigFailure,
  saveTransConfigSuccess,
  closeTransactionSnackBar,
  setTransactionInputDataToEdit,
  updateTransactionFailure,
  updateTransactionSuccess,
  handlChangeReverseDate,
} from "../../actions/TransactionAction";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
  deleteScheduleTransactionFailure,
  deleteScheduleTransactionSuccess,
  fetchScheduleTransactionByClientFailure,
  fetchScheduleTransactionByClientSuccess,
  handleShowFileUploadErrorMessage,
  uploadDocFileFailure,
  uploadDocFileSuccess,
  closeScheduleTransactionSnackBar,
  searchClientScheduleTransactionFailure,
  searchClientScheduleTransactionSuccess,
  handleChangeFromDate,
  handleChangeToDate,
  handleChangeStatusToFilter,
  handleChangeScheduleTypeFilter,
  searchClientScheduleTransactionByMediumAndStatusAndDateFailure,
  searchClientScheduleTransactionByMediumAndStatusAndDateSuccess,
  handleChangeTransactionMedium,
  handleChangeTransactionType,
  clearScheduleTransactionInputData,
  clearFileData,
  handleChangeScheduleTransactionInputData,
  setOpenModalToScheduleTransaction,
  setScheduleTransactionInputDataToEdit,
  updateScheduleTransactionSuccess,
  updateScheduleTransactionFailure,
  exportScheduleTransactionSuccess,
  exportScheduleTransactionFailure,
  handleChangeScheduleTerm,
  clearScheduleTransactionFilterForm,
  handleScheduleTransFilter,
  clearSchTransFilterData,
  searchScheduleTransactionByMediumAndStatusAndDateFailure,
  searchScheduleTransactionByMediumAndStatusAndDateSuccess,
  saveSchTransConfigFailure,
  saveSchTransConfigSuccess,
} from "../../actions/ScheduleTransactionAction";
import {
  addClientBankDetailsFailure,
  addClientBankDetailsSuccess,
  addNoteFailure,
  addNoteSuccess,
  addPaymentDetailFailure,
  addPaymentDetailSuccess,
  calculateEligibilityFailure,
  calculateEligibilitySuccess,
  clearActions,
  clearEligibilitySetting,
  clearNoteDescription,
  clearWorkflow,
  clientProfilePictureRequestMade,
  deleteCaseByClientFailure,
  deleteCaseByClientSuccess,
  deleteClientBankDetailsFailure,
  deleteClientBankDetailsSuccess,
  deleteNoteFailure,
  deleteNoteSuccess,
  fetchCaseByClientIdFailure,
  fetchCaseByClientIdSuccess,
  fetchClientPaymentDetailFailure,
  fetchClientPaymentDetailSuccess,
  fetchFiledDetailByClientFailure,
  fetchFiledDetailByClientSuccess,
  fetchNoteByIdFailure,
  fetchNoteByIdSuccess,
  getAssignedEventForClientCalendarFailure,
  getAssignedEventForClientCalendarSuccess,
  handleChangeAccountName,
  handleChangeAccountNumber,
  handleChangeAction,
  handleChangeAdmissionDate,
  handleChangeClientAchDelayThreshold,
  handleChangeClientAnnualFee,
  handleChangeClientFee,
  handleChangeClientInstallment,
  handleChangeClientMonthlyFee,
  handleChangeClientSpendDown,
  handleChangeClient,
  handleChangeEligibilitySetting,
  handleChangeIdsValue,
  handleChangeNoteDescription,
  handleChangeRoutingNumber,
  handleChangeWorkflow,
  setBankDetailsToEdit,
  setNoteDescriptionToEdit,
  updateClientBankDetailsFailure,
  updateClientBankDetailsSuccess,
  updateClientProfilePictureFailure,
  updateClientProfilePictureSuccess,
  updateNoteFailure,
  updateNoteSuccess,
  uploadClientProfilePictureFailure,
  uploadClientProfilePictureSuccess,
  updateClientFailure,
  handleChangeToOpenClientFormModal,
  updateClientSuccess,
  saveEnabledNotificationSuccess,
  saveEnabledNotificationFailure,
  getAlertByAccountSuccess,
  getAlertByAccountFailure,
  handleChangeHolidayAlert,
  handleChangeUpcomingAlert,
  handleChangeBankName,
  handleChangeBankAccountType,
  clearBankDetail,
  handleChangeToOpenNoteModalForClient,
  handleChangeToNotesForClient,
  // handleChangeToNotes,
  // fetchTransactionClientSuccess,
  addNoteSuccessForClient,
  // getClientTransactionSuccessForClient,
  handleChangeDescData,
  updateDescriptionSuccess,
  // delcineClientTransCheckSuccess, delcineClientTransCheckFailure,
  handleChangeBlockedTransData,
  clearEditBlockedTrans,
} from "../../actions/ClientAction";
import {
  addContactFileFailure,
  addContactFileSuccess,
  closeSnackBarForContact,
  contactRequestMade,
  deleteContactFileFailure,
  deleteContactFileSuccess,
  fetchContactFileFailure,
  fetchContactFileSuccess,
  handleChangeContactFiles,
  setContactFileToEdit,
  updateContactFileFailure,
  updateContactFileSuccess,
} from "../../actions/ContactAction";
import CalendarApi from "../../api/CalendarApi";
import {
  activityLogRequestMade,
  fetchActivityLogByIdFailure,
  fetchActivityLogByIdSuccess,
} from "../../actions/ActivityLogAction";
import {
  addHolidaysFailure,
  addHolidaysSuccess,
  clearModalData,
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  fetchHolidaysFailure,
  fetchHolidaysSuccess,
  handleChangeCalendarType,
  handleChangeHolidayName,
  makeSuccessToFalse,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import {
  clearUpTransFilter,
  closeUpcomingTransSnackBar,
  fetchClientUpcomingTransactionFailure,
  fetchClientUpcomingTransactionSuccess,
  handlechangeRemarkToSkip,
  handleChangeToOpenSkipModal,
  handleUpTransFilter,
  processTransactionFailure,
  processTransactionSuccess,
} from "../../actions/UpComingTransactionAction";
import {
  fetchVendorClientByIdFailure,
  fetchVendorClientByIdSuccess,
} from "../../actions/VendorAction";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
          if (response.client && response.client.bankDetail.length === 1) {
            const e =
              response.client.bankDetail[0] &&
              response.client.bankDetail[0].bankDetailId;
            props.handleChangeScheduleTransactionInputData("bankId", e);
            props.handleChangeTransData("bankDetailId", e);
          }
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },
    fetchCaseTemplateList: () => {
      dispatch(caseTemplateRequestMade(true));
      CaseTemplateAPI.fetchCaseTemplateList((response, error) => {
        if (response) {
          dispatch(fetchCaseTemplateSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCaseTemplateFailure(error.msg));
          } else {
            dispatch(fetchCaseTemplateFailure(error));
          }
        }
      });
    },
    getClientTransaction: (clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.getClientTransaction(clientId, (response, error) => {
        if (response) {
          dispatch(getClientTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getClientTransactionFailure(error.msg));
          } else {
            dispatch(getClientTransactionFailure(error));
          }
        }
      });
    },
    fetchScheduleTransactionListByClient: (id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.fetchScheduleTransactionListByClient(
        id,
        (response, error) => {
          if (response) {
            dispatch(fetchScheduleTransactionByClientSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchScheduleTransactionByClientFailure(error.msg));
            } else {
              dispatch(fetchScheduleTransactionByClientFailure(error));
            }
          }
        }
      );
    },
    fetchCaseByClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchCaseByClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchCaseByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCaseByClientIdFailure(error.msg));
          } else {
            dispatch(fetchCaseByClientIdFailure(error));
          }
        }
      });
    },
    handleChangeStatus: (status, id) =>
      dispatch(handleChangeStatus(status, id)),

    deleteCase: (caseId, clientId, props) => {
      dispatch(clientRequestMade(true));
      CaseAPI.deleteCase(caseId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteCaseByClientFailure(error.msg));
          } else {
            dispatch(deleteCaseByClientFailure(error));
          }
        } else {
          dispatch(deleteCaseByClientSuccess(caseId));
          props.fetchCaseByClient(clientId);
        }
      });
    },
    deleteScheduleTransaction: (scheduleTransactionId, clientId, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.deleteScheduleTransaction(
        scheduleTransactionId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(deleteScheduleTransactionFailure(error.msg));
            } else {
              dispatch(deleteScheduleTransactionFailure(error));
            }
          } else {
            props.fetchScheduleTransactionListByClient(clientId);
            dispatch(deleteScheduleTransactionSuccess(scheduleTransactionId));
          }
        }
      );
    },
    updateScheduleTransactionStatus: (statusData, id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.updateScheduleTransactionStatus(
        statusData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionListByClient(id);
            dispatch(editScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(editScheduleTransactionFailure(error.msg));
            } else {
              dispatch(editScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    handleChangeAccountName: (accountName) =>
      dispatch(handleChangeAccountName(accountName)),
    handleChangeAccountNumber: (accountNumber) =>
      dispatch(handleChangeAccountNumber(accountNumber)),
    handleChangeRoutingNumber: (routingNumber) =>
      dispatch(handleChangeRoutingNumber(routingNumber)),
    handleChangeBankName: (bankName) =>
      dispatch(handleChangeBankName(bankName)),
    handleChangeBankAccountType: (bankAccountType) =>
      dispatch(handleChangeBankAccountType(bankAccountType)),
    clearBankDetail: () => {
      dispatch(clearBankDetail());
    },

    addClientBankDetails: (bankDetail, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.addClientBankDetails(bankDetail, (response, error) => {
        if (response) {
          props.fetchSingleClient(bankDetail.clientId);
          props.fetchActivityLogByClientId(bankDetail.clientId);
          dispatch(addClientBankDetailsSuccess(true));
        } else {
          if (error.msg) {
            dispatch(addClientBankDetailsFailure(error.msg));
          } else {
            dispatch(addClientBankDetailsFailure(error));
          }
        }
      });
    },
    updateClientBankDetails: (bankDetail, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.updateClientBankDetails(bankDetail, (response, error) => {
        if (response) {
          props.fetchSingleClient(bankDetail.clientId);
          dispatch(updateClientBankDetailsSuccess(true));
        } else {
          if (error.msg) {
            dispatch(updateClientBankDetailsFailure(error.msg));
          } else {
            dispatch(updateClientBankDetailsFailure(error));
          }
        }
      });
    },
    setAddBankDetailsSuccessToFalse: () => {
      dispatch(addClientBankDetailsSuccess(false));
    },
    setUpdateBankDetailsSuccessToFalse: () => {
      dispatch(updateClientBankDetailsSuccess(false));
    },
    deleteClientBankDetail: (bankDetailId, clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.deleteClientBankDetails(bankDetailId, (response, error) => {
        if (response) {
          props.fetchSingleClient(clientId);
          dispatch(deleteClientBankDetailsSuccess(true));
        } else {
          if (error.msg) {
            dispatch(deleteClientBankDetailsFailure(error.msg));
          } else {
            dispatch(deleteClientBankDetailsFailure(error));
          }
        }
      });
    },
    setDeleteBankDetailsSuccessToFalse: () => {
      dispatch(deleteClientBankDetailsSuccess(false));
    },
    setBankDetailToEdit: (bankDetails) => {
      dispatch(setBankDetailsToEdit(bankDetails));
    },
    handleChangeCloseSnackBar: () => {
      dispatch(closeSnackBarForClient(true));
    },
    fetchUserList: () => {
      dispatch(userRequestMade(true));
      UserAPI.fetchUserList((response, error) => {
        if (response) {
          dispatch(fetchUserSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchUserFailure(error.msg));
          } else {
            dispatch(fetchUserFailure(error));
          }
        }
      });
    },
    registerCase: (caseData, props) => {
      dispatch(registerCaseRequestMade(true));
      RegisterCaseAPI.registerCase(caseData, (response, error) => {
        if (response) {
          dispatch(registerCaseSuccess(true));
          props.fetchCaseByClient(caseData.clientId);
        } else {
          if (error.msg) {
            dispatch(registerCaseFailure(error.msg));
          } else {
            dispatch(registerCaseFailure(error));
          }
        }
      });
    },
    handleChangeCaseInRegisterCase: (id, value) =>
      dispatch(handleChangeCaseInRegisterCase(id, value)),
    handleChangeContactFile: (id, value) => {
      dispatch(handleChangeContactFiles(id, value));
    },
    uploadImageFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formData = new FormData();
      formData.append("image", selectedFiles.image);
      formData.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadImageFile(formData, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
          props.handleChangeContactFile("documentUrl", response.fileUrl);
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },
    addContactFiles: (contactFiles, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.addContactFiles(contactFiles, (response, error) => {
        if (response) {
          dispatch(addContactFileSuccess(response));
          props.fetchContactFilesList(contactFiles.refId);
        } else {
          if (error.msg) {
            dispatch(addContactFileFailure(error.msg));
          } else {
            dispatch(addContactFileFailure(error));
          }
        }
      });
    },
    fetchFileListByClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchFileListByClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchFiledDetailByClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchFiledDetailByClientFailure(error.msg));
          } else {
            dispatch(fetchFiledDetailByClientFailure(error));
          }
        }
      });
    },
    fetchContactFilesList: (contactId, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.fetchContactFiles(contactId, (response, error) => {
        if (response) {
          dispatch(fetchContactFileSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchContactFileFailure(error.msg));
          } else {
            dispatch(fetchContactFileFailure(error));
          }
        }
      });
    },
    setContactFileToEdit: (files) => {
      dispatch(setContactFileToEdit(files));
    },
    updateContactFiles: (contactFiles, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.editContactFiles(contactFiles, (response, error) => {
        if (response) {
          dispatch(updateContactFileSuccess(response));
          props.fetchContactFilesList(contactFiles.refId);
        } else {
          if (error.msg) {
            dispatch(updateContactFileFailure(error.msg));
          } else {
            dispatch(updateContactFileFailure(error));
          }
        }
      });
    },
    deleteFiles: (refId, cleintId, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.deleteContactFiles(refId, (response, error) => {
        if (response) {
          dispatch(deleteContactFileSuccess(response));
          props.fetchContactFilesList(cleintId);
        } else {
          if (error.msg) {
            dispatch(deleteContactFileFailure(error.msg));
          } else {
            dispatch(deleteContactFileFailure(error));
          }
        }
      });
    },
    fetchDataListForCalendarByClientId: (id) => {
      dispatch(detailsForCalendarRequestMade(true));
      CalendarApi.fetchDataListForCalendarById(id, (response, error) => {
        if (response) {
          dispatch(fetchDataForCalendarByClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDataForCalendarByClientFailure(error.msg));
          } else {
            dispatch(fetchDataForCalendarByClientFailure(error));
          }
        }
      });
    },
    closeContactSnackBar: () => {
      dispatch(closeSnackBarForContact(true));
    },

    //for payment
    fetchClientPayment: (id, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientPayment(id, (response, error) => {
        if (response) {
          dispatch(fetchClientPaymentDetailSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientPaymentDetailFailure(error.msg));
          } else {
            dispatch(fetchClientPaymentDetailFailure(error));
          }
        }
      });
    },
    handleChangeClientFee: (fee) => {
      dispatch(handleChangeClientFee(fee));
    },
    handleChangeClientAnnualFee: (annualFee) => {
      dispatch(handleChangeClientAnnualFee(annualFee));
    },
    handleChangeClientMonthlyFeeValue: (monthlyFee) => {
      dispatch(handleChangeClientMonthlyFee(monthlyFee));
    },
    handleChangeAdmissionDate: (admissionDate) => {
      dispatch(handleChangeAdmissionDate(admissionDate));
    },
    handleChangeClientSpendDown: (spendDownThreshold) => {
      dispatch(handleChangeClientSpendDown(spendDownThreshold));
    },
    handleChangeClientAchDelayThreshold: (achDelayThreshold) => {
      dispatch(handleChangeClientAchDelayThreshold(achDelayThreshold));
    },
    handleChangeClientInstallment: (installmentMonth) => {
      dispatch(handleChangeClientInstallment(installmentMonth));
    },

    addClientPayment: (paymentData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.addClientPayment(paymentData, (response, error) => {
        if (response) {
          dispatch(addPaymentDetailSuccess(response));
          // props.history.push({pathname: '/client'})
        } else {
          if (error.msg) {
            dispatch(addPaymentDetailFailure(error.msg));
          } else {
            dispatch(addPaymentDetailFailure(error));
          }
        }
      });
    },

    //set password
    handleChangeEmail: (id, value) => dispatch(handleChangeEmail(id, value)),
    setClientPassword: (data, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.setClientPassword(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(setPasswordFailure(error.msg));
          } else {
            dispatch(setPasswordFailure(error));
          }
        } else {
          dispatch(setPasswordSuccess(true));
          // props.fetchClientList();
        }
      });
    },
    setPasswordSuccessToFalse: () => {
      dispatch(setPasswordSuccess(false));
    },
    handleSnackBarClose: () => dispatch(closeSnackBarForClient(true)),
    updateClientProfilePicture: (data, props) => {
      dispatch(clientProfilePictureRequestMade(true));
      ClientAPI.updateClientProfilePicture(data, (response, error) => {
        if (response) {
          // props.history.push('/client')
          dispatch(updateClientProfilePictureSuccess(response));
        } else {
          if (error.msg) {
            dispatch(updateClientProfilePictureFailure(error.msg));
          } else {
            dispatch(updateClientProfilePictureFailure(error));
          }
        }
      });
    },
    updateProfilePicture: (selectedFiles, clientId, props) => {
      dispatch(profileRequestMade(true));
      let formdata = new FormData();
      formdata.append("image", selectedFiles.file);
      formdata.append("mimeType", selectedFiles.type);
      ProfileAPI.updateProfilePicture(formdata, (response, error) => {
        if (response) {
          var data = {
            clientId: clientId,
            profilePicUrl: response.fileUrl,
          };
          dispatch(uploadClientProfilePictureSuccess(data, true));
          props.updateClientProfilePicture(data);
        } else {
          if (error.msg) {
            dispatch(uploadClientProfilePictureFailure(error.msg));
          } else {
            dispatch(uploadClientProfilePictureFailure(error));
          }
        }
      });
    },
    getClientAssignedEvent: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.getClientAssignedEvent(clientId, (response, error) => {
        if (response) {
          dispatch(getAssignedEventForClientCalendarSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAssignedEventForClientCalendarFailure(error.msg));
          } else {
            dispatch(getAssignedEventForClientCalendarFailure(error));
          }
        }
      });
    },
    //CALCULATE ELIGIBILITY
    calculateEligibility: (eligibilityData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.calculateEligibility(eligibilityData, (response, error) => {
        if (response) {
          dispatch(calculateEligibilitySuccess(response));
        } else {
          if (error.msg) {
            dispatch(calculateEligibilityFailure(error.msg));
          } else {
            dispatch(calculateEligibilityFailure(error));
          }
        }
      });
    },
    handleChangeEligibilityData: (id, value) => {
      dispatch(handleChangeEligibilitySetting(id, value));
    },
    handleChangeIdsValue: (id, value) => {
      dispatch(handleChangeIdsValue(id, value));
    },
    handleChangeWorkflow: (workflowId) => {
      dispatch(handleChangeWorkflow(workflowId));
    },
    clearWorkflow: () => {
      dispatch(clearWorkflow());
    },
    handleChangeAction: (actionId) => {
      dispatch(handleChangeAction(actionId));
    },
    clearAction: () => {
      dispatch(clearActions());
    },
    clearEligibilitySetting: () => {
      dispatch(clearEligibilitySetting());
    },

    //NOTES
    // handle change
    handleChangeNoteDescription: (id, value) => {
      dispatch(handleChangeNoteDescription(id, value));
    },
    //set note
    setNotesToEdit: (noteData) => {
      dispatch(setNoteDescriptionToEdit(noteData));
    },
    // add notes*
    addClientNotes: (noteData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.addNewNote(noteData, (response, error) => {
        if (response) {
          dispatch(addNoteSuccess(true));
          props.fetchNoteById(noteData.refId);
        } else {
          if (error.msg) {
            dispatch(addNoteFailure(error.msg));
          } else {
            dispatch(addNoteFailure(error));
          }
        }
      });
    },
    //update note
    updateClientNotes: (noteData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.updateNotes(noteData, (response, error) => {
        if (response) {
          dispatch(updateNoteSuccess(response));
          props.fetchNoteById(noteData.refId);
        } else {
          if (error.msg) {
            dispatch(updateNoteFailure(error.msg));
          } else {
            dispatch(updateNoteFailure(error));
          }
        }
      });
    },
    //delete note
    deleteClientNote: (noteId, clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.deleteNote(noteId, (response, error) => {
        if (response) {
          dispatch(deleteNoteSuccess(true));
          props.fetchNoteById(clientId);
        } else {
          if (error.msg) {
            dispatch(deleteNoteFailure(error.msg));
          } else {
            dispatch(deleteNoteFailure(error));
          }
        }
      });
    },
    //fetch node by id
    fetchNoteById: (id, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchNotesById(id, (response, error) => {
        if (response) {
          dispatch(fetchNoteByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchNoteByIdFailure(error.msg));
          } else {
            dispatch(fetchNoteByIdFailure(error));
          }
        }
      });
    },
    //clear description data
    clearNoteDescription: () => {
      dispatch(clearNoteDescription());
    },

    //GET CLIENT'S ACTIVITY LOG
    fetchActivityLogByClientId: (clientId, workflowId, caseId, props) => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLogById(
        clientId,
        "",
        "",
        (response, error) => {
          if (response) {
            dispatch(fetchActivityLogByIdSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchActivityLogByIdFailure(error.msg));
            } else {
              dispatch(fetchActivityLogByIdFailure(error));
            }
          }
        }
      );
    },

    handleChangeSnackBar: () => {
      dispatch(closeScheduleTransactionSnackBar(true));
    },

    // add holiday
    handleChangeHolidayName: (holidayName) => {
      dispatch(handleChangeHolidayName(holidayName));
    },
    handleChangeCalendarType: (calendarType) => {
      dispatch(handleChangeCalendarType(calendarType));
    },
    addHolidays: (holidayData, clientId, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addHolidays(holidayData, (response, error) => {
        if (response) {
          props.fetchHolidaysList();
          props.fetchDataListForCalendarByClientId(clientId);
          props.getClientAssignedEvent(clientId);
          dispatch(addHolidaysSuccess(true, response));
        } else {
          if (error.msg) {
            dispatch(addHolidaysFailure(error.msg));
          } else {
            dispatch(addHolidaysFailure(false));
          }
        }
      });
    },
    setToFalse: () => {
      dispatch(makeSuccessToFalse(false));
    },
    clearModalData: () => {
      dispatch(clearModalData());
    },
    fetchHolidaysList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchHolidayDetails((response, error) => {
        if (response) {
          dispatch(fetchHolidaysSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchHolidaysFailure(error.msg));
          } else {
            dispatch(fetchHolidaysFailure(error));
          }
        }
      });
    },
    // update client
    handleChangeClient: (id, value) => {
      dispatch(handleChangeClient(id, value));
    },
    // handleChangeOpenModal: bool => {
    //     dispatch(handleChangeOpenModal(bool));
    // },
    handleChangeToOpenClientFormModal: (bool) => {
      dispatch(handleChangeToOpenClientFormModal(bool));
    },

    handleChangeToOpenNoteModalForClient: (bool) => {
      dispatch(handleChangeToOpenNoteModalForClient(bool));
    },

    handleChangeToNotesForClient: (notes) => {
      dispatch(handleChangeToNotesForClient(notes));
    },

    addNoteForTransactions: (notesData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.addTransactionNotes(notesData, (response, error) => {
        if (response) {
          dispatch(addNoteSuccessForClient(response));
          props.getClientTransaction(clientId);
          if (handleChangeToOpenNoteModalForClient) {
            handleChangeToOpenNoteModalForClient(false);
          }
        } else {
          if (error.msg) {
            dispatch(addNoteFailure(error.msg));
          } else {
            dispatch(addNoteFailure(error));
          }
        }
      });
    },

    editClient: (clientData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.editClient(clientData, (response, error) => {
        if (response) {
          dispatch(updateClientSuccess(true));
          props.handleChangeToOpenClientFormModal(false);
          props.fetchSingleClient(clientData.clientId);
        } else {
          if (error.msg) {
            dispatch(updateClientFailure(error.msg));
            props.handleChangeToOpenClientFormModal(true);
          } else {
            dispatch(updateClientFailure(error));
            props.handleChangeToOpenClientFormModal(true);
          }
        }
      });
    },

    clientOnDischarge: (data, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.clientOnDischarge(data, (response, error) => {
        if (response) {
          dispatch(updateClientSuccess(true));

          props.fetchSingleClient(data.clientId);
        } else {
          if (error.msg) {
            dispatch(updateClientFailure(error.msg));
          } else {
            dispatch(updateClientFailure(error));
          }
        }
      });
    },
    clientOnHold: (data, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.clientOnHold(data, (response, error) => {
        if (response) {
          dispatch(updateClientSuccess(true));

          props.fetchSingleClient(data.clientId);
        } else {
          if (error.msg) {
            dispatch(updateClientFailure(error.msg));
          } else {
            dispatch(updateClientFailure(error));
          }
        }
      });
    },

    // client's transaction
    searchClientTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      clientId
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },
    // client's transaction
    filterClientTransactionByAll: (
      id,
      description,
      checkNo,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      balance,
      debitCreditAmount,
      compareType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.filterTransactionByClient(
        id,
        description,
        checkNo,
        // userName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        balance,
        debitCreditAmount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientTransactionByMediumAndStatusAndDateFailure(error)
              );
            }
          } else {
            dispatch(
              searchClientTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },
    searchClientTransaction: (value, clientId) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchClientTransaction(
        value,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientTransactionFailure(error.msg));
            } else {
              dispatch(searchClientTransactionFailure(error));
            }
          } else {
            dispatch(searchClientTransactionSuccess(response));
          }
        }
      );
    },
    handleChangeTransactionDateFrom: (from) =>
      dispatch(handleChangeDateFrom(from)),
    handleChangeTransactionDateTo: (to) => dispatch(handleChangeDateTo(to)),
    handleChangeTransactionMedium: (medium) =>
      dispatch(handleChangeMedium(medium)),
    handleChangeTransactionStatus: (status) =>
      dispatch(handleTransactionStatus(status)),
    handleChangeTerm: (term) => dispatch(handleChangeTerm(term)),
    handleChangeTransionType: (transType) =>
      dispatch(handleChangeTransType(transType)),
    handleChangeVendor: (vendor) => dispatch(handleChangeVendor(vendor)),
    clearSnackBar: () => dispatch(openSnackBar()),
    exportSingleClientTransactionList: (clientId, exportType) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransaction(
        clientId,
        exportType,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportSingleClientTransactionFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionFailure(error));
            }
          }
        }
      );
    },
    exportTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      clientId,
      exportType
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        clientId,
        exportType,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },

    // export transaction by column filter
    exportTransactionByColumnFilter: (
      clientId,
      transactionMedium,
      transactionType,

      transactionStatus,

      exportType,
      description,
      checkNo,
      userName,
      vendorName,
      balance,
      compareType,
      transDate,
      filterDebitAmount
    ) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByColumnFilter(
        clientId,
        transactionMedium,
        transactionType,

        transactionStatus,

        exportType,
        description,
        checkNo,
        userName,
        vendorName,
        balance,
        compareType,
        transDate === null ? 0 : transDate,
        filterDebitAmount,
        (response, error) => {
          if (response) {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportTransactionByStatusAndMediumSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportTransactionByStatusAndMediumFailure(error.msg));
            } else {
              dispatch(exportTransactionByStatusAndMediumFailure(error));
            }
          }
        }
      );
    },
    exportSingleClientTransactionByTerm: (term, clientId, exportType) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.exportSingleClientTransactionByTerm(
        term,
        clientId,
        exportType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(exportSingleClientTransactionByTermFailure(error.msg));
            } else {
              dispatch(exportSingleClientTransactionByTermFailure(error));
            }
          } else {
            window.open(response.transactionExportResponse.url, "_blank");
            dispatch(exportSingleClientTransactionByTermSuccess(response));
          }
        }
      );
    },

    // EXPORING VOD
    exportTransactionVOD: (ClientID, from, to, exportType) => {
      dispatch(exportTransactionVODRequest(true));
      TransactionAPI.exportTransactionVOD(
        ClientID,
        from === null ? 0 : from,
        to === null ? 0 : to,
        exportType,
        (response, error) => {
          if (response && !response.msg) {
            window.open(response.url, "_blank");
            dispatch(exportTransactionVODSuccess(response));
          } else if (response && response.msg) {
            dispatch(exportTransactionVODFailure(response.msg));
          } else {
            if (error.msg) {
              dispatch(exportTransactionVODFailure(error.msg));
            } else {
              dispatch(exportTransactionVODFailure(error));
            }
          }
        }
      );
    },
    getClientUpcomingTrans: (clientId) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.getClientUpcomingTransList(
        clientId,

        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },
    // fetch upcoming by Client id
    // might not need this api
    fetchClientUpcomingTransactionList: (
      clientId,
      from,
      to,
      medium,
      term,
      vendor,
      type
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.fetchClientUpcomingTransactionList(
        clientId,
        from === null ? "0" : from,
        to === null ? "0" : to,
        medium,
        term,
        vendor,
        type,
        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },

    handleUpTransFilter: (id, value) => {
      dispatch(handleUpTransFilter(id, value));
    },

    clearUpTransFilter: () => {
      dispatch(clearUpTransFilter());
    },

    // handleChangeDateFrom: (from) => dispatch(handleChangeDateFrom(from)),
    // handleChangeDateTo: (to) => dispatch(handleChangeDateTo(to)),
    // handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),

    // filter client upcoming transaction transaction

    searchUpcomingTransactionByClient: (
      // startDate,
      // endDate,
      clientId,
      vendorName,
      transactionMedium,
      transactionType,
      // transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByClient(
        // startDate === null ? "" : startDate,
        // endDate === null ? "" : endDate,
        clientId,
        vendorName,
        transactionMedium,
        transactionType,
        // transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },

    // ALERT NOTIFICATION FOR CLIENT
    saveNotifications: (enabledData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.saveEnabledNotification(enabledData, (response, error) => {
        if (response) {
          dispatch(saveEnabledNotificationSuccess(response));
          props.getAlertInfo(enabledData.accountId);
        } else {
          if (error.msg) {
            dispatch(saveEnabledNotificationFailure(error.msg));
            props.getAlertInfo(enabledData.accountId);
          } else {
            dispatch(saveEnabledNotificationFailure(error));
            props.getAlertInfo(enabledData.accountId);
          }
        }
      });
    },
    getAlertInfo: (accountId) => {
      dispatch(clientRequestMade(true));
      ProfileAPI.getAlertInfo(accountId, (response, error) => {
        if (response) {
          dispatch(getAlertByAccountSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAlertByAccountFailure(error.msg));
          } else {
            dispatch(getAlertByAccountFailure(error));
          }
        }
      });
    },
    handleChangeHolidayAlert: (bool) => {
      dispatch(handleChangeHolidayAlert(bool));
    },
    handleChangeUpcomingAlert: (bool) => {
      dispatch(handleChangeUpcomingAlert(bool));
    },
    closeSnackBarForClient: (bool) => {
      dispatch(closeSnackBarForClient(bool));
    },

    handleChangeTransStatus: (status) =>
      dispatch(handleChangeTransactionStatus(status)),
    handleChangeRemark: (remark) =>
      dispatch(handleChangeTransactionRemark(remark)),
    updateSubmitTransactionList: (transData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateTransactionStatus(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },

    undoReverseClientTranx: (transData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.undoReverseTranx(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },

    setChangeTransactionStatusSuccessToFalse: () => {
      dispatch(updateSubmitTransactionSuccess(false));
    },
    handleShowFileUploadErrorMessage: (error) => {
      dispatch(handleShowFileUploadErrorMessage(error));
    },
    closeScheduleTransactionSnackBar: () => {
      dispatch(closeScheduleTransactionSnackBar());
    },

    // create transaction
    setOpenCreateTransaction: (bool) => {
      dispatch(setOpenCreateTransactionForm(bool));
    },
    handleChangeTransData: (id, value) => {
      dispatch(handleChangeTransData(id, value));
    },
    clearCreateTransactionForm: () => {
      dispatch(clearCreateTransactionForm());
    },
    //DESCRIPTION
    addDescription: (description, clientId, props) => {
      dispatch(clientRequestMade(true));
      TransactionAPI.addDescription(description, (response, error) => {
        if (response) {
          dispatch(updateDescriptionSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(addNoteFailure(error.msg));
          } else {
            dispatch(addNoteFailure(error));
          }
        }
      });
    },
    handleChangeDescData: (id, value) => {
      dispatch(handleChangeDescData(id, value));
    },
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },
    uploadDocumentFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formData = new FormData();
      formData.append("doc", selectedFiles.document);
      formData.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadDocumentFile(formData, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
          props.handleChangeContactFile("documentUrl", response.fileUrl);
          props.handleChangeTransData("document", response.fileUrl);
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },
    createTransData: (value, props) => {
      TransactionAPI.createTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(createTransactionFailure(error.msg));
          } else {
            dispatch(createTransactionFailure(error));
          }
        } else {
          dispatch(createTransactionSuccess(true));
          props.getClientTransaction(value.clientId);
        }
      });
    },

    // delete transaction
    deleteTransData: (transactionId, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.deleteTransaction(transactionId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteTransactionFailure(error.msg));
          } else {
            dispatch(deleteTransactionFailure(error));
          }
        } else {
          props.getClientTransaction(clientId);
          dispatch(deleteTransactionSuccess(transactionId));
        }
      });
    },

    fetchActivityLogByRefId: (id) => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLogByRefId(id, (response, error) => {
        if (response) {
          dispatch(fetchActivityLogByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchActivityLogByIdFailure(error.msg));
          } else {
            dispatch(fetchActivityLogByIdFailure(error));
          }
        }
      });
    },
    searchClientScheduleTransaction: (value, clientId) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransaction(
        value,
        clientId,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientScheduleTransactionFailure(error.msg));
            } else {
              dispatch(searchClientScheduleTransactionFailure(error));
            }
          } else {
            dispatch(searchClientScheduleTransactionSuccess(response));
          }
        }
      );
    },
    handlChangeReverseDate: (date) => {
      dispatch(handlChangeReverseDate(date));
    },
    handleChangeFromDate: (from) => dispatch(handleChangeFromDate(from)),
    handleChangeToDate: (to) => dispatch(handleChangeToDate(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),
    handleChangeStatusToFilter: (status) =>
      dispatch(handleChangeStatusToFilter(status)),
    handleChangeScheduleTypeFilter: (status) =>
      dispatch(handleChangeScheduleTypeFilter(status)),
    handleChangeTransactionTypeForSchedule: (type) =>
      dispatch(handleChangeTransactionType(type)),
    searchClientScheduleTransactionByAll: (
      medium,
      status,
      type,
      from,
      to,
      vendor,
      clientId,
      transactionType
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.searchClientScheduleTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        clientId,
        transactionType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error
                )
              );
            }
          } else {
            dispatch(
              searchClientScheduleTransactionByMediumAndStatusAndDateSuccess(
                response
              )
            );
          }
        }
      );
    },

    filterScheduleTransactionByAll: (
      id,
      startDate,
      endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.filterScheduleTransactionByClient(
        id,
        startDate === null ? "" : startDate,
        endDate === null ? "" : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error.msg
                )
              );
            } else {
              dispatch(
                searchClientScheduleTransactionByMediumAndStatusAndDateFailure(
                  error
                )
              );
            }
          } else {
            dispatch(
              searchClientScheduleTransactionByMediumAndStatusAndDateSuccess(
                response
              )
            );
          }
        }
      );
    },

    handleChangeRemarkToSkip: (remarkToSkip) => {
      dispatch(handlechangeRemarkToSkip(remarkToSkip));
    },
    handleChangeToOpenSkipModal: (bool) => {
      dispatch(handleChangeToOpenSkipModal(bool));
    },

    skipUpcomingTransaction: (data, clientId, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.skipUpcomingTransaction(
        data,
        (response, error) => {
          if (response) {
            dispatch(processTransactionSuccess(response));
            props.getClientUpcomingTrans(clientId);
            // props.fetchClientUpcomingTransactionList(
            //   clientId,
            //   0,
            //   0,
            //   "UNKNOWN_TRANSACTION_MEDIUM",
            //   "",
            //   "",
            //   "UNKNOWN_TRANSACTION_TYPE"
            // );
            props.handleChangeToOpenSkipModal(false);
          } else {
            if (error.msg) {
              dispatch(processTransactionFailure(error.msg));
              props.handleChangeToOpenSkipModal(true);
            } else {
              dispatch(processTransactionFailure(error));
              props.handleChangeToOpenSkipModal(true);
            }
          }
        }
      );
    },
    processUpcomingTransaction: (data, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.processUpcomingTransaction(
        data,
        (response, error) => {
          if (response) {
            dispatch(processTransactionSuccess(response));
            props.getClientUpcomingTrans(data.clientId);
            // props.fetchClientUpcomingTransactionList(
            //   data.clientId,
            //   0,
            //   0,
            //   "UNKNOWN_TRANSACTION_MEDIUM",
            //   "",
            //   "",
            //   "UNKNOWN_TRANSACTION_TYPE"
            // );
          } else {
            if (error.msg) {
              dispatch(processTransactionFailure(error.msg));
            } else {
              dispatch(processTransactionFailure(error));
            }
          }
        }
      );
    },
    handleChangeUpcomingTransSnackBar: () =>
      dispatch(closeUpcomingTransSnackBar(true)),
    //schedule transaction
    getAmountDetailByClientId: (clientId) => {
      TransactionAPI.getAmountDetailByClientId(clientId, (response, error) => {
        if (response) {
          dispatch(getAmountDetailByClientIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAmountDetailByClientIdFailure(error.msg));
          } else {
            dispatch(getAmountDetailByClientIdFailure(error));
          }
        }
      });
    },
    clearAmountDetail: () => {
      dispatch(clearAmountDetail());
    },
    handleChangeTransactionType: (transType) =>
      dispatch(handleChangeTransactionType(transType)),
    fetchVendorClientById: (id) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorClientById(id, (response, error) => {
        if (response) {
          dispatch(fetchVendorClientByIdSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorClientByIdFailure(error.msg));
          } else {
            dispatch(fetchVendorClientByIdFailure(error));
          }
        }
      });
    },
    clearScheduleTransactionInputData: () =>
      dispatch(clearScheduleTransactionInputData()),
    clearFileData: () => {
      dispatch(clearFileData());
    },
    handleChangeScheduleTransactionInputData: (id, input) =>
      dispatch(handleChangeScheduleTransactionInputData(id, input)),
    setOpenModalToScheduleTransaction: (bool) => {
      dispatch(setOpenModalToScheduleTransaction(bool));
    },
    handleChangeScheduleTransactionMedium: (transMediumType) =>
      dispatch(handleChangeTransactionMedium(transMediumType)),
    updateScheduleTransaction: (scheduleTransData, id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.updateScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionListByClient(id);
            dispatch(updateScheduleTransactionSuccess(true));
          } else {
            if (error.msg) {
              dispatch(updateScheduleTransactionFailure(error.msg));
            } else {
              dispatch(updateScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    addNewScheduleTransaction: (scheduleTransData, id, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.addNewScheduleTransaction(
        scheduleTransData,
        (response, error) => {
          if (response) {
            props.fetchScheduleTransactionListByClient(id);
            dispatch(addNewScheduleTransactionSuccess(true));
          } else {
            if (error.msg) {
              dispatch(addNewScheduleTransactionFailure(error.msg));
            } else {
              dispatch(addNewScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    setScheduleTransactionInputDataToEdit: (scheduleTransData) => {
      dispatch(setScheduleTransactionInputDataToEdit(scheduleTransData));
    },

    // Export client schedule transaction

    // exportScheduleTransactionList: (exportType) => {
    //   dispatch(scheduleTransactionRequestMade(true));
    //   TransactionAPI.exportTransaction(exportType, (response, error) => {
    //     if (response) {
    //       window.open(response.transactionExportResponse.url, "_blank");
    //       dispatch(exportTransactionSuccess(response));
    //     } else {
    //       if (error.msg) {
    //         dispatch(exportTransactionFailure(error.msg));
    //       } else {
    //         dispatch(exportTransactionFailure(error));
    //       }
    //     }
    //   });
    // },

    exportAllClientScheduleTransaction: (clientId, exportType, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportClientScheduleTransaction(
        clientId,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    exportClientScheduleTransactionByAll: (
      clientId,
      term,
      medium,
      status,
      type,
      from,
      to,
      transactionType,
      vendor,
      exportType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportClientScheduleTransaction(
        clientId,
        term,
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        transactionType,
        vendor,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },

    // export by column filter
    exportClientScheduleTransactionByColumnFilter: (
      clientId,
      startDate,
      endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType,
      exportType,
      props
    ) => {
      dispatch(scheduleTransactionRequestMade(true));
      ScheduleTransactionAPI.exportClientScheduleTransactionByColumnFilter(
        clientId,
        startDate === null ? 0 : startDate,
        endDate === null ? 0 : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        transactionStatus,
        transactionDate,
        scheduleDate === null ? 0 : scheduleDate,
        amount,
        compareType,
        exportType,
        (response, error) => {
          if (response) {
            var popUp = window.open(response.url, "_blank");
            if (popUp == null || typeof popUp == "undefined") {
              alert(
                'Please disable your pop-up blocker and click the "Open" link again.'
              );
            } else {
              popUp.focus();
            }
            dispatch(exportScheduleTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportScheduleTransactionFailure(error.msg));
            } else {
              dispatch(exportScheduleTransactionFailure(error));
            }
          }
        }
      );
    },
    handleChangeScheduleTerm: (term) => {
      dispatch(handleChangeScheduleTerm(term));
    },
    clearScheduleTransactionFilterForm: () => {
      dispatch(clearScheduleTransactionFilterForm());
    },
    closeClientTransactionSnackBar: (bool) => {
      dispatch(closeClientTransactionSnackBar(bool));
    },

    handleChangeBlockedTransData: (id, value) => {
      dispatch(handleChangeBlockedTransData(id, value));
    },
    clearEditBlockedTrans: () => {
      dispatch(clearEditBlockedTrans());
    },
    // edit blocked transaction
    editBlockedTransaction: (data, clientId, props) => {
      TransactionAPI.updateBlockedTrans(data, (response, error) => {
        if (response) {
          dispatch(updateBlockedTransSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(updateBlockedTransFailure(error.msg));
          } else {
            dispatch(updateBlockedTransFailure(error));
          }
        }
      });
    },
    updateCheckStatus: (checkData, clientId, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateCheck(checkData, (response, error) => {
        if (response) {
          dispatch(delcineTransCheckSuccess(response));
          props.getClientTransaction(clientId);
        } else {
          if (error.msg) {
            dispatch(delcineTransCheckFailure(error.msg));
          } else {
            dispatch(delcineTransCheckFailure(error));
          }
        }
      });
    },
    handleTransactionSnackBarClose: () =>
      dispatch(closeTransactionSnackBar(true)),
    // filter transaction
    handleTransactionFilter: (id, value) => {
      dispatch(handleTransactionFilter(id, value));
    },

    clearTransactionFilter: () => {
      dispatch(clearTransactionFilter());
    },
    handleScheduleTransFilter: (id, value) => {
      dispatch(handleScheduleTransFilter(id, value));
    },
    clearSchTransFilterData: () => {
      dispatch(clearSchTransFilterData());
    },
    // config
    saveInConfig: (data) => {
      TransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveTransConfigFailure(error.msg));
          } else {
            dispatch(saveTransConfigFailure(error));
          }
        } else {
          dispatch(saveTransConfigSuccess(response));
        }
      });
    },
    saveInConfigSch: (data) => {
      ScheduleTransactionAPI.saveConfig(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveSchTransConfigFailure(error.msg));
          } else {
            dispatch(saveSchTransConfigFailure(error));
          }
        } else {
          dispatch(saveSchTransConfigSuccess(response));
        }
      });
    },
    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getTransConfigFailure(error.msg));
          } else {
            dispatch(getTransConfigFailure(error));
          }
        } else {
          dispatch(getTransConfigSuccess(response.configs));
        }
      });
    },

    // set data for editing trans
    setTransactionInputDataToEdit: (selectedTransData) =>
      dispatch(setTransactionInputDataToEdit(selectedTransData)),

    updateClientTransData: (value, props) => {
      TransactionAPI.updateTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(updateTransactionFailure(error.msg));
          } else {
            dispatch(updateTransactionFailure(error));
          }
        } else {
          dispatch(updateTransactionSuccess(true));
          props.getClientTransaction(value.clientId);
        }
      });
    },
  };
};

export const ClientDetailPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetailPageScreen);
