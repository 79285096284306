import { Popover } from "antd";
import React from "react";

const ExportButton = ({ onClickCSV, onClickPDF, onClickVOD, name }) => {
  const content = (
    <div style={{ width: "75px" }}>
      <div role="presentation" className="content1" onClick={onClickPDF}>
        PDF
      </div>
      <div role="presentation" className="content2" onClick={onClickCSV}>
        CSV
      </div>
     {name === "VOD" ? (
          <div role="presentation" className="content3" onClick={onClickVOD}>
          VOD
        </div>
     ) : ""}
       
      
    </div>
  );
  return (
    <Popover content={content} placement="bottom" trigger="hover">
      <button
        className="exportButton"
      >
        <img
          alt={"setting"}
          src={"/assets/images/export.svg"}
          style={{
            width: "26px",
            height: "18px",
            marginRight: "7px",
            marginBottom: "4px",
          }}
        />
        Export
      </button>
    </Popover>
  );
};

export default ExportButton;
