import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import MySnackbarContentWrapper from "../components/Snackbar";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TablePagination from "@material-ui/core/TablePagination";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Avatar, Select, Tag, Tooltip, Tabs } from "antd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
// import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
// import moment from "moment";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import ScheduleNewTransaction from "./scheduleTransaction/ScheduleNewTransaction";
import ChangedLogPopover from "../components/ChangedLogPopover";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import ExportButton from "../components/ExportButton";
import SnackBarComponent from "../components/SnackBarComponenet";
import {
  FilterStatusData,
  FilterMediumData,
  FilterTypeData,
  FilterClientSchStatusData,
  FilterScheduleTimeData,
  FilterUPTypeData,
  allTypeArray,
} from "../components/FilterOptionList";
import {
  transactionMediumHelper,
  transactionTypeHelper,
  ClientSheduleStatusHelper,
  scheduleTimeHelper,
  descendingComparatorTrans,
  transactionStatusHelper,
  getRangeType,
  showGreaterThanSign,
} from "./TransactionHelper";
import CheckboxFilterPopover from "../components/CheckboxFilterPopover";
import ButtonComponent from "./ButtonComponent";
// import { arraysEqual } from "../components/Helper";

const { Option } = Select;
const { TabPane } = Tabs;
class IndividualClientScheduleTransactionScreen extends Component {
  constructor(props) {
    super(props);
    var pageURL = window.location.href;
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openSearchDialogue: false,
      openDeleteScheduleDialogue: false,
      openUpdateClientDialogue: false,
      openUpdateScheduleDialogue: false,
      openUpdateCancelDialogue: false,
      openUpdateCloseDialogue: false,
      openUpdateApproveDialogue: false,
      openActionMenu: false,
      popoverElement: null,
      scheduleClientTrans: "",
      clientId: pageURL.substr(pageURL.lastIndexOf("/") + 1),
      clearBankDetail: false,
      errors: {
        amount: "",
        scheduledDate: "",
        endDate: "",
        note: "",
      },
      isUpdate: false,
      isReopen: false,
      buttonDisable: false,
      openActivityLogModal: false,
      isChecked: false,
      isALlChecked: true,
      isAllMediumChecked: true,
      isAllTypeChecked: true,
      isAllUPTypeCheked: true,
      isALlScheduleChecked: true,
      isALlScheduleTypeChecked: true,
      isStatusCheck: FilterStatusData.map((status) => status.name),
      isScheduleStatusCheck: FilterClientSchStatusData.map(
        (status) => status.name
      ),
      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      isTypeCheck: FilterTypeData.map((type) => type.name),
      // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      isUPTypeCheck: allTypeArray.map((type) => type.name),
      isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      order: "desc",
      orderBy: "transactionTimestamp",
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,
      configName: "",
      openRangeBar: false,
    };
    this.props.fetchScheduleTransactionListByClient(
      this.state.clientId,
      this.props
    );
  }

  componentDidMount() {
    this.props.fetchDescriptionList();
    this.props.fetchSingleClient(this.state.clientId, this.props);
    this.props.clearSchTransFilterData();
  }

  handleAllScheduleCheckbox = () => {
    this.setState({ isALlScheduleChecked: !this.state.isALlScheduleChecked });
    this.setState({
      isScheduleStatusCheck: FilterClientSchStatusData.map((li) => li.name),
    });
    if (this.state.isALlScheduleChecked) {
      this.setState({ isScheduleStatusCheck: [] });
    }
  };

  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };

  handleAllUPTypeCheckbox = () => {
    this.setState({ isAllUPTypeCheked: !this.state.isAllUPTypeCheked });
    this.setState({ isUPTypeCheck: allTypeArray.map((li) => li.name) });
    if (this.state.isAllUPTypeCheked) {
      this.setState({ isUPTypeCheck: [] });
    }
  };

  handleAllScheduleTypeCheckbox = () => {
    this.setState({
      isALlScheduleTypeChecked: !this.state.isALlScheduleTypeChecked,
    });
    this.setState({
      isScheduleTypeCheck: FilterScheduleTimeData.map((li) => li.name),
    });
    if (this.state.isALlScheduleTypeChecked) {
      this.setState({ isScheduleTypeCheck: [] });
    }
  };

  handleSelectedScheduleChange = (e) => {
    const { value, checked } = e.target;

    this.setState({
      isScheduleStatusCheck: [...this.state.isScheduleStatusCheck, value],
    });
    if (!checked) {
      this.setState({
        isScheduleStatusCheck: this.state.isScheduleStatusCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;

    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedScheduleTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState({
      isScheduleTypeCheck: [...this.state.isScheduleTypeCheck, value],
    });
    if (!checked) {
      this.setState({
        isScheduleTypeCheck: this.state.isScheduleTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  handleSelectedUPTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState({ isUPTypeCheck: [...this.state.isUPTypeCheck, value] });
    if (!checked) {
      this.setState({
        isUPTypeCheck: this.state.isUPTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };
  render() {
    const { errors } = this.state;
    const scheduleTransactionState =
      this.props && this.props.scheduleTransactionState;
    const clientTransaction =
      scheduleTransactionState && scheduleTransactionState.clientScheduleTrans;
    const clientName =
      clientTransaction &&
      clientTransaction[0].client &&
      clientTransaction[0].client.firstName +
        " " +
        clientTransaction[0].client.lastName;
    const clientNameForBreadcrumb = clientName;
    // const mediumType =
    // scheduleTransactionState && scheduleTransactionState.mediumType;
    const startDate =
      scheduleTransactionState && scheduleTransactionState.startDate;
    const endDate =
      scheduleTransactionState && scheduleTransactionState.endDate;
    // const scheduleStatus =
    //   scheduleTransactionState && scheduleTransactionState.scheduleStatus;
    // const scheduleType =
    //   scheduleTransactionState && scheduleTransactionState.scheduleType;
    const term = scheduleTransactionState && scheduleTransactionState.term;
    const vendorToFilter =
      scheduleTransactionState && scheduleTransactionState.vendorToFilter;
    const id = this.props.scheduleTransactionState.scheduleTransId;
    const clientId =
      scheduleTransactionState && scheduleTransactionState.clientId;
    const totalScheduleTransaction =
      scheduleTransactionState &&
      scheduleTransactionState.totalScheduleTransaction;
    const status = this.props.scheduleTransactionState.status;
    const open =
      scheduleTransactionState &&
      scheduleTransactionState.openModalToScheduleTransaction;
    const openScheduleTransactionSnackBar =
      scheduleTransactionState &&
      scheduleTransactionState.openScheduleTransactionSnackBar;
    const errorMsg =
      scheduleTransactionState && scheduleTransactionState.errorMsg;
    const openScheduleTransactionSuccessSnackBar =
      scheduleTransactionState &&
      scheduleTransactionState.openScheduleTransactionSuccessSnackBar;
    // const transType = scheduleTransactionState && scheduleTransactionState.transType;

    //  new filter
    const filterStartDate =
      scheduleTransactionState && scheduleTransactionState.filterStartDate;

    const filterEndDate =
      scheduleTransactionState && scheduleTransactionState.filterEndDate;
    const filterAmount =
      scheduleTransactionState && scheduleTransactionState.filterAmount;
    const filterName =
      scheduleTransactionState && scheduleTransactionState.filterName;
    const filterVendor =
      scheduleTransactionState && scheduleTransactionState.filterVendor;
    const filterMedium =
      scheduleTransactionState && scheduleTransactionState.filterMedium;
    const filterType =
      scheduleTransactionState && scheduleTransactionState.filterType;
    const filterStatus =
      scheduleTransactionState && scheduleTransactionState.filterStatus;
    const filterScheduleDate =
      scheduleTransactionState && scheduleTransactionState.filterScheduleDate;

    const configlist =
      scheduleTransactionState && scheduleTransactionState.configList;
    const compareType =
      scheduleTransactionState && scheduleTransactionState.compareType;

    const setButtonDisable = (bool) => {
      this.setState({
        buttonDisable: bool,
      });
    };

    const getDate = (dateToConvert) => {
      return new Date(parseInt(dateToConvert)).toLocaleDateString();
    };

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleInitialCheckboxValue = () => {
      this.setState({
        isChecked: false,
        isALlChecked: true,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isAllUPTypeCheked: true,
        isALlScheduleChecked: true,
        isALlScheduleTypeChecked: true,
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isScheduleStatusCheck: FilterClientSchStatusData.map(
          (status) => status.name
        ),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
        isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      });
    };
    const handleChangeOpenSearchDialogue = () => {
      handleInitialCheckboxValue();
      this.setState({
        openSearchDialogue: true,
      });
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
      });
    };
    const handleCloseDialogueForScheduleTransaction = () => {
      this.setState({
        openDeleteScheduleDialogue: false,
        openUpdateScheduleDialogue: false,
      });
    };

    // const handleOpenDialogueForScheduleTransaction = () => {
    //   this.setState({
    //     openDeleteScheduleDialogue: true,
    //   });
    // };

    const handleOpenDialogueForScheduleTransactionUpdate = () => {
      this.setState({
        openUpdateScheduleDialogue: true,
      });
    };
    const handleCloseDialogueForScheduleTransactionUpdate = () => {
      this.setState({
        openUpdateScheduleDialogue: false,
      });
    };
    const handleOkDeleteScheduleTransactionDialogue = (clientId) => {
      this.props.deleteScheduleTransaction(
        this.state.scheduleClientTrans.scheduleTransactionId,
        clientId,
        this.props
      );
      this.setState({
        openDeleteScheduleDialogue: false,
      });
    };

    //UPDATE AS CANCEL
    const handleCloseUpdateAsCancel = () => {
      this.setState({
        openUpdateCancelDialogue: false,
      });
    };
    const handleOpenUpdateAsCancel = () => {
      this.setState({
        openUpdateCancelDialogue: true,
      });
    };

    //UPDATE AS CLOSE
    const handleOpenUpdateAsClose = () => {
      this.setState({
        openUpdateCloseDialogue: true,
      });
    };
    const handleCloseUpdateAsClose = () => {
      this.setState({
        openUpdateCloseDialogue: false,
      });
    };

    // const getPickerDate = (dateToConvert) => {
    //   let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
    //     "fr-CA"
    //   );
    //   return moment(dateConverted, "YYYY-MM-DD");
    // };

    // UPDATE AS APPROVE
    const handleOpenUpdateAsApprove = () => {
      this.setState({
        openUpdateApproveDialogue: true,
      });
    };
    const handleCloseUpdateAsApprove = () => {
      this.setState({
        openUpdateApproveDialogue: false,
      });
    };
    const handleOkScheduleStatusDialogue = (statusData, clientId) => {
      this.props.updateScheduleTransactionStatus(
        statusData,
        clientId,
        this.props
      );
      this.props.updateScheduleTransactionStatus(
        statusData,
        clientId,
        this.props
      );
      this.setState({
        openUpdateScheduleDialogue: false,
        openUpdateCloseDialogue: false,
      });
    };

    const updateScheduleTransaction = (requestBody) => {
      this.props.updateScheduleTransaction(requestBody, clientId, this.props);
    };

    const addNewScheduleTransaction = (requestBody) => {
      this.props.addNewScheduleTransaction(requestBody, clientId, this.props);
    };
    const vendorList = this.props.vendorState.vendorAccount;

    // activity states
    const activityState = this.props && this.props.activityLogState;
    const activityLogValue = activityState && activityState.activityLogValue;

    const handleOpenActivityLogModal = () => {
      this.setState({
        openActivityLogModal: true,
      });
    };
    const handleCloseActivityLogModal = () => {
      this.setState({
        openActivityLogModal: false,
      });
    };

    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort(
            (a, b) => -descendingComparatorTrans(a, b, orderBy)
          );

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) =>
            descendingComparatorTrans(a, b, orderBy)
          );

        return list;
      }
    }
    const sortedTransactionList = getComparator(
      clientTransaction,
      this.state.order,
      this.state.orderBy
    );
    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
        isClientSchTrans: false,
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
        isClientSchTrans: false,
      });
    };
    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        startDate: filterStartDate === null ? "" : filterStartDate,
        endDate: filterEndDate === null ? "" : filterEndDate,
        clientName: filterName,
        vendorName: filterVendor,
        amount: filterAmount,
        transactionMedium: filterMedium,
        transactionType: filterType,
        transactionStatus: filterStatus,
        compareType: compareType,
        scheduleDate: filterScheduleDate === null ? "" : filterScheduleDate,
        type: 9,

        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };
      this.props.fetchScheduleTransactionListByClient(
        this.state.clientId,
        this.props
      );
      this.props.clearSchTransFilterData();
      this.props.saveInConfig(data);
    };
    const isFilterEmpty =
      filterStartDate !== null ||
      filterEndDate !== null ||
      filterName !== "" ||
      filterVendor !== "" ||
      filterAmount !== "" ||
      filterMedium !== "" ||
      filterType !== "" ||
      filterStatus !== "" ||
      filterScheduleDate !== "";

    const configStatus = this.state.configName !== "" && isFilterEmpty;

    const handleUseConfigFilter = (config) => {
      if (
        config.scheduleFilter.startDate &&
        config.scheduleFilter.startDate !== "0"
      ) {
        this.props.handleScheduleTransFilter(
          "filterStartDate",
          config.scheduleFilter.startDate
        );
      }
      if (
        config.scheduleFilter.endDate &&
        config.scheduleFilter.endDate !== "0"
      ) {
        this.props.handleScheduleTransFilter(
          "filterEndDate",
          config.scheduleFilter.endDate
        );
      }

      if (config.scheduleFilter.vendorName) {
        this.props.handleScheduleTransFilter(
          "filterVendor",
          config.scheduleFilter.vendorName
        );
      }
      if (config.scheduleFilter.transactionMedium) {
        this.props.handleScheduleTransFilter(
          "filterMedium",
          config.scheduleFilter.transactionMedium
        );
      }
      if (config.scheduleFilter.transactionType) {
        this.props.handleScheduleTransFilter(
          "filterType",
          config.scheduleFilter.transactionType
        );
      }
      if (config.scheduleFilter.transactionStatus) {
        this.props.handleScheduleTransFilter(
          "filterStatus",
          config.scheduleFilter.transactionStatus
        );
      }
      if (
        config.scheduleFilter.scheduleDate &&
        config.scheduleFilter.scheduleDate !== "0"
      ) {
        this.props.handleScheduleTransFilter(
          "filterScheduleDate",
          config.scheduleFilter.scheduleDate
        );
      }
      if (config.scheduleFilter.amount) {
        this.props.handleScheduleTransFilter(
          "filterAmount",
          config.scheduleFilter.amount
        );
      }
      if (config.scheduleFilter.comparator) {
        this.props.handleScheduleTransFilter(
          "compareType",
          getRangeType(config.scheduleFilter.comparator)
        );
      }

      this.props.filterScheduleTransactionByAll(
        clientId,
        config.scheduleFilter.startDate ? config.scheduleFilter.startDate : "0",
        config.scheduleFilter.endDate ? config.scheduleFilter.endDate : "0",
        clientNameForBreadcrumb,
        config.scheduleFilter.vendorName
          ? config.scheduleFilter.vendorName
          : "",
        config.scheduleFilter.transactionMedium
          ? config.scheduleFilter.transactionMedium
          : "",
        config.scheduleFilter.transactionType
          ? config.scheduleFilter.transactionType
          : "",
        config.scheduleFilter.transactionStatus
          ? ClientSheduleStatusHelper([config.scheduleFilter.transactionStatus])
          : "",
        "",
        config.scheduleFilter.scheduleDate
          ? config.scheduleFilter.scheduleDate
          : "",
        config.scheduleFilter.amount ? config.scheduleFilter.amount : "",
        config.scheduleFilter.comparator
          ? getRangeType(config.scheduleFilter.comparator)
          : "0"
      );
    };

    // function to set the initaial value of the main filters
    const setInitialValueofMainFilters = () => {
      this.setState({
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isScheduleStatusCheck: FilterClientSchStatusData.map(
          (status) => status.name
        ),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
        isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      });
    };
    return (
      <React.Fragment>
        {this.props.scheduleTransactionState.loading ? (
          <div
            className={
              this.props.scheduleTransactionState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs
          aria-label="breadcrumb"
          className="breadcrumb"
          style={style.breadcrumb}
        >
          <Link
            color="inherit"
            to="/dashboard"
            className="link"
            style={style.link}
          >
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>

          <Link
            color="inherit"
            to="/transaction/schedule"
            className="link"
            style={style.link}
          >
            Schedule Transaction
          </Link>
          <Typography color="inherit" className="link" style={style.link}>
            {clientTransaction &&
              clientTransaction
                .map((name) => (
                  <span>
                    {name.client.firstName + " " + name.client.lastName}
                  </span>
                ))
                .reduce((named) => {
                  if (named) {
                    return <span>{named}</span>;
                  } else {
                    return null;
                  }
                })}
          </Typography>
        </Breadcrumbs>
        <div
          style={{
            paddingTop: "58px",
            margin: "0 3%",
          }}
        >
          <Tabs
            defaultActiveKey="schedule"
            onChange={(key) => {
              this.props.history.push(`/transaction/${key}`);
            }}
            className="fixed-tab"
          >
            <TabPane tab="Transactions" key="list" />
            <TabPane tab="Schedule Transaction" key="schedule" />
            <TabPane tab="Upcoming Transaction" key="upcoming" />
            <TabPane tab="Annual Transaction" key="annual" />
            <TabPane tab="Ledger Transaction" key="ledger" />
            <TabPane tab="Deleted Transaction" key="delete" />
          </Tabs>
        </div>
        <div style={style.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ marginTop: "25px" }}>
                <span style={{ marginRight: "4px", fontSize: "16px" }}>
                  Total Schedule Transaction:
                </span>
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {totalScheduleTransaction ? totalScheduleTransaction : "0"}
                </span>
              </div>
            </div>
            <div className={"d-flex flex-row-reverse"} style={style.flex}>
              <div>
                <button
                  className="settingButton"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                    });
                  }}
                >
                  <img
                    alt={"setting"}
                    src={"/assets/images/sort.png"}
                    style={{ width: "18px", height: "18px" }}
                  />
                </button>
              </div>
              <div>
                <button
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchScheduleTransactionListByClient(
                      this.state.clientId,
                      this.props
                    );
                    this.props.clearScheduleTransactionFilterForm();
                    this.props.clearSchTransFilterData();
                  }}
                >
                  <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
                </button>
              </div>
              <div>
                <button
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchVendor();
                    handleChangeOpenSearchDialogue();
                    this.props.clearScheduleTransactionFilterForm();
                    this.props.clearSchTransFilterData();
                  }}
                >
                  <FilterListIcon
                    style={{ color: "#fff", marginTop: "-2px" }}
                  />
                </button>
              </div>
              {/****** Export Button ********/}
              <div>
                <>
                  {isFilterEmpty ? (
                    <ExportButton
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportClientScheduleTransactionByColumnFilter(
                          clientId,
                          filterStartDate,
                          filterEndDate,
                          filterName,
                          filterVendor,
                          filterMedium,
                          filterType,
                          ClientSheduleStatusHelper([filterStatus]),
                          "",
                          filterScheduleDate,
                          filterAmount,
                          compareType,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportClientScheduleTransactionByColumnFilter(
                          clientId,
                          filterStartDate,
                          filterEndDate,
                          filterName,
                          filterVendor,
                          filterMedium,
                          filterType,
                          ClientSheduleStatusHelper([filterStatus]),
                          "",
                          filterScheduleDate,
                          filterAmount,
                          compareType,
                          "PDF_EXPORT"
                        );
                      }}
                    />
                  ) : (
                    <ExportButton
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportClientScheduleTransactionByAll(
                          clientId,
                          term,
                          filterMedium
                            ? transactionMediumHelper([filterMedium])
                            : transactionMediumHelper(this.state.isMediumCheck),
                          filterStatus
                            ? ClientSheduleStatusHelper([filterStatus])
                            : ClientSheduleStatusHelper(
                                this.state.isScheduleStatusCheck
                              ),
                          // transactionMediumHelper(this.state.isMediumCheck),
                          // ClientSheduleStatusHelper(
                          //   this.state.isScheduleStatusCheck
                          // ),
                          scheduleTimeHelper(this.state.isScheduleTypeCheck),
                          startDate,
                          endDate,
                          filterType
                            ? transactionTypeHelper(
                                [filterType],
                                "transactionType"
                              )
                            : transactionTypeHelper(
                                this.state.isUPTypeCheck,
                                "transactionType"
                              ),
                          // transactionTypeHelper(
                          //   this.state.isUPTypeCheck,
                          //   "transactionType"
                          // ),
                          vendorToFilter,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportClientScheduleTransactionByAll(
                          clientId,
                          term,
                          filterMedium
                            ? transactionMediumHelper([filterMedium])
                            : transactionMediumHelper(this.state.isMediumCheck),
                          filterStatus
                            ? ClientSheduleStatusHelper([filterStatus])
                            : ClientSheduleStatusHelper(
                                this.state.isScheduleStatusCheck
                              ),
                          // transactionMediumHelper(this.state.isMediumCheck),
                          // ClientSheduleStatusHelper(
                          //   this.state.isScheduleStatusCheck
                          // ),
                          scheduleTimeHelper(this.state.isScheduleTypeCheck),
                          startDate,
                          endDate,
                          filterType
                            ? transactionTypeHelper(
                                [filterType],
                                "transactionType"
                              )
                            : transactionTypeHelper(
                                this.state.isUPTypeCheck,
                                "transactionType"
                              ),
                          // transactionTypeHelper(
                          //   this.state.isUPTypeCheck,
                          //   "transactionType"
                          // ),
                          vendorToFilter,
                          "CSV_EXPORT"
                        );
                      }}
                    />
                  )}
                </>
              </div>
              <div>
                <button
                  className="addButton"
                  style={{ width: "215px", marginRight: "12px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isUpdate: false,
                      isFirstOpen: true,
                    });
                    this.props.setOpenModalToScheduleTransaction(true);
                    this.props.clearScheduleTransactionInputData();
                    this.props.clearFileData();
                    this.props.fetchVendor();
                    this.props.fetchVendorClientById(clientId);
                    this.props.getAmountDetailByClientId(clientId);
                    this.props.handleChangeScheduleTransactionInputData(
                      "clientId",
                      clientId
                    );
                  }}
                >
                  <AddIcon /> Schedule Transaction
                </button>
              </div>
              <div className="search">
                <div style={style.searchIcon}>
                  <SearchIcon style={{ color: "#9c9c9c" }} />
                </div>
                <InputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  style={style.inputBase}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      page: 0,
                    });
                    this.props.handleChangeScheduleTerm(e.target.value);
                    if (e.target.value === "" || e.target.value === " ") {
                      this.props.fetchScheduleTransactionListByClient(clientId);
                    } else {
                      this.props.searchClientScheduleTransaction(
                        e.target.value,
                        clientId
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="tableHeader">#</TableCell>
                    <TableCell
                      className="first-tableHeader"
                      key="scheduledDate"
                      sortDirection={
                        this.state.orderBy === "scheduledDate"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "scheduledDate"}
                        direction={
                          this.state.orderBy === "scheduledDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("scheduledDate")}
                      >
                        Start Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="endDate"
                      sortDirection={
                        this.state.orderBy === "endDate"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "endDate"}
                        direction={
                          this.state.orderBy === "endDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("endDate")}
                      >
                        End Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="amount"
                      sortDirection={
                        this.state.orderBy === "amount"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "amount"}
                        direction={
                          this.state.orderBy === "amount"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("amount")}
                      >
                        Amount
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="vendorName"
                      sortDirection={
                        this.state.orderBy === "vendorName"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "vendorName"}
                        direction={
                          this.state.orderBy === "vendorName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("vendorName")}
                      >
                        Vendor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionMedium"
                      sortDirection={
                        this.state.orderBy === "transactionMedium"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionMedium"}
                        direction={
                          this.state.orderBy === "transactionMedium"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionMedium")}
                      >
                        Medium
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionType"
                      sortDirection={
                        this.state.orderBy === "transactionType"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionType"}
                        direction={
                          this.state.orderBy === "transactionType"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionType")}
                      >
                        Transaction Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="scheduleTransactionStatus"
                      sortDirection={
                        this.state.orderBy === "scheduleTransactionStatus"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={
                          this.state.orderBy === "scheduleTransactionStatus"
                        }
                        direction={
                          this.state.orderBy === "scheduleTransactionStatus"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler(
                          "scheduleTransactionStatus"
                        )}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="createdAt"
                      sortDirection={
                        this.state.orderBy === "createdAt"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "createdAt"}
                        direction={
                          this.state.orderBy === "createdAt"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("createdAt")}
                      >
                        Scheduled Date/By/Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="sticky-search-bar">
                    <TableCell />
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={
                            filterStartDate === null ? "" : filterStartDate
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterStartDate",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              e.target.value,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              filterVendor,
                              filterMedium,
                              filterType,
                              filterStatus,
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterEndDate === null ? "" : filterEndDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterEndDate",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              e.target.value,
                              clientNameForBreadcrumb,
                              filterVendor,
                              filterMedium,
                              filterType,
                              filterStatus,
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div
                        className="sm-search"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="show-range-sign">
                          {showGreaterThanSign(compareType)}
                        </span>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterAmount}
                          className="show-range"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterAmount",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              filterVendor,
                              filterMedium,
                              filterType,
                              filterStatus,
                              "",
                              filterScheduleDate,
                              e.target.value,
                              compareType
                            );
                          }}
                        />
                        <ExpandMoreIcon
                          id="Popover1"
                          className="expand-more-icon"
                          onClick={(e) => {
                            this.setState({
                              popoverElement: e.currentTarget,
                              // client: client.client,
                              openRangeBar: true,
                            });
                          }}
                        />
                        <Popover
                          open={this.state.openRangeBar}
                          anchorEl={this.state.popoverElement}
                          onClose={(e) => {
                            e.preventDefault();
                            this.setState({ openRangeBar: false });
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          style={{ boxShadow: "none" }}
                        >
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                  page: 0,
                                });
                                this.props.handleScheduleTransFilter(
                                  "compareType",
                                  "2"
                                );
                                this.props.clearScheduleTransactionFilterForm();
                                setInitialValueofMainFilters();
                                this.props.filterScheduleTransactionByAll(
                                  clientId,
                                  filterStartDate,
                                  filterEndDate,
                                  clientNameForBreadcrumb,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  filterStatus,
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  "2"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Lesser than {`(<)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();

                                this.props.getConfigList("1");
                                this.setState({
                                  openRangeBar: false,
                                  page: 0,
                                });
                                this.props.handleScheduleTransFilter(
                                  "compareType",
                                  "1"
                                );
                                this.props.clearScheduleTransactionFilterForm();
                                setInitialValueofMainFilters();
                                this.props.filterScheduleTransactionByAll(
                                  clientId,
                                  filterStartDate,
                                  filterEndDate,
                                  clientNameForBreadcrumb,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  filterStatus,
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  "1"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Greater than {`(>)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                  page: 0,
                                });
                                this.props.handleScheduleTransFilter(
                                  "compareType",
                                  "0"
                                );
                                this.props.clearScheduleTransactionFilterForm();
                                setInitialValueofMainFilters();
                                this.props.filterScheduleTransactionByAll(
                                  clientId,
                                  filterStartDate,
                                  filterEndDate,
                                  clientNameForBreadcrumb,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  filterStatus,
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  "0"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>None</span>
                            </span>
                          </MenuList>
                        </Popover>
                      </div>
                    </TableCell>

                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterVendor}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterVendor",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              e.target.value,
                              filterMedium,
                              filterType,
                              filterStatus,
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={filterMedium}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleScheduleTransFilter(
                              "filterMedium",
                              e
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              filterVendor,
                              e,
                              filterType,
                              filterStatus,
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        >
                          <Option value="CHECK">Check</Option>
                          <Option value="CASH">Cash</Option>
                          <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                          <Option value="ACH">ACH</Option>
                          <Option value="CARD">Card</Option>
                          <Option value="LEDGER">Ledger</Option>
                          <Option value="OTHER_MEDIUM">Other medium</Option>
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={filterType}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleScheduleTransFilter(
                              "filterType",
                              e
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              filterVendor,
                              filterMedium,
                              e,
                              filterStatus,
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        >
                          {allTypeArray &&
                            allTypeArray.map((list) => (
                              <Option value={list.val}>{list.name}</Option>
                            ))}
                          {/* <Option value="DISBURSEMENT">Disbursement</Option>
                          <Option value="DEPOSIT">Deposit</Option>
                          <Option value="FEE">Fee</Option> */}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={filterStatus}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleScheduleTransFilter(
                              "filterStatus",
                              e
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([e]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        >
                          {FilterClientSchStatusData &&
                            FilterClientSchStatusData.map((list) => (
                              <Option value={list.name}>{list.name}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterScheduleDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterScheduleDate",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({ page: 0 });
                            this.props.filterScheduleTransactionByAll(
                              clientId,
                              filterStartDate,
                              filterEndDate,
                              clientNameForBreadcrumb,
                              filterVendor,
                              filterMedium,
                              filterType,
                              filterStatus,
                              "",
                              e.target.value,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableCol">
                      <MoreHorizIcon
                        id="Popover1"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          this.setState({
                            popoverElement: e.currentTarget,

                            openConfigMenu: true,
                          });
                        }}
                      />

                      <Popover
                        open={this.state.openConfigMenu}
                        anchorEl={this.state.popoverElement}
                        onClose={(e) => {
                          e.preventDefault();
                          this.setState({ openConfigMenu: false });
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        style={{ boxShadow: "none" }}
                      >
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                OpenSaveFilterInConfigmenu: true,
                                openConfigMenu: false,
                                isClientSchTrans: true,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              Save In Config
                            </span>
                          </span>
                        </MenuList>
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.getConfigList("9");
                              this.setState({
                                OpenViewFilterInConfig: true,
                                openConfigMenu: false,
                                isClientSchTrans: true,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              View Config
                            </span>
                          </span>
                        </MenuList>
                      </Popover>
                    </TableCell>

                    <TableCell />
                  </TableRow>
                </TableBody>
                {clientTransaction &&
                  clientTransaction
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((clientTrans, index) => (
                      <TableBody key={clientTrans.scheduleTransactionId}>
                        <TableRow>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "right",
                              height: "3rem",
                              width: "60px",
                            }}
                          >
                            {(this.state.page + 1 - 1) *
                              this.state.rowsPerPage +
                              (index + 1)}
                          </TableCell>
                          <TableCell className="first-tableBody">
                            {clientTrans &&
                              new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(
                                clientTrans.scheduleDetail &&
                                  clientTrans.scheduleDetail.scheduledDate
                                  ? clientTrans.scheduleDetail &&
                                      clientTrans.scheduleDetail.scheduledDate
                                  : "-"
                              )}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              height: "4.5rem",
                            }}
                          >
                            {/* {clientTrans &&
                              new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(
                                clientTrans.scheduleDetail &&
                                  clientTrans.scheduleDetail.endDate
                                  ? clientTrans.endDate &&
                                      clientTrans.scheduleDetail.endDate
                                  : "-"
                              )} */}
                            {clientTrans.scheduleDetail.endDate
                              ? getDate(clientTrans.scheduleDetail.endDate)
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              // textAlign: "right",
                              height: "3rem",
                              width: "60px",
                            }}
                          >
                            {formatter.format(clientTrans.amount / 100)}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            {clientTrans.vendorAccount &&
                              clientTrans.vendorAccount.vendorName}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            {clientTrans.transactionMedium}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            {clientTrans.transactionType &&
                              clientTrans.transactionType.replace("_", " ")}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            <Tag
                              style={style.colors}
                              color={
                                clientTrans.scheduleTransactionStatus ===
                                "SCHEDULE_PENDING"
                                  ? "purple"
                                  : clientTrans.scheduleTransactionStatus ===
                                    "SCHEDULED"
                                  ? "blue"
                                  : clientTrans.scheduleTransactionStatus ===
                                    "APPROVED"
                                  ? "green"
                                  : clientTrans.scheduleTransactionStatus ===
                                    "CLOSED"
                                  ? "orange"
                                  : clientTrans.scheduleTransactionStatus ===
                                    "DISABLED"
                                  ? "volcano"
                                  : clientTrans.scheduleTransactionStatus ===
                                    "CANCELLED"
                                  ? "red"
                                  : "cyan"
                              }
                            >
                              {clientTrans.scheduleTransactionStatus ===
                              "SCHEDULE_PENDING"
                                ? clientTrans.scheduleTransactionStatus.replace(
                                    "SCHEDULE_",
                                    " "
                                  )
                                : clientTrans.scheduleTransactionStatus.replace(
                                    "_",
                                    " "
                                  )}
                            </Tag>
                          </TableCell>
                          <TableCell>
                            {clientTrans.scheduleDetail ? (
                              <div>
                                <div>
                                  {clientTrans.scheduleDetail &&
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(
                                      clientTrans.createdAt
                                        ? clientTrans.createdAt
                                        : "-"
                                    )}
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  {clientTrans.scheduleDetail &&
                                  clientTrans.scheduleDetail.scheduleByName ? (
                                    <>
                                      <Avatar
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                          marginRight: "8px",
                                        }}
                                        src={
                                          clientTrans.scheduleDetail.profilePic
                                            ? clientTrans.scheduleDetail
                                                .profilePic
                                            : "/assets/images/profile.svg"
                                        }
                                      />
                                      <span>
                                        {
                                          clientTrans.scheduleDetail
                                            .scheduleByName
                                        }
                                      </span>
                                      <span> / </span>
                                      <span>
                                        {clientTrans.scheduleDetail
                                          .scheduleType &&
                                          clientTrans.scheduleDetail.scheduleType.replace(
                                            "_",
                                            " "
                                          )}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  openActionMenu: true,
                                  scheduleClientTrans: clientTrans,
                                });
                                this.props.handleChangeStatus(
                                  clientTrans.scheduleTransactionStatus,
                                  clientTrans.scheduleTransactionId
                                );
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                {status === "APPROVED" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                        handleOpenDialogueForScheduleTransactionUpdate();
                                      }}
                                    >
                                      <ScheduleIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Schedule
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsClose();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                    >
                                      <CloseIcon style={{ paddingBottom: 0 }} />
                                      Close
                                    </MenuItem>
                                  </div>
                                ) : status === "SCHEDULED" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsClose();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                    >
                                      <CloseIcon style={{ paddingBottom: 0 }} />{" "}
                                      Close
                                    </MenuItem>
                                  </div>
                                ) : status === "CLOSED" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          openActionMenu: false,
                                          isReopen: true,
                                        });
                                        handleOpenDialogueForScheduleTransactionUpdate();
                                      }}
                                    >
                                      <ScheduleIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Reopen
                                    </MenuItem>
                                  </div>
                                ) : status === "SCHEDULE_PENDING" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsApprove();
                                        this.setState({
                                          openActionMenu: false,
                                          isReopen: false,
                                        });
                                      }}
                                    >
                                      <DoneIcon style={{ paddingBottom: 0 }} />
                                      Approve
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsCancel();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                    >
                                      <CancelOutlinedIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Cancel
                                    </MenuItem>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <MenuItem
                                  hidden={status === "CLOSED"}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setScheduleTransactionInputDataToEdit(
                                      this.state.scheduleClientTrans
                                    );
                                    this.props.setOpenModalToScheduleTransaction(
                                      true
                                    );
                                    this.props.getAmountDetailByClientId(
                                      clientId
                                    );
                                    this.props.handleChangeScheduleTransactionInputData(
                                      "clientId",
                                      clientId
                                    );
                                    this.setState({
                                      openActionMenu: false,
                                      isUpdate: true,
                                      buttonDisable: false,
                                    });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                                {/* <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          openActionMenu: false,
                                          isReopen: true,
                                        });
                                        handleOpenDialogueForScheduleTransactionUpdate();
                                      }}
                                    >
                                      <ScheduleIcon style={{ paddingBottom: 0 }} />{" "}
                                      Reopen
                                    </MenuItem> */}
                              </MenuList>
                            </Popover>
                          </TableCell>
                          <TableCell
                            style={{
                              paddingLeft: "0px",
                              fontSize: "15px",
                              color: "rgb(98, 98, 98)",
                              textAlign: "left",
                              height: "4.5rem",
                              paddingRight: "0px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.fetchActivityLogByRefId(
                                clientTrans.scheduleTransactionId
                              );
                              handleOpenActivityLogModal();
                            }}
                          >
                            <Tooltip title="View Activity Log">
                              <img
                                width={22}
                                src={"/assets/images/activityLog1.svg"}
                                alt=""
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {clientTransaction && clientTransaction.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    clientTransaction.length > 0
                      ? clientTransaction.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>

        {/*Delete schedule transaction*/}
        <Dialog
          open={this.state.openDeleteScheduleDialogue}
          onClose={handleCloseDialogueForScheduleTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete schedule transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForScheduleTransaction}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteScheduleTransactionDialogue(clientId);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to scheduling*/}
        <Dialog
          open={this.state.openUpdateScheduleDialogue}
          onClose={handleCloseDialogueForScheduleTransactionUpdate}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              {this.state.isReopen
                ? "Do you want to reopen the transaction?"
                : "Do you want to schedule the transaction?"}
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForScheduleTransactionUpdate}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "SCHEDULED",
                      };
                      handleOkScheduleStatusDialogue(statusData);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to close*/}
        <Dialog
          open={this.state.openUpdateCloseDialogue}
          onClose={handleCloseUpdateAsClose}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to close the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsClose}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "CLOSED",
                      };
                      handleOkScheduleStatusDialogue(statusData, clientId);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to cancel*/}
        <Dialog
          open={this.state.openUpdateCancelDialogue}
          onClose={handleCloseUpdateAsCancel}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to cancel the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsCancel}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "CANCELLED",
                      };

                      handleOkScheduleStatusDialogue(statusData, clientId);
                      handleCloseUpdateAsCancel();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to approve*/}
        <Dialog
          open={this.state.openUpdateApproveDialogue}
          onClose={handleCloseUpdateAsApprove}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to approve the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsApprove}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "APPROVED",
                      };
                      handleCloseUpdateAsApprove();
                      handleOkScheduleStatusDialogue(statusData, clientId);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openSearchDialogue}
          maxWidth="lg"
          onClose={handleChangeCloseSearchDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseSearchDialogue}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeFromDate(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeToDate(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeFromDate(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeToDate(date);
              }}
            />
            <label style={style.label}> Medium </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterMediumData}
              isItemCheck={this.state.isMediumCheck}
              isAllCheck={this.state.isAllMediumChecked}
              handleAllCheckbox={this.handleAllMediumCheckbox}
              handleSelectedChange={this.handleSelectedMediumChange}
            />
            <label style={style.label}> Status </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterClientSchStatusData}
              isItemCheck={this.state.isScheduleStatusCheck}
              isAllCheck={this.state.isALlScheduleChecked}
              handleAllCheckbox={this.handleAllScheduleCheckbox}
              handleSelectedChange={this.handleSelectedScheduleChange}
            />
            <label style={style.label}>Schedule Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterScheduleTimeData}
              isItemCheck={this.state.isScheduleTypeCheck}
              isAllCheck={this.state.isALlScheduleTypeChecked}
              handleAllCheckbox={this.handleAllScheduleTypeCheckbox}
              handleSelectedChange={this.handleSelectedScheduleTypeChange}
            />
            <label style={style.label}>Transaction Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={allTypeArray}
              isItemCheck={this.state.isUPTypeCheck}
              isAllCheck={this.state.isAllUPTypeCheked}
              handleAllCheckbox={this.handleAllUPTypeCheckbox}
              handleSelectedChange={this.handleSelectedUPTypeChange}
            />
            <label style={style.label}> Vendor </label>
            <div className="select-input h40">
              <Select
                className="inputField"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  this.props.handleChangeVendorToFilter(e);
                }}
              >
                {vendorList &&
                  vendorList.map((list) => (
                    <Option value={list.vendorId}>{list.vendorName}</Option>
                  ))}
              </Select>
            </div>
            <div>
              <div className="row">
                <div className="col-6"></div>
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        page: 0,
                      });
                      handleChangeCloseSearchDialogue();
                      this.props.searchClientScheduleTransactionByAll(
                        // mediumType,
                        // scheduleStatus,
                        // scheduleType,
                        transactionMediumHelper(this.state.isMediumCheck),
                        ClientSheduleStatusHelper(
                          this.state.isScheduleStatusCheck
                        ),
                        scheduleTimeHelper(this.state.isScheduleTypeCheck),
                        startDate,
                        endDate,
                        vendorToFilter,
                        clientId,
                        // transType,
                        transactionTypeHelper(
                          this.state.isUPTypeCheck,
                          "transactionType"
                        ),

                        this.props
                      );
                    }}
                    className="addButton"
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <ScheduleNewTransaction
          openModal={open}
          clientTransaction={clientTransaction}
          setOpenModalToScheduleTransaction={(bool) => {
            this.props.setOpenModalToScheduleTransaction(bool);
          }}
          scheduleTransactionState={scheduleTransactionState}
          transactionState={this.props && this.props.transactionState}
          vendorState={this.props && this.props.vendorState}
          settingState={this.props && this.props.settingState}
          isUpdate={this.state.isUpdate}
          handleChangeScheduleTransactionInputData={(id, value) => {
            this.props.handleChangeScheduleTransactionInputData(id, value);
          }}
          handleChangeTransactionType={(type) => {
            this.props.handleChangeTransactionType(type);
          }}
          handleChangeTransactionMedium={(medium) => {
            this.props.handleChangeTransactionMedium(medium);
          }}
          setButtonDisable={(bool) => {
            setButtonDisable(bool);
          }}
          buttonDisable={this.state.buttonDisable}
          updateScheduleTransaction={(requestBody) => {
            updateScheduleTransaction(requestBody);
          }}
          addNewScheduleTransaction={(requestBody) => {
            addNewScheduleTransaction(requestBody);
          }}
          errors={errors}
          uploadDocumentFile={(fileData) => {
            this.props.uploadDocumentFile(fileData, this.props);
          }}
          handleShowFileUploadErrorMessage={(bool) => {
            this.props.handleShowFileUploadErrorMessage(bool);
          }}
          clientName={clientName}
          fetchSingleClient={() => {
            this.props.fetchSingleClient(clientId, this.props);
          }}
        />

        {/* Activity Log */}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openActivityLogModal}
          onClose={handleCloseActivityLogModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseActivityLogModal}
          >
            Changelog Info
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{ width: "1000px", maxHeight: "1000px", paddingTop: "25px" }}
          >
            {activityLogValue && activityLogValue.length > 0 ? (
              activityLogValue.map((logValue) => (
                <div className="changeLogOuterDiv">
                  <div>
                    <Avatar
                      className="changeLogAvatar"
                      src={
                        logValue.profilePic
                          ? logValue.profilePic
                          : "/assets/images/profile.svg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "25px", fontSize: "16px" }}>
                    <span style={{ fontWeight: 600 }}>
                      {logValue.userName ? logValue.userName : "User"}
                    </span>{" "}
                    changed{" "}
                    <span style={{ fontWeight: 600 }}>
                      {logValue.activity ===
                      "schedule.transaction.status.updated"
                        ? "schedule transaction status to"
                        : "schedule transactions."}
                    </span>{" "}
                    {logValue.activity ===
                    "schedule.transaction.status.updated" ? (
                      <span>"{logValue.refValue}"</span>
                    ) : (
                      <ChangedLogPopover
                        refValue={logValue.refValue}
                        formatter={formatter}
                      />
                    )}
                    <div style={{ fontSize: "14px" }}>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(logValue.createdAt)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>
                  <img src="/assets/images/activityLog1.svg" alt="" />
                </div>
                <div>No changes till date !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config.configFilters);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.transactionState.openTransactionSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={this.props.transactionState.errorMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openScheduleTransactionSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeScheduleTransactionSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeScheduleTransactionSnackBar}
            variant="error"
            message={errorMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openScheduleTransactionSuccessSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeScheduleTransactionSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeScheduleTransactionSnackBar}
            variant="success"
            message="Successfully Updated"
          />
        </Snackbar>
        {/******* Success/Error Message ********/}
        <SnackBarComponent
          onClose={this.props.closeScheduleTransactionSnackBar}
          variant="success"
          open={
            scheduleTransactionState &&
            scheduleTransactionState.openSuccessSnackBar
          }
          message={
            scheduleTransactionState && scheduleTransactionState.successMsg
          }
        />
        <SnackBarComponent
          onClose={this.props.closeScheduleTransactionSnackBar}
          variant="error"
          open={
            scheduleTransactionState &&
            scheduleTransactionState.openErrorSnackBar
          }
          message={
            scheduleTransactionState && scheduleTransactionState.errorMsg
          }
        />
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    margin: "5px 3% 1% 2%",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "23%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    marginTop: "20px",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "180px",
    height: "40px",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
    marginTopL: "48px",
  },
  icon: {
    position: "absolute",
    top: "53px",
    right: "30px",
    color: " rgb(200, 197, 197)",
    fontSize: "32px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },

  filterButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
    marginRight: "12px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
    fontWeight: "600",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableHeaderAmount: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "right",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
    marginleft: "12px",
  },
  exportButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "100px",
    height: "35px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "190px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
    height: "40px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default IndividualClientScheduleTransactionScreen;
