import React, { Component } from "react";

import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import "../styles/styles.css";
import "./calendarStyle.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Avatar,
  Checkbox,
  Select,
  Tabs,
  Tag,
  Popover as PopoverContent,
} from "antd";
import { UserDeleteOutlined } from "@ant-design/icons";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  TableSortLabel,
} from "@material-ui/core";
// import { CheckOutlined} from '@ant-design/icons';
import ActiveStatusPopover from "./ActiveStatusPopover";
import { finalAmount, naturalRound } from "../utils/currencyConvertor";
import TablePagination from "@material-ui/core/TablePagination";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CloseIcon from "@material-ui/icons/Close";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { LocalDb } from "../api";
import { lighten, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MySnackbarContentWrapper from "../components/Snackbar";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import { Dropdown } from "semantic-ui-react";
import DocumentsScreen from "./DocumentsScreen";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ClientPaymentSettingScreen from "./ClientPaymentSettingScreen";
import DialogTitleComponent from "./DialogTitleComponent";
import EligibilityCalculationComponent from "../components/EligibilityCalculationComponent";
import NoteComponent from "../components/NoteComponent";
import ActivityLogTable from "./ActivityLogTable";
import Helpers, { arraysEqual } from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import {
  getFormattedDateTimeValue,
  getFormattedDateValue,
} from "../utils/DateConverter";
import OpenContactFields from "../components/DynamicContactField";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import NotesPopover from "../components/NotesPopover";
import ExportButton from "../components/ExportButton";
import AlertNotificationEnableScreen from "./AlertNotificationEnabledScreen";
import CreateTransactionForm from "./Transaction/CreateTransactionForm";
import ScheduleNewTransaction from "./scheduleTransaction/ScheduleNewTransaction";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import ChangedLogPopover from "../components/ChangedLogPopover";
import SnackBarComponent from "../components/SnackBarComponenet";
import PopoverForMenu from "../components/PopoverForMenu";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
// import VendorClientScreen from "../screens/VendorClientScreen"
import { VendorClientContainer } from "../components/container/VendorClientsContainer";
import {
  FilterStatusData,
  FilterMediumData,
  FilterTypeData,
  FilterClientSchStatusData,
  FilterUPTypeData,
  FilterScheduleTimeData,
  FilterTypeDataForLedger,
  allTypeArray,
} from "../components/FilterOptionList";
import {
  transactionStatusHelper,
  transactionMediumHelper,
  transactionTypeHelper,
  ClientSheduleStatusHelper,
  scheduleTimeHelper,
  descendingComparatorTrans,
  showGreaterThanSign,
  getRangeType,
} from "./TransactionHelper";
import CheckboxFilterPopover from "../components/CheckboxFilterPopover";
import { getDateWithStartTimeValue } from "../utils/DateConverter";
const { TabPane } = Tabs;
const { Option } = Select;
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

class ClientDetailPageScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    var pageURL = window.location.href;
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openSearchDialogue: false,
      openDeleteScheduleDialogue: false,
      openUpdateClientDialogue: false,
      openUpdateScheduleDialogue: false,
      openUpdateCancelDialogue: false,
      openUpdateCloseDialogue: false,
      openUpdateApproveDialogue: false,
      openDeleteCaseDialogue: false,
      openAddBankDetailDialogue: false,
      openDeleteBankDetailDialogue: false,
      openUpdateBankDetailDialogue: false,
      openDialogToRegisterCase: false,
      openModalToSetPassword: false,
      openModalToEditClient: false,
      openModalToEditBlockedTransaction: false,
      openModalToUndoReverse: false,
      openActionMenu: false,
      openActionMenu1: false,
      popoverElement: null,
      popoverElement1: null,
      updatedContact: [],
      scheduleClientTrans: {},
      case: {},
      bankList: {},
      caseTemplate: [],
      filteredWorkFlow: [],
      agent: [],
      allAgentsAssigned: [],
      clientId: pageURL.substr(pageURL.lastIndexOf("/") + 1),
      clientDetailTabs: localStorage.getItem("clientDetailTabs"),
      clicked: false,
      checkedValue: false,
      setValue: false,
      openModalToAddEvent: false,
      contacts: [],
      errors: {
        bankAccountNumber: "",
        accountName: "",
        routingNumber: "",
      },
      scheduleError: {
        amount: "",
        scheduledDate: "",
        endDate: "",
        note: "",
      },
      end: "",
      start: "",
      currentView: "month",
      openGeneralTransactionModal: false,
      transactionPage: 0,
      transactionRowPerPage: 8,
      upcomingTransactionPage: 0,
      upcomingTransactionRowPerPage: 8,
      openModalToChangeTransStatus: false,
      openModalToApproveTransaction: false,
      transactionId: "",
      openActivityLogModal: false,
      isDecline: false,
      openScheduleFilter: false,
      isUpdate: false,
      isTransUpdate: false,
      selectedTransData: "",
      buttonDisable: false,
      openActivityLogModalForSchedule: false,
      trans: {},
      openDropDown: false,
      tempRowId: "",
      openModalToDeleteTransaction: false,
      visible: false,
      corporateTransactionType: "DEPOSIT",
      customIndex: 0,
      isALlChecked: true,
      isAllMediumChecked: true,
      isAllTypeChecked: true,
      isALlScheduleChecked: true,
      isAllUPTypeCheked: true,
      isALlScheduleTypeChecked: true,
      isAllLedCaseTypeChecked: true,
      isStatusCheck: FilterStatusData.map((status) => status.name),
      isScheduleStatusCheck: FilterClientSchStatusData.map(
        (status) => status.name
      ),
      isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      isTypeCheck: FilterTypeData.map((type) => type.name),
      // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      isUPTypeCheck: allTypeArray.map((type) => type.name),
      isLedCaseTypeCheck: FilterTypeDataForLedger.map((type) => type.name),
      openModalToDeclineCheck: false,
      prevAmount: "",
      order: "desc",
      orderBy: "transactionTimestamp",
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,
      configName: "",
      isClientSchTrans: false,
      openRangeBar: false,
      openRangeBarSCH: false,
      isGreaterThan: 0,
      upcomingTrans: "",
      isProcess: false,
      openDialogueToProcess: false,
      openModalToFilterUPTransaction: false,
      openTrnxInfoDialogue: false,
      openConfirmationToUndoReverse: false,
      openDialogueToDischargeTransaction: false,
      openDialogueToHoldTransaction: false,
      openDialogueToResumeTransaction: false,
      changeCurrnetTrnxStatusTo: "",
      openToolbarToChangeStatus: false,
    };
    this.props.handleChangeCaseInRegisterCase("clientId", this.state.clientId);
  }

  componentDidMount() {
    this.props.fetchDescriptionList();
    // const vendors = this.props.state.vendorState.vendors.vendorAccount;
    const clientId = this.props.match.params.clientId;
    this.props.getClientUpcomingTrans(clientId);

    const accountId =
      this.props.location.state && this.props.location.state.accountId;

    this.props.fetchSingleClient(clientId, this.props);
    this.props.getClientTransaction(clientId);
    this.props.fetchScheduleTransactionListByClient(clientId);
    this.props.fetchCaseByClient(clientId);
    this.props.fetchCaseTemplateList();
    this.props.fetchUserList();
    this.props.fetchContactFilesList(this.state.clientId, this.props);
    this.props.fetchDataListForCalendarByClientId(this.state.clientId);
    this.props.fetchClientPayment(this.state.clientId, this.props);
    this.props.fetchActivityLogByClientId(this.state.clientId, "", "");
    this.props.fetchHolidaysList();
    this.props.setOpenModalToScheduleTransaction(false);
    this.props.setOpenCreateTransaction(false);
    this.props.clearTransactionFilter();
    this.props.clearSchTransFilterData();
    // this.props.getClientTransactionSuccess()
    if (accountId === undefined) {
      this.props.getClientAssignedEvent(clientId);
    } else {
      // this.props.getAlertInfo(accountId);
      this.props.getClientAssignedEvent(accountId);
    }
    const clientState = this.props.state.clientState;
    const clientData = clientState && clientState.clientData;

    this.props.fetchNoteById(clientId, this.props);
    this.setState({
      contacts: clientData.contacts,
      updatedContact: clientData.contacts,
    });
    if (clientData.isEnableTransaction && clientData.isEnableTransaction) {
      this.setState({
        isEnableTransaction: true,
      });
    }
  }

  componentWillUnmount() {
    localStorage.setItem("clientDetailTabs", "0");
    this.props.clearEligibilitySetting();
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleAllCheckbox = () => {
    this.setState({ isALlChecked: !this.state.isALlChecked });
    this.setState({ isStatusCheck: FilterStatusData.map((li) => li.name) });
    if (this.state.isALlChecked) {
      this.setState({ isStatusCheck: [] });
    }
  };

  handleAllScheduleCheckbox = () => {
    this.setState({ isALlScheduleChecked: !this.state.isALlScheduleChecked });
    this.setState({
      isScheduleStatusCheck: FilterClientSchStatusData.map((li) => li.name),
    });
    if (this.state.isALlScheduleChecked) {
      this.setState({ isScheduleStatusCheck: [] });
    }
  };

  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };

  handleAllLedCaseTypeCheckbox = () => {
    this.setState({
      isAllLedCaseTypeChecked: !this.state.isAllLedCaseTypeChecked,
    });
    this.setState({
      isLedCaseTypeCheck: FilterTypeDataForLedger.map((li) => li.name),
    });
    if (this.state.isAllLedCaseTypeChecked) {
      this.setState({ isLedCaseTypeCheck: [] });
    }
  };

  handleAllScheduleTypeCheckbox = () => {
    this.setState({
      isALlScheduleTypeChecked: !this.state.isALlScheduleTypeChecked,
    });
    this.setState({
      isScheduleTypeCheck: FilterScheduleTimeData.map((li) => li.name),
    });
    if (this.state.isALlScheduleTypeChecked) {
      this.setState({ isScheduleTypeCheck: [] });
    }
  };

  handleAllUPTypeCheckbox = () => {
    this.setState({ isAllUPTypeCheked: !this.state.isAllUPTypeCheked });
    this.setState({ isUPTypeCheck: allTypeArray.map((li) => li.name) });
    if (this.state.isAllUPTypeCheked) {
      this.setState({ isUPTypeCheck: [] });
    }
  };

  handleSelectedChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isStatusCheck: [...this.state.isStatusCheck, value] });
    this.setState({ isALlChecked: !this.state.isALlChecked });
    if (!checked) {
      this.setState({
        isStatusCheck: this.state.isStatusCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedScheduleChange = (e) => {
    const { value, checked } = e.target;
    this.setState({
      isScheduleStatusCheck: [...this.state.isScheduleStatusCheck, value],
    });
    this.setState({ isALlScheduleChecked: !this.state.isALlScheduleChecked });
    if (!checked) {
      this.setState({
        isScheduleStatusCheck: this.state.isScheduleStatusCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  handleSelectedUPTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isUPTypeCheck: [...this.state.isUPTypeCheck, value] });
    if (!checked) {
      this.setState({
        isUPTypeCheck: this.state.isUPTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedLedCaseTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({
      isLedCaseTypeCheck: [...this.state.isLedCaseTypeCheck, value],
    });
    if (!checked) {
      this.setState({
        isLedCaseTypeCheck: this.state.isLedCaseTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedScheduleTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({
      isScheduleTypeCheck: [...this.state.isScheduleTypeCheck, value],
    });
    if (!checked) {
      this.setState({
        isScheduleTypeCheck: this.state.isScheduleTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };

  render() {
    const { errors } = this.state;
    const { scheduleError } = this.state;
    const calendarState = this.props.state && this.props.state.calendarState;
    const calendarDetails = calendarState && calendarState.details;
    const caseTemplateState = this.props.state.caseTemplateState;
    const userState = this.props.state.userState;

    // transaction state
    const transactionState = this.props.state.transactionState;
    const clientTransaction = transactionState.clientTransaction;
    const totalBalance = transactionState && transactionState.totalBalance;
    const totalTransaction =
      transactionState && transactionState.totalTransaction;
    const startDate = transactionState && transactionState.from;
    const endDate = transactionState && transactionState.to;
    // const mediumType = transactionState && transactionState.mediumType;
    // const txnStatus = transactionState && transactionState.transactionStatus;
    // const transactionType = transactionState && transactionState.transType;
    const term = transactionState && transactionState.term;
    const vendorToFilter = transactionState && transactionState.vendorToFilter;
    const trasactionLoading = transactionState && transactionState.loading;
    const transStatus = transactionState && transactionState.transStatus;
    const declineRemark = transactionState && transactionState.declineRemark;
    const reversedDate = transactionState && transactionState.reverseDate;
    // const openNotesModal = transactionState && transactionState.openNotesModal;
    const vendorList =
      this.props.state &&
      this.props.state.vendorState &&
      this.props.state.vendorState.vendorAccount;
    // const trans = transactionState && transactionState.trans;

    // new filter states
    const transDate = transactionState && transactionState.filterDate;
    const desc = transactionState && transactionState.filterDesc;
    const amount = transactionState && transactionState.filterAmount;
    const checkNo = transactionState && transactionState.filterCheckNo;
    const user = transactionState && transactionState.filterUser;
    const type = transactionState && transactionState.filterType;
    const stat = transactionState && transactionState.filtetStatus;
    const vendor = transactionState && transactionState.filterVendor;
    const medium = transactionState && transactionState.filterMedium;
    const compareType = transactionState && transactionState.compareType;
    const configlist = transactionState && transactionState.configlist;
    const filterDebitAmount =
      transactionState && transactionState.filterDebitAmount;
    const filterCreditAmount =
      transactionState && transactionState.filterCreditAmount;

    const scheduleTransactionState = this.props.state.scheduleTransactionState;
    const filterStartDate =
      scheduleTransactionState && scheduleTransactionState.filterStartDate;

    const filterEndDate =
      scheduleTransactionState && scheduleTransactionState.filterEndDate;
    const filterAmount =
      scheduleTransactionState && scheduleTransactionState.filterAmount;
    const filterName =
      scheduleTransactionState && scheduleTransactionState.filterName;
    const filterVendor =
      scheduleTransactionState && scheduleTransactionState.filterVendor;
    const filterMedium =
      scheduleTransactionState && scheduleTransactionState.filterMedium;
    const filterType =
      scheduleTransactionState && scheduleTransactionState.filterType;
    const filterStatus =
      scheduleTransactionState && scheduleTransactionState.filterStatus;
    const filterScheduleDate =
      scheduleTransactionState && scheduleTransactionState.filterScheduleDate;
    const compareTypeSCH =
      scheduleTransactionState && scheduleTransactionState.compareType;
    // client state
    const clientState = this.props.state.clientState;
    const email = clientState && clientState.email;
    const blockedData = clientState && clientState.blockedTransData;
    const caseDetails = clientState && clientState.caseDetails;
    const accountName = clientState && clientState.accountName;
    const accountNumber = clientState && clientState.accountNumber;
    const routingNumber = clientState && clientState.routingNumber;
    const bankName = clientState && clientState.bankName;
    const bankAccountType = clientState && clientState.bankAccountType;
    const clientData = clientState && clientState.clientData;
    const clientNameForSchedule =
      clientData && clientData.firstName + " " + clientData.lastName;
    const openClientFormModal = clientState && clientState.openClientFormModal;
    const clientRes = clientState && clientState.clientRes;
    const disableButton =
      clientRes && clientRes.isEnableTransaction === undefined;
    const bankDetail = clientRes.bankDetail;
    const clientNameForBreadcrumb =
      clientRes && clientRes.firstName + " " + clientRes.lastName;
    const openNotesModalForClient =
      clientState && clientState.openNotesModalForClient;
    //schedule state
    const scheduleState = this.props.state.scheduleTransactionState;
    const scheduleLoading = scheduleState && scheduleState.loading;
    const id = scheduleState && scheduleState.scheduleTransId;
    const clientScheduleTrans = scheduleState.clientScheduleTrans;
    const status = scheduleState && scheduleState.status;
    const openScheduleTransactionSnackBar =
      scheduleState && scheduleState.openScheduleTransactionSnackBar;
    const errorMsg = scheduleState && scheduleState.errorMsg;
    const totalScheduleTransaction =
      scheduleState && scheduleState.totalScheduleTransaction;
    // const scheduleMediumType = scheduleState && scheduleState.mediumType;
    const scheduleStartDate = scheduleState && scheduleState.startDate;
    const scheduleEndDate = scheduleState && scheduleState.endDate;
    // const scheduleStatus = scheduleState && scheduleState.scheduleStatus;
    // const scheduleType = scheduleState && scheduleState.scheduleType;
    const scheduleTerm = scheduleState && scheduleState.term;
    // const transTypeForSchedule = scheduleState && scheduleState.transType;
    const scheduleVendorToFilter =
      scheduleState && scheduleState.vendorToFilter;

    const blockedStatus =
      blockedData.amount !== this.state.prevAmount || blockedData.date !== null;
    //upcoming transaction
    const upcomingTransactionState =
      this.props && this.props.state.upcomingTransactionState;
    const clientUpcomingTransaction =
      upcomingTransactionState &&
      upcomingTransactionState.clientUpcomingTransaction;

    const UPStartDate =
      upcomingTransactionState && upcomingTransactionState.startDate;
    const UPEndDate =
      upcomingTransactionState && upcomingTransactionState.endDate;

    // new filter
    const UPDate =
      upcomingTransactionState && upcomingTransactionState.filterDate;
    const UPAmount =
      upcomingTransactionState && upcomingTransactionState.filterAmount;
    const UPUser =
      upcomingTransactionState && upcomingTransactionState.filterUser;
    const UPVendor =
      upcomingTransactionState && upcomingTransactionState.filterVendor;
    const UPMedium =
      upcomingTransactionState && upcomingTransactionState.filterMedium;
    const UPType =
      upcomingTransactionState && upcomingTransactionState.filterType;
    const UPSchDate =
      upcomingTransactionState && upcomingTransactionState.filterSchedule;

    const UPCompareType =
      upcomingTransactionState && upcomingTransactionState.compareType;

    const openModalToSkipTransaction =
      upcomingTransactionState &&
      upcomingTransactionState.openModalToSkipTransaction;
    const remarkToSkip =
      upcomingTransactionState && upcomingTransactionState.remarkToSkip;
    const skipTransactionValue = remarkToSkip !== "";
    const declineStatus = declineRemark !== "";
    const setButtonDisable = (bool) => {
      this.setState({
        buttonDisable: bool,
      });
    };
    const updateScheduleTransaction = (requestBody) => {
      this.props.updateScheduleTransaction(requestBody, clientId, this.props);
    };

    const addNewScheduleTransaction = (requestBody) => {
      this.props.addNewScheduleTransaction(requestBody, clientId, this.props);
    };

    let userRoleAgent = {};
    if (userState.user && userState.user.length >= 1) {
      userRoleAgent = userState.user.filter((e) => e.userRole === "AGENT");
    }
    // count for transactions in corporate client
    let count = 0;
    const changeAssignAgents = (workFlowId, value) => {
      let a = createObject(workFlowId, value);
      this.setState({ allAgentsAssigned: a }, () => {
        this.props.handleChangeCaseInRegisterCase(
          "caseAgents",
          this.state.allAgentsAssigned
        );
      });
    };
    const createObject = (workFlowId, value) => {
      let allAgentsAssigned = this.state.allAgentsAssigned.filter(
        (single) => single.refId !== workFlowId
      );

      let data = {
        refId: workFlowId,
        userId: value,
      };
      allAgentsAssigned.push(data);

      return allAgentsAssigned;
    };
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB.loginResponse.loginUser.corporate
        ? "CORPORATE_USER"
        : "CLIENT_USER"
      : null;
    const userRole =
      userType === "EVERFUND_USER"
        ? LocalDb.getSessions().loginResponse.loginUser.user.userRole
        : null;

    const userRoleData =
      userInfoFromDB.loginResponse.loginUser.user &&
      userInfoFromDB.loginResponse.loginUser.user.userRole;
    const accountTypeData =
      userInfoFromDB.loginResponse.loginUser.user &&
      userInfoFromDB.loginResponse.loginUser.user.accountType;

    const clientId = this.props.match.params.clientId;
    const transactionClientDetail =
      clientTransaction && clientTransaction[0].Client;

    const handleOpenActivityLogModalForSchedule = () => {
      this.setState({
        openActivityLogModalForSchedule: true,
      });
    };
    const handleCloseActivityLogModalForSchedule = () => {
      this.setState({
        openActivityLogModalForSchedule: false,
      });
    };

    const handleChangeOpenModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: true,
      });
    };

    const handleChangeCloseModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: false,
        transactionId: "",
      });
      this.props.handleChangeRemark("");
    };
    const handleChangeOpenModalToUndoReverse = () => {
      this.setState({
        openModalToUndoReverse: true,
      });
    };
    const handleChangeCloseModalToUndoReverse = () => {
      this.setState({
        openModalToUndoReverse: false,
        // transactionId: "",
      });
      this.props.handleChangeRemark("");
    };
    const handleUpdateCheckStatus = () => {
      const checkData = {
        // checkPrintId:
        transactionId: this.state.transactionId,
        description: declineRemark,
      };
      this.props.updateCheckStatus(checkData, clientId, this.props);
    };
    const handleModalToEditBlockedTransaction = () => {
      this.setState({
        openModalToEditBlockedTransaction: true,
      });
    };
    const handleCloseToEditBlockedTransaction = () => {
      this.setState({
        openModalToEditBlockedTransaction: false,
        transactionId: "",
      });
      this.props.clearEditBlockedTrans();
    };
    const handleChangeBlockedTransaction = () => {
      if (blockedData.date === null) {
        const data = {
          transactionId: this.state.transactionId,
          amount: blockedData.amount,
          // transactionTimestamp: blockedData.date === null ? 0 : blockedData.date,
        };

        this.props.editBlockedTransaction(data, clientId, this.props);
      } else {
        const data = {
          transactionId: this.state.transactionId,
          amount: blockedData.amount,
          transactionTimestamp:
            blockedData.date === null ? 0 : blockedData.date,
        };

        this.props.editBlockedTransaction(data, clientId, this.props);
      }
    };
    const getDate = (dateToConvert) => {
      return new Date(parseInt(dateToConvert)).toLocaleDateString();
    };

    function callback(key) {
      localStorage.setItem("clientDetailTabs", key);
    }
    const setPageChange = () => {
      this.setState({
        page: 0,
        rowsPerPage: 10,
      });
    };
    // handle change pages
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeTransactionPage = (event, newPage) => {
      this.setState({
        transactionPage: newPage,
      });
    };
    const handleChangeTransactionRowsPerPage = (event) => {
      this.setState({
        transactionRowPerPage: parseInt(event.target.value, 10),
      });
    };
    const handleChangeUpcomingTransactionPage = (event, newPage) => {
      this.setState({
        upcomingTransactionPage: newPage,
      });
    };
    const handleChangeUpcomingTransactionRowsPerPage = (event) => {
      this.setState({
        upcomingTransactionRowPerPage: parseInt(event.target.value, 10),
      });
    };
    const handleOpenGeneralTransactionModal = () => {
      this.setState({
        openGeneralTransactionModal: true,
      });
    };
    const handleCloseGeneralTransactionModal = () => {
      this.setState({
        openGeneralTransactionModal: false,
      });
    };
    const handleCloseDialogueForDeleteCase = () => {
      this.setState({
        openDeleteCaseDialogue: false,
      });
    };
    const handleOpenDialogueForDeleteCaseTemplate = (caseId) => {
      this.setState({
        openDeleteCaseDialogue: true,
        caseId: caseId,
      });
    };

    const handleOpenDialogToRegisterCase = () => {
      this.setState({
        openDialogToRegisterCase: true,
        filteredWorkFlow: [],
      });
    };
    const handleCloseDialogToRegisterCase = () => {
      this.setState({
        openDialogToRegisterCase: false,
      });
    };
    const handleOkDeleteCaseDialogue = () => {
      this.props.deleteCase(this.state.case.caseId, clientId, this.props);
      this.setState({
        openDeleteCaseDialogue: false,
      });
    };
    // delete
    const handleOkDeleteScheduleTransactionDialogue = (clientId) => {
      this.props.deleteScheduleTransaction(
        this.state.scheduleClientTrans.scheduleTransactionId,
        clientId,
        this.props
      );
      this.setState({
        openDeleteScheduleDialogue: false,
        scheduleTransactionId: "",
      });
    };
    const handleCloseDialogueForScheduleTransaction = () => {
      this.setState({
        openDeleteScheduleDialogue: false,
        openUpdateScheduleDialogue: false,
      });
    };
    // const handleOpenDialogueForScheduleTransaction = (
    //   scheduleTransactionId
    // ) => {
    //   this.setState({
    //     openDeleteScheduleDialogue: true,
    //     scheduleTransactionId: scheduleTransactionId,
    //   });
    // };
    // UPDATE AS APPROVE
    // const handleOpenUpdateAsApprove = () => {
    //   this.setState({
    //     openUpdateApproveDialogue: true,
    //   });
    // };
    const handleCloseUpdateAsApprove = () => {
      this.setState({
        openUpdateApproveDialogue: false,
      });
    };
    //UPDATE API
    const handleOkScheduleStatusDialogue = (statusData, clientId) => {
      this.props.updateScheduleTransactionStatus(
        statusData,
        clientId,
        this.props
      );
      this.setState({
        openUpdateScheduleDialogue: false,
        openUpdateCloseDialogue: false,
      });
    };
    //UPDATE AS SCHEDULE
    const handleOpenDialogueForScheduleTransactionUpdate = () => {
      this.setState({
        openUpdateScheduleDialogue: true,
      });
    };
    const handleCloseDialogueForScheduleTransactionUpdate = () => {
      this.setState({
        openUpdateScheduleDialogue: false,
      });
    };
    //UPDATE AS CANCEL
    const handleCloseUpdateAsCancel = () => {
      this.setState({
        openUpdateCancelDialogue: false,
      });
    };
    const handleOpenUpdateAsCancel = () => {
      this.setState({
        openUpdateCancelDialogue: true,
      });
    };

    //UPDATE AS CLOSE
    const handleOpenUpdateAsClose = () => {
      this.setState({
        openUpdateCloseDialogue: true,
      });
    };
    const handleCloseUpdateAsClose = () => {
      this.setState({
        openUpdateCloseDialogue: false,
      });
    };

    //add bank detail
    const handleCloseDialogueToAddBankDetails = () => {
      this.setState({
        openAddBankDetailDialogue: false,
      });
    };
    const handleOpenDialogueToAddBankDetails = () => {
      this.setState({
        openAddBankDetailDialogue: true,
        errors: {
          bankAccountNumber: "",
          accountName: "",
          routingNumber: "",
        },
      });
    };

    // handle to open transaction filter modal

    const handleInitialCheckboxValue = () => {
      this.setState({
        isALlChecked: true,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isALlScheduleChecked: true,
        isAllUPTypeCheked: true,
        isALlScheduleTypeChecked: true,
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isScheduleStatusCheck: FilterClientSchStatusData.map(
          (status) => status.name
        ),
        isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
      });
    };

    const handleChangeOpenSearchDialogue = () => {
      handleInitialCheckboxValue();
      this.setState({
        openSearchDialogue: true,
      });
      this.props.clearSnackBar();
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
      });
    };
    // handle to open  schedule transaction filter modal
    const handleOpenScheduleTransactionFilter = () => {
      handleInitialCheckboxValue();
      this.setState({
        openScheduleFilter: true,
      });
      // this.props.clearSnackBar();
    };
    const handleCloseScheduleTransactionFilter = () => {
      this.setState({
        openScheduleFilter: false,
      });
    };

    //date filter
    // const getPickerDate = (dateToConvert) => {
    //   let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
    //     "fr-CA"
    //   );
    //   return moment(dateConverted, "YYYY-MM-DD");
    // };
    // const handleChangeDateFrom = (range) => {
    //   if (range) {
    //     const valueOfInput1 = range.format();
    //     this.props.handleChangeTransactionDateFrom(
    //       new Date(valueOfInput1).getTime()
    //     );
    //   } else if (range === null) {
    //     this.props.handleChangeTransactionDateFrom(
    //       new Date(0).getTime(),
    //       new Date(0).getTime()
    //     );
    //   }
    // };
    // const handleChangeDateTo = (range) => {
    //   if (range) {
    //     const valueOfInput1 = range.format();
    //     this.props.handleChangeTransactionDateTo(
    //       new Date(valueOfInput1).getTime()
    //     );
    //   } else if (range === null) {
    //     this.props.handleChangeTransactionDateTo(
    //       new Date(0).getTime(),
    //       new Date(0).getTime()
    //     );
    //   }
    // };

    // var date = new Date();
    // const filteredValue =
    //   startDate !== null ||
    //   endDate !== null ||
    //   mediumType !== "UNKNOWN_TRANSACTION_MEDIUM" ||
    //   txnStatus !== "UNKNOWN_TRANSACTION_STATUS" ||
    //   transactionType !== "UNKNOWN_TRANSACTION_TYPE" ||
    //   vendorToFilter !== "";

    // open info dialog for transaction
    const handleOpenDialogue = () => {
      this.setState({
        openTrnxInfoDialogue: true,
      });
    };

    const handleCloseDialogue = () => {
      this.setState({
        openTrnxInfoDialogue: false,
      });
    };
    //delete bank details
    const handleOpenDialogueToDeleteAccount = () => {
      this.setState({
        openDeleteBankDetailDialogue: true,
      });
    };
    const handleCloseDialogueToDeleteAccount = () => {
      this.setState({
        openDeleteBankDetailDialogue: false,
      });
    };
    //update bank details
    const handleCloseDialogueToUpdateBankDetails = () => {
      this.setState({
        openUpdateBankDetailDialogue: false,
      });
    };
    const handleOpenDialogueToUpdateBankDetails = () => {
      this.setState({
        openUpdateBankDetailDialogue: true,
        errors: {
          bankAccountNumber: "",
          accountName: "",
          routingNumber: "",
        },
      });
    };
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    const uploadDocumentFile = (singleFile) => {
      this.props.uploadDocumentFile(singleFile, this.props);
    };
    const uploadImageFile = (singleFile) => {
      this.props.uploadImageFile(singleFile, this.props);
    };

    const contactState = this.props.state && this.props.state.contactState;
    const contactFileData = contactState && contactState.contactFileData;
    const contactFileList = contactState && contactState.contactFileList;
    const fileResponse =
      this.props.state.clientState && this.props.state.clientState.fileResponse;

    const handleChangeContactFile = (id, value) => {
      this.props.handleChangeContactFile(id, value);
    };

    const fetchContactFilesList = () => {
      this.props.fetchContactFilesList(this.state.clientId, this.props);
    };

    const addClientFile = () => {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
        });
        this.props.addContactFiles(contactFileData, this.props);
        setTimeout(() => {
          this.setState({
            clicked: false,
          });
        }, 3000);
      }
    };

    const setContactFileToEdit = (files) => {
      this.props.setContactFileToEdit(files);
    };

    const updateClientFile = () => {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
        });
        this.props.updateContactFiles(contactFileData, this.props);
        setTimeout(() => {
          this.setState({
            clicked: false,
          });
        }, 3000);
      }
    };

    const deleteFile = (documentId) => {
      this.props.deleteFiles(documentId, this.state.clientId, this.props);
    };

    // for calendar data
    const settingsState = this.props && this.props.state.settingState;
    const holidayName = settingsState && settingsState.eventName;
    const calendarType = settingsState && settingsState.calendarType;
    const value = holidayName !== "";
    const holidayDetails = settingsState && settingsState.holidayDetails;
    const eventStyleGetter = (event, start, end, isSelected) => {
      var backgroundColor =
        this.state.currentView === "agenda" ? "#ffffff" : event.hexColor;
      var color = this.state.currentView === "agenda" ? "#000000" : "#ffffff";
      var style = {
        backgroundColor: backgroundColor,
        color: color,
        borderRadius: "5px",
        minHeight: "5%",
      };
      return {
        style: style,
      };
    };
    const DateTimeString = (dateToConvert) => {
      return new Date(parseInt(dateToConvert));
    };
    const allHolidayEventsList = holidayDetails
      ? holidayDetails.map((item) => {
          const list = {};
          if (this.state.currentView === "month") {
            list.title =
              new Intl.DateTimeFormat("default", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(item.startDate) !==
              new Intl.DateTimeFormat("default", {
                hour: "2-digit",
                minute: "2-digit",
              }).format(item.endDate)
                ? `
            ${new Intl.DateTimeFormat("default", {
              hour: "2-digit",
              minute: "2-digit",
            }).format(item.startDate)} -
                    ${new Intl.DateTimeFormat("default", {
                      hour: "2-digit",
                      minute: "2-digit",
                    }).format(item.endDate)} ${item.eventName}`
                : ` ${item.eventName}`;
          } else {
            list.title = `${item.eventName}`;
          }
          list.start = DateTimeString(item.startDate);
          list.end = DateTimeString(item.endDate);
          list.id = item.calendarSettingId;
          list.hexColor = "#98ca69";
          return list;
        })
      : [
          {
            title: "",
            start: Date.now(),
            end: Date.now(),
            hexColor: "#EAF6FE",
            allDay: true,
          },
        ];

    const eventList = calendarDetails
      ? calendarDetails.map((item) => {
          const list = {};
          list.title = `${item.user.firstName} (${item.caseName} - ${item.workFlowName})`;
          list.start = DateTimeString(item.scheduledDate);
          list.end = DateTimeString(item.scheduledDate);
          // list.allDay = true;
          list.id = item.caseId;
          list.workflowName = item.workFlowName;
          list.hexColor = "#69abca";
          return list;
        })
      : [
          {
            title: "",
            start: Date.now(),
            end: Date.now(),
            hexColor: "#EAF6FE",
            allDay: true,
          },
        ];

    const allEvents = [...eventList, ...allHolidayEventsList];
    const handleOpenModalToAddEvent = ({ start, end }) => {
      this.props.clearModalData();
      this.setState({
        openModalToAddEvent: true,
        start: new Date(start).setHours(0, 0, 0),
        end: new Date(end).setHours(23, 59, 59),
      });
    };
    const handleCloseModalToAddEvent = () => {
      this.setState({
        openModalToAddEvent: false,
      });
    };
    //handle changes
    // const handleChangeStartDate = (range) => {
    //   if (range) {
    //     const valueOfInput1 = range.format();
    //     this.setState({
    //       start: new Date(valueOfInput1).getTime(),
    //     });
    //   } else if (range === null) {
    //     this.setState({
    //       start: new Date(0).getTime,
    //     });
    //   }
    // };
    // const handleChangeEndDate = (range) => {
    //   if (range) {
    //     const valueOfInput1 = range.format();
    //     this.setState({
    //       end: new Date(valueOfInput1).getTime(),
    //     });
    //   } else if (range === null) {
    //     this.setState({
    //       end: new Date(0).getTime,
    //     });
    //   }
    // };

    const handleChangeClientFee = (fee) => {
      this.props.handleChangeClientFee(fee);
    };
    const handleChangeClientMonthlyFeeValue = (monthlyFee) => {
      this.props.handleChangeClientMonthlyFeeValue(monthlyFee);
    };
    const handleChangeClientAnnualFee = (annualFee) => {
      this.props.handleChangeClientAnnualFee(annualFee);
    };
    const handleChangeAdmissionDate = (addmissionDate) => {
      this.props.handleChangeAdmissionDate(addmissionDate);
    };
    const handleChangeClientSpendDown = (spendDownThreshold) => {
      this.props.handleChangeClientSpendDown(spendDownThreshold);
    };
    const handleChangeClientAchDelayThreshold = (achDelayThreshold) => {
      this.props.handleChangeClientAchDelayThreshold(achDelayThreshold);
    };
    const handleChangeClientInstallment = (installmentMonth) => {
      this.props.handleChangeClientInstallment(installmentMonth);
    };

    const addClientPayment = (paymentData) => {
      this.props.addClientPayment(paymentData, this.props);
    };

    const onChangeFileHandler = (eventFileHandler, clientId) => {
      let file = eventFileHandler.target.files[0];
      let type = file && file.type;

      let singleFile = {
        file: file,
        type: type,
      };
      {
        this.setState(
          {
            isBlocking: true,
          },
          () => {
            this.props.updateProfilePicture(singleFile, clientId, this.props);
          }
        );
      }
    };

    //set password
    const handleOpenDialogueToSetPassword = () => {
      this.setState({
        openModalToSetPassword: true,
      });
    };
    const handleCloseDialogueToSetPassword = () => {
      this.setState({
        openModalToSetPassword: false,
      });
    };
    const profile = clientState && clientState.profilePicUrl;

    //ELIGIBILITY CALCULATION
    const calculateEligibility = (eligibilityData) => {
      this.props.calculateEligibility(eligibilityData);
    };
    const handleChangeEligibilityData = (id, value) => {
      this.props.handleChangeEligibilityData(id, value);
    };
    const handleChangeWorkFlow = (workflowId) => {
      this.props.handleChangeWorkflow(workflowId);
    };
    const handleChangeAction = (actionId) => {
      this.props.handleChangeAction(actionId);
    };
    const eligibilityData = clientState && clientState.eligibilityData;
    const workflowId = clientState && clientState.workflowId;
    const actionId = clientState && clientState.actionId;
    const loading = clientState && clientState.loading;
    const bankValue =
      accountName !== "" &&
      accountNumber !== "" &&
      routingNumber !== "" &&
      bankName !== "" &&
      bankAccountType !== "";

    //notes implementation
    const handleChangeNoteDescription = (id, value) => {
      this.props.handleChangeNoteDescription(id, value);
    };
    const addClientNotes = (noteData) => {
      this.props.addClientNotes(noteData, this.props);
    };
    const updateClientNotes = (noteData) => {
      this.props.updateClientNotes(noteData, this.props);
    };
    const deleteNotes = (id) => {
      this.props.deleteClientNote(id, clientId, this.props);
    };
    const fetchNoteById = (id) => {
      this.props.fetchNoteById(id, this.props);
    };
    const setNotesToEdit = (noteData) => {
      this.props.setNotesToEdit(noteData, this.props);
    };
    const noteData = clientState && clientState.noteData;
    const notes = clientState && clientState.notes;
    const notesValue = notes !== "";

    // const description = this.props.state.settingState.descriptionList;
    // const descriptionList = settingState && settingState.descriptionList;

    // // vendor client screen
    // const handleChangeVendorClient = (id, value) => {
    //   this.props.handleChangeVendorClient(id, value);
    // };

    //ACTIVITY LOG
    const fetchActivityLogByClientId = (id) => {
      this.props.fetchActivityLogByClientId(id, "", "");
    };

    const activityState = this.props.state && this.props.state.activityLogState;
    const activityLogValue = activityState && activityState.activityLogValue;
    const remarkValue = declineRemark !== "";
    const statusChangeValue =
      transStatus === "TRANSACTION_APPROVED" ? true : declineRemark !== "";

    //add contacts

    const showInputForContactField = () => {
      let a = createContactObject();
      this.setState({
        contacts: [...this.state.contacts, a],
      });
    };
    const createContactObject = () => {
      return {
        contactId: String(Math.random()),
        name: "",
        emailPhone: "",
        contactType: "UNKNOWN_CONTACT_TYPE",
        phone: "",
        dob: null,
      };
    };

    const filterEditContactData = () => {
      let resultA = this.state.updatedContact.filter((elm) =>
        this.state.contacts
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
      );
      let resultB = this.state.contacts
        .filter(
          (elm) =>
            !this.state.updatedContact
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        )
        .map((single) => {
          return {
            name: single.name,
            emailPhone: single.emailPhone,
            contactType: single.contactType,
            phone: single.phone,
            dob: single.dob,
          };
        });
      let contactForm = [...resultA, ...resultB];
      this.props.handleChangeClient("contacts", contactForm);
    };

    //to delete field
    const closeContactsFieldSection = (key) => {
      var removedActionData = this.state.contacts.filter(
        (single) => single.contactId !== key
      );
      this.setState(
        {
          contacts: removedActionData,
        },
        () => {
          filterEditContactData();
        }
      );
    };
    const handleOpenDeleteContactsDialogue = (contactId) => {
      this.setState({
        openDeleteContactDialogue: true,
        contactId: contactId,
      });
    };
    const handleCloseDeleteContactsDialogue = () => {
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };
    const handleOkDeleteContactsDialogue = () => {
      closeContactsFieldSection(this.state.contactId);
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };

    const handleShowFileUploadErrorMessage = (error) => {
      this.props.handleShowFileUploadErrorMessage(error);
    };

    // handle delete modal
    const handleDeleteModalOpen = () => {
      this.setState({
        openModalToDeleteTransaction: true,
      });
    };
    const handleDeleteModalClose = () => {
      this.setState({
        openModalToDeleteTransaction: false,
      });
    };

    // delete status handling
    const handleDeleteStatus = () => {
      const data = this.state.transactionId;
      this.props.deleteTransData(data, this.state.clientId, this.props);
    };

    //handle modal to change transaction status
    const handleOpenChangeTransStatusModal = (item) => {
      if (item && item.transactionStatus === "TRANSACTION_APPROVED") {
        const data = {
          transaction: [
            {
              transactionId: this.state.transactionId,
              transactionStatus: "TRANSACTION_SUBMITTED",
              // remark: declineRemark,
            },
          ],
        };
        this.props.updateSubmitTransactionList(
          data,
          this.state.clientId,
          this.props
        );
      } else {
        this.setState({
          openModalToChangeTransStatus: true,
        });
      }
    };

    const handleCloseChangeTransStatusnModal = () => {
      this.setState({
        openModalToChangeTransStatus: false,
      });
      this.props.handlChangeReverseDate(null);
    };

    const handleStatusToApprove = () => {
      const data = {
        transaction: [
          {
            transactionId: this.state.transactionId,
            transactionStatus: "TRANSACTION_APPROVED",
          },
        ],
      };
      this.props.updateSubmitTransactionList(
        data,
        this.state.clientId,
        this.props
      );
    };

    // changes status to approve
    const handleOpenToApproveTransaction = () => {
      this.setState({
        openModalToApproveTransaction: true,
      });
    };

    const handleCloseToApproveTransaction = () => {
      this.setState({
        openModalToApproveTransaction: false,
      });
    };

    const handleUpdateStatus = () => {
      if (declineRemark === "") {
        const data = {
          transaction: [
            {
              transactionId: this.state.transactionId,
              transactionStatus: transStatus,
            },
          ],
        };
        this.props.updateSubmitTransactionList(data, clientId, this.props);
      } else {
        if (this.state.openModalToUndoReverse) {
          const data = {
            transactionId: this.state.transactionId,
            remark: declineRemark,
          };
          this.props.undoReverseClientTranx(
            data,
            this.state.clientId,
            this.props
          );
        } else {
          const remarkData = {
            transaction: [
              {
                transactionId: this.state.transactionId,
                // transactionStatus: transStatus,
                // transactionStatus: "TRANSACTION_APPROVED",
                transactionStatus: "TRANSACTION_REVERSED",
                transactionTimestamp: reversedDate === null ? 0 : reversedDate,
                remark: declineRemark,
              },
            ],
          };
          this.props.updateSubmitTransactionList(
            remarkData,
            clientId,
            this.props
          );
        }
      }
    };

    const handleOpenActivityLogModal = () => {
      this.setState({
        openActivityLogModal: true,
      });
    };
    const handleCloseActivityLogModal = () => {
      this.setState({
        openActivityLogModal: false,
      });
    };

    // CLIENT ALERT NOTIFICATION
    const handleChangeHolidayAlert = (holidayAlert) => {
      this.props.handleChangeHolidayAlert(holidayAlert);
    };
    const handleChangeUpcomingAlert = (upcomingAlert) => {
      this.props.handleChangeUpcomingAlert(upcomingAlert);
    };
    const saveNotifications = (enabledData) => {
      this.props.saveNotifications(enabledData, this.props);
    };

    const setOpenCreateTransaction = (bool) => {
      this.props.setOpenCreateTransaction(bool);
    };

    const handleChangeDropDown = (id) => {
      this.setState({
        openDropDown: !this.state.openDropDown,
        tempRowId: id,
      });
    };
    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort(
            (a, b) => -descendingComparatorTrans(a, b, orderBy)
          );

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) =>
            descendingComparatorTrans(a, b, orderBy)
          );

        return list;
      }
    }
    const sortedTransactionList = getComparator(
      clientTransaction,
      this.state.order,
      this.state.orderBy
    );

    const sortedSchTransactionList = getComparator(
      clientScheduleTrans,
      this.state.order,
      this.state.orderBy
    );

    const sortedUpcomingTransactionList = getComparator(
      clientUpcomingTransaction,
      this.state.order,
      this.state.orderBy
    );

    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
        isClientSchTrans: false,
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
        isClientSchTrans: false,
      });
    };

    const handleFilterSaveConfig = () => {
      if (this.state.isClientSchTrans) {
        const data = {
          configName: this.state.configName,
          startDate: filterStartDate === null ? "" : filterStartDate,
          endDate: filterEndDate === null ? "" : filterEndDate,
          clientName: filterName,
          vendorName: filterVendor,
          amount: filterAmount,
          transactionMedium: filterMedium,
          transactionType: filterType,
          transactionStatus: filterStatus,
          compareType: compareTypeSCH,
          scheduleDate: filterScheduleDate === null ? "" : filterScheduleDate,
          configType: 9,

          sortBy: this.state.order === "asc" ? "ASC" : "DESC",
        };

        this.props.fetchScheduleTransactionListByClient(clientId);
        this.props.clearSchTransFilterData();
        this.props.saveInConfigSch(data);
      } else {
        const data = {
          configName: this.state.configName,
          description: desc,
          balance: amount,
          checkNo: checkNo,
          userName: clientNameForBreadcrumb,
          vendorName: vendor,
          transactionMedium: medium,
          transactionType: type,
          transactionStatus: stat,
          compareType: compareType,
          transactionDate: transDate === null ? "" : transDate,
          configType: 8,

          sortBy: this.state.order === "asc" ? "ASC" : "DESC",
        };
        this.props.getClientTransaction(clientId);
        this.props.saveInConfig(data);
        this.props.clearTransactionFilter();
      }
    };

    const filterStatusTrans =
      transDate !== null ||
      desc !== "" ||
      amount !== "" ||
      checkNo !== "" ||
      user !== "" ||
      medium !== "" ||
      type !== "" ||
      stat !== "" ||
      filterDebitAmount !== "" ||
      filterCreditAmount !== "" ||
      vendor !== "";

    const configStatus = this.state.configName !== "" && filterStatusTrans;

    const isFilterEmpty =
      filterStartDate !== null ||
      filterEndDate !== null ||
      filterName !== "" ||
      filterVendor !== "" ||
      filterAmount !== "" ||
      filterMedium !== "" ||
      filterType !== "" ||
      filterStatus !== "" ||
      filterScheduleDate !== "";

    const configStatusSch = this.state.configName !== "" && isFilterEmpty;

    const handleUseConfigFilter = (config) => {
      this.props.clearTransactionFilter();
      this.props.clearSchTransFilterData();

      if (!this.state.isClientSchTrans) {
        if (
          config.transactionFilter.transactionDate &&
          config.transactionFilter.transactionDate !== "0"
        ) {
          this.props.handleTransactionFilter(
            "filterDate",
            config.transactionFilter.transactionDate
          );
        }
        if (config.transactionFilter.description) {
          this.props.handleTransactionFilter(
            "filterDesc",
            config.transactionFilter.description
          );
        }
        if (config.transactionFilter.balance) {
          this.props.handleTransactionFilter(
            "filterAmount",
            config.transactionFilter.balance
          );
        }
        if (config.transactionFilter.checkNo) {
          this.props.handleTransactionFilter(
            "filterCheckNo",
            config.transactionFilter.checkNo
          );
        }
        if (config.transactionFilter.vendorName) {
          this.props.handleTransactionFilter(
            "filterVendor",
            config.transactionFilter.vendorName
          );
        }
        if (config.transactionFilter.transactionMedium) {
          this.props.handleTransactionFilter(
            "filterMedium",
            config.transactionFilter.transactionMedium
          );
        }
        if (config.transactionFilter.transactionType) {
          this.props.handleTransactionFilter(
            "filterType",
            config.transactionFilter.transactionType
          );
        }
        if (config.transactionFilter.transactionStatus) {
          this.props.handleTransactionFilter(
            "filtetStatus",
            config.transactionFilter.transactionStatus
          );
        }
        if (config.transactionFilter.comparator) {
          this.props.handleTransactionFilter(
            "compareType",
            getRangeType(config.transactionFilter.comparator)
          );
        }

        this.props.filterClientTransactionByAll(
          clientId,
          config.transactionFilter.description
            ? config.transactionFilter.description
            : "",
          config.transactionFilter.checkNo
            ? config.transactionFilter.checkNo
            : "",
          config.transactionFilter.vendorName
            ? config.transactionFilter.vendorName
            : "",
          config.transactionFilter.transactionMedium
            ? config.transactionFilter.transactionMedium
            : "",
          config.transactionFilter.transactionType
            ? config.transactionFilter.transactionType
            : "",
          config.transactionFilter.transactionStatus
            ? transactionStatusHelper([
                config.transactionFilter.transactionStatus,
              ])
            : "",
          config.transactionFilter.transactionDate
            ? config.transactionFilter.transactionDate
            : 0,
          config.transactionFilter.balance
            ? config.transactionFilter.balance
            : "",
          "",
          config.transactionFilter.comparator
            ? getRangeType(config.transactionFilter.comparator)
            : "0"
        );
      } else {
        if (
          config.scheduleFilter.startDate &&
          config.scheduleFilter.startDate !== "0"
        ) {
          this.props.handleScheduleTransFilter(
            "filterStartDate",
            config.scheduleFilter.startDate
          );
        }
        if (
          config.scheduleFilter.endDate &&
          config.scheduleFilter.endDate !== "0"
        ) {
          this.props.handleScheduleTransFilter(
            "filterEndDate",
            config.scheduleFilter.endDate
          );
        }
        // if (config.scheduleFilter.clientName) {
        //   this.props.handleScheduleTransFilter(
        //     "filterName",
        //     config.scheduleFilter.clientName
        //   );
        // }
        if (config.scheduleFilter.vendorName) {
          this.props.handleScheduleTransFilter(
            "filterVendor",
            config.scheduleFilter.vendorName
          );
        }
        if (config.scheduleFilter.transactionMedium) {
          this.props.handleScheduleTransFilter(
            "filterMedium",
            config.scheduleFilter.transactionMedium
          );
        }
        if (config.scheduleFilter.transactionType) {
          this.props.handleScheduleTransFilter(
            "filterType",
            config.scheduleFilter.transactionType
          );
        }
        if (config.scheduleFilter.transactionStatus) {
          this.props.handleScheduleTransFilter(
            "filterStatus",
            ClientSheduleStatusHelper([config.scheduleFilter.transactionStatus])
          );
        }
        if (
          config.scheduleFilter.scheduleDate &&
          config.scheduleFilter.scheduleDate !== "0"
        ) {
          this.props.handleScheduleTransFilter(
            "filterScheduleDate",
            config.scheduleFilter.scheduleDate
          );
        }
        if (config.scheduleFilter.amount) {
          this.props.handleScheduleTransFilter(
            "filterAmount",
            config.scheduleFilter.amount
          );
        }
        if (config.scheduleFilter.comparator) {
          this.props.handleScheduleTransFilter(
            "compareType",
            getRangeType(config.scheduleFilter.comparator)
          );
        }

        this.props.filterScheduleTransactionByAll(
          clientId,
          config.scheduleFilter.startDate
            ? config.scheduleFilter.startDate
            : "0",
          config.scheduleFilter.endDate ? config.scheduleFilter.endDate : "0",
          clientNameForBreadcrumb,
          config.scheduleFilter.vendorName
            ? config.scheduleFilter.vendorName
            : "",
          config.scheduleFilter.transactionMedium
            ? config.scheduleFilter.transactionMedium
            : "",
          config.scheduleFilter.transactionType
            ? config.scheduleFilter.transactionType
            : "",
          config.scheduleFilter.transactionStatus
            ? ClientSheduleStatusHelper(config.scheduleFilter.transactionStatus)
            : "",
          "",
          config.scheduleFilter.scheduleDate
            ? config.scheduleFilter.scheduleDate
            : "",
          config.scheduleFilter.amount ? config.scheduleFilter.amount : "",
          config.scheduleFilter.comparator
            ? getRangeType(config.scheduleFilter.comparator)
            : "0"
        );
      }
    };

    const handleSkipTransaction = () => {
      const dataToSkip = {
        scheduleId: this.state.upcomingTrans.scheduleTransactionId,
        skipTimestamp: this.state.upcomingTrans.upcomingTxnDate,
        remark: remarkToSkip,
      };
      this.props.skipUpcomingTransaction(dataToSkip, clientId, this.props);
    };

    const handleCloseDialogueToProcess = () => {
      this.setState({
        openDialogueToProcess: false,
      });
    };
    const handleOpenDialogueToProcess = () => {
      this.setState({
        openDialogueToProcess: true,
      });
    };

    const handleOkTransaction = (upcomingTrans) => {
      const dataToProceed = {
        scheduleId: upcomingTrans.scheduleTransactionId,
        clientId: upcomingTrans.client.clientId,
        vendorId: upcomingTrans.vendorAccount.vendorId,
        amount: upcomingTrans.amount,
        note: upcomingTrans.note ? upcomingTrans.note : "",
        transactionType: upcomingTrans.transactionType,
        transactionMedium: upcomingTrans.transactionMedium,
        document: upcomingTrans.document ? upcomingTrans.document : "",
        bankDetailId: upcomingTrans.bankDetailId
          ? upcomingTrans.bankDetailId
          : "",
        description: upcomingTrans.description ? upcomingTrans.description : "",
        upcomingTxnDate: upcomingTrans.upcomingTxnDate,
        isProcessed: true,
        isLedger: upcomingTrans.isLedger,
      };
      this.props.processUpcomingTransaction(dataToProceed, this.props);
      this.setState({
        openDialogueToProcess: false,
      });
    };

    const handleOpenTransactionFilterModal = () => {
      handleInitialCheckboxValue();
      this.setState({
        openModalToFilterUPTransaction: true,
      });
      this.props.handleChangeTransactionDateFrom(null);
      this.props.handleChangeTransactionDateTo(null);
      this.props.handleChangeTransactionMedium("UNKNOWN_TRANSACTION_MEDIUM");
      this.props.handleChangeVendor("");
    };

    const handleCloseTransactionFilterModal = () => {
      this.setState({
        openModalToFilterUPTransaction: false,
      });
    };

    // handle undo a reversed transaction
    const handleUndoReversedTrans = () => {
      this.setState({
        openConfirmationToUndoReverse: true,
      });
    };
    const handleCloseUndoReverse = () => {
      this.setState({
        openConfirmationToUndoReverse: false,
      });
    };

    // function to set the initaial value of the main filters
    const setInitialValueofMainFilters = () => {
      this.setState({
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isScheduleStatusCheck: FilterClientSchStatusData.map(
          (status) => status.name
        ),
        isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
        isLedCaseTypeCheck: FilterTypeDataForLedger.map((type) => type.name),
      });
    };

    const handleCloseDialogueToDischargeTransaction = () => {
      this.setState({
        openDialogueToDischargeTransaction: false,
      });
    };
    const handleCloseDialogueToHoldClient = () => {
      this.setState({
        openDialogueToHoldTransaction: false,
      });
    };
    const handleCloseDialogueToResumeTransaction = () => {
      this.setState({
        openDialogueToResumeTransaction: false,
      });
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Client Detail" />
        {(clientState && clientState.loading) ||
        trasactionLoading ||
        (contactState && contactState.loading) ? (
          <div
            className={
              (clientState && clientState.loading) ||
              trasactionLoading ||
              (contactState && contactState.loading)
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {settingsState && settingsState.holidayAddSuccess === true
          ? this.setState(
              {
                openModalToAddEvent: false,
              },
              () => {
                this.props.setToFalse();
              }
            )
          : null}
        {(clientState && clientState.success) ||
        clientState.resetSuccess === true
          ? this.setState(
              {
                openAddBankDetailDialogue: false,
                openUpdateBankDetailDialogue: false,
                openModalToSetPassword: false,
                openModalToChangeTransStatus: false,
                openModalToDeleteTransaction: false,
              },
              () => {
                if (this.state.setValue === "add") {
                  this.props.setAddBankDetailsSuccessToFalse();
                } else if (this.state.setValue === "update") {
                  this.props.setUpdateBankDetailsSuccessToFalse();
                } else if (this.state.setValue === "delete") {
                  this.props.setDeleteBankDetailsSuccessToFalse();
                } else if (this.state.setValue === "set") {
                  this.props.setPasswordSuccessToFalse();
                } else if (this.state.setValue === "changeStatus") {
                  this.props.setChangeTransactionStatusSuccessToFalse();
                }
                // this.props.setUpdateBankDetailsSuccessToFalse();
              }
            )
          : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          {userType && userType === "CORPORATE_USER" ? (
            ""
          ) : (
            <Link color="inherit" to="/dashboard" className="links">
              <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
              Home
            </Link>
          )}
          <Link color="inherit" to="/client" className="links">
            Clients
          </Link>
          <Typography color="inherit" className="link">
            {clientNameForBreadcrumb}
          </Typography>
        </Breadcrumbs>
        <div style={{ marginLeft: "3%", marginTop: "48px", marginRight: "3%" }}>
          <Tabs
            defaultActiveKey={this.state.clientDetailTabs}
            onChange={(e) => {
              callback(e);
              setPageChange();
            }}
            className="fixed-tab"
          >
            <TabPane tab="Client Details" key="0">
              <div style={style.containerClient}>
                <div className="row" style={style.header}>
                  <div className="col-lg-11 col-md-4 col-sm-4">
                    <h5 style={style.headerText}> Client Detail </h5>
                  </div>
                </div>
                <div className="row" style={style.row}>
                  <div className="col-2">
                    {/* <div style={{border:"2px solid #8bc83f", borderRadius:"50%",  padding: "5px"}}> */}
                    <Avatar
                      alt="Profile"
                      src={profile ? profile : "/assets/images/profile.svg"}
                      style={{
                        width: "150px",
                        height: "150px",
                      }}
                    />
                    {/* </div> */}
                    {userType && userType === "CORPORATE_USER" ? (
                      <div style={{ display: "none" }}></div>
                    ) : (
                      <div
                        style={{
                          color: "#666666",
                          transition:
                            "all .3s cubic-bezier(.175, .885, .32, 1.275)",
                        }}
                      >
                        <label htmlFor="file">
                          <img
                            src={"/assets/images/edit_photo.png"}
                            alt="Edit"
                            style={{
                              background: "#BCBCBC",
                              width: "30px",
                              height: "30px",
                              boxShadow:
                                "rgba(0, 0, 0, 0) 0px 0px 1px 0px," +
                                " rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
                              borderRadius: "50%",
                              cursor: "pointer",
                              position: "absolute",
                              top: "90px",
                              left: "138px",
                            }}
                          />
                        </label>
                        <input
                          className="file-upload"
                          type="file"
                          id="file"
                          onChange={(e) => {
                            e.preventDefault();
                            onChangeFileHandler(e, this.state.clientId);
                          }}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}

                    {userType && userType === "CORPORATE_USER" ? (
                      <div style={{ display: "none" }}></div>
                    ) : (
                      <div>
                        <div
                          className="set-password-div"
                          onClick={() => {
                            handleOpenDialogueToSetPassword();
                          }}
                        >
                          Set Password
                        </div>

                        <div
                          style={style.editDiv}
                          role="presentation"
                          onClick={() => {
                            this.props.handleChangeToOpenClientFormModal(true);
                            this.setState({
                              contacts: clientData.contacts
                                ? clientData.contacts
                                : [],
                              updatedContact: clientData.contacts
                                ? clientData.contacts
                                : [],
                            });
                          }}
                        >
                          <EditOutlinedIcon
                            style={{
                              width: "20px",
                              marginBottom: "3px",
                            }}
                          />
                          <span>Edit</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-8" style={{ marginTop: "18px" }}>
                    <div className="row">
                      <div className="col-4">
                        <label htmlFor="ssn" style={style.columnHeading}>
                          Client Id
                        </label>
                        <label style={style.span}>
                          {clientRes && clientRes.clientNo
                            ? clientRes.clientNo
                            : clientRes.ssn}
                        </label>
                      </div>
                      <div className="col-4">
                        <label htmlFor="firstName" style={style.columnHeading}>
                          First Name
                        </label>
                        <label style={style.span}>
                          {clientRes && clientRes.firstName}
                        </label>
                      </div>
                      <div className="col-4">
                        <label htmlFor="lastName" style={style.columnHeading}>
                          Last Name
                        </label>
                        <label style={style.span}>
                          {clientRes && clientRes.lastName}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <label htmlFor="dob" style={style.columnHeading}>
                          Date-Of-Birth
                        </label>
                        <label style={style.span}>
                          {clientRes && getFormattedDateValue(clientRes.dob)}
                        </label>
                      </div>
                      <div className="col-4">
                        <label htmlFor="gender" style={style.columnHeading}>
                          Gender
                        </label>
                        <label style={style.span}>
                          {clientRes && clientRes.gender === "MALE"
                            ? "Male"
                            : clientRes && clientRes.gender === "FEMALE"
                            ? "Female"
                            : clientRes && clientRes.gender}
                        </label>
                      </div>
                      <div className="col-4">
                        <label
                          htmlFor="maritalStatus"
                          style={style.columnHeading}
                        >
                          Marital Status
                        </label>
                        <label style={style.span}>
                          {clientRes && clientRes.maritalStatus === "SINGLE"
                            ? "Single"
                            : clientRes && clientRes.maritalStatus === "MARRIED"
                            ? "Married"
                            : clientRes &&
                              clientRes.maritalStatus === "DIVORCED"
                            ? "Divorced"
                            : clientRes && clientRes.maritalStatus}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <label htmlFor="Status" style={style.columnHeading}>
                          Status
                          {userType && userType === "CORPORATE_USER" ? (
                            <div style={{ display: "none" }}></div>
                          ) : (
                            <>
                              <ExpandMoreIcon
                                id="Popover1"
                                className="expand-more-icon"
                                onClick={(e) => {
                                  this.setState({
                                    popoverElement: e.currentTarget,

                                    openToolbarToChangeStatus: true,
                                  });
                                }}
                              />
                              <Popover
                                open={this.state.openToolbarToChangeStatus}
                                anchorEl={this.state.popoverElement}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    openToolbarToChangeStatus: false,
                                  });
                                }}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                style={{ boxShadow: "none" }}
                              >
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src="/assets/images/customer.svg"
                                    alt="customer"
                                    className="iconImage"
                                  />
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (clientRes.isHold) {
                                        this.setState({
                                          openDialogueToResumeTransaction: true,
                                          changeCurrnetTrnxStatusTo: "hold",
                                        });
                                      } else {
                                        this.setState({
                                          openDialogueToHoldTransaction: true,
                                        });
                                      }
                                      this.setState({
                                        openToolbarToChangeStatus: false,
                                      });
                                    }}
                                    style={{ marginLeft: "5.5px" }}
                                  >
                                    Client On Hold
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <UserDeleteOutlined className="iconImage" />

                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (clientRes.isDischarged) {
                                        this.setState({
                                          openDialogueToResumeTransaction: true,
                                          changeCurrnetTrnxStatusTo:
                                            "discharged",
                                        });
                                      } else {
                                        this.setState({
                                          openDialogueToDischargeTransaction: true,
                                        });
                                      }
                                      this.setState({
                                        openToolbarToChangeStatus: false,
                                      });
                                    }}
                                    style={{ marginLeft: "5.5px" }}
                                  >
                                    Client On Discharge
                                  </span>
                                </MenuList>
                              </Popover>
                            </>
                          )}
                        </label>
                        <label style={style.span}>
                          {clientData.isEnableTransaction &&
                          clientData.isHold === undefined &&
                          clientData.isDischarged === undefined ? (
                            <ActiveStatusPopover
                              status={"Active"}
                              content={
                                <div>
                                  Active. You can now make transactions.
                                </div>
                              }
                            />
                          ) : clientData.isHold ? (
                            <ActiveStatusPopover
                              status={"On Hold"}
                              content={
                                <div>
                                  Inactive. Cannot make any transactions.
                                </div>
                              }
                            />
                          ) : (
                            <ActiveStatusPopover
                              status={"Discharged"}
                              content={
                                <div>
                                  Inactive. Cannot make any transactions.
                                </div>
                              }
                            />
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Transaction" key="1">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div style={{ marginBottom: "8px" }}>
                    <span style={{ marginRight: "4px", fontSize: "16px" }}>
                      Total Balance:
                    </span>
                    <span style={{ fontSize: "16px", fontWeight: "600" }}>
                      {totalBalance
                        ? formatter.format(totalBalance / 100)
                        : "$0.00"}
                    </span>
                  </div>
                  <div style={{ marginBottom: "18px" }}>
                    <span style={{ marginRight: "4px", fontSize: "16px" }}>
                      Total Transaction :
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {totalTransaction ? totalTransaction : "0"}
                    </span>
                  </div>
                </div>
                <div className={"d-flex flex-row-reverse"} style={style.flex}>
                  <div>
                    <button
                      className="filterButton"
                      onClick={() => {
                        this.props.getClientTransaction(clientId);
                        this.props.clearTransactionFilter();
                        this.props.handleChangeTransactionDateFrom(null);
                        this.props.handleChangeTransactionDateTo(null);
                      }}
                    >
                      <RefreshIcon
                        style={{ color: "#fff", marginTop: "-2px" }}
                      />
                    </button>
                  </div>
                  {/*filter*/}
                  <div>
                    <button
                      className="filterButton"
                      onClick={() => {
                        this.props.fetchVendor();
                        handleChangeOpenSearchDialogue();
                        this.props.clearTransactionFilter();
                      }}
                    >
                      <FilterListIcon
                        style={{ color: "#fff", marginTop: "-2px" }}
                      />
                    </button>
                  </div>
                  {userType && userType === "CORPORATE_USER" ? (
                    <div style={{ display: "none" }}></div>
                  ) : (
                    <div>
                      <button
                        className="settingButton"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenGeneralTransactionModal();
                          this.props.getClientUpcomingTrans(
                            this.state.clientId
                            // 0,
                            // 0,
                            // "UNKNOWN_TRANSACTION_MEDIUM",
                            // "",
                            // "",
                            // "UNKNOWN_TRANSACTION_TYPE"
                          );
                        }}
                      >
                        <img
                          alt={"setting"}
                          src={"/assets/images/allTransaction.svg"}
                          style={{ width: "20px", height: "18px" }}
                        />
                      </button>
                    </div>
                  )}
                  {/*export*/}
                  {userType && userType === "CORPORATE_USER" ? (
                    <ExportButton
                      name={"VOD"}
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionByAll(
                          // mediumType,
                          // txnStatus,
                          transactionMediumHelper(this.state.isMediumCheck),
                          transactionStatusHelper(this.state.isStatusCheck),
                          "DEPOSIT",
                          startDate,
                          endDate,
                          vendorToFilter,
                          clientId,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionByAll(
                          // mediumType,
                          // txnStatus,
                          transactionMediumHelper(this.state.isMediumCheck),
                          transactionStatusHelper(this.state.isStatusCheck),
                          "DEPOSIT",
                          startDate,
                          endDate,
                          vendorToFilter,
                          clientId,
                          "CSV_EXPORT"
                        );
                      }}
                      onClickVOD={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionVOD(
                          clientId,
                          startDate,
                          endDate,
                          "PDF_EXPORT"
                        );
                      }}
                    />
                  ) : arraysEqual(
                      this.state.isStatusCheck,
                      FilterStatusData.map((status) => status.name)
                    ) &&
                    arraysEqual(
                      this.state.isTypeCheck,
                      FilterTypeData.map((type) => type.name)
                    ) &&
                    arraysEqual(
                      this.state.isMediumCheck,
                      FilterMediumData.map((medium) => medium.name)
                    ) &&
                    (startDate === null || startDate === 0) &&
                    (endDate === null || endDate === 0) &&
                    // term === "" &&
                    vendorToFilter === "" &&
                    !filterStatusTrans ? (
                    <ExportButton
                      name={"VOD"}
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportSingleClientTransactionList(
                          clientId,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportSingleClientTransactionList(
                          clientId,
                          "CSV_EXPORT"
                        );
                      }}
                      onClickVOD={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionVOD(
                          clientId,
                          startDate,
                          endDate,
                          "PDF_EXPORT"
                        );
                      }}
                    />
                  ) : term !== "" ? (
                    <ExportButton
                      name={"VOD"}
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportSingleClientTransactionByTerm(
                          term,
                          clientId,
                          "PDF_EXPORT",
                          this.props
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportSingleClientTransactionByTerm(
                          term,
                          clientId,
                          "CSV_EXPORT",
                          this.props
                        );
                      }}
                      onClickVOD={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionVOD(
                          clientId,
                          startDate,
                          endDate,
                          "PDF_EXPORT"
                        );
                      }}
                    />
                  ) : filterStatusTrans ? (
                    <ExportButton
                      name={"VOD"}
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionByColumnFilter(
                          clientId,
                          medium,
                          type,
                          stat,
                          "PDF_EXPORT",
                          desc,
                          checkNo,
                          user,
                          vendor,
                          amount,
                          compareType,
                          transDate,
                          filterDebitAmount !== ""
                            ? -filterDebitAmount
                            : filterCreditAmount
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionByColumnFilter(
                          clientId,
                          medium,
                          type,
                          stat,
                          "CSV_EXPORT",
                          desc,
                          checkNo,
                          user,
                          vendor,
                          amount,
                          compareType,
                          transDate,
                          filterDebitAmount !== ""
                            ? -filterDebitAmount
                            : filterCreditAmount
                        );
                      }}
                      onClickVOD={(e) => {
                        e.preventDefault();

                        this.props.exportTransactionVOD(
                          clientId,
                          startDate,
                          endDate,
                          "PDF_EXPORT"
                        );
                      }}
                    />
                  ) : (
                    <ExportButton
                      name={"VOD"}
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionByAll(
                          transactionMediumHelper(this.state.isMediumCheck),
                          transactionStatusHelper(this.state.isStatusCheck),
                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "type"
                          ),

                          startDate,
                          endDate,
                          vendorToFilter,
                          clientId,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionByAll(
                          transactionMediumHelper(this.state.isMediumCheck),
                          transactionStatusHelper(this.state.isStatusCheck),
                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "type"
                          ),

                          startDate,
                          endDate,
                          vendorToFilter,
                          clientId,
                          "CSV_EXPORT"
                        );
                      }}
                      onClickVOD={(e) => {
                        e.preventDefault();
                        this.props.exportTransactionVOD(
                          clientId,
                          startDate,
                          endDate,
                          "PDF_EXPORT"
                        );
                      }}
                    />
                  )}
                  {userType && userType === "CORPORATE_USER" ? (
                    <div style={{ display: "none" }}></div>
                  ) : (
                    <div>
                      <button
                        disabled={disableButton}
                        style={
                          disableButton
                            ? { background: "#8bc83fc4" }
                            : { background: "#8bc83f" }
                        }
                        className="button-css"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCreateTransaction(true);
                          this.props.fetchVendor();
                          this.props.fetchDescriptionList();
                          this.props.getAmountDetailByClientId(clientId);
                          this.props.clearCreateTransactionForm();
                          this.props.fetchVendorClientById(clientId);
                          this.setState({ isTransUpdate: false });
                        }}
                      >
                        Create Transaction
                      </button>
                    </div>
                  )}
                  <div className="search">
                    <div style={style.searchIcon}>
                      <SearchIcon style={{ color: "#9c9c9c" }} />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      style={style.inputBase}
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.value === "" || e.target.value === " ") {
                          this.props.getClientTransaction(clientId);
                        } else {
                          this.props.searchClientTransaction(
                            e.target.value,
                            clientId
                          );
                          this.props.handleChangeTerm(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={"row"} style={style.card}>
                <TableContainer className="fixed-table-header-second">
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                    ref={this.tableRef}
                  >
                    <TableHead style={{ padding: "1rem" }}>
                      <TableRow>
                        <TableCell className="tableHeader">#</TableCell>

                        <TableCell
                          className="first-tableHeader"
                          key="transactionTimestamp"
                          sortDirection={
                            this.state.orderBy === "transactionTimestamp"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={
                              this.state.orderBy === "transactionTimestamp"
                            }
                            direction={
                              this.state.orderBy === "transactionTimestamp"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler(
                              "transactionTimestamp"
                            )}
                          >
                            Transaction Date
                          </TableSortLabel>
                        </TableCell>

                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableHeader" />
                        )}

                        <TableCell
                          className="tableHeader"
                          key="description"
                          sortDirection={
                            this.state.orderBy === "description"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={this.state.orderBy === "description"}
                            direction={
                              this.state.orderBy === "description"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler("description")}
                          >
                            Description
                          </TableSortLabel>
                        </TableCell>
                        <TableCell className="tableHeader">Debits</TableCell>
                        <TableCell className="tableHeader">Credits</TableCell>
                        <TableCell
                          className="tableHeader"
                          key="balance"
                          sortDirection={
                            this.state.orderBy === "balance"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={this.state.orderBy === "balance"}
                            direction={
                              this.state.orderBy === "balance"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler("balance")}
                          >
                            Balance
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="tableHeader"
                          key="refId"
                          sortDirection={
                            this.state.orderBy === "refId"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={this.state.orderBy === "refId"}
                            direction={
                              this.state.orderBy === "refId"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler("refId")}
                          >
                            Ref / Check No.
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="tableHeader"
                          key="vendorName"
                          sortDirection={
                            this.state.orderBy === "vendorName"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={this.state.orderBy === "vendorName"}
                            direction={
                              this.state.orderBy === "vendorName"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler("vendorName")}
                          >
                            Vendor
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="tableHeader"
                          key="transactionMedium"
                          sortDirection={
                            this.state.orderBy === "transactionMedium"
                              ? this.state.order
                              : false
                          }
                        >
                          {" "}
                          <TableSortLabel
                            active={this.state.orderBy === "transactionMedium"}
                            direction={
                              this.state.orderBy === "transactionMedium"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler(
                              "transactionMedium"
                            )}
                          >
                            Medium
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="tableHeader"
                          key="transactionType"
                          sortDirection={
                            this.state.orderBy === "transactionType"
                              ? this.state.order
                              : false
                          }
                        >
                          {" "}
                          <TableSortLabel
                            active={this.state.orderBy === "transactionType"}
                            direction={
                              this.state.orderBy === "transactionType"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler("transactionType")}
                          >
                            Type
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          className="tableHeader"
                          key="transactionStatus"
                          sortDirection={
                            this.state.orderBy === "transactionStatus"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={this.state.orderBy === "transactionStatus"}
                            direction={
                              this.state.orderBy === "transactionStatus"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler(
                              "transactionStatus"
                            )}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableHeader">
                            ACH Submitted Date
                          </TableCell>
                        )}
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableHeader">
                            Check Print Date
                          </TableCell>
                        )}
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableHeader">
                            Posted Date
                          </TableCell>
                        )}

                        <TableCell className="tableHeader">
                          Scheduled Date/By/Type
                        </TableCell>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={
                          userType && userType === "CORPORATE_USER"
                            ? "sticky-search-bar"
                            : "sticky-search-bar-second"
                        }
                      >
                        <TableCell className="first-tableBody" />

                        <TableCell className="tableBody">
                          <div className="sm-search">
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              value={transDate === null ? "" : transDate}
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.handleTransactionFilter(
                                  "filterDate",
                                  e.target.value
                                );
                                setInitialValueofMainFilters();
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  e.target.value,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                                this.setState({ page: 0 });
                              }}
                            />
                          </div>
                        </TableCell>
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell />
                        )}
                        {/* <TableCell className="tableBody " /> */}
                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <Select
                              className="dropdown"
                              dropdownStyle={{
                                maxHeight: "auto",
                                overflowY: "scroll",
                              }}
                              placeholder="Search…"
                              value={desc}
                              getPopupContainer={(node) => node.parentNode}
                              onChange={(e) => {
                                this.props.handleTransactionFilter(
                                  "filterDesc",
                                  e
                                );
                                setInitialValueofMainFilters();
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  e,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                              }}
                            >
                              {this.props.state.settingState.descriptionList &&
                                this.props.state.settingState.descriptionList.map(
                                  (list) => (
                                    <Option value={list.description}>
                                      {list.description}
                                    </Option>
                                  )
                                )}
                            </Select>
                          </div>
                        </TableCell>
                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              value={filterDebitAmount}
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.handleTransactionFilter(
                                  "filterDebitAmount",
                                  e.target.value
                                );
                                this.props.handleTransactionFilter(
                                  "filterCreditAmount",
                                  ""
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  -e.target.value,
                                  compareType
                                );
                                this.setState({ page: 0 });
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              value={filterCreditAmount}
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.handleTransactionFilter(
                                  "filterCreditAmount",
                                  e.target.value
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.props.handleTransactionFilter(
                                  "filterDebitAmount",
                                  ""
                                );
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  e.target.value,
                                  compareType
                                );
                                this.setState({ page: 0 });
                              }}
                            />
                          </div>
                        </TableCell>
                        {/* <TableCell /> */}
                        <TableCell className="tableBody">
                          <div
                            className="sm-search"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span className="show-range-sign">
                              {showGreaterThanSign(compareType)}
                            </span>
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              value={amount}
                              className="show-range"
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.handleTransactionFilter(
                                  "filterAmount",
                                  e.target.value
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  e.target.value,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                              }}
                            />
                            <ExpandMoreIcon
                              id="Popover1"
                              className="expand-more-icon"
                              onClick={(e) => {
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  // client: client.client,
                                  openRangeBar: true,
                                });
                              }}
                            />
                            <Popover
                              open={this.state.openRangeBar}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openRangeBar: false });
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      openRangeBar: false,
                                      page: 0,
                                    });
                                    this.props.handleTransactionFilter(
                                      "compareType",
                                      "2"
                                    );
                                    this.props.handleChangeTransactionDateFrom(
                                      null
                                    );
                                    this.props.handleChangeTransactionDateTo(
                                      null
                                    );
                                    setInitialValueofMainFilters();
                                    this.props.filterClientTransactionByAll(
                                      clientId,
                                      desc,
                                      checkNo,
                                      // clientNameForBreadcrumb,
                                      vendor,
                                      medium,
                                      type,
                                      transactionStatusHelper([stat]),
                                      transDate,
                                      amount,
                                      filterDebitAmount !== ""
                                        ? -filterDebitAmount
                                        : filterCreditAmount,
                                      "2"
                                    );
                                  }}
                                  className="iconImage"
                                >
                                  <span style={{ marginLeft: "6px" }}>
                                    Lesser than {`(<)`}
                                  </span>
                                </span>
                              </MenuList>
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.props.handleTransactionFilter(
                                      "compareType",
                                      "1"
                                    );
                                    this.setState({
                                      openRangeBar: false,
                                      page: 0,
                                    });
                                    this.props.handleChangeTransactionDateFrom(
                                      null
                                    );
                                    this.props.handleChangeTransactionDateTo(
                                      null
                                    );
                                    setInitialValueofMainFilters();
                                    this.props.filterClientTransactionByAll(
                                      clientId,
                                      desc,
                                      checkNo,
                                      // clientNameForBreadcrumb,
                                      vendor,
                                      medium,
                                      type,
                                      transactionStatusHelper([stat]),
                                      transDate,
                                      amount,
                                      filterDebitAmount !== ""
                                        ? -filterDebitAmount
                                        : filterCreditAmount,
                                      "1"
                                    );
                                  }}
                                  className="iconImage"
                                >
                                  <span style={{ marginLeft: "6px" }}>
                                    Greater than {`(>)`}
                                  </span>
                                </span>
                              </MenuList>
                              <MenuList
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState({
                                      openRangeBar: false,
                                      page: 0,
                                    });
                                    this.props.handleTransactionFilter(
                                      "compareType",
                                      "0"
                                    );
                                    this.props.handleChangeTransactionDateFrom(
                                      null
                                    );
                                    this.props.handleChangeTransactionDateTo(
                                      null
                                    );
                                    setInitialValueofMainFilters();
                                    this.props.filterClientTransactionByAll(
                                      clientId,
                                      desc,
                                      checkNo,
                                      // clientNameForBreadcrumb,
                                      vendor,
                                      medium,
                                      type,
                                      transactionStatusHelper([stat]),
                                      transDate,
                                      amount,
                                      filterDebitAmount !== ""
                                        ? -filterDebitAmount
                                        : filterCreditAmount,
                                      "0"
                                    );
                                  }}
                                  className="iconImage"
                                >
                                  <span style={{ marginLeft: "6px" }}>
                                    None
                                  </span>
                                </span>
                              </MenuList>
                            </Popover>
                          </div>
                        </TableCell>
                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              value={checkNo}
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.handleTransactionFilter(
                                  "filterCheckNo",
                                  e.target.value
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  e.target.value,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                                //   }
                              }}
                            />
                          </div>
                        </TableCell>

                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              value={vendor}
                              onChange={(e) => {
                                e.preventDefault();
                                this.props.handleTransactionFilter(
                                  "filterVendor",
                                  e.target.value
                                );
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });

                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  e.target.value,
                                  medium,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                                //   }
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <Select
                              className="dropdown"
                              dropdownStyle={{
                                maxHeight: "auto",
                                overflowY: "scroll",
                              }}
                              placeholder="Search…"
                              value={medium}
                              getPopupContainer={(node) => node.parentNode}
                              onChange={(e) => {
                                this.props.handleTransactionFilter(
                                  "filterMedium",
                                  e
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  e,
                                  type,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                              }}
                            >
                              <Option value="CHECK">Check</Option>
                              <Option value="CASH">Cash</Option>
                              <Option value="WIRE_TRANSFER">
                                Wire Transfer
                              </Option>
                              <Option value="ACH">ACH</Option>
                              <Option value="CARD">Card</Option>
                              <Option value="LEDGER">Ledger</Option>
                              <Option value="OTHER_MEDIUM">Other medium</Option>
                            </Select>
                          </div>
                        </TableCell>

                        <TableCell className="tableBody ">
                          <div className="sm-search">
                            <Select
                              className="dropdown"
                              dropdownStyle={{
                                maxHeight: "auto",
                                overflowY: "scroll",
                              }}
                              placeholder="Search…"
                              value={type}
                              getPopupContainer={(node) => node.parentNode}
                              onChange={(e) => {
                                this.props.handleTransactionFilter(
                                  "filterType",
                                  e
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  e,
                                  transactionStatusHelper([stat]),
                                  transDate,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                              }}
                            >
                              {userType && userType === "CORPORATE_USER" ? (
                                <Option value="DEPOSIT">Deposit</Option>
                              ) : (
                                allTypeArray &&
                                allTypeArray.map((list) => (
                                  <Option value={list.val}>{list.name}</Option>
                                ))
                              )}
                              {/* <Option value="DISBURSEMENT">Disbursement</Option>
                              <Option value="DEPOSIT">Deposit</Option>
                              <Option value="FEE">Fee</Option>
                              <Option value="REFUND">Refund</Option> */}
                            </Select>
                          </div>
                        </TableCell>
                        <TableCell className="tableBody">
                          <div className="sm-search">
                            <Select
                              className="dropdown"
                              dropdownStyle={{
                                maxHeight: "auto",
                                overflowY: "scroll",
                              }}
                              placeholder="Search…"
                              value={stat}
                              getPopupContainer={(node) => node.parentNode}
                              onChange={(e) => {
                                this.props.handleTransactionFilter(
                                  "filtetStatus",
                                  e
                                );
                                this.props.handleChangeTransactionDateFrom(
                                  null
                                );
                                this.props.handleChangeTransactionDateTo(null);
                                setInitialValueofMainFilters();
                                this.setState({ page: 0 });
                                this.props.filterClientTransactionByAll(
                                  clientId,
                                  desc,
                                  checkNo,
                                  // clientNameForBreadcrumb,
                                  vendor,
                                  medium,
                                  type,
                                  transactionStatusHelper([e]),
                                  transDate,
                                  amount,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount,
                                  compareType
                                );
                              }}
                            >
                              {FilterStatusData &&
                                FilterStatusData.map((list) => (
                                  <Option value={list.name}>{list.name}</Option>
                                ))}
                            </Select>
                          </div>
                        </TableCell>
                        <TableCell className="tableBody ">
                          {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                  
                                  />
                                </div> */}
                        </TableCell>
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableBody ">
                            {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={clientNo}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleChangeFilterClientno(
                                        e.target.value
                                      );

                                      this.props.filterClient(
                                        name,
                                        e.target.value,
                                        caseCount,
                                        date,
                                        balance,
                                        term
                                      );
                                    }}
                                  />
                                </div> */}
                          </TableCell>
                        )}
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableBody ">
                            {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}

                                  />
                                </div> */}
                          </TableCell>
                        )}
                        {userType && userType === "CORPORATE_USER" ? (
                          <div style={{ display: "none" }}></div>
                        ) : (
                          <TableCell className="tableBody ">
                            {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    
                                  />
                                </div> */}
                          </TableCell>
                        )}

                        <TableCell

                        // style={{ paddingLeft: "0", cursor: "pointer" }}
                        >
                          <MoreHorizIcon
                            id="Popover1"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              this.setState({
                                popoverElement: e.currentTarget,

                                openConfigMenu: true,
                              });
                            }}
                          />

                          <Popover
                            open={this.state.openConfigMenu}
                            anchorEl={this.state.popoverElement}
                            onClose={(e) => {
                              e.preventDefault();
                              this.setState({ openConfigMenu: false });
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            style={{ boxShadow: "none" }}
                          >
                            <MenuList
                              style={{
                                color: "#4d4d4d",
                                padding: "1rem !important",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    OpenSaveFilterInConfigmenu: true,
                                    openConfigMenu: false,
                                  });
                                }}
                                className="iconImage"
                              >
                                <span style={{ marginLeft: "6px" }}>
                                  Save In Config
                                </span>
                              </span>
                            </MenuList>
                            <MenuList
                              style={{
                                color: "#4d4d4d",
                                padding: "1rem !important",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.props.getConfigList("8");
                                  this.setState({
                                    OpenViewFilterInConfig: true,
                                    openConfigMenu: false,
                                  });
                                }}
                                className="iconImage"
                              >
                                <span style={{ marginLeft: "6px" }}>
                                  View Config
                                </span>
                              </span>
                            </MenuList>
                          </Popover>
                        </TableCell>

                        <TableCell />
                      </TableRow>
                    </TableBody>
                    {sortedTransactionList &&
                      sortedTransactionList
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((list, index) => {
                          if (list.transactionType === "DEPOSIT") {
                            count =
                              (this.state.page + 1 - 1) *
                                this.state.rowsPerPage +
                              (count + 1);
                          }

                          return (
                            <>
                              {userType && userType === "CORPORATE_USER" ? (
                                <>
                                  {list.transactionType === "DEPOSIT" && (
                                    <TableBody key={list.transactionId}>
                                      <TableRow
                                        style={
                                          list.transactionStatus ===
                                            "TRANSACTION_ON_HOLD" ||
                                          list.transactionStatus ===
                                            "TRANSACTION_BLOCKED"
                                            ? {
                                                cursor: "pointer",
                                                background: "#fff1f0",
                                              }
                                            : { cursor: "pointer" }
                                        }
                                      >
                                        <TableCell>{count}</TableCell>

                                        <TableCell
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            paddingLeft: "40px",
                                            height: "4.5rem",
                                          }}
                                        >
                                          {new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(list.transactionTimestamp)}
                                        </TableCell>
                                        {userType &&
                                        userType === "CORPORATE_USER" ? (
                                          <div
                                            style={{ display: "none" }}
                                          ></div>
                                        ) : (
                                          <TableCell
                                            style={{
                                              paddingRight: "0px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {list.note ? (
                                              <NotesPopover
                                                content={
                                                  <>
                                                    <span>{list.note}</span>
                                                    <Tooltip
                                                      placement="bottom"
                                                      title="Edit Note"
                                                    >
                                                      <EditOutlinedIcon
                                                        onClick={() => {
                                                          this.setState({
                                                            isUpdate: true,
                                                            trans: list,
                                                          });
                                                          this.props.handleChangeToNotesForClient(
                                                            list.note
                                                          );
                                                          this.props.handleChangeToOpenNoteModalForClient(
                                                            true
                                                          );
                                                        }}
                                                        style={{
                                                          marginLeft: "4px",
                                                          width: "15px",
                                                          paddingBottom: "2px",
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </>
                                                }
                                              />
                                            ) : (
                                              <Tooltip
                                                placement="bottom"
                                                title="Add Note"
                                              >
                                                <NoteAddIcon
                                                  onClick={() => {
                                                    this.setState({
                                                      isUpdate: false,
                                                      trans: list,
                                                    });
                                                    this.props.handleChangeToNotesForClient(
                                                      ""
                                                    );
                                                    this.props.handleChangeToOpenNoteModalForClient(
                                                      true
                                                    );
                                                  }}
                                                  style={{
                                                    color: "rgb(126, 126, 126)",
                                                  }}
                                                />
                                              </Tooltip>
                                            )}
                                          </TableCell>
                                        )}
                                        <TableCell className="tableBody">
                                          {/* {list.description ? list.description : "-"} */}
                                          {/* <i class="fa-solid fa-pen fa-xs" onClick={handleChangeDropDown}/> */}

                                          <div
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleOpenDialogue();
                                              this.setState({
                                                trans: list,
                                              });
                                            }}
                                            style={{
                                              position: "relative",
                                              top: "10px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {list.description}
                                          </div>
                                          {userType &&
                                          userType === "CORPORATE_USER" ? (
                                            <div
                                              style={{ display: "none" }}
                                            ></div>
                                          ) : (
                                            <PopoverContent
                                              visible={
                                                list.transactionId ===
                                                  this.state.tempRowId &&
                                                this.state.visible
                                              }
                                              onVisibleChange={
                                                this.handleVisibleChange
                                              }
                                              // visible={list.transactionId === this.state.tempRowId && this.state.openDropDown}
                                              onClick={() => {
                                                this.setState({
                                                  trans: list,
                                                });
                                              }}
                                              trigger={"click"}
                                              placement="bottom"
                                              content={
                                                <>
                                                  {this.props.state
                                                    .settingState &&
                                                    this.props.state.settingState.descriptionList.map(
                                                      (list) => (
                                                        <div
                                                          value={
                                                            list.descriptionId
                                                          }
                                                          className="listHover hideScrollBar"
                                                          onClick={(e) => {
                                                            const selectedData =
                                                              this.props.state
                                                                .settingState &&
                                                              this.props.state.settingState.descriptionList.find(
                                                                (elem) =>
                                                                  elem.descriptionId ===
                                                                  list.descriptionId
                                                              );
                                                            const descriptionData =
                                                              {
                                                                transactionId:
                                                                  this.state
                                                                    .trans
                                                                    .transactionId,
                                                                description:
                                                                  selectedData &&
                                                                  selectedData.description,
                                                              };
                                                            this.props.addDescription(
                                                              descriptionData,
                                                              this.state
                                                                .clientId,
                                                              this.props
                                                            );
                                                            this.handleVisibleChange(
                                                              false
                                                            );
                                                          }}
                                                        >
                                                          {list.description}
                                                          <hr
                                                            style={{
                                                              borderTop: "none",
                                                            }}
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                </>
                                              }
                                            >
                                              <span
                                                id="dropDownToggler"
                                                onClick={(e) => {
                                                  handleChangeDropDown(
                                                    list.transactionId
                                                  );
                                                }}
                                                style={style.editDesc}
                                              >
                                                <i
                                                  class="fa-solid fa-pen fa-xs"
                                                  id="checkDropDown"
                                                />
                                              </span>
                                            </PopoverContent>
                                          )}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          className="tableAmountBody"
                                        >
                                          {list.amount
                                            ? list.amount &&
                                              list.amount.charAt(0) === "-"
                                              ? formatter
                                                  .format(list.amount / 100)
                                                  .substring(1)
                                              : "-"
                                            : formatter.format(0)}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          className="tableAmountBody"
                                        >
                                          {list.amount
                                            ? list.amount &&
                                              list.amount.charAt(0) === "-"
                                              ? "-"
                                              : formatter.format(
                                                  list.amount / 100
                                                )
                                            : formatter.format(0)}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            height: "4.5rem",
                                            // paddingLeft: '47px',
                                            paddingRight: 0,
                                          }}
                                        >
                                          {list.balance
                                            ? formatter.format(
                                                list.balance / 100
                                              )
                                            : formatter.format(0)}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            height: "4.5rem",
                                            // paddingLeft: '47px',
                                            paddingRight: 0,
                                          }}
                                        >
                                          {list.refId}
                                          <span>
                                            {list.checkNo
                                              ? " / " + list.checkNo + " "
                                              : ""}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            paddingLeft: "18px",
                                            height: "4.5rem",
                                          }}
                                        >
                                          {list.vendorAccount.vendorName
                                            ? list.vendorAccount.vendorName
                                            : "-"}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            paddingLeft: "18px",
                                            height: "4.5rem",
                                          }}
                                        >
                                          {list.transactionMedium}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            paddingLeft: "18px",
                                            height: "4.5rem",
                                          }}
                                        >
                                          {list.transactionType.replace(
                                            "_",
                                            " "
                                          )}
                                        </TableCell>
                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            paddingLeft: "18px",
                                            height: "4.5rem",
                                            cursor: "auto",
                                            width: "10%",
                                          }}
                                        >
                                          <>
                                            <Tag
                                              color={
                                                list.transactionStatus ===
                                                "TRANSACTION_APPROVED"
                                                  ? "green"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_REVERSED"
                                                  ? "red"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_CREATED"
                                                  ? "purple"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_SUBMITTED"
                                                  ? "blue"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_BLOCKED"
                                                  ? "yellow"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_RETURNED"
                                                  ? "geekblue"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_POSTED"
                                                  ? "cyan"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_SKIPPED"
                                                  ? "orange"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_ON_HOLD"
                                                  ? "volcano"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_CANCELLED"
                                                  ? "gold"
                                                  : list.transactionStatus ===
                                                    "TRANSACTION_CLOSED"
                                                  ? "magenta"
                                                  : "lime"
                                              }
                                            >
                                              {list.transactionStatus.replace(
                                                "TRANSACTION_",
                                                " "
                                              )}
                                            </Tag>
                                            {list.remark !== undefined ? (
                                              <NotesPopover
                                                content={list.remark}
                                                isRemark
                                              />
                                            ) : null}
                                          </>
                                        </TableCell>

                                        <TableCell
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogue();
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          style={{
                                            fontSize: "15px",
                                            color: "#626262",
                                            textAlign: "left",
                                            paddingLeft: "18px",
                                            height: "4.5rem",
                                            width: "225px",
                                          }}
                                        >
                                          {list.transactionDetails ? (
                                            <div>
                                              <div>
                                                {list.transactionDetails &&
                                                  new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                      second: "2-digit",
                                                    }
                                                  ).format(
                                                    list.transactionDetails
                                                      .scheduleDate
                                                      ? list.transactionDetails
                                                          .scheduleDate
                                                      : "-"
                                                  )}
                                              </div>
                                              <div style={{ marginTop: "5px" }}>
                                                {list.transactionDetails
                                                  .scheduleBy ? (
                                                  <>
                                                    <Avatar
                                                      style={{
                                                        width: "28px",
                                                        height: "28px",
                                                        marginRight: "8px",
                                                      }}
                                                      src={
                                                        list.transactionDetails
                                                          .profilePic
                                                          ? list
                                                              .transactionDetails
                                                              .profilePic
                                                          : "/assets/images/profile.svg"
                                                      }
                                                    />
                                                    <span>
                                                      {
                                                        list.transactionDetails
                                                          .scheduleBy
                                                      }
                                                    </span>
                                                    <span> / </span>
                                                    <span>
                                                      {list.transactionDetails
                                                        .scheduleType &&
                                                        list.transactionDetails.scheduleType.replace(
                                                          "_",
                                                          " "
                                                        )}
                                                    </span>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            "-"
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {list.transactionStatus ===
                                            "TRANSACTION_ON_HOLD" ||
                                          list.transactionStatus ===
                                            "TRANSACTION_BLOCKED" ? (
                                            <>
                                              <PopoverForMenu
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    transactionId:
                                                      list.transactionId,
                                                  });
                                                }}
                                                content={
                                                  <>
                                                    <div
                                                      role="presentation"
                                                      onClick={() => {
                                                        this.setState({
                                                          openActionMenu: false,
                                                          setValue:
                                                            "changeStatus",
                                                          isDecline: false,
                                                        });
                                                        this.props.handleChangeTransStatus(
                                                          ""
                                                        );
                                                        this.props.handleChangeRemark(
                                                          ""
                                                        );
                                                        handleOpenToApproveTransaction();
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Approve
                                                    </div>
                                                  </>
                                                }
                                              />
                                            </>
                                          ) : list.transactionStatus ===
                                            "TRANSACTION_SUBMITTED" ? (
                                            list.transactionMedium ===
                                              "LEDGER" ||
                                            (list.transactionMedium ===
                                              "CHECK" &&
                                              list.transactionType ===
                                                "DISBURSEMENT") ||
                                            list.transactionMedium === "ACH" ? (
                                              <div />
                                            ) : (
                                              <div>
                                                <>
                                                  <PopoverForMenu
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.setState({
                                                        transactionId:
                                                          list.transactionId,
                                                      });
                                                    }}
                                                    content={
                                                      <>
                                                        <div
                                                          role="presentation"
                                                          onClick={() => {
                                                            this.setState({
                                                              openActionMenu: false,
                                                              setValue:
                                                                "changeStatus",
                                                              isDecline: true,
                                                            });
                                                            this.props.handleChangeRemark(
                                                              ""
                                                            );
                                                            handleOpenChangeTransStatusModal();
                                                          }}
                                                          className="cursorP"
                                                        >
                                                          Reverse
                                                        </div>
                                                        {/* {list.transactionStatus ===
                                                        "TRANSACTION_CREATED" ||
                                                      list.transactionStatus ===
                                                        "TRANSACTION_APPROVED" ? (
                                                        <>
                                                          <hr />
                                                          <div
                                                            role="presentation"
                                                            onClick={() => {
                                                              this.setState({
                                                                openActionMenu: false,
                                                                isUpdate: true,
                                                              });
                                                              setOpenCreateTransaction(
                                                                true
                                                              );
                                                              this.props.fetchVendor();
                                                              this.props.fetchDescriptionList();
                                                              this.props.getAmountDetailByClientId(
                                                                clientId
                                                              );

                                                              this.props.fetchVendorClientById(
                                                                clientId
                                                              );
                                                              // this.props.setTransactionInputDataToEdit(
                                                              //   this.state
                                                              //     .selectedTransData
                                                              // );
                                                            }}
                                                            className="cursorP"
                                                          >
                                                            Edit
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )} */}
                                                      </>
                                                    }
                                                  />
                                                </>
                                              </div>
                                            )
                                          ) : list.transactionStatus ===
                                              "TRANSACTION_APPROVED" &&
                                            list.transactionMedium === "ACH" ? (
                                            <div>
                                              <>
                                                <PopoverForMenu
                                                  style={{
                                                    padding: "10px 12px",
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                      transactionId:
                                                        list.transactionId,
                                                    });
                                                  }}
                                                  content={
                                                    <>
                                                      <div
                                                        role="presentation"
                                                        // style={{ padding: "0.2rem" }}
                                                        onClick={() => {
                                                          this.setState({
                                                            openActionMenu: false,
                                                            setValue:
                                                              "changeStatus",
                                                            isDecline: true,
                                                          });
                                                          this.props.handleChangeRemark(
                                                            ""
                                                          );
                                                          handleOpenChangeTransStatusModal(
                                                            list
                                                          );
                                                        }}
                                                        className="cursorP"
                                                      >
                                                        Complete
                                                      </div>
                                                      {userRoleData ===
                                                        "SUPER_ADMIN" &&
                                                        accountTypeData ===
                                                          "EVERFUND_USER" && (
                                                          <>
                                                            <hr />
                                                            <div
                                                              role="presentation"
                                                              // style={{ padding: "0.2rem" }}
                                                              onClick={() => {
                                                                this.setState({
                                                                  openActionMenu: false,
                                                                  setValue:
                                                                    "changeStatus",
                                                                  isDecline: true,
                                                                });
                                                                this.props.handleChangeRemark(
                                                                  ""
                                                                );
                                                                handleDeleteModalOpen();
                                                              }}
                                                              className="cursorP"
                                                            >
                                                              Delete
                                                            </div>
                                                            {/* <hr />
                                                          <div
                                                            role="presentation"
                                                            onClick={() => {
                                                              this.setState({
                                                                openActionMenu: false,
                                                                isUpdate: true,
                                                              });
                                                              setOpenCreateTransaction(
                                                                true
                                                              );
                                                              this.props.fetchVendor();
                                                              this.props.fetchDescriptionList();
                                                              this.props.getAmountDetailByClientId(
                                                                clientId
                                                              );

                                                              this.props.fetchVendorClientById(
                                                                clientId
                                                              );
                                                              // this.props.setTransactionInputDataToEdit(
                                                              //   this.state
                                                              //     .selectedTransData
                                                              // );
                                                            }}
                                                            className="cursorP"
                                                          >
                                                            Edit
                                                          </div> */}
                                                          </>
                                                        )}
                                                    </>
                                                  }
                                                />
                                              </>
                                            </div>
                                          ) : userRoleData === "SUPER_ADMIN" &&
                                            accountTypeData ===
                                              "EVERFUND_USER" &&
                                            (list.transactionStatus ===
                                              "TRANSACTION_APPROVED" ||
                                              list.transactionStatus ===
                                                "TRANSACTION_CREATED") ? (
                                            <div>
                                              <>
                                                <PopoverForMenu
                                                  placement="bottom"
                                                  trigger="click"
                                                  content={
                                                    <>
                                                      <div
                                                        role="presentation"
                                                        onClick={() => {
                                                          this.setState({
                                                            openActionMenu: false,
                                                            setValue:
                                                              "changeStatus",
                                                            transactionId:
                                                              list.transactionId,
                                                          });
                                                          this.props.handleChangeRemark(
                                                            ""
                                                          );
                                                          this.props.handleChangeTransStatus(
                                                            ""
                                                          );
                                                          handleDeleteModalOpen();
                                                        }}
                                                        className="cursorP"
                                                      >
                                                        Delete
                                                      </div>
                                                      {/* <hr />
                                                    <div
                                                      role="presentation"
                                                      onClick={() => {
                                                        this.setState({
                                                          openActionMenu: false,
                                                          isUpdate: true,
                                                        });
                                                        setOpenCreateTransaction(
                                                          true
                                                        );
                                                        this.props.fetchVendor();
                                                        this.props.fetchDescriptionList();
                                                        this.props.getAmountDetailByClientId(
                                                          clientId
                                                        );

                                                        this.props.fetchVendorClientById(
                                                          clientId
                                                        );
                                                        // this.props.setTransactionInputDataToEdit(
                                                        //   this.state
                                                        //     .selectedTransData
                                                        // );
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Edit
                                                    </div> */}
                                                    </>
                                                  }
                                                />
                                              </>
                                            </div>
                                          ) : (
                                            <div />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            paddingLeft: "0px",
                                            fontSize: "15px",
                                            color: "rgb(98, 98, 98)",
                                            textAlign: "left",
                                            height: "4.5rem",
                                            paddingRight: "0px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.fetchActivityLogByRefId(
                                              list.transactionId
                                            );
                                            handleOpenActivityLogModal();
                                          }}
                                        >
                                          <Tooltip title="View Activity Log">
                                            <img
                                              width={22}
                                              src={
                                                "/assets/images/activityLog1.svg"
                                              }
                                              alt=""
                                            />
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  )}
                                </>
                              ) : (
                                <TableBody key={list.transactionId}>
                                  <TableRow
                                    style={
                                      list.transactionStatus ===
                                        "TRANSACTION_ON_HOLD" ||
                                      list.transactionStatus ===
                                        "TRANSACTION_BLOCKED"
                                        ? {
                                            cursor: "pointer",
                                            background: "#fff1f0",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                  >
                                    <TableCell>
                                      {(this.state.page + 1 - 1) *
                                        this.state.rowsPerPage +
                                        (index + 1)}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "40px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {new Intl.DateTimeFormat("default", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                      }).format(list.transactionTimestamp)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        paddingRight: "0px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {list.note ? (
                                        <NotesPopover
                                          content={
                                            <>
                                              <span>{list.note}</span>
                                              <Tooltip
                                                placement="bottom"
                                                title="Edit Note"
                                              >
                                                <EditOutlinedIcon
                                                  onClick={() => {
                                                    this.setState({
                                                      isUpdate: true,
                                                      trans: list,
                                                    });
                                                    this.props.handleChangeToNotesForClient(
                                                      list.note
                                                    );
                                                    this.props.handleChangeToOpenNoteModalForClient(
                                                      true
                                                    );
                                                  }}
                                                  style={{
                                                    marginLeft: "4px",
                                                    width: "15px",
                                                    paddingBottom: "2px",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </>
                                          }
                                        />
                                      ) : (
                                        <Tooltip
                                          placement="bottom"
                                          title="Add Note"
                                        >
                                          <NoteAddIcon
                                            onClick={() => {
                                              this.setState({
                                                isUpdate: false,
                                                trans: list,
                                              });
                                              this.props.handleChangeToNotesForClient(
                                                ""
                                              );
                                              this.props.handleChangeToOpenNoteModalForClient(
                                                true
                                              );
                                            }}
                                            style={{
                                              color: "rgb(126, 126, 126)",
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                    <TableCell className="tableBody">
                                      {/* {list.description ? list.description : "-"} */}
                                      {/* <i class="fa-solid fa-pen fa-xs" onClick={handleChangeDropDown}/> */}

                                      <div
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleOpenDialogue();
                                          this.setState({
                                            trans: list,
                                          });
                                        }}
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {list.description}
                                      </div>
                                      {userType &&
                                      userType === "CORPORATE_USER" ? (
                                        <div style={{ display: "none" }}></div>
                                      ) : (
                                        <PopoverContent
                                          visible={
                                            list.transactionId ===
                                              this.state.tempRowId &&
                                            this.state.visible
                                          }
                                          onVisibleChange={
                                            this.handleVisibleChange
                                          }
                                          // visible={list.transactionId === this.state.tempRowId && this.state.openDropDown}
                                          onClick={() => {
                                            this.setState({
                                              trans: list,
                                            });
                                          }}
                                          trigger={"click"}
                                          placement="bottom"
                                          content={
                                            <>
                                              {this.props.state.settingState &&
                                                this.props.state.settingState.descriptionList.map(
                                                  (list) => (
                                                    <div
                                                      value={list.descriptionId}
                                                      className="listHover hideScrollBar"
                                                      onClick={(e) => {
                                                        const selectedData =
                                                          this.props.state
                                                            .settingState &&
                                                          this.props.state.settingState.descriptionList.find(
                                                            (elem) =>
                                                              elem.descriptionId ===
                                                              list.descriptionId
                                                          );
                                                        const descriptionData =
                                                          {
                                                            transactionId:
                                                              this.state.trans
                                                                .transactionId,
                                                            description:
                                                              selectedData &&
                                                              selectedData.description,
                                                          };
                                                        this.props.addDescription(
                                                          descriptionData,
                                                          this.state.clientId,
                                                          this.props
                                                        );
                                                        this.handleVisibleChange(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      {list.description}
                                                      <hr
                                                        style={{
                                                          borderTop: "none",
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )}
                                            </>
                                          }
                                        >
                                          <span
                                            id="dropDownToggler"
                                            onClick={(e) => {
                                              handleChangeDropDown(
                                                list.transactionId
                                              );
                                            }}
                                            style={style.editDesc}
                                          >
                                            <i
                                              class="fa-solid fa-pen fa-xs"
                                              id="checkDropDown"
                                            />
                                          </span>
                                        </PopoverContent>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      className="tableAmountBody"
                                    >
                                      {list.amount
                                        ? list.amount &&
                                          list.amount.charAt(0) === "-"
                                          ? formatter
                                              .format(list.amount / 100)
                                              .substring(1)
                                          : "-"
                                        : formatter.format(0)}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      className="tableAmountBody"
                                    >
                                      {list.amount
                                        ? list.amount &&
                                          list.amount.charAt(0) === "-"
                                          ? "-"
                                          : formatter.format(list.amount / 100)
                                        : formatter.format(0)}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      className="tableBody"
                                    >
                                      {list.balance
                                        ? formatter.format(list.balance / 100)
                                        : formatter.format(0)}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        // paddingLeft: '47px',
                                        paddingRight: 0,
                                      }}
                                    >
                                      {list.refId}
                                      <span>
                                        {list.checkNo
                                          ? " / " + list.checkNo + " "
                                          : ""}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {list.vendorAccount.vendorName
                                        ? list.vendorAccount.vendorName
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {list.transactionMedium}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {list.transactionType.replace("_", " ")}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                        cursor: "auto",
                                        width: "10%",
                                      }}
                                    >
                                      <>
                                        <Tag
                                          color={
                                            list.transactionStatus ===
                                            "TRANSACTION_APPROVED"
                                              ? "green"
                                              : list.transactionStatus ===
                                                "TRANSACTION_REVERSED"
                                              ? "red"
                                              : list.transactionStatus ===
                                                "TRANSACTION_CREATED"
                                              ? "purple"
                                              : list.transactionStatus ===
                                                "TRANSACTION_SUBMITTED"
                                              ? "blue"
                                              : list.transactionStatus ===
                                                "TRANSACTION_BLOCKED"
                                              ? "yellow"
                                              : list.transactionStatus ===
                                                "TRANSACTION_RETURNED"
                                              ? "geekblue"
                                              : list.transactionStatus ===
                                                "TRANSACTION_POSTED"
                                              ? "cyan"
                                              : list.transactionStatus ===
                                                "TRANSACTION_SKIPPED"
                                              ? "orange"
                                              : list.transactionStatus ===
                                                "TRANSACTION_ON_HOLD"
                                              ? "volcano"
                                              : list.transactionStatus ===
                                                "TRANSACTION_CANCELLED"
                                              ? "gold"
                                              : list.transactionStatus ===
                                                "TRANSACTION_CLOSED"
                                              ? "magenta"
                                              : "lime"
                                          }
                                        >
                                          {list.transactionStatus.replace(
                                            "TRANSACTION_",
                                            " "
                                          )}
                                        </Tag>
                                        {list.remark !== undefined ? (
                                          <NotesPopover
                                            content={list.remark}
                                            isRemark
                                          />
                                        ) : null}
                                      </>
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "40px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {list.achLog &&
                                      list.achLog.achSubmittedDate &&
                                      list.achLog.achSubmittedDate
                                        ? new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(
                                            list.achLog.achSubmittedDate
                                              ? list.achLog.achSubmittedDate
                                              : "-"
                                          )
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "40px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {list.checkPrint &&
                                      list.checkPrint.checkPrintDate &&
                                      list.checkPrint.checkPrintDate
                                        ? new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(
                                            list.checkPrint.checkPrintDate
                                              ? list.checkPrint.checkPrintDate
                                              : "-"
                                          )
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "40px",
                                        height: "4.5rem",
                                      }}
                                    >
                                      {list.postedAt
                                        ? new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(
                                            list.postedAt ? list.postedAt : "-"
                                          )
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState({
                                          trans: list,
                                        });
                                      }}
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                        width: "225px",
                                      }}
                                    >
                                      {list.transactionDetails ? (
                                        <div>
                                          <div>
                                            {list.transactionDetails &&
                                              new Intl.DateTimeFormat("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                              }).format(
                                                list.transactionDetails
                                                  .scheduleDate
                                                  ? list.transactionDetails
                                                      .scheduleDate
                                                  : "-"
                                              )}
                                          </div>
                                          <div style={{ marginTop: "5px" }}>
                                            {list.transactionDetails
                                              .scheduleBy ? (
                                              <>
                                                <Avatar
                                                  style={{
                                                    width: "28px",
                                                    height: "28px",
                                                    marginRight: "8px",
                                                  }}
                                                  src={
                                                    list.transactionDetails
                                                      .profilePic
                                                      ? list.transactionDetails
                                                          .profilePic
                                                      : "/assets/images/profile.svg"
                                                  }
                                                />
                                                <span>
                                                  {
                                                    list.transactionDetails
                                                      .scheduleBy
                                                  }
                                                </span>
                                                <span> / </span>
                                                <span>
                                                  {list.transactionDetails
                                                    .scheduleType &&
                                                    list.transactionDetails.scheduleType.replace(
                                                      "_",
                                                      " "
                                                    )}
                                                </span>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {list.transactionStatus ===
                                        "TRANSACTION_ON_HOLD" ||
                                      list.transactionStatus ===
                                        "TRANSACTION_BLOCKED" ? (
                                        <>
                                          <PopoverForMenu
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({
                                                transactionId:
                                                  list.transactionId,
                                              });
                                            }}
                                            content={
                                              <>
                                                <div
                                                  role="presentation"
                                                  onClick={() => {
                                                    this.setState({
                                                      openActionMenu: false,
                                                      setValue: "changeStatus",
                                                      isDecline: false,
                                                    });
                                                    this.props.handleChangeTransStatus(
                                                      ""
                                                    );
                                                    this.props.handleChangeRemark(
                                                      ""
                                                    );
                                                    handleOpenToApproveTransaction();
                                                  }}
                                                  className="cursorP"
                                                >
                                                  Approve
                                                </div>

                                                {list.transactionStatus ===
                                                  "TRANSACTION_BLOCKED" && (
                                                  <>
                                                    <hr />
                                                    <div
                                                      role="presentation"
                                                      style={{
                                                        padding: "0.2rem",
                                                      }}
                                                      onClick={() => {
                                                        this.setState({
                                                          //   openActionMenu: false,
                                                          transactionId:
                                                            list.transactionId,
                                                        });
                                                        handleModalToEditBlockedTransaction();
                                                        this.props.handleChangeBlockedTransData(
                                                          "amount",
                                                          list.amount.substring(
                                                            1
                                                          )
                                                        );
                                                        this.setState({
                                                          prevAmount:
                                                            list.amount,
                                                        });
                                                        // this.props.handleChangeBlockedTransData("date", new Date().getTime());
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Edit
                                                    </div>
                                                    <hr />
                                                    <div
                                                      role="presentation"
                                                      // style={{ padding: "0.2rem" }}
                                                      onClick={() => {
                                                        this.setState({
                                                          openActionMenu: false,
                                                          setValue:
                                                            "changeStatus",
                                                          isDecline: true,
                                                        });
                                                        this.props.handleChangeRemark(
                                                          ""
                                                        );
                                                        handleDeleteModalOpen();
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Delete
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            }
                                          />
                                        </>
                                      ) : list.transactionStatus ===
                                          "TRANSACTION_SUBMITTED" &&
                                        list.transactionMedium === "CHECK" &&
                                        list.transactionType ===
                                          "DISBURSEMENT" ? (
                                        <>
                                          <PopoverForMenu
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({
                                                transactionId:
                                                  list.transactionId,
                                              });
                                            }}
                                            content={
                                              <div
                                                role="presentation"
                                                onClick={() => {
                                                  this.setState({
                                                    openActionMenu: false,
                                                    transactionId:
                                                      list.transactionId,
                                                  });
                                                  this.props.handleChangeRemark(
                                                    ""
                                                  );

                                                  handleChangeOpenModalToDecline();
                                                }}
                                                className="cursorP"
                                              >
                                                Decline
                                              </div>
                                            }
                                          />
                                        </>
                                      ) : list.transactionStatus ===
                                        "TRANSACTION_REVERSED" ? (
                                        <>
                                          <PopoverForMenu
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({
                                                transactionId:
                                                  list.transactionId,
                                              });
                                            }}
                                            content={
                                              <div
                                                role="presentation"
                                                onClick={() => {
                                                  this.setState({
                                                    openActionMenu: false,
                                                    transactionId:
                                                      list.transactionId,
                                                  });

                                                  handleChangeOpenModalToUndoReverse();
                                                  // handleUndoReversedTrans();
                                                }}
                                                className="cursorP"
                                              >
                                                Undo
                                              </div>
                                            }
                                          />
                                        </>
                                      ) : list.transactionStatus ===
                                        "TRANSACTION_SUBMITTED" ? (
                                        // list.transactionMedium === "LEDGER" ||
                                        // (list.transactionMedium === "CHECK" &&
                                        //   list.transactionType ===
                                        //     "DISBURSEMENT") ||
                                        // list.transactionMedium === "ACH" ? (
                                        //   <div />
                                        // ) : (
                                        <div>
                                          <>
                                            <PopoverForMenu
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  transactionId:
                                                    list.transactionId,
                                                  selectedTransData: list,
                                                });
                                              }}
                                              content={
                                                <>
                                                  <div
                                                    role="presentation"
                                                    onClick={() => {
                                                      this.setState({
                                                        openActionMenu: false,
                                                        setValue:
                                                          "changeStatus",
                                                        isDecline: true,
                                                      });
                                                      this.props.handleChangeRemark(
                                                        ""
                                                      );
                                                      handleOpenChangeTransStatusModal();
                                                    }}
                                                    className="cursorP"
                                                  >
                                                    Reverse
                                                  </div>
                                                  {list.transactionStatus ===
                                                    "TRANSACTION_CREATED" ||
                                                  list.transactionStatus ===
                                                    "TRANSACTION_APPROVED" ? (
                                                    <>
                                                      <hr />
                                                      <div
                                                        role="presentation"
                                                        onClick={() => {
                                                          this.setState({
                                                            openActionMenu: false,
                                                            isTransUpdate: true,
                                                          });
                                                          setOpenCreateTransaction(
                                                            true
                                                          );
                                                          this.props.clearCreateTransactionForm();
                                                          this.props.fetchVendor();
                                                          this.props.fetchDescriptionList();
                                                          this.props.getAmountDetailByClientId(
                                                            clientId
                                                          );

                                                          this.props.fetchVendorClientById(
                                                            clientId
                                                          );
                                                          this.props.setTransactionInputDataToEdit(
                                                            this.state
                                                              .selectedTransData
                                                          );
                                                        }}
                                                        className="cursorP"
                                                      >
                                                        Edit
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              }
                                            />
                                          </>
                                        </div>
                                      ) : // )
                                      list.transactionStatus ===
                                          "TRANSACTION_APPROVED" &&
                                        list.transactionMedium === "ACH" ? (
                                        <div>
                                          <>
                                            <PopoverForMenu
                                              style={{ padding: "10px 12px" }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  transactionId:
                                                    list.transactionId,
                                                  selectedTransData: list,
                                                });
                                              }}
                                              content={
                                                <>
                                                  <div
                                                    role="presentation"
                                                    // style={{ padding: "0.2rem" }}
                                                    onClick={() => {
                                                      this.setState({
                                                        openActionMenu: false,
                                                        setValue:
                                                          "changeStatus",
                                                        isDecline: true,
                                                      });
                                                      this.props.handleChangeRemark(
                                                        ""
                                                      );
                                                      handleOpenChangeTransStatusModal(
                                                        list
                                                      );
                                                    }}
                                                    className="cursorP"
                                                  >
                                                    Complete
                                                  </div>
                                                  {userRoleData ===
                                                    "SUPER_ADMIN" &&
                                                    accountTypeData ===
                                                      "EVERFUND_USER" && (
                                                      <>
                                                        <hr />
                                                        <div
                                                          role="presentation"
                                                          // style={{ padding: "0.2rem" }}
                                                          onClick={() => {
                                                            this.setState({
                                                              openActionMenu: false,
                                                              setValue:
                                                                "changeStatus",
                                                              isDecline: true,
                                                            });
                                                            this.props.handleChangeRemark(
                                                              ""
                                                            );
                                                            handleDeleteModalOpen();
                                                          }}
                                                          className="cursorP"
                                                        >
                                                          Delete
                                                        </div>
                                                        <hr />
                                                        <div
                                                          role="presentation"
                                                          onClick={() => {
                                                            this.setState({
                                                              openActionMenu: false,
                                                              isTransUpdate: true,
                                                            });
                                                            this.props.clearCreateTransactionForm();
                                                            setOpenCreateTransaction(
                                                              true
                                                            );
                                                            this.props.fetchVendor();
                                                            this.props.fetchDescriptionList();
                                                            this.props.getAmountDetailByClientId(
                                                              clientId
                                                            );

                                                            this.props.fetchVendorClientById(
                                                              clientId
                                                            );
                                                            this.props.setTransactionInputDataToEdit(
                                                              this.state
                                                                .selectedTransData
                                                            );
                                                          }}
                                                          className="cursorP"
                                                        >
                                                          Edit
                                                        </div>
                                                      </>
                                                    )}
                                                </>
                                              }
                                            />
                                          </>
                                        </div>
                                      ) : userRoleData === "SUPER_ADMIN" &&
                                        accountTypeData === "EVERFUND_USER" &&
                                        (list.transactionStatus ===
                                          "TRANSACTION_APPROVED" ||
                                          list.transactionStatus ===
                                            "TRANSACTION_CREATED") ? (
                                        <div>
                                          <>
                                            <PopoverForMenu
                                              placement="bottom"
                                              trigger="click"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  selectedTransData: list,
                                                });
                                              }}
                                              content={
                                                <>
                                                  <div
                                                    role="presentation"
                                                    onClick={() => {
                                                      this.setState({
                                                        openActionMenu: false,
                                                        setValue:
                                                          "changeStatus",
                                                        transactionId:
                                                          list.transactionId,
                                                      });
                                                      this.props.handleChangeRemark(
                                                        ""
                                                      );
                                                      this.props.handleChangeTransStatus(
                                                        ""
                                                      );
                                                      handleDeleteModalOpen();
                                                    }}
                                                    className="cursorP"
                                                  >
                                                    Delete
                                                  </div>

                                                  {list.transactionStatus ===
                                                    "TRANSACTION_APPROVED" &&
                                                    list.transactionType !==
                                                      "DEPOSIT" &&
                                                    list.transactionMedium !==
                                                      "LEDGER" && (
                                                      <>
                                                        <hr />
                                                        <div
                                                          role="presentation"
                                                          onClick={() => {
                                                            this.setState({
                                                              openActionMenu: false,
                                                              isTransUpdate: true,
                                                            });
                                                            this.props.clearCreateTransactionForm();
                                                            setOpenCreateTransaction(
                                                              true
                                                            );
                                                            this.props.fetchVendor();
                                                            this.props.fetchDescriptionList();
                                                            this.props.getAmountDetailByClientId(
                                                              clientId
                                                            );

                                                            this.props.fetchVendorClientById(
                                                              clientId
                                                            );
                                                            this.props.setTransactionInputDataToEdit(
                                                              this.state
                                                                .selectedTransData
                                                            );
                                                          }}
                                                          className="cursorP"
                                                        >
                                                          Edit
                                                        </div>
                                                      </>
                                                    )}
                                                </>
                                              }
                                            />
                                          </>
                                        </div>
                                      ) : (
                                        <div />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        paddingLeft: "0px",
                                        fontSize: "15px",
                                        color: "rgb(98, 98, 98)",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        paddingRight: "0px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.fetchActivityLogByRefId(
                                          list.transactionId
                                        );
                                        handleOpenActivityLogModal();
                                      }}
                                    >
                                      <Tooltip title="View Activity Log">
                                        <img
                                          width={22}
                                          src={
                                            "/assets/images/activityLog1.svg"
                                          }
                                          alt=""
                                        />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </>
                          );
                        })}
                  </Table>
                </TableContainer>
                {sortedTransactionList && sortedTransactionList.length > 0 ? (
                  <div className="paginate-border">
                    <TablePagination
                      labelRowsPerPage={false}
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={
                        sortedTransactionList.length > 0
                          ? sortedTransactionList.length
                          : 100
                      }
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "1rem",
                        color: "#606060",
                      }}
                    >
                      {" "}
                      No data to show
                    </h3>
                  </div>
                )}
                {/* </TableContainer> */}
              </div>
            </TabPane>
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Schedule Transaction" key="2">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // marginTop: "60px"
                    }}
                  >
                    <div>
                      <div style={{ marginTop: "30px" }}>
                        <span style={{ marginRight: "10px", fontSize: "16px" }}>
                          Total Schedule Transaction :
                        </span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {totalScheduleTransaction
                            ? totalScheduleTransaction
                            : "0"}
                        </span>
                      </div>
                    </div>
                    <div
                      className={"d-flex flex-row-reverse"}
                      style={style.flex}
                    >
                      {/*refresh button*/}
                      <div>
                        <button
                          className="filterButton"
                          onClick={() => {
                            this.props.fetchScheduleTransactionListByClient(
                              this.state.clientId,
                              this.props
                            );
                            this.props.clearSchTransFilterData();
                            this.props.clearScheduleTransactionFilterForm();
                          }}
                        >
                          <RefreshIcon
                            style={{ color: "#fff", marginTop: "-2px" }}
                          />
                        </button>
                      </div>
                      {/*filter button*/}
                      <div>
                        <button
                          className="filterButton"
                          onClick={() => {
                            this.props.fetchVendor();
                            handleOpenScheduleTransactionFilter();
                            this.props.clearScheduleTransactionFilterForm();
                            this.props.clearSchTransFilterData();
                          }}
                        >
                          <FilterListIcon
                            style={{ color: "#fff", marginTop: "-2px" }}
                          />
                        </button>
                      </div>
                      {/****** Export Button ********/}
                      <div>
                        <>
                          {isFilterEmpty ? (
                            <ExportButton
                              onClickPDF={(e) => {
                                e.preventDefault();
                                this.props.exportClientScheduleTransactionByColumnFilter(
                                  clientId,
                                  filterStartDate,
                                  filterEndDate,
                                  filterName,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  ClientSheduleStatusHelper([filterStatus]),
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  compareType,
                                  "PDF_EXPORT"
                                );
                              }}
                              onClickCSV={(e) => {
                                e.preventDefault();
                                this.props.exportClientScheduleTransactionByColumnFilter(
                                  clientId,
                                  filterStartDate,
                                  filterEndDate,
                                  filterName,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  ClientSheduleStatusHelper([filterStatus]),
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  compareType,
                                  "PDF_EXPORT"
                                );
                              }}
                            />
                          ) : (
                            <ExportButton
                              name={"VOD"}
                              onClickPDF={(e) => {
                                e.preventDefault();
                                this.props.exportClientScheduleTransactionByAll(
                                  clientId,
                                  scheduleTerm,
                                  transactionMediumHelper(
                                    this.state.isMediumCheck
                                  ),
                                  ClientSheduleStatusHelper(
                                    this.state.isScheduleStatusCheck
                                  ),
                                  // scheduleType,

                                  scheduleTimeHelper(
                                    this.state.isScheduleTypeCheck
                                  ),
                                  scheduleStartDate,
                                  scheduleEndDate,

                                  // transTypeForSchedule,
                                  transactionTypeHelper(
                                    this.state.isUPTypeCheck,
                                    "transactionType"
                                  ),
                                  vendorToFilter,
                                  "PDF_EXPORT"
                                );
                              }}
                              onClickCSV={(e) => {
                                e.preventDefault();
                                this.props.exportClientScheduleTransactionByAll(
                                  clientId,
                                  scheduleTerm,
                                  transactionMediumHelper(
                                    this.state.isMediumCheck
                                  ),
                                  ClientSheduleStatusHelper(
                                    this.state.isScheduleStatusCheck
                                  ),
                                  // scheduleType,
                                  scheduleTimeHelper(
                                    this.state.isScheduleTypeCheck
                                  ),
                                  scheduleStartDate,
                                  scheduleEndDate,
                                  transactionTypeHelper(
                                    this.state.isUPTypeCheck,
                                    "transactionType"
                                  ),
                                  vendorToFilter,
                                  "CSV_EXPORT"
                                );
                              }}
                              onClickVOD={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionVOD(
                                  clientId,
                                  startDate,
                                  endDate,
                                  "PDF_EXPORT"
                                );
                              }}
                            />
                          )}
                        </>
                      </div>
                      <div>
                        <button
                          disabled={disableButton}
                          style={
                            disableButton
                              ? {
                                  background: "#8bc83fc4",
                                  width: "215px",
                                  marginRight: "12px",
                                }
                              : {
                                  background: "#8bc83f",
                                  width: "215px",
                                  marginRight: "12px",
                                }
                          }
                          className="button-css"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              isUpdate: false,
                              isFirstOpen: true,
                            });
                            this.props.setOpenModalToScheduleTransaction(true);
                            this.props.clearScheduleTransactionInputData();
                            this.props.fetchDescriptionList();
                            this.props.clearFileData();
                            this.props.fetchVendor();
                            this.props.fetchVendorClientById(clientId);
                            this.props.getAmountDetailByClientId(clientId);
                            this.props.handleChangeScheduleTransactionInputData(
                              "clientId",
                              clientId
                            );
                          }}
                        >
                          <AddIcon /> Schedule Transaction
                        </button>
                      </div>
                      <div className="search">
                        <div style={style.searchIcon}>
                          <SearchIcon style={{ color: "#9c9c9c" }} />
                        </div>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          style={style.inputBase}
                          onChange={(e) => {
                            e.preventDefault();
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchScheduleTransactionListByClient(
                                clientId
                              );
                            } else {
                              this.props.searchClientScheduleTransaction(
                                e.target.value,
                                clientId
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"row"} style={style.card}>
                    <TableContainer className="fixed-table-header-second">
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        ref={this.tableRef}
                      >
                        <TableHead style={{ padding: "1rem" }}>
                          <TableRow>
                            <TableCell
                              className="first-tableHeader"
                              key="scheduledDate"
                              sortDirection={
                                this.state.orderBy === "scheduledDate"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "scheduledDate"}
                                direction={
                                  this.state.orderBy === "scheduledDate"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "scheduledDate"
                                )}
                              >
                                Start Date
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="endDate"
                              sortDirection={
                                this.state.orderBy === "endDate"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "endDate"}
                                direction={
                                  this.state.orderBy === "endDate"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("endDate")}
                              >
                                End Date
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="amount"
                              sortDirection={
                                this.state.orderBy === "amount"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "amount"}
                                direction={
                                  this.state.orderBy === "amount"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("amount")}
                              >
                                Amount
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="firstName"
                              sortDirection={
                                this.state.orderBy === "firstName"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "firstName"}
                                direction={
                                  this.state.orderBy === "firstName"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("firstName")}
                              >
                                Client Name
                              </TableSortLabel>
                            </TableCell>
                            {/*<TableCell className="tableHeader">*/}
                            {/*  Schedule Type*/}
                            {/*</TableCell>*/}
                            <TableCell
                              className="tableHeader"
                              key="vendorName"
                              sortDirection={
                                this.state.orderBy === "vendorName"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "vendorName"}
                                direction={
                                  this.state.orderBy === "vendorName"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("vendorName")}
                              >
                                Vendor
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="transactionMedium"
                              sortDirection={
                                this.state.orderBy === "transactionMedium"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "transactionMedium"
                                }
                                direction={
                                  this.state.orderBy === "transactionMedium"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "transactionMedium"
                                )}
                              >
                                Medium
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="transactionType"
                              sortDirection={
                                this.state.orderBy === "transactionType"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "transactionType"
                                }
                                direction={
                                  this.state.orderBy === "transactionType"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "transactionType"
                                )}
                              >
                                Transaction Type
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="scheduleTransactionStatus"
                              sortDirection={
                                this.state.orderBy ===
                                "scheduleTransactionStatus"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy ===
                                  "scheduleTransactionStatus"
                                }
                                direction={
                                  this.state.orderBy ===
                                  "scheduleTransactionStatus"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "scheduleTransactionStatus"
                                )}
                              >
                                Status
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="createdAt"
                              sortDirection={
                                this.state.orderBy === "createdAt"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "createdAt"}
                                direction={
                                  this.state.orderBy === "createdAt"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("createdAt")}
                              >
                                Scheduled Date/By/Type
                              </TableSortLabel>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={
                                    filterStartDate === null
                                      ? ""
                                      : filterStartDate
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleScheduleTransFilter(
                                      "filterStartDate",
                                      e.target.value
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });

                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      e.target.value,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      filterType,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={
                                    filterEndDate === null ? "" : filterEndDate
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleScheduleTransFilter(
                                      "filterEndDate",
                                      e.target.value
                                    );
                                    this.setState({ page: 0 });
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      e.target.value,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      filterType,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div
                                className="sm-search"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="show-range-sign">
                                  {showGreaterThanSign(compareTypeSCH)}
                                </span>
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={filterAmount}
                                  className="show-range"
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleScheduleTransFilter(
                                      "filterAmount",
                                      e.target.value
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      filterType,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      e.target.value,
                                      compareTypeSCH
                                    );
                                  }}
                                />
                                <ExpandMoreIcon
                                  id="Popover1"
                                  className="expand-more-icon"
                                  onClick={(e) => {
                                    this.setState({
                                      popoverElement: e.currentTarget,

                                      openRangeBarSCH: true,
                                    });
                                  }}
                                />
                                <Popover
                                  open={this.state.openRangeBarSCH}
                                  anchorEl={this.state.popoverElement}
                                  onClose={(e) => {
                                    e.preventDefault();
                                    this.setState({ openRangeBarSCH: false });
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  style={{ boxShadow: "none" }}
                                >
                                  <MenuList
                                    style={{
                                      color: "#4d4d4d",
                                      padding: "1rem !important",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          openRangeBarSCH: false,

                                          page: 0,
                                        });
                                        this.props.handleScheduleTransFilter(
                                          "compareType",
                                          "2"
                                        );
                                        this.props.handleChangeFromDate(null);
                                        this.props.handleChangeToDate(null);
                                        setInitialValueofMainFilters();
                                        this.props.filterScheduleTransactionByAll(
                                          clientId,
                                          filterStartDate,
                                          filterEndDate,
                                          clientNameForBreadcrumb,
                                          filterVendor,
                                          filterMedium,
                                          filterType,
                                          filterStatus,
                                          "",
                                          filterScheduleDate,
                                          filterAmount,
                                          "2"
                                        );
                                      }}
                                      className="iconImage"
                                    >
                                      <span style={{ marginLeft: "6px" }}>
                                        Lesser than {`(<)`}
                                      </span>
                                    </span>
                                  </MenuList>
                                  <MenuList
                                    style={{
                                      color: "#4d4d4d",
                                      padding: "1rem !important",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          openRangeBarSCH: false,
                                          page: 0,
                                        });
                                        this.props.handleScheduleTransFilter(
                                          "compareType",
                                          "1"
                                        );
                                        this.props.handleChangeFromDate(null);
                                        this.props.handleChangeToDate(null);
                                        setInitialValueofMainFilters();
                                        this.props.filterScheduleTransactionByAll(
                                          clientId,
                                          filterStartDate,
                                          filterEndDate,
                                          clientNameForBreadcrumb,
                                          filterVendor,
                                          filterMedium,
                                          filterType,
                                          filterStatus,
                                          "",
                                          filterScheduleDate,
                                          filterAmount,
                                          "1"
                                        );
                                      }}
                                      className="iconImage"
                                    >
                                      <span style={{ marginLeft: "6px" }}>
                                        Greater than {`(>)`}
                                      </span>
                                    </span>
                                  </MenuList>
                                  <MenuList
                                    style={{
                                      color: "#4d4d4d",
                                      padding: "1rem !important",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <span
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          openRangeBarSCH: false,
                                          page: 0,
                                        });
                                        this.props.handleScheduleTransFilter(
                                          "compareType",
                                          "0"
                                        );
                                        this.props.handleChangeFromDate(null);
                                        this.props.handleChangeToDate(null);
                                        setInitialValueofMainFilters();
                                        this.props.filterScheduleTransactionByAll(
                                          clientId,
                                          filterStartDate,
                                          filterEndDate,
                                          clientNameForBreadcrumb,
                                          filterVendor,
                                          filterMedium,
                                          filterType,
                                          filterStatus,
                                          "",
                                          filterScheduleDate,
                                          filterAmount,
                                          "0"
                                        );
                                      }}
                                      className="iconImage"
                                    >
                                      <span style={{ marginLeft: "6px" }}>
                                        None
                                      </span>
                                    </span>
                                  </MenuList>
                                </Popover>
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={filterName}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleScheduleTransFilter(
                                      "filterName",
                                      e.target.value
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      filterType,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={filterVendor}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleScheduleTransFilter(
                                      "filterVendor",
                                      e.target.value
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      e.target.value,
                                      filterMedium,
                                      filterType,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={filterMedium}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleScheduleTransFilter(
                                      "filterMedium",
                                      e
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      e,
                                      filterType,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                >
                                  <Option value="CHECK">Check</Option>
                                  <Option value="CASH">Cash</Option>
                                  <Option value="WIRE_TRANSFER">
                                    Wire Transfer
                                  </Option>
                                  <Option value="ACH">ACH</Option>
                                  <Option value="CARD">Card</Option>
                                  <Option value="LEDGER">Ledger</Option>
                                  <Option value="OTHER_MEDIUM">
                                    Other medium
                                  </Option>
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={filterType}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleScheduleTransFilter(
                                      "filterType",
                                      e
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      e,
                                      filterStatus,
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                >
                                  {allTypeArray &&
                                    allTypeArray.map((list) => (
                                      <Option value={list.val}>
                                        {list.name}
                                      </Option>
                                    ))}
                                  {/* <Option value="DISBURSEMENT">
                                    Disbursement
                                  </Option>
                                  <Option value="DEPOSIT">Deposit</Option>
                                  <Option value="FEE">Fee</Option> */}
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={filterStatus}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleScheduleTransFilter(
                                      "filterStatus",
                                      e
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      filterType,
                                      ClientSheduleStatusHelper([e]),
                                      "",
                                      filterScheduleDate,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                >
                                  {FilterClientSchStatusData &&
                                    FilterClientSchStatusData.map((list) => (
                                      <Option value={list.name}>
                                        {list.name}
                                      </Option>
                                    ))}
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableHeader sticky-search-bar">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={filterScheduleDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleScheduleTransFilter(
                                      "filterScheduleDate",
                                      e.target.value
                                    );
                                    this.props.handleChangeFromDate(null);
                                    this.props.handleChangeToDate(null);
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterScheduleTransactionByAll(
                                      clientId,
                                      filterStartDate,
                                      filterEndDate,
                                      clientNameForBreadcrumb,
                                      filterVendor,
                                      filterMedium,
                                      filterType,
                                      filterStatus,
                                      "",
                                      e.target.value,
                                      filterAmount,
                                      compareTypeSCH
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableCol sticky-search-bar">
                              <MoreHorizIcon
                                id="Popover1"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  this.setState({
                                    popoverElement: e.currentTarget,

                                    openConfigMenu: true,
                                  });
                                }}
                              />

                              <Popover
                                open={this.state.openConfigMenu}
                                anchorEl={this.state.popoverElement}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.setState({ openConfigMenu: false });
                                }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                style={{ boxShadow: "none" }}
                              >
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        OpenSaveFilterInConfigmenu: true,
                                        openConfigMenu: false,
                                        isClientSchTrans: true,
                                      });
                                    }}
                                    className="iconImage"
                                  >
                                    <span style={{ marginLeft: "6px" }}>
                                      Save In Config
                                    </span>
                                  </span>
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "1rem !important",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.props.getConfigList("9");
                                      this.setState({
                                        OpenViewFilterInConfig: true,
                                        openConfigMenu: false,
                                        isClientSchTrans: true,
                                      });
                                    }}
                                    className="iconImage"
                                  >
                                    <span style={{ marginLeft: "6px" }}>
                                      View Config
                                    </span>
                                  </span>
                                </MenuList>
                              </Popover>
                            </TableCell>

                            <TableCell className="sticky-search-bar" />
                          </TableRow>
                        </TableBody>
                        {sortedSchTransactionList &&
                          sortedSchTransactionList
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map((scheduleTransaction) => (
                              <TableBody
                                key={scheduleTransaction.scheduleTransactionId}
                              >
                                <TableRow>
                                  <TableCell style={style.tableFirstBody}>
                                    {/*{getDate(scheduleTransaction.scheduleDetail.scheduledDate)}*/}
                                    {scheduleTransaction &&
                                      new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                      }).format(
                                        scheduleTransaction.scheduleDetail
                                          .scheduledDate
                                          ? scheduleTransaction.scheduleDetail
                                              .scheduledDate
                                          : "-"
                                      )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      height: "4.5rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {scheduleTransaction &&
                                    scheduleTransaction.scheduleDetail &&
                                    scheduleTransaction.scheduleDetail.endDate
                                      ? new Intl.DateTimeFormat("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                        }).format(
                                          scheduleTransaction.scheduleDetail
                                            .endDate
                                        )
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      // textAlign: "right",
                                      height: "4.5rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {scheduleTransaction.amount
                                      ? formatter.format(
                                          scheduleTransaction.amount / 100
                                        )
                                      : formatter.format(0)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {scheduleTransaction.client.firstName +
                                      " " +
                                      scheduleTransaction.client.lastName}
                                  </TableCell>
                                  {/*<TableCell*/}
                                  {/*    style={{*/}
                                  {/*      fontSize: "15px",*/}
                                  {/*      color: "#626262",*/}
                                  {/*      textAlign: "left",*/}
                                  {/*      paddingLeft: "18px",*/}
                                  {/*      height: "4.5rem",*/}
                                  {/*      cursor: "pointer",*/}
                                  {/*    }}*/}
                                  {/*>*/}
                                  {/*  {*/}
                                  {/*    scheduleTransaction.scheduleDetail*/}
                                  {/*        .scheduleType.replace("_", " ")*/}
                                  {/*  }*/}
                                  {/*</TableCell>*/}
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    {scheduleTransaction.vendorAccount &&
                                      scheduleTransaction.vendorAccount
                                        .vendorName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {scheduleTransaction.transactionMedium.replace(
                                      "_",
                                      " "
                                    )}
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {scheduleTransaction.transactionType.replace(
                                      "_",
                                      " "
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    <Tag
                                      style={style.colors}
                                      color={
                                        scheduleTransaction.scheduleTransactionStatus ===
                                        "SCHEDULE_PENDING"
                                          ? "purple"
                                          : scheduleTransaction.scheduleTransactionStatus ===
                                            "SCHEDULED"
                                          ? "blue"
                                          : scheduleTransaction.scheduleTransactionStatus ===
                                            "APPROVED"
                                          ? "green"
                                          : scheduleTransaction.scheduleTransactionStatus ===
                                            "CLOSED"
                                          ? "orange"
                                          : scheduleTransaction.scheduleTransactionStatus ===
                                            "CANCELLED"
                                          ? "red"
                                          : "lime"
                                      }
                                    >
                                      {scheduleTransaction.scheduleTransactionStatus ===
                                      "SCHEDULE_PENDING"
                                        ? scheduleTransaction.scheduleTransactionStatus.replace(
                                            "SCHEDULE_",
                                            " "
                                          )
                                        : scheduleTransaction.scheduleTransactionStatus.replace(
                                            "_",
                                            " "
                                          )}
                                    </Tag>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    <div>
                                      {scheduleTransaction &&
                                        new Intl.DateTimeFormat("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          second: "2-digit",
                                        }).format(
                                          scheduleTransaction.createdAt
                                            ? scheduleTransaction.createdAt
                                            : "-"
                                        )}
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                      {scheduleTransaction.scheduleDetail
                                        .scheduleByName ? (
                                        <>
                                          <Avatar
                                            style={{
                                              width: "28px",
                                              height: "28px",
                                              marginRight: "8px",
                                            }}
                                            src={
                                              scheduleTransaction.scheduleDetail
                                                .profileUrl
                                                ? scheduleTransaction
                                                    .scheduleDetail.profileUrl
                                                : "/assets/images/profile.svg"
                                            }
                                          />
                                          <span>
                                            {
                                              scheduleTransaction.scheduleDetail
                                                .scheduleByName
                                            }
                                          </span>
                                          <span> / </span>
                                          <span>
                                            {scheduleTransaction.scheduleDetail
                                              .scheduleType &&
                                              scheduleTransaction.scheduleDetail.scheduleType.replace(
                                                "_",
                                                " "
                                              )}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          popoverElement1: e.currentTarget,
                                          openActionMenu: true,
                                          scheduleClientTrans:
                                            scheduleTransaction,
                                        });
                                        this.props.handleChangeStatus(
                                          scheduleTransaction.scheduleTransactionStatus,
                                          scheduleTransaction.scheduleTransactionId
                                        );
                                      }}
                                    />
                                    <Popover
                                      open={this.state.openActionMenu}
                                      anchorEl={this.state.popoverElement1}
                                      onClose={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      style={{ boxShadow: "none" }}
                                    >
                                      <MenuList
                                        id="popover-list"
                                        style={{
                                          color: "#4d4d4d",
                                          padding: "1rem !important",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {status && status === "APPROVED" ? (
                                          <div>
                                            <MenuItem
                                              onClick={() => {
                                                this.setState({
                                                  openActionMenu: false,
                                                });
                                                handleOpenDialogueForScheduleTransactionUpdate();
                                              }}
                                            >
                                              <ScheduleIcon
                                                style={{ paddingBottom: 0 }}
                                              />
                                              Schedule
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                handleOpenUpdateAsClose();
                                                this.setState({
                                                  openActionMenu: false,
                                                });
                                              }}
                                            >
                                              <CloseIcon
                                                style={{ paddingBottom: 0 }}
                                              />
                                              Close
                                            </MenuItem>
                                          </div>
                                        ) : status && status === "SCHEDULED" ? (
                                          <div>
                                            <MenuItem
                                              onClick={() => {
                                                handleOpenUpdateAsClose();
                                                this.setState({
                                                  openActionMenu: false,
                                                });
                                              }}
                                            >
                                              <CloseIcon
                                                style={{ paddingBottom: 0 }}
                                              />{" "}
                                              Close
                                            </MenuItem>
                                          </div>
                                        ) : status === "CLOSED" ? (
                                          <div>
                                            <MenuItem
                                              onClick={() => {
                                                this.setState({
                                                  openActionMenu: false,
                                                  isReopen: true,
                                                });
                                                handleOpenDialogueForScheduleTransactionUpdate();
                                              }}
                                            >
                                              <ScheduleIcon
                                                style={{ paddingBottom: 0 }}
                                              />
                                              Reopen
                                            </MenuItem>
                                          </div>
                                        ) : status === "SCHEDULE_PENDING" ? (
                                          <div>
                                            <MenuItem
                                              onClick={() => {
                                                this.setState({
                                                  openActionMenu: false,
                                                  isReopen: false,
                                                });
                                                handleOpenDialogueForScheduleTransactionUpdate();
                                              }}
                                            >
                                              <ScheduleIcon
                                                style={{ paddingBottom: 0 }}
                                              />
                                              Schedule
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                handleOpenUpdateAsCancel();
                                                this.setState({
                                                  openActionMenu: false,
                                                });
                                              }}
                                            >
                                              <CancelOutlinedIcon
                                                style={{ paddingBottom: 0 }}
                                              />
                                              Cancel
                                            </MenuItem>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <MenuItem
                                          hidden={status === "CLOSED"}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.setScheduleTransactionInputDataToEdit(
                                              this.state.scheduleClientTrans
                                            );
                                            this.props.setOpenModalToScheduleTransaction(
                                              true
                                            );
                                            this.props.getAmountDetailByClientId(
                                              clientId
                                            );
                                            this.props.handleChangeScheduleTransactionInputData(
                                              "clientId",
                                              clientId
                                            );
                                            this.setState({
                                              openActionMenu: false,
                                              isUpdate: true,
                                            });
                                          }}
                                        >
                                          <EditOutlinedIcon
                                            style={{ paddingBottom: 0 }}
                                          />{" "}
                                          Edit
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      paddingLeft: "0px",
                                      fontSize: "15px",
                                      color: "rgb(98, 98, 98)",
                                      textAlign: "left",
                                      height: "4.5rem",
                                      paddingRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.fetchActivityLogByRefId(
                                        scheduleTransaction.scheduleTransactionId
                                      );
                                      handleOpenActivityLogModalForSchedule();
                                    }}
                                  >
                                    <Tooltip title="View Activity Log">
                                      <img
                                        width={22}
                                        src={"/assets/images/activityLog1.svg"}
                                        alt=""
                                      />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                      </Table>
                    </TableContainer>
                    {sortedSchTransactionList &&
                    sortedSchTransactionList.length > 0 ? (
                      <div className="paginate-border">
                        <TablePagination
                          labelRowsPerPage={false}
                          rowsPerPageOptions={[4, 10, 25, 100]}
                          component="div"
                          count={
                            sortedSchTransactionList.length > 0
                              ? sortedSchTransactionList.length
                              : 100
                          }
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          backIconButtonProps={{
                            "aria-label": "previous page",
                          }}
                          nextIconButtonProps={{
                            "aria-label": "next page",
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            padding: "1rem",
                            color: "#606060",
                          }}
                        >
                          {" "}
                          No data to show
                        </h3>
                      </div>
                    )}
                    {/* </TableContainer> */}
                  </div>
                </div>
              </TabPane>
            )}

            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Upcoming Transaction" key="14">
                <div>
                  <div className={"d-flex flex-row-reverse"} style={style.flex}>
                    <div>
                      <button
                        className="filterButton"
                        onClick={() => {
                          this.props.getClientUpcomingTrans(clientId);
                          this.props.clearUpTransFilter();
                        }}
                      >
                        <RefreshIcon
                          style={{ color: "#fff", marginTop: "-2px" }}
                        />
                      </button>
                    </div>
                    <div>
                      <button
                        className="filterButton"
                        style={style.filterButton}
                        onClick={() => {
                          this.props.fetchVendor();
                          handleOpenTransactionFilterModal();
                        }}
                      >
                        <FilterListIcon
                          style={{ color: "#fff", marginTop: "-2px" }}
                        />
                      </button>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="search">
                        <div style={style.searchIcon}>
                          <SearchIcon style={{ color: "#9c9c9c" }} />
                        </div>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          style={style.inputBase}
                          onChange={(e) => {
                            this.setState({
                              page: 0,
                            });
                            e.preventDefault();
                            this.props.fetchClientUpcomingTransactionList(
                              this.state.clientId,
                              0,
                              0,
                              "CHECK&medium=CASH&medium=WIRE_TRANSFER&medium=ACH&medium=CARD&medium=LEDGER&medium=OTHER_MEDIUM",
                              e.target.value,
                              "",
                              "DISBURSEMENT&transactionType=DEPOSIT"
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"row"} style={style.card}>
                    <TableContainer className="fixed-table-header-second">
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        ref={this.tableRef}
                      >
                        <TableHead style={{ padding: "1rem" }}>
                          <TableRow>
                            <TableCell className="tableHeader">#</TableCell>
                            <TableCell
                              className="first-tableHeader"
                              key="upcomingTxnDate"
                              sortDirection={
                                this.state.orderBy === "upcomingTxnDate"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "upcomingTxnDate"
                                }
                                direction={
                                  this.state.orderBy === "upcomingTxnDate"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "upcomingTxnDate"
                                )}
                              >
                                Transaction Date/Time
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="amount"
                              sortDirection={
                                this.state.orderBy === "amount"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "amount"}
                                direction={
                                  this.state.orderBy === "amount"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("amount")}
                              >
                                Amount
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="vendorName"
                              sortDirection={
                                this.state.orderBy === "vendorName"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "vendorName"}
                                direction={
                                  this.state.orderBy === "vendorName"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("vendorName")}
                              >
                                Vendor
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="transactionMedium"
                              sortDirection={
                                this.state.orderBy === "transactionMedium"
                                  ? this.state.order
                                  : false
                              }
                            >
                              {" "}
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "transactionMedium"
                                }
                                direction={
                                  this.state.orderBy === "transactionMedium"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "transactionMedium"
                                )}
                              >
                                Medium
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="transactionType"
                              sortDirection={
                                this.state.orderBy === "transactionType"
                                  ? this.state.order
                                  : false
                              }
                            >
                              {" "}
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "transactionType"
                                }
                                direction={
                                  this.state.orderBy === "transactionType"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "transactionType"
                                )}
                              >
                                Type
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="scheduledDate"
                              sortDirection={
                                this.state.orderBy === "scheduledDate"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "scheduledDate"}
                                direction={
                                  this.state.orderBy === "scheduledDate"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "scheduledDate"
                                )}
                              >
                                Scheduled Date/By/Type
                              </TableSortLabel>
                            </TableCell>
                            <TableCell className="tableHeader"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            className="sticky-search-bar"
                            style={{ top: "42px" }}
                          >
                            <TableCell className="first-tableBody " />
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPDate === null ? "" : UPDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterDate",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByClient(
                                      clientId,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      e.target.value,
                                      UPSchDate,
                                      UPAmount,
                                      compareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPAmount}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterAmount",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByClient(
                                      clientId,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      e.target.value,
                                      compareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>

                            <TableCell className="tableBody ">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPVendor}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterVendor",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByClient(
                                      clientId,
                                      e.target.value,
                                      UPMedium,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      compareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={UPMedium}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleUpTransFilter(
                                      "filterMedium",
                                      e
                                    );
                                    this.props.searchUpcomingTransactionByClient(
                                      clientId,
                                      UPVendor,
                                      e,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      compareType
                                    );
                                  }}
                                >
                                  <Option value="CHECK">Check</Option>
                                  <Option value="CASH">Cash</Option>
                                  <Option value="WIRE_TRANSFER">
                                    Wire Transfer
                                  </Option>
                                  <Option value="ACH">ACH</Option>
                                  <Option value="CARD">Card</Option>
                                  <Option value="LEDGER">Ledger</Option>
                                  <Option value="OTHER_MEDIUM">
                                    Other medium
                                  </Option>
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={UPType}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleUpTransFilter(
                                      "filterType",
                                      e
                                    );
                                    this.props.searchUpcomingTransactionByClient(
                                      clientId,
                                      UPVendor,
                                      UPMedium,
                                      e,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      compareType
                                    );
                                  }}
                                >
                                  <Option value="DISBURSEMENT">
                                    Disbursement
                                  </Option>
                                  <Option value="DEPOSIT">Deposit</Option>
                                  <Option value="FEE">Fee</Option>
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableBody ">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPSchDate === null ? "" : UPSchDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterSchedule",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByClient(
                                      clientId,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      UPDate,

                                      e.target.value,
                                      UPAmount,
                                      compareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>

                            <TableCell
                              style={{ paddingLeft: "0", cursor: "pointer" }}
                            >
                              <Popover
                                placement="bottom"
                                trigger="click"
                                content={
                                  <>
                                    <div
                                      role="presentation"
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   this.setState({
                                      //     OpenSaveFilterInConfigmenu: true,

                                      //     openConfigMenu: false,
                                      //   });
                                      // }}
                                      className="cursorP"
                                    >
                                      Save In Config
                                    </div>

                                    <div
                                      role="presentation"
                                      style={{
                                        padding: "0.2rem",
                                      }}
                                      // onClick={(e) => {
                                      //   e.preventDefault();

                                      //   this.props.getConfigList("4");
                                      //   this.setState({
                                      //     OpenViewFilterInConfig: true,

                                      //     openConfigMenu: false,
                                      //   });
                                      // }}
                                      className="cursorP"
                                    >
                                      View Config
                                    </div>
                                  </>
                                }
                              >
                                <MoreHorizIcon
                                // onClick={(e) => {
                                //   e.preventDefault();

                                //   this.setState({
                                //     popoverElement: e.currentTarget,

                                //     openConfigMenu: true,
                                //   });
                                // }}
                                />
                              </Popover>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        {sortedUpcomingTransactionList &&
                          sortedUpcomingTransactionList
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map((list, index) => (
                              <TableBody>
                                <TableRow
                                  style={
                                    (list.transactionType === "DISBURSEMENT" ||
                                      list.transactionType === "FEE") &&
                                    list.isFundAvailable === undefined
                                      ? {
                                          cursor: "pointer",
                                          background: "#fff1f0",
                                        }
                                      : { cursor: "pointer" }
                                  }
                                >
                                  <TableCell>
                                    {" "}
                                    {(this.state.page + 1 - 1) *
                                      this.state.rowsPerPage +
                                      (index + 1)}{" "}
                                  </TableCell>
                                  <TableCell className="first-tableBody">
                                    {new Intl.DateTimeFormat("default", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(list.upcomingTxnDate)}
                                  </TableCell>
                                  <TableCell className="tableAmount">
                                    {list.amount &&
                                      formatter.format(list.amount / 100)}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.vendorAccount.vendorName}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.transactionMedium}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.transactionType}
                                  </TableCell>
                                  <TableCell className="">
                                    {list.scheduleDetail ? (
                                      <div>
                                        <div>
                                          {list.createdAt &&
                                            new Intl.DateTimeFormat("en-US", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                            }).format(
                                              list.createdAt
                                                ? list.createdAt
                                                : "-"
                                            )}
                                        </div>
                                        <div style={{ marginTop: "5px" }}>
                                          {list.scheduleDetail
                                            .scheduleByName ? (
                                            <>
                                              <Avatar
                                                style={{
                                                  width: "28px",
                                                  height: "28px",
                                                  marginRight: "8px",
                                                }}
                                                src={
                                                  list.scheduleDetail.profileUrl
                                                    ? list.scheduleDetail
                                                        .profileUrl
                                                    : "/assets/images/profile.svg"
                                                }
                                              />
                                              <span>
                                                {
                                                  list.scheduleDetail
                                                    .scheduleByName
                                                }
                                              </span>
                                              <span> / </span>
                                              <span>
                                                {list.scheduleDetail
                                                  .scheduleType &&
                                                  list.scheduleDetail.scheduleType.replace(
                                                    "_",
                                                    " "
                                                  )}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {list.isSkipped === undefined &&
                                    list.allowProcessNow === true ? (
                                      <div style={{ display: "flex" }}>
                                        <Button
                                          style={style.skipNow}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              upcomingTrans: list,
                                              isProcess: false,
                                            });
                                            this.props.handleChangeToOpenSkipModal(
                                              true
                                            );
                                            this.props.handleChangeRemarkToSkip(
                                              ""
                                            );
                                          }}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            Skip
                                          </span>
                                        </Button>
                                        <Button
                                          style={style.processNow}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              upcomingTrans: list,
                                              isProcess: true,
                                            });
                                            handleOpenDialogueToProcess();
                                          }}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            Process Now
                                          </span>
                                        </Button>
                                      </div>
                                    ) : list.isSkipped === undefined &&
                                      list.allowProcessNow === undefined ? (
                                      <Button
                                        style={style.skipNow}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            upcomingTrans: list,
                                            isProcess: false,
                                          });
                                          this.props.handleChangeToOpenSkipModal(
                                            true
                                          );
                                          this.props.handleChangeRemarkToSkip(
                                            ""
                                          );
                                        }}
                                      >
                                        <span style={{ fontSize: "10px" }}>
                                          Skip
                                        </span>
                                      </Button>
                                    ) : list.isSkipped === true ? (
                                      <>
                                        <Tag color="orange">Skipped</Tag>
                                        {list.remark ? (
                                          <NotesPopover
                                            content={list.remark}
                                            isRemark
                                          />
                                        ) : null}
                                      </>
                                    ) : (
                                      <div></div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                      </Table>
                    </TableContainer>
                    {sortedUpcomingTransactionList &&
                    sortedUpcomingTransactionList.length > 0 ? (
                      <div
                        style={{
                          justifyContent: "flex-start",
                          width: "100%",
                          borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        <TablePagination
                          labelRowsPerPage={false}
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={
                            sortedUpcomingTransactionList.length > 0
                              ? sortedUpcomingTransactionList.length
                              : 100
                          }
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          backIconButtonProps={{
                            "aria-label": "previous page",
                          }}
                          nextIconButtonProps={{
                            "aria-label": "next page",
                          }}
                          onPageChange={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            padding: "1rem",
                            color: "#606060",
                          }}
                        >
                          {" "}
                          No data to show
                        </h3>
                      </div>
                    )}
                  </div>
                </div>
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Cases" key="3">
                <div className={"d-flex flex-row-reverse div-flex"}>
                  <div>
                    <button
                      className="addButton"
                      // style={{ marginTop: "55px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDialogToRegisterCase();
                      }}
                    >
                      <AddIcon />
                      Register Case
                    </button>
                  </div>
                </div>
                <div className={"row"} style={style.card}>
                  <TableContainer className="fixed-table-header-second">
                    <Table
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                      ref={this.tableRef}
                    >
                      <TableHead style={{ padding: "1rem" }}>
                        <TableRow>
                          <TableCell className="first-tableHeader">
                            Case Id
                          </TableCell>
                          <TableCell className="tableHeader">
                            Register Date
                          </TableCell>
                          <TableCell className="tableHeader">
                            Progress
                          </TableCell>
                          {userRole === "AGENT" ||
                          userType === "CORPORATE_USER" ||
                          userType === "CLIENT_USER" ? null : (
                            <TableCell />
                          )}
                        </TableRow>
                      </TableHead>
                      {caseDetails &&
                        caseDetails
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((singleCase) => {
                            return (
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "40px",
                                      height: "4.5rem",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        case: singleCase,
                                      });
                                      this.props.history.push({
                                        pathname:
                                          "/case/update/" + singleCase.caseId,
                                        dataToPass: this.state.case.caseId,
                                      });
                                    }}
                                  >
                                    {singleCase.caseId}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      // paddingLeft: '32px',
                                      height: "4.5rem",
                                    }}
                                  >
                                    {getDate(singleCase.createdAt)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      height: "4.5rem",
                                      width: "350px",
                                      paddingRight: "3rem",
                                    }}
                                  >
                                    <div style={style.progressDiv}>
                                      <BorderLinearProgress
                                        variant="determinate"
                                        color="secondary"
                                        value={
                                          singleCase.completed
                                            ? singleCase.completed
                                            : 0
                                        }
                                      />
                                      <div style={style.progressData}>
                                        <p>
                                          {singleCase.completed !== undefined
                                            ? Number.isInteger(
                                                singleCase.completed
                                              )
                                              ? singleCase.completed
                                              : singleCase.completed.toFixed(2)
                                            : 0}
                                          %
                                        </p>
                                      </div>
                                    </div>
                                  </TableCell>
                                  {userRole === "AGENT" ||
                                  userType === "CORPORATE_USER" ||
                                  userType === "CLIENT_USER" ? null : (
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "center",
                                        width: "130px",
                                      }}
                                    >
                                      <Tooltip title={"Delete"}>
                                        <DeleteOutlinedIcon
                                          style={{
                                            paddingBottom: 0,
                                            color: "#626262",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              case: singleCase,
                                            });
                                            handleOpenDialogueForDeleteCaseTemplate();
                                          }}
                                        />
                                      </Tooltip>
                                    </TableCell>
                                  )}
                                </TableRow>
                              </TableBody>
                            );
                          })}
                    </Table>
                  </TableContainer>
                  {caseDetails && caseDetails.length > 0 ? (
                    <div
                      style={{
                        justifyContent: "flex-start",
                        width: "100%",
                        borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <TablePagination
                        labelRowsPerPage={false}
                        rowsPerPageOptions={[4, 10, 25, 100]}
                        component="div"
                        count={
                          caseDetails.length > 0 ? caseDetails.length : 100
                        }
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                          "aria-label": "previous page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "next page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "1rem",
                          color: "#606060",
                        }}
                      >
                        {" "}
                        No data to show
                      </h3>
                    </div>
                  )}
                  {/* </TableContainer> */}
                </div>
              </TabPane>
            )}
            <TabPane tab="Files" key="4">
              <DocumentsScreen
                uploadImageFile={uploadImageFile}
                scheduleLoading={scheduleLoading}
                uploadDocumentFile={uploadDocumentFile}
                contactFileData={contactFileData}
                handleChangeContactFile={handleChangeContactFile}
                clientId={this.state.clientId}
                addClientFile={addClientFile}
                fetchContactFilesList={fetchContactFilesList}
                fileResponse={fileResponse}
                contactFileList={contactFileList}
                setContactFileToEdit={setContactFileToEdit}
                updateClientFile={updateClientFile}
                contactState={contactState}
                deleteFile={deleteFile}
                handleShowFileUploadErrorMessage={
                  handleShowFileUploadErrorMessage
                }
                openScheduleTransactionSnackBar={
                  openScheduleTransactionSnackBar
                }
                errorMsg={errorMsg}
                closeScheduleTransactionSnackBar={
                  this.props.closeScheduleTransactionSnackBar
                }
                // closeSnackBar={this.props.closeContactSnackBar}
              />
            </TabPane>

            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Bank details" key="5">
                <div className={"d-flex flex-row-reverse div-flex"}>
                  <div>
                    <button
                      className="addButton"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenDialogueToAddBankDetails();
                        this.props.clearBankDetail();
                        this.props.handleChangeAccountName(
                          clientRes &&
                            clientRes.firstName + " " + clientRes.lastName
                        );
                      }}
                    >
                      <AddIcon />
                      Add bank details
                    </button>
                  </div>
                </div>
                <div className={"row"} style={style.card}>
                  <TableContainer>
                    <Table
                      size="small"
                      stickyHeader
                      aria-label="sticky table"
                      ref={this.tableRef}
                    >
                      <TableHead style={{ padding: "1rem" }}>
                        <TableRow>
                          <TableCell className="first-tableHeader">
                            Bank Name
                          </TableCell>
                          <TableCell className="tableHeader">
                            Bank Account Type
                          </TableCell>
                          <TableCell className="tableHeader">
                            Account holder Name
                          </TableCell>
                          <TableCell className="tableHeader">
                            Routing Number
                          </TableCell>
                          <TableCell className="tableHeader">
                            Account Number
                          </TableCell>
                          <TableCell className="tableHeader">
                            Created Date
                          </TableCell>
                          <TableCell className="tableHeader"></TableCell>
                        </TableRow>
                      </TableHead>
                      {bankDetail &&
                        bankDetail
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((detail) => (
                            <TableBody>
                              <TableRow>
                                {/*<TableCell className="first-tableBody" style={{width : "300px"}}>*/}
                                {/*    {detail.bankDetailId}*/}
                                {/*</TableCell>*/}
                                <TableCell className="first-tableBody">
                                  {detail.bankName}
                                </TableCell>
                                <TableCell className="tableBody">
                                  {(detail.bankAccountType &&
                                    detail.bankAccountType ===
                                      "CHECKING_ACCOUNT") ||
                                  detail.bankAccountType === "SAVING_ACCOUNT"
                                    ? detail.bankAccountType.replace("_", " ")
                                    : "-"}
                                </TableCell>
                                <TableCell className="tableBody">
                                  {detail.accountName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "15px",
                                    color: "#626262",
                                    textAlign: "left",
                                    height: "4.5rem",
                                  }}
                                >
                                  {detail.routingNumber}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "15px",
                                    color: "#626262",
                                    textAlign: "left",
                                    height: "4.5rem",
                                  }}
                                >
                                  {detail.accountNumber}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "15px",
                                    color: "#626262",
                                    textAlign: "left",
                                    // paddingLeft: '32px',
                                    height: "4.5rem",
                                  }}
                                >
                                  {getDate(detail.createdAt)}
                                </TableCell>
                                <TableCell>
                                  <MoreHorizIcon
                                    id="Popover1"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        popoverElement1: e.currentTarget,
                                        openActionMenu1: true,
                                        bankList: detail,
                                      });
                                    }}
                                  />
                                  <Popover
                                    open={this.state.openActionMenu1}
                                    anchorEl={this.state.popoverElement1}
                                    onClose={(e) => {
                                      e.preventDefault();
                                      this.setState({ openActionMenu1: false });
                                    }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    style={{ boxShadow: "none" }}
                                  >
                                    <MenuList
                                      id="popover-list"
                                      style={{
                                        color: "#4d4d4d",
                                        padding: "1rem !important",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <MenuItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleOpenDialogueToUpdateBankDetails();
                                          this.props.setBankDetailToEdit(
                                            this.state.bankList
                                          );
                                          this.setState({
                                            openActionMenu1: false,
                                          });
                                        }}
                                      >
                                        <EditOutlinedIcon
                                          style={{ paddingBottom: 0 }}
                                        />{" "}
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleOpenDialogueToDeleteAccount();
                                          this.setState({
                                            openActionMenu1: false,
                                          });
                                        }}
                                      >
                                        <DeleteOutlinedIcon
                                          style={{ paddingBottom: 0 }}
                                        />{" "}
                                        Delete
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                    </Table>
                  </TableContainer>
                  {bankDetail && bankDetail.length > 0 ? (
                    <div
                      style={{
                        justifyContent: "flex-start",
                        width: "100%",
                        borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                      }}
                    >
                      <TablePagination
                        labelRowsPerPage={false}
                        rowsPerPageOptions={[4, 10, 25, 100]}
                        component="div"
                        count={bankDetail.length > 0 ? bankDetail.length : 100}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                          "aria-label": "previous page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "next page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "1rem",
                          color: "#606060",
                        }}
                      >
                        {" "}
                        No data to show
                      </h3>
                    </div>
                  )}
                  {/* </TableContainer> */}
                </div>
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Calendar" key="6">
                <div className="calendar-wrapper">
                  <Calendar
                    style={{ height: "620px" }}
                    localizer={localizer}
                    events={allEvents}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleOpenModalToAddEvent}
                    messages={{ agenda: "Scheduled Task" }}
                    onView={(e) => {
                      this.setState({
                        currentView: e,
                      });
                    }}
                    popup
                    eventPropGetter={eventStyleGetter}
                  />
                </div>
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Payment Settings" key="7">
                <ClientPaymentSettingScreen
                  clientState={clientState}
                  handleChangeClientMonthlyFee={
                    handleChangeClientMonthlyFeeValue
                  }
                  handleChangeClientFee={handleChangeClientFee}
                  handleChangeClientAnnualFee={handleChangeClientAnnualFee}
                  handleChangeClientSpendDown={handleChangeClientSpendDown}
                  handleChangeClientAchDelayThreshold={
                    handleChangeClientAchDelayThreshold
                  }
                  handleChangeClientInstallment={handleChangeClientInstallment}
                  handleChangeAdmissionDate={handleChangeAdmissionDate}
                  addClientPayment={addClientPayment}
                  clientId={this.state.clientId}
                />
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Notifications Settings" key="8">
                {clientData.accountId !== undefined ? (
                  <AlertNotificationEnableScreen
                    accountId={clientData.accountId}
                    clientState={clientState}
                    handleChangeHolidayAlert={handleChangeHolidayAlert}
                    handleChangeUpcomingAlert={handleChangeUpcomingAlert}
                    saveNotifications={saveNotifications}
                    getAlertInfo={(id) => {
                      this.props.getAlertInfo(id);
                    }}
                  />
                ) : (
                  <div className="onNote">
                    This client is not registered yet!
                  </div>
                )}
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Eligibility Calculation" key="9">
                <div
                  className="row"
                  style={{ height: "500px", marginLeft: "0px" }}
                >
                  <div className="col-6">
                    <EligibilityCalculationComponent
                      caseDetails={caseDetails}
                      isInsideClientDetail={true}
                      proprties={this.props}
                      handleOpenDialogToRegisterCase={
                        handleOpenDialogToRegisterCase
                      }
                      calculateEligibility={calculateEligibility}
                      handleChangeEligibilityData={handleChangeEligibilityData}
                      eligibilityData={eligibilityData}
                      clientId={this.state.clientId}
                      handleChangeWorkFlow={handleChangeWorkFlow}
                      handleChangeAction={handleChangeAction}
                      clearWorkflow={this.props.clearWorkflow}
                      clearAction={this.props.clearAction}
                      workflowId={workflowId}
                      actionId={actionId}
                    />
                  </div>
                </div>
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Notes" key="10">
                <NoteComponent
                  handleChangeNoteDescription={handleChangeNoteDescription}
                  addClientNotes={addClientNotes}
                  updateClientNotes={updateClientNotes}
                  noteData={noteData}
                  clientId={this.state.clientId}
                  fetchNoteById={fetchNoteById}
                  deleteNotes={deleteNotes}
                  notes={notes}
                  isClient={true}
                  clearNoteDescription={this.props.clearNoteDescription}
                  setNotesToEdit={setNotesToEdit}
                />
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Vendor Client" key="11">
                <VendorClientContainer {...this.props} />
              </TabPane>
            )}
            {userType && userType === "CORPORATE_USER" ? (
              ""
            ) : (
              <TabPane tab="Activity Log" key="12">
                <div>
                  <ActivityLogTable
                    fetchActivityLogByClientId={fetchActivityLogByClientId}
                    clientId={this.state.clientId}
                    activityLogs={activityLogValue}
                  />
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>

        {/* Delete transaction  */}
        <Dialog
          open={this.state.openModalToDeleteTransaction}
          onClose={handleCloseToApproveTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to Delete transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleDeleteModalClose}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteModalClose();
                      handleDeleteStatus();
                      // handleOkTransaction(this.state.upcomingTrans);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/**** Update status to approved******/}
        <Dialog
          open={this.state.openModalToApproveTransaction}
          onClose={handleOpenToApproveTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to approve transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseToApproveTransaction}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseToApproveTransaction();
                      handleStatusToApprove();
                      // handleOkTransaction(this.state.upcomingTrans);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/* change transaction status */}
        <Dialog
          open={this.state.openModalToChangeTransStatus}
          onClose={handleCloseChangeTransStatusnModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseChangeTransStatusnModal}
            >
              Update Transaction Status
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-lg-12" hidden={this.state.isDecline}>
                  <label className="label">
                    Status <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input selects h40">
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ value: e });
                        this.props.handleChangeTransStatus(e);
                      }}
                    >
                      <Option hidden />
                      <Option value="TRANSACTION_APPROVED">Approve</Option>
                      <Option value="TRANSACTION_CLOSED">Close</Option>
                    </Select>
                  </div>
                </div>
                {transStatus === "TRANSACTION_CLOSED" ||
                this.state.isDecline ? (
                  <>
                    <div className="col-lg-12">
                      <label className="label">
                        Remark <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="text"
                        id="remark"
                        className="input"
                        onChange={(e) => {
                          e.preventDefault();
                          this.props.handleChangeRemark(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label className="label">New Date</label>
                      <div>
                        <KeyBoardDatePickerComponent
                          dateTimeFormat
                          isCalendarDate
                          dateValue={
                            reversedDate &&
                            getDateWithStartTimeValue(reversedDate)
                          }
                          onChangeDate={(date) => {
                            this.props.handlChangeReverseDate(date);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={
                      this.state.isDecline ? remarkValue : statusChangeValue
                    }
                    buttonName="Update"
                    loading={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdateStatus();
                      handleCloseChangeTransStatusnModal();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*Reset Password*/}
        <Dialog
          open={this.state.openModalToSetPassword}
          onClose={handleCloseDialogueToSetPassword}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToSetPassword}
            >
              Set Password
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <label className="label" style={{ textAlign: "left" }}>
                  Email
                </label>
                <input
                  type="text"
                  className="inputField"
                  id="emailPhone"
                  autoComplete="off"
                  style={style.input}
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeEmail(e.target.id, e.target.value);
                  }}
                />
                <div className="row">
                  <div className={"col-6"} />
                  <div
                    className={"col-6"}
                    style={{ paddingLeft: "72px", paddingTop: "18px" }}
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        // handleCloseSetPasswordDialogue();
                        this.setState({
                          setValue: "set",
                        });
                        const data = {
                          accountType: "EVERFUND_CLIENT",
                          client: {
                            clientId: this.state.clientId,
                            emailPhone: email,
                          },
                        };
                        this.props.setClientPassword(data, this.props);
                      }}
                      disabled={!email}
                      className={!email ? "disabledAddButton" : "addButton"}
                      // className="addButton"
                    >
                      Set Password
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        {/*Delete client*/}
        <Dialog
          open={this.state.openDeleteCaseDialogue}
          onClose={handleCloseDialogueForDeleteCase}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete case?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForDeleteCase}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteCaseDialogue();
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*Delete schedule transaction*/}
        <Dialog
          open={this.state.openDeleteScheduleDialogue}
          onClose={handleCloseDialogueForScheduleTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete schedule transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForScheduleTransaction}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteScheduleTransactionDialogue(clientId);
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to approve*/}
        <Dialog
          open={this.state.openUpdateApproveDialogue}
          onClose={handleCloseUpdateAsApprove}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to approve the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsApprove}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "APPROVED",
                      };
                      handleCloseUpdateAsApprove();
                      handleOkScheduleStatusDialogue(statusData, clientId);
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to scheduling*/}
        <Dialog
          open={this.state.openUpdateScheduleDialogue}
          onClose={handleCloseDialogueForScheduleTransactionUpdate}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to schedule the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForScheduleTransactionUpdate}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "SCHEDULED",
                      };
                      handleOkScheduleStatusDialogue(statusData, clientId);
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to close*/}
        <Dialog
          open={this.state.openUpdateCloseDialogue}
          onClose={handleCloseUpdateAsClose}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to close the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsClose}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "CLOSED",
                      };
                      handleOkScheduleStatusDialogue(statusData, clientId);
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to cancel*/}
        <Dialog
          open={this.state.openUpdateCancelDialogue}
          onClose={handleCloseUpdateAsCancel}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to cancel the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsCancel}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "CANCELLED",
                      };
                      handleOkScheduleStatusDialogue(statusData, clientId);
                      handleCloseUpdateAsCancel();
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*ADD BANK DETAILS*/}
        <Dialog
          open={this.state.openAddBankDetailDialogue}
          onClose={handleCloseDialogueToAddBankDetails}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToAddBankDetails}
            >
              Add Bank Details
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContentBig"
            >
              <div className="row">
                <div className="col-6">
                  <label className="label">
                    Bank Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    autoComplete="off"
                    className="inputField"
                    name="bankName"
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeBankName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label className="label">
                    Bank Account Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input selects h40">
                    <Select
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.props.handleChangeBankAccountType(e);
                      }}
                    >
                      <Option value="CHECKING_ACCOUNT">Checking Account</Option>
                      <Option value="SAVING_ACCOUNT">Savings Account</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label className="label">
                    Account Holder Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="inputField"
                    name="accountName"
                    value={accountName}
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeAccountName(e.target.value);
                    }}
                  />
                  {errors.accountName.length > 0 && (
                    <span className="error">{errors.accountName}</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="label">
                    Account Number
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="inputField"
                    name="bankAccountNumber"
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeAccountNumber(e.target.value);
                    }}
                  />
                  {errors.bankAccountNumber.length > 0 && (
                    <span className="error">{errors.bankAccountNumber}</span>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label className="label">
                    Routing Number
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="inputField"
                    name="routingNumber"
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeRoutingNumber(e.target.value);
                    }}
                  />
                  {errors.routingNumber.length > 0 && (
                    <span className="error">{errors.routingNumber}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "14px" }}>
                  <ButtonComponent
                    value={bankValue}
                    loading={loading}
                    buttonName="Add"
                    onClick={(e) => {
                      e.preventDefault();
                      const data = {
                        clientId: clientId,
                        accountName: accountName,
                        accountNumber: accountNumber,
                        routingNumber: routingNumber,
                        bankName: bankName,
                        bankAccountType: bankAccountType,
                      };
                      if (!this.state.clicked) {
                        this.setState({
                          clicked: true,
                          setValue: "add",
                        });
                        this.props.addClientBankDetails(data, this.props);
                        setTimeout(() => {
                          this.setState({
                            clicked: false,
                          });
                        }, 3000);
                      }
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*REGISTER CASE */}
        <Dialog
          open={this.state.openDialogToRegisterCase}
          onClose={handleCloseDialogToRegisterCase}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogToRegisterCase}
            >
              Register Case
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContentBig"
            >
              <div className="row">
                {caseTemplateState &&
                caseTemplateState.caseTemplate &&
                this.state.caseTemplate &&
                caseTemplateState.caseTemplate.length > 0 &&
                this.state.caseTemplate.length !==
                  caseTemplateState.caseTemplate.length
                  ? this.setState({
                      caseTemplate: caseTemplateState.caseTemplate.map(
                        (template) => ({
                          key: template.caseTemplateId,
                          text: template.templateName,
                          value: template.caseTemplateId,
                        })
                      ),
                    })
                  : null}
                <div className="col-12">
                  <label
                    className="label mb-2"
                    style={{ textAlign: "left", marginBottom: "4px" }}
                  >
                    Select Case Template
                  </label>
                  <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    selectOnBlur={false}
                    id="caseTemplateId"
                    options={this.state.caseTemplate}
                    onChange={(e, data) => {
                      e.preventDefault();
                      this.setState({
                        isBlocking: true,
                        caseTemplateId: data.value,
                        filteredWorkFlow: caseTemplateState.caseTemplate.filter(
                          (s) => s.caseTemplateId === data.value
                        ),
                      });
                      this.props.handleChangeCaseInRegisterCase(
                        "caseTemplateId",
                        data.value
                      );
                    }}
                    style={style.dropdownInput}
                  />
                </div>
              </div>
              {this.state.filteredWorkFlow &&
              this.state.filteredWorkFlow.length > 0 ? (
                <div className="row">
                  <div className="card-body" style={style.cardBody}>
                    {this.state.filteredWorkFlow &&
                      this.state.filteredWorkFlow.length > 0 &&
                      this.state.filteredWorkFlow.map((single) => {
                        return (
                          <div className="row mt-3" style={style.rowFirst}>
                            <div className={"col-6"}>
                              <h6 style={style.columnHeading}>Workflow</h6>
                            </div>
                            <div className="col-6">
                              <h6 style={style.columnHeading}>Assign to</h6>
                            </div>
                          </div>
                        );
                      })}
                    {this.state.filteredWorkFlow &&
                      this.state.filteredWorkFlow.length > 0 &&
                      this.state.filteredWorkFlow.map((single) => {
                        return (
                          <div>
                            {single.workflow &&
                              single.workflow.length > 0 &&
                              single.workflow.map((singleWorkflow) => {
                                return (
                                  <div className="row" style={style.caseRow}>
                                    <div className={"col-6"}>
                                      <label
                                        style={{
                                          color: "rgb(98, 95, 95)",
                                          paddingTop: "16px",
                                          fontWeight: "550",
                                        }}
                                      >
                                        {singleWorkflow.workflowName}
                                      </label>
                                    </div>
                                    <div className="col-6">
                                      <div style={style.select}>
                                        {userRoleAgent &&
                                        this.state.agent &&
                                        userRoleAgent.length > 0 &&
                                        this.state.agent.length !==
                                          userRoleAgent.length
                                          ? this.setState({
                                              agent: userRoleAgent.map(
                                                (singleAgent) => ({
                                                  key: singleAgent.userId,
                                                  text: singleAgent.fullName,
                                                  value: singleAgent.userId,
                                                })
                                              ),
                                            })
                                          : null}
                                        <Dropdown
                                          placeholder="Agent"
                                          fluid
                                          clearable
                                          search
                                          selection
                                          selectOnBlur={false}
                                          id={singleWorkflow.workflowId}
                                          options={this.state.agent}
                                          onChange={(e, data) => {
                                            e.preventDefault();
                                            changeAssignAgents(
                                              singleWorkflow.workflowId,
                                              data.value
                                            );
                                            this.setState({ isBlocking: true });
                                          }}
                                          onAddItem={() => {}}
                                          style={style.dropdownInput}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "25px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        isBlocking: false,
                        filteredWorkFlow: [],
                      });
                      handleCloseDialogToRegisterCase();
                      this.props.registerCase(
                        this.props.state.registerCaseState.caseData,
                        this.props
                      );
                    }}
                    className="addButton"
                  >
                    Register
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*DELETE ACCOUNT DETAILS*/}
        <Dialog
          open={this.state.openDeleteBankDetailDialogue}
          onClose={handleCloseDialogueForScheduleTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent className="dialogContentBig">
            <DialogContentText
              style={{
                background: "white",
                textAlign: "center",
                width: "450px",
              }}
            >
              Do you want to delete this bank account?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToDeleteAccount}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      this.setState({
                        setValue: "delete",
                      });
                      e.preventDefault();
                      handleCloseDialogueToDeleteAccount();
                      this.props.deleteClientBankDetail(
                        this.state.bankList.bankDetailId,
                        clientId,
                        this.props
                      );
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*Update BANK DETAILS*/}
        <Dialog
          open={this.state.openUpdateBankDetailDialogue}
          onClose={handleCloseDialogueToUpdateBankDetails}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToUpdateBankDetails}
            >
              Update Bank Details
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContent"
            >
              <div className="row">
                <div className="col-6">
                  <label className="label">
                    Bank Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    autoComplete="off"
                    className="inputField"
                    name="bankName"
                    value={bankName}
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeBankName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-6">
                  <label className="label">
                    Bank Account Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input selects h40">
                    <Select
                      value={bankAccountType}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.props.handleChangeBankAccountType(e);
                      }}
                    >
                      <Option value="CHECKING_ACCOUNT">Checking Account</Option>
                      <Option value="SAVING_ACCOUNT">Savings Account</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label className="label">
                    Account Holder Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="inputField"
                    name="accountName"
                    value={accountName}
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeAccountName(e.target.value);
                    }}
                  />
                  {errors.accountName.length > 0 && (
                    <span className="error">{errors.accountName}</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="label">
                    Account Number
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="inputField"
                    name="bankAccountNumber"
                    value={accountNumber}
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeAccountNumber(e.target.value);
                    }}
                  />
                  {errors.bankAccountNumber.length > 0 && (
                    <span className="error">{errors.bankAccountNumber}</span>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label className="label">
                    Routing Number
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="inputField"
                    name="routingNumber"
                    value={routingNumber}
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeRoutingNumber(e.target.value);
                    }}
                  />
                  {errors.routingNumber.length > 0 && (
                    <span className="error">{errors.routingNumber}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "14px" }}>
                  <ButtonComponent
                    value={bankValue}
                    loading={loading}
                    buttonName="Update"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        setValue: "update",
                      });
                      const data = {
                        bankDetailId: this.state.bankList.bankDetailId,
                        clientId: clientId,
                        accountName: accountName,
                        accountNumber: accountNumber,
                        routingNumber: routingNumber,
                        bankName: bankName,
                        bankAccountType: bankAccountType,
                      };
                      this.props.updateClientBankDetails(data, this.props);
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*add holiday*/}
        <Dialog
          open={this.state.openModalToAddEvent}
          onClose={handleCloseModalToAddEvent}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseModalToAddEvent}
            >
              Add Holiday/ Event
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                paddingTop: "0px",
                borderBottom: "none",
              }}
              className="dialogContent"
            >
              <div>
                <label className="label">
                  Calendar Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div className="select-input h40">
                  <Select
                    className="inputField"
                    showSearch={false}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.props.handleChangeCalendarType(e);
                    }}
                  >
                    <Option value="HOLIDAY">Holiday</Option>
                    <Option value="EVENT">Event</Option>
                  </Select>
                </div>
              </div>
              <div style={{ marginTop: "12px" }}>
                <label className="label">
                  Holiday/ Event Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="holiday"
                  className="input"
                  autoComplete="off"
                  onChange={(e) => {
                    this.props.handleChangeHolidayName(e.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Start Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-dob">
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={
                        this.state.start &&
                        getFormattedDateTimeValue(this.state.start)
                      }
                      onChangeDate={(date) => {
                        this.setState({
                          start: date,
                        });
                      }}
                    />
                    {/*<DatePicker*/}
                    {/*  className="rangeBase"*/}
                    {/*  value={*/}
                    {/*    this.state.start && getDateFromPicker(this.state.start)*/}
                    {/*  }*/}
                    {/*  showTime={{*/}
                    {/*    defaultValue: moment("00:00:00", "HH:mm:ss"),*/}
                    {/*  }}*/}
                    {/*  dropdownClassName="client-calendar-holiday-start-datepicker"*/}
                    {/*  placeholder="YYYY-MM-DD HH:mm:ss"*/}
                    {/*  getPopupContainer={(trigger) => trigger.parentNode}*/}
                    {/*  onChange={handleChangeStartDate}*/}
                    {/*  showToday={false}*/}
                    {/*/>*/}
                  </div>
                </div>
                <div className="col-lg-12" style={{ marginTop: "12px" }}>
                  <label className="label">
                    End Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-dob">
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={
                        this.state.end &&
                        getFormattedDateTimeValue(this.state.end)
                      }
                      onChangeDate={(date) => {
                        this.setState({
                          end: date,
                        });
                        // this.props.handleChangeStartDate(date);
                      }}
                    />
                    {/*<DatePicker*/}
                    {/*  className="rangeBase"*/}
                    {/*  value={*/}
                    {/*    this.state.end && getDateFromPicker(this.state.end)*/}
                    {/*  }*/}
                    {/*  dropdownClassName="client-calendar-holiday-start-datepicker"*/}
                    {/*  placeholder="YYYY-MM-DD HH:mm:ss"*/}
                    {/*  showTime={{*/}
                    {/*    defaultValue: moment("00:00:00", "HH:mm:ss"),*/}
                    {/*  }}*/}
                    {/*  getPopupContainer={(trigger) => trigger.parentNode}*/}
                    {/*  onChange={handleChangeEndDate}*/}
                    {/*  showToday={false}*/}
                    {/*/>*/}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "14px" }}>
                  <Button
                    style={{ float: "right" }}
                    disabled={!value}
                    className={value ? "addButton" : "disabledAddButton"}
                    onClick={(e) => {
                      e.preventDefault();
                      const data = {
                        calendarType: calendarType,
                        eventName: holidayName,
                        startDate: this.state.start,
                        endDate: this.state.end,
                      };
                      this.props.addHolidays(data, clientId, this.props);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*UPDATE CLIENTS*/}
        <Dialog
          open={openClientFormModal}
          onClose={() => {
            this.props.handleChangeToOpenClientFormModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                this.props.handleChangeToOpenClientFormModal(false);
              }}
            >
              Update Client
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ maxHeight: "630px" }}
              className="dialogContentWidth850"
            >
              <div className="row mt-3">
                <div className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    First Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="firstName"
                    autoComplete="off"
                    className="inputField"
                    value={clientData && clientData.firstName}
                    name="firstName"
                    onChange={(e) => {
                      e.preventDefault();
                      // Helpers.handleValidationOnChange(e, errors);
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {/* {errors.firstName.length > 0 &&
                                    <span className='error'>{errors.firstName}</span>} */}
                </div>
                <div className="col-4">
                  <label htmlFor="lastName" style={style.columnHeading}>
                    Last Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    value={clientData && clientData.lastName}
                    className="inputField"
                    autoComplete="off"
                    onChange={(e) => {
                      // Helpers.handleValidationOnChange(e, errors);
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {/* {errors.lastName.length > 0 &&
                                    <span className='error'>{errors.lastName}</span>} */}
                </div>
                <div className="col-4" style={{ position: "inherit" }}>
                  <label style={style.columnHeading}>
                    Date-of-birth
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-add-date">
                    <KeyBoardDatePickerComponent
                      dateValue={
                        clientData && getFormattedDateValue(clientData.dob)
                      }
                      onChangeDate={(data) => {
                        this.props.handleChangeClient("dob", data);
                      }}
                    />
                    {/* <DatePicker
                      value={clientData && getDateValue(clientData.dob)}
                      getPopupContainer={(node) => node.parentNode}
                      format="MM-DD-YYYY"
                      // onChange={handleChangeEditDate}
                    /> */}
                  </div>
                  {/* {errors.dob.length > 0 &&
                                    <span className='error'>{errors.dob}</span>} */}
                </div>
              </div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4">
                  <label htmlFor="gender" style={style.columnHeading}>
                    Gender
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="contactType"
                      showSearch={false}
                      value={clientData && clientData.gender}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClient("gender", e);
                      }}
                    >
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Marital Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="maritalStatus"
                      showSearch={false}
                      value={clientData && clientData.maritalStatus}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClient("maritalStatus", e);
                      }}
                    >
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    County
                  </label>
                  <input
                    type="text"
                    id="county"
                    className="inputField"
                    value={clientData && clientData.county}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4" style={{ position: "inherit" }}>
                  <Checkbox
                    id="isEnableTransaction"
                    checked={!!(clientData && clientData.isEnableTransaction)}
                    value={clientData && clientData.isEnableTransaction}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.checked
                      );
                    }}
                    style={style.columnHeading}
                  >
                    Enable Transaction
                  </Checkbox>
                </div>
              </div>
              {!(this.state.contacts && this.state.contacts.length > 0) ? (
                <div
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForContactField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    Add Contact
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenContactFields
                    fieldArray={this.state.contacts}
                    isEdit={true}
                    filterEditContactData={filterEditContactData}
                    handleOpenDeleteDialogue={handleOpenDeleteContactsDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForContactField();
                      }}
                    >
                      {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                      {/*     alt="Add Button"*/}
                      {/*     className="addButtonIcon"/>*/}
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  padding: "0.5rem 0px 0.5rem",
                  float: "right",
                  marginRight: "7px",
                }}
              >
                <div></div>
                <div>
                  <ButtonComponent
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: false });
                      var clientData = this.props.state.clientState.clientData;
                      if (!this.state.clicked) {
                        this.setState({
                          clicked: true,
                          setValue: "add",
                        });
                        this.props.editClient(clientData, this.props);
                        setTimeout(() => {
                          this.setState({
                            clicked: false,
                          });
                        }, 3000);
                      }
                    }}
                    buttonName="Update"
                    noValue
                    // loading={this.props.state.clientState.loading}
                    // disabled={!clientDataValue}
                    // className={clientDataValue ? "addButton" : "disabledAddButton"}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*DELETE CLIENT CONTACT*/}
        <Dialog
          open={this.state.openDeleteContactDialogue}
          onClose={handleCloseDeleteContactsDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete contact?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteContactsDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteContactsDialogue();
                      }}
                      style={style.deleteCaseTemplateButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        {/*FILTER FOR TRANSACTION*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openSearchDialogue}
          maxWidth="lg"
          onClose={handleChangeCloseSearchDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseSearchDialogue}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              paddingLeft: "1.5rem !important",
              paddingRight: "1.5rem !important",
              width: "450px",
              paddingTop: "5px",
            }}
          >
            <div className="row mt-3">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeTransactionDateFrom(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeTransactionDateTo(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeTransactionDateFrom(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeTransactionDateTo(date);
              }}
            />
            <div className="mt-3">
              <label style={style.label}> Medium </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterMediumData}
                isItemCheck={this.state.isMediumCheck}
                isAllCheck={this.state.isAllMediumChecked}
                handleAllCheckbox={this.handleAllMediumCheckbox}
                handleSelectedChange={this.handleSelectedMediumChange}
              />
            </div>
            <div className="mt-3">
              <label style={style.label}> Status </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterStatusData}
                isItemCheck={this.state.isStatusCheck}
                isAllCheck={this.state.isALlChecked}
                handleAllCheckbox={this.handleAllCheckbox}
                handleSelectedChange={this.handleSelectedChange}
              />
            </div>
            <div className="mt-3">
              <label style={style.label}> Type </label>
              {userType && userType === "CORPORATE_USER" ? (
                <input
                  className="inputField"
                  value={this.state.corporateTransactionType}
                />
              ) : (
                <CheckboxFilterPopover
                  // forMappingFilterData={
                  //   this.state.isMediumCheck[0] === "Ledger"
                  //     ? FilterTypeDataForLedger
                  //     : FilterUPTypeData
                  // }
                  forMappingFilterData={allTypeArray}
                  isItemCheck={this.state.isUPTypeCheck}
                  isAllCheck={this.state.isAllUPTypeCheked}
                  // isItemCheck={
                  //   this.state.isMediumCheck[0] === "Ledger"
                  //     ? this.state.isLedCaseTypeCheck
                  //     : this.state.isUPTypeCheck
                  // }
                  // isAllCheck={
                  //   this.state.isMediumCheck[0] === "Ledger"
                  //     ? this.state.isAllLedCaseTypeChecked
                  //     : this.state.isAllUPTypeCheked
                  // }
                  handleAllCheckbox={this.handleAllUPTypeCheckbox}
                  handleSelectedChange={this.handleSelectedUPTypeChange}
                  // handleAllCheckbox={
                  //   this.state.isMediumCheck[0] === "Ledger"
                  //     ? this.handleAllLedCaseTypeCheckbox
                  //     : this.handleAllUPTypeCheckbox
                  // }
                  // handleSelectedChange={
                  //   this.state.isMediumCheck[0] === "Ledger"
                  //     ? this.handleSelectedLedCaseTypeChange
                  //     : this.handleSelectedUPTypeChange
                  // }
                />
              )}
            </div>
            <div className="mt-3">
              <label style={style.label}> Vendor </label>
              <div className="select-input h40">
                <Select
                  style={style.inputField}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(node) => node.parentNode}
                  // style={{ width: 200 }}
                  onChange={(e) => {
                    this.props.handleChangeVendor(e);
                  }}
                >
                  {vendorList &&
                    vendorList.map((list) => (
                      <Option value={list.vendorId}>{list.vendorName}</Option>
                    ))}
                </Select>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  {userType && userType === "CORPORATE_USER" ? (
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          page: 0,
                        });
                        this.props.searchClientTransactionByAll(
                          // mediumType,
                          // txnStatus,
                          transactionMediumHelper(this.state.isMediumCheck),
                          transactionStatusHelper(this.state.isStatusCheck),
                          this.state.corporateTransactionType,
                          startDate,
                          endDate,
                          vendorToFilter,
                          clientId
                        );
                        handleChangeCloseSearchDialogue();
                      }}
                      className="addButton"
                    >
                      Filter
                    </Button>
                  ) : (startDate === null || startDate === 0) &&
                    (endDate === null || endDate === 0) &&
                    vendorToFilter === "" &&
                    arraysEqual(
                      this.state.isStatusCheck,
                      FilterStatusData.map((status) => status.name)
                    ) &&
                    arraysEqual(
                      this.state.isTypeCheck,
                      FilterTypeData.map((type) => type.name)
                    ) &&
                    arraysEqual(
                      this.state.isMediumCheck,
                      FilterMediumData.map((medium) => medium.name)
                    ) ? (
                    <>
                      <Button
                        style={{ float: "right" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            page: 0,
                          });
                          this.props.getClientTransaction(clientId);
                          handleChangeCloseSearchDialogue();
                        }}
                        // disabled={!filteredValue}
                        // className={
                        //   filteredValue ? "addButton" : "disabledAddButton"}
                        className="addButton"
                      >
                        Filter
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          page: 0,
                        });
                        this.props.searchClientTransactionByAll(
                          // mediumType,
                          // txnStatus,
                          transactionMediumHelper(this.state.isMediumCheck),
                          transactionStatusHelper(this.state.isStatusCheck),
                          this.state.isMediumCheck[0] === "Ledger"
                            ? transactionTypeHelper(
                                this.state.isLedCaseTypeCheck,
                                "type"
                              )
                            : transactionTypeHelper(
                                this.state.isUPTypeCheck,
                                "type"
                              ),
                          // transactionType,
                          startDate,
                          endDate,
                          vendorToFilter,
                          clientId
                        );
                        handleChangeCloseSearchDialogue();
                      }}
                      // disabled={!filteredValue}
                      // className={
                      //   filteredValue ? "addButton" : "disabledAddButton"}
                      className="addButton"
                    >
                      Filter
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*General transaction table modal*/}
        <Dialog
          open={this.state.openGeneralTransactionModal}
          onClose={handleCloseGeneralTransactionModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseGeneralTransactionModal}
            >
              All Transactions
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContentForLongModal"
            >
              <div className="row">
                <div
                  className="col-lg-6"
                  style={{
                    borderRight: "1px solid #8080803b",
                  }}
                >
                  <div className="table-title">Transaction Table </div>
                  <div className="card-box">
                    <TableContainer>
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        ref={this.tableRef}
                      >
                        <TableHead style={{ padding: "1rem" }}>
                          <TableRow>
                            <TableCell className="first-tableHeader">
                              Transaction Date
                            </TableCell>
                            <TableCell className="tableHeader"></TableCell>
                            <TableCell className="tableHeader">
                              Description
                            </TableCell>
                            <TableCell className="tableHeaderAmount">
                              Debits
                            </TableCell>
                            <TableCell className="tableHeaderAmount">
                              Credits
                            </TableCell>
                            <TableCell className="tableHeaderAmount">
                              Balance
                            </TableCell>
                            <TableCell className="tableHeader">
                              Ref / Check No.
                            </TableCell>
                            <TableCell className="tableHeader">
                              Vendor
                            </TableCell>
                            <TableCell className="tableHeader">
                              Medium
                            </TableCell>
                            <TableCell className="tableHeader">Type</TableCell>
                            <TableCell className="tableHeader">
                              Status
                            </TableCell>
                            {/* <TableCell className="tableHeader">
                              Scheduled Date/By
                            </TableCell> */}
                          </TableRow>
                        </TableHead>
                        {clientTransaction &&
                          clientTransaction
                            .slice(
                              this.state.transactionPage *
                                this.state.transactionRowPerPage,
                              this.state.transactionPage *
                                this.state.transactionRowPerPage +
                                this.state.transactionRowPerPage
                            )
                            .map((list) => (
                              <TableBody key={list.transactionId}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "40px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    {new Intl.DateTimeFormat("default", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(list.transactionTimestamp)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      paddingRight: "0px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {list.note ? (
                                      <NotesPopover content={list.note} />
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.description ? list.description : "-"}
                                  </TableCell>
                                  <TableCell className="tableAmountBody">
                                    {list.amount
                                      ? list.amount &&
                                        list.amount.charAt(0) === "-"
                                        ? formatter
                                            .format(list.amount / 100)
                                            .substring(1)
                                        : "-"
                                      : formatter.format(0)}
                                  </TableCell>
                                  <TableCell className="tableAmountBody">
                                    {list.amount
                                      ? list.amount &&
                                        list.amount.charAt(0) === "-"
                                        ? "-"
                                        : formatter.format(list.amount / 100)
                                      : formatter.format(0)}
                                  </TableCell>
                                  <TableCell className="tableAmountBody">
                                    {list.balance
                                      ? formatter.format(list.balance / 100)
                                      : formatter.format(0)}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      height: "4.5rem",
                                      // paddingLeft: '47px',
                                      paddingRight: 0,
                                    }}
                                  >
                                    {list.refId}
                                    <span>
                                      {list.checkNo
                                        ? " / " + list.checkNo + " "
                                        : ""}
                                    </span>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    {list.vendorAccount.vendorName
                                      ? list.vendorAccount.vendorName
                                      : "-"}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    {list.transactionMedium.replace("_", " ")}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                    }}
                                  >
                                    {list.transactionType.replace("_", " ")}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontSize: "15px",
                                      color: "#626262",
                                      textAlign: "left",
                                      paddingLeft: "18px",
                                      height: "4.5rem",
                                      cursor: "auto",
                                      width: "10%",
                                    }}
                                  >
                                    <>
                                      <Tag
                                        color={
                                          list.transactionStatus ===
                                          "TRANSACTION_APPROVED"
                                            ? "green"
                                            : list.transactionStatus ===
                                              "TRANSACTION_REVERSED"
                                            ? "red"
                                            : list.transactionStatus ===
                                              "TRANSACTION_CREATED"
                                            ? "purple"
                                            : list.transactionStatus ===
                                              "TRANSACTION_SUBMITTED"
                                            ? "blue"
                                            : list.transactionStatus ===
                                              "TRANSACTION_BLOCKED"
                                            ? "yellow"
                                            : list.transactionStatus ===
                                              "TRANSACTION_RETURNED"
                                            ? "geekblue"
                                            : list.transactionStatus ===
                                              "TRANSACTION_POSTED"
                                            ? "cyan"
                                            : list.transactionStatus ===
                                              "TRANSACTION_SKIPPED"
                                            ? "orange"
                                            : list.transactionStatus ===
                                              "TRANSACTION_ON_HOLD"
                                            ? "volcano"
                                            : list.transactionStatus ===
                                              "TRANSACTION_CANCELLED"
                                            ? "gold"
                                            : list.transactionStatus ===
                                              "TRANSACTION_CLOSED"
                                            ? "magenta"
                                            : "lime"
                                        }
                                      >
                                        {list.transactionStatus.replace(
                                          "TRANSACTION_",
                                          " "
                                        )}
                                      </Tag>
                                      {list.remark !== undefined ? (
                                        <NotesPopover
                                          content={list.remark}
                                          isRemark
                                        />
                                      ) : null}
                                    </>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                      </Table>
                      {clientTransaction && clientTransaction.length > 0 ? (
                        <div
                          style={{
                            justifyContent: "flex-start",
                            width: "100%",
                          }}
                        >
                          <TablePagination
                            labelRowsPerPage={false}
                            rowsPerPageOptions={[8, 25, 100]}
                            component="div"
                            count={
                              clientTransaction.length > 0
                                ? clientTransaction.length
                                : 100
                            }
                            rowsPerPage={this.state.transactionRowPerPage}
                            page={this.state.transactionPage}
                            backIconButtonProps={{
                              "aria-label": "previous page",
                            }}
                            nextIconButtonProps={{
                              "aria-label": "next page",
                            }}
                            onChangePage={handleChangeTransactionPage}
                            onChangeRowsPerPage={
                              handleChangeTransactionRowsPerPage
                            }
                          />
                        </div>
                      ) : (
                        <div>
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              padding: "1rem",
                              color: "#606060",
                            }}
                          >
                            {" "}
                            No data to show
                          </h3>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="table-title">Upcoming Transaction Table </div>
                  <div className="card-box">
                    <TableContainer>
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        ref={this.tableRef}
                      >
                        <TableHead style={{ padding: "1rem" }}>
                          <TableRow>
                            <TableCell
                              className="first-tableHeader"
                              style={{ width: "230px" }}
                            >
                              Transaction Date
                            </TableCell>
                            <TableCell className="tableHeader">
                              Description
                            </TableCell>
                            <TableCell className="tableHeaderAmount">
                              Amount
                            </TableCell>
                            <TableCell className="tableHeader">
                              Vendor
                            </TableCell>
                            <TableCell className="tableHeader">
                              Medium
                            </TableCell>
                            <TableCell className="tableHeader">Type</TableCell>
                          </TableRow>
                        </TableHead>
                        {clientUpcomingTransaction &&
                          clientUpcomingTransaction
                            .slice(
                              this.state.upcomingTransactionPage *
                                this.state.upcomingTransactionRowPerPage,
                              this.state.upcomingTransactionPage *
                                this.state.upcomingTransactionRowPerPage +
                                this.state.upcomingTransactionRowPerPage
                            )
                            .map((list) => (
                              <TableBody>
                                <TableRow
                                  style={
                                    (list.transactionType === "DISBURSEMENT" ||
                                      list.transactionType === "FEE") &&
                                    list.isFundAvailable === undefined
                                      ? {
                                          cursor: "pointer",
                                          background: "#fff1f0",
                                        }
                                      : { cursor: "pointer" }
                                  }
                                >
                                  <TableCell className="first-tableBody">
                                    {new Intl.DateTimeFormat("default", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(list.upcomingTxnDate)}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.description}
                                  </TableCell>
                                  <TableCell className="tableAmountBody">
                                    {list.amount &&
                                      formatter.format(list.amount / 100)}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.vendorAccount.vendorName}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.transactionMedium}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.transactionType}
                                  </TableCell>
                                  {/* <TableCell className="">
                                    {list.scheduleDetail ? (
                                      <div>
                                        <div>
                                          {list.scheduleDetail &&
                                            new Intl.DateTimeFormat("en-US", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                            }).format(
                                              list.scheduleDetail.scheduledDate
                                                ? list.scheduleDetail
                                                    .scheduledDate
                                                : "-"
                                            )}
                                        </div>
                                        <div style={{ marginTop: "5px" }}>
                                          {list.scheduleDetail
                                            .scheduleByName ? (
                                            <>
                                              <Avatar
                                                style={{
                                                  width: "28px",
                                                  height: "28px",
                                                  marginRight: "8px",
                                                }}
                                                src={
                                                  list.scheduleDetail.profileUrl
                                                }
                                              />
                                              <span>
                                                {
                                                  list.scheduleDetail
                                                    .scheduleByName
                                                }
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell> */}
                                  {/* <TableCell>
                                    {list.isSkipped === undefined &&
                                    list.allowProcessNow === true ? (
                                      <div style={{ display: "flex" }}>
                                        <Button
                                          style={style.skipNow}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              upcomingTrans: list,
                                              isProcess: false,
                                            });
                                            this.props.handleChangeToOpenSkipModal(
                                              true
                                            );
                                            this.props.handleChangeRemarkToSkip(
                                              ""
                                            );
                                          }}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            Skip
                                          </span>
                                        </Button>
                                        <Button
                                          style={style.processNow}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              upcomingTrans: list,
                                              isProcess: true,
                                            });
                                            handleOpenDialogueToProcess();
                                          }}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            Process Now
                                          </span>
                                        </Button>
                                      </div>
                                    ) : list.isSkipped === undefined &&
                                      list.allowProcessNow === undefined ? (
                                      <Button
                                        style={style.skipNow}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            upcomingTrans: list,
                                            isProcess: false,
                                          });
                                          this.props.handleChangeToOpenSkipModal(
                                            true
                                          );
                                          this.props.handleChangeRemarkToSkip(
                                            ""
                                          );
                                        }}
                                      >
                                        <span style={{ fontSize: "10px" }}>
                                          Skip
                                        </span>
                                      </Button>
                                    ) : list.isSkipped === true ? (
                                      <>
                                        <Tag color="yellow">Skipped</Tag>
                                        {list.remark ? (
                                          <NotesPopover
                                            content={list.remark}
                                            isRemark
                                          />
                                        ) : null}
                                      </>
                                    ) : (
                                      <div></div>
                                    )}
                                  </TableCell> */}
                                </TableRow>
                              </TableBody>
                            ))}
                      </Table>
                      {clientUpcomingTransaction &&
                      clientUpcomingTransaction.length > 0 ? (
                        <div
                          style={{
                            justifyContent: "flex-start",
                            width: "100%",
                          }}
                        >
                          <TablePagination
                            labelRowsPerPage={false}
                            rowsPerPageOptions={[8, 25, 100]}
                            component="div"
                            count={
                              clientUpcomingTransaction.length > 0
                                ? clientUpcomingTransaction.length
                                : 100
                            }
                            rowsPerPage={
                              this.state.upcomingTransactionRowPerPage
                            }
                            page={this.state.upcomingTransactionPage}
                            backIconButtonProps={{
                              "aria-label": "previous page",
                            }}
                            nextIconButtonProps={{
                              "aria-label": "next page",
                            }}
                            onChangePage={handleChangeUpcomingTransactionPage}
                            onChangeRowsPerPage={
                              handleChangeUpcomingTransactionRowsPerPage
                            }
                          />
                        </div>
                      ) : (
                        <div>
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              padding: "1rem",
                              color: "#606060",
                            }}
                          >
                            {" "}
                            No data to show
                          </h3>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/******* SCHEDULE TRANSACTION ********/}
        <ScheduleNewTransaction
          openModal={
            scheduleState && scheduleState.openModalToScheduleTransaction
          }
          clientTransaction={clientTransaction}
          clientData={clientData}
          setOpenModalToScheduleTransaction={(bool) => {
            this.props.setOpenModalToScheduleTransaction(bool);
          }}
          scheduleTransactionState={scheduleState}
          transactionState={
            this.props.state && this.props.state.transactionState
          }
          vendorState={this.props.state && this.props.state.vendorState}
          settingState={this.props.state && this.props.state.settingState}
          isUpdate={this.state.isUpdate}
          handleChangeScheduleTransactionInputData={(id, value) => {
            this.props.handleChangeScheduleTransactionInputData(id, value);
          }}
          handleChangeTransactionType={(type) => {
            this.props.handleChangeTransactionType(type);
          }}
          handleChangeTransactionMedium={(medium) => {
            this.props.handleChangeScheduleTransactionMedium(medium);
          }}
          setButtonDisable={(bool) => {
            setButtonDisable(bool);
          }}
          buttonDisable={this.state.buttonDisable}
          updateScheduleTransaction={(requestBody) => {
            updateScheduleTransaction(requestBody);
          }}
          addNewScheduleTransaction={(requestBody) => {
            addNewScheduleTransaction(requestBody);
          }}
          errors={scheduleError}
          uploadDocumentFile={(fileData) => {
            this.props.uploadDocumentFile(fileData, this.props);
          }}
          handleShowFileUploadErrorMessage={(bool) => {
            this.props.handleShowFileUploadErrorMessage(bool);
          }}
          clientName={clientNameForSchedule}
          fetchSingleClient={() => {
            this.props.fetchSingleClient(clientId, this.props);
          }}
        />
        {/******  CREATE TRANSACTION ******/}
        <CreateTransactionForm
          setOpenCreateTransaction={setOpenCreateTransaction}
          transactionState={transactionState}
          clientData={clientData}
          transactionClientDetail={transactionClientDetail}
          handleChangeTransData={(id, value) => {
            this.props.handleChangeTransData(id, value);
          }}
          vendorAccount={
            this.props &&
            this.props.state.vendorState &&
            this.props.state.vendorState.vendorAccount
          }
          vendorState={this.props.state && this.props.state.vendorState}
          settingState={this.props.state && this.props.state.settingState}
          scheduleTransactionState={
            this.props.state && this.props.state.scheduleTransactionState
          }
          uploadDocumentFile={(fileData) => {
            this.props.uploadDocumentFile(fileData, this.props);
          }}
          createTransData={(data) => {
            this.props.createTransData(data, this.props);
          }}
          handleShowFileUploadErrorMessage={(bool) => {
            this.props.handleShowFileUploadErrorMessage(bool);
          }}
          fetchSingleClient={() => {
            this.props.fetchSingleClient(this.state.clientId, this.props);
          }}
          isUpdate={this.state.isTransUpdate}
          updateClientTransData={(data) => {
            this.props.updateClientTransData(data, this.props);
          }}
        />

        {/* Trnx Info Model */}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openTrnxInfoDialogue}
          onClose={handleCloseDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogue}
          >
            Transaction Detail
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              paddingTop: "25px",
              paddingBottom: "25px",
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Reference Id
                  </label>
                  <label style={style.clientData}>
                    {this.state.trans && this.state.trans.refId}
                  </label>
                </span>
                <span className="col-4">
                  <label style={style.columnHeading}>Debits (Dollar)</label>
                  <label style={style.clientData}>
                    {this.state.trans && this.state.trans.amount
                      ? this.state.trans.amount.charAt(0)
                        ? formatter
                            .format(this.state.trans.amount / 100)
                            .substring(1)
                        : "-"
                      : formatter.format(0)}
                  </label>
                </span>
                <span className="col-4">
                  <label style={style.columnHeading}>Credit (Dollar)</label>
                  <label style={style.clientData}>
                    {this.state.trans && this.state.trans.amount
                      ? this.state.trans.amount.charAt(0)
                        ? "-"
                        : formatter.format(this.state.trans.amount / 100)
                      : formatter.format(0)}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Client Name
                  </label>
                  <label style={style.extraData}>
                    {this.state.trans &&
                      this.state.trans.Client &&
                      this.state.trans.Client.firstName +
                        " " +
                        this.state.trans.Client.lastName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Vendor Name
                  </label>
                  <label style={style.clientData}>
                    {this.state.trans &&
                    this.state.trans.vendorAccount &&
                    this.state.trans.vendorAccount.vendorName
                      ? this.state.trans.vendorAccount.vendorName
                      : "-"}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Transaction Medium
                  </label>
                  <label style={style.clientData}>
                    {this.state.trans &&
                      this.state.trans.transactionMedium &&
                      this.state.trans.transactionMedium.replace("_", " ")}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span
                  className="col-4"
                  hidden={
                    this.state.trans && this.state.trans.checkNo === undefined
                  }
                >
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Check No.
                  </label>
                  <label style={style.extraData}>
                    {this.state.trans && this.state.trans.checkNo}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Transaction Type
                  </label>
                  <label style={style.extraData}>
                    {this.state.trans && this.state.trans.transactionType}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Created Date
                  </label>
                  <label style={style.clientData}>
                    {this.state.trans &&
                      this.state.trans.createdAt &&
                      new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(this.state.trans.createdAt || "-")}
                  </label>
                </span>
                <span
                  className={
                    this.state.trans && this.state.trans.checkNo
                      ? "col-4 mt-3"
                      : "col-4"
                  }
                >
                  <label htmlFor="dob" style={style.columnHeading}>
                    Transaction Date
                  </label>
                  <label style={style.clientData}>
                    {this.state.trans &&
                      this.state.trans.transactionTimestamp &&
                      new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(this.state.trans.transactionTimestamp || "-")}
                  </label>
                </span>
                <span className="col-4 mt-3">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Description
                  </label>
                  <label style={style.extraData}>
                    {this.state.trans && this.state.trans.description
                      ? this.state.trans.description
                      : "-"}
                  </label>
                </span>
              </span>
              <span
                className="row"
                hidden={!(this.state.trans && this.state.trans.note)}
              >
                <div
                  style={{
                    marginLeft: " 240px",
                    marginTop: "20px",
                    fontSize: "17px",
                  }}
                >
                  <span>
                    Note :{this.state.trans && this.state.trans.note}{" "}
                  </span>
                </div>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*modal to undo reverse */}
        <Dialog
          open={this.state.openModalToUndoReverse}
          onClose={handleChangeCloseModalToUndoReverse}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseModalToUndoReverse}
            >
              Undo Reverse
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Remark <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeRemark(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={declineStatus}
                    buttonName="Undo"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseModalToUndoReverse();
                      handleUpdateStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*modal to decline check*/}
        <Dialog
          open={this.state.openModalToDeclineCheck}
          onClose={handleChangeCloseModalToDecline}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseModalToDecline}
            >
              Decline Check
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Remark <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeRemark(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={declineStatus}
                    buttonName="Decline"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseModalToDecline();
                      handleUpdateCheckStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/******* ACTIVITY LOG ***********/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openActivityLogModal}
          onClose={handleCloseActivityLogModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseActivityLogModal}
          >
            Changelog Info
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{ width: "726px", maxHeight: "450px", paddingTop: "25px" }}
          >
            {activityLogValue && activityLogValue.length > 0 ? (
              activityLogValue.map((logValue) => (
                <div className="changeLogOuterDiv">
                  <div>
                    <Avatar
                      className="changeLogAvatar"
                      src={
                        logValue.profilePic
                          ? logValue.profilePic
                          : "/assets/images/profile.svg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "25px", fontSize: "16px" }}>
                    <span style={{ fontWeight: 600 }}>
                      {logValue.userName ? logValue.userName : "User"}
                    </span>{" "}
                    {logValue.activity === "transaction.updated"
                      ? "updated"
                      : logValue.activity === "transaction.added"
                      ? "added"
                      : "changed"}{" "}
                    <span style={{ fontWeight: 600 }}>
                      {logValue.activity === "transaction.note.updated"
                        ? "transaction note as"
                        : logValue.activity === "transaction.updated" ||
                          logValue.activity === "transaction.added"
                        ? "transaction"
                        : "transaction status to"}
                    </span>{" "}
                    <span>
                      {logValue.activity ===
                      "transaction.status.updated.to APPROVED"
                        ? `'APPROVED'`
                        : logValue.activity ===
                          "transaction.status.updated.to REVERSED"
                        ? "REVERSED"
                        : logValue.refValue &&
                          logValue.refValue.charAt(0) === "{"
                        ? ""
                        : `"${logValue.refValue}"`}{" "}
                      {/* {logValue.activity ===
                      "transaction.status.updated.to APPROVED"
                        ? `from "${logValue.refValue}"`
                        : ""} */}
                    </span>
                    {logValue.refValue &&
                      logValue.refValue.charAt(0) === "{" &&
                      logValue.activity === "transaction.updated" && (
                        <>
                          <br />
                          <ChangedLogPopover
                            refValue={logValue.refValue}
                            formatter={formatter}
                          />
                        </>
                      )}
                    <div style={{ fontSize: "14px" }}>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(logValue.createdAt)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>
                  <img src="/assets/images/activityLog1.svg" alt="" />
                </div>
                <div>No changes till date !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        {/* dialog to undo reverse transaction */}
        <Dialog
          open={this.state.openConfirmationToUndoReverse}
          onClose={handleCloseUndoReverse}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to undo this reversed transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUndoReverse}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseUndoReverse();
                      // handleCloseChangeTransStatusModal();
                      handleUpdateStatus();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*add/update notes modal*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={openNotesModalForClient}
          maxWidth="lg"
          onClose={() => {
            this.props.handleChangeToOpenNoteModalForClient(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              this.props.handleChangeToOpenNoteModalForClient(false);
            }}
          >
            {!this.state.isUpdate ? "Add Note" : "Update Note"}
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div>
              <div className="row">
                <div className="col-12 mt-4">
                  <label htmlFor="amount" style={style.columnHeading}>
                    Note
                    <span style={{ color: "red" }}> *</span>{" "}
                  </label>
                  <input
                    id="remark"
                    name="remark"
                    autoComplete="off"
                    className="inputField"
                    value={notes}
                    onChange={(e) => {
                      this.setState({
                        isBlocking: true,
                      });
                      this.props.handleChangeToNotesForClient(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const noteData = {
                        transactionId: this.state.trans.transactionId,
                        note: notes,
                      };
                      this.props.addNoteForTransactions(
                        noteData,
                        clientId,
                        this.props
                      );
                    }}
                    loading={clientState && clientState.loading}
                    disabled={!notesValue}
                    className={notesValue ? "addButton" : "disabledAddButton"}
                  >
                    {!this.state.isUpdate ? "Add" : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*FILTER FOR SCHEDULE*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openScheduleFilter}
          maxWidth="lg"
          onClose={handleCloseScheduleTransactionFilter}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseScheduleTransactionFilter}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row mt-4">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={
                      scheduleStartDate &&
                      getFormattedDateValue(scheduleStartDate)
                    }
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeFromDate(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={
                      scheduleEndDate && getFormattedDateValue(scheduleEndDate)
                    }
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeToDate(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeFromDate(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeToDate(date);
              }}
            />
            <div className="mt-4" />
            <label style={style.label}> Medium </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterMediumData}
              isItemCheck={this.state.isMediumCheck}
              isAllCheck={this.state.isAllMediumChecked}
              handleAllCheckbox={this.handleAllMediumCheckbox}
              handleSelectedChange={this.handleSelectedMediumChange}
            />
            <div className="mt-4" />
            <label style={style.label}> Status </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterClientSchStatusData}
              isItemCheck={this.state.isScheduleStatusCheck}
              isAllCheck={this.state.isALlScheduleChecked}
              handleAllCheckbox={this.handleAllScheduleCheckbox}
              handleSelectedChange={this.handleSelectedScheduleChange}
            />
            <div className="mt-4" />
            <label style={style.label}> Schedule Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterScheduleTimeData}
              isItemCheck={this.state.isScheduleTypeCheck}
              isAllCheck={this.state.isALlScheduleTypeChecked}
              handleAllCheckbox={this.handleAllScheduleTypeCheckbox}
              handleSelectedChange={this.handleSelectedScheduleTypeChange}
            />
            <div className="mt-4" />
            <label style={style.label}> Transaction Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={allTypeArray}
              isItemCheck={this.state.isUPTypeCheck}
              isAllCheck={this.state.isAllUPTypeCheked}
              handleAllCheckbox={this.handleAllUPTypeCheckbox}
              handleSelectedChange={this.handleSelectedUPTypeChange}
            />
            <div className="mt-4" />
            <label style={style.label}> Vendor </label>
            <div className="select-input h40">
              <Select
                style={style.inputField}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(node) => node.parentNode}
                // style={{ width: 200 }}
                onChange={(e) => {
                  this.props.handleChangeVendorToFilter(e);
                }}
              >
                {vendorList &&
                  vendorList.map((list) => (
                    <Option value={list.vendorId}>{list.vendorName}</Option>
                  ))}
              </Select>
            </div>
            <div>
              <div className="row">
                <div className="col-6"></div>
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  {(scheduleStartDate === null || scheduleStartDate === 0) &&
                  (scheduleEndDate === null || scheduleEndDate === 0) &&
                  arraysEqual(
                    this.state.isScheduleStatusCheck,
                    FilterClientSchStatusData.map((status) => status.name)
                  ) &&
                  arraysEqual(
                    this.state.isUPTypeCheck,
                    FilterUPTypeData.map((type) => type.name)
                  ) &&
                  arraysEqual(
                    this.state.isScheduleTypeCheck,
                    FilterScheduleTimeData.map((type) => type.name)
                  ) &&
                  arraysEqual(
                    this.state.isMediumCheck,
                    FilterMediumData.map((medium) => medium.name)
                  ) ? (
                    <>
                      <Button
                        style={{ float: "right" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            page: 0,
                          });
                          handleCloseScheduleTransactionFilter();
                          this.props.fetchScheduleTransactionListByClient(
                            clientId
                          );
                        }}
                        className="addButton"
                      >
                        Filter
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          page: 0,
                        });
                        handleCloseScheduleTransactionFilter();
                        this.props.searchClientScheduleTransactionByAll(
                          // scheduleMediumType,
                          // scheduleStatus,
                          transactionMediumHelper(this.state.isMediumCheck),
                          ClientSheduleStatusHelper(
                            this.state.isScheduleStatusCheck
                          ),
                          // scheduleType,
                          scheduleTimeHelper(this.state.isScheduleTypeCheck),
                          scheduleStartDate === null ? 0 : scheduleStartDate,
                          scheduleEndDate === null ? 0 : scheduleEndDate,
                          scheduleVendorToFilter,
                          clientId,
                          // transTypeForSchedule,
                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "transactionType"
                          ),
                          this.props
                        );
                      }}
                      className="addButton"
                    >
                      Filter
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* Activity Log */}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openActivityLogModalForSchedule}
          onClose={handleCloseActivityLogModalForSchedule}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseActivityLogModalForSchedule}
          >
            Changelog Info
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{ width: "1000px", maxHeight: "1000px", paddingTop: "25px" }}
          >
            {activityLogValue && activityLogValue.length > 0 ? (
              activityLogValue.map((logValue) => (
                <div className="changeLogOuterDiv">
                  <div>
                    <Avatar
                      className="changeLogAvatar"
                      src={
                        logValue.profilePic
                          ? logValue.profilePic
                          : "/assets/images/profile.svg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "25px", fontSize: "16px" }}>
                    <span style={{ fontWeight: 600 }}>
                      {logValue.userName ? logValue.userName : "User"}
                    </span>{" "}
                    changed{" "}
                    <span style={{ fontWeight: 600 }}>
                      {logValue.activity ===
                      "schedule.transaction.status.updated"
                        ? "schedule transaction status to"
                        : "schedule transactions."}
                    </span>{" "}
                    {logValue.activity ===
                    "schedule.transaction.status.updated" ? (
                      <span>"{logValue.refValue}"</span>
                    ) : (
                      <ChangedLogPopover
                        refValue={logValue.refValue}
                        formatter={formatter}
                      />
                    )}
                    <div style={{ fontSize: "14px" }}>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(logValue.createdAt)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>
                  <img src="/assets/images/activityLog1.svg" alt="" />
                </div>
                <div>No changes till date !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        {/*FILTER FOR UPCOMING TRANSACTION*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openModalToFilterUPTransaction}
          maxWidth="lg"
          onClose={handleCloseTransactionFilterModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseTransactionFilterModal}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeTransactionDateFrom(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeTransactionDateTo(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeTransactionDateFrom(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeTransactionDateTo(date);
              }}
            />
            <label style={style.label}> Medium </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterMediumData}
              isItemCheck={this.state.isMediumCheck}
              isAllCheck={this.state.isAllMediumChecked}
              handleAllCheckbox={this.handleAllMediumCheckbox}
              handleSelectedChange={this.handleSelectedMediumChange}
            />
            <label style={style.label}> Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterUPTypeData}
              isItemCheck={this.state.isUPTypeCheck}
              isAllCheck={this.state.isAllUPTypeCheked}
              handleAllCheckbox={this.handleAllUPTypeCheckbox}
              handleSelectedChange={this.handleSelectedUPTypeChange}
            />
            <label style={style.label}> Vendor </label>
            <div className="select-input h40">
              <Select
                style={style.inputField}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(node) => node.parentNode}
                // style={{ width: 200 }}
                onChange={(e) => {
                  this.props.handleChangeVendor(e);
                }}
              >
                {vendorList &&
                  vendorList.map((list) => (
                    <Option value={list.vendorId}>{list.vendorName}</Option>
                  ))}
              </Select>
            </div>
            <div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        page: 0,
                      });
                      this.props.fetchClientUpcomingTransactionList(
                        this.state.clientId,
                        startDate,
                        endDate,
                        // mediumType,
                        transactionMediumHelper(this.state.isMediumCheck),
                        "",
                        vendorToFilter,
                        // transactionType
                        transactionTypeHelper(
                          this.state.isUPTypeCheck,
                          "transactionType"
                        )
                      );
                      handleCloseTransactionFilterModal();
                    }}
                    // disabled={!filteredValue}
                    // className={
                    //   filteredValue ? "addButton" : "disabledAddButton"
                    // }
                    className="addButton"
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*modal to edit blocked transaction */}
        <Dialog
          open={this.state.openModalToEditBlockedTransaction}
          onClose={handleCloseToEditBlockedTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseToEditBlockedTransaction}
            >
              Update
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Amount (Dollar) <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  autoComplete="off"
                  className="inputField"
                  value={
                    blockedData.amount === 0 ? "" : blockedData.amount / 100
                  }
                  onKeyPress={(e) => {
                    naturalRound(e);
                  }}
                  onChange={(e) => {
                    this.props.handleChangeBlockedTransData(
                      e.target.id,
                      finalAmount(e)
                    );
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    New Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={
                        blockedData.date &&
                        getDateWithStartTimeValue(blockedData.date)
                      }
                      onChangeDate={(date) => {
                        this.props.handleChangeBlockedTransData("date", date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={blockedStatus}
                    buttonName="Update"
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseToEditBlockedTransaction();
                      handleChangeBlockedTransaction();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={
                      this.state.isClientSchTrans
                        ? configStatusSch
                        : configStatus
                    }
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*PROCESS NOW*/}
        <Dialog
          open={this.state.openDialogueToProcess}
          onClose={handleCloseDialogueToProcess}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to proceed transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToProcess}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkTransaction(this.state.upcomingTrans);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*SKIP UPCOMING TRANSACTION*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={openModalToSkipTransaction}
          maxWidth="lg"
          onClose={() => {
            this.props.handleChangeToOpenSkipModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              this.props.handleChangeToOpenSkipModal(false);
            }}
          >
            Skip Transaction
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div>
              <div className="row">
                <div className="col-12 mt-4">
                  <label htmlFor="amount" style={style.columnHeading}>
                    Remark
                    <span style={{ color: "red" }}> *</span>{" "}
                  </label>
                  <input
                    id="remark"
                    name="remark"
                    autoComplete="off"
                    className="inputField"
                    onChange={(e) => {
                      this.setState({
                        isBlocking: true,
                      });
                      this.props.handleChangeRemarkToSkip(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSkipTransaction();
                    }}
                    disabled={!skipTransactionValue}
                    className={
                      skipTransactionValue ? "addButton" : "disabledAddButton"
                    }
                  >
                    Skip
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config.configFilters);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        {/* Client discharge */}
        <Dialog
          open={this.state.openDialogueToDischargeTransaction}
          onClose={handleCloseDialogueToDischargeTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to discharge this client?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToDischargeTransaction}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToDischargeTransaction();
                      const data = {
                        clientId: clientRes.clientId,
                        isDischarged: true,
                      };
                      this.props.clientOnDischarge(data, this.props);
                      // handleOpenDialogueToUnHoldClient()
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*hold client*/}
        <Dialog
          open={this.state.openDialogueToHoldTransaction}
          onClose={handleCloseDialogueToHoldClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to hold this client?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToHoldClient}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToHoldClient();
                      const data = {
                        clientId: clientRes.clientId,
                        isHold: true,
                      };
                      this.props.clientOnHold(data, this.props);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*rejects client schedule transaction*/}
        <Dialog
          open={this.state.openDialogueToResumeTransaction}
          onClose={handleCloseDialogueToResumeTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              {/* {this.state.changeCurrnetTrnxStatusTo === "hold"
                ? "Do you want to remove the client from hold?"
                : "Do you want to remove the client from discharge?"} */}
              Do you want to resume the schedule transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={() => {
                      handleCloseDialogueToResumeTransaction();
                    }}
                    style={style.cancelButton}
                  >
                    No
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDialogueToResumeTransaction();
                      if (this.state.changeCurrnetTrnxStatusTo === "hold") {
                        const data = {
                          clientId: clientRes.clientId,
                          isHold: false,
                          isResumeTxn: true,
                        };
                        this.props.clientOnHold(data, this.props);
                      } else {
                        const data = {
                          clientId: clientRes.clientId,
                          isDischarged: false,
                          // isResumeTxn: true,
                        };
                        this.props.clientOnDischarge(data, this.props);
                      }
                    }}
                    style={style.deleteClientButton}
                  >
                    yes
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={clientState && clientState.openSnackBarForSuccessMessage}
          autoHideDuration={5000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="success"
            message={clientState && clientState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={clientState && clientState.openClientSnackBar}
          autoHideDuration={5000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="success"
            message={clientState && clientState.successMsg}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={clientState && clientState.openClientErrorSnackBar}
          autoHideDuration={5000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="error"
            message={clientState && clientState.errorMsgForClient}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={clientState && clientState.openClientFailureSnackBar}
          autoHideDuration={5000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="error"
            message={clientState && clientState.errorMsgForClient}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={clientState && clientState.openSnackBarForErrorMessage}
          autoHideDuration={6000}
          onClose={this.props.handleChangeCloseSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeCloseSnackBar}
            variant="error"
            message={clientState && clientState.errorMsgForClient}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={contactState.openSuccessContactFileSnackBar}
          autoHideDuration={5000}
          onClose={this.props.closeContactSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeContactSnackBar}
            variant="success"
            message={contactState && contactState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={contactState.openErrorContactSnackBar}
          autoHideDuration={5000}
          onClose={this.props.closeContactSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeContactSnackBar}
            variant="error"
            message={contactState.errorMsg}
          />
        </Snackbar>
        {/******* Success/Error Message For Schedule Form********/}
        <SnackBarComponent
          onClose={this.props.handleChangeSnackBar}
          variant="success"
          open={scheduleState && scheduleState.openSuccessSnackBar}
          message={scheduleState && scheduleState.successMsg}
        />
        <SnackBarComponent
          onClose={this.props.handleChangeSnackBar}
          variant="error"
          open={scheduleState && scheduleState.openErrorSnackBar}
          message={scheduleState && scheduleState.errorMsg}
        />
        {/******* Success/Error Message For Create Txn Form ********/}
        <SnackBarComponent
          onClose={this.props.closeClientTransactionSnackBar}
          variant="success"
          open={
            transactionState &&
            transactionState.openModalCreateTransactionSuccess
          }
          message={transactionState && transactionState.successMsg}
        />
        <SnackBarComponent
          onClose={this.props.closeClientTransactionSnackBar}
          variant="error"
          open={
            transactionState && transactionState.openModalCreateTransactionError
          }
          message={transactionState && transactionState.errorMsg}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={transactionState.openTransactionSuccessSnackbar}
          autoHideDuration={4000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="success"
            message={transactionState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={upcomingTransactionState.openProcessSnackBar}
          autoHideDuration={5000}
          onClose={this.props.handleChangeUpcomingTransSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeUpcomingTransSnackBar}
            variant="success"
            message="Successfully proceed"
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten("#8BC83F", 0.8),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#8BC83F",
  },
})(LinearProgress);
const style = {
  card: {
    background: "#ffffff",
    border: "1px solid #E9E9E9",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
  },
  tableFirstBody: {
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    paddingLeft: "40px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  containerClient: {
    background: "#ffffff",
    marginTop: "20px",
    marginLeft: "1%",
    marginRight: "3%",
    // boxShadow:
    //   "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    position: "inherit",
  },
  containerCase: {
    background: "#ffffff",
    marginTop: "28px",
    marginLeft: "3%",
    marginRight: "3%",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    position: "inherit",
    paddingBottom: "1rem",
    marginBottom: "1rem",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    // position: 'fixed',
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    paddingBottom: "0.5rem",
    width: "100%",
    position: "inherit",
  },
  header: {
    borderBottom: "1px solid #E9E9E9",
    marginLeft: "2px",
    marginRight: "2px",
  },
  headerText: {
    color: "#cc6600",
    fontSize: "22px",
    fontWeight: "bolder",
    paddingTop: "10px",
    marginLeft: "2%",
    paddingBottom: "10px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "25px",
    paddingLeft: "40px",
    paddingBottom: "25px",
    position: "inherit",
  },
  caseRow: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 0",
    position: "inherit",
  },
  rowCase: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "20px",
    paddingLeft: "42px",
    paddingBottom: "0px",
    paddingRight: "40px",
    position: "inherit",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    // paddingLeft: '2rem',
    width: "90%",
  },
  rowCaseField: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "40px",
    paddingBottom: "18px",
    paddingRight: "40px",
    position: "inherit",
  },
  tableBodyAmount: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "right",
    height: "4.5rem",
    // paddingRight: '48px',
  },
  buttonRow: {
    paddingTop: "0px",
    paddingLeft: "40px",
    paddingBottom: "20px",
    paddingRight: "55px",
    justifyContent: "flex-end",
  },
  column1: {
    flex: "33%",
    padding: "0 6px",
  },
  column2: {
    flex: "34%",
    padding: "0 6px",
    position: "inherit",
  },
  column3: {
    flex: "33%",
    position: "inherit",
  },
  formGroupIcon: {
    marginTop: "20px",
    position: "relative",
    display: "block",
  },
  columnHeading: {
    color: "#6b6868",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    position: "inherit",
  },
  inputField: {
    border: "0",
    backgroundColor: "#ffffff",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    // width: "90%",
    width: "200px",
    paddingTop: "0px",
    marginTop: "10px",
    paddingLeft: "15px",
    position: "inherit",
  },
  span: {
    color: "rgb(99,97,92)",
    fontSize: "14x",
    height: "40px",
    width: "90%",
    paddingTop: "0.4rem",
    fontWeight: 550,
  },
  columnCaseHeading: {
    color: "#5e5b5b",
    fontSize: "16px",
    width: "100%",
    position: "inherit",
  },
  inputFieldCaseName: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "#5e5b5b",
    fontSize: "18x",
    height: "46px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "0.8rem",
    paddingLeft: "15px",
    marginBottom: "1rem",
    position: "inherit",
  },
  inputFieldDate: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "#5e5b5b",
    fontSize: "18x",
    height: "46px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "0px",
    paddingLeft: "15px",
    marginBottom: "1rem",
    position: "inherit",
    paddingRight: "12px",
  },
  inputFieldWorkflow: {
    border: "0",
    backgroundColor: "#ffffff",
    color: "rgb(130, 132, 129)",
    fontSize: "16x",
    height: "46px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "0.8rem",
    paddingLeft: "15px",
    marginBottom: "1rem",
    position: "inherit",
  },
  dropdownInput: {
    // marginTop: '1rem',
    marginRight: "2rem",
    height: "46px",
    paddingTop: "15px",
  },
  nextButton: {
    backgroundColor: "#8BC83F",
    width: "220px",
    height: "44px",
    border: "0",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "bold",
    marginTop: "31px",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  editDiv: {
    cursor: "pointer",
    // paddingLeft: "100px",
    paddingTop: "12px",
    fontSize: "16px",
    color: "#8bc83f",
    fontWeight: 600,
    marginLeft: "40px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  editDesc: {
    marginLeft: "118px",
    top: "-10px",
    position: "relative",
    padding: "20px",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  skipNow: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "20%",
    marginRight: "12px",
  },
  processNow: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "40%",
    marginRight: "12px",
  },
};

export default ClientDetailPageScreen;
