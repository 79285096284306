import { Popover } from "antd";
import React from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const ImportButton = ({
  onClickHoliday,
  onClickEvent,
  onClickImport,
}) => {
  const content = (
    <div style={{ width: "75px" }}>
      <div
        role="presentation"
        className="content1"
        onClick={onClickHoliday}
      >
        Holiday
      </div>
      <div
        role="presentation"
        className="content2"
        onClick={onClickEvent}
      >
        Event
      </div>
    </div>
  );
  return (
    <Popover content={content} placement="bottom" trigger="hover">
      <button className="addButton" onClick={onClickImport}>
        <ArrowDownwardIcon />
        Import
      </button>
    </Popover>
  );
};

export default ImportButton;
