import APIEndPoints from "../constants/APIConstants";
import {API} from "./index";

class DynamicActionClientAPI extends API {
    validateLink = (linkKey, callback) => {
        fetch(APIEndPoints.VALIDATE_URL_LINK_KEY + linkKey, {
            method: "GET",
            headers: this.headers,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    }

    addNewAction = (dynamicActionData, callback) => {
        fetch(APIEndPoints.ADD_CLIENT_ACTION_DATA, {
            method: "POST",
            headers: this.headers,
            body: JSON.stringify(dynamicActionData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    editDynamicActionData = ( dynamicActionData,callback) => {
        fetch( APIEndPoints.ADD_CLIENT_ACTION_DATA ,{
            method: 'PATCH',
            headers: this.headers,
            body:JSON.stringify(dynamicActionData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    uploadImageFileByClient = (profilePicture, linkKey, callback) => {
        fetch(APIEndPoints.UPLOAD_IMAGE_FILE_BY_CLIENT + linkKey, {
            method: "POST",
            headers: this.multiPartHeader,
            body: profilePicture,
            mimeType:'multipart/form-data'
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    };
    uploadDocumentFileByClient = (documents, linkKey , callback) => {
        fetch(APIEndPoints.UPLOAD_DOCUMENT_FILE_BY_CLIENT + linkKey, {
            method: "POST",
            headers: this.multiPartHeader,
            body: documents,
            mimeType:'multipart/form-data'
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    };
    getConditionalCaseFormWithRefIdByClient = (linkKey ,refId, callback) => {
        var constUrl = APIEndPoints.GET_CONDITIONAL_FORM_BY_REF_ID_BY_CLIENT + linkKey + "/" + refId;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        })
    };
};

export default new DynamicActionClientAPI();