export const naturalRound = (e) => {
  const dec = e.target.value.indexOf(".");
  const tooLong = e.target.value.length > dec + 2;
  const invalidNum = isNaN(parseFloat(e.target.value));
  if ((dec >= 0 && tooLong) || invalidNum) {
    e.target.value = e.target.value.slice(0, -1);
  }
};

export const finalAmount = (e) => {
  const targetValue = e.target.value * 100;
  const finalValue =
    targetValue.toFixed(2) === "0.00" ? 0 : targetValue.toFixed(2);
  return Number(finalValue);
};

export const editAmount = (value) => {
  const targetValue = value * 200;
  const finalValue =
    targetValue.toFixed(2) === "0.00" ? 0 : targetValue.toFixed(2);
  return Number(finalValue);
};
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});
