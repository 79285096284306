import React, { useEffect, useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExportButton from "../../components/ExportButton";
// import PaperComponent from "../PaperComponent";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TableOuterTotalDiv from "./TableOuterTotalDiv";
import { Popover } from "antd";
import EditOutlined from "@material-ui/icons/EditOutlined";
import { Select, Tooltip } from "antd";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotesPopover from "../../components/NotesPopover";
import { formatter } from "../../utils/currencyConvertor";
import TransactionTag from "./TransactionTag";
import TablePaginationComponent from "../../components/TablePaginationComponent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";
import PaperComponent from "../PaperComponent";
import DialogTitleComponent from "../DialogTitleComponent";
import { useState } from "react";

import ButtonComponent from "../ButtonComponent";
import {
  getRangeType,
  showGreaterThanSign,
  transactionStatusHelper,
} from "../TransactionHelper";
import { allTypeArray } from "../../components/FilterOptionList";
const { Option } = Select;

const LedgerTransactionTable = ({
  tableList,
  descriptionList,
  propsData,
  fetchLedgerTransactionList,
  page,
  rowsPerPage,
  setPage,
  setInitialPage,
  setRowPerPage,
  tableRef,
  rangeRef,
  totalAmount,
  totalTransaction,
  handleOpenLedgerTransFilter,
  exportLedgerTransactionInPDF,
  exportLedgerTransactionInCSV,
  openCreateTransactionForm,
  clearAmountDetail,
  fetchWithdrawalVendorList,
  clearWithdrawalTransactionForm,
  ledgerTransaction,
  history,
  clearSnackBar,
  setOpenLedgerTxnUpdate,
  setTransactionStatus,
  transactionState,
  notesValue,
  openNotesModal,
  notes,
  handleChangeToNotes,
  handleChangeToOpenNoteModal,
  trans,
  isUpdateNote,
  transactionId,
  addNoteForTransaction,
  openNotesModalForLedger,
  handleChangeToOpenNoteModalForLedger,
  settingState,
  addDescription,
  FilterLEDTypeData,
  FilterStatusData,
  setInitialValueofMainFilters,
  exportLedgerTransactionInPDFByColumnFilter,
  exportLedgerTransactionInCSVByColumnFilter,
}) => {
  const [isUpdateNotes, setIsUpdateNotes] = useState(isUpdateNote);
  const [isTrans, setIsTrans] = useState(trans);
  const [stateData, setStateData] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [tempRowId, setTempRowId] = useState("");
  const [visible, setVisible] = useState(false);
  const [popoverElement, setPopoverElement] = useState(null);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("transactionTimestamp");
  const [OpenSaveFilterInConfigmenu, setOpenSaveFilterInConfigmenu] =
    useState(false);
  const [openConfigMenu, setOpenConfigMenu] = useState(false);
  const [OpenViewFilterInConfig, setOpenViewFilterInConfig] = useState(false);
  const [configName, setConfigName] = useState("");

  const [popVisible, setPopVisible] = useState(false);

  useEffect(() => {
    fetchLedgerTransactionList(
      0,
      0,
      "UNKNOWN_TRANSACTION_STATUS",
      "UNKNOWN_TRANSACTION_TYPE",
      ""
    );
    propsData.clearTransactionFilter();
  }, []);

  const ref = useRef();
  const handleClickOutside = (event) => {
    if (rangeRef.current && !rangeRef.current.contains(event.target)) {
      setPopVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // });
  const handleChangeDropDown = (id) => {
    setOpenDropDown(!openDropDown);
    setTempRowId(id);
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  // new filter states
  const transDate =
    propsData.transactionState && propsData.transactionState.filterDate;
  const desc =
    propsData.transactionState && propsData.transactionState.filterDesc;
  const amount =
    propsData.transactionState && propsData.transactionState.filterAmount;
  const checkNo =
    propsData.transactionState && propsData.transactionState.filterCheckNo;
  const user =
    propsData.transactionState && propsData.transactionState.filterUser;
  const type =
    propsData.transactionState && propsData.transactionState.filterType;
  const stat =
    propsData.transactionState && propsData.transactionState.filtetStatus;
  const vendor =
    propsData.transactionState && propsData.transactionState.filterVendor;
  const medium =
    propsData.transactionState && propsData.transactionState.filterMedium;
  const configlist =
    propsData.transactionState && propsData.transactionState.configlist;

  const compareType =
    propsData.transactionState && propsData.transactionState.compareType;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "balance") {
      let Abalance = a[orderBy] ? a[orderBy] : "0";
      let Bbalance = b[orderBy] ? b[orderBy] : "0";

      return Abalance - Bbalance;
    } else if (orderBy === "refId") {
      return a[orderBy].toString().localeCompare(b[orderBy].toString(), "eng", {
        numeric: true,
      });
    } else if (orderBy === "firstName") {
      let first = a.Client && a.Client[orderBy] ? a.Client[orderBy] : "z";
      let second = b.Client && b.Client[orderBy] ? b.Client[orderBy] : "z";
      if (second < first) {
        return -1;
      }
      if (second > first) {
        return 1;
      }
      return null;
    } else if (orderBy === "vendorName") {
      let first =
        a.vendorAccount && a.vendorAccount[orderBy]
          ? a.vendorAccount[orderBy]
          : "z";
      let second =
        b.vendorAccount && b.vendorAccount[orderBy]
          ? b.vendorAccount[orderBy]
          : "z";
      if (second < first) {
        return -1;
      }
      if (second > first) {
        return 1;
      }
      return null;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return null;
    }
  }

  function getComparator(transactionList, order, orderBy) {
    if (order === "asc") {
      const list =
        transactionList &&
        transactionList.length > 0 &&
        transactionList.sort((a, b) => -descendingComparator(a, b, orderBy));

      return list;
    } else {
      const list =
        transactionList &&
        transactionList.length > 0 &&
        transactionList.sort((a, b) => descendingComparator(a, b, orderBy));

      return list;
    }
  }
  const sortedLedgerTrans = getComparator(tableList, order, orderBy);

  const handleChangeCloseConfigModal = () => {
    setOpenSaveFilterInConfigmenu(false);
    setConfigName("");
  };
  const handleChangeCloseViewConfigModal = () => {
    setOpenViewFilterInConfig(false);
  };

  const filterStatus =
    transDate !== null ||
    desc !== "" ||
    amount !== "" ||
    checkNo !== "" ||
    user !== "" ||
    medium !== "" ||
    type !== "" ||
    stat !== "" ||
    vendor !== "";

  const configStatus = configName !== "" && filterStatus;
  const handleFilterSaveConfig = () => {
    const data = {
      configName: configName,
      description: desc,
      balance: amount,
      checkNo: checkNo,
      userName: user,
      vendorName: vendor,
      transactionMedium: medium,
      transactionType: type,
      transactionStatus: stat,
      compareType: compareType,
      transactionDate: transDate === null ? "" : transDate,
      configType: 6,
      sortBy: order,
    };
    fetchLedgerTransactionList(
      0,
      0,
      "UNKNOWN_TRANSACTION_STATUS",
      "UNKNOWN_TRANSACTION_TYPE",
      ""
    );

    propsData.saveInConfig(data);
    propsData.clearTransactionFilter();
  };

  const handleUseConfigFilter = (config) => {
    propsData.clearTransactionFilter();
    if (config.transactionFilter.balance) {
      propsData.handleTransactionFilter(
        "filterAmount",
        config.transactionFilter.balance
      );
    }
    if (config.transactionFilter.checkNo) {
      propsData.handleTransactionFilter(
        "filterCheckNo",
        config.transactionFilter.checkNo
      );
    }
    if (config.transactionFilter.transactionMedium) {
      propsData.handleTransactionFilter(
        "filterMedium",
        config.transactionFilter.transactionMedium
      );
    }
    if (config.transactionFilter.description) {
      propsData.handleTransactionFilter(
        "filterDesc",
        config.transactionFilter.description
      );
    }
    if (config.transactionFilter.transactionDate !== "0") {
      propsData.handleTransactionFilter(
        "filterDate",
        config.transactionFilter.transactionDate
      );
    }
    if (config.transactionFilter.transactionStatus) {
      propsData.handleTransactionFilter(
        "filtetStatus",
        config.transactionFilter.transactionStatus
      );
    }
    if (config.transactionFilter.transactionType) {
      propsData.handleTransactionFilter(
        "filterType",
        config.transactionFilter.transactionType
      );
    }
    if (config.transactionFilter.userName) {
      propsData.handleTransactionFilter(
        "filterUser",
        config.transactionFilter.userName
      );
    }
    if (config.transactionFilter.vendorName) {
      propsData.handleTransactionFilter(
        "filterVendor",
        config.transactionFilter.vendorName
      );
    }
    if (config.transactionFilter.comparator) {
      propsData.handleTransactionFilter(
        "compareType",
        getRangeType(config.transactionFilter.comparator)
      );
    }

    propsData.filterLedgerByAll(
      config.transactionFilter.description
        ? config.transactionFilter.description
        : "",
      config.transactionFilter.checkNo ? config.transactionFilter.checkNo : "",
      config.transactionFilter.userName
        ? config.transactionFilter.userName
        : "",
      config.transactionFilter.vendorName
        ? config.transactionFilter.vendorName
        : "",
      config.transactionFilter.transactionMedium
        ? config.transactionFilter.transactionMedium
        : "",
      config.transactionFilter.transactionType
        ? config.transactionFilter.transactionType
        : "",
      config.transactionFilter.transactionStatus
        ? transactionStatusHelper([config.transactionFilter.transactionStatus])
        : "",
      config.transactionFilter.transactionDate
        ? config.transactionFilter.transactionDate
        : "0",
      config.transactionFilter.balance ? config.transactionFilter.balance : "",
      config.transactionFilter.comparator
        ? getRangeType(config.transactionFilter.comparator)
        : "0"
    );
  };
  return (
    <>
      <div className="flex-space-between">
        <div style={style.flex}>
          <TableOuterTotalDiv
            totalAmount={totalAmount}
            totalTransaction={totalTransaction}
          />
        </div>
        <div className={"d-flex flex-row-reverse flexStyle"}>
          {/****** Refresh ******/}
          <div>
            <button
              className="filterButton"
              onClick={() => {
                fetchLedgerTransactionList(
                  0,
                  0,
                  "UNKNOWN_TRANSACTION_STATUS",
                  "UNKNOWN_TRANSACTION_TYPE",
                  ""
                );
                propsData.clearTransactionFilter();
              }}
            >
              <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
            </button>
          </div>
          {/****** Filter ******/}
          <div style={{ display: "flex" }}>
            <button
              className="filterButton"
              onClick={() => {
                handleOpenLedgerTransFilter();
                propsData.clearTransactionFilter();
              }}
            >
              <FilterListIcon style={{ color: "#fff", marginTop: "-2px" }} />
            </button>
          </div>
          {/****** Export ******/}
          <div>
            {filterStatus ? (
              <ExportButton
                onClickPDF={(e) => {
                  e.preventDefault();
                  exportLedgerTransactionInPDFByColumnFilter(
                    desc,
                    checkNo,
                    user,
                    vendor,
                    medium,
                    type,
                    transactionStatusHelper([stat]),
                    transDate,
                    amount,
                    compareType
                  );
                }}
                onClickCSV={(e) => {
                  e.preventDefault();
                  exportLedgerTransactionInCSVByColumnFilter(
                    desc,
                    checkNo,
                    user,
                    vendor,
                    medium,
                    type,
                    transactionStatusHelper([stat]),
                    transDate,
                    amount,
                    compareType
                  );
                }}
              />
            ) : (
              <ExportButton
                onClickPDF={(e) => {
                  e.preventDefault();
                  exportLedgerTransactionInPDF();
                }}
                onClickCSV={(e) => {
                  e.preventDefault();
                  exportLedgerTransactionInCSV();
                }}
              />
            )}
          </div>
          {/****** Create Transaction ******/}
          <div>
            <button
              className="transactionBtn"
              onClick={(e) => {
                e.preventDefault();
                openCreateTransactionForm();
                clearAmountDetail();
                fetchWithdrawalVendorList();
                clearWithdrawalTransactionForm();
              }}
            >
              Create Transaction
            </button>
          </div>
          {/****** Search in table ******/}
          <div>
            <div className="search">
              <div className="searchIcon">
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                className="inputBase"
                onChange={(e) => {
                  e.preventDefault();
                  setPage(e, 0);
                  fetchLedgerTransactionList(
                    0,
                    0,
                    "UNKNOWN_TRANSACTION_STATUS",
                    "UNKNOWN_TRANSACTION_TYPE",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"row table-card"}>
        <TableContainer className="fixed-table-header-second">
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
            ref={tableRef}
          >
            <TableHead style={{ padding: "1rem" }}>
              <TableRow>
                <TableCell className="tableHeader">#</TableCell>
                <TableCell
                  className="first-tableHeader"
                  key="transactionTimestamp"
                  sortDirection={
                    orderBy === "transactionTimestamp" ? order : false
                  }
                >
                  <TableSortLabel
                    active={orderBy === "transactionTimestamp"}
                    direction={
                      orderBy === "transactionTimestamp" ? order : "asc"
                    }
                    className="override-mui-table"
                    onClick={createSortHandler("transactionTimestamp")}
                  >
                    Transaction Date
                  </TableSortLabel>
                </TableCell>
                <TableCell className="tableHeader" />
                <TableCell
                  className="first-tableHeader"
                  style={{ width: "0px" }}
                  key="description"
                  sortDirection={orderBy === "description" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "description"}
                    direction={orderBy === "description" ? order : "asc"}
                    className="override-mui-table"
                    onClick={createSortHandler("description")}
                  >
                    Description
                  </TableSortLabel>
                </TableCell>
                <TableCell className="tableHeaderAmount">Debits</TableCell>
                <TableCell className="tableHeaderAmount">Credits</TableCell>
                <TableCell
                  className="tableHeader"
                  key="balance"
                  sortDirection={orderBy === "balance" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "balance"}
                    direction={orderBy === "balance" ? order : "asc"}
                    className="override-mui-table"
                    onClick={createSortHandler("balance")}
                  >
                    Balance
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className="tableHeaderRefId"
                  key="refId"
                  sortDirection={orderBy === "refId" ? order : false}
                >
                  {" "}
                  <TableSortLabel
                    active={orderBy === "refId"}
                    direction={orderBy === "refId" ? order : "asc"}
                    className="override-mui-table"
                    onClick={createSortHandler("refId")}
                  >
                    Reference Id
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className="tableHeader"
                  key="transactionType"
                  sortDirection={orderBy === "transactionType" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "transactionType"}
                    direction={orderBy === "transactionType" ? order : "asc"}
                    className="override-mui-table"
                    onClick={createSortHandler("transactionType")}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className="tableHeader"
                  key="firstName"
                  sortDirection={orderBy === "firstName" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "firstName"}
                    direction={orderBy === "firstName" ? order : "asc"}
                    className="override-mui-table"
                    onClick={createSortHandler("firstName")}
                  >
                    User
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className="tableHeader"
                  key="transactionStatus"
                  sortDirection={
                    orderBy === "transactionStatus" ? order : false
                  }
                >
                  {" "}
                  <TableSortLabel
                    active={orderBy === "transactionStatus"}
                    direction={orderBy === "transactionStatus" ? order : "asc"}
                    className="override-mui-table"
                    onClick={createSortHandler("transactionStatus")}
                  >
                    Transaction Status
                  </TableSortLabel>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="sticky-search-bar">
                <TableCell className="first-tableBody" />
                <TableCell className="tableBody">
                  <div className="sm-search">
                    <InputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={transDate}
                      onChange={(e) => {
                        e.preventDefault();
                        propsData.handleTransactionFilter(
                          "filterDate",
                          e.target.value
                        );
                        setInitialPage();
                        setInitialValueofMainFilters();
                        propsData.filterLedgerByAll(
                          desc,
                          checkNo,
                          user,
                          vendor,
                          medium,
                          type,
                          stat,
                          e.target.value,
                          amount,
                          compareType
                        );
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell />
                <TableCell className="first-tableBody">
                  <div className="sm-search">
                    <Select
                      className="dropdown"
                      dropdownStyle={{
                        maxHeight: "auto",
                        overflowY: "scroll",
                      }}
                      placeholder="Search…"
                      value={desc}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        propsData.handleTransactionFilter("filterDesc", e);
                        setInitialPage();
                        setInitialValueofMainFilters();
                        propsData.filterLedgerByAll(
                          e,
                          checkNo,
                          user,
                          vendor,
                          medium,
                          type,
                          transactionStatusHelper([stat]),
                          transDate,
                          amount,
                          compareType
                        );
                      }}
                    >
                      {descriptionList &&
                        descriptionList.map((list) => (
                          <Option value={list.description}>
                            {list.description}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell className="tableBody" ref={rangeRef}>
                  <div
                    className="sm-search"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span className="show-range-sign">
                      {showGreaterThanSign(compareType)}
                    </span>
                    <InputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={amount}
                      className="show-range"
                      onChange={(e) => {
                        e.preventDefault();
                        propsData.handleTransactionFilter(
                          "filterAmount",
                          e.target.value
                        );
                        setInitialValueofMainFilters();
                        setInitialPage();
                        propsData.filterLedgerByAll(
                          desc,
                          checkNo,
                          user,
                          vendor,
                          medium,
                          type,
                          transactionStatusHelper([stat]),
                          transDate,
                          e.target.value,
                          compareType
                        );
                      }}
                    />
                    <Popover
                      placement="bottom"
                      trigger="click"
                      visible={popVisible}
                      content={
                        <>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              setPopVisible(false);
                              propsData.handleTransactionFilter(
                                "compareType",
                                "2"
                              );
                              setInitialValueofMainFilters();
                              setInitialPage();
                              propsData.filterLedgerByAll(
                                desc,
                                checkNo,
                                user,
                                vendor,
                                medium,
                                type,
                                transactionStatusHelper([stat]),
                                transDate,
                                amount,
                                "2"
                              );
                            }}
                            className="cursorP"
                          >
                            Lesser than {`(<)`}
                          </div>

                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              setPopVisible(false);
                              propsData.handleTransactionFilter(
                                "compareType",
                                "1"
                              );
                              setInitialValueofMainFilters();
                              setInitialPage();
                              propsData.filterLedgerByAll(
                                desc,
                                checkNo,
                                user,
                                vendor,
                                medium,
                                type,
                                transactionStatusHelper([stat]),
                                transDate,
                                amount,
                                "1"
                              );
                            }}
                            className="cursorP"
                          >
                            Greater than {`(>)`}
                          </div>
                          <div
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                              setPopVisible(false);
                              propsData.handleTransactionFilter(
                                "compareType",
                                "0"
                              );
                              setInitialValueofMainFilters();
                              setInitialPage();
                              propsData.filterLedgerByAll(
                                desc,
                                checkNo,
                                user,
                                vendor,
                                medium,
                                type,
                                transactionStatusHelper([stat]),
                                transDate,
                                amount,
                                "0"
                              );
                            }}
                            className="cursorP"
                          >
                            None
                          </div>
                        </>
                      }
                    >
                      <ExpandMoreIcon
                        className="expand-more-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setPopVisible(true);
                        }}
                      />
                    </Popover>
                  </div>
                </TableCell>
                <TableCell className="tableBody">
                  <div className="sm-search">
                    <InputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={checkNo}
                      onChange={(e) => {
                        e.preventDefault();
                        propsData.handleTransactionFilter(
                          "filterCheckNo",
                          e.target.value
                        );
                        setInitialPage();
                        setInitialValueofMainFilters();
                        propsData.filterLedgerByAll(
                          desc,
                          e.target.value,
                          user,
                          vendor,
                          medium,
                          type,
                          transactionStatusHelper([stat]),
                          transDate,
                          amount,
                          compareType
                        );
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell className="tableBody">
                  <div className="sm-search">
                    <Select
                      className="dropdown"
                      dropdownStyle={{
                        maxHeight: "auto",
                        overflowY: "scroll",
                      }}
                      placeholder="Search…"
                      value={type}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        propsData.handleTransactionFilter("filterType", e);
                        setInitialPage();
                        setInitialValueofMainFilters();
                        propsData.filterLedgerByAll(
                          desc,
                          checkNo,
                          user,
                          vendor,
                          medium,
                          e,
                          transactionStatusHelper([stat]),
                          transDate,
                          amount,
                          compareType
                        );
                      }}
                    >
                      {allTypeArray &&
                        allTypeArray.map((list) => (
                          <Option value={list.val}>{list.name}</Option>
                        ))}
                    </Select>
                  </div>
                </TableCell>
                <TableCell className="tableBody">
                  <div className="sm-search">
                    <InputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      value={user}
                      onChange={(e) => {
                        e.preventDefault();
                        propsData.handleTransactionFilter(
                          "filterUser",
                          e.target.value
                        );
                        setInitialValueofMainFilters();
                        setInitialPage();
                        propsData.filterLedgerByAll(
                          desc,
                          checkNo,
                          e.target.value,
                          vendor,
                          medium,
                          type,
                          transactionStatusHelper([stat]),
                          transDate,
                          amount,
                          compareType
                        );
                      }}
                    />
                  </div>
                </TableCell>
                <TableCell className="tableBody">
                  <div className="sm-search">
                    <Select
                      className="dropdown"
                      dropdownStyle={{
                        maxHeight: "auto",
                        overflowY: "scroll",
                      }}
                      placeholder="Search…"
                      value={stat}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        propsData.handleTransactionFilter("filtetStatus", e);
                        setInitialPage();
                        setInitialValueofMainFilters();
                        propsData.filterLedgerByAll(
                          desc,
                          checkNo,
                          user,
                          vendor,
                          medium,
                          type,
                          transactionStatusHelper([e]),
                          transDate,
                          amount,
                          compareType
                        );
                      }}
                    >
                      {FilterStatusData &&
                        FilterStatusData.map((list) => (
                          <Option value={list.name}>{list.name}</Option>
                        ))}
                    </Select>
                  </div>
                </TableCell>
                <TableCell style={{ paddingLeft: "0", cursor: "pointer" }}>
                  <Popover
                    placement="bottom"
                    trigger="click"
                    content={
                      <>
                        <div
                          role="presentation"
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenSaveFilterInConfigmenu(true);
                            setOpenConfigMenu(false);
                          }}
                          className="cursorP"
                        >
                          Save In Config
                        </div>

                        <div
                          role="presentation"
                          style={{
                            padding: "0.2rem",
                          }}
                          onClick={(e) => {
                            e.preventDefault();

                            propsData.getConfigList("6");
                            setOpenViewFilterInConfig(true);
                            setOpenConfigMenu(false);
                          }}
                          className="cursorP"
                        >
                          View Config
                        </div>
                      </>
                    }
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        e.preventDefault();
                        setPopoverElement(e.currentTarget);
                        setOpenConfigMenu(false);
                      }}
                    />
                  </Popover>
                </TableCell>
              </TableRow>
            </TableBody>
            {sortedLedgerTrans &&
              sortedLedgerTrans
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((list, index) => (
                  <TableBody key={list.transactionId}>
                    <TableRow
                      style={
                        list.transactionStatus === "TRANSACTION_ON_HOLD" ||
                        list.transactionStatus === "TRANSACTION_BLOCKED"
                          ? { cursor: "pointer", background: "#fff1f0" }
                          : { cursor: "pointer" }
                      }
                    >
                      <TableCell>
                        {(page + 1 - 1) * rowsPerPage + (index + 1)}
                      </TableCell>
                      <TableCell className="first-tableBody">
                        {new Intl.DateTimeFormat("default", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(list.createdAt || "-")}
                      </TableCell>
                      <TableCell style={{ paddingRight: "0px" }}>
                        {list.note ? (
                          <NotesPopover
                            content={
                              <>
                                <span>{list.note}</span>
                                <Tooltip placement="bottom" title="Edit Note">
                                  <EditOutlined
                                    onClick={() => {
                                      setIsUpdateNotes(true);
                                      setIsTrans(list);
                                      handleChangeToNotes(list.note);
                                      handleChangeToOpenNoteModalForLedger(
                                        true
                                      );
                                    }}
                                    style={{
                                      marginLeft: "4px",
                                      width: "15px",
                                      paddingBottom: "2px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </>
                            }
                          />
                        ) : (
                          <Tooltip placement="bottom" title="Add Note">
                            <NoteAddIcon
                              onClick={() => {
                                handleChangeToNotes("");
                                handleChangeToOpenNoteModalForLedger(true);
                                // this.setState({
                                //     isUpdateNote: false,
                                //     trans: list,
                                // });
                                // setIsUpdateNotes(false)
                                setIsTrans(list);
                              }}
                              style={{
                                color: "rgb(126, 126, 126)",
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className="first-tableBody">
                        {/* {list.description ? list.description : "-"} */}
                        <div
                          style={{
                            position: "relative",
                            top: "10px",
                            cursor: "pointer",
                          }}
                        >
                          {list.description}
                        </div>
                        <Popover
                          visible={list.transactionId === tempRowId && visible}
                          onVisibleChange={handleVisibleChange}
                          // visible={list.transactionId === this.state.tempRowId && this.state.openDropDown}
                          onClick={() => {
                            setIsTrans(list);
                          }}
                          trigger={"click"}
                          placement="bottomLeft"
                          content={
                            <>
                              {settingState &&
                                settingState.descriptionList.map((list) => (
                                  <div
                                    value={list.descriptionId}
                                    className="listHover hideScrollBar"
                                    onClick={(e) => {
                                      const selectedData =
                                        settingState &&
                                        settingState.descriptionList.find(
                                          (elem) =>
                                            elem.descriptionId ===
                                            list.descriptionId
                                        );
                                      const descriptionData = {
                                        transactionId: isTrans.transactionId,
                                        description:
                                          selectedData &&
                                          selectedData.description,
                                      };
                                      addDescription(
                                        descriptionData,
                                        true,
                                        fetchLedgerTransactionList
                                      );
                                      handleVisibleChange(false);
                                    }}
                                  >
                                    {list.description}
                                    <hr style={{ borderTop: "none" }} />
                                  </div>
                                ))}
                            </>
                          }
                        >
                          <span
                            id="dropDownToggler"
                            onClick={(e) => {
                              handleChangeDropDown(list.transactionId);
                            }}
                            style={style.editDesc}
                          >
                            <i
                              class="fa-solid fa-pen fa-xs"
                              id="checkDropDown"
                            />
                          </span>
                        </Popover>
                      </TableCell>
                      <TableCell className="tableAmountBody">
                        {list.amount
                          ? list.amount && list.amount.charAt(0) === "-"
                            ? formatter.format(list.amount / 100).substring(1)
                            : "-"
                          : formatter.format(0)}
                      </TableCell>
                      <TableCell className="tableAmountBody">
                        {list.amount
                          ? list.amount.charAt(0) === "-"
                            ? "-"
                            : formatter.format(list.amount / 100)
                          : formatter.format(0)}
                      </TableCell>
                      <TableCell className="tableBody">
                        {list.balance
                          ? formatter.format(list.balance / 100)
                          : formatter.format(0)}
                      </TableCell>
                      <TableCell className="tableRefIdBody">
                        {list.refId}
                      </TableCell>
                      <TableCell className="tableBody">
                        {list.transactionType ? list.transactionType : "-"}
                      </TableCell>
                      <TableCell
                        className="tableBody"
                        style={{ width: "205px" }}
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            clearSnackBar();
                            if (
                              list.Client &&
                              list.Client.firstName &&
                              list.Client.lastName
                            ) {
                              history.push({
                                pathname:
                                  "/transaction/client/ledger_transaction/" +
                                  list.Client.clientId,
                              });
                            } else {
                              history.push({
                                pathname:
                                  "/transaction/client/ledger_transaction/" +
                                  list.vendorId,
                              });
                            }
                          }}
                        >
                          {list.Client &&
                          list.Client.firstName &&
                          list.Client.lastName
                            ? list.Client.firstName + " " + list.Client.lastName
                            : list.vendorAccount
                            ? list.vendorAccount.vendorName
                            : "-"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <>
                          <TransactionTag list={list} />
                          {list.remark ? (
                            <>
                              <NotesPopover content={list.remark} isRemark />
                            </>
                          ) : null}
                        </>
                      </TableCell>
                      <TableCell>
                        {list.transactionStatus === "TRANSACTION_SUBMITTED" ||
                        list.transactionStatus === "TRANSACTION_APPROVED" ? (
                          <div>
                            <Popover
                              placement="bottom"
                              content={
                                <div
                                  role="presentation"
                                  onClick={() => {
                                    setOpenLedgerTxnUpdate(true);
                                  }}
                                  className="cursorP"
                                >
                                  Update Status
                                </div>
                              }
                            >
                              <MoreHorizIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setTransactionStatus(
                                    list.transactionId,
                                    list.transactionStatus
                                  );
                                }}
                              />
                            </Popover>
                          </div>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
          </Table>
        </TableContainer>
        <TablePaginationComponent
          tableList={sortedLedgerTrans}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowPerPage={setRowPerPage}
        />
        {/* </TableContainer> */}
        {/*add/update notes modal*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={openNotesModalForLedger}
          maxWidth="lg"
          onClose={() => {
            handleChangeToOpenNoteModalForLedger(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              handleChangeToOpenNoteModalForLedger(false);
            }}
          >
            {!isUpdateNotes ? "Add Note" : "Update Note"}
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div>
              <div className="row">
                <div className="col-12 mt-4">
                  <label htmlFor="amount" style={style.columnHeading}>
                    Note
                    <span style={{ color: "red" }}> *</span>{" "}
                  </label>
                  <input
                    id="remark"
                    name="remark"
                    autoComplete="off"
                    className="inputField"
                    value={notes}
                    onChange={(e) => {
                      setStateData({ isBlocking: true });
                      handleChangeToNotes(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const noteData = {
                        transactionId: isTrans.transactionId,
                        note: notes,
                      };
                      addNoteForTransaction(noteData, true);
                    }}
                    loading={transactionState && transactionState.loading}
                    disabled={!notesValue}
                    className={notesValue ? "addButton" : "disabledAddButton"}
                  >
                    {!isUpdateNotes ? "Add" : "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/*modal to save in config*/}
        <Dialog
          open={OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    setConfigName(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config.configFilters);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
const style = {
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  editDesc: {
    marginLeft: "118px",
    top: "-10px",
    position: "relative",
    padding: "20px",
  },
  flex: {
    // paddingBottom: "1rem",
    // paddingTop: "4.5rem",
    paddingLeft: "1rem",
    outline: "none",
    overflow: "auto",
    // marginBottom: "5px",
  },
};
export default LedgerTransactionTable;
