import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import "../styles/styles.css";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import { Avatar } from "antd";
import ChangedLogPopover from "../components/ChangedLogPopover";
import { formatter } from "../utils/currencyConvertor";

class ActivityLogScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
  }
  // componentDidMount() {
  //     this.props.fetchActivityLogById(this.props.clientId);
  // }

  render() {
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const { activityLogs } = this.props;

    return (
      <React.Fragment>
        <div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="first-tableHeader">User</TableCell>
                    <TableCell className="tableHeader">Activity</TableCell>
                    <TableCell className="tableHeader">Date/Time</TableCell>
                    <TableCell className="tableHeader">Activity Type</TableCell>
                  </TableRow>
                </TableHead>
                {activityLogs &&
                  activityLogs
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((activity) => (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className="first-tableBody"
                            style={{ width: "25%" }}
                          >
                            <Avatar
                              alt="Profile"
                              src={
                                activity.profilePic
                                  ? activity.profilePic
                                  : "/assets/images/profile.svg"
                              }
                            />
                            <span style={{ marginLeft: "12px" }}>
                              {activity.userName !== undefined
                                ? activity.userName
                                : activity.refValue &&
                                  activity.refValue.charAt(0) === "{"
                                ? "System"
                                : activity.refValue}
                            </span>
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "30%" }}
                          >
                            {activity.activity
                              ? activity.activity
                                  .replace(".", " ")
                                  .replace(".", " ")
                                  .replace(".", " ")
                              : "-"}
                            {activity.refValue &&
                              activity.refValue.charAt(0) === "{" &&
                              activity.activity === "client.updated" && (
                                <>
                                  <br />
                                  <ChangedLogPopover
                                    refValue={activity.refValue}
                                    formatter={formatter}
                                  />
                                </>
                              )}
                          </TableCell>
                          <TableCell className="tableBody">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(activity.createdAt)}
                          </TableCell>
                          <TableCell className="tableBody">
                            {activity.activityType &&
                              activity.activityType.replace("_", " ")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {activityLogs && activityLogs.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={activityLogs.length > 0 ? activityLogs.length : 100}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    border: "1px solid rgb(224, 224, 224)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "1rem",
  },
};
export default ActivityLogScreen;
