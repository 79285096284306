import React from "react";
import {Select, Space} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";
const {Option} = Select;

const OpenSocialHandleFields = ({fieldArray, filterSocialHandleData, handleOpenDeleteDialogue,filterEditSocialHandleData, updateFiledArray, isEdit}) => {

    const handleChangeUserName = (key, value) => {
        fieldArray.filter(single => single.socialHandleId === key)[0].username = value;
        if(isEdit){
            filterEditSocialHandleData()
        }else{
            filterSocialHandleData()
        }
    };
    const handleChangeSocialHandleType = (key, value) => {
        fieldArray.filter(single => single.socialHandleId === key)[0].socialHandleType = value;
        if(isEdit){
            filterEditSocialHandleData()
        }else{
            filterSocialHandleData()
        }
    };

    let div = [];
    // Outer loop to create parent
    if (fieldArray.length > 0) {
        div.push(<>
            <h5 style={{
                width: '100%',
                color: '#8BC83F',
                paddingBottom: '0.8rem',
                fontWeight: '550'
            }}>List of social handle</h5>
            <Space
                style={{ display: "flex" }}
                align="baseline"
            >
                <div style={{width: "190px"}}>
                    <label htmlFor="name" style={{marginBottom: "0px"}}>User Name
                        <span style={{color: "red"}} > *</span></label>
                </div>
                <div style={{width: "152px"}}>
                    <label htmlFor="contactType" style={{marginBottom: "0px"}}>Social Handle
                        <span style={{color: "red"}}> *</span>
                    </label>
                </div>
            </Space>
        </>)
    }

    fieldArray &&
    fieldArray[0].socialHandleId &&
    fieldArray.map(single => {
        return div.push(
            <Space
                key={single.socialHandleId}
                style={{ display: "flex"}}
                align="baseline"
            >
                <div id={single.socialHandleId}>
                    <input
                        id={single.socialHandleId}
                        className="inputField"
                        value={single.username}
                        name="userName"
                        onChange={(e) => {
                            e.preventDefault();
                            handleChangeUserName(single.socialHandleId, e.target.value)
                        }}
                    />
                </div>
                <div id={single.socialHandleId} style={{width: "200px"}}>
                    <div className="select-input selects h40"
                         style={{marginTop: "6px"}}>
                        <Select
                            value={single.socialHandleType}
                            showSearch={false}
                            getPopupContainer={node => node.parentNode}
                            onChange={e => {
                                handleChangeSocialHandleType(single.socialHandleId, e);
                            }}
                        >
                            <Option value="INSTAGRAM">Instagram</Option>
                            <Option value="TWITTER">Twitter</Option>
                            <Option value="PINTEREST">Pinterest</Option>
                            <Option value="REDDIT">Reddit</Option>
                            <Option value="SNAPCHAT">Snapchat</Option>
                            <Option value="WHATSAPP">Whatsapp</Option>
                            <Option value="TUMBLER">Tumbler</Option>
                            <Option value="DISCORD">Discord</Option>
                            <Option value="GOOGLE_HANGOUT">Google hangout</Option>
                            <Option value="SKYPE">Skype</Option>
                            <Option value="TELEGRAM">Telegram</Option>
                            <Option value="OTHER_SOCIAL_HANDLE">Other</Option>
                        </Select>
                    </div>
                </div>
                <div>
                    <MinusCircleOutlined
                        onClick={e => {
                            e.preventDefault();
                            handleOpenDeleteDialogue(single.socialHandleId);
                        }}
                    />
                </div>
            </Space>
        );
    });
    return div;

};

export default OpenSocialHandleFields;