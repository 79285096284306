import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import {Dropdown} from "semantic-ui-react";
import HomeIcon from '@material-ui/icons/Home';
import {CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {Prompt} from "react-router";
import Typography from "@material-ui/core/Typography";
import {HelmetComponent} from "../components/Helmet";

class CaseTemplateFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workflowData: [],
            searchedWorkflow: [],
            dropdownCount: 0,
            openDropdownForWorkflow: false,
            isBlocking: false
        }
    };

    componentDidMount() {
        this.props.fetchWorkflowList();
    }

    componentWillUnmount() {
        this.props.handleSnackBarClose();
    }

    render() {
        const caseTemplateState = this.props.state.caseTemplateState;

        const showInputForField = () => {
            let a = createAddFormObject();
            this.setState({
                openDropdownForWorkflow: true,
                workflowData: [...this.state.workflowData, a]
            });
        };

        const createAddFormObject = () => {
            let formData = {
                id: Math.random(),
                options: this.state.searchedWorkflow.filter(
                    s => !this.state.workflowData.find(a => s.value === a.value.workflowId)
                ),
                value: {
                    workflowId: '',
                    order: this.state.workflowData.length + 1
                }
            };
            return formData
        };

        const changeWorkflowNameValue = (key, value) => {
            var perviousValue = this.state.workflowData.filter(
                single => single.id == key
            )[0].value.workflowId;

            this.state.workflowData.filter(
                single => single.id == key
            )[0].value.workflowId = value;

            var rest = this.state.workflowData.filter(single => single.id != key);

            rest.map(single => {
                single.options = single.options.filter(s => s.value != value);
            });

            if (rest.length > 0 && perviousValue != "") {
                rest.map(single => {
                    single.options.push(
                        this.state.searchedWorkflow.filter(s => s.value == perviousValue)[0]
                    );
                });
            }

            filterWorkflowFormData()
        };

        const filterWorkflowFormData = () => {
            let workflow = [];
            this.state.workflowData.map((single) => {
                return (
                    workflow.push(single.value))
            });
            this.props.handleChangeCaseTemplate("workflow", workflow);
        };

        const openDropdownForWorkflow = () => {
            let div = [];
            // Outer loop to create parent
            this.state.workflowData.map(single => {
                return (
                    div.push(<div>
                        <div className="row">
                            <div className="col-10" style={{paddingRight: '30px'}}>
                                <Dropdown
                                    placeholder="Workflow"
                                    clearable
                                    fluid
                                    search
                                    selection
                                    selectOnBlur={false}
                                    id={single.id}
                                    value={single.value.workflowId}
                                    options={single.options}
                                    onChange={(e, data) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: true});
                                        changeWorkflowNameValue(single.id, data.value);
                                    }}
                                    style={style.dropdownInput}
                                />
                            </div>
                            <div className="col-2" style={{paddingLeft: 0,position:'inherit'}}>
                                <RemoveCircleOutlineIcon id={single.id}
                                                         style={{
                                                             color: 'grey',
                                                             width: '24px',
                                                             height: '24px',
                                                             marginTop: '2rem',
                                                             cursor:'pointer'
                                                         }}
                                                         onClick={e => {
                                                             e.preventDefault();
                                                             this.setState({isBlocking: true});
                                                             closeFieldSection(single.id);
                                                         }}
                                />
                            </div>
                        </div>
                    </div>))
            });
            return div
        };

        const closeFieldSection = (key) => {
            var perviousValue = this.state.workflowData.filter(
                single => single.id == key
            )[0].value.workflowId;
            var previousOrder = this.state.workflowData.filter(
                single => single.id == key
            )[0].value.order;

            var rest = this.state.workflowData.filter(single => single.id != key);
            if (rest.length > 0 && perviousValue != "") {
                rest.map(single => {
                    single.options.push(
                        this.state.searchedWorkflow.filter(s => s.value == perviousValue)[0]
                    );
                });
            }
            var removedActionData = this.state.workflowData.filter(x => x.id != key)
            var lessThanOrder = removedActionData.filter(x => x.value.order < previousOrder)


           var orderFiltered =  removedActionData.filter(x => x.value.order > previousOrder)

           orderFiltered.map(single=> {
               single.value.order = single.value.order - 1
           })

           lessThanOrder = [...lessThanOrder, ...orderFiltered]

            this.setState({
                workflowData:lessThanOrder
            }, () => {
                filterWorkflowFormData()
            })
        };

        const value = caseTemplateState.caseTemplateData && caseTemplateState.caseTemplateData.templateName !== '';

        return (
            <React.Fragment>
                <HelmetComponent title="Case Template"/>
                {/*Prompt */}
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />

                {/*loader*/}
                {this.props.state.caseTemplateState.loading ? (
                    <div
                        className={
                            this.props.state.caseTemplateState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                {caseTemplateState &&
                caseTemplateState.workflow &&
                this.state.searchedWorkflow &&
                caseTemplateState.workflow.length > 0 &&
                this.state.searchedWorkflow.length !==
                caseTemplateState.workflow.length ? this.setState({
                    searchedWorkflow: caseTemplateState.workflow.map(workflow => ({
                        key: workflow.workflowId,
                        text: workflow.workflowName,
                        value: workflow.workflowId
                    }))
                }) : null}

                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/casetemplate" style={style.link}>
                        Case Template
                    </Link>
                    <Typography color="inherit" className="link">
                        Create Case Template
                    </Typography>
                </Breadcrumbs>

                <div style={style.container}>
                    <div className="row">
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <label style={style.label}>Case Template Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="templateName"
                                onChange={e => {
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeCaseTemplate(e.target.id, e.target.value)
                                }
                                }
                                style={style.input}
                            />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-5"
                             style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            {openDropdownForWorkflow()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4"
                             style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <div style={{float: 'right', padding: '1.3rem 0 0 0', cursor:'pointer'}} onClick={(e) => {
                                e.preventDefault();
                                this.setState({isBlocking: true});
                                showInputForField();
                            }}>
                                <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                     style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Workflow</span>
                            </div>
                            <div className={"row"}
                                 style={{
                                     justifyContent: 'flex-end',
                                     padding: '2rem 0 1rem 0',
                                     marginLeft: 0,
                                     width: '100%'
                                 }}>
                                <button
                                    disabled={!value}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({isBlocking: false});
                                        this.props.addNewCaseTemplate(this.props.state.caseTemplateState.caseTemplateData, this.props);
                                    }}
                                    style={!value ? style.disabledButton : style.button}
                                >Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Snackbar
                    anchorOrigin={
                        {
                            vertical: "top",
                            horizontal:
                                "right"
                        }
                    }
                    open={this.props.state.caseTemplateState.openCaseTemplateSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    < MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.caseTemplateState.errorMsgForCaseTemplate}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    container: {
        background: "#ffffff",
        marginTop: "78px",
        marginLeft: '3.5%',
        marginRight: '3.5%',
        height: '100%',
        // boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    label: {
        color: '#606060',
        marginBottom: '0',
        fontSize: '16px',
        paddingBottom: "0.5rem"
    },
    input: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%'
    },
    dropdownInput: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginTop: '1.5rem',
        position: 'inherit'
    },
    header: {
        color: '#cc6600',
        fontSize: '22px',
        fontWeight: 'bolder',
        borderBottom: "1px solid #DEDEDE",
        paddingTop: "1rem",
        paddingBottom: '1rem',
        paddingLeft: '2rem'
    },
    selectedAction: {
        border: '1px solid #DCDCDC',
        padding: '1rem',
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        fontSize: '14px',
        marginBottom: '0.5rem',
        width: '80%'
    },
    link: {
        color: '#8BC83F'
    },
    button: {
        height: '35px',
        background: "#8BC83F",
        color: 'white',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        width: '100px'
    },
    disabledButton: {
        height: '35px',
        background: "#b1d681",
        color: 'white',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        width: '100px'
    }
};

export default CaseTemplateFormScreen;
