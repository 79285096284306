import {ACTION_TYPE_CORPORATE, ACTION_TYPE_SHIPPING_LABEL} from "../constants/Type";

export const requestShippingLabel = bool =>{
    return{
        type: ACTION_TYPE_SHIPPING_LABEL.REQUEST_SHIPPING_LABEL,
        bool,
    }
}

export const closeShippingSnackBar = bool => {
    return{
        type: ACTION_TYPE_SHIPPING_LABEL.CLOSE_SHIPPING_SNACKBAR,
        bool,
    }
}
export const handleChangeShippingValue = (id, value) => {
    return{
        type: ACTION_TYPE_SHIPPING_LABEL.HANDLE_CHANGE_SHIPPING_VALUE,
        id, value,
    }
}
export const setShippingToEdit = (shippingData) => {
    return{
        type: ACTION_TYPE_SHIPPING_LABEL.SET_SHIPPING_TO_EDIT,
        shippingData
    }
}

export const fetchShippingListSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_SUCCESS,
        response
    };
};

export const fetchShippingListFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_FAILURE,
        error
    };
};
export const fetchShippingListByIdSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_BY_ID_SUCCESS,
        response
    };
};

export const fetchShippingListByIdFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LIST_BY_ID_FAILURE,
        error
    };
};

export const addShippingAddressSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.ADD_ADDRESS_SUCCESS,
        response
    };
};

export const addShippingAddressFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.ADD_ADDRESS_FAILURE,
        error
    };
};
export const updateShippingAddressSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.UPDATE_SHIPPING_ADDRESS_SUCCESS,
        response
    };
};

export const updateShippingAddressFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.UPDATE_SHIPPING_ADDRESS_FAILURE,
        error
    };
};
export const deleteShippingAddressSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.DELETE_SHIPPING_SUCCESS,
        response
    };
};

export const deleteShippingAddressFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.DELETE_SHIPPING_FAILURE,
        error
    };
};

export const fetchShippingLabelSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LABEL_SUCCESS,
        response
    };
};

export const fetchShippingLabelFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.FETCH_SHIPPING_LABEL_FAILURE,
        error
    };
};
export const printShippingLabelSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.PRINT_SHIPPING_LABEL_SUCCESS,
        response
    };
};

export const printShippingLabelFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.PRINT_SHIPPING_LABEL_FAILURE,
        error
    };
};
export const handleChangeShippingFormValue = (id, value) => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.HANDLE_CHANGE_SHIPPING_FORM_VALUE,
        id,
        value
    };
};


export const setShippingAddressToEmpty = () => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.SET_SHIPPING_ADDRESS_TO_EMPTY,
    };
};

export const setShippingLabelToEmpty = () => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.SET_SHIPPING_LABEL_TO_EMPTY,
    };
};

export const trackShipmentSuccess = response => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.TRACK_SHIPMENT_SUCCESS,
        response
    };
};

export const trackShipmentFailure = error => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.TRACK_SHIPMENT_FAILURE,
        error
    };
};

export const handleClearTrackingReport = () => {
    return{
        type: ACTION_TYPE_SHIPPING_LABEL.HANDLE_CLEAR_TRACK_REPORT,
    }
}

//SEARCH SHIPPING
export const searchShippingSuccess = (response) => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.SEARCH_SHIPPING_ADDRESS_SUCCESS,
        response
    }
};

export const searchShippingFailure = (error) => {
    return {
        type: ACTION_TYPE_SHIPPING_LABEL.SEARCH_SHIPPING_ADDRESS_FAILURE,
        error
    }
};



