import React, { Component } from "react";
import { HelmetComponent } from "../components/Helmet";
import {
  Button,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogContent from "@material-ui/core/DialogContent";
import { Tag, Tooltip } from "antd";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import MySnackbarContentWrapper from "../components/Snackbar";
import DialogTitleComponent from "./DialogTitleComponent";
import ButtonComponent from "./ButtonComponent";
import RefreshIcon from "@material-ui/icons/Refresh";
import FilterListIcon from "@material-ui/icons/FilterList";
import TransactionFilterForm from "./Transaction/TransactionFilterForm";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {
  FilterMediumData,
  FilterTypeData,
} from "../components/FilterOptionList";
class SubmitTransactionScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openModalToUpdateState: false,
      selected: [],
      clearSelect: false,
      selectedCheckMedium: [],
      sortType: "asc",
      openConfirmationModal: false,
      openSearchDialogue: false,

      isAllMediumChecked: true,
      isAllTypeChecked: true,

      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      isTypeCheck: FilterTypeData.map((type) => type.name),
    };
  }
  componentDidMount() {
    this.props.fetchApprovedTransactionList();
    this.props.fetchVendor();
  }
  componentWillUnmount() {
    this.props.handleTransactionSnackBarClose();
  }
  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };
  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  handleAllTypeCheckbox = () => {
    this.setState({ isAllTypeChecked: !this.state.isAllTypeChecked });
    this.setState({ isTypeCheck: FilterTypeData.map((li) => li.name) });
    if (this.state.isAllTypeChecked) {
      this.setState({ isTypeCheck: [] });
    }
  };
  handleSelectedTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isTypeCheck: [...this.state.isTypeCheck, value] });
    if (!checked) {
      this.setState({
        isTypeCheck: this.state.isTypeCheck.filter((item) => item !== value),
      });
    }
  };

  render() {
    const transactionStat = this.props && this.props.state.transactionState;
    const approvedTransactionList =
      transactionStat && transactionStat.approvedTransactionList;
    const cancelledRemark = transactionStat && transactionStat.declineRemark;
    const cancelStatus = cancelledRemark !== "";
    const handleChangeOpenModal = () => {
      this.setState({
        openModalToUpdateState: true,
      });
      this.props.handleChangeRemark("");
    };
    const handleChangeCloseModal = () => {
      this.setState({
        openModalToUpdateState: false,
      });
    };
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeOpenConfirmationModal = () => {
      this.props.handleChangeRemark("");
      this.setState({
        openConfirmationModal: true,
      });
    };
    const handleChangeCloseConfirmationModal = () => {
      this.setState({
        openConfirmationModal: false,
      });
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected =
          approvedTransactionList &&
          approvedTransactionList.map((n) => n.transactionId);
        this.setState({
          selected: newSelected,
          clearSelect: false,
          selectedCheckMedium: approvedTransactionList,
        });
      } else {
        this.setState({
          selected: [],
          selectedCheckMedium: [],
        });
      }
    };
    const handleChangeOpenSearchDialogue = () => {
      this.setState({
        openSearchDialogue: true,
        // page: 0,
      });
      this.props.clearSnackBar();
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
      });
    };

    const checkTransactionMedium =
      this.state.selectedCheckMedium &&
      this.state.selectedCheckMedium.filter(
        (medium) => medium.transactionMedium === "ACH"
      );
    const isACHSelected =
      checkTransactionMedium && checkTransactionMedium.length > 0;
    const handleClick = (event, id) => {
      const selectedIndex = this.state.selected.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selected: newSelected,
        clearSelect: false,
      });
    };
    const isSelected = (id) => this.state.selected.indexOf(id) !== -1;
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    const handleUpdateStatus = () => {
      var mappedData = this.state.selected.map(function (elem) {
        if (!cancelStatus) {
          return {
            transactionId: elem,
            transactionStatus: "TRANSACTION_SUBMITTED",
          };
        } else {
          return {
            transactionId: elem,
            transactionStatus: "TRANSACTION_CLOSED",
            remark: cancelledRemark,
          };
        }
      });
      if (mappedData.length !== 0) {
        const selectedData = {
          transaction: mappedData,
        };
        this.props.updateSubmitTransactionList(selectedData, this.props);
      } else {
        this.setState({
          openErrorSnackBar: true,
        });
      }
      this.setState({
        selected: [],
      });
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Submit Transaction" />
        {transactionStat && transactionStat.loading ? (
          <div
            className={
              transactionStat && transactionStat.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/transaction/list" className="links">
            Transaction
          </Link>
          <Typography color="inherit" className="link">
            Submit Transaction
          </Typography>
        </Breadcrumbs>
        <div style={{ margin: "80px 3% 2%" }}>
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            <div style={{ display: "flex" }}>
              {this.state.selected &&
              this.state.selected.length > 0 &&
              this.state.clearSelect === false ? (
                <div style={{ display: "flex" }}>
                  <button
                    className="printButton"
                    style={{ width: "70px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeOpenConfirmationModal();
                    }}
                  >
                    Close
                  </button>
                  <Tooltip
                    placement="left"
                    className={
                      isACHSelected
                        ? "disablePrintButton printButton-padding"
                        : "printButton printButton-padding"
                    }
                    title={
                      isACHSelected
                        ? "Transaction medium ACH can't be submitted."
                        : ""
                    }
                  >
                    <div
                      style={
                        isACHSelected
                          ? { width: "70px", background: "rgb(177, 214, 129)" }
                          : { width: "70px" }
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isACHSelected) {
                          handleChangeOpenModal();
                        }
                      }}
                    >
                      Submit
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <button
                    className="printButton"
                    style={{ width: "70px", background: "rgb(177, 214, 129)" }}
                    disabled
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeOpenModal();
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="printButton"
                    style={{ width: "70px", background: "rgb(177, 214, 129)" }}
                    disabled
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeOpenModal();
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}
              {/***** Filter *****/}
              <div>
                <button
                  // hidden
                  style={{ marginLeft: "0px" }}
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchVendor();
                    handleChangeOpenSearchDialogue();
                  }}
                >
                  <FilterListIcon
                    style={{ color: "#fff", marginTop: "-2px" }}
                  />
                </button>
              </div>
              <div>
                <button
                  hidden
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchApprovedTransactionList();
                  }}
                >
                  <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
                </button>
              </div>
            </div>
            <div className="search">
              <div style={style.searchIcon}>
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={style.inputBase}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value === "" || e.target.value === " ") {
                    this.props.fetchApprovedTransactionList();
                  } else {
                    this.props.searchTransaction(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        indeterminate={
                          this.state.selected &&
                          this.state.selected.length > 0 &&
                          approvedTransactionList &&
                          this.state.selected &&
                          this.state.selected.length <
                            approvedTransactionList.length
                        }
                        checked={
                          approvedTransactionList &&
                          approvedTransactionList.length > 0 &&
                          approvedTransactionList &&
                          this.state.selected &&
                          this.state.selected.length ===
                            approvedTransactionList.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all transaction" }}
                      />
                    </TableCell>
                    <TableCell className="first-tableHeader">
                      Transaction Date
                    </TableCell>
                    <TableCell className="tableHeaderAmount">Debits</TableCell>
                    <TableCell className="tableHeaderAmount">Credits</TableCell>
                    <TableCell className="tableHeaderAmount">Balance</TableCell>
                    <TableCell className="tableHeader">Reference Id</TableCell>
                    <TableCell className="tableHeader">User</TableCell>
                    <TableCell className="tableHeader">Vendor</TableCell>
                    <TableCell className="tableHeader">Medium</TableCell>
                    <TableCell className="tableHeader">
                      Transaction Type
                    </TableCell>
                    <TableCell className="tableHeader">
                      Transaction Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                {approvedTransactionList &&
                  approvedTransactionList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list, index) => {
                      const isItemSelected = isSelected(list.transactionId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableBody key={list.transactionId}>
                          <TableRow
                            onClick={(event) => {
                              if (!isItemSelected) {
                                const checkData = {
                                  id: list.transactionId,
                                  transactionMedium: list.transactionMedium,
                                };
                                this.setState({
                                  selectedCheckMedium: [
                                    ...this.state.selectedCheckMedium,
                                    checkData,
                                  ],
                                });
                              } else {
                                const removedItem =
                                  this.state.selectedCheckMedium.filter(
                                    (data) => data.id !== list.transactionId
                                  );
                                this.setState({
                                  selectedCheckMedium: removedItem,
                                });
                              }
                              handleClick(event, list.transactionId);
                            }}
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell className="first-tableBody">
                              {new Intl.DateTimeFormat("default", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(list.createdAt ? list.createdAt : "-")}
                            </TableCell>
                            <TableCell className="tableAmountBody">
                              {list.amount.charAt(0) === "-"
                                ? formatter
                                    .format(list.amount / 100)
                                    .substring(1)
                                : "-"}
                            </TableCell>
                            <TableCell className="tableAmountBody">
                              {list.amount.charAt(0) === "-"
                                ? "-"
                                : formatter.format(list.amount / 100)}
                            </TableCell>
                            <TableCell className="tableAmountBody">
                              {formatter.format(list.balance / 100)}
                            </TableCell>
                            <TableCell className="tableBody">
                              {list.refId}
                            </TableCell>
                            <TableCell className="tableBody">
                              {list.Client.lastName
                                ? list.Client.firstName +
                                  " " +
                                  list.Client.lastName
                                : list.Client.firstName}
                            </TableCell>
                            <TableCell className="tableBody">
                              {list.vendorAccount.vendorName
                                ? list.vendorAccount.vendorName
                                : "-"}
                            </TableCell>
                            <TableCell className="tableBody">
                              {list.transactionMedium.replace("_", " ")}
                            </TableCell>
                            <TableCell className="tableBody">
                              {list.transactionType.replace("_", " ")}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                paddingLeft: "18px",
                                height: "4.5rem",
                                cursor: "auto",
                              }}
                            >
                              <Tag style={style.colors} color="green">
                                {list.transactionStatus.replace(
                                  "TRANSACTION_",
                                  " "
                                )}
                              </Tag>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
              </Table>
            </TableContainer>
            {approvedTransactionList && approvedTransactionList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    approvedTransactionList.length > 0
                      ? approvedTransactionList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div style={{ justifyContent: "center", width: "100%" }}>
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
          </div>
        </div>
        <Dialog
          open={this.state.openConfirmationModal}
          onClose={handleChangeCloseConfirmationModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfirmationModal}
            >
              Update Transaction
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Remark <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeRemark(e.target.value);
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={cancelStatus}
                    buttonName="Update"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        selectedCheckMedium: [],
                      });
                      handleChangeCloseConfirmationModal();
                      handleUpdateStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        <Dialog
          className="modal-element"
          open={this.state.openModalToUpdateState}
          onClose={handleChangeCloseModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want submit{" "}
              {this.state.selected && this.state.selected.length > 1
                ? "these"
                : "this"}{" "}
              transaction ?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleChangeCloseModal}
                    className="cancelButton"
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseModal();
                      handleUpdateStatus();
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*FILTER FOR TRANSACTION*/}
        <TransactionFilterForm
          openFilterModal={this.state.openSearchDialogue}
          // for filter medium
          FilterMediumData={FilterMediumData}
          isMediumCheck={this.state.isMediumCheck}
          isAllMediumChecked={this.state.isAllMediumChecked}
          handleAllMediumCheckbox={this.handleAllMediumCheckbox}
          handleSelectedMediumChange={this.handleSelectedMediumChange}
          // for type filter
          FilterTypeData={FilterTypeData}
          isTypeCheck={this.state.isTypeCheck}
          isAllTypeChecked={this.state.isAllTypeChecked}
          handleAllTypeCheckbox={this.handleAllTypeCheckbox}
          handleSelectedTypeChange={this.handleSelectedTypeChange}
          handleChangeCloseSearchDialogue={handleChangeCloseSearchDialogue}
          vendorState={this.props.state && this.props.state.vendorState}
          transactionState={this.state && this.props.state.transactionState}
          handleChangeDateFrom={(data) => {
            this.props.handleChangeDateFrom(data);
          }}
          handleChangeDateTo={(data) => {
            this.props.handleChangeDateTo(data);
          }}
          handleChangeMedium={(data) => {
            this.props.handleChangeMedium(data);
          }}
          // handleChangeStatus={(date) => {this.props.handleChangeStatus(data)}}
          // handleChangeTerm={(date) => {this.props.handleChangeTerm(data)}}
          handleChangeVendor={(data) => {
            this.props.handleChangeVendor(data);
          }}
          handleChangeTransType={(data) => {
            this.props.handleChangeTransType(data);
          }}
          handleCloseSearchDialogue={() => handleChangeCloseSearchDialogue()}
          searchTransactionByAll={(medium, status, type, from, to, vendor) =>
            this.props.searchTransactionByAll(
              medium,
              status,
              type,
              from,
              to,
              vendor
            )
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={transactionStat && transactionStat.openUpdateErrorSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={transactionStat && transactionStat.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={transactionStat && transactionStat.openUpdateSuccessSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="success"
            message={transactionStat && transactionStat.successMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    margin: "12px 0px 1rem",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    fontSize: "14px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  filterButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
  },
  inputBase: {
    // paddingLeft:'2.3rem',
    width: "270px",
    // heigth: '26px'
  },
};

export default SubmitTransactionScreen;
