import React from "react";
import PaperComponent from "../PaperComponent";
import DialogTitleComponent from "../DialogTitleComponent";
import Dialog from "@material-ui/core/Dialog";
import {
  finalAmount,
  formatter,
  naturalRound,
} from "../../utils/currencyConvertor";
import { Select, Tooltip } from "antd";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { getFormattedDateTimeValue } from "../../utils/DateConverter";
import { DocumentImage } from "../../components/DocumentImage";
import ButtonComponent from "../ButtonComponent";
import DialogContent from "@material-ui/core/DialogContent";
import Helpers from "../../components/Helper";
import KeyBoardDatePickerComponent from "../DatePicker/DatePickerComponent";
import { onChangeDocumentFileHandler } from "../../utils/fileHandler";
const { Option } = Select;

const ScheduleNewTransaction = (props) => {
  const errors = props && props.errors;
  const scheduleTransactionState = props && props.scheduleTransactionState;
  const scheduleTransactionInputData =
    scheduleTransactionState &&
    scheduleTransactionState.scheduleTransactionInputData;
  const clientRes =
    scheduleTransactionState && scheduleTransactionState.clientRes;
  const transactionState = props && props.transactionState;
  const amountDetails = transactionState && transactionState.amountDetails;
  const fileUrl = scheduleTransactionState && scheduleTransactionState.fileUrl;
  const transMedium =
    scheduleTransactionInputData &&
    scheduleTransactionInputData.transactionMedium;
  const transType =
    scheduleTransactionInputData &&
    scheduleTransactionInputData.transactionType;
  const vendorState = props && props.vendorState;
  const vendorList = vendorState.vendorAccount;
  const clientVendor = vendorState && vendorState.clientVendor;
  const vendorData =
    vendorList &&
    vendorList.filter(
      (vendor) => vendor.vendorType && vendor.vendorType.isDefault
    );
  const settingState = props && props.settingState;
  const descriptionList = settingState && settingState.descriptionList;
  const isUpdate = props && props.isUpdate;

  const value =
    scheduleTransactionInputData &&
    scheduleTransactionInputData.transactionType !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.transactionMedium !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.amount !== 0 &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.description !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.scheduleType !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.scheduledDate !== "";
  const scheduleValueWithBank =
    scheduleTransactionInputData &&
    scheduleTransactionInputData.transactionType !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.transactionMedium !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.amount !== 0 &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.description !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.scheduleType !== "" &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.scheduledDate !== null &&
    scheduleTransactionInputData &&
    scheduleTransactionInputData.bankId !== "";

  const scheduleReqBody = {
    scheduleTransactionId:
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduleTransactionId,
    client: {
      clientId:
        scheduleTransactionInputData && scheduleTransactionInputData.clientId,
    },
    vendorAccount: {
      vendorId:
        scheduleTransactionInputData && scheduleTransactionInputData.vendorId,
    },
    amount: scheduleTransactionInputData && scheduleTransactionInputData.amount,
    note: scheduleTransactionInputData && scheduleTransactionInputData.note,
    description:
      scheduleTransactionInputData && scheduleTransactionInputData.description,
    scheduleDetail: {
      scheduledDate:
        scheduleTransactionInputData &&
        scheduleTransactionInputData.scheduledDate,
      endDate:
        scheduleTransactionInputData.endDate !== ""
          ? scheduleTransactionInputData.endDate
          : "0",
      scheduleType:
        scheduleTransactionInputData &&
        scheduleTransactionInputData.scheduleType,
    },
    transactionType:
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionType,
    transactionMedium:
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionMedium,
    isLedger:
      scheduleTransactionInputData && scheduleTransactionInputData.isLedger,
  };
  if (transType === "DEPOSIT" && transMedium === "CHECK") {
    scheduleReqBody.bankDetailId =
      scheduleTransactionInputData && scheduleTransactionInputData.bankId;
    scheduleReqBody.document = fileUrl;
  } else if (transType === "DEPOSIT" && transMedium !== "CHECK") {
    scheduleReqBody.bankDetailId =
      scheduleTransactionInputData && scheduleTransactionInputData.bankId;
  } else if (transType !== "DEPOSIT" && transMedium === "CHECK") {
    scheduleReqBody.document = fileUrl;
  }

  const handleChange = (e) => {
    props.handleChangeScheduleTransactionInputData(
      "isLedger",
      e.target.checked
    );
  };

  return (
    <div>
      <Dialog
        id="viewScheduleTransactionDetails"
        maxWidth="lg"
        open={props.openModal}
        onClose={() => {
          props.setOpenModalToScheduleTransaction(false);
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="#draggable-dialog-title"
      >
        <DialogTitleComponent
          id="draggable-dialog-title"
          onClose={() => {
            props.setOpenModalToScheduleTransaction(false);
          }}
        >
          {!isUpdate
            ? "Schedule New Transaction"
            : "Update Schedule Transaction"}
        </DialogTitleComponent>
        <DialogContent dividers className="dialogContentForForms">
          <div className="row mt-2">
            <div className="col-lg-4 select-input h40">
              <label htmlFor="clientName">Client Name</label>
              <input
                type="name"
                id="amount"
                className="inputField"
                disabled
                value={props.clientName}
              />
            </div>
            <div className="col-lg-4">
              <label htmlFor="amount">
                Amount (Dollar)
                <span style={{ color: "red" }}> *</span>
              </label>
              <input
                type="number"
                id="amount"
                name="amount"
                autoComplete="off"
                className="inputField"
                value={
                  scheduleTransactionInputData.amount === 0
                    ? ""
                    : scheduleTransactionInputData.amount / 100
                }
                onKeyPress={(e) => {
                  naturalRound(e);
                }}
                onChange={(e) => {
                  props.handleChangeScheduleTransactionInputData(
                    e.target.id,
                    finalAmount(e)
                  );
                  props.setButtonDisable(true);
                  Helpers.handleValidationOnChange(e, errors);
                }}
              />
              {amountDetails && amountDetails.totalBalance ? (
                <div className="mt-2">
                  <span style={{ color: "#8BC34A" }}>Total Balance</span> :
                  <span style={{ color: "#676767" }}>
                    {" "}
                    {amountDetails
                      ? formatter.format(amountDetails.totalBalance / 100)
                      : ""}
                  </span>
                  <span>
                    <Tooltip
                      placement="bottom"
                      overlayInnerStyle={{ width: "215px" }}
                      title={
                        <div>
                          <div>
                            <span>Posted Balance: </span>
                            <span>
                              {formatter.format(
                                amountDetails.actualBalance / 100
                              )}
                            </span>
                          </div>
                          <div>
                            <span>Submitted Balance: </span>
                            <span>
                              {formatter.format(
                                amountDetails.availableBalance / 100
                              )}
                            </span>
                          </div>
                          {amountDetails.upcomingBalance ? (
                            <div>
                              <span>Upcoming Balance: </span>
                              <span>
                                {formatter.format(
                                  amountDetails.upcomingBalance / 100
                                )}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      }
                      getPopupContainer={(node) => node.parentNode}
                    >
                      <AccountBalanceWalletIcon
                        style={{
                          marginLeft: "5px",
                          color: "#636363d6",
                          width: "19px",
                          marginTop: "-3px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
              ) : (
                <div className="mt-2">
                  <span style={{ color: "#8BC34A" }}>Total Balance</span> :
                  <span style={{ color: "#676767" }}>
                    {" "}
                    {formatter.format(0 / 100)}
                  </span>
                </div>
              )}
              {errors.amount.length > 0 && (
                <span className="error">{errors.amount}</span>
              )}
            </div>
            <div className="col-lg-4">
              <label htmlFor="scheduleType">
                Schedule Type
                <span style={{ color: "red" }}> *</span>
              </label>
              <div
                className="select-input selects h40"
                style={{ width: "100%" }}
              >
                <Select
                  getPopupContainer={(node) => node.parentNode}
                  value={scheduleTransactionInputData.scheduleType}
                  onChange={(e) => {
                    props.handleChangeScheduleTransactionInputData(
                      "scheduleType",
                      e
                    );
                    if (e === "ONE_TIME") {
                      props.handleChangeScheduleTransactionInputData(
                        "endDate",
                        scheduleTransactionInputData.scheduledDate
                      );
                    } else {
                      props.handleChangeScheduleTransactionInputData(
                        "endDate",
                        null
                      );
                    }
                    // else {
                    //   props.handleChangeScheduleTransactionInputData(
                    //     "endDate",
                    //     new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(
                    //       23,
                    //       59,
                    //       59
                    //     )
                    //   );
                    // }
                    props.setButtonDisable(true);
                  }}
                >
                  <Option value="ONE_TIME">One Time</Option>
                  <Option value="DAILY">Daily</Option>
                  <Option value="WEEKLY">Weekly</Option>
                  <Option value="MONTHLY"> Monthly</Option>
                  <Option value="QUARTERLY">Quarterly</Option>
                  <Option value="YEARLY">Yearly</Option>
                  <Option value="NTH_DAY">nth Day</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4">
              <label htmlFor="startDate">
                Schedule Start Date
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="select-dob">
                <KeyBoardDatePickerComponent
                  dateTimeFormat
                  disablePast
                  dateValue={
                    scheduleTransactionInputData.scheduledDate &&
                    getFormattedDateTimeValue(
                      scheduleTransactionInputData.scheduledDate
                    )
                  }
                  onChangeDate={(startDate) => {
                    // const date = new Date(startDate).setHours(0, 0, 0);
                    props.handleChangeScheduleTransactionInputData(
                      "scheduledDate",
                      startDate
                    );
                    props.setButtonDisable(true);
                    if (
                      scheduleTransactionInputData &&
                      scheduleTransactionInputData.scheduleType === "ONE_TIME"
                    ) {
                      props.handleChangeScheduleTransactionInputData(
                        "endDate",
                        startDate
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <label htmlFor="endDate">Schedule End Date </label>
              <div className="select-dob">
                <KeyBoardDatePickerComponent
                  dateTimeFormat
                  disableMessage="disableMessage"
                  disabled={
                    scheduleTransactionInputData &&
                    scheduleTransactionInputData.scheduleType === "ONE_TIME"
                  }
                  dateValue={
                    scheduleTransactionInputData.endDate &&
                    getFormattedDateTimeValue(
                      scheduleTransactionInputData.endDate
                    )
                  }
                  onChangeDate={(endDate) => {
                    // const endDate = new Date(date).setHours(23, 59, 59);
                    props.handleChangeScheduleTransactionInputData(
                      "endDate",
                      endDate
                    );
                    props.setButtonDisable(true);
                  }}
                />
              </div>
              {/*{errors.endDate.length > 0 && (*/}
              {/*    <span className="error">{errors.endDate}</span>*/}
              {/*)}*/}
            </div>
            <div className="col-lg-4">
              <label htmlFor="transMedium">
                Transaction Medium
                <span style={{ color: "red" }}> *</span>
              </label>
              <div
                className="select-input selects h40"
                style={{ width: "100%" }}
              >
                <Select
                  id="transactionMedium"
                  value={scheduleTransactionInputData.transactionMedium}
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(e) => {
                    props.setButtonDisable(true);
                    props.handleChangeScheduleTransactionInputData(
                      "transactionMedium",
                      e
                    );
                    props.handleChangeTransactionMedium(e);
                    if (!isUpdate) {
                      props.handleChangeScheduleTransactionInputData(
                        "transactionType",
                        ""
                      );
                      props.handleChangeScheduleTransactionInputData(
                        "vendorId",
                        ""
                      );
                    }
                  }}
                >
                  <Option value="CHECK">Check</Option>
                  <Option value="CASH">Cash</Option>
                  <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                  <Option value="ACH">ACH</Option>
                  <Option value="CARD">Card</Option>
                  <Option value="LEDGER">Ledger</Option>
                  <Option value="OTHER_MEDIUM">Other medium</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {isUpdate ? (
              <div className="col-lg-4 select-input h40">
                <label htmlFor="clientName">Transaction Type</label>
                <input
                  type="name"
                  id="amount"
                  className="inputField"
                  disabled
                  value={
                    scheduleTransactionInputData.transactionType ===
                    "DISBURSEMENT"
                      ? "Disbursement"
                      : "Deposit"
                  }
                />
              </div>
            ) : (
              <div className="col-lg-4">
                <label htmlFor="transType">
                  Transaction Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div
                  className="select-input selects h40"
                  style={{ width: "100%" }}
                >
                  <Select
                    getPopupContainer={(node) => node.parentNode}
                    // onClick={() => {
                    //     props.fetchSingleClient(clientId);
                    // }}
                    value={scheduleTransactionInputData.transactionType}
                    onChange={(e) => {
                      props.fetchSingleClient();
                      props.setButtonDisable(true);
                      if (
                        e === "DEPOSIT" ||
                        e === "FEE" ||
                        e === "REFUND" ||
                        e === "ANNUAL_FEE"
                      ) {
                        props.handleChangeScheduleTransactionInputData(
                          "vendorId",
                          vendorData && vendorData[0] && vendorData[0].vendorId
                        );
                      } else {
                        if (clientVendor && clientVendor.length === 1) {
                          props.handleChangeScheduleTransactionInputData(
                            "vendorId",
                            clientVendor[0].vendorAccount &&
                              clientVendor[0].vendorAccount.vendorId
                          );
                        } else {
                          props.handleChangeScheduleTransactionInputData(
                            "vendorId",
                            ""
                          );
                        }
                      }
                      props.handleChangeScheduleTransactionInputData(
                        "transactionType",
                        e
                      );
                      props.handleChangeTransactionType(e);
                    }}
                  >
                    <Option
                      value="DISBURSEMENT"
                      hidden={transMedium === "LEDGER"}
                    >
                      Disbursement
                    </Option>
                    <Option value="DEPOSIT" hidden={transMedium === "LEDGER"}>
                      Deposit
                    </Option>
                    <Option value="FEE" hidden={transMedium !== "LEDGER"}>
                      Fee
                    </Option>
                    <Option value="REFUND" hidden={transMedium !== "LEDGER"}>
                      Refund
                    </Option>
                    {/* <Option value="ANNUAL_FEE" hidden={transMedium !== "LEDGER"}>Annual Fee</Option> */}
                  </Select>
                </div>
              </div>
            )}
            {isUpdate ? (
              <div className="col-lg-4 select-input h40">
                <label htmlFor="clientName">Vendor</label>
                <input
                  type="name"
                  id="amount"
                  className="inputField"
                  disabled
                  value={scheduleTransactionInputData.vendorName}
                />
              </div>
            ) : (
              <>
                <div className="col-lg-4">
                  <label htmlFor="vendor">
                    Vendor
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  {transType === "DEPOSIT" ||
                  transType === "FEE" ||
                  transType === "REFUND" ? (
                    <input
                      className="inputField"
                      value={
                        vendorData && vendorData[0]
                          ? vendorData[0].vendorName
                          : clientVendor &&
                            clientVendor[0] &&
                            clientVendor[0].vendorAccount.vendorName
                      }
                      disabled
                    />
                  ) : (
                    <div
                      className="select-input selects h40"
                      style={{ width: "100%" }}
                    >
                      <Select
                        className="drop"
                        dropdownStyle={{
                          maxHeight: "100px",
                          overflowY: "scroll",
                        }}
                        value={scheduleTransactionInputData.vendorId}
                        getPopupContainer={(node) => node.parentNode}
                        disabled={
                          transType === "DEPOSIT" ||
                          transType === "FEE" ||
                          transType === "REFUND"
                        }
                        onChange={(e) => {
                          props.setButtonDisable(true);
                          props.handleChangeScheduleTransactionInputData(
                            "vendorId",
                            e
                          );
                        }}
                      >
                        {(transType === "DEPOSIT" ||
                          transType === "FEE" ||
                          transType === "REFUND") &&
                        vendorData &&
                        vendorData[0] ? (
                          <Option value={vendorData[0].vendorId}>
                            {vendorData[0].vendorName}
                          </Option>
                        ) : (
                          clientVendor &&
                          clientVendor.map((vendor) => (
                            <Option
                              value={
                                vendor.vendorAccount &&
                                vendor.vendorAccount.vendorId
                              }
                            >
                              {vendor.vendorAccount &&
                                vendor.vendorAccount.vendorName}
                            </Option>
                          ))
                        )}
                      </Select>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="col-lg-4 select-input h40">
              <label htmlFor="clientName">
                Description
                <span style={{ color: "red" }}> *</span>
              </label>
              <Select
                value={scheduleTransactionInputData.description}
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  props.setButtonDisable(true);
                  props.handleChangeScheduleTransactionInputData(
                    "description",
                    e
                  );
                }}
              >
                {descriptionList &&
                  descriptionList.map((list) => (
                    <Option value={list.description}>{list.description}</Option>
                  ))}
              </Select>
            </div>
            <div
              className="col-lg-4 mt-3"
              hidden={transMedium === "CHECK" || transType === "DEPOSIT"}
            >
              <label htmlFor="note">Note</label>
              <input
                className="inputField"
                value={scheduleTransactionInputData.note}
                id="note"
                type="text"
                name="note"
                autoComplete="off"
                onChange={(e) => {
                  props.handleChangeScheduleTransactionInputData(
                    e.target.id,
                    e.target.value
                  );
                  props.setButtonDisable(true);
                  Helpers.handleValidationOnChange(e, errors);
                }}
              />
              {errors.note.length > 0 && (
                <span className="error">{errors.note}</span>
              )}
            </div>
          </div>
          <div
            className={"row mt-4"}
            hidden={!(transMedium === "CHECK" || transType === "DEPOSIT")}
          >
            <div
              className="col-lg-4"
              hidden={
                !(
                  transMedium === "CHECK" ||
                  (transType === "DEPOSIT" && transMedium !== "CHECK")
                )
              }
            >
              <label htmlFor="note">Note</label>
              <input
                className="inputField"
                id="note"
                type="text"
                name="note"
                value={scheduleTransactionInputData.note}
                autoComplete="off"
                onChange={(e) => {
                  props.handleChangeScheduleTransactionInputData(
                    e.target.id,
                    e.target.value
                  );
                  props.setButtonDisable(true);
                  Helpers.handleValidationOnChange(e, errors);
                }}
              />
              {errors.note.length > 0 && (
                <span className="error">{errors.note}</span>
              )}
            </div>
            <div
              className="col-lg-4"
              hidden={!(transMedium === "ACH" && transType === "DEPOSIT")}
            >
              <label htmlFor="note">
                Client Bank Account <span style={{ color: "red" }}> *</span>
              </label>
              {clientRes &&
              clientRes.bankDetail &&
              clientRes.bankDetail.length === 1 ? (
                <input
                  className="inputField"
                  disabled
                  value={
                    clientRes.bankDetail[0].accountName +
                    " (" +
                    clientRes.bankDetail[0].accountNumber +
                    ")"
                  }
                />
              ) : (
                <div className="select-input selects h40">
                  <Select
                    value={scheduleTransactionInputData.bankId}
                    showSearch={false}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      props.setButtonDisable(true);
                      props.handleChangeScheduleTransactionInputData(
                        "bankId",
                        e
                      );
                    }}
                  >
                    {clientRes &&
                      clientRes.bankDetail &&
                      clientRes.bankDetail.map((detail) => (
                        <Option value={detail.bankDetailId}>
                          {detail.accountName +
                            " " +
                            " (" +
                            detail.accountNumber +
                            " )"}
                        </Option>
                      ))}
                  </Select>
                </div>
              )}
            </div>
            <div className="col-lg-4" hidden={transMedium !== "CHECK"}>
              <label htmlFor="note">Print Document</label>
              <div>
                <DocumentImage documentURL={fileUrl} />
              </div>
              <label
                style={{
                  width: "115px",
                  marginTop: "0.5rem",
                  marginBottom: "1.5rem",
                  background: "rgba(204,204,254,0.32)",
                  border: "1px solid #7b7b7b",
                  textAlign: "center",
                  padding: "4px",
                  cursor: "pointer",
                }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    e.preventDefault();
                    const result = onChangeDocumentFileHandler(e);
                    if (result.name) {
                      props.uploadDocumentFile(result);
                    } else {
                      props.handleShowFileUploadErrorMessage(result);
                    }
                    props.setButtonDisable(true);
                  }}
                  className="hidden"
                  style={{ display: "none" }}
                />
                {scheduleTransactionState.loading
                  ? "Loading..."
                  : "Select file"}
              </label>
            </div>
          </div>

          {/* LEDGER  checkbox  */}
          {scheduleTransactionInputData.transactionType === "DEPOSIT" ? (
            <>
              <div style={style.checkBoxStyle}>
                <input
                  type="checkbox"
                  name="isLedger"
                  value="true"
                  id="checkboxinfo"
                  checked={scheduleTransactionInputData.isLedger}
                  onChange={(e) => handleChange(e)}
                />
                <label style={style.ledgerLabelStyle}>Ledger</label>
              </div>
            </>
          ) : (
            ""
          )}

          <div style={{ padding: "1rem 0px" }}>
            <div
              style={{
                display: "flex",
                marginTop: "9px",
                justifyContent: "flex-end",
              }}
            >
              <ButtonComponent
                buttonName={!isUpdate ? "Schedule" : "Update"}
                value={
                  !isUpdate
                    ? transMedium === "ACH" && transType === "DEPOSIT"
                      ? scheduleValueWithBank
                      : value
                    : props.buttonDisable
                }
                onClick={(e) => {
                  if (
                    clientVendor &&
                    clientVendor.length === 1 &&
                    transType !== "DEPOSIT" &&
                    transType !== "FEE" &&
                    transType !== "REFUND"
                  ) {
                    scheduleReqBody.vendorAccount.vendorId =
                      clientVendor &&
                      clientVendor[0] &&
                      clientVendor[0].vendorAccount.vendorId;
                  }
                  if (isUpdate) {
                    props.updateScheduleTransaction(scheduleReqBody);
                  } else {
                    props.addNewScheduleTransaction(scheduleReqBody);
                  }
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const style = {
  checkBoxStyle: {
    position: "absolute",
    top: "87%",
    right: "89%",
  },
  ledgerLabelStyle: {
    color: "rgb(98, 95, 95)",
    fontSize: "16px",
    padding: "5px",
  },
};
export default ScheduleNewTransaction;
