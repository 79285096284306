import {
  ACTION_TYPE_CLIENT,
  ACTION_TYPE_SET_PASSWORD,
  ACTION_TYPE_NOTE,
} from "../constants/Type";

export const clientRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_REQUEST_MADE,
    bool,
  };
};
export const clientProfilePictureRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_PROFILE_PICTURE_UPDATE_REQUEST,
    bool,
  };
};
export const setOpenClientForm = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.SET_OPEN_CLIENT_FORM_MODAL,
    bool,
  };
};
export const fetchClientSuccess = (client) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_FETCH_SUCCESS,
    client,
  };
};
export const fetchClientWithBalanceSuccess = (client) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_WITH_BALANCE_FETCH_SUCCESS,
    client,
  };
};
export const fetchClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_FETCH_FAILURE,
    error,
  };
};
export const closeSnackBarForClient = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.CLOSE_SNACK_BAR_CLIENT_PAGE,
    bool,
  };
};
export const deleteClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.DELETE_CLIENT_FAILURE,
    error,
  };
};
export const deleteClientSuccess = (clientId) => {
  return {
    type: ACTION_TYPE_CLIENT.DELETE_CLIENT_SUCCESS,
    clientId,
  };
};
export const deleteCaseByClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.DELETE_CASE_BY_CLIENT_FAILURE,
    error,
  };
};
export const deleteCaseByClientSuccess = (caseId) => {
  return {
    type: ACTION_TYPE_CLIENT.DELETE_CASE_BY_CLIENT_SUCCESS,
    caseId,
  };
};
export const handleChangeClient = (id, value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT,
    id,
    value,
  };
};
export const setClientToEdit = (clientData) => {
  return {
    type: ACTION_TYPE_CLIENT.SET_CLIENT_TO_EDIT,
    clientData,
  };
};

export const handleChangeToOpenClientFormModal = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_OPEN_CLIENT_FORM_MAODAL,
    bool,
  };
};

export const updateClientSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_ADD_SUCCESS_IN_CLIENT_UPDATE,
    response,
  };
};

export const updateClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_ADD_FAILURE_IN_CLIENT_UPDATE,
    error,
  };
};

export const searchClientSuccessInClientPage = (client) => {
  return {
    type: ACTION_TYPE_CLIENT.SEARCH_CLIENT_SUCCESS,
    client,
  };
};

export const searchClientWithBalanceFailInClientPage = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.SEARCH_CLIENT_WITH_BALANCE_FAILURE,
    error,
  };
};
export const getConfigSuccess = (config) => {
  return {
    type: ACTION_TYPE_CLIENT.GET_CONFIG_SUCCESS,
    config,
  };
};

export const getConfigFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.GET_CONFIG_FAILURE,
    error,
  };
};

export const saveConfigSuccess = () => {
  return {
    type: ACTION_TYPE_CLIENT.SAVE_CONFIG_SUCCESS,
  };
};

export const saveConfigFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.SAVE_CONFIG_FAILURE,
    error,
  };
};
export const clearFilterData = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_FILTER_DATA,
  };
};
export const searchClientWithBalanceSuccessInClientPage = (client) => {
  return {
    type: ACTION_TYPE_CLIENT.SEARCH_CLIENT_WITH_BALANCE_SUCCESS,
    client,
  };
};

export const searchClientFailInClientPage = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.SEARCH_CLIENT_FAILURE,
    error,
  };
};

export const clearClientSingle = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_SINGLE_CLIENT_DATA,
  };
};

export const fetchSingleClientSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_SINGLE_CLIENT_SUCCESS,
    response,
  };
};

export const fetchSingleClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_SINGLE_CLIENT_FAILURE,
    error,
  };
};

export const handleChangeEmail1 = (id, value) => {
  return {
    type: ACTION_TYPE_SET_PASSWORD.HANDLE_CHANGE_EMAIL,
    id,
    value,
  };
};

export const fetchFiledDetailByClientSuccess = (fileResponse) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_FILE_DETAILS_BY_CLIENT_SUCCESS,
    fileResponse,
  };
};

export const fetchFiledDetailByClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_FILE_DETAILS_BY_CLIENT_FAILURE,
    error,
  };
};
export const updateClientProfilePictureSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.UPDATE_CLIENT_PROFILE_PICTURE_SUCCESS,
    response,
  };
};

export const updateClientProfilePictureFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.UPDATE_CLIENT_PROFILE_PICTURE_FAILURE,
    error,
  };
};

export const uploadClientProfilePictureSuccess = (data) => {
  return {
    type: ACTION_TYPE_CLIENT.UPLOAD_PROFILE_PICTURE_SUCCESS,
    data,
  };
};

export const uploadClientProfilePictureFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.UPLOAD_PROFILE_PICTURE_FAILURE,
    error,
  };
};

export const fetchCaseByClientIdSuccess = (caseDetails) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_CASE_BY_CLIENT_ID_SUCCESS,
    caseDetails,
  };
};

export const fetchCaseByClientIdFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_CASE_BY_CLIENT_ID_FAILURE,
    error,
  };
};

//CLIENT'S BANK DETAIL ACTIONS

export const fetchBankDetailByBankIdSuccess = (bankDetails) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_SUCCESS,
    bankDetails,
  };
};

export const fetchBankDetailByBankIdFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_FAILURE,
    error,
  };
};

export const setBankDetailsToEdit = (bankDetail) => {
  return {
    type: ACTION_TYPE_CLIENT.SET_BANK_DETAIL_TO_EDIT,
    bankDetail,
  };
};
export const handleChangeAccountName = (accName) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ACCOUNT_NAME,
    accName,
  };
};

export const handleChangeAccountNumber = (accNumber) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ACCOUNT_NUMBER,
    accNumber,
  };
};

export const handleChangeRoutingNumber = (routingNumber) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ROUTING_NUMBER,
    routingNumber,
  };
};
export const handleChangeBankName = (bankName) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_BANK_NAME,
    bankName,
  };
};

export const handleChangeBankAccountType = (bankAccountType) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_BANK_ACCOUNT_TYPE,
    bankAccountType,
  };
};

export const clearBankDetail = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_BANK_DETAIL,
  };
};

export const addClientBankDetailsSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_CLIENT_BANK_DETAILS_SUCCESS,
    response,
  };
};
export const addClientBankDetailsFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_CLIENT_BANK_DETAILS_FAILURE,
    error,
  };
};

export const updateClientBankDetailsSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.UPDATE_CLIENT_BANK_DETAILS_SUCCESS,
    response,
  };
};
export const updateClientBankDetailsFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.UPDATE_CLIENT_BANK_DETAILS_FAILURE,
    error,
  };
};

export const deleteClientBankDetailsSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.DELETE_CLIENT_BANK_DETAILS_SUCCESS,
    response,
  };
};
export const deleteClientBankDetailsFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.DELETE_CLIENT_BANK_DETAILS_FAILURE,
    error,
  };
};

//PAYMENT SETTING
export const handleChangeClientFee = (fee) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_FEE_PERCENT,
    fee,
  };
};
export const handleChangeClientMonthlyFee = (monthlyFee) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_MONTHLY_FEE,
    monthlyFee,
  };
};
export const handleChangeClientAnnualFee = (annualFee) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_ANNUAL_FEE,
    annualFee,
  };
};
export const handleChangeClientSpendDown = (spendDownThreshold) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_SPEND_DOWN,
    spendDownThreshold,
  };
};
export const handleChangeClientInstallment = (installmentMonth) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_INSTALLMENT,
    installmentMonth,
  };
};
export const handleChangeAdmissionDate = (admissionDate) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ADMISSION_DATE,
    admissionDate,
  };
};
export const handleChangeClientAchDelayThreshold = (achDelayThreshold) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_ACH_DELAY_THRESHOLD,
    achDelayThreshold,
  };
};

export const addPaymentDetailSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_PAYMENT_DETAILS_SUCCESS,
    response,
  };
};
export const addPaymentDetailFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_PAYMENT_DETAILS_FAILURE,
    error,
  };
};
export const fetchClientPaymentDetailSuccess = (paymentDetail) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_PAYMENT_DETAILS_SUCCESS,
    paymentDetail,
  };
};
export const fetchClientPaymentDetailFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_PAYMENT_DETAILS_FAILURE,
    error,
  };
};
export const clearPaymentValues = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_PAYMENT_VALUES,
  };
};

export const clientOnHoldSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_ON_HOLD_SUCCESS,
    response,
  };
};
export const clientOnHoldFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_ON_HOLD_FAILURE,
    error,
  };
};

export const clientOnDischargeSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_ON_DISCHARGE_SUCCESS,
    response,
  };
};
export const clientOnDischargeFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_ON_DISCHARGE_FAILURE,
    error,
  };
};

export const setUploadToFalse = () => {
  return {
    type: ACTION_TYPE_CLIENT.SET_UPLOAD_SUCCESS_TO_FALSE,
  };
};

//need to merge
export const handleChangeClientNameId = (clientId) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_NAME,
    clientId,
  };
};

export const addNewClientSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_CLIENT_SUCCESS,
    response,
  };
};
export const addNewClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_CLIENT_FAILURE,
    error,
  };
};

export const setAddClientSuccessToTrueOrFalse = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.SET_ADD_CLIENT_SUCCESS_TO_TRUE_OR_FALSE,
    bool,
  };
};

export const clearClientInputData = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_CLIENT_DATA_INPUT,
  };
};

export const handleChangeAddedClientId = (value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ADDED_CLIENT_ID,
    value,
  };
};

//get assigned event on clients calendar

export const getAssignedEventForClientCalendarSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_SUCCESS,
    response,
  };
};
export const getAssignedEventForClientCalendarFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_FAILURE,
    error,
  };
};

//eligibility calculation
export const calculateEligibilitySuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.ELIGIBILITY_CALCULATION_SUCCESS,
    response,
  };
};
export const calculateEligibilityFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.ELIGIBILITY_CALCULATION_FAILURE,
    error,
  };
};
export const handleChangeEligibilitySetting = (id, value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGES_ELIGIBILITY_SETTING,
    id,
    value,
  };
};
export const handleChangeIdsValue = (id, value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_IDS_VALUE,
    id,
    value,
  };
};
export const handleChangeWorkflow = (workflowId) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_WORKFLOW,
    workflowId,
  };
};
export const handleChangeAction = (actionId) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ACTION,
    actionId,
  };
};
export const clearWorkflow = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_WORKFLOW,
  };
};

export const clearActions = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_ACTIONS,
  };
};

export const clearEligibilitySetting = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_ELIGIBILITY_SETTING,
  };
};

//for note

export const noteRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_NOTE.REQUEST_FOR_NOTE,
    bool,
  };
};

export const clearNoteDescription = () => {
  return {
    type: ACTION_TYPE_NOTE.CLEAR_NOTE_DESCRIPTION,
  };
};

export const handleChangeNoteDescription = (id, description) => {
  return {
    type: ACTION_TYPE_NOTE.HANDLE_CHANGE_NOTE_DESCRIPTION,
    id,
    description,
  };
};

export const setNoteDescriptionToEdit = (noteDescription) => {
  return {
    type: ACTION_TYPE_NOTE.SET_NOTE_DESCRIPTION_TO_EDIT,
    noteDescription,
  };
};

export const fetchNoteSuccess = (notes) => {
  return {
    type: ACTION_TYPE_NOTE.GET_NOTE_SUCCESS,
    notes,
  };
};
export const fetchNoteFailure = (error) => {
  return {
    type: ACTION_TYPE_NOTE.GET_NOTE_FAILURE,
    error,
  };
};

export const updateDescriptionSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.UPDATE_DESCRIPTION_SUCCESS,
    response,
  };
};

export const updateDescriptionFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.UPDATE_DESCRIPTION_FAILURE,
    error,
  };
};

export const addNoteSuccess = (response) => {
  return {
    type: ACTION_TYPE_NOTE.ADD_NOTE_SUCCESS,
    response,
  };
};
export const addNoteFailure = (error) => {
  return {
    type: ACTION_TYPE_NOTE.ADD_NOTE_FAILURE,
    error,
  };
};

export const updateNoteSuccess = (bool) => {
  return {
    type: ACTION_TYPE_NOTE.UPDATE_NOTE_SUCCESS,
    bool,
  };
};
export const updateNoteFailure = (error) => {
  return {
    type: ACTION_TYPE_NOTE.UPDATE_NOTE_FAILURE,
    error,
  };
};

export const deleteNoteSuccess = (bool) => {
  return {
    type: ACTION_TYPE_NOTE.DELETE_NOTE_SUCCESS,
    bool,
  };
};
export const deleteNoteFailure = (error) => {
  return {
    type: ACTION_TYPE_NOTE.DELETE_NOTE_FAILURE,
    error,
  };
};

export const fetchNoteByIdSuccess = (notes) => {
  return {
    type: ACTION_TYPE_NOTE.GET_NOTE_BY_ID_SUCCESS,
    notes,
  };
};
export const fetchNoteByIdFailure = (error) => {
  return {
    type: ACTION_TYPE_NOTE.GET_NOTE_BY_ID_FAILURE,
    error,
  };
};

//SET CHECK PRINT (TRUE/FALSE)
export const setCheckPrintSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_FETCH_SUCCESS,
    response,
  };
};
export const setCheckPrintFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.CLIENT_FETCH_FAILURE,
    error,
  };
};

// CLIENT'S NOTIFICATION SETTING
export const handleChangeToOpenSetNotificationModal = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_TO_OPEN_SET_NOTIFICATION_MODAL,
    bool,
  };
};
export const handleChangeEnableNotifications = (id, value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_ENABLE_NOTIFICATIONS,
    id,
    value,
  };
};
export const handleChangeUpcomingAlert = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_UPCOMING_ALERT,
    bool,
  };
};
export const handleChangeHolidayAlert = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_HOLIDAY_ALERT,
    bool,
  };
};
export const saveEnabledNotificationSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.SAVE_NOTIFICATIONS_SETTING_SUCCESS,
    response,
  };
};

export const saveEnabledNotificationFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.SAVE_NOTIFICATIONS_SETTING_FAILURE,
    error,
  };
};
export const getAlertByAccountSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.GET_ALERT_BY_ACCOUNT_ID_SUCCESS,
    response,
  };
};

export const getAlertByAccountFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.GET_ALERT_BY_ACCOUNT_ID_FAILURE,
    error,
  };
};

// CLIENT DETAIL PAGE

export const handleChangeToOpenNoteModalForClient = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_CLIENT,
    bool,
  };
};

export const handleChangeToNotesForClient = (notes) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_NOTES_FOR_CLIENT,
    notes,
  };
};

export const fetchTransactionClientSuccess = (transactionData) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_CLIENT_SUCCESS,
    transactionData,
  };
};

export const fetchTransactionClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_CLIENT_FAILURE,
    error,
  };
};

export const addNoteSuccessForClient = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.ADD_NOTES_SUCCESS_FOR_CLIENT,
    response,
  };
};

export const getClientTransactionSuccessForClient = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS,
    response,
  };
};

export const handleChangeVendorClientVendorData = (id, value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_VENDOR_CLIENT_DATA_VENDOR_DATA,
    id,
    value,
  };
};

export const handleChangeDescData = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_DESC_DATA,
    response,
  };
};

// client filter
export const handleChangeFilterName = (clientname) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_NAME,
    clientname,
  };
};
export const handleChangeFilterClientno = (clientNo) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_CLIENTNO,
    clientNo,
  };
};
export const handleChangeFilterCaseCount = (caseCount) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_CASECOUNT,
    caseCount,
  };
};
export const handleChangeFilterDate = (date) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_DATE,
    date,
  };
};
export const handleChangeUserStatus = (status) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_USER_STATUS,
    status,
  };
};
export const handleChangeFetchAllStatus = (bool) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FETCH_ALL_STATUS,
    bool,
  };
};
export const handleChangeFilterBalance = (balance) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_BALANCE,
    balance,
  };
};
export const handleChangeFilterTerm = (term) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_TERM,
    term,
  };
};
export const delcineClientTransCheckSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.DECLINE_CLIENT_TRANS_CHECK_SUCCESS,
    response,
  };
};

export const delcineClientTransCheckFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.DECLINE_CLIENT_TRANS_CHECK_FAILURE,
    error,
  };
};
// UPDATE BLOCKED TRANSACTION
export const handleChangeBlockedTransData = (id, value) => {
  return {
    type: ACTION_TYPE_CLIENT.HANDLE_EDIT_BLOCKED_TRANS_DATA,
    value,
    id,
  };
};
export const updateBlockedTransSuccess = (response) => {
  return {
    type: ACTION_TYPE_CLIENT.UPLOAD_BLOCKED_TRANSACTION_SUCCESS,
    response,
  };
};

export const updateBlockedTransFailure = (error) => {
  return {
    type: ACTION_TYPE_CLIENT.UPLOAD_BLOCKED_TRANSACTION_FAILURE,
    error,
  };
};
export const clearEditBlockedTrans = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_BLOCKED_TRANSACTION,
  };
};
export const clearClientList = () => {
  return {
    type: ACTION_TYPE_CLIENT.CLEAR_CLIENT_LIST,
  };
};
