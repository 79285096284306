import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitleComponent from "../screens/DialogTitleComponent";
import React, { Component } from "react";
import PaperComponent from "../screens/PaperComponent";
import ButtonComponent from "../screens/ButtonComponent";

class SaveConfigModal extends Component {
  render() {
    return (
      <>
        <Dialog
          open={this.props.openSaveConfig}
          onClose={this.props.handleCLoseModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={this.props.handleCLoseModal}
            >
              Save Filter
            </DialogTitleComponent>

            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  //   onChange={this.props.setName}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    // value={configStatus}
                    buttonName="Save"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   handleChangeCloseConfigModal();
                    //   handleFilterSaveConfig();
                    // }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      </>
    );
  }
}

export default SaveConfigModal;
