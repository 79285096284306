import {
  ACTION_TYPE_CHECK_PRINT,
  ACTION_TYPE_TRANSACTION,
} from "../constants/Type";

const initialState = {
  loading: false,
  openCheckPrintSnackBar: false,
  errorMsg: "",
  checkPrintList: [],
  bulkCheckPrintList: [],
  bankName: "",
  date: "",
  amount: "",
  review: "",
  payTo: "",
  startDate: "",
  endDate: "",
  clientId: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip5: "",
  singleBulkData: {},
  checkBoxValue: false,
  checkedValue: [],
  uncheckedValue: [],
  finalList: [],
  openBulkPrintErrorSnackBar: false,
  success: false,
  attachement: "",
  description: "",
  openSuccessSnackbar: false,
  openFailureSnackbar: false,
  // filter

  filterDate: null,
  filterCheckNo: "",
  filterBankName: "",
  filterVendor: "",
  filterAmount: "",
  compareType: "0",

  // config list
  configList: [],
};
const checkPrintReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_CHECK_PRINT.REQUEST_CHECK_PRINT:
      return {
        ...state,
        loading: true,
      };

    case ACTION_TYPE_CHECK_PRINT.EXPORT_CHECK_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_CHECK_PRINT.EXPORT_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openFailureSnackBar: true,
      };

    case ACTION_TYPE_CHECK_PRINT.EXPORT_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        url: action.response,
      };

    case ACTION_TYPE_CHECK_PRINT.CLOSE_CHECK_PRINT_SNACKBAR:
      return {
        ...state,
        openBulkPrintErrorSnackBar: false,
        openCheckPrintSnackBar: false,
        openSuccessSnackbar: false,
        openFailureSnackbar: false,
      };
    case ACTION_TYPE_CHECK_PRINT.FETCH_CHECK_PRINT_SUCCESS:
      // console.log(action.response.checkPrints);
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
        success: false,
      };
    case ACTION_TYPE_CHECK_PRINT.FETCH_CHECK_PRINT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_CHECK_PRINT.FETCH_BULK_CHECK_PRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkCheckPrintList: action.response.transactions,
      };
    case ACTION_TYPE_CHECK_PRINT.FETCH_BULK_CHECK_PRINT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_SELECT_BANK:
      return {
        ...state,
        loading: false,
        bankName: action.bankName,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_DATE:
      return {
        ...state,
        loading: false,
        date: action.date,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_AMOUNT:
      return {
        ...state,
        loading: false,
        amount: action.amount,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_MEMO:
      return {
        ...state,
        loading: false,
        review: action.review,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ATTACHEMENT:
      return {
        ...state,
        loading: false,
        attachement: action.attachment,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_DESCRIPTION:
      return {
        ...state,
        loading: false,
        description: action.description,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_PAY_TO:
      return {
        ...state,
        loading: false,
        payTo: action.payTo,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ADDRESS1:
      return {
        ...state,
        loading: false,
        address1: action.addressFirst,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ADDRESS2:
      return {
        ...state,
        loading: false,
        address2: action.addressSecond,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_STATE:
      return {
        ...state,
        loading: false,
        state: action.state,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_CITY:
      return {
        ...state,
        loading: false,
        city: action.city,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_ZIPCODE:
      return {
        ...state,
        loading: false,
        zip5: action.zipCode,
      };

    case ACTION_TYPE_CHECK_PRINT.PRINT_MANUAL_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.response,
      };
    case ACTION_TYPE_CHECK_PRINT.PRINT_MANUAL_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.PRINT_BULK_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkCheckPrintRes: action.response.checkPrintReq.checkPrint,
      };
    case ACTION_TYPE_CHECK_PRINT.PRINT_BULK_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openBulkPrintErrorSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_START_DATE:
      return {
        ...state,
        loading: false,
        startDate: action.startDate,
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_END_DATE:
      return {
        ...state,
        loading: false,
        endDate: action.endDate,
      };

    case ACTION_TYPE_CHECK_PRINT.CLOSE_FILTER_MODAL:
      return {
        ...state,
        bankName: action.bankName,
        amount: action.amount,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case ACTION_TYPE_CHECK_PRINT.SET_MANUAL_CHECK_DATA_TO_EMPTY:
      return {
        ...state,
        bankName: "",
        amount: "",
        review: "",
        payTo: "",
        clientId: "",
      };
    // FILTER BY SINGLE FIELD
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    //Filter with two
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkPrintList: action.response.checkPrints,
      };
    case ACTION_TYPE_CHECK_PRINT.FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openCheckPrintSnackBar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.CLEAR_CHECKED_VALUE:
      return {
        ...state,
        loading: false,
        checkedValue: [],
        uncheckedValue: [],
      };
    case ACTION_TYPE_CHECK_PRINT.HANDLE_CHANGE_CLIENT:
      return {
        ...state,
        clientId: action.value,
      };
    case ACTION_TYPE_CHECK_PRINT.DECLINE_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        openSuccessSnackbar: true,
        successMsg: "Successfully Declined",
      };
    case ACTION_TYPE_CHECK_PRINT.DECLINE_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openFailureSnackbar: true,
      };

    // new filter
    case ACTION_TYPE_CHECK_PRINT.CHECK_PRINT_FILTER:
      return {
        ...state,
        loading: false,
        [action.id]: action.value,
      };
    case ACTION_TYPE_CHECK_PRINT.CLEAR_CHECK_PRINT_FILTER:
      return {
        ...state,
        filterDate: null,
        filterCheckNo: "",
        filterBankName: "",
        filterVendor: "",
        filterAmount: "",
        compareType: "0",
      };
    case ACTION_TYPE_CHECK_PRINT.GET_CHECK_PRINT_CONFIG_SUCCESS:
      return {
        ...state,
        configList: action.payload,
      };
    case ACTION_TYPE_CHECK_PRINT.GET_CHECK_PRINT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openFailureSnackbar: true,
      };
    case ACTION_TYPE_CHECK_PRINT.SAVE_CHECK_PRINT_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        openSuccessSnackbar: true,
        successMsg: "Successfully Saved",
      };
    case ACTION_TYPE_CHECK_PRINT.SAVE_CHECK_PRINT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openFailureSnackbar: true,
      };
    default:
      return state;
  }
};
export default checkPrintReducer;
