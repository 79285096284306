import { Checkbox, Popover } from "antd";
import React, { Component } from "react";
import MultipleSelectCard from "./MultipleSelectCard";

class CheckboxFilterPopover extends Component {
  render() {
    return (
      <>
        <Popover
          placement="bottom"
          trigger="click"
          overlayStyle={{
            width: "395px",
            height: "auto",
            overflowY: "scroll",
            position: "absolute",
            zIndex: 33888,
          }}
          content={
            <>
              <Checkbox
                onChange={() => this.props.handleAllCheckbox()}
                defaultChecked="true"
              >
                All
              </Checkbox>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                }}
              >
                {this.props.forMappingFilterData &&
                  this.props.forMappingFilterData.map((data) => (
                    <Checkbox
                      checked={this.props.isItemCheck.includes(data.name)}
                      value={data.name}
                      onChange={this.props.handleSelectedChange}
                      style={{ marginLeft: 0 }}
                    >
                      {data.name}
                    </Checkbox>
                  ))}
              </div>
            </>
          }
        >
          <div className="select-input-dropdown h40">
            {this.props.isItemCheck.length ===
            this.props.forMappingFilterData.length ? (
              <span className="select-all">All</span>
            ) : (
              <>
                {this.props.isItemCheck.map((item) => (
                  <MultipleSelectCard value={item} />
                ))}
              </>
            )}
          </div>
        </Popover>
      </>
    );
  }
}

export default CheckboxFilterPopover;
