import { connect } from "react-redux";
import { ActivityLogScreen } from "../../screens";
import { ActivityLogAPI } from "../../api";
import {
  activityLogRequestMade,
  fetchACHActivityLogFailure,
  fetchACHActivityLogSuccess,
  // fetchActivityLogByIdFailure,
  // fetchActivityLogByIdSuccess,
  fetchActivityLogFailure,
  fetchActivityLogSuccess,
} from "../../actions/ActivityLogAction";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchActivityLog: () => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchActivityLog((response, error) => {
        if (response) {
          dispatch(fetchActivityLogSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchActivityLogFailure(error.msg));
          } else {
            dispatch(fetchActivityLogFailure(error));
          }
        }
      });
    },
    fetchACHActivityLog: () => {
      dispatch(activityLogRequestMade(true));
      ActivityLogAPI.fetchACHActivityLog((response, error) => {
        if (response) {
          dispatch(fetchACHActivityLogSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchACHActivityLogFailure(error.msg));
          } else {
            dispatch(fetchACHActivityLogFailure(error));
          }
        }
      });
    },
    // fetchActivityLogByRefId: (id) => {
    //   dispatch(activityLogRequestMade(true));
    //   ActivityLogAPI.fetchActivityLogByRefId(id, (response, error) => {
    //     if (response) {
    //       dispatch(fetchActivityLogByIdSuccess(response));
    //     } else {
    //       if (error.msg) {
    //         dispatch(fetchActivityLogByIdFailure(error.msg));
    //       } else {
    //         dispatch(fetchActivityLogByIdFailure(error));
    //       }
    //     }
    //   });
    // },
  };
};

export const ActivityLogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLogScreen);
