// const BASE_API = 'https://everfund-api-prod.brilltech.com';
// const BASE_API = 'https://everfund-api.brilltech.com';
import { REACT_APP_URL } from "../utils/config";

export const BASE_API = REACT_APP_URL;
const APIEndPoints = {
  //account
  LOGIN: BASE_API + "/login",
  LOGOUT: BASE_API + "/logout",
  GET_USER_INFO: BASE_API + "/account/password/request?emailPhone=",
  RESET_PASSWORD: BASE_API + "/account/password/reset",
  //user
  FETCH_USER: BASE_API + "/users",
  FETCH_PROFILE_EVERFUND_USER: BASE_API + "/user?userId=",
  FETCH_PROFILE_CORPORATE_USER: BASE_API + "/corporate?corporateId=",
  FETCH_PROFILE_CLIENT_USER: BASE_API + "/client/profile/",

  ADD_USER: BASE_API + "/account",
  UPDATE_USER_ROLE: BASE_API + "/user/role",
  DELETE_USER: BASE_API + "/account?refId=",
  RESET_PASSWORD_BY_SUPERADMIN: BASE_API + "/account/password/request/",
  //case
  FETCH_CASE: BASE_API + "/cases",
  DELETE_CASE: BASE_API + "/case?caseId=",
  UPDATE_CASE: BASE_API + "/case",
  SCHEDULE_DATE_BY_AGENT: BASE_API + "/agent/schedule/date",
  FETCH_SINGLE_CASE: BASE_API + "/case/id?caseId=",
  // SEARCH_CASE:  BASE_API + '/search/case?term=',
  FETCH_CALENDAR_LIST: BASE_API + "/view/agent/calendar",
  FETCH_CALENDAR_LIST_BY_ID: BASE_API + "/view/agent/calendar/",
  SEARCH_CALENDAR_DATA: BASE_API + "/calendar/search?term=",
  SEARCH_CALENDAR_DATA_BY_AGENT_ID: BASE_API + "/calendar/search/agent/",
  SEARCH_CALENDAR_DATA_BY_CLIENT_ID: BASE_API + "/calendar/search/client/",
  FETCH_FILE_DETAILS_BY_CASE: BASE_API + "/case/view/file?caseId=",

  //client
  FETCH_CLIENT: BASE_API + "/clients",
  FETCH_CLIENT_WITH_BALANCE: BASE_API + "/clients/balance",
  FETCH_SINGLE_CLIENT: BASE_API + "/client?clientId=",
  DELETE_CLIENT: BASE_API + "/client?clientId=",
  DELETE_CLIENT_IN_CORPORATE: BASE_API + "/corporate/",
  UPDATE_CLIENT: BASE_API + "/client",
  SEARCH_CLIENT: BASE_API + "/search/client?term=",
  FILTER_CLIENT: BASE_API + "/clients/search/balance?name=",
  // SAVE_FILTER_CONFIG: BASE_API,
  SAVE_FILTER_CONFIG: BASE_API + "/config?",
  GET_FILTER_CONFIG: BASE_API + "/config?type=",

  FETCH_FILE_LIST_BY_CLIENT: BASE_API + "/action/view/file/",
  FETCH_CASE_BY_CLIENT: BASE_API + "/case/client?clientId=",
  //workflow
  FETCH_WORKFLOW: BASE_API + "/workflows",
  ADD_WORKFLOW: BASE_API + "/workflow",
  DELETE_WORKFLOW: BASE_API + "/workflow?workflowId=",
  FETCH_SINGLE_WORKFLOW: BASE_API + "/workflow?workflowId=",
  EDIT_WORKFLOW: BASE_API + "/workflow",
  //action
  FETCH_ACTION: BASE_API + "/actions",
  FETCH_SINGLE_ACTION: BASE_API + "/action/",
  ADD_ACTION: BASE_API + "/action",
  DELETE_ACTION: BASE_API + "/action?actionId=",
  EDIT_ACTION: BASE_API + "/action",
  //case template
  FETCH_CASE_TEMPLATE: BASE_API + "/templates",
  DELETE_CASE_TEMPLATE: BASE_API + "/template?caseTemplateId=",
  ADD_CASE_TEMPLATE: BASE_API + "/template",
  FETCH_SINGLE_CASE_TEMPLATE: BASE_API + "/template?caseTemplateId=",
  EDIT_CASE_TEMPLATE: BASE_API + "/template",
  //registerCase
  ADD_CLIENT: BASE_API + "/client",
  SEARCH_BY_SSN: BASE_API + "/client/ssn?ssn=",
  REGISTER_CASE: BASE_API + "/case",
  //profile
  CHANGE_PASSWORD: BASE_API + "/account/password/update",
  UPLOAD_PROFILE_PICTURE: BASE_API + "/upload/profile",
  ASSIGN_PROFILE_PICTURE: BASE_API + "/user/profilepicture?url=",
  UPDATE_PROFILE_CLIENT_PICTURE: BASE_API + "/client/profile/picture",
  UPDATE_USER: BASE_API + "/user",
  //dynamic action
  ADD_DYNAMIC_ACTION_DATA: BASE_API + "/action/data",
  UPDATE_DYNAMIC_ACTION_DATA: BASE_API + "/action/data",
  UPLOAD_IMAGE_FILE: BASE_API + "/upload/image",
  UPLOAD_DOCUMENT_FILE: BASE_API + "/upload/document",
  FETCH_DYNAMIC_ACTION_DATA: BASE_API + "/action?actionId=",
  MARK_DYNAMIC_ACTION_DATA: BASE_API + "/case/workflow/action",
  GENERATE_LINK: BASE_API + "/generate/url",
  EXPORT_ACTION_DATA: BASE_API + "/action/form/export?actionId=",
  //dynamic action for client
  VALIDATE_URL_LINK_KEY: BASE_API + "/view/url/",
  ADD_CLIENT_ACTION_DATA: BASE_API + "/form/data/client",
  UPLOAD_IMAGE_FILE_BY_CLIENT: BASE_API + "/upload/image/client/",
  UPLOAD_DOCUMENT_FILE_BY_CLIENT: BASE_API + "/upload/doc/client/",

  //client's notification
  SAVE_ENABLED_NOTIFICATION: BASE_API + "/email/alert",
  GET_ALERT_BY_ACCOUNT_ID: BASE_API + "/email/alert/",

  //corporate
  FETCH_CORPORATE: BASE_API + "/corporates",
  FETCH_CORPORATE_BY_ID: BASE_API + "/client/corporate?corporateId=",
  ADD_CORPORATE: BASE_API + "/account",
  UPDATE_CORPORATE: BASE_API + "/corporate",
  DELETE_CORPORATE: BASE_API + "/account?refId=",
  //corporate client
  ADD_CORPORATE_CLIENT: BASE_API + "/client/corporate?corporateId=",
  ASSIGN_CLIENT_TO_CORPORATE: BASE_API + "/client/assign/corporate",
  //client list
  SEARCH_CLIENT_LIST: BASE_API + "/search/client?term=",

  // schedule transaction
  FETCH_SCHEDULE_TRANSACTION: BASE_API + "/schedule/transaction/all",
  FETCH_SCHEDULE_TRANSACTION_BY_ID: BASE_API + "/schedule/transaction/",
  FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_ID:
    BASE_API + "/schedule/transaction/client/",
  ADD_SCHEDULE_TRANSACTION: BASE_API + "/schedule/transaction/admin",
  UPDATE_SCHEDULE_TRANSACTION_FORM: BASE_API + "/schedule/transaction",
  UPDATE_SCHEDULE_TRANSACTION: BASE_API + "/schedule/transaction/status",
  DELETE_SCHEDULE_TRANSACTION: BASE_API + "/schedule/transaction/delete/",

  SEARCH_SCHEDULE_TRANSACTION:
    BASE_API + "/schedule/transaction/search/all?term=",
  SEARCH_SCHEDULE_TRANSACTION_FROM_DATE:
    BASE_API + "/schedule/transaction/search/all?from=",
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM:
    BASE_API + "/schedule/transaction/search/all?medium=",
  SEARCH_SCHEDULE_TRANSACTION_BY_STATUS:
    BASE_API + "/schedule/transaction/search/all?status=",

  SEARCH_CLIENT_SCHEDULE_TRANSACTION:
    BASE_API + "/schedule/transaction/search/client/",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_FROM_DATE:
    BASE_API + "/schedule/transaction/search/client/",
  REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT:
    BASE_API + "/schedule/transaction/client",
  EXPORT_SCHEDULE_TRANSACTION: BASE_API + "/schedule/export?term=",
  EXPORT_CLIENT_SCHEDULE_TRANSACTION: BASE_API + "/schedule/export/",

  // Vendor
  ADD_VENDOR: BASE_API + "/vendor/account",
  FETCH_VENDOR: BASE_API + "/vendor/accounts",
  SEARCH_VENDOR: BASE_API + "/vendor/accounts?term=",
  DELETE_VENDOR: BASE_API + "/vendor/account/delete/",
  FETCH_VENDOR_BY_ID: BASE_API + "/vendor/account/",
  UPDATE_VENDOR: BASE_API + "/vendor/account",

  ADD_VENDOR_CLIENT: BASE_API + "/vendor/client ",
  UPDATE_VENDOR_CLIENT: BASE_API + "/vendor/client",
  DELETE_VENDOR_CLIENT: BASE_API + "/vendor/client/",
  FETCH_VENDOR_CLIENTS_LIST: BASE_API + "/vendor/client/",
  FETCH_VENDOR_CLIENT_BY_CLIENT_ID: BASE_API + "/clientvendor/",

  // Card
  ADD_CARD: BASE_API + "/card",
  FETCH_CARD: BASE_API + "/cards",
  SEARCH_CARD: BASE_API + "/search/card/",
  FETCH_CARD_BY_ORDER_ID: BASE_API + "/card/",
  UPDATE_ORDER_STATUS: BASE_API + "/card/order/status/update",
  UPDATE_CARD_STATUS: BASE_API + "/card/status/update",

  //TRANSACTION API CONSTANT
  FETCH_TRANSACTION: BASE_API + "/transaction/all",
  FETCH_TRANSACTION_BY_ID: BASE_API + "/transaction/",
  FETCH_INDIVIDUAL_CLIENT_TRANSACTION: BASE_API + "/transaction/client/",
  FETCH_TRANSACTION_DELETED: BASE_API + "/transactions/deleted",

  SEARCH_TRANSACTION: BASE_API + "/transaction/search?term=",
  SEARCH_TRANSACTION_BY_MEDIUM: BASE_API + "/transaction/search?medium=",
  SEARCH_TRANSACTION_BY_STATUS: BASE_API + "/transaction/search?status=",
  SEARCH_TRANSACTION_BY_DATE: BASE_API + "/transaction/search?from=",
  SEARCH_TRANSACTION_BY_ALL: BASE_API + "/transaction/search?medium=",

  CHECK_TRANSACTION_DECLINE: BASE_API + "/transaction/decline",
  // UPDATE BLOCKED TRANSACTION
  UPDATE_BLOCKED_TRANSACTION: BASE_API + "/transaction/amount",
  //for client
  SEARCH_CLIENT_TRANSACTION: BASE_API + "/transaction/client/search/",
  SEARCH_CLIENT_TRANSACTION_BY_DATE: BASE_API + "/transaction/client/search/",

  //EXPORT PDF
  EXPORT_TRANSACTION: BASE_API + "/transaction/report/export?e=",
  EXPORT_TRANSACTION_WITH_TERM:
    BASE_API + "/transaction/filter/report/export?term=",
  EXPORT_TRANSACTION_WITH_DATE:
    BASE_API + "/transaction/filter/report/export?from=",
  EXPORT_TRANSACTION_WITH_MEDIUM:
    BASE_API + "/transaction/filter/report/export?medium=",
  EXPORT_TRANSACTION_WITH_STATUS:
    BASE_API + "/transaction/filter/report/export?status=",

  EXPORT_TRANSACTION_BY_ALL:
    BASE_API + "/transaction/filter/report/export?medium=",
  EXPORT_TRANSACTION_BY_COLUMN_FILTER: BASE_API + "/transaction/report/export?",

  //EXPORT VOD
  EXPORT_TRANSACTION_VOD: BASE_API + "/transaction/vod/",

  // EXPORT CHECK PRINT CSV
  EXPORT_CHECK_PRINT: BASE_API + "/check-print?from=",

  //EXPORT PDF BY CLIENT ID
  EXPORT_SINGLE_CLIENT_TRANSACTION: BASE_API + "/transaction/report/export/",
  EXPORT_SINGLE_CLIENT_TRANSACTION_WITH_FILTER:
    BASE_API + "/transaction/client/filter/report/export?clientId=",

  //Set Password
  SET_CLIENT_PASSWORD: BASE_API + "/account",

  //ACTIVITY LOG
  FETCH_ACTIVITY_LOG: BASE_API + "/activity/log",
  FETCH_ACTIVITY_LOG_BY_ID: BASE_API + "/activity/log/",
  FETCH_ACH_ACTIVITY_LOG: BASE_API + "/ach/activity/log",

  //UPCOMING TRANSACTION
  FETCH_ANNUAL_UPCOMING_TRANSACTION: BASE_API + "/schedule/fee/transaction",
  FETCH_UPCOMING_TRANSACTION: BASE_API + "/schedule/transaction/upcoming",
  FETCH_CLIENT_UPCOMING_TRANSACTION:
    BASE_API + "/schedule/transaction/upcoming/client/",
  PROCESS_UPCOMING_TRANSACTION: BASE_API + "/transaction",
  SKIP_UPCOMING_TRANSACTION: BASE_API + "/skip/transaction",
  SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM:
    BASE_API + "/schedule/fee/transaction?term=",
  SEARCH_UPCOMING_TRANSACTION_BY_TERM:
    BASE_API + "/schedule/transaction/upcoming/search?term=",
  SEARCH_UPCOMING_TRANSACTION_BY_DATE:
    BASE_API + "/schedule/transaction/upcoming/search?from=",
  SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM:
    BASE_API + "/schedule/transaction/upcoming/search?medium=",
  SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_DATE:
    BASE_API + "/schedule/transaction/upcoming/search?medium=",
  FILTER_BY_ALL: BASE_API + "/schedule/transaction/all?",
  FILTER_UPCOMING_TRANSACTION: BASE_API + "/schedule/transaction/upcoming?",

  //CALENDAR_SETTING
  FETCH_CALENDAR_HOLIDAY: BASE_API + "/calendar/setting",
  FETCH_CALENDAR_HOLIDAY_BY_ID: BASE_API + "/calendar/setting/id",
  ADD_HOLIDAYS: BASE_API + "/calendar/setting ",
  UPDATE_HOLIDAYS: BASE_API + "/calendar/setting",
  DELETE_HOLIDAYS: BASE_API + "/calendar/setting/delete/",

  //PAYMENT SETTING
  ADD_FEE_PERCENTAGE: BASE_API + "/payment/setting",
  FETCH_FEE_PERCENTAGE: BASE_API + "/payment/setting",

  // CLIENT'S BANK DETAIL
  FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID:
    BASE_API + "/client/bank/detail/307341eb5a864a1b9f2dbc618d798bf3",
  ADD_CLIENT_BANK_DETAIL: BASE_API + "/client/bank/detail",
  UPDATE_CLIENT_BANK_DETAIL: BASE_API + "/client/bank/detail",
  DELETE_CLIENT_BANK_DETAILS: BASE_API + "/client/bank/detail/delete/",

  //CHECK PRINT API
  FETCH_CHECK_PRINT: BASE_API + "/check/print",
  FETCH_BULK_CHECK_PRINT: BASE_API + "/transaction/check/all",
  PRINT_MANUAL_CHECK: BASE_API + "/check/print/manual",
  SET_CHECK_PRINT: BASE_API + "/check/print",
  PRINT_BULK_CHECK: BASE_API + "/check/print",
  FILTER_CHECK_PRINT: BASE_API + "/check/print/search?",
  FILTER_CHECK_PRINT_ALL: BASE_API + "/check/print?",
  UPDATE_CHECK_STATUS: BASE_API + "/check/update",

  //SHIPPING LABEL
  FETCH_SHIPPING_LIST: BASE_API + "/address/shipping",
  FETCH_SHIPPING_LIST_BY_ID: BASE_API + "/address/",
  ADD_SHIPPING_ADDRESS: BASE_API + "/address",
  UPDATE_SHIPPING_ADDRESS: BASE_API + "/address",
  DELETE_SHIPPING_ADDRESS: BASE_API + "/address/delete/",

  FETCH_SHIPPING_LABEL: BASE_API + "/shipping/label",
  PRINT_SHIPPING_LABEL: BASE_API + "/shipping/label",

  //SUBMIT TRANSACTION
  UPDATE_TRANSACTION_STATUS: BASE_API + "/transaction/status",
  UNDO_REVERSE_TRANX: BASE_API + "/transaction/undo",
  FETCH_SUBMIT_TRANSACTION: BASE_API + "/transaction/check",
  FETCH_CREATED_TRANSACTION: BASE_API + "/transaction/created",
  FETCH_APPROVED_TRANSACTIONS: BASE_API + "/transaction/approved",

  //CREATE TRANSACTION
  CREATE_TRANSACTION: BASE_API + "/transaction",
  GET_AMOUNT_DETAIL_BY_CLIENT_ID: BASE_API + "/balance/",

  //DELETE TRANSACTION
  DELETE_TRANSACTION_BY_ID: BASE_API + "/transaction/",

  //ADD TRANSACTION NOTE
  ADD_TRANSACTION_NOTES: BASE_API + "/transaction/note",

  //LEDGER TRANSACTION
  FETCH_LEDGER_TRANSACTION: BASE_API + "/transaction/ledger?",
  EXPORT_LEDGER_TRANSACTION: BASE_API + "/transaction/export/ledger?",
  UPDATE_LEDGER_TRANSACTION_STATUS: BASE_API + "/transaction/ledger/status",
  // CLIENT LEDGER TRANSACTION
  FETCH_CLIENT_LEDGER_TRANSACTION: BASE_API + "/transaction/ledger/client/",
  EXPORT_CLIENT_LEDGER_TRANSACTION:
    BASE_API + "/transaction/export/ledger/client/",

  // WITHDRAWAL TRANSACTION
  FETCH_WITHDRAWAL_TRANSACTION: BASE_API + "/withdrawal/vendor",

  //CONTACT
  FETCH_CONTACT_LIST: BASE_API + "/contact/case",
  ADD_CONTACT: BASE_API + "/contact",
  UPDATE_CONTACT: BASE_API + "/contact",
  DELETE_CONTACT: BASE_API + "/contact?contactId=",

  //COMPANY
  FETCH_COMPANY_LIST: BASE_API + "/company",
  ADD_COMPANY: BASE_API + "/company",
  UPDATE_COMPANY: BASE_API + "/company",
  DELETE_COMPANY: BASE_API + "/company/delete/",
  SEARCH_INDIVIDUAL_CONTACT: BASE_API + "/search/contact?term=",
  SEARCH_COMPANY_CONTACT: BASE_API + "/company/search?term=",
  DELETE_COMPANY_CONTACT: BASE_API + "/delete/company/",

  //CASE CONTACT
  FETCH_CASE_CONTACTS: BASE_API + "/case/contact?caseId=",
  ADD_INDIVIDUAL_CONTACT: BASE_API + "/contact/individual",
  ADD_COMPANY_CONTACT: BASE_API + "/contact/company",
  ADD_EXISTING_CONTACT: BASE_API + "/contact/add",
  SEARCH_CASE_CONTACT: BASE_API + "/search/contact/company?term=",
  //CLIENT'S PAYMENT SETTING
  FETCH_CLIENT_PAYMENT: BASE_API + "/payment/setting/client/",
  ADD_CLIENT_PAYMENT: BASE_API + "/payment/setting/client",

  //CONTACT RELATIONSHIP
  FETCH_CONTACTS_RELATIONSHIPS: BASE_API + "/relationship",
  ADD_CONTACT_RELATIONSHIP: BASE_API + "/relationship",
  UPDATE_CONTACT_RELATIONSHIP: BASE_API + "/relationship",

  //CONTACT FILES
  FETCH_CONTACT_FILES: BASE_API + "/document?refId=",
  ADD_CONTACT_FILES: BASE_API + "/contact/document",
  EDIT_CONTACT_FILES: BASE_API + "/contact/document",
  DELETE_FILES: BASE_API + "/document/",

  //CONTACT COMPANY TYPE
  FETCH_CONTACT_COMPANY_TYPE: BASE_API + "/company/type/all",
  ADD_CONTACT_COMPANY_TYPE: BASE_API + "/company/type",
  EDIT_CONTACT_COMPANY_TYPE: BASE_API + "/company/type",
  SEARCH_CONTACT_COMPANY_TYPE: BASE_API + "/company/type/search?term=",

  //CLIENT'S CASE CONTACTS
  FETCH_CLIENT_CASE_CONTACT: BASE_API + "/case/contact/client?clientId=",

  //CLIENT ON HOLD
  CLIENT_ON_HOLD: BASE_API + "/client/hold",

  //CLIENT ON DISCHARGE
  CLIENT_ON_DISCHARGE: BASE_API + "/client/discharged",

  //SEARCH OPTION VALUE
  SEARCH_EXISTING_OPTION_VALUE: BASE_API + "/option/value?term=",

  // SET ACCOUNT FOR COMPANY OR CONTACT
  SET_ACCOUNT_FOR_CONTACTS: BASE_API + "/account",

  // PERMISSION FOR RELATIONSHIP
  GET_PERMISSION_FOR_RELATIONSHIP: BASE_API + "/permission",
  ASSIGN_PERMISSION_FOR_RELATIONSHIP: BASE_API + "/permission/assign",

  //GET AND SET CONDITIONAL FORM BY REF ID IN ACTION
  GET_CONDITIONAL_ACTION_FORM_BY_REF_ID: BASE_API + "/conditional/form/",
  SET_CONDITIONAL_ACTION_FORM: BASE_API + "/conditional/form",

  //GET AND SET CONDITIONAL FORM BY REF ID IN CASE PAGE
  // GET_CONDITIONAL_FORM_BY_REF_ID_IN_CASE : BASE_API + '/conditional/form/data?caseId=',
  GET_CONDITIONAL_FORM_BY_REF_ID_IN_CASE:
    BASE_API + "/conditional/data?caseId=",
  SET_CONDITIONAL_FORM_BY_REF_ID_IN_CASE: BASE_API + "/conditional/form/data ",

  //GET AND SET CONDITIONAL FORM BY REF ID IN CASE PAGE BY CLIENT
  GET_CONDITIONAL_FORM_BY_REF_ID_BY_CLIENT:
    BASE_API + "/conditional/form/client/",
  SET_CONDITIONAL_FORM_BY_REF_ID_BY_CLIENT:
    BASE_API + "/conditional/form/client",

  //IMPORT CALENDAR
  IMPORT_HOLIDAY_CALENDAR: BASE_API + "/calendar/import?type=",

  //GET DATA FOR DASHBOARD
  GET_STATUS_FOR_DASHBOARD: BASE_API + "/case/stat",
  GET_TRANSACTION_STATUS: BASE_API + "/stat/transaction",
  GET_OTHER_STATUS_FOR_DASHBOARD: BASE_API + "/global/stat",

  //IMPORT TRANSACTION
  IMPORT_TRANSACTION: BASE_API + "/transaction/import",
  GET_BANK_DETAILS: BASE_API + "/bank/balance",

  //TRACK SHIPMENT
  TRACK_SHIPMENT: BASE_API + "/shipping?trackingId=",

  //EMAIL TEMPLATES
  POST_EMAIL_TEMPLATE: BASE_API + "/email/template",
  UPDATE_EMAIL_TEMPLATE: BASE_API + "/email/template",
  FETCH_EMAIL_TEMPLATE: BASE_API + "/email/template",
  DELETE_EMAIL_TEMPLATE: BASE_API + "/email/template/",

  //ASSIGN EMAIL TYPE
  ASSIGN_EMAIL_TYPE: BASE_API + "/template/assign",
  UPDATE_EMAIL_TYPE: BASE_API + "/template/assign",
  FETCH_EMAIL_TYPE: BASE_API + "/template/assign",

  //ASSIGN EVENTS TO CLIENTS/AGENTS
  SEARCH_CLIENTS_OR_AGENTS: BASE_API + "/account/search?term=",
  GET_ASSIGNED_CLIENTS_OR_AGENTS: BASE_API + "/event/assign/",
  ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS: BASE_API + "/assign/event ",

  //get events in client's or agent's calendar
  GET_ASSIGNED_EVENTS_TO_CLIENTS_OR_AGENTS_CALENDAR:
    BASE_API + "/assign/event/",

  //GET ASSIGNED EVENT IN CALENDAR
  GET_ASSIGNED_EVENT_IN_CALENDAR: BASE_API + "/assign/event/",

  //DOWNLOAD ACH DOCUMENT IN TRANSACTION
  DOWNLOAD_ACH_DOCUMENT: BASE_API + "/transaction/generated/",

  //ELIGIBILITY SETTING
  FETCH_ELIGIBILITY_DATA: BASE_API + "/eligibility/setting",
  ADD_ELIGIBILITY: BASE_API + "/eligibility/setting",
  EDIT_ELIGIBILITY: BASE_API + "/eligibility/setting",

  //ELIGIBILITY CALCULATION
  CALCULATE_ELIGIBILITY: BASE_API + "/calculate/eligibility",

  // DESCRIPTION SETTING
  DESCRIPTION_API: BASE_API + "/description",
  UPDATE_TRANSACTION_DESCRIPTION: BASE_API + "/transaction/description",
  DEFAULT_DESCRIPTION: BASE_API + "/description/default/",

  //VENDOR TYPE SETTING
  VENDOR_TYPE_API: BASE_API + "/vendor/type",
  FETCH_VENDOR_TYPE: BASE_API + "/vendor/type?term=",
  CHECK_VENDOR_TYPE: BASE_API + "/check/vendor/type?",

  //CHECKING ACCOUNTS SETTING
  FETCH_CHECKING_ACCOUNT_LIST: BASE_API + "/checking/account",
  ADD_CHECKING_ACCOUNT: BASE_API + "/checking/account",

  //FILTER AND EXPORT CASES
  FILTER_CASES: BASE_API + "/search/case?term=",
  EXPORT_CASES: BASE_API + "/case/export?term=",

  //NOTE FOR CLIENT AND CASES
  ADD_NOTE: BASE_API + "/note",
  UPDATE_NOTE: BASE_API + "/note",
  GET_NOTE_BY_ID: BASE_API + "/notes/",
  GET_NOTE: BASE_API + "/note",
  DELETE_NOTE: BASE_API + "/note/",

  // SEARCH CORPORATE
  SEARCH_SHIPPING_ADDRESS: BASE_API + "/address/shipping?term=",

  // SEARCH SHIPPING ADDRESS
  SEARCH_CORPORATE: BASE_API + "/corporates?term=",

  //GLOBAL SEARCH
  GLOBAL_SEARCH: BASE_API + "/global/search?term=",

  //GET DOCUMENTS
  GET_DOCUMENTS: BASE_API + "/global/document",

  //SEARCH DOCUMENT
  SEARCH_DOCUMENT: BASE_API + "/global/document?term=",

  //ACTIVITY LOG FOR EACH FIELD
  GET_ACTIVITY_LOG_BY_ID: BASE_API + "/activity/log/",
};

export default APIEndPoints;
