import { connect } from 'react-redux';
import PaymentSettingScreen from "../../screens/PaymentSettingScreen";
import React from "react";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = () => {
    console.log("dispatch")
};


export const PaymentSettingContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentSettingScreen);