import React, { Component } from "react";
import { Tabs } from "antd";
import TableComponent from "../components/TableComponent";
import { CircularProgress } from "@material-ui/core";
const { TabPane } = Tabs;

class GlobalNavigationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: false,
    };
  }

  render() {
    function callback(key) {
      localStorage.setItem("tabKey", key);
    }
    const dashboardState = this.props && this.props.state.dashBoardState;
    const globalNavigation = dashboardState && dashboardState.globalNavigation;
    console.log("***globalNavigation***", globalNavigation);
    const casesList =
      globalNavigation && globalNavigation.case !== undefined
        ? globalNavigation.case
        : [];
    const clientList =
      globalNavigation && globalNavigation.client !== undefined
        ? globalNavigation.client
        : [];
    const addressList =
      globalNavigation && globalNavigation.address !== undefined
        ? globalNavigation.address
        : [];
    const companyList =
      globalNavigation && globalNavigation.company !== undefined
        ? globalNavigation.company
        : [];
    const contactList =
      globalNavigation && globalNavigation.contact !== undefined
        ? globalNavigation.contact
        : [];
    const corporateList =
      globalNavigation && globalNavigation.corporate !== undefined
        ? globalNavigation.corporate
        : [];

    console.log("What's in props", this.props);

    return (
      <React.Fragment>
        {dashboardState && dashboardState.loading ? (
          <div
            className={
              dashboardState && dashboardState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <div style={{ marginTop: "15px", marginLeft: "3%", marginRight: "3%" }}>
          <h4 style={{ color: "#8BC34A" }}>Search Result</h4>
          <Tabs defaultActiveKey={this.state.tabValue} onChange={callback}>
            <TabPane tab="Cases" key="1">
              <TableComponent
                isCase={true}
                globalList={casesList}
                tableFirstHeader="Case Id"
                title1="Client"
                title2="Register Date"
                properties={this.props}
              />
            </TabPane>
            <TabPane tab="Clients" key="2">
              <TableComponent
                isClient={true}
                globalList={clientList}
                tableFirstHeader="Client Id"
                title1="Client"
                title2="Register Date"
                properties={this.props}
              />
            </TabPane>
            <TabPane tab="Contacts" key="3">
              <TableComponent
                isContact={true}
                globalList={contactList}
                tableFirstHeader="Full Name"
                title1="Email"
                title2="Phone Number"
                title3="Address"
                properties={this.props}
              />
            </TabPane>
            <TabPane tab="Company" key="4">
              <TableComponent
                isCompany={true}
                globalList={companyList}
                tableFirstHeader="Full Name"
                title1="Email"
                title2="Phone Number"
                title3="Address"
                properties={this.props}
              />
            </TabPane>
            <TabPane tab="Address" key="5">
              <TableComponent
                isAddress={true}
                globalList={addressList}
                tableFirstHeader="Primary Address"
                title="Secondary Address"
                title1="City"
                title2="State"
                title3="Zip Code"
                properties={this.props}
              />
            </TabPane>
            <TabPane tab="Corporate" key="6">
              <TableComponent
                isCorporate={true}
                globalList={corporateList}
                tableFirstHeader="Corporate Name"
                title1="Email"
                title2="Register Date"
                properties={this.props}
              />
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default GlobalNavigationTable;
