import { ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE } from "../constants/Type";

export const dynamicActionRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.DYNAMIC_ACTION_DATA_REQUEST_MADE,
    bool,
  };
};

export const handleChangeActionInputData = (inputtedData, actionFormId) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_ACTION_INPUT_DATA,
    inputtedData,
    actionFormId,
  };
};
export const fetchActionInfoSuccess = (actionInfo) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.FETCH_ACTION_INFO_SUCCESS,
    actionInfo,
  };
};
export const setPreviousTrue = (bool) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_PREVIOUS_VALUE_TO_TRUE,
    bool,
  };
};
export const addDynamicActionDataSuccess = (bool) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.ADD_DATA_SUCCESS,
    bool,
  };
};
export const addDynamicActionDataFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.ADD_DATA_FAILURE,
    error,
  };
};
export const markDynamicActionDataSuccess = (bool) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.MARK_DYNAMIC_ACTION_DATA_SUCCESS,
    bool,
  };
};
export const markDynamicActionDataFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.MARK_DYNAMIC_ACTION_DATA_FAILURE,
    error,
  };
};
export const handleChangeDynamicActionData = (actionFormData) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_DYNAMIC_ACTION_DATA,
    actionFormData,
  };
};
export const closeSnackBarForDynamicActionPage = (bool) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.CLOSE_SNACK_BAR_DYNAMIC_ACTION_PAGE,
    bool,
  };
};
export const setDynamicActionDataToEdit = (dynamicActionData) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_DYNAMIC_ACTION_DATA_TO_EDIT,
    dynamicActionData,
  };
};

export const updateFileSuccess = (data) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_PHOTO_SUCCESS,
    data,
  };
};

export const updateFileFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_PHOTO_FAILURE,
    error,
  };
};

export const handleChangeClientEmail = (email) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CLIENT_EMAIL,
    email,
  };
};

export const generateLinkSuccess = (response) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GENERATE_LINK_SUCCESS,
    response,
  };
};

export const generateLinkFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GENERATE_LINK_FAILURE,
    error,
  };
};

export const handleChangeImage = (id) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_IMAGE,
    id,
  };
};
export const handleChangeFileName = (id, fileName) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_FILE_NAME,
    id,
    fileName,
  };
};
export const handleChangeSelectedValue = (id, selectedValue) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_SELECTED_VALUE,
    id,
    selectedValue,
  };
};
export const handleChangeCheckBoxValue = (
  id,
  isSelected,
  data,
  fullData,
  option
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CHECKBOX_VALUE,
    id,
    isSelected,
    data,
    fullData,
    option,
  };
};

export const handleChangeContactValue = (id, contactData, selectedContact) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONTACT_VALUE,
    id,
    contactData,
    selectedContact,
  };
};
export const handleChangeDateOfBirth = (id, dateValue) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_DOB,
    id,
    dateValue,
  };
};
export const exportActionDataSuccess = (response) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.EXPORT_ACTION_DATA_SUCCESS,
    response,
  };
};
export const exportActionDataFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.EXPORT_ACTION_DATA_FAILURE,
    error,
  };
};

//case
export const getConditionalCaseFormSuccess = (
  conditionalCaseData,
  caseId,
  workflowId,
  refId,
  actionFormId,
  step,
  prevActionFormId,
  prevRefId,
  prevOptionName
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_SUCCESS,
    conditionalCaseData,
    caseId,
    workflowId,
    refId,
    actionFormId,
    step,
    prevActionFormId,
    prevRefId,
    prevOptionName,
  };
};

export const getConditionalCaseFormFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_FAILURE,
    error,
  };
};

export const setConditionalCaseFormSuccess = (response) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_DATA_FOR_CASE_SUCCESS,
    response,
  };
};

export const setConditionalCaseFormFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_DATA_FOR_CASE_FAILURE,
    error,
  };
};

export const setConditionalFormDataRequest = (
  conditionalFormDataRequest,
  setPrevious
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_FORM_DATA_REQUEST,
    conditionalFormDataRequest,
    setPrevious,
  };
};

export const handleChangeConditionalActionData = (
  data,
  selectedValue,
  refId,
  conditionalFormId
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_FORM_DATA,
    data,
    selectedValue,
    refId,
    conditionalFormId,
  };
};

export const handleChangeCheckBoxValueForConditionalForm = (
  conditionalFormId,
  isSelected,
  data,
  refId,
  parentRefId,
  parentActionFormId,
  conditionalFormDataIdList
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_FORM_CHECKBOX_DATA,
    conditionalFormId,
    isSelected,
    data,
    refId,
    parentRefId,
    parentActionFormId,
    conditionalFormDataIdList,
  };
};

export const updateConditionalFileSuccess = (
  photo,
  selectedValue,
  refId,
  conditionalFormId,
  fileName
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_CONDITIONAL_PHOTO_SUCCESS,
    photo,
    selectedValue,
    refId,
    conditionalFormId,
    fileName,
  };
};

export const updateConditionalFileFailure = (error) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.UPLOAD_CONDITIONAL_PHOTO_FAILURE,
    error,
  };
};

export const setConditionalFormDataToEmpty = () => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_FORM_DATA_TO_EMPTY,
  };
};

export const setConditionalFormSuccessMessage = (bool) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.SET_CONDITIONAL_SUCCESS,
    bool,
  };
};

export const handleSetSelectedOption = (conditionalFormId, data) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_SET_SELECTED_OPTION,
    conditionalFormId,
    data,
  };
};

export const handleChangeConditionalFileValue = (
  value,
  refId,
  conditionalFormId
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_FORM_FILE_NAME,
    value,
    refId,
    conditionalFormId,
  };
};

export const handleChangeConditionalContactValue = (
  conditionalFormId,
  contactData,
  selectedContact,
  refId
) => {
  return {
    type: ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE.HANDLE_CHANGE_CONDITIONAL_CONTACT_VALUE,
    conditionalFormId,
    contactData,
    selectedContact,
    refId,
  };
};
