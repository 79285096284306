import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class ClientAPI extends API {
  fetchClientList = (callback) => {
    fetch(APIEndPoints.FETCH_CLIENT, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchClientWithBalance = (callback) => {
    fetch(APIEndPoints.FETCH_CLIENT_WITH_BALANCE, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchCorporateClient = (id, callback) => {
    fetch(APIEndPoints.FETCH_CORPORATE_BY_ID + id, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  fetchSingleClient = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_SINGLE_CLIENT + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  addNewClient = (clientData, callback) => {
    let body = JSON.stringify(clientData);
    fetch(APIEndPoints.ADD_CLIENT, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  deleteClient = (clientId, callback) => {
    const urlString = APIEndPoints.DELETE_CLIENT + clientId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  // delete client in corporate id
  deleteClientInCorporate = (clientId, corporateId, callback) => {
    const urlString =
      APIEndPoints.DELETE_CLIENT_IN_CORPORATE +
      corporateId +
      "/client/" +
      clientId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  editClient = (clientData, callback) => {
    fetch(APIEndPoints.UPDATE_CLIENT, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(clientData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  searchClient = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_CLIENT + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterClient = (
    name,
    clientNo,
    caseCount,
    date,
    balance,
    isGreaterThan,
    isHold,
    isDischarged,
    fetchAll,
    term,
    callback
  ) => {
    const urlString =
      APIEndPoints.FILTER_CLIENT +
      name +
      "&clientNo=" +
      clientNo +
      "&caseCount=" +
      caseCount +
      "&date=" +
      date +
      "&balance=" +
      balance +
      "&compareType=" +
      isGreaterThan +
      "&isHold=" +
      isHold +
      "&isDischarged=" +
      isDischarged +
      "&fetchAll=" +
      fetchAll +
      "&term=" +
      term;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  saveConfig = (data, callback) => {
    const urlString =
      APIEndPoints.SAVE_FILTER_CONFIG +
      "name=" +
      data.name +
      "&clientNo=" +
      data.clientNo +
      "&caseCount=" +
      data.caseCount +
      "&date=" +
      data.date +
      "&balance=" +
      data.balance +
      "&compareType=" +
      data.compareType +
      "&configName=" +
      data.configName +
      "&type=" +
      data.type +
      "&sortBy=" +
      data.sortBy;
    fetch(urlString, {
      method: "POST",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  getConfigList = (type, callback) => {
    const urlString = APIEndPoints.GET_FILTER_CONFIG + type;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  setClientPassword = (data, callback) => {
    const urlString = APIEndPoints.SET_CLIENT_PASSWORD;
    fetch(urlString, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchFileListByClient = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_FILE_LIST_BY_CLIENT + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  updateClientProfilePicture = (data, callback) => {
    let url = APIEndPoints.UPDATE_PROFILE_CLIENT_PICTURE;
    fetch(url, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchCaseByClient = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_CASE_BY_CLIENT + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  addClientBankDetails = (data, callback) => {
    let url = APIEndPoints.ADD_CLIENT_BANK_DETAIL;
    fetch(url, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  deleteClientBankDetails = (id, callback) => {
    let url = APIEndPoints.DELETE_CLIENT_BANK_DETAILS + id;
    fetch(url, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  updateClientBankDetails = (data, callback) => {
    let url = APIEndPoints.UPDATE_CLIENT_BANK_DETAIL;
    fetch(url, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  //PAYMENT SETTING
  fetchClientPayment = (id, callback) => {
    const constUrl = APIEndPoints.FETCH_CLIENT_PAYMENT + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  addClientPayment = (data, callback) => {
    let url = APIEndPoints.ADD_CLIENT_PAYMENT;
    fetch(url, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  clientOnHold = (bool, callback) => {
    let url = APIEndPoints.CLIENT_ON_HOLD;
    fetch(url, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(bool),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  clientOnDischarge = (bool, callback) => {
    let url = APIEndPoints.CLIENT_ON_DISCHARGE;
    fetch(url, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(bool),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling patching user api", error);
        callback(null, "Service error, please try again.");
      });
  };

  getClientAssignedEvent = (id, callback) => {
    const constUrl =
      APIEndPoints.GET_ASSIGNED_EVENTS_TO_CLIENTS_OR_AGENTS_CALENDAR + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };

  //ELIGIBILITY CALCULATION
  calculateEligibility = (eligibilityData, callback) => {
    let url = APIEndPoints.CALCULATE_ELIGIBILITY;
    fetch(url, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(eligibilityData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling patching user api", error);
        callback(null, "Service error, please try again.");
      });
  };

  //NOTES
  fetchNotes = (callback) => {
    fetch(APIEndPoints.GET_NOTE, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling user api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchNotesById = (id, callback) => {
    fetch(APIEndPoints.GET_NOTE_BY_ID + id, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling user api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  addNewNote = (data, callback) => {
    let url = APIEndPoints.ADD_NOTE;
    fetch(url, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling patching user api", error);
        callback(null, "Service error, please try again.");
      });
  };
  updateNotes = (noteDescription, callback) => {
    let body = JSON.stringify(noteDescription);
    fetch(APIEndPoints.UPDATE_NOTE, {
      method: "PATCH",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling patching user api", error);
        callback(null, "Service error, please try again.");
      });
  };
  deleteNote = (noteId, callback) => {
    const urlString = APIEndPoints.DELETE_NOTE + noteId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  // CLIENT'S NOTIFICATION SETTING
  saveEnabledNotification = (enabledData, callback) => {
    let body = JSON.stringify(enabledData);
    fetch(APIEndPoints.SAVE_ENABLED_NOTIFICATION, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add client api", error);
        callback(null, "Service error, please try again.");
      });
  };

  addClientNotes = (noteData, callback) => {
    fetch(APIEndPoints.ADD_CLIENT_NOTES, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(noteData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log(error);
        callback(null, "Service error, please try again.");
      });
  };
}

export default new ClientAPI();
