import {
  ACTION_TYPE_ACTIVITY_LOG,
  ACTION_TYPE_UPCOMING_TRANSACTION,
} from "../constants/Type";

const initialState = {
  loading: false,
  error: "",
  openProcessSnackBar: false,
  openErrorProcessSnackBar: false,
  upcomingTrans: [],
  annualUpComingTransactionData: [],
  // clientUpcomingTransaction: [],
  openModalToSkipTransaction: false,
  remarkToSkip: "",
  filterDate: null,
  filterAmount: "",
  filterUser: "",
  filterVendor: "",
  filterMedium: "",
  filterType: "",
  filterSchedule: null,
  compareType: "0",
};

const upcomingTransactionState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_UPCOMING_TRANSACTION.UP_COMING_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.CLOSE_SNACK_BAR:
      return {
        ...state,
        openProcessSnackBar: false,
        openErrorProcessSnackBar: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_UP_COMING_TRANSACTION_SUCCESS:
      return {
        ...state,
        upcomingTrans: action.upComingTransactionData.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_UP_COMING_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_ANNUAL_UP_COMING_TRANSACTION_SUCCESS:
      return {
        ...state,
        annualUpComingTransactionData:
          action.annualUpComingTransactionData.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_ANNUAL_UP_COMING_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.PROCESS_TRANSACTION_SUCCESS:
      return {
        ...state,
        response: action.response,
        openProcessSnackBar: true,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.PROCESS_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action.error,
        openErrorProcessSnackBar: true,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_TERM_SUCCESS:
      return {
        ...state,
        upcomingTrans: action.searchRes.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_TERM_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_SUCCESS:
      return {
        ...state,
        annualUpComingTransactionData: action.searchRes.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        upcomingTrans: action.searchRes.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        upcomingTrans: action.searchRes.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
      return {
        ...state,
        upcomingTrans: action.searchRes.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_CLIENT_UP_COMING_TRANSACTION_SUCCESS:
      return {
        ...state,
        clientUpcomingTransaction: action.response.scheduleTransactions,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_CLIENT_UP_COMING_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.HANDLE_CHANGE_OPEN_MODAL_TO_SKIP_TRANSACTION:
      return {
        ...state,
        openModalToSkipTransaction: action.bool,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.HANDLE_CHANGE_REMARK_TO_SKIP:
      return {
        ...state,
        remarkToSkip: action.remarkToSkip,
      };

    // new filter
    case ACTION_TYPE_UPCOMING_TRANSACTION.HANDLE_UP_TRANS_FILTER:
      return {
        ...state,
        [action.id]: action.value,
      };
    case ACTION_TYPE_UPCOMING_TRANSACTION.CLEAR_UP_TRANS_FILTER:
      return {
        ...state,
        filterDate: null,
        filterAmount: "",
        filterUser: "",
        filterVendor: "",
        filterMedium: "",
        filterType: "",
        filterSchedule: null,
        compareType: "0",
      };
    default:
      return state;
  }
};

export default upcomingTransactionState;
