import {
  ACTION_TYPE_CLIENT,
  ACTION_TYPE_SCHEDULE_TRANSACTION,
} from "../constants/Type";

const initialState = {
  loading: false,
  openSuccessSnackBar: false,
  openErrorSnackBar: false,
  openScheduleTransactionSnackBar: false,
  openScheduleTransactionSuccessSnackBar: false,
  scheduleTransaction: [],
  schedule: {},
  errorMsg: "",
  successMsg: "",
  status: "",
  scheduleStatus: "UNKNOWN_SCHEDULE_STATUS",
  scheduleType: "UNKNOWN_SCHEDULE_TYPE",
  startDate: null,
  endDate: null,
  scheduleTransId: "",
  mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
  transMediumType: "",
  transType: "UNKNOWN_TRANSACTION_TYPE",
  term: "",
  vendorToFilter: "",
  totalScheduleTransaction: "",
  clientId: "",
  fileUrl: "",
  clientRes: {},
  bankDetailId: "",
  clientList: [],
  scheduleTransactionInputData: {
    clientId: "",
    amount: 0,
    scheduleType: "",
    scheduledDate: null,
    endDate: null,
    transactionMedium: "",
    transactionType: "",
    vendorId: "",
    description: "",
    note: "",
    isLedger: false,
  },
  addScheduleTransactionSuccess: false,
  openModalToScheduleTransaction: false,
  filterStartDate: null,
  filterEndDate: null,
  filterAmount: "",
  filterName: "",
  filterVendor: "",
  filterMedium: "",
  filterType: "",
  filterStatus: "",
  filterScheduleDate: "",
  compareType: "0",
  configList: [],
};

const scheduleTransactionState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SCHEDULE_TRANSACTION_REQUEST_MADE:
      return {
        ...state,
        loading: action.bool,
      };

    case ACTION_TYPE_SCHEDULE_TRANSACTION.CLOSE_SNACK_BAR_SCHEDULE_PAGE:
      return {
        ...state,
        openSuccessSnackBar: false,
        openErrorSnackBar: false,
        openScheduleTransactionSnackBar: false,
        openScheduleTransactionSuccessSnackBar: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_SHOW_FILE_UPLOAD_FAILURE_MESSAGE:
      return {
        ...state,
        openScheduleTransactionSnackBar: true,
        errorMsg: action.error,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_SUCCESS:
      return {
        ...state,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        openScheduleTransactionSnackBar: true,
        errorMsg: action.error,
      };

    case ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS:
      return {
        ...state,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        clientId: action.scheduleTransaction.clientId,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        // openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_DETAILS:
      const newScheduleDetails = { ...state.scheduleDetail };
      newScheduleDetails[action.id] = action.value;
      return {
        ...state,
        scheduleDetail: newScheduleDetails,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_TRANSACTION_MEDIUM:
      return {
        ...state,
        transMediumType: action.transMediumType,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_VENDOR_TO_FILTER:
      return {
        ...state,
        vendorToFilter: action.vendorToFilter,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_TRANSACTION_TYPE:
      return {
        ...state,
        transType: action.transType,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_OTHER_SCHEDULE_TRANSACTION:
      const newScheduleTransactionDetails = {
        ...state.otherScheduleTransaction,
      };
      newScheduleTransactionDetails[action.id] = action.value;
      return {
        ...state,
        scheduleTransactionInputData: newScheduleTransactionDetails,
        otherScheduleTransaction: newScheduleTransactionDetails,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_TRANSACTION_INPUT_DATA:
      const newScheduleTransactionInput = {
        ...state.scheduleTransactionInputData,
      };
      newScheduleTransactionInput[action.id] = action.input;
      return {
        ...state,
        scheduleTransactionInputData: newScheduleTransactionInput,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_SCHEDULE_TRANSACTION_INPUT_DATA:
      return {
        ...state,
        scheduleTransactionInputData: {
          clientId: "",
          amount: 0,
          scheduleType: "",
          scheduledDate: new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(
            2,
            0,
            0
          ),
          // endDate: new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(
          //   23,
          //   59,
          //   59
          // ),
          // endDate: new Date(24 * 60 * 60 * 1000).setHours(23, 59, 59),
          endDate: "",
          transactionMedium: "",
          transactionType: "",
          vendorId: "",
          description: "",
          note: "",
          bankId: "",
        },
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SET_SCHEDULE_TRANSACTION_INPUT_DATA_TO_EDIT:
      const scheduledData = action.scheduledData;
      return {
        ...state,
        scheduleTransactionInputData: {
          scheduleTransactionId:
            scheduledData && scheduledData.scheduleTransactionId,
          clientId:
            scheduledData &&
            scheduledData.client &&
            scheduledData.client.clientId,
          clientName:
            scheduledData &&
            scheduledData.client &&
            scheduledData.client.firstName +
              " " +
              scheduledData.client.lastName,
          amount: scheduledData && scheduledData.amount,
          scheduleType:
            scheduledData &&
            scheduledData.scheduleDetail &&
            scheduledData.scheduleDetail.scheduleType,
          scheduledDate:
            scheduledData &&
            scheduledData.scheduleDetail &&
            scheduledData.scheduleDetail.scheduledDate,
          endDate:
            scheduledData &&
            scheduledData.scheduleDetail &&
            scheduledData.scheduleDetail.endDate,
          transactionMedium: scheduledData && scheduledData.transactionMedium,
          transactionType: scheduledData && scheduledData.transactionType,
          vendorId:
            scheduledData &&
            scheduledData.vendorAccount &&
            scheduledData.vendorAccount.vendorId,
          vendorName:
            scheduledData &&
            scheduledData.vendorAccount &&
            scheduledData.vendorAccount.vendorName,
          description: scheduledData && scheduledData.description,
          note: scheduledData && scheduledData.note,
          isLedger: scheduledData && scheduledData.isLedger,
          bankId: scheduledData && scheduledData.bankDetailId,
        },
        fileUrl:
          scheduledData && scheduledData.document ? scheduledData.document : "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_CLIENT_NAME:
      return {
        ...state,
        clientId: action.clientId,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.ADD_SCHEDULE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        addScheduleTransactionSuccess: action.bool,
        transType: "UNKNOWN_TRANSACTION_TYPE",
        transMediumType: "",
        openSuccessSnackBar: true,
        successMsg: "Successfully Scheduled",
        openModalToScheduleTransaction: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.ADD_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        openErrorSnackBar: true,
        openScheduleTransactionSnackBar: true,
        openScheduleTransactionSuccessSnackBar: false,
        openModalToScheduleTransaction: true,
        errorMsg: action.error,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addScheduleTransaction: action.bool,
        openScheduleTransactionSnackBar: false,
        openScheduleTransactionSuccessSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        openScheduleTransactionSnackBar: true,
        openScheduleTransactionSuccessSnackBar: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.DELETE_SCHEDULE_TRANSACTION_SUCCESS:
      const oldScheduleList = state.scheduleTransaction;
      let newSchedule = [];
      for (let i = 0; i < oldScheduleList.length; i++) {
        if (
          oldScheduleList[i].scheduleTransactionId !==
          action.scheduleTransactionId
        ) {
          newSchedule.push(oldScheduleList[i]);
        }
      }
      return {
        ...state,
        newSchedule: newSchedule,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.DELETE_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        errorMsg: action.error,
        loading: false,
        openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SET_SCHEDULE_TRANSACTION_TO_EDIT:
      return {
        ...state,
        schedule: action.schedule.scheduleTransaction,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        clientList: action.clientList.clients ? action.clientList.clients : [],
        openScheduleTransactionSnackBar: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_STATUS:
      return {
        ...state,
        loading: false,
        status: action.status,
        scheduleTransId: action.id,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.UPDATE_SCHEDULE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action.response,
        addScheduleTransactionSuccess: action.bool,
        openSuccessSnackBar: true,
        successMsg: "Successfully Updated",
        openModalToScheduleTransaction: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.UPDATE_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openErrorSnackBar: true,
        openModalToScheduleTransaction: true,
      };
    //EXPORT
    case ACTION_TYPE_SCHEDULE_TRANSACTION.EXPORT_SCHEDULE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        openSuccessSnackBar: true,
        successMsg: "Successfully Exported",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.EXPORT_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        openErrorSnackBar: true,
        errorMsg: action.error,
      };
    //*****HANDLE CHANGES
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_FROM_DATE:
      return {
        ...state,
        loading: false,
        startDate: action.from,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_TO_DATE:
      return {
        ...state,
        loading: false,
        endDate: action.to,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_MEDIUM:
      return {
        ...state,
        loading: false,
        mediumType: action.medium,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_STATUS_TO_FILTER:
      return {
        ...state,
        loading: false,
        scheduleStatus: action.status,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_TYPE:
      return {
        ...state,
        loading: false,
        scheduleType: action.scheduleType,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_SCHEDULE_TERM:
      return {
        ...state,
        loading: false,
        term: action.term,
      };
    //*****SEARCH SCHEDULE TRANSACTION
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        // openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        startDate: null,
        endDate: null,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //by status
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // scheduleStatus: "",
        // term:"",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //by medium and status
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "",
        // scheduleStatus: "",
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //by medium and date
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "",
        // startDate: null,
        // endDate: null,
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //by status and date
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // scheduleStatus: "",
        // startDate: null,
        // endDate: null,
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //by medium and status and date and type
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        // scheduleStatus: "UNKNOWN_SCHEDULE_STATUS",
        // startDate: null,
        // endDate: null,
        // scheduleType: "UNKNOWN_SCHEDULE_TYPE",
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_SCHEDULE_TRANSACTION_BY_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_SCHEDULE_TRANSACTION_FORM:
      return {
        ...state,
        // loading: false,
        mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        scheduleStatus: "UNKNOWN_SCHEDULE_STATUS",
        startDate: null,
        endDate: null,
        scheduleType: "UNKNOWN_SCHEDULE_TYPE",
        transType: "UNKNOWN_TRANSACTION_TYPE",
        term: "",
      };

    //****SEARCH SCHEDULE TRANSACTION BY CLIENT
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleTransaction: action.scheduleTransaction.scheduleTransactions,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    //DATE
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // startDate: null,
        // endDate: null,
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //MEDIUM
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "",
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //STATUS
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // scheduleStatus: "",
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //MEDIUM and status
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "",
        // scheduleStatus: "",
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //MEDIUM and Date
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "",
        // startDate: null,
        // endDate: null,
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //STATUS
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // scheduleStatus: "",
        // startDate: null,
        // endDate: null,
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    //MEDIUM and STATUS and DATE
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        clientScheduleTrans: action.scheduleTransaction.scheduleTransactions,
        totalScheduleTransaction: action.scheduleTransaction.totalCount,
        // mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        // scheduleStatus: "UNKNOWN_SCHEDULE_STATUS",
        // startDate: null,
        // endDate: null,
        // scheduleType: "UNKNOWN_SCHEDULE_TYPE",
        // term: "",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.UPLOAD_DOCUMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        fileUrl: action.response.fileUrl,
        // openScheduleTransactionSuccessSnackBar: true,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.UPLOAD_DOCUMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openScheduleTransactionSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.FETCH_SINGLE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientRes: action.response.client,
      };
    case ACTION_TYPE_CLIENT.FETCH_SINGLE_CLIENT_FAILURE:
      return {
        ...state,
        errorMsg: action.error,
        clientRes: {},
        loading: false,
      };
    case ACTION_TYPE_CLIENT.CLEAR_SINGLE_CLIENT_DATA:
      return {
        ...state,
        loading: false,
        clientRes: {},
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_CHANGE_BANK_DETAIL_NAME:
      return {
        ...state,
        bankDetailId: action.name,
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_FILE_DATA:
      return {
        ...state,
        fileUrl: "",
        loading: false,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SET_OPEN_MODAL_TO_SCHEDULE_TRANSACTION:
      return {
        ...state,
        loading: false,
        openModalToScheduleTransaction: action.bool,
      };
    // new filter
    case ACTION_TYPE_SCHEDULE_TRANSACTION.HANDLE_SCH_TRANS_FILTER:
      return {
        ...state,
        loading: false,
        [action.id]: action.value,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.CLEAR_SCH_TRANS_FILTER:
      return {
        ...state,
        filterStartDate: null,
        filterEndDate: null,
        filterAmount: "",
        filterName: "",
        filterVendor: "",
        filterMedium: "",
        filterType: "",
        filterStatus: "",
        filterScheduleDate: "",
        compareType: "0",
      };

    // config
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SAVE_SCH_TRANS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        openSuccessSnackBar: true,
        successMsg: "Successfully Saved",
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.SAVE_SCH_TRANS_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.GET_SCH_TRANS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        configList: action.payload,
      };
    case ACTION_TYPE_SCHEDULE_TRANSACTION.GET_SCH_TRANS_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    default:
      return state;
  }
};
export default scheduleTransactionState;
