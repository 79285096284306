import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { DatePicker, Select } from "antd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TablePagination from "@material-ui/core/TablePagination";
import MySnackbarContentWrapper from "../components/Snackbar";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DialogContentText from "@material-ui/core/DialogContentText";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import moment from "moment";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers from "../components/Helper";
import OpenAddressFields from "../components/DynamicAddressField";
import OpenSocialHandleFields from "../components/DynamicSocialHandleField";
import ButtonComponent from "./ButtonComponent";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {getFormattedDateValue} from "../utils/DateConverter";

const { Option } = Select;

class IndividualContactScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openActionMenu: false,
      popoverElement: null,
      contactData: {},
      socialHandles: [],
      editSocialHandle: [],
      setValue: false,
      clicked: false,
      newSocialHandleId: "",
      updatedSocialHandle: [],
      errors: {
        name: "",
        emailPhone: "",
        contactType: "",
        dob: "",
        contactNumber: "",
        address: "",
        userName: "",
      },
      address: [],
      updatedAddress: [],
      openDeleteModal: false,
      addressId: "",
    };
  }
  componentDidMount() {
    this.props.fetchContactList();
  }

  render() {
    const contactState =
      this.props.propsState && this.props.propsState.contactState;
    const contactData = contactState && contactState.contactData;
    const contactsList = contactState && contactState.contactList;
    const contactValue =
      contactData.contactType !== "" &&
      contactData.emailPhone !== "" &&
      contactData.name !== "";
    const loading = contactState && contactState.loading;
    const { errors } = this.state;
    console.log({ errors });

    const handleChangeDateOfBirth = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        const eventValue = {
          target: {
            name: "dob",
            value: new Date(valueOfInput1).getTime(),
          },
        };
        Helpers.handleValidationOnChange(eventValue, errors);
        this.props.handleChangeContactInput(
          "dob",
          new Date(valueOfInput1).getTime()
        );
      } else {
        this.props.handleChangeContactInput("dob", new Date(0).getTime());
      }
    };
    const getDate = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };
    const handleChangeSelect = (value) => {
      this.props.handleChangeContactInput("contactType", value);
    };

    const addIndividualContact = () => {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
        });
        this.props.addIndividualContact(contactData);
        setTimeout(() => {
          this.setState({
            clicked: false,
          });
        }, 3000);
      }
    };

    //***************** address data ******************** //
    const showInputForField = () => {
      let a = createAddressObject();
      this.setState({
        address: [...this.state.address, a],
      });
    };
    const createAddressObject = () => {
      return {
        addressId: String(Math.random()),
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip5: "",
      };
    };
    const filterAddressData = () => {
      let addressForm = [];
      this.state.address.map((single) => {
        const data = {
          address1: single.address1,
          address2: single.address2,
          city: single.city,
          state: single.state,
          zip5: single.zip5,
        };
        return addressForm.push(data);
      });
      this.props.handleChangeContactInput("address", addressForm);
    };
    const filterEditAddressData = () => {
      let resultA = this.state.updatedAddress.filter((elm) =>
        this.state.address
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
      );
      let resultB = this.state.address
        .filter(
          (elm) =>
            !this.state.updatedAddress
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        )
        .map((single) => {
          const data = {
            address1: single.address1,
            address2: single.address2,
            city: single.city,
            state: single.state,
            zip5: single.zip5,
          };
          return data;
        });
      let addressForm = [...resultA, ...resultB];
      this.props.handleChangeContactInput("address", addressForm);
    };
    const handleOpenDeleteDialogue = (addressId) => {
      this.setState({
        openDeleteModal: true,
        addressId: addressId,
      });
    };
    const handleCloseDeleteDialogue = () => {
      this.setState({
        openDeleteModal: false,
        addressId: "",
      });
    };
    const handleOkDeleteDialogue = () => {
      closeFieldSection(this.state.addressId);
      this.setState({
        openDeleteModal: false,
        addressId: "",
      });
    };
    const closeFieldSection = (key) => {
      var removedActionData = this.state.address.filter(
        (single) => single.addressId !== key
      );
      this.setState(
        {
          address: removedActionData,
        },
        () => {
          filterAddressData();
        }
      );
    };

    //***************** social handle data ******************** //
    const createSocialHandleObject = () => {
      return {
        socialHandleId: String(Math.random()),
        username: "",
        socialHandleType: "",
      };
    };
    const showSocialHandleField = () => {
      let newObj = createSocialHandleObject();
      this.setState({
        socialHandles: [...this.state.socialHandles, newObj],
      });
    };
    const filterSocialHandleData = () => {
      let socialHandleForm = [];
      this.state.socialHandles.map((single) => {
        const data = {
          username: single.username,
          socialHandleType: single.socialHandleType,
        };
        return socialHandleForm.push(data);
      });
      this.props.handleChangeContactInput("socialHandle", socialHandleForm);
    };
    const filterEditSocialHandleData = () => {
      let resultA = this.state.updatedSocialHandle.filter((elm) =>
        this.state.socialHandles
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
      );
      let resultB = this.state.socialHandles
        .filter(
          (elm) =>
            !this.state.updatedSocialHandle
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        )
        .map((single) => {
          const data = {
            username: single.username,
            socialHandleType: single.socialHandleType,
          };
          return data;
        });
      let socialHandleForm = [...resultA, ...resultB];
      this.props.handleChangeContactInput("socialHandle", socialHandleForm);
    };
    const handleClickOpenDeleteSocialHandleDialogue = (socialHandleId) => {
      this.setState({
        openDeleteSocialHandleDialogue: true,
        socialHandleId: socialHandleId,
      });
    };
    const handleCloseDeleteSocialHandleDialogue = () => {
      this.setState({
        openDeleteSocialHandleDialogue: false,
        socialHandleId: "",
      });
    };
    const handleOkDeleteSocialHandleDialogue = () => {
      closeSocialHandleFieldSection(this.state.socialHandleId);
      this.setState({
        openDeleteSocialHandleDialogue: false,
        socialHandleId: "",
      });
    };
    const closeSocialHandleFieldSection = (key) => {
      var removedActionData = this.state.socialHandles.filter(
        (single) => single.socialHandleId !== key
      );
      this.setState(
        {
          socialHandles: removedActionData,
        },
        () => {
          filterSocialHandleData();
        }
      );
    };

    return (
      <React.Fragment>
        {this.state.setValue === false
          ? contactData &&
            contactData.socialHandle &&
            contactData.socialHandle.length > 0
            ? this.setState({
                editContacts: contactData.socialHandle,
                updatedSocialHandle: contactData.socialHandle,
                setValue: true,
              })
            : this.setState({
                editContacts: [],
                updatedSocialHandle: [],
                setValue: true,
              })
          : null}

        {contactState && contactState.loading ? (
          <div
            className={
              contactState && contactState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        <div style={{ margin: "0 3% 2%" }}>
          <div className={"d-flex flex-row-reverse div-flex"}>
            <div>
              <button
                className="addButton"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.handleChangeOpenModalToAddContact();
                  this.setState({
                    socialHandles: [],
                    address: [],
                    errors: {
                      name: "",
                      emailPhone: "",
                      contactType: "",
                      dob: "",
                      contactNumber: "",
                      address: "",
                      userName: "",
                    },
                  });
                }}
              >
                <AddIcon /> Add Contact
              </button>
            </div>
            <div className="search">
              <div className="searchIcon">
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                className="inputBase"
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value === "" || e.target.value === " ") {
                    this.props.fetchContactList();
                  } else {
                    this.props.searchIndividualContact(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header-second">
              <Table size="small" stickyHeader aria-label="sticky table" ref={this.props.tableRef}>
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell
                      className="first-tableHeader"
                      style={{ width: "410px" }}
                    >
                      Full Name
                    </TableCell>
                    <TableCell className="tableHeader">Email</TableCell>
                    <TableCell className="tableHeader">Phone Number</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {contactsList &&
                  contactsList
                    .slice(
                      this.props.page * this.props.rowsPerPage,
                      this.props.page * this.props.rowsPerPage +
                        this.props.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody key={list.contactId}>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            {list.name}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.emailPhone}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.phone ? list.phone : "-"}
                          </TableCell>
                          <TableCell>
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    popoverElement: e.currentTarget,
                                    openActionMenu: true,
                                    contactData: list,
                                  },
                                  () => {
                                    this.props.setContactInputToEdit(
                                      this.state.contactData
                                    );
                                  }
                                );
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <MenuItem
                                  style={{ padding: "5px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeOpenModalToSetContactAccount();
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <AccountBoxIcon
                                    style={{ marginRight: "8px" }}
                                  />{" "}
                                  Set Account
                                </MenuItem>
                                <MenuItem
                                  style={{ padding: "5px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.property.history.push({
                                      pathname:
                                        "/contact/" +
                                        this.state.contactData.contactId +
                                        "/files",
                                    });
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DescriptionIcon
                                    style={{ marginRight: "8px" }}
                                  />{" "}
                                  Files
                                </MenuItem>
                                <MenuItem
                                  style={{ padding: "5px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeOpenModalToEditContact();
                                    if (
                                      contactData &&
                                      contactData.socialHandle !== undefined
                                    ) {
                                      this.setState({
                                        socialHandles: contactData.socialHandle,
                                        editSocialHandle:
                                          contactData.socialHandle,
                                        updatedSocialHandle:
                                          contactData.socialHandle,
                                        errors: {
                                          name: "",
                                          emailPhone: "",
                                          contactType: "",
                                          dob: "",
                                          contactNumber: "",
                                          address: "",
                                          userName: "",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        editSocialHandle: [],
                                        socialHandles: [],
                                        errors: {
                                          name: "",
                                          emailPhone: "",
                                          contactType: "",
                                          dob: "",
                                          contactNumber: "",
                                          address: "",
                                          userName: "",
                                        },
                                      });
                                    }
                                    if (
                                      contactData &&
                                      contactData.address !== undefined
                                    ) {
                                      this.setState({
                                        address: contactData.address,
                                        editAddress: contactData.address,
                                        updatedAddress: contactData.address,
                                      });
                                    } else {
                                      this.setState({
                                        address: [],
                                        editAddress: [],
                                      });
                                    }
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ marginRight: "8px" }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  style={{ padding: "5px" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // handleOpenModalToDelete();
                                    this.props.handleChangeOpenModalToDeleteContact();
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    style={{ marginRight: "8px" }}
                                  />{" "}
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
              </TableContainer>
              {contactsList && contactsList.length > 0 ? (
                <div style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={contactsList.length > 0 ? contactsList.length : 100}
                    rowsPerPage={this.props.rowsPerPage}
                    page={this.props.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={this.props.handleChangePage}
                    onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div style={{     
                  display: "flex", 
                  width: "100%",
                  justifyContent:"center"
                  }}>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "1rem",
                      color: "#606060",
                    }}
                  >
                    {" "}
                    No data to show
                  </h3>
                </div>
              )}
            {/* </TableContainer> */}
          </div>
        </div>

        {/*add contact*/}
        <Dialog
          open={this.props.openModalToAddContact}
          onClose={this.props.handleChangeCloseModalToAddContact}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={this.props.handleChangeCloseModalToAddContact}
            >
              Add Contact
            </DialogTitleComponent>
            <DialogContent
              dividers
              className="dialogContentWidth850"
              style={{
                maxHeight: "562px",
                paddingTop: "0px",
                borderBottom: "none",
              }}
            >
              <div className="row">
                <div className="col-6">
                  <label className="label">
                    Full Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="inputField"
                    autoComplete="off"
                    onChange={(e) => {
                      this.props.handleChangeContactInput(
                        e.target.id,
                        e.target.value
                      );
                      Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                  {errors.name.length > 0 && (
                    <span className="error">{errors.name}</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="label">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="emailPhone"
                    name="emailPhone"
                    className="inputField"
                    autoComplete="off"
                    onChange={(e) => {
                      this.props.handleChangeContactInput(
                        e.target.id,
                        e.target.value
                      );
                      Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                  {errors.emailPhone.length > 0 && (
                    <span className="error">{errors.emailPhone}</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="label">
                    Contact Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="selects h40">
                    <Select
                      id="contactType"
                      className="drop"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        handleChangeSelect(e);
                      }}
                    >
                      <Option value="EVERFUND_USER_CONTACT">
                        Everfund user
                      </Option>
                      <Option value="CORPORATE_CONTACT">Corporate</Option>
                      <Option value="CLIENT_CONTACT">Client</Option>
                      <Option value="CLIENT_RELATIVE_CONTACT">
                        Client Relative
                      </Option>
                      <Option value="OTHER_CONTACT_TYPE">
                        Other contact type
                      </Option>
                    </Select>
                  </div>
                </div>
                <div className="col-6">
                  <label className="label">Phone number</label>
                  <input
                    id="phone"
                    className="inputField"
                    name="contactNumber"
                    autoComplete="off"
                    onChange={(e) => {
                      this.props.handleChangeContactInput(
                        e.target.id,
                        e.target.value
                      );
                      Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                  {errors.contactNumber.length > 0 && (
                    <span className="error">{errors.contactNumber}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="label">Date-of-birth</label>
                  <div className="select-add-date">
                    <KeyBoardDatePickerComponent
                        isContacts
                        dateValue={
                          contactData && contactData.dob
                              ? getFormattedDateValue(contactData.dob)
                              : null
                        }
                        onChangeDate={(data) => {
                          this.props.handleChangeContactInput(
                              "dob",
                              data
                          );
                        }}
                    />
                  </div>
                </div>
              </div>
              {!(this.state.socialHandles.length > 0) ? (
                <div
                  className="addFieldOuterDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showSocialHandleField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    Add Social Handle
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenSocialHandleFields
                    fieldArray={this.state.socialHandles}
                    filterSocialHandleData={filterSocialHandleData}
                    handleOpenDeleteDialogue={
                      handleClickOpenDeleteSocialHandleDialogue
                    }
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showSocialHandleField();
                      }}
                    >
                      {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                      {/*     alt="Add Button"*/}
                      {/*     className="addButtonIcon"/>*/}
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
              {!(this.state.address.length > 0) ? (
                <div
                  className="addFieldOuterDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span className="addFieldSpan">Add Address</span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenAddressFields
                    fieldArray={this.state.address}
                    filterAddressData={filterAddressData}
                    handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForField();
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
              <ButtonComponent
                onClick={(e) => {
                  e.preventDefault();
                  addIndividualContact();
                }}
                value={contactValue}
                buttonName="Add"
                loading={loading}
              />
            </DialogActions>
          </div>
        </Dialog>
        {/*edit contact*/}
        <Dialog
          open={this.props.openModalToEditContact}
          onClose={() => {
            this.props.handleChangeCloseModalToEditContact();
            this.setState({
              address: [],
            });
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={this.props.handleChangeCloseModalToEditContact}
            >
              Update Contact
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContentWidth850">
              <div className="row">
                <div className="col-6">
                  <label className="label">
                    Full Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={contactData && contactData.name}
                    className="inputField"
                    autoComplete="off"
                    onChange={(e) => {
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeContactInput(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.name.length > 0 && (
                    <span className="error">{errors.name}</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="label">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="emailPhone"
                    name="emailPhone"
                    value={contactData && contactData.emailPhone}
                    className="inputField"
                    autoComplete="off"
                    onChange={(e) => {
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeContactInput(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.emailPhone.length > 0 && (
                    <span className="error">{errors.emailPhone}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="label">Phone number</label>
                  <input
                    id="phone"
                    value={contactData && contactData.phone}
                    className="inputField"
                    name="contactNumber"
                    autoComplete="off"
                    onChange={(e) => {
                      Helpers.handleValidationOnChange(e, errors);
                      this.props.handleChangeContactInput(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.contactNumber.length > 0 && (
                    <span className="error">{errors.contactNumber}</span>
                  )}
                </div>
                <div className="col-6">
                  <label className="label">Date-of-birth</label>
                  <div className="select-add-date">
                    <KeyBoardDatePickerComponent
                        isContacts
                        dateValue={
                          contactData && contactData.dob
                              ? getFormattedDateValue(contactData.dob)
                              : null
                        }
                        onChangeDate={(data) => {
                          this.props.handleChangeContactInput(
                              "dob",
                              data
                          );
                        }}
                    />
                  </div>
                </div>
              </div>
              {!(
                this.state.socialHandles && this.state.socialHandles.length > 0
              ) ? (
                <div
                  style={{ cursor: "pointer", marginTop: "1.5rem" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showSocialHandleField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    style={{ width: "22px", height: "22px", cursor: "pointer" }}
                  />
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    Add Social Handle
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenSocialHandleFields
                    fieldArray={this.state.socialHandles}
                    isEdit={true}
                    filterEditSocialHandleData={filterEditSocialHandleData}
                    handleOpenDeleteDialogue={
                      handleClickOpenDeleteSocialHandleDialogue
                    }
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showSocialHandleField();
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
              {!(this.state.address.length > 0) ? (
                <div
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                    Add Address
                  </span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenAddressFields
                    fieldArray={this.state.address}
                    isEdit={true}
                    filterEditAddressData={filterEditAddressData}
                    handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForField();
                      }}
                    >
                      {/*<img src="/assets/images/add-button-inside-circle.svg"*/}
                      {/*     alt="Add Button"*/}
                      {/*     className="addButtonIcon"/>*/}
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
              <ButtonComponent
                onClick={(e) => {
                  e.preventDefault();
                  this.props.editIndividualContact(contactData);
                }}
                value={contactValue}
                loading={loading}
                buttonName="Update"
              />
            </DialogActions>
          </div>
        </Dialog>
        {/*delete contact*/}
        <Dialog
          open={this.props.openModalToDeleteContact}
          onClose={this.props.handleChangeCloseModalToDeleteContact}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete this contact?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={this.props.handleChangeCloseModalToDeleteContact}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.deleteIndividualContact(
                        this.state.contactData.contactId
                      );
                      this.props.handleChangeCloseModalToDeleteContact();
                    }}
                    className="addUserButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*delete social add handle*/}
        <Dialog
          open={this.state.openDeleteSocialHandleDialogue}
          onClose={handleCloseDeleteSocialHandleDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <div style={{ background: "#ffffff", borderRadius: "18px" }}>
            <DialogTitle
              id="draggable-dialog-title"
              style={{ cursor: "move" }}
            />
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete social handle?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteSocialHandleDialogue}
                      className="cancelButton"
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteSocialHandleDialogue();
                        // handleOpenContactListToSendModal();
                      }}
                      className="addUserButton"
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        {/*DELETE CONTACT ADDRESS*/}
        <Dialog
          open={this.state.openDeleteModal}
          onClose={handleCloseDeleteDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent className="dialogContentForDeleteModal">
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete address?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteDialogue}
                      className="cancelButton"
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteDialogue();
                      }}
                      className="deleteOkButton"
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        {/*set contact account*/}
        <Dialog
          open={this.props.openModalToSetContactAccount}
          onClose={this.props.handleChangeCloseModalToSetContactAccount}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to set account for{" "}
              {this.state.contactData && this.state.contactData.name}?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={
                      this.props.handleChangeCloseModalToSetContactAccount
                    }
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>
                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const accountDetails = {
                        accountType: "EVERFUND_CONTACT",
                        contact: {
                          contactId:
                            this.state.contactData &&
                            this.state.contactData.contactId,
                          emailPhone:
                            this.state.contactData &&
                            this.state.contactData.emailPhone,
                        },
                      };
                      this.props.setContactsAccount(accountDetails);
                      this.props.handleChangeCloseModalToSetContactAccount();
                    }}
                    className="addUserButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={contactState.openSuccessContactSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeSnackBar}
            variant="success"
            message={contactState && contactState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={contactState.openErrorContactSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeSnackBar}
            variant="error"
            message={contactState.errorMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    border: "1px solid rgb(224, 224, 224)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "1rem",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "4px",
    width: "213px",
    height: "40px",
  },
  columnHeadingContact: {
    color: "#625F5F",
    fontSize: "16px",
    width: "100%",
    marginBottom: 0,
  },
  expandMoreIconContact: {
    position: "absolute",
    right: "14px",
    top: "16px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  inputFieldContact: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "10px",
    // marginBottom: '15px',
    paddingLeft: "13px",
  },
  dropdownInput: {
    paddingLeft: "8px",
    height: "40px",
    color: "#606060",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "110%",
    fontSize: "15px",
    marginTop: "10px",
    marginLeft: "-12px",
  },
};
export default IndividualContactScreen;
