import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class UpcomingTransactionAPI extends API {
  fetchUpcomingTransactionList = (callback) => {
    fetch(APIEndPoints.FETCH_UPCOMING_TRANSACTION, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  getClientUpcomingTransList = (clientId, callback) => {
    fetch(
      APIEndPoints.FETCH_CLIENT_UPCOMING_TRANSACTION + clientId,

      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchClientUpcomingTransactionList = (
    clientId,
    from,
    to,
    medium,
    term,
    vendor,
    type,
    callback
  ) => {
    fetch(
      APIEndPoints.FETCH_CLIENT_UPCOMING_TRANSACTION +
        clientId +
        "?from=" +
        from +
        "&to=" +
        to +
        "&medium=" +
        medium +
        "&term=" +
        term +
        "&vendor=" +
        vendor +
        "&transactionType=" +
        type,
      {
        method: "GET",
        headers: this.authHeaders,
      }
    )
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  fetchAnnualUpcomingTransactionList = (callback) => {
    fetch(APIEndPoints.FETCH_ANNUAL_UPCOMING_TRANSACTION, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling client api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  processUpcomingTransaction = (scheduleTransData, callback) => {
    let body = JSON.stringify(scheduleTransData);
    fetch(APIEndPoints.PROCESS_UPCOMING_TRANSACTION, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add User api", error);
        callback(null, "Service error, please try again.");
      });
  };
  skipUpcomingTransaction = (scheduleTransData, callback) => {
    let body = JSON.stringify(scheduleTransData);
    fetch(APIEndPoints.SKIP_UPCOMING_TRANSACTION, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling add User api", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchUpcomingTransactionByTerm = (value, callback) => {
    const urlString = APIEndPoints.SEARCH_UPCOMING_TRANSACTION_BY_TERM + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  searchAnnualUpcomingTransactionByTerm = (value, callback) => {
    const urlString =
      APIEndPoints.SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM + value;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchUpcomingTransactionByMedium = (medium, callback) => {
    const urlString =
      APIEndPoints.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM + medium;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchUpcomingTransactionByDate = (from, to, callback) => {
    const urlString =
      APIEndPoints.SEARCH_UPCOMING_TRANSACTION_BY_DATE + from + "&to=" + to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchUpcomingTransactionByMediumAndDate = (
    medium,
    from,
    to,
    vendor,
    type,
    callback
  ) => {
    const urlString =
      APIEndPoints.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_DATE +
      medium +
      "&from=" +
      from +
      "&to=" +
      to +
      "&vendor=" +
      vendor +
      "&transactionType=" +
      type;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
  searchUpcomingTransactionByAll = (
    // startDate,
    // endDate,
    clientName,
    vendorName,
    transactionMedium,
    transactionType,
    // transactionStatus,
    transactionDate,
    scheduleDate,
    amount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FILTER_UPCOMING_TRANSACTION +
      //   "startDate=" +
      //   startDate +
      //   "&endDate=" +
      //   endDate +
      "clientName=" +
      clientName +
      "&vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      // "&transactionType=" +
      "&txnType=" +
      transactionType +
      //   "&transactionStatus=" +
      //   transactionStatus +
      "&transactionDate=" +
      transactionDate +
      "&scheduleDate=" +
      scheduleDate +
      "&amount=" +
      amount +
      "&compareType=" +
      compareType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  searchUpcomingTransactionByClient = (
    // startDate,
    // endDate,
    clientId,
    vendorName,
    transactionMedium,
    transactionType,
    // transactionStatus,
    transactionDate,
    scheduleDate,
    amount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FETCH_CLIENT_UPCOMING_TRANSACTION +
      //   "startDate=" +
      //   startDate +
      //   "&endDate=" +
      //   endDate +

      clientId +
      "?vendorName=" +
      vendorName +
      "&transactionMedium=" +
      transactionMedium +
      // "&transactionType=" +
      "&txnType=" +
      transactionType +
      "&transactionDate=" +
      transactionDate +
      "&scheduleDate=" +
      scheduleDate +
      "&amount=" +
      amount +
      "&compareType=" +
      compareType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };

  // save config
  saveConfigUP = (data, callback) => {
    const urlString =
      APIEndPoints.SAVE_FILTER_CONFIG +
      "clientName=" +
      data.clientName +
      "&vendorName=" +
      data.vendorName +
      "&amount=" +
      data.balance +
      "&transactionMedium=" +
      data.transactionMedium +
      "&transactionType=" +
      data.transactionType +
      "&scheduleDate=" +
      data.scheduleDate +
      "&transactionDate=" +
      data.transactionDate +
      "&configName=" +
      data.configName +
      "&type=" +
      data.type +
      "&compareType=" +
      data.compareType +
      "&compareType=" +
      data.compareType +
      "&sortBy=" +
      data.sortBy;
    fetch(urlString, {
      method: "POST",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        console.log("Error in calling search api.", error);
        callback(null, "Service error, please try again.");
      });
  };
}
export default new UpcomingTransactionAPI();
