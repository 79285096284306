import {connect} from 'react-redux';
import DocumentHub from "../../screens/DocumentHubScreen";
import {
    clearFilterModal,
    closeSnackBar,
    fetchDocumentsFailure,
    fetchDocumentsSuccess, filterDocumentsFailure,
    filterDocumentsSuccess, handleChangeDocumentName, handleChangeFromDate, handleChangeToDate,
    requestForDocuments, setFilterSuccessToFalse
} from "../../actions/DocumentsAction";
import {DocumentsAPI} from "../../api";

const mapStateToProps = (state) => {
    return {
        state,
    };
};
const mapDispatchToProps = dispatch => {
    return{
        fetchDocuments: () => {
            dispatch(requestForDocuments(true));
            DocumentsAPI.fetchDocumentsList((response, error) => {
                if (response) {
                    dispatch(fetchDocumentsSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchDocumentsFailure(error.msg))
                    } else {
                        dispatch(fetchDocumentsFailure(error));
                    }
                }
            })
        },
        searchDocuments : (term, from, to, props) => {
            dispatch(requestForDocuments(true));
            DocumentsAPI.searchDocuments(term,from,to, (response, error) => {
                if (response) {
                    dispatch(filterDocumentsSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(filterDocumentsFailure(error.msg));
                    } else {
                        dispatch(filterDocumentsFailure(false));
                    }
                }
            });
        },
        setFilterSuccessToFalse: () => {dispatch(setFilterSuccessToFalse())},
        handleChangeFileName: fileName => {dispatch(handleChangeDocumentName(fileName));},
        handleChangeFromDate: fileName => {dispatch(handleChangeFromDate(fileName));},
        handleChangeToDate: fileName => {dispatch(handleChangeToDate(fileName));},
        closeSnackBar: () => {dispatch(closeSnackBar());},
        clearFilterModal: () => {dispatch(clearFilterModal());},
    }
}
export const DocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentHub);