import React, {Component} from "react";
import '../App.css';
import '../styles/styles.css';
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Button} from "@material-ui/core";

class BulkCheckScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openConformModal: false,
            numPages: null,
            pageNumber: 1,
            mergedPdfUrl: '',
        }
    }
    render() {
        window.onafterprint = () => {
            window.history.back();
        }
        const checkPrintRequest = this.props.location && this.props.location.state &&
            this.props.location.state.response.checkPrintReq;
        const checkPrintList = checkPrintRequest && checkPrintRequest.checkPrint;
        const checkPrintData ={
            checkPrint: checkPrintList && checkPrintList.map(list => {
                return {
                    checkPrintId: list.checkPrintId,
                    transactionId: list.transactionId,
                    isPrinted: true
                }
            })
        }
        const handleOpenModal = () => {
            this.setState({
                openConformModal: true,
            })
        }
        const handleCloseModal = () => {
            this.setState({
                openConformModal: false,
            })
        }
        return (
            <React.Fragment>
                <style>
                    {`@media print {.modal-element{display: none;}}`}
                </style>
                <div>
                    <button className="floatDiv" onClick={() => {handleOpenModal()}}>Print</button>
                </div>
                <Dialog
                    className="modal-element"
                    open={this.state.openConformModal}
                    onClose={handleCloseModal}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title" style={{cursor: "move"}}>
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation</h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px'
                        }}>
                        <DialogContentText>
                            Do you want to print check?

                            <span className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '0.5rem',
                                paddingTop: '1.6rem'
                            }}>
                                <span className={"col-6"}>
                                    <Button autoFocus onClick={handleCloseModal}
                                            className="cancelButton"
                                            style={style.cancelButton}>
                                        cancel
                                    </Button>
                                </span>
                                <span className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        handleCloseModal();
                                        window.print();
                                        this.props.setCheckPrint(checkPrintData);
                                    }} style={style.deleteCaseTemplateButton}>
                                        ok
                                    </Button>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

const style = {
    outerDiv: {
        height: "344px",
        marginTop: '20px',
    },
    innerDiv: {
        padding: "20px",
    },
    dateCol: {
        float: "right",
        marginTop: '18px',
    },
    dateSpan: {
        padding: "8px",
    },
    amount: {
        padding: "8px",
        fontSize: "18px",
    },
    code: {
        display: "flex",
        justifyContent: "space-between",
    },
    inputField: {
        width: " 530px",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        background: "white",
        borderBottom: "1px solid #80808099",
        marginLeft: '18px',
    },
    inputFieldLast: {
        width: " 300px",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        background: "white",
        borderBottom: "1px solid #80808099",
    },
    inputFieldAmount: {
        width: "605px",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        background: "white",
        borderBottom: "1px solid #80808099",
    },
    deleteCaseTemplateButton: {
        background: '#8BC83F',
        color: 'white',
        width: '100%',
        padding: '0.5rem',
        fontSize: '14px',
    }
}

export default BulkCheckScreen;
