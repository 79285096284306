import {ACTION_TYPE_CASE} from "../constants/Type";

export const detailsForCalendarRequestMade = bool =>{
    return{
        type: ACTION_TYPE_CASE.DETAIL_FOR_CALENDAR_REQUEST_MADE,
        bool,
    }
}
export const fetchDataForCalendarSuccess = response => {
    return {
        type: ACTION_TYPE_CASE.FETCH_DATA_FOR_CALENDAR_SUCCESS,
        response
    };
};

export const fetchDataForCalendarFailure = error => {
    return {
        type: ACTION_TYPE_CASE.FETCH_DATA_FOR_CALENDAR_FAILURE,
        error
    };
};

export const fetchDataForCalendarByClientSuccess = response => {
    return {
        type: ACTION_TYPE_CASE.FETCH_CALENDAR_BY_CLIENT_ID_SUCCESS,
        response
    };
};

export const fetchDataForCalendarByClientFailure = error => {
    return {
        type: ACTION_TYPE_CASE.FETCH_CALENDAR_BY_CLIENT_ID_FAILURE,
        error
    };
};

export const fetchDataForCalendarByAgentSuccess = response => {
    return {
        type: ACTION_TYPE_CASE.FETCH_CALENDAR_BY_AGENT_ID_SUCCESS,
        response
    };
};

export const fetchDataForCalendarByAgentFailure = error => {
    return {
        type: ACTION_TYPE_CASE.FETCH_CALENDAR_BY_AGENT_ID_FAILURE,
        error
    };
};

export const searchCalendarDataSuccess = searchData => {
    return {
        type: ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_SUCCESS,
        searchData
    };
};

export const searchCalendarDataFailure = error => {
    return {
        type: ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_FAILURE,
        error
    };
};
export const searchCalendarDataByClientSuccess = searchData => {
    return {
        type: ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_CLIENT_ID_SUCCESS,
        searchData
    };
};

export const searchCalendarDataByClientFailure = error => {
    return {
        type: ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_CLIENT_ID_FAILURE,
        error
    };
};
export const searchCalendarDataByAgentSuccess = searchData => {
    return {
        type: ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_AGENT_ID_SUCCESS,
        searchData
    };
};

export const searchCalendarDataByAgentFailure = error => {
    return {
        type: ACTION_TYPE_CASE.SEARCH_CALENDAR_DATA_BY_AGENT_ID_FAILURE,
        error
    };
};

