import { connect } from 'react-redux';
import ClientFilesDetailScreen from "../../screens/ClientFileDetailsScreen";
import {clientRequestMade, scheduleTransactionRequestMade} from "../../actions";
import {ClientAPI, DynamicActionAPI} from "../../api";
import {fetchFiledDetailByClientFailure, fetchFiledDetailByClientSuccess} from "../../actions/ClientAction";
import {uploadDocFileFailure, uploadDocFileSuccess} from "../../actions/ScheduleTransactionAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
   return{
       fetchFileListByClient: (clientId , props) =>{
           dispatch(clientRequestMade(true));
           ClientAPI.fetchFileListByClient(clientId, (response, error) => {
               if (response) {
                   dispatch(fetchFiledDetailByClientSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchFiledDetailByClientFailure(error.msg))
                   } else {
                       dispatch(fetchFiledDetailByClientFailure(error));
                   }
               }
           })
       },
}}

export const ClientFileDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ClientFilesDetailScreen);