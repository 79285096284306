import {connect} from 'react-redux';
import {CaseUpdateFormScreen} from "../../screens";
import {
    caseRequestMade, clientRequestMade,
    closeSnackBarForCase, closeSnackBarForClient, closeSnackBarForUser, corporateClientRequestMade,
    fetchUserFailure,
    fetchUserSuccess,
    getSingleClientInfo,
    handleChangeCase,
    handleChangeCaseAgent,
    makeChangeWorkFlowAgents,
    registerCaseFailure,
    setCaseToEdit,
    updateCaseFailure,
    updateCaseSuccess,
    userRequestMade
} from "../../actions";
import {ActivityLogAPI, CaseAPI, ClientAPI, ContactApi, UserAPI} from "../../api";
import {
    addCaseCompanyFailure,
    addCaseCompanySuccess,
    addCaseContactFailure,
    addCaseContactSuccess,
    addCaseExistingContactFailure,
    addCaseExistingContactSuccess,
    fetchCaseContactFailure,
    fetchCaseContactSuccess,
    fetchFiledDetailByCaseFailure,
    fetchFiledDetailByCaseSuccess,
    handleChangeCaseCompanyInput,
    handleChangeCaseContactInput,
    handleChangeDateByAgent,
    handleChangeDateOnly,
    handleChangeExistingCompanyInput,
    handleChangeRelationshipNameInCase,
    handleClick,
    scheduleDateByAgentFailure,
    scheduleDateByAgentSuccess,
    searchExistingContactFailure,
    searchExistingContactSuccess,
    setCaseCompanyContactToEmpty,
    setCaseIndividualContactToEmpty
} from "../../actions/CaseAction";
import {
    contactRequestMade,
    fetchCompanyTypeFailure,
    fetchCompanyTypeSuccess,
    fetchRelationshipFailure,
    fetchRelationshipSuccess,
    searchContactFailure,
    searchContactSuccess
} from "../../actions/ContactAction";
import {
    addNoteFailure,
    addNoteSuccess,
    clearNoteDescription,
    deleteNoteFailure,
    deleteNoteSuccess,
    fetchNoteByIdFailure,
    fetchNoteByIdSuccess,
    handleChangeNoteDescription,
    setNoteDescriptionToEdit,
    updateNoteFailure,
    updateNoteSuccess
} from "../../actions/ClientAction";
import {
    activityLogRequestMade,
    fetchActivityLogByIdFailure,
    fetchActivityLogByIdSuccess
} from "../../actions/ActivityLogAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleChangeCaseAgent: (id, value) => dispatch(handleChangeCaseAgent(id, value)),
        handleChangeDate: (id, arrayValue) => dispatch(handleChangeDateByAgent(id, arrayValue)),
        fetchUserList: () => {
            dispatch(userRequestMade(true));
            UserAPI.fetchUserList((response, error) => {
                if (response) {
                    dispatch(fetchUserSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchUserFailure(error.msg))
                    } else {
                        dispatch(fetchUserFailure(error));
                    }
                }
            })
        },
        fetchClientInfo: (clientId) => {
            dispatch(caseRequestMade(true));
            ClientAPI.fetchSingleClient(clientId, (response, error) => {
                if (response) {
                    dispatch(getSingleClientInfo(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchUserFailure(error.msg))
                    } else {
                        dispatch(fetchUserFailure(error));
                    }
                }
            })
        },
        changeWorkFlowAgents: (workFlowID, value) => dispatch(makeChangeWorkFlowAgents(workFlowID, value)),
        handleChangeDateOnly: (id, date) => dispatch(handleChangeDateOnly(id,date)),
        handleChangeCase: (id, value) => dispatch(handleChangeCase(id, value)),
        editCase: (caseData, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.editCase(caseData, (response, error) => {
                if (response) {
                    dispatch(updateCaseSuccess(true));
                    props.history.replace('/case');
                } else {
                    if (error.msg) {
                        dispatch(updateCaseFailure(error.msg));
                    } else {
                        dispatch(updateCaseFailure(error));
                    }
                }
            });
        },
        getCaseInfo: (caseId, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.fetchSingleCase(caseId, (response, error) => {
                if (response) {
                    dispatch(caseRequestMade(true));
                    props.fetchClientInfo(response.case.clientId);
                    dispatch(setCaseToEdit(response.case));
                } else {
                    if (error.msg) {
                        dispatch(registerCaseFailure(error.msg));
                    } else {
                        dispatch(registerCaseFailure(error));
                    }
                }
            })
        },
        scheduleDateAgent : (data, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.scheduleDateByAgent(data, (response, error) => {
                if (response) {
                    dispatch(scheduleDateByAgentSuccess(true));
                    props.history.replace('/case');
                } else {
                    if (error.msg) {
                        dispatch(scheduleDateByAgentFailure(error.msg));
                    } else {
                        dispatch(scheduleDateByAgentFailure(error));
                    }
                }
            });
        },
        setCaseData: (caseFormData) => {
            dispatch(setCaseToEdit(caseFormData))
        },
        setClienData: (clientData) => {
            dispatch(getSingleClientInfo(clientData));
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForCase(true)),
        handleSnackBarCloseForUser: () => dispatch(closeSnackBarForUser(true)),
        handleClick: (id) => dispatch(handleClick(id)),

        fetchFileListByCase: (caseId) => {
            dispatch(corporateClientRequestMade(true));
            CaseAPI.fetchFileDetailsByCase(caseId, (response, error) => {
                if (response) {
                    dispatch(fetchFiledDetailByCaseSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchFiledDetailByCaseFailure(error.msg));
                    } else {
                        dispatch(fetchFiledDetailByCaseFailure(error));
                    }
                }
            })
        },

        // CASE CONTACT
        fetchCaseContact: (caseId) => {
            dispatch(corporateClientRequestMade(true));
            CaseAPI.fetchCaseContact(caseId, (response, error) => {
                if (response) {
                    dispatch(fetchCaseContactSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCaseContactFailure(error.msg));
                    } else {
                        dispatch(fetchCaseContactFailure(error));
                    }
                }
            })
        },
        handleChangeCaseContactInput: (id, value) =>{
            dispatch(handleChangeCaseContactInput(id, value))
        },
        addCaseContact: (caseContactData, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.addCaseIndividualContact(caseContactData, (response, error) => {
                if (response) {
                    dispatch(addCaseContactSuccess(true));
                    props.fetchCaseContact(caseContactData.caseId);
                } else {
                    if (error.msg) {
                        dispatch(addCaseContactFailure(error.msg));
                    } else {
                        dispatch(addCaseContactFailure(error));
                    }
                }
            });
        },
        setCaseContactSuccessToFalse: () => {dispatch(addCaseContactSuccess(false))},
        handleChangeCaseCompanyInput: (id, value) =>{
            dispatch(handleChangeCaseCompanyInput(id, value))
        },
        addCaseCompanyContact: (caseCompanyData, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.addCaseCompanyContact(caseCompanyData, (response, error) => {
                if (response) {
                    dispatch(addCaseCompanySuccess(true));
                    props.fetchCaseContact(caseCompanyData.caseId);
                } else {
                    if (error.msg) {
                        // LocalDb.removeSession();
                        // props.history.push({pathname: '/login'})
                        dispatch(addCaseCompanyFailure(error.msg));
                    } else {
                        dispatch(addCaseCompanyFailure(error));
                    }
                }
            });
        },
        setCaseCompanySuccessToFalse: () => {dispatch(addCaseCompanySuccess(false))},
        handleChangeExistingContact: (value) =>{
            dispatch(handleChangeExistingCompanyInput(value))
        },
        addExistingCaseContact: (existingContactData, props) => {
            dispatch(caseRequestMade(true));
            CaseAPI.addCaseExistingContact(existingContactData, (response, error) => {
                if (response) {
                    dispatch(addCaseExistingContactSuccess(true));
                    props.fetchCaseContact(existingContactData.caseId);
                } else {
                    if (error.msg) {
                        dispatch(addCaseExistingContactFailure(error.msg));
                    } else {
                        dispatch(addCaseExistingContactFailure(error));
                    }
                }
            });
        },
        setCaseExistingContactSuccessToFalse: () => {dispatch(addCaseExistingContactSuccess(false))},
        searchExistingContact: (value) => {
            dispatch(caseRequestMade(true));
            CaseAPI.searchExistingContact(value, (response, error) => {
                if (response) {
                    dispatch(searchExistingContactSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchExistingContactFailure(error.msg));
                    } else {
                        dispatch(searchExistingContactFailure(error));
                    }
                }
            });
        },

        setCaseIndividualContactToEmpty : () => {
            dispatch(setCaseIndividualContactToEmpty())
        },
        setCaseCompanyContactToEmpty : () =>{
            dispatch(setCaseCompanyContactToEmpty())
        },
        handleChangeRelationshipName : (relationship) => {
            dispatch(handleChangeRelationshipNameInCase(relationship))
        },
        fetchRelationshipList: () =>{
            dispatch(contactRequestMade(true));
            ContactApi.fetchRelationship((response, error) => {
                if (response) {
                    dispatch(fetchRelationshipSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchRelationshipFailure(error.msg))
                    } else {
                        dispatch(fetchRelationshipFailure(error));
                    }
                }
            })
        },
        searchIndividualContact: (value, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.searchIndividualContact(value, (response, error) => {
                if (response) {
                    dispatch(searchContactSuccess(response));
                    // props.fetchCompanyList();
                } else {
                    if (error.msg) {
                        dispatch(searchContactFailure(error.msg));
                    } else {
                        dispatch(searchContactFailure(error));
                    }
                }
            });
        },
//fetch contact company type
        fetchCompanyType: () => {
            dispatch(contactRequestMade(true));
            ContactApi.fetchCompanyType((response, error) => {
                if (response) {
                    dispatch(fetchCompanyTypeSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchCompanyTypeFailure(error.msg));
                    } else {
                        dispatch(fetchCompanyTypeFailure(error));
                    }
                }
            });
        },
        //NOTES
        // handle change
        handleChangeNoteDescription : (id, value) => {dispatch(handleChangeNoteDescription(id, value))},
        //set note
        setNotesToEdit : (noteData) => {dispatch(setNoteDescriptionToEdit(noteData))},
        // add notes*
        addCaseNotes: (noteData, props) => {
            dispatch(clientRequestMade(true));
            ClientAPI.addNewNote(noteData, (response, error) => {
                if (response) {
                    dispatch(addNoteSuccess(true));
                    props.fetchNoteById(noteData.refId);
                    // props.fetchActivityLogByClientId(noteData.refId);
                } else {
                    if (error.msg) {
                        dispatch(addNoteFailure(error.msg));
                    } else {
                        dispatch(addNoteFailure(error));
                    }
                }
            });
        },
        //update note
        updateCaseNotes: (noteData, props) => {
            dispatch(clientRequestMade(true));
            ClientAPI.updateNotes(noteData, (response, error) => {
                if (response) {
                    dispatch(updateNoteSuccess(response));
                    props.fetchNoteById(noteData.refId);
                    // props.fetchActivityLogByClientId(noteData.refId);
                } else {
                    if (error.msg) {
                        dispatch(updateNoteFailure(error.msg));
                    } else {
                        dispatch(updateNoteFailure(error));
                    }
                }
            });
        },
        //delete note
        deleteCaseNote: (noteId,id, props) => {
            dispatch(clientRequestMade(true));
            ClientAPI.deleteNote(noteId, (response, error) => {
                if (response) {
                    dispatch(deleteNoteSuccess(true));
                    props.fetchNoteById(id);
                } else {
                    if (error.msg) {
                        dispatch(deleteNoteFailure(error.msg));
                    } else {
                        dispatch(deleteNoteFailure(error));
                    }
                }
            });
        },
        //fetch node by id
        fetchNoteById: (id, props) =>{
            dispatch(clientRequestMade(true));
            ClientAPI.fetchNotesById(id,(response, error) => {
                if (response) {
                    dispatch(fetchNoteByIdSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchNoteByIdFailure(error.msg))
                    } else {
                        dispatch(fetchNoteByIdFailure(error));
                    }
                }
            })
        },
        //clear description data
        clearNoteDescription : () => {dispatch(clearNoteDescription())},
        //handle close client's snackbar
        handleChangeCloseSnackBar : () => {dispatch(closeSnackBarForClient(true))},

        //activity log
        //GET CLIENT'S ACTIVITY LOG
        fetchActivityLogByCaseId: (caseId , props) =>{
            dispatch(activityLogRequestMade(true));
            ActivityLogAPI.fetchActivityLogById(caseId, "", "",(response, error) => {
                if (response) {
                    dispatch(fetchActivityLogByIdSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchActivityLogByIdFailure(error.msg))
                    } else {
                        dispatch(fetchActivityLogByIdFailure(error));
                    }
                }
            })
        },
    };
};
export const CaseUpdateContainer = connect(mapStateToProps, mapDispatchToProps)(CaseUpdateFormScreen);