import { connect } from 'react-redux';
import PrintShippingScreen from "../../screens/PrintShippingScreen";
import {
    addShippingAddressSuccess,
    closeShippingSnackBar,
    fetchShippingLabelFailure,
    fetchShippingLabelSuccess,
    fetchShippingListFailure,
    fetchShippingListSuccess,
    handleChangeShippingFormValue, handleClearTrackingReport,
    printShippingLabelFailure,
    printShippingLabelSuccess,
    requestShippingLabel,
    setShippingAddressToEmpty,
    setShippingLabelToEmpty, trackShipmentFailure,
    trackShipmentSuccess,
    updateShippingAddressSuccess
} from "../../actions/ShippingLabelAction";
import {ShippingApi} from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
  return{
      fetchShippingList: () => {
          dispatch(requestShippingLabel(true));
          ShippingApi.fetchShippingList((response, error) => {
              if (response) {
                  dispatch(fetchShippingListSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(fetchShippingListFailure(error.msg))
                  } else {
                      dispatch(fetchShippingListFailure(error));
                  }
              }
          })
      },
      fetchShippingLabelList: () => {
          dispatch(requestShippingLabel(true));
          ShippingApi.fetchShippingLabelList((response, error) => {
              if (response) {
                  dispatch(fetchShippingLabelSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(fetchShippingLabelFailure(error.msg))
                  } else {
                      dispatch(fetchShippingLabelFailure(error));
                  }
              }
          })
      },
      handleChangeShippingValue : (id, value) => {dispatch(handleChangeShippingFormValue(id,value))},
      closeSnackBar: () => {dispatch(closeShippingSnackBar(true))},
      printShippingLabel: (shippingData, props) => {
          dispatch(requestShippingLabel(true));
          ShippingApi.printShippingLabel(shippingData,(response, error) => {
              if (response) {
                  props.fetchShippingLabelList();
                  dispatch(printShippingLabelSuccess(true));
                  const printUrl = () =>{
                      var win = window.open('' );
                      var img=win.document.createElement("img");
                      img.src= response.shippingLabel.labelImageUrl;
                      win.document.body.appendChild(img);
                      img.onload = function(){
                          win.print();
                      }
                  }
                  return printUrl();
              } else {
                  if (error.msg) {
                      dispatch(printShippingLabelFailure(error.msg))
                  } else {
                      dispatch(printShippingLabelFailure(error));
                  }
              }
          })
      },
      setAddShippingLabelToFalse : () => {dispatch(printShippingLabelSuccess(false))},

      setSettingLabelToEmpty: () => {
          dispatch(setShippingLabelToEmpty());
      },

     trackShippingInfo: (id, props) => {
          dispatch(requestShippingLabel(true));
          ShippingApi.trackShippingInfo(id,(response, error) => {
              if (response) {
                  dispatch(trackShipmentSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(trackShipmentFailure(error.msg))
                  } else {
                      dispatch(trackShipmentFailure(error));
                  }
              }
          })
      },
      handleClearTrackingReport : () => {
          dispatch(handleClearTrackingReport());
      }
  }
};

export const PrintShippingContainer = connect(mapStateToProps, mapDispatchToProps)(PrintShippingScreen);