import React from "react";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "../PaperComponent";
import DialogTitleComponent from "../DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import { Select, Tooltip } from "antd";
import {
  finalAmount,
  formatter,
  naturalRound,
} from "../../utils/currencyConvertor";
import { DocumentImage } from "../../components/DocumentImage";
import ButtonComponent from "../ButtonComponent";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { onChangeDocumentFileHandler } from "../../utils/fileHandler";

const { Option } = Select;

const CreateTransactionForm = (props) => {
  const openCreateTransaction =
    props.transactionState && props.transactionState.openCreateTransactionModal;
  const clientId = props.clientData && props.clientData.clientId;
  const firstName = props.clientData && props.clientData.firstName;
  const lastName = props.clientData && props.clientData.lastName;
  const transData = props.transactionState && props.transactionState.transData;

  const transactionMedium = transData.transactionMedium;
  const amountDetails =
    props.transactionState && props.transactionState.amountDetails;

  const transType = transData.transactionType;
  const vendorList = props.vendorAccount;
  const clientVendor = props.vendorState && props.vendorState.clientVendor;
  const vendorData =
    vendorList &&
    vendorList.filter(
      (vendor) => vendor.vendorType && vendor.vendorType.isDefault
    );
  const settingState = props && props.settingState;
  const descriptionList = settingState && settingState.descriptionList;
  const transValue =
    transData.amount !== "" &&
    transData.transactionType !== "" &&
    transData.transactionMedium !== "" &&
    transData.description !== "";
  const transValueWithBank =
    transData.amount !== "" &&
    transData.transactionType !== "" &&
    transData.transactionMedium !== "" &&
    transData.description !== "" &&
    transData.bankDetailId !== undefined;
  const loading = props.transactionState && props.transactionState.loading;
  const scheduleTransactionState = props.scheduleTransactionState;
  const scheduleLoading =
    scheduleTransactionState && scheduleTransactionState.loading;
  const clientRes =
    scheduleTransactionState && scheduleTransactionState.clientRes;
  const fileUrl = scheduleTransactionState && scheduleTransactionState.fileUrl;

  const handleChange = (e) => {
    props.handleChangeTransData("isLedger", e.target.checked);
  };

  // trasaction update request body
  const transReqBody = {
    transactionId: transData && transData.transactionId,
    clientId: transData && transData.clientId,
    amount: transData && transData.amount,
    vendorId: transData && transData.vendorId,
    description: transData && transData.description,
    transactionMedium: transData && transData.transactionMedium,
    transactionType: transData && transData.transactionType,
    transactionStatus: transData && transData.transactionStatus,
    note: transData && transData.note,
    document: transData && transData.document,
    isLedger: transData && transData.isLedger,
    bankDetailId: transData && transData.bankDetailId,
  };
  return (
    <div>
      <Dialog
        open={openCreateTransaction}
        onClose={() => {
          props.setOpenCreateTransaction(false);
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="#draggable-dialog-title"
        maxWidth="xl"
      >
        <div>
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              props.setOpenCreateTransaction(false);
            }}
          >
            {props.isUpdate ? "Update Transaction" : "Create Transaction"}
          </DialogTitleComponent>
          <DialogContent dividers className="dialogContentForForms">
            <div className="row mt-2">
              <div className="col-lg-4 select-input h40">
                <label htmlFor="clientName">
                  Client Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  disabled
                  value={firstName + " " + lastName}
                  className="inputField"
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="amount">
                  Amount (Dollar)
                  <span style={{ color: "red" }}> *</span>{" "}
                </label>
                <div>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    autoComplete="off"
                    className="inputField"
                    onKeyPress={(e) => {
                      naturalRound(e);
                    }}
                    onChange={(e) => {
                      props.handleChangeTransData(e.target.id, finalAmount(e));
                    }}
                    value={transData.amount === 0 ? "" : transData.amount / 100}
                  />
                </div>
                {amountDetails && amountDetails.totalBalance ? (
                  <div className="mt-2">
                    <span style={{ color: "#8BC34A" }}>Total Balance</span> :
                    <span style={{ color: "#676767" }}>
                      {" "}
                      {amountDetails
                        ? formatter.format(amountDetails.totalBalance / 100)
                        : ""}
                    </span>
                    <span>
                      <Tooltip
                        placement="bottom"
                        overlayInnerStyle={{ width: "215px" }}
                        title={
                          <div>
                            <div>
                              <span>Posted Balance: </span>
                              <span>
                                {formatter.format(
                                  amountDetails.actualBalance / 100
                                )}
                              </span>
                            </div>
                            <div>
                              <span>Submitted Balance: </span>
                              <span>
                                {formatter.format(
                                  amountDetails.availableBalance / 100
                                )}
                              </span>
                            </div>
                            {amountDetails.upcomingBalance ? (
                              <div>
                                <span>Upcoming Balance: </span>
                                <span>
                                  {formatter.format(
                                    amountDetails.upcomingBalance / 100
                                  )}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        }
                        getPopupContainer={(node) => node.parentNode}
                      >
                        <AccountBalanceWalletIcon
                          style={{
                            marginLeft: "5px",
                            color: "#636363d6",
                            width: "19px",
                            marginTop: "-3px",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                ) : (
                  <div className="mt-2">
                    <span style={{ color: "#8BC34A" }}>Total Balance</span> :
                    <span style={{ color: "#676767" }}>
                      {" "}
                      {formatter.format(0 / 100)}
                    </span>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="transMedium">
                  Transaction Medium <span style={{ color: "red" }}> *</span>
                </label>
                <div
                  className="select-input selects h40"
                  style={{ width: "100%" }}
                >
                  <Select
                    getPopupContainer={(node) => node.parentNode}
                    id="transactionMedium"
                    onChange={(e) => {
                      if (!props.isUpdate) {
                        props.handleChangeTransData("transactionType", "");
                        props.handleChangeTransData("vendorId", "");
                      }
                      props.handleChangeTransData("transactionMedium", e);
                    }}
                    value={transData.transactionMedium}
                  >
                    <Option value="CHECK">Check</Option>
                    <Option value="CASH">Cash</Option>
                    <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                    <Option value="ACH">ACH</Option>
                    <Option value="CARD">Card</Option>
                    <Option value="LEDGER">Ledger</Option>
                    <Option value="OTHER_MEDIUM">Other medium</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {props.isUpdate ? (
                <div className="col-lg-4">
                  <label htmlFor="transType">
                    Transaction Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="amount"
                    className="inputField"
                    disabled
                    value={
                      transData.transactionType === "DISBURSEMENT"
                        ? "Disbursement"
                        : "Deposit"
                    }
                  />
                </div>
              ) : (
                <div className="col-lg-4">
                  <label htmlFor="transType">
                    Transaction Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      getPopupContainer={(node) => node.parentNode}
                      value={transData.transactionType}
                      onChange={(e) => {
                        props.fetchSingleClient();
                        if (e === "DEPOSIT" || e === "FEE" || e === "REFUND") {
                          props.handleChangeTransData(
                            "vendorId",
                            vendorData &&
                              vendorData[0] &&
                              vendorData[0].vendorId
                          );
                        } else {
                          props.handleChangeTransData("vendorId", " ");
                        }
                        props.handleChangeTransData("transactionType", e);
                      }}
                    >
                      <Option
                        value="DISBURSEMENT"
                        hidden={transactionMedium === "LEDGER"}
                      >
                        Disbursement
                      </Option>
                      <Option
                        value="DEPOSIT"
                        hidden={transactionMedium === "LEDGER"}
                      >
                        Deposit
                      </Option>
                      <Option
                        value="FEE"
                        hidden={transactionMedium !== "LEDGER"}
                      >
                        Fee
                      </Option>
                      <Option
                        value="REFUND"
                        hidden={transactionMedium !== "LEDGER"}
                      >
                        Refund
                      </Option>
                    </Select>
                  </div>
                </div>
              )}
              {props.isUpdate ? (
                <div className="col-lg-4 select-input h40">
                  <label htmlFor="clientName">Vendor</label>
                  <input
                    type="name"
                    id="amount"
                    className="inputField"
                    disabled
                    value={transData.vendorName}
                  />
                </div>
              ) : (
                <div className="col-lg-4">
                  <label htmlFor="vendor">
                    Vendor
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  {transType === "DEPOSIT" ||
                  transType === "FEE" ||
                  transType === "REFUND" ? (
                    <input
                      className="inputField"
                      value={
                        vendorData && vendorData[0]
                          ? vendorData[0].vendorName
                          : clientVendor &&
                            clientVendor[0] &&
                            clientVendor[0].vendorAccount.vendorName
                      }
                      disabled
                    />
                  ) : (
                    <div
                      className="select-input selects h40"
                      style={{ width: "100%" }}
                    >
                      <Select
                        className="drop"
                        dropdownStyle={{
                          maxHeight: "100px",
                          overflowY: "scroll",
                        }}
                        value={transData.vendorId}
                        getPopupContainer={(node) => node.parentNode}
                        disabled={
                          transType === "DEPOSIT" ||
                          transType === "FEE" ||
                          transType === "REFUND"
                        }
                        onChange={(e) => {
                          props.handleChangeTransData("vendorId", e);
                        }}
                      >
                        {(transType === "DEPOSIT" ||
                          transType === "FEE" ||
                          transType === "REFUND") &&
                        vendorData &&
                        vendorData[0] ? (
                          <Option value={vendorData[0].vendorId}>
                            {vendorData[0].vendorName}
                          </Option>
                        ) : (
                          clientVendor &&
                          clientVendor.map((vendor) => (
                            <Option
                              value={
                                vendor.vendorAccount &&
                                vendor.vendorAccount.vendorId
                              }
                            >
                              {vendor.vendorAccount &&
                                vendor.vendorAccount.vendorName}
                            </Option>
                          ))
                        )}
                      </Select>
                    </div>
                  )}
                </div>
              )}
              <div className="col-lg-4 select-input h40">
                <label htmlFor="clientName">
                  Description
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  // className="drop"
                  // dropdownStyle={{ maxHeight: "110px", overflowY: "scroll" }}
                  value={transData.description}
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(e) => {
                    props.handleChangeTransData("description", e);
                  }}
                >
                  {descriptionList &&
                    descriptionList.map((list) => (
                      <Option value={list.description}>
                        {list.description}
                      </Option>
                    ))}
                </Select>
              </div>
              <div
                className="col-lg-4 mt-3"
                hidden={
                  transactionMedium === "CHECK" || transType === "DEPOSIT"
                }
              >
                <label htmlFor="note">Note</label>
                <input
                  className="inputField"
                  id="note"
                  name="note"
                  type="text"
                  value={transData.note}
                  autoComplete="off"
                  onChange={(e) => {
                    props.handleChangeTransData(e.target.id, e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="row mt-4"
              hidden={
                !(transactionMedium === "CHECK" || transType === "DEPOSIT")
              }
            >
              <div
                className="col-lg-4"
                hidden={
                  !(
                    transactionMedium === "CHECK" ||
                    (transType === "DEPOSIT" && transactionMedium !== "CHECK")
                  )
                }
              >
                <label htmlFor="note">Notes</label>
                <input
                  className="inputField"
                  id="note"
                  name="note"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    props.handleChangeTransData(e.target.id, e.target.value);
                  }}
                />
              </div>
              <div
                className="col-lg-4"
                hidden={
                  !(transactionMedium === "ACH" && transType === "DEPOSIT")
                }
              >
                <label htmlFor="note">
                  Client Bank Account <span style={{ color: "red" }}> *</span>
                </label>
                {clientRes &&
                clientRes.bankDetail &&
                clientRes.bankDetail.length === 1 ? (
                  <input
                    className="inputField"
                    disabled
                    value={
                      clientRes.bankDetail[0].accountName +
                      " (" +
                      clientRes.bankDetail[0].accountNumber +
                      ")"
                    }
                  />
                ) : (
                  <div className="select-input selects h40">
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      value={transData.bankDetailId}
                      onChange={(e) => {
                        props.handleChangeTransData("bankDetailId", e);
                      }}
                    >
                      {clientRes &&
                        clientRes.bankDetail &&
                        clientRes.bankDetail.map((detail) => (
                          <Option value={detail.bankDetailId}>
                            {detail.accountName +
                              " " +
                              " (" +
                              detail.accountNumber +
                              " )"}
                          </Option>
                        ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="col-lg-4" hidden={transactionMedium !== "CHECK"}>
                <label htmlFor="note">Print Document</label>
                <div>
                  <DocumentImage documentURL={fileUrl} showDiv />
                </div>
                <label
                  style={{
                    width: "115px",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    background: "rgba(204,204,254,0.32)",
                    border: "1px solid #7b7b7b",
                    textAlign: "center",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      e.preventDefault();
                      const result = onChangeDocumentFileHandler(e);
                      if (result.name) {
                        props.uploadDocumentFile(result);
                      } else {
                        props.handleShowFileUploadErrorMessage(result);
                      }
                      // props.uploadDocumentFile(onChangeDocumentFileHandler(e));
                    }}
                    className="hidden"
                    style={{ display: "none" }}
                  />
                  {scheduleLoading ? "Loading..." : "Select File"}
                </label>
              </div>
            </div>
            <div
              className="row"
              style={{
                padding: "1.5rem 0px 0.5rem",
                float: "right",
                marginRight: "0px",
              }}
            >
              {/* LEDGER checkbox  */}
              {transData.transactionType === "DEPOSIT" ? (
                <>
                  <div style={style.checkBoxStyle}>
                    <input
                      type="checkbox"
                      name="isLedger"
                      id="checkboxinfo"
                      checked={transData.isLedger}
                      onChange={(e) => handleChange(e)}
                    />
                    <label style={style.ledgerLabelStyle}>Ledger</label>
                  </div>
                </>
              ) : (
                ""
              )}
              <div>
                <ButtonComponent
                  onClick={(e) => {
                    e.preventDefault();
                    transData.clientId = clientId;
                    if (
                      clientVendor &&
                      clientVendor.length === 1 &&
                      transType !== "DEPOSIT" &&
                      transType !== "FEE" &&
                      transType !== "REFUND"
                    ) {
                      transData.vendorId =
                        clientVendor &&
                        clientVendor[0] &&
                        clientVendor[0].vendorAccount.vendorId;
                    }
                    {
                      props.isUpdate
                        ? props.updateClientTransData(transReqBody)
                        : props.createTransData(transData);
                    }
                  }}
                  value={
                    transactionMedium === "ACH" && transType === "DEPOSIT"
                      ? transValueWithBank
                      : transValue
                  }
                  loading={loading}
                  buttonName={props.isUpdate ? "Update" : "Create"}
                />
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

const style = {
  checkBoxStyle: {
    position: "absolute",
    top: "87%",
    right: "89%",
  },
  ledgerLabelStyle: {
    color: "rgb(98, 95, 95)",
    fontSize: "16px",
    padding: "5px",
  },
};

export default CreateTransactionForm;
