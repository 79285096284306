import React, {Component} from "react";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import DateConverter from "../utils/DateConverter";
import TablePagination from "@material-ui/core/TablePagination";
import AddIcon from "@material-ui/icons/Add";
// import Dialog from "@material-ui/core/Dialog";
// import PaperComponent from "../screens/PaperComponent";
// import DialogTitleComponent from "../screens/DialogTitleComponent";
// import DialogContent from "@material-ui/core/DialogContent";
import RefreshIcon from "@material-ui/icons/Refresh";

class ImageDocumentsComponent extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state ={
            page: 0,
            rowsPerPage: 10,
        }
    }
    render() {
        const documentHubList = this.props.documentHubList;
        const documentState = this.props.documentState;
        const fileName = documentState && documentState.fileName;
        const fromDate = documentState && documentState.fromDate;
        const toDate = documentState && documentState.toDate;
        const handleOpenToFilterDocument = this.props.handleOpenToFilterDocument;

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        return (
            <React.Fragment>
                <div className={"d-flex flex-row-reverse div-flex"} style={{paddingTop:"0px"}}>
                        {/*refresh button*/}
                        <div>
                            <button className="filterButton"
                                    onClick={()=>{this.props.fetchDocument()}}
                            >
                                <RefreshIcon style={{color: '#fff', marginTop: "-2px"}}/>
                            </button>
                        </div>
                        <div>
                            <button className="filterButton" onClick={() => {
                                handleOpenToFilterDocument();
                                this.setState({
                                    page: 0,
                                })
                            }}>
                                <FilterListIcon style={{color: '#fff', marginTop: "-2px"}}/>
                            </button>
                        </div>
                        <div hidden={!this.props.isClient}>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}>
                                <AddIcon/> Add File
                            </button>
                        </div>
                </div>
                <div className={"row"} style={style.card}>
                    <TableContainer className="fixed-table-header-second">
                        <Table size="small" stickyHeader aria-label="sticky table" ref={this.tableRef}>
                            <TableHead style={{padding: "1rem"}}>
                                <TableRow>
                                    <TableCell className="first-tableHeader" style={{ width:"610px"}}>
                                        Files
                                    </TableCell>
                                    <TableCell className="tableHeader">
                                        Created Date
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {
                                documentHubList && documentHubList.slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage).map(
                                    document => (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell className="first-tableBody" >
                                                    <a href={document.url} target="_blank" className="actionsLink">
                                                        <span>
                                                           {this.props.isImage ?
                                                               <img src={document.url} alt="pdf" style={{width: '30px', marginRight: '12px'}}/>:
                                                               document.url.split(".").pop() === "pdf"?
                                                                   <img src="/assets/images/pdf.svg" alt="pdf"
                                                                        style={{width: '30px', marginRight: '12px'}}/>
                                                                   : document.url.split(".").pop() === "txt"?
                                                                   <img src="/assets/images/text_logo.png" alt="txt"
                                                                        style={{width: '30px', marginRight: '12px'}}/>
                                                                   : null
                                                           }
                                                        </span>
                                                        {document.fileName}
                                                    </a>
                                                </TableCell>
                                                <TableCell className="tableBody">
                                                    {DateConverter(document.createdAt)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                )
                            }
                        </Table>
                        </TableContainer>
                        {documentHubList && documentHubList.length > 0 ? (
                            <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                <TablePagination
                                    labelRowsPerPage={false}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={
                                        documentHubList.length > 0
                                            ? documentHubList.length
                                            : 100
                                    }
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        "aria-label": "previous page"
                                    }}
                                    nextIconButtonProps={{
                                        "aria-label": "next page"
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </div>
                        ) : (
                            <div style={{     
                                display: "flex", 
                                width: "100%",
                                justifyContent:"center"
                                }}>
                                <h3
                                    style={{
                                        textAlign: "center",
                                        fontSize: "14px",
                                        padding: "1rem",
                                        color: "#606060"
                                    }}
                                >
                                    {" "}
                                    No data to show
                                </h3>
                            </div>
                        )}
                    {/* </TableContainer> */}
                </div>
            </React.Fragment>
        )
    }
};

const style = {
    card: {
        background: "rgb(255, 255, 255)",
        border: "1px solid rgb(233, 233, 233)",
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: "0px",
        marginRight: "0px",
        marginBottom: "1rem",
    },
}

export default ImageDocumentsComponent;