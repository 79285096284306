import { ACTION_TYPE_CASE, ACTION_TYPE_CONTACT } from "../constants/Type";

const initialState = {
  case: [],
  loading: false,
  openCaseSnackBar: false,
  errorMsgForCase: "",
  successMsg: "",
  workflowName: "",
  openCaseUpdateSnackBar: false,
  openCaseContactSuccess: false,
  openCaseContactError: false,
  agentAssign: [],
  calendarData: [],
  fileResponse: [],
  caseContactList: [],
  individualContact: {
    emailPhone: "",
    name: "",
    phone: "",
    address: [],
  },
  caseCompanyData: {
    companyName: "",
    email: "",
    phone: "",
    address: [],
    contact: [],
    companyTypeId: "",
  },
  refId: "",
  existingContacts: {},
  relationshipId: "",
  success: false,
  caseStartDate: 0,
  caseEndDate: 0,
  caseStatus: "UNKNOWN_STATUS",
  openCaseSuccessSnackBar: false,
  openCaseErrorSnackBar: false,
  //   pagination
  nextUrl: "",
  totalCaseCount: "",
  count: "",
};
const caseState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_CASE.SET_INDIVIDUAL_CASE_CONTACT_TO_EMPTY:
      return {
        ...state,
        individualContact: {
          emailPhone: "",
          name: "",
          phone: "",
          address: [],
        },
      };
    case ACTION_TYPE_CASE.SET_CASE_COMPANY_CONTACT_TO_EMPTY:
      return {
        ...state,
        caseCompanyData: {
          companyName: "",
          email: "",
          companyTypeId: "",
          phone: "",
          address: [],
          contact: [],
        },
      };
    case ACTION_TYPE_CASE.CASE_FETCH_SUCCESS:
      let getUrl = state.nextUrl;
      if (action.cases && action.cases.nextUrl) {
        getUrl = action.cases.nextUrl;
      }

      return {
        ...state,
        case: action.cases.cases,
        loading: false,
        openCaseSnackBar: false,
        fileResponse: [],
        nextUrl: getUrl,
        totalCaseCount: action.cases.totalCaseCount,
        count: action.cases.count,
      };

    //   PAGINATION

    case ACTION_TYPE_CASE.CLEAR_NEXT_URL:
      return {
        ...state,
        nextUrl: "",
      };
    case ACTION_TYPE_CASE.CASE_PAGINATED_FETCH_SUCCESS:
      let newUrl = state.nextUrl;
      if (action.cases && action.cases.nextUrl) {
        newUrl = action.cases.nextUrl;
      } else {
        newUrl = "";
      }
      return {
        ...state,
        case: [...state.case, ...action.cases.cases],

        loading: false,
        openCaseSnackBar: false,
        fileResponse: [],
        nextUrl: newUrl,
      };

    case ACTION_TYPE_CASE.FETCH_CLIENT_INFO:
      return {
        ...state,
        client: action.clientInfo.client,
        loading: false,
        openCaseSnackBar: false,
      };

    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_AGENT:
      const t = { ...state.caseData };
      t[action.id] = action.value;
      return {
        ...state,
        caseData: t,
        loading: false,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_DATE:
      const data = { ...state.caseData };
      data[action.id] = action.arrayValue;
      return {
        ...state,
        caseData: data,
        loading: false,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_WORKFLOW_AGENT:
      let workflow = state.caseData.workflow.filter(
        (s) => s.workflowId == action.workFlowID
      )[0];
      let userArray = [];
      userArray.push({
        user: {
          userId: action.value,
        },
      });
      workflow.workflowAgents = userArray;
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseSnackBar: true,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_DATE_ONLY:
      let flow = state.caseData.workflow.filter(
        (s) => s.workflowAgents[0].agentAssignId == action.id
      )[0];
      let userArrayDate = [];
      userArrayDate.push({
        agentAssignId: action.id,
        scheduledDate: action.date,
      });
      flow.workflowAgents = userArrayDate;
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseSnackBar: true,
      };
    case ACTION_TYPE_CASE.CASE_FETCH_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseSnackBar: true,
      };
    case ACTION_TYPE_CASE.CASE_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_CASE.DELETE_CASE_SUCCESS:
      const oldCaseList = state.case;
      let newCase = [];
      for (let i = 0; i < oldCaseList.length; i++) {
        if (oldCaseList[i].caseId !== action.caseId) {
          newCase.push(oldCaseList[i]);
        }
      }
      return {
        ...state,
        newCase: newCase,
        loading: false,
        openClientSnackBar: false,
      };
    case ACTION_TYPE_CASE.DELETE_CASE_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseSnackBar: true,
      };
    case ACTION_TYPE_CASE.CLOSE_SNACK_BAR_CASE_PAGE:
      return {
        ...state,
        openCaseSnackBar: false,
        openCaseUpdateSnackBar: false,
        openCaseContactSuccess: false,
        openCaseContactError: false,
        openCaseSuccessSnackBar: false,
        openCaseErrorSnackBar: false,
      };
    case ACTION_TYPE_CASE.SET_CASE_TO_EDIT:
      return {
        ...state,
        loading: false,
        caseData: action.caseData,
        openCaseUpdateSnackBar: false,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE:
      const newForm = { ...state.caseData };
      newForm[action.id] = action.value;
      return {
        ...state,
        caseData: newForm,
        loading: false,
      };

    case ACTION_TYPE_CASE.UPDATE_CASE_SUCCESS:
      return {
        ...state,
        updateCaseSuccess: action.bool,
        loading: false,
        openCaseUpdateSnackBar: false,
      };
    case ACTION_TYPE_CASE.UPDATE_CASE_FAILURE:
      return {
        ...state,
        errorMsgForUpdateCase: action.error,
        loading: false,
        openCaseUpdateSnackBar: true,
      };

    case ACTION_TYPE_CASE.SCHEDULE_DATE_BY_AGENT_SUCCESS:
      return {
        ...state,
        response: action.response,
        loading: false,
        openCaseUpdateSnackBar: false,
      };
    case ACTION_TYPE_CASE.SCHEDULE_DATE_BY_AGENT_FAILURE:
      return {
        ...state,
        errorMsgForUpdateCase: action.error,
        loading: false,
        openCaseUpdateSnackBar: true,
      };
    case ACTION_TYPE_CASE.FETCH_FILE_DETAILS_FOR_CASE_SUCCESS:
      return {
        ...state,
        openCaseUpdateSnackBar: false,
        fileResponse: action.fileResponse.actions,
        loading: false,
      };
    case ACTION_TYPE_CASE.FETCH_FILE_DETAILS_FOR_CASE_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
      };
    case ACTION_TYPE_CASE.HANDLE_CLICK:
      return {
        ...state,
        caseId: action.id,
        loading: false,
      };
    case ACTION_TYPE_CASE.GET_WORKFLOW:
      return {
        ...state,
        workflowName: action.workflowName,
        loading: false,
      };
    case ACTION_TYPE_CASE.FETCH_CASE_CONTACT_SUCCESS:
      return {
        ...state,
        caseContactList: action.caseContactList.caseContacts,
        loading: false,
        success: false,
      };
    case ACTION_TYPE_CASE.FETCH_CASE_CONTACT_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_CONTACT_INPUT:
      const newContactData = { ...state.individualContact };
      newContactData[action.id] = action.value;
      return {
        ...state,
        individualContact: newContactData,
      };
    case ACTION_TYPE_CASE.ADD_CASE_INDIVIDUAL_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: "Successfully Added",
        openCaseContactSuccess: true,
        success: action.response,
      };
    case ACTION_TYPE_CASE.ADD_CASE_INDIVIDUAL_CONTACT_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseContactError: true,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_COMPANY_INPUT:
      const newCompanyData = { ...state.caseCompanyData };
      newCompanyData[action.id] = action.value;
      return {
        ...state,
        caseCompanyData: newCompanyData,
      };
    case ACTION_TYPE_CASE.ADD_CASE_COMPANY_CONTACT_SUCCESS:
      return {
        ...state,
        success: action.response,
        loading: false,
        successMsg: "Successfully Added",
        openCaseContactSuccess: true,
      };
    case ACTION_TYPE_CASE.ADD_CASE_COMPANY_CONTACT_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseContactError: true,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_EXISTING_CONTACT:
      return {
        ...state,
        refId: action.value,
      };
    case ACTION_TYPE_CASE.ADD_CASE_EXISTING_CONTACT_SUCCESS:
      return {
        ...state,
        success: action.response,
        loading: false,
        successMsg: "Successfully Added",
        openCaseContactSuccess: true,
      };
    case ACTION_TYPE_CASE.ADD_CASE_EXISTING_CONTACT_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseContactError: true,
      };
    case ACTION_TYPE_CASE.SEARCH_EXISTING_CONTACT_SUCCESS:
      return {
        ...state,
        existingContacts: action.existingList,
        loading: false,
      };
    case ACTION_TYPE_CASE.SEARCH_EXISTING_CONTACT_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        openCaseContactError: true,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_RELATIONSHIP:
      return {
        ...state,
        relationshipId: action.relationship,
      };
    //FILTER AND EXPORT
    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_STATUS:
      return {
        ...state,
        caseStatus: action.caseStatus,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_END_DATE:
      return {
        ...state,
        caseEndDate: action.caseEndDate,
      };
    case ACTION_TYPE_CASE.HANDLE_CHANGE_CASE_START_DATE:
      return {
        ...state,
        caseStartDate: action.caseStartDate,
      };
    case ACTION_TYPE_CASE.SEARCH_CASE_SUCCESS:
      let searchNextUrl = state.nextUrl;
      if (action.cases && action.cases.nextUrl) {
        searchNextUrl = action.cases.nextUrl;
      }

      return {
        ...state,
        case: action.cases.cases,
        success: action.bool,
        loading: false,
        nextUrl: searchNextUrl,
        totalCaseCount: action.cases.totalCaseCount,
        count: action.cases.count,
      };

    case ACTION_TYPE_CASE.SEARCH_CASE_FAILURE:
      return {
        ...state,
        errorMsgForCase: action.error,
        loading: false,
        searchedCase: null,
      };
    case ACTION_TYPE_CASE.EXPORT_CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        openCaseSuccessSnackBar: true,
        successMsg: "Exported Successfully",
      };
    case ACTION_TYPE_CASE.EXPORT_CASE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForCase: action.error,
        openCaseErrorSnackBar: true,
      };
    case ACTION_TYPE_CASE.CLEAR_FILTER_DATA:
      return {
        ...state,
        loading: false,
        caseStartDate: 0,
        caseEndDate: 0,
        caseStatus: "UNKNOWN_STATUS",
      };
    default:
      return state;
  }
};
export default caseState;
