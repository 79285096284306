import { ACTION_TYPE_DOCUMENTS} from "../constants/Type";

export const requestForDocuments = () =>{
    return{
        type: ACTION_TYPE_DOCUMENTS.REQUEST_FOR_DOCUMENT,
    };
};
export const closeSnackBar = () => {
    return{
        type: ACTION_TYPE_DOCUMENTS.CLOSE_SNACKBAR,
    };
};
export const clearFilterModal = () => {
    return{
        type: ACTION_TYPE_DOCUMENTS.CLEAR_FILTER_MODAL,
    };
};
export const setFilterSuccessToFalse = () => {
    return{
        type: ACTION_TYPE_DOCUMENTS.SET_FILTER_SUCCESS_TO_FALSE,
    };
};
export const fetchDocumentsSuccess = response => {
    return {
        type: ACTION_TYPE_DOCUMENTS.FETCH_DOCUMENT_SUCCESS,
        response
    };
};

export const fetchDocumentsFailure = error => {
    return {
        type: ACTION_TYPE_DOCUMENTS.FETCH_DOCUMENT_FAILURE,
        error
    };
};

export const filterDocumentsSuccess = response => {
    return {
        type: ACTION_TYPE_DOCUMENTS.FILTER_DOCUMENT_SUCCESS,
        response
    };
};

export const filterDocumentsFailure = error => {
    return {
        type: ACTION_TYPE_DOCUMENTS.FILTER_DOCUMENT_FAILURE,
        error
    };
};


export const handleChangeDocumentName = fileName => {
    return {
        type: ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_FILE_NAME,
        fileName,
    };
};

export const handleChangeFromDate = fromDate => {
    return {
        type: ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_START_DATE,
        fromDate,
    };
};

export const handleChangeToDate = toDate => {
    return {
        type: ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_END_DATE,
        toDate,
    };
};