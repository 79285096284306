import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class CorporateClientAPI extends API {
    fetchCorporateList = callback => {
        fetch(APIEndPoints.FETCH_CORPORATE, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchSingleCorporate = (id,callback) => {
        var constUrl = APIEndPoints.FETCH_CORPORATE_BY_ID + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                console.log(error);
                callback(null, "Service error, please try again.");
            });
    };

    addNewCorporateClient = (corporateId, corporateClientData, callback) => {
        let body = JSON.stringify(corporateClientData);
        fetch(APIEndPoints.ADD_CORPORATE_CLIENT + corporateId, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    assignClientToCorporate = (corporateId, clientId, callback) => {
        let body = JSON.stringify({corporateId: corporateId,clientId: clientId});
        fetch(APIEndPoints.ASSIGN_CLIENT_TO_CORPORATE, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteCorporateClient = (corporateId, callback) => {
        const urlString = APIEndPoints.DELETE_CLIENT + corporateId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    editCorporateClient = ( corporateFormData,callback) => {
        fetch( APIEndPoints.UPDATE_CORPORATE ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(corporateFormData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
}

export default new CorporateClientAPI();