import React, {Component} from "react";
import {DatePicker, Radio, Select} from "antd";
import {Checkbox} from "@material-ui/core";
import currencies from "../currencies";
import moment from "moment";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
const {Option} = Select;

class DynamicActionConditionalForm extends Component{
    constructor(props) {
        super(props);
        this.state = {
            conditionalForm:[],
            editedConditionalData: [],
            conditionalFormId:"",
            checked:"",
            optionValueName:"",
            optionRefId:"",
            success: false,
            selectedContactId:"",
            singleConditionalActionState:{},
        }
    }
    componentDidMount() {
        this.props.fetchContactList();
    }

    render() {
        const conditionalFormCaseDataArray = this.props.item;
        const currentActionFormId = this.props.activeActionFormId;
        const currentRefId = this.props.activeRefId;
        const currentOptionValueName = this.props.activeOptionName;
        const contactsList = this.props.contactsList;
        const previousActionData = this.props.previousActionData;
        const caseId = this.props.caseId;
        const workflowId = this.props.workflowId;

        const getDate = (dateToConvert) => {
            let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString('fr-CA');
            return moment(dateConverted,"YYYY-MM-DD");
        };

        const handleChangeContactValue = (id,event, refId) =>{
            this.setState({
                selectedContactId: event
            },()=>{
                const selectedContact = contactsList && contactsList.filter(list=> list.contactId ===
                    this.state.selectedContactId);
                this.props.handleChangeConditionalContactValue(id, selectedContact, event, refId);
            })
        };

        return(
            <React.Fragment>
                <div className="dynamicContainer">
                    {conditionalFormCaseDataArray && conditionalFormCaseDataArray.conditionalForms
                    && conditionalFormCaseDataArray.conditionalForms.length > 0
                    && conditionalFormCaseDataArray.conditionalForms.map(singleConditionalData => {
                        return(
                            <>
                                <div className="row" key={singleConditionalData.conditionalFormId}>
                                    <div className="col-12">
                                        <div className="label">
                                            <label className="fieldLabel">
                                                {singleConditionalData.fieldName}{" "}
                                                <span
                                                    hidden={singleConditionalData.required !== true}
                                                    style={{
                                                        fontSize: "20px",
                                                        color: "red",
                                                        paddingLeft: "3px",
                                                    }}
                                                >
                                              *
                                            </span>
                                            </label>
                                            {
                                                singleConditionalData.content ?
                                                    <></> :
                                                    <span style={{marginLeft: "8px", cursor: "pointer"}}
                                                          onClick={()=>{
                                                              this.setState({
                                                                  singleConditionalActionState: singleConditionalData
                                                              }, () => {
                                                                  this.props.fetchActivityLogById(singleConditionalData.conditionalFormId, workflowId, caseId);
                                                              });
                                                          }}>
                                                <img style={{width: "15px", cursor: "pointer"}} src='/assets/images/logInfo.svg' alt="logInfo"/>
                                            </span>
                                            }
                                        </div>
                                        {
                                            singleConditionalData.fieldType === "TEXT_FIELD" ? (
                                                <>
                                                    <input
                                                        className="inputField"
                                                        type="text"
                                                        autoComplete="off"
                                                        required={singleConditionalData.required === true}
                                                        id={singleConditionalData.conditionalFormId}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        onChange={(e) => {
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                this.props.changeConditionalFormValues(
                                                                singleConditionalData.conditionalFormId,
                                                                e.target.value, this.props.activeRefId, conditionalFormDataId)
                                                        }}
                                                    />
                                                </>
                                            ) : singleConditionalData.fieldType === "PHONE"?(
                                                <>
                                                    <input
                                                        className="inputField"
                                                        type="text"
                                                        required={singleConditionalData.required === true}
                                                        id={singleConditionalData.conditionalFormId}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        onChange={(e) => {
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            this.props.changeConditionalFormValues(
                                                                singleConditionalData.conditionalFormId,
                                                                e.target.value, this.props.activeRefId,conditionalFormDataId)
                                                        }}
                                                    />
                                                </>
                                            ) : singleConditionalData.fieldType === "EMAIL" ?(
                                                <>
                                                    <input
                                                        className="inputField"
                                                        autoComplete="off"
                                                        required={singleConditionalData.required === true}
                                                        type="text"
                                                        id={singleConditionalData.conditionalFormId}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        onChange={(e) => {
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            this.props.changeConditionalFormValues(
                                                                singleConditionalData.conditionalFormId,
                                                                e.target.value, this.props.activeRefId, conditionalFormDataId)
                                                        }}
                                                    />
                                                </>
                                            ) : singleConditionalData.fieldType === "CONTENT"  ? (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={{
                                                            marginTop: '5px',
                                                            marginBottom:'5px',
                                                            color: '#434343',
                                                            fontSize: '14px',
                                                        }}>{singleConditionalData.content}</div>
                                                    </div>
                                                </div>
                                            ) : singleConditionalData.fieldType === "SSN" ?(
                                                <>
                                                    <input
                                                        className="inputField"
                                                        autoComplete="off"
                                                        required={singleConditionalData.required === true}
                                                        type="number"
                                                        id={singleConditionalData.conditionalFormId}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        onChange={(e) => {
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            this.props.changeConditionalFormValues(
                                                                singleConditionalData.conditionalFormId,
                                                                e.target.value,this.props.activeRefId,conditionalFormDataId)
                                                        }}
                                                    />
                                                </>

                                            ) : singleConditionalData.fieldType === "CURRENCY" ? (
                                                    <div className="selects h40 mb-3">
                                                        <Select
                                                            required={singleConditionalData.required === true}
                                                            id={singleConditionalData.conditionalFormId}
                                                            showSearch={false}
                                                            value={singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].data}
                                                            getPopupContainer={node => node.parentNode}
                                                            onChange={(e) => {
                                                                // this.props.changeConditionalFormValues(
                                                                //     singleConditionalData.conditionalFormId, e)
                                                                const conditionalFormDataId =
                                                                    singleConditionalData.conditionalFormData &&
                                                                    singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                        singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                this.props.changeConditionalFormValues(
                                                                    singleConditionalData.conditionalFormId,
                                                                    e, this.props.activeRefId, conditionalFormDataId)
                                                            }}
                                                        >
                                                            {
                                                                currencies && currencies.map(
                                                                    option =>
                                                                        <Option value={option.code} >
                                                                            {option.code} ( {option.symbol} )
                                                                        </Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                            ) : singleConditionalData.fieldType === "DOB" ? (
                                                <>
                                                    <div className="select-dob mb-3" onClick={()=>{
                                                        this.setState({isBlocking: true})
                                                    }}>
                                                        <DatePicker
                                                            getPopupContainer={node => node.parentNode}
                                                            required={singleConditionalData.required === true}
                                                            value={singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].data &&
                                                            getDate(singleConditionalData.conditionalFormData[0].data)}
                                                            format="MM-DD-YYYY"
                                                            onChange={(event)=>{
                                                                const conditionalFormDataId =
                                                                    singleConditionalData.conditionalFormData &&
                                                                    singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                        singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                if(event) {
                                                                    const valueOfInput1 = event.format();
                                                                    this.props.changeConditionalFormValues(
                                                                        singleConditionalData.conditionalFormId,
                                                                        new Date(valueOfInput1).getTime(),
                                                                        this.props.activeRefId, conditionalFormDataId)
                                                                }
                                                            }}
                                                            onPanelChange={(event) => {
                                                                if(event) {
                                                                    const conditionalFormDataId =
                                                                        singleConditionalData.conditionalFormData &&
                                                                        singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                            singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                    const valueOfInput1 = event.format();
                                                                    this.props.changeConditionalFormValues(
                                                                        singleConditionalData.conditionalFormId,
                                                                        new Date(valueOfInput1).getTime(),
                                                                        this.props.activeRefId, conditionalFormDataId)
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            ) : singleConditionalData.fieldType === "DROPDOWN" ? (
                                                <div className="selects h40 mb-3">
                                                    <Select
                                                        required={singleConditionalData.required === true}
                                                        id={singleConditionalData.conditionalFormId}
                                                        showSearch={false}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        getPopupContainer={node => node.parentNode}
                                                        onChange={(e) => {
                                                            // this.props.changeConditionalFormValues(
                                                            //     singleConditionalData.conditionalFormId, e)
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                    singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            this.props.changeConditionalFormValues(
                                                                singleConditionalData.conditionalFormId,
                                                                e, this.props.activeRefId, conditionalFormDataId)
                                                        }}
                                                        // onChange={(e) => {
                                                        //     this.setState({isBlocking: true});
                                                        //     const conditionalFormDataId =
                                                        //         singleConditionalData.conditionalFormData &&
                                                        //         singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                        //         singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                        //     this.props.changeConditionalFormValues(
                                                        //         singleConditionalData.conditionalFormId,
                                                        //         e, this.props.activeRefId,conditionalFormDataId)
                                                        // }}
                                                    >
                                                        {
                                                            singleConditionalData.optionValue &&
                                                            singleConditionalData.optionValue.map(
                                                                option =>
                                                                    <Option value={option.optionValueId}>
                                                                        {option.optionValueName}
                                                                    </Option>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                            ) : singleConditionalData.fieldType === "RADIO_BUTTON" ? (
                                                <div style={{marginBottom: "12px", marginTop: "10px"}}>
                                                    <Radio.Group
                                                        required={singleConditionalData.required === true}
                                                        id={singleConditionalData.conditionalFormId}
                                                        value={singleConditionalData.conditionalFormData &&
                                                        singleConditionalData.conditionalFormData[0].data}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            const conditionalFormDataId =
                                                                singleConditionalData.conditionalFormData &&
                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                    singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                            this.props.changeConditionalFormValues(
                                                                singleConditionalData.conditionalFormId,
                                                                e.target.value, this.props.activeRefId, conditionalFormDataId)
                                                        }}>
                                                        {
                                                            singleConditionalData.optionValue &&
                                                            singleConditionalData.optionValue.map(
                                                                option =>
                                                                    <Radio
                                                                        style={{fontSize: "18px"}}
                                                                        value={option.optionValueId}>
                                                                        <span style={{fontSize: "16px"}}>
                                                                            {option.optionValueName}
                                                                        </span>
                                                                    </Radio>
                                                            )
                                                        }
                                                    </Radio.Group>
                                                </div>
                                            ) : singleConditionalData.fieldType === "CHECKBOX" ?(
                                                <div>
                                                    <ul style={{listStyleType: "none", paddingLeft: "5px"}}>
                                                        {
                                                            singleConditionalData.optionValue &&
                                                            singleConditionalData.optionValue.map(
                                                                (option, index) => {
                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return(
                                                                        <li>
                                                                            <Checkbox
                                                                                onClick={(event)=>{
                                                                                    this.props.handleClick(event, option)
                                                                                }}
                                                                                checked={!!option.isSelected}
                                                                                value={singleConditionalData.conditionalFormData &&
                                                                                singleConditionalData.conditionalFormData.map(action => action.data)}
                                                                                onChange={(event) => {
                                                                                    this.setState({
                                                                                        checked : event.target.checked,
                                                                                        activeRefId: option.refId,
                                                                                        activeConditionalFormId:  singleConditionalData.conditionalFormId,
                                                                                    })
                                                                                    //FOR GET
                                                                                    const conditionalFormDataIdList =
                                                                                        singleConditionalData.conditionalFormData && singleConditionalData.conditionalFormData.map(
                                                                                            conditionalForm => {
                                                                                                return{
                                                                                                    conditionalFormDataId: conditionalForm.conditionalFormDataId,
                                                                                                    data: conditionalForm.data,
                                                                                                }
                                                                                            }
                                                                                        )
                                                                                    if(conditionalFormCaseDataArray
                                                                                        && conditionalFormCaseDataArray.step === "firstStep" && event.target.checked){
                                                                                        this.props.handleSetSelectedOption(singleConditionalData.conditionalFormId, option.optionValueId)
                                                                                        this.props.getConditionalDataForCase(option.refId,
                                                                                            singleConditionalData.conditionalFormId, "secondStep", currentActionFormId, currentRefId, currentOptionValueName)
                                                                                    }else if(conditionalFormCaseDataArray
                                                                                        && conditionalFormCaseDataArray.step === "secondStep" && event.target.checked){
                                                                                        this.props.handleSetSelectedOption(singleConditionalData.conditionalFormId, option.optionValueId)
                                                                                        this.props.getConditionalDataForCase(option.refId,
                                                                                            singleConditionalData.conditionalFormId, "thirdStep", currentActionFormId, currentRefId, currentOptionValueName);
                                                                                    }
                                                                                    this.props.handleChangeCheckBoxValueForConditional(
                                                                                        singleConditionalData.conditionalFormId,
                                                                                        event.target.checked,
                                                                                        option.optionValueId,
                                                                                        option.refId,
                                                                                        this.props.activeRefId,
                                                                                        this.props.activeActionFormId,
                                                                                        conditionalFormDataIdList
                                                                                    )
                                                                                }}
                                                                                inputProps={{'aria-labelledby': labelId}}
                                                                            />
                                                                            <span style={{fontSize: "14px",  color: "#434343"}}>
                                                                                {option.optionValueName}
                                                                            </span>
                                                                            {
                                                                                option.isSelected ?
                                                                                        <span onClick={() => {
                                                                                            const conditionalFormDataId =
                                                                                                singleConditionalData.conditionalFormData &&
                                                                                                singleConditionalData.conditionalFormData[0] &&
                                                                                                singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                                                    singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                                            this.props.changeConditionalFormValues(
                                                                                                singleConditionalData.conditionalFormId,
                                                                                                option.optionValueId, this.props.activeRefId,conditionalFormDataId, "CHECKBOX")
                                                                                            this.props.handleChangeIds(option.optionValueName, option.refId)
                                                                                            this.props.changeActiveValue(singleConditionalData.conditionalFormId,
                                                                                                option.refId, option.optionValueName);
                                                                                            if(conditionalFormCaseDataArray
                                                                                                && conditionalFormCaseDataArray.step === "firstStep"){
                                                                                                this.props.getConditionalDataForCase(option.refId,
                                                                                                    singleConditionalData.conditionalFormId, "secondStep",
                                                                                                    currentActionFormId, currentRefId, currentOptionValueName)
                                                                                            }else if(conditionalFormCaseDataArray
                                                                                                && conditionalFormCaseDataArray.step === "secondStep"){
                                                                                                this.props.getConditionalDataForCase(option.refId,
                                                                                                    singleConditionalData.conditionalFormId, "thirdStep",
                                                                                                    currentActionFormId, currentRefId, currentOptionValueName);
                                                                                            }
                                                                                        }} hidden={!option.isConditionalForm}>
                                                                                            <EditOutlinedIcon
                                                                                                style={{
                                                                                                    paddingLeft:"12px",
                                                                                                    width: "30px",
                                                                                                    paddingBottom: "4px",
                                                                                                    cursor:"pointer"}}
                                                                                            />
                                                                                        </span>: null
                                                                            }
                                                                        </li>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            ) : singleConditionalData.fieldType === "CONTACT" ? (
                                                <>
                                                    <div className="select-input h40">
                                                        <Select
                                                            className="drop"
                                                            value={singleConditionalData.conditionalFormData &&
                                                            singleConditionalData.conditionalFormData[0].data}
                                                            getPopupContainer={node => node.parentNode}
                                                            onChange={(e)=>{
                                                                handleChangeContactValue(singleConditionalData.conditionalFormId, e,this.props.activeRefId );
                                                                // this.props.handleChangeConditionalContactValue(value, refId, conditionalFormId)
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onSearch={(value)=>{
                                                                this.props.searchIndividualContact(value);
                                                            }}
                                                        >
                                                            {contactsList && contactsList.map(list =>
                                                                <Option value={list.contactId}>{list.name}</Option>
                                                            )}
                                                        </Select>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-6">
                                                            <label>Email</label>
                                                            <div>
                                                                <input
                                                                    disabled
                                                                    id={singleConditionalData.conditionalFormId}
                                                                    value={singleConditionalData.contact &&
                                                                    singleConditionalData.contact.emailPhone}
                                                                    className="fileNameInput outerFormDiv"
                                                                    // style={style.inputField}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Phone</label>
                                                            <input
                                                                disabled
                                                                id={singleConditionalData.conditionalFormId}
                                                                value={singleConditionalData.contact &&
                                                                singleConditionalData.contact.phone}
                                                                className="fileNameInput outerFormDiv"
                                                            />
                                                        </div>
                                                        <div className="col-6" hidden>
                                                            <label>Address</label>
                                                            <div>
                                                                <input
                                                                    disabled
                                                                    id={singleConditionalData.conditionalFormId}
                                                                    value={singleConditionalData.contact &&
                                                                    singleConditionalData.contact.address}
                                                                    className="fileNameInput outerFormDiv"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <label>Contact Type</label>
                                                            <div>
                                                                <input
                                                                    disabled
                                                                    id={singleConditionalData.conditionalFormId}
                                                                    value={singleConditionalData.contact &&
                                                                    singleConditionalData.contact.contactType.replace("_"," ")}
                                                                    className="fileNameInput outerFormDiv"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : singleConditionalData.fieldType === "PHOTO" ? (
                                                <>
                                                <div style={{minWidth: '20px !important'}}>
                                                    {
                                                        singleConditionalData.conditionalFormData ?
                                                            <div>
                                                                <div className="extra">
                                                                    <img className='dataImage'
                                                                         src={singleConditionalData.conditionalFormData &&
                                                                         singleConditionalData.conditionalFormData.length > 0 &&
                                                                         singleConditionalData.conditionalFormData[0].data}
                                                                         style={{minWidth: '100px !important'}}/>
                                                                </div>
                                                                <div>
                                                                    <input type="text"
                                                                           className="fileNameInput outerFormDiv"
                                                                           id={singleConditionalData.conditionalFormId}
                                                                           value={singleConditionalData.conditionalFormData &&
                                                                           singleConditionalData.conditionalFormData[0].fileName}
                                                                           placeholder="Image Name"
                                                                           onChange={(e) => {
                                                                               e.preventDefault();
                                                                               const conditionalFormDataId =
                                                                                   singleConditionalData.conditionalFormData &&
                                                                                   singleConditionalData.conditionalFormData[0].conditionalFormDataId ?
                                                                                       singleConditionalData.conditionalFormData[0].conditionalFormDataId:""
                                                                               this.props.handleChangeConditionalFileValue(
                                                                                   e.target.value, this.props.activeRefId, singleConditionalData.conditionalFormId,)
                                                                           }}
                                                                    />
                                                                </div>
                                                            </div> : null
                                                    }

                                                    <div style={{width: '100%'}} className="non-printable">
                                                        <label style={{
                                                            width: '115px',
                                                            marginTop: '0.5rem',
                                                            marginBottom: '1.5rem',
                                                            background: 'rgba(204,204,254,0.32)',
                                                            border: '1px solid #7b7b7b',
                                                            textAlign: 'center',
                                                            padding: '4px',
                                                            cursor: 'pointer'
                                                        }}>
                                                            <input
                                                                id={singleConditionalData.conditionalFormId}
                                                                type="file"
                                                                required={singleConditionalData.required === true}
                                                                onChange={(e) => {
                                                                    e.preventDefault();
                                                                    this.setState({isBlocking: true});
                                                                    this.props.onChangeImageFileHandlerInConditional(e,
                                                                        singleConditionalData.conditionalFormId,
                                                                        this.props.activeRefId,
                                                                        singleConditionalData.conditionalFormData
                                                                        && singleConditionalData.conditionalFormData[0].fileName)
                                                                }}
                                                                className="hidden" style={{display: 'none'}}
                                                            />
                                                            Select file
                                                        </label>
                                                    </div>
                                                </div>
                                                </>
                                            ) : singleConditionalData.fieldType === "DOCUMENT" ? (
                                                <>
                                                    <div style={{minWidth: '20px !important'}}>
                                                        {
                                                            singleConditionalData.conditionalFormData
                                                                && singleConditionalData.conditionalFormData[0]?
                                                                <>
                                                                    {singleConditionalData.conditionalFormData[0].data.split(".").pop() === "pdf" ?
                                                                        <div className="extra">
                                                                            <img
                                                                                src="/assets/images/pdf.svg"
                                                                                alt="pdf"
                                                                                style={{
                                                                                    width: '40px',
                                                                                    marginRight: '12px'
                                                                                }}/>
                                                                        </div>
                                                                        :
                                                                        <div className="extra">
                                                                            <img
                                                                                src="/assets/images/text_logo.png"
                                                                                alt="pdf"
                                                                                style={{
                                                                                    width: '40px',
                                                                                    marginRight: '12px'
                                                                                }}/>
                                                                        </div>
                                                                    }
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            id={singleConditionalData.conditionalFormId}
                                                                            value={singleConditionalData.conditionalFormData &&
                                                                            singleConditionalData.conditionalFormData[0].fileName}
                                                                            className="fileNameInput outerFormDiv"
                                                                            placeholder="Document Name"
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                this.props.handleChangeConditionalFileValue(
                                                                                    e.target.value, this.props.activeRefId, singleConditionalData.conditionalFormId)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </> : null
                                                        }
                                                        <div style={{width: '100%'}} className="non-printable">
                                                            <label style={{
                                                                width: '115px',
                                                                marginTop: '0.5rem',
                                                                marginBottom: '1.5rem',
                                                                background: 'rgba(204,204,254,0.32)',
                                                                border: '1px solid #7b7b7b',
                                                                textAlign: 'center',
                                                                padding: '4px',
                                                                cursor: 'pointer'
                                                            }}>
                                                                <input
                                                                    id={singleConditionalData.conditionalFormId}
                                                                    type="file"
                                                                    required={singleConditionalData.required === true}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.onChangeDocumentFileHandlerInConditional(e,
                                                                            singleConditionalData.conditionalFormId,
                                                                            this.props.activeRefId,
                                                                            singleConditionalData.conditionalFormData
                                                                            && singleConditionalData.conditionalFormData[0].fileName)
                                                                        this.setState({isBlocking: true});
                                                                    }}
                                                                    className="hidden" style={{display: 'none'}}
                                                                />
                                                                Select file
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : <div>Nothing to show</div>
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div
                    className="row"
                    style={{marginRight: "0rem", marginTop: "2rem"}}
                >
                    <div className="col-4"/>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                        {conditionalFormCaseDataArray &&
                        conditionalFormCaseDataArray.step === "secondStep"
                        || conditionalFormCaseDataArray.step === "thirdStep"?
                            <div style={{position: "absolute", left: "52px"}}>
                                <button
                                    style={style.backButton}
                                    onClick={()=>{
                                        this.props.changeActiveValue( conditionalFormCaseDataArray.prevActionFormId,
                                            conditionalFormCaseDataArray.prevRefId, conditionalFormCaseDataArray.prevOptionName)
                                    }}
                                >BACK</button>
                            </div>: null}
                        {conditionalFormCaseDataArray && conditionalFormCaseDataArray.conditionalForms
                        && conditionalFormCaseDataArray.conditionalForms.length > 0 ? (
                            <>
                                <div>
                                    <button
                                        className="submitButton"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if(conditionalFormCaseDataArray.isSubmitted  === true || previousActionData){
                                                //for Edit
                                                let conditionalFormDataArray = []
                                                conditionalFormCaseDataArray &&
                                                    conditionalFormCaseDataArray.conditionalForms.length > 0 &&
                                                    conditionalFormCaseDataArray.conditionalForms.map(form => {
                                                        form.conditionalFormData &&  form.conditionalFormData.length > 0 &&
                                                        form.conditionalFormData.map(formData => {
                                                            if(formData.fileName){
                                                                if(formData.conditionalFormDataId){
                                                                    let conditionalObject = {
                                                                        conditionalFormDataId: formData.conditionalFormDataId ? formData.conditionalFormDataId : "",
                                                                        data: formData.data,
                                                                        conditionalFormId: form.conditionalFormId,
                                                                        fileName: formData.fileName
                                                                    }
                                                                    conditionalFormDataArray.push(conditionalObject)
                                                                }else{
                                                                    let conditionalObject = {
                                                                        data: formData.data,
                                                                        conditionalFormId: form.conditionalFormId,
                                                                        fileName: formData.fileName
                                                                    }
                                                                    conditionalFormDataArray.push(conditionalObject)
                                                                }
                                                            }else{
                                                                if(formData.conditionalFormDataId){
                                                                    let conditionalObject = {
                                                                        conditionalFormDataId: formData.conditionalFormDataId,
                                                                        data: formData.data,
                                                                        conditionalFormId: form.conditionalFormId,
                                                                    }
                                                                    conditionalFormDataArray.push(conditionalObject)
                                                                }else{
                                                                    let conditionalObject = {
                                                                        data: formData.data,
                                                                        conditionalFormId: form.conditionalFormId,
                                                                    }
                                                                    conditionalFormDataArray.push(conditionalObject)
                                                                }
                                                            }
                                                        })
                                                 })
                                                const conditionalData = {
                                                    refId: this.props.activeRefId,
                                                    caseId: this.props.caseId,
                                                    workflowId: this.props.workflowId,
                                                    conditionalFormData: conditionalFormDataArray
                                                }
                                                this.props.updateConditionalDataForCase(conditionalData);
                                                if(conditionalFormCaseDataArray && conditionalFormCaseDataArray.step==="firstStep"){
                                                    this.props.handleCloseModalFormConditionalData();
                                                }else {
                                                    this.props.changeActiveValue( conditionalFormCaseDataArray.prevActionFormId,
                                                        conditionalFormCaseDataArray.prevRefId, conditionalFormCaseDataArray.prevOptionName)
                                                }
                                            }else{
                                                let conditionalFormDataArray =[];
                                                conditionalFormCaseDataArray &&
                                                    conditionalFormCaseDataArray.conditionalForms.length > 0 &&
                                                    conditionalFormCaseDataArray.conditionalForms.filter(form =>
                                                        form.conditionalFormData && form.conditionalFormData[0] &&
                                                        !form.conditionalFormData[0].conditionalFormDataId).map(form =>
                                                    {
                                                        form.conditionalFormData.map(formData => {
                                                            if(formData.fileName){
                                                                let conditionalObject = {
                                                                    data: formData.data,
                                                                    conditionalFormId: formData.conditionalFormId,
                                                                    fileName: formData.fileName
                                                                }
                                                                conditionalFormDataArray.push(conditionalObject)
                                                            }else{
                                                                let conditionalObject = {
                                                                    data: formData.data,
                                                                    conditionalFormId: formData.conditionalFormId,
                                                                    // fileName: formData.fileName
                                                                }
                                                                conditionalFormDataArray.push(conditionalObject)
                                                            }
                                                        })
                                                    })
                                                const data = {
                                                    refId: conditionalFormCaseDataArray &&
                                                        conditionalFormCaseDataArray.refId,
                                                    conditionalFormData: conditionalFormDataArray,
                                                }
                                                this.props.setConditionalFormDataRequest(data, true)
                                                if(conditionalFormCaseDataArray &&
                                                    conditionalFormCaseDataArray.step==="firstStep"){
                                                    this.props.handleCloseModalFormConditionalData();
                                                }else {
                                                    this.props.changeActiveValue( conditionalFormCaseDataArray.prevActionFormId,
                                                        conditionalFormCaseDataArray.prevRefId, conditionalFormCaseDataArray.prevOptionName)
                                                }

                                            }
                                        }}
                                    >
                                        {!conditionalFormCaseDataArray.isSubmitted && previousActionData === false ? "SUBMIT" : "UPDATE"}
                                    </button>
                                    {/*<button hidden onClick={()=>{*/}
                                    {/*    if(conditionalFormCaseDataArray.isSubmitted  === true){*/}
                                    {/*        //for Edit*/}
                                    {/*        let conditionalFormDataArray = []*/}
                                    {/*        conditionalFormCaseDataArray &&*/}
                                    {/*        conditionalFormCaseDataArray.conditionalForms.length > 0 &&*/}
                                    {/*        conditionalFormCaseDataArray.conditionalForms.map(form => {*/}
                                    {/*            form.conditionalFormData &&  form.conditionalFormData.length > 0 &&*/}
                                    {/*            form.conditionalFormData.map(formData => {*/}
                                    {/*                if(formData.fileName){*/}
                                    {/*                    if(formData.conditionalFormDataId){*/}
                                    {/*                        let conditionalObject = {*/}
                                    {/*                            conditionalFormDataId: formData.conditionalFormDataId
                                    ? formData.conditionalFormDataId : "",*/}
                                    {/*                            data: formData.data,*/}
                                    {/*                            conditionalFormId: form.conditionalFormId,*/}
                                    {/*                            fileName: formData.fileName*/}
                                    {/*                        }*/}
                                    {/*                        conditionalFormDataArray.push(conditionalObject)*/}
                                    {/*                    }else{*/}
                                    {/*                        let conditionalObject = {*/}
                                    {/*                            data: formData.data,*/}
                                    {/*                            conditionalFormId: form.conditionalFormId,*/}
                                    {/*                            fileName: formData.fileName*/}
                                    {/*                        }*/}
                                    {/*                        conditionalFormDataArray.push(conditionalObject)*/}
                                    {/*                    }*/}
                                    {/*                }else{*/}
                                    {/*                    if(formData.conditionalFormDataId){*/}
                                    {/*                        let conditionalObject = {*/}
                                    {/*                            conditionalFormDataId: formData.conditionalFormDataId,*/}
                                    {/*                            data: formData.data,*/}
                                    {/*                            conditionalFormId: form.conditionalFormId,*/}
                                    {/*                        }*/}
                                    {/*                        conditionalFormDataArray.push(conditionalObject)*/}
                                    {/*                    }else{*/}
                                    {/*                        let conditionalObject = {*/}
                                    {/*                            data: formData.data,*/}
                                    {/*                            conditionalFormId: form.conditionalFormId,*/}
                                    {/*                        }*/}
                                    {/*                        conditionalFormDataArray.push(conditionalObject)*/}
                                    {/*                    }*/}

                                    {/*                }*/}
                                    {/*            })*/}
                                    {/*        })*/}
                                    {/*        const conditionalData = {*/}
                                    {/*            refId: this.props.activeRefId,*/}
                                    {/*            caseId: this.props.caseId,*/}
                                    {/*            workflowId: this.props.workflowId,*/}
                                    {/*            conditionalFormData: conditionalFormDataArray*/}
                                    {/*        }*/}
                                    {/*    }else{*/}
                                    {/*        let conditionalFormDataArray =[];*/}
                                    {/*        conditionalFormCaseDataArray &&*/}
                                    {/*        conditionalFormCaseDataArray.conditionalForms.length > 0 &&*/}
                                    {/*        conditionalFormCaseDataArray.conditionalForms.filter(form =>*/}
                                    {/*            form.conditionalFormData && form.conditionalFormData[0] &&*/}
                                    {/*            !form.conditionalFormData[0].conditionalFormDataId).map(form =>*/}
                                    {/*        {*/}
                                    {/*            form.conditionalFormData.map(formData => {*/}
                                    {/*                if(formData.fileName){*/}
                                    {/*                    let conditionalObject = {*/}
                                    {/*                        data: formData.data,*/}
                                    {/*                        conditionalFormId: formData.conditionalFormId,*/}
                                    {/*                        fileName: formData.fileName*/}
                                    {/*                    }*/}
                                    {/*                    conditionalFormDataArray.push(conditionalObject)*/}
                                    {/*                }else{*/}
                                    {/*                    let conditionalObject = {*/}
                                    {/*                        data: formData.data,*/}
                                    {/*                        conditionalFormId: formData.conditionalFormId,*/}
                                    {/*                    }*/}
                                    {/*                    conditionalFormDataArray.push(conditionalObject)*/}
                                    {/*                }*/}
                                    {/*            })*/}
                                    {/*        })*/}
                                    {/*        const data = {*/}
                                    {/*            refId: conditionalFormCaseDataArray &&*/}
                                    {/*                conditionalFormCaseDataArray.refId,*/}
                                    {/*            conditionalFormData: conditionalFormDataArray,*/}
                                    {/*        }*/}
                                    {/*    }*/}
                                    {/*}}>*/}
                                    {/*    SAVE*/}
                                    {/*</button>*/}
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const style ={
    backButton: {
        background: "#8BC83F",
        color: "white",
        border: "none",
        width: "130%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
        height:"37px"
    },
}

export default DynamicActionConditionalForm;