import React, {Component} from "react";
import {Select} from "antd";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {Checkbox} from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const {Option} = Select;
class ActionFormFieldsComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            conditionalForm: this.props.conditionalActionForm.conditionalForm,
            setValue: false,
            checkedValue: false,
            selectedOptionValue: [],
            actionFormId:"",
            optionId: "",
            activeActionFormId:"",
            activeOptionId:"",
            activeOptionName:"",
        }
    }
    showInputForField = () => {
        let a = this.createAddFormObject();
        this.setState({
            conditionalForm: [...this.state.conditionalForm, a]
        });
    };
    createAddFormObject = () => {
        return {
            id: String(Math.random()),
            value: {
                fieldName: '',
                fieldType: '',
                isRequired: false,
                orderNo: this.state.conditionalForm.length + 1
            }
        };
    };
    render() {
        // (current option id)
        const currentOptionId = this.props.activeOptionId;
        // (current actionFormId)
        const currentActionFormId = this.props.activeActionFormId;
        // (previous option name)
        const currentOptionName = this.props.activeOptionName;

        //options' list for checkbox, radio, dropdown
        const optionValues= this.props.optionValues;
        const conditionalActionForm = this.props.conditionalActionForm;
        const setConditionalFormActionData = this.props.setConditionalFormActionData;

        const changeFieldNameValue = (key, value) => {
            const conditionalForm = this.state.conditionalForm;
            conditionalForm.filter(single => single.id === key)[0].value.fieldName = value;
            this.setState({conditionalForm: conditionalForm},()=>
            {this.props.onChangeConditionalActionForm(currentActionFormId, currentOptionId, this.state.conditionalForm)});
        };

        const changeFieldTypeValue = (key, value) => {
            const conditionalForm = this.state.conditionalForm;
            conditionalForm.filter(single => single.id === key)[0].value.fieldType = value;
            this.setState({
                conditionalForm: conditionalForm},
                ()=>{
                    this.props.onChangeConditionalActionForm(currentActionFormId, currentOptionId, this.state.conditionalForm)
                });
        };

        const changeAmountTypeValue = (key, value) => {
            const conditionalForm = this.state.conditionalForm;
            conditionalForm.filter(single => single.id === key)[0].value.amountType = value;
            this.setState({
                    conditionalForm: conditionalForm},
                ()=>{
                    this.props.onChangeConditionalActionForm(currentActionFormId, currentOptionId, this.state.conditionalForm)
                });
        };
        //change content type
        const changeFieldContentValue = (key, value) => {
            const conditionalForm = this.state.conditionalForm;
            conditionalForm.filter(single => single.id === key)[0].value.content = value;
            this.setState({
                    conditionalForm: conditionalForm},
                ()=>{
                    this.props.onChangeConditionalActionForm(currentActionFormId, currentOptionId, this.state.conditionalForm)
                });
        };
        const changeFieldRequiredValue = (key, value) => {
            const conditionalForm = this.state.conditionalForm;
            conditionalForm.filter(single => single.id === key)[0].value.isRequired = value;
            this.setState({
                    conditionalForm: conditionalForm},
                ()=>{
                    this.props.onChangeConditionalActionForm(currentActionFormId, currentOptionId, this.state.conditionalForm)
                });
        };

        const changeFieldOptionValues = (key, optionArray, value) => {
            const conditionalForm = this.state.conditionalForm;
            conditionalForm.filter(single => single.id === key)[0].value.optionValue = value;
            conditionalForm.filter(single => single.id === key)[0].optionObject = optionArray;
            this.setState({
                    conditionalForm: conditionalForm,
                }, ()=>{
                    this.props.onChangeConditionalActionForm(currentActionFormId, currentOptionId, this.state.conditionalForm)
                });
        }

        const changeFieldTypeCheckedValue = (key, activeOptionId, checkedValue) => {
            if(this.state.conditionalForm.filter(single => single.id === key)[0].optionObject.
            filter(obj => obj.optionValueId === activeOptionId)[0]){
                this.state.conditionalForm.filter(single => single.id === key)[0].optionObject.
                filter(obj => obj.optionValueId === activeOptionId)[0].checkedValue = checkedValue;
            }
        };

        const openDropdownForTextBox = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.conditionalForm.length > 0) {
                div.push(<div className="row" style={style.fieldLabel}>
                    <div className="col-4" style={{position: 'inherit'}}>
                        Field Title
                    </div>
                    <div className="col-4" style={{position: 'inherit', paddingLeft: '20px'}}>
                        Field Type
                    </div>
                    <div className="col-3" style={{position: 'inherit'}}>
                        Is Required
                    </div>
                </div>);
            }
            this.state.conditionalForm.map(single => {
                const value = single.value.isRequired;
                return (
                    div.push(<>
                        <div className="row mb-3">
                            <div className="col-4" style={{position: 'inherit'}}>
                                <input type="text"
                                       id={single.id}
                                       value={single.value.fieldName}
                                       onChange={e => {
                                           e.preventDefault();
                                           changeFieldNameValue(e.target.id, e.target.value)
                                       }}
                                       className="inputField"
                                />
                            </div>
                            <div className="col-4" style={{position: 'inherit'}}>
                                    <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                        <Select
                                            className="drop"
                                            showSearch
                                            value={single.value.fieldType}
                                            onChange={e => {
                                                changeFieldTypeValue(single.id, e)
                                            }}
                                            dropdownStyle={{maxHeight: "100px"}}
                                            filterOption={(input, option) =>
                                                option.children &&
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            getPopupContainer={node => node.parentNode}
                                        >
                                            <Option value="TEXT_FIELD">Text Field</Option>
                                            <Option value="EMAIL">Email</Option>
                                            <Option value="PHONE">Phone</Option>
                                            <Option value="PHOTO">Photo</Option>
                                            <Option value="DOCUMENT">Document</Option>
                                            <Option value="DROPDOWN">Dropdown</Option>
                                            <Option value="CHECKBOX">Checkbox</Option>
                                            <Option value="RADIO_BUTTON">Radio Button</Option>
                                            <Option value="SSN">SSN</Option>
                                            <Option value="DOB">Date-of-birth</Option>
                                            <Option value="CONTACT">Contact</Option>
                                            <Option value="CURRENCY">Currency</Option>
                                            <Option value="AMOUNT">Amount</Option>
                                            <Option value="CONTENT">Content</Option>
                                        </Select>
                                    </div>
                            </div>
                            <div className="col-2" style={{
                                textAlign: 'center',
                                fontSize: '16px',
                                position: 'inherit',
                                paddingRight: '35px'
                            }}>
                                <input type="checkbox"
                                       className="form-check-input"
                                       hidden={single.value.fieldType === 'CONTENT'}
                                       id={single.id}
                                       value={single.value.isRequired}
                                       onChange={e => {
                                           changeFieldRequiredValue(e.target.id, !value)
                                       }}
                                       style={{
                                           fontSize: '16px',
                                           marginTop: '10px',
                                           marginLeft: '0',
                                           position: 'inherit',
                                       }}/>
                            </div>
                            <div className="col-1" style={{position: 'inherit'}}>
                                <RemoveCircleOutlineIcon
                                    id={single.id}
                                    style={{
                                        color: 'grey',
                                        width: '24px',
                                        height: '24px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={e => {
                                        e.preventDefault();
                                        closeFieldSection(single.id);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-3"
                             style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'CHECKBOX'}>
                            <div className="col-8">
                                <div className="multiple-select">
                                    <Select
                                        showArrow
                                        mode="tags"
                                        size="default"
                                        className="drop"
                                        placeholder="Add options"
                                        value={single.value.optionValue &&
                                        single.value.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                        getPopupContainer={node => node.parentNode}
                                        onChange={(e)=>{
                                            const optionObject = e.map(el => {
                                                const ids = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (ids.length === 0) {
                                                    return {
                                                        optionValueId: String(Math.random()),
                                                        optionValueName: el,
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el,
                                                        optionValueName: ids[0].optionValueName,
                                                    }
                                                }
                                            })
                                            const optionValue = e.map(el => {
                                                const ids = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (ids.length === 0) {
                                                    return {
                                                        optionValueName: el,
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el,
                                                    }
                                                }
                                            })
                                            changeFieldOptionValues(single.id, optionObject, optionValue);
                                        }}
                                        style={{width: '100%'}}
                                        dropdownStyle={{ height: "100px"}}
                                    >
                                        {
                                            optionValues && optionValues.map(value =>
                                                <Option key={value.optionValueId} value={value.optionValueId}>
                                                    {value.optionValueName}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {conditionalActionForm.step === 'thirdStep' ? null :
                        <div  id={single.id} className="mt-3 mb-4" hidden={single.value.fieldType !== 'CHECKBOX'}>
                            <ul style={{listStyleType: "none", paddingLeft: "0px", marginLeft: "-10px"}}>
                                {single.optionObject && single.optionObject.map(option =>
                                    <li id={option.optionValueId}>
                                        <Checkbox
                                            checked={option.checkedValue}
                                            onChange={(event) =>
                                            {
                                                const data = {
                                                    actionId: single.id,
                                                    optionId: option.optionValueId,
                                                    isSelected: event.target.checked,
                                                    optionName: option.optionValueName,
                                                    conditionalForm: [],
                                                    previousOptionId: currentOptionId,
                                                    previousActionFormId: currentActionFormId,
                                                    previousOptionName: currentOptionName,
                                                }
                                                if(conditionalActionForm && conditionalActionForm.step === 'firstStep'){
                                                    data.step = 'secondStep'
                                                }else if(conditionalActionForm && conditionalActionForm.step === 'secondStep'){
                                                    data.step = 'thirdStep'
                                                }
                                                this.setState({
                                                    activeActionFormId: single.id,
                                                    activeOptionId: option.optionValueId,
                                                    activeOptionName: option.optionValueName,
                                                    checkedValue: event.target.checked,
                                                }, () => {
                                                    if(this.state.checkedValue){
                                                        setConditionalFormActionData(data)
                                                        this.props.changeActiveIds(single.id, option.optionValueId,
                                                            option.optionValueName )
                                                    }
                                                })
                                                changeFieldTypeCheckedValue(single.id ,option.optionValueId,
                                                    event.target.checked)
                                            }}
                                        />
                                        {option.optionValueName}
                                        <span onClick={() => {
                                            this.setState({
                                                activeOptionId: option.optionValueId,
                                            })
                                            this.props.changeActiveIds(single.id, option.optionValueId, option.optionValueName)
                                            // this.props.handleOpenNewActionForm(this.state.checkedValue);
                                        }}  hidden={!option.checkedValue}>
                                            <EditOutlinedIcon
                                                style={{
                                                    paddingLeft:"12px",
                                                    width: "30px",
                                                    paddingBottom: "4px",
                                                    cursor:"pointer"}}
                                            />
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>}
                        <div className="row mb-5" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'RADIO_BUTTON'}
                        >
                            <div className="col-8">
                                <div
                                    className="multiple-select"
                                >
                                    <Select
                                        showArrow
                                        mode="tags"
                                        size="default"
                                        placeholder="Add options"
                                        filterOption={(input, option) =>
                                            option.children &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={single.value.optionValue &&
                                        single.value.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                        getPopupContainer={node => node.parentNode}
                                        onChange={(e) => {
                                            const optionValue = e.map(el => {
                                                const name = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (name.length === 0) {
                                                    return {
                                                        optionValueName: el
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el
                                                    }
                                                }

                                            })
                                            changeFieldOptionValues(single.id,[], optionValue)
                                        }}
                                        dropdownStyle={{ height: "100px"}}
                                        style={{width: '100%'}}
                                    >
                                        {
                                            optionValues && optionValues.map(value =>
                                                <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'DROPDOWN'}
                        >
                            <div className="col-8">
                                <div
                                    className="multiple-select"
                                >
                                    <Select
                                        showArrow
                                        mode="tags"
                                        size="default"
                                        placeholder="Add options"
                                        getPopupContainer={node => node.parentNode}
                                        value={single.value.optionValue &&
                                        single.value.optionValue.map(value=> value.optionValueId || value.optionValueName)}
                                        dropdownStyle={{ height: "100px"}}
                                        filterOption={(input, option) =>
                                            option.children &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e) => {
                                            const optionValue = e.map(el => {
                                                const name = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (name.length === 0) {
                                                    return {
                                                        optionValueName: el
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el
                                                    }
                                                }

                                            })
                                            changeFieldOptionValues(single.id,[], optionValue)
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        {
                                            optionValues && optionValues.map(value =>
                                                <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'AMOUNT'}
                        >
                            <div className="col-8">
                                <div className="multiple-select h40">
                                    <Select
                                        showArrow
                                        size="default"
                                        placeholder="Select amount type"
                                        filterOption={(input, option) =>
                                            option.children &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e) => {
                                            changeAmountTypeValue(single.id, e)
                                        }}
                                        value={single.value.amountType}
                                        getPopupContainer={node => node.parentNode}
                                        style={{width: '100%'}}
                                    >
                                        <Option value="INCOME">Income</Option>
                                        <Option value="ASSET">Asset</Option>
                                        <Option value="EXPENSE">Expense</Option>
                                        <Option value="IGNORE">Ignore</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4"
                             id={single.id}
                             hidden={single.value.fieldType !== 'CONTENT'}
                        >
                            <div className="col-8">
                                <div className="multiple-select">
                                    <textarea
                                        className="inputField"
                                        onChange={(e)=>{
                                            changeFieldContentValue(single.id, e.target.value)
                                        }}
                                        style={{minHeight: '150px', padding:'15px'}}/>
                                </div>
                            </div>
                        </div>
                    </>)
                )
            });
            return div
        };
        const closeFieldSection = (key) => {
            var previousOrder = this.state.conditionalForm.filter(
                single => single.id === key
            )[0].value.orderNo;
            var removedActionData = this.state.conditionalForm.filter(x => x.id !== key)
            var lessThanOrder = removedActionData.filter(x => x.value.orderNo < previousOrder)
            var orderFiltered = removedActionData.filter(x => x.value.orderNo > previousOrder)
            orderFiltered.map(single => {
                single.value.orderNo = single.value.orderNo - 1
            })
            lessThanOrder = [...lessThanOrder, ...orderFiltered]

            this.setState({
                conditionalForm: lessThanOrder
            }, () => {
                // filterActionFormData()
            })
        };

        return(
            <React.Fragment>
                <div style={style.container}>
                    <div className={"row"}>
                        <div className="col-12" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            {openDropdownForTextBox()}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <div style={{cursor: 'pointer'}}
                                 onClick={(e) => {
                                     this.showInputForField();
                                 }}>
                                <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                     style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Field</span>
                            </div>
                        </div>
                    </div>
                    <div className={"row"} style={{
                        justifyContent: 'flex-end',
                        padding: '0 0 1rem 0',
                        marginLeft: 0,
                        width: '100%',
                    }}>
                        <div className="col-4"/>
                        <div className="col-4"/>
                        <div className="col-4">
                            {
                                conditionalActionForm &&
                                conditionalActionForm.step === "secondStep" ||  conditionalActionForm.step === "thirdStep" ?
                            <div style={{position: "absolute", left: "65px"}}>
                                <button
                                    style={style.backButton}
                                    onClick={()=>{
                                        this.props.changeActiveIds( conditionalActionForm.previousActionFormId,
                                            conditionalActionForm.previousOptionId,
                                            conditionalActionForm.previousOptionName)
                                    }}
                                >BACK</button>
                            </div>: null}
                            <div>
                                <button
                                    onClick={()=>{
                                      if(conditionalActionForm && conditionalActionForm.step === "firstStep"){
                                          if(conditionalActionForm.filterCondition !== undefined){
                                              this.props.changeFieldConditionalForm(currentActionFormId,
                                                  currentOptionId, conditionalActionForm.filterCondition);
                                          }else{
                                              this.props.onHandleFirstConditionalFormStep(currentActionFormId,
                                                  currentOptionId, this.state.conditionalForm)
                                          }
                                            this.props.handleCloseDialogueForNewActionForm()
                                        }else if(conditionalActionForm && conditionalActionForm.step === "secondStep"
                                          || conditionalActionForm.step === "thirdStep"){
                                          this.props.changeConditionalFormInOption(conditionalActionForm.previousActionFormId,
                                              conditionalActionForm.previousOptionId, conditionalActionForm.actionId,
                                              conditionalActionForm.optionId, this.state.conditionalForm);
                                          this.props.changeActiveIds( conditionalActionForm.previousActionFormId,
                                              conditionalActionForm.previousOptionId,
                                              conditionalActionForm.previousOptionName)
                                      }
                                    }}
                                    disabled={this.state.conditionalForm.length === 0}
                                    style={this.state.conditionalForm.length === 0 ? style.disableButton
                                        : style.button}
                                >
                                    ADD
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const style = {
    container: {
        background: "#ffffff",
        // marginTop: "78px",
        marginLeft: '3.5%',
        marginRight: '3.5%'
    },
    label: {
        color: '#606060',
        marginBottom: '0',
        fontSize: '16px',
        paddingBottom: "0.5rem"
    },
    input: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        color: "#606060",
        width: '100%',
        marginBottom: '1.2rem'
    },
    button: {
        background: "#8BC83F",
        color: "white",
        border: "none",
        width: "42%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
        float: "right",
        marginRight: "-32px",
    },
    backButton: {
        background: "#8BC83F",
        color: "white",
        border: "none",
        width: "130%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
        // float: "right",
        // marginRight: "-150px",
    },
    disableButton:{
        background: "#a8d77c",
        color: "white",
        border: "none",
        width: "42%",
        fontSize: "15px",
        padding: "0.4rem",
        borderRadius: "2px",
        float: "right",
        marginRight: "-32px",
    }
};

export default ActionFormFieldsComponent;