import {ACTION_TYPE_USER} from "../constants/Type";

const initialState = {
    loading: false,
    openUserSnackBar: false,
    openPasswordResetSuccessSnackBar: false,
    errorMsgForUser: '',
    SUPER_ADMIN: false,
    ADMIN: false,
    AGENT: false,
    CORPORATE: false,
    dataToDisplay: '',
    tabValue: "0",
    userData: {
        user: {
            fullName: '',
            emailPhone: '',
            userRole: 1
        },
        accountType: 1
    },
    password: {
        newPassword: '',
        code: ''
    },
    addUserSuccess: false,

};

const userState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_USER.USER_FETCH_SUCCESS:
            console.log("state.userData.user.userRole ", state.userData.user.userRole )
            if (state.userData.user.userRole === 1 || state.userData.user.userRole === '1'
                || state.userData.user.userRole === "SUPER_ADMIN") {
                return {
                    ...state,
                    user: action.user.users,
                    dataToDisplay: action.user.users.filter(
                        e => e.userRole === "SUPER_ADMIN"
                    ),
                    loading: false,
                    openUserSnackBar: false,
                    addUserSuccess: false,
                };
            } else if (
                state.userData.user.userRole === 2 ||
                state.userData.user.userRole === "2" || state.userData.user.userRole === "ADMIN") {
                return {
                    ...state,
                    user: action.user.users,
                    dataToDisplay: action.user.users.filter(
                        e => e.userRole === "ADMIN"
                    ),
                    loading: false,
                    openUserSnackBar: false,
                    addUserSuccess: false,
                };
            } else if (
                state.userData.user.userRole === 3 ||
                state.userData.user.userRole === "3" || state.userData.user.userRole === "AGENT") {
                return {
                    ...state,
                    user: action.user.users,
                    dataToDisplay: action.user.users.filter(
                        e => e.userRole === "AGENT"
                    ),
                    loading: false,
                    openUserSnackBar: false,
                    addUserSuccess: false,
                };
            } else {
                break;
            }

        case ACTION_TYPE_USER.USER_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForUser: action.error,
                loading: false,
                openUserSnackBar: true
            };
        case ACTION_TYPE_USER.HANDLE_CHANGE_USER:
            const newForm = {...state.userData};
            newForm.user[action.id] = action.value;
            return {
                ...state,
                userData: newForm,
                loading: false
            };
        case ACTION_TYPE_USER.HANDLE_CHANGE_FOR_RESET_PASSWORD:
            const newPasswordForm = {...state};
            newPasswordForm[action.id] = action.value;
            return {
                ...newPasswordForm,
                loading: false,
            };
        case ACTION_TYPE_USER.HANDLE_CHANGE_FOR_USER_IMAGE:
            const oldUserData = {...state.userData};
            return {
                ...state,
                userData: oldUserData,
                loading: false
            };
        case ACTION_TYPE_USER.USER_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_USER.ADD_USER_SUCCESS:
            return {
                ...state,
                addUserSuccess: action.bool,
                loading: false,
                openUserSnackBar: false
            };
        case ACTION_TYPE_USER.ADD_USER_FAILURE:
            return {
                ...state,
                errorMsgForUser: action.error,
                loading: false,
                openUserSnackBar: true
            };
        case ACTION_TYPE_USER.CLOSE_SNACK_BAR_USER_PAGE:
            return {
                ...state,
                openUserSnackBar: false,
                openPasswordResetSuccessSnackBar: false,
            };
        case ACTION_TYPE_USER.SET_FILTERED_USER_ROLE:
            return {
                ...state,
                dataToDisplay: action.dataToDisplay,
                loading: false
            };
        case ACTION_TYPE_USER.SET_DATA_TO_DISPLAY_EMPTY:
            return {
                ...state,
                dataToDisplay: action.data,
                loading: false
            };
        case ACTION_TYPE_USER.DELETE_USER_SUCCESS:
            const oldUserList = state.user;
            let newUsers = [];
            for (let i = 0; i < oldUserList.length; i++) {
                if (oldUserList[i].userId !== action.userId) {
                    newUsers.push(oldUserList[i]);
                }
            }
            return {
                ...state,
                newUser: newUsers,
                loading: false,
                openUserSnackBar: false
            };
        case ACTION_TYPE_USER.UPDATE_USER_ROLE_SUCCESS:
            return {
                ...state,
                addUserSuccess: action.bool,
            }
        case ACTION_TYPE_USER.DELETE_USER_FAILURE:
            return {
                ...state,
                deleteFailMsg: action.error,
                loading: false,
                openUserSnackBar: true,
                errorMsgForUser: action.error
            };
        case ACTION_TYPE_USER.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                openPasswordResetSuccessSnackBar: true,
                openUserSnackBar: false
            };
        case ACTION_TYPE_USER.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                openPasswordResetSuccessSnackBar: false,
                errorMsgForUser: action.error,
                openUserSnackBar: true
            };
        case ACTION_TYPE_USER.SET_USER_DATA_TO_EMPTY:
            return {
                ...state,
            };
        default:
            return state;
    }
};

export default userState;