import { connect } from 'react-redux';
import ScheduleTransactionForm from "../../screens/ScheduleTransactionFormScreen";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
    scheduleTransactionRequestMade,
    addNewScheduleTransactionFailure,
    addNewScheduleTransactionSuccess,
    handleChangeOtherScheduleTransaction,
    handleChangeScheduleDetails,
    vendorRequestMade,
    fetchVendorSuccess,
    fetchVendorFailure,
    closeScheduleTransactionSnackBar,
    fetchScheduleTransactionSuccess,
    fetchScheduleTransactionFailure,
    searchScheduleTransactionFailure,
    searchScheduleTransactionSuccess,
    searchClientListFailure,
    searchClientListSuccess,
    clientRequestMade,
    fetchSingleClientSuccess,
    fetchSingleClientFailure,
} from "../../actions";
import {ClientAPI, DynamicActionAPI, VendorAPI} from "../../api";
import {
    clearFileData,
    handleChangeBankDetailName,
    handleChangeClientName, handleChangeTransactionMedium, handleChangeTransactionType,
    requestScheduleTransactionFailure,
    requestScheduleTransactionSuccess, uploadDocFileFailure, uploadDocFileSuccess
} from "../../actions/ScheduleTransactionAction";

const mapStateToProps = state => (
    state
);

const mapDispatchToProps = (dispatch) => {
    return{
        handleChangeAddScheduleDetails: (id, value) => dispatch(handleChangeScheduleDetails(id, value)),
        handleChangeScheduleTransaction: (id,value) => dispatch(handleChangeOtherScheduleTransaction(id,value)),
        handleChangeClientName: (clientId) => dispatch(handleChangeClientName(clientId)),
        handleChangeTransactionMedium: (transMediumType) => dispatch(handleChangeTransactionMedium(transMediumType)),
        handleChangeTransactionType: (transType) => dispatch(handleChangeTransactionType(transType)),
        handleChangeBankDetailName: (name) => dispatch(handleChangeBankDetailName(name)),
        handleSnackBarClose: () => dispatch(closeScheduleTransactionSnackBar(true)),

        fetchScheduleTransactionList: () => {
            dispatch(scheduleTransactionRequestMade(true));
            ScheduleTransactionAPI.fetchScheduleTransactionList((response, error) => {
                if (response) {
                    dispatch(fetchScheduleTransactionSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchScheduleTransactionFailure(error.msg))
                    } else {
                        dispatch(fetchScheduleTransactionFailure(error));
                    }
                }
            });
        },
        searchScheduleTransaction: (value) => {
            ScheduleTransactionAPI.searchScheduleTransaction(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchScheduleTransactionFailure(error.msg))
                    } else {
                        dispatch(searchScheduleTransactionFailure(error));
                    }
                } else {
                    dispatch(searchScheduleTransactionSuccess(response));
                    console.log('response', response);
                }
            })
        },

        searchClientList: (value) => {
            ScheduleTransactionAPI.searchClientList(value, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(searchClientListFailure(error.msg))
                    } else {
                        dispatch(searchClientListFailure(error));
                    }
                } else {
                    dispatch(searchClientListSuccess(response));
                    console.log('response', response);
                }
            })
        },

        addNewScheduleTransaction : (scheduleTransData, props) => {
            dispatch(scheduleTransactionRequestMade(true));
            console.log('format',scheduleTransData );
            ScheduleTransactionAPI.addNewScheduleTransaction(scheduleTransData, (response, error) => {
                console.log('response in cont', response);
                if (response) {
                    props.fetchScheduleTransactionList();
                    dispatch(addNewScheduleTransactionSuccess(true));
                    props.history.push('/schedule')
                } else {
                    if (error.msg) {
                        dispatch(addNewScheduleTransactionFailure(error.msg));
                    } else {
                        dispatch(addNewScheduleTransactionFailure(error));
                    }
                }
            });
        },
        requestScheduleTransaction : (scheduleTransData, props) => {
            dispatch(scheduleTransactionRequestMade(true));
            ScheduleTransactionAPI.requestScheduleTransaction(scheduleTransData, (response, error) => {
                if (response) {
                    props.fetchScheduleTransactionList();
                    dispatch(requestScheduleTransactionSuccess(true));
                    props.history.push('/schedule')
                } else {
                    if (error.msg) {
                        dispatch(requestScheduleTransactionFailure(error.msg));
                    } else {
                        console.log('error2',error )
                        dispatch(requestScheduleTransactionFailure(error));
                    }
                }
            });
        },
        fetchVendor: () => {
            dispatch(vendorRequestMade(true));
            VendorAPI.fetchVendorList((response, error) => {
                console.log('res', response);
                if (response) {
                    console.log("response", response)
                    dispatch(fetchVendorSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchVendorFailure(error.msg))
                    } else {
                        dispatch(fetchVendorFailure(error));
                    }
                }
            })
        },
        uploadDocumentFile: (selectedFiles, props) => {
            dispatch(scheduleTransactionRequestMade(true));
            let formdata = new FormData();
            formdata.append("doc", selectedFiles.document);
            formdata.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadDocumentFile(formdata, (response, error) => {
                if (response) {
                    dispatch(uploadDocFileSuccess(response))
                } else {
                    if (error.msg) {
                        dispatch(uploadDocFileFailure(error.msg));
                    } else {
                        dispatch(uploadDocFileFailure(error));
                    }
                }
            });
        },
        fetchSingleClient: (clientId , props) =>{
            dispatch(clientRequestMade(true));
            ClientAPI.fetchSingleClient(clientId, (response, error) => {
                if (response) {
                    dispatch(fetchSingleClientSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchSingleClientFailure(error.msg))
                    } else {
                        dispatch(fetchSingleClientFailure(error));
                    }
                }
            })
        },
        clearFileData: () => {
            dispatch(clearFileData());
        }
    }
};


export const ScheduleTransactionFormContainer = connect(mapStateToProps, mapDispatchToProps)(ScheduleTransactionForm);