import React from 'react';
import {formatter} from "../../utils/currencyConvertor";

const TableOuterTotalDiv = ({totalAmount, totalTransaction}) =>{
    return(
        <div>
            <div style={{ marginBottom: "8px" }}>
                      <span className="table-total-title">
                        Total Balance :
                      </span>
                <span className="table-total-value">
                        {totalAmount
                        ? formatter.format(totalAmount / 100)
                        : "$0.00"}
                      </span>
            </div>
            <div style={{ marginBottom: "18px" }}>
                      <span className="table-total-title">
                        Total Transaction :
                      </span>
                <span className="table-total-value">
                        {totalTransaction ? totalTransaction : "0"}
                      </span>
            </div>
        </div>
    )
};

export default TableOuterTotalDiv;