import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import LocalDb from '../api/LocalStorage';

const AuthRequiredRoute = ({component: Component, ...rest}) => (

   
    <Route {...rest} render={props => (
        (LocalDb.getSessions() && LocalDb.getSessions().loginResponse.loginUser.user &&
            !LocalDb.getSessions().loginResponse.loginUser.user.isFirsLogin)
        // || (LocalDb.getSessions() && LocalDb.getSessions().loginResponse.loginUser.corporate &&
            // !LocalDb.getSessions().loginResponse.loginUser.corporate.isFirsLogin)
        || (LocalDb.getSessions() && LocalDb.getSessions().loginResponse.loginUser.client &&
            !LocalDb.getSessions().loginResponse.loginUser.client.isFirsLogin)
            ? <Redirect to={{pathname: '/dashboard', state: {from: props.location}}}/>
            : 

            // added for corporate user
            (LocalDb.getSessions() && LocalDb.getSessions().loginResponse.loginUser.corporate &&
            !LocalDb.getSessions().loginResponse.loginUser.corporate.isFirsLogin) 
           ?  <Redirect to={{pathname: '/client',  state: {from: props.location}}}/>
            // added end for corporate user

            : <Component {...props} />    
    )}/>
);
export default AuthRequiredRoute;