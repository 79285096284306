import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import { Avatar, Select, Tag, Tooltip } from "antd";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { LocalDb } from "../../api";
import FilterListIcon from "@material-ui/icons/FilterList";
import "./index.css";
import "../../styles/styles.css";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "../PaperComponent";
import RefreshIcon from "@material-ui/icons/Refresh";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogTitleComponent from "../DialogTitleComponent";
import Helpers, { arraysEqual } from "../../components/Helper";
import { HelmetComponent } from "../../components/Helmet";
import ButtonComponent from "../ButtonComponent";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  finalAmount,
  formatter,
  naturalRound,
} from "../../utils/currencyConvertor";
import { DocumentImage } from "../../components/DocumentImage";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ChangedLogPopover from "../../components/ChangedLogPopover";
import KeyBoardDatePickerComponent from "../DatePicker/DatePickerComponent";
import {
  getFormattedDateTimeValue,
  getFormattedDateValue,
} from "../../utils/DateConverter";
import SelectiveDateForFilter from "./SelectiveDateForFilter";
import SnackBarComponent from "../../components/SnackBarComponenet";
import ExportButton from "../../components/ExportButton";
import {
  FilterStatusData,
  FilterMediumData,
  FilterTypeData,
  FilterClientSchStatusData,
  FilterScheduleTimeData,
  FilterUPTypeData,
  allTypeArray,
} from "../../components/FilterOptionList";
import {
  transactionMediumHelper,
  transactionTypeHelper,
  ClientSheduleStatusHelper,
  scheduleTimeHelper,
  transactionStatusHelper,
  showGreaterThanSign,
  getRangeType,
} from "../TransactionHelper";
import CheckboxFilterPopover from "../../components/CheckboxFilterPopover";

const { Option } = Select;

class ScheduleTransactionScreen extends Component {
  componentDidMount() {
    this.props.fetchVendor();
    this.props.fetchDescriptionList();
    this.props.fetchScheduleTransactionList();
    this.props.clearSchTransFilterData();
  }

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openDeleteScheduleDialogue: false,
      openUpdateClientDialogue: false,
      openUpdateScheduleDialogue: false,
      openUpdateCancelDialogue: false,
      openUpdateCloseDialogue: false,
      openUpdateApproveDialogue: false,
      openUpdateDisableDialogue: false,
      openModalToScheduleTransaction: false,
      openSearchDialogue: false,
      page: 0,
      rowsPerPage: 10,
      openActionMenu: false,
      popoverElement: null,
      scheduleTrans: "",
      scheduleTransactionId: "",
      sortType: "asc",
      approve: false,
      showDropdown: false,
      errors: {
        amount: "",
        scheduledDate: "",
        endDate: "",
        note: "",
      },
      startDate: "",
      isReopen: false,
      isUpdate: false,
      isFirstOpen: false,
      openActivityLogModal: false,
      clearBankDetail: false,
      isFirstSelect: false,
      buttonDisable: false,
      start: "",
      isChecked: false,
      isALlChecked: true,
      isAllMediumChecked: true,
      isAllTypeChecked: true,
      isAllUPTypeCheked: true,
      isALlScheduleChecked: true,
      isALlScheduleTypeChecked: true,
      isStatusCheck: FilterStatusData.map((status) => status.name),
      isScheduleStatusCheck: FilterClientSchStatusData.map(
        (status) => status.name
      ),
      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      isTypeCheck: FilterTypeData.map((type) => type.name),
      // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      isUPTypeCheck: allTypeArray.map((type) => type.name),
      isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      order: "desc",
      orderBy: "scheduledDate",
      // config menu
      openConfigMenu: false,
      OpenViewFilterInConfig: false,
      OpenSaveFilterInConfigmenu: false,
      configName: "",
      openRangeBar: false,
    };
  }

  handleChange(e) {
    this.props.handleChangeScheduleTransactionInputData(
      "isLedger",
      e.target.checked
    );
  }

  handleAllScheduleCheckbox = () => {
    this.setState({ isALlScheduleChecked: !this.state.isALlScheduleChecked });
    this.setState({
      isScheduleStatusCheck: FilterClientSchStatusData.map((li) => li.name),
    });
    if (this.state.isALlScheduleChecked) {
      this.setState({ isScheduleStatusCheck: [] });
    }
  };

  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };

  handleAllUPTypeCheckbox = () => {
    this.setState({ isAllUPTypeCheked: !this.state.isAllUPTypeCheked });
    this.setState({ isUPTypeCheck: allTypeArray.map((li) => li.name) });
    if (this.state.isAllUPTypeCheked) {
      this.setState({ isUPTypeCheck: [] });
    }
  };

  handleAllScheduleTypeCheckbox = () => {
    this.setState({
      isALlScheduleTypeChecked: !this.state.isALlScheduleTypeChecked,
    });
    this.setState({
      isScheduleTypeCheck: FilterScheduleTimeData.map((li) => li.name),
    });
    if (this.state.isALlScheduleTypeChecked) {
      this.setState({ isScheduleTypeCheck: [] });
    }
  };

  handleSelectedScheduleChange = (e) => {
    const { value, checked } = e.target;
    this.setState({
      isScheduleStatusCheck: [...this.state.isScheduleStatusCheck, value],
    });
    if (!checked) {
      this.setState({
        isScheduleStatusCheck: this.state.isScheduleStatusCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedScheduleTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState({
      isScheduleTypeCheck: [...this.state.isScheduleTypeCheck, value],
    });
    if (!checked) {
      this.setState({
        isScheduleTypeCheck: this.state.isScheduleTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  handleSelectedUPTypeChange = (e) => {
    const { value, checked } = e.target;

    this.setState({ isUPTypeCheck: [...this.state.isUPTypeCheck, value] });
    if (!checked) {
      this.setState({
        isUPTypeCheck: this.state.isUPTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };
  render(state, callback) {
    const { errors } = this.state;
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;
    const userClientId = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.client
        ? userInfoFromDB.loginResponse.loginUser.client.clientId
        : null
      : null;
    const isClient =
      userType === "CLIENT_USER" &&
      userInfoFromDB &&
      userInfoFromDB.loginResponse.loginUser.client.isEnableTransaction;

    // SCHEDULE TRANS STATES
    const status = this.props.scheduleTransactionState.status;
    const id = this.props.scheduleTransactionState.scheduleTransId;
    const schedule = this.props.scheduleTransactionState.schedule;
    const scheduleTransactionState =
      this.props && this.props.scheduleTransactionState;
    const scheduleTransactionList =
      scheduleTransactionState && scheduleTransactionState.scheduleTransaction;
    const clientList =
      scheduleTransactionState && scheduleTransactionState.clientList;
    // const mediumType =
    //   scheduleTransactionState && scheduleTransactionState.mediumType;
    const startDate =
      scheduleTransactionState && scheduleTransactionState.startDate;
    const endDate =
      scheduleTransactionState && scheduleTransactionState.endDate;
    // const scheduleStatus =
    //   scheduleTransactionState && scheduleTransactionState.scheduleStatus;
    // const scheduleType =
    //   scheduleTransactionState && scheduleTransactionState.scheduleType;
    const term = scheduleTransactionState && scheduleTransactionState.term;
    const fileUrl =
      scheduleTransactionState && scheduleTransactionState.fileUrl;
    const clientRes =
      scheduleTransactionState && scheduleTransactionState.clientRes;

    // const clientId =
    //   scheduleTransactionState && scheduleTransactionState.clientId;
    const totalScheduleTransaction =
      scheduleTransactionState &&
      scheduleTransactionState.totalScheduleTransaction;
    const scheduleTransactionInputData =
      scheduleTransactionState &&
      scheduleTransactionState.scheduleTransactionInputData;
    const transMedium =
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionMedium;
    const transactionType =
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionType;
    // const transType =
    //     scheduleTransactionState &&
    //     scheduleTransactionState.transType;
    const vendorToFilter =
      scheduleTransactionState && scheduleTransactionState.vendorToFilter;

    // TRANSACTION STATE
    const transactionState = this.props && this.props.transactionState;
    const amountDetails = transactionState && transactionState.amountDetails;

    //  SETTING DESCRIPTION/VENDOR STATES
    const vendorList = this.props.vendorState.vendorAccount;
    const clientVendor =
      this.props.vendorState && this.props.vendorState.clientVendor;

    const vendorData =
      vendorList &&
      vendorList.filter(
        (vendor) => vendor.vendorType && vendor.vendorType.isDefault
      );
    const settingState = this.props && this.props.settingState;
    const descriptionList = settingState && settingState.descriptionList;

    const value =
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionType !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionMedium !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.amount !== 0 &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.description !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.clientId !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduleType !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduledDate !== null;
    const scheduleValueWithBank =
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionType !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionMedium !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.amount !== 0 &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.description !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.clientId !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduleType !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.bankId !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduledDate !== null;

    const clientValue =
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionType !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.transactionMedium !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.amount !== 0 &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.description !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduleType !== "" &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.scheduledDate !== null &&
      scheduleTransactionInputData &&
      scheduleTransactionInputData.endDate !== null;

    // new filter
    const filterStartDate =
      scheduleTransactionState && scheduleTransactionState.filterStartDate;
    const filterEndDate =
      scheduleTransactionState && scheduleTransactionState.filterEndDate;
    const filterAmount =
      scheduleTransactionState && scheduleTransactionState.filterAmount;
    const filterName =
      scheduleTransactionState && scheduleTransactionState.filterName;
    const filterVendor =
      scheduleTransactionState && scheduleTransactionState.filterVendor;
    const filterMedium =
      scheduleTransactionState && scheduleTransactionState.filterMedium;
    const filterType =
      scheduleTransactionState && scheduleTransactionState.filterType;
    const filterStatus =
      scheduleTransactionState && scheduleTransactionState.filterStatus;
    const filterScheduleDate =
      scheduleTransactionState && scheduleTransactionState.filterScheduleDate;
    const configList =
      scheduleTransactionState && scheduleTransactionState.configList;
    const compareType =
      scheduleTransactionState && scheduleTransactionState.compareType;

    //DIALOG TITLE CSS
    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography
            variant="h6"
            style={{
              color: "rgb(204, 102, 0)",
              fontWeight: "600",
              paddingLeft: "17px",
            }}
          >
            {children}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    const getDate = (dateToConvert) => {
      return new Date(parseInt(dateToConvert)).toLocaleDateString();
    };

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleCloseDialogueForUpdateClient = () => {
      this.setState({
        openUpdateClientDialogue: false,
      });
    };
    const handleOpenDialogueForUpdateClient = () => {
      this.setState({
        openUpdateClientDialogue: true,
      });
    };

    const handleCloseDialogueToScheduleTransaction = () => {
      this.setState({
        openModalToScheduleTransaction: false,
      });
      this.props.clearFileData();
    };
    const handleOpenDialogueToScheduleTransaction = () => {
      this.setState({
        openModalToScheduleTransaction: true,
        errors: {
          amount: "",
          scheduledDate: "",
          endDate: "",
          note: "",
        },
        startDate: "",
      });
    };

    const handleCloseDialogueForScheduleTransaction = () => {
      this.setState({
        openDeleteScheduleDialogue: false,
        openUpdateScheduleDialogue: false,
      });
    };
    // const handleOpenDialogueForScheduleTransaction = (
    //   scheduleTransactionId
    // ) => {
    //   this.setState({
    //     openDeleteScheduleDialogue: true,
    //     scheduleTransactionId: scheduleTransactionId,
    //   });
    // };

    const handleOkDeleteScheduleTransactionDialogue = () => {
      this.props.deleteScheduleTransaction(
        this.state.scheduleTrans.scheduleTransactionId,
        this.props
      );
      this.setState({
        openDeleteScheduleDialogue: false,
        scheduleTransactionId: "",
      });
    };

    const handleOpenDialogueForScheduleTransactionUpdate = () => {
      this.setState({
        openUpdateScheduleDialogue: true,
      });
    };
    const handleCloseDialogueForScheduleTransactionUpdate = () => {
      this.setState({
        openUpdateScheduleDialogue: false,
      });
    };
    //UPDATE AS CANCEL
    const handleCloseUpdateAsCancel = () => {
      this.setState({
        openUpdateCancelDialogue: false,
      });
    };
    const handleOpenUpdateAsCancel = () => {
      this.setState({
        openUpdateCancelDialogue: true,
      });
    };

    //UPDATE AS CLOSE
    const handleOpenUpdateAsClose = () => {
      this.setState({
        openUpdateCloseDialogue: true,
      });
    };
    const handleCloseUpdateAsClose = () => {
      this.setState({
        openUpdateCloseDialogue: false,
      });
    };

    //UPDATE AS DISABLE
    // const handleOpenUpdateAsDisable = () => {
    //   this.setState({
    //     openUpdateDisableDialogue: true,
    //   });
    // };
    const handleCloseUpdateAsDisable = () => {
      this.setState({
        openUpdateDisableDialogue: false,
      });
    };

    // UPDATE AS APPROVE
    // const handleOpenUpdateAsApprove = () => {
    //   this.setState({
    //     openUpdateApproveDialogue: true,
    //   });
    // };
    const handleCloseUpdateAsApprove = () => {
      this.setState({
        openUpdateApproveDialogue: false,
      });
    };
    const handleOkScheduleStatusDialogue = (statusData) => {
      this.props.updateScheduleTransactionStatus(statusData, this.props);
      this.setState({
        openUpdateScheduleDialogue: false,
        openUpdateCloseDialogue: false,
      });
    };

    const handleInitialCheckboxValue = () => {
      this.setState({
        isChecked: false,
        isALlChecked: true,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isAllUPTypeCheked: true,
        isALlScheduleChecked: true,
        isALlScheduleTypeChecked: true,
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isScheduleStatusCheck: FilterClientSchStatusData.map(
          (status) => status.name
        ),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
        isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      });
    };
    const handleChangeOpenSearchDialogue = () => {
      handleInitialCheckboxValue();
      this.setState({
        openSearchDialogue: true,
      });
      this.props.clearScheduleTransactionFilterForm();
      this.props.clearSchTransFilterData();
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
      });
    };
    const onChangeDocumentFileHandler = (eventFileHandler) => {
      this.setState({
        buttonDisable: true,
      });
      let file = eventFileHandler.target.files[0];
      if (file && file.type !== undefined && file.name !== undefined) {
        let type = file.type;
        let name = file.name;
        let singleFile = {
          document: file,
          type: type,
          name: name,
        };
        {
          this.setState(
            {
              isBlocking: true,
            },
            () => {
              this.props.uploadDocumentFile(singleFile, this.props);
            }
          );
        }
      } else {
        this.setState({
          openSnackBar: true,
        });
      }
    };

    const scheduleReqBody = {
      client: {
        clientId:
          scheduleTransactionInputData && scheduleTransactionInputData.clientId,
      },
      vendorAccount: {
        vendorId:
          scheduleTransactionInputData && scheduleTransactionInputData.vendorId,
      },
      amount:
        scheduleTransactionInputData && scheduleTransactionInputData.amount,
      note: scheduleTransactionInputData && scheduleTransactionInputData.note,
      description:
        scheduleTransactionInputData &&
        scheduleTransactionInputData.description,
      scheduleDetail: {
        scheduledDate:
          scheduleTransactionInputData &&
          scheduleTransactionInputData.scheduledDate,

        endDate:
          scheduleTransactionInputData &&
          scheduleTransactionInputData.endDate === ""
            ? "0"
            : scheduleTransactionInputData.endDate,
        scheduleType:
          scheduleTransactionInputData &&
          scheduleTransactionInputData.scheduleType,
      },
      transactionType:
        scheduleTransactionInputData &&
        scheduleTransactionInputData.transactionType,
      transactionMedium:
        scheduleTransactionInputData &&
        scheduleTransactionInputData.transactionMedium,
      isLedger:
        scheduleTransactionInputData && scheduleTransactionInputData.isLedger,
    };
    if (transactionType === "DEPOSIT" && transMedium === "CHECK") {
      scheduleReqBody.bankDetailId =
        scheduleTransactionInputData && scheduleTransactionInputData.bankId;
      scheduleReqBody.document = fileUrl;
    } else if (transactionType === "DEPOSIT" && transMedium !== "CHECK") {
      scheduleReqBody.bankDetailId =
        scheduleTransactionInputData && scheduleTransactionInputData.bankId;
    } else if (transactionType !== "DEPOSIT" && transMedium === "CHECK") {
      scheduleReqBody.document = fileUrl;
    }
    if (this.state.isUpdate) {
      scheduleReqBody.scheduleTransactionId =
        scheduleTransactionInputData &&
        scheduleTransactionInputData.scheduleTransactionId;
    }

    // activity states
    const activityState = this.props && this.props.activityLogState;
    const activityLogValue = activityState && activityState.activityLogValue;

    const handleOpenActivityLogModal = () => {
      this.setState({
        openActivityLogModal: true,
      });
    };
    const handleCloseActivityLogModal = () => {
      this.setState({
        openActivityLogModal: false,
      });
    };

    // sorting
    function descendingComparator(a, b, orderBy) {
      if (orderBy === "scheduledDate" || orderBy === "endDate") {
        let first =
          a.scheduleDetail && a.scheduleDetail[orderBy]
            ? a.scheduleDetail && a.scheduleDetail[orderBy]
            : "0";
        let second =
          b.scheduleDetail && b.scheduleDetail[orderBy]
            ? b.scheduleDetail && b.scheduleDetail[orderBy]
            : "0";

        return sortFunction(first, second);
      } else if (orderBy === "amount") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "firstName") {
        let first = a.client && a.client[orderBy];

        let second = b.client && b.client[orderBy];
        return sortFunction(first, second);
      } else if (orderBy === "vendorName") {
        let first = a.vendorAccount && a.vendorAccount[orderBy];
        let second = b.vendorAccount && b.vendorAccount[orderBy];
        return sortFunction(first, second);
      } else {
        return sortFunction(a[orderBy], b[orderBy]);
      }
    }
    function sortFunction(first, second) {
      if (second < first) {
        return -1;
      }
      if (second > first) {
        return 1;
      }
      return null;
    }

    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }
    const sortedScheduleTransactionList = getComparator(
      scheduleTransactionList,
      this.state.order,
      this.state.orderBy
    );
    // const sortedScheduleTransactionList = scheduleTransactionList
    // ? scheduleTransactionList.sort((a, b) => {
    //     // if (a.createdAt && b.createdAt) {
    //     const data = this.state.sortType === "asc" ? -1 : 1;
    //     return data * a.createdAt.localeCompare(b.createdAt);
    //     // }
    //     // return scheduleTransactionList;
    //   })
    // : null;

    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
      });
    };

    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        startDate: filterStartDate === null ? "" : filterStartDate,
        endDate: filterEndDate === null ? "" : filterEndDate,
        clientName: filterName,
        vendorName: filterVendor,
        amount: filterAmount,
        transactionMedium: filterMedium,
        transactionType: filterType,
        transactionStatus: filterStatus,
        compareType: compareType,
        scheduleDate: filterScheduleDate === null ? "" : filterScheduleDate,
        type: 3,

        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };

      this.props.fetchScheduleTransactionList();
      this.props.clearSchTransFilterData();
      this.props.saveInConfig(data);
    };

    const isFilterEmpty =
      filterStartDate !== null ||
      filterEndDate !== null ||
      filterName !== "" ||
      filterVendor !== "" ||
      filterAmount !== "" ||
      filterMedium !== "" ||
      filterType !== "" ||
      filterStatus !== "" ||
      filterScheduleDate !== "";

    const configStatus = this.state.configName !== "" && isFilterEmpty;

    const handleUseConfigFilter = (config) => {
      this.props.clearSchTransFilterData();
      if (
        config.scheduleFilter.startDate &&
        config.scheduleFilter.startDate !== "0"
      ) {
        this.props.handleScheduleTransFilter(
          "filterStartDate",
          config.scheduleFilter.startDate
        );
      }
      if (
        config.scheduleFilter.endDate &&
        config.scheduleFilter.endDate !== "0"
      ) {
        this.props.handleScheduleTransFilter(
          "filterEndDate",
          config.scheduleFilter.endDate
        );
      }
      if (config.scheduleFilter.clientName) {
        this.props.handleScheduleTransFilter(
          "filterName",
          config.scheduleFilter.clientName
        );
      }
      if (config.scheduleFilter.vendorName) {
        this.props.handleScheduleTransFilter(
          "filterVendor",
          config.scheduleFilter.vendorName
        );
      }
      if (config.scheduleFilter.transactionMedium) {
        this.props.handleScheduleTransFilter(
          "filterMedium",
          config.scheduleFilter.transactionMedium
        );
      }
      if (config.scheduleFilter.transactionType) {
        this.props.handleScheduleTransFilter(
          "filterType",
          config.scheduleFilter.transactionType
        );
      }
      if (config.scheduleFilter.transactionStatus) {
        this.props.handleScheduleTransFilter(
          "filterStatus",
          config.scheduleFilter.transactionStatus
        );
      }
      if (
        config.scheduleFilter.scheduleDate &&
        config.scheduleFilter.scheduleDate !== "0"
      ) {
        this.props.handleScheduleTransFilter(
          "filterScheduleDate",
          config.scheduleFilter.scheduleDate
        );
      }
      if (config.scheduleFilter.amount) {
        this.props.handleScheduleTransFilter(
          "filterAmount",
          config.scheduleFilter.amount
        );
      }
      if (config.scheduleFilter.comparator) {
        this.props.handleScheduleTransFilter(
          "compareType",
          getRangeType(config.scheduleFilter.comparator)
        );
      }

      this.props.filterScheduleTransactionByAll(
        config.scheduleFilter.startDate ? config.scheduleFilter.startDate : "0",
        config.scheduleFilter.endDate ? config.scheduleFilter.endDate : "0",
        config.scheduleFilter.clientName
          ? config.scheduleFilter.clientName
          : "",
        config.scheduleFilter.vendorName
          ? config.scheduleFilter.vendorName
          : "",
        config.scheduleFilter.transactionMedium
          ? config.scheduleFilter.transactionMedium
          : "",
        config.scheduleFilter.transactionType
          ? config.scheduleFilter.transactionType
          : "",
        config.scheduleFilter.transactionStatus
          ? ClientSheduleStatusHelper([config.scheduleFilter.transactionStatus])
          : "",
        "",
        config.scheduleFilter.scheduleDate
          ? config.scheduleFilter.scheduleDate
          : "",
        config.scheduleFilter.amount ? config.scheduleFilter.amount : "",
        config.scheduleFilter.comparator
          ? getRangeType(config.scheduleFilter.comparator)
          : "0"
      );
    };

    // function to set the initaial value of the main filters
    const setInitialValueofMainFilters = () => {
      this.setState({
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isScheduleStatusCheck: FilterClientSchStatusData.map(
          (status) => status.name
        ),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        // isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isUPTypeCheck: allTypeArray.map((type) => type.name),
        isScheduleTypeCheck: FilterScheduleTimeData.map((type) => type.name),
      });
    };
    return (
      <>
        <HelmetComponent
          title="Schedule Transaction"
          content="This is Schedule Transaction"
        />
        {this.props.scheduleTransactionState.loading ? (
          <div
            className={
              this.props.scheduleTransactionState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {this.props.scheduleTransactionState.addScheduleTransactionSuccess ===
        true
          ? this.setState(
              {
                openModalToScheduleTransaction: false,
              },
              () => {
                this.props.setAddNewScheduleTransactionSuccessToFalse();
              }
            )
          : null}
        <div>
          <div
            style={
              userType === "CLIENT_USER"
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "70px",
                  }
                : { display: "block" }
            }
          >
            <div
              style={
                userType === "CLIENT_USER"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div style={{ marginTop: "30px" }}>
                <span style={{ marginRight: "10px", fontSize: "16px" }}>
                  Total Schedule Transaction :
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {totalScheduleTransaction ? totalScheduleTransaction : "0"}
                </span>
              </div>
            </div>
            <div className={"d-flex flex-row-reverse"} style={style.flex}>
              <div>
                <button
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchScheduleTransactionList();
                    this.props.clearSchTransFilterData();
                    this.props.clearScheduleTransactionFilterForm();
                  }}
                >
                  <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
                </button>
              </div>
              {/****** Filter Button ********/}
              <div>
                <button
                  className="filterButton"
                  onClick={handleChangeOpenSearchDialogue}
                >
                  <FilterListIcon
                    style={{ color: "#fff", marginTop: "-2px" }}
                  />
                </button>
              </div>
              {/****** Export Button ********/}
              <div>
                <>
                  {isFilterEmpty ? (
                    <ExportButton
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportScheduleTransactionByColumnFilter(
                          filterStartDate,
                          filterEndDate,
                          filterName,
                          filterVendor,
                          filterMedium,
                          filterType,
                          ClientSheduleStatusHelper([filterStatus]),
                          "",
                          filterScheduleDate,
                          filterAmount,
                          compareType,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportScheduleTransactionByColumnFilter(
                          filterStartDate,
                          filterEndDate,
                          filterName,
                          filterVendor,
                          filterMedium,
                          filterType,
                          ClientSheduleStatusHelper([filterStatus]),
                          "",
                          filterScheduleDate,
                          filterAmount,
                          compareType,
                          "CSV_EXPORT"
                        );
                      }}
                    />
                  ) : (
                    <ExportButton
                      onClickPDF={(e) => {
                        e.preventDefault();
                        this.props.exportScheduleTransactionByAll(
                          term,

                          transactionMediumHelper(this.state.isMediumCheck),
                          ClientSheduleStatusHelper(
                            this.state.isScheduleStatusCheck
                          ),

                          scheduleTimeHelper(this.state.isScheduleTypeCheck),
                          startDate,
                          endDate,

                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "transactionType"
                          ),

                          vendorToFilter,
                          "PDF_EXPORT"
                        );
                      }}
                      onClickCSV={(e) => {
                        e.preventDefault();
                        this.props.exportScheduleTransactionByAll(
                          term,
                          transactionMediumHelper(this.state.isMediumCheck),
                          ClientSheduleStatusHelper(
                            this.state.isScheduleStatusCheck
                          ),

                          scheduleTimeHelper(this.state.isScheduleTypeCheck),
                          startDate,
                          endDate,

                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "transactionType"
                          ),

                          vendorToFilter,
                          "CSV_EXPORT"
                        );
                      }}
                    />
                  )}
                </>
              </div>
              {userType === "CLIENT_USER" ? (
                userInfoFromDB &&
                userInfoFromDB.loginResponse.loginUser.client
                  .isEnableTransaction ? (
                  <button
                    className="addButton"
                    style={{ width: "215px", marginRight: "12px" }}
                    onClick={(e) => {
                      this.setState({
                        isFirstOpen: true,
                      });
                      e.preventDefault();
                      this.props.clearScheduleTransactionInputData();
                      this.props.clearFileData();
                      handleOpenDialogueToScheduleTransaction();
                    }}
                  >
                    Request Transaction
                  </button>
                ) : null
              ) : (
                <div>
                  <button
                    className="addButton"
                    style={{ width: "215px", marginRight: "12px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        isUpdate: false,
                        isFirstOpen: true,
                      });
                      this.props.clearScheduleTransactionInputData();
                      this.props.clearFileData();
                      this.props.clearAmountDetail();
                      // this.props.fetchSingleClient('', this.props);
                      this.props.clearClientSingle();
                      this.props.handleChangeScheduleTransactionInputData(
                        "bankId",
                        ""
                      );
                      const today = new Date();
                      let tomorrow = new Date();
                      tomorrow.setDate(today.getDate() + 1);
                      // this.props.handleChangeScheduleTransactionInputData('scheduledDate',
                      //     new Date(tomorrow).setHours(0, 0, 0));
                      // this.props.handleChangeScheduleTransactionInputData('endDate',
                      //     new Date(tomorrow).setHours(23, 29, 29));
                      handleOpenDialogueToScheduleTransaction();
                    }}
                  >
                    <AddIcon /> Schedule Transaction
                  </button>
                </div>
                // this.props.getAmountDetailByClientId("");
              )}
              <div className="search">
                <div style={style.searchIcon}>
                  <SearchIcon style={{ color: "#9c9c9c" }} />
                </div>
                <InputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  style={style.inputBase}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      page: 0,
                    });
                    this.props.handleChangeScheduleTerm(e.target.value);
                    if (e.target.value === "" || e.target.value === " ") {
                      this.props.fetchScheduleTransactionList();
                    } else {
                      this.props.searchScheduleTransaction(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header-second">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="tableHeader">#</TableCell>
                    <TableCell
                      className="first-tableHeader"
                      key="scheduledDate"
                      sortDirection={
                        this.state.orderBy === "scheduledDate"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "scheduledDate"}
                        direction={
                          this.state.orderBy === "scheduledDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("scheduledDate")}
                      >
                        Start Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="endDate"
                      sortDirection={
                        this.state.orderBy === "endDate"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "endDate"}
                        direction={
                          this.state.orderBy === "endDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("endDate")}
                      >
                        End Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="amount"
                      sortDirection={
                        this.state.orderBy === "amount"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "amount"}
                        direction={
                          this.state.orderBy === "amount"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("amount")}
                      >
                        Amount
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="firstName"
                      sortDirection={
                        this.state.orderBy === "firstName"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "firstName"}
                        direction={
                          this.state.orderBy === "firstName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("firstName")}
                      >
                        Client Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="vendorName"
                      sortDirection={
                        this.state.orderBy === "vendorName"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "vendorName"}
                        direction={
                          this.state.orderBy === "vendorName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("vendorName")}
                      >
                        Vendor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionMedium"
                      sortDirection={
                        this.state.orderBy === "transactionMedium"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "transactionMedium"}
                        direction={
                          this.state.orderBy === "transactionMedium"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionMedium")}
                      >
                        Medium
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionType"
                      sortDirection={
                        this.state.orderBy === "transactionType"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "transactionType"}
                        direction={
                          this.state.orderBy === "transactionType"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionType")}
                      >
                        Transaction Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="scheduleTransactionStatus"
                      sortDirection={
                        this.state.orderBy === "scheduleTransactionStatus"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={
                          this.state.orderBy === "scheduleTransactionStatus"
                        }
                        direction={
                          this.state.orderBy === "scheduleTransactionStatus"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler(
                          "scheduleTransactionStatus"
                        )}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="createdAt"
                      sortDirection={
                        this.state.orderBy === "createdAt"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "createdAt"}
                        direction={
                          this.state.orderBy === "createdAt"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("createdAt")}
                      >
                        Scheduled Date/By/Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="sticky-search-bar">
                    <TableCell />
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={
                            filterStartDate === null ? "" : filterStartDate
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterStartDate",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterScheduleTransactionByAll(
                              e.target.value,
                              filterEndDate,
                              filterName,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterEndDate === null ? "" : filterEndDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterEndDate",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              e.target.value,
                              filterName,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div
                        className="sm-search"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span className="show-range-sign">
                          {showGreaterThanSign(compareType)}
                        </span>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterAmount}
                          className="show-range"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterAmount",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              filterName,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              e.target.value,
                              compareType
                            );
                          }}
                        />
                        <ExpandMoreIcon
                          id="Popover1"
                          style={{ cursor: "pointer" }}
                          className="expand-more-icon"
                          onClick={(e) => {
                            this.setState({
                              popoverElement: e.currentTarget,
                              // client: client.client,
                              openRangeBar: true,
                            });
                          }}
                        />
                        <Popover
                          open={this.state.openRangeBar}
                          anchorEl={this.state.popoverElement}
                          onClose={(e) => {
                            e.preventDefault();
                            this.setState({ openRangeBar: false });
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          style={{ boxShadow: "none" }}
                        >
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                });
                                this.props.handleScheduleTransFilter(
                                  "compareType",
                                  "2"
                                );
                                this.props.clearScheduleTransactionFilterForm();
                                setInitialValueofMainFilters();
                                this.setState({
                                  page: 0,
                                });
                                this.props.filterScheduleTransactionByAll(
                                  filterStartDate,
                                  filterEndDate,
                                  filterName,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  ClientSheduleStatusHelper([filterStatus]),
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  "2"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Lesser than {`(<)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();

                                this.props.getConfigList("1");
                                this.setState({
                                  openRangeBar: false,
                                });
                                this.props.clearScheduleTransactionFilterForm();
                                setInitialValueofMainFilters();
                                this.props.handleScheduleTransFilter(
                                  "compareType",
                                  "1"
                                );
                                this.setState({
                                  page: 0,
                                });
                                this.props.filterScheduleTransactionByAll(
                                  filterStartDate,
                                  filterEndDate,
                                  filterName,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  ClientSheduleStatusHelper([filterStatus]),
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  "1"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Greater than {`(>)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                });
                                this.props.handleScheduleTransFilter(
                                  "compareType",
                                  "0"
                                );
                                this.setState({
                                  page: 0,
                                });
                                this.props.clearScheduleTransactionFilterForm();
                                setInitialValueofMainFilters();
                                this.props.filterScheduleTransactionByAll(
                                  filterStartDate,
                                  filterEndDate,
                                  filterName,
                                  filterVendor,
                                  filterMedium,
                                  filterType,
                                  ClientSheduleStatusHelper([filterStatus]),
                                  "",
                                  filterScheduleDate,
                                  filterAmount,
                                  "0"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>None</span>
                            </span>
                          </MenuList>
                        </Popover>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterName}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterName",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              e.target.value,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterVendor}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterVendor",
                              e.target.value
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            this.setState({
                              page: 0,
                            });
                            setInitialValueofMainFilters();
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              filterName,
                              e.target.value,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={filterMedium}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleScheduleTransFilter(
                              "filterMedium",
                              e
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              filterName,
                              filterVendor,
                              e,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        >
                          <Option value="CHECK">Check</Option>
                          <Option value="CASH">Cash</Option>
                          <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                          <Option value="ACH">ACH</Option>
                          <Option value="CARD">Card</Option>
                          <Option value="LEDGER">Ledger</Option>
                          <Option value="OTHER_MEDIUM">Other medium</Option>
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={filterType}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleScheduleTransFilter(
                              "filterType",
                              e
                            );
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.setState({
                              page: 0,
                            });
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              filterName,
                              filterVendor,
                              filterMedium,
                              e,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        >
                          {allTypeArray &&
                            allTypeArray.map((list) => (
                              <Option value={list.val}>{list.name}</Option>
                            ))}
                          {/* <Option value="DISBURSEMENT">Disbursement</Option>
                          <Option value="DEPOSIT">Deposit</Option>
                          <Option value="FEE">Fee</Option> */}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={filterStatus}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleScheduleTransFilter(
                              "filterStatus",
                              e
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              filterName,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([e]),
                              "",
                              filterScheduleDate,
                              filterAmount,
                              compareType
                            );
                          }}
                        >
                          {FilterClientSchStatusData &&
                            FilterClientSchStatusData.map((list) => (
                              <Option value={list.name}>{list.name}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableHeader">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={filterScheduleDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleScheduleTransFilter(
                              "filterScheduleDate",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.clearScheduleTransactionFilterForm();
                            setInitialValueofMainFilters();
                            this.props.filterScheduleTransactionByAll(
                              filterStartDate,
                              filterEndDate,
                              filterName,
                              filterVendor,
                              filterMedium,
                              filterType,
                              ClientSheduleStatusHelper([filterStatus]),
                              "",
                              e.target.value,
                              filterAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableCol">
                      <MoreHorizIcon
                        id="Popover1"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          this.setState({
                            popoverElement: e.currentTarget,

                            openConfigMenu: true,
                          });
                        }}
                      />

                      <Popover
                        open={this.state.openConfigMenu}
                        anchorEl={this.state.popoverElement}
                        onClose={(e) => {
                          e.preventDefault();
                          this.setState({ openConfigMenu: false });
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        style={{ boxShadow: "none" }}
                      >
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                OpenSaveFilterInConfigmenu: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              Save In Config
                            </span>
                          </span>
                        </MenuList>
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.getConfigList("3");
                              this.setState({
                                OpenViewFilterInConfig: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              View Config
                            </span>
                          </span>
                        </MenuList>
                      </Popover>
                    </TableCell>

                    <TableCell />
                  </TableRow>
                </TableBody>
                {sortedScheduleTransactionList &&
                  sortedScheduleTransactionList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((scheduleTransaction, index) => (
                      <TableBody
                        key={scheduleTransaction.scheduleTransactionId}
                      >
                        <TableRow>
                          <TableCell>
                            {" "}
                            {(this.state.page + 1 - 1) *
                              this.state.rowsPerPage +
                              (index + 1)}
                          </TableCell>
                          <TableCell
                            className="first-tableBody"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  scheduleTrans: scheduleTransaction,
                                },
                                () => {
                                  this.props.getScheduleTransactionInfo(
                                    this.state.scheduleTrans
                                      .scheduleTransactionId
                                  );
                                }
                              );
                              handleOpenDialogueForUpdateClient();
                            }}
                          >
                            {scheduleTransaction &&
                              new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(
                                scheduleTransaction.scheduleDetail.scheduledDate
                                  ? scheduleTransaction.scheduleDetail
                                      .scheduledDate
                                  : "-"
                              )}
                          </TableCell>

                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              height: "4.5rem",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  scheduleTrans: scheduleTransaction,
                                },
                                () => {
                                  this.props.getScheduleTransactionInfo(
                                    this.state.scheduleTrans
                                      .scheduleTransactionId
                                  );
                                }
                              );
                              handleOpenDialogueForUpdateClient();
                            }}
                          >
                            {scheduleTransaction &&
                            scheduleTransaction.scheduleDetail &&
                            scheduleTransaction.scheduleDetail.endDate
                              ? new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(
                                  scheduleTransaction.scheduleDetail.endDate
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              // textAlign: "right",
                              height: "4.5rem",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  scheduleTrans: scheduleTransaction,
                                },
                                () => {
                                  this.props.getScheduleTransactionInfo(
                                    this.state.scheduleTrans
                                      .scheduleTransactionId
                                  );
                                }
                              );
                              handleOpenDialogueForUpdateClient();
                            }}
                          >
                            {scheduleTransaction.amount
                              ? formatter.format(
                                  scheduleTransaction.amount / 100
                                )
                              : formatter.format(0)}
                          </TableCell>
                          {userType === "CLIENT_USER" ? (
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                paddingLeft: "18px",
                                height: "4.5rem",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    scheduleTrans: scheduleTransaction,
                                  },
                                  () => {
                                    this.props.getScheduleTransactionInfo(
                                      this.state.scheduleTrans
                                        .scheduleTransactionId
                                    );
                                  }
                                );
                                handleOpenDialogueForUpdateClient();
                              }}
                            >
                              {scheduleTransaction.client.firstName +
                                " " +
                                scheduleTransaction.client.lastName}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                paddingLeft: "18px",
                                height: "4.5rem",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  scheduleTrans: scheduleTransaction,
                                });
                              }}
                            >
                              <span
                                onClick={() => {
                                  this.setState(
                                    {
                                      scheduleTrans: scheduleTransaction,
                                    },
                                    () => {
                                      this.props.history.push({
                                        pathname:
                                          "/schedule/client/" +
                                          this.state.scheduleTrans.client
                                            .clientId,
                                      });
                                    }
                                  );
                                }}
                              >
                                {scheduleTransaction.client.firstName +
                                  " " +
                                  scheduleTransaction.client.lastName}
                              </span>
                            </TableCell>
                          )}
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                              cursor: "pointer",
                            }}
                          >
                            {scheduleTransaction &&
                              scheduleTransaction.vendorAccount &&
                              scheduleTransaction.vendorAccount.vendorName}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  scheduleTrans: scheduleTransaction,
                                },
                                () => {
                                  this.props.getScheduleTransactionInfo(
                                    this.state.scheduleTrans
                                      .scheduleTransactionId
                                  );
                                }
                              );
                              handleOpenDialogueForUpdateClient();
                            }}
                          >
                            {scheduleTransaction.transactionMedium.replace(
                              "_",
                              " "
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState(
                                {
                                  scheduleTrans: scheduleTransaction,
                                },
                                () => {
                                  this.props.getScheduleTransactionInfo(
                                    this.state.scheduleTrans
                                      .scheduleTransactionId
                                  );
                                }
                              );
                              handleOpenDialogueForUpdateClient();
                            }}
                          >
                            {scheduleTransaction.transactionType}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            <Tag
                              style={style.colors}
                              color={
                                scheduleTransaction.scheduleTransactionStatus ===
                                "SCHEDULE_PENDING"
                                  ? "purple"
                                  : scheduleTransaction.scheduleTransactionStatus ===
                                    "SCHEDULED"
                                  ? "blue"
                                  : scheduleTransaction.scheduleTransactionStatus ===
                                    "APPROVED"
                                  ? "green"
                                  : scheduleTransaction.scheduleTransactionStatus ===
                                    "CLOSED"
                                  ? "orange"
                                  : scheduleTransaction.scheduleTransactionStatus ===
                                    "DISABLED"
                                  ? "volcano"
                                  : scheduleTransaction.scheduleTransactionStatus ===
                                    "CANCELLED"
                                  ? "red"
                                  : "cyan"
                              }
                            >
                              {scheduleTransaction.scheduleTransactionStatus ===
                              "SCHEDULE_PENDING"
                                ? scheduleTransaction.scheduleTransactionStatus.replace(
                                    "SCHEDULE_",
                                    " "
                                  )
                                : scheduleTransaction.scheduleTransactionStatus.replace(
                                    "_",
                                    " "
                                  )}
                            </Tag>
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            <div>
                              {scheduleTransaction &&
                                scheduleTransaction.createdAt &&
                                new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(
                                  scheduleTransaction.createdAt
                                    ? scheduleTransaction.createdAt
                                    : "-"
                                )}
                            </div>
                            <div style={{ marginTop: "5px" }}>
                              {scheduleTransaction.scheduleDetail
                                .scheduleByName ? (
                                <>
                                  <Avatar
                                    style={{
                                      width: "28px",
                                      height: "28px",
                                      marginRight: "8px",
                                    }}
                                    src={
                                      scheduleTransaction.scheduleDetail
                                        .profileUrl
                                        ? scheduleTransaction.scheduleDetail
                                            .profileUrl
                                        : "/assets/images/profile.svg"
                                    }
                                  />
                                  <span>
                                    {
                                      scheduleTransaction.scheduleDetail
                                        .scheduleByName
                                    }
                                  </span>
                                  <span> / </span>
                                  <span>
                                    {scheduleTransaction.scheduleDetail
                                      .scheduleType &&
                                      scheduleTransaction.scheduleDetail.scheduleType.replace(
                                        "_",
                                        " "
                                      )}
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>

                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                            }}
                          >
                            {" "}
                            {userType === "CLIENT_USER" ? (
                              <MoreHorizIcon style={{ display: "none" }} />
                            ) : (
                              <MoreHorizIcon
                                id="Popover1"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    popoverElement: e.currentTarget,
                                    openActionMenu: true,
                                    scheduleTrans: scheduleTransaction,
                                  });
                                  this.props.handleChangeStatus(
                                    scheduleTransaction.scheduleTransactionStatus,
                                    scheduleTransaction.scheduleTransactionId
                                  );
                                }}
                              />
                            )}
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                {status === "APPROVED" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                        handleOpenDialogueForScheduleTransactionUpdate();
                                      }}
                                    >
                                      <ScheduleIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Schedule
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsClose();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                    >
                                      <CloseIcon style={{ paddingBottom: 0 }} />
                                      Close
                                    </MenuItem>
                                  </div>
                                ) : status === "SCHEDULED" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsClose();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                    >
                                      <CloseIcon style={{ paddingBottom: 0 }} />{" "}
                                      Close
                                    </MenuItem>
                                  </div>
                                ) : status === "CLOSED" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          openActionMenu: false,
                                          isReopen: true,
                                        });
                                        handleOpenDialogueForScheduleTransactionUpdate();
                                      }}
                                    >
                                      <ScheduleIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Reopen
                                    </MenuItem>
                                  </div>
                                ) : status === "SCHEDULE_PENDING" ? (
                                  <div>
                                    <MenuItem
                                      onClick={() => {
                                        this.setState({
                                          openActionMenu: false,
                                          isReopen: false,
                                        });
                                        handleOpenDialogueForScheduleTransactionUpdate();
                                      }}
                                    >
                                      <ScheduleIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Schedule
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        handleOpenUpdateAsCancel();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                    >
                                      <CancelOutlinedIcon
                                        style={{ paddingBottom: 0 }}
                                      />
                                      Cancel
                                    </MenuItem>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <>
                                  <MenuItem
                                    hidden={status === "CLOSED"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.props.setScheduleTransactionInputDataToEdit(
                                        this.state.scheduleTrans
                                      );
                                      this.props.fetchSingleClient(
                                        this.state.scheduleTrans.client
                                          .clientId,
                                        this.props
                                      );
                                      this.props.getAmountDetailByClientId(
                                        this.state.scheduleTrans.client.clientId
                                      );
                                      handleOpenDialogueToScheduleTransaction();
                                      this.setState({
                                        openActionMenu: false,
                                        isUpdate: true,
                                        buttonDisable: false,
                                      });
                                    }}
                                  >
                                    <EditOutlinedIcon
                                      style={{ paddingBottom: 0 }}
                                    />{" "}
                                    Edit
                                  </MenuItem>
                                  {/* <MenuItem
                                          onClick={e => {
                                              e.preventDefault();
                                              handleOpenDialogueForScheduleTransaction();
                                              this.setState({openActionMenu: false,}
                                              );
                                          }}>
                                          <DeleteOutlinedIcon style={{paddingBottom: 0}}/> Delete
                                      </MenuItem> */}
                                </>
                              </MenuList>
                            </Popover>
                          </TableCell>
                          <TableCell
                            style={{
                              paddingLeft: "0px",
                              fontSize: "15px",
                              color: "rgb(98, 98, 98)",
                              textAlign: "left",
                              height: "4.5rem",
                              paddingRight: "0px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.fetchActivityLogByRefId(
                                scheduleTransaction.scheduleTransactionId
                              );
                              handleOpenActivityLogModal();
                            }}
                          >
                            <Tooltip title="View Activity Log">
                              <img
                                width={22}
                                src={"/assets/images/activityLog1.svg"}
                                alt=""
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {sortedScheduleTransactionList &&
            sortedScheduleTransactionList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedScheduleTransactionList.length > 0
                      ? sortedScheduleTransactionList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>

        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openActivityLogModal}
          onClose={handleCloseActivityLogModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseActivityLogModal}
          >
            Changelog Info
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{ width: "1000px", maxHeight: "1000px", paddingTop: "25px" }}
          >
            {activityLogValue && activityLogValue.length > 0 ? (
              activityLogValue.map((logValue) => (
                <div className="changeLogOuterDiv">
                  <div>
                    <Avatar
                      className="changeLogAvatar"
                      src={
                        logValue.profilePic
                          ? logValue.profilePic
                          : "/assets/images/profile.svg"
                      }
                    />
                  </div>
                  <div style={{ marginLeft: "25px", fontSize: "16px" }}>
                    <span style={{ fontWeight: 600 }}>
                      {logValue.userName ? logValue.userName : "User"}
                    </span>{" "}
                    changed{" "}
                    <span style={{ fontWeight: 600 }}>
                      {logValue.activity ===
                      "schedule.transaction.status.updated"
                        ? "schedule transaction status to"
                        : "schedule transactions."}
                    </span>{" "}
                    {logValue.activity ===
                    "schedule.transaction.status.updated" ? (
                      <span>"{logValue.refValue}"</span>
                    ) : (
                      <ChangedLogPopover
                        refValue={logValue.refValue}
                        formatter={formatter}
                      />
                    )}
                    <div style={{ fontSize: "14px" }}>
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(logValue.createdAt)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>
                  <img src="/assets/images/activityLog1.svg" alt="" />
                </div>
                <div>No changes till date !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        {/*FILTER SCHEDULE TRANSACTION*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openSearchDialogue}
          maxWidth="lg"
          onClose={handleChangeCloseSearchDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseSearchDialogue}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeFromDate(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeToDate(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeFromDate(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeToDate(date);
              }}
            />
            <label style={style.label}> Medium </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterMediumData}
              isItemCheck={this.state.isMediumCheck}
              isAllCheck={this.state.isAllMediumChecked}
              handleAllCheckbox={this.handleAllMediumCheckbox}
              handleSelectedChange={this.handleSelectedMediumChange}
            />
            <label style={style.label}> Status </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterClientSchStatusData}
              isItemCheck={this.state.isScheduleStatusCheck}
              isAllCheck={this.state.isALlScheduleChecked}
              handleAllCheckbox={this.handleAllScheduleCheckbox}
              handleSelectedChange={this.handleSelectedScheduleChange}
            />
            <label style={style.label}>Schedule Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterScheduleTimeData}
              isItemCheck={this.state.isScheduleTypeCheck}
              isAllCheck={this.state.isALlScheduleTypeChecked}
              handleAllCheckbox={this.handleAllScheduleTypeCheckbox}
              handleSelectedChange={this.handleSelectedScheduleTypeChange}
            />
            <label style={style.label}>Transaction Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={allTypeArray}
              isItemCheck={this.state.isUPTypeCheck}
              isAllCheck={this.state.isAllUPTypeCheked}
              handleAllCheckbox={this.handleAllUPTypeCheckbox}
              handleSelectedChange={this.handleSelectedUPTypeChange}
            />
            <label style={style.label}> Vendor </label>
            <div className="select-input h40">
              <Select
                style={style.inputField}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  this.props.handleChangeVendorToFilter(e);
                }}
              >
                {vendorList &&
                  vendorList.map((list) => (
                    <Option value={list.vendorId}>{list.vendorName}</Option>
                  ))}
              </Select>
            </div>
            <div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  {(startDate === null || startDate === 0) &&
                  (endDate === null || endDate === 0) &&
                  arraysEqual(
                    this.state.isScheduleStatusCheck,
                    FilterClientSchStatusData.map((status) => status.name)
                  ) &&
                  arraysEqual(
                    this.state.isScheduleTypeCheck,
                    FilterScheduleTimeData.map((type) => type.name)
                  ) &&
                  arraysEqual(
                    this.state.isUPTypeCheck,
                    FilterUPTypeData.map((type) => type.name)
                  ) &&
                  arraysEqual(
                    this.state.isMediumCheck,
                    FilterMediumData.map((medium) => medium.name)
                  ) ? (
                    <>
                      <Button
                        style={{ float: "right" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            page: 0,
                          });
                          this.props.fetchScheduleTransactionList();
                          handleChangeCloseSearchDialogue();
                        }}
                        className="addButton"
                      >
                        Filter
                      </Button>
                    </>
                  ) : (
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          page: 0,
                        });
                        this.props.searchScheduleTransactionByAll(
                          // mediumType,
                          // scheduleStatus,
                          transactionMediumHelper(this.state.isMediumCheck),
                          ClientSheduleStatusHelper(
                            this.state.isScheduleStatusCheck
                          ),
                          // scheduleType,
                          scheduleTimeHelper(this.state.isScheduleTypeCheck),
                          startDate,
                          endDate,
                          vendorToFilter,
                          // transType,
                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "transactionType"
                          )
                        );
                        handleChangeCloseSearchDialogue();
                      }}
                      className="addButton"
                    >
                      Filter
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*Schedule Transaction details*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openUpdateClientDialogue}
          onClose={handleCloseDialogueForUpdateClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForUpdateClient}
          >
            Schedule Transaction Detail
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              // marginLeft: '1rem'
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Client Name
                  </label>
                  <label style={style.clientData}>
                    {schedule &&
                      schedule.client &&
                      schedule.client.firstName +
                        " " +
                        schedule.client.lastName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Amount(Dollar)
                  </label>
                  <label style={style.clientData}>
                    {schedule && schedule.amount
                      ? formatter.format(schedule.amount / 100)
                      : formatter.format(0)}
                  </label>
                </span>
                <span className="col-4" style={{ position: "initial" }}>
                  <label htmlFor="gender" style={style.columnHeading}>
                    Start Date
                  </label>
                  <label style={style.clientData}>
                    {schedule &&
                      schedule.scheduleDetail &&
                      getDate(schedule.scheduleDetail.scheduledDate)}{" "}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    End Date
                  </label>
                  <label style={style.clientData}>
                    {schedule &&
                    schedule.scheduleDetail &&
                    schedule.scheduleDetail.endDate
                      ? getDate(schedule.scheduleDetail.endDate)
                      : "-"}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Schedule Type
                  </label>
                  <label style={style.clientData}>
                    {schedule &&
                    schedule.scheduleDetail &&
                    schedule.scheduleDetail.scheduleType
                      ? schedule.scheduleDetail.scheduleType.replace("_", " ")
                      : null}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Transaction type
                  </label>
                  <label style={style.clientData}>
                    {schedule && schedule.transactionType}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Transaction Medium
                  </label>
                  <label style={style.clientData}>
                    {schedule && schedule.transactionMedium
                      ? schedule.transactionMedium.replace("_", " ")
                      : null}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Transaction Status
                  </label>
                  <label style={style.clientData}>
                    {schedule && schedule.scheduleTransactionStatus
                      ? schedule.scheduleTransactionStatus.replace("_", " ")
                      : null}
                  </label>
                </span>
              </span>
              {schedule && schedule.note !== undefined ? (
                <span className="row">
                  <div
                    style={{
                      marginLeft: " 240px",
                      marginTop: "20px",
                      fontSize: "17px",
                    }}
                  >
                    <span>Note : {schedule && schedule.note}</span>
                  </div>
                </span>
              ) : null}
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*form of schedule transaction*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openModalToScheduleTransaction}
          maxWidth="lg"
          onClose={handleCloseDialogueToScheduleTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueToScheduleTransaction}
          >
            {isClient
              ? "Request Transaction "
              : !this.state.isUpdate
              ? "Schedule New Transaction"
              : "Update Schedule Transaction"}
          </DialogTitleComponent>
          <DialogContent dividers className="dialogContentForForms">
            <div className="row mt-2">
              {isClient || this.state.isUpdate ? (
                <div className="col-lg-4 select-input h40">
                  <label htmlFor="clientName" style={style.columnHeading}>
                    Client Name
                  </label>
                  <input
                    type="name"
                    id="amount"
                    className="inputField"
                    disabled
                    value={
                      userInfoFromDB.loginResponse.loginUser.client
                        ? userInfoFromDB.loginResponse.loginUser.client
                            .firstName +
                          " " +
                          userInfoFromDB.loginResponse.loginUser.client.lastName
                        : scheduleTransactionInputData.clientName
                    }
                  />
                </div>
              ) : (
                <div className="col-lg-4 select-input h40">
                  <label htmlFor="clientName" style={style.columnHeading}>
                    Client Name <span style={{ color: "red" }}> *</span>
                  </label>
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    onSearch={(value) => {
                      this.props.searchClientList(value);
                    }}
                    disable={this.state.isUpdate}
                    onInputKeyDown={() => {
                      this.props.handleChangeScheduleTransactionInputData(
                        "bankId",
                        ""
                      );
                      this.props.fetchSingleClientSuccess();
                      this.setState({
                        showDropdown: true,
                        clearBankDetail: true,
                      });
                    }}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.setState({ isBlocking: true, isFirstSelect: true });
                      this.props.fetchSingleClient(e, this.props);
                      this.props.fetchVendorClientById(e);
                      this.props.handleChangeScheduleTransactionInputData(
                        "clientId",
                        e
                      );
                    }}
                    onSelect={(e) => {
                      this.props.fetchSingleClient(e, this.props);
                      this.props.getAmountDetailByClientId(e);
                      this.setState({
                        showDropdown: false,
                        isFirstOpen: false,
                        clearBankDetail: false,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        showDropdown: false,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultOpen={false}
                    dropdownStyle={
                      this.state.showDropdown
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    {clientList &&
                      clientList.map((res) =>
                        res.isEnableTransaction && !res.isHold ? (
                          <>
                            <Option key={res.clientId} value={res.clientId}>
                              {res.firstName +
                                " " +
                                res.lastName +
                                " (" +
                                res.clientNo +
                                " )"}
                            </Option>
                          </>
                        ) : null
                      )}
                  </Select>
                </div>
              )}
              <div className="col-lg-4">
                <label htmlFor="amount" style={style.columnHeading}>
                  Amount (Dollar)
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  name="amount"
                  autoComplete="off"
                  className="inputField"
                  value={
                    scheduleTransactionInputData.amount === 0
                      ? ""
                      : scheduleTransactionInputData.amount / 100
                  }
                  onKeyPress={(e) => {
                    naturalRound(e);
                  }}
                  onChange={(e) => {
                    this.props.handleChangeScheduleTransactionInputData(
                      e.target.id,
                      finalAmount(e)
                    );
                    this.setState({ isBlocking: true, buttonDisable: true });
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {amountDetails && amountDetails.totalBalance ? (
                  <div className="mt-2">
                    <span style={{ color: "#8BC34A" }}>Total Balance</span> :
                    <span style={{ color: "#676767" }}>
                      {" "}
                      {amountDetails
                        ? formatter.format(amountDetails.totalBalance / 100)
                        : ""}
                    </span>
                    <span>
                      <Tooltip
                        placement="bottom"
                        overlayInnerStyle={{ width: "215px" }}
                        title={
                          <div>
                            <div>
                              <span>Posted Balance: </span>
                              <span>
                                {formatter.format(
                                  amountDetails.actualBalance / 100
                                )}
                              </span>
                            </div>
                            <div>
                              <span>Submitted Balance: </span>
                              <span>
                                {formatter.format(
                                  amountDetails.availableBalance / 100
                                )}
                              </span>
                            </div>
                            {amountDetails.upcomingBalance ? (
                              <div>
                                <span>Upcoming Balance: </span>
                                <span>
                                  {formatter.format(
                                    amountDetails.upcomingBalance / 100
                                  )}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        }
                        getPopupContainer={(node) => node.parentNode}
                      >
                        <AccountBalanceWalletIcon
                          style={{
                            marginLeft: "5px",
                            color: "#636363d6",
                            width: "19px",
                            marginTop: "-3px",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </div>
                ) : (
                  <>
                    {amountDetails ? (
                      <div className="mt-2">
                        <span style={{ color: "#8BC34A" }}>Total Balance</span>{" "}
                        :
                        <span style={{ color: "#676767" }}>
                          {" "}
                          {formatter.format(0 / 100)}
                        </span>
                      </div>
                    ) : null}
                  </>
                )}
                {errors.amount.length > 0 && (
                  <span className="error">{errors.amount}</span>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="scheduleType" style={style.columnHeading}>
                  Schedule Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div
                  className="select-input selects h40"
                  style={{ width: "100%" }}
                >
                  <Select
                    getPopupContainer={(node) => node.parentNode}
                    value={scheduleTransactionInputData.scheduleType}
                    onChange={(e) => {
                      this.props.handleChangeScheduleTransactionInputData(
                        "scheduleType",
                        e
                      );
                      if (e === "ONE_TIME") {
                        this.props.handleChangeScheduleTransactionInputData(
                          "endDate",
                          scheduleTransactionInputData.scheduledDate
                        );
                      } else {
                        this.props.handleChangeScheduleTransactionInputData(
                          "endDate",
                          null
                        );
                      }
                      // else {
                      //   this.props.handleChangeScheduleTransactionInputData(
                      //     "endDate",
                      //     new Date(Date.now() + 24 * 60 * 60 * 1000).setHours(
                      //       23,
                      //       59,
                      //       59
                      //     )
                      //   );
                      // }
                      this.setState({
                        isBlocking: true,
                        startDate: "",
                        buttonDisable: true,
                      });
                    }}
                  >
                    <Option value="ONE_TIME">One Time</Option>
                    <Option value="DAILY">Daily</Option>
                    <Option value="WEEKLY">Weekly</Option>
                    <Option value="MONTHLY"> Monthly</Option>
                    <Option value="QUARTERLY">Quarterly</Option>
                    <Option value="YEARLY">Yearly</Option>
                    <Option value="NTH_DAY">nth Day</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-4">
                <label htmlFor="startDate" style={style.columnHeading}>
                  Schedule Start Date
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div className="select-dob">
                  <KeyBoardDatePickerComponent
                    dateTimeFormat
                    // disablePast={true}
                    // minDateMessage={!this.state.isUpdate}
                    dateValue={
                      scheduleTransactionInputData.scheduledDate &&
                      getFormattedDateTimeValue(
                        scheduleTransactionInputData.scheduledDate
                      )
                    }
                    onChangeDate={(startDate) => {
                      // const startDate = new Date(date).setHours(0, 0, 0);
                      this.props.handleChangeScheduleTransactionInputData(
                        "scheduledDate",
                        startDate
                      );
                      this.setState({
                        buttonDisable: true,
                      });
                      if (
                        scheduleTransactionInputData &&
                        scheduleTransactionInputData.scheduleType === "ONE_TIME"
                      ) {
                        this.props.handleChangeScheduleTransactionInputData(
                          "endDate",
                          startDate
                        );
                      }
                    }}
                  />
                </div>
                {errors.scheduledDate.length > 0 && (
                  <span className="error">{errors.scheduledDate}</span>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="endDate" style={style.columnHeading}>
                  Schedule End Date{" "}
                </label>
                <div className="select-dob">
                  <KeyBoardDatePickerComponent
                    dateTimeFormat
                    disableMessage="disableMessage"
                    disabled={
                      scheduleTransactionInputData &&
                      scheduleTransactionInputData.scheduleType === "ONE_TIME"
                    }
                    dateValue={
                      scheduleTransactionInputData.endDate &&
                      getFormattedDateTimeValue(
                        scheduleTransactionInputData.endDate
                      )
                    }
                    onChangeDate={(endDate) => {
                      this.props.handleChangeScheduleTransactionInputData(
                        "endDate",
                        endDate
                      );
                      this.setState({
                        buttonDisable: true,
                      });
                    }}
                  />
                </div>
                {errors.endDate.length > 0 && (
                  <span className="error">{errors.endDate}</span>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="transMedium" style={style.columnHeading}>
                  Transaction Medium
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div
                  className="select-input selects h40"
                  style={{ width: "100%" }}
                >
                  <Select
                    id="transactionMedium"
                    value={scheduleTransactionInputData.transactionMedium}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.setState({ isBlocking: true, buttonDisable: true });
                      this.props.handleChangeScheduleTransactionInputData(
                        "transactionMedium",
                        e
                      );
                      this.props.handleChangeTransactionMedium(e);
                      if (!this.state.isUpdate) {
                        this.props.handleChangeScheduleTransactionInputData(
                          "transactionType",
                          ""
                        );
                        this.props.handleChangeScheduleTransactionInputData(
                          "vendorId",
                          ""
                        );
                      }
                    }}
                    // dropdownStyle={{height:"100px"}}
                  >
                    <Option value="CHECK">Check</Option>
                    <Option value="CASH">Cash</Option>
                    <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                    <Option value="ACH">ACH</Option>
                    <Option value="CARD">Card</Option>
                    <Option value="LEDGER">Ledger</Option>
                    <Option value="OTHER_MEDIUM">Other medium</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              {this.state.isUpdate ? (
                <div className="col-lg-4 select-input h40">
                  <label htmlFor="clientName" style={style.columnHeading}>
                    Transaction Type
                  </label>
                  <input
                    type="name"
                    id="amount"
                    className="inputField"
                    disabled
                    value={
                      scheduleTransactionInputData.transactionType ===
                      "DISBURSEMENT"
                        ? "Disbursement"
                        : "Deposit"
                    }
                  />
                </div>
              ) : (
                <div className="col-lg-4">
                  <label htmlFor="transactionType" style={style.columnHeading}>
                    Transaction Type
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      getPopupContainer={(node) => node.parentNode}
                      onClick={() => {
                        if (userType === "CLIENT_USER") {
                          this.props.fetchSingleClient(
                            userClientId,
                            this.props
                          );
                        }
                      }}
                      value={scheduleTransactionInputData.transactionType}
                      onChange={(e) => {
                        this.props.handleChangeScheduleTransactionInputData(
                          "isLedger",
                          false
                        );

                        this.setState({
                          isBlocking: true,
                          buttonDisable: true,
                        });
                        if (
                          e === "DEPOSIT" ||
                          e === "FEE" ||
                          e === "REFUND" ||
                          e === "ANNUAL_FEE"
                        ) {
                          this.props.handleChangeScheduleTransactionInputData(
                            "vendorId",
                            vendorData &&
                              vendorData[0] &&
                              vendorData[0].vendorId
                          );
                        } else {
                          this.props.handleChangeScheduleTransactionInputData(
                            "vendorId",
                            ""
                          );
                        }
                        this.props.handleChangeScheduleTransactionInputData(
                          "transactionType",
                          e
                        );
                        this.props.handleChangeTransactionType(e);
                      }}
                    >
                      <Option
                        value="DISBURSEMENT"
                        hidden={transMedium === "LEDGER"}
                      >
                        Disbursement
                      </Option>
                      <Option value="DEPOSIT" hidden={transMedium === "LEDGER"}>
                        Deposit
                      </Option>
                      <Option value="FEE" hidden={transMedium !== "LEDGER"}>
                        Fee
                      </Option>
                      <Option value="REFUND" hidden={transMedium !== "LEDGER"}>
                        Refund
                      </Option>
                      {/* <Option value="ANNUAL_FEE" hidden={transMedium !== "LEDGER"}>Annual Fee</Option> */}
                    </Select>
                  </div>
                </div>
              )}
              {this.state.isUpdate ? (
                <div className="col-lg-4 select-input h40">
                  <label htmlFor="clientName" style={style.columnHeading}>
                    Vendor
                  </label>
                  <input
                    type="name"
                    id="amount"
                    className="inputField"
                    disabled
                    value={scheduleTransactionInputData.vendorName}
                  />
                </div>
              ) : (
                <>
                  <div className="col-lg-4">
                    <label htmlFor="vendor" style={style.columnHeading}>
                      Vendor
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    {transactionType === "DEPOSIT" ||
                    transactionType === "FEE" ||
                    transactionType === "REFUND" ? (
                      <input
                        className="inputField"
                        value={
                          vendorData && vendorData[0]
                            ? vendorData[0].vendorName
                            : clientVendor &&
                              clientVendor[0] &&
                              clientVendor[0].vendorAccount.vendorName
                        }
                        disabled
                      />
                    ) : (
                      <div
                        className="select-input selects h40"
                        style={{ width: "100%" }}
                      >
                        <Select
                          className="drop"
                          dropdownStyle={{
                            maxHeight: "100px",
                            overflowY: "scroll",
                          }}
                          value={scheduleTransactionInputData.vendorId}
                          getPopupContainer={(node) => node.parentNode}
                          disabled={
                            transactionType === "DEPOSIT" ||
                            transactionType === "FEE" ||
                            transactionType === "REFUND"
                          }
                          onChange={(e) => {
                            this.setState({
                              isBlocking: true,
                              buttonDisable: true,
                            });
                            this.props.handleChangeScheduleTransactionInputData(
                              "vendorId",
                              e
                            );
                          }}
                        >
                          {(transactionType === "DEPOSIT" ||
                            transactionType === "FEE" ||
                            transactionType === "REFUND") &&
                          vendorData &&
                          vendorData[0] ? (
                            <Option value={vendorData[0].vendorId}>
                              {vendorData[0].vendorName}
                            </Option>
                          ) : (
                            clientVendor &&
                            clientVendor.map((vendor) => (
                              <Option
                                value={
                                  vendor.vendorAccount &&
                                  vendor.vendorAccount.vendorId
                                }
                              >
                                {vendor.vendorAccount &&
                                  vendor.vendorAccount.vendorName}
                              </Option>
                            ))
                          )}
                        </Select>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="col-lg-4 select-input h40">
                <label htmlFor="clientName" style={style.columnHeading}>
                  Description
                  <span style={{ color: "red" }}> *</span>
                </label>
                <Select
                  // className="drop"
                  // dropdownStyle={{ maxHeight: "110px", overflowY: "scroll" }}
                  value={scheduleTransactionInputData.description}
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(e) => {
                    this.setState({
                      isBlocking: true,
                      buttonDisable: true,
                    });
                    this.props.handleChangeScheduleTransactionInputData(
                      "description",
                      e
                    );
                  }}
                >
                  {descriptionList &&
                    descriptionList.map((list) => (
                      <Option value={list.description}>
                        {list.description}
                      </Option>
                    ))}
                </Select>
              </div>
              <div
                className="col-lg-4 mt-3"
                hidden={
                  transMedium === "CHECK" || transactionType === "DEPOSIT"
                }
              >
                <label htmlFor="note" style={style.columnHeading}>
                  Note
                </label>
                <input
                  className="inputField"
                  value={scheduleTransactionInputData.note}
                  id="note"
                  type="text"
                  name="note"
                  autoComplete="off"
                  onChange={(e) => {
                    this.props.handleChangeScheduleTransactionInputData(
                      e.target.id,
                      e.target.value
                    );
                    this.setState({ isBlocking: true, buttonDisable: true });
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.note.length > 0 && (
                  <span className="error">{errors.note}</span>
                )}
              </div>
            </div>
            <div
              className={"row mt-4"}
              hidden={
                !(transMedium === "CHECK" || transactionType === "DEPOSIT")
              }
            >
              <div
                className="col-lg-4"
                hidden={
                  !(
                    transMedium === "CHECK" ||
                    (transactionType === "DEPOSIT" && transMedium !== "CHECK")
                  )
                }
              >
                <label htmlFor="note" style={style.columnHeading}>
                  Note
                </label>
                <input
                  className="inputField"
                  id="note"
                  type="text"
                  name="note"
                  value={scheduleTransactionInputData.note}
                  autoComplete="off"
                  onChange={(e) => {
                    this.props.handleChangeScheduleTransactionInputData(
                      e.target.id,
                      e.target.value
                    );
                    this.setState({ isBlocking: true, buttonDisable: true });
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.note.length > 0 && (
                  <span className="error">{errors.note}</span>
                )}
              </div>
              <div
                className="col-lg-4"
                hidden={
                  !(
                    (transMedium === "ACH" && transactionType === "DEPOSIT")
                    // || transactionType === "DEPOSIT" && transMedium !== "CHECK"
                  )
                }
              >
                <label htmlFor="note" style={style.columnHeading}>
                  Client Bank Account <span style={{ color: "red" }}> *</span>
                </label>
                {clientRes &&
                clientRes.bankDetail &&
                clientRes.bankDetail.length === 1 ? (
                  <input
                    className="inputField"
                    disabled
                    value={
                      !this.state.clearBankDetail
                        ? clientRes.bankDetail[0].accountName +
                          " (" +
                          clientRes.bankDetail[0].accountNumber +
                          ")"
                        : ""
                    }
                  />
                ) : (
                  <div className="select-input selects h40">
                    <Select
                      value={
                        !this.state.isFirstSelect
                          ? scheduleTransactionInputData.bankId
                          : ""
                      }
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                          isFirstSelect: false,
                          buttonDisable: true,
                        });
                        this.props.handleChangeScheduleTransactionInputData(
                          "bankId",
                          e
                        );
                      }}
                    >
                      {clientRes &&
                        clientRes.bankDetail &&
                        clientRes.bankDetail.map((detail) => (
                          <Option value={detail.bankDetailId}>
                            {detail.accountName +
                              " " +
                              " (" +
                              detail.accountNumber +
                              " )"}
                          </Option>
                        ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="col-lg-4" hidden={transMedium !== "CHECK"}>
                <label htmlFor="note" style={style.columnHeading}>
                  Print Document
                </label>
                <div>
                  <DocumentImage documentURL={fileUrl} />
                </div>
                <label
                  style={{
                    width: "115px",
                    marginTop: "0.5rem",
                    marginBottom: "1.5rem",
                    background: "rgba(204,204,254,0.32)",
                    border: "1px solid #7b7b7b",
                    textAlign: "center",
                    padding: "4px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      onChangeDocumentFileHandler(e);
                    }}
                    className="hidden"
                    style={{ display: "none" }}
                  />
                  {this.props.scheduleTransactionState.loading
                    ? "Loading..."
                    : "Select file"}
                </label>
              </div>
            </div>

            {/* LEDGER  checkbox  */}
            {scheduleTransactionInputData.transactionType === "DEPOSIT" ? (
              <>
                <div style={style.checkBoxStyle}>
                  <input
                    type="checkbox"
                    name="isLedger"
                    checked={scheduleTransactionInputData.isLedger}
                    value="true"
                    id="checkboxinfo"
                    onChange={(e) => {
                      this.handleChange(e);
                      this.setState({ isBlocking: true, buttonDisable: true });
                    }}
                  />
                  <label style={style.ledgerLabelStyle}>Ledger</label>
                </div>
              </>
            ) : (
              ""
            )}

            <div style={{ padding: "1rem 0px" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "9px",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonComponent
                  buttonName={
                    isClient
                      ? "Request"
                      : !this.state.isUpdate
                      ? "Schedule"
                      : "Update"
                  }
                  value={
                    isClient
                      ? clientValue
                      : !this.state.isUpdate
                      ? transMedium === "ACH" && transactionType === "DEPOSIT"
                        ? scheduleValueWithBank
                        : value
                      : this.state.buttonDisable
                  }
                  // loading={this.props.scheduleTransactionState.loading}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: false });
                    if (!this.state.clicked) {
                      this.setState({
                        clicked: true,
                      });
                      if (
                        clientVendor &&
                        clientVendor.length === 1 &&
                        transactionType !== "DEPOSIT" &&
                        transactionType !== "FEE" &&
                        transactionType !== "REFUND"
                      ) {
                        scheduleReqBody.vendorAccount.vendorId =
                          clientVendor &&
                          clientVendor[0] &&
                          clientVendor[0].vendorAccount.vendorId;
                      }
                      if (isClient) {
                        this.props.requestScheduleTransaction(
                          scheduleReqBody,
                          this.props
                        );
                      } else {
                        if (this.state.isUpdate) {
                          this.props.updateScheduleTransaction(
                            scheduleReqBody,
                            this.props
                          );
                        } else {
                          this.props.addNewScheduleTransaction(
                            scheduleReqBody,
                            this.props
                          );
                        }
                      }
                      setTimeout(() => {
                        this.setState({
                          clicked: false,
                        });
                      }, 3000);
                    }
                  }}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/*Delete schedule transaction*/}
        <Dialog
          open={this.state.openDeleteScheduleDialogue}
          onClose={handleCloseDialogueForScheduleTransaction}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete schedule transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForScheduleTransaction}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteScheduleTransactionDialogue();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to scheduling*/}
        <Dialog
          open={this.state.openUpdateScheduleDialogue}
          onClose={handleCloseDialogueForScheduleTransactionUpdate}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              {this.state.isReopen
                ? "Do you want to reopen the transaction?"
                : "Do you want to schedule the transaction?"}
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForScheduleTransactionUpdate}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "SCHEDULED",
                      };
                      handleOkScheduleStatusDialogue(statusData);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to close*/}
        <Dialog
          open={this.state.openUpdateCloseDialogue}
          onClose={handleCloseUpdateAsClose}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: " move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to close the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsClose}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "CLOSED",
                      };
                      handleOkScheduleStatusDialogue(statusData);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to disable*/}
        <Dialog
          open={this.state.openUpdateDisableDialogue}
          onClose={handleCloseUpdateAsDisable}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to disable the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsDisable}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "DISABLED",
                      };
                      handleOkScheduleStatusDialogue(statusData);
                      handleCloseUpdateAsDisable();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*status update to cancel*/}
        <Dialog
          open={this.state.openUpdateCancelDialogue}
          onClose={handleCloseUpdateAsCancel}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to cancel the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsCancel}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "CANCELLED",
                      };

                      handleOkScheduleStatusDialogue(statusData);
                      handleCloseUpdateAsCancel();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/******* Approve Status ********/}
        <Dialog
          open={this.state.openUpdateApproveDialogue}
          onClose={handleCloseUpdateAsApprove}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to approve the transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseUpdateAsApprove}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        scheduleTransactionId: id,
                        scheduleTransactionStatus: "APPROVED",
                      };
                      handleCloseUpdateAsApprove();
                      handleOkScheduleStatusDialogue(statusData);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configList && configList.length > 0 ? (
              configList.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config.configFilters);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        {/******* Success/Error Message ********/}
        <SnackBarComponent
          onClose={this.props.handleChangeSnackBar}
          variant="success"
          open={
            scheduleTransactionState &&
            scheduleTransactionState.openSuccessSnackBar
          }
          message={
            scheduleTransactionState && scheduleTransactionState.successMsg
          }
        />
        <SnackBarComponent
          onClose={this.props.handleChangeSnackBar}
          variant="error"
          open={
            scheduleTransactionState &&
            scheduleTransactionState.openErrorSnackBar
          }
          message={
            scheduleTransactionState && scheduleTransactionState.errorMsg
          }
        />
      </>
    );
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: { minWidth: "500px" },
});

const style = {
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "180px",
    height: "40px",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    marginTop: "20px",
  },
  card: {
    background: "#ffffff",
    // outline:'1px solid #E9E9E9',
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: "3px",
    marginRight: "3px",
    marginBottom: "1rem",
    marginTop: "0px",
  },
  flex: {
    paddingBottom: "1rem",
    // paddingTop: "1rem",
    paddingLeft: "1rem",
    outline: "none",
    overflow: "auto",
    marginBottom: "5px",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    // width: "23%",
    textTransform: "capitalize",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
    width: "200px",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableFirstHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    paddingLeft: "50px",
  },
  tableBody: {
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
  },
  tableFirstBody: {
    paddingTop: "15px",
    paddingBottom: "15px",
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    paddingLeft: "50px",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
    height: "40px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  checkBoxStyle: {
    position: "absolute",
    top: "87%",
    right: "89%",
  },
  ledgerLabelStyle: {
    color: "rgb(98, 95, 95)",
    fontSize: "16px",
    padding: "5px",
  },
};

export default ScheduleTransactionScreen;
