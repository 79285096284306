import { connect } from "react-redux";
import { SettingScreen } from "../../screens";
import { ContactApi, SettingsAPI } from "../../api";
import {
  addCheckingAccountFailure,
  addCheckingAccountSuccess,
  addDescriptionFailure,
  addDescriptionSuccess,
  addEligibilityDataFailure,
  addEligibilityDataSuccess,
  addFeePercentageFailure,
  addFeePercentageSuccess,
  addHolidaysFailure,
  addHolidaysSuccess,
  addVendorTypeFailure,
  addVendorTypeSuccess,
  assignEventToClientOrAgentsFailure,
  assignEventToClientOrAgentsSuccess,
  checkVendorTypeFailure,
  checkVendorTypeSuccess,
  clearCheckingAccountForm,
  clearDescriptionData,
  clearEligibilityData,
  clearVendorTypeData,
  clearVendorTypeForm,
  closeCalendarSettingSnackBar,
  deleteDescriptionFailure,
  deleteDescriptionSuccess,
  deleteHolidaysFailure,
  deleteHolidaysSuccess,
  fetchCheckingAccountFailure,
  fetchCheckingAccountSuccess,
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  fetchEligibilityDataFailure,
  fetchEligibilityDataSuccess,
  fetchFeePercentageFailure,
  fetchFeePercentageSuccess,
  fetchHolidaysFailure,
  fetchHolidaysSuccess,
  fetchVendorTypeFailure,
  fetchVendorTypeSuccess,
  getAssignedClientOrAgentsFailure,
  getAssignedClientOrAgentsSuccess,
  handleChangeAchDelayThreshold,
  handleChangeAchUploadTime,
  handleChangeAchUploadTimeFormat,
  handleChangeAnnualFee,
  handleChangeAssignEvent,
  handleChangeCalendarType,
  handleChangeCheckingAccountAddress,
  handleChangeCheckingAccountInput,
  handleChangeDescription,
  handleChangeEligibilityData,
  handleChangeEndDate,
  handleChangeEventId,
  handleChangeFeePercentage,
  handleChangeHolidayName,
  handleInitialStartDate,
  handleChangeICSFile,
  handleChangeIsUpdate,
  handleChangeMaxMonthlyFee,
  handleChangeMinMonthlyFee,
  handleChangeSpendDown,
  handleChangeStartDate,
  handleChangeToOpenCheckingAccount,
  handleChangeVendorTypeInput,
  handleCloseAddHolidayModal,
  handleOpenAddHolidayModal,
  importHolidayCalendarFailure,
  importHolidayCalendarSuccess,
  requestInCalendarSetting,
  searchClientOrAgentAccountFailure,
  searchClientOrAgentAccountSuccess,
  setCheckingAccountDataToEdit,
  setDescriptionDataToEdit,
  setEligibilityDataToEdit,
  setHolidayToEdit,
  setImportCalendarSuccess,
  setVendorTypeToEdit,
  updateCheckingAccountFailure,
  updateCheckingAccountSuccess,
  updateDescriptionFailure,
  updateDescriptionSuccess,
  updateEligibilityDataFailure,
  updateEligibilityDataSuccess,
  updateHolidaysFailure,
  updateHolidaysSuccess,
  updateVendorTypeFailure,
  updateVendorTypeSuccess,
  defaultDescriptionRequest,
  fetchDefaultDescriptionSuccess,
  fetchDefaultDescriptionFailure,
} from "../../actions/SettingsAction";
import {
  addCompanyTypeFailure,
  addCompanyTypeSuccess,
  addRelationshipFailure,
  addRelationshipSuccess,
  closeSnackBarForContact,
  contactRequestMade,
  fetchCompanyTypeFailure,
  fetchCompanyTypeSuccess,
  fetchRelationshipFailure,
  fetchRelationshipSuccess,
  getRelationshipPermissionFailure,
  getRelationshipPermissionSuccess,
  handleChangeCompanyType,
  handleChangePermissionName,
  handleChangeRelationshipName,
  searchCompanyTypeFailure,
  searchCompanyTypeSuccess,
  setCompanyTypeToEdit,
  setContactDataToEmpty,
  setRelationshipPermissionFailure,
  setRelationshipPermissionSuccess,
  setRelationshipToEdit,
  updateCompanyTypeFailure,
  updateCompanyTypeSuccess,
  updateRelationshipFailure,
  updateRelationshipSuccess,
} from "../../actions/ContactAction";
import { clearVendorInputData } from "../../actions/VendorAction";
import { handleChange } from "../../actions";
const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchHolidaysList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchHolidayDetails((response, error) => {
        if (response) {
          dispatch(fetchHolidaysSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchHolidaysFailure(error.msg));
          } else {
            dispatch(fetchHolidaysFailure(error));
          }
        }
      });
    },
    handleSettingSnackBarClose: () => {
      dispatch(closeCalendarSettingSnackBar(true));
    },

    handleChangeStartDate: (newStartDate) => {
      dispatch(handleChangeStartDate(newStartDate));
    },
    handleChangeEndDate: (endDate) => {
      dispatch(handleChangeEndDate(endDate));
    },
    handleOpenAddHolidayModal: (bool) => {
      dispatch(handleOpenAddHolidayModal(bool));
    },
    handleCloseAddHolidayModal: (bool) => {
      dispatch(handleCloseAddHolidayModal(bool));
    },
    setAddHolidayToFalse: () => {
      dispatch(updateHolidaysSuccess(false));
    },
    handleChangeHolidayName: (holidayName) => {
      dispatch(handleChangeHolidayName(holidayName));
    },
    handleInitialStartDate: (date) => {
      dispatch(handleInitialStartDate(date));
    },

    handleChangeCalendarType: (calendarType) => {
      dispatch(handleChangeCalendarType(calendarType));
    },
    addHolidays: (holidayData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addHolidays(holidayData, (response, error) => {
        if (response) {
          dispatch(addHolidaysSuccess(response));
          props.fetchHolidaysList();
          props.handleOpenAddHolidayModal(false);
        } else {
          if (error.msg) {
            dispatch(addHolidaysFailure(error.msg));
            props.handleCloseAddHolidayModal(true);
          } else {
            dispatch(addHolidaysFailure(false));
          }
        }
      });
    },
    updateHolidays: (holidayData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.updateHolidays(holidayData, (response, error) => {
        if (response) {
          dispatch(updateHolidaysSuccess(true));
          props.fetchHolidaysList();
        } else {
          if (error.msg) {
            dispatch(updateHolidaysFailure(error.msg));
          } else {
            dispatch(updateHolidaysFailure(error));
          }
        }
      });
    },
    deleteHolidays: (id, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.deleteHolidays(id, (response, error) => {
        if (response) {
          props.fetchHolidaysList();
          dispatch(deleteHolidaysSuccess(response));
        } else {
          if (error.msg) {
            dispatch(deleteHolidaysFailure(error.msg));
          } else {
            dispatch(deleteHolidaysFailure(error));
          }
        }
      });
    },
    setHolidayToEdit: (holidayList) => {
      dispatch(setHolidayToEdit(holidayList));
    },
    handleChangeFeePercentage: (fee) => {
      dispatch(handleChangeFeePercentage(fee));
    },
    handleChangeMinMonthlyFee: (minMonthlyFee) => {
      dispatch(handleChangeMinMonthlyFee(minMonthlyFee));
    },
    handleChangeMaxMonthlyFee: (maxMonthlyFee) => {
      dispatch(handleChangeMaxMonthlyFee(maxMonthlyFee));
    },
    handleChangeAnnualFee: (annualFee) => {
      dispatch(handleChangeAnnualFee(annualFee));
    },
    handleChangeSpendDown: (spendDownThreshold) => {
      dispatch(handleChangeSpendDown(spendDownThreshold));
    },
    handleChangeAchDelayThreshold: (achDelayThreshold) => {
      dispatch(handleChangeAchDelayThreshold(achDelayThreshold));
    },
    handleChangeAchUploadTime: (achUploadTime) => {
      dispatch(handleChangeAchUploadTime(achUploadTime));
    },
    handleChangeTimeFormat: (timeFormat) => {
      dispatch(handleChangeAchUploadTimeFormat(timeFormat));
    },
    addFeePercentage: (data, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addFeePercentage(data, (response, error) => {
        if (response) {
          dispatch(addFeePercentageSuccess(response));
        } else {
          if (error.msg) {
            dispatch(addFeePercentageFailure(error.msg));
          } else {
            dispatch(addFeePercentageFailure(error));
          }
        }
      });
    },
    fetchFeePercentage: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchFeePercentage((response, error) => {
        if (response) {
          dispatch(fetchFeePercentageSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchFeePercentageFailure(error.msg));
          } else {
            dispatch(fetchFeePercentageFailure(error));
          }
        }
      });
    },
    //contact company type
    handleChangeCompanyType: (name) => {
      dispatch(handleChangeCompanyType(name));
    },
    setCompanyTypeToEdit: (companyType) => {
      dispatch(setCompanyTypeToEdit(companyType));
    },
    setAddCompanyTypeToFalse: () => {
      dispatch(addCompanyTypeSuccess(false));
    },
    setUpdateCompanyTypeToFalse: () => {
      dispatch(updateRelationshipSuccess(false));
    },
    addCompanyType: (value, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.addCompanyType(value, (response, error) => {
        if (response) {
          dispatch(addCompanyTypeSuccess(true));
          props.fetchCompanyType();
        } else {
          if (error.msg) {
            dispatch(addCompanyTypeFailure(error.msg));
          } else {
            dispatch(addCompanyTypeFailure(error));
          }
        }
      });
    },
    updateCompanyType: (value, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.editCompanyType(value, (response, error) => {
        if (response) {
          dispatch(updateCompanyTypeSuccess(true));
          props.fetchCompanyType();
        } else {
          if (error.msg) {
            dispatch(updateCompanyTypeFailure(error.msg));
          } else {
            dispatch(updateCompanyTypeFailure(error));
          }
        }
      });
    },
    fetchCompanyType: () => {
      dispatch(contactRequestMade(true));
      ContactApi.fetchCompanyType((response, error) => {
        if (response) {
          dispatch(fetchCompanyTypeSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCompanyTypeFailure(error.msg));
          } else {
            dispatch(fetchCompanyTypeFailure(error));
          }
        }
      });
    },
    searchCompanyType: (term, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.searchCompanyType(term, (response, error) => {
        if (response) {
          dispatch(searchCompanyTypeSuccess(response));
        } else {
          if (error.msg) {
            dispatch(searchCompanyTypeFailure(error.msg));
          } else {
            dispatch(searchCompanyTypeFailure(error));
          }
        }
      });
    },
    handleChangeRelationshipName: (name) => {
      dispatch(handleChangeRelationshipName(name));
    },
    setRelationshipToEdit: (relationship) => {
      dispatch(setRelationshipToEdit(relationship));
    },
    setAddTRelationshipToFalse: () => {
      dispatch(addRelationshipSuccess(false));
    },
    setUpdateTRelationshipToFalse: () => {
      dispatch(updateRelationshipSuccess(false));
    },
    setPermissionToFalse: () => {
      dispatch(setRelationshipPermissionSuccess(false));
    },
    addRelationship: (value, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.addRelationship(value, (response, error) => {
        if (response) {
          dispatch(addRelationshipSuccess(true));
          props.fetchRelationshipList();
        } else {
          if (error.msg) {
            dispatch(addRelationshipFailure(error.msg));
          } else {
            dispatch(addRelationshipFailure(error));
          }
        }
      });
    },
    updateRelationship: (value, props) => {
      dispatch(contactRequestMade(true));
      ContactApi.editRelationship(value, (response, error) => {
        if (response) {
          dispatch(updateRelationshipSuccess(true));
          props.fetchRelationshipList();
        } else {
          if (error.msg) {
            dispatch(updateRelationshipFailure(error.msg));
          } else {
            dispatch(updateRelationshipFailure(error));
          }
        }
      });
    },
    fetchRelationshipList: () => {
      dispatch(contactRequestMade(true));
      ContactApi.fetchRelationship((response, error) => {
        if (response) {
          dispatch(fetchRelationshipSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchRelationshipFailure(error.msg));
          } else {
            dispatch(fetchRelationshipFailure(error));
          }
        }
      });
    },
    setContactDataToEmpty: () => {
      dispatch(setContactDataToEmpty());
    },
    closeContactSnackBar: () => {
      dispatch(closeSnackBarForContact(true));
    },
    getRelationshipPermissionList: () => {
      dispatch(contactRequestMade(true));
      SettingsAPI.getRelationshipPermission((response, error) => {
        if (response) {
          dispatch(getRelationshipPermissionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getRelationshipPermissionFailure(error.msg));
          } else {
            dispatch(getRelationshipPermissionFailure(error));
          }
        }
      });
    },
    setRelationshipPermissionList: (data, props) => {
      dispatch(contactRequestMade(true));
      SettingsAPI.setRelationshipPermission(data, (response, error) => {
        if (response) {
          dispatch(setRelationshipPermissionSuccess(true));
          props.fetchRelationshipList();
        } else {
          if (error.msg) {
            dispatch(setRelationshipPermissionFailure(error.msg));
          } else {
            dispatch(setRelationshipPermissionFailure(error));
          }
        }
      });
    },
    handleChangePermissionName: (permissionName) => {
      dispatch(handleChangePermissionName(permissionName));
    },

    handleChangeICSFile: (icsFile) => {
      dispatch(handleChangeICSFile(icsFile));
    },

    uploadISVFileToImport: (icsFile, calendarType, props) => {
      dispatch(requestInCalendarSetting(true));
      let formData = new FormData();
      formData.append("doc", icsFile.document);
      SettingsAPI.uploadISVFileToImport(
        formData,
        calendarType,
        (response, error) => {
          if (response) {
            dispatch(importHolidayCalendarSuccess(response));
            props.fetchHolidaysList();
          } else {
            if (error.msg) {
              dispatch(importHolidayCalendarFailure(error.msg));
            } else {
              dispatch(importHolidayCalendarFailure(error));
            }
          }
        }
      );
    },

    setImportHolidayCalendarSuccessToFalse: (bool) => {
      dispatch(setImportCalendarSuccess(bool));
    },

    //ASSIGN EVENTS TO CLIENTS OR AGENTS
    searchClientOrAgentAccountSuccess: (value) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.searchClientOrAgentAccountSuccess(
        value,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(searchClientOrAgentAccountFailure(error.msg));
            } else {
              dispatch(searchClientOrAgentAccountFailure(error));
            }
          } else {
            dispatch(searchClientOrAgentAccountSuccess(response));
          }
        }
      );
    },
    assignEventToClientsOrAgent: (value, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.assignEventToClientsOrAgent(value, (response, error) => {
        if (response) {
          dispatch(assignEventToClientOrAgentsSuccess(true));
        } else {
          if (error.msg) {
            dispatch(assignEventToClientOrAgentsFailure(error.msg));
          } else {
            dispatch(assignEventToClientOrAgentsFailure(error));
          }
        }
      });
    },
    setAssignEventSuccessToFalse: () => {
      dispatch(assignEventToClientOrAgentsSuccess(false));
    },
    getEventAssignedClientsOrAgent: (id, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.getEventAssignedClientsOrAgent(id, (response, error) => {
        if (response) {
          dispatch(getAssignedClientOrAgentsSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAssignedClientOrAgentsFailure(error.msg));
          } else {
            dispatch(getAssignedClientOrAgentsFailure(error));
          }
        }
      });
    },
    handleChangeAssignEvent: (value) => {
      dispatch(handleChangeAssignEvent(value));
    },
    handleChangeEventId: (value) => {
      dispatch(handleChangeEventId(value));
    },

    //ELIGIBILITY SETTING
    handleChangeEligibilityData: (id, value) => {
      dispatch(handleChangeEligibilityData(id, value));
    },
    setEligibilityDataToEdit: (eligibilityData) => {
      dispatch(setEligibilityDataToEdit(eligibilityData));
    },
    clearEligibilityData: () => {
      dispatch(clearEligibilityData());
    },

    fetchEligibilityData: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchEligibilityData((response, error) => {
        if (response) {
          dispatch(fetchEligibilityDataSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchEligibilityDataFailure(error.msg));
          } else {
            dispatch(fetchEligibilityDataFailure(error));
          }
        }
      });
    },

    addEligibility: (eligibilityData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addEligibilityData(eligibilityData, (response, error) => {
        if (response) {
          dispatch(addEligibilityDataSuccess(true));
          props.fetchEligibilityData();
        } else {
          if (error.msg) {
            dispatch(addEligibilityDataFailure(error.msg));
          } else {
            dispatch(addEligibilityDataFailure(false));
          }
        }
      });
    },

    updateEligibility: (eligibilityData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.updateEligibilityData(eligibilityData, (response, error) => {
        if (response) {
          dispatch(updateEligibilityDataSuccess(true));
          props.fetchEligibilityData();
        } else {
          if (error.msg) {
            dispatch(updateEligibilityDataFailure(error.msg));
          } else {
            dispatch(updateEligibilityDataFailure(false));
          }
        }
      });
    },

    setAddEligibilitySuccessToFalse: () => {
      dispatch(addEligibilityDataSuccess(false));
    },
    setUpdateEligibilitySuccessToFalse: () => {
      dispatch(updateEligibilityDataSuccess(false));
    },

    //DESCRIPTION SETTING
    handleChangeDescription: (id, value) => {
      dispatch(handleChangeDescription(id, value));
    },
    setDescriptionToEdit: (descriptionData) => {
      dispatch(setDescriptionDataToEdit(descriptionData));
    },
    clearDescriptionModal: () => {
      dispatch(clearDescriptionData());
    },

    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },

    // setDefaultDescription: (descriptionId, props) => {
    //   dispatch(defaultDescriptionRequest(true));
    //   SettingsAPI.setDefaultDescription(descriptionId, (response, error) => {
    //     if (response) {
    //       dispatch(fetchDefaultDescriptionSuccess(response));
    //       console.log(response, "response");
    //       console.log(props);
    //       // props.fetchDescriptionList();
    //     } else {
    //       if (error.msg) {
    //         dispatch(fetchDefaultDescriptionFailure(error.msg));
    //       } else {
    //         dispatch(fetchDefaultDescriptionFailure(error));
    //       }
    //     }
    //   });
    // },
    setDefaultDescription: (descriptionId, props) => {
      dispatch(defaultDescriptionRequest(true));
      SettingsAPI.setDefaultDescription(descriptionId, (response, error) => {
        if (response) {
          dispatch(fetchDefaultDescriptionSuccess(response));
          console.log(props, "props");
          props.fetchDescriptionList();
        } else {
          if (error.msg) {
            dispatch(fetchDefaultDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDefaultDescriptionFailure(error.msg));
          }
        }
      });
    },
    addDescription: (descriptionData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addNewDescription(descriptionData, (response, error) => {
        if (response) {
          dispatch(addDescriptionSuccess(true));
          console.log(props, "props");
          props.fetchDescriptionList();
        } else {
          if (error.msg) {
            dispatch(addDescriptionFailure(error.msg));
          } else {
            dispatch(addDescriptionFailure(false));
          }
        }
      });
    },

    updateDescription: (descriptionData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.updateDescription(descriptionData, (response, error) => {
        if (response) {
          dispatch(updateDescriptionSuccess(true));
          props.fetchDescriptionList();
        } else {
          if (error.msg) {
            dispatch(updateDescriptionFailure(error.msg));
          } else {
            dispatch(updateDescriptionFailure(false));
          }
        }
      });
    },
    deleteDescription: (descriptionId, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.deleteDescription(descriptionId, (response, error) => {
        if (response) {
          dispatch(deleteDescriptionSuccess(true));
          props.fetchDescriptionList();
        } else {
          if (error.msg) {
            dispatch(deleteDescriptionFailure(error.msg));
          } else {
            dispatch(deleteDescriptionFailure(false));
          }
        }
      });
    },
    setAddDescriptionSuccessToFalse: () => {
      dispatch(addDescriptionSuccess(false));
    },
    setUpdateDescriptionSuccessToFalse: () => {
      dispatch(updateDescriptionSuccess(false));
    },

    // VENDOR TYPE SETTING

    handleChangeVendorTypeInput: (id, value) => {
      dispatch(handleChangeVendorTypeInput(id, value));
    },
    clearVendorTypeData: () => {
      dispatch(clearVendorTypeForm());
    },
    setVendorTypeToEdit: (vendorTypeData) =>
      dispatch(setVendorTypeToEdit(vendorTypeData)),
    fetchVendorTypeList: (searchTerm) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchVendorType(searchTerm, (response, error) => {
        if (response) {
          dispatch(fetchVendorTypeSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorTypeFailure(error.msg));
          } else {
            dispatch(fetchVendorTypeFailure(error));
          }
        }
      });
    },
    checkVendorType: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.checkVendorType((response, error) => {
        if (response) {
          dispatch(checkVendorTypeSuccess(response));
        } else {
          if (error.msg) {
            dispatch(checkVendorTypeFailure(error.msg));
          } else {
            dispatch(checkVendorTypeFailure(error));
          }
        }
      });
    },
    addVendorType: (vendorTypeData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addNewVendorType(vendorTypeData, (response, error) => {
        if (response) {
          dispatch(addVendorTypeSuccess(true));
          props.fetchVendorTypeList("");
        } else {
          if (error.msg) {
            dispatch(addVendorTypeFailure(error.msg));
          } else {
            dispatch(addVendorTypeFailure(false));
          }
        }
      });
    },
    setAddVendorTypeSuccessToFalse: () => {
      dispatch(addVendorTypeSuccess(false));
    },
    updateVendorType: (vendorTypeData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.updateVendorType(vendorTypeData, (response, error) => {
        if (response) {
          dispatch(updateVendorTypeSuccess(true));
          props.fetchVendorTypeList("");
        } else {
          if (error.msg) {
            dispatch(updateVendorTypeFailure(error.msg));
          } else {
            dispatch(updateVendorTypeFailure(false));
          }
        }
      });
    },
    setUpdateVendorTypeSuccessToFalse: () => {
      dispatch(updateVendorTypeSuccess(false));
    },

    // CHECKING ACCOUNT SETTING
    handleChangeToOpenCheckingAccount: (bool) => {
      dispatch(handleChangeToOpenCheckingAccount(bool));
    },
    handleChangeCheckingAccountData: (id, value) => {
      dispatch(handleChangeCheckingAccountInput(id, value));
    },
    handleChangeCheckingAccountAddress: (id, value) => {
      dispatch(handleChangeCheckingAccountAddress(id, value));
    },
    clearCheckingAccountData: () => {
      dispatch(clearCheckingAccountForm());
    },
    setCheckingAccountDataToEdit: (checkingAccountData) =>
      dispatch(setCheckingAccountDataToEdit(checkingAccountData)),
    handleChangeIsUpdate: (isUpdate) => {
      dispatch(handleChangeIsUpdate(isUpdate));
    },
    fetchCheckingAccountsList: (props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchCheckingAccount((response, error) => {
        if (response) {
          dispatch(fetchCheckingAccountSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCheckingAccountFailure(error.msg));
          } else {
            dispatch(fetchCheckingAccountFailure(error));
          }
        }
      });
    },
    addCheckingAccount: (checkingAccountData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.addNewCheckingAccount(
        checkingAccountData,
        (response, error) => {
          if (response) {
            dispatch(addCheckingAccountSuccess(true));
            props.fetchCheckingAccountsList();
            props.handleChangeToOpenCheckingAccount(false);
          } else {
            if (error.msg) {
              dispatch(addCheckingAccountFailure(error.msg));
              props.handleChangeToOpenCheckingAccount(true);
            } else {
              dispatch(addCheckingAccountFailure(false));
              props.handleChangeToOpenCheckingAccount(true);
            }
          }
        }
      );
    },
    updateCheckingAccount: (checkingAccountData, props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.updateCheckingAccount(
        checkingAccountData,
        (response, error) => {
          if (response) {
            dispatch(updateCheckingAccountSuccess(true));
            props.fetchCheckingAccountsList();
            props.handleChangeToOpenCheckingAccount(false);
          } else {
            if (error.msg) {
              dispatch(updateCheckingAccountFailure(error.msg));
              props.handleChangeToOpenCheckingAccount(true);
            } else {
              dispatch(updateCheckingAccountFailure(false));
              props.handleChangeToOpenCheckingAccount(true);
            }
          }
        }
      );
    },
  };
};

export const SettingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingScreen);
