import {
  ACTION_TYPE_CLIENT,
  ACTION_TYPE_NOTE,
  ACTION_TYPE_REGISTER_CASE,
  ACTION_TYPE_SET_PASSWORD,
  // ACTION_TYPE_SETTING,
} from "../constants/Type";

// emailAlert

const initialState = {
  loading: false,
  openClientSnackBar: false,
  openClientErrorSnackBar: false,
  openClientDeleteSnackBar: false,
  openUpdateProfilePictureSnackBar: false,
  notes: "",
  clientRes: {},
  clientData: {
    ssn: "",
    firstName: "",
    lastName: "",
    gender: -1,
    dob: null,
    isEnableTransaction: false,
    maritalStatus: -1,
    profilePicUrl: "",
    contacts: [],
  },
  clientId: "",
  email: "",
  fileResponse: [],
  profilePicUrl: "",
  accountName: "",
  accountNumber: "",
  routingNumber: "",
  bankName: "",
  bankAccountType: "",
  openErrorSnackBar: false,
  openDeleteBankDetailSnackBar: false,
  openUploadErrorSnackBar: false,
  openClientDeleteSuccess: false,
  paymentSettingId: "",
  feePercentage: "",
  monthlyFee: 0,
  annualFee: 0,
  spendDownThreshold: 0,
  achDelayThreshold: "",
  installmentMonth: "",
  admissionDate: "",
  client: [],
  clientWithBalance: [],
  success: false,
  clientNameId: "Search Existing Client",
  addedClientNameId: "",
  clientResData: {},
  openAddClientErrorSnackbar: false,
  addSuccess: false,
  openClientSetPasswordSuccess: false,
  successMsg: "",
  errorMsgForClient: "",
  openSnackBarForSuccessMessage: false,
  openSnackBarForErrorMessage: false,
  openClientFailureSnackBar: false,
  eligibilityData: {
    familyType: "",
    memberNo: "",
    caseId: "",
  },
  workflowId: "",
  actionId: "",
  noteData: {
    // title: "",
    description: "",
  },
  familyType: "",
  member: "",
  successPayment: false,
  openClientFormModal: false,
  openSetNotificationModal: false,
  enabledNotificationData: {
    isUpcomingTransactionEnabled: false,
    isHolidaysEnabled: false,
    isAllEnabled: false,
  },
  upcomingAlert: false,
  holidayAlert: false,
  emailAlertId: "",
  openNotesModalForClient: false,
  openModalCreateClientSuccess: false,
  openModalCreateClientError: false,
  maxMonthlyFee: "",
  minMonthlyFee: "",
  // filter client
  name: "",
  clientNo: "",
  date: null,
  caseCount: "",
  balance: "",
  term: "",
  userstatus: "",
  // for fetching all user status
  fetchAll: true,
  blockedTransData: {
    transactionId: "",
    amount: 0,
    date: null,
  },
  // config list
  configlist: [],

  // pagination
  nextUrl: "",
};

const clientState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_CLIENT.CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        client: action.client.clients,
        loading: false,
        openClientSnackBar: false,
        success: false,
      };
    case ACTION_TYPE_CLIENT.CLIENT_WITH_BALANCE_FETCH_SUCCESS:
      let getUrl = state.nextUrl;
      if (action.client.clientBalance && action.client.clientBalance.nextUrl) {
        getUrl = action.client.clientBalance.nextUrl;
      }
      return {
        ...state,
        // client: action.client.clientBalance,
        clientWithBalance: action.client.clientBalance,
        loading: false,
        openClientSnackBar: false,
        success: false,
      };
    case ACTION_TYPE_CLIENT.CLIENT_FETCH_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openClientDeleteSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.CLIENT_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_NAME:
      return {
        ...state,
        loading: false,
        clientNameId: action.clientId,
      };
    case ACTION_TYPE_CLIENT.CLIENT_PROFILE_PICTURE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_CLIENT.DELETE_CLIENT_SUCCESS:
      const oldClientList = state.clientWithBalance;
      let newClients = [];
      for (let i = 0; i < oldClientList.length; i++) {
        if (oldClientList[i].client.clientId !== action.clientId) {
          newClients.push(oldClientList[i]);
        }
      }
      return {
        ...state,
        clientWithBalance: newClients,
        loading: false,
        openClientDeleteSnackBar: false,
        openClientDeleteSuccess: true,
      };
    case ACTION_TYPE_CLIENT.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openClientDeleteSnackBar: true,
        openClientDeleteSuccess: false,
      };
    case ACTION_TYPE_CLIENT.CLOSE_SNACK_BAR_CLIENT_PAGE:
      return {
        ...state,
        openClientSnackBar: false,
        openClientErrorSnackBar: false,
        openClientFailureSnackBar: false,
        openClientDeleteSnackBar: false,
        openUpdateProfilePictureSnackBar: false,
        openErrorSnackBar: false,
        openDeleteBankDetailSnackBar: false,
        openUploadErrorSnackBar: false,
        openClientDeleteSuccess: false,
        openAddClientErrorSnackbar: false,
        openClientSetPasswordSuccess: false,
        openSnackBarForSuccessMessage: false,
        openSnackBarForErrorMessage: false,
        name: "",
        clientNo: "",
        date: null,
        caseCount: "",
        balance: "",
        term: "",
      };
    case ACTION_TYPE_CLIENT.CLEAR_FILTER_DATA:
      return {
        ...state,
        name: "",
        clientNo: "",
        date: null,
        caseCount: "",
        userstatus: "",
        balance: "",
        fetchAll: true,
        term: "",
      };

    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT:
      const newForm = { ...state.clientData };
      newForm[action.id] = action.value;
      return {
        ...state,
        clientData: newForm,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.CLEAR_CLIENT_DATA_INPUT:
      return {
        ...state,
        clientData: {
          ssn: "",
          firstName: "",
          lastName: "",
          gender: -1,
          dob: null,
          isEnableTransaction: false,
          maritalStatus: -1,
          profilePicUrl: "",
          contacts: [],
        },
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ADDED_CLIENT_ID:
      return {
        ...state,
        addedClientNameId: action.value,
      };
    case ACTION_TYPE_CLIENT.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        clientData: action.response.client,
        addedClientNameId: action.response.client.clientId,
        loading: false,
      };
    case ACTION_TYPE_REGISTER_CASE.ADD_CLIENT_FORM_DATA_SUCCESS:
      return {
        ...state,
        openClientFormModal: false,
      };
    case ACTION_TYPE_REGISTER_CASE.ADD_CLIENT_FORM_DATA_FAILURE:
      return {
        ...state,
        openClientFormModal: true,
      };

    case ACTION_TYPE_CLIENT.ADD_CLIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        openAddClientErrorSnackbar: true,
        openClientFormModal: true,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.SET_ADD_CLIENT_SUCCESS_TO_TRUE_OR_FALSE:
      return {
        ...state,
        addSuccess: action.bool,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.SET_CLIENT_TO_EDIT:
      return {
        ...state,
        clientData: action.clientData,
        loading: false,
        openClientSnackBar: false,
      };
    case ACTION_TYPE_CLIENT.CLIENT_ADD_SUCCESS_IN_CLIENT_UPDATE:
      return {
        ...state,
        successMsg: "Successfully Updated",
        loading: false,
        openClientSnackBar: true,
        openClientErrorSnackBar: false,
      };
    case ACTION_TYPE_CLIENT.CLIENT_ADD_FAILURE_IN_CLIENT_UPDATE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openClientSnackBar: false,
        openClientErrorSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.SEARCH_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.client.clientBalance,
        loading: false,
        openClientSnackBar: false,
      };

    case ACTION_TYPE_CLIENT.SEARCH_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        searchedClient: null,
        openClientSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.SEARCH_CLIENT_WITH_BALANCE_SUCCESS:
      return {
        ...state,
        clientWithBalance: action.client.clientBalance,
        loading: false,
        openClientSnackBar: false,
      };

    case ACTION_TYPE_CLIENT.SEARCH_CLIENT_WITH_BALANCE_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        searchedClient: null,
        openErrorSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.FETCH_SINGLE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientRes: action.response.client,
        clientData: action.response.client,
        profilePicUrl: action.response.client.profilePicUrl,
      };
    case ACTION_TYPE_CLIENT.FETCH_SINGLE_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openClientSnackBar: false,
      };
    case ACTION_TYPE_SET_PASSWORD.SET_CLIENT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetSuccess: action.bool,
        email: "",
        successMsg: "Successfully Set",
        openSnackBarForSuccessMessage: true,
        openClientSetPasswordSuccess: true,
      };
    case ACTION_TYPE_SET_PASSWORD.SET_CLIENT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openSnackBarForErrorMessage: true,
        openClientDeleteSnackBar: true,
      };
    case ACTION_TYPE_SET_PASSWORD.HANDLE_CHANGE_EMAIL:
      return {
        ...state,
        loading: false,
        email: action.value,
        clientId: action.id,
      };
    case ACTION_TYPE_CLIENT.FETCH_FILE_DETAILS_BY_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        fileResponse: action.fileResponse ? action.fileResponse.actions : null,
      };
    case ACTION_TYPE_CLIENT.FETCH_FILE_DETAILS_BY_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openClientSnackBar: false,
      };
    case ACTION_TYPE_CLIENT.UPDATE_CLIENT_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        ppResponse: action.response,
        openSnackBarForSuccessMessage: true,
        successMsg: "Successfully Updated",
        loading: false,
      };
    case ACTION_TYPE_CLIENT.UPDATE_CLIENT_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        openSnackBarForErrorMessage: true,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.DECLINE_CLIENT_TRANS_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        openSnackBarForSuccessMessage: true,
        openSnackBarForErrorMessage: false,
        successMsg: "Success",
        success: action.response,
      };
    case ACTION_TYPE_CLIENT.DECLINE_CLIENT_TRANS_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        openSnackBarForErrorMessage: true,
        error: action.error,
      };
    case ACTION_TYPE_CLIENT.UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        data: action.data,
        profilePicUrl: action.data.profilePicUrl,
        loading: false,
        // success: true,
      };
    case ACTION_TYPE_CLIENT.UPLOAD_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        openSnackBarForErrorMessage: true,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.SET_UPLOAD_SUCCESS_TO_FALSE:
      return {
        ...state,
        success: false,
      };
    case ACTION_TYPE_CLIENT.FETCH_CASE_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        caseDetails: action.caseDetails.cases,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.FETCH_CASE_BY_CLIENT_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        caseDetails: [],
        loading: false,
      };
    case ACTION_TYPE_CLIENT.DELETE_CASE_BY_CLIENT_SUCCESS:
      return {
        ...state,
        caseId: action.caseId,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.DELETE_CASE_BY_CLIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_SUCCESS:
      return {
        ...state,
        bankDetails: action.bankDetails,
        success: false,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ACCOUNT_NAME:
      return {
        ...state,
        accountName: action.accName,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ACCOUNT_NUMBER:
      return {
        ...state,
        accountNumber: action.accNumber,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ROUTING_NUMBER:
      return {
        ...state,
        routingNumber: action.routingNumber,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_BANK_NAME:
      return {
        ...state,
        bankName: action.bankName,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_BANK_ACCOUNT_TYPE:
      return {
        ...state,
        bankAccountType: action.bankAccountType,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.ADD_CLIENT_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        success: action.response,
        openSnackBarForSuccessMessage: true,
        successMsg: "Successfully Added",
        loading: false,
      };
    case ACTION_TYPE_CLIENT.ADD_CLIENT_BANK_DETAILS_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openSnackBarForErrorMessage: true,
      };
    case ACTION_TYPE_CLIENT.UPDATE_CLIENT_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        success: action.response,
        loading: false,
        openSnackBarForSuccessMessage: true,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_CLIENT.UPDATE_CLIENT_BANK_DETAILS_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openSnackBarForErrorMessage: true,
      };
    case ACTION_TYPE_CLIENT.DELETE_CLIENT_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        success: action.response,
        loading: false,
        successMsg: "Successfully Deleted",
        openSnackBarForSuccessMessage: true,
      };
    case ACTION_TYPE_CLIENT.DELETE_CLIENT_BANK_DETAILS_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openSnackBarForErrorMessage: true,
      };
    case ACTION_TYPE_CLIENT.SET_BANK_DETAIL_TO_EDIT:
      return {
        ...state,
        accountName: action.bankDetail.accountName,
        accountNumber: action.bankDetail.accountNumber,
        routingNumber: action.bankDetail.routingNumber,
        bankName: action.bankDetail.bankName,
        bankAccountType: action.bankDetail.bankAccountType,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.CLEAR_BANK_DETAIL:
      return {
        ...state,
        accountName: "",
        accountNumber: "",
        routingNumber: "",
        bankName: "",
        bankAccountType: "",
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_FEE_PERCENT:
      return {
        ...state,
        feePercentage: action.fee,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_MONTHLY_FEE:
      return {
        ...state,
        monthlyFee: action.monthlyFee,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_ANNUAL_FEE:
      return {
        ...state,
        annualFee: action.annualFee,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ADMISSION_DATE:
      return {
        ...state,
        admissionDate: action.admissionDate,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_SPEND_DOWN:
      return {
        ...state,
        spendDownThreshold: action.spendDownThreshold,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_ACH_DELAY_THRESHOLD:
      return {
        ...state,
        achDelayThreshold: action.achDelayThreshold,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_CLIENT_INSTALLMENT:
      return {
        ...state,
        installmentMonth: action.installmentMonth,
      };
    case ACTION_TYPE_CLIENT.CLEAR_PAYMENT_VALUES:
      return {
        ...state,
        spendDownThreshold: "",
        loading: false,
      };
    case ACTION_TYPE_CLIENT.FETCH_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isUpdate: !!action.paymentDetail.paymentSetting.monthlyFee,
        paymentSettingId: action.paymentDetail.paymentSetting.paymentSettingId,
        feePercentage: action.paymentDetail.paymentSetting.feePercentage,
        monthlyFee: action.paymentDetail.paymentSetting.monthlyFee,
        annualFee: action.paymentDetail.paymentSetting.annualFee,
        admissionDate: action.paymentDetail.paymentSetting.admissionDate,
        spendDownThreshold:
          action.paymentDetail.paymentSetting.spendDownThreshold,
        installmentMonth: action.paymentDetail.paymentSetting.installmentMonth,
        achDelayThreshold:
          action.paymentDetail.paymentSetting.achDelayThreshold,
        loading: false,
        maxMonthlyFee: action.paymentDetail.paymentSetting.maxMonthlyFee,
        minMonthlyFee: action.paymentDetail.paymentSetting.minMonthlyFee,
      };
    case ACTION_TYPE_CLIENT.FETCH_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_CLIENT.ADD_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        openClientSnackBar: true,
        successMsg: "Successfully Added",
        loading: false,
        isUpdate: !!action.response.paymentSetting.monthlyFee,
        paymentSettingId: action.response.paymentSetting.paymentSettingId,
        feePercentage: action.response.paymentSetting.feePercentage,
        monthlyFee: action.response.paymentSetting.monthlyFee,
        annualFee: action.response.paymentSetting.annualFee,
        admissionDate: action.response.paymentSetting.admissionDate,
        spendDownThreshold: action.response.paymentSetting.spendDownThreshold,
        installmentMonth: action.response.paymentSetting.installmentMonth,
        achDelayThreshold: action.response.paymentSetting.achDelayThreshold,
      };
    case ACTION_TYPE_CLIENT.ADD_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        openClientSnackBar: false,
        isUpdate: false,
        // successPayment: false,
        openClientFailureSnackBar: true,
        errorMsgForClient: action.error,
      };
    case ACTION_TYPE_CLIENT.CLIENT_ON_HOLD_SUCCESS:
      return {
        ...state,
        // clientPaymentDetail: action.response.paymentSetting,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.CLIENT_ON_HOLD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_CLIENT.CLIENT_ON_DISCHARGE_SUCCESS:
      return {
        ...state,
        // clientPaymentDetail: action.response.paymentSetting,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.CLIENT_ON_DISCHARGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_CLIENT.GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_SUCCESS:
      return {
        ...state,
        loading: false,
        calendarEvents: action.response.calendarSettings,
      };
    case ACTION_TYPE_CLIENT.GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //ELIGIBILITY CALCULATION
    case ACTION_TYPE_CLIENT.HANDLE_CHANGES_ELIGIBILITY_SETTING:
      const newEligibilitySettingData = { ...state.eligibilityData };
      newEligibilitySettingData[action.id] = action.value;
      return {
        ...state,
        eligibilityData: newEligibilitySettingData,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.ELIGIBILITY_CALCULATION_SUCCESS:
      return {
        ...state,
        loading: false,
        openSnackBarForSuccessMessage: true,
        successMsg: action.response.calculateEligibility.msg,
      };
    case ACTION_TYPE_CLIENT.ELIGIBILITY_CALCULATION_FAILURE:
      return {
        ...state,
        loading: false,
        openSnackBarForErrorMessage: true,
        errorMsgForClient: action.error,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_WORKFLOW:
      return {
        ...state,
        loading: false,
        workflowId: action.workflowId,
      };
    case ACTION_TYPE_CLIENT.CLEAR_WORKFLOW:
      return {
        ...state,
        loading: false,
        workflowId: "",
        actionId: "",
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ACTION:
      return {
        ...state,
        loading: false,
        actionId: action.actionId,
      };
    case ACTION_TYPE_CLIENT.CLEAR_ACTIONS:
      return {
        ...state,
        loading: false,
        actionId: "",
      };
    case ACTION_TYPE_CLIENT.CLEAR_ELIGIBILITY_SETTING:
      return {
        ...state,
        loading: false,
        workflowId: "",
        actionId: "",
        eligibilityData: {
          familyType: "",
          memberNo: "",
          caseId: "",
        },
      };
    //*********** note **********
    case ACTION_TYPE_NOTE.HANDLE_CHANGE_NOTE_DESCRIPTION:
      const newNote = { ...state.noteData };
      newNote[action.id] = action.description;
      return {
        ...state,
        noteData: newNote,
        loading: false,
      };
    case ACTION_TYPE_NOTE.SET_NOTE_DESCRIPTION_TO_EDIT:
      return {
        ...state,
        noteData: action.noteDescription,
        loading: false,
      };
    case ACTION_TYPE_NOTE.CLEAR_NOTE_DESCRIPTION:
      return {
        ...state,
        noteData: {
          title: "",
          description: "",
        },
      };
    case ACTION_TYPE_CLIENT.UPDATE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: "Sucessfully Updated",
      };
    case ACTION_TYPE_NOTE.ADD_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        openSnackBarForSuccessMessage: true,
        successMsg: "Successfully Added",
        openNotesModalForClient: false,
        // clientTransaction:transaction
        // success: action.bool,
      };
    case ACTION_TYPE_NOTE.ADD_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openSnackBarForErrorMessage: true,
      };
    case ACTION_TYPE_NOTE.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        openSnackBarForSuccessMessage: true,
        successMsg: "Successfully Updated",
        // success: action.bool,
      };
    case ACTION_TYPE_NOTE.UPDATE_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openSnackBarForErrorMessage: true,
      };
    case ACTION_TYPE_NOTE.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        openSnackBarForSuccessMessage: true,
        successMsg: "Successfully Deleted",
        // success: action.bool,
      };
    case ACTION_TYPE_NOTE.DELETE_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsgForClient: action.error,
        openSnackBarForErrorMessage: true,
      };
    case ACTION_TYPE_NOTE.GET_NOTE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: action.notes.notes,
        // openSnackBarForSuccessMessage: true,
        // openNotesModalForClient: false,
        // successMsg: "Successfully Added",
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_OPEN_CLIENT_FORM_MAODAL:
      return {
        ...state,
        loading: false,
        openClientFormModal: action.bool,
      };
    // SET NOTIFICATION
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_TO_OPEN_SET_NOTIFICATION_MODAL:
      return {
        ...state,
        openSetNotificationModal: action.bool,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_ENABLE_NOTIFICATIONS:
      const newEnabledData = { ...state.enabledNotificationData };
      newEnabledData[action.id] = action.value;
      return {
        ...state,
        enabledNotificationData: newEnabledData,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_UPCOMING_ALERT:
      return {
        ...state,
        upcomingAlert: action.bool,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_HOLIDAY_ALERT:
      return {
        ...state,
        holidayAlert: action.bool,
      };
    case ACTION_TYPE_CLIENT.SAVE_NOTIFICATIONS_SETTING_SUCCESS:
      return {
        ...state,
        response: action.response,
        openClientSnackBar: true,
        successMsg: "Sucessfully Set",
        loading: false,
      };
    case ACTION_TYPE_CLIENT.SAVE_NOTIFICATIONS_SETTING_FAILURE:
      return {
        ...state,
        openClientErrorSnackBar: true,
        errorMsgForClient: action.error,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.GET_ALERT_BY_ACCOUNT_ID_SUCCESS:
      return {
        ...state,
        response: action.response.emailAlert,
        upcomingAlert: !!action.response.emailAlert.upcomingTransactionAlert,
        holidayAlert: !!action.response.emailAlert.upcomingHolidayAlert,
        emailAlertId: action.response.emailAlert.emailAlertId
          ? action.response.emailAlert.emailAlertId
          : "",
        loading: false,
      };
    case ACTION_TYPE_CLIENT.GET_ALERT_BY_ACCOUNT_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    // CLIENT DETAIL PAGE
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_CLIENT:
      return {
        ...state,
        openNotesModalForClient: action.bool,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.ADD_NOTES_SUCCESS_FOR_CLIENT:
      return {
        ...state,
        loading: false,
        openNotesModalForClient: false,
        openModalCreateClientSuccess: true,
        successMsg: "Sucessfully Updated",
      };
    case ACTION_TYPE_CLIENT.ADD_NOTES_FAILURE_FOR_CLIENT:
      return {
        ...state,
        loading: false,
        openNotesModalForClient: true,
        openModalCreateClientError: true,
        errorMsgForClient: action.error,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_NOTES_FOR_CLIENT:
      return {
        ...state,
        notes: action.notes,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: action.transactionData.transactions,
        totalBalance: action.transactionData.totalAmount,
        totalTransaction: action.transactionData.totalCount,
        from: null,
        to: null,
        mediumType: "UNKNOWN_TRANSACTION_MEDIUM",
        transactionStatus: "UNKNOWN_TRANSACTION_STATUS",
        transType: "UNKNOWN_TRANSACTION_TYPE",
        term: "",
        runningAmount: 0,
      };
    case ACTION_TYPE_CLIENT.FETCH_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
        openTransactionSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_VENDOR_CLIENT_DATA_VENDOR_DATA:
      const newVendorClient = { ...state.vendorClientData };
      newVendorClient[action.id] = action.value;
      return {
        ...state,
        vendorClientData: newVendorClient,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_DESC_DATA:
      return {
        ...state,
        loading: false,
        successMsg: "Sucessfully Updated",
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_NAME:
      return {
        ...state,
        loading: false,
        name: action.clientname,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_CLIENTNO:
      return {
        ...state,
        loading: false,
        clientNo: action.clientNo,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_CASECOUNT:
      return {
        ...state,
        loading: false,
        caseCount: action.caseCount,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_USER_STATUS:
      return {
        ...state,
        loading: false,
        userstatus: action.status,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FETCH_ALL_STATUS:
      return {
        ...state,
        loading: false,
        fetchAll: action.bool,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_DATE:
      return {
        ...state,
        loading: false,
        date: action.date,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_BALANCE:
      return {
        ...state,
        loading: false,
        balance: action.balance,
      };
    case ACTION_TYPE_CLIENT.HANDLE_CHANGE_FILTER_TERM:
      return {
        ...state,
        loading: false,
        term: action.term,
      };
    case ACTION_TYPE_CLIENT.HANDLE_EDIT_BLOCKED_TRANS_DATA:
      const newBlockedTransData = { ...state.blockedTransData };
      newBlockedTransData[action.id] = action.value;
      return {
        ...state,
        blockedTransData: newBlockedTransData,
      };
    case ACTION_TYPE_CLIENT.CLEAR_BLOCKED_TRANSACTION:
      return {
        ...state,
        blockedTransData: {
          transactionId: "",
          amount: 0,
          date: null,
        },
      };
    case ACTION_TYPE_CLIENT.CLEAR_CLIENT_LIST:
      return {
        ...state,
        client: [],
      };
    case ACTION_TYPE_CLIENT.GET_CONFIG_SUCCESS:
      return {
        ...state,
        configlist: action.config,
      };
    case ACTION_TYPE_CLIENT.GET_CONFIG_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openClientDeleteSnackBar: true,
      };
    case ACTION_TYPE_CLIENT.SAVE_CONFIG_SUCCESS:
      return {
        ...state,
        success: action.response,
        loading: false,
        // successMsg: "Successfully Set",
        // openSnackBarForSuccessMessage: true,
        openClientSnackBar: true,
        successMsg: "Successfully Saved",
      };
    case ACTION_TYPE_CLIENT.SAVE_CONFIG_FAILURE:
      return {
        ...state,
        errorMsgForClient: action.error,
        loading: false,
        openSnackBarForErrorMessage: true,
      };
    default:
      return state;
  }
};

export default clientState;
