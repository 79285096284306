import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Select, Tag } from "antd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import "./cardScreen.css";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import MySnackbarContentWrapper from "../../components/Snackbar";
import PaperComponent from "../PaperComponent";
import DialogTitleComponent from "../DialogTitleComponent";
import { HelmetComponent } from "../../components/Helmet";
import ButtonComponent from "../ButtonComponent";

const { Option } = Select;

class CardScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      popoverElement: null,
      cards: "",
      orderId: "",
      page: 0,
      rowsPerPage: 10,
      openActionMenu: false,
      openDialogueForOrderUser: false,
      openStatusUpdateDialogue: false,
      sortType: "asc",
      showDropdown: false,
      openDialogueConfirm: false,
      openActiveStatusUpdateDialogue: false,
      visible: false,
    };
  }
  componentDidMount() {
    this.props.fetchCard();
    this.props.fetchScheduleTransactionList();
    // this.props.fetchClientList();
  }

  render() {
    const cardState = this.props.state && this.props.state.cardState;
    const orderCardList = cardState && cardState.cards;

    const orderCards = this.props.state.cardState.orderCards;

    const clientState = this.props.state.clientState;
    const clients = clientState && clientState.client;

    const clientList =
      this.props.state && this.props.state.scheduleTransactionState.clientList;

    const clientId =
      this.props.state && this.props.state.scheduleTransactionState.clientId;

    const value = clientId && clientId !== "";

    const orderStatus = cardState && cardState.orderStatus;
    const cardStatus = cardState && cardState.cardStatus;
    const id = cardState && cardState.orderId;

    const handleCloseDialogueForUpdateStatus = () => {
      this.setState({
        openStatusUpdateDialogue: false,
      });
    };
    const handleOpenDialogueForUpdateStatus = () => {
      this.setState({
        openStatusUpdateDialogue: true,
      });
    };
    const handleCloseDialogueForUpdateActiveStatus = () => {
      this.setState({
        openActiveStatusUpdateDialogue: false,
      });
    };
    const handleOpenDialogueForUpdateActiveStatus = () => {
      this.setState({
        openActiveStatusUpdateDialogue: true,
      });
    };

    // DATE CONVERTER
    const getDate = (dateToConvert) => {
      let dateConverted = new Date(
        parseInt(dateToConvert)
      ).toLocaleDateString();
      return dateConverted;
    };

    // SORT LIST
    const sortedOrderCardList = orderCardList
      ? orderCardList.sort((a, b) => {
          const data = this.state.sortType === "asc" ? 1 : -1;
          return data * a.createdAt.localeCompare(b.createdAt);
        })
      : null;

    // HANDLE PAGES
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    //Dialogue
    const handleOpenDialogueForOrderCard = () => {
      this.setState(
        {
          openDialogueForOrderUser: true,
        },
        () => {
          this.props.setOrderCardDataToEmpty();
        }
      );
    };
    const handleCloseDialogueForOrderCard = () => {
      this.setState({
        openDialogueForOrderUser: false,
      });
    };

    const handleOk = (data) => {
      this.props.orderCard(data, this.props);
    };

    const handleOpenDialogue = () => {
      this.setState({
        openDialogue: true,
      });
    };
    const handleCloseDialogue = () => {
      this.setState({
        openDialogue: false,
      });
    };
    const loading = cardState && cardState.loading;

    return (
      <React.Fragment>
        <HelmetComponent title="Card" />
        {loading ? (
          <div className={loading ? "loader" : "loader hidden"} id="loader">
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {cardState && cardState.success === true
          ? this.setState(
              {
                openDialogueForOrderUser: false,
              },
              () => {
                this.props.setOrderCardSuccessToFalse();
              }
            )
          : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Card
          </Typography>
        </Breadcrumbs>
        <div className="contain">
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            <div>
              <button
                className="settingButton"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                  });
                }}
              >
                <img
                  alt={"setting"}
                  src={"assets/images/sort.png"}
                  style={{ width: "18px", height: "18px" }}
                />
              </button>
            </div>
            <div>
              <button
                className="addButton"
                onClick={() => {
                  this.props.handleChangeClientId("");
                  handleOpenDialogueForOrderCard();
                }}
              >
                <AddIcon /> Order Card
              </button>
            </div>
            <div className="search">
              <div style={style.searchIcon}>
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={style.inputBase}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value === "" || e.target.value === " ") {
                    this.props.fetchCard();
                  } else {
                    this.props.searchCard(e.target.value);
                  }
                }}
              />
            </div>
          </div>

          {/* <div style={{paddingTop: "70px"}}> */}
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="first-tableHeader">
                      Client Name
                    </TableCell>
                    <TableCell className="tableHeader">Ordered Date</TableCell>
                    <TableCell className="tableHeader">Status</TableCell>
                    <TableCell className="tableHeader"></TableCell>
                  </TableRow>
                </TableHead>
                {sortedOrderCardList &&
                  sortedOrderCardList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((card) => (
                      <TableBody key={card.orderId}>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            <button
                              style={style.btnStyle}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    cards: card,
                                  },
                                  () => {
                                    this.props.getOrderCardInfo(
                                      this.state.cards.orderId
                                    );
                                  }
                                );
                                handleOpenDialogue();
                              }}
                            >
                              {card &&
                                card.cardFirstName + " " + card.cardLastName}
                            </button>
                          </TableCell>
                          <TableCell className="tableBody">
                            {card && getDate(card.createdAt)}
                          </TableCell>
                          <TableCell className="tableBody">
                            <Tag
                              style={style.colors}
                              color={
                                card.orderStatus === "PENDING_ORDER"
                                  ? "geekblue"
                                  : card.orderStatus === "CANCELLED_ORDER"
                                  ? "magenta"
                                  : card.cardStatus === "BLOCKED_CARD"
                                  ? "volcano"
                                  : card.cardStatus === "ORDERED_CARD"
                                  ? "purple"
                                  : card.cardStatus === "ACTIVE_CARD"
                                  ? "green"
                                  : card.cardStatus === "CLEAR_CARD"
                                  ? "blue"
                                  : card.cardStatus === "FRAUD_CARD"
                                  ? "red"
                                  : card.cardStatus === "LOST_CARD"
                                  ? "orange"
                                  : card.cardStatus === "STOLEN_CARD"
                                  ? "cyan"
                                  : "lime"
                              }
                            >
                              {card.orderStatus === "PENDING_ORDER"
                                ? card.orderStatus.replace("_ORDER", " ")
                                : card.orderStatus === "COMPLETED_ORDER_STATUS"
                                ? card.orderStatus.replace("_ORDER", " ")
                                : card.cardStatus === "BLOCKED_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : card.cardStatus === "ORDERED_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : card.cardStatus === "ACTIVE_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : card.cardStatus === "CLEAR_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : card.cardStatus === "FRAUD_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : card.cardStatus === "CLEAR_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : card.cardStatus === "FRAUD_CARD"
                                ? card.cardStatus.replace("_CARD", " ")
                                : ""}
                            </Tag>
                          </TableCell>
                          <TableCell className="tableBody">
                            {card.orderStatus === "CANCELLED_ORDER_STATUS" ? (
                              <MoreHorizIcon style={{ display: "none" }} />
                            ) : (
                              <MoreHorizIcon
                                id="Popover1"
                                style={{ cursor: "pointer", display: "none" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    popoverElement: e.currentTarget,
                                    openActionMenu: true,
                                    cards: card,
                                  });
                                  if (
                                    card.orderStatus ===
                                      "PENDING_ORDER_STATUS" ||
                                    card.orderStatus ===
                                      "CANCELLED_ORDER_STATUS"
                                  ) {
                                    this.props.handleChangeOrderStatus(
                                      card.orderStatus,
                                      card.orderId
                                    );
                                  } else if (
                                    (card.orderStatus ===
                                      "COMPLETED_ORDER_STATUS" &&
                                      card.cardStatus === "ORDERED_CARD") ||
                                    (card.orderStatus ===
                                      "COMPLETED_ORDER_STATUS" &&
                                      card.cardStatus === "ACTIVE_CARD") ||
                                    (card.orderStatus ===
                                      "COMPLETED_ORDER_STATUS" &&
                                      card.cardStatus === "FREEZE_CARD")
                                  ) {
                                    this.props.handleChangeOrderStatus(
                                      card.orderStatus,
                                      card.orderId
                                    );
                                    this.props.handleChangeCardStatus(
                                      card.cardStatus,
                                      card.orderId
                                    );
                                  } else {
                                    return null;
                                  }
                                }}
                              />
                            )}
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                {orderStatus === "PENDING_ORDER_STATUS" ? (
                                  <MenuItem
                                    onClick={() => {
                                      handleOpenDialogueForUpdateStatus();
                                      this.setState({ openActionMenu: false });
                                    }}
                                  >
                                    <CancelOutlinedIcon
                                      style={{ paddingBottom: 0 }}
                                    />
                                    Cancel Order
                                  </MenuItem>
                                ) : orderStatus === "CANCELLED_ORDER_STATUS" ? (
                                  ""
                                ) : cardStatus === "ORDERED_CARD" ? (
                                  <MenuItem
                                    onClick={() => {
                                      this.setState({ openActionMenu: false });
                                      handleOpenDialogueForUpdateActiveStatus();
                                    }}
                                  >
                                    Active
                                  </MenuItem>
                                ) : cardStatus === "ACTIVE_CARD" ? (
                                  <MenuItem
                                    onClick={() => {
                                      const statusData = {
                                        orderId: id,
                                        cardStatus: "FREEZE_CARD",
                                      };
                                      this.setState({ openActionMenu: false });
                                      this.props.updateCardStatus(
                                        statusData,
                                        this.props
                                      );
                                    }}
                                  >
                                    Freeze
                                  </MenuItem>
                                ) : cardStatus === "FREEZE_CARD" ? (
                                  <MenuItem
                                    onClick={() => {
                                      const statusData = {
                                        orderId: id,
                                        cardStatus: "ACTIVE_CARD",
                                      };
                                      this.setState({ openActionMenu: false });
                                      this.props.updateCardStatus(
                                        statusData,
                                        this.props
                                      );
                                    }}
                                  >
                                    Active
                                  </MenuItem>
                                ) : (
                                  ""
                                )}
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {sortedOrderCardList && sortedOrderCardList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedOrderCardList.length > 0
                      ? sortedOrderCardList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
          {/* </div> */}
        </div>

        {/*Order Card*/}
        <Dialog
          open={this.state.openDialogueForOrderUser}
          onClose={handleCloseDialogueForOrderCard}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForOrderCard}
          >
            Order Card
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              paddingLeft: "1.5rem !important",
              paddingRight: "1.5rem !important",
              width: "450px",
            }}
          >
            <label style={style.label}>Client Name</label>
            <div className="select-input h40">
              <Select
                showSearch
                className="drop"
                getPopupContainer={(node) => node.parentNode}
                style={{ width: 200, height: "40px" }}
                onSearch={(value) => {
                  this.props.searchClientList(value);
                }}
                onInputKeyDown={() => {
                  this.setState({
                    showDropdown: true,
                  });
                }}
                onChange={(e) => {
                  this.props.handleChangeClientId(e);
                }}
                onSelect={() => {
                  this.setState({
                    showDropdown: false,
                  });
                }}
                onBlur={() => {
                  this.setState({
                    showDropdown: false,
                  });
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                defaultOpen={false}
                dropdownStyle={
                  this.state.showDropdown
                    ? { display: "block", maxHeight: "70px" }
                    : { display: "none" }
                }
              >
                {clientList &&
                  clientList.map((res) => (
                    <Option key={res.clientId} value={res.clientId}>
                      {res.firstName +
                        " " +
                        res.lastName +
                        " (" +
                        res.ssn +
                        " )"}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="row" style={{ paddingTop: "30px" }}>
              <div className={"col-6"} />
              <div className={"col-6"} style={{ paddingLeft: "92px" }}>
                <ButtonComponent
                  value={value}
                  loading={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    const data = {
                      client: {
                        clientId: clientId,
                      },
                    };
                    // handleCloseDialogueForOrderCard();
                    handleOk(data);
                  }}
                  buttonName="Order Card"
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*order status update*/}
        <Dialog
          open={this.state.openStatusUpdateDialogue}
          onClose={handleCloseDialogueForUpdateStatus}
          aria-labelledby="draggable-dialog-title"
          PaperComponent={PaperComponent}
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to cancel order status?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForUpdateStatus}
                    // style={style.cancelButton}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        orderId: id,
                        orderStatus: "CANCELLED_ORDER_STATUS",
                      };
                      this.setState({ openStatusUpdateDialogue: false });
                      this.props.updateOrderStatus(statusData, this.props);
                    }}
                    // style={style.deleteClientButton}
                    className="deleteClientButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.openActiveStatusUpdateDialogue}
          onClose={handleCloseDialogueForUpdateActiveStatus}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title">
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to active card status?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForUpdateActiveStatus}
                    // style={style.cancelButton}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      const statusData = {
                        orderId: id,
                        cardStatus: "ACTIVE_CARD",
                      };
                      this.props.updateCardStatus(statusData, this.props);
                      this.setState({ openActiveStatusUpdateDialogue: false });
                    }}
                    // style={style.deleteClientButton}
                    className="deleteClientButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/*card details*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openDialogue}
          onClose={handleCloseDialogue}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogue}
          >
            Order Card Details
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              paddingTop: "25px",
              paddingBottom: "25px",
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-6">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Card Name
                  </label>
                  <label style={style.clientData}>
                    {orderCards &&
                      orderCards.cardFirstName + " " + orderCards.cardLastName}
                  </label>
                </span>
                <span className="col-6">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Order Status
                  </label>
                  <label style={style.clientData}>
                    {orderCards && orderCards.orderStatus
                      ? orderCards.orderStatus.replace("_", " ")
                      : null}
                  </label>
                </span>
              </span>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                {orderCards && orderCards.cardStatus ? (
                  <span className="col-6" style={{ position: "initial" }}>
                    <label htmlFor="gender" style={style.columnHeading}>
                      Card Status
                    </label>
                    <label style={style.clientData}>
                      {orderCards && orderCards.cardStatus
                        ? orderCards.cardStatus.replace("_", " ")
                        : null}
                    </label>
                  </span>
                ) : (
                  <span></span>
                )}
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={cardState && cardState.openCardErrorSnackBar}
          autoHideDuration={4000}
          onClose={this.props.closeCardSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeCardSnackBar}
            variant="error"
            message={cardState && cardState.errorMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={cardState && cardState.openCardSuccessSnackBar}
          autoHideDuration={4000}
          onClose={this.props.closeCardSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeCardSnackBar}
            variant="success"
            message={cardState && cardState.successMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  btnStyle: {
    border: "none",
    background: "none",
    color: "#626262",
    padding: 0,
    cursor: "pointer",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    // position: "fixed",
    // width: "100%",
    // background: "white",
    // zIndex: 1,
    // right: "2.5%",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    // paddingLeft:'2.3rem',
    width: "270px",
    // heigth: '26px'
  },

  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default CardScreen;
