import RootReducer from "../reducers/RootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

const loggerMiddleware = createLogger();
const initialState = {};
const middleware = [thunk, loggerMiddleware];
const store = createStore(
    RootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware)
    )
);

export default store;
