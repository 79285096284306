import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Select } from "antd";
import MySnackbarContentWrapper from "../components/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
const { Option } = Select;

class VendorClientScreen extends Component {
  constructor(props) {
    const pathname = window.location.pathname.split("/");
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openAddClientModal: false,
      openEditClientModal: false,
      openDeleteModal: false,
      showDropdown: false,
      openActionMenu: false,
      popoverElement: null,
      page: 0,
      rowsPerPage: 10,
      vendorId: pathname[2],
      vendorIds: pathname[3],
      vendorClient: {},
      clicked: false,
      setValue: "",
      errors: {
        number: "",
      },
      clientId: pathname[3],
      clientIds: pathname[2],
    };
  }
  componentDidMount() {
    {
      this.props.location.pathname.includes("/client/details/") &&
        this.props.fetchVendor(); // for showing vendor list data on client page
    }
    {
      this.props.location.pathname.includes("/client/details/") &&
        this.props.fetchVendorClientById &&
        this.props.fetchVendorClientById(this.state.clientId);
    }
    {
      this.props.location.pathname.includes("/client/details/") &&
        this.props.fetchVendorClient &&
        this.props.fetchVendorClient(this.state.clientId); //for showing the data which we add through Add Vendor
    }
    {
      !this.props.location.pathname.includes("/client/details/") &&
        this.props.fetchVendorClient &&
        this.props.fetchVendorClient(this.state.vendorId); //for showing the data which we add through Add Client
    }
    {
      !this.props.location.pathname.includes("/client/details/") &&
        this.props.getVendorInfo &&
        this.props.getVendorInfo(this.state.vendorId); //for vendor account details box information
    }
  }

  render() {
    const vendorState = this.props.state && this.props.state.vendorState;
    const vendors = this.props.state.vendorState.vendors.vendorAccount;
    const vendorClientData = this.props.state.vendorState.vendorClientData;
    const vendorClientList = this.props.state.vendorState.vendorClientList;
    const success = this.props.state.vendorState.success;
    const clientState = this.props.state.scheduleTransactionState;
    const clients = clientState && clientState.clientList;
    const loading = vendorState && vendorState.loading;
    const { errors } = this.state;
    const vendorList = this.props.state.vendorState.vendorAccount;
    const clientId = this.props.match.params.clientId;
    const clientVendor = this.props.state.vendorState.clientVendor;
    const vendorDataList = this.props.state.vendorState.vendorAccount;

    const value =
      vendorClientData.clientId !== "" &&
      vendorClientData.clientRefId !== "" &&
      vendorClientData.vendorId !== "";

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    //handles to open modal
    const handleOpenAddAddClientModal = () => {
      this.setState({
        openAddClientModal: true,
        errors: {
          number: "",
        },
      });
      this.props.handleChangeVendorClient &&
        this.props.handleChangeVendorClient("vendorId", "");
      this.props.handleChangeVendorClient &&
        this.props.handleChangeVendorClient("clientId", "");
      this.props.handleChangeVendorClient &&
        this.props.handleChangeVendorClient("clientRefId", "");
    };
    const handleCloseAddAddClientModal = () => {
      this.setState({
        openAddClientModal: false,
        errors: {
          number: "",
        },
      });
    };
    const handleOpenEditClientModal = () => {
      this.setState({
        openEditClientModal: true,
        errors: {
          number: "",
        },
      });
    };
    const handleCloseEditClientModal = () => {
      this.setState({
        openEditClientModal: false,
        errors: {
          number: "",
        },
      });
    };
    const handleOpenDeleteClientModal = () => {
      this.setState({
        openDeleteModal: true,
      });
    };
    const handleCloseDeleteClientModal = () => {
      this.setState({
        openDeleteModal: false,
      });
    };

    const handleDeleteVendorClient = () => {
      const vendorClientId = this.state.vendorClient.vendorClientId;
      const vendorId = this.state.vendorClient.vendorId;
      const clientId = this.state.vendorClient.clientId;
      this.props.deleteVendorClient &&
        this.props.deleteVendorClient(
          clientId,
          vendorClientId,
          vendorId,
          this.props
        );
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Vendor Client" />
        {this.props.state.vendorState.loading ? (
          <div
            className={
              this.props.state.vendorState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {success && success === true
          ? this.setState(
              {
                openAddClientModal: false,
                openEditClientModal: false,
              },
              () => {
                if (this.state.setValue === "add") {
                  this.props.setAddVendorClientSuccessToFalse();
                } else {
                  this.props.setUpdateVendorClientSuccessToFalse();
                }
              }
            )
          : null}
        {!this.props.location.pathname.includes("/client/details/") && (
          <Breadcrumbs aria-label="breadcrumb" className="bc">
            <Link color="inherit" to="/dashboard" className="links">
              <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
              Home
            </Link>
            <Link color="inherit" to="/vendor" className="links">
              Vendor Account
            </Link>
            <Typography color="inherit" className="link">
              Vendor Clients
            </Typography>
          </Breadcrumbs>
        )}

        {!this.props.location.pathname.includes("/client/details/") && (
          <div style={{ margin: "80px 0 0" }}>
            <div style={style.containerClient}>
              <div className="row" style={style.header}>
                <h5 style={style.headerText}> Vendor Account Detail </h5>
              </div>
              <div className="row" style={style.row}>
                <div className="column" style={style.column1}>
                  <div className="form-group">
                    <label style={style.columnHeading}>Vendor Name</label>
                    <label style={style.span}>
                      {vendors && vendors.vendorName}
                    </label>
                  </div>
                  <div className="form-group" style={style.formGroupIcon}>
                    <label style={style.columnHeading}>
                      Account Holder Name
                    </label>
                    <label style={style.span}>
                      {vendors && vendors.accountName
                        ? vendors.accountName
                        : "-"}
                    </label>
                  </div>
                  {/*<div className="form-group" style={style.formGroupIcon}>*/}
                  {/*    <label htmlFor="lastName" style={style.columnHeading}>Medium</label>*/}
                  {/*    <label style={style.span}>*/}
                  {/*        {vendors && vendors.transactionMedium}*/}
                  {/*    </label>*/}
                  {/*</div>*/}
                </div>
                <div className="column" style={style.column2}>
                  <div className="form-group">
                    <label style={style.columnHeading}>Vendor Type</label>
                    <label style={style.span}>
                      {vendors && vendors.vendorType !== undefined
                        ? vendors.vendorType &&
                          vendors.vendorType.vendorTypeName.replace("_", " ")
                        : "-"}
                    </label>
                  </div>
                  <div className="form-group" style={style.formGroupIcon}>
                    <label htmlFor="gender" style={style.columnHeading}>
                      Contact Number
                    </label>
                    <label style={style.span}>
                      {vendors && vendors.vendorContactNumber
                        ? vendors.vendorContactNumber
                        : "-"}
                    </label>
                  </div>
                </div>
                <div className="column" style={style.column3}>
                  <div className="form-group">
                    <label style={style.columnHeading}>
                      Bank Account Number
                    </label>
                    <label style={style.span}>
                      {vendors && vendors.accountNumber
                        ? vendors.accountNumber
                        : "-"}
                    </label>
                  </div>
                  <div className="form-group" style={style.formGroupIcon}>
                    <label htmlFor="lastName" style={style.columnHeading}>
                      Routing Number
                    </label>
                    <label style={style.span}>
                      {vendors && vendors.routingNumber
                        ? vendors.routingNumber
                        : "-"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ margin: "0 5% 3%" }}>
          <div className={"d-flex flex-row-reverse div-flex"}>
            <button
              className="addButton"
              style={{ width: "140px" }}
              onClick={(e) => {
                e.preventDefault();
                handleOpenAddAddClientModal();
                // {
                //     this.props.location.pathname.includes('/client/details/') &&
                //         this.props.fetchVendorClientById(clientId)
                // };
              }}
            >
              <AddIcon />{" "}
              {this.props.location.pathname.includes("/client/details/")
                ? "Add Vendor"
                : "Add Client"}
            </button>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header-second">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell
                      className="first-tableHeader"
                      style={{ paddingLeft: "60px" }}
                    >
                      {this.props.location.pathname.includes("/client/details/")
                        ? "Vendor Name"
                        : "Client Name"}
                    </TableCell>
                    <TableCell className="tableHeader">
                      Account Number
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {this.props.location.pathname.includes("/client/details/") &&
                  clientVendor &&
                  clientVendor
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            {list.vendorAccount &&
                              list.vendorAccount.vendorName}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.clientRefId}
                          </TableCell>
                          <TableCell>
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  openActionMenu: true,
                                  vendorClient: list,
                                });
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenEditClientModal();
                                    this.props.setVendorToEdit &&
                                      this.props.setVendorToEdit(
                                        this.state.vendorClient
                                      );
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDeleteClientModal();
                                    // this.props.setVendorToEdit(this.state.vendorClient)
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                {!this.props.location.pathname.includes("/client/details/") &&
                  vendorClientList &&
                  vendorClientList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            {list.client &&
                              list.client.firstName +
                                " " +
                                list.client.lastName}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.clientRefId}
                          </TableCell>
                          <TableCell>
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  openActionMenu: true,
                                  vendorClient: list,
                                });
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenEditClientModal();
                                    this.props.setVendorToEdit &&
                                      this.props.setVendorToEdit(
                                        this.state.vendorClient
                                      );
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDeleteClientModal();
                                    // this.props.setVendorToEdit(this.state.vendorClient)
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {this.props.location.pathname.includes("/client/details/") &&
              clientVendor &&
              clientVendor.length > 0 && (
                <div
                  style={{
                    justifyContent: "flex-start",
                    width: "100%",
                    borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={clientVendor.length > 0 ? clientVendor.length : 100}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              )}
            {!this.props.location.pathname.includes("/client/details/") &&
              vendorClientList &&
              vendorClientList.length > 0 && (
                <div
                  style={{
                    justifyContent: "flex-start",
                    width: "100%",
                    borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={
                      vendorClientList.length > 0
                        ? vendorClientList.length
                        : 100
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              )}

            {/* </TableContainer> */}
          </div>
        </div>
        {/********* Add Vendor Client *******/}
        <Dialog
          open={this.state.openAddClientModal}
          onClose={handleCloseAddAddClientModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseAddAddClientModal}
            >
              {this.props.location.pathname.includes("/client/details/")
                ? "Add Vendor"
                : "Add Client"}
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContent"
            >
              <label className="label">
                {this.props.location.pathname.includes("/client/details/")
                  ? "Vendor Name"
                  : "Client Name"}
                <span style={{ color: "red" }}> *</span>
              </label>
              <div className="select-input select h40">
                <Select
                  showSearch
                  className="drop"
                  getPopupContainer={(node) => node.parentNode}
                  style={{ width: 200 }}
                  onSearch={(value) => {
                    this.props.searchClientList &&
                      this.props.searchClientList(value);
                  }}
                  onInputKeyDown={() => {
                    this.setState({
                      showDropdown: true,
                    });
                  }}
                  onChange={(e) => {
                    {
                      this.props.location.pathname.includes(
                        "/client/details/"
                      ) &&
                        this.props.handleChangeVendorClient &&
                        this.props.handleChangeVendorClient("vendorId", e);
                    }
                    {
                      !this.props.location.pathname.includes(
                        "/client/details/"
                      ) &&
                        this.props.handleChangeVendorClient &&
                        this.props.handleChangeVendorClient("clientId", e);
                    }
                  }}
                  onSelect={() => {
                    this.setState({
                      showDropdown: false,
                    });
                  }}
                  onBlur={() => {
                    this.setState({
                      showDropdown: false,
                    });
                  }}
                  filterOption={(input, option) =>
                    option.children &&
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultOpen={false}
                  dropdownStyle={
                    this.state.showDropdown
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {this.props.location.pathname.includes("/client/details/") &&
                    vendorDataList &&
                    vendorDataList.map((res) => (
                      <Option key={res.vendorId} value={res.vendorId}>
                        {res && res.vendorName}
                      </Option>
                    ))}

                  {!this.props.location.pathname.includes("/client/details/") &&
                    clients &&
                    clients.map((res) => (
                      <Option key={res.clientId} value={res.clientId}>
                        {res.firstName +
                          " " +
                          res.lastName +
                          " (" +
                          res.clientNo +
                          " )"}
                      </Option>
                    ))}
                </Select>
              </div>
              <label className="label">
                Account Number
                <span style={{ color: "red" }}> *</span>
              </label>
              <input
                type="text"
                id="clientRefId"
                className="input"
                autoComplete="off"
                onChange={(e) => {
                  Helpers.handleValidationOnChange(e, errors);
                  {
                    this.props.location.pathname.includes("/client/details/") &&
                      this.props.handleChangeVendorClient &&
                      this.props.handleChangeVendorClient(
                        "clientId",
                        this.state.clientId
                      );
                    this.props.handleChangeVendorClient &&
                      this.props.handleChangeVendorClient(
                        e.target.id,
                        e.target.value
                      );
                  }
                  {
                    !this.props.location.pathname.includes(
                      "/client/details/"
                    ) &&
                      this.props.handleChangeVendorClient &&
                      this.props.handleChangeVendorClient(
                        "vendorId",
                        this.state.vendorId
                      );
                    this.props.handleChangeVendorClient &&
                      this.props.handleChangeVendorClient(
                        e.target.id,
                        e.target.value
                      );
                  }
                }}
              />
              {errors.number.length > 0 && (
                <span className="error">{errors.number}</span>
              )}
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "8px" }}>
                  <Button
                    style={{ float: "right" }}
                    disabled={!value || loading}
                    className={
                      value && !loading ? "addButton" : "disabledAddButton"
                    }
                    onClick={() => {
                      if (!this.state.clicked) {
                        this.setState({
                          clicked: true,
                        });
                        this.props.addVendorClient(
                          vendorClientData,
                          this.props
                        );
                        setTimeout(() => {
                          this.setState({
                            clicked: false,
                            setValue: "add",
                          });
                        }, 3000);
                      }
                      // handleCloseAddAddClientModal();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/********* Edit Vendor Client *******/}
        <Dialog
          open={this.state.openEditClientModal}
          onClose={handleCloseEditClientModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseEditClientModal}
            >
              Update Vendor
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px" }}
              className="dialogContent"
            >
              <label className="label">Vendor Name</label>
              <div className="select-input h40">
                <Select
                  showSearch
                  getPopupContainer={(node) => node.parentNode}
                  style={{ width: 200 }}
                  onSearch={(value) => {
                    this.props.searchClientList &&
                      this.props.searchClientList(value);
                  }}
                  value={
                    this.props.location.pathname.includes("/client/details/")
                      ? vendorClientData &&
                        vendorClientData.vendorAccount &&
                        vendorClientData.vendorAccount.vendorName
                      : vendorClientData &&
                        vendorClientData.client &&
                        vendorClientData.client.firstName
                  }
                  disabled
                  onInputKeyDown={() => {
                    this.setState({
                      showDropdown: true,
                    });
                  }}
                  onChange={(e) => {
                    this.props.handleChangeVendorClient &&
                      this.props.handleChangeVendorClient("clientId", e);
                  }}
                  onSelect={() => {
                    this.setState({
                      showDropdown: false,
                    });
                  }}
                  onBlur={() => {
                    this.setState({
                      showDropdown: false,
                    });
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultOpen={false}
                  dropdownStyle={
                    this.state.showDropdown
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {!this.props.location.pathname.includes("/client/details/") &&
                    clients.map((res) =>
                      res.isEnableTransaction ? (
                        <>
                          {clients &&
                            clients.map((res) => (
                              <Option key={res.clientId} value={res.clientId}>
                                {res.firstName +
                                  " " +
                                  res.lastName +
                                  " (" +
                                  res.clientNo +
                                  " )"}
                              </Option>
                            ))}
                        </>
                      ) : null
                    )}
                  {this.props.location.pathname.includes("/client/details/") &&
                    vendorDataList.map((res) =>
                      res.isEnableTransaction ? (
                        <>
                          {vendorDataList &&
                            vendorDataList.map((res) => (
                              <Option key={res.vendorId} value={res.vendorId}>
                                {res.vendorName}
                              </Option>
                            ))}
                        </>
                      ) : null
                    )}
                </Select>
              </div>
              <label className="label">
                Account Number
                <span style={{ color: "red" }}> *</span>
              </label>
              <input
                type="text"
                id="clientRefId"
                className="input"
                value={vendorClientData && vendorClientData.clientRefId}
                autoComplete="off"
                onChange={(e) => {
                  Helpers.handleValidationOnChange(e, errors);
                  this.props.handleChangeVendorClient &&
                    this.props.handleChangeVendorClient(
                      e.target.id,
                      e.target.value
                    );
                }}
              />
              {errors.number.length > 0 && (
                <span className="error">{errors.number}</span>
              )}
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "8px" }}>
                  <Button
                    style={{ float: "right" }}
                    disabled={!value}
                    className={value ? "addButton" : "disabledAddButton"}
                    onClick={() => {
                      this.props.editVendorClient(vendorClientData, this.props);
                      // handleCloseEditClientModal();
                    }}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/******** Delete Vendor Client ********/}
        <Dialog
          open={this.state.openDeleteModal}
          onClose={handleCloseDeleteClientModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent className="deleteDialogueContent">
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete client?
                <div
                  className="row"
                  style={{ padding: "1.6rem 10px 1rem 10px" }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteClientModal}
                      className="cancelButton"
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseDeleteClientModal();
                        handleDeleteVendorClient();
                      }}
                      className="deleteOkButton"
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.vendorState.openVendorErrorSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleCloseVendorSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="error"
            message={this.props.state.vendorState.errorMsgForVendor}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.vendorState.openVendorSuccessSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleCloseVendorSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleCloseVendorSnackBar}
            variant="success"
            message={this.props.state.vendorState.successMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginBottom: "1rem",
  },
  header: {
    borderBottom: "1px solid #E9E9E9",
    marginLeft: "2px",
    marginRight: "2px",
  },
  headerText: {
    color: "#cc6600",
    fontSize: "22px",
    fontWeight: "bolder",
    paddingTop: "10px",
    marginLeft: "2%",
    paddingBottom: "10px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "25px",
    paddingLeft: "40px",
    // paddingBottom: '25px',
    position: "inherit",
  },
  column1: {
    flex: "33%",
    padding: "0 6px",
  },
  column2: {
    flex: "34%",
    padding: "0 6px",
    position: "inherit",
  },
  column3: {
    flex: "33%",
    position: "inherit",
  },
  formGroupIcon: {
    marginTop: "20px",
    position: "relative",
    display: "block",
  },
  columnHeading: {
    color: "#6b6868",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    position: "inherit",
  },
  span: {
    color: "rgb(99,97,92)",
    fontSize: "14x",
    height: "40px",
    width: "90%",
    paddingTop: "0.4rem",
    fontWeight: 550,
  },
  containerClient: {
    background: "#ffffff",
    marginTop: "38px",
    marginLeft: "2%",
    marginRight: "3%",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    position: "inherit",
    borderBottom: "1px solid #8080804f",
  },
};
export default VendorClientScreen;
