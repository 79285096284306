import { ACTION_TYPE_REGISTER_CASE } from "../constants/Type";

const initialState = {
    loading: false,
    openRegisterCaseSnackBarForClient: false,
    errorMsgForRegisterCase: '',
    openRegisterCaseSnackBar:false,
    openRegisterCaseSnackBarForCase: false,
    addClientSuccessfull: false,
    addClientSuccess: false,
    registerCaseSuccessfully:false,
    clientData: {
        ssn: "",
        firstName: "",
        lastName: "",
        gender: '',
        dob: null,
        isEnableTransaction: false,
        maritalStatus: '',
        contacts:[]
    },
    caseData: {
        // clientId:'',
        // addedBy:'',
        // caseTemplateId:''
    },
    clientCaseContact:[],
    searchedClient:[],
    success:"",
    successMsg:"",
    openClientSuccessSnackBar: false,
    openClientErrorSnackBar: false,
};

const registerCaseState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_REGISTER_CASE.REGISTER_CASE_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_REGISTER_CASE.CLIENT_FETCH_SUCCESS:
            return {
                ...state,
                client: action.client.clients,
                loading: false,
                openRegisterCaseSnackBar: false,
            };
        case ACTION_TYPE_REGISTER_CASE.CLIENT_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForRegisterCase: action.error,
                loading: false,
                openRegisterCaseSnackBar: true,
                openRegisterCaseSnackBarForCase: false,
            };
        case ACTION_TYPE_REGISTER_CASE.HANDLE_CHANGE_CLIENT_IN_REGISTER_CASE:
            const newClientForm = {...state.clientData};
            newClientForm[action.id] = action.value;
            return {
                ...state,
                clientData: newClientForm,
                loading: false
            };
        case ACTION_TYPE_REGISTER_CASE.CLEAR_CLIENT_FORM_DATA:
            return {
                ...state,
                clientData: {
                    ssn: "",
                    firstName: "",
                    lastName: "",
                    gender: '',
                    dob: null,
                    maritalStatus: '',
                },
            }
        case ACTION_TYPE_REGISTER_CASE.CLIENT_ADD_SUCCESS:
            if (action.response !== ""){
                return {
                    ...state,
                    success: action.response,
                    addClientSuccessfull: true,
                    clientData: action.response,
                    loading: false,
                    openRegisterCaseSnackBarForClient: false,
                    openRegisterCaseSnackBarForCase: false,
                };
            }else{
                return {
                    ...state,
                    addClientSuccess: action.response,
                    clientData: {
                        ssn: "",
                        firstName: "",
                        lastName: "",
                        gender: "",
                        dob: null,
                        maritalStatus: ""
                    },
                    loading: false,
                    openRegisterCaseSnackBarForClient: false,
                    openRegisterCaseSnackBarForCase: false,
                };
            }
        case ACTION_TYPE_REGISTER_CASE.ADD_CLIENT_FORM_DATA_SUCCESS:
            return {
                ...state,
                success: action.response,
                clientData: {},
                openClientSuccessSnackBar: true,
                successMsg:"Successfully added"
            }
        case ACTION_TYPE_REGISTER_CASE.ADD_CLIENT_FORM_DATA_FAILURE:
            return {
                ...state,
                errorMsgForRegisterCase: action.error,
                openRegisterCaseSnackBarForCase: false,
                openClientErrorSnackBar: true,
            }
        case ACTION_TYPE_REGISTER_CASE.SET_ADD_SUCESS_FALSE:
            return {
                ...state,
                addClientSuccessfull: action.error,
                loading: false,
                openRegisterCaseSnackBar: false,
                openRegisterCaseSnackBarForCase: false,
            };
        case ACTION_TYPE_REGISTER_CASE.CLIENT_ADD_FAILURE:
            return {
                ...state,
                errorMsgForRegisterCase: action.error,
                loading: false,
                openRegisterCaseSnackBarForClient: true,
                openRegisterCaseSnackBarForCase: false,
            };
        case ACTION_TYPE_REGISTER_CASE.HANDLE_CHANGE_CASE_IN_REGISTER_CASE:
            const newCase = { ...state.caseData };
            newCase[action.id] = action.value;
            return {
                ...state,
                caseData: newCase,
                loading: false
            };
        case ACTION_TYPE_REGISTER_CASE.REGISTER_CASE_SUCCESS:
            return {
                ...state,
                registerCaseSuccessfully:action.bool,
                registerCaseSuccess: action.bool,
                loading: false,
                openRegisterCaseSnackBarForCase: false
            };
        case ACTION_TYPE_REGISTER_CASE.REGISTER_CASE_FAILURE:
            return {
                ...state,
                errorMsgForRegisterCase: action.error,
                loading: false,
                openRegisterCaseSnackBarForCase: true
            };
        case ACTION_TYPE_REGISTER_CASE.SEARCH_CLIENT_BY_SSN_SUCCESS:
            return {
                ...state,
                searchedClient: action.client.clients,
                loading: false,
                openRegisterCaseSnackBar: false,
                openRegisterCaseSnackBarForCase: false,
            };

        case ACTION_TYPE_REGISTER_CASE.SEARCH_CLIENT_BY_SSN_FAILURE:
            return {
                ...state,
                errorMsgForRegisterCase: action.error,
                loading: false,
                searchedClient: null,
                openRegisterCaseSnackBarForClient: false,
                openRegisterCaseSnackBarForCase: false,
            };
        case ACTION_TYPE_REGISTER_CASE.CLOSE_SNACK_BAR_REGISTER_CASE_PAGE:
            return {
                ...state,
                openRegisterCaseSnackBarForClient:false,
                openRegisterCaseSnackBar: false,
                openRegisterCaseSnackBarForCase: false,
                openClientSuccessSnackBar: false,
                openClientErrorSnackBar: false,
            };
        case ACTION_TYPE_REGISTER_CASE.FETCH_CLIENT_CASE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                clientCaseContact: action.clientCaseContact.caseContacts,
            };
        case ACTION_TYPE_REGISTER_CASE.FETCH_CLIENT_CASE_CONTACT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsgForRegisterCase: action.error,
            };
        default:
            return state;
    }
};

export default registerCaseState;


