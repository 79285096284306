import React from 'react';
import {Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "./Snackbar";

const SnackBarComponent = ({variant, onClose, open, message}) => {
    return(
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={open}
            autoHideDuration={5000}
            onClose={onClose}
        >
            <MySnackbarContentWrapper
                onClose={onClose}
                variant={variant}
                message={message}
            />
        </Snackbar>
    )
};

export default SnackBarComponent;