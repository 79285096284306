class LocalDb {
    
    constructor() {
        this.sessionKey = "everFundSession";
        this.currentRoute = "clientId";
    }

    getSessions() {
        const itemGot = localStorage.getItem(this.sessionKey);
        if (itemGot !== null) {
            return (JSON.parse(itemGot));
        } else {
            return null;
        }
    }
    setSession(session, callback) {
        try {
            localStorage.setItem(this.sessionKey, JSON.stringify(session), (error) => callback(error));
            // localStorage.setItem("session", JSON.stringify(session), (error) => callback(error));
            localStorage.removeItem(this.currentRoute)
            callback(false);
        } catch (error) {
            console.log("Error while setting session.", error);
        }
    }
    removeSession(callback) {
        try {
            localStorage.removeItem(this.sessionKey, (error) => callback(error));
        } catch (error) {
            console.log("Error while removing session.", error);
        }
    }

    //not been used
    saveInLocalDB(key,dataToSave,callback) {
        try {
            localStorage.setItem(key, JSON.stringify(dataToSave), (error) => callback(error));
            callback(false);
        } catch (error) {
            console.log("Error while setting session.", error);
        }
    }

    //not been used
    getSavedItemFromDB(key){
        const itemGot = localStorage.getItem(key);
        if (itemGot !==null) {
            return (JSON.parse(itemGot));
        } else {
            return null;
        }
    }
    getAccountType(){
        const userData = localStorage.getItem(this.sessionKey) ? JSON.parse(localStorage.getItem(this.sessionKey)) : undefined
        // console.log(userData);
        return userData && userData.loginResponse && userData.loginResponse.loginUser && userData.loginResponse.loginUser.corporate && userData.loginResponse.loginUser.corporate.accountType;
    }

    getClinetId(){
        let clientId = localStorage.getItem(this.currentRoute);
        // console.log(split[3], "this is the real id");
        return clientId && clientId
    }
    isLogin = () => {
        return !!localStorage.getItem(this.sessionKey);
    }
}
export default new LocalDb();