import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  CircularProgress,
  // Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
// import MySnackbarContentWrapper from "../components/Snackbar";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import HomeIcon from "@material-ui/icons/Home";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import { lighten, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";
import { LocalDb } from "../api";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import { Select } from "antd";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import DialogTitleComponent from "./DialogTitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import Helpers from "../components/Helper";
import OpenContactFields from "../components/DynamicContactField";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import SnackBarComponent from "../components/SnackBarComponenet";

const { Option } = Select;
class CaseScreen extends Component {
  componentDidMount() {
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;
    const isClient = userType === "CLIENT_USER";
    if (!isClient) {
      this.props.fetchClientList();
      this.props.fetchUserList();
    }
    this.props.fetchCaseList();
    this.props.fetchCaseTemplateList();
  }
  componentWillUnmount() {
    this.props.handleSnackBarClose();
  }
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    // this.firstRowRef = React.createRef();
    this.state = {
      openActionMenu: false,
      popoverElement: null,
      page: 0,
      rowsPerPage: 10,
      sortType: "asc",
      openDeleteCaseDialogue: false,
      case: "",
      caseId: "",
      modal2Visible: false,
      openDialogueToRegisterCase: false,
      activeStep: 0,
      showDropdown: false,
      caseTemplate: [],
      filteredWorkFlow: [],
      agent: [],
      allAgentsAssigned: [],
      openDialogueToAddClient: false,
      contacts: [],
      openDeleteContactDialogue: false,
      disableButton: true,
      addedClient: false,
      selected: [],
      openModalToFilterCase: false,
      //for validation
      errors: {
        clientId: "",
        ssn: "",
        firstName: "",
        lastName: "",
        dob: "",
        gender: "",
        maritalStatus: "",
      },
    };
  }
  render() {
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB.loginResponse.loginUser.corporate
        ? "CORPORATE_USER"
        : "CLIENT_USER"
      : null;
    const userRole =
      userType === "EVERFUND_USER"
        ? LocalDb.getSessions().loginResponse.loginUser.user.userRole
        : null;

    const clientState = this.props.state.corporateClientState.clientData.client;
    const caseState = this.props.state.caseState;
    const caseList = caseState && caseState.case;

    // pagination
    const nextUrl = caseState.nextUrl;
    const totalCaseCount = caseState.totalCaseCount;
    const count = caseState.count;
    // const clientList =  this.props.state.clientState
    //     && this.props.state.clientState.client;
    const clientSearchList =
      this.props.state.scheduleTransactionState &&
      this.props.state.scheduleTransactionState.clientList;
    const clientId =
      this.props.state.clientState && this.props.state.clientState.clientNameId;
    const addedClientNameId =
      this.props.state.clientState &&
      this.props.state.clientState.addedClientNameId;

    const clientResData =
      this.props.state.clientState && this.props.state.clientState.clientData;

    const sortedCaseList = caseList
      ? caseList.sort((a, b) => {
          const data = this.state.sortType === "asc" ? -1 : 1;
          return data * a.createdAt.localeCompare(b.createdAt);
        })
      : null;

    const clientCaseContact =
      this.props.state.registerCaseState.clientCaseContact;

    const caseStartDate =
      caseState && caseState.caseStartDate === 0
        ? null
        : caseState.caseStartDate;
    const caseEndDate =
      caseState && caseState.caseEndDate === 0 ? null : caseState.caseEndDate;
    const caseStatus = caseState && caseState.caseStatus;

    const filterCase =
      (caseStartDate !== null && caseEndDate !== null) ||
      caseStatus !== "UNKNOWN_STATUS";

    const clientData =
      this.props.state.clientState && this.props.state.clientState.clientData;
    const clientEditedValue =
      clientData.firstName !== "" &&
      clientData.lastName !== "" &&
      clientData.gender !== -1 &&
      clientData.maritalStatus !== -1 &&
      clientData.dob !== null;

    //DIALOG TITLE CSS
    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography
            variant="h6"
            style={{ color: "rgb(204, 102, 0)", fontWeight: "600" }}
          >
            {children}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    //handle change for validation
    const { errors } = this.state;

    //pagination handle

    const handleChangePage = (event, newPage) => {
      if (nextUrl && newPage > this.state.page) {
        this.props.fetchPaginatedCaseList(nextUrl);
      }

      this.setState({
        page: newPage,
      });

      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    //filter cases
    const handleOpenToFilterCase = () => {
      this.props.clearFilterData();
      this.setState({
        openModalToFilterCase: true,
      });
    };
    const handleCloseToFilterCase = () => {
      this.setState({
        openModalToFilterCase: false,
      });
    };

    //HANDLE TO OPEN REGISTER CASE MODAL
    const handleOpenToRegisterDialogue = () => {
      this.setState({
        openDialogueToRegisterCase: true,
        activeStep: 0,
        filteredWorkFlow: [],
      });
      this.props.handleChangeClientName("Search Existing Client");
      this.props.handleChangeAddedClientId("");
      this.props.handleChangeCaseInRegisterCase("caseTemplateId", "");
      this.props.fetchClientCaseContacts("");
    };
    const handleCloseToRegisterDialogue = () => {
      this.setState({
        openDialogueToRegisterCase: false,
      });
    };

    //HANDLE TO UPDATE CLIENT
    const handleCloseDialogueForUpdateClient = () => {
      this.setState({
        openUpdateClientDialogue: false,
      });
    };
    const handleOpenDialogueForUpdateClient = () => {
      this.setState({
        openUpdateClientDialogue: true,
      });
    };

    //HANDLE TO DELETE CASE MODAL
    const handleCloseDialogueForDeleteCase = () => {
      this.setState({
        openDeleteCaseDialogue: false,
      });
    };
    const handleOpenDialogueForDeleteCaseTemplate = (caseId) => {
      this.setState({
        openDeleteCaseDialogue: true,
        caseId: caseId,
      });
    };
    const handleOkDeleteCaseDialogue = () => {
      this.props.deleteCase(this.state.case.caseId, this.props);
      this.setState({
        openDeleteCaseDialogue: false,
        caseId: "",
      });
    };

    // FOR CLIENT'S CONTACT
    const showInputForField = () => {
      let a = createContactObject();
      this.setState({
        contacts: [...this.state.contacts, a],
      });
    };
    const createContactObject = () => {
      return {
        contactId: String(Math.random()),
        name: "",
        emailPhone: "",
        contactType: "UNKNOWN_CONTACT_TYPE",
        phone: "",
        dob: null,
      };
    };
    const filterContactData = () => {
      let contactForm = [];
      this.state.contacts.map((single) => {
        const data = {
          name: single.name,
          emailPhone: single.emailPhone,
          contactType: single.contactType,
          phone: single.phone,
          dob: single.dob,
        };
        return contactForm.push(data);
      });
      this.props.handleChangeClient("contacts", contactForm);
    };
    const closeContactFieldSection = (key) => {
      var removedActionData = this.state.contacts.filter(
        (single) => single.contactId !== key
      );
      this.setState(
        {
          contacts: removedActionData,
        },
        () => {
          filterContactData();
        }
      );
    };
    const handleClickOpenDeleteContactDialogue = (contactId) => {
      this.setState({
        openDeleteContactDialogue: true,
        contactId: contactId,
      });
    };
    const handleCloseDeleteContactDialogue = () => {
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };
    const handleOkDeleteContactDialogue = () => {
      closeContactFieldSection(this.state.contactId);
      this.setState({
        openDeleteContactDialogue: false,
        contactId: "",
      });
    };

    // *******
    const changeContactFieldNameValue = (key, value) => {
      this.state.contacts.filter((single) => single.id === key)[0].value.name =
        value;
      filterContactData();
    };
    const changeContactFieldEmailValue = (key, value) => {
      this.state.contacts.filter(
        (single) => single.id === key
      )[0].value.emailPhone = value;
      filterContactData();
    };
    const changeContactFieldTypeValue = (key, value) => {
      if (value === "") {
        this.state.contacts.filter(
          (single) => single.id === key
        )[0].value.contactType = "UNKNOWN_CONTACT_TYPE";
      } else {
        this.state.contacts.filter(
          (single) => single.id === key
        )[0].value.contactType = value;
      }
      filterContactData();
    };
    const changeContactFieldPhoneValue = (key, value) => {
      this.state.contacts.filter((single) => single.id === key)[0].value.phone =
        value;
      filterContactData();
    };
    const changeContactFieldAddressValue = (key, value) => {
      this.state.contacts.filter(
        (single) => single.id === key
      )[0].value.address = value;
      filterContactData();
    };
    const changeContactFieldDobValue = (key, value) => {
      if (value === "") {
        this.state.contacts.filter(
          (single) => single.id === key
        )[0].value.dob = 0;
      } else {
        this.state.contacts.filter((single) => single.id === key)[0].value.dob =
          value;
      }
      filterContactData();
    };
    const handleChangeDateContact = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        const id = this.state.id;
        changeContactFieldDobValue(id, new Date(valueOfInput1).getTime());
      }
    };
    const getDateValue = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };

    //*****TO FILTER****
    // this month
    function convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    }
    var date = new Date();
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    //this week
    var first = date.getDate() - date.getDay();
    var last = first + 6;
    var weekFirstDay = new Date(date.setDate(first)).toLocaleString();
    var weekLastDay = new Date(date.setDate(last)).toLocaleString();

    //HANDLE DATE FORMATS
    const getDateOfBirth = (datetoConvert) => {
      let dateConverted = new Date(
        parseInt(datetoConvert)
      ).toLocaleDateString();
      return dateConverted.replace("/", "-").replace("/", "-");
    };
    const getDate = (datetoConvert) => {
      let dateConverted = new Date(
        parseInt(datetoConvert)
      ).toLocaleDateString();
      return dateConverted;
    };
    const getDateForClient = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };
    const getPickerDate = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
        "fr-CA"
      );
      return moment(dateConverted, "YYYY-MM-DD");
    };

    //CASE TEMPLATES
    const caseTemplateState = this.props.state.caseTemplateState;
    const userState = this.props.state.userState;
    let userRoleAgent = {};
    if (userState.user && userState.user.length >= 1) {
      userRoleAgent = userState.user.filter((e) => e.userRole === "AGENT");
    }
    //handle changes in case template
    const handleChangeStateValue = (data) => {
      this.setState({
        isBlocking: true,
        caseTemplateId: data.value,
        filteredWorkFlow: caseTemplateState.caseTemplate.filter(
          (s) => s.caseTemplateId === data.value
        ),
      });
    };
    const changeAssignAgents = (workFlowId, value) => {
      let a = createObject(workFlowId, value);
      this.setState({ allAgentsAssigned: a }, () => {
        this.props.handleChangeCaseInRegisterCase(
          "caseAgents",
          this.state.allAgentsAssigned
        );
      });
    };
    const createObject = (workFlowId, value) => {
      let allAgentsAssigned = this.state.allAgentsAssigned.filter(
        (single) => single.refId !== workFlowId
      );
      let data = {
        refId: workFlowId,
        userId: value,
      };
      allAgentsAssigned.push(data);
      return allAgentsAssigned;
    };

    //HANDLE TO ADD NEW CLIENT MODAL
    const handleOpenToAddNewClient = () => {
      this.setState({
        openDialogueToAddClient: true,
        errors: {
          clientId: "",
          ssn: "",
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          maritalStatus: "",
        },
      });
      this.props.clearClientInputData();
      this.props.handleChangeClientName("Search Existing Client");
      this.props.handleChangeAddedClientId("");
    };

    const handleCloseToAddNewClient = () => {
      this.setState({
        openDialogueToAddClient: false,
      });
    };
    const handleChangeDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        const eventValue = {
          target: {
            name: "dob",
            value: new Date(valueOfInput1).getTime(),
          },
        };
        this.props.handleChangeClient("dob", new Date(valueOfInput1).getTime());
        Helpers.handleValidationOnChange(eventValue, errors);
      }
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = clientCaseContact.map((n) => n);
        this.setState({
          selected: newSelected,
        });
        return;
      }
      this.setState({
        selected: [],
      });
      // this.props.handleChangeCaseInRegisterCase("caseContact", newCaseContactArray);
    };

    const handleClick = (event, id) => {
      const selectedIndex = this.state.selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selected: newSelected,
      });
    };

    const isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    const newCaseContactArray =
      this.state.selected &&
      this.state.selected.map((object) => ({
        relationshipId: object.relationshipId,
        caseContactType: object.caseContactType,
        refId: object.contact
          ? object.contact.contactId
          : object.company
          ? object.company.companyId
          : "",
      }));

    //multi stepper
    function getSteps() {
      if (clientCaseContact && clientCaseContact.length > 0) {
        return ["Select Client", "Case Detail", "Contacts"];
      } else {
        return ["Select Client", "Case Detail"];
      }
    }
    const steps = getSteps();
    const handleNext = () => {
      if (
        caseTemplateState &&
        caseTemplateState.caseTemplate &&
        this.state.caseTemplate &&
        caseTemplateState.caseTemplate.length > 0 &&
        this.state.caseTemplate.length !== caseTemplateState.caseTemplate.length
      ) {
        this.setState({
          caseTemplate: caseTemplateState.caseTemplate.map((template) => ({
            key: template.caseTemplateId,
            text: template.templateName,
            value: template.caseTemplateId,
          })),
        });
      }
      this.setState((previousState, props) => ({
        activeStep: previousState.activeStep + 1,
      }));
    };
    const handleBack = () => {
      this.setState((previousState, props) => ({
        activeStep: previousState.activeStep - 1,
      }));
    };
    const getStepContent = (stepIndex, props, state) => {
      switch (stepIndex) {
        case 0:
          return (
            <>
              <div
                style={
                  clientId === "Search Existing Client"
                    ? {
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "55px",
                      }
                    : {
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "35px",
                        borderBottom: "1px solid #80808033",
                        marginBottom: "12px",
                      }
                }
              >
                <div>
                  <button
                    className="newButton"
                    onClick={handleOpenToAddNewClient}
                  >
                    Add New Client
                  </button>
                </div>
                <div style={{ margin: "10px 25px" }}>Or</div>
                <div style={{ width: "295px" }}>
                  <div className="select-input selects h40">
                    <Select
                      showSearch
                      getPopupContainer={(node) => node.parentNode}
                      className="drop"
                      onSearch={(value) => {
                        this.props.searchClientList(value);
                      }}
                      onInputKeyDown={() => {
                        this.setState({
                          showDropdown: true,
                        });
                      }}
                      value={clientId}
                      onChange={(e) => {
                        this.props.handleChangeClientName(e);
                        this.props.fetchSingleClient(e);
                        this.props.fetchClientCaseContacts("");
                        this.setState({
                          disableButton: false,
                          addedClient: false,
                          activeStep: 0,
                          filteredWorkFlow: [],
                        });
                      }}
                      onSelect={() => {
                        this.setState({
                          showDropdown: false,
                        });
                      }}
                      onBlur={() => {
                        this.setState({
                          showDropdown: false,
                        });
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      defaultOpen={false}
                      dropdownStyle={
                        this.state.showDropdown
                          ? { display: "block", maxHeight: "100px" }
                          : { display: "none" }
                      }
                    >
                      {clientSearchList &&
                        clientSearchList.map((res) => (
                          <Option value={res.clientId}>
                            {res.firstName +
                              " " +
                              res.lastName +
                              " (" +
                              res.clientNo +
                              " )"}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </div>
              </div>
              {clientId !== "Search Existing Client" ||
              addedClientNameId !== "" ? (
                <div style={{ paddingBottom: "30px" }}>
                  <Typography
                    variant="h6"
                    style={{ color: "rgb(204, 102, 0)", fontWeight: "600" }}
                  >
                    Client Details
                  </Typography>
                  {/*<h5>Client Details</h5>*/}
                  <div className="row mt-3" style={style.row}>
                    <div className="col-4">
                      <label htmlFor="firstName" style={style.columnHeading}>
                        First Name
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="name"
                        id="firstName"
                        readOnly
                        style={style.inputField}
                        value={clientResData && clientResData.firstName}
                        onChange={(e) => {
                          e.preventDefault();
                          this.setState({ isBlocking: true });
                          // this.props.handleChangeClient(e.target.id, e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <label htmlFor="lastName" style={style.columnHeading}>
                        Last Name
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        id="lastName"
                        autoComplete="off"
                        style={style.inputField}
                        readOnly
                        value={clientResData && clientResData.lastName}
                      />
                    </div>
                    <div className="col-4" style={{ position: "inherit" }}>
                      <label style={style.columnHeading}>
                        Date-Of-Birth
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <div
                        className="select-date-full"
                        style={{ marginTop: "12px" }}
                      >
                        <KeyBoardDatePickerComponent
                          dateValue={
                            clientResData &&
                            getFormattedDateValue(clientResData.dob)
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "2rem" }}>
                    <div className="col-4">
                      <label htmlFor="gender" style={style.columnHeading}>
                        Gender
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <div
                        className="select-input selects h40"
                        style={{ marginTop: "12px", width: "100%" }}
                      >
                        <Select
                          aria-readonly
                          id="gender"
                          value={clientResData && clientResData.gender}
                        >
                          <Option value={-1}></Option>
                          <Option value="MALE">Male</Option>
                          <Option value="FEMALE">Female</Option>
                          <Option value="OTHER">Other</Option>
                        </Select>
                      </div>
                    </div>
                    <div className="col-4">
                      <label
                        htmlFor="maritalStatus"
                        style={style.columnHeading}
                      >
                        Marital Status <span style={{ color: "red" }}> *</span>
                      </label>
                      <div
                        className="select-input selects h40"
                        style={{ marginTop: "12px", width: "100%" }}
                      >
                        <Select
                          value={clientResData && clientResData.maritalStatus}
                          id="maritalStatus"
                          aria-readonly
                        >
                          <Option value={-1}></Option>
                          <Option value="SINGLE">Single</Option>
                          <Option value="MARRIED">Married</Option>
                          <Option value="DIVORCED">Divorced</Option>
                        </Select>
                      </div>
                    </div>
                    {clientResData && clientResData.county ? (
                      <div className="col-4">
                        <label style={style.columnHeading}>County</label>
                        <input
                          type="name"
                          id="county"
                          readOnly
                          style={style.inputField}
                          value={clientResData && clientResData.county}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({ isBlocking: true });
                            // this.props.handleChangeClient(e.target.id, e.target.value);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="row" style={{ marginTop: "2rem" }}>
                    <div className="col-4" style={{ display: "flex" }}>
                      <div style={{ marginLeft: "-12px" }}>
                        <Checkbox
                          onClick={() => {
                            return false;
                          }}
                          id="isEnableTransaction"
                          checked={
                            clientResData && clientResData.isEnableTransaction
                              ? true
                              : false
                          }
                          value={
                            clientResData && clientResData.isEnableTransaction
                          }
                          style={style.columnHeading}
                        />
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        Enable Transaction
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <Button
                disabled={this.state.disableButton}
                onClick={handleNext}
                className="nextClick"
                style={
                  clientId !== "Search Existing Client" ||
                  addedClientNameId !== ""
                    ? {
                        background: "#8BC34A",
                        border: "1px solid #8BC34A",
                        color: "white",
                        fontWeight: "600",
                        left: "86%",
                      }
                    : {
                        background: "#8bc83f99",
                        border: "1px solid #8bc83f99",
                        color: "white",
                        fontWeight: "600",
                        left: "86%",
                      }
                }
              >
                Next
              </Button>
            </>
          );
        case 1:
          return (
            <div style={{ borderTop: "1px solid #80808033" }}>
              <Typography
                className="mt-3"
                variant="h6"
                style={{ color: "rgb(204, 102, 0)", fontWeight: "600" }}
              >
                Case Details
              </Typography>
              <div
                className="row"
                style={
                  this.state.filteredWorkFlow &&
                  this.state.filteredWorkFlow.length > 0
                    ? null
                    : { paddingBottom: "53px" }
                }
              >
                {caseTemplateState &&
                caseTemplateState.caseTemplate &&
                state.caseTemplate &&
                caseTemplateState.caseTemplate.length > 0 &&
                state.caseTemplate.length !==
                  caseTemplateState.caseTemplate.length
                  ? this.setState({
                      caseTemplate: caseTemplateState.caseTemplate.map(
                        (template) => ({
                          key: template.caseTemplateId,
                          text: template.templateName,
                          value: template.caseTemplateId,
                        })
                      ),
                    })
                  : null}
                <div className="col-6">
                  <label
                    className="label"
                    style={{ textAlign: "left", marginBottom: "4px" }}
                  >
                    Select Case Template
                  </label>
                  <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    selectOnBlur={false}
                    className="mt-2"
                    id="caseTemplateId"
                    value={
                      props.state.registerCaseState &&
                      props.state.registerCaseState.caseData.caseTemplateId
                    }
                    options={state.caseTemplate}
                    onChange={(e, data) => {
                      e.preventDefault();
                      handleChangeStateValue(data);
                      props.handleChangeCaseInRegisterCase(
                        "caseTemplateId",
                        data.value
                      );
                      if (this.state.addedClient) {
                        props.handleChangeCaseInRegisterCase(
                          "clientId",
                          addedClientNameId
                        );
                      } else {
                        props.handleChangeCaseInRegisterCase(
                          "clientId",
                          clientId
                        );
                      }
                    }}
                    style={style.dropdownInput}
                  />
                </div>
              </div>
              {this.state.filteredWorkFlow &&
              this.state.filteredWorkFlow.length > 0 ? (
                <div className="row">
                  <div className="card-body" style={style.cardBody}>
                    {this.state.filteredWorkFlow &&
                      this.state.filteredWorkFlow.length > 0 &&
                      this.state.filteredWorkFlow.map((single) => {
                        return (
                          <div className="row mt-3" style={style.rowFirst}>
                            <div className={"col-6"}>
                              <h6 style={style.columnHeading}>Workflow</h6>
                            </div>
                            <div className="col-6">
                              <h6 style={style.columnHeading}>Assign to</h6>
                            </div>
                          </div>
                        );
                      })}
                    {this.state.filteredWorkFlow &&
                      this.state.filteredWorkFlow.length > 0 &&
                      this.state.filteredWorkFlow.map((single) => {
                        return (
                          <div>
                            {single.workflow &&
                              single.workflow.length > 0 &&
                              single.workflow.map((singleWorkflow) => {
                                return (
                                  <div
                                    className="row mt-3"
                                    style={style.caseRow}
                                  >
                                    <div className={"col-6"}>
                                      <label
                                        style={{
                                          color: "rgb(98, 95, 95)",
                                          paddingTop: "16px",
                                          fontWeight: "550",
                                        }}
                                      >
                                        {singleWorkflow.workflowName}
                                      </label>
                                    </div>
                                    <div className="col-6">
                                      <div style={style.select}>
                                        {userRoleAgent &&
                                        this.state.agent &&
                                        userRoleAgent.length > 0 &&
                                        this.state.agent.length !==
                                          userRoleAgent.length
                                          ? this.setState({
                                              agent: userRoleAgent.map(
                                                (singleAgent) => ({
                                                  key: singleAgent.userId,
                                                  text: singleAgent.fullName,
                                                  value: singleAgent.userId,
                                                })
                                              ),
                                            })
                                          : null}
                                        <Dropdown
                                          placeholder="Agent"
                                          fluid
                                          clearable
                                          search
                                          selection
                                          selectOnBlur={false}
                                          id={singleWorkflow.workflowId}
                                          options={this.state.agent}
                                          onChange={(e, data) => {
                                            e.preventDefault();
                                            changeAssignAgents(
                                              singleWorkflow.workflowId,
                                              data.value
                                            );
                                            this.setState({ isBlocking: true });
                                          }}
                                          style={style.dropdownInput}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <div hidden></div>
              )}
              <img
                src={"assets/images/back arrow.svg"}
                onClick={handleBack}
                alt=""
                style={
                  this.state.filteredWorkFlow &&
                  this.state.filteredWorkFlow.length > 0
                    ? {
                        width: "38px",
                        height: "38px",
                        position: "relative",
                        cursor: "pointer",
                      }
                    : {
                        width: "38px",
                        height: "38px",
                        position: "relative",
                        bottom: "0.5rem",
                        cursor: "pointer",
                      }
                }
              />
              <Button
                onClick={() => {
                  this.props.fetchClientCaseContacts(clientId, this.props);
                  handleNext();
                }}
                className="nextClick"
                disabled={!this.state.filteredWorkFlow.length > 0}
                style={
                  this.state.filteredWorkFlow &&
                  this.state.filteredWorkFlow.length > 0
                    ? {
                        background: "#8bc34a",
                        border: "1px solid #8BC34A",
                        color: "white",
                        fontWeight: "600",
                        top: "88%",
                      }
                    : {
                        background: "#8bc83f99",
                        border: "1px solid #8bc83f99",
                        color: "white",
                        fontWeight: "600",
                      }
                }
              >
                {this.state.activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          );
        case 2:
          if (clientCaseContact && clientCaseContact.length > 0) {
            return (
              <div style={{ borderTop: "1px solid rgba(128, 128, 128, 0.2)" }}>
                <Typography
                  className="mt-3"
                  variant="h6"
                  style={{ color: "rgb(204, 102, 0)", fontWeight: "600" }}
                >
                  <div style={{ paddingLeft: "5px" }}>
                    <Checkbox
                      indeterminate={
                        this.state.selected &&
                        this.state.selected.length > 0 &&
                        clientCaseContact &&
                        this.state.selected &&
                        this.state.selected.length < clientCaseContact.length
                      }
                      checked={
                        clientCaseContact &&
                        clientCaseContact.length > 0 &&
                        clientCaseContact &&
                        this.state.selected &&
                        this.state.selected.length === clientCaseContact.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all check" }}
                    />
                    <span>List of contacts :</span>
                  </div>
                </Typography>
                <div
                  className="row"
                  style={{ marginLeft: "40px", marginBottom: "22px" }}
                >
                  <div>
                    <ul style={{ listStyleType: "none", paddingLeft: "5px" }}>
                      {clientCaseContact &&
                        clientCaseContact.map((contacts, index) => {
                          const isItemSelected = isSelected(contacts);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <li>
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, contacts)
                                }
                                selected={isItemSelected}
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                              <span>
                                {contacts.company
                                  ? contacts.company.companyName
                                  : contacts.contact.name}{" "}
                                ({contacts.relationship.name})
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <img
                  src={"assets/images/back arrow.svg"}
                  onClick={handleBack}
                  alt=""
                  style={{
                    width: "38px",
                    height: "38px",
                    position: "relative",
                    bottom: "0.5rem",
                    cursor: "pointer",
                  }}
                />
                <Button
                  onClick={() => {
                    this.props.handleChangeCaseInRegisterCase(
                      "caseContact",
                      newCaseContactArray
                    );
                    handleNext();
                  }}
                  className="nextClick"
                  style={{
                    background: "#8BC34A",
                    border: "1px solid #8bc83f99",
                    color: "white",
                    fontWeight: "600",
                  }}
                >
                  {this.state.activeStep === steps.length - 1
                    ? "Finish"
                    : "Next"}
                </Button>
              </div>
            );
          }

        default:
          return "Unknown stepIndex";
      }
    };
    const loading =
      this.props.state &&
      this.props.state.registerCaseState &&
      this.props.state.registerCaseState.loading;

    return (
      <React.Fragment>
        <HelmetComponent title="Case" />
        {this.props.state.caseState.loading ? (
          <div
            className={
              this.props.state.caseState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        {this.props.state.clientState.addSuccess === true ||
        (clientState && clientState.success === true)
          ? this.setState(
              {
                openDialogueToAddClient: false,
                openModalToFilterCase: false,
              },
              () => {
                this.props.setAddClientSuccessToTrueOrFalse();
                this.props.setFilterSuccessToFalse();
              }
            )
          : null}

        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Case
          </Typography>
        </Breadcrumbs>

        <div style={style.container}>
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            {/******* Refresh *********/}
            <div>
              <button
                className="filterButton"
                onClick={() => {
                  this.props.fetchCaseList();
                  this.props.clearNextUrl();
                  this.props.clearFilterData();
                }}
              >
                <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
              </button>
            </div>
            <div>
              {userType === "CORPORATE_USER" ||
              userType === "CLIENT_USER" ? null : (
                <button
                  style={style.settingButton}
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.fetchDataListForCalendar();
                    this.props.history.push("/case/calendar");
                  }}
                >
                  <DateRangeIcon />
                </button>
              )}
            </div>
            {/******** Filter *********/}
            <div>
              <button className="filterButton" onClick={handleOpenToFilterCase}>
                <FilterListIcon style={{ color: "#fff", marginTop: "-2px" }} />
              </button>
            </div>
            {/******** Export *********/}
            <button
              className="exportButton"
              style={{ marginLeft: "12px" }}
              onClick={(e) => {
                e.preventDefault();
                this.props.exportCases(
                  "",
                  caseStartDate,
                  caseEndDate,
                  caseStatus
                );
              }}
            >
              <img
                alt={"setting"}
                src={"/assets/images/export.svg"}
                style={{
                  width: "26px",
                  height: "18px",
                  marginRight: "7px",
                  marginBottom: "4px",
                }}
              />
              Export
            </button>
            {/******* Register Case *********/}
            <div>
              {userType === "CORPORATE_USER" ||
              userType === "CLIENT_USER" ||
              userRole === "AGENT" ? null : (
                <button
                  style={style.addButton}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenToRegisterDialogue();
                  }}
                >
                  <AddIcon />
                  Register Case
                </button>
              )}
            </div>
            <div className="search">
              <div style={style.searchIcon}>
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={style.inputBase}
                onChange={(e) => {
                  e.preventDefault();
                  if (e.target.value === "" || e.target.value === " ") {
                    this.props.fetchCaseList();
                  } else {
                    this.props.filterCase(
                      e.target.value,
                      0,
                      0,
                      "UNKNOWN_STATUS"
                    );
                  }
                }}
              />
            </div>
          </div>

          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell style={style.tableHeader}>#</TableCell>
                    <TableCell className="first-tableHeader">Case Id</TableCell>
                    <TableCell style={style.tableHeader}>Client</TableCell>
                    <TableCell style={style.tableHeader}>
                      Register Date
                    </TableCell>
                    <TableCell style={style.tableHeader}>Progress</TableCell>
                    {userRole === "AGENT" ||
                    userType === "CORPORATE_USER" ||
                    userType === "CLIENT_USER" ? null : (
                      <TableCell
                        style={{
                          color: "#8BC83F",
                          fontWeight: "bold",
                          fontSize: "15px",
                          textAlign: "center",
                          width: "130px",
                          height: "3rem",
                          // position: 'inherit'
                        }}
                      ></TableCell>
                    )}
                  </TableRow>
                </TableHead>

                {sortedCaseList &&
                  sortedCaseList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((singleCase, index) => {
                      return (
                        <TableBody>
                          <TableRow key={index}>
                            <TableCell>
                              {(this.state.page + 1 - 1) *
                                this.state.rowsPerPage +
                                (index + 1)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                height: "4.5rem",
                                textDecoration: "underline",
                                cursor: "pointer",
                                paddingLeft: "40px",
                                width: "15%",
                              }}
                              onClick={() => {
                                this.setState({
                                  case: singleCase,
                                });
                                this.props.history.push({
                                  pathname: "/case/update/" + singleCase.caseId,
                                  dataToPass: this.state.case.caseId,
                                });
                              }}
                            >
                              {singleCase.caseId}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                height: "4.5rem",
                                width: "20%",
                              }}
                            >
                              <button
                                onClick={() => {
                                  this.setState(
                                    {
                                      case: singleCase,
                                    },
                                    () => {
                                      this.props.getClientInfo(
                                        this.state.case.clientId
                                      );
                                    }
                                  );
                                  handleOpenDialogueForUpdateClient();
                                }}
                                style={{
                                  border: "none",
                                  background: "none",
                                  color: "#626262",
                                  padding: 0,
                                }}
                              >
                                {singleCase.clientName}
                              </button>
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                height: "4.5rem",
                                width: "20%",
                              }}
                            >
                              {getDate(singleCase.createdAt)}
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "15px",
                                color: "#626262",
                                textAlign: "left",
                                height: "4.5rem",
                                width: "30%",
                                paddingRight: "3rem",
                              }}
                            >
                              <div style={style.progressDiv}>
                                <BorderLinearProgress
                                  variant="determinate"
                                  color="secondary"
                                  value={
                                    singleCase.completed
                                      ? singleCase.completed
                                      : 0
                                  }
                                />
                                <div style={style.progressData}>
                                  <p>
                                    {singleCase.completed !== undefined
                                      ? Number.isInteger(singleCase.completed)
                                        ? singleCase.completed
                                        : singleCase.completed.toFixed(2)
                                      : 0}
                                    %
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            {userRole === "AGENT" ||
                            userType === "CORPORATE_USER" ||
                            userType === "CLIENT_USER" ? null : (
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "center",
                                  width: "30%",
                                  // paddingLeft: '62px',
                                }}
                              >
                                <Tooltip title={"Delete"}>
                                  <DeleteOutlinedIcon
                                    style={{
                                      paddingBottom: 0,
                                      color: "#FF5722",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        case: singleCase,
                                      });
                                      handleOpenDialogueForDeleteCaseTemplate();
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      );
                    })}
              </Table>
            </TableContainer>
            {sortedCaseList && sortedCaseList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 20]}
                  component="div"
                  count={
                    // filterCase ? count :
                    totalCaseCount
                    // sortedCaseList.length > 0 ? sortedCaseList.length : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>

        {/******* Dialog for filter case *********/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openModalToFilterCase}
          maxWidth="lg"
          onClose={handleCloseToFilterCase}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseToFilterCase}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={
                      caseStartDate && getFormattedDateValue(caseStartDate)
                    }
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeCaseStartDate(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={
                      caseEndDate && getFormattedDateValue(caseEndDate)
                    }
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeCaseEndDate(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeCaseStartDate(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeCaseEndDate(date);
              }}
            />
            <label className="label"> Status </label>
            <div className="select-input h40">
              <Select
                showSearch={false}
                getPopupContainer={(node) => node.parentNode}
                style={{ width: 200 }}
                onChange={(e) => {
                  this.props.handleChangeCaseStatus(e);
                }}
              >
                <Option value="UNKNOWN_STATUS" hidden />
                <Option value="PENDING">Pending</Option>
                <Option value="IN_PROGRESS">In progress</Option>
                <Option value="COMPLETED">Completed</Option>
                <Option value="BLOCKED">Blocked</Option>
              </Select>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6" style={{ paddingTop: "15px" }}>
                <Button
                  style={{ float: "right" }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      page: 0,
                    });
                    handleCloseToFilterCase();
                    this.props.filterCase(
                      "",
                      caseStartDate,
                      caseEndDate,
                      caseStatus
                    );
                  }}
                  disabled={!filterCase}
                  className={filterCase ? "addButton" : "disabledAddButton"}
                >
                  Filter
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/******* Dialog for register case *********/}
        <Dialog
          onClose={handleCloseToRegisterDialogue}
          aria-labelledby="draggable-dialog-title"
          open={this.state.openDialogueToRegisterCase}
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            id="draggable-dialog-title"
            onClose={handleCloseToRegisterDialogue}
          >
            Register Case
          </DialogTitle>
          <DialogContent dividers style={{ width: "726px", padding: " 32px" }}>
            <div>
              <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {this.state.activeStep === steps.length ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          src={"assets/images/back arrow.svg"}
                          onClick={handleBack}
                          alt=""
                          style={{
                            width: "38px",
                            height: "38px",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          color: "#808080d9",
                          fontWeight: 600,
                        }}
                      >
                        <Typography>All steps completed</Typography>
                      </div>
                      <div>
                        <ButtonComponent
                          loading={loading}
                          onClick={() => {
                            handleCloseToRegisterDialogue();
                            this.props.registerCase(
                              this.props.state.registerCaseState.caseData,
                              this.props
                            );
                          }}
                          noValue
                          buttonName="Register"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <Typography>
                      {getStepContent(
                        this.state.activeStep,
                        this.props,
                        this.state
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/******* Dialog for delete client *********/}
        <Dialog
          open={this.state.openDeleteCaseDialogue}
          onClose={handleCloseDialogueForDeleteCase}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete case?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForDeleteCase}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkDeleteCaseDialogue();
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/******* Dialog for client *********/}
        <Dialog
          id="updateClient"
          open={this.state.openUpdateClientDialogue}
          onClose={handleCloseDialogueForUpdateClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="lg"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForUpdateClient}
          >
            Client Detail
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              // paddingLeft: "32px",
              paddingTop: "24px",
              paddingBottom: "24px",
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Client Id
                  </label>
                  <label style={style.clientData}>
                    {clientState && clientState.clientNo}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    First Name
                  </label>
                  <label style={style.clientData}>
                    {clientState && clientState.firstName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="lastName" style={style.columnHeading}>
                    Last Name
                  </label>
                  <label style={style.clientData}>
                    {clientState && clientState.lastName}
                  </label>
                </span>
              </span>

              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="dob" style={style.columnHeading}>
                    Date-Of-Birth
                  </label>
                  <label style={style.clientData}>
                    {clientState && getDateOfBirth(clientState.dob)}
                  </label>
                </span>
                <span className="col-4" style={{ position: "initial" }}>
                  <label htmlFor="gender" style={style.columnHeading}>
                    Gender
                  </label>
                  <label style={style.clientData}>
                    {clientState && clientState.gender === "MALE"
                      ? "Male"
                      : "Female"}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="maritalStatus" style={style.columnHeading}>
                    Marital Status
                  </label>
                  <label style={style.clientData}>
                    {clientState && clientState.maritalStatus === "SINGLE"
                      ? "Single"
                      : clientState && clientState.maritalStatus === "MARRIED"
                      ? "Married"
                      : "Divorced"}
                  </label>
                </span>
              </span>

              {clientState && clientState.contacts ? (
                <span
                  className="row"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0.5rem",
                    paddingTop: "1.6rem",
                    width: "700px",
                  }}
                >
                  <h5
                    style={{
                      color: "#8BC83F",
                      padding: "0.6rem",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    List Of Contacts
                  </h5>
                  <span
                    className={"row"}
                    style={{ width: "100%", paddingLeft: "15px" }}
                  >
                    <span className="col-5">
                      <label htmlFor="name" style={style.columnHeading}>
                        Name
                      </label>
                    </span>
                    <span className="col-5">
                      <label htmlFor="name" style={style.columnHeading}>
                        Contact Type
                      </label>
                    </span>
                  </span>
                  {clientState &&
                    clientState.contacts.map((single) => {
                      return (
                        <span
                          className={"row"}
                          style={{ width: "100%", paddingLeft: "15px" }}
                        >
                          <span className="col-5">
                            <label style={style.clientData}>
                              {single.name}
                            </label>
                          </span>
                          <span className="col-5">
                            <label style={style.clientData}>
                              {single.contactType === "EVERFUND_USER_CONTACT"
                                ? "Everfund User"
                                : single.contactType === "CORPORATE_CONTACT"
                                ? "Corporate"
                                : single.contactType === "CLIENT_CONTACT"
                                ? "Client"
                                : "Client Relative"}
                            </label>
                          </span>
                        </span>
                      );
                    })}
                </span>
              ) : null}
            </DialogContentText>
          </DialogContent>
        </Dialog>

        {/******* Add new client *********/}
        <Dialog
          open={this.state.openDialogueToAddClient}
          onClose={handleCloseToAddNewClient}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitle
              id="draggable-dialog-title"
              onClose={handleCloseToAddNewClient}
            >
              Add Client
            </DialogTitle>
            <DialogContent
              dividers
              style={{ maxHeight: "630px", paddingTop: "5px" }}
              className="dialogContentWidth850"
            >
              <div className="row mt-2">
                {/*<div className="col-4">*/}
                {/*    <label htmlFor="ssn" className="label">Client Id <span style={{color: "red"}}>*</span></label>*/}
                {/*    <input*/}
                {/*        type='number'*/}
                {/*        id="ssn"*/}
                {/*        className="inputField"*/}
                {/*        name="clientId"*/}
                {/*        onChange={(e) => {*/}
                {/*            e.preventDefault();*/}
                {/*            Helpers.handleValidationOnChange(e, errors);*/}
                {/*            this.setState({isBlocking: true});*/}
                {/*            this.props.handleChangeClient(e.target.id, e.target.value);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    {errors.clientId.length > 0 &&*/}
                {/*    <span className='error'>{errors.clientId}</span>}*/}
                {/*</div>*/}
                <div className="col-4">
                  <label htmlFor="firstName" className="label">
                    First Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="name"
                    id="firstName"
                    name="firstName"
                    autoComplete="off"
                    className="inputField"
                    onChange={(e) => {
                      e.preventDefault();
                      Helpers.handleValidationOnChange(e, errors);
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.firstName.length > 0 && (
                    <span className="error">{errors.firstName}</span>
                  )}
                </div>
                <div className="col-4">
                  <label htmlFor="lastName" className="label">
                    Last Name
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    className="inputField"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      console.log("hello", e);
                    }}
                    onChange={(e) => {
                      Helpers.handleValidationOnChange(e, errors);
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                  {errors.lastName.length > 0 && (
                    <span className="error">{errors.lastName}</span>
                  )}
                </div>
                <div className="col-4" style={{ position: "inherit" }}>
                  <label className="label">
                    Date-of-birth
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-date-full">
                    <KeyBoardDatePickerComponent
                      dateValue={
                        clientData && getFormattedDateValue(clientData.dob)
                      }
                      onChangeDate={(data) => {
                        this.props.handleChangeClient("dob", data);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label className="label">
                    Gender
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="contactType"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClient("gender", e);
                      }}
                    >
                      <Option value={-1} hidden />
                      <Option value="MALE">Male</Option>
                      <Option value="FEMALE">Female</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label className="label">
                    Marital Status <span style={{ color: "red" }}>*</span>
                  </label>
                  <div
                    className="select-input selects h40"
                    style={{ width: "100%" }}
                  >
                    <Select
                      id="maritalStatus"
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ isBlocking: true });
                        this.props.handleChangeClient("maritalStatus", e);
                      }}
                    >
                      <Option value={-1} hidden />
                      <Option value="SINGLE">Single</Option>
                      <Option value="MARRIED">Married</Option>
                      <Option value="DIVORCED">Divorced</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-4">
                  <label className="label">County</label>
                  <input
                    type="text"
                    id="county"
                    className="inputField"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: true });
                      this.props.handleChangeClient(
                        e.target.id,
                        e.target.value
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4" style={{ display: "flex" }}>
                  <div style={{ marginLeft: "-12px" }}>
                    <Checkbox
                      id="isEnableTransaction"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeClient(
                          e.target.id,
                          e.target.checked
                        );
                      }}
                      className="label"
                    />
                  </div>
                  <div style={{ marginTop: "25px" }}>Enable Transaction</div>
                </div>
              </div>
              {!(this.state.contacts.length > 0) ? (
                <div
                  className="addFieldOuterDiv"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isBlocking: false,
                    });
                    showInputForField();
                  }}
                >
                  <img
                    src="/assets/images/add-button-inside-circle.svg"
                    alt="Add Button"
                    className="addButtonIcon"
                  />
                  <span className="addFieldSpan">Add Contact</span>
                </div>
              ) : (
                <div style={{ marginTop: "2rem" }}>
                  <OpenContactFields
                    fieldArray={this.state.contacts}
                    filterContactData={filterContactData}
                    handleOpenDeleteDialogue={
                      handleClickOpenDeleteContactDialogue
                    }
                  />
                  <div
                    style={{
                      display: "block",
                      marginTop: "1rem",
                    }}
                  >
                    <span
                      className="cursorP"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ isBlocking: false });
                        showInputForField();
                      }}
                    >
                      <span style={{ fontSize: "16px", color: "#81b447" }}>
                        + Add More
                      </span>
                    </span>
                  </div>
                </div>
              )}
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 1.5rem",
                  float: "right",
                  marginRight: "7px",
                }}
              >
                <div>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // handleCloseToAddNewClient();
                      this.setState({ isBlocking: false });
                      var clientData = this.props.state.clientState.clientData;
                      clientData.ssn =
                        clientData.ssn && clientData.ssn.replace(/-/g, "");
                      if (!this.state.clicked) {
                        this.setState({
                          clicked: true,
                          setValue: "add",
                          disableButton: false,
                          addedClient: true,
                        });
                        this.props.handleChangeClientName(
                          "Search Existing Client"
                        );
                        this.props.addClient(clientData, this.props);
                        setTimeout(() => {
                          this.setState({
                            clicked: false,
                          });
                        }, 3000);
                      }
                    }}
                    disabled={!clientEditedValue}
                    className={
                      clientEditedValue ? "addButton" : "disabledAddButton"
                    }
                  >
                    Add
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/******* Delete client's contact *********/}
        <Dialog
          open={this.state.openDeleteContactDialogue}
          onClose={handleCloseDeleteContactDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ background: "#ffffff", borderRadius: "18px" }}>
            <DialogTitle id="draggable-dialog-title">
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.5rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to delete contact?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteContactDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteContactDialogue();
                      }}
                      style={style.addButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>

        {/******* Success/Error Snackbars *********/}
        <SnackBarComponent
          onClose={this.props.handleSnackBarClose}
          variant="error"
          open={caseState && caseState.openCaseErrorSnackBar}
          message={caseState && caseState.errorMsgForCase}
        />
        <SnackBarComponent
          onClose={this.props.handleSnackBarClose}
          variant="error"
          open={this.props.state.caseState.openCaseSnackBar}
          message={this.props.state.caseState.errorMsgForCase}
        />
        <SnackBarComponent
          onClose={this.props.handleCloseSnackBarForClient}
          variant="error"
          open={this.props.state.clientState.openAddClientErrorSnackbar}
          message={this.props.state.clientState.error}
        />
      </React.Fragment>
    );
  }
}

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten("#8BC83F", 0.8),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#8BC83F",
  },
})(LinearProgress);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: { minWidth: "500px" },
});

const style = {
  container: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "1.5%",
    marginTop: "48px",
  },
  card: {
    background: "#ffffff",
    // outline:'1px solid #E9E9E9',
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
    marginTop: ".7rem",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    // top: 0,
    // position: 'inherit'
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    // position: "fixed",
    // width: "100%",
    // background: "white",
    // zIndex: 1,
    // right: "2.5%",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "12px",
    width: "35px",
    height: "35px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "160px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
};

export default CaseScreen;
