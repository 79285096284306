import {connect} from 'react-redux';
import {CorporateClientEditFormScreen} from "../../screens";
import {
    addClientSuccess,
    addCorporateClientFailure,
    clientRequestMade,
    closeSnackBarForClient,
    corporateClientRequestMade,
    fetchClientFailureInCorporateClient,
    fetchClientSuccessInCorporateClient,
    updateClientFailure
} from "../../actions";
import {ClientAPI, RegisterCaseAPI} from "../../api";
import {handleChangeCorporateClientEdit, setCorporateClientToEdit} from "../../actions/CorporateClientAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientInfo: (clientId) => {
            dispatch(corporateClientRequestMade(true));
            ClientAPI.fetchSingleClient(clientId, (response, error) => {
                if (response) {
                    dispatch(setCorporateClientToEdit(response));
                } else {
                    if (error.msg) {
                        dispatch(addCorporateClientFailure(error.msg));
                    } else {
                        dispatch(addCorporateClientFailure(error));
                    }
                }
            })
        },
        fetchClientList: () => {
            dispatch(corporateClientRequestMade(true));
            RegisterCaseAPI.fetchClientList((response, error) => {
                if (response) {
                    dispatch(fetchClientSuccessInCorporateClient(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientFailureInCorporateClient(error.msg))
                    } else {
                        dispatch(fetchClientFailureInCorporateClient(error));
                    }
                }
            })
        },
        handleChangeCorporateClientEdit: (id, value) => dispatch(handleChangeCorporateClientEdit(id, value)),
        editClient: (clientData, corporateId, props) => {
            dispatch(clientRequestMade(true));
            ClientAPI.editClient(clientData, (response, error) => {
                if (response) {
                    dispatch(addClientSuccess(true));
                    props.history.replace('/corporate/client/' + corporateId);
                } else {
                    if (error.msg) {
                        dispatch(updateClientFailure(error.msg));
                    } else {
                        dispatch(updateClientFailure(error));
                    }
                }
            });
        },
        setClientData: (clientData) => {
            dispatch(setCorporateClientToEdit(clientData))
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForClient(true)),
    };
};


export const CorporateClientEditContainer = connect(mapStateToProps, mapDispatchToProps)(CorporateClientEditFormScreen);