import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class ContactApi extends API {
    fetchContactList = callback => {
        fetch(APIEndPoints.FETCH_CONTACT_LIST, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling client api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    addContact= (data, callback) => {
        let url = APIEndPoints.ADD_CONTACT;
        fetch( url, {
            method: "POST",
            headers: this.authHeaders,
            body: JSON.stringify(data),
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling patching user api", error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteContact = (contactId, callback) => {
        const urlString = APIEndPoints.DELETE_CONTACT + contactId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    editContact = (contactData, callback) => {
        fetch(APIEndPoints.UPDATE_CONTACT, {
            method: 'PATCH',
            headers: this.authHeaders,
            body: JSON.stringify(contactData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    //company's apis
    fetchCompanyList = callback => {
        fetch(APIEndPoints.FETCH_COMPANY_LIST, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling client api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    addCompany= (data, callback) => {
        let url = APIEndPoints.ADD_COMPANY;
        fetch( url, {
            method: "POST",
            headers: this.authHeaders,
            body: JSON.stringify(data),
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling patching user api", error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteCompany = (companyId, callback) => {
        const urlString = APIEndPoints.DELETE_COMPANY + companyId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    editCompany= (companyData, callback) => {
        fetch(APIEndPoints.UPDATE_COMPANY, {
            method: 'PATCH',
            headers: this.authHeaders,
            body: JSON.stringify(companyData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    searchIndividualContact = (value, callback) => {
        const urlString = APIEndPoints.SEARCH_INDIVIDUAL_CONTACT + value;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };
    searchCompanyContact = (value, callback) => {
        const urlString = APIEndPoints.SEARCH_COMPANY_CONTACT + value;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };
    deleteCompanyContact = (companyId,contactId, callback) => {
        const urlString = APIEndPoints.DELETE_COMPANY_CONTACT + companyId + "?contactId=" + contactId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    //add relationship
    addRelationship = (relationship, callback) => {
        fetch(APIEndPoints.ADD_CONTACT_RELATIONSHIP, {
            method: 'POST',
            headers: this.authHeaders,
            body: JSON.stringify(relationship)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    editRelationship = (relationship, callback) => {
        fetch(APIEndPoints.UPDATE_CONTACT_RELATIONSHIP, {
            method: 'PATCH',
            headers: this.authHeaders,
            body: JSON.stringify(relationship)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    fetchRelationship = callback => {
        fetch(APIEndPoints.FETCH_CONTACTS_RELATIONSHIPS, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling client api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    //CONTACT FILES API
    addContactFiles = (relationship, callback) => {
        fetch(APIEndPoints.ADD_CONTACT_FILES, {
            method: 'POST',
            headers: this.authHeaders,
            body: JSON.stringify(relationship)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    editContactFiles = (relationship, callback) => {
        fetch(APIEndPoints.EDIT_CONTACT_FILES, {
            method: 'PATCH',
            headers: this.authHeaders,
            body: JSON.stringify(relationship)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    deleteContactFiles = (refId, callback) => {
        fetch(APIEndPoints.DELETE_FILES + refId, {
            method: 'DELETE',
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    fetchContactFiles = (contactId, callback)=> {
        fetch(APIEndPoints.FETCH_CONTACT_FILES + contactId, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling client api.", error);
            callback(null, "Service error, please try again.");
        });
    };
//add company type
    addCompanyType= (companyType, callback) => {
        fetch(APIEndPoints.ADD_CONTACT_COMPANY_TYPE, {
            method: 'POST',
            headers: this.authHeaders,
            body: JSON.stringify(companyType)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    editCompanyType = (companyType, callback) => {
        fetch(APIEndPoints.EDIT_CONTACT_COMPANY_TYPE, {
            method: 'PATCH',
            headers: this.authHeaders,
            body: JSON.stringify(companyType)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    fetchCompanyType = callback => {
        fetch(APIEndPoints.FETCH_CONTACT_COMPANY_TYPE, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling client api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    searchCompanyType = (term, callback) => {
        fetch(APIEndPoints.SEARCH_CONTACT_COMPANY_TYPE+term, {
            method: 'GET',
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    // set contact or company account
    setContactsAccount= (accountDetails, callback) => {
        fetch(APIEndPoints.SET_ACCOUNT_FOR_CONTACTS, {
            method: 'POST',
            headers: this.authHeaders,
            body: JSON.stringify(accountDetails)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
}
export default new ContactApi();