import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { DatePicker, Select, Tabs, Tag } from "antd";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  MenuList,
  Snackbar,
  Table,
  TableBody,
  Popover,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "../styles/styles.css";
import { TimePicker } from "antd";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DialogContentText from "@material-ui/core/DialogContentText";
import TablePagination from "@material-ui/core/TablePagination";
import MySnackbarContentWrapper from "../components/Snackbar";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import RelationshipScreen from "./RelationshipScreen";
import CompanyTypeScreen from "./CompanyTypeScreen";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import EligibilityScreen from "./EligibilityScreen";
import { HelmetComponent } from "../components/Helmet";
import InfoPopover from "./InfoPopoverComponent";
import ButtonComponent from "./ButtonComponent";
import DescriptionScreen from "./DescriptionScreen";
import VendorTypeScreen from "./VendorTypeScreen";
import CheckingAccountScreen from "./CheckingAccountScreen";
import ImportButton from "../components/ImportButton";
import { finalAmount } from "../utils/currencyConvertor";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {
  getDateWithEndTimeValue,
  getDateWithStartTimeValue,
  getFormattedDateTimeValue,
  DateTimeFormat,
} from "../utils/DateConverter";

const { TabPane } = Tabs;
const { Option } = Select;
const format = "hh:mm A";

class SettingScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openDialogueForFee: false,
      openDialogueToEditHoliday: false,
      openDeleteHolidayDialogue: false,
      openModalToAddRelationship: false,
      openModalToEditRelationship: false,
      openModalToAddCompanyType: false,
      openModalToEditCompanyType: false,
      openModalToAssignPermission: false,
      openModalToAssignEventToClientsOrAgents: false,
      openModalToAddEligibilityData: false,
      openModalToUpdateEligibilityData: false,
      openModalToAddDescription: false,
      openModalToUpdateDescription: false,
      openModalToDeleteDescription: false,
      //vendor type
      openModalToAddVendor: false,
      openModalToDeleteVendor: false,
      pageInRelationship: 0,
      rowsPerPageInRelationship: 10,
      pageInCompanyType: 0,
      rowsPerPageInCompanyType: 10,
      pageInDescription: 0,
      rowsPerPageInDescription: 10,
      openActionMenu: false,
      popoverElement: null,
      setValue: "",
      page: 0,
      rowsPerPage: 10,
      holidayList: {},
      tabValue: localStorage.getItem("tabKey"),
      clicked: false,
      openModalToSelectIcsFile: false,
      openSnackBar: false,
      showDropdown: false,
      pageInVendorType: 0,
      rowsPerPageInVendorType: 10,
      customStart: false,
    };
  }

  componentDidMount() {
    this.props.fetchHolidaysList();
    this.props.fetchFeePercentage();
  }

  componentWillUnmount() {
    localStorage.setItem("tabKey", "1");
  }

  render() {
    const settingsState = this.props && this.props.state.settingState;
    const holidayName = settingsState && settingsState.eventName;
    const calendarType = settingsState && settingsState.calendarType;
    const endDate = settingsState && settingsState.endDate;
    const startDate = settingsState && settingsState.startDate;
    const newStartDate = settingsState && settingsState.newStartDate;
    const holidayDetails = settingsState && settingsState.holidayDetails;
    const feePercentage = settingsState && settingsState.feePercentage;
    const minMonthlyFee = settingsState && settingsState.minMonthlyFee;
    const maxMonthlyFee = settingsState && settingsState.maxMonthlyFee;
    const annualFee = settingsState && settingsState.annualFee;
    const achDelayThreshold = settingsState && settingsState.achDelayThreshold;
    const achUploadTime = settingsState && settingsState.achUploadTime;
    const timeFormat = settingsState && settingsState.timeFormat;
    const errorMsg = settingsState && settingsState.error;
    const open = settingsState && settingsState.open;

    const assignEvent = settingsState && settingsState.assignEvent;
    const eventId = settingsState && settingsState.eventId;
    const loading =
      this.props.state &&
      this.props.state.settingState &&
      this.props.state.settingState.loading;

    // var moment = require('moment');
    // moment().tz("America/Los_Angeles").format();
    // const valueInAssignEv
    const holidayValue =
      holidayName !== "" &&
      startDate !== "" &&
      endDate !== "" &&
      calendarType !== "";
    const icsFile = settingsState && settingsState.icsFile;
    const clientOrAgentAccount =
      settingsState && settingsState.clientOrAgentAccount;

    const value = icsFile !== "";
    const assignValue = assignEvent !== undefined;
    const paymentSettingValue =
      feePercentage !== "" &&
      annualFee !== 0 &&
      minMonthlyFee !== 0 &&
      maxMonthlyFee !== 0 &&
      achDelayThreshold !== "" &&
      achUploadTime !== "" &&
      timeFormat !== "";

    const sortedHolidayDetailsList = holidayDetails
      ? holidayDetails.sort((a, b) => {
          const data = this.state.sortType === "asc" ? 1 : -1;
          return data * a.createdAt.localeCompare(b.createdAt);
        })
      : null;

    function callback(key) {
      localStorage.setItem("tabKey", key);
    }

    const getDateFromPicker = (dateToConvert) => {
      let dateConverted = new Date(parseInt(dateToConvert));
      return moment(dateConverted, "YYYY-MM-DD HH:mm:ss");
    };

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleCloseDialogueForFee = () => {
      this.setState({
        openDialogueForFee: false,
      });
    };

    //assign permission
    const handleOpenToAssignPermission = () => {
      this.setState({
        openModalToAssignPermission: true,
      });
    };
    const handleCloseToAssignPermission = () => {
      this.setState({
        openModalToAssignPermission: false,
      });
    };
    // HANDLE CHANGE FOR DATE
    const handleChangeStartDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        this.props.handleChangeStartDate(new Date(valueOfInput1).getTime());
      } else if (range === null) {
        this.props.handleChangeStartDate(
          new Date(0).getTime(),
          new Date(0).getTime()
        );
      }
    };
    const handleChangeEndDate = (range) => {
      if (range) {
        const valueOfInput1 = range.format();
        this.props.handleChangeEndDate(new Date(valueOfInput1).getTime());
      } else if (range === null) {
        this.props.handleChangeEndDate(
          new Date(0).getTime(),
          new Date(0).getTime()
        );
      }
    };

    // EDIT HOLIDAY
    const handleOpenDialogueToEditHoliday = () => {
      this.setState({
        openDialogueToEditHoliday: true,
      });
    };
    const handleCloseDialogueToEditHoliday = () => {
      this.setState({
        openDialogueToEditHoliday: false,
      });
    };

    // DELETE HOLIDAY
    const handleOpenDialogueToDeleteHoliday = () => {
      this.setState({
        openDeleteHolidayDialogue: true,
      });
    };
    const handleCloseDialogueToDeleteHoliday = () => {
      this.setState({
        openDeleteHolidayDialogue: false,
      });
    };
    const handleOkToDeleteHoliday = () => {
      this.props.deleteHolidays(
        this.state.holidayList.calendarSettingId,
        this.props
      );
      this.setState({
        openDeleteHolidayDialogue: false,
      });
    };

    //row per page handle
    const handleChangePageInRelationship = (event, newPage) => {
      this.setState({
        pageInRelationship: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPageInRelationship = (event) => {
      this.setState({
        rowsPerPageInRelationship: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangePageCompanyType = (event, newPage) => {
      this.setState({
        pageInCompanyType: newPage,
      });
    };
    const handleChangeRowsPerPageCompanyType = (event) => {
      this.setState({
        rowsPerPageInCompanyType: parseInt(event.target.value, 10),
      });
    };

    //to add relationship
    const handleChangeOpenModalToAddRelationship = () => {
      this.setState(
        {
          openModalToAddRelationship: true,
        },
        () => {
          this.props.setContactDataToEmpty();
        }
      );
    };
    const handleChangeCloseModalToAddRelationship = () => {
      this.setState({
        openModalToAddRelationship: false,
      });
    };
    //to edit relationship
    const handleChangeOpenModalToEditRelationship = () => {
      this.setState({
        openModalToEditRelationship: true,
      });
    };
    const handleChangeCloseModalToEditRelationship = () => {
      this.setState({
        openModalToEditRelationship: false,
      });
    };
    //to add company Type
    const handleChangeOpenModalToAddCompanyType = () => {
      this.props.handleChangeCompanyType("");
      this.setState({
        openModalToAddCompanyType: true,
      });
    };
    const handleChangeCloseModalToAddCompanyType = () => {
      this.setState({
        openModalToAddCompanyType: false,
      });
    };
    //to edit company type
    const handleChangeOpenModalToEditCompanyType = () => {
      this.setState({
        openModalToEditCompanyType: true,
      });
    };
    const handleChangeCloseModalToEditCompanyType = () => {
      this.setState({
        openModalToEditCompanyType: false,
      });
    };

    //for relationship
    const handleChangeRelationshipName = (name) => {
      this.props.handleChangeRelationshipName(name);
    };
    const handleChangePermissionName = (permissionName) => {
      this.props.handleChangePermissionName(permissionName);
    };
    const addRelationship = (relationshipName) => {
      this.setState({
        setValue: "add",
      });
      this.props.addRelationship(relationshipName, this.props);
    };
    const editRelationship = (relationshipName) => {
      this.props.updateRelationship(relationshipName, this.props);
    };
    const setRelationshipToEdit = (relationship) => {
      this.props.setRelationshipToEdit(relationship);
    };
    const setRelationshipPermissionList = (permissionData) => {
      this.props.setRelationshipPermissionList(permissionData, this.props);
    };

    //for company type
    const handleChangeCompanyTypeName = (name) => {
      this.props.handleChangeCompanyType(name);
    };
    const addCompanyType = (companyTypeName) => {
      this.setState({
        setValue: "add",
      });
      this.props.addCompanyType(companyTypeName, this.props);
    };
    const editCompanyType = (companyTypeName) => {
      this.setState({
        setValue: "edit",
      });
      this.props.updateCompanyType(companyTypeName, this.props);
    };
    const setCompanyTypeToEdit = (companyType) => {
      this.props.setCompanyTypeToEdit(companyType);
    };
    const searchCompanyType = (term) => {
      this.props.searchCompanyType(term, this.props);
    };

    //IMPORT HOLIDAY CALENDAR

    const handleOpenModalToSelectICSFile = () => {
      this.setState({
        openModalToSelectIcsFile: true,
      });
    };

    const handleCloseModalToSelectICSFile = () => {
      this.setState({
        openModalToSelectIcsFile: false,
      });
    };

    const handleChangeIscFile = (eventFileHandler) => {
      let file = eventFileHandler.target.files[0];
      if (file && file.type === "text/calendar") {
        let type = file.type;
        let singleFile = {
          document: file,
          type: type,
        };
        {
          this.setState(
            {
              isBlocking: true,
            },
            () => {
              this.props.handleChangeICSFile(singleFile, this.props);
            }
          );
        }
      } else {
        this.setState({
          openSnackBar: true,
        });
      }
    };

    //ASSIGN EVENT TO AGENTS/CLIENTS
    const handleOpenToAssignEventToClientOrAgent = () => {
      this.setState({
        openModalToAssignEventToClientsOrAgents: true,
      });
    };
    const handleCloseToAssignEventToClientOrAgent = () => {
      this.setState({
        openModalToAssignEventToClientsOrAgents: false,
      });
    };

    const assignedData = settingsState && settingsState.assignedData;

    //ELIGIBILITY SETTINGS
    const handleOpenModalToAddEligibility = () => {
      this.props.clearEligibilityData();
      this.setState({
        openModalToAddEligibilityData: true,
      });
    };

    const handleCloseModalToAddEligibility = () => {
      this.setState({
        openModalToAddEligibilityData: false,
      });
    };

    const handleOpenModalToUpdateEligibility = () => {
      this.setState({
        openModalToUpdateEligibilityData: true,
      });
    };

    const handleCloseModalToUpdateEligibility = () => {
      this.setState({
        openModalToUpdateEligibilityData: false,
      });
    };

    const handleChangeEligibilityData = (id, value) => {
      this.props.handleChangeEligibilityData(id, value);
    };
    const setEligibilityDataToEdit = (eligibilityData) => {
      this.props.setEligibilityDataToEdit(eligibilityData);
    };
    const addEligibility = (eligibilityData) => {
      this.setState({
        setValue: "addEligibility",
      });
      this.props.addEligibility(eligibilityData, this.props);
    };
    const updateEligibility = (eligibilityData) => {
      this.setState({
        setValue: "updateEligibility",
      });
      this.props.updateEligibility(eligibilityData, this.props);
    };
    // VENDOR TYPE SETTING

    const handleOpenModalToAddVendorType = () => {
      this.setState({
        openModalToAddVendor: true,
      });
    };

    const handleCloseModalToAddVendorType = () => {
      this.setState({
        openModalToAddVendor: false,
      });
    };
    const handleCloseModalToDeleteVendorType = () => {
      this.setState({
        openModalToDeleteVendor: false,
      });
    };
    const handleOpenModalToDeleteVendorType = () => {
      this.setState({
        openModalToDeleteVendor: true,
      });
    };

    const handleChangePageVendorType = (event, newPage) => {
      this.setState({
        pageInVendorType: newPage,
      });
    };
    const handleChangeRowsPerPageVendorType = (event) => {
      this.setState({
        rowsPerPageInVendorType: parseInt(event.target.value, 10),
      });
    };

    const handleChangeVendorTypeInput = (id, value) => {
      this.props.handleChangeVendorTypeInput(id, value);
    };

    const setVendorTypeToEdit = (vendorTypeData) => {
      this.props.setVendorTypeToEdit(vendorTypeData);
    };
    const fetchVendorTypeList = (searchTerm) => {
      this.props.fetchVendorTypeList(searchTerm);
    };
    const addVendorType = (vendorTypeData) => {
      this.setState({
        setValue: "addVendorType",
      });
      this.props.addVendorType(vendorTypeData, this.props);
    };
    const updateVendorType = (vendorTypeData) => {
      this.setState({
        setValue: "addVendorType",
      });
      this.props.updateVendorType(vendorTypeData, this.props);
    };

    // DESCRIPTION SETTING

    const handleOpenModalToAddDescription = () => {
      this.setState({
        openModalToAddDescription: true,
      });
    };

    const handleCloseModalToAddDescription = () => {
      this.setState({
        openModalToAddDescription: false,
      });
    };

    const handleOpenModalToUpdateDescription = () => {
      this.setState({
        openModalToUpdateDescription: true,
      });
    };

    const handleCloseModalToUpdateDescription = () => {
      this.setState({
        openModalToUpdateDescription: false,
      });
    };
    const handleOpenModalToDeleteDescription = () => {
      this.setState({
        openModalToDeleteDescription: true,
      });
    };

    const handleCloseModalToDeleteDescription = () => {
      this.setState({
        openModalToDeleteDescription: false,
      });
    };

    const handleChangePageDescription = (event, newPage) => {
      this.setState({
        pageInDescription: newPage,
      });
    };
    const handleChangeRowsPerPageDescription = (event) => {
      this.setState({
        rowsPerPageInDescription: parseInt(event.target.value, 10),
      });
    };

    const handleChangeDescription = (id, value) => {
      this.props.handleChangeDescription(id, value);
    };
    const setDescriptionToEdit = (descriptionData) => {
      this.props.setDescriptionToEdit(descriptionData);
    };
    const setDefaultDescription = (descriptionId) => {
      this.props.setDefaultDescription(descriptionId, this.props);
    };
    const addDescription = (descriptionData) => {
      this.setState({
        setValue: "addDescription",
      });
      this.props.addDescription(descriptionData, this.props);
    };
    const updateDescription = (descriptionData) => {
      this.setState({
        setValue: "updateDescription",
      });
      this.props.updateDescription(descriptionData, this.props);
    };
    const deleteDescription = (descriptionId) => {
      this.props.deleteDescription(descriptionId, this.props);
    };
    const convertTimeAndFormat = (timeInputValue) => {
      var d = new Date(timeInputValue);
      var hour = d.getHours();
      var minute = d.getMinutes();
      var timeValue = "";
      var timeFormat = "";
      const arrayHrs = [
        12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        10, 11,
      ];
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (hour < 12) {
        timeValue = arrayHrs[hour] + ":" + minute;
        timeFormat = "AM";
      } else {
        timeValue = arrayHrs[hour] + ":" + minute;
        timeFormat = "PM";
      }
      this.props.handleChangeAchUploadTime(timeValue);
      this.props.handleChangeTimeFormat(timeFormat);
    };
    var currentTime = moment();

    // checking account settings

    const addCheckingAccount = (data) => {
      this.props.addCheckingAccount(data, this.props);
    };
    const updateCheckingAccount = (data) => {
      this.props.updateCheckingAccount(data, this.props);
    };
    const setCheckingAccountDataToEdit = (checkingAccountData) => {
      this.props.setCheckingAccountDataToEdit(checkingAccountData);
    };
    const handleChangeCheckingData = (id, value) => {
      this.props.handleChangeCheckingAccountData(id, value);
    };
    const handleChangeCheckingAccountAddress = (id, value) => {
      this.props.handleChangeCheckingAccountAddress(id, value);
    };
    const handleChangeToOpenCheckingAccount = (bool) => {
      this.props.handleChangeToOpenCheckingAccount(bool);
    };
    const changeInitialStartDateValue = (date) => {
      // this.props.handleChangeStartDate(newStartDate);
      // this.props.handleInitialStartDate(date);
      this.setState({
        customStart: true,
      });
      console.log(this.state.customStart);
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Setting" />
        {this.props.state.settingState.loading ? (
          <div
            className={
              this.props.state.settingState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {this.props.state.settingState.success === true ||
        this.props.state.contactState.success === true
          ? this.setState(
              {
                openDialogueToEditHoliday: false,
                openModalToAddRelationship: false,
                openModalToEditRelationship: false,
                openModalToAddCompanyType: false,
                openModalToEditCompanyType: false,
                openModalToAssignPermission: false,
                openModalToSelectIcsFile: false,
                openModalToAssignEventToClientsOrAgents: false,
                openModalToAddEligibilityData: false,
                openModalToUpdateEligibilityData: false,
                openModalToAddDescription: false,
                openModalToUpdateDescription: false,
                openModalToAddVendor: false,
              },
              () => {
                this.props.setAddHolidayToFalse();
                if (this.state.setValue === "add") {
                  this.props.setAddTRelationshipToFalse();
                  this.props.setAddCompanyTypeToFalse();
                } else if (this.state.setValue === "edit") {
                  this.props.setUpdateTRelationshipToFalse();
                  this.props.setUpdateCompanyTypeToFalse();
                } else if (this.state.setValue === "assign") {
                  this.props.setAssignEventSuccessToFalse();
                } else if (this.state.setValue === "addEligibility") {
                  this.props.setAddEligibilitySuccessToFalse();
                } else if (this.state.setValue === "updateEligibility") {
                  this.props.setUpdateEligibilitySuccessToFalse();
                } else if (this.state.setValue === "addDescription") {
                  this.props.setAddDescriptionSuccessToFalse();
                } else if (this.state.setValue === "updateDescription") {
                  this.props.setUpdateDescriptionSuccessToFalse();
                } else if (this.state.setValue === "addVendorType") {
                  this.props.setAddVendorTypeSuccessToFalse();
                  this.props.setUpdateVendorTypeSuccessToFalse();
                } else {
                  this.props.setPermissionToFalse();
                  this.props.setImportHolidayCalendarSuccessToFalse(false);
                }
              }
            )
          : null}

        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Settings
          </Typography>
        </Breadcrumbs>
        <div style={{ paddingTop: "50px", margin: "0 3%" }}>
          <Tabs defaultActiveKey={this.state.tabValue} onChange={callback}>
            <TabPane tab="Calendar Setting" key="1">
              <div>
                <div className={"d-flex flex-row-reverse div-flex"}>
                  <ImportButton
                    onClickEvent={() => {
                      handleOpenModalToSelectICSFile();
                      this.props.handleChangeCalendarType("EVENT");
                    }}
                    onClickHoliday={() => {
                      handleOpenModalToSelectICSFile();
                      this.props.handleChangeCalendarType("HOLIDAY");
                    }}
                    onClickImport={() => {
                      this.props.handleChangeICSFile("");
                    }}
                  />
                  <div style={{ marginRight: "12px" }}>
                    <button
                      className="addButton"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.handleOpenAddHolidayModal(true);
                        this.props.handleChangeHolidayName("");
                        this.props.handleChangeCalendarType("");
                        // this.props.handleInitialStartDate(new Date().setHours(0, 0, 0));
                        this.props.handleChangeStartDate(
                          new Date().setHours(0, 0, 0)
                        );
                        this.props.handleChangeEndDate(
                          new Date().setHours(23, 59, 59)
                        );
                        this.setState({
                          customStart: false,
                        });
                        // this.setState({
                        //   newStartDate: new Date().setHours(0,0,0),
                        // })
                      }}
                    >
                      <AddIcon /> Add Holiday/ Event
                    </button>
                  </div>
                </div>
                <div>
                  <div className={"row"} style={style.card}>
                    <TableContainer className="fixed-table-header-second">
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        ref={this.tableRef}
                      >
                        <TableHead style={{ padding: "1rem" }}>
                          <TableRow>
                            <TableCell
                              className="first-tableHeader"
                              style={{ paddingLeft: "60px" }}
                            >
                              Holiday/ Event Name
                            </TableCell>
                            <TableCell className="tableHeader">
                              Start Date
                            </TableCell>
                            <TableCell className="tableHeader">
                              End Date
                            </TableCell>
                            <TableCell className="tableHeader"></TableCell>
                          </TableRow>
                        </TableHead>
                        {sortedHolidayDetailsList &&
                          sortedHolidayDetailsList
                            .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                                this.state.rowsPerPage
                            )
                            .map((list) => (
                              <TableBody>
                                <TableRow>
                                  <TableCell className="first-tableBody">
                                    {list.eventName}
                                    {list.calendarType ? (
                                      <Tag
                                        color={
                                          list.calendarType === "EVENT"
                                            ? "blue"
                                            : "red"
                                        }
                                        style={{
                                          marginLeft: "10px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {list.calendarType}
                                      </Tag>
                                    ) : null}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {new Intl.DateTimeFormat("default", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(list.startDate)}
                                    {/*{getDate(list.startDate)}*/}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {new Intl.DateTimeFormat("default", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(list.endDate)}
                                  </TableCell>
                                  <TableCell>
                                    <MoreHorizIcon
                                      id="Popover1"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          popoverElement: e.currentTarget,
                                          openActionMenu: true,
                                          holidayList: list,
                                        });
                                      }}
                                    />
                                    <Popover
                                      open={this.state.openActionMenu}
                                      anchorEl={this.state.popoverElement}
                                      onClose={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          openActionMenu: false,
                                        });
                                      }}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                      }}
                                      style={{ boxShadow: "none" }}
                                    >
                                      <MenuList
                                        id="popover-list"
                                        style={{
                                          color: "#4d4d4d",
                                          padding: "1rem !important",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenToAssignEventToClientOrAgent();
                                            this.props.getEventAssignedClientsOrAgent(
                                              this.state.holidayList
                                                .calendarSettingId
                                            );
                                            this.setState({
                                              openActionMenu: false,
                                            });
                                          }}
                                        >
                                          <img
                                            src="/assets/images/assignEvent.svg"
                                            style={{
                                              width: "21px",
                                              marginRight: "8px",
                                            }}
                                          />
                                          Assign To
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogueToEditHoliday();
                                            this.props.setHolidayToEdit(
                                              this.state.holidayList
                                            );
                                            this.setState({
                                              openActionMenu: false,
                                            });
                                          }}
                                        >
                                          <EditOutlinedIcon
                                            style={{
                                              paddingBottom: 0,
                                              marginRight: "8px",
                                            }}
                                          />{" "}
                                          Edit
                                        </MenuItem>
                                        <MenuItem
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleOpenDialogueToDeleteHoliday();
                                            this.setState({
                                              openActionMenu: false,
                                            });
                                          }}
                                        >
                                          <DeleteOutlinedIcon
                                            style={{
                                              paddingBottom: 0,
                                              marginRight: "8px",
                                            }}
                                          />{" "}
                                          Delete
                                        </MenuItem>
                                      </MenuList>
                                    </Popover>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                      </Table>
                    </TableContainer>
                    {sortedHolidayDetailsList &&
                    sortedHolidayDetailsList.length > 0 ? (
                      <div
                        style={{
                          justifyContent: "flex-start",
                          width: "100%",
                          borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        <TablePagination
                          labelRowsPerPage={false}
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={
                            sortedHolidayDetailsList.length > 0
                              ? sortedHolidayDetailsList.length
                              : 100
                          }
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          backIconButtonProps={{
                            "aria-label": "previous page",
                          }}
                          nextIconButtonProps={{
                            "aria-label": "next page",
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            padding: "1rem",
                            color: "#606060",
                          }}
                        >
                          {" "}
                          No data to show
                        </h3>
                      </div>
                    )}
                    {/* </TableContainer> */}
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Payment Setting" key="2">
              <div
                style={{
                  marginLeft: "1px",
                  maxHeight: "70vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="row">
                  <div className="col-4">
                    <label className="label">
                      Fee Percentage <span style={{ color: "red" }}>*</span>
                      <InfoPopover
                        content={
                          <div>
                            Fee % that will be applied in transaction amount
                            (eg: 2, 2.5, 10).
                          </div>
                        }
                      />
                    </label>

                    <input
                      type="number"
                      id="fee"
                      value={feePercentage}
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeFeePercentage(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label className="label">
                      Min. Monthly Fee(Dollar)
                      <span style={{ color: "red" }}>*</span>
                      <InfoPopover
                        content={<div>Minimum fee per month.</div>}
                      />
                    </label>

                    <input
                      type="number"
                      id="fee"
                      value={minMonthlyFee === 0 ? "" : minMonthlyFee / 100}
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeMinMonthlyFee(finalAmount(e));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label className="label">
                      Max. Monthly Fee(Dollar)
                      <span style={{ color: "red" }}>*</span>
                      <InfoPopover
                        content={<div>Maximum fee per month.</div>}
                      />
                    </label>

                    <input
                      type="number"
                      id="fee"
                      value={maxMonthlyFee === 0 ? "" : maxMonthlyFee / 100}
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeMaxMonthlyFee(finalAmount(e));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label className="label">
                      Annual Fee(Dollar) <span style={{ color: "red" }}>*</span>
                      <InfoPopover
                        content={
                          <div>
                            The fee that will be charged to the client on an
                            annual basis (eg: 100, 200).
                          </div>
                        }
                      />
                    </label>
                    <input
                      type="number"
                      id="annualFee"
                      value={annualFee === 0 ? "" : annualFee / 100}
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeAnnualFee(finalAmount(e));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label className="label">
                      ACH Delay Threshold{" "}
                      <span style={{ color: "red" }}>*</span>
                      <InfoPopover
                        content={
                          <div>Estimated ACH return days (eg: 2, 3, 4).</div>
                        }
                      />
                    </label>
                    <input
                      type="number"
                      id="achDelayThreshold"
                      value={achDelayThreshold}
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeAchDelayThreshold(
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label className="label">
                      ACH Upload Time<span style={{ color: "red" }}>*</span>
                      <InfoPopover
                        content={<div>Time For ACH Upload(eg: 02:30AM).</div>}
                      />
                    </label>
                    <div className="select-add-date">
                      <TimePicker
                        value={
                          achUploadTime === ""
                            ? null
                            : moment(`${achUploadTime}${timeFormat}`, format)
                        }
                        format={format}
                        onChange={(e) => {
                          if (e !== null) {
                            const valueOfInput1 = e.format();
                            convertTimeAndFormat(valueOfInput1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 1.5rem",
                  }}
                >
                  <div className={"col-2"}></div>
                  <div className={"col-2"}>
                    <ButtonComponent
                      onClick={(e) => {
                        e.preventDefault();
                        const data = {
                          feePercentage: feePercentage,
                          annualFee: annualFee,
                          minMonthlyFee: minMonthlyFee,
                          maxMonthlyFee: maxMonthlyFee,
                          achDelayThreshold: achDelayThreshold,
                          achUploadTime: achUploadTime,
                          timeFormat: timeFormat,
                        };
                        handleCloseDialogueForFee();
                        this.props.addFeePercentage(data);
                      }}
                      value={paymentSettingValue}
                      buttonName={feePercentage ? "Update" : "Add"}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Relationship" key="3">
              <RelationshipScreen
                propsState={this.props.state}
                property={this.props}
                handleChangePage={handleChangePageInRelationship}
                page={this.state.pageInRelationship}
                tableRef={this.tableRef}
                handleChangeRowsPerPage={handleChangeRowsPerPageInRelationship}
                rowsPerPage={this.state.rowsPerPageInRelationship}
                openModalToAddRelationship={
                  this.state.openModalToAddRelationship
                }
                handleChangeOpenModalToAddRelationship={
                  handleChangeOpenModalToAddRelationship
                }
                handleChangeCloseModalToAddRelationship={
                  handleChangeCloseModalToAddRelationship
                }
                openModalToEditRelationship={
                  this.state.openModalToEditRelationship
                }
                handleChangeOpenModalToEditRelationship={
                  handleChangeOpenModalToEditRelationship
                }
                handleChangeCloseModalToEditRelationship={
                  handleChangeCloseModalToEditRelationship
                }
                handleOpenToAssignPermission={handleOpenToAssignPermission}
                handleCloseToAssignPermission={handleCloseToAssignPermission}
                openModalToAssignPermission={
                  this.state.openModalToAssignPermission
                }
                handleChangeRelationshipName={handleChangeRelationshipName}
                addRelationship={addRelationship}
                editRelationship={editRelationship}
                setRelationshipToEdit={setRelationshipToEdit}
                getRelationshipPermission={
                  this.props.getRelationshipPermissionList
                }
                fetchRelationshipList={this.props.fetchRelationshipList}
                closeSnackBar={this.props.closeContactSnackBar}
                handleChangePermissionName={handleChangePermissionName}
                setRelationshipPermissionList={setRelationshipPermissionList}
              />
            </TabPane>
            <TabPane tab="Company Type" key="4">
              <CompanyTypeScreen
                propsState={this.props.state}
                property={this.props}
                page={this.state.pageInCompanyType}
                rowsPerPage={this.state.rowsPerPageInCompanyType}
                handleChangePageCompanyType={handleChangePageCompanyType}
                handleChangeRowsPerPageCompanyType={
                  handleChangeRowsPerPageCompanyType
                }
                openModalToAddCompanyType={this.state.openModalToAddCompanyType}
                openModalToEditCompanyType={
                  this.state.openModalToEditCompanyType
                }
                handleChangeOpenModalToAddCompanyType={
                  handleChangeOpenModalToAddCompanyType
                }
                handleChangeCloseModalToAddCompanyType={
                  handleChangeCloseModalToAddCompanyType
                }
                handleChangeOpenModalToEditCompanyType={
                  handleChangeOpenModalToEditCompanyType
                }
                handleChangeCloseModalToEditCompanyType={
                  handleChangeCloseModalToEditCompanyType
                }
                //apis
                handleChangeCompanyTypeName={handleChangeCompanyTypeName}
                addCompanyType={addCompanyType}
                editCompanyType={editCompanyType}
                setCompanyTypeToEdit={setCompanyTypeToEdit}
                fetchCompanyType={this.props.fetchCompanyType}
                searchCompanyType={searchCompanyType}
                closeSnackBar={this.props.closeContactSnackBar}
              />
            </TabPane>
            <TabPane tab="Vendor Type" key="5">
              <VendorTypeScreen
                loading={
                  this.props.state &&
                  this.props.state.settingState &&
                  this.props.state.settingState.loading
                }
                settingState={settingsState}
                isUpdate={this.state.isUpdate}
                handleOpenModalToAddVendorType={handleOpenModalToAddVendorType}
                openModalToAddVendor={this.state.openModalToAddVendor}
                handleCloseModalToAddVendorType={
                  handleCloseModalToAddVendorType
                }
                handleCloseModalToDeleteVendorType={
                  handleCloseModalToDeleteVendorType
                }
                openModalToDeleteVendor={this.state.openModalToDeleteVendor}
                handleOpenModalToDeleteVendorType={
                  handleOpenModalToDeleteVendorType
                }
                handleChangeVendorTypeInput={handleChangeVendorTypeInput}
                addVendorType={addVendorType}
                updateVendorType={updateVendorType}
                clearVendorTypeData={this.props.clearVendorTypeData}
                setVendorTypeToEdit={setVendorTypeToEdit}
                fetchVendorTypeData={fetchVendorTypeList}
                checkVendorType={this.props.checkVendorType}
                handleChangePageVendorType={handleChangePageVendorType}
                handleChangeRowsPerPageVendorType={
                  handleChangeRowsPerPageVendorType
                }
                page={this.state.pageInVendorType}
                rowsPerPage={this.state.rowsPerPageInVendorType}
              />
            </TabPane>
            <TabPane tab="Eligibility Setting" key="6">
              <EligibilityScreen
                handleOpenModalToAddEligibility={
                  handleOpenModalToAddEligibility
                }
                handleCloseModalToAddEligibility={
                  handleCloseModalToAddEligibility
                }
                handleOpenModalToUpdateEligibility={
                  handleOpenModalToUpdateEligibility
                }
                handleCloseModalToUpdateEligibility={
                  handleCloseModalToUpdateEligibility
                }
                handleChangeEligibilityData={handleChangeEligibilityData}
                setEligibilityDataToEdit={setEligibilityDataToEdit}
                addEligibility={addEligibility}
                updateEligibility={updateEligibility}
                loading={
                  this.props.state &&
                  this.props.state.settingState &&
                  this.props.state.settingState.loading
                }
                fetchEligibilityData={this.props.fetchEligibilityData}
                openModalToAddEligibilityData={
                  this.state.openModalToAddEligibilityData
                }
                openModalToUpdateEligibilityData={
                  this.state.openModalToUpdateEligibilityData
                }
                settingState={settingsState}
              />
            </TabPane>
            <TabPane tab="Description" key="7">
              <DescriptionScreen
                handleChangePageDescription={handleChangePageDescription}
                handleChangeRowsPerPageDescription={
                  handleChangeRowsPerPageDescription
                }
                page={this.state.pageInDescription}
                rowsPerPage={this.state.rowsPerPageInDescription}
                handleOpenModalToAddDescription={
                  handleOpenModalToAddDescription
                }
                handleCloseModalToAddDescription={
                  handleCloseModalToAddDescription
                }
                handleOpenModalToUpdateDescription={
                  handleOpenModalToUpdateDescription
                }
                handleCloseModalToUpdateDescription={
                  handleCloseModalToUpdateDescription
                }
                handleOpenModalToDeleteDescription={
                  handleOpenModalToDeleteDescription
                }
                handleCloseModalToDeleteDescription={
                  handleCloseModalToDeleteDescription
                }
                openModalToDeleteDescription={
                  this.state.openModalToDeleteDescription
                }
                openModalToAddDescription={this.state.openModalToAddDescription}
                openModalToUpdateDescription={
                  this.state.openModalToUpdateDescription
                }
                fetchDescriptionList={this.props.fetchDescriptionList}
                setDefaultDescription={setDefaultDescription}
                handleChangeDescription={handleChangeDescription}
                setDescriptionToEdit={setDescriptionToEdit}
                addDescription={addDescription}
                updateDescription={updateDescription}
                deleteDescription={deleteDescription}
                settingState={settingsState}
                clearDescriptionModal={this.props.clearDescriptionModal}
                loading={
                  this.props.state &&
                  this.props.state.settingState &&
                  this.props.state.settingState.loading
                }
              />
            </TabPane>
            <TabPane tab="Checking Account" key="8">
              <CheckingAccountScreen
                fetchCheckingAccountsList={this.props.fetchCheckingAccountsList}
                addCheckingAccount={addCheckingAccount}
                updateCheckingAccount={updateCheckingAccount}
                setCheckingAccountDataToEdit={setCheckingAccountDataToEdit}
                handleChangeCheckingData={handleChangeCheckingData}
                handleChangeCheckingAccountAddress={
                  handleChangeCheckingAccountAddress
                }
                handleChangeToOpenCheckingAccount={
                  handleChangeToOpenCheckingAccount
                }
                settingsState={settingsState}
                clearCheckingAccountData={this.props.clearCheckingAccountData}
                handleSettingSnackBarClose={
                  this.props.handleSettingSnackBarClose
                }
              />
            </TabPane>
          </Tabs>
        </div>
        {/*assign holiday/Event*/}
        <Dialog
          open={this.state.openModalToAssignEventToClientsOrAgents}
          onClose={handleCloseToAssignEventToClientOrAgent}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseToAssignEventToClientOrAgent}
            >
              Assign Event
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{ paddingTop: "5px", borderBottom: "none" }}
              className="dialogContent"
            >
              <div className="row">
                <div className="col-lg-12" style={{ marginBottom: "18px" }}>
                  <label className="label">
                    Clients/ Agents
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div
                    className="select-input selects "
                    style={{ width: "100%", minHeight: "40px" }}
                  >
                    <Select
                      showSearch
                      className="drop"
                      value={assignEvent}
                      getPopupContainer={(node) => node.parentNode}
                      onSearch={(value) => {
                        this.props.searchClientOrAgentAccountSuccess(value);
                      }}
                      onInputKeyDown={() => {
                        this.setState({
                          showDropdown: true,
                        });
                      }}
                      mode="tags"
                      onChange={(e) => {
                        this.props.handleChangeEventId(
                          this.state.holidayList.calendarSettingId
                        );
                        this.props.handleChangeAssignEvent(e);
                      }}
                      onSelect={() => {
                        this.setState({
                          showDropdown: false,
                        });
                      }}
                      onBlur={() => {
                        this.setState({
                          showDropdown: false,
                        });
                      }}
                      filterOption={(input, option) =>
                        option &&
                        option.children &&
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      defaultOpen={false}
                      dropdownStyle={
                        this.state.showDropdown
                          ? {
                              display: "block",
                              maxHeight: "150px",
                            }
                          : { display: "none" }
                      }
                    >
                      {clientOrAgentAccount
                        ? clientOrAgentAccount.map((account) => (
                            <Option
                              key={account.accountId}
                              value={account.accountId}
                            >
                              {account.emailPhone}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">Holiday/ Event Name</label>
                  <input
                    disabled
                    className="inputField"
                    value={
                      this.state.holidayList && this.state.holidayList.eventName
                    }
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
              <Button
                disabled={!assignValue}
                onClick={() => {
                  this.setState({
                    setValue: "assign",
                  });
                  const assignedData = {
                    eventId: eventId,
                    assignEvent:
                      assignEvent &&
                      assignEvent.map((event) => {
                        return {
                          assignTo: event,
                        };
                      }),
                  };
                  this.props.assignEventToClientsOrAgent(
                    assignedData,
                    this.props
                  );
                }}
                className={assignValue ? "addButton" : "disabledAddButton"}
              >
                Assign
              </Button>
            </DialogActions>
          </div>
        </Dialog>

        {/*add holiday*/}
        <Dialog
          open={open}
          onClose={() => {
            this.props.handleCloseAddHolidayModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                this.props.handleCloseAddHolidayModal(false);
              }}
            >
              Add Holiday/ Event
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                paddingTop: "5px",
                borderBottom: "none",
                // maxHeight: "400px",
              }}
              className="dialogContent"
            >
              <div>
                <label className="label">
                  Calendar Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div className="select-input h40">
                  <Select
                    className="inputField"
                    showSearch={false}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.props.handleChangeCalendarType(e);
                    }}
                  >
                    <Option value="HOLIDAY">Holiday</Option>
                    <Option value="EVENT">Event</Option>
                  </Select>
                </div>
              </div>
              <div style={{ marginTop: "12px" }}>
                <label className="label">
                  Holiday/ Event Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="holiday"
                  className="input"
                  autoComplete="off"
                  onChange={(e) => {
                    this.props.handleChangeHolidayName(e.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Start Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      // dateValue={newStartDate && getDateWithStartTimeValue(newStarttDate)}
                      dateValue={
                        startDate && getDateWithStartTimeValue(startDate)
                      }
                      // dateValue={this.state.customStart === true && getDateWithStartTimeValue(startDate)}
                      onChangeDate={(date) => {
                        changeInitialStartDateValue(date);
                        this.props.handleChangeStartDate(date);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-12" style={{ marginTop: "12px" }}>
                  <label className="label">
                    End Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={endDate && getDateWithEndTimeValue(endDate)}
                      onChangeDate={(date) => {
                        // const endDate = new Date(date).setHours(23,59,59);
                        this.props.handleChangeEndDate(date);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "14px" }}>
                  <ButtonComponent
                    onClick={(e) => {
                      e.preventDefault();
                      const data = {
                        eventName: holidayName,
                        startDate: startDate,
                        endDate: endDate,
                        calendarType: calendarType,
                      };
                      if (!this.state.clicked) {
                        this.setState({
                          clicked: true,
                        });
                        this.props.addHolidays(data, this.props);
                        setTimeout(() => {
                          this.setState({
                            clicked: false,
                          });
                        }, 3000);
                      }
                    }}
                    value={holidayValue}
                    buttonName="Add"
                    loading={loading}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*import holiday*/}
        <Dialog
          open={this.state.openModalToSelectIcsFile}
          onClose={handleCloseModalToSelectICSFile}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseModalToSelectICSFile}
            >
              Select Calendar File
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="note" className="label">
                    Document <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    {icsFile ? (
                      <img
                        src="/assets/images/ICSFile.webp"
                        alt="pdf"
                        style={{
                          width: "50px",
                          marginBottom: "8px",
                          marginTop: "7px",
                        }}
                      />
                    ) : null}
                  </div>
                  <div>
                    <label
                      style={{
                        width: "85px",
                        marginTop: "0.5rem",
                        background: "rgba(204,204,254,0.32)",
                        border: "1px solid #7b7b7b",
                        textAlign: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          e.preventDefault();
                          handleChangeIscFile(e);
                        }}
                        className="hidden"
                        style={{ display: "none" }}
                      />
                      Select file
                    </label>
                    <span style={{ fontStyle: "italic" }}>
                      {" "}
                      (Select ICS file)
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={"col-6"}></div>
                <div className={"col-6"} style={{ paddingLeft: "134px" }}>
                  <Button
                    disabled={!value}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.uploadISVFileToImport(
                        icsFile,
                        calendarType,
                        this.props
                      );
                    }}
                    style={{ textTransform: "capitalize" }}
                    className={value ? "addButton" : "disabledAddButton"}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*edit holiday*/}
        <Dialog
          open={this.state.openDialogueToEditHoliday}
          onClose={handleCloseDialogueToEditHoliday}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogueToEditHoliday}
            >
              Update Holiday/ Event
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                paddingTop: "5px",
              }}
              className="dialogContent"
            >
              <div>
                <label className="label">
                  Calendar Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div className="select-input h40">
                  <Select
                    value={calendarType}
                    className="inputField"
                    showSearch={false}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.props.handleChangeCalendarType(e);
                    }}
                  >
                    <Option value="HOLIDAY">Holiday</Option>
                    <Option value="EVENT">Event</Option>
                  </Select>
                </div>
              </div>
              <div style={{ paddingTop: "12px" }}>
                <label className="label">
                  Holiday/Event Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  value={holidayName}
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeHolidayName(e.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Start Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={
                        startDate && getFormattedDateTimeValue(startDate)
                      }
                      onChangeDate={(date) => {
                        this.props.handleChangeStartDate(date);
                      }}
                    />
                    {/*<DatePicker*/}
                    {/*  className="rangeBase"*/}
                    {/*  showTime={{*/}
                    {/*    defaultValue: moment("00:00:00", "HH:mm:ss"),*/}
                    {/*  }}*/}
                    {/*  dropdownClassName="holiday-start-datepicker"*/}
                    {/*  placeholder="YYYY-MM-DD HH:mm:ss"*/}
                    {/*  getPopupContainer={(trigger) => trigger.parentNode}*/}
                    {/*  value={startDate && getDateFromPicker(startDate)}*/}
                    {/*  dateFormat="YYYY-MM-DD"*/}
                    {/*  onChange={handleChangeStartDate}*/}
                    {/*  showToday={false}*/}
                    {/*/>*/}
                  </div>
                </div>
                <div className="col-lg-12" style={{ marginTop: "12px" }}>
                  <label className="label">
                    End Date
                    <span style={{ color: "red" }}> *</span>
                  </label>
                  <div>
                    <KeyBoardDatePickerComponent
                      dateTimeFormat
                      isCalendarDate
                      dateValue={endDate && getFormattedDateTimeValue(endDate)}
                      onChangeDate={(date) => {
                        this.props.handleChangeEndDate(date);
                      }}
                    />
                    {/*<DatePicker*/}
                    {/*  className="rangeBase"*/}
                    {/*  showTime={{*/}
                    {/*    defaultValue: moment("23:59:59", "HH:mm:ss"),*/}
                    {/*  }}*/}
                    {/*  getPopupContainer={(trigger) => trigger.parentNode}*/}
                    {/*  value={endDate && getDateFromPicker(endDate)}*/}
                    {/*  placeholder="YYYY-MM-DD HH:mm:ss"*/}
                    {/*  dateFormat="YYYY-MM-DD"*/}
                    {/*  onChange={handleChangeEndDate}*/}
                    {/*  showToday={false}*/}
                    {/*/>*/}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingTop: "14px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const data = {
                        calendarSettingId:
                          this.state.holidayList.calendarSettingId,
                        eventName: holidayName,
                        startDate: startDate,
                        endDate: endDate,
                        calendarType: calendarType,
                      };
                      this.props.updateHolidays(data, this.props);
                      // handleCloseDialogueToEditHoliday();
                    }}
                    disabled={!holidayValue}
                    className={holidayValue ? "addButton" : "disabledAddButton"}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*DELETE*/}
        <Dialog
          open={this.state.openDeleteHolidayDialogue}
          onClose={handleCloseDialogueToDeleteHoliday}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete this holiday?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToDeleteHoliday}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkToDeleteHoliday();
                      handleCloseDialogueToDeleteHoliday();
                    }}
                    className="addUserButton"
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={settingsState && settingsState.openCalendarSettingSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="success"
            message="Successfully added"
          />
        </Snackbar>
        {settingsState && settingsState.success === true ? (
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={settingsState && settingsState.openSettingUpdate}
            autoHideDuration={3000}
            onClose={this.props.handleSettingSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleSettingSnackBarClose}
              variant="success"
              message="Updated successfully"
            />
          </Snackbar>
        ) : null}

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={settingsState && settingsState.openSettingDelete}
          autoHideDuration={3000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="success"
            message="Deleted successfully"
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            settingsState && settingsState.openPaymentSettingSuccessSnackBar
          }
          autoHideDuration={3000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="success"
            message="Successfully send"
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={settingsState && settingsState.openSettingErrorSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="error"
            message={errorMsg}
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            settingsState && settingsState.openCalendarSettingSuccessSnackbar
          }
          autoHideDuration={3000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="success"
            message={settingsState && settingsState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={
            settingsState && settingsState.openCalendarSettingFailureSnackbar
          }
          autoHideDuration={5000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="error"
            message={settingsState && settingsState.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openSnackBar}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({
              openSnackBar: false,
            });
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.setState({
                openSnackBar: false,
              });
            }}
            variant="error"
            message="Invalid file type"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={settingsState && settingsState.openSettingSuccessSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="success"
            message={settingsState && settingsState.successMsg}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={settingsState && settingsState.openSettingErrorSnackBar}
          autoHideDuration={5000}
          onClose={this.props.handleSettingSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSettingSnackBarClose}
            variant="error"
            message={settingsState && settingsState.error}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  card: {
    background: "#ffffff",
    border: "1px solid rgb(224, 224, 224)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginLeft: 0,
    marginBottom: "1rem",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "100%",
    height: "40px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "90%",
    paddingTop: "0px",
    marginTop: "11px",
    paddingLeft: "15px",
  },
  block: {
    display: "block",
  },
  none: {
    display: "none",
  },
};

export default SettingScreen;
