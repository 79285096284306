import { connect } from "react-redux";
import {
  clearUpTransFilter,
  closeUpcomingTransSnackBar,
  fetchClientUpcomingTransactionFailure,
  fetchClientUpcomingTransactionSuccess,
  handlechangeRemarkToSkip,
  handleChangeToOpenSkipModal,
  handleUpTransFilter,
  searchUpcomingTransByMediumAndDateFailure,
  searchUpcomingTransByMediumAndDateSuccess,
} from "../../actions/UpComingTransactionAction";
import { ClientAPI, UpcomingTransactionAPI, VendorAPI } from "../../api";
import IndividualClientUpcomingTxnScreen from "../../screens/IndividualClientUpcomingTxnScreen";
import {
  upComingTransactionRequestMade,
  processTransactionSuccess,
  processTransactionFailure,
} from "../../actions/UpComingTransactionAction";
import {
  getTransConfigFailure,
  getTransConfigSuccess,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleChangeMedium,
  handleChangeTransType,
  handleChangeVendor,
  saveTransConfigFailure,
  saveTransConfigSuccess,
} from "../../actions/TransactionAction";
import {
  clientRequestMade,
  fetchSingleClientFailure,
  fetchSingleClientSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  vendorRequestMade,
} from "../../actions";

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
  return {
    // get all client ledger transaction

    getClientUpcomingTrans: (clientId) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.getClientUpcomingTransList(
        clientId,

        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },
    // fetch client ledger transaction
    fetchClientUpcomingTransactionList: (
      clientId,
      from,
      to,
      medium,
      term,
      vendor,
      type
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.fetchClientUpcomingTransactionList(
        clientId,
        from === null ? 0 : from,
        to === null ? 0 : to,
        medium,
        term,
        vendor,
        type,
        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },
    handleChangeDateFrom: (from) => dispatch(handleChangeDateFrom(from)),
    handleChangeDateTo: (to) => dispatch(handleChangeDateTo(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),

    fetchSingleClient: (clientId, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchSingleClient(clientId, (response, error) => {
        if (response) {
          dispatch(fetchSingleClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchSingleClientFailure(error.msg));
          } else {
            dispatch(fetchSingleClientFailure(error));
          }
        }
      });
    },

    handleChangeToOpenSkipModal: (bool) => {
      dispatch(handleChangeToOpenSkipModal(bool));
    },
    handleChangeRemarkToSkip: (remarkToSkip) => {
      dispatch(handlechangeRemarkToSkip(remarkToSkip));
    },

    processUpcomingTransaction: (data, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.processUpcomingTransaction(
        data,
        (response, error) => {
          if (response) {
            dispatch(processTransactionSuccess(response));
            props.fetchClientUpcomingTransactionList(
              data.clientId,
              0,
              0,
              "UNKNOWN_TRANSACTION_MEDIUM",
              "",
              "",
              "UNKNOWN_TRANSACTION_TYPE"
            );
          } else {
            if (error.msg) {
              dispatch(processTransactionFailure(error.msg));
            } else {
              dispatch(processTransactionFailure(error));
            }
          }
        }
      );
    },

    skipUpcomingTransaction: (data, clientId, props) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.skipUpcomingTransaction(
        data,
        (response, error) => {
          if (response) {
            dispatch(processTransactionSuccess(response));
            props.fetchClientUpcomingTransactionList(
              clientId,
              0,
              0,
              "UNKNOWN_TRANSACTION_MEDIUM",
              "",
              "",
              "UNKNOWN_TRANSACTION_TYPE"
            );
            props.handleChangeToOpenSkipModal(false);
          } else {
            if (error.msg) {
              dispatch(processTransactionFailure(error.msg));
              props.handleChangeToOpenSkipModal(true);
            } else {
              dispatch(processTransactionFailure(error));
              props.handleChangeToOpenSkipModal(true);
            }
          }
        }
      );
    },
    handleChangeUpcomingTransSnackBar: () =>
      dispatch(closeUpcomingTransSnackBar(true)),
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    handleChangeVendor: (vendor) => dispatch(handleChangeVendor(vendor)),
    handleChangeTransType: (type) => dispatch(handleChangeTransType(type)),

    // filter transaction

    searchUpcomingTransactionByAll: (
      // startDate,
      // endDate,
      clientName,
      vendorName,
      transactionMedium,
      transactionType,
      // transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByAll(
        // startDate === null ? "" : startDate,
        // endDate === null ? "" : endDate,
        clientName,
        vendorName,
        transactionMedium,
        transactionType,
        // transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },

    // filter client upcoming transaction transaction

    searchUpcomingTransactionByClient: (
      // startDate,
      // endDate,
      clientId,
      vendorName,
      transactionMedium,
      transactionType,
      // transactionStatus,
      transactionDate,
      scheduleDate,
      amount,
      compareType
    ) => {
      dispatch(upComingTransactionRequestMade(true));
      UpcomingTransactionAPI.searchUpcomingTransactionByClient(
        // startDate === null ? "" : startDate,
        // endDate === null ? "" : endDate,
        clientId,
        vendorName,
        transactionMedium,
        transactionType,
        // transactionStatus,
        transactionDate === null ? "" : transactionDate,
        scheduleDate === null ? "" : scheduleDate,
        amount,
        compareType,
        (response, error) => {
          if (response) {
            dispatch(fetchClientUpcomingTransactionSuccess(response));
          } else {
            if (error.msg) {
              dispatch(fetchClientUpcomingTransactionFailure(error.msg));
            } else {
              dispatch(fetchClientUpcomingTransactionFailure(error));
            }
          }
        }
      );
    },

    handleUpTransFilter: (id, value) => {
      dispatch(handleUpTransFilter(id, value));
    },

    clearUpTransFilter: () => {
      dispatch(clearUpTransFilter());
    },
    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(getTransConfigFailure(error.msg));
          } else {
            dispatch(getTransConfigFailure(error));
          }
        } else {
          dispatch(getTransConfigSuccess(response.configs));
        }
      });
    },
    saveInConfigfor: (data) => {
      UpcomingTransactionAPI.saveConfigUP(data, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(saveTransConfigFailure(error.msg));
          } else {
            dispatch(saveTransConfigFailure(error));
          }
        } else {
          dispatch(saveTransConfigSuccess(response));
        }
      });
    },
  };
};

export const IndividualClientUpcomingTxnContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualClientUpcomingTxnScreen);
