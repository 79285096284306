import { connect } from 'react-redux';
import ShippingLabelScreen from "../../screens/ShippingLabelScreen";
import {ShippingApi} from "../../api";
import {
    addShippingAddressFailure,
    addShippingAddressSuccess,
    closeShippingSnackBar,
    deleteShippingAddressFailure,
    deleteShippingAddressSuccess,
    fetchShippingListByIdFailure,
    fetchShippingListByIdSuccess,
    fetchShippingListFailure,
    fetchShippingListSuccess,
    handleChangeShippingValue,
    requestShippingLabel,
    searchShippingFailure, searchShippingSuccess,
    setAddShippingAddressToFalse,
    setShippingAddressToEmpty,
    setShippingToEdit,
    updateShippingAddressFailure,
    updateShippingAddressSuccess
} from "../../actions/ShippingLabelAction";
import {setContactDataToEmpty} from "../../actions/ContactAction";
import CorporateAPI from "../../api/CorporateAPI";
import {searchCorporateFailure, searchCorporateSuccess} from "../../actions/CorporateAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
   return{
       fetchShippingList: () => {
           dispatch(requestShippingLabel(true));
           ShippingApi.fetchShippingList((response, error) => {
               if (response) {
                   dispatch(fetchShippingListSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchShippingListFailure(error.msg))
                   } else {
                       dispatch(fetchShippingListFailure(error));
                   }
               }
           })
       },
       fetchShippingListById: (id, props) => {
           dispatch(requestShippingLabel(true));
           ShippingApi.fetchShippingListById(id,(response, error) => {
               if (response) {
                   dispatch(fetchShippingListByIdSuccess(response));
               } else {
                   if (error.msg) {
                       dispatch(fetchShippingListByIdFailure(error.msg))
                   } else {
                       dispatch(fetchShippingListByIdFailure(error));
                   }
               }
           })
       },
       handleChangeShippingValue : (id, value) => {dispatch(handleChangeShippingValue(id,value))},
       closeSnackBar: () => {dispatch(closeShippingSnackBar(true))},
       setShippingAddrToEdit: (shippingData) =>{dispatch(setShippingToEdit(shippingData))},

       setAddShippingAddressToFalse : () => {dispatch(addShippingAddressSuccess(false))},
       setUpdateShippingAddressToFalse : () => {dispatch(updateShippingAddressSuccess(false))},

       setSettingAddressToEmpty: () => {
           dispatch(setShippingAddressToEmpty())
       },
       addShippingAddress: (shippingData, props) => {
           dispatch(requestShippingLabel(true));
           ShippingApi.addShippingAddress(shippingData,(response, error) => {
               if (response) {
                   dispatch(addShippingAddressSuccess(true));
                   props.fetchShippingList();
               } else {
                   if (error.msg) {
                       dispatch(addShippingAddressFailure(error.msg))
                   } else {
                       dispatch(addShippingAddressFailure(error));
                   }
               }
           })
       },
       updateShippingAddress: (shippingData, props) => {
           dispatch(requestShippingLabel(true));
           ShippingApi.updateShippingAddress(shippingData,(response, error) => {
               if (response) {
                   dispatch(updateShippingAddressSuccess(true));
                   props.fetchShippingList();
               } else {
                   if (error.msg) {
                       dispatch(updateShippingAddressFailure(error.msg))
                   } else {
                       dispatch(updateShippingAddressFailure(error));
                   }
               }
           })
       },
       deleteShippingAddress: (shippingId, props) => {
           dispatch(requestShippingLabel(true));
           ShippingApi.deleteShippingAddress(shippingId,(response, error) => {
               if (response) {
                   dispatch(deleteShippingAddressSuccess(response));
                   props.fetchShippingList();
               } else {
                   if (error.msg) {
                       dispatch(deleteShippingAddressFailure(error.msg))
                   } else {
                       dispatch(deleteShippingAddressFailure(error));
                   }
               }
           })
       },

       //search
       searchShipping: (value) => {
           ShippingApi.searchShippingAddress(value, (response, error) => {
               if (error) {
                   if (error.msg) {
                       dispatch(searchShippingFailure(error.msg))
                   } else {
                       dispatch(searchShippingFailure(error));
                   }
               } else {
                   dispatch(searchShippingSuccess(response));
               }
           })
       },
   }
};

export const ShippingLabelContainer = connect(mapStateToProps, mapDispatchToProps)(ShippingLabelScreen);