import APIEndPoints from "../constants/APIConstants";
import API from "./API";

class CheckPrintAPI extends API {
  fetchCheckPrintList = (callback) => {
    fetch(APIEndPoints.FETCH_CHECK_PRINT, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  fetchBulkCheckPrintList = (callback) => {
    fetch(APIEndPoints.FETCH_BULK_CHECK_PRINT, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  setCheckPrint = (checkPrint, callback) => {
    fetch(APIEndPoints.SET_CHECK_PRINT, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(checkPrint),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  printManualCheck = (checkData, callback) => {
    fetch(APIEndPoints.PRINT_MANUAL_CHECK, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(checkData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  printBulkCheck = (bulkCheckData, callback) => {
    fetch(APIEndPoints.PRINT_BULK_CHECK, {
      method: "POST",
      headers: this.authHeaders,
      body: JSON.stringify(bulkCheckData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  updateCheck = (checkData, callback) => {
    fetch(APIEndPoints.UPDATE_CHECK_STATUS, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(checkData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  filterCheckPrintByBank = (bank, callback) => {
    const urlString = APIEndPoints.FILTER_CHECK_PRINT + "term=" + bank;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByDate = (from, to, callback) => {
    const urlString =
      APIEndPoints.FILTER_CHECK_PRINT + "from=" + from + "&to=" + to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByAmount = (amount, callback) => {
    const urlString = APIEndPoints.FILTER_CHECK_PRINT + "amount=" + amount;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByTerm = (term, callback) => {
    const urlString = APIEndPoints.FILTER_CHECK_PRINT + "term=" + term;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  //FILTER BY TWO
  filterCheckPrintByBankAndDate = (bank, from, to, callback) => {
    const urlString =
      APIEndPoints.FILTER_CHECK_PRINT +
      "term=" +
      bank +
      "&from=" +
      from +
      "&to=" +
      to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByBankAndAmount = (bank, amount, callback) => {
    const urlString =
      APIEndPoints.FILTER_CHECK_PRINT + "term=" + bank + "&amount=" + amount;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByDateAndAmount = (from, to, amount, callback) => {
    const urlString =
      APIEndPoints.FILTER_CHECK_PRINT +
      "from=" +
      from +
      "&to=" +
      to +
      "&amount=" +
      amount;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByBankAndDateAndAmount = (
    bank,
    from,
    to,
    amount,
    callback
  ) => {
    const urlString =
      APIEndPoints.FILTER_CHECK_PRINT +
      "term=" +
      bank +
      "&from=" +
      from +
      "&to=" +
      to +
      "&amount=" +
      amount;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
  filterCheckPrintByAll = (
    checkNo,
    bankName,
    vendorName,
    // status,
    date,
    amount,
    compareType,
    callback
  ) => {
    const urlString =
      APIEndPoints.FILTER_CHECK_PRINT_ALL +
      "bankName=" +
      bankName +
      "&checkNo=" +
      checkNo +
      "&vendorName=" +
      vendorName +
      "&amount=" +
      amount +
      // "&status=" +
      // status +
      "&date=" +
      date +
      "&compareType=" +
      compareType;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  exportChecks = (from, to, callback) => {
    const urlString = APIEndPoints.EXPORT_CHECK_PRINT + from + "&to=" + to;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  getConfigList = (type, callback) => {
    const urlString = APIEndPoints.GET_FILTER_CONFIG + type;
    fetch(urlString, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  saveConfig = (data, callback) => {
    const urlString =
      APIEndPoints.SAVE_FILTER_CONFIG +
      "amount=" +
      data.amount +
      "&checkNo=" +
      data.checkNo +
      "&bankName=" +
      data.bankName +
      "&vendorName=" +
      data.vendorName +
      "&date=" +
      data.date +
      "&configName=" +
      data.configName +
      "&type=" +
      data.type +
      "&compareType=" +
      data.compareType;

    fetch(urlString, {
      method: "POST",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
}

export default new CheckPrintAPI();
