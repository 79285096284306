import { connect } from 'react-redux';
import CalendarSettingScreen from "../../screens/CalendarSettingScreen";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = () => {
    console.log("")
};

export const CalendarSettingContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarSettingScreen);