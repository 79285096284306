import {ACTION_TYPE_EMAIL_TEMPLATE} from "../constants/Type";

const initialState = {
    loading: false,
    error: '',
    successMsg: '',
    openErrorSnackBar: false,
    openSuccessSnackBar: false,
    emailTemplateList: [],
    successResponse: false,
    emailTemplatesData: {
            templateName: "",
            subjectPart: "",
            htmlPart: '',
            textPart: "",
    },
    htmlPart:'',
    templateAssign: {
        emailTemplatedId: "",
        emailType: "",
    },
    templateAssignList: [],
}

const emailTemplateState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_EMAIL_TEMPLATE.REQUEST_MADE_IN_EMAIL_TEMPLATE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.CLOSE_SNACK_BAR:
            return {
                ...state,
                loading: false,
                openErrorSnackBar: false,
                openSuccessSnackBar: false,
            }
        case ACTION_TYPE_EMAIL_TEMPLATE.HANDLE_CHANGE_EMAIL_TEMPLATE_DATA:
            const newForm = {...state.emailTemplatesData};
            newForm[action.id] = action.value;
            return {
                ...state,
                emailTemplatesData: newForm,
                loading: false
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.HANDLE_CHANGE_HTML_PART:
            return {
                ...state,
                htmlPart: action.htmlPart,
                loading: false,
            }
        case ACTION_TYPE_EMAIL_TEMPLATE.SET_EMAIL_TEMPLATE_TO_EDIT:
            return {
                ...state,
                loading: false,
                emailTemplatesData: action.templateData,
                htmlPart: action.templateData.htmlPart
                // templateAssign: action.templateData,
            }
        case ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                emailTemplateList: action.response.emailTemplates,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openErrorSnackBar: true,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.POST_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                successResponse: action.bool,
                openSuccessSnackBar: true,
                successMsg: "Successfully Added"
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.POST_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openErrorSnackBar: true,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                successResponse: action.bool,
                openSuccessSnackBar: true,
                successMsg: "Successfully Updated"
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                openErrorSnackBar: true,
                error: action.error,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.DELETE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                successResponse: action.bool,
                openSuccessSnackBar: true,
                successMsg: "Successfully Deleted"
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.DELETE_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                openErrorSnackBar: true,
                error: action.error,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.CLEAR_TEMPLATE_MODAL:
            return {
                ...state,
                loading: false,
                emailTemplatesData: {
                    templateName: "",
                    subjectPart: "",
                    htmlPart: '',
                    textPart: "",
                },
                templateAssign: {
                    emailTemplatedId: "",
                    emailType: "",
                },
                htmlPart: '',
            }
            //****** assign template ******
        case ACTION_TYPE_EMAIL_TEMPLATE.HANDLE_CHANGE_ASSIGN_TEMPLATE_DATA:
            const newAssignForm = {...state.templateAssign};
            newAssignForm[action.id] = action.value;
            return {
                ...state,
                templateAssign: newAssignForm,
                loading: false
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.SET_ASSIGNED_EMAIL_TEMPLATE_TO_EDIT:
            console.log("action.template assign", action.templateAssign);
            return {
                ...state,
                loading: false,
                templateAssign: action.templateAssign,
            }
        case ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                templateAssignList: action.response.templateAssigns,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.FETCH_EMAIL_TYPE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openErrorSnackBar: true,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                successResponse: action.bool,
                openSuccessSnackBar: true,
                successMsg: "Successfully Assigned"
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                openErrorSnackBar: true,
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                successResponse: action.bool,
                openSuccessSnackBar: true,
                successMsg: "Successfully Updated"
            };
        case ACTION_TYPE_EMAIL_TEMPLATE.UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                openErrorSnackBar: true,
                error: action.error,
            };

        default:
            return state;
    }
};
export default emailTemplateState;