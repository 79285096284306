import APIEndPoints from '../constants/APIConstants';
import API from "./API";

class ShippingAPI extends API {
    fetchShippingList = callback => {
        fetch(APIEndPoints.FETCH_SHIPPING_LIST, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    fetchShippingListById = (id,callback) => {
        fetch(APIEndPoints.FETCH_SHIPPING_LIST_BY_ID + id, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    addShippingAddress = (shippingData, callback) => {
        fetch(APIEndPoints.ADD_SHIPPING_ADDRESS, {
            method: 'POST',
            headers: this.authHeaders,
            body: JSON.stringify(shippingData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    updateShippingAddress = (shippingData, callback) => {
        fetch(APIEndPoints.UPDATE_SHIPPING_ADDRESS, {
            method: 'PATCH',
            headers: this.authHeaders,
            body: JSON.stringify(shippingData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    deleteShippingAddress = (shippingId, callback) => {
        const urlString = APIEndPoints.DELETE_SHIPPING_ADDRESS + shippingId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    fetchShippingLabelList = callback => {
        fetch(APIEndPoints.FETCH_SHIPPING_LABEL, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    printShippingLabel = (shippingData, callback) => {
        fetch(APIEndPoints.PRINT_SHIPPING_LABEL, {
            method: 'POST',
            headers: this.authHeaders,
            body: JSON.stringify(shippingData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    trackShippingInfo = (id,callback) => {

        fetch(APIEndPoints.TRACK_SHIPMENT + id, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    //SEARCH SHIPPING ADDRESS
    searchShippingAddress = (value, callback) => {
        const urlString = APIEndPoints.SEARCH_SHIPPING_ADDRESS + value;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };
}

export default new ShippingAPI();