import { ACTION_TYPE_UPCOMING_TRANSACTION } from "../constants/Type";
export const upComingTransactionRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.UP_COMING_TRANSACTION_REQUEST,
    bool,
  };
};

export const closeUpcomingTransSnackBar = (bool) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.CLOSE_SNACK_BAR,
    bool,
  };
};

export const fetchUpcomingTransactionSuccess = (upComingTransactionData) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_UP_COMING_TRANSACTION_SUCCESS,
    upComingTransactionData,
  };
};

export const fetchUpcomingTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_UP_COMING_TRANSACTION_FAILURE,
    error,
  };
};
export const fetchAnnualUpcomingTransactionSuccess = (
  annualUpComingTransactionData
) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_ANNUAL_UP_COMING_TRANSACTION_SUCCESS,
    annualUpComingTransactionData,
  };
};

export const fetchAnnualUpcomingTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_ANNUAL_UP_COMING_TRANSACTION_FAILURE,
    error,
  };
};
export const processTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.PROCESS_TRANSACTION_SUCCESS,
    response,
  };
};

export const processTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.PROCESS_TRANSACTION_FAILURE,
    error,
  };
};
export const searchUpcomingTransByTermSuccess = (searchRes) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_TERM_SUCCESS,
    searchRes,
  };
};

export const searchUpcomingTransByTermFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_TERM_FAILURE,
    error,
  };
};

export const searchAnnualUpcomingTransByTermSuccess = (searchRes) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_SUCCESS,
    searchRes,
  };
};

export const searchAnnualUpcomingTransByTermFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_FAILURE,
    error,
  };
};

export const searchUpcomingTransByDateSuccess = (searchRes) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_DATE_SUCCESS,
    searchRes,
  };
};

export const searchUpcomingTransByDateFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_DATE_FAILURE,
    error,
  };
};

export const searchUpcomingTransByMediumSuccess = (searchRes) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_SUCCESS,
    searchRes,
  };
};

export const searchUpcomingTransByMediumFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_FAILURE,
    error,
  };
};

export const searchUpcomingTransByMediumAndDateSuccess = (searchRes) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS,
    searchRes,
  };
};

export const searchUpcomingTransByMediumAndDateFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE,
    error,
  };
};

export const fetchClientUpcomingTransactionSuccess = (response) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_CLIENT_UP_COMING_TRANSACTION_SUCCESS,
    response,
  };
};

export const fetchClientUpcomingTransactionFailure = (error) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.FETCH_CLIENT_UP_COMING_TRANSACTION_FAILURE,
    error,
  };
};

export const handleChangeToOpenSkipModal = (bool) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.HANDLE_CHANGE_OPEN_MODAL_TO_SKIP_TRANSACTION,
    bool,
  };
};

export const handlechangeRemarkToSkip = (remarkToSkip) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.HANDLE_CHANGE_REMARK_TO_SKIP,
    remarkToSkip,
  };
};

// new filter
export const handleUpTransFilter = (id, value) => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.HANDLE_UP_TRANS_FILTER,
    id,
    value,
  };
};
export const clearUpTransFilter = () => {
  return {
    type: ACTION_TYPE_UPCOMING_TRANSACTION.CLEAR_UP_TRANS_FILTER,
  };
};
