import { connect } from "react-redux";
import CheckPrintScreen from "../../screens/CheckPrintScreen";
import {
  fetchVendorFailure,
  fetchVendorSuccess,
  searchClientListFailure,
  searchClientListSuccess,
  vendorRequestMade,
} from "../../actions";
import {
  bulkCheckPrintFailure,
  bulkCheckPrintSuccess,
  checkPrintRequestMade,
  closeModalForCheckPrint,
  closeSnackBarForCheckPrint,
  fetchCheckPrintFailure,
  fetchCheckPrintSuccess,
  filterByAmountFailure,
  filterByAmountSuccess,
  filterByBankAndAmountFailure,
  filterByBankAndAmountSuccess,
  filterByBankAndDateAndAmountFailure,
  filterByBankAndDateAndAmountSuccess,
  filterByBankAndDateFailure,
  filterByBankAndDateSuccess,
  filterByBankFailure,
  filterByBankSuccess,
  filterByDateAmountFailure,
  filterByDateAmountSuccess,
  filterByDateFailure,
  filterByDateSuccess,
  handleChangeAmount,
  handleChangeClientIdForCheck,
  handleChangeDate,
  handleChangeEndDate,
  handleChangeMemo,
  handleChangePayTo,
  handleChangeAddress1,
  handleChangeAddress2,
  handleChangeCity,
  handleChangeState,
  handleChangeZipCode,
  handleChangeAttachment,
  handleChangeSelectBank,
  handleChangeStartDate,
  manualCheckPrintFailure,
  manualCheckPrintSuccess,
  setManualCheckDataEmpty,
  handleChangeDescription,
  delcineCheckSuccess,
  delcineCheckFailure,
  exportRequestMade,
  exportCheckSuccess,
  exportCheckFailure,
  handleCheckPrintFilter,
  clearFilterData,
  checkPrintConfigFailure,
  checkPrintConfigSuccess,
  saveCheckPrintConfigFailure,
  saveCheckPrintConfigSuccess,
  getCheckPrintConfigFailure,
  getCheckPrintConfigSuccess,
} from "../../actions/CheckPrintAction";
import { scheduleTransactionRequestMade } from "../../actions";
import {
  CheckPrintAPI,
  VendorAPI,
  DynamicActionAPI,
  SettingsAPI,
  ClientAPI,
} from "../../api";
import {
  uploadDocFileFailure,
  uploadDocFileSuccess,
} from "../../actions/ScheduleTransactionAction";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
  fetchCheckingAccountFailure,
  fetchCheckingAccountSuccess,
  fetchDescriptionFailure,
  fetchDescriptionSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
import { closeScheduleTransactionSnackBar } from "../../actions";
import { handleChangeTransactionRemark } from "../../actions/TransactionAction";
const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCheckPrintList: () => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.fetchCheckPrintList((response, error) => {
        if (response) {
          dispatch(fetchCheckPrintSuccess(response));
          // response && response.map()
        } else {
          if (error.msg) {
            dispatch(fetchCheckPrintFailure(error.msg));
          } else {
            dispatch(fetchCheckPrintFailure(error));
          }
        }
      });
    },
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },

    handleChangeBankName: (bankName) => {
      dispatch(handleChangeSelectBank(bankName));
    },
    handleChangePayTo: (payTo) => {
      dispatch(handleChangePayTo(payTo));
    },
    handleChangeAmount: (amount) => {
      dispatch(handleChangeAmount(amount));
    },
    handleChangeAddress1: (addressFirst) => {
      dispatch(handleChangeAddress1(addressFirst));
    },
    handleChangeAddress2: (addressSecond) => {
      dispatch(handleChangeAddress2(addressSecond));
    },
    handleChangeCity: (city) => {
      dispatch(handleChangeCity(city));
    },
    handleChangeState: (state) => {
      dispatch(handleChangeState(state));
    },
    handleChangeZipCode: (zipCode) => {
      dispatch(handleChangeZipCode(zipCode));
    },
    handleChangeDate: (date) => {
      dispatch(handleChangeDate(date));
    },
    handleChangeMemo: (review) => {
      dispatch(handleChangeMemo(review));
    },
    handleChangeAttachment: (attachment) => {
      dispatch(handleChangeAttachment(attachment));
    },
    handleChangeDescription: (description) => {
      dispatch(handleChangeDescription(description));
    },
    handleChangeStartDate: (startDate) =>
      dispatch(handleChangeStartDate(startDate)),
    handleChangeEndDate: (endDate) => dispatch(handleChangeEndDate(endDate)),
    handleClosePrintSnackBar: () => dispatch(closeSnackBarForCheckPrint(true)),
    clearFilterModal: (bankName, amount, startDate, endDate) =>
      dispatch(closeModalForCheckPrint(bankName, amount, startDate, endDate)),

    printManualCheck: (checkData, accountNumber, routingNumber, props) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.printManualCheck(checkData, (response, error) => {
        if (response) {
          dispatch(manualCheckPrintSuccess(true));
          props.history.push({
            pathname: "/checkToPrint",
            state: { response, accountNumber, routingNumber },
          });
        } else {
          if (error.msg) {
            dispatch(manualCheckPrintFailure(error.msg));
          } else {
            dispatch(manualCheckPrintFailure(error));
          }
        }
      });
    },
    printBulkCheck: (bulkCheckData) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.printBulkCheck(bulkCheckData, (response, error) => {
        if (response) {
          dispatch(bulkCheckPrintSuccess(response));
        } else {
          if (error.msg) {
            dispatch(bulkCheckPrintFailure(error.msg));
          } else {
            dispatch(bulkCheckPrintFailure(error));
          }
        }
      });
    },
    updateCheckStatus: (checkData, props) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.updateCheck(checkData, (response, error) => {
        if (response) {
          dispatch(delcineCheckSuccess(response));
          props.fetchCheckPrintList();
        } else {
          if (error.msg) {
            dispatch(delcineCheckFailure(error.msg));
          } else {
            dispatch(delcineCheckFailure(error));
          }
        }
      });
    },

    //filter for single field
    filterCheckPrintByDate: (from, to) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByDate(from, to, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(filterByDateFailure(error.msg));
          } else {
            dispatch(filterByDateFailure(error));
          }
        } else {
          dispatch(filterByDateSuccess(response));
        }
      });
    },
    filterCheckPrintByBank: (bank) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByBank(bank, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(filterByBankFailure(error.msg));
          } else {
            dispatch(filterByBankFailure(error));
          }
        } else {
          dispatch(filterByBankSuccess(response));
        }
      });
    },
    filterCheckPrintByAmount: (amount) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByAmount(amount, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(filterByAmountFailure(error.msg));
          } else {
            dispatch(filterByAmountFailure(error));
          }
        } else {
          dispatch(filterByAmountSuccess(response));
        }
      });
    },
    filterCheckPrintByTerm: (term) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByTerm(term, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(filterByAmountFailure(error.msg));
          } else {
            dispatch(filterByAmountFailure(error));
          }
        } else {
          dispatch(filterByAmountSuccess(response));
        }
      });
    },
    filterCheckPrintByDateAndAmount: (from, to, amount) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByDateAndAmount(
        from,
        to,
        amount,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(filterByDateAmountFailure(error.msg));
            } else {
              dispatch(filterByDateAmountFailure(error));
            }
          } else {
            dispatch(filterByDateAmountSuccess(response));
          }
        }
      );
    },
    filterCheckPrintByBankAndAmount: (bank, amount) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByBankAndAmount(
        bank,
        amount,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(filterByBankAndAmountFailure(error.msg));
            } else {
              dispatch(filterByBankAndAmountFailure(error));
            }
          } else {
            dispatch(filterByBankAndAmountSuccess(response));
          }
        }
      );
    },
    filterCheckPrintByBankAndDate: (bank, from, to) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByBankAndDate(
        bank,
        from,
        to,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(filterByBankAndDateFailure(error.msg));
            } else {
              dispatch(filterByBankAndDateFailure(error));
            }
          } else {
            dispatch(filterByBankAndDateSuccess(response));
          }
        }
      );
    },
    filterCheckPrintByBankAndDateAndAmount: (bank, from, to, amount) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByBankAndDateAndAmount(
        bank,
        from,
        to,
        amount,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(filterByBankAndDateAndAmountFailure(error.msg));
            } else {
              dispatch(filterByBankAndDateAndAmountFailure(error));
            }
          } else {
            dispatch(filterByBankAndDateAndAmountSuccess(response));
          }
        }
      );
    },
    filterCheckPrintByAll: (
      checkNo,
      bankName,
      vendorName,
      // status,
      date,
      amount,
      compareType
    ) => {
      dispatch(checkPrintRequestMade(true));
      CheckPrintAPI.filterCheckPrintByAll(
        checkNo,
        bankName,
        vendorName,
        date === null ? "" : date,
        amount,
        compareType,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(filterByBankAndDateAndAmountFailure(error.msg));
            } else {
              dispatch(filterByBankAndDateAndAmountFailure(error));
            }
          } else {
            dispatch(filterByBankAndDateAndAmountSuccess(response));
          }
        }
      );
    },
    //client
    handleChangeClientId: (clientId) =>
      dispatch(handleChangeClientIdForCheck(clientId)),
    searchClientList: (value) => {
      ScheduleTransactionAPI.searchClientList(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchClientListFailure(error.msg));
          } else {
            dispatch(searchClientListFailure(error));
          }
        } else {
          dispatch(searchClientListSuccess(response));
        }
      });
    },
    setManualCheckPrintSuccessToFalse: () => {
      dispatch(manualCheckPrintSuccess(false));
    },
    setManualCheckPrintDataToEmpty: () => {
      dispatch(setManualCheckDataEmpty());
    },

    uploadDocumentFile: (selectedFiles, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      let formData = new FormData();
      formData.append("doc", selectedFiles.document);
      formData.append("mimeType", selectedFiles.type);
      DynamicActionAPI.uploadDocumentFile(formData, (response, error) => {
        if (response) {
          dispatch(uploadDocFileSuccess(response));
          props.handleChangeAttachment(response.fileUrl);
        } else {
          if (error.msg) {
            dispatch(uploadDocFileFailure(error.msg));
          } else {
            dispatch(uploadDocFileFailure(error));
          }
        }
      });
    },
    //description
    fetchDescriptionList: () => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchDescription((response, error) => {
        if (response) {
          dispatch(fetchDescriptionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchDescriptionFailure(error.msg));
          } else {
            dispatch(fetchDescriptionFailure(error));
          }
        }
      });
    },

    // EXPORT CSV
    exportCheckPrint: (from, to) => {
      dispatch(exportRequestMade(true));
      CheckPrintAPI.exportChecks(
        from === null ? 0 : from,
        to === null ? 0 : to,
        (response, error) => {
          if (response) {
            {
              response.url && window.open(response.url, "_blank");
            }
            dispatch(exportCheckSuccess(response));
          } else {
            if (error.msg) {
              dispatch(exportCheckFailure(error.msg));
            } else {
              console.log(error);
              dispatch(exportCheckFailure(error));
            }
          }
        }
      );
    },

    closeScheduleTransactionSnackBar: () => {
      dispatch(closeScheduleTransactionSnackBar());
    },
    handleChangeRemark: (remark) => {
      dispatch(handleChangeTransactionRemark(remark));
    },
    fetchCheckingAccountsList: (props) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchCheckingAccount((response, error) => {
        if (response) {
          dispatch(fetchCheckingAccountSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCheckingAccountFailure(error.msg));
          } else {
            dispatch(fetchCheckingAccountFailure(error));
          }
        }
      });
    },

    // filter checkprint
    handleCheckPrintFilter: (id, value) => {
      dispatch(handleCheckPrintFilter(id, value));
    },

    clearFilterData: () => dispatch(clearFilterData(true)),
    filterClient: (name, clientNo, caseCount, date, balance, term) => {
      dispatch(checkPrintRequestMade(true));
      ClientAPI.filterClient(
        name,
        clientNo,
        caseCount,
        date === null ? 0 : date,
        balance,
        term,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(filterByAmountFailure(error.msg));
            } else {
              dispatch(filterByAmountFailure(error));
            }
          } else {
            dispatch(filterByAmountSuccess(response));
          }
        }
      );
    },
    getConfigList: (type) => {
      ClientAPI.getConfigList(type, (response, error) => {
        console.log(response, "response");
        if (error) {
          if (error.msg) {
            dispatch(getCheckPrintConfigFailure(error.msg));
          } else {
            dispatch(getCheckPrintConfigFailure(error));
          }
        } else {
          dispatch(getCheckPrintConfigSuccess(response.configs));
        }
      });
    },
    saveInConfig: (data) => {
      CheckPrintAPI.saveConfig(data, (response, error) => {
        console.log(response);
        if (error) {
          if (error.msg) {
            dispatch(saveCheckPrintConfigFailure(error.msg));
          } else {
            dispatch(saveCheckPrintConfigFailure(error));
          }
        } else {
          dispatch(saveCheckPrintConfigSuccess(response));
        }
      });
    },
  };
};

export const CheckPrintContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckPrintScreen);
