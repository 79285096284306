import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Typography from "@material-ui/core/Typography";
import {Prompt} from "react-router";
import {Select} from "antd";
const {Option} = Select;


class VendorEditFormScreen extends Component {
    constructor(props) {
        super(props);
       this.state = {
           isBlocking: false,
       }
    }
    componentWillMount() {
        this.props.fetchVendor();
    }

    render() {
        const vendorData = this.props.vendorState.vendorData;

        const editVendorAPICall = () => {
            this.props.editVendor(this.props.vendorState.vendorData, this.props)
        };

        return(
            <React.Fragment>
                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/vendor" style={style.link}>
                        Vendor Account
                    </Link>
                    <Typography color="inherit" className="link">
                        Update Vendor
                    </Typography>
                </Breadcrumbs>
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />
                <div style={style.container}>
                    <div className="card" style={style.card}>
                        <div className="form">
                            <div className="card-body" style={style.cardBody}>
                                <div className="row mt-3">
                                    <div className="col-lg-4">
                                        <label style={style.columnHeading}>Name
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type='name'
                                            id="vendorName"
                                            className="form-control"
                                            value={vendorData && vendorData.vendorName}
                                            style={style.inputField}
                                            onChange={(e)=>{
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeVendor(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="type" style={style.columnHeading}>Type
                                            <span style={{color:"red"}}> *</span></label>
                                        <div className="select-input selects h40" style={{marginTop: "12px"}}>
                                            <Select
                                                    value={vendorData && vendorData.vendorType}
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            isBlocking: true,
                                                        })
                                                        this.props.handleChangeVendor("vendorType", e)
                                                    }}

                                            >
                                                <Option value="RENT">Rent</Option>
                                                <Option value="ELECTRIC_BILL">Electric Bill</Option>
                                                <Option value="INTERNET_BILL">Internet Bill</Option>
                                                <Option value="EVERFUND_VENDOR">Everfund Vendor</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="vendorAddress" style={style.columnHeading}>Address
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type='name'
                                            id="vendorAddress"
                                            value={vendorData && vendorData.vendorAddress}
                                            style={style.inputField}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeVendor(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-4">
                                        <label htmlFor="vendorContactNumber" style={style.columnHeading}>Contact Number
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type='name'
                                            value={vendorData && vendorData.vendorContactNumber}
                                            id="vendorContactNumber"
                                            style={style.inputField}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeVendor(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="accountName" style={style.columnHeading}>Account Name
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type='accountName'
                                            id="accountName"
                                            value={vendorData && vendorData.accountName}
                                            style={style.inputField}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeVendor(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="accountNumber" style={style.columnHeading}>Bank Account Number
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type='name'
                                            id="accountNumber"
                                            value={vendorData && vendorData.accountNumber}
                                            style={style.inputField}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeVendor(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-4">
                                        <label htmlFor="routingNumber" style={style.columnHeading}>Routing Number
                                            <span style={{color:"red"}}> *</span></label>
                                        <input
                                            type='name'
                                            id="routingNumber"
                                            value={vendorData && vendorData.routingNumber}
                                            style={style.inputField}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                this.setState({
                                                    isBlocking: true,
                                                })
                                                this.props.handleChangeVendor(
                                                    e.target.id,
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label htmlFor="transactionMedium" style={style.columnHeading}>
                                            Transaction Medium  <span style={{color:"red"}}> *</span></label>
                                        <div className="select-input selects h40" style={{marginTop: "12px"}}>
                                            <Select
                                                    value={vendorData && vendorData.transactionMedium}
                                                    id="transactionMedium"
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            isBlocking: true,
                                                        })
                                                        this.props.handleChangeVendor("transactionMedium", e)
                                                    }}
                                            >
                                                <Option value="CHECK">Check</Option>
                                                <Option value="CASH">Cash</Option>
                                                <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                                                <Option value="ACH">ACH</Option>
                                                <Option value="CARD">Card</Option>
                                                <Option value="OTHER_MEDIUM">Other medium</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-4"/>
                                    <div className="col-4"/>
                                    <div className="col-4">
                                        <button
                                            className="btn btn-primary"
                                            style={style.nextButton}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                this.setState({isBlocking: false,})
                                                editVendorAPICall()
                                            }}
                                        > Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const style={
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    container: {
        marginLeft: "3%",
        marginRight: "3%",
        marginBottom: "2%",
        marginTop: "75px"
    },
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: '240px',
        // marginLeft: 0,
        marginRight: 0,
        marginBottom: "1rem",
        width: '70%',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#fff',
        paddingRight: '8px',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '4%',
        height: '64px'
    },
    cardBody: {
        paddingLeft: '4%',
        paddingRight: '4%'
    },
    clientText: {
        color: '#cc6600',
        fontSize: '20px',
        fontWeight: 'bolder',
        paddingTop: '15px',
    },
    inputField: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    inputTextField:{
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '75px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '16px',
        paddingLeft: '15px'
    },
    columnHeading: {
        color: '#625F5F',
        marginBottom: '0px',
        fontSize: '16px',
        width: '100%',
        textAlign: 'left !important'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
    },
    selectHead: {
        position: 'relative',
    },
    selectBarHead: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        marginTop: '5px',
        height: '40px',
        fontSize: '15px'
    },
    select: {
        position: "relative",
        height: "40px",
        marginTop: '10px',
        // box-shadow: 0 0 0 2px #8bc83f;
        // border-radius: 0px;
    },
    icon: {
        position: 'absolute',
        top: '34px',
        right: '25px',
        color: '#C8C5C5',
        fontSize: '32px',
    },
    nextButton: {
        backgroundColor: '#8BC83F',
        float: 'right',
        width: '40%',
        height: '40px',
        border: '0',
        color: '#fff',
        fontSize: '17px',
        fontWeight: 'bold',
        marginTop: '16px',
        marginRight: 0,
        marginBottom: '5px'
    },


}
export default VendorEditFormScreen;