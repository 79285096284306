import {ACTION_TYPE_SETTING} from "../constants/Type";


export const requestInCalendarSetting = bool =>{
    return{
        type: ACTION_TYPE_SETTING.REQUEST_IN_CALENDAR_SETTING,
        bool,
    }
}

export const closeCalendarSettingSnackBar = bool => {
    return{
        type: ACTION_TYPE_SETTING.CLOSE_CALENDAR_SETTING_SNACKBAR,
        bool,
    }
}

export const fetchHolidaysSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_HOLIDAY_SUCCESS,
        response
    };
};

export const fetchHolidaysFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_HOLIDAY_FAILURE,
        error
    };
};
export const handleChangeCalendarType = calendarType => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_CALENDAR_TYPE,
        calendarType,
    }
}
export const handleChangeHolidayName = holidayName => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_HOLIDAY_NAME,
        holidayName,
    }
}
export const handleInitialStartDate = date => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_INITIAL_START_DATE,
        date,
    }
}
export const handleChangeStartDate = startDate => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_START_DATE,
        startDate,
    }
}
export const handleChangeEndDate = endDate => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_END_DATE,
        endDate,
    }
}

export const addHolidaysSuccess =  (bool, response) => {
    return {
        type: ACTION_TYPE_SETTING.ADD_HOLIDAYS_SUCCESS,
        bool,
        response
    };
};

export const makeSuccessToFalse = (bool) => {
    return{
        type: ACTION_TYPE_SETTING.MAKE_SUCCESS_TO_FALSE,
        bool,
    }
}

export const clearModalData = () =>{
    return{
        type: ACTION_TYPE_SETTING.CLEAR_MODAL_HOLIDAY_DATA
    }
}

// export const

export const addHolidaysFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ADD_HOLIDAYS_FAILURE,
        error
    };
};

export const setHolidayToEdit = holidayList => {
    return {
        type: ACTION_TYPE_SETTING.SET_HOLIDAY_TO_EDIT,
        holidayList
    };
};

export const updateHolidaysSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.UPDATE_HOLIDAYS_SUCCESS,
        response
    };
};

export const updateHolidaysFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.UPDATE_HOLIDAYS_FAILURE,
        error
    };
};

export const deleteHolidaysSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.DELETE_HOLIDAYS_SUCCESS,
        response
    };
};

export const deleteHolidaysFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.DELETE_HOLIDAYS_FAILURE,
        error
    };
};


//FEE PERCENTAGE (PAYMENT SETTING)
export const handleChangeFeePercentage = fee => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_FEE_PERCENTAGE,
        fee,
    }
}
export const handleChangeMinMonthlyFee = minMonthlyFee => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_MIN_MONTHLY_FEE,
        minMonthlyFee,
    }
}
export const handleChangeMaxMonthlyFee = maxMonthlyFee => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_MAX_MONTHLY_FEE,
        maxMonthlyFee,
    }
}

export const handleChangeAnnualFee = annualFee => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_ANNUAL_FEE,
        annualFee,
    }
}
export const handleChangeSpendDown = spendDownThreshold => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_SPEND_DOWN,
        spendDownThreshold,
    }
}

export const handleChangeAchDelayThreshold = achDelayThreshold => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_ACH_DELAY_THRESHOLD,
        achDelayThreshold,
    }
}

export const handleChangeAchUploadTime = achUploadTime => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_ACH_UPLOAD_TIME,
        achUploadTime,
    }
}

export const handleChangeAchUploadTimeFormat = timeFormat => {
    return{
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_TIME_FORMAT,
        timeFormat,
    }
}

export const fetchFeePercentageSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_FEE_PERCENTAGE_SUCCESS,
        response
    };
};

export const fetchFeePercentageFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_FEE_PERCENTAGE_FAILURE,
        error
    };
};

export const addFeePercentageSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.ADD_FEE_PERCENTAGE_SUCCESS,
        response
    };
};

export const addFeePercentageFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ADD_FEE_PERCENTAGE_FAILURE,
        error
    };
};

export const handleOpenAddHolidayModal = bool =>{
    return {
        type: ACTION_TYPE_SETTING.HANDLE_OPEN_ADD_HOLIDAY_MODAL,
        bool
    };
};
export const handleCloseAddHolidayModal = bool =>{
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CLOSE_ADD_HOLIDAY_MODAL,
        bool
    };
};

//IMPORT HOLIDAY CALENDAR
export const importHolidayCalendarSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.IMPORT_HOLIDAY_CALENDAR_SUCCESS,
        response
    };
};

export const importHolidayCalendarFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.IMPORT_HOLIDAY_CALENDAR_FAILURE,
        error
    };
};

export const handleChangeICSFile = icsFile =>{
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_ICS_FILE,
        icsFile
    };
};

export const setImportCalendarSuccess = bool => {
    return{
        type: ACTION_TYPE_SETTING.SET_IMPORT_HOLIDAY_CALENDAR_SUCCESS,
        bool,
    }
}

//ASSIGN EVENTS FOR CLIENTS/AGENTS

export const handleChangeAssignEvent = (assignedEvents) => {
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_ASSIGNED_EVENTS,
        assignedEvents
    };
};
export const handleChangeEventId= (eventId) => {
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_EVENT_ID,
        eventId
    };
};

export const searchClientOrAgentAccountSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.SEARCH_CLIENTS_OR_AGENTS_SUCCESS,
        response
    };
};

export const searchClientOrAgentAccountFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.SEARCH_CLIENTS_OR_AGENTS_SUCCESS,
        error
    };
};

export const assignEventToClientOrAgentsSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_SUCCESS,
        bool
    };
};

export const assignEventToClientOrAgentsFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_FAILURE,
        error
    };
};

export const getAssignedClientOrAgentsSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.GET_ASSIGNED_CLIENTS_OR_AGENTS_SUCCESS,
        response
    };
};

export const getAssignedClientOrAgentsFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.GET_ASSIGNED_CLIENTS_OR_AGENTS_FAILURE,
        error
    };
};

//ELIGIBILITY SETTING
export const handleChangeEligibilityData = (id, value) => {
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_ELIGIBILITY_INPUT,
        id , value
    };
};
export const setEligibilityDataToEdit = (eligibilityData) => {
    return {
        type: ACTION_TYPE_SETTING.SET_ELIGIBILITY_DATA_TO_EDIT,
        eligibilityData
    };
};

export const clearEligibilityData = () => {
    return{
        type: ACTION_TYPE_SETTING.CLEAR_ELIGIBILITY_DATA,
    }
}

export const fetchEligibilityDataSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_ELIGIBILITY_DATA_SUCCESS,
        response
    };
};

export const fetchEligibilityDataFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_ELIGIBILITY_DATA_FAILURE,
        error
    };
};
export const addEligibilityDataSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.ADD_ELIGIBILITY_SUCCESS,
        bool,
    };
};

export const addEligibilityDataFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ADD_ELIGIBILITY_FAILURE,
        error
    };
};

export const updateEligibilityDataSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.ADD_ELIGIBILITY_SUCCESS,
        bool,
    };
};

export const updateEligibilityDataFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ADD_ELIGIBILITY_FAILURE,
        error
    };
};

//DESCRIPTION SETTING
export const handleChangeDescription = (id, value) => {
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_DESCRIPTION,
        id , value
    };
};
export const setDescriptionDataToEdit = (descriptionData) => {
    return {
        type: ACTION_TYPE_SETTING.SET_DESCRIPTION_DATA_TO_EDIT,
        descriptionData
    };
};

export const clearDescriptionData = () => {
    return{
        type: ACTION_TYPE_SETTING.CLEAR_DESCRIPTION_DATA,
    }
}

export const fetchDescriptionSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_DESCRIPTION_LIST_SUCCESS,
        response
    };
};

export const fetchDescriptionFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_REQUEST,
        error
    };
};
export const defaultDescriptionRequest = bool =>{
    return{
        type: ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_REQUEST,
        bool,
    }
}
export const fetchDefaultDescriptionSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_SUCCESS,
        bool
    };
};

export const fetchDefaultDescriptionFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_FAILURE,
        error
    };
};

export const addDescriptionSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.ADD_DESCRIPTION_SUCCESS,
        bool,
    };
};

export const addDescriptionFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ADD_DESCRIPTION_FAILURE,
        error
    };
};

export const updateDescriptionSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.UPDATE_DESCRIPTION_SUCCESS,
        bool,
    };
};

export const updateDescriptionFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.UPDATE_DESCRIPTION_FAILURE,
        error
    };
}

export const deleteDescriptionSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.DELETE_HOLIDAYS_SUCCESS,
        bool,
    };
};

export const deleteDescriptionFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.DELETE_HOLIDAYS_FAILURE,
        error
    };
}

//VENDOR TYPE SETTING
export const handleChangeVendorTypeInput = (id, value) => {
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_VENDOR_TYPE_INPUT,
        id,
        value,
    };
};
export const clearVendorTypeForm = () => {
    return {
        type: ACTION_TYPE_SETTING.CLEAR_VENDOR_TYPE_FORM,
    };
};
export const setVendorTypeToEdit = (vendorTypeData) => {
    return {
        type: ACTION_TYPE_SETTING.SET_VENDOR_TYPE_TO_EDIT,
        vendorTypeData,
    };
};
export const fetchVendorTypeSuccess = vendorTypeList => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_VENDOR_TYPE_LIST_SUCCESS,
        vendorTypeList,
    };
};
export const fetchVendorTypeFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.FETCH_VENDOR_TYPE_LIST_FAILURE,
        error
    };
};
export const checkVendorTypeSuccess = response => {
    return {
        type: ACTION_TYPE_SETTING.CHECK_VENDOR_TYPE_LIST_SUCCESS,
        response,
    };
};
export const checkVendorTypeFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.CHECK_VENDOR_TYPE_LIST_FAILURE,
        error
    };
};
export const addVendorTypeSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.ADD_VENDOR_TYPE_SUCCESS,
        bool,
    };
};
export const addVendorTypeFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.ADD_VENDOR_TYPE_FAILURE,
        error
    };
};
export const updateVendorTypeSuccess = bool => {
    return {
        type: ACTION_TYPE_SETTING.UPDATE_VENDOR_TYPE_SUCCESS,
        bool,
    };
};
export const updateVendorTypeFailure = error => {
    return {
        type: ACTION_TYPE_SETTING.UPDATE_VENDOR_TYPE_FAILURE,
        error
    };
};

// CHECKING ACCOUNT SETTING
export const handleChangeCheckingAccountInput = (id, value) => {
    return {
      type: ACTION_TYPE_SETTING.HANDLE_CHANGE_CHECKING_ACCOUNT_DATA,
      id,
      value,
    };
  };
export const handleChangeCheckingAccountAddress = (id, value) => {
    return {
        type: ACTION_TYPE_SETTING.HANDLE_CHANGE_CHECKING_ACCOUNT_ADDRESS_DATA,
        id,
        value,
    };
};
export const handleChangeToOpenCheckingAccount = (bool) => {
    return {
      type: ACTION_TYPE_SETTING.HANDLE_CHANGE_TO_OPEN_CHECKING_ACCOUNT_FORM_MODAL,
      bool,
    };
};

export const handleChangeIsUpdate = (isUpdate) => {
    return {
      type: ACTION_TYPE_SETTING.HANDLE_CHANGE_IS_UPDATE,
      isUpdate,
    };
};

export const clearCheckingAccountForm = () => {
    return {
      type: ACTION_TYPE_SETTING.CLEAR_CHECKING_ACCOUNT_FORM,
    };
};
export const setCheckingAccountDataToEdit = (checkingAccountData) => {
    return {
      type: ACTION_TYPE_SETTING.SET_CHECKING_ACCOUNT_DATA_TO_EDIT,
      checkingAccountData,
    };
};
export const fetchCheckingAccountSuccess = (checkingAccountList) => {
    return {
      type: ACTION_TYPE_SETTING.FETCH_CHECKING_ACCOUNT_SUCCESS,
      checkingAccountList,
    };
};
export const fetchCheckingAccountFailure = (error) => {
    return {
      type: ACTION_TYPE_SETTING.FETCH_CHECKING_ACCOUNT_FAILURE,
      error,
    };
};
export const addCheckingAccountSuccess = (bool) => {
    return {
      type: ACTION_TYPE_SETTING.ADD_VENDOR_TYPE_SUCCESS,
      bool,
    };
};
export const addCheckingAccountFailure = (error) => {
    return {
      type: ACTION_TYPE_SETTING.ADD_VENDOR_TYPE_FAILURE,
      error,
    };
};
export const updateCheckingAccountSuccess = (bool) => {
    return {
      type: ACTION_TYPE_SETTING.UPDATE_VENDOR_TYPE_SUCCESS,
      bool,
    };
};
export const updateCheckingAccountFailure = (error) => {
    return {
      type: ACTION_TYPE_SETTING.UPDATE_VENDOR_TYPE_FAILURE,
      error,
    };
};



