import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import {Checkbox, CircularProgress, DialogActions, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {Prompt} from "react-router";
import Typography from "@material-ui/core/Typography";
import {Select} from "antd";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogContent from "@material-ui/core/DialogContent";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DialogTitleComponent from "./DialogTitleComponent";
import ActionFormFieldsComponent from "./ActionFormFieldsComponent";
import {HelmetComponent} from "../components/Helmet";

const {Option} = Select;

class ActionFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogueForNewActionForm: false,
            actionForms: [],
            isBlocking: false,
            renderPage: false,
            indexId: '',
            checkedValue: false,
            singleFieldObject:{},
            activeActionFormId:"",
            activeOptionId:"",
            activeOptionName:"",
            conditionalActionForm:[],
            conditionalForm:[],
            selected: [],
        }
    };

    componentDidMount() {
        this.props.searchExistingOptionValue("");
    }

    componentWillUnmount() {
        this.props.handleChangeAction("actionForms", []);
        this.props.handleSnackBarClose();
    }

    render() {

        const actionState = this.props.state && this.props.state.actionState;
        const optionValues = actionState && actionState.optionValues;

        //HANDLE CHANGE FOR BACK
        const handleChangeBack = (optionId , actionFormId) => {
            const filteredConditionalActionForm = this.state.conditionalActionForm
                && this.state.conditionalActionForm.length > 0 &&
                this.state.conditionalActionForm.filter(item => item.actionId !== actionFormId
                    && item.conditionalForm.length > 0)
            this.setState({
                conditionalActionForm: filteredConditionalActionForm
            })
        }
        //FOR FIRST STEP CONDITIONAL FORM
        const onHandleFirstConditionalFormStep = (currentActionId, currentOptionId, conditionalActionForm) => {
         const filteredActionForm = this.state.actionForms &&  this.state.actionForms.length > 0
             && this.state.actionForms.filter(action => action.id === currentActionId);
         const optionArray = filteredActionForm && filteredActionForm[0].value.optionValue;
         const conditionalActionFormData = conditionalActionForm && conditionalActionForm.length > 0
             && conditionalActionForm.map(condition => condition.value)
         if(optionArray && optionArray.length > 0){
             optionArray.filter(option => option.optionValueId === currentOptionId)[0].conditionalForm =
                 conditionalActionFormData
         }}
        //SETTING CONDITIONAL FORM IN OPTION
        const changeConditionalFormInOption = (previousActionId, previousOptionId, currentActionId,
                                               currentOptionId, conditionalForm) =>
        {
            const filteredConditionalActionForm = this.state.conditionalActionForm
                && this.state.conditionalActionForm.length > 0 &&
                this.state.conditionalActionForm.filter(item => item.actionId === previousActionId).filter(item =>
                    item.optionId === previousOptionId)[0];
            if( filteredConditionalActionForm.conditionalForm
                && filteredConditionalActionForm.conditionalForm.length > 0)
            {
                const filterOption =  filteredConditionalActionForm.conditionalForm.filter(form=> form.id === currentActionId)[0]
                    && filteredConditionalActionForm.conditionalForm.filter(form=> form.id === currentActionId)[0].optionObject
                if( filterOption && filterOption.length > 0)
                {
                    filterOption.filter(option => option.optionValueId === currentOptionId)[0].conditionalForm = conditionalForm;
                }
                filterConditionalFormFunction(filteredConditionalActionForm, currentActionId, currentOptionId);
            }
        };
        const filterConditionalFormFunction = (filteredConditionalActionForm, currentActionId, currentOptionId) =>{
          const optionObject =  filteredConditionalActionForm && filteredConditionalActionForm.conditionalForm.filter(form=>
              form.id === currentActionId)[0].optionObject;
            const resultA = optionObject.filter( option => {
                return optionValues.find( object => object.optionValueId === option.optionValueId)});
            const finalResultA = resultA && resultA.map(res => {
                if(res.conditionalForm){
                    const conditionValues = res.conditionalForm && res.conditionalForm.map(form => form.value);
                    return {
                        optionValueId: res.optionValueId,
                        conditionalForm: conditionValues,
                    };
                }else{
                    return {
                        optionValueId: res.optionValueId,
                    };
                }
            });
            const resultB = optionObject.filter( option => {
                return !optionValues.find( object => object.optionValueId === option.optionValueId);
            });
            const finalResultB = resultB && resultB.map(res => {
                const conditionValues = res.conditionalForm
                    && res.conditionalForm.map(form => form.value);
                if(res.conditionalForm){
                    return {
                        optionValueName: res.optionValueName,
                        conditionalForm: conditionValues,
                    };
                }else{
                    return {
                        optionValueName: res.optionValueName,
                    };
                }
            });
            filteredConditionalActionForm.conditionalForm.filter(form=>
                form.id === currentActionId)[0].value.optionValue = [...finalResultA, ...finalResultB];
            filteredConditionalActionForm.filterCondition = filteredConditionalActionForm.conditionalForm.map(item => item.value)
        }
        //FOR MULTIPLE CONDITIONAL FORM CHANGE
        const changeFieldConditionalForm = (key, activeOptionId, value) => {
            if(this.state.actionForms &&
                this.state.actionForms.filter(single => single.id === key)[0].optionObject.filter(obj =>
                    obj.optionValueId === activeOptionId)[0] !== undefined)
            {
                this.state.actionForms.filter(single => single.id === key)[0].optionObject.filter(obj =>
                    obj.optionValueId === activeOptionId)[0].conditionalForm = value;
                const optionObject = this.state.actionForms.filter(single => single.id === key)[0].optionObject
                const resultA = optionObject.filter( option => {
                    return optionValues.find( object => object.optionValueId === option.optionValueId)});
                const finalResultA = resultA && resultA.map(res => {
                    if(res.conditionalForm){
                        return {
                            optionValueId: res.optionValueId,
                            conditionalForm: res.conditionalForm,
                        };
                    }else{
                        return {
                            optionValueId: res.optionValueId,
                        };
                    }
                });
                const resultB = optionObject.filter( option => {
                    return !optionValues.find( object => object.optionValueId === option.optionValueId);
                });
                const finalResultB = resultB && resultB.map(res => {
                    if(res.conditionalForm){
                        return {
                            optionValueName: res.optionValueName,
                            conditionalForm: res.conditionalForm,
                        };
                    }else{
                        return {
                            optionValueName: res.optionValueName,
                        };
                    }
                });
                this.state.actionForms.filter(single => single.id === key)[0].value.optionValue = [...finalResultA, ...finalResultB];
                filterActionFormData();
            }else{
                return null;
            }
        };
        // change conditionalActionForm state
        const setConditionalFormActionData = (dataObject) =>{
            if(!this.state.checkedValue && this.state.conditionalActionForm &&
                this.state.conditionalActionForm.filter(item => item.optionId === dataObject.optionId).length > 0 ){
                 return null;
            }else if(this.state.checkedValue ) {
                if(this.state.conditionalActionForm &&
                    this.state.conditionalActionForm.filter(item => item.optionId === dataObject.optionId).length > 0 ){
                    return null;
                }else{
                    this.setState({
                        conditionalActionForm: [...this.state.conditionalActionForm, dataObject],
                    })
                }
            }
        }
        // FIRST NEW ACTION MODAL
        const handleCloseDialogueForNewActionForm = () =>{
            this.setState({
                openDialogueForNewActionForm: false
            })
        }
        const handleOpenDialogueForNewActionForm = () =>{
            if(this.state.checkedValue){
                this.setState({
                    openDialogueForNewActionForm: true,
                })
            }
        }
        const openDropdownForTextBox = () => {
            let div = [];
            // Outer loop to create parent
            if (this.state.actionForms.length > 0) {
                div.push(<div className="row" style={style.fieldLabel}>
                    <div className="col-4" style={{position: 'inherit'}}>
                        Field Title
                    </div>
                    <div className="col-4" style={{position: 'inherit', paddingLeft: '20px'}}>
                        Field Type
                    </div>
                    <div className="col-3" style={{position: 'inherit'}}>
                        Is Required
                    </div>
                </div>);
            }
            this.state.actionForms.map(single => {
                const value = single.value.required;
                return (
                    div.push(<>
                        <div className="row">
                            <div className="col-4" style={{position: 'inherit'}}>
                                <input type="text"
                                       id={single.id}
                                       value={single.value.fieldName}
                                       onChange={e => {
                                           e.preventDefault();
                                           this.setState({
                                               isBlocking: true,
                                           });
                                           changeFieldNameValue(e.target.id, e.target.value)
                                       }}
                                       style={style.dropdownInput}/>
                            </div>
                            <div className="col-4" style={{position: 'inherit'}}>
                                <div style={style.select}>
                                    <div className="select-input selects h40" style={{marginTop: "0px"}}>
                                        <Select id={single.id} value={single.value.fieldType}
                                                onChange={e => {
                                                    changeFieldTypeValue(single.id, e)
                                                }}>
                                            <Option value="TEXT_FIELD">Text Field</Option>
                                            <Option value="EMAIL">Email</Option>
                                            <Option value="PHONE">Phone</Option>
                                            <Option value="PHOTO">Photo</Option>
                                            <Option value="DOCUMENT">Document</Option>
                                            <Option value="DROPDOWN">Dropdown</Option>
                                            <Option value="CHECKBOX">Checkbox</Option>
                                            <Option value="RADIO_BUTTON">Radio Button</Option>
                                            <Option value="SSN">SSN</Option>
                                            <Option value="DOB">Date-of-birth</Option>
                                            <Option value="CONTACT">Contact</Option>
                                            <Option value="CURRENCY">Currency</Option>
                                            <Option value="AMOUNT">Amount</Option>
                                            <Option value="CONTENT">Content</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2" style={{
                                textAlign: 'center',
                                fontSize: '16px',
                                position: 'inherit',
                                paddingRight: '35px'
                            }}>
                                <input
                                    type="checkbox"
                                       hidden={single.value.fieldType === 'CONTENT'}
                                       className="form-check-input"
                                       id={single.id}
                                       checked={value}
                                       onChange={e => {
                                           this.setState({isBlocking: true});
                                           changeFieldRequiredValue(e.target.id, !value)
                                       }}
                                       style={{
                                           fontSize: '16px',
                                           marginTop: '10px',
                                           marginLeft: '0',
                                           position: 'inherit',
                                       }}/>
                            </div>
                            <div className="col-1" style={{position: 'inherit'}}>
                                <RemoveCircleOutlineIcon
                                    id={single.id}
                                    style={{
                                         color: 'grey',
                                         width: '24px',
                                         height: '24px',
                                         cursor: 'pointer'
                                    }}
                                    onClick={e => {
                                         e.preventDefault();
                                         closeFieldSection(single.id);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-3"
                             style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'CHECKBOX'}>
                            <div className="col-8">
                                <div className="multiple-select">
                                    <Select
                                        showArrow
                                        mode="tags"
                                        size="default"
                                        placeholder="Add options"
                                        getPopupContainer={node => node.parentNode}
                                        filterOption={(input, option) =>
                                            option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onSelect={(e)=>{
                                            onChangeSelectedValue(e);
                                        }}
                                        onDeselect={(e) => {
                                            onChangeDeselectedValue(e);
                                        }}
                                        onChange={(e, option) => {
                                            this.setState({
                                                singleFieldObject : single,
                                                activeActionFormId: single.id,
                                            })
                                            const optionObject = e.map(el => {
                                                const ids = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (ids.length === 0) {
                                                    return {
                                                        optionValueId: String(Math.random()),
                                                        optionValueName: el,
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el,
                                                        optionValueName: ids[0].optionValueName,
                                                    }
                                                }
                                            })
                                            const optionValue = e.map(el => {
                                                const ids = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (ids.length === 0) {
                                                    return {
                                                        optionValueName: el,
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el,
                                                    }
                                                }
                                            })
                                            changeFieldOptionValue(single.id, optionObject, optionValue);
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        {
                                            optionValues && optionValues.map((value, index) =>
                                                <Option key={index} value={value.optionValueId}>
                                                    {value.optionValueName}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div  id={single.id} className="mt-3 mb-4" hidden={single.value.fieldType !== 'CHECKBOX'}>
                            <ul style={{listStyleType: "none", paddingLeft: "0px", marginLeft: "-10px"}}>
                                {single.optionObject && single.optionObject.map((option,index) =>{
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return(
                                        <li id={option.optionValueId}>
                                            <Checkbox
                                                inputProps={{ 'aria-labelledby': labelId }}
                                                onChange={(event) =>
                                                {
                                                    const data = {
                                                        actionId: single.id,
                                                        optionId: option.optionValueId,
                                                        isSelected: event.target.checked,
                                                        optionName: option.optionValueName,
                                                        conditionalForm: [],
                                                        step: "firstStep",
                                                    }
                                                    this.setState({
                                                        activeActionFormId: single.id,
                                                        activeOptionId: option.optionValueId,
                                                        checkedValue: event.target.checked,
                                                        activeOptionName: option.optionValueName,
                                                    }, () => {
                                                        handleOpenDialogueForNewActionForm();
                                                        setConditionalFormActionData(data);
                                                    })
                                                    changeFieldTypeCheckedValue(single.id, option.optionValueId,
                                                        event.target.checked)
                                                }}
                                            />
                                            {option.optionValueName}
                                            <span onClick={() => {
                                                this.setState({
                                                    activeOptionId: option.optionValueId,
                                                    activeActionFormId: single.id,
                                                    activeOptionName: option.optionValueName,
                                                    singleFieldObject: single,
                                                    openDialogueForNewActionForm: true,
                                                })
                                                handleOpenDialogueForNewActionForm()
                                            }}  hidden={!option.checkedValue}>
                                                <EditOutlinedIcon
                                                    style={{
                                                        paddingLeft:"12px",
                                                        width: "30px",
                                                        paddingBottom: "4px",
                                                        cursor:"pointer"}}
                                                />
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/*<div  id={single.id} className="mt-3 mb-4" hidden={single.value.fieldType !== 'CHECKBOX'}>*/}
                        {/*    <ul style={{listStyleType: "none", paddingLeft: "0px", marginLeft: "-10px"}}>*/}
                        {/*        {optionValues && optionValues.filter(option => option.selected === true).map((option,index) =>{*/}
                        {/*            const labelId = `enhanced-table-checkbox-${index}`;*/}
                        {/*            return(*/}
                        {/*                <li id={option.optionValueId}>*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={option.checkedValue}*/}
                        {/*                        inputProps={{ 'aria-labelledby': labelId }}*/}
                        {/*                        onChange={(event) =>*/}
                        {/*                        {*/}
                        {/*                            const data = {*/}
                        {/*                                actionId: single.id,*/}
                        {/*                                optionId: option.optionValueId,*/}
                        {/*                                isSelected: event.target.checked,*/}
                        {/*                                optionName: option.optionValueName,*/}
                        {/*                                conditionalForm: [],*/}
                        {/*                                step: "firstStep",*/}
                        {/*                            }*/}
                        {/*                            this.setState({*/}
                        {/*                                activeActionFormId: single.id,*/}
                        {/*                                activeOptionId: option.optionValueId,*/}
                        {/*                                checkedValue: event.target.checked,*/}
                        {/*                                activeOptionName: option.optionValueName,*/}
                        {/*                            }, () => {*/}
                        {/*                                handleOpenDialogueForNewActionForm();*/}
                        {/*                                setConditionalFormActionData(data);*/}
                        {/*                            })*/}
                        {/*                            changeFieldTypeCheckedValue(single.id, option.optionValueId,*/}
                        {/*                                event.target.checked)*/}
                        {/*                        }}*/}
                        {/*                    />*/}
                        {/*                    {option.optionValueName}*/}
                        {/*                    <span onClick={() => {*/}
                        {/*                        this.setState({*/}
                        {/*                            activeOptionId: option.optionValueId,*/}
                        {/*                            activeActionFormId: single.id,*/}
                        {/*                            activeOptionName: option.optionValueName,*/}
                        {/*                            singleFieldObject: single,*/}
                        {/*                            openDialogueForNewActionForm: true,*/}
                        {/*                        })*/}
                        {/*                        handleOpenDialogueForNewActionForm()*/}
                        {/*                    }}  hidden={!option.checkedValue}>*/}
                        {/*                        <EditOutlinedIcon*/}
                        {/*                            style={{*/}
                        {/*                                paddingLeft:"12px",*/}
                        {/*                                width: "30px",*/}
                        {/*                                paddingBottom: "4px",*/}
                        {/*                                cursor:"pointer"}}*/}
                        {/*                        />*/}
                        {/*                    </span>*/}
                        {/*                </li>*/}
                        {/*            )*/}
                        {/*        })}*/}
                        {/*        /!*{*!/*/}
                        {/*        /!*    optionValues && optionValues.filter(option => option.selected === true).map(filter =>*!/*/}
                        {/*        /!*        <li>{filter.optionValueName}</li>)*!/*/}
                        {/*        /!*}*!/*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        <div className="row mb-5" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'DROPDOWN'}
                        >
                            <div className="col-8">
                                <div className="multiple-select">
                                    <Select
                                        showArrow
                                        mode="tags"
                                        size="default"
                                        placeholder="Add options"
                                        getPopupContainer={node => node.parentNode}
                                        // onSelect={(e)=>{
                                        //     onChangeSelectedValue(e);
                                        // }}
                                        // onDeselect={(e) => {
                                        //     onChangeDeselectedValue(e);
                                        // }}
                                        filterOption={(input, option) =>
                                            option.children &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e) => {
                                            const optionValue = e.map(el => {
                                                const name = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (name.length === 0) {
                                                    return {
                                                        optionValueName: el
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el
                                                    }
                                                }

                                            })
                                            changeFieldOptionValue(single.id,"", optionValue)
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        {
                                            optionValues && optionValues.map(value =>
                                                <Option value={value.optionValueId}>{value.optionValueName}</Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'RADIO_BUTTON'}
                        >
                            <div className="col-8">
                                <div className="multiple-select">
                                    <Select
                                        showArrow
                                        mode="tags"
                                        size="default"
                                        placeholder="Add options"
                                        filterOption={(input, option) =>
                                            option.children &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        getPopupContainer={node => node.parentNode}
                                        // onSelect={(e)=>{
                                        //     onChangeSelectedValue(e);
                                        // }}
                                        // onDeselect={(e) => {
                                        //     onChangeDeselectedValue(e);
                                        // }}
                                        onChange={(e) => {
                                            const optionValue = e.map(el => {
                                                const name = optionValues && optionValues.filter(value =>
                                                    value.optionValueId === el)
                                                if (name.length === 0) {
                                                    return {
                                                        optionValueName: el
                                                    }
                                                } else {
                                                    return {
                                                        optionValueId: el
                                                    }
                                                }

                                            })
                                            changeFieldOptionValue(single.id,"", optionValue)
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        {
                                            optionValues && optionValues.map(value =>
                                                <Option value={value.optionValueId}>{value.optionValueName}</Option>)
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'AMOUNT'}
                        >
                            <div className="col-8">
                                <div className="multiple-select h40">
                                    <Select
                                        showArrow
                                        size="default"
                                        placeholder="Select amount type"
                                        filterOption={(input, option) =>
                                            option.children &&
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(e)=>{
                                            changeFieldAmountTypeValue(single.id, e)
                                        }}
                                        getPopupContainer={node => node.parentNode}
                                        style={{width: '100%'}}
                                    >
                                        <Option value="INCOME">Income</Option>
                                        <Option value="ASSET">Asset</Option>
                                        <Option value="EXPENSE">Expense</Option>
                                        <Option value="IGNORE">Ignore</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3" style={{marginTop: "-12px"}}
                             id={single.id}
                             hidden={single.value.fieldType !== 'CONTENT'}
                        >
                            <div className="col-8">
                                <div className="multiple-select">
                                    <textarea
                                          className="inputField"
                                          onChange={(e)=>{
                                              changeFieldContentValue(single.id, e.target.value)
                                          }}
                                          style={{minHeight: '150px', padding:'15px'}}/>
                                </div>
                            </div>
                        </div>
                    </>)
                )
            });
            return div
        };
        //change name
        const changeFieldNameValue = (key, value) => {
            this.state.actionForms.filter(single => single.id === key)[0].value.fieldName = value;
            filterActionFormData()
        };
        //change type
        const changeFieldTypeValue = (key, value) => {
            this.state.actionForms.filter(single => single.id === key)[0].value.fieldType = value;
            filterActionFormData()
        };
        //change amount type
        const changeFieldAmountTypeValue = (key, value) => {
            this.state.actionForms.filter(single => single.id === key)[0].value.amountType = value;
            filterActionFormData()
        };
        //change content type
        const changeFieldContentValue = (key, value) => {
            this.state.actionForms.filter(single => single.id === key)[0].value.content = value;
            filterActionFormData()
        };
        //change require field
        const changeFieldRequiredValue = (key, value) => {
            var actionForms = this.state.actionForms;
            actionForms.filter(single => single.id === key)[0].value.required = value;
            this.setState({actionForms: actionForms})
        };
        const changeActiveIds = (actionFormId, optionId, optionName) => {
            this.setState({
                activeActionFormId:actionFormId,
                activeOptionId:optionId,
                activeOptionName:optionName,
            })
        }

        //handle change to send actionForm
        const filterActionFormData = () => {
            let actionForm = [];
            this.state.actionForms.map((single) => {
                return (
                    actionForm.push(single.value)
                )
            });
            this.props.handleChangeAction("actionForms", actionForm);
        };

        //close or delete filed
        const closeFieldSection = (key) => {
            var previousOrder = this.state.actionForms.filter(
                single => single.id === key
            )[0].value.orderNo;
            var removedActionData = this.state.actionForms.filter(x => x.id !== key)
            var lessThanOrder = removedActionData.filter(x => x.value.orderNo < previousOrder)
            var orderFiltered = removedActionData.filter(x => x.value.orderNo > previousOrder)
            orderFiltered.map(single => {
                single.value.orderNo = single.value.orderNo - 1
            })
            lessThanOrder = [...lessThanOrder, ...orderFiltered]

            this.setState({
                actionForms: lessThanOrder
            }, () => {
                filterActionFormData()
            })
        };

        const showInputForField = () => {
            let a = createAddFormObject();
            this.setState({
                actionForms: [...this.state.actionForms, a]
            });
        };
        const createAddFormObject = () => {
            return {
                id: String(Math.random()),
                optionObject: [],
                value: {
                    fieldName: '',
                    fieldType: '',
                    required: false,
                    orderNo: this.state.actionForms.length + 1
                }
            };
        };

        const onChangeConditionalActionForm = (key, optionId, conditionalForm) =>{
            if(this.state.conditionalActionForm && this.state.conditionalActionForm.length > 0){
                this.state.conditionalActionForm.filter(single => single.actionId === key
                    && single.optionId === optionId)[0].conditionalForm = conditionalForm;
            }
        }

        const changeFieldOptionValue = (key, optionArray, value) => {
            this.state.actionForms.filter(single => single.id === key)[0].optionObject = optionArray;
            this.state.actionForms.filter(single => single.id === key)[0].value.optionValue = value;
            filterActionFormData();
        };

        const onChangeSelectedValue = eventValue =>{
            if(optionValues){
                const abc = optionValues.filter(single => single.optionValueId === eventValue)[0];
                if(abc !== undefined){
                    abc.selected = true
                }
            }
        }
        const onChangeDeselectedValue = deselectedValue => {
            if(optionValues){
                const abc = optionValues.filter(single => single.optionValueId === deselectedValue)[0];
                if(abc !== undefined){
                    abc.selected = false
                }
            }
        }

        const changeFieldTypeCheckedValue = (key, activeOptionId, checkedValue) => {
            if(this.state.actionForms.filter(single => single.id === key)[0].optionObject.filter(obj =>
                obj.optionValueId === activeOptionId)[0])
            {
                this.state.actionForms.filter(single => single.id === key)[0].optionObject.
                filter(obj => obj.optionValueId === activeOptionId)[0].checkedValue = checkedValue;
                filterActionFormData();
            }
            if(optionValues){
                const abc = optionValues.filter(single => single.optionValueId === activeOptionId)[0];
                abc.checkedValue = checkedValue
            }
        };

        // ADD API
        const addActionAPICALL = () => {
            this.props.addNewAction(this.props.state.actionState.actionData, this.props)
        };

        const value = this.props.state.actionState.actionData &&
            this.props.state.actionState.actionData.actionName !== '';

        const optionObjectValues = this.state.singleFieldObject &&
                                this.state.singleFieldObject.optionObject &&
                                this.state.singleFieldObject.optionObject.filter(option =>
                                    option.optionValueId === this.state.activeOptionId)[0];

        const handleChangeFirstConditionalForm = (conditionalForm) =>{
            this.props.handleChangeFirstConditionalForm(conditionalForm);
        }

        return (
            <React.Fragment>
                <HelmetComponent title="Action"/>
                <Prompt
                    when={this.state.isBlocking}
                    message="Are you sure you want to leave this page?"
                />

                {
                    this.props.state.actionState.loading ?
                    <div className={this.props.state.actionState.loading ? 'loader' : 'loader hidden'} id="loader">
                        <CircularProgress style={{color: '#8BC83F'}}/>
                    </div> : null
                }
                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8bc83f', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/action" style={style.link}>
                        Action
                    </Link>
                    <Typography color="inherit" className="link">
                        Create Action
                    </Typography>
                </Breadcrumbs>
                <div style={style.container}>
                    <div className={"row"}>
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <label style={style.label}>Action Title</label>
                            <input
                                type="text"
                                className="form-control"
                                autoComplete='off'
                                id="actionName"
                                onChange={e => {
                                    this.setState({isBlocking: true});
                                    this.props.handleChangeAction(e.target.id, e.target.value)
                                }}
                                style={style.input}
                            />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-5" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            {openDropdownForTextBox()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4" style={{paddingLeft: '0', paddingRight: '0', position: 'inherit'}}>
                            <div style={{float: 'right', cursor: 'pointer'}} onClick={(e) => {
                                e.preventDefault();
                                this.setState({isBlocking: true});
                                showInputForField();
                            }}>
                                <img src="/assets/images/add-button-inside-circle.svg" alt="Add Button"
                                     style={{width: '22px', height: '22px', cursor: 'pointer'}}/>
                                <span style={{fontSize: '14px', paddingLeft: '4px'}}>Add Field</span>
                            </div>
                            <div className={"row"}
                                 style={{
                                    justifyContent: 'flex-end',
                                    padding: '2rem 0 1rem 0',
                                    marginLeft: 0,
                                    width: '100%'
                            }}>
                                <button
                                    disabled={!value}
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({isBlocking: false});
                                        addActionAPICALL();
                                    }}
                                    style={!value ? style.disabledButton : style.button}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

               {/* ************ CONDITIONAL FORM MODAL************ */}
                <Dialog
                    id={this.state.indexId}
                    open={this.state.openDialogueForNewActionForm}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                    PaperComponent={PaperComponent}
                    onClose={handleCloseDialogueForNewActionForm}
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={handleCloseDialogueForNewActionForm}>
                                 {this.state.activeOptionName}
                        </DialogTitleComponent>
                        <DialogContent dividers style={{ width: "700px", maxHeight: "500px"}}>
                            {this.state.conditionalActionForm && this.state.conditionalActionForm.length > 0
                            && this.state.conditionalActionForm.map(item =>
                                item.actionId === this.state.activeActionFormId &&
                                item.optionId === this.state.activeOptionId && (
                                <div>
                                    <ActionFormFieldsComponent
                                        handleChangeBack={handleChangeBack}
                                        changeActiveIds={changeActiveIds}
                                        onChangeConditionalActionForm={onChangeConditionalActionForm}
                                        conditionalActionForm={item}
                                        setConditionalFormActionData={setConditionalFormActionData}
                                        handleChangeFirstConditionalForm={handleChangeFirstConditionalForm}
                                        activeOptionId={this.state.activeOptionId}
                                        activeActionFormId={this.state.activeActionFormId}
                                        activeOptionName={this.state.activeOptionName}
                                        optionObjectValues={optionObjectValues}
                                        optionValues={optionValues}
                                        handleCloseDialogueForNewActionForm={handleCloseDialogueForNewActionForm}
                                        changeFieldConditionalForm={changeFieldConditionalForm}
                                        changeConditionalFormInOption={changeConditionalFormInOption}
                                        onHandleFirstConditionalFormStep={onHandleFirstConditionalFormStep}
                                    />
                                </div>
                            ))}
                        </DialogContent>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.actionState.openActionSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.actionState.errorMsgForAction}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    container: {
        background: "#ffffff",
        marginTop: "78px",
        marginLeft: '3.5%',
        marginRight: '3.5%'
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    label: {
        color: '#606060',
        marginBottom: '0',
        fontSize: '16px',
        paddingBottom: "0.5rem"
    },
    input: {
        height: "40px",
        backgroundColor: "#F4F5F9",
        border: "none",
        color: "#606060",
        width: '100%',
        marginBottom: '1.2rem'
    },
    dropdownInput: {
        paddingLeft: '8px',
        height: "40px",
        color: "#606060",
        backgroundColor: "#F4F5F9",
        border: "none",
        width: '100%',
        fontSize: '15px',
        marginBottom: '1.5rem',
        marginTop: '4px'
    },
    select: {
        position: "relative",
        height: "40px"
    },
    selectedAction: {
        border: '1px solid #DCDCDC',
        padding: '1rem',
        boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        fontSize: '14px',
        marginBottom: '0.5rem',
        width: '80%'
    },
    button: {
        height: '35px',
        background: "#8BC83F",
        color: 'white',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        width: '100px'
    },
    disabledButton: {
        height: '35px',
        background: "#b1d681",
        color: 'white',
        fontSize: '16px',
        border: 'none',
        borderRadius: '4px',
        width: '100px'
    },
    fieldLabel: {
        color: '#606060',
        fontSize: '15px',
        paddingTop: '1rem',
        paddingBottom: '0.5rem',
        paddingRight: '1rem'
    },
    expandMoreIcon: {
        position: "absolute",
        right: "8px",
        top: "8px",
        color: "#C8C5C5",
        fontSize: "35px"
    }
};
export default ActionFormScreen;
