import { Popover } from "antd";
import React from "react";
import AddIcon from "@material-ui/icons/Add";

const AddFilesButton = ({ onClickImages, onClickDocuments }) => {
  // const content = (
  //   <div style={{ width: "75px" }}>
  //     <div role="presentation" className="content1" onClick={onClickImages}>
  //       Images
  //     </div>
  //     <div role="presentation" className="content2" onClick={onClickDocuments}>
  //       Documents
  //     </div>
  //   </div>
  // );
  return (
    <Popover 
          // content={content} 
          placement="bottom" 
          // trigger="hover"
    >
      <button
        className="addButton"
        onClick={onClickImages}
      >
        <AddIcon /> Add File
      </button>
    </Popover>
  );
};

export default AddFilesButton;
