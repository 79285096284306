import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuItem,
  MenuList,
  Snackbar,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TablePagination from "@material-ui/core/TablePagination";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import ButtonComponent from "./ButtonComponent";
import MySnackbarContentWrapper from "../components/Snackbar";
import { DocumentImage } from "../components/DocumentImage";
import AddFilesButton from "../components/AddFilesButton";
import { uploadDocumentValidation, uploadImageValidation } from "../components/Helper";
const { Dragger } = Upload;

class IndividualContactFilesScreen extends Component {
  constructor(props) {
    super(props);
    const pathname = window.location.pathname.split("/");
    this.state = {
      contactId: pathname[2],
      fileList: {},
      openAddFileModal: false,
      openEditFileModal: false,
      openActionMenu: false,
      popoverElement: null,
      page: 0,
      rowsPerPage: 10,
      clicked: false,
      isDocument: false,
      fileUploaded: false,
    };
  }
  componentDidMount() {
    this.props.fetchContactFilesList(this.state.contactId, this.props);
  }

  render() {
    const scheduleTransactionState =
      this.props && this.props.state.scheduleTransactionState;
    const contactState = this.props.state && this.props.state.contactState;
    const loading = contactState && contactState.loading;
    const contactFileData = contactState && contactState.contactFileData;
    const contactFileValue =
      contactFileData.documentName !== "" && contactFileData.documentUrl !== "";
    const contactFileList = contactState && contactState.contactFileList;
    const scheduleLoading =
      scheduleTransactionState && scheduleTransactionState.loading;
    const openScheduleTransactionSnackBar =
      scheduleTransactionState &&
      scheduleTransactionState.openScheduleTransactionSnackBar;
    const errorMsg =
      scheduleTransactionState && scheduleTransactionState.errorMsg;

     
    const getDate = (dateToConvert) => {
      let dateConverted = new Date(
        parseInt(dateToConvert)
      ).toLocaleDateString();
      return dateConverted;
    };
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
    };
    const handleCloseAddFileModal = () => {
      this.setState({
        openAddFileModal: false,
        fileUploaded: false,
      });
    };
    const handleOpenAddFileModal = () => {
      this.setState({
        openAddFileModal: true,
      });
    };
    const handleCloseEditFileModal = () => {
      this.setState({
        openEditFileModal: false,
        fileUploaded: false,
      });
    };
    const handleOpenEditFileModal = () => {
      this.setState({
        openEditFileModal: true,
      });
    };
    const onDragAndDropFileHandler = (info) => {
      let file = info;
      // console.log(file.type)
      if ( file.type === "image/png" ||
           file.type === "image/jpeg" ||
           file.type === "text/jpg"
          ){
            onChangeImageFileHandler(file);
            this.setState({
              isDocument: false,
              fileUploaded: true,
            });
      }
        else{
          onChangeDocumentFileHandler(file);
          this.setState({
            isDocument: true,
            fileUploaded: true,
          });
      }
    }
    const onChangeFileHandler = (eventFileHandler) => {
      // console.log(eventFileHandler.target.files[0]);
      let file = eventFileHandler.target.files[0];
      // console.log(file.type);
      if ( file.type === "image/png" ||
           file.type === "image/jpeg" ||
           file.type === "text/jpg"
          ){
            onChangeImageFileHandler(file);
            this.setState({
              isDocument: false,
              fileUploaded: true,
            });
      }
        else{
          onChangeDocumentFileHandler(file);
          this.setState({
            isDocument: true,
            fileUploaded: true,
          });
      }
    }
    const onChangeDocumentFileHandler = (file) => {
      // let file = eventFileHandler.target.files[0];
      let type = file && file.type;
      let name = file && file.name;
      let size = file && file.size;
      let singleFile = {
        document: file,
        type: type,
        name: name,
      };
      if (uploadDocumentValidation(size, type)) {     
        this.props.uploadDocumentFile(singleFile, this.props);
      } else {
        if(size > 26214400){
          this.props.handleShowFileUploadErrorMessage(
            "File size is too large. Maximum size allowed is 25MB."
          );
        }else{
          this.props.handleShowFileUploadErrorMessage(
            "Invalid file type. Only PDF, TXT, CSV, EXCEL, WORD types are accepted"
          );
        }       
      }
    };
    const onChangeImageFileHandler = (file) => {
      // let file = eventFileHandler.target.files[0];
      let type = file && file.type;
      let name = file && file.name;
      let size = file && file.size;
      if (uploadImageValidation(size, type)) {
        let singleFile = {
          image: file,
          type: type,
          name: name,
        };
        this.props.uploadImageFile(singleFile, this.props);
      } else {
        if(size > 26214400){
          this.props.handleShowFileUploadErrorMessage(
            "File size is too large. Maximum size allowed is 25MB."
          );
        }else{
          this.props.handleShowFileUploadErrorMessage(
            "Invalid file type. Only PNG, JPG and JPEG types are accepted"
          );
        }
      }
    };

    const addIndividualContactFile = () => {
      if (!this.state.clicked) {
        this.setState({
          clicked: true,
        });
        this.props.addContactFiles(contactFileData, this.props);
        setTimeout(() => {
          this.setState({
            clicked: false,
          });
        }, 3000);
      }
    };

    const isImageToUpdate =
      (contactFileData.documentUrl &&
        contactFileData.documentUrl.split(".").pop() === "jpg") ||
      contactFileData.documentUrl.split(".").pop() === "jpeg" ||
      contactFileData.documentUrl.split(".").pop() === "png";

    // const abc =
    //   '{"note": ["", ""], "amount": [2600, 2600], "document": ["", ""], "end_date": [1637298000000, 1637298000000], "description": ["Surplus Deposit", "Surplus Deposit"], "schedule_type": [1, 1], "scheduled_date": [1637298000000, 1637298000000], "transaction_medium": [1, 1]}';

    return (
      <React.Fragment>
        {contactState && contactState.loading ? (
          <div
            className={
              contactState && contactState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/contact" className="links">
            Contact
          </Link>
          <Typography color="inherit" className="link">
            Contact Files
          </Typography>
        </Breadcrumbs>
        <div style={{ margin: "80px 3% 2%" }}>
          <div className={"d-flex flex-row-reverse div-flex"}>
            <div>
              <AddFilesButton
                // onClickDocuments={() => {
                //   handleOpenAddFileModal();
                //   this.props.handleChangeContactFile("documentUrl", "");
                //   this.setState({
                //     isDocument: true,
                //   });
                // }}
                onClickImages={() => {
                  handleOpenAddFileModal();
                  this.props.handleChangeContactFile("documentUrl", "");
                  // this.setState({
                  //   isDocument: false,
                  // });
                }}
              />
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="first-tableHeader">Files</TableCell>
                    <TableCell className="tableHeader">Date</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {contactFileList &&
                  contactFileList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            <a
                              href={list.documentUrl}
                              target="_blank"
                              className="actionsLink"
                            >
                              <DocumentImage documentURL={list.documentUrl} />
                              {list.documentName}
                            </a>
                          </TableCell>
                          <TableCell className="tableBody">
                            {getDate(list.createdAt)}
                          </TableCell>
                          <TableCell>
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  openActionMenu: true,
                                  fileList: list,
                                });
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenEditFileModal();
                                    this.props.setContactFileToEdit(
                                      this.state.fileList
                                    );
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
              {contactFileList && contactFileList.length > 0 ? (
                <div style={{ justifyContent: "flex-start", width: "100%" }}>
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={
                      contactFileList.length > 0 ? contactFileList.length : 100
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "1rem",
                      color: "#606060",
                    }}
                  >
                    {" "}
                    No data to show
                  </h3>
                </div>
              )}
            </TableContainer>
          </div>
        </div>
        {/*add files*/}
        <Dialog
          open={this.state.openAddFileModal}
          onClose={handleCloseAddFileModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseAddFileModal}
            >
              Add file
            </DialogTitleComponent>
            <DialogContent
              dividers
              // style={{
              //   borderBottom: "none",
              //   paddingTop: "0px",
              //   paddingBottom: "0px",
              // }}
              className="dialogContent"
            >
              <div className="row">
                <div className="col-12">
                {this.state.fileUploaded === true && 
                  <label htmlFor="note" className="label">
                    {this.state.isDocument ? "Document File" : "Image File"}
                    {" "}
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </label>
                }
                  {contactFileData.documentUrl ? (
                    <>
                        <div>
                        {this.state.isDocument ? (
                          <DocumentImage
                            documentURL={contactFileData.documentUrl}
                          />
                        ) : (
                          <img
                            className="dataImage"
                            src={contactFileData.documentUrl}
                            style={{ minWidth: "100px !important" }}
                          />
                        )}
                      </div>
                      <div style={{ marginBottom: "-18px" }}>
                        <input
                          type="text"
                          id="documentName"
                          className="input"
                          autoComplete="off"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeContactFile(
                              e.target.id,
                              e.target.value
                            );
                            this.props.handleChangeContactFile(
                              "refId",
                              this.state.contactId
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : null}

                  {this.state.fileUploaded === false && 
                      <Dragger
                        name="file"
                  
                        onDrop={(e) => {
                          e.preventDefault();
                          onDragAndDropFileHandler(e.dataTransfer.files[0]);
                        }}
                  
                         onChange= {(e) => {  onDragAndDropFileHandler(e.file.originFileObj) }}
                      >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                   }
                  <br />
                  <label
                    style={{
                      width: "85px",
                      marginTop: "1rem",
                      background: "rgba(204,204,254,0.32)",
                      border: "1px solid #7b7b7b",
                      textAlign: "center",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        onChangeFileHandler(e);
                      }}
                      className="hidden"
                      style={{ display: "none" }}
                    />
                    Select file
                  </label>
                </div>
              </div>
            {/* </DialogContent> */}
            <DialogActions style={{ padding: "10px 20px 12px 0px" }}>
              <ButtonComponent
                onClick={(e) => {
                  e.preventDefault();
                  addIndividualContactFile();
                  // this.props.addContactFiles(contactFileData, this.props);
                  handleCloseAddFileModal();
                }}
                value={contactFileValue}
                loading={loading}
                buttonName="Add"
              />
            </DialogActions>
            </DialogContent>
          </div>
        </Dialog>

        {/*edit files*/}
        <Dialog
          open={this.state.openEditFileModal}
          onClose={handleCloseEditFileModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseEditFileModal}
            >
              Update file
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                borderBottom: "none",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              className="dialogContent"
            >
              <div className="row">
                <div className="col-12">
                  <label htmlFor="note" className="label">
                    {isImageToUpdate ? "Image File" : "Document File"}{" "}
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </label>
                  {contactFileData.documentUrl ? (
                    <>
                      <div>
                        {!isImageToUpdate ? (
                            <DocumentImage
                              documentURL={contactFileData.documentUrl}
                            />
                          ) : (
                            <img
                              className="dataImage"
                              src={contactFileData.documentUrl}
                              style={{ minWidth: "100px !important" }}
                            />
                          )}
                      </div>
                      <div style={{ marginBottom: "-18px" }}>
                        <input
                          type="text"
                          id="documentName"
                          autoComplete="off"
                          value={contactFileData.documentName}
                          className="input"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeContactFile(
                              e.target.id,
                              e.target.value
                            );
                            this.props.handleChangeContactFile(
                              "refId",
                              this.state.contactId
                            );
                          }}
                        />
                      </div>
                    </>
                  ): null }
                  <br/>
                  <label
                    style={{
                      width: "115px",
                      marginTop: "0.5rem",
                      marginBottom: "1.5rem",
                      background: "rgba(204,204,254,0.32)",
                      border: "1px solid #7b7b7b",
                      textAlign: "center",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        onChangeFileHandler(e);
                      }}
                      className="hidden"
                      style={{ display: "none" }}
                    />
                    Select file
                  </label>
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 12px 0px" }}>
              <ButtonComponent
                onClick={(e) => {
                  e.preventDefault();
                  this.props.updateContactFiles(contactFileData, this.props);
                  handleCloseEditFileModal();
                }}
                value={contactFileValue}
                loading={loading}
                buttonName="Update"
              />
            </DialogActions>
          </div>
        </Dialog>
        {/* file upload error message */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openScheduleTransactionSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeScheduleTransactionSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeScheduleTransactionSnackBar}
            variant="error"
            message={errorMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}
const style = {
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginRight: 0,
    marginBottom: "1rem",
  },
};
export default IndividualContactFilesScreen;
