
import React, { Component } from "react";
import {
  Button,
  MenuItem,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitleComponent from "./DialogTitleComponent";
import DateConverter from "../utils/DateConverter";
import MySnackbarContentWrapper from "../components/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import Helpers, { uploadDocumentValidation, uploadImageValidation } from "../components/Helper";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DocumentImage } from "../components/DocumentImage";
import AddFilesButton from "../components/AddFilesButton";
import {LocalDb} from "../api";
const { Dragger } = Upload;

class DocumentsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialogToAddFile: false,
      fileList: {},
      openActionMenu: false,
      popoverElement: null,
      openEditFileModal: false,
      openDeleteFileModal: false,
      page: 0,
      rowsPerPage: 4,
      openErrorSnackBar: false,
      errors: {
        document: "",
      },
      isDocument: false,
      fileUploaded: false,
    };
  }
  componentDidMount() {
    this.props.fetchContactFilesList();
  }

  render() {
    const contactFileData = this.props.contactFileData;
    const clientId = this.props.clientId;
    const contactFileList = this.props.contactFileList;
    const contactState = this.props.contactState;
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : "CORPORATE_USER"
      : null;
      {userType && console.log(userType)}
    const value =
      contactFileData.documentUrl !== "" && contactFileData.documentName !== "";

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()};
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()};
    };

    const handleOpenToAddFile = () => {
      this.props.handleChangeContactFile("refId", clientId);
      this.setState({
        openDialogToAddFile: true,
        errors: {
          document: "",
        },
      });
    };
    const handleCloseToAddFile = () => {
      this.setState({
        openDialogToAddFile: false,
        fileUploaded: false,
      });
    };
    const handleCloseEditFileModal = () => {
      this.setState({
        openEditFileModal: false,
        fileUploaded: false,
      });
    };
    const handleOpenEditFileModal = () => {
      this.setState({
        openEditFileModal: true,
        errors: {
          document: "",
        },
      });
    };
    const handleOpenDeleteFileModal = () => {
      this.setState({
        openDeleteFileModal: true,
      });
    };
    const handleCloseDeleteFileModal = () => {
      this.setState({
        openDeleteFileModal: false,
      });
    };


    const onDragAndDropFileHandler = (info) => {
      let file = info;
      console.log(file.type)
      if ( file.type === "image/png" ||
           file.type === "image/jpeg" ||
           file.type === "text/jpg"
          ){
            onChangeImageFileHandler(file);
            this.setState({
              isDocument: false,
              fileUploaded: true,
            });
      }
        else{
          onChangeDocumentFileHandler(file);
          this.setState({
            isDocument: true,
            fileUploaded: true,
          });
      }
    }

    const onChangeFileHandler = (eventFileHandler) => {
      // console.log(eventFileHandler.target.files[0]);
      let file = eventFileHandler.target.files[0];
      // console.log(file.type);
      if ( file.type === "image/png" ||
           file.type === "image/jpeg" ||
           file.type === "text/jpg"
          ){
            onChangeImageFileHandler(file);
            this.setState({
              isDocument: false,
              fileUploaded: true,
            });
      }
        else{
          onChangeDocumentFileHandler(file);
          this.setState({
            isDocument: true,
            fileUploaded: true,
          });
      }
    }
    const onChangeDocumentFileHandler = (file) => {
      // let file = eventFileHandler.target.files[0];
      let type = file && file.type;
      let name = file && file.name;
      let size = file && file.size;
      let singleFile = {
        document: file,
        type: type,
        name: name,
      };
      if (uploadDocumentValidation(size, type)) {
        this.props.uploadDocumentFile(singleFile, this.props);
      } else {
        if (size > 26214400) {
          this.props.handleShowFileUploadErrorMessage(
            "File size is too large. Maximum size allowed is 25MB."
          );
        } else {
          this.props.handleShowFileUploadErrorMessage(
            "Invalid file type. Only PDF, TXT, CSV, EXCEL, WORD types are accepted"
          );
        }
      }
    };

    const onChangeImageFileHandler = (file) => {
      // let file = eventFileHandler.target.files[0];
    //  console.log(file)
      let type = file && file.type;
      let name = file && file.name;
      let size = file && file.size;
      if (uploadImageValidation(size, type)) {
        let singleFile = {
          image: file,
          type: type,
          name: name,
        };
        this.props.uploadImageFile(singleFile, this.props);
      } else {
        if (size > 26214400) {
          this.props.handleShowFileUploadErrorMessage(
            "File size is too large. Maximum size allowed is 25MB."
          );
        } else {
          this.props.handleShowFileUploadErrorMessage(
            "Invalid file type. Only PNG, JPG and JPEG types are accepted"
          );
        }
      }
    };

    const isImageToUpdate =
      (contactFileData.documentUrl &&
        contactFileData.documentUrl.split(".").pop() === "jpg") ||
      contactFileData.documentUrl.split(".").pop() === "jpeg" ||
      contactFileData.documentUrl.split(".").pop() === "png";

    return (
      <React.Fragment>
        <div>
          {userType && userType === "CORPORATE_USER" ? 
          <div style={{display: "none"}}></div> :
          <div
            className={"d-flex flex-row-reverse div-flex"}
            // style={{ paddingTop: "0px" }}
          >
            <div>
              <AddFilesButton
                onClickImages={() => {
                  handleOpenToAddFile();
                  this.props.handleChangeContactFile("documentUrl", "");
                  this.props.handleChangeContactFile("documentName", "");
                }}
              />
            </div>
          </div>
          }
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header-second">
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="first-tableHeader">Files</TableCell>
                    <TableCell className="tableHeader">Date</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {contactFileList &&
                  contactFileList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list) => (
                      <TableBody>
                        <TableRow>
                          <TableCell className="first-tableBody">
                            <a
                              href={list.documentUrl}
                              target="_blank"
                              className="actionsLink"
                            >
                              <DocumentImage documentURL={list.documentUrl} />
                              {list.documentName}
                            </a>
                          </TableCell>
                          <TableCell className="tableBody">
                            {DateConverter(list.createdAt)}
                          </TableCell>
                          <TableCell>
                          {userType && userType === "CORPORATE_USER" ? 
                              <div style={{display: "none"}}></div> :
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  popoverElement: e.currentTarget,
                                  openActionMenu: true,
                                  fileList: list,
                                });
                              }}
                            />
                            }
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuList
                                id="popover-list"
                                style={{
                                  color: "#4d4d4d",
                                  padding: "1rem !important",
                                  cursor: "pointer",
                                }}
                              >
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenEditFileModal();
                                    this.props.setContactFileToEdit(
                                      this.state.fileList
                                    );
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDeleteFileModal();
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
              </TableContainer>
              {contactFileList && contactFileList.length > 0 ? (
                <div style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[4, 10, 25, 100]}
                    component="div"
                    count={
                      contactFileList.length > 0 ? contactFileList.length : 100
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div style={{     
                        display: "flex", 
                        width: "100%",
                        justifyContent:"center"
                    }}>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "1rem",
                      color: "#606060",
                    }}
                  >
                    {" "}
                    No data to show
                  </h3>
                </div>
              )}
            {/* </TableContainer> */}
          </div>
        </div>
        {/*add files*/}
        <Dialog
          open={this.state.openDialogToAddFile}
          onClose={handleCloseToAddFile}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseToAddFile}
            >
              Add file
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-12">
                  {this.state.fileUploaded === true && 
                    <label htmlFor="note" className="label">
                    {this.state.isDocument ? "Document File" : "Image File"}
                    {" "}
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </label>
                  }
                  {contactFileData.documentUrl ? (
                    <>
                      <div>
                        {this.state.isDocument ? (
                          <DocumentImage
                            documentURL={contactFileData.documentUrl}
                          />
                        ) : (
                          <img
                            className="dataImage"
                            src={contactFileData.documentUrl}
                            style={{ minWidth: "100px !important" }}
                          />
                        )}
                      </div>
                      <div style={{ marginBottom: "-18px" }}>
                        <input
                          type="text"
                          id="documentName"
                          className="input"
                          name="document"
                          autoComplete="off"
                          onChange={(e) => {
                            e.preventDefault();
                            Helpers.handleValidationOnChange(
                              e,
                              this.state.errors
                            );
                            this.props.handleChangeContactFile(
                              e.target.id,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                  
                  {this.state.fileUploaded === false && 
                  <Dragger
                  name="file"
             
                  onDrop={(e) => {
                    e.preventDefault();
                    onDragAndDropFileHandler(e.dataTransfer.files[0]);
                  }}
               
                  onChange= {(e) => {  onDragAndDropFileHandler(e.file.originFileObj) }}
                  >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                   }
                    <br/>
                  <label
                    style={{
                      width: "85px",
                      marginTop: "1rem",
                      background: "rgba(204,204,254,0.32)",
                      border: "1px solid #7b7b7b",
                      textAlign: "center",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >

                 
                    <input
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        onChangeFileHandler(e);
                      }}
                      className="hidden"
                      style={{ display: "none" }}
                    />
                    {this.props.scheduleLoading ? "Loading..." : "Select File"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "142px" }}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseToAddFile();
                      this.props.addClientFile();
                    }}
                    style={{ textTransform: "none" }}
                    className={!value ? "disabledAddButton" : "addButton"}
                    disabled={!value}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*edit files*/}
        <Dialog
          open={this.state.openEditFileModal}
          onClose={handleCloseEditFileModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseEditFileModal}
            >
              Update file
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="note" style={style.columnHeading}>
                    {isImageToUpdate ? "Image File" : "Document File"}
                    {" "}
                    {/* <span style={{ color: "red" }}> *</span> */}
                  </label>
                  {contactFileData.documentUrl ? (
                    <>
                      <div>
                        {!isImageToUpdate ? (
                          <DocumentImage
                            documentURL={contactFileData.documentUrl}
                          />
                        ) : (
                          <img
                            className="dataImage"
                            src={contactFileData.documentUrl}
                            style={{ minWidth: "100px !important" }}
                          />
                        )}
                      </div>
                      <div style={{ marginBottom: "-18px" }}>
                        <input
                          type="text"
                          id="documentName"
                          autoComplete="off"
                          value={contactFileData.documentName}
                          className="input"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleChangeContactFile(
                              e.target.id,
                              e.target.value
                            );
                            this.props.handleChangeContactFile(
                              "refId",
                              clientId
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                  <br />
                  <label
                    style={{
                      width: "115px",
                      marginTop: "0.5rem",
                      marginBottom: "1.5rem",
                      background: "rgba(204,204,254,0.32)",
                      border: "1px solid #7b7b7b",
                      textAlign: "center",
                      padding: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        onChangeFileHandler(e);
                      }}
                      className="hidden"
                      style={{ display: "none" }}
                    />
                    {this.props.scheduleLoading ? "Loading..." : "Select File"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "128px" }}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.updateClientFile();
                      handleCloseEditFileModal();
                    }}
                    style={{ textTransform: "none" }}
                    className={!value ? "disabledAddButton" : "addButton"}
                    disabled={!value}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/* delete file modal */}
        {/*Delete client*/}
        <Dialog
          open={this.state.openDeleteFileModal}
          onClose={handleCloseDeleteFileModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete this file?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDeleteFileModal}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleCloseDeleteFileModal();
                      this.props.deleteFile(
                        this.state.fileList.contactDocumentId
                      );
                      // handleOkDeleteClientDialogue()
                    }}
                    style={style.deleteOkButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openErrorSnackBar}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({
              openSnackBar: false,
            });
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.setState({
                openErrorSnackBar: false,
              });
            }}
            variant="error"
            message="Invalid file type"
          />
        </Snackbar>
         {/* file upload error message */}
         <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.openScheduleTransactionSnackBar}
          autoHideDuration={6000}
          onClose={this.props.closeScheduleTransactionSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.closeScheduleTransactionSnackBar}
            variant="error"
            message={this.props.errorMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  card: {
    background: "rgb(255, 255, 255)",
    border: "1px solid rgb(233, 233, 233)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "1rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.6rem",
  },
  deleteOkButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
};

export default DocumentsScreen;
