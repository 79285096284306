import React, { useEffect } from "react";
import { Switch } from "antd";

const AlertNotificationEnableScreen = ({
  accountId,
  clientState,
  handleChangeHolidayAlert,
  handleChangeUpcomingAlert,
  saveNotifications,
getAlertInfo,
}) => {
    useEffect(() => {
        getAlertInfo(accountId);
    },  [])
  const upcomingAlert = clientState && clientState.upcomingAlert;
  const holidayAlert = clientState && clientState.holidayAlert;
  const emailAlertId = clientState && clientState.emailAlertId;
  console.log({upcomingAlert, holidayAlert, emailAlertId})
//   const openClientSnackBar = clientState && clientState.openClientSnackBar;
//   const successMsg = clientState && clientState.successMsg;
//   const openClientErrorSnackBar =
//     clientState && clientState.openClientErrorSnackBar;
//   const errorMsgForClient = clientState && clientState.errorMsgForClient;
  
  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-4">
          <div style={{ fontSize: "18px" }}>Holiday Alert</div>
          <div style={{ color: "#676767" }}>
            Enable holiday alert message for schedule transaction.
          </div>
        </div>
        <div className="col-lg-4" style={{ paddingTop: "12px" }}>
          <Switch
            checked={holidayAlert}
            onChange={(e) => {
              const enabledData = {
                accountId: accountId,
                upcomingTransactionAlert: upcomingAlert,
                upcomingHolidayAlert: e,
              };
              if (emailAlertId !== "") {
                enabledData.emailAlertId = emailAlertId;
              }
              saveNotifications(enabledData);
              handleChangeHolidayAlert(e);
            }}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-4">
          <div style={{ fontSize: "18px" }}>Upcoming Transaction Alert</div>
          <div style={{ color: "#676767" }}>
            Enable upcoming transaction alert message.
          </div>
        </div>
        <div className="col-lg-4" style={{ paddingTop: "12px" }}>
          <Switch
            checked={upcomingAlert}
            onChange={(e) => {               
              const enabledData = {
                accountId: accountId,
                upcomingTransactionAlert: e,
                upcomingHolidayAlert: holidayAlert,
              };
              if (emailAlertId !== "") {
                enabledData.emailAlertId = emailAlertId;
              }
              handleChangeUpcomingAlert(e);
              saveNotifications(enabledData);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertNotificationEnableScreen;
