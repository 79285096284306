import { ACTION_TYPE_DASHBOARD} from "../constants/Type";

export const fetchCaseDataForDashboardSuccess = response => {
    return {
        type: ACTION_TYPE_DASHBOARD.GET_CASE_STATUS_FOR_DASHBOARD_SUCCESS,
        response
    };
};

export const fetchCaseDataForDashboardFailure = error => {
    return {
        type: ACTION_TYPE_DASHBOARD.GET_CASE_STATUS_FOR_DASHBOARD_FAILURE,
        error
    };
};

export const requestForDashboard = () =>{
    return{
        type: ACTION_TYPE_DASHBOARD.REQUEST_MADE_FOR_DASHBOARD,
    };
};

export const fetchTransactionDataForDashboardSuccess = response => {
    return {
        type: ACTION_TYPE_DASHBOARD.GET_TRANSACTION_STATUS_FOR_DASHBOARD_SUCCESS,
        response
    };
};

export const fetchTransactionDataForDashboardFailure = error => {
    return {
        type: ACTION_TYPE_DASHBOARD.GET_TRANSACTION_STATUS_FOR_DASHBOARD_FAILURE,
        error
    };
};

export const fetchOtherStatForDashboardSuccess = response => {
    return {
        type: ACTION_TYPE_DASHBOARD.GET_OTHER_STATUS_FOR_DASHBOARD_SUCCESS,
        response
    };
};

export const fetchOtherStatForDashboardFailure = error => {
    return {
        type: ACTION_TYPE_DASHBOARD.GET_OTHER_STATUS_FOR_DASHBOARD_FAILURE,
        error
    };
};

export const globalSearchSuccess = response => {
    return {
        type: ACTION_TYPE_DASHBOARD.GLOBAL_SEARCH_SUCCESS,
        response
    };
};

export const globalSearchFailure = error => {
    return {
        type: ACTION_TYPE_DASHBOARD.GLOBAL_SEARCH_FAILURE,
        error
    };
};

