import React from 'react';
import {Tag} from "antd";

const TransactionTag = ({list}) => {
    return(
        <Tag
            color={
                list.transactionStatus ===
                "TRANSACTION_APPROVED"
                    ? "green"
                    : list.transactionStatus ===
                    "TRANSACTION_BLOCKED"
                        ? "yellow"
                        : list.transactionStatus ===
                        "TRANSACTION_REVERSED"
                            ? "red"
                            : list.transactionStatus ===
                            "TRANSACTION_CREATED"
                                ? "purple"
                                : list.transactionStatus ===
                                "TRANSACTION_SUBMITTED"
                                    ? "blue"
                                    : list.transactionStatus ===
                                    "TRANSACTION_RETURNED"
                                        ? "geekblue"
                                        : list.transactionStatus ===
                                        "TRANSACTION_POSTED"
                                            ? "cyan"
                                            : list.transactionStatus ===
                                            "TRANSACTION_SKIPPED"
                                                ? "orange"
                                                : list.transactionStatus ===
                                                "TRANSACTION_ON_HOLD"
                                                    ? "volcano"
                                                    : list.transactionStatus ===
                                                    "TRANSACTION_CANCELLED"
                                                        ? "gold"
                                                        : list.transactionStatus ===
                                                        "TRANSACTION_CLOSED"
                                                            ? "magenta"
                                                            : "lime"
            }
        >
            {list.transactionStatus &&
                list.transactionStatus
                    .replace("TRANSACTION_", " ")
                    .replace("_", " ")}
        </Tag>
    )
};

export default TransactionTag;