import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  MenuList,
  // Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TablePagination from "@material-ui/core/TablePagination";
import { Select, Tabs, Tag, Tooltip } from "antd";
import MySnackbarContentWrapper from "../components/Snackbar";
import { LocalDb } from "../api";
import "../styles/styles.css";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import RefreshIcon from "@material-ui/icons/Refresh";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers, { arraysEqual } from "../components/Helper";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import {
  editAmount,
  finalAmount,
  naturalRound,
} from "../utils/currencyConvertor";
import { Avatar, Popover } from "antd";
import ExportButton from "../components/ExportButton";
import { DocumentImage } from "../components/DocumentImage";
import NotesPopover from "../components/NotesPopover";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import EditOutlined from "@material-ui/icons/EditOutlined";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {
  getFormattedDateTimeValue,
  getFormattedDateValue,
} from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import AnnualTransactionTable from "./Transaction/AnnualTransactionTable";
import LedgerTransactionTable from "./Transaction/LedgerTransactionTable";
import { ScheduleTransactionContainer } from "../components/container";
// import ClientDetailPageScreen from './ClientDetailPageScreen';
import {
  FilterStatusData,
  FilterMediumData,
  FilterTypeData,
  FilterTypeDataForLedger,
  FilterUPTypeData,
  FilterLEDTypeData,
  allTypeArray,
} from "../components/FilterOptionList";
// import MultipleSelectCard from '../components/MultipleSelectCard';
import {
  transactionStatusHelper,
  transactionMediumHelper,
  transactionTypeHelper,
  showGreaterThanSign,
  getRangeType,
} from "./TransactionHelper";
import CheckboxFilterPopover from "../components/CheckboxFilterPopover";
import { getDateWithStartTimeValue } from "../utils/DateConverter";
import { Tab } from "semantic-ui-react";
import SaveConfigModal from "../components/SaveConfigModal";
import ChangedLogPopover from "../components/ChangedLogPopover";
import DeletedTransactionTable from "./Transaction/DeletedTransaction";
const { Option } = Select;
const { TabPane } = Tabs;

class TransactionScreenScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.rangeRef = React.createRef();
    // this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      openDialogue: false,
      openSearchDialogue: false,
      openUpcomingFilterDialogue: false,
      openDialogueToProcess: false,
      openModalToCreateTransaction: false,
      openModalToUndoReverse: false,
      page: 0,
      rowsPerPage: 10,
      pageUpcoming: 0,
      rowsPerPageForUpcoming: 10,
      pageAnnualUpcoming: 0,
      rowsPerPageAnnualUpcoming: 10,
      pageLedgerTrans: 0,
      rowsPerPageLedgerTrans: 10,
      trans: {},
      transaction: "",
      transactionId: "",
      checkNo: "",
      status: "hello",
      isUpdate: false,
      buttonDisable: true,
      selectedTransData: "",
      upComingTrans: {},
      transTabKey: localStorage.getItem("transTabKey"),
      openModalToImportTransaction: false,
      openModalToChangeTransStatus: false,
      setValue: "",
      errors: {
        clientName: "",
        amount: "",
        transactionMedium: "",
        transactionType: "",
        vendor: "",
        note: "",
        bankId: "",
        document: "",
      },
      isProcess: false,
      openActionMenu: false,
      popoverElement: null,
      isFirstOpen: false,
      openLedgerFilter: false,
      ledgerTrans: {},
      upComingTransactionObject: {},
      isUpdateNote: false,
      openActivityLogModal: false,
      clearBankDetail: false,
      isFirstSelect: false,
      openModalToDeclineTransaction: false,
      openModalToDeclineCheck: false,
      openModalToEditBlockedTransaction: false,
      isDeclined: false,
      selectedStatus: "",
      isReverse: false,
      checkValue: null,
      isChecked: false,
      openDropDown: false,
      tempRowId: "",
      openModalToDeleteTransaction: false,
      visible: false,
      isALlChecked: true,
      isAllMediumChecked: true,
      isAllTypeChecked: true,
      isAllUPTypeCheked: true,
      isAllLEDTypeChecked: true,
      isAllLedCaseTypeChecked: true,
      isStatusCheck: FilterStatusData.map((status) => status.name),
      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      // isTypeCheck: FilterTypeData.map((type) => type.name),
      isTypeCheck: allTypeArray.map((type) => type.name),
      isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      // isLEDTypeCheck: FilterLEDTypeData.map((type) => type.name),
      isLEDTypeCheck: allTypeArray.map((type) => type.name),
      isLedCaseTypeCheck: FilterTypeDataForLedger.map((type) => type.name),
      prevAmount: "",
      order: "desc",
      orderBy: "transactionTimestamp",
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,

      configName: "",
      openConfirmationToUndoReverse: false,
      isUpcomingFilter: false,
    };
  }

  componentDidMount() {
    // document.addEventListener('click', this.handleClickOutSide, true);
    document.addEventListener("click", this.handleClickOutside);
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;

    const isClient = userType === "CLIENT_USER";
    if (isClient) {
      if (this.state.transTabKey === "list") {
        this.props.fetchTransactionList();
      }
      if (this.state.transTabKey === "upcoming") {
        this.props.fetchUpcomingTransactionList();
      }
    }
    this.props.fetchVendor();
    this.props.fetchDescriptionList();
    this.props.clearTransactionFilter();
    this.props.clearUpTransFilter();
  }

  componentWillUnmount() {
    localStorage.setItem("transTabKey", "list");
    document.removeEventListener("click", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.rangeRef && !this.rangeRef.current.contains(event.target)) {
      this.setState({ openRangeBar: false });
    }
  }

  handleChange(e) {
    this.props.handleChangeTransData("isLedger", e.target.checked);
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleAllCheckbox = () => {
    this.setState({ isALlChecked: !this.state.isALlChecked });
    this.setState({ isStatusCheck: FilterStatusData.map((li) => li.name) });
    if (this.state.isALlChecked) {
      this.setState({ isStatusCheck: [] });
    }
  };

  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };

  handleAllTypeCheckbox = () => {
    this.setState({ isAllTypeChecked: !this.state.isAllTypeChecked });
    this.setState({ isTypeCheck: allTypeArray.map((li) => li.name) });
    if (this.state.isAllTypeChecked) {
      this.setState({ isTypeCheck: [] });
    }
  };

  handleAllUPTypeCheckbox = () => {
    this.setState({ isAllUPTypeCheked: !this.state.isAllUPTypeCheked });
    this.setState({ isUPTypeCheck: FilterUPTypeData.map((li) => li.name) });
    if (this.state.isAllUPTypeCheked) {
      this.setState({ isUPTypeCheck: [] });
    }
  };

  handleAllLEDTypeCheckbox = () => {
    this.setState({ isAllLEDTypeChecked: !this.state.isAllLEDTypeChecked });
    this.setState({ isLEDTypeCheck: allTypeArray.map((li) => li.name) });
    if (this.state.isAllLEDTypeChecked) {
      this.setState({ isLEDTypeCheck: [] });
    }
  };
  handleAllLedCaseTypeCheckbox = () => {
    this.setState({
      isAllLedCaseTypeChecked: !this.state.isAllLedCaseTypeChecked,
    });
    this.setState({
      isLedCaseTypeCheck: FilterTypeDataForLedger.map((li) => li.name),
    });
    if (this.state.isAllLedCaseTypeChecked) {
      this.setState({ isLedCaseTypeCheck: [] });
    }
  };

  handleSelectedChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isStatusCheck: [...this.state.isStatusCheck, value] });
    if (!checked) {
      this.setState({
        isStatusCheck: this.state.isStatusCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isTypeCheck: [...this.state.isTypeCheck, value] });
    if (!checked) {
      this.setState({
        isTypeCheck: this.state.isTypeCheck.filter((item) => item !== value),
      });
    }
  };

  handleSelectedUPTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isUPTypeCheck: [...this.state.isUPTypeCheck, value] });
    if (!checked) {
      this.setState({
        isUPTypeCheck: this.state.isUPTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedLEDTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isLEDTypeCheck: [...this.state.isLEDTypeCheck, value] });
    if (!checked) {
      this.setState({
        isLEDTypeCheck: this.state.isLEDTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  handleSelectedLedCaseTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({
      isLedCaseTypeCheck: [...this.state.isLedCaseTypeCheck, value],
    });
    if (!checked) {
      this.setState({
        isLedCaseTypeCheck: this.state.isLedCaseTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };
  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };

  render() {
    // user
    const userInfoFromDB = LocalDb.getSessions();
    const userType = userInfoFromDB
      ? userInfoFromDB.loginResponse.loginUser.user
        ? "EVERFUND_USER"
        : userInfoFromDB
        ? userInfoFromDB.loginResponse.loginUser.corporate
          ? "CORPORATE_USER"
          : "CLIENT_USER"
        : null
      : null;
    const userId =
      userInfoFromDB && userInfoFromDB.loginResponse.loginUser.user
        ? userInfoFromDB.loginResponse.loginUser.user.userId
        : null;

    const clientUserId =
      userInfoFromDB && userInfoFromDB.loginResponse.loginUser.client
        ? userInfoFromDB.loginResponse.loginUser.client.clientId
        : null;
    const userRole =
      userInfoFromDB.loginResponse.loginUser.user &&
      userInfoFromDB.loginResponse.loginUser.user.userRole;
    const accountType =
      userInfoFromDB.loginResponse.loginUser.user &&
      userInfoFromDB.loginResponse.loginUser.user.accountType;

    const activeTab = this.props.location.pathname.split("/")[2];

    // transaction states
    const transactionState = this.props && this.props.transactionState;

    const transactionList =
      transactionState && transactionState.transactionData;
    const ledgerTransactionList =
      transactionState &&
      transactionState.ledgerTransactionList &&
      transactionState.ledgerTransactionList.transactions;
    const totalAmount =
      transactionState && transactionState.ledgerTransactionList.totalAmount;
    const transStatus = transactionState && transactionState.transStatus;
    const declineRemark = transactionState && transactionState.declineRemark;
    const csvFile = transactionState && transactionState.csvFile;
    const value = csvFile !== "";
    const trans = transactionState && transactionState.trans;

    const deletedTransData =
      transactionState && transactionState.deletedTransaction;
    const transData = transactionState && transactionState.transData;
    const blockedData = transactionState && transactionState.blockedTransData;
    const transactionMedium = transData.transactionMedium;
    const transType = transData.transactionType;
    // const filterStatusCheckedData = transactionState && transactionState.filterStatusData;
    // const filterStatusData = filterStatusCheckedData;
    const startDate = transactionState && transactionState.from;
    const endDate = transactionState && transactionState.to;
    const mediumType = transactionState && transactionState.mediumType;
    const status = transactionState && transactionState.transactionStatus;
    const transactionType = transactionState && transactionState.transType;
    const term = transactionState && transactionState.term;
    const vendorToFilter = transactionState && transactionState.vendorToFilter;
    const openNotesModal = transactionState && transactionState.openNotesModal;
    const notes = transactionState && transactionState.notes;
    const openCreateWithdrawVendorTransaction =
      transactionState && transactionState.openCreateWithdrawVendorTransaction;
    const withdrawalVendorList =
      transactionState && transactionState.withdrawalVendorList;
    const amountDetails = transactionState && transactionState.amountDetails;
    const bankDetails = transactionState && transactionState.bankDetails;
    const openNotesModalForLedger =
      transactionState && transactionState.openNotesModalForLedger;

    const reversedDate = transactionState && transactionState.reverseDate;
    // upcoming transaction states
    const upcomingTransactionState =
      this.props && this.props.upcomingTransactionState;
    const upcomingTrans =
      upcomingTransactionState && upcomingTransactionState.upcomingTrans;

    const annualUpcomingTrans =
      upcomingTransactionState &&
      upcomingTransactionState.annualUpComingTransactionData;
    const openModalToSkipTransaction =
      upcomingTransactionState &&
      upcomingTransactionState.openModalToSkipTransaction;
    const remarkToSkip =
      upcomingTransactionState && upcomingTransactionState.remarkToSkip;

    // new filter
    const UPDate =
      upcomingTransactionState && upcomingTransactionState.filterDate;
    const UPAmount =
      upcomingTransactionState && upcomingTransactionState.filterAmount;
    const UPUser =
      upcomingTransactionState && upcomingTransactionState.filterUser;
    const UPVendor =
      upcomingTransactionState && upcomingTransactionState.filterVendor;
    const UPMedium =
      upcomingTransactionState && upcomingTransactionState.filterMedium;
    const UPType =
      upcomingTransactionState && upcomingTransactionState.filterType;
    const UPSchDate =
      upcomingTransactionState && upcomingTransactionState.filterSchedule;

    const UPCompareType =
      upcomingTransactionState && upcomingTransactionState.compareType;

    // schedule transaction states
    const scheduleTransactionState =
      this.props && this.props.scheduleTransactionState;
    const clients =
      scheduleTransactionState && scheduleTransactionState.clientList;
    const clientRes =
      scheduleTransactionState && scheduleTransactionState.clientRes;

    const fileUrl =
      scheduleTransactionState && scheduleTransactionState.fileUrl;
    const scheduleLoading =
      scheduleTransactionState && scheduleTransactionState.loading;
    const openScheduleTransactionSnackBar =
      scheduleTransactionState &&
      scheduleTransactionState.openScheduleTransactionSnackBar;
    const errorMsg =
      scheduleTransactionState && scheduleTransactionState.errorMsg;

    // setting states
    const settingState = this.props && this.props.settingState;
    const descriptionList = settingState && settingState.descriptionList;
    // vendor states
    const vendorList = this.props.vendorState.vendorAccount;
    const clientVendor =
      this.props.vendorState && this.props.vendorState.clientVendor;
    const vendorData =
      vendorList &&
      vendorList.filter(
        (vendor) => vendor.vendorType && vendor.vendorType.isDefault
      );

    // new filter states
    const transDate = transactionState && transactionState.filterDate;
    const desc = transactionState && transactionState.filterDesc;
    const amount = transactionState && transactionState.filterAmount;
    const checkNo = transactionState && transactionState.filterCheckNo;
    const user = transactionState && transactionState.filterUser;
    const type = transactionState && transactionState.filterType;

    const stat = transactionState && transactionState.filtetStatus;
    const vendor = transactionState && transactionState.filterVendor;
    const medium = transactionState && transactionState.filterMedium;
    const configlist = transactionState && transactionState.configlist;

    const filterDebitAmount =
      transactionState && transactionState.filterDebitAmount;
    const filterCreditAmount =
      transactionState && transactionState.filterCreditAmount;
    const compareType = transactionState && transactionState.compareType;
    // activity states
    const activityState = this.props && this.props.activityLogState;
    const activityLogValue = activityState && activityState.activityLogValue;

    const handleOpenActivityLogModal = () => {
      this.setState({
        openActivityLogModal: true,
      });
    };
    const handleCloseActivityLogModal = () => {
      this.setState({
        openActivityLogModal: false,
      });
    };
    const handleChangeOpenModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: true,
      });
    };
    const handleChangeOpenModalToUndoReverse = () => {
      this.setState({
        openModalToUndoReverse: true,
      });
    };
    const handleChangeCloseModalToUndoReverse = () => {
      this.setState({
        openModalToUndoReverse: false,
      });
    };

    const handleModalToEditBlockedTransaction = () => {
      this.setState({
        openModalToEditBlockedTransaction: true,
      });
    };
    const handleCloseToEditBlockedTransaction = () => {
      this.setState({
        openModalToEditBlockedTransaction: false,
      });
      this.props.clearEditBlockedTrans();
    };
    const handleChangeCloseModalToDecline = () => {
      this.setState({
        openModalToDeclineCheck: false,
      });
    };
    const handleUpdateCheckStatus = () => {
      const checkData = {
        // checkPrintId:
        transactionId: this.state.transactionId,
        description: declineRemark,
      };
      this.props.updateCheckStatus(checkData, this.props);
    };

    const handleChangeBlockedTransaction = () => {
      if (blockedData.date === null) {
        const data = {
          transactionId: this.state.transactionId,
          amount: blockedData.amount,
          // transactionTimestamp: blockedData.date === null ? 0 : blockedData.date,
        };

        this.props.editBlockedTransaction(data, this.props);
      } else {
        const data = {
          transactionId: this.state.transactionId,
          amount: blockedData.amount,
          transactionTimestamp:
            blockedData.date === null ? 0 : blockedData.date,
        };

        this.props.editBlockedTransaction(data, this.props);
      }
      //   const data = {
      //     transactionId: this.state.transactionId,
      //     amount: blockedData.amount,
      //     transactionTimestamp: blockedData.date === null ? 0 : blockedData.date,
      //   };
      //   this.props.editBlockedTransaction(data, this.props);
    };

    // const handleUpdateBlockedTransaction = () => {
    // 	const data = {
    // 		transaction: [
    // 			{
    // 				transactionId: this.state.transactionId,
    // 				amount: blockedData.amount,
    // 				transactionTimestamp: blockedData.transactionId,
    // 			},
    // 		],
    // 	};
    // 	this.props.updateBlockedTransaction(data, this.props);
    // }
    // const filteredValue =
    // 	startDate !== null ||
    // 	endDate !== null ||
    // 	// mediumType !== "UNKNOWN_TRANSACTION_MEDIUM" ||
    // 	// status !== "UNKNOWN_TRANSACTION_STATUS" ||
    // 	transactionType !== 'UNKNOWN_TRANSACTION_TYPE' ||
    // 	vendorToFilter !== '';
    // const filteredLedgerValue =
    // 	startDate !== null ||
    // 	endDate !== null ||
    // 	status !== 'UNKNOWN_TRANSACTION_STATUS' ||
    // 	transactionType !== 'UNKNOWN_TRANSACTION_TYPE';
    const skipTransactionValue = remarkToSkip !== "";
    const notesValue = notes !== "";

    const callback = (key) => {
      localStorage.setItem("transTabKey", key);
      if (key === "upcoming") {
        this.props.fetchUpcomingTransactionList();
      }
      this.setState({
        page: 0,
        rowsPerPage: 10,
      });
    };

    const { errors } = this.state;
    const transValue =
      transData.clientId !== "" &&
      transData.amount !== "" &&
      transData.transactionType !== "" &&
      transData.transactionMedium !== "" &&
      transData.description !== "";
    const transBankValue =
      transData.clientId !== "" &&
      transData.amount !== "" &&
      transData.transactionType !== "" &&
      transData.transactionMedium !== "" &&
      transData.description !== "" &&
      transData.bankDetailId !== "";
    const withdrawalVendorTransactionValue =
      transData.amount !== "" &&
      transData.transactionType !== "" &&
      transData.transactionMedium !== "" &&
      transData.description !== "";
    // transData.vendorId !== ""

    //for client side only
    const totalBalance = transactionState && transactionState.totalBalance;
    const totalTransaction =
      transactionState && transactionState.totalTransaction;

    // const sortedTransactionList = transactionList
    //   ? transactionList.sort((a, b) => {
    //       const data = this.state.sortType === "asc" ? 1 : -1;
    //       console.log(a.transactionTimestamp, b.transactionTimestamp, "sorted");
    //       return (
    //         data * a.transactionTimestamp.localeCompare(b.transactionTimestamp)
    //       );
    //     })
    //   : null;
    // const sortedTransactionList = transactionList;

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    const handleOpenDialogue = () => {
      this.setState({
        openDialogue: true,
        status: "hello",
        errors: {
          clientName: "",
          amount: "",
          transactionMedium: "",
          transactionType: "",
          vendor: "",
          note: "",
          bankId: "",
          document: "",
        },
      });
    };

    const handleCloseDialogueToProcess = () => {
      this.setState({
        openDialogueToProcess: false,
      });
    };
    const handleOpenDialogueToProcess = () => {
      this.setState({
        openDialogueToProcess: true,
      });
    };
    const handleOkTransaction = (upcomingTrans) => {
      if (this.state.isProcess) {
        const dataToProceed = {
          scheduleId: upcomingTrans.scheduleTransactionId,
          clientId: upcomingTrans.client.clientId,
          vendorId: upcomingTrans.vendorAccount.vendorId,
          amount: upcomingTrans.amount,
          note: upcomingTrans.note ? upcomingTrans.note : "",
          transactionType: upcomingTrans.transactionType,
          transactionMedium: upcomingTrans.transactionMedium,
          document: upcomingTrans.document ? upcomingTrans.document : "",
          bankDetailId: upcomingTrans.bankDetailId
            ? upcomingTrans.bankDetailId
            : "",
          description: upcomingTrans.description
            ? upcomingTrans.description
            : "",
          upcomingTxnDate: upcomingTrans.upcomingTxnDate,
          isProcessed: true,
          isLedger: upcomingTrans.isLedger,
        };
        this.props.processUpcomingTransaction(dataToProceed, this.props);
      } else {
        const dataToSkip = {
          scheduleId: upcomingTrans.scheduleTransactionId,
          skipTimestamp: upcomingTrans.upcomingTxnDate,
        };
        this.props.skipUpcomingTransaction(dataToSkip, this.props);
      }
      this.setState({
        openDialogueToProcess: false,
      });
    };
    const handleSkipTransaction = () => {
      const dataToSkip = {
        scheduleId: this.state.upcomingTrans.scheduleTransactionId,
        skipTimestamp: this.state.upcomingTrans.upcomingTxnDate,
        remark: remarkToSkip,
      };
      this.props.skipUpcomingTransaction(dataToSkip, this.props);
    };
    const handleCloseDialogue = () => {
      this.setState({
        openDialogue: false,
      });
    };
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangePageForUpcoming = (event, newPage) => {
      this.setState({
        pageUpcoming: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPageForUpcoming = (event) => {
      this.setState({
        rowsPerPageForUpcoming: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const setPage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const setRowPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const setInitialPage = () => {
      this.setState({
        page: 0,
      });
    };

    const setTransactionStatus = (id, status) => {
      this.setState({
        transactionId: id,
        selectedStatus: status,
      });
    };
    const handleChangeOpenSearchDialogue = () => {
      handleInitialCheckboxValue();
      this.setState({
        openSearchDialogue: true,
        // page: 0,
      });
      this.props.clearSnackBar();
      this.props.clearTransactionFilter();
    };
    const handleChangeCloseSearchDialogue = () => {
      this.setState({
        openSearchDialogue: false,
      });
    };

    const handleInitialCheckboxValue = () => {
      this.setState({
        isALlChecked: true,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        // isTypeCheck: FilterTyApeData.map((type) => type.name),
        isTypeCheck: allTypeArray.map((type) => type.name),
        isLEDTypeCheck: allTypeArray.map((type) => type.name),
      });
    };
    const handleOpenLedgerTransFilter = () => {
      handleInitialCheckboxValue();
      this.setState({
        openLedgerFilter: true,
        // pageLedgerTrans: 0,
      });
      this.props.clearSnackBar();
    };
    const handleCloseLedgerTransFilter = () => {
      this.setState({
        openLedgerFilter: false,
      });
    };

    const handleChangeOpenUpcomingFilterDialogue = () => {
      this.setState({
        openUpcomingFilterDialogue: true,
        // pageUpcoming: 0,
      });
      this.props.clearSnackBar();
    };
    const handleChangeCloseUpcomingFilterDialogue = () => {
      this.setState({
        openUpcomingFilterDialogue: false,
        isALlChecked: true,
        isAllMediumChecked: true,
        isAllTypeChecked: true,
        isAllUPTypeCheked: true,
        isAllLEDTypeChecked: true,
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isTypeCheck: FilterTypeData.map((type) => type.name),
        isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        isLEDTypeCheck: allTypeArray.map((type) => type.name),
      });
    };

    // modal to create transaction
    const handleOpenDialogueToCreateTransaction = () => {
      this.setState({
        clearBankDetail: true,
        openModalToCreateTransaction: true,
        errors: {
          clientName: "",
          amount: "",
          transactionMedium: "",
          transactionType: "",
          vendor: "",
          note: "",
          bankId: "",
          document: "",
        },
      });
      this.props.clearFileData();
      if (userType === "CLIENT_USER") {
        this.props.handleChangeTransData("clientId", userId);
      }
      this.props.clearTransactionForm();
      this.props.clearClientSingle();
      // this.props.fetchSingleClient('', this.props);
      this.props.handleChangeTransData("clientId", "");
      this.props.handleChangeTransData("bankDetailId", "");
      this.props.handleChangeTransData("vendorId", " ");
    };
    const handleCloseDialogueToCreateTransaction = () => {
      this.setState({
        openModalToCreateTransaction: false,
        isUpdate: false,
      });
    };

    const onChangeDocumentFileHandler = (eventFileHandler) => {
      let file = eventFileHandler.target.files[0];
      let type = file && file.type;
      let name = file && file.name;
      let singleFile = {
        document: file,
        type: type,
        name: name,
      };
      {
        this.setState(
          {
            isBlocking: true,
          },
          () => {
            this.props.uploadDocumentFile(singleFile, this.props);
          }
        );
      }
    };

    const handleChangeCsvFile = (eventFileHandler) => {
      let file = eventFileHandler.target.files[0];
      if (
        (file && file.type === "text/csv") ||
        file.type === "application/vnd.ms-excel"
      ) {
        let type = file && file.type;
        let singleFile = {
          document: file,
          type: type,
        };
        {
          this.setState(
            {
              isBlocking: true,
            },
            () => {
              this.props.handleChangeCSVFile(singleFile, this.props);
            }
          );
        }
      } else {
        this.setState({
          openSnackBar: true,
        });
      }
    };

    //handle modal to import transaction
    const handleOpenImportTransactionModal = () => {
      this.setState({
        openModalToImportTransaction: true,
      });
    };

    const handleCloseImportTransactionModal = () => {
      this.setState({
        openModalToImportTransaction: false,
      });
    };
    //handle modal to change transaction status
    const handleOpenChangeTransStatusModal = () => {
      this.setState({
        openModalToChangeTransStatus: true,
      });
    };

    const handleCloseChangeTransStatusModal = () => {
      this.setState({
        openModalToChangeTransStatus: false,
      });
    };

    // handle delete modal
    const handleDeleteModalOpen = () => {
      this.setState({
        openModalToDeleteTransaction: true,
      });
    };
    const handleDeleteModalClose = () => {
      this.setState({
        openModalToDeleteTransaction: false,
      });
    };

    // handle modal to change transaction
    const handleOpenToDeclineTransaction = (item) => {
      if (item && item.transactionStatus === "TRANSACTION_APPROVED") {
        const data = {
          transaction: [
            {
              transactionId: this.state.transactionId,
              transactionStatus: "TRANSACTION_SUBMITTED",
              // remark: declineRemark,
            },
          ],
        };
        this.props.updateSubmitTransactionList(data, this.props);
      } else {
        this.setState({
          openModalToDeclineTransaction: true,
        });
      }
    };

    // handle undo a reversed transaction
    const handleUndoReversedTrans = () => {
      this.setState({
        openConfirmationToUndoReverse: true,
      });
    };
    const handleCloseUndoReverse = () => {
      this.setState({
        openConfirmationToUndoReverse: false,
      });
    };
    const handleCloseToDeclineTransaction = () => {
      this.setState({
        openModalToDeclineTransaction: false,
      });
      this.props.handlChangeReverseDate(null);
    };
    const declineStatus = declineRemark !== "";

    const blockedStatus =
      blockedData.amount !== this.state.prevAmount || blockedData.date !== null;

    const disableButton =
      transStatus === "TRANSACTION_APPROVED" ? true : declineRemark !== "";
    const handleUpdateStatus = () => {
      // if (this.state.openModalToUndoReverse) {
      //   const data = {
      //     transactionId: this.state.transactionId,
      //     remark: declineRemark,
      //   };

      //   this.props.undoReverseTranx(data, this.props);
      // } else {
      const data = {
        transaction: [
          {
            transactionId: this.state.transactionId,
            transactionStatus: "TRANSACTION_APPROVED",
            // transactionStatus: "TRANSACTION_REVERSED",
            // transactionTimestamp: reversedDate === null ? 0 : reversedDate,
            remark: declineRemark,
          },
        ],
      };

      this.props.updateSubmitTransactionList(data, this.props);
      // }
    };
    const handleUpdateReverseStatus = () => {
      if (this.state.openModalToUndoReverse) {
        const data = {
          transactionId: this.state.transactionId,
          remark: declineRemark,
        };

        this.props.undoReverseTranx(data, this.props);
      } else {
        const data = {
          transaction: [
            {
              transactionId: this.state.transactionId,
              // transactionStatus: "TRANSACTION_APPROVED",
              transactionStatus: "TRANSACTION_REVERSED",
              transactionTimestamp: reversedDate === null ? 0 : reversedDate,
              remark: declineRemark,
            },
          ],
        };

        this.props.updateSubmitTransactionList(data, this.props);
      }
    };
    // delete status handling
    const handleDeleteStatus = () => {
      const data = this.state.transactionId;
      this.props.deleteTransData(data, this.props);
    };

    const handleUpdateLedgerStatus = () => {
      const updatedStatusData = {
        transaction: [
          {
            transactionId: this.state.transactionId,
            transactionStatus: transStatus,
            remark: declineRemark,
          },
        ],
      };
      this.props.updateLedgerTransaction(updatedStatusData, this.props);
    };

    const loading =
      this.props.transactionState && this.props.transactionState.loading;

    const openLedgerTxnUpdateModal =
      transactionState && transactionState.openLedgerTxnUpdateModal;
    const setOpenLedgerTxnUpdate = (bool) => {
      this.props.setOpenLedgerTxnUpdate(bool);
    };

    const handleChangeToNotes = (check) => {
      this.props.handleChangeToNotes(check);
    };

    const handleChangeToOpenNoteModal = (test) => {
      this.props.handleChangeToOpenNoteModal(test);
    };

    const addNoteForTransaction = (note, isFromLedger) => {
      if (isFromLedger) {
        this.props.addNoteForTransaction(
          note,
          undefined,
          this.props.handleChangeToOpenNoteModalForLedger,
          this.props.fetchLedgerTransactionList
        );
      } else {
        this.props.addNoteForTransaction(note, this.props);
      }
    };

    const addDescription = (description, props) => {
      this.props.addDescription(description, this.props);
    };

    const handleChangeDropDown = (id) => {
      this.setState({
        openDropDown: !this.state.openDropDown,
        tempRowId: id,
      });
    };

    function descendingComparator(a, b, orderBy) {
      if (orderBy === "balance" || orderBy === "amount") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "cases") {
        let first = b.client[orderBy] ? b.client[orderBy].length : 0;
        let second = a.client[orderBy] ? a.client[orderBy].length : 0;
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return null;
      } else if (orderBy === "refId") {
        return a[orderBy]
          .toString()
          .localeCompare(b[orderBy].toString(), "eng", {
            numeric: true,
          });
      } else if (orderBy === "firstName") {
        let first = a.Client && a.Client[orderBy] ? a.Client[orderBy] : "z";
        let second = b.Client && b.Client[orderBy] ? b.Client[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "firstNameUP") {
        let first = a.client && a.client.firstName ? a.client.firstName : "z";
        let second = b.client && b.client.firstName ? b.client.firstName : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "vendorName") {
        let first =
          a.vendorAccount && a.vendorAccount[orderBy]
            ? a.vendorAccount[orderBy]
            : "z";
        let second =
          b.vendorAccount && b.vendorAccount[orderBy]
            ? b.vendorAccount[orderBy]
            : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "checkPrintDate") {
        let first =
          a.checkPrint && a.checkPrint[orderBy] ? a.checkPrint[orderBy] : "z";
        let second =
          b.checkPrint && b.checkPrint[orderBy] ? b.checkPrint[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "scheduleDate ") {
        let first =
          a.transactionDetails && a.transactionDetails[orderBy]
            ? a.transactionDetails[orderBy]
            : "0";
        let second =
          b.transactionDetails && b.transactionDetails[orderBy]
            ? b.transactionDetails[orderBy]
            : "0";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "scheduledDate") {
        let first =
          a.scheduleDetail && a.scheduleDetail[orderBy]
            ? a.scheduleDetail[orderBy]
            : "0";
        let second =
          b.scheduleDetail && b.scheduleDetail[orderBy]
            ? b.scheduleDetail[orderBy]
            : "0";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return null;
      }
    }

    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }

    const sortedTransactionList = getComparator(
      transactionList,
      this.state.order,
      this.state.orderBy
    );
    const sortedUpcomingTrans = getComparator(
      upcomingTrans,
      this.state.order,
      this.state.orderBy
    );

    const filterStatus =
      transDate !== null ||
      desc !== "" ||
      amount !== "" ||
      checkNo !== "" ||
      user !== "" ||
      medium !== "" ||
      type !== "" ||
      stat !== "" ||
      filterDebitAmount !== "" ||
      filterCreditAmount !== "" ||
      vendor !== "";

    const configStatusTrans = this.state.configName !== "" && filterStatus;

    const filterStatusUP =
      UPDate !== null ||
      UPAmount !== "" ||
      UPUser !== "" ||
      UPVendor !== "" ||
      UPMedium !== "" ||
      UPType !== "" ||
      UPSchDate !== "";

    const configStatusUP = this.state.configName !== "" && filterStatusUP;

    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
        isUpcomingFilter: false,
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
        isUpcomingFilter: false,
      });
    };

    const handleFilterSaveConfig = () => {
      if (!this.state.isUpcomingFilter) {
        const data = {
          configName: this.state.configName,
          description: desc,
          balance: amount,
          checkNo: checkNo,
          userName: user,
          vendorName: vendor,
          transactionMedium: medium,
          transactionType: type,
          transactionStatus: stat,
          compareType: compareType,
          transactionDate: transDate === null ? "" : transDate,
          configType: 2,

          sortBy: this.state.order === "asc" ? "ASC" : "DESC",
        };
        this.props.fetchTransactionList();
        this.props.saveInConfig(data);
        this.props.clearTransactionFilter();
      } else {
        const data = {
          configName: this.state.configName,
          type: 4,
          transactionDate: UPDate === null ? "" : UPDate,
          balance: UPAmount,
          clientName: UPUser,
          vendorName: UPVendor,
          transactionMedium: UPMedium,
          transactionType: UPType,
          scheduleDate: UPSchDate === null ? "" : UPSchDate,
          compareType: UPCompareType,
          sortBy: this.state.order === "asc" ? "ASC" : "DESC",
        };
        this.props.fetchUpcomingTransactionList();
        this.props.saveInConfigforUP(data);
        this.props.clearUpTransFilter();
      }
    };

    const handleUseConfigFilter = (config) => {
      if (!this.state.isUpcomingFilter) {
        this.props.clearTransactionFilter();
        if (config.transactionFilter.balance) {
          this.props.handleTransactionFilter(
            "filterAmount",
            config.transactionFilter.balance
          );
        }
        if (config.transactionFilter.checkNo) {
          this.props.handleTransactionFilter(
            "filterCheckNo",
            config.transactionFilter.checkNo
          );
        }
        if (config.transactionFilter.transactionMedium) {
          this.props.handleTransactionFilter(
            "filterMedium",
            config.transactionFilter.transactionMedium
          );
        }
        if (config.transactionFilter.description) {
          this.props.handleTransactionFilter(
            "filterDesc",
            config.transactionFilter.description
          );
        }
        if (config.transactionFilter.transactionDate !== "0") {
          this.props.handleTransactionFilter(
            "filterDate",
            config.transactionFilter.transactionDate
          );
        }
        if (config.transactionFilter.transactionStatus) {
          this.props.handleTransactionFilter(
            "filtetStatus",
            config.transactionFilter.transactionStatus
          );
        }
        if (config.transactionFilter.transactionType) {
          this.props.handleTransactionFilter(
            "filterType",
            config.transactionFilter.transactionType
          );
        }
        if (config.transactionFilter.userName) {
          this.props.handleTransactionFilter(
            "filterUser",
            config.transactionFilter.userName
          );
        }
        if (config.transactionFilter.vendorName) {
          this.props.handleTransactionFilter(
            "filterVendor",
            config.transactionFilter.vendorName
          );
        }
        if (config.transactionFilter.comparator) {
          this.props.handleTransactionFilter(
            "compareType",
            getRangeType(config.transactionFilter.comparator)
          );
        }

        this.props.filterTransactionByAll(
          config.transactionFilter.description
            ? config.transactionFilter.description
            : "",
          config.transactionFilter.checkNo
            ? config.transactionFilter.checkNo
            : "",
          config.transactionFilter.userName
            ? config.transactionFilter.userName
            : "",
          config.transactionFilter.vendorName
            ? config.transactionFilter.vendorName
            : "",
          config.transactionFilter.transactionMedium
            ? config.transactionFilter.transactionMedium
            : "",
          config.transactionFilter.transactionType
            ? config.transactionFilter.transactionType
            : "",
          config.transactionFilter.transactionStatus
            ? transactionStatusHelper([
                config.transactionFilter.transactionStatus,
              ])
            : "",
          config.transactionFilter.transactionDate
            ? config.transactionFilter.transactionDate
            : "0",
          config.transactionFilter.balance
            ? config.transactionFilter.balance
            : "",
          "",
          config.transactionFilter.comparator
            ? getRangeType(config.transactionFilter.comparator)
            : "0"
        );
      } else {
        this.props.clearUpTransFilter();
        if (config.scheduleFilter.amount) {
          this.props.handleUpTransFilter(
            "filterAmount",
            config.scheduleFilter.amount
          );
        }
        if (config.scheduleFilter.clientName) {
          this.props.handleUpTransFilter(
            "filterUser",
            config.scheduleFilter.clientName
          );
        }
        if (config.scheduleFilter.scheduleDate !== "0") {
          this.props.handleUpTransFilter(
            "filterSchedule",
            config.scheduleFilter.scheduleDate
          );
        }
        if (config.scheduleFilter.transactionDate) {
          this.props.handleUpTransFilter(
            "filterDate",
            config.scheduleFilter.transactionDate
          );
        }
        if (config.scheduleFilter.transactionMedium) {
          this.props.handleUpTransFilter(
            "filterMedium",
            config.scheduleFilter.transactionMedium
          );
        }
        if (config.scheduleFilter.transactionType) {
          this.props.handleUpTransFilter(
            "filterType",
            config.scheduleFilter.transactionType
          );
        }
        if (config.scheduleFilter.vendorName) {
          this.props.handleUpTransFilter(
            "filterVendor",
            config.scheduleFilter.vendorName
          );
        }
        if (config.scheduleFilter.comparator) {
          this.props.handleUpTransFilter(
            "compareType",
            getRangeType(config.scheduleFilter.comparator)
          );
        }

        this.props.searchUpcomingTransactionByAll(
          config.scheduleFilter.clientName
            ? config.scheduleFilter.clientName
            : "",
          config.scheduleFilter.vendorName
            ? config.scheduleFilter.vendorName
            : "",
          config.scheduleFilter.transactionMedium
            ? config.scheduleFilter.transactionMedium
            : "",
          config.scheduleFilter.transactionType
            ? config.scheduleFilter.transactionType
            : "",

          config.scheduleFilter.transactionDate
            ? config.scheduleFilter.transactionDate
            : "0",
          config.scheduleFilter.scheduleDate
            ? config.scheduleFilter.scheduleDate
            : "0",
          config.scheduleFilter.amount ? config.scheduleFilter.amount : "",
          config.scheduleFilter.comparator
            ? getRangeType(config.scheduleFilter.comparator)
            : "0"
        );
      }
    };

    // trasaction update request body
    const transReqBody = {
      transactionId: transData && transData.transactionId,
      clientId: transData && transData.clientId,
      amount: transData && transData.amount,
      vendorId: transData && transData.vendorId,
      description: transData && transData.description,
      transactionMedium: transData && transData.transactionMedium,
      transactionType: transData && transData.transactionType,
      transactionStatus: transData && transData.transactionStatus,
      note: transData && transData.note,
      document: transData && transData.document,
      isLedger: transData && transData.isLedger,
      bankDetailId: transData && transData.bankDetailId,
    };

    // function to set the initaial value of the main filters
    const setInitialValueofMainFilters = () => {
      this.setState({
        isStatusCheck: FilterStatusData.map((status) => status.name),
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        // isTypeCheck: FilterTypeData.map((type) => type.name),
        isTypeCheck: allTypeArray.map((type) => type.name),
        isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
        // isLEDTypeCheck: FilterLEDTypeData.map((type) => type.name),
        isLEDTypeCheck: allTypeArray.map((type) => type.name),
        isLedCaseTypeCheck: FilterTypeDataForLedger.map((type) => type.name),
      });
    };

    return (
      <React.Fragment>
        <div>
          <HelmetComponent title="Transaction" />
          {loading || this.props.upcomingTransactionState.loading ? (
            <div
              className={
                loading || this.props.upcomingTransactionState.loading
                  ? "loader"
                  : "loader hidden"
              }
              id="loader"
            >
              <CircularProgress style={{ color: "#8BC83F" }} />
            </div>
          ) : null}
          {this.props.transactionState.success === true
            ? this.setState(
                {
                  openModalToCreateTransaction: false,
                  openModalToImportTransaction: false,
                  openModalToChangeTransStatus: false,
                  openModalToDeleteTransaction: false,
                },
                () => {
                  if (this.state.setValue === "import") {
                    this.props.importTransactionSuccessToFalse();
                  } else if (this.state.setvalue === "changeStatus") {
                    this.props.setChangeTransactionStatusSuccessToFalse();
                  } else if (this.state.isUpdate) {
                    this.props.setUpdateTransactionSuccessToFalse();
                  } else {
                    this.props.setCreateTransactionSuccessToFalse();
                  }
                }
              )
            : null}
          <Breadcrumbs
            aria-label="breadcrumb"
            className="breadcrumb"
            style={style.breadcrumb}
          >
            <Link
              color="inherit"
              to="/dashboard"
              className="link"
              style={style.link}
            >
              <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
              Home
            </Link>
            <Typography color="inherit" className="link">
              Transactions
            </Typography>
          </Breadcrumbs>
          {userType === "CLIENT_USER" ? (
            <div
              // style={{ margin: "70px 3% 3%" }}
              style={{ paddingTop: "61px", margin: "0px 3%" }}
            >
              <div
                style={
                  userType === "CLIENT_USER"
                    ? {
                        display: "flex",
                        justifyContent: "space-between",
                      }
                    : { display: "block" }
                }
              >
                <div
                  style={
                    userType === "CLIENT_USER"
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div style={{ marginBottom: "8px" }}>
                    <span style={{ marginRight: "10px", fontSize: "16px" }}>
                      Total Balance :
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {totalBalance
                        ? formatter.format(totalBalance / 100)
                        : "$0.00"}
                    </span>
                  </div>
                  <div style={{ marginBottom: "8px" }}>
                    <span style={{ marginRight: "10px", fontSize: "16px" }}>
                      Total Transaction :
                    </span>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {totalTransaction ? totalTransaction : 0}
                    </span>
                  </div>
                </div>
                <div className={"d-flex flex-row-reverse"} style={style.flex}>
                  {/********** Re-fresh *********/}
                  <div>
                    <button
                      className="filterButton"
                      onClick={() => {
                        this.props.fetchTransactionList();
                      }}
                    >
                      <RefreshIcon
                        style={{ color: "#fff", marginTop: "-2px" }}
                      />
                    </button>
                  </div>
                  {/*sort*/}
                  <div>
                    <button
                      style={style.settingButton}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          sortType:
                            this.state.sortType === "asc" ? "dsc" : "asc",
                        });
                      }}
                    >
                      <img
                        alt={"setting"}
                        src="/assets/images/sort.png"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </button>
                  </div>
                  <div style={{ display: "flex", marginLeft: "15px" }}>
                    <button
                      style={style.filterButton}
                      onClick={handleChangeOpenSearchDialogue}
                    >
                      <FilterListIcon
                        style={{ color: "#fff", marginTop: "-2px" }}
                      />
                    </button>
                  </div>
                  <div>
                    {arraysEqual(
                      this.state.isStatusCheck,
                      FilterStatusData.map((status) => status.name)
                    ) &&
                    arraysEqual(
                      this.state.isTypeCheck,
                      allTypeArray.map((type) => type.name)
                    ) &&
                    arraysEqual(
                      this.state.isMediumCheck,
                      FilterMediumData.map((medium) => medium.name)
                    ) &&
                    (startDate === null || startDate === 0) &&
                    (endDate === null || endDate === 0) &&
                    // startDate === 0 &&
                    // endDate === 0 &&
                    // status === "UNKNOWN_TRANSACTION_STATUS" &&
                    // transactionType === "UNKNOWN_TRANSACTION_TYPE" &&
                    // mediumType === "UNKNOWN_TRANSACTION_MEDIUM" &&
                    term === "" &&
                    vendorToFilter === "" ? (
                      <ExportButton
                        name={"VOD"}
                        onClickPDF={(e) => {
                          e.preventDefault();
                          // this.props.exportSingleClientTransactionList(
                          //   clientUserId,
                          //   "PDF_EXPORT"
                          // );
                          this.props.exportTransactionList("PDF_EXPORT");
                        }}
                        onClickCSV={(e) => {
                          e.preventDefault();
                          // this.props.exportSingleClientTransactionList(
                          //   clientUserId,
                          //   "CSV_EXPORT"
                          // );
                          this.props.exportTransactionList("CSV_EXPORT");
                        }}
                        onClickVOD={(e) => {
                          e.preventDefault();
                          this.props.exportTransactionVOD(
                            clientUserId,
                            startDate,
                            endDate,
                            "PDF_EXPORT"
                          );
                        }}
                      />
                    ) : term !== "" ? (
                      <ExportButton
                        name={"VOD"}
                        onClickPDF={(e) => {
                          e.preventDefault();
                          // this.props.exportSingleClientTransactionByTerm(
                          //   term,
                          //   clientUserId,
                          //   "PDF_EXPORT",
                          //   this.props
                          // );
                          this.props.exportTransactionByTerm(
                            term,
                            "PDF_EXPORT",
                            this.props
                          );
                        }}
                        onClickCSV={(e) => {
                          e.preventDefault();
                          // this.props.exportSingleClientTransactionByTerm(
                          //   term,
                          //   clientUserId,
                          //   "CSV_EXPORT",
                          //   this.props
                          // );
                          this.props.exportTransactionByTerm(
                            term,
                            "CSV_EXPORT",
                            this.props
                          );
                        }}
                        onClickVOD={(e) => {
                          e.preventDefault();
                          this.props.exportTransactionVOD(
                            clientUserId,
                            startDate,
                            endDate,
                            "PDF_EXPORT"
                          );
                        }}
                      />
                    ) : (
                      <ExportButton
                        name={"VOD"}
                        onClickPDF={(e) => {
                          e.preventDefault();
                          // this.props.exportTransactionByAll(
                          //   transactionMediumHelper(this.state.isMediumCheck),
                          //   transactionStatusHelper(this.state.isStatusCheck),
                          //   transactionTypeHelper(
                          //     this.state.isUPTypeCheck,
                          //     "type"
                          //   ),

                          //   startDate,
                          //   endDate,
                          //   vendorToFilter,
                          //   clientUserId,
                          //   "PDF_EXPORT"
                          // );
                          this.props.exportTransactionByAll(
                            transactionMediumHelper(this.state.isMediumCheck),
                            transactionStatusHelper(this.state.isStatusCheck),
                            transactionTypeHelper(
                              this.state.isTypeCheck,
                              "type"
                            ),
                            startDate,
                            endDate,
                            vendorToFilter,
                            "PDF_EXPORT"
                          );
                        }}
                        onClickCSV={(e) => {
                          e.preventDefault();
                          // this.props.exportTransactionByAll(
                          //   transactionMediumHelper(this.state.isMediumCheck),
                          //   transactionStatusHelper(this.state.isStatusCheck),
                          //   transactionTypeHelper(
                          //     this.state.isUPTypeCheck,
                          //     "type"
                          //   ),

                          //   startDate,
                          //   endDate,
                          //   vendorToFilter,
                          //   clientUserId,
                          //   "CSV_EXPORT"
                          // );
                          this.props.exportTransactionByAll(
                            transactionMediumHelper(this.state.isMediumCheck),
                            transactionStatusHelper(this.state.isStatusCheck),
                            transactionTypeHelper(
                              this.state.isTypeCheck,
                              "type"
                            ),
                            startDate,
                            endDate,
                            vendorToFilter,
                            "CSV_EXPORT"
                          );
                        }}
                        onClickVOD={(e) => {
                          e.preventDefault();
                          this.props.exportTransactionVOD(
                            clientUserId,
                            startDate,
                            endDate,
                            "PDF_EXPORT"
                          );
                        }}
                      />
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="search">
                      <div style={style.searchIcon}>
                        <SearchIcon style={{ color: "#9c9c9c" }} />
                      </div>
                      <InputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        style={style.inputBase}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.value === "" || e.target.value === " ") {
                            this.props.fetchTransactionList();
                          } else {
                            this.props.searchTransaction(
                              e.target.value,
                              this.props
                            );
                            this.props.handleChangeTerm(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/************** NON-ADMIN TRANSACTION *****************/}
              <div className={"row"} style={style.card}>
                <TableContainer className="fixed-table-header">
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                    ref={this.tableRef}
                  >
                    <TableHead style={{ padding: "1rem" }}>
                      <TableRow>
                        <TableCell className="first-tableHeader">S.N</TableCell>
                        <TableCell
                          className="first-tableHeader"
                          key="transactionTimestamp"
                          sortDirection={
                            this.state.orderBy === "transactionTimestamp"
                              ? this.state.order
                              : false
                          }
                        >
                          <TableSortLabel
                            active={
                              this.state.orderBy === "transactionTimestamp"
                            }
                            direction={
                              this.state.orderBy === "transactionTimestamp"
                                ? this.state.order
                                : "asc"
                            }
                            className="override-mui-table"
                            onClick={this.createSortHandler(
                              "transactionTimestamp"
                            )}
                          >
                            Transaction Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell />
                        <TableCell className="tableHeader">
                          Description
                        </TableCell>
                        <TableCell className="tableHeaderAmount">
                          Debits
                        </TableCell>
                        <TableCell className="tableHeaderAmount">
                          Credits
                        </TableCell>
                        <TableCell className="tableHeaderAmount">
                          Balance
                        </TableCell>
                        <TableCell className="tableHeaderRefId">
                          Ref / Check No.
                        </TableCell>
                        <TableCell className="tableHeader">User</TableCell>
                        <TableCell className="tableHeader">Vendor</TableCell>
                        <TableCell className="tableHeader">Medium</TableCell>
                        <TableCell className="tableHeader">Type</TableCell>
                        <TableCell className="tableHeader">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    {transactionList &&
                      transactionList
                        .slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((list, index) => (
                          <TableBody key={list.transactionId}>
                            <TableRow
                              style={
                                list.transactionStatus ===
                                  "TRANSACTION_ON_HOLD" ||
                                list.transactionStatus === "TRANSACTION_BLOCKED"
                                  ? { cursor: "pointer", background: "#fff1f0" }
                                  : { cursor: "pointer" }
                              }
                            >
                              <TableCell className="first-tableBody">
                                {(this.state.page + 1 - 1) *
                                  this.state.rowsPerPage +
                                  (index + 1)}
                              </TableCell>
                              <TableCell
                                className="first-tableBody"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {new Intl.DateTimeFormat("default", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(list.transactionTimestamp || "-")}
                              </TableCell>
                              <TableCell style={{ paddingRight: "0px" }}>
                                {list.note ? (
                                  <NotesPopover content={list.note} />
                                ) : (
                                  <NoteAddIcon
                                    style={{ color: "rgb(126, 126, 126)" }}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                className="tableBody"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.description ? list.description : "-"}
                              </TableCell>
                              <TableCell
                                className="tableAmountBody"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.amount
                                  ? list.amount && list.amount.charAt(0) === "-"
                                    ? formatter
                                        .format(list.amount / 100)
                                        .substring(1)
                                    : "-"
                                  : formatter.format(0)}
                              </TableCell>
                              <TableCell
                                className="tableAmountBody"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.amount
                                  ? list.amount.charAt(0) === "-"
                                    ? "-"
                                    : formatter.format(list.amount / 100)
                                  : formatter.format(0)}
                              </TableCell>
                              <TableCell
                                className="tableAmountBody"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.balance
                                  ? formatter.format(list.balance / 100)
                                  : formatter.format(0)}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "left",
                                  height: "4.5rem",
                                  paddingLeft: "47px",
                                  paddingRight: 0,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.refId}
                                <span>
                                  {list.checkNo
                                    ? " / " + list.checkNo + " "
                                    : ""}
                                </span>
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "left",
                                  height: "4.5rem",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.Client.lastName
                                  ? list.Client.firstName +
                                    " " +
                                    list.Client.lastName
                                  : list.Client.firstName}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "left",
                                  paddingLeft: "18px",
                                  height: "4.5rem",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.vendorAccount.vendorName
                                  ? list.vendorAccount.vendorName
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "left",
                                  paddingLeft: "18px",
                                  height: "4.5rem",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.transactionMedium &&
                                  list.transactionMedium.replace("_", " ")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "left",
                                  paddingLeft: "18px",
                                  height: "4.5rem",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleOpenDialogue();
                                  this.setState(
                                    {
                                      trans: list,
                                    },
                                    () => {
                                      this.props.getTransactionInfo(
                                        this.state.trans.transactionId
                                      );
                                    }
                                  );
                                }}
                              >
                                {list.transactionType.replace("_", " ")}
                              </TableCell>
                              <TableCell
                                style={{
                                  fontSize: "15px",
                                  color: "#626262",
                                  textAlign: "left",
                                  paddingLeft: "18px",
                                  height: "4.5rem",
                                  cursor: "auto",
                                  width: "10%",
                                }}
                              >
                                <>
                                  <Tag
                                    style={style.colors}
                                    color={
                                      list.transactionStatus ===
                                      "TRANSACTION_APPROVED"
                                        ? "green"
                                        : list.transactionStatus ===
                                          "TRANSACTION_REVERSED"
                                        ? "red"
                                        : list.transactionStatus ===
                                          "TRANSACTION_CREATED"
                                        ? "purple"
                                        : list.transactionStatus ===
                                          "TRANSACTION_SUBMITTED"
                                        ? "blue"
                                        : list.transactionStatus ===
                                          "TRANSACTION_BLOCKED"
                                        ? "yellow"
                                        : list.transactionStatus ===
                                          "TRANSACTION_RETURNED"
                                        ? "geekblue"
                                        : list.transactionStatus ===
                                          "TRANSACTION_POSTED"
                                        ? "cyan"
                                        : list.transactionStatus ===
                                          "TRANSACTION_SKIPPED"
                                        ? "orange"
                                        : list.transactionStatus ===
                                          "TRANSACTION_ON_HOLD"
                                        ? "volcano"
                                        : list.transactionStatus ===
                                          "TRANSACTION_CANCELLED"
                                        ? "gold"
                                        : list.transactionStatus ===
                                          "TRANSACTION_CLOSED"
                                        ? "magenta"
                                        : "lime"
                                    }
                                  >
                                    {list.transactionStatus &&
                                      list.transactionStatus
                                        .replace("TRANSACTION_", " ")
                                        .replace("_", " ")}
                                  </Tag>
                                  {list.remark !== undefined ? (
                                    <NotesPopover
                                      content={list.remark}
                                      isRemark
                                    />
                                  ) : null}
                                </>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))}
                  </Table>
                </TableContainer>
                {transactionList && transactionList.length > 0 ? (
                  <div
                    style={{
                      justifyContent: "flex-start",
                      width: "100%",
                      borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TablePagination
                      labelRowsPerPage={false}
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={
                        transactionList.length > 0
                          ? transactionList.length
                          : 100
                      }
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "1rem",
                        color: "#606060",
                      }}
                    >
                      {" "}
                      No data to show
                    </h3>
                  </div>
                )}
                {/* </TableContainer> */}
              </div>
            </div>
          ) : (
            <div
              style={{
                paddingTop: "48px",
                margin: "0 3%",
              }}
            >
              <Tabs
                defaultActiveKey={activeTab}
                onChange={(key) => {
                  this.props.history.push("/transaction/" + key);
                  callback(key);
                }}
                // className="fixed-tab"
              >
                <TabPane tab="Transactions" key="list">
                  <div style={style.container}>
                    <div
                      style={
                        userType === "CLIENT_USER"
                          ? {
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "70px",
                            }
                          : { display: "block" }
                      }
                    >
                      <div
                        style={
                          userType === "CLIENT_USER"
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div style={{ marginBottom: "8px" }}>
                          <span
                            style={{
                              marginRight: "10px",
                              fontSize: "16px",
                            }}
                          >
                            Total Balance :
                          </span>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {totalBalance
                              ? formatter.format(totalBalance / 100)
                              : "$0.00"}
                          </span>
                        </div>
                        <div style={{ marginBottom: "8px" }}>
                          <span
                            style={{ marginRight: "10px", fontSize: "16px" }}
                          >
                            Total Transaction :
                          </span>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {totalTransaction ? totalTransaction : 0}
                          </span>
                        </div>
                      </div>
                      <div
                        className={"d-flex flex-row-reverse"}
                        style={style.flex}
                      >
                        {/********** Re-fresh *********/}
                        <div>
                          <button
                            className="filterButton"
                            onClick={() => {
                              this.props.fetchTransactionList();
                              this.props.clearTransactionFilter();
                            }}
                          >
                            <RefreshIcon
                              style={{ color: "#fff", marginTop: "-2px" }}
                            />
                          </button>
                        </div>
                        {/********** Filter ********/}
                        <div style={{ display: "flex" }}>
                          <button
                            className="filterButton"
                            onClick={handleChangeOpenSearchDialogue}
                          >
                            <FilterListIcon
                              style={{ color: "#fff", marginTop: "-2px" }}
                            />
                          </button>
                        </div>
                        {/*********** Export ********/}
                        <div>
                          {(startDate === null || startDate === 0) &&
                          (endDate === null || endDate === 0) &&
                          term === "" &&
                          vendorToFilter === "" &&
                          arraysEqual(
                            this.state.isStatusCheck,
                            FilterStatusData.map((status) => status.name)
                          ) &&
                          arraysEqual(
                            this.state.isTypeCheck,
                            allTypeArray.map((type) => type.name)
                          ) &&
                          arraysEqual(
                            this.state.isMediumCheck,
                            FilterMediumData.map((medium) => medium.name)
                          ) &&
                          !filterStatus ? (
                            <>
                              <ExportButton
                                onClickPDF={(e) => {
                                  e.preventDefault();
                                  this.props.exportTransactionList(
                                    "PDF_EXPORT"
                                  );
                                }}
                                onClickCSV={(e) => {
                                  e.preventDefault();
                                  this.props.exportTransactionList(
                                    "CSV_EXPORT"
                                  );
                                }}
                              />
                            </>
                          ) : term !== "" ? (
                            <ExportButton
                              onClickPDF={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionByTerm(
                                  term,
                                  "PDF_EXPORT",
                                  this.props
                                );
                              }}
                              onClickCSV={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionByTerm(
                                  term,
                                  "CSV_EXPORT",
                                  this.props
                                );
                              }}
                            />
                          ) : filterStatus ? (
                            <ExportButton
                              onClickPDF={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionByColumnFilter(
                                  medium,
                                  type,
                                  stat,
                                  "PDF_EXPORT",
                                  desc,
                                  checkNo,
                                  user,
                                  vendor,
                                  amount,
                                  compareType,
                                  transDate,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount
                                );
                              }}
                              onClickCSV={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionByColumnFilter(
                                  medium,
                                  type,
                                  stat,
                                  "CSV_EXPORT",
                                  desc,
                                  checkNo,
                                  user,
                                  vendor,
                                  amount,
                                  compareType,
                                  transDate,
                                  filterDebitAmount !== ""
                                    ? -filterDebitAmount
                                    : filterCreditAmount
                                );
                              }}
                            />
                          ) : (
                            <ExportButton
                              onClickPDF={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionByAll(
                                  transactionMediumHelper(
                                    this.state.isMediumCheck
                                  ),
                                  transactionStatusHelper(
                                    this.state.isStatusCheck
                                  ),
                                  transactionTypeHelper(
                                    this.state.isTypeCheck,
                                    "type"
                                  ),
                                  startDate,
                                  endDate,
                                  vendorToFilter,
                                  "PDF_EXPORT"
                                );
                              }}
                              onClickCSV={(e) => {
                                e.preventDefault();
                                this.props.exportTransactionByAll(
                                  medium
                                    ? transactionMediumHelper([medium])
                                    : transactionMediumHelper(
                                        this.state.isMediumCheck
                                      ),
                                  stat
                                    ? transactionStatusHelper([stat])
                                    : transactionStatusHelper(
                                        this.state.isStatusCheck
                                      ),
                                  type
                                    ? transactionTypeHelper([type], "type")
                                    : transactionTypeHelper(
                                        this.state.isTypeCheck,
                                        "type"
                                      ),

                                  startDate,
                                  endDate,
                                  vendorToFilter,
                                  "CSV_EXPORT"
                                );
                              }}
                            />
                          )}
                        </div>
                        {/*********** Post Transaction *********/}
                        <div>
                          <button
                            style={style.postTransButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenImportTransactionModal();
                              this.props.handleChangeCSVFile("");
                              this.props.getBankDetails();
                            }}
                          >
                            Post Transaction
                          </button>
                        </div>
                        {/*********** Submit Transaction *********/}
                        <div>
                          <button
                            style={style.postTransButton}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push({
                                pathname: "/transaction/submitted/list",
                              });
                            }}
                          >
                            Submit Deposit
                          </button>
                        </div>
                        {/*********** Approve Transaction *********/}
                        <div>
                          <button
                            style={style.postTransButton}
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push({
                                pathname: "/transaction/approved/list",
                              });
                            }}
                          >
                            Approve Transaction
                          </button>
                        </div>
                        {/*********** Create Transaction *********/}
                        <div>
                          <button
                            style={style.postTransButton}
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenDialogueToCreateTransaction();
                              this.props.clearAmountDetail();
                              this.setState({
                                isFirstOpen: true,
                              });
                            }}
                          >
                            Create Transaction
                          </button>
                        </div>
                        {/*********** Search Transaction *********/}
                        <div style={{ display: "flex" }}>
                          <div className="search">
                            <div style={style.searchIcon}>
                              <SearchIcon style={{ color: "#9c9c9c" }} />
                            </div>
                            <InputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              style={style.inputBase}
                              onChange={(e) => {
                                e.preventDefault();
                                this.setState({
                                  page: 0,
                                });
                                if (
                                  e.target.value === "" ||
                                  e.target.value === " "
                                ) {
                                  this.props.fetchTransactionList();
                                } else {
                                  this.props.searchTransaction(
                                    e.target.value,
                                    this.props
                                  );
                                  this.props.handleChangeTerm(e.target.value);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row"} style={style.card}>
                      <TableContainer className="fixed-table-header-second">
                        <Table
                          size="small"
                          stickyHeader
                          aria-label="sticky table"
                          ref={this.tableRef}
                        >
                          <TableHead style={{ padding: "1rem" }}>
                            <TableRow>
                              <TableCell className="first-tableHeader">
                                #
                              </TableCell>
                              <TableCell
                                // className="first-tableHeader"
                                className="tableHeader"
                                key="transactionTimestamp"
                                sortDirection={
                                  this.state.orderBy === "transactionTimestamp"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={
                                    this.state.orderBy ===
                                    "transactionTimestamp"
                                  }
                                  direction={
                                    this.state.orderBy ===
                                    "transactionTimestamp"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler(
                                    "transactionTimestamp"
                                  )}
                                >
                                  Transaction Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className="tableHeader" />
                              <TableCell
                                // className="tableHeader"
                                className="first-tableHeader"
                                key="description"
                                sortDirection={
                                  this.state.orderBy === "description"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "description"}
                                  direction={
                                    this.state.orderBy === "description"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler(
                                    "description"
                                  )}
                                >
                                  Description
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className="tableHeader" />
                              <TableCell className="tableHeader">
                                Debits
                              </TableCell>
                              <TableCell className="tableHeader">
                                Credits
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="balance"
                                sortDirection={
                                  this.state.orderBy === "balance"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "balance"}
                                  direction={
                                    this.state.orderBy === "balance"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler("balance")}
                                >
                                  Balance
                                </TableSortLabel>
                              </TableCell>

                              <TableCell
                                className="tableHeaderRefId"
                                key="refId"
                                sortDirection={
                                  this.state.orderBy === "refId"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "refId"}
                                  direction={
                                    this.state.orderBy === "refId"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler("refId")}
                                >
                                  Ref / Check No.
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="firstName"
                                sortDirection={
                                  this.state.orderBy === "firstName"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "firstName"}
                                  direction={
                                    this.state.orderBy === "firstName"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler("firstName")}
                                >
                                  User
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="vendorName"
                                sortDirection={
                                  this.state.orderBy === "vendorName"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "vendorName"}
                                  direction={
                                    this.state.orderBy === "vendorName"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler("vendorName")}
                                >
                                  Vendor
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="transactionMedium"
                                sortDirection={
                                  this.state.orderBy === "transactionMedium"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={
                                    this.state.orderBy === "transactionMedium"
                                  }
                                  direction={
                                    this.state.orderBy === "transactionMedium"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler(
                                    "transactionMedium"
                                  )}
                                >
                                  Medium
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="transactionType"
                                sortDirection={
                                  this.state.orderBy === "transactionType"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={
                                    this.state.orderBy === "transactionType"
                                  }
                                  direction={
                                    this.state.orderBy === "transactionType"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler(
                                    "transactionType"
                                  )}
                                >
                                  Type
                                </TableSortLabel>
                              </TableCell>
                              <TableCell className="tableHeader">
                                Status
                              </TableCell>
                              <TableCell className="tableHeader">
                                ACH Submitted Date
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="checkPrintDate"
                                sortDirection={
                                  this.state.orderBy === "checkPrintDate"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={
                                    this.state.orderBy === "checkPrintDate"
                                  }
                                  direction={
                                    this.state.orderBy === "checkPrintDate"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler(
                                    "checkPrintDate"
                                  )}
                                >
                                  Check Print Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="postedAt"
                                sortDirection={
                                  this.state.orderBy === "postedAt"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "postedAt"}
                                  direction={
                                    this.state.orderBy === "postedAt"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler("postedAt")}
                                >
                                  Posted Date
                                </TableSortLabel>
                              </TableCell>
                              <TableCell
                                className="tableHeader"
                                key="scheduleDate"
                                sortDirection={
                                  this.state.orderBy === "scheduleDate"
                                    ? this.state.order
                                    : false
                                }
                              >
                                <TableSortLabel
                                  active={this.state.orderBy === "scheduleDate"}
                                  direction={
                                    this.state.orderBy === "scheduleDate"
                                      ? this.state.order
                                      : "asc"
                                  }
                                  className="override-mui-table"
                                  onClick={this.createSortHandler(
                                    "scheduleDate"
                                  )}
                                >
                                  Scheduled Date/By/Type
                                </TableSortLabel>
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow className="sticky-search-bar-second">
                              <TableCell className="first-tableBody" />
                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={transDate === null ? "" : transDate}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterDate",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                        this.props.handleTransactionFilter(
                                          "filterDate",
                                          null
                                        );
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          checkNo,
                                          user,
                                          vendor,
                                          medium,
                                          type,
                                          stat,
                                          e.target.value,
                                          amount,
                                          filterDebitAmount !== ""
                                            ? -filterDebitAmount
                                            : filterCreditAmount,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell />
                              <TableCell className="first-tableBody">
                                {/* <div className="sm-search"> */}
                                <Select
                                  className="dropdown"
                                  // dropdownStyle={{ maxHeight: "110px", overflowY: "scroll" }}
                                  value={desc}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleTransactionFilter(
                                      "filterDesc",
                                      e
                                    );
                                    this.props.clearTransactionMainFilter();
                                    setInitialValueofMainFilters();
                                    this.setState({ page: 0 });
                                    this.props.filterTransactionByAll(
                                      e,
                                      checkNo,
                                      user,
                                      vendor,
                                      medium,
                                      type,
                                      stat,
                                      transDate,
                                      amount,
                                      filterDebitAmount !== ""
                                        ? -filterDebitAmount
                                        : filterCreditAmount,
                                      compareType
                                    );
                                  }}
                                >
                                  {descriptionList &&
                                    descriptionList.map((list) => (
                                      <Option value={list.description}>
                                        {list.description}
                                      </Option>
                                    ))}
                                </Select>

                                {/* </div> */}
                              </TableCell>
                              <TableCell />
                              <TableCell>
                                <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={filterDebitAmount}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterDebitAmount",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.props.handleTransactionFilter(
                                        "filterCreditAmount",
                                        ""
                                      );

                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                        this.props.handleTransactionFilter(
                                          "filterDebitAmount",
                                          ""
                                        );
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          checkNo,
                                          user,
                                          vendor,
                                          medium,
                                          type,
                                          stat,
                                          transDate,
                                          amount,
                                          -e.target.value,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={filterCreditAmount}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterCreditAmount",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.props.handleTransactionFilter(
                                        "filterDebitAmount",
                                        ""
                                      );

                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                        this.props.handleTransactionFilter(
                                          "filterCreditAmount",
                                          ""
                                        );
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          checkNo,
                                          user,
                                          vendor,
                                          medium,
                                          type,
                                          stat,
                                          transDate,
                                          amount,
                                          e.target.value,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                className="tableBody"
                                ref={this.rangeRef}
                              >
                                <div
                                  className="sm-search"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span className="show-range-sign">
                                    {showGreaterThanSign(compareType)}
                                  </span>
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={amount}
                                    className="show-range"
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterAmount",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          checkNo,
                                          user,
                                          vendor,
                                          medium,
                                          type,
                                          stat,
                                          transDate,
                                          e.target.value,
                                          filterDebitAmount !== ""
                                            ? -filterDebitAmount
                                            : filterCreditAmount,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                  <Popover
                                    placement="bottom"
                                    trigger="click"
                                    // visible={this.state.openRangeBar}
                                    content={
                                      <>
                                        <div
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              openRangeBar: false,
                                              page: 0,
                                            });
                                            this.props.clearTransactionMainFilter();
                                            setInitialValueofMainFilters();
                                            this.props.handleTransactionFilter(
                                              "compareType",
                                              "2"
                                            );

                                            this.props.filterTransactionByAll(
                                              desc,
                                              checkNo,
                                              user,
                                              vendor,
                                              medium,
                                              type,
                                              stat,
                                              transDate,
                                              amount,
                                              filterDebitAmount !== ""
                                                ? -filterDebitAmount
                                                : filterCreditAmount,
                                              "2"
                                            );
                                          }}
                                          className="cursorP"
                                        >
                                          Lesser than {`(<)`}
                                        </div>

                                        <div
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();

                                            this.setState({
                                              openRangeBar: false,
                                              page: 0,
                                            });
                                            this.props.handleTransactionFilter(
                                              "compareType",
                                              "1"
                                            );
                                            this.props.clearTransactionMainFilter();
                                            setInitialValueofMainFilters();
                                            this.props.filterTransactionByAll(
                                              desc,
                                              checkNo,
                                              user,
                                              vendor,
                                              medium,
                                              type,
                                              stat,
                                              transDate,
                                              amount,
                                              filterDebitAmount !== ""
                                                ? -filterDebitAmount
                                                : filterCreditAmount,
                                              "1"
                                            );
                                          }}
                                          className="cursorP"
                                        >
                                          Greater than {`(>)`}
                                        </div>
                                        <div
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();

                                            this.setState({
                                              openRangeBar: false,
                                              page: 0,
                                            });
                                            this.props.handleTransactionFilter(
                                              "compareType",
                                              "0"
                                            );
                                            this.props.clearTransactionMainFilter();
                                            setInitialValueofMainFilters();
                                            this.props.filterTransactionByAll(
                                              desc,
                                              checkNo,
                                              user,
                                              vendor,
                                              medium,
                                              type,
                                              stat,
                                              transDate,
                                              amount,
                                              filterDebitAmount !== ""
                                                ? -filterDebitAmount
                                                : filterCreditAmount,
                                              "0"
                                            );
                                          }}
                                          className="cursorP"
                                        >
                                          None
                                        </div>
                                      </>
                                    }
                                  >
                                    <ExpandMoreIcon
                                      className="expand-more-icon"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.setState({
                                          openRangeBar: true,
                                        });
                                      }}
                                    />
                                  </Popover>
                                </div>
                              </TableCell>
                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={checkNo}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterCheckNo",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          e.target.value,
                                          user,
                                          vendor,
                                          medium,
                                          type,
                                          stat,
                                          transDate,
                                          amount,
                                          filterDebitAmount !== ""
                                            ? -filterDebitAmount
                                            : filterCreditAmount,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={user}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterUser",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          checkNo,
                                          e.target.value,
                                          vendor,
                                          medium,
                                          type,
                                          stat,
                                          transDate,
                                          amount,
                                          filterDebitAmount !== ""
                                            ? -filterDebitAmount
                                            : filterCreditAmount,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={vendor}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleTransactionFilter(
                                        "filterVendor",
                                        e.target.value
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      if (
                                        e.target.value === "" ||
                                        e.target.value === " "
                                      ) {
                                        this.props.fetchTransactionList();
                                      } else {
                                        this.props.filterTransactionByAll(
                                          desc,
                                          checkNo,
                                          user,
                                          e.target.value,
                                          medium,
                                          type,
                                          stat,
                                          transDate,
                                          amount,
                                          filterDebitAmount !== ""
                                            ? -filterDebitAmount
                                            : filterCreditAmount,
                                          compareType
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <Select
                                    className="dropdown"
                                    dropdownStyle={{
                                      maxHeight: "auto",
                                      overflowY: "scroll",
                                    }}
                                    placeholder="Search…"
                                    value={medium}
                                    getPopupContainer={(node) =>
                                      node.parentNode
                                    }
                                    onChange={(e) => {
                                      this.props.handleTransactionFilter(
                                        "filterMedium",
                                        e
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      this.props.filterTransactionByAll(
                                        desc,
                                        checkNo,
                                        user,
                                        vendor,
                                        e,
                                        type,
                                        stat,
                                        transDate,
                                        amount,
                                        filterDebitAmount !== ""
                                          ? -filterDebitAmount
                                          : filterCreditAmount,
                                        compareType
                                      );
                                    }}
                                  >
                                    <Option value="CHECK">Check</Option>
                                    <Option value="CASH">Cash</Option>
                                    <Option value="WIRE_TRANSFER">
                                      Wire Transfer
                                    </Option>
                                    <Option value="ACH">ACH</Option>
                                    <Option value="CARD">Card</Option>
                                    <Option value="LEDGER">Ledger</Option>
                                    <Option value="OTHER_MEDIUM">
                                      Other medium
                                    </Option>
                                  </Select>
                                </div>
                              </TableCell>

                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <Select
                                    className="dropdown"
                                    dropdownStyle={{
                                      maxHeight: "auto",
                                      overflowY: "scroll",
                                    }}
                                    placeholder="Search…"
                                    value={type}
                                    getPopupContainer={(node) =>
                                      node.parentNode
                                    }
                                    onChange={(e) => {
                                      this.props.handleTransactionFilter(
                                        "filterType",
                                        e
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      this.props.filterTransactionByAll(
                                        desc,
                                        checkNo,
                                        user,
                                        vendor,
                                        medium,
                                        e,
                                        stat,
                                        transDate,
                                        amount,
                                        filterDebitAmount !== ""
                                          ? -filterDebitAmount
                                          : filterCreditAmount,
                                        compareType
                                      );
                                    }}
                                  >
                                    {allTypeArray &&
                                      allTypeArray.map((list) => (
                                        <Option value={list.val}>
                                          {list.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </div>
                              </TableCell>
                              <TableCell className="tableBody">
                                <div className="sm-search">
                                  <Select
                                    className="dropdown"
                                    dropdownStyle={{
                                      maxHeight: "auto",
                                      overflowY: "scroll",
                                    }}
                                    placeholder="Search…"
                                    value={stat}
                                    getPopupContainer={(node) =>
                                      node.parentNode
                                    }
                                    onChange={(e) => {
                                      this.props.handleTransactionFilter(
                                        "filtetStatus",
                                        e
                                      );
                                      this.props.clearTransactionMainFilter();
                                      setInitialValueofMainFilters();
                                      this.setState({ page: 0 });
                                      this.props.filterTransactionByAll(
                                        desc,
                                        checkNo,
                                        user,
                                        vendor,
                                        medium,
                                        type,
                                        transactionStatusHelper([e]),
                                        transDate,
                                        amount,
                                        filterDebitAmount !== ""
                                          ? -filterDebitAmount
                                          : filterCreditAmount,
                                        compareType
                                      );
                                    }}
                                  >
                                    {FilterStatusData &&
                                      FilterStatusData.map((list) => (
                                        <Option value={list.name}>
                                          {list.name}
                                        </Option>
                                      ))}
                                  </Select>
                                </div>
                              </TableCell>
                              <TableCell className="tableBody">
                                {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                  
                                  />
                                </div> */}
                              </TableCell>
                              <TableCell className="tableBody">
                                {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    value={clientNo}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      this.props.handleChangeFilterClientno(
                                        e.target.value
                                      );

                                      this.props.filterClient(
                                        name,
                                        e.target.value,
                                        caseCount,
                                        date,
                                        balance,
                                        term
                                      );
                                    }}
                                  />
                                </div> */}
                              </TableCell>
                              <TableCell className="tableBody">
                                {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}

                                  />
                                </div> */}
                              </TableCell>
                              <TableCell className="tableBody">
                                {/* <div className="sm-search">
                                  <InputBase
                                    placeholder="Search…"
                                    inputProps={{ "aria-label": "search" }}
                                    
                                  />
                                </div> */}
                              </TableCell>
                              <TableCell
                                style={{ paddingLeft: "0", cursor: "pointer" }}
                              >
                                <Popover
                                  placement="bottom"
                                  trigger="click"
                                  content={
                                    <>
                                      <div
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            OpenSaveFilterInConfigmenu: true,
                                            openConfigMenu: false,
                                          });
                                        }}
                                        className="cursorP"
                                      >
                                        Save In Config
                                      </div>

                                      <div
                                        role="presentation"
                                        style={{
                                          padding: "0.2rem",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.props.getConfigList("2");
                                          this.setState({
                                            OpenViewFilterInConfig: true,
                                            openConfigMenu: false,
                                          });
                                        }}
                                        className="cursorP"
                                      >
                                        View Config
                                      </div>
                                    </>
                                  }
                                >
                                  <MoreHorizIcon
                                    onClick={(e) => {
                                      e.preventDefault();

                                      this.setState({
                                        popoverElement: e.currentTarget,

                                        openConfigMenu: true,
                                      });
                                    }}
                                  />
                                </Popover>
                              </TableCell>

                              <TableCell />
                            </TableRow>
                          </TableBody>

                          {/* </TableBody> */}
                          {sortedTransactionList &&
                            sortedTransactionList
                              .slice(
                                this.state.page * this.state.rowsPerPage,
                                this.state.page * this.state.rowsPerPage +
                                  this.state.rowsPerPage
                              )
                              .map((list, index) => (
                                <TableBody key={list.transactionId}>
                                  <TableRow
                                    style={
                                      list.transactionStatus ===
                                        "TRANSACTION_ON_HOLD" ||
                                      list.transactionStatus ===
                                        "TRANSACTION_BLOCKED"
                                        ? {
                                            cursor: "pointer",
                                            background: "#fff1f0",
                                          }
                                        : { cursor: "pointer" }
                                    }
                                  >
                                    <TableCell className="first-tableBody">
                                      {(this.state.page + 1 - 1) *
                                        this.state.rowsPerPage +
                                        (index + 1)}
                                    </TableCell>
                                    <TableCell
                                      className="tableBody"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {new Intl.DateTimeFormat("default", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                      }).format(
                                        list.transactionTimestamp || "-"
                                      )}
                                    </TableCell>
                                    <TableCell style={{ paddingRight: "0px" }}>
                                      {list.note ? (
                                        <NotesPopover
                                          content={
                                            <>
                                              <span>{list.note}</span>
                                              <Tooltip
                                                placement="bottom"
                                                title="Edit Note"
                                              >
                                                <EditOutlined
                                                  onClick={() => {
                                                    this.setState({
                                                      isUpdateNote: true,
                                                      trans: list,
                                                    });
                                                    handleChangeToNotes(
                                                      list.note
                                                    );
                                                    handleChangeToOpenNoteModal(
                                                      true
                                                    );
                                                  }}
                                                  style={{
                                                    marginLeft: "4px",
                                                    width: "15px",
                                                    paddingBottom: "2px",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </>
                                          }
                                        />
                                      ) : (
                                        <Tooltip
                                          placement="bottom"
                                          title="Add Note"
                                        >
                                          <NoteAddIcon
                                            onClick={() => {
                                              this.setState({
                                                isUpdateNote: false,
                                                trans: list,
                                              });
                                              handleChangeToNotes("");
                                              handleChangeToOpenNoteModal(true);
                                            }}
                                            style={{
                                              color: "rgb(126, 126, 126)",
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                    <TableCell className="first-tableBody">
                                      {/* <Select showArrow={false} bordered={false}
                                        open={list.transactionId === this.state.tempRowId && this.state.openDropDown}
                                        // value={this.props.state.transactionState.transData.description}
                                        defaultValue={list.description}
                                        getPopupContainer={(node) => node.parentNode}
                                        onClick={() => {
                                          this.setState({
                                            trans: list,
                                          })
                                        }}
                                        onChange={(e) => {
                                          const selectedData = this.props.settingState &&
                                            this.props.settingState.descriptionList.find(elem => elem.descriptionId === e)
                                          const descriptionData = {
                                            // transactionId: this.state.trans.transactionId,
                                            transactionId: list.transactionId,
                                            description: selectedData.description
                                          };
                                          this.props.addDescription(descriptionData, e);
                                          this.setState({
                                            openDropDown: false
                                          })
                                        }}
                                      >
                                        {this.props.settingState &&
                                          this.props.settingState.descriptionList.map((list) => (
                                            <Option value={list.descriptionId}>
                                              {list.description}
                                            </Option>
                                          ))}
                                      </Select> */}
                                      {/* <span id="dropDownToggler" onClick={(e) => {
                                        e.stopPropagation();
                                        handleChangeDropDown(list.transactionId);
                                      }}
                                        style={style.editDesc}>
                                        <i class="fa-solid fa-pen fa-xs" id="checkDropDown" />
                                      </span> */}
                                      <div
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {list.description}
                                      </div>

                                      <Popover
                                        visible={
                                          list.transactionId ===
                                            this.state.tempRowId &&
                                          this.state.visible
                                        }
                                        onVisibleChange={
                                          this.handleVisibleChange
                                        }
                                        // visible={list.transactionId === this.state.tempRowId && this.state.openDropDown}
                                        onClick={() => {
                                          this.setState({
                                            trans: list,
                                          });
                                        }}
                                        trigger={"click"}
                                        placement="bottomLeft"
                                        content={
                                          <>
                                            {this.props.settingState &&
                                              this.props.settingState.descriptionList.map(
                                                (list) => (
                                                  <div
                                                    value={list.descriptionId}
                                                    className="listHover hideScrollBar"
                                                    onClick={(e) => {
                                                      const selectedData =
                                                        this.props
                                                          .settingState &&
                                                        this.props.settingState.descriptionList.find(
                                                          (elem) =>
                                                            elem.descriptionId ===
                                                            list.descriptionId
                                                        );
                                                      const descriptionData = {
                                                        transactionId:
                                                          this.state.trans
                                                            .transactionId,
                                                        description:
                                                          selectedData &&
                                                          selectedData.description,
                                                      };
                                                      this.props.addDescription(
                                                        descriptionData,
                                                        this.props
                                                      );
                                                      this.handleVisibleChange(
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    {list.description}
                                                    <hr
                                                      style={{
                                                        borderTop: "none",
                                                      }}
                                                    />
                                                  </div>
                                                )
                                              )}
                                          </>
                                        }
                                      >
                                        <span
                                          id="dropDownToggler"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleChangeDropDown(
                                              list.transactionId
                                            );
                                          }}
                                          style={style.editDesc}
                                        >
                                          <i
                                            class="fa-solid fa-pen fa-xs"
                                            id="checkDropDown"
                                          />
                                        </span>
                                      </Popover>
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        paddingRight: "0px",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        // paddingLeft: "47px",
                                        paddingRight: 0,
                                      }}
                                      // className="tableAmountBody"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.amount
                                        ? list.amount &&
                                          list.amount.charAt(0) === "-"
                                          ? formatter
                                              .format(list.amount / 100)
                                              .substring(1)
                                          : "-"
                                        : formatter.format(0)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        // paddingLeft: "47px",
                                        paddingRight: 0,
                                      }}
                                      // className="tableAmountBody"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.amount
                                        ? list.amount.charAt(0) === "-"
                                          ? "-"
                                          : formatter.format(list.amount / 100)
                                        : formatter.format(0)}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        // paddingLeft: "47px",
                                        paddingRight: 0,
                                      }}
                                      // className="tableAmountBody"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.balance
                                        ? formatter.format(list.balance / 100)
                                        : formatter.format(0)}
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        paddingLeft: "47px",
                                        paddingRight: 0,
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.refId}
                                      <span>
                                        {list.checkNo
                                          ? " / " + list.checkNo + " "
                                          : ""}
                                      </span>
                                    </TableCell>
                                    {userType === "CLIENT_USER" ? (
                                      <TableCell
                                        style={{
                                          fontSize: "15px",
                                          color: "#626262",
                                          textAlign: "left",
                                          height: "4.5rem",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleOpenDialogue();
                                          this.setState(
                                            {
                                              trans: list,
                                            },
                                            () => {
                                              this.props.getTransactionInfo(
                                                this.state.trans.transactionId
                                              );
                                            }
                                          );
                                        }}
                                      >
                                        {list.Client === {}
                                          ? "-"
                                          : list.Client.lastName
                                          ? list.Client.firstName +
                                            " " +
                                            list.Client.lastName
                                          : list.Client.firstName}
                                      </TableCell>
                                    ) : (
                                      <TableCell
                                        style={{
                                          fontSize: "15px",
                                          color: "#626262",
                                          textAlign: "left",
                                          height: "4.5rem",
                                          textDecoration: "underline",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            trans: list,
                                          });
                                        }}
                                      >
                                        <span
                                          onClick={() => {
                                            this.setState(
                                              {
                                                trans: list,
                                              },
                                              () => {
                                                this.props.clearSnackBar();
                                                this.props.history.push({
                                                  pathname:
                                                    "/transaction/client/" +
                                                    this.state.trans.Client
                                                      .clientId,
                                                });
                                              }
                                            );
                                          }}
                                        >
                                          {list.Client.firstName === undefined
                                            ? "-"
                                            : list.Client.lastName
                                            ? list.Client.firstName +
                                              " " +
                                              list.Client.lastName
                                            : list.Client.firstName}
                                        </span>
                                      </TableCell>
                                    )}
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.vendorAccount.vendorName
                                        ? list.vendorAccount.vendorName
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.transactionMedium &&
                                        list.transactionMedium.replace(
                                          "_",
                                          " "
                                        )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.transactionType.replace("_", " ")}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                        cursor: "auto",
                                        width: "10%",
                                      }}
                                    >
                                      <>
                                        <Tag
                                          style={style.colors}
                                          color={
                                            list.transactionStatus ===
                                            "TRANSACTION_APPROVED"
                                              ? "green"
                                              : list.transactionStatus ===
                                                "TRANSACTION_BLOCKED"
                                              ? "yellow"
                                              : list.transactionStatus ===
                                                "TRANSACTION_REVERSED"
                                              ? "red"
                                              : list.transactionStatus ===
                                                "TRANSACTION_CREATED"
                                              ? "purple"
                                              : list.transactionStatus ===
                                                "TRANSACTION_SUBMITTED"
                                              ? "blue"
                                              : list.transactionStatus ===
                                                "TRANSACTION_RETURNED"
                                              ? "geekblue"
                                              : list.transactionStatus ===
                                                "TRANSACTION_POSTED"
                                              ? "cyan"
                                              : list.transactionStatus ===
                                                "TRANSACTION_SKIPPED"
                                              ? "orange"
                                              : list.transactionStatus ===
                                                "TRANSACTION_ON_HOLD"
                                              ? "volcano"
                                              : list.transactionStatus ===
                                                "TRANSACTION_CANCELLED"
                                              ? "gold"
                                              : list.transactionStatus ===
                                                "TRANSACTION_CLOSED"
                                              ? "magenta"
                                              : "lime"
                                          }
                                        >
                                          {list.transactionStatus &&
                                            list.transactionStatus
                                              .replace("TRANSACTION_", " ")
                                              .replace("_", " ")}
                                        </Tag>
                                        {list.remark !== undefined ? (
                                          <NotesPopover
                                            isRemark
                                            content={list.remark}
                                          />
                                        ) : null}
                                      </>
                                    </TableCell>
                                    <TableCell
                                      className="first-tableBody"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.achLog &&
                                      list.achLog.achSubmittedDate &&
                                      list.achLog.achSubmittedDate
                                        ? new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(
                                            list.achLog.achSubmittedDate
                                              ? list.achLog.achSubmittedDate
                                              : "-"
                                          )
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                        width: "225px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.checkPrint &&
                                      list.checkPrint.checkPrintDate &&
                                      list.checkPrint.checkPrintDate
                                        ? new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(
                                            list.checkPrint.checkPrintDate
                                              ? list.checkPrint.checkPrintDate
                                              : "-"
                                          )
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      // className="first-tableBody"
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                        width: "225px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenDialogue();
                                        this.setState(
                                          {
                                            trans: list,
                                          },
                                          () => {
                                            this.props.getTransactionInfo(
                                              this.state.trans.transactionId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      {list.postedAt
                                        ? new Intl.DateTimeFormat("default", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }).format(
                                            list.postedAt ? list.postedAt : "-"
                                          )
                                        : "-"}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        fontSize: "15px",
                                        color: "#626262",
                                        textAlign: "left",
                                        paddingLeft: "18px",
                                        height: "4.5rem",
                                        width: "225px",
                                      }}
                                    >
                                      {list.transactionDetails ? (
                                        <div>
                                          <div>
                                            {list.transactionDetails &&
                                              list.transactionDetails
                                                .scheduleDate &&
                                              new Intl.DateTimeFormat("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                              }).format(
                                                list.transactionDetails &&
                                                  list.transactionDetails
                                                    .scheduleDate
                                                  ? list.transactionDetails &&
                                                      list.transactionDetails
                                                        .scheduleDate
                                                  : "-"
                                              )}
                                          </div>
                                          <div style={{ marginTop: "5px" }}>
                                            {list.transactionDetails &&
                                            list.transactionDetails
                                              .scheduleBy ? (
                                              <>
                                                <Avatar
                                                  style={{
                                                    width: "28px",
                                                    height: "28px",
                                                    marginRight: "8px",
                                                  }}
                                                  src={
                                                    list.transactionDetails
                                                      .profilePic
                                                      ? list.transactionDetails
                                                          .profilePic
                                                      : "/assets/images/profile.svg"
                                                  }
                                                />
                                                <span>
                                                  {
                                                    list.transactionDetails
                                                      .scheduleBy
                                                  }
                                                </span>
                                                <span> / </span>
                                                <span>
                                                  {list.transactionDetails
                                                    .scheduleType &&
                                                    list.transactionDetails.scheduleType.replace(
                                                      "_",
                                                      " "
                                                    )}
                                                </span>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </TableCell>
                                    <TableCell style={{ paddingLeft: "0px" }}>
                                      {list.transactionStatus ===
                                        "TRANSACTION_ON_HOLD" ||
                                      list.transactionStatus ===
                                        "TRANSACTION_BLOCKED" ? (
                                        <>
                                          <Popover
                                            placement="bottom"
                                            trigger="click"
                                            content={
                                              <>
                                                <div
                                                  role="presentation"
                                                  onClick={() => {
                                                    this.setState({
                                                      openActionMenu: false,
                                                      setValue: "changeStatus",
                                                    });
                                                    this.props.handleChangeRemark(
                                                      ""
                                                    );
                                                    this.props.handleChangeTransStatus(
                                                      ""
                                                    );
                                                    handleOpenChangeTransStatusModal();
                                                  }}
                                                  className="cursorP"
                                                >
                                                  Approve
                                                </div>

                                                {list.transactionStatus ===
                                                  "TRANSACTION_BLOCKED" && (
                                                  <>
                                                    <hr />
                                                    <div
                                                      role="presentation"
                                                      style={{
                                                        padding: "0.2rem",
                                                      }}
                                                      onClick={() => {
                                                        // this.setState({
                                                        //   openActionMenu: false,
                                                        // });
                                                        handleModalToEditBlockedTransaction();
                                                        this.props.handleChangeBlockedTransData(
                                                          "amount",
                                                          list.amount.substring(
                                                            1
                                                          )
                                                        );
                                                        this.setState({
                                                          prevAmount:
                                                            list.amount,
                                                        });
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Edit
                                                    </div>
                                                    <hr />
                                                    <div
                                                      role="presentation"
                                                      // style={{ padding: "0.2rem" }}
                                                      onClick={() => {
                                                        this.setState({
                                                          openActionMenu: false,
                                                          setValue:
                                                            "changeStatus",
                                                        });
                                                        this.props.handleChangeRemark(
                                                          ""
                                                        );
                                                        this.props.handleChangeTransStatus(
                                                          ""
                                                        );
                                                        handleDeleteModalOpen();
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Delete
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            }
                                          >
                                            <MoreHorizIcon
                                              onClick={(e) => {
                                                e.preventDefault();

                                                this.setState({
                                                  popoverElement:
                                                    e.currentTarget,
                                                  openActionMenu: true,
                                                  selectedTransData: list,
                                                  transactionId:
                                                    list.transactionId,
                                                });
                                              }}
                                            />
                                          </Popover>
                                        </>
                                      ) : list.transactionStatus ===
                                          "TRANSACTION_SUBMITTED" &&
                                        list.transactionMedium === "CHECK" &&
                                        list.transactionType ===
                                          "DISBURSEMENT" ? (
                                        <div>
                                          <>
                                            <Popover
                                              trigger="click"
                                              placement="bottom"
                                              content={
                                                <div
                                                  role="presentation"
                                                  style={{
                                                    padding: "0.2rem",
                                                  }}
                                                  onClick={() => {
                                                    //   this.setState({
                                                    //     openActionMenu: false,
                                                    //     setValue: "changeStatus",
                                                    //   });
                                                    this.props.handleChangeRemark(
                                                      ""
                                                    );

                                                    handleChangeOpenModalToDecline();
                                                  }}
                                                  className="cursorP"
                                                >
                                                  Decline
                                                </div>
                                              }
                                            >
                                              <MoreHorizIcon
                                                id="Popover1"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    popoverElement:
                                                      e.currentTarget,
                                                    openActionMenu: true,
                                                    selectedTransData: list,
                                                    transactionId:
                                                      list.transactionId,
                                                    checkNo:
                                                      list.refId && list.refId,
                                                  });
                                                }}
                                              />
                                            </Popover>
                                          </>
                                        </div>
                                      ) : list.transactionStatus ===
                                        "TRANSACTION_REVERSED" ? (
                                        <div>
                                          <>
                                            <Popover
                                              trigger="click"
                                              placement="bottom"
                                              content={
                                                <>
                                                  <div
                                                    role="presentation"
                                                    onClick={() => {
                                                      this.setState({
                                                        openActionMenu: false,
                                                        setValue:
                                                          "changeStatus",
                                                      });
                                                      this.props.handleChangeRemark(
                                                        ""
                                                      );
                                                      // this.props.handleChangeTransStatus(
                                                      //   "TRANSACTION_REVERSED"
                                                      // );

                                                      handleChangeOpenModalToUndoReverse();
                                                      // handleUndoReversedTrans();
                                                    }}
                                                    className="cursorP"
                                                  >
                                                    Undo
                                                  </div>
                                                </>
                                              }
                                            >
                                              <MoreHorizIcon
                                                id="Popover1"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    popoverElement:
                                                      e.currentTarget,
                                                    openActionMenu: true,
                                                    selectedTransData: list,
                                                    transactionId:
                                                      list.transactionId,
                                                    checkNo:
                                                      list.refId && list.refId,
                                                  });
                                                }}
                                              />
                                            </Popover>
                                          </>
                                        </div>
                                      ) : // : list.transactionStatus ===
                                      //   "TRANSACTION_POSTED" ? (
                                      //   <div>
                                      //     <>
                                      //       <Popover
                                      //         trigger="click"
                                      //         placement="bottom"
                                      //         content={
                                      //           <>
                                      //             <div
                                      //               role="presentation"
                                      //               onClick={() => {
                                      //                 this.setState({
                                      //                   openActionMenu: false,
                                      //                   setValue:
                                      //                     "changeStatus",
                                      //                 });
                                      //                 this.props.handleChangeRemark(
                                      //                   ""
                                      //                 );
                                      //                 this.props.handleChangeTransStatus(
                                      //                   "TRANSACTION_REVERSED"
                                      //                 );
                                      //                 handleOpenToDeclineTransaction();
                                      //               }}
                                      //               className="cursorP"
                                      //             >
                                      //               Reversed
                                      //             </div>
                                      //           </>
                                      //         }
                                      //       >
                                      //         <MoreHorizIcon
                                      //           id="Popover1"
                                      //           style={{ cursor: "pointer" }}
                                      //           onClick={(e) => {
                                      //             e.preventDefault();
                                      //             this.setState({
                                      //               popoverElement:
                                      //                 e.currentTarget,
                                      //               openActionMenu: true,
                                      //               selectedTransData: list,
                                      //               transactionId:
                                      //                 list.transactionId,
                                      //               checkNo:
                                      //                 list.refId && list.refId,
                                      //             });
                                      //           }}
                                      //         />
                                      //       </Popover>
                                      //     </>
                                      //   </div>
                                      // )
                                      // : list.transactionStatus ===
                                      //   "TRANSACTION_REVERSED" ? (
                                      //   <div>
                                      //     <>
                                      //       <Popover
                                      //         trigger="click"
                                      //         placement="bottom"
                                      //         content={
                                      //           <>
                                      //             <div
                                      //               role="presentation"
                                      //               onClick={() => {
                                      //                 this.setState({
                                      //                   openActionMenu: false,
                                      //                   setValue:
                                      //                     "changeStatus",
                                      //                 });
                                      //                 this.props.handleChangeRemark(
                                      //                   ""
                                      //                 );
                                      //                 this.props.handleChangeTransStatus(
                                      //                   "TRANSACTION_REVERSED"
                                      //                 );
                                      //                 handleOpenToDeclineTransaction();
                                      //               }}
                                      //               className="cursorP"
                                      //             >
                                      //               Reversed
                                      //             </div>
                                      //           </>
                                      //         }
                                      //       >
                                      //         <MoreHorizIcon
                                      //           id="Popover1"
                                      //           style={{ cursor: "pointer" }}
                                      //           onClick={(e) => {
                                      //             e.preventDefault();
                                      //             this.setState({
                                      //               popoverElement:
                                      //                 e.currentTarget,
                                      //               openActionMenu: true,
                                      //               selectedTransData: list,
                                      //               transactionId:
                                      //                 list.transactionId,
                                      //               checkNo:
                                      //                 list.refId && list.refId,
                                      //             });
                                      //           }}
                                      //         />
                                      //       </Popover>
                                      //     </>
                                      //   </div>
                                      // )
                                      list.transactionStatus ===
                                        "TRANSACTION_SUBMITTED" ? (
                                        // list.transactionMedium === "LEDGER" ||
                                        // (list.transactionMedium === "CHECK" &&
                                        //   list.transactionType ===
                                        //     "DISBURSEMENT") ||
                                        // list.transactionMedium === "ACH" ? (
                                        //   <div />
                                        // ) : (
                                        <div>
                                          <>
                                            <Popover
                                              trigger="click"
                                              placement="bottom"
                                              content={
                                                <>
                                                  <div
                                                    role="presentation"
                                                    onClick={() => {
                                                      this.setState({
                                                        openActionMenu: false,
                                                        setValue:
                                                          "changeStatus",
                                                      });
                                                      this.props.handleChangeRemark(
                                                        ""
                                                      );
                                                      this.props.handleChangeTransStatus(
                                                        "TRANSACTION_REVERSED"
                                                      );
                                                      handleOpenToDeclineTransaction();
                                                    }}
                                                    className="cursorP"
                                                  >
                                                    Reversed
                                                  </div>
                                                  {list.transactionStatus ===
                                                    "TRANSACTION_CREATED" ||
                                                  list.transactionStatus ===
                                                    "TRANSACTION_APPROVED" ? (
                                                    <>
                                                      <hr />
                                                      <div
                                                        role="presentation"
                                                        style={{
                                                          padding: "0.2rem",
                                                        }}
                                                        onClick={() => {
                                                          this.setState({
                                                            openActionMenu: false,
                                                            isUpdate: true,
                                                            // setValue: "changeStatus",
                                                          });
                                                          handleOpenDialogueToCreateTransaction();
                                                          this.props.setTransactionInputDataToEdit(
                                                            this.state
                                                              .selectedTransData
                                                          );
                                                          this.props.fetchSingleClient(
                                                            this.state
                                                              .selectedTransData
                                                              .clientId,
                                                            this.props
                                                          );
                                                          this.setState({
                                                            clearBankDetail: false,
                                                          });
                                                        }}
                                                        className="cursorP"
                                                      >
                                                        Edit
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              }
                                            >
                                              <MoreHorizIcon
                                                id="Popover1"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    popoverElement:
                                                      e.currentTarget,
                                                    openActionMenu: true,
                                                    selectedTransData: list,
                                                    transactionId:
                                                      list.transactionId,
                                                    checkNo:
                                                      list.refId && list.refId,
                                                  });
                                                }}
                                              />
                                            </Popover>
                                          </>
                                        </div>
                                      ) : // )
                                      list.transactionStatus ===
                                          "TRANSACTION_APPROVED" &&
                                        list.transactionMedium === "ACH" ? (
                                        <div>
                                          <>
                                            <Popover
                                              style={{ padding: "10px 12px" }}
                                              trigger="click"
                                              placement="bottom"
                                              content={
                                                <>
                                                  <div
                                                    role="presentation"
                                                    // style={{ padding: "0.2rem" }}
                                                    onClick={() => {
                                                      this.setState({
                                                        openActionMenu: false,
                                                        setValue:
                                                          "changeStatus",
                                                      });
                                                      this.props.handleChangeRemark(
                                                        ""
                                                      );
                                                      this.props.handleChangeTransStatus(
                                                        "TRANSACTION_REVERSED"
                                                      );
                                                      handleOpenToDeclineTransaction(
                                                        list
                                                      );
                                                    }}
                                                    className="cursorP"
                                                  >
                                                    Complete
                                                  </div>

                                                  {userRole === "SUPER_ADMIN" &&
                                                    accountType ===
                                                      "EVERFUND_USER" && (
                                                      <>
                                                        <hr />
                                                        <div
                                                          role="presentation"
                                                          // style={{ padding: "0.2rem" }}
                                                          onClick={() => {
                                                            this.setState({
                                                              openActionMenu: false,
                                                              setValue:
                                                                "changeStatus",
                                                            });
                                                            this.props.handleChangeRemark(
                                                              ""
                                                            );
                                                            this.props.handleChangeTransStatus(
                                                              ""
                                                            );
                                                            handleDeleteModalOpen();
                                                          }}
                                                          className="cursorP"
                                                        >
                                                          Delete
                                                        </div>

                                                        <hr />
                                                        <div
                                                          role="presentation"
                                                          style={{
                                                            padding: "0.2rem",
                                                          }}
                                                          onClick={() => {
                                                            this.setState({
                                                              openActionMenu: false,
                                                              isUpdate: true,
                                                              // setValue: "changeStatus",
                                                            });
                                                            handleOpenDialogueToCreateTransaction();
                                                            this.props.setTransactionInputDataToEdit(
                                                              this.state
                                                                .selectedTransData
                                                            );
                                                            this.props.fetchSingleClient(
                                                              this.state
                                                                .selectedTransData
                                                                .clientId,
                                                              this.props
                                                            );
                                                            this.setState({
                                                              clearBankDetail: false,
                                                            });
                                                            // this.props.handleChangeRemark("");
                                                            // this.props.handleChangeTransStatus("");
                                                            // handleDeleteModalOpen();
                                                          }}
                                                          className="cursorP"
                                                        >
                                                          Edit
                                                        </div>
                                                      </>
                                                    )}
                                                </>
                                              }
                                            >
                                              <MoreHorizIcon
                                                id="Popover1"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    popoverElement:
                                                      e.currentTarget,
                                                    openActionMenu: true,
                                                    selectedTransData: list,
                                                    transactionId:
                                                      list.transactionId,
                                                  });
                                                }}
                                              />
                                            </Popover>
                                          </>
                                        </div>
                                      ) : list.transactionStatus ===
                                          "TRANSACTION_CREATED" ||
                                        list.transactionStatus ===
                                          "TRANSACTION_APPROVED" ? (
                                        userRole === "SUPER_ADMIN" &&
                                        accountType === "EVERFUND_USER" && (
                                          <div>
                                            <>
                                              <Popover
                                                placement="bottom"
                                                trigger="click"
                                                content={
                                                  <>
                                                    <div
                                                      role="presentation"
                                                      onClick={() => {
                                                        this.setState({
                                                          openActionMenu: false,
                                                          setValue:
                                                            "changeStatus",
                                                        });
                                                        this.props.handleChangeRemark(
                                                          ""
                                                        );
                                                        this.props.handleChangeTransStatus(
                                                          ""
                                                        );
                                                        handleDeleteModalOpen();
                                                      }}
                                                      className="cursorP"
                                                    >
                                                      Delete
                                                    </div>
                                                    {list.transactionStatus ===
                                                      "TRANSACTION_APPROVED" &&
                                                      list.transactionType !==
                                                        "DEPOSIT" &&
                                                      list.transactionMedium !==
                                                        "LEDGER" && (
                                                        <>
                                                          <hr />
                                                          <div
                                                            role="presentation"
                                                            style={{
                                                              padding: "0.2rem",
                                                            }}
                                                            onClick={() => {
                                                              this.setState({
                                                                openActionMenu: false,
                                                                isUpdate: true,
                                                                // setValue: "changeStatus",
                                                              });
                                                              handleOpenDialogueToCreateTransaction();
                                                              this.props.setTransactionInputDataToEdit(
                                                                this.state
                                                                  .selectedTransData
                                                              );
                                                              this.props.fetchSingleClient(
                                                                this.state
                                                                  .selectedTransData
                                                                  .clientId,
                                                                this.props
                                                              );
                                                              this.setState({
                                                                clearBankDetail: false,
                                                              });
                                                            }}
                                                            className="cursorP"
                                                          >
                                                            Edit
                                                          </div>
                                                        </>
                                                      )}
                                                  </>
                                                }
                                              >
                                                <MoreHorizIcon
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                      popoverElement:
                                                        e.currentTarget,
                                                      openActionMenu: true,
                                                      selectedTransData: list,
                                                      transactionId:
                                                        list.transactionId,
                                                    });
                                                  }}
                                                />
                                              </Popover>
                                            </>
                                          </div>
                                        )
                                      ) : (
                                        <div />
                                      )}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        paddingLeft: "0px",
                                        fontSize: "15px",
                                        color: "rgb(98, 98, 98)",
                                        textAlign: "left",
                                        height: "4.5rem",
                                        paddingRight: "0px",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.fetchActivityLogByRefId(
                                          list.transactionId
                                        );
                                        handleOpenActivityLogModal();
                                      }}
                                    >
                                      <Tooltip title="View Activity Log">
                                        <img
                                          width={22}
                                          src={
                                            "/assets/images/activityLog1.svg"
                                          }
                                          alt=""
                                        />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ))}
                        </Table>
                      </TableContainer>
                      {sortedTransactionList &&
                      sortedTransactionList.length > 0 ? (
                        <div
                          style={{
                            justifyContent: "flex-start",
                            width: "100%",
                            borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          <TablePagination
                            labelRowsPerPage={false}
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={
                              sortedTransactionList.length > 0
                                ? sortedTransactionList.length
                                : 100
                            }
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            backIconButtonProps={{
                              "aria-label": "previous page",
                            }}
                            nextIconButtonProps={{
                              "aria-label": "next page",
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <h3
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              padding: "1rem",
                              color: "#606060",
                            }}
                          >
                            {" "}
                            No data to show
                          </h3>
                        </div>
                      )}
                      {/* </TableContainer> */}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Schedule Transactions" key="schedule">
                  {/* <div style={{paddingTop: "4.5rem"}}> */}
                  <ScheduleTransactionContainer history={this.props.history} />
                  {/* </div> */}
                </TabPane>
                <TabPane tab="Upcoming Transactions" key="upcoming">
                  {/*<UpcomingTransactionTable*/}
                  {/*    upcomingTrans={upcomingTrans}*/}
                  {/*    page={this.state.page}*/}
                  {/*    rowsPerPage={this.state.rowsPerPage}*/}
                  {/*    setPage={setPage}*/}
                  {/*    setRowPerPage={setRowPerPage}*/}
                  {/*    fetchUpcomingTransactionList={() =>{this.props.fetchUpcomingTransactionList()}}*/}
                  {/*/>*/}
                  <div
                    className={"d-flex flex-row-reverse"}
                    style={{
                      paddingBottom: "1rem",
                      // paddingTop: "1rem",
                      paddingLeft: "1rem",
                      outline: "none",
                      overflow: "auto",
                      marginBottom: "5px",
                    }}
                  >
                    <div>
                      <button
                        className="filterButton"
                        onClick={() => {
                          this.props.fetchUpcomingTransactionList();
                          this.props.clearUpTransFilter();
                        }}
                      >
                        <RefreshIcon
                          style={{ color: "#fff", marginTop: "-2px" }}
                        />
                      </button>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={style.filterButton}
                        onClick={handleChangeOpenUpcomingFilterDialogue}
                      >
                        <FilterListIcon
                          style={{ color: "#fff", marginTop: "-2px" }}
                        />
                      </button>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="search">
                        <div style={style.searchIcon}>
                          <SearchIcon style={{ color: "#9c9c9c" }} />
                        </div>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          style={style.inputBase}
                          onChange={(e) => {
                            e.preventDefault();
                            this.setState({
                              pageUpcoming: 0,
                            });
                            if (
                              e.target.value === "" ||
                              e.target.value === " "
                            ) {
                              this.props.fetchUpcomingTransactionList();
                            } else {
                              this.props.searchUpcomingTransactionByTerm(
                                e.target.value,
                                this.props
                              );
                              this.props.searchAnnualUpcomingTransactionByTerm();
                              this.props.handleChangeTerm(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={"row"} style={style.card}>
                    <TableContainer className="fixed-table-header-second">
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                        ref={this.tableRef}
                      >
                        <TableHead style={{ padding: "1rem" }}>
                          <TableRow>
                            <TableCell className="tableHeader">#</TableCell>
                            <TableCell
                              className="first-tableHeader"
                              style={{ width: "230px" }}
                              key="upcomingTxnDate"
                              sortDirection={
                                this.state.orderBy === "upcomingTxnDate"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "upcomingTxnDate"
                                }
                                direction={
                                  this.state.orderBy === "upcomingTxnDate"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "upcomingTxnDate"
                                )}
                              >
                                Transaction Date/Time
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="amount"
                              sortDirection={
                                this.state.orderBy === "amount"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "amount"}
                                direction={
                                  this.state.orderBy === "amount"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("amount")}
                              >
                                Amount
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              // style={{ paddingLeft: "80px" }}
                              key="firstNameUP"
                              sortDirection={
                                this.state.orderBy === "firstNameUP"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "firstNameUP"}
                                direction={
                                  this.state.orderBy === "firstNameUP"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("firstNameUP")}
                              >
                                User
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="vendorName"
                              sortDirection={
                                this.state.orderBy === "vendorName"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "vendorName"}
                                direction={
                                  this.state.orderBy === "vendorName"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler("vendorName")}
                              >
                                Vendor
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="transactionMedium"
                              sortDirection={
                                this.state.orderBy === "transactionMedium"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "transactionMedium"
                                }
                                direction={
                                  this.state.orderBy === "transactionMedium"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "transactionMedium"
                                )}
                              >
                                Medium
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="transactionType"
                              sortDirection={
                                this.state.orderBy === "transactionType"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={
                                  this.state.orderBy === "transactionType"
                                }
                                direction={
                                  this.state.orderBy === "transactionType"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "transactionType"
                                )}
                              >
                                Type
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              className="tableHeader"
                              key="scheduledDate"
                              sortDirection={
                                this.state.orderBy === "scheduledDate"
                                  ? this.state.order
                                  : false
                              }
                            >
                              <TableSortLabel
                                active={this.state.orderBy === "scheduledDate"}
                                direction={
                                  this.state.orderBy === "scheduledDate"
                                    ? this.state.order
                                    : "asc"
                                }
                                className="override-mui-table"
                                onClick={this.createSortHandler(
                                  "scheduledDate"
                                )}
                              >
                                Scheduled Date/By/Type
                              </TableSortLabel>
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className="sticky-search-bar">
                            <TableCell className="first-tableBody" />
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPDate === null ? "" : UPDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterDate",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByAll(
                                      UPUser,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      e.target.value,
                                      UPSchDate,
                                      UPAmount,
                                      UPCompareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              className="tableBody"
                              // ref={this.rangeRef}
                            >
                              <div
                                className="sm-search"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span className="show-range-sign">
                                  {showGreaterThanSign(UPCompareType)}
                                </span>
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPAmount}
                                  className="show-range"
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterAmount",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByAll(
                                      UPUser,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      e.target.value,
                                      UPCompareType
                                    );
                                  }}
                                />
                                <Popover
                                  placement="bottom"
                                  trigger="click"
                                  content={
                                    <>
                                      <div
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            openRangeBar: false,
                                          });
                                          this.props.handleUpTransFilter(
                                            "compareType",
                                            "2"
                                          );
                                          this.props.searchUpcomingTransactionByAll(
                                            UPUser,
                                            UPVendor,
                                            UPMedium,
                                            UPType,

                                            UPDate,
                                            UPSchDate,
                                            UPAmount,
                                            "2"
                                          );
                                        }}
                                        className="cursorP"
                                      >
                                        Lesser than {`(<)`}
                                      </div>

                                      <div
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.setState({
                                            openRangeBar: false,
                                          });
                                          this.props.handleUpTransFilter(
                                            "compareType",
                                            "1"
                                          );
                                          this.props.searchUpcomingTransactionByAll(
                                            UPUser,
                                            UPVendor,
                                            UPMedium,
                                            UPType,

                                            UPDate,
                                            UPSchDate,
                                            UPAmount,
                                            "1"
                                          );
                                        }}
                                        className="cursorP"
                                      >
                                        Greater than {`(>)`}
                                      </div>
                                      <div
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          this.setState({
                                            openRangeBar: false,
                                          });
                                          this.props.handleUpTransFilter(
                                            "compareType",
                                            "0"
                                          );
                                          this.props.searchUpcomingTransactionByAll(
                                            UPUser,
                                            UPVendor,
                                            UPMedium,
                                            UPType,

                                            UPDate,
                                            UPSchDate,
                                            UPAmount,
                                            "0"
                                          );
                                        }}
                                        className="cursorP"
                                      >
                                        None
                                      </div>
                                    </>
                                  }
                                >
                                  <ExpandMoreIcon
                                    className="expand-more-icon"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        popoverElement: e.currentTarget,
                                        // client: client.client,
                                        openRangeBar: true,
                                      });
                                    }}
                                  />
                                </Popover>
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPUser}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterUser",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByAll(
                                      e.target.value,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      UPCompareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPVendor}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterVendor",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByAll(
                                      UPUser,
                                      e.target.value,
                                      UPMedium,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      UPCompareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={UPMedium}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleUpTransFilter(
                                      "filterMedium",
                                      e
                                    );
                                    this.props.searchUpcomingTransactionByAll(
                                      UPUser,
                                      UPVendor,
                                      e,
                                      UPType,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      UPCompareType
                                    );
                                  }}
                                >
                                  <Option value="CHECK">Check</Option>
                                  <Option value="CASH">Cash</Option>
                                  <Option value="WIRE_TRANSFER">
                                    Wire Transfer
                                  </Option>
                                  <Option value="ACH">ACH</Option>
                                  <Option value="CARD">Card</Option>
                                  <Option value="LEDGER">Ledger</Option>
                                  <Option value="OTHER_MEDIUM">
                                    Other medium
                                  </Option>
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <Select
                                  className="dropdown"
                                  dropdownStyle={{
                                    maxHeight: "auto",
                                    overflowY: "scroll",
                                  }}
                                  placeholder="Search…"
                                  value={UPType}
                                  getPopupContainer={(node) => node.parentNode}
                                  onChange={(e) => {
                                    this.props.handleUpTransFilter(
                                      "filterType",
                                      e
                                    );
                                    this.props.searchUpcomingTransactionByAll(
                                      UPUser,
                                      UPVendor,
                                      UPMedium,
                                      e,

                                      UPDate,
                                      UPSchDate,
                                      UPAmount,
                                      UPCompareType
                                    );
                                  }}
                                >
                                  <Option value="DISBURSEMENT">
                                    Disbursement
                                  </Option>
                                  <Option value="DEPOSIT">Deposit</Option>
                                  <Option value="FEE">Fee</Option>
                                </Select>
                              </div>
                            </TableCell>
                            <TableCell className="tableBody">
                              <div className="sm-search">
                                <InputBase
                                  placeholder="Search…"
                                  inputProps={{ "aria-label": "search" }}
                                  value={UPSchDate === null ? "" : UPSchDate}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    this.props.handleUpTransFilter(
                                      "filterSchedule",
                                      e.target.value
                                    );

                                    this.props.searchUpcomingTransactionByAll(
                                      UPUser,
                                      UPVendor,
                                      UPMedium,
                                      UPType,

                                      UPDate,

                                      e.target.value,
                                      UPAmount,
                                      UPCompareType
                                    );
                                  }}
                                />
                              </div>
                            </TableCell>

                            <TableCell
                              style={{ paddingLeft: "0", cursor: "pointer" }}
                            >
                              <Popover
                                placement="bottom"
                                trigger="click"
                                content={
                                  <>
                                    <div
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          OpenSaveFilterInConfigmenu: true,
                                          isUpcomingFilter: true,
                                          openConfigMenu: false,
                                        });
                                      }}
                                      className="cursorP"
                                    >
                                      Save In Config
                                    </div>

                                    <div
                                      role="presentation"
                                      style={{
                                        padding: "0.2rem",
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.props.getConfigList("4");
                                        this.setState({
                                          OpenViewFilterInConfig: true,
                                          isUpcomingFilter: true,
                                          openConfigMenu: false,
                                        });
                                      }}
                                      className="cursorP"
                                    >
                                      View Config
                                    </div>
                                  </>
                                }
                              >
                                <MoreHorizIcon
                                  onClick={(e) => {
                                    e.preventDefault();

                                    this.setState({
                                      popoverElement: e.currentTarget,

                                      openConfigMenu: true,
                                    });
                                  }}
                                />
                              </Popover>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        {sortedUpcomingTrans &&
                          sortedUpcomingTrans
                            .slice(
                              this.state.pageUpcoming *
                                this.state.rowsPerPageForUpcoming,
                              this.state.pageUpcoming *
                                this.state.rowsPerPageForUpcoming +
                                this.state.rowsPerPageForUpcoming
                            )
                            .map((list, index) => (
                              <TableBody>
                                <TableRow
                                  style={
                                    (list.transactionType === "DISBURSEMENT" ||
                                      list.transactionType === "FEE") &&
                                    list.isFundAvailable === undefined
                                      ? {
                                          cursor: "pointer",
                                          background: "#fff1f0",
                                        }
                                      : { cursor: "pointer" }
                                  }
                                >
                                  <TableCell>
                                    {" "}
                                    {(this.state.pageUpcoming + 1 - 1) *
                                      this.state.rowsPerPageForUpcoming +
                                      (index + 1)}{" "}
                                  </TableCell>
                                  <TableCell className="first-tableBody">
                                    {new Intl.DateTimeFormat("default", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(list.upcomingTxnDate)}
                                    {/*list.upcomingTxnDate*/}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.amount &&
                                      formatter.format(list.amount / 100)}
                                  </TableCell>
                                  <TableCell
                                    className="tableBody"
                                    // style={{ paddingLeft: "80px" }}
                                  >
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            upComingTransactionObject: list,
                                          },
                                          () => {
                                            this.props.history.push({
                                              pathname:
                                                "/transaction/client/upcoming_transaction/" +
                                                this.state
                                                  .upComingTransactionObject
                                                  .client.clientId,
                                            });
                                          }
                                        );
                                      }}
                                    >
                                      {list.client.firstName +
                                        " " +
                                        list.client.lastName}
                                    </span>
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.vendorAccount.vendorName}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.transactionMedium}
                                  </TableCell>
                                  <TableCell className="tableBody">
                                    {list.transactionType}
                                  </TableCell>
                                  <TableCell className="">
                                    {list.scheduleDetail ? (
                                      <div>
                                        <div>
                                          {list.createdAt &&
                                            new Intl.DateTimeFormat("en-US", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              second: "2-digit",
                                            }).format(
                                              list.createdAt
                                                ? list.createdAt
                                                : "-"
                                            )}
                                        </div>
                                        <div style={{ marginTop: "5px" }}>
                                          {list.scheduleDetail
                                            .scheduleByName ? (
                                            <>
                                              <Avatar
                                                style={{
                                                  width: "28px",
                                                  height: "28px",
                                                  marginRight: "8px",
                                                }}
                                                src={
                                                  list.scheduleDetail.profileUrl
                                                    ? list.scheduleDetail
                                                        .profileUrl
                                                    : "/assets/images/profile.svg"
                                                }
                                              />
                                              <span>
                                                {
                                                  list.scheduleDetail
                                                    .scheduleByName
                                                }
                                              </span>
                                              <span> / </span>
                                              <span>
                                                {list.scheduleDetail
                                                  .scheduleType &&
                                                  list.scheduleDetail.scheduleType.replace(
                                                    "_",
                                                    " "
                                                  )}
                                              </span>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {list.isSkipped === undefined &&
                                    list.allowProcessNow === true ? (
                                      <div style={{ display: "flex" }}>
                                        <Button
                                          style={style.skipNow}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              upcomingTrans: list,
                                              isProcess: false,
                                            });
                                            this.props.handleChangeToOpenSkipModal(
                                              true
                                            );
                                            // handleOpenDialogueToProcess();
                                          }}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            Skip
                                          </span>
                                        </Button>
                                        <Button
                                          style={style.processNow}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              upcomingTrans: list,
                                              isProcess: true,
                                            });
                                            handleOpenDialogueToProcess();
                                            this.props.handleChangeRemarkToSkip(
                                              ""
                                            );
                                          }}
                                        >
                                          <span style={{ fontSize: "10px" }}>
                                            Process Now
                                          </span>
                                        </Button>
                                      </div>
                                    ) : list.isSkipped === undefined &&
                                      list.allowProcessNow === undefined ? (
                                      <Button
                                        style={style.skipNow}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            upcomingTrans: list,
                                            isProcess: false,
                                          });
                                          this.props.handleChangeToOpenSkipModal(
                                            true
                                          );
                                          this.props.handleChangeRemarkToSkip(
                                            ""
                                          );
                                        }}
                                      >
                                        <span style={{ fontSize: "10px" }}>
                                          Skip
                                        </span>
                                      </Button>
                                    ) : list.isSkipped === true ? (
                                      <>
                                        <Tag color="orange">Skipped</Tag>
                                        {list.remark ? (
                                          <NotesPopover
                                            content={list.remark}
                                            isRemark
                                          />
                                        ) : null}
                                      </>
                                    ) : (
                                      <div></div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                      </Table>
                    </TableContainer>
                    {sortedUpcomingTrans && sortedUpcomingTrans.length > 0 ? (
                      <div
                        style={{
                          justifyContent: "flex-start",
                          width: "100%",
                          borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        <TablePagination
                          labelRowsPerPage={false}
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={
                            sortedUpcomingTrans.length > 0
                              ? sortedUpcomingTrans.length
                              : 100
                          }
                          rowsPerPage={this.state.rowsPerPageForUpcoming}
                          page={this.state.pageUpcoming}
                          backIconButtonProps={{
                            "aria-label": "previous page",
                          }}
                          nextIconButtonProps={{
                            "aria-label": "next page",
                          }}
                          onChangePage={handleChangePageForUpcoming}
                          onChangeRowsPerPage={
                            handleChangeRowsPerPageForUpcoming
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            padding: "1rem",
                            color: "#606060",
                          }}
                        >
                          {" "}
                          No data to show
                        </h3>
                      </div>
                    )}
                    {/* </TableContainer> */}
                  </div>
                </TabPane>
                <TabPane tab="Annual Transactions" key="annual">
                  <AnnualTransactionTable
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    setPage={setPage}
                    tableRef={this.tableRef}
                    setRowPerPage={setRowPerPage}
                    annualUpcomingTrans={annualUpcomingTrans}
                    fetchAnnualTransaction={
                      this.props.fetchAnnualUpcomingTransactionList
                    }
                    searchAnnualUpcomingTransactionByTerm={(value) => {
                      this.props.searchAnnualUpcomingTransactionByTerm(
                        value,
                        this.props
                      );
                    }}
                  />
                </TabPane>
                <TabPane tab="Ledger Transactions" key="ledger">
                  <LedgerTransactionTable
                    setTransactionStatus={setTransactionStatus}
                    tableList={ledgerTransactionList}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    setPage={setPage}
                    setInitialPage={setInitialPage}
                    tableRef={this.tableRef}
                    rangeRef={this.rangeRef}
                    totalTransaction={totalTransaction}
                    totalAmount={totalAmount}
                    setRowPerPage={setRowPerPage}
                    descriptionList={descriptionList}
                    FilterStatusData={FilterStatusData}
                    FilterLEDTypeData={FilterLEDTypeData}
                    setInitialValueofMainFilters={setInitialValueofMainFilters}
                    fetchLedgerTransactionList={(
                      from,
                      to,
                      status,
                      type,
                      term
                    ) => {
                      this.props.fetchLedgerTransactionList(
                        from,
                        to,
                        status,
                        type,
                        term
                      );
                    }}
                    exportLedgerTransactionInPDF={() => {
                      this.props.exportLedgerTransactionList(
                        startDate,
                        endDate,

                        transactionStatusHelper(this.state.isStatusCheck),
                        transactionTypeHelper(
                          this.state.isLEDTypeCheck,
                          "type"
                        ),
                        "",
                        "PDF_EXPORT"
                      );
                    }}
                    exportLedgerTransactionInCSV={() => {
                      this.props.exportLedgerTransactionList(
                        startDate,
                        endDate,

                        transactionStatusHelper(this.state.isStatusCheck),
                        transactionTypeHelper(
                          this.state.isLEDTypeCheck,
                          "type"
                        ),

                        "",
                        "CSV_EXPORT"
                      );
                    }}
                    exportLedgerTransactionInPDFByColumnFilter={(
                      desc,
                      checkNo,
                      user,
                      vendor,
                      medium,
                      type,
                      stat,
                      transDate,
                      amount,
                      compareType
                    ) => {
                      this.props.exportLedgerTransactionListByColumnFilter(
                        desc,
                        checkNo,
                        user,
                        vendor,
                        medium,
                        type,
                        stat,
                        transDate,
                        amount,
                        compareType,
                        "PDF_EXPORT"
                      );
                    }}
                    exportLedgerTransactionInCSVByColumnFilter={(
                      desc,
                      checkNo,
                      user,
                      vendor,
                      medium,
                      type,
                      stat,
                      transDate,
                      amount,
                      compareType
                    ) => {
                      this.props.exportLedgerTransactionListByColumnFilter(
                        desc,
                        checkNo,
                        user,
                        vendor,
                        medium,
                        type,
                        stat,
                        transDate,
                        amount,
                        compareType,
                        "CSV_EXPORT"
                      );
                    }}
                    handleOpenLedgerTransFilter={handleOpenLedgerTransFilter}
                    openCreateTransactionForm={() => {
                      this.props.handleOpenCreateWithdrawalVendorTransaction(
                        true
                      );
                    }}
                    clearAmountDetail={() => {
                      this.props.clearAmountDetail();
                    }}
                    fetchWithdrawalVendorList={() => {
                      this.props.fetchWithdrawalVendorList();
                    }}
                    clearWithdrawalTransactionForm={() => {
                      this.props.clearWithdrawalTransactionForm();
                    }}
                    ledgerTransaction={this.state.ledgerTrans}
                    history={this.props.history}
                    clearSnackBar={() => {
                      this.props.clearSnackBar();
                    }}
                    setOpenLedgerTxnUpdate={setOpenLedgerTxnUpdate}
                    transactionState={transactionState}
                    notesValue={notesValue}
                    notes={notes}
                    openNotesModalForLedger={openNotesModalForLedger}
                    handleChangeToNotes={handleChangeToNotes}
                    handleChangeToOpenNoteModal={handleChangeToOpenNoteModal}
                    trans={this.state.trans}
                    isUpdateNote={this.state.isUpdateNote}
                    transactionId={this.state.transactionId}
                    addNoteForTransaction={addNoteForTransaction}
                    handleChangeToOpenNoteModalForLedger={
                      this.props.handleChangeToOpenNoteModalForLedger
                    }
                    settingState={settingState}
                    addDescription={addDescription}
                    propsData={this.props}
                  />
                </TabPane>
                <TabPane tab="Deleted Transactions" key="delete">
                  <DeletedTransactionTable
                    fetchDeletedTransaction={() => {
                      this.props.fetchDeletedTransaction();
                    }}
                    deletedTransData={deletedTransData}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    setPage={setPage}
                    setInitialPage={setInitialPage}
                    setRowPerPage={setRowPerPage}
                  />
                </TabPane>
              </Tabs>
              {/* </Affix> */}
            </div>
          )}

          {/**** Update status ******/}

          <Dialog
            open={this.state.openModalToChangeTransStatus}
            onClose={handleCloseDialogueToProcess}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.8rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to approve transaction?
                <span
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <span className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseChangeTransStatusModal}
                      style={style.cancel}
                    >
                      cancel
                    </Button>
                  </span>

                  <span className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseChangeTransStatusModal();
                        handleUpdateStatus();
                        // handleOkTransaction(this.state.upcomingTrans);
                      }}
                      style={style.deleteClientButton}
                    >
                      ok
                    </Button>
                  </span>
                </span>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          {/* dialog to undo reverse transaction */}
          <Dialog
            open={this.state.openConfirmationToUndoReverse}
            onClose={handleCloseUndoReverse}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.8rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to undo this reversed transaction?
                <span
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <span className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseUndoReverse}
                      style={style.cancel}
                    >
                      cancel
                    </Button>
                  </span>

                  <span className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseChangeTransStatusModal();
                        handleUpdateReverseStatus();
                      }}
                      style={style.deleteClientButton}
                    >
                      ok
                    </Button>
                  </span>
                </span>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          {/*modal to undo revere */}
          <Dialog
            open={this.state.openModalToUndoReverse}
            onClose={handleChangeCloseModalToUndoReverse}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleChangeCloseModalToUndoReverse}
              >
                Undo Reverse
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div>
                  <label className="label">
                    Remark <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    id="remark"
                    className="input"
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeRemark(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                  }}
                >
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                    <ButtonComponent
                      value={declineStatus}
                      buttonName="Undo"
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeCloseModalToUndoReverse();
                        handleUpdateReverseStatus();
                        // handleUpdateCheckStatus();
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>
          {/*modal to decline check*/}
          <Dialog
            open={this.state.openModalToDeclineCheck}
            onClose={handleChangeCloseModalToDecline}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleChangeCloseModalToDecline}
              >
                Decline Check
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div>
                  <label className="label">
                    Remark <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    id="remark"
                    className="input"
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeRemark(e.target.value);
                    }}
                  />
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                  }}
                >
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                    <ButtonComponent
                      value={declineStatus}
                      buttonName="Decline"
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeCloseModalToDecline();
                        handleUpdateCheckStatus();
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/*modal to edit blocked transaction */}
          <Dialog
            open={this.state.openModalToEditBlockedTransaction}
            onClose={handleCloseToEditBlockedTransaction}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleCloseToEditBlockedTransaction}
              >
                Update
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div>
                  <label className="label">
                    Amount (Dollar) <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    autoComplete="off"
                    className="inputField"
                    value={
                      blockedData.amount === 0 ? "" : blockedData.amount / 100
                    }
                    onKeyPress={(e) => {
                      naturalRound(e);
                    }}
                    onChange={(e) => {
                      this.props.handleChangeBlockedTransData(
                        e.target.id,
                        finalAmount(e)
                      );
                      Helpers.handleValidationOnChange(e, errors);
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label className="label">New Date</label>
                    <div>
                      <KeyBoardDatePickerComponent
                        dateTimeFormat
                        isCalendarDate
                        dateValue={
                          blockedData.date &&
                          getDateWithStartTimeValue(blockedData.date)
                        }
                        onChangeDate={(date) => {
                          this.props.handleChangeBlockedTransData("date", date);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                  }}
                >
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                    <ButtonComponent
                      value={blockedStatus}
                      buttonName="Update"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseToEditBlockedTransaction();
                        handleChangeBlockedTransaction();
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/* Delete transaction  */}
          <Dialog
            open={this.state.openModalToDeleteTransaction}
            onClose={handleCloseDialogueToProcess}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.8rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                Do you want to Delete transaction?
                <span
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <span className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleDeleteModalClose}
                      style={style.cancel}
                    >
                      cancel
                    </Button>
                  </span>

                  <span className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteModalClose();
                        handleDeleteStatus();
                        // handleOkTransaction(this.state.upcomingTrans);
                      }}
                      style={style.deleteClientButton}
                    >
                      ok
                    </Button>
                  </span>
                </span>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          <Dialog
            open={false}
            onClose={handleCloseChangeTransStatusModal}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-lg-12">
                  <label className="label">
                    Status <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input selects h40">
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ value: e });
                        this.props.handleChangeTransStatus(e);
                      }}
                    >
                      <Option hidden />
                      <Option value="TRANSACTION_APPROVED">Approve</Option>
                      <Option value="TRANSACTION_CANCELLED">Cancel</Option>
                    </Select>
                  </div>
                </div>
                {transStatus === "TRANSACTION_CANCELLED" ? (
                  <div className="col-lg-12">
                    <label className="label">
                      Remark <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      id="remark"
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeRemark(e.target.value);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={disableButton}
                    buttonName="Update"
                    loading={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdateStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/**************** Import Transaction ************/}
          <Dialog
            open={this.state.openModalToImportTransaction}
            onClose={handleCloseImportTransactionModal}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleCloseImportTransactionModal}
              >
                Select Bank Statement
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <span>Total Bank Balance: </span>
                      <span style={{ color: "#676767" }}>
                        {bankDetails && formatter.format(bankDetails / 100)}
                      </span>
                    </div>
                    <label
                      htmlFor="note"
                      className="label"
                      style={{ marginTop: "10px !important" }}
                    >
                      Document <span style={{ color: "red" }}> *</span>
                    </label>
                    <div>
                      {csvFile ? (
                        <img
                          src="/assets/images/csv.png"
                          alt="pdf"
                          style={{
                            width: "50px",
                            marginBottom: "8px",
                            marginTop: "7px",
                          }}
                        />
                      ) : null}
                    </div>
                    <div>
                      <label
                        style={{
                          width: "85px",
                          marginTop: "0.5rem",
                          // marginBottom: '1.5rem',
                          background: "rgba(204,204,254,0.32)",
                          border: "1px solid #7b7b7b",
                          textAlign: "center",
                          padding: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          type="file"
                          onChange={(e) => {
                            e.preventDefault();
                            handleChangeCsvFile(e);
                            // onChangeDocumentFileHandler(e);
                          }}
                          className="hidden"
                          style={{ display: "none" }}
                        />
                        Select file
                      </label>{" "}
                      <soan style={{ fontStyle: "italic" }}>
                        (Select CSV file)
                      </soan>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={"col-6"}></div>
                  <div className={"col-6"} style={{ paddingLeft: "134px" }}>
                    <Button
                      disabled={!value}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          setValue: "import",
                        });
                        this.props.uploadCSVFileToImport(csvFile, this.props);
                      }}
                      style={{ textTransform: "capitalize" }}
                      className={value ? "addButton" : "disabledAddButton"}
                    >
                      Import
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/*********** UPDATE LEDGER STATUS ***************/}

          <Dialog
            open={openLedgerTxnUpdateModal}
            onClose={() => {
              setOpenLedgerTxnUpdate(false);
            }}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={() => {
                  setOpenLedgerTxnUpdate(false);
                }}
              >
                Update Status
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div className="row">
                  <div className="col-lg-12" hidden={this.state.isDecline}>
                    <label className="label">
                      Status <span style={{ color: "red" }}> *</span>
                    </label>
                    <div className="select-input selects h40">
                      <Select
                        showSearch={false}
                        getPopupContainer={(node) => node.parentNode}
                        onChange={(e) => {
                          this.setState({ value: e });
                          this.props.handleChangeTransStatus(e);
                        }}
                      >
                        <Option hidden />
                        <Option
                          value="TRANSACTION_SUBMITTED"
                          hidden={
                            this.state.selectedStatus ===
                            "TRANSACTION_SUBMITTED"
                          }
                        >
                          Submitted
                        </Option>
                        <Option value="TRANSACTION_POSTED">Posted</Option>
                        <Option value="TRANSACTION_CLOSED">Close</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label className="label">
                      Remark <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      id="remark"
                      className="input"
                      onChange={(e) => {
                        e.preventDefault();
                        this.props.handleChangeRemark(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                  }}
                >
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                    <ButtonComponent
                      noValue
                      buttonName="Update"
                      loading={this.props.transactionState.loading}
                      onClick={(e) => {
                        e.preventDefault();
                        handleUpdateLedgerStatus();
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/************ FILTER FOR TRANSACTION **************/}
          <Dialog
            id="viewScheduleTransactionDetails"
            open={this.state.openSearchDialogue}
            maxWidth="lg"
            onClose={handleChangeCloseSearchDialogue}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseSearchDialogue}
            >
              Filter
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                width: "450px",
                paddingTop: "5px",
              }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <label style={style.label}>Start Date</label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                      dateValue={startDate && getFormattedDateValue(startDate)}
                      isFilterFiled
                      onChangeDate={(data) => {
                        this.props.handleChangeDateFrom(
                          new Date(data).setHours(0, 0, 0)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label style={style.label}>End Date</label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                      dateValue={endDate && getFormattedDateValue(endDate)}
                      isFilterFiled
                      onChangeDate={(data) => {
                        this.props.handleChangeDateTo(
                          new Date(data).setHours(23, 29, 29)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <SelectiveDateForFilter
                handleChangeFromDate={(date) => {
                  this.props.handleChangeDateFrom(date);
                }}
                handleChangeToDate={(date) => {
                  this.props.handleChangeDateTo(date);
                }}
              />
              <label style={style.label}> Medium </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterMediumData}
                isItemCheck={this.state.isMediumCheck}
                isAllCheck={this.state.isAllMediumChecked}
                handleAllCheckbox={this.handleAllMediumCheckbox}
                handleSelectedChange={this.handleSelectedMediumChange}
              />
              <label style={style.label}> Status </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterStatusData}
                isItemCheck={this.state.isStatusCheck}
                isAllCheck={this.state.isALlChecked}
                handleAllCheckbox={this.handleAllCheckbox}
                handleSelectedChange={this.handleSelectedChange}
              />

              <label style={style.label}> Type </label>
              <CheckboxFilterPopover
                forMappingFilterData={
                  // this.state.isMediumCheck[0] === "Ledger"
                  //   ? FilterTypeDataForLedger
                  //   : FilterTypeData
                  allTypeArray
                }
                // isItemCheck={
                //   // this.state.isMediumCheck[0] === "Ledger"
                //   //   ? this.state.isLedCaseTypeCheck
                //   //   : this.state.isTypeCheck
                //   this.state.isTypeCheck
                // }
                isItemCheck={this.state.isTypeCheck}
                isAllCheck={
                  // this.state.isMediumCheck[0] === "Ledger"
                  //   ? this.state.isAllLedCaseTypeChecked
                  //   : this.state.isAllTypeChecked
                  this.state.isAllTypeChecked
                }
                // handleAllCheckbox={
                //   this.state.isMediumCheck[0] === "Ledger"
                //     ? this.handleAllLedCaseTypeCheckbox
                //     : this.handleAllTypeCheckbox
                // }
                // handleSelectedChange={
                //   this.state.isMediumCheck[0] === "Ledger"
                //     ? this.handleSelectedLedCaseTypeChange
                //     : this.handleSelectedTypeChange
                // }
                handleAllCheckbox={this.handleAllTypeCheckbox}
                handleSelectedChange={this.handleSelectedTypeChange}
              />
              <label style={style.label}> Vendor </label>
              <div className="select-input h40">
                <Select
                  className="inputField"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(node) => node.parentNode}
                  onChange={(e) => {
                    this.props.handleChangeVendor(e);
                  }}
                >
                  {vendorList &&
                    vendorList.map((list) => (
                      <Option value={list.vendorId}>{list.vendorName}</Option>
                    ))}
                </Select>
              </div>
              <div>
                <div className="row">
                  <div className="col-6" />
                  <div className="col-6" style={{ paddingTop: "15px" }}>
                    {(startDate === null || startDate === 0) &&
                    (endDate === null || endDate === 0) &&
                    term === "" &&
                    vendorToFilter === "" &&
                    arraysEqual(
                      this.state.isStatusCheck,
                      FilterStatusData.map((status) => status.name)
                    ) &&
                    arraysEqual(
                      this.state.isTypeCheck,
                      FilterTypeData.map((type) => type.name)
                    ) &&
                    arraysEqual(
                      this.state.isMediumCheck,
                      FilterMediumData.map((medium) => medium.name)
                    ) ? (
                      <>
                        <Button
                          style={{ float: "right" }}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              page: 0,
                            });
                            this.props.fetchTransactionList();
                            handleChangeCloseSearchDialogue();
                          }}
                          // disabled={!filteredValue}
                          // className={
                          //   filteredValue ? "addButton" : "disabledAddButton"
                          // }
                          className="addButton"
                        >
                          Filter
                        </Button>
                      </>
                    ) : (
                      <Button
                        style={{ float: "right" }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            page: 0,
                          });
                          this.props.searchTransactionByAll(
                            // mediumType,
                            // status,
                            transactionMediumHelper(this.state.isMediumCheck),
                            transactionStatusHelper(this.state.isStatusCheck),

                            transactionTypeHelper(
                              this.state.isTypeCheck,
                              "type"
                            ),
                            // this.state.isMediumCheck[0] === "Ledger"
                            //   ? transactionTypeHelper(
                            //       this.state.isLedCaseTypeCheck,
                            //       "type"
                            //     )
                            //   : transactionTypeHelper(
                            //       this.state.isTypeCheck,
                            //       "type"
                            //     ),
                            // transactionType,
                            startDate,
                            endDate,
                            vendorToFilter
                          );
                          handleChangeCloseSearchDialogue();
                        }}
                        // disabled={!filteredValue}
                        // className={
                        //   filteredValue ? "addButton" : "disabledAddButton"
                        // }
                        className="addButton"
                      >
                        Filter
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/************** FILTER FOR LEDGER TRANSACTION ************/}
          <Dialog
            id="viewScheduleTransactionDetails"
            open={this.state.openLedgerFilter}
            maxWidth="lg"
            onClose={handleCloseLedgerTransFilter}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseLedgerTransFilter}
            >
              Filter
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                paddingLeft: "1.5rem !important",
                paddingRight: "1.5rem !important",
                width: "450px",
                paddingTop: "5px",
              }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <label style={style.label}>Start Date</label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                      dateValue={startDate && getFormattedDateValue(startDate)}
                      isFilterFiled
                      onChangeDate={(data) => {
                        this.props.handleChangeDateFrom(
                          new Date(data).setHours(0, 0, 0)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label style={style.label}>End Date</label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                      dateValue={endDate && getFormattedDateValue(endDate)}
                      isFilterFiled
                      onChangeDate={(data) => {
                        this.props.handleChangeDateTo(
                          new Date(data).setHours(23, 29, 29)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <SelectiveDateForFilter
                handleChangeFromDate={(date) => {
                  this.props.handleChangeDateFrom(date);
                }}
                handleChangeToDate={(date) => {
                  this.props.handleChangeDateTo(date);
                }}
              />
              <label style={style.label}> Status </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterStatusData}
                isItemCheck={this.state.isStatusCheck}
                isAllCheck={this.state.isALlChecked}
                handleAllCheckbox={this.handleAllCheckbox}
                handleSelectedChange={this.handleSelectedChange}
              />
              <label style={style.label}> Type </label>
              <CheckboxFilterPopover
                forMappingFilterData={allTypeArray}
                isItemCheck={this.state.isLEDTypeCheck}
                isAllCheck={this.state.isAllLEDTypeChecked}
                handleAllCheckbox={this.handleAllLEDTypeCheckbox}
                handleSelectedChange={this.handleSelectedLEDTypeChange}
              />
              <div>
                <div className="row">
                  <div className="col-6" />
                  <div className="col-6" style={{ paddingTop: "15px" }}>
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          pageLedgerTrans: 0,
                        });
                        this.props.fetchLedgerTransactionList(
                          startDate,
                          endDate,
                          // status,
                          transactionStatusHelper(this.state.isStatusCheck),
                          // transactionType,
                          transactionTypeHelper(
                            this.state.isLEDTypeCheck,
                            "type"
                          ),
                          term
                        );
                        handleCloseLedgerTransFilter();
                      }}
                      // disabled={!filteredLedgerValue}
                      // className={
                      //   filteredLedgerValue ? "addButton" : "disabledAddButton"
                      // }
                      className={"addButton"}
                    >
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            id="viewScheduleTransactionDetails"
            maxWidth="lg"
            open={this.state.openDialogue}
            onClose={handleCloseDialogue}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseDialogue}
            >
              Transaction Detail
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                background: "white",
                textAlign: "left",
                paddingTop: "25px",
                paddingBottom: "25px",
              }}
            >
              <DialogContentText>
                <span
                  className="row"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0.5rem",
                    paddingTop: "0.4rem",
                    width: "700px",
                  }}
                >
                  <span className="col-4">
                    <label htmlFor="firstName" style={style.columnHeading}>
                      Reference Id
                    </label>
                    <label style={style.clientData}>
                      {trans && trans.refId}
                    </label>
                  </span>
                  <span className="col-4">
                    <label style={style.columnHeading}>Debits (Dollar)</label>
                    <label style={style.clientData}>
                      {trans && trans.amount
                        ? trans.amount.charAt(0)
                          ? formatter.format(trans.amount / 100).substring(1)
                          : "-"
                        : formatter.format(0)}
                    </label>
                  </span>
                  <span className="col-4">
                    <label style={style.columnHeading}>Credit (Dollar)</label>
                    <label style={style.clientData}>
                      {trans && trans.amount
                        ? trans.amount.charAt(0)
                          ? "-"
                          : formatter.format(trans.amount / 100)
                        : formatter.format(0)}
                    </label>
                  </span>
                </span>
                <span
                  className="row"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0.5rem",
                    paddingTop: "1.6rem",
                    width: "700px",
                  }}
                >
                  <span className="col-4">
                    <label htmlFor="ssn" style={style.columnHeading}>
                      Client Name
                    </label>
                    <label style={style.extraData}>
                      {trans &&
                        trans.Client &&
                        trans.Client.firstName + " " + trans.Client.lastName}
                    </label>
                  </span>
                  <span className="col-4">
                    <label htmlFor="firstName" style={style.columnHeading}>
                      Vendor Name
                    </label>
                    <label style={style.clientData}>
                      {trans &&
                      trans.vendorAccount &&
                      trans.vendorAccount.vendorName
                        ? trans.vendorAccount.vendorName
                        : "-"}
                    </label>
                  </span>
                  <span className="col-4">
                    <label htmlFor="dob" style={style.columnHeading}>
                      Transaction Medium
                    </label>
                    <label style={style.clientData}>
                      {trans &&
                        trans.transactionMedium &&
                        trans.transactionMedium.replace("_", " ")}
                    </label>
                  </span>
                </span>
                <span
                  className="row"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingBottom: "0.5rem",
                    paddingTop: "1.6rem",
                    width: "700px",
                  }}
                >
                  <span
                    className="col-4"
                    hidden={trans && trans.checkNo === undefined}
                  >
                    <label htmlFor="ssn" style={style.columnHeading}>
                      Check No.
                    </label>
                    <label style={style.extraData}>
                      {trans && trans.checkNo}
                    </label>
                  </span>
                  <span className="col-4">
                    <label htmlFor="ssn" style={style.columnHeading}>
                      Transaction Type
                    </label>
                    <label style={style.extraData}>
                      {trans && trans.transactionType}
                    </label>
                  </span>
                  <span className="col-4">
                    <label htmlFor="dob" style={style.columnHeading}>
                      Created Date
                    </label>
                    <label style={style.clientData}>
                      {trans &&
                        new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(trans.createdAt || "-")}
                    </label>
                  </span>
                  <span
                    className={trans && trans.checkNo ? "col-4 mt-3" : "col-4"}
                  >
                    <label htmlFor="dob" style={style.columnHeading}>
                      Transaction Date
                    </label>
                    <label style={style.clientData}>
                      {trans &&
                        new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(trans.transactionTimestamp || "-")}
                    </label>
                  </span>
                  <span className="col-4 mt-3">
                    <label htmlFor="ssn" style={style.columnHeading}>
                      Description
                    </label>
                    <label style={style.extraData}>
                      {trans && trans.description ? trans.description : "-"}
                    </label>
                  </span>
                </span>
                <span className="row" hidden={!(trans && trans.note)}>
                  <div
                    style={{
                      marginLeft: " 240px",
                      marginTop: "20px",
                      fontSize: "17px",
                    }}
                  >
                    <span>Note :{trans && trans.note} </span>
                  </div>
                </span>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          {/************* Process Upcoming Transaction ***********/}
          <Dialog
            open={this.state.openDialogueToProcess}
            onClose={handleCloseDialogueToProcess}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
              <h3
                style={{
                  color: "#8BC83F",
                  padding: "0.8rem",
                  textAlign: "center",
                }}
              >
                Confirmation
              </h3>
            </DialogTitle>
            <DialogContent
              style={{
                background: "white",
                textAlign: "center",
                width: "450px",
              }}
            >
              <DialogContentText>
                {this.state.isProcess
                  ? "Do you want to proceed transaction?"
                  : "Do you want to skip transaction?"}

                <span
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <span className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDialogueToProcess}
                      style={style.cancel}
                    >
                      cancel
                    </Button>
                  </span>

                  <span className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkTransaction(this.state.upcomingTrans);
                      }}
                      style={style.deleteClientButton}
                    >
                      ok
                    </Button>
                  </span>
                </span>
              </DialogContentText>
            </DialogContent>
          </Dialog>

          {/************ SKIP UPCOMING TRANSACTION *********/}
          <Dialog
            id="viewScheduleTransactionDetails"
            open={openModalToSkipTransaction}
            maxWidth="lg"
            onClose={() => {
              this.props.handleChangeToOpenSkipModal(false);
            }}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                this.props.handleChangeToOpenSkipModal(false);
              }}
            >
              Skip Transaction
            </DialogTitleComponent>
            <DialogContent dividers className="filterDialog">
              <div>
                <div className="row">
                  <div className="col-12 mt-4">
                    <label htmlFor="amount" style={style.columnHeading}>
                      Remark
                      <span style={{ color: "red" }}> *</span>{" "}
                    </label>
                    <input
                      id="remark"
                      name="remark"
                      autoComplete="off"
                      className="inputField"
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeRemarkToSkip(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6" />
                  <div className="col-6" style={{ paddingTop: "15px" }}>
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSkipTransaction();
                      }}
                      disabled={!skipTransactionValue}
                      className={
                        skipTransactionValue ? "addButton" : "disabledAddButton"
                      }
                    >
                      Skip
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/*add/update notes modal*/}
          <Dialog
            id="viewScheduleTransactionDetails"
            open={openNotesModal}
            maxWidth="lg"
            onClose={() => {
              handleChangeToOpenNoteModal(false);
            }}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                handleChangeToOpenNoteModal(false);
              }}
            >
              {!this.state.isUpdateNote ? "Add Note" : "Update Note"}
            </DialogTitleComponent>
            <DialogContent dividers className="filterDialog">
              <div>
                <div className="row">
                  <div className="col-12 mt-4">
                    <label htmlFor="amount" style={style.columnHeading}>
                      Note
                      <span style={{ color: "red" }}> *</span>{" "}
                    </label>
                    <input
                      id="remark"
                      name="remark"
                      autoComplete="off"
                      className="inputField"
                      value={notes}
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        handleChangeToNotes(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6" />
                  <div className="col-6" style={{ paddingTop: "15px" }}>
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        const noteData = {
                          transactionId: this.state.trans.transactionId,
                          note: notes,
                        };
                        addNoteForTransaction(noteData);
                      }}
                      loading={transactionState && transactionState.loading}
                      disabled={!notesValue}
                      className={notesValue ? "addButton" : "disabledAddButton"}
                    >
                      {!this.state.isUpdateNote ? "Add" : "Update"}
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/*FILTER FOR UPCOMING TRANSACTION*/}
          <Dialog
            id="viewScheduleTransactionDetails"
            open={this.state.openUpcomingFilterDialogue}
            maxWidth="lg"
            onClose={handleChangeCloseUpcomingFilterDialogue}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseUpcomingFilterDialogue}
            >
              Filter
            </DialogTitleComponent>
            <DialogContent dividers className="filterDialog">
              <div className="row">
                <div className="col-lg-6">
                  <label style={style.label}>Start Date</label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                      dateValue={startDate && getFormattedDateValue(startDate)}
                      isFilterFiled
                      onChangeDate={(data) => {
                        this.props.handleChangeDateFrom(
                          new Date(data).setHours(0, 0, 0)
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label style={style.label}>End Date</label>
                  <div className="select-input" style={style.searchDate}>
                    <KeyBoardDatePickerComponent
                      dateValue={endDate && getFormattedDateValue(endDate)}
                      isFilterFiled
                      onChangeDate={(data) => {
                        this.props.handleChangeDateTo(
                          new Date(data).setHours(23, 29, 29)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <SelectiveDateForFilter
                handleChangeFromDate={(date) => {
                  this.props.handleChangeDateFrom(date);
                }}
                handleChangeToDate={(date) => {
                  this.props.handleChangeDateTo(date);
                }}
              />
              <label style={style.label}> Medium </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterMediumData}
                isItemCheck={this.state.isMediumCheck}
                isAllCheck={this.state.isAllMediumChecked}
                handleAllCheckbox={this.handleAllMediumCheckbox}
                handleSelectedChange={this.handleSelectedMediumChange}
              />
              <label style={style.label}> Type </label>
              <CheckboxFilterPopover
                forMappingFilterData={FilterUPTypeData}
                isItemCheck={this.state.isUPTypeCheck}
                isAllCheck={this.state.isAllUPTypeCheked}
                handleAllCheckbox={this.handleAllUPTypeCheckbox}
                handleSelectedChange={this.handleSelectedUPTypeChange}
              />
              <label style={style.label}> Vendor </label>
              <div className="select-input h40">
                <Select
                  showSearch
                  style={style.inputField}
                  getPopupContainer={(node) => node.parentNode}
                  // style={{ width: 200 }}
                  onChange={(e) => {
                    this.props.handleChangeVendor(e);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {vendorList &&
                    vendorList.map((list) => (
                      <Option value={list.vendorId}>{list.vendorName}</Option>
                    ))}
                </Select>
              </div>
              <div>
                <div className="row">
                  <div className="col-6" />
                  <div className="col-6" style={{ paddingTop: "15px" }}>
                    <Button
                      style={{ float: "right" }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          pageUpcoming: 0,
                        });
                        this.props.searchUpcomingTransactionByMediumAndDate(
                          // mediumType,
                          transactionMediumHelper(this.state.isMediumCheck),
                          startDate,
                          endDate,
                          vendorToFilter,
                          // transactionType,
                          transactionTypeHelper(
                            this.state.isUPTypeCheck,
                            "transactionType"
                          )
                        );
                        handleChangeCloseUpcomingFilterDialogue();
                      }}
                      // disabled={!filteredValue}
                      // className={
                      //   filteredValue ? "addButton" : "disabledAddButton"
                      // }
                      className="addButton"
                    >
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          {/*MODAL TO CREATE WITHDRAWAL VENDOR TRANSACTION*/}
          <Dialog
            open={openCreateWithdrawVendorTransaction}
            onClose={() => {
              this.props.handleOpenCreateWithdrawalVendorTransaction(false);
            }}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={() => {
                  this.props.handleOpenCreateWithdrawalVendorTransaction(false);
                }}
              >
                Create Transaction
              </DialogTitleComponent>
              <DialogContent
                dividers
                style={{ padding: "30px 24px" }}
                className="dialogContentForForms"
              >
                <div className="row mt-2">
                  <div className="col-lg-4">
                    <label htmlFor="amount" style={style.columnHeading}>
                      Amount (Dollar)
                      <span style={{ color: "red" }}> *</span>{" "}
                    </label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      autoComplete="off"
                      className="inputField"
                      onKeyPress={(e) => {
                        naturalRound(e);
                      }}
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeTransData(
                          e.target.id,
                          finalAmount(e)
                        );
                        Helpers.handleValidationOnChange(e, errors);
                      }}
                    />
                    {errors.amount.length > 0 && (
                      <span className="error">{errors.amount}</span>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="transMedium" style={style.columnHeading}>
                      Transaction Medium{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ width: "100%" }}
                    >
                      <Select
                        getPopupContainer={(node) => node.parentNode}
                        id="transactionMedium"
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          this.props.handleChangeTransData(
                            "transactionMedium",
                            e
                          );
                        }}
                      >
                        <Option value="CHECK">Check</Option>
                        <Option value="CASH">Cash</Option>
                        <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                        <Option value="ACH">ACH</Option>
                        <Option value="CARD">Card</Option>
                        <Option value="LEDGER">Ledger</Option>
                        <Option value="OTHER_MEDIUM">Other medium</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="transType" style={style.columnHeading}>
                      Transaction Type
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <input disabled className="inputField" value="Withdrawal" />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-4">
                    <label htmlFor="vendor" style={style.columnHeading}>
                      Vendor
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ width: "100%" }}
                    >
                      <Select
                        value={transData.vendorId}
                        getPopupContainer={(node) => node.parentNode}
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          this.props.handleChangeTransData("vendorId", e);
                        }}
                      >
                        {withdrawalVendorList &&
                          withdrawalVendorList.map((vendor) => (
                            <Option value={vendor.vendorId}>
                              {vendor.vendorName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 select-input h40">
                    <label htmlFor="clientName" style={style.columnHeading}>
                      Description
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      className="drop"
                      dropdownStyle={{ maxHeight: "90px", overflowY: "scroll" }}
                      value={transData.description}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeTransData("description", e);
                      }}
                    >
                      {descriptionList &&
                        descriptionList.map((list) => (
                          <Option value={list.description}>
                            {list.description}
                          </Option>
                        ))}
                    </Select>
                    {errors.clientName.length > 0 && (
                      <span className="error">{errors.clientName}</span>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="note" style={style.columnHeading}>
                      Note
                    </label>
                    <input
                      className="inputField"
                      id="note"
                      name="note"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeTransData(
                          e.target.id,
                          e.target.value
                        );
                        Helpers.handleValidationOnChange(e, errors);
                      }}
                    />
                    {errors.note.length > 0 && (
                      <span className="error">{errors.note}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-4 mt-4"
                    hidden={transactionMedium !== "CHECK"}
                  >
                    <label htmlFor="note" style={style.columnHeading}>
                      Print Document
                    </label>
                    <div>
                      <DocumentImage documentURL={fileUrl} showDiv />
                    </div>
                    <label
                      style={{
                        width: "115px",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                        background: "rgba(204,204,254,0.32)",
                        border: "1px solid #7b7b7b",
                        textAlign: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          e.preventDefault();
                          onChangeDocumentFileHandler(e);
                        }}
                        className="hidden"
                        style={{ display: "none" }}
                      />
                      {scheduleLoading ? "Loading..." : "Select File"}
                    </label>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                    float: "right",
                    marginRight: "0px",
                  }}
                >
                  <div>
                    <ButtonComponent
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          isBlocking: false,
                        });
                        if (!this.state.clicked) {
                          this.setState({
                            clicked: true,
                          });
                          this.props.createWithdrawVendorTransaction(
                            transData,
                            this.props
                          );
                          setTimeout(() => {
                            this.setState({
                              clicked: false,
                            });
                          }, 3000);
                        }
                      }}
                      value={withdrawalVendorTransactionValue}
                      loading={loading}
                      buttonName="Create"
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/*MODAL TO CREATE TRANSACTION*/}
          <Dialog
            open={this.state.openModalToCreateTransaction}
            onClose={handleCloseDialogueToCreateTransaction}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleCloseDialogueToCreateTransaction}
              >
                {!this.state.isUpdate
                  ? "Create Transaction"
                  : "Update Transaction"}
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContentForForms">
                <div className="row mt-2">
                  {this.state.isUpdate ? (
                    <div className="col-lg-4 select-input h40">
                      <label htmlFor="clientName" style={style.columnHeading}>
                        Client Name
                      </label>
                      <input
                        type="name"
                        id="amount"
                        className="inputField"
                        disabled
                        value={
                          userInfoFromDB.loginResponse.loginUser.client
                            ? userInfoFromDB.loginResponse.loginUser.client
                                .firstName +
                              " " +
                              userInfoFromDB.loginResponse.loginUser.client
                                .lastName
                            : transData.clientName
                        }
                      />
                    </div>
                  ) : (
                    <div className="col-lg-4 select-input h40">
                      <label htmlFor="clientName" style={style.columnHeading}>
                        Client Name
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <Select
                        showSearch
                        name="clientId"
                        className="drop"
                        getPopupContainer={(node) => node.parentNode}
                        style={{ width: 200 }}
                        onSearch={(value) => {
                          this.props.searchClientList(value);
                        }}
                        onInputKeyDown={(e) => {
                          this.props.handleChangeTransData("bankDetailId", "");
                          this.props.fetchSingleClientSuccess();
                          this.setState({
                            showDropdown: true,
                            clearBankDetail: true,
                          });
                        }}
                        disabled={userType === "CLIENT_USER"}
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                            isFirstSelect: true,
                          });
                          this.props.fetchSingleClient(e, this.props);
                          this.props.fetchVendorClientById(e);
                          this.props.handleChangeTransData("clientId", e);
                        }}
                        onSelect={(e) => {
                          this.setState({
                            showDropdown: false,
                            isFirstOpen: false,
                            clearBankDetail: false,
                          });
                          this.props.getAmountDetailByClientId(e);
                          this.props.fetchSingleClient(e, this.props);
                        }}
                        onBlur={() => {
                          this.setState({
                            showDropdown: false,
                          });
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        defaultOpen={false}
                        dropdownStyle={
                          this.state.showDropdown
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        {clients &&
                          clients.map((res) =>
                            res.isEnableTransaction ? (
                              <>
                                <Option key={res.clientId} value={res.clientId}>
                                  {res.firstName +
                                    " " +
                                    res.lastName +
                                    " (" +
                                    res.clientNo +
                                    " )"}
                                </Option>
                              </>
                            ) : null
                          )}
                      </Select>
                      {errors.clientName.length > 0 && (
                        <span className="error">{errors.clientName}</span>
                      )}
                    </div>
                  )}
                  <div className="col-lg-4">
                    <label htmlFor="amount" style={style.columnHeading}>
                      Amount (Dollar)
                      <span style={{ color: "red" }}> *</span>{" "}
                    </label>
                    <div>
                      <input
                        type="number"
                        id="amount"
                        name="amount"
                        autoComplete="off"
                        className="inputField"
                        value={
                          transData.amount === 0 ? "" : transData.amount / 100
                        }
                        onKeyPress={(e) => {
                          naturalRound(e);
                        }}
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          this.props.handleChangeTransData(
                            e.target.id,
                            finalAmount(e)
                          );
                          Helpers.handleValidationOnChange(e, errors);
                        }}
                      />
                    </div>
                    {amountDetails && amountDetails.totalBalance ? (
                      <div className="mt-2">
                        <span style={{ color: "#8BC34A" }}>Total Balance</span>{" "}
                        :
                        <span style={{ color: "#676767" }}>
                          {" "}
                          {amountDetails
                            ? formatter.format(amountDetails.totalBalance / 100)
                            : ""}
                        </span>
                        <span>
                          <Tooltip
                            placement="bottom"
                            className="pop"
                            overlayInnerStyle={{ width: "215px" }}
                            title={
                              <div>
                                <div>
                                  <span>Posted Balance: </span>
                                  <span>
                                    {amountDetails.actualBalance
                                      ? formatter.format(
                                          amountDetails.actualBalance / 100
                                        )
                                      : "$0.00"}
                                  </span>
                                </div>
                                <div>
                                  <span>Submitted Balance: </span>
                                  <span>
                                    {amountDetails.availableBalance
                                      ? formatter.format(
                                          amountDetails.availableBalance / 100
                                        )
                                      : "$0.00"}
                                  </span>
                                </div>
                                {amountDetails.upcomingBalance ? (
                                  <div>
                                    <span>Upcoming Balance: </span>
                                    <span>
                                      {amountDetails.upcomingBalance
                                        ? formatter.format(
                                            amountDetails.upcomingBalance / 100
                                          )
                                        : "$0.00"}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            }
                            getPopupContainer={(node) => node.parentNode}
                          >
                            <AccountBalanceWalletIcon
                              style={{
                                marginLeft: "5px",
                                color: "#636363d6",
                                width: "19px",
                                marginTop: "-3px",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </span>
                      </div>
                    ) : null}
                    {errors.amount.length > 0 && (
                      <span className="error">{errors.amount}</span>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="transMedium" style={style.columnHeading}>
                      Transaction Medium{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <div
                      className="select-input selects h40"
                      style={{ width: "100%" }}
                    >
                      <Select
                        getPopupContainer={(node) => node.parentNode}
                        value={transData.transactionMedium}
                        id="transactionMedium"
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          if (!this.state.isUpdate) {
                            this.props.handleChangeTransData(
                              "transactionType",
                              ""
                            );
                            this.props.handleChangeTransData("vendorId", "");
                          }

                          this.props.handleChangeTransData(
                            "transactionMedium",
                            e
                          );
                        }}
                      >
                        <Option value="CHECK">Check</Option>
                        <Option value="CASH">Cash</Option>
                        <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                        <Option value="ACH">ACH</Option>
                        <Option value="CARD">Card</Option>
                        <Option value="LEDGER">Ledger</Option>
                        <Option value="OTHER_MEDIUM">Other medium</Option>
                      </Select>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    amountDetails && amountDetails.totalBalance
                      ? "row mt-2"
                      : "row mt-4"
                  }
                >
                  {this.state.isUpdate ? (
                    <div className="col-lg-4">
                      <label htmlFor="transType" style={style.columnHeading}>
                        Transaction Type
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        type="name"
                        id="amount"
                        className="inputField"
                        disabled
                        value={
                          transData.transactionType === "DISBURSEMENT"
                            ? "Disbursement"
                            : "Deposit"
                        }
                      />
                    </div>
                  ) : (
                    <div className="col-lg-4">
                      <label htmlFor="transType" style={style.columnHeading}>
                        Transaction Type
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <div
                        className="select-input selects h40"
                        style={{ width: "100%" }}
                      >
                        <Select
                          id="checkValueInfoLedger"
                          getPopupContainer={(node) => node.parentNode}
                          value={transData.transactionType}
                          onChange={(e) => {
                            this.props.handleChangeTransData("isLedger", false);
                            this.setState({
                              isBlocking: true,
                            });
                            if (
                              e === "DEPOSIT" ||
                              e === "FEE" ||
                              e === "REFUND"
                            ) {
                              this.props.handleChangeTransData(
                                "vendorId",
                                vendorData &&
                                  vendorData[0] &&
                                  vendorData[0].vendorId
                              );
                            } else {
                              this.props.handleChangeTransData("vendorId", " ");
                            }
                            this.props.handleChangeTransData(
                              "transactionType",
                              e
                            );
                          }}
                        >
                          <Option
                            value="DISBURSEMENT"
                            hidden={transactionMedium === "LEDGER"}
                          >
                            Disbursement
                          </Option>
                          <Option
                            value="DEPOSIT"
                            hidden={transactionMedium === "LEDGER"}
                          >
                            Deposit
                          </Option>
                          <Option
                            value="FEE"
                            hidden={transactionMedium !== "LEDGER"}
                          >
                            Fee
                          </Option>
                          <Option
                            value="REFUND"
                            hidden={transactionMedium !== "LEDGER"}
                          >
                            Refund
                          </Option>
                        </Select>
                      </div>
                    </div>
                  )}
                  {this.state.isUpdate ? (
                    <div className="col-lg-4 select-input h40">
                      <label htmlFor="clientName" style={style.columnHeading}>
                        Vendor
                      </label>
                      <input
                        type="name"
                        id="amount"
                        className="inputField"
                        disabled
                        value={transData.vendorName}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-4">
                        <label htmlFor="vendor" style={style.columnHeading}>
                          Vendor
                          <span style={{ color: "red" }}> *</span>
                        </label>
                        {transType === "DEPOSIT" ||
                        transType === "FEE" ||
                        transType === "REFUND" ? (
                          <input
                            className="inputField"
                            value={
                              vendorData && vendorData[0]
                                ? vendorData[0].vendorName
                                : clientVendor &&
                                  clientVendor[0] &&
                                  clientVendor[0].vendorAccount.vendorName
                            }
                            disabled
                          />
                        ) : (
                          <div
                            className="select-input selects h40"
                            style={{ width: "100%" }}
                          >
                            <Select
                              className="drop"
                              dropdownStyle={{
                                maxHeight: "100px",
                                overflowY: "scroll",
                              }}
                              value={transData.vendorId}
                              getPopupContainer={(node) => node.parentNode}
                              disabled={
                                transType === "DEPOSIT" ||
                                transType === "FEE" ||
                                transType === "REFUND"
                              }
                              onChange={(e) => {
                                this.setState({
                                  isBlocking: true,
                                });
                                this.props.handleChangeTransData("vendorId", e);
                              }}
                            >
                              {(transType === "DEPOSIT" ||
                                transType === "FEE" ||
                                transType === "REFUND") &&
                              vendorData &&
                              vendorData[0] ? (
                                <Option value={vendorData[0].vendorId}>
                                  {vendorData[0].vendorName}
                                </Option>
                              ) : (
                                clientVendor &&
                                clientVendor.map((vendor) => (
                                  <Option value={vendor.vendorAccount.vendorId}>
                                    {vendor.vendorAccount.vendorName}
                                  </Option>
                                ))
                              )}
                            </Select>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="col-lg-4" hidden>
                    <label htmlFor="vendor" style={style.columnHeading}>
                      Vendor
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    {clientVendor &&
                    clientVendor.length === 1 &&
                    !this.state.isFirstOpen ? (
                      <input
                        className="inputField"
                        value={
                          (transType === "DEPOSIT" ||
                            transType === "FEE" ||
                            transType === "REFUND") &&
                          vendorData &&
                          vendorData[0]
                            ? vendorData[0].vendorName
                            : clientVendor &&
                              clientVendor[0] &&
                              clientVendor[0].vendorAccount.vendorName
                        }
                        disabled
                      />
                    ) : !this.state.isFirstOpen ? (
                      <div
                        className="select-input selects h40"
                        style={{ width: "100%" }}
                      >
                        <Select
                          className="drop"
                          dropdownStyle={{
                            maxHeight: "100px",
                            overflowY: "scroll",
                          }}
                          value={transData.vendorId}
                          getPopupContainer={(node) => node.parentNode}
                          disabled={
                            transType === "DEPOSIT" ||
                            transType === "FEE" ||
                            transType === "REFUND"
                          }
                          onChange={(e) => {
                            this.setState({
                              isBlocking: true,
                            });
                            this.props.handleChangeTransData("vendorId", e);
                          }}
                        >
                          {(transType === "DEPOSIT" ||
                            transType === "FEE" ||
                            transType === "REFUND") &&
                          vendorData &&
                          vendorData[0] ? (
                            <Option value={vendorData[0].vendorId}>
                              {vendorData[0].vendorName}
                            </Option>
                          ) : (
                            clientVendor &&
                            clientVendor.map((vendor) => (
                              <Option value={vendor.vendorAccount.vendorId}>
                                {vendor.vendorAccount.vendorName}
                              </Option>
                            ))
                          )}
                        </Select>
                      </div>
                    ) : (
                      <input className="inputField" disabled />
                    )}
                  </div>

                  <div className="col-lg-4 select-input h40">
                    <label htmlFor="clientName" style={style.columnHeading}>
                      Description
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Select
                      // className="drop"
                      // dropdownStyle={{ maxHeight: "110px", overflowY: "scroll" }}
                      value={transData.description}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeTransData("description", e);
                      }}
                    >
                      {descriptionList &&
                        descriptionList.map((list) => (
                          <Option value={list.description}>
                            {list.description}
                          </Option>
                        ))}
                    </Select>
                    {errors.clientName.length > 0 && (
                      <span className="error">{errors.clientName}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-4 mt-3"
                    hidden={
                      transactionMedium === "CHECK" || transType === "DEPOSIT"
                    }
                  >
                    <label htmlFor="note" style={style.columnHeading}>
                      Note
                    </label>
                    <input
                      className="inputField"
                      id="note"
                      name="note"
                      type="text"
                      autoComplete="off"
                      value={transData.note}
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeTransData(
                          e.target.id,
                          e.target.value
                        );
                        Helpers.handleValidationOnChange(e, errors);
                      }}
                    />
                    {errors.note.length > 0 && (
                      <span className="error">{errors.note}</span>
                    )}
                  </div>
                </div>
                <div
                  className="row mt-4"
                  hidden={
                    !(transactionMedium === "CHECK" || transType === "DEPOSIT")
                  }
                >
                  <div
                    className="col-lg-4"
                    hidden={
                      !(
                        transactionMedium === "CHECK" ||
                        (transType === "DEPOSIT" &&
                          transactionMedium !== "CHECK")
                      )
                    }
                  >
                    <label htmlFor="note" style={style.columnHeading}>
                      Notes
                    </label>
                    <input
                      className="inputField"
                      id="note"
                      name="note"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({
                          isBlocking: true,
                        });
                        this.props.handleChangeTransData(
                          e.target.id,
                          e.target.value
                        );
                        Helpers.handleValidationOnChange(e, errors);
                      }}
                    />
                    {errors.note.length > 0 && (
                      <span className="error">{errors.note}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-4"
                    hidden={
                      !(transactionMedium === "ACH" && transType === "DEPOSIT")
                    }
                  >
                    <label htmlFor="note" style={style.columnHeading}>
                      Client Bank Account{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    {clientRes &&
                    clientRes.bankDetail &&
                    clientRes.bankDetail.length === 1 ? (
                      <input
                        className="inputField"
                        disabled
                        value={
                          !this.state.clearBankDetail
                            ? clientRes.bankDetail[0].accountName +
                              " (" +
                              clientRes.bankDetail[0].accountNumber +
                              ")"
                            : ""
                        }
                      />
                    ) : (
                      <div className="select-input selects h40">
                        <Select
                          showSearch={false}
                          getPopupContainer={(node) => node.parentNode}
                          value={
                            !this.state.isFirstSelect
                              ? transData.bankDetailId
                              : ""
                          }
                          onChange={(e) => {
                            this.setState({
                              isBlocking: true,
                              isFirstSelect: false,
                            });

                            this.props.handleChangeTransData("bankDetailId", e);
                          }}
                        >
                          {clientRes &&
                            clientRes.bankDetail &&
                            clientRes.bankDetail.map((detail) => (
                              <Option value={detail.bankDetailId}>
                                {detail.accountName +
                                  " " +
                                  " (" +
                                  detail.accountNumber +
                                  " )"}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    )}
                  </div>
                  <div
                    className="col-lg-4"
                    hidden={transactionMedium !== "CHECK"}
                  >
                    <label htmlFor="note" style={style.columnHeading}>
                      Print Document
                    </label>
                    <div>
                      <DocumentImage documentURL={fileUrl} showDiv />
                    </div>
                    <label
                      style={{
                        width: "115px",
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                        background: "rgba(204,204,254,0.32)",
                        border: "1px solid #7b7b7b",
                        textAlign: "center",
                        padding: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          e.preventDefault();
                          onChangeDocumentFileHandler(e);
                        }}
                        className="hidden"
                        style={{ display: "none" }}
                      />
                      {scheduleLoading ? "Loading..." : "Select File"}
                    </label>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                    float: "right",
                    marginRight: "0px",
                  }}
                >
                  {/* LEDGER checkbox  */}
                  {transData.transactionType === "DEPOSIT" ? (
                    <>
                      <div style={style.checkBoxStyle}>
                        <input
                          type="checkbox"
                          name="isLedger"
                          id="checkboxinfo"
                          checked={transData.isLedger}
                          onChange={(e) => this.handleChange(e)}
                        />
                        <label style={style.ledgerLabelStyle}>Ledger</label>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div>
                    <ButtonComponent
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          isBlocking: false,
                        });
                        if (!this.state.clicked) {
                          this.setState({
                            clicked: true,
                          });
                          if (
                            clientVendor &&
                            clientVendor.length === 1 &&
                            transType !== "DEPOSIT" &&
                            transType !== "FEE" &&
                            transType !== "REFUND"
                          ) {
                            transData.vendorId =
                              clientVendor &&
                              clientVendor[0] &&
                              clientVendor[0].vendorAccount.vendorId;
                          }
                          if (this.state.isUpdate) {
                            this.props.updateTransData(
                              transReqBody,
                              this.props
                            );
                          } else {
                            this.props.createTransData(
                              transReqBody,
                              this.props
                            );
                          }
                          setTimeout(() => {
                            this.setState({
                              clicked: false,
                            });
                          }, 3000);
                        }
                      }}
                      value={
                        transactionMedium === "ACH" && transType === "DEPOSIT"
                          ? transBankValue
                          : transValue
                      }
                      loading={loading}
                      buttonName={this.state.isUpdate ? "Update" : "Create"}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          <Dialog
            id="viewScheduleTransactionDetails"
            maxWidth="lg"
            open={this.state.openActivityLogModal}
            onClose={handleCloseActivityLogModal}
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleCloseActivityLogModal}
            >
              Changelog Info
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                width: "726px",
                maxHeight: "450px",
                paddingTop: "25px",
              }}
            >
              {activityLogValue && activityLogValue.length > 0 ? (
                activityLogValue.map((logValue) => (
                  <div className="changeLogOuterDiv">
                    <div>
                      <Avatar
                        className="changeLogAvatar"
                        src={
                          logValue.profilePic
                            ? logValue.profilePic
                            : "/assets/images/profile.svg"
                        }
                      />
                    </div>
                    <div style={{ marginLeft: "25px", fontSize: "16px" }}>
                      <span style={{ fontWeight: 600 }}>
                        {logValue.userName ? logValue.userName : "User"}
                      </span>{" "}
                      {logValue.activity === "transaction.updated"
                        ? "updated"
                        : logValue.activity === "transaction.added"
                        ? "added"
                        : "changed"}{" "}
                      <span style={{ fontWeight: 600 }}>
                        {logValue.activity === "transaction.note.updated"
                          ? "transaction note as"
                          : logValue.activity === "transaction.updated" ||
                            logValue.activity === "transaction.added"
                          ? "transaction"
                          : "transaction status to"}
                      </span>{" "}
                      <span>
                        {logValue.activity ===
                        "transaction.status.updated.to APPROVED"
                          ? `'APPROVED'`
                          : logValue.activity ===
                            "transaction.status.updated.to REVERSED"
                          ? "REVERSED"
                          : logValue.refValue &&
                            logValue.refValue.charAt(0) === "{"
                          ? ""
                          : `"${logValue.refValue}"`}{" "}
                        {/* {logValue.activity ===
                        "transaction.status.updated.to APPROVED"
                          ? `from "${logValue.refValue}"`
                          : ""} */}
                      </span>
                      {logValue.refValue &&
                        logValue.refValue.charAt(0) === "{" &&
                        logValue.activity === "transaction.updated" && (
                          <>
                            <br />
                            <ChangedLogPopover
                              refValue={logValue.refValue}
                              formatter={formatter}
                            />
                          </>
                        )}
                      {/* {logValue.activity === "transaction.updated" ? (
                        <span>"{logValue.refValue}"</span>
                      ) : (
                        <ChangedLogPopover
                          refValue={logValue.refValue}
                          formatter={formatter}
                        />
                      )} */}
                      <div style={{ fontSize: "14px" }}>
                        {new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(logValue.createdAt || "")}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="emptyResponse"
                  style={{ marginTop: "20px", marginBottom: "25px" }}
                >
                  <div>
                    <img src="/assets/images/activityLog1.svg" alt="" />
                  </div>
                  <div>No changes till date !</div>
                </div>
              )}
            </DialogContent>
          </Dialog>

          {/* STATUS UPDATE */}
          <Dialog
            open={this.state.openModalToDeclineTransaction}
            onClose={handleCloseToDeclineTransaction}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleCloseToDeclineTransaction}
              >
                Update Transaction Status
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div>
                  <label className="label">
                    Remark <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    id="remark"
                    className="input"
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeRemark(e.target.value);
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label className="label">New Date</label>
                    <div>
                      <KeyBoardDatePickerComponent
                        dateTimeFormat
                        isCalendarDate
                        dateValue={
                          reversedDate &&
                          getDateWithStartTimeValue(reversedDate)
                        }
                        onChangeDate={(date) => {
                          this.props.handlChangeReverseDate(date);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                  }}
                >
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                    <ButtonComponent
                      value={declineStatus}
                      buttonName="Update"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseToDeclineTransaction();
                        handleUpdateReverseStatus();
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/*modal to save in config*/}
          <Dialog
            open={this.state.OpenSaveFilterInConfigmenu}
            onClose={handleChangeCloseConfigModal}
            PaperComponent={PaperComponent}
            aria-labelledby="#draggable-dialog-title"
            maxWidth="xl"
          >
            <div>
              <DialogTitleComponent
                id="draggable-dialog-title"
                onClose={handleChangeCloseConfigModal}
              >
                Save Filter
              </DialogTitleComponent>
              <DialogContent dividers className="dialogContent">
                <div>
                  <label className="label">
                    Name <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    id="remark"
                    className="input"
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({
                        configName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  className="row"
                  style={{
                    padding: "1.5rem 0px 0.5rem",
                  }}
                >
                  <div className={"col-6"} />
                  <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                    <ButtonComponent
                      value={
                        this.state.isUpcomingFilter
                          ? configStatusUP
                          : configStatusTrans
                      }
                      buttonName="Save"
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeCloseConfigModal();
                        handleFilterSaveConfig();
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
            </div>
          </Dialog>

          {/*view config modal */}
          <Dialog
            maxWidth="lg"
            open={this.state.OpenViewFilterInConfig}
            onClose={handleChangeCloseViewConfigModal}
          >
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseViewConfigModal}
            >
              Saved Configs
            </DialogTitleComponent>
            <DialogContent
              dividers
              style={{
                width: "326px",
                height: "250px",
                paddingTop: "25px",
              }}
            >
              {configlist && configlist.length > 0 ? (
                configlist.map((config) => (
                  <div
                    className="changeConfigOuterDiv"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        handleChangeCloseViewConfigModal();
                        handleUseConfigFilter(config.configFilters);
                      }}
                    >
                      {config.configName}
                    </span>
                  </div>
                ))
              ) : (
                <div
                  className="emptyResponse"
                  style={{ marginTop: "20px", marginBottom: "25px" }}
                >
                  <div>No saved config !</div>
                </div>
              )}
            </DialogContent>
          </Dialog>

          {/* file upload error message */}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openScheduleTransactionSnackBar}
            autoHideDuration={4000}
            onClose={this.props.closeScheduleTransactionSnackBar}
          >
            <MySnackbarContentWrapper
              onClose={this.props.closeScheduleTransactionSnackBar}
              variant="error"
              message={errorMsg}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.transactionState.openTransactionErrorSnackbar}
            autoHideDuration={4000}
            onClose={this.props.handleTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleTransactionSnackBarClose}
              variant="error"
              message={this.props.transactionState.errorMsg}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.transactionState.openTransactionSuccessSnackbar}
            autoHideDuration={4000}
            onClose={this.props.handleTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleTransactionSnackBarClose}
              variant="success"
              message={this.props.transactionState.successMsg}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.state.openSnackBar}
            autoHideDuration={4000}
            onClose={() => {
              this.setState({
                openSnackBar: false,
              });
            }}
          >
            <MySnackbarContentWrapper
              onClose={() => {
                this.setState({
                  openSnackBar: false,
                });
              }}
              variant="error"
              message="Invalid file type"
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.transactionState.openModalCreateTransactionError}
            autoHideDuration={4000}
            onClose={this.props.handleTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleTransactionSnackBarClose}
              variant="error"
              message={this.props.transactionState.errorMsg}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.transactionState.openModalCreateTransactionSuccess}
            autoHideDuration={4000}
            onClose={this.props.handleTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleTransactionSnackBarClose}
              variant="success"
              message={this.props.transactionState.successMsg}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.upcomingTransactionState.openErrorProcessSnackBar}
            autoHideDuration={4000}
            onClose={this.props.handleChangeUpcomingTransSnackBar}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleChangeUpcomingTransSnackBar}
              variant="error"
              message={this.props.upcomingTransactionState.error}
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.upcomingTransactionState.openProcessSnackBar}
            autoHideDuration={4000}
            onClose={this.props.handleUpcomingTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleUpcomingTransactionSnackBarClose}
              variant="success"
              message="Successfully proceed"
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.transactionState.openUpdateSuccessSnackBar}
            autoHideDuration={4000}
            onClose={this.props.handleTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleTransactionSnackBarClose}
              variant="success"
              message="Successfully Updated"
            />
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={this.props.transactionState.openUpdateErrorSnackBar}
            autoHideDuration={4000}
            onClose={this.props.handleTransactionSnackBarClose}
          >
            <MySnackbarContentWrapper
              onClose={this.props.handleTransactionSnackBarClose}
              variant="error"
              message={this.props.transactionState.error}
            />
          </Snackbar>
        </div>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    marginLeft: "0%",
    marginRight: "0%",
    marginBottom: "0%",
    marginTop: "-16px",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "23%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    marginTop: "20px",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "200px",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
  card: {
    background: "#ffffff",
    // outline:'1px solid #E9E9E9',
    border: "1px solid #e0e0e0",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: "2px",
    marginRight: 0,
    marginBottom: "1rem",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  icon: {
    position: "absolute",
    top: "64px",
    right: "30px",
    color: " rgb(200, 197, 197)",
    fontSize: "32px",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableHeaderAmount: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "right",
    height: "3rem",
    top: 0,
    position: "inherit",
    paddingRight: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  tableBodyAmount: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "right",
    height: "4.5rem",
    paddingRight: "48px",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    outline: "none",
    overflow: "auto",
    marginBottom: "5px",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  clientExportButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "150px",
    height: "35px",
    marginRight: "15px",
  },
  exportButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "100px",
    height: "35px",
    // marginRight: "12px",
  },
  postTransButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "165px",
    height: "35px",
    marginRight: "12px",
  },
  searchButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "106px",
    height: "35px",
    marginRight: "12px",
  },
  filterButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "190px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  processNow: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "40%",
    marginRight: "12px",
  },
  skipNow: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "20%",
    marginRight: "12px",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
    height: "40px",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "180px",
    height: "40px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginRight: '2rem !important',
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  checkBoxStyle: {
    position: "absolute",
    top: "87%",
    right: "89%",
  },
  ledgerLabelStyle: {
    color: "rgb(98, 95, 95)",
    fontSize: "16px",
    padding: "5px",
  },
  editDesc: {
    // marginLeft: "124px",
    // top: "-22px",
    // position: "relative"
    marginLeft: "118px",
    top: "-10px",
    position: "relative",
    padding: "20px",
  },
  // flex: {
  //   paddingBottom: "1rem",
  //   paddingTop: "1rem",
  //   paddingLeft: "1rem",
  //   position: "fixed",
  //   width: "100%",
  //   background: "white",
  //   zIndex: 1,
  //   // right: "2.5%",
  // },
};

export default TransactionScreenScreen;
