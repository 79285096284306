export const FilterStatusData = [
  {
    id: 1,
    name: "Created",
  },
  {
    id: 2,
    name: "Approved",
  },
  {
    id: 3,
    name: "Submitted",
  },
  {
    id: 4,
    name: "Posted",
  },
  {
    id: 5,
    name: "Reversed",
  },
  {
    id: 6,
    name: "Skipped",
  },
  {
    id: 7,
    name: "On hold",
  },
  {
    id: 8,
    name: "Blocked",
  },
  {
    id: 9,
    name: "Returned",
  },
  {
    id: 10,
    name: "Canceled",
  },
  {
    id: 11,
    name: "Closed",
  },
];

export const FilterMediumData = [
  {
    id: 1,
    name: "Check",
  },
  {
    id: 2,
    name: "Cash",
  },
  {
    id: 3,
    name: "Wire Transfer",
  },
  {
    id: 4,
    name: "Ach",
  },
  {
    id: 5,
    name: "Card",
  },
  {
    id: 6,
    name: "Ledger",
  },
  {
    id: 7,
    name: "Other Medium",
  },
];

export const FilterTypeData = [
  {
    id: 1,
    name: "Disbursement",
  },
  {
    id: 2,
    name: "Deposit",
  },
  {
    id: 3,
    name: "Withdrawal",
  },
];

export const FilterClientSchStatusData = [
  {
    id: 1,
    name: "Pending",
  },
  {
    id: 2,
    name: "Approved",
  },
  {
    id: 3,
    name: "Scheduled",
  },
  {
    id: 4,
    name: "Closed",
  },
  {
    id: 5,
    name: "Disable",
  },
  {
    id: 6,
    name: "Cancelled",
  },
];

export const FilterTypeDataForLedger = [
  {
    id: 1,
    name: "Fee",
  },
  {
    id: 2,
    name: "Refund",
  },
  {
    id: 3,
    name: "Annual Fee",
  },
  {
    id: 4,
    name: "Fee Returned",
  },
];

export const FilterUPTypeData = [
  {
    id: 1,
    name: "Disbursement",
  },
  {
    id: 2,
    name: "Deposit",
  },
];

export const allTypeArray = [
  {
    id: 1,
    name: "Fee",
    val: "FEE",
  },
  {
    id: 2,
    name: "Refund",
    val: "REFUND",
  },
  {
    id: 3,
    name: "Annual Fee",
    val: "ANNUAL_FEE",
  },
  {
    id: 4,
    name: "Fee Returned",
    val: "FEE_RETURNED",
  },
  {
    id: 5,
    name: "Withdrawal",
    val: "WITHDRAWAL",
  },
  {
    id: 6,
    name: "Disbursement",
    val: "DISBURSEMENT",
  },
  {
    id: 7,
    name: "Deposit",
    val: "DEPOSIT",
  },
];

export const FilterLEDTypeData = [
  {
    id: 1,
    name: "Fee",
    val: "FEE",
  },
  {
    id: 2,
    name: "Refund",
    val: "REFUND",
  },
  {
    id: 3,
    name: "Annual Fee",
    val: "ANNUAL_FEE",
  },
  {
    id: 4,
    name: "Fee Returned",
    val: "FEE_RETURNED",
  },
  {
    id: 5,
    name: "Withdrawal",
    val: "WITHDRAWAL",
  },
];

export const FilterScheduleTimeData = [
  {
    id: 1,
    name: "One Time",
  },
  {
    id: 2,
    name: "Daily",
  },
  {
    id: 3,
    name: "Weekly",
  },
  {
    id: 4,
    name: "Monthly",
  },
  {
    id: 5,
    name: "Quarterly",
  },
  {
    id: 6,
    name: "Yearly",
  },
  {
    id: 7,
    name: "nth Day",
  },
];
