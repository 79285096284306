import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Button,
    CircularProgress,
    MenuItem,

    MenuList, Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TablePagination from "@material-ui/core/TablePagination";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import ButtonComponent from "./ButtonComponent";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Tag } from "antd";

class CompanyTypeScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            description: {},
            defaultId: '',
            clicked: false,
        }
    }
    componentDidMount() {
        this.props.fetchDescriptionList();
    }

    render() {
        const settingState = this.props.settingState;
        const descriptionData = settingState && settingState.descriptionData;
        const descriptionList = settingState && settingState.descriptionList;
        const value = descriptionData && descriptionData.description !== '';
        const loading = this.props.loading;
        return(
            <React.Fragment>
                { loading ? (
                    <div
                        className={
                           loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <div>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.clearDescriptionModal();
                                    this.props.handleOpenModalToAddDescription()
                                }}>
                                <AddIcon/> Add Description
                            </button>
                        </div>
                        <div className="search" hidden>
                            <div className="searchIcon">
                                <SearchIcon style={{color: '#9c9c9c'}}/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                className="inputBase"
                                onChange={(e)=>{
                                    e.preventDefault();
                                    if(e.target.value === "" || e.target.value === " " ){
                                        this.props.fetchDescriptionList();
                                    }else{
                                        this.props.fetchDescriptionList(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header-second">
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Description
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {descriptionList && descriptionList.slice(
                                        this.props.page * this.props.rowsPerPage,
                                        this.props.page * this.props.rowsPerPage +
                                        this.props.rowsPerPage
                                    ).map(list => (
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="first-tableBody">
                                                {list.description}
                                                {list &&
                                                    list.isDefault ? (
                                                    <Tag
                                                      color="blue"
                                                      style={{
                                                        marginLeft: "12px",
                                                      }}
                                                    >
                                                      Default
                                                    </Tag>
                                                  ) : (
                                                    ""
                                                  )}
                                            </TableCell>
                                            <TableCell>
                                                <MoreHorizIcon
                                                    id="Popover1"
                                                    style={{cursor: 'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            popoverElement: e.currentTarget,
                                                            openActionMenu: true,
                                                            description: list,
                                                            defaultId: list.descriptionId,
                                                        })
                                                    }}
                                                />
                                                <Popover
                                                    open={this.state.openActionMenu}
                                                    anchorEl={this.state.popoverElement}
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        this.setState({openActionMenu: false})
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    style={{boxShadow: 'none'}}
                                                >
                                                    <MenuList id="popover-list" style={{
                                                        color: '#4d4d4d',
                                                        padding: '1rem !important',
                                                        cursor: 'pointer'
                                                    }}>
                                                        <MenuItem
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    this.props.setDefaultDescription(this.state.defaultId);
                                                                    this.setState({openActionMenu: false,}
                                                                    );
                                                                }}
                                                        >
                                                        Set Default
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.handleOpenModalToUpdateDescription();
                                                                this.props.setDescriptionToEdit(this.state.description)
                                                                this.setState({openActionMenu: false,}
                                                                );
                                                            }}>
                                                            <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.handleOpenModalToDeleteDescription();
                                                                this.props.setDescriptionToEdit(this.state.description)
                                                                this.setState({openActionMenu: false,}
                                                                );
                                                            }}>
                                                            <DeleteOutlinedIcon style={{paddingBottom: 0}}/> Delete
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                            </TableContainer>
                            {descriptionList && descriptionList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            descriptionList.length > 0
                                                ? descriptionList.length
                                                : 100
                                        }
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={this.props.handleChangePageDescription}
                                        onChangeRowsPerPage={this.props.handleChangeRowsPerPageDescription}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>
                {/*add description type*/}
                <Dialog
                    open={this.props.openModalToAddDescription}
                    onClose={this.props.handleCloseModalToAddDescription}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleCloseModalToAddDescription}>
                            Add Description
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Description</label>
                                    <input
                                        type='text'
                                        id="description"
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.props.handleChangeDescription("description", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        onClick={e => {
                                            e.preventDefault();
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                const descriptionReqBody = {
                                                    description: descriptionData.description,
                                                }
                                                this.props.addDescription(descriptionReqBody);
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        value={value}
                                        buttonName="Add"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*update description type*/}
                <Dialog
                    open={this.props.openModalToUpdateDescription}
                    onClose={this.props.handleCloseModalToUpdateDescription}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleCloseModalToUpdateDescription}>
                            Update Description
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Description</label>
                                    <input
                                        type='text'
                                        id="description"
                                        className="input"
                                        autoComplete='off'
                                        value={descriptionData.description}
                                        onChange={(e) => {
                                            this.props.handleChangeDescription("description", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        onClick={e => {
                                            e.preventDefault();
                                            const descriptionReqBody = {
                                                descriptionId: descriptionData.descriptionId,
                                                description: descriptionData.description,
                                            }
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.updateDescription(descriptionReqBody);
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        value={value}
                                        buttonName="Update"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*Delete description*/}
                <Dialog
                    open={this.props.openModalToDeleteDescription}
                    onClose={this.props.handleCloseModalToDeleteDescription}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title"  style={{cursor: "move"}}>
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation</h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px'
                        }}>
                        <DialogContentText>
                            Do you want to delete this description?

                            <span className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '0.5rem',
                                paddingTop: '1.6rem'
                            }}>
                                <span className={"col-6"}>
                                    <Button autoFocus
                                            onClick={this.props.handleCloseModalToDeleteDescription}
                                            className="cancelButton">
                                        cancel
                                    </Button>
                                </span>

                                <span className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleCloseModalToDeleteDescription();
                                        this.props.deleteDescription(this.state.description.descriptionId);
                                    }}
                                    className="deleteOkButton">
                                        ok
                                    </Button>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
};
const style = {
    card: {
        background: "#ffffff",
        border: "1px solid rgb(224, 224, 224)",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginLeft: 0,
        marginBottom: "1rem",
    },
}

export default CompanyTypeScreen;