import APIEndPoints from '../constants/APIConstants';
import API from "./API";
class CalendarApi extends API {
    fetchDataListForCalendar = callback => {
        fetch(APIEndPoints.FETCH_CALENDAR_LIST, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    fetchDataListForCalendarById = (id , callback) => {
        fetch(APIEndPoints.FETCH_CALENDAR_LIST_BY_ID + id, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    searchCalendar = (value , callback) => {
        fetch(APIEndPoints.SEARCH_CALENDAR_DATA + value, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    searchCalendarByAgentId = (value, agentId , callback) => {
        fetch(APIEndPoints.SEARCH_CALENDAR_DATA_BY_AGENT_ID + agentId + "?term=" + value, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
    searchCalendarByClientId = (value, clientId , callback) => {
        fetch(APIEndPoints.SEARCH_CALENDAR_DATA_BY_AGENT_ID + clientId + "?term=" + value, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        });
    };
}
export default new CalendarApi();
