import { connect } from "react-redux";
import VendorScreen from "../../screens/VendorScreen";
import {
  fetchVendorFailure,
  fetchVendorSuccess,
  vendorRequestMade,
  searchVendorSuccess,
  searchVendorFailure,
  scheduleTransactionRequestMade,
  addNewVendorFailure,
  deleteVendorFailure,
  deleteVendorSuccess,
  handleChangeVendor,
  addNewVendorSuccess,
  closeVendorSnackBar,
} from "../../actions";
import { SettingsAPI, VendorAPI } from "../../api";
import {
  clearVendorInputData,
  handleChangeAddressAddress,
  handleChangeAddressData,
  setVendorToEdit,
  updateVendorFailure,
  updateVendorSuccess,
  filterDataArray,
} from "../../actions/VendorAction";
import {
  fetchVendorTypeFailure,
  fetchVendorTypeSuccess,
  requestInCalendarSetting,
} from "../../actions/SettingsAction";
const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    searchVendor: (value) => {
      VendorAPI.searchVendor(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchVendorFailure(error.msg));
          } else {
            dispatch(searchVendorFailure(error));
          }
        } else {
          dispatch(searchVendorSuccess(response));
          console.log("response", response);
        }
      });
    },
    getVendorInfo: (vendorId) => {
      console.log("heyhey", vendorId);
      dispatch(scheduleTransactionRequestMade(true));
      VendorAPI.fetchSingleVendor(vendorId, (response, error) => {
        if (response) {
          dispatch(setVendorToEdit(response));
        } else {
          if (error.msg) {
            dispatch(addNewVendorFailure(error.msg));
          } else {
            dispatch(addNewVendorFailure(error));
          }
        }
      });
    },
    setVendorToEdit: (vendorList) => {
      dispatch(setVendorToEdit(vendorList));
    },
    deleteVendor: (vendorId, props) => {
      dispatch(scheduleTransactionRequestMade(true));
      VendorAPI.deleteVendor(vendorId, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(deleteVendorFailure(error.msg));
          } else {
            dispatch(deleteVendorFailure(error));
          }
        } else {
          dispatch(deleteVendorSuccess(vendorId));
          props.fetchVendor();
        }
      });
    },

    handleChangeVendor: (id, value, label) => {
      // console.log("id handleChangeVendor", id, "value handleChangeVendor", value);
      dispatch(handleChangeVendor(id, value, label));
    },

    addNewVendor: (vendorData, vaendorData, props) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.addNewVendor(vaendorData, (response, error) => {
        if (response) {
          dispatch(addNewVendorSuccess(true));
          props.fetchVendor();
        } else {
          if (error.msg) {
            dispatch(addNewVendorFailure(error.msg));
          } else {
            dispatch(addNewVendorFailure(error));
          }
        }
      });
    },
    editVendor: (vendorList, props) => {
      dispatch(vendorRequestMade(true));
      VendorAPI.editVendor(vendorList, (response, error) => {
        if (response) {
          dispatch(updateVendorSuccess(true));
          props.fetchVendor();
        } else {
          if (error.msg) {
            dispatch(updateVendorFailure(error.msg));
          } else {
            dispatch(updateVendorFailure(error));
          }
        }
      });
    },
    handleSnackBarClose: () => dispatch(closeVendorSnackBar(true)),
    setAddNewVendorSuccessToFalse: () => dispatch(addNewVendorSuccess(false)),
    setUpdateNewVendorSuccessToFalse: () =>
      dispatch(updateVendorSuccess(false)),
    clearVendorInputData: () => {
      dispatch(clearVendorInputData());
    },
    filterDataArray: () => {
      dispatch(filterDataArray());
    },
    handleChangeAddressData: (id, value) => {
      dispatch(handleChangeAddressData(id, value));
    },
    handleChangeAddressAddress: () => {
      dispatch(handleChangeAddressAddress());
    },
    // get vendor type
    fetchVendorTypeList: (searchTerm) => {
      dispatch(requestInCalendarSetting(true));
      SettingsAPI.fetchVendorType(searchTerm, (response, error) => {
        if (response) {
          dispatch(fetchVendorTypeSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorTypeFailure(error.msg));
          } else {
            dispatch(fetchVendorTypeFailure(error));
          }
        }
      });
    },
  };
};

export const VendorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorScreen);
