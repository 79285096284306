import { connect } from 'react-redux';
import ClientPaymentSettingScreen from "../../screens/ClientPaymentSettingScreen";
import {handleChangeTerm} from "../../actions/TransactionAction";
import {
    addCompanyFailure,
    addCompanySuccess,
    contactRequestMade,
    fetchCompanyFailure,
    fetchCompanySuccess
} from "../../actions/ContactAction";
import {ClientAPI, ContactApi} from "../../api";
import {
    addPaymentDetailFailure,
    addPaymentDetailSuccess,
    clientRequestMade,
    fetchClientPaymentDetailFailure,
    fetchClientPaymentDetailSuccess,
    handleChangeClientAchDelayThreshold,
    handleChangeClientAnnualFee,
    handleChangeClientFee,
    handleChangeClientInstallment, handleChangeClientMonthlyFee,
    handleChangeClientSpendDown
} from "../../actions/ClientAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return{
        handleChangeClientFee: (fee) => {dispatch(handleChangeClientFee(fee))},
        handleChangeClientMonthlyFeeValue: (monthlyFee) =>
        {dispatch(handleChangeClientMonthlyFee(monthlyFee))},
        handleChangeClientAnnualFee: (annualFee) =>
        {dispatch(handleChangeClientAnnualFee(annualFee))},
        handleChangeClientSpendDown: (spendDownThreshold) =>
        {dispatch(handleChangeClientSpendDown(spendDownThreshold))},
        handleChangeClientAchDelayThreshold: (achDelayThreshold) =>
        {dispatch(handleChangeClientAchDelayThreshold(achDelayThreshold))},
        handleChangeClientInstallment: (installmentMonth) =>
        {dispatch(handleChangeClientInstallment(installmentMonth))},

        fetchClientPayment: (id, props) =>{
            dispatch(clientRequestMade(true));
            ClientAPI.fetchClientPayment(id,(response, error) => {
                if (response) {
                    dispatch(fetchClientPaymentDetailSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchClientPaymentDetailFailure(error.msg))
                    } else {
                        dispatch(fetchClientPaymentDetailFailure(error));
                    }
                }
            })
        },
        addClientPayment: (paymentData, props) => {
            dispatch(clientRequestMade(true));
            ClientAPI.addClientPayment(paymentData, (response, error) => {
                console.log(response)
                if (response) {
                    dispatch(addPaymentDetailSuccess(response));
                    // props.history.push({pathname: '/client'})
                } else {
                    if (error.msg) {
                        dispatch(addPaymentDetailFailure(error.msg));
                    } else {
                        dispatch(addPaymentDetailFailure(error));
                    }
                }
            });
        },
        
    }
};

export const ClientPaymentSettingContainer = connect(mapStateToProps, mapDispatchToProps)(ClientPaymentSettingScreen);