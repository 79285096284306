import React from 'react';
import  {useEffect } from 'react';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../App.css';
import HomeIcon from '@material-ui/icons/Home';
import { ClientInfoContainer } from "../components/container/registerCaseContainer/ClientInfoContainer";
import { CaseContainer } from "../components/container/registerCaseContainer/CaseContainer";
import {PaymentContainer} from "../components/container/registerCaseContainer/PaymentContainer";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#8bc83f',
            backgroundColor: "#fff !important"
        },
    },
    completed: {
        '& $line': {
            borderColor: '#8bc83f',
        },
    },
    line: {
        height: 3,
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    button: {

        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    // return ['Client info', 'Select Case', 'Payment'];
    return ["", ""]
}

export default function RegisterCaseScreen(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const pushToCase = () => {
        props.history.replace('/case')
    };

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        // Specify how to clean up after this effect:
        return function cleanup() {
          console.log("here")
        };
      });
    
     
    
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <ClientInfoContainer handleNext={handleNext} />;
            case 1:
                return <CaseContainer
                    pushToCase={pushToCase}
                    handleNext={handleNext}
                />;
            case 2:
                return <PaymentContainer />;
            default:
                return 'Unknown step';

        }
    };

    const style = {
        breadcrumb: {
            fontSize: '14px',
            borderBottom: '1px solid #dddddd',
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '2.5%',
            color: '#8BC83F',
            fontWeight: 'bold'
        },
        link: {
            color: '#8BC83F'
        },
        stepper: {
            marginTop: '2%',
            marginLeft: '16%',
            marginRight: '16%',
            marginBottom: '1%',
            backgroundColor: 'rgb(255,255,255)'
        },
        card: {
            marginLeft: '13%',
            marginRight: '13%'
        },
    };


    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                <Link color="inherit" to="/dashboard" style={style.link}>
                    <HomeIcon style={{ color: '#8BC83F', paddingBottom: '2px' }} />Home
                </Link>
                <Link color="inherit" to="/case" style={style.link}>
                    Case
                </Link>
                <Typography color="inherit" className="link">
                    Register Case
                </Typography>
            </Breadcrumbs>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} style={style.stepper}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel icon={index + 1}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography component={'div'} className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                ) : (
                        <div style={style.card}>
                            <Typography component={'div'}
                                className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div>
                                {activeStep === 0 ? null :
                                    <img src={"assets/images/back arrow.svg"}
                                         onClick={handleBack}
                                        alt=""
                                        style={{
                                            width: '38px', height: '38px',
                                            position: 'relative', bottom: '4.5rem', left: '5.5%',
                                            cursor:'pointer'
                                        }} />}
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}