import { ACTION_TYPE_USER } from "../constants/Type";

export const userRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_USER.USER_REQUEST_MADE,
        bool
    };
};

export const fetchUserSuccess = user => {
    return {
        type: ACTION_TYPE_USER.USER_FETCH_SUCCESS,
        user
    };
};

export const fetchUserFailure = error => {
    return {
        type: ACTION_TYPE_USER.USER_FETCH_FAILURE,
        error
    };
};

export const closeSnackBarForUser = (bool) => {
    return {
        type: ACTION_TYPE_USER.CLOSE_SNACK_BAR_USER_PAGE,
        bool
    };
};

export const handleChangeUser = (id, value) => {
    return {
        type: ACTION_TYPE_USER.HANDLE_CHANGE_USER,
        id,
        value
    };
};

export const handleChangePassword = (id, value) => {
    return {
        type: ACTION_TYPE_USER.HANDLE_CHANGE_FOR_RESET_PASSWORD,
        id,
        value
    };
};

export const handleChangeForUserImage = (image, id, value) => {
    return {
        type: ACTION_TYPE_USER.HANDLE_CHANGE_FOR_USER_IMAGE,
        id,
        value
    }
};

export const addUserSuccess = bool => {
    return {
        type: ACTION_TYPE_USER.ADD_USER_SUCCESS,
        bool
    };
};

export const addUserFailure = error => {
    return {
        type: ACTION_TYPE_USER.ADD_USER_FAILURE,
        error
    };
};

export const updateUserRoleSuccess = userData => {
    return {
        type: ACTION_TYPE_USER.UPDATE_USER_ROLE_SUCCESS,
        userData
    };
};

export const updateUserRoleFailure = (error) => {
    return {
        type: ACTION_TYPE_USER.UPDATE_USER_ROLE_FAILURE,
        error
    }
};

export const deleteUserSuccess = (userId) => {
    return {
        type: ACTION_TYPE_USER.DELETE_USER_SUCCESS,
        userId
    }
};

export const deleteUserFailure = (error) => {
    return {
        type: ACTION_TYPE_USER.DELETE_USER_FAILURE,
        error
    }
};

export const resetPasswordSuccess = bool => {
    return {
        type: ACTION_TYPE_USER.RESET_PASSWORD_SUCCESS,
        bool
    };
};

export const resetPasswordFailure = error => {
    return {
        type: ACTION_TYPE_USER.RESET_PASSWORD_FAILURE,
        error
    };
};

export const setUserRoleTabToEdit = (dataToDisplay) => {
    return {
        type: ACTION_TYPE_USER.SET_FILTERED_USER_ROLE,
        dataToDisplay
    }
};

export const setUserRoleToEmpty = () => {
    return {
        type: ACTION_TYPE_USER.SET_USER_DATA_TO_EMPTY,
    }
}
export const setUserDataToDisplayEmpty= (data) => {
    return {
        type: ACTION_TYPE_USER.SET_DATA_TO_DISPLAY_EMPTY,
        data,
    }
}
