import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import HomeIcon from "@material-ui/icons/Home";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { Prompt } from "react-router";
import Typography from "@material-ui/core/Typography";
import { HelmetComponent } from "../components/Helmet";

class WorkflowEditFormScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdownForAction: false,
      searchedAction: [],
      action: [],
      setValue: false,
      isBlocking: false,
    };
    if (props.location.pathname.replace("/workflow/edit/", "").length > 0) {
      this.props.getWorkflowInfo(
        props.location.pathname.replace("/workflow/edit/", "")
      );
    }
  }

  componentDidMount() {
    this.props.fetchActionList();
  }

  componentWillUnmount() {
    this.props.handleSnackBarClose();
    if (this.props.state.workflowFormState.workflowData.workflowName !== "") {
      this.props.setWorkflowData({
        workflowName: "",
      });
    }
  }

  render() {
    const workflowFormState = this.props.state.workflowFormState;
    const createAddFormExistingActions = () => {
      var actions = [];
      var toFilterFrom = workflowFormState.workflowData.action;
      workflowFormState.workflowData.action &&
        workflowFormState.workflowData.action.map((single) => {
          var alreadyChoosen = this.state.searchedAction.filter(
            (singleSearchedAction) =>
              singleSearchedAction.actionId == single.actionId
          )[0];
          var filtered = this.state.searchedAction.filter(
            (singleSearchedAction) =>
              !toFilterFrom.find(
                (singlePreSelectedAction) =>
                  singlePreSelectedAction.actionId ===
                  singleSearchedAction.actionId
              )
          );
          filtered.push(alreadyChoosen ? alreadyChoosen : "");

          let formData = {
            id: single.actionId,
            options: filtered,
            value: {
              actionId: single.actionId,
              order: single.order,
            },
          };
          actions.push(formData ? formData : "");
        });
      if (this.state.action.length != actions.length) {
        this.setState({
          action: actions,
          setValue: true,
        });
      }
    };

    const createAddFormObject = () => {
      let formData = {
        id: String(Math.random()),
        options: this.state.searchedAction.filter(
          (s) => !this.state.action.find((a) => s.value === a.value.actionId)
        ),
        value: {
          actionId: "",
          order: this.state.action.length + 1,
        },
      };
      return formData;
    };

    const showInputForField = () => {
      let a = createAddFormObject();
      this.setState({
        action: [...this.state.action, a],
      });
    };

    const changeActionNameValue = (key, value) => {
      var perviousValue = this.state.action.filter(
        (single) => single.id == key
      )[0].value.actionId;

      this.state.action.filter((single) => single.id == key)[0].value.actionId =
        value;

      var rest = this.state.action.filter((single) => single.id != key);

      rest.map((single) => {
        single.options = single.options.filter((s) => s.value != value);
      });

      if (rest.length > 0 && perviousValue != "") {
        rest.map((single) => {
          single.options.push(
            this.state.searchedAction.filter((s) => s.value == perviousValue)[0]
          );
        });
      }
      filterActionFormData();
    };

    const filterActionFormData = () => {
      let action = [];
      this.state.action.map((single) => {
        return action.push(single.value);
      });
      this.props.handleChangeWorkflow("action", action);
    };

    const closeFieldSection = (key) => {
      //previous value
      var perviousValue = this.state.action.filter(
        (single) => single.id == key
      )[0].value.actionId;

      var previousOrder = this.state.action.filter(
        (single) => single.id == key
      )[0].value.order;

      var rest = this.state.action.filter((single) => single.id != key);
      if (rest.length > 0 && perviousValue != "") {
        rest.map((single) => {
          single.options.push(
            this.state.searchedAction.filter((s) => s.value == perviousValue)[0]
          );
        });
      }
      var removedActionData = this.state.action.filter((x) => x.id != key);
      var lessThanOrder = removedActionData.filter(
        (x) => x.value.order < previousOrder
      );

      var orderFiltered = removedActionData.filter(
        (x) => x.value.order > previousOrder
      );

      orderFiltered.map((single) => {
        single.value.order = single.value.order - 1;
      });

      lessThanOrder = [...lessThanOrder, ...orderFiltered];
      this.setState(
        {
          action: lessThanOrder,
        },
        () => {
          filterActionFormData();
        }
      );
    };

    const openDropdownForAction = () => {
      let div = [];
      // Outer loop to create parent
      this.state.action.map((single) => {
        return div.push(
          <div>
            <div className="row">
              <div className="col-10" style={{ paddingRight: "30px" }}>
                <Dropdown
                  // disabled={single && single.value ? false : true}
                  placeholder="Action"
                  clearable
                  fluid
                  search
                  selection
                  selectOnBlur={false}
                  id={single.id}
                  value={single && single.value.actionId}
                  options={single && single.options}
                  onChange={(e, data) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    changeActionNameValue(single.id, data.value);
                  }}
                  style={style.dropdownInput}
                />
              </div>
              <div className="col-2" style={{ paddingLeft: 0 }}>
                <RemoveCircleOutlineIcon
                  id={single.id}
                  style={{
                    color: "grey",
                    width: "24px",
                    height: "24px",
                    marginTop: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    closeFieldSection(single.id);
                  }}
                />
              </div>
            </div>
          </div>
        );
      });
      return div;
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Workflow" />
        <Prompt
          when={this.state.isBlocking}
          message="Are you sure you want to leave this page?"
        />

        {this.props.state.workflowFormState.loading ? (
          <div
            className={
              this.props.state.workflowFormState.loading
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}

        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/workflow" style={style.link}>
            Workflow
          </Link>
          <Typography color="inherit" className="link">
            Edit Workflow
          </Typography>
        </Breadcrumbs>

        <div style={style.container}>
          {workflowFormState &&
          workflowFormState.action &&
          this.state.searchedAction &&
          workflowFormState.action.length > 0 &&
          this.state.searchedAction.length !== workflowFormState.action.length
            ? this.setState({
                searchedAction: workflowFormState.action.map((action) => ({
                  actionId: action.actionId,
                  text: action.actionName,
                  value: action.actionId,
                })),
              })
            : null}
          {this.state.setValue === false &&
          workflowFormState.workflowData &&
          workflowFormState.workflowData.action &&
          this.state.searchedAction.length > 0 &&
          workflowFormState.workflowData.action.length > 0
            ? createAddFormExistingActions()
            : null}
          <div className="row">
            <div
              className="col-4"
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                position: "inherit",
              }}
            >
              <label style={style.label}>Workflow Name</label>
              <input
                type="text"
                className="form-control"
                id="workflowName"
                value={
                  workflowFormState.workflowData
                    ? workflowFormState.workflowData.workflowName
                    : null
                }
                onChange={(e) => {
                  this.setState({ isBlocking: true });
                  this.props.handleChangeWorkflow(e.target.id, e.target.value);
                }}
                style={style.input}
              />
            </div>
          </div>
          <div className={"row"}>
            <div
              className="col-5"
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                position: "inherit",
              }}
            >
              {this.state.action.length > 0 ? openDropdownForAction() : null}
            </div>
          </div>

          <div className={"row"}>
            <div
              className="col-4"
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                position: "inherit",
              }}
            >
              <div
                style={{
                  float: "right",
                  padding: "1.3rem 0 0 0",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ isBlocking: true });
                  showInputForField();
                }}
              >
                <img
                  src="/assets/images/add-button-inside-circle.svg"
                  alt="Add Button"
                  style={{ width: "22px", height: "22px", cursor: "pointer" }}
                />
                <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                  Add Action
                </span>
              </div>
              <div
                className={"row"}
                style={{
                  justifyContent: "flex-end",
                  padding: "2rem 0 1rem 0",
                  marginLeft: 0,
                  width: "100%",
                }}
              >
                <button
                  style={style.button}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: false });
                    this.props.editWorkflow(
                      this.props.state.workflowFormState.workflowData,
                      this.props
                    );
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.workflowFormState.openWorkflowFormSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.state.workflowFormState.errorMsgForWorkflowForm}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    background: "#ffffff",
    marginTop: "78px",
    marginLeft: "3.5%",
    marginRight: "3.5%",
    height: "100%",
    // boxShadow: "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    paddingBottom: "0.5rem",
    width: "100%",
    position: "inherit",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "100%",
    position: "inherit",
  },
  select: {
    position: "relative",
    height: "40px",
  },
  expandMoreIcon: {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  dropdownInput: {
    height: "42px",
    backgroundColor: "#F4F5F9",
    border: "none",
    width: "100%",
    // paddingTop: '6px',
    fontSize: "15px",
    marginTop: "1.5rem",
    position: "inherit",
  },
  header: {
    color: "#cc6600",
    fontSize: "22px",
    fontWeight: "bolder",
    borderBottom: "1px solid #DEDEDE",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "2rem",
  },
  selectedAction: {
    border: "1px solid #DCDCDC",
    padding: "1rem",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    fontSize: "14px",
    marginBottom: "0.5rem",
    width: "80%",
  },
  button: {
    height: "35px",
    background: "#8BC83F",
    color: "white",
    fontSize: "16px",
    border: "none",
    borderRadius: "4px",
    width: "100px",
  },
};

export default WorkflowEditFormScreen;
