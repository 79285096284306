import {ACTION_TYPE_CARD } from "../constants/Type";

export const cardRequestMade = bool => {
    return{
        type: ACTION_TYPE_CARD.CARD_REQUEST_MADE,
        bool
    }
}
export const handleChangeClientId = clientId => {
    return{
        type: ACTION_TYPE_CARD.HANDLE_CHANGE_CARD_INPUT,
        clientId
    }
}

export const handleChangeOrderStatus = (orderStatus, id) => {

    return{
        type: ACTION_TYPE_CARD.HANDLE_CHANGE_ORDER_STATUS,
        orderStatus,id
    }
}

export const handleChangeCardStatus = (cardStatus, id) => {
    return{
        type: ACTION_TYPE_CARD.HANDLE_CHANGE_CARD_STATUS,
        cardStatus,id
    }
}

export const fetchCardSuccess = cards => {
    return{
        type: ACTION_TYPE_CARD.FETCH_CARD_SUCCESS,
        cards
    }
}
export const fetchCardFailure = error =>{
    return{
        type:ACTION_TYPE_CARD.FETCH_CARD_FAILURE,
        error
    }
}
export const updateOrderStatusSuccess = orderStatusRes => {
    return{
        type: ACTION_TYPE_CARD.UPDATE_ORDER_STATUS_SUCCESS,
        orderStatusRes
    }
}

export const updateOrderStatusFailure = error =>{
    return{
        type: ACTION_TYPE_CARD.UPDATE_ORDER_STATUS_FAILURE,
        error
    }
}
export const updateCardStatusSuccess = cardStatusRes => {
    return{
        type: ACTION_TYPE_CARD.UPDATE_CARD_STATUS_SUCCESS,
        cardStatusRes
    }
}
export const updateCardStatusFailure = error =>{
    return{
        type: ACTION_TYPE_CARD.UPDATE_CARD_STATUS_FAILURE,
        error
    }
}

export const orderCardSuccess = bool => {
    return{
        type: ACTION_TYPE_CARD.ORDER_CARD_SUCCESS,
        bool
    }
}
export const orderCardFailure = error =>{
    return{
        type: ACTION_TYPE_CARD.ORDER_CARD_FAILURE,
        error
    }
}

export const searchCardSuccess = card => {
    return{
        type: ACTION_TYPE_CARD.SEARCH_CARD_SUCCESS,
        card
    }
}
export const searchCardFailure = error =>{
    return{
        type: ACTION_TYPE_CARD.SEARCH_CARD_FAILURE,
        error
    }
}
export const setCardToView = orderCards =>{
    return{
        type: ACTION_TYPE_CARD.SET_CARD_TO_VIEW,
        orderCards
    }
}

export const setCardDataToEmpty = () =>{
    return{
        type: ACTION_TYPE_CARD.SET_ORDER_CARD_TO_EMPTY,
    }
}

export const closeCardSnackBar = bool =>{
    return{
        type: ACTION_TYPE_CARD.CLOSE_SNACK_BAR_CARD_PAGE,
        bool,
    }
}


