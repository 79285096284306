import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import "../styles/styles.css";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { DatePicker} from 'antd';
import Typography from "@material-ui/core/Typography";

class CalendarSettingScreen extends Component {
    constructor(props) {
        super(props);
        this.state ={
            openDialogueToAddHoliday: false,
        }
    }
    render() {

        const handleCloseDialogueToAddHoliday = () => {
            this.setState({
                openDialogueToAddHoliday: false
            })
        };
        const handleOpenDialogueToAddHoliday = () => {
            this.setState({
                openDialogueToAddHoliday: true,
            })
        };
        return(
        <React.Fragment>
            <Breadcrumbs aria-label="breadcrumb" className="bc">
                <Link color="inherit" to="/dashboard" className="links">
                    <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                </Link>
                <Typography color="inherit" className="link">
                    Calendar Setting
                </Typography>
            </Breadcrumbs>
            <div style={{margin: "80px 3% 2%"}}>
                <div className={"d-flex flex-row-reverse div-flex"}>
                    <div>
                        <button
                            className="addButton"
                            style={{width: '140px'}}
                            onClick={(e) => {
                                e.preventDefault();
                                handleOpenDialogueToAddHoliday()
                            }}>
                            <AddIcon/> Add Holiday
                        </button>
                    </div>
                </div>
                <div className={"row"} style={style.card}>
                        <TableContainer >
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader" style={{paddingLeft: "60px"}}>
                                            Start Date
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            End Date
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Holiday Name
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="first-tableBody">
                                                {Date.now()}
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                {Date.now()}
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                New year
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                            </Table>
                            {/*{fileResponse && fileResponse.length > 0 ? (*/}
                            {/*    <div style={{justifyContent: "flex-start", width: "100%"}}>*/}
                            {/*        <TablePagination*/}
                            {/*            labelRowsPerPage={false}*/}
                            {/*            rowsPerPageOptions={[10, 25, 100]}*/}
                            {/*            component="div"*/}
                            {/*            count={*/}
                            {/*                fileResponse.length > 0*/}
                            {/*                    ? fileResponse.length*/}
                            {/*                    : 100*/}
                            {/*            }*/}
                            {/*            rowsPerPage={this.state.rowsPerPage}*/}
                            {/*            page={this.state.page}*/}
                            {/*            backIconButtonProps={{*/}
                            {/*                "aria-label": "previous page"*/}
                            {/*            }}*/}
                            {/*            nextIconButtonProps={{*/}
                            {/*                "aria-label": "next page"*/}
                            {/*            }}*/}
                            {/*            onChangePage={handleChangePage}*/}
                            {/*            onChangeRowsPerPage={handleChangeRowsPerPage}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*) : (*/}
                            {/*    <div>*/}
                            {/*        <h3*/}
                            {/*            style={{*/}
                            {/*                textAlign: "center",*/}
                            {/*                fontSize: "14px",*/}
                            {/*                padding: "1rem",*/}
                            {/*                color: "#606060"*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            {" "}*/}
                            {/*            No data to show*/}
                            {/*        </h3>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </TableContainer>
                    </div>
                </div>
            <Dialog
                open={this.state.openDialogueToAddHoliday}
                onClose={handleCloseDialogueToAddHoliday}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <div style={{background: "#8BC83F", borderRadius: "18px"}}>
                    <DialogTitle
                        style={style.dialogHeaderForAddUser}
                        id="draggable-dialog-title"
                    />
                    <DialogContent
                        style={{
                            background: "white",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            paddingLeft: "1.5rem !important",
                            paddingRight: "1.5rem !important",
                            width: '450px'
                        }}
                    >
                        <h3
                            style={{
                                textAlign: "center",
                                color: "#8BC83F",
                                padding: "0.6rem"
                            }}
                        >
                          Add Holiday
                        </h3>
                        <label style={style.label}>Holiday Name</label>
                        <input
                            type='text'
                            id="holiday"
                            style={style.input}
                            onChange={(e) => {
                              e.preventDefault();
                            }}
                        />
                        <div className="row">
                            <div className="col-lg-6">
                                <label style={style.label}>Start Date</label>
                                <div className="select-input" style={style.searchDate}>
                                    <DatePicker
                                        style={style.rangeBase}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        // onChange={handleChangeDateFrom}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label style={style.label}>End Date</label>
                                <div className="select-input" style={style.searchDate}>
                                    <DatePicker
                                        style={style.rangeBase}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        // onChange={handleChangeDateTo}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{
                                padding: '2.5rem 0px 1.5rem'
                            }}
                        >
                            <div className={"col-6"}>
                                <Button
                                    onClick={e => {
                                        e.preventDefault();
                                        handleCloseDialogueToAddHoliday();
                                    }}
                                    style={style.cancelButton}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className={"col-6"}>
                                <Button
                                    onClick={e => {
                                        e.preventDefault();;
                                    }}
                                    style={style.addUserButton}
                                    // style={style.addUserButton}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        </React.Fragment>
        )
    }
}
const style = {
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginBottom: "1rem",
    },
    addUserButton: {
        background: "#8BC83F",
        color: "white",
        width: "100%",
        padding: "0.5rem",
        border: "1px solid #8BC83F"
    },
    dialogHeaderForAddUser: {
        cursor: "move",
        background: "#8BC83F",
        color: "white",
        paddingTop: "0px",
        paddingRight: "1rem",
        paddingLeft: "1rem"
    },
    label: {
        color: "#606060",
        marginBottom: "0",
        fontSize: "16px",
        width: "100%"
    },
    input: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '4px',
        marginBottom:'12px',
        paddingLeft: '15px'
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
    searchDate: {
        position: 'relative',
        backgroundColor: "#F4F5F9",
        marginRight: '1.2rem',
        marginTop: '10px',
        width: '180px',
        height: '40px',
    },
    rangeBase: {
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        width: '270px',
        border: 'none',
        borderRadius: '8px',
    },
}
export default CalendarSettingScreen;