import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import "../styles/styles.css";
import "./cardScreen/cardScreen.css";
import {
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PrintIcon from "@material-ui/icons/Print";
import Checkbox from "@material-ui/core/Checkbox";
import { Select } from "antd";
import MySnackbarContentWrapper from "../components/Snackbar";
import Typography from "@material-ui/core/Typography";
import { HelmetComponent } from "../components/Helmet";

const { Option } = Select;

class BulkCheckPrintScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 8,
      openActionMenu: false,
      openModalToPrint: false,
      openErrorSnackBar: false,
      selected: [],
      clicked: false,
      isSelectBank: false,
    };
  }
  componentDidMount() {
    this.props.fetchBulkCheckPrintList();
    this.props.fetchVendor();
    this.props.clearCheckedValue();
    this.props.fetchCheckingAccountsList();
  }

  render() {
    const checkPrintState = this.props && this.props.state.checkPrintState;
    const bulkCheckPrintList =
      checkPrintState && checkPrintState.bulkCheckPrintList;
    const vendorState = this.props && this.props.state.vendorState;
    const vendorAccount = vendorState && vendorState.vendorAccount;
    const systemVendor =
      vendorAccount &&
      vendorAccount.filter(
        (vendorList) => vendorList.vendorType && vendorList.vendorType.isDefault
      );
    const vendorToShow =
      systemVendor && systemVendor[0] && systemVendor[0].accountName
        ? systemVendor
        : [];
    const accountNumber =
      vendorToShow && vendorToShow.map((vendor) => vendor.accountNumber)[0];
    const routingNumber =
      vendorToShow && vendorToShow.map((vendor) => vendor.routingNumber)[0];

    const settingsState =
      this.props && this.props.state && this.props.state.settingState;
    const checkingAccountData =
      settingsState && settingsState.checkingAccountData;
    const destinationName =
      checkingAccountData && checkingAccountData.destinationName;

    const sortedBulkCheckList = bulkCheckPrintList
      ? bulkCheckPrintList.sort((a, b) => {
          const data = this.state.sortType === "asc" ? 1 : -1;
          return data * a.createdAt.localeCompare(b.createdAt);
        })
      : null;

    const getDate = (dateToConvert) => {
      return new Date(parseInt(dateToConvert)).toLocaleDateString();
    };
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds =
          sortedBulkCheckList &&
          sortedBulkCheckList.map((n) => n.transactionId);
        this.setState({
          selected: newSelecteds,
        });
        return;
      }
      this.setState({
        selected: [],
      });
    };
    const handleOpenErrorSnackBar = () => {
      this.setState({
        openErrorSnackBar: true,
      });
    };
    const handleCloseErrorSnackBar = () => {
      this.setState({
        openErrorSnackBar: false,
      });
    };

    const handleClick = (event, id) => {
      const selectedIndex = this.state.selected.indexOf(id);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selected: newSelected,
      });
    };
    const isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    return (
      <React.Fragment>
        <HelmetComponent title="Check Print" />
        {checkPrintState.loading || vendorState.loading ? (
          <div
            className={
              checkPrintState.loading || (vendorState && vendorState.loading)
                ? "loader"
                : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs aria-label="breadcrumb" className="bc">
          <Link color="inherit" to="/dashboard" className="links">
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link color="inherit" to="/checkprint" className="links">
            Check Print
          </Link>
          <Typography color="inherit" className="link">
            Bulk Check Print
          </Typography>
        </Breadcrumbs>
        <div style={{ margin: "80px 3% 2%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "228px" }}>
              <label style={style.label}>
                {" "}
                Select Bank
                <span style={{ color: "red" }}> *</span>
              </label>
              <input value={destinationName} className="inputField" />
              {/*{*/}
              {/*    vendorToShow && vendorToShow.length === 1 ?*/}
              {/*    <input className="inputField" value={vendorToShow &&*/}
              {/*        vendorToShow[0] && vendorToShow[0].accountName}/>  :*/}
              {/*    <div className="selects h40">*/}
              {/*    <Select*/}
              {/*        showSearch={false}*/}
              {/*        getPopupContainer={node => node.parentNode}*/}
              {/*        onChange={(e) => {*/}
              {/*            this.props.handleChangeBankName(e)*/}
              {/*        }}*/}
              {/*        onClick={() => {*/}
              {/*            this.setState({*/}
              {/*                isSelectBank: true,*/}
              {/*            })*/}
              {/*            if(vendorToShow && vendorToShow.length === 0 ){*/}
              {/*                handleOpenErrorSnackBar();*/}
              {/*            }*/}
              {/*        }}*/}
              {/*        disabled={vendorToShow && vendorToShow.length === 0}*/}
              {/*    >*/}
              {/*        {*/}
              {/*            vendorToShow && vendorToShow.map(vendor => (*/}
              {/*                <Option value={vendor.accountName}>{vendor.accountName}</Option>*/}
              {/*            ))*/}
              {/*        }*/}
              {/*    </Select>*/}
              {/*</div>*/}
              {/*}*/}
            </div>
            <div>
              <button
                className="bulkPrintButton"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    isSelectBank: false,
                  });
                  var mappedData = this.state.selected.map(function (elem) {
                    return {
                      transactionId: elem,
                      bankName: destinationName,
                    };
                  });
                  if (mappedData.length !== 0) {
                    const checkData = {
                      checkPrint: mappedData,
                    };
                    if (!this.state.clicked) {
                      this.setState({
                        clicked: true,
                      });
                      this.props.printBulkCheck(
                        checkData,
                        accountNumber,
                        routingNumber,
                        this.props
                      );
                      setTimeout(() => {
                        this.setState({
                          clicked: false,
                        });
                      }, 3000);
                    }
                  } else {
                    this.setState({
                      openErrorSnackBar: true,
                    });
                  }
                }}
              >
                <PrintIcon
                  style={{
                    width: "26px",
                    height: "30px",
                    marginRight: "7px",
                    marginBottom: "4px",
                  }}
                />
                Print
              </button>
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell
                      className="tableHeader"
                      style={{ width: "12px" }}
                    >
                      <Checkbox
                        indeterminate={
                          this.state.selected &&
                          this.state.selected.length > 0 &&
                          sortedBulkCheckList &&
                          this.state.selected &&
                          this.state.selected.length <
                            sortedBulkCheckList.length
                        }
                        checked={
                          sortedBulkCheckList &&
                          sortedBulkCheckList.length > 0 &&
                          sortedBulkCheckList &&
                          this.state.selected &&
                          this.state.selected.length ===
                            sortedBulkCheckList.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{ "aria-label": "select all check" }}
                      />
                    </TableCell>
                    {/*<TableCell className="first-tableHeader" style={{width: "425px"}}>*/}
                    {/*    Transaction Id*/}
                    {/*</TableCell>*/}
                    <TableCell className="tableHeader">Client</TableCell>
                    <TableCell className="tableHeader">Vendor</TableCell>
                    <TableCell className="tableHeader">Date</TableCell>
                    <TableCell
                      className="tableHeaderAmount"
                      style={{ paddingRight: "96px !important" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {sortedBulkCheckList &&
                  sortedBulkCheckList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list, index) => {
                      const isItemSelected = isSelected(list.transactionId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableBody key={list.transactionId}>
                          <TableRow
                            onClick={(event) =>
                              handleClick(event, list.transactionId)
                            }
                            selected={isItemSelected}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              className="tableBody"
                              style={{ width: "12px" }}
                            >
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            {/*<TableCell className="first-tableBody" style={{width: "425px"}}>*/}
                            {/*    {list.transactionId}*/}
                            {/*</TableCell>*/}
                            <TableCell className="tableBody">
                              {list.Client.firstName +
                                " " +
                                list.Client.lastName}
                            </TableCell>
                            <TableCell
                              className="tableBody"
                              style={{ width: "300px" }}
                            >
                              {list.vendorAccount.vendorName
                                ? list.vendorAccount.vendorName
                                : "-"}
                            </TableCell>
                            <TableCell className="tableBody">
                              {getDate(list.createdAt)}
                            </TableCell>
                            <TableCell
                              className="tableAmountBody"
                              style={{ width: "3px" }}
                            >
                              {formatter.format(list.amount / 100)}
                            </TableCell>
                            <TableCell style={{ width: "50%" }}></TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
              </Table>
              {sortedBulkCheckList && sortedBulkCheckList.length > 0 ? (
                <div style={{ justifyContent: "flex-start", width: "100%" }}>
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[8, 10, 25, 100]}
                    component="div"
                    count={
                      sortedBulkCheckList.length > 0
                        ? sortedBulkCheckList.length
                        : 100
                    }
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "1rem",
                      color: "#606060",
                    }}
                  >
                    {" "}
                    No data to show
                  </h3>
                </div>
              )}
            </TableContainer>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openErrorSnackBar}
          autoHideDuration={4000}
          onClose={() => {
            this.setState({
              openErrorSnackBar: false,
            });
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              this.setState({
                openErrorSnackBar: false,
              });
            }}
            variant="error"
            message={
              this.state.isSelectBank
                ? "Please add Everfund bank account first!"
                : "No data selected"
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.state.checkPrintState.openBulkPrintErrorSnackBar}
          autoHideDuration={3000}
          onClose={this.props.handleClosePrintSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleClosePrintSnackBar}
            variant="error"
            message={this.props.state.checkPrintState.errorMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    margin: "12px 0px 1rem",
    // height: 670,
    // width: '100%'
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    // marginTop: '20px',
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "50%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },
};

export default BulkCheckPrintScreen;
