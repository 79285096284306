import {ACTION_TYPE_CONTACT, ACTION_TYPE_SHIPPING_LABEL} from "../constants/Type";

const initialState = {
    loading: false,
    openErrorContactSnackBar: false,
    openSuccessContactSnackBar: false,
    errorMsg: '',
    successMsg:'',
    contactList:[],
    contactData:{
        emailPhone:"",
        name:"",
        contactType: "",
        phone:"",
        dob:0,
        address:[],
    },
    companyList:[],
    companyData:{
        companyId:"",
        companyName:"",
        email:"",
        phone:"",
        address:[],
        contact:[],
        companyTypeId: "",
    },
    relationshipName: "",
    relationShipList: [],
    permissionTypeList: [],
    permissionName: [],
    contactFileData: {
        documentName: "",
        documentUrl:"",
        refId: "",
    },
    contactFileList:[],
    success: false,
    companyTypeName: "",
    companyTypeList:[],
    companyType:{},
    relationship:{}
}

const contactState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_CONTACT.REQUEST_CONTACT:
            return {
                ...state,
                loading: action.bool,
            };
        case ACTION_TYPE_CONTACT.CLOSE_CONTACT_SNACKBAR:
            return {
                ...state,
                openErrorContactSnackBar: false,
                openSuccessContactSnackBar: false,
                openSuccessContactFileSnackBar: false,
            }
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_CONTACT_INPUT:
            const newForm = {...state.contactData};
            newForm[action.id] = action.value;
            return {
                ...state,
                contactData:newForm,
            };
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_COMPANY_INPUT:
            const newCompanyForm = {...state.companyData};
            newCompanyForm[action.id] = action.value;
            return {
                ...state,
                companyData:newCompanyForm,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_LIST_SUCCESS:

            return {
                ...state,
                contactList: action.contactList.contacts,
                loading: false,
                success: false,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_LIST_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.FETCH_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companyList: action.companyList.companies,
                loading: false,
                success: false,
            };
        case ACTION_TYPE_CONTACT.FETCH_COMPANY_LIST_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully added',
                success: action.bool,
            };
        case ACTION_TYPE_CONTACT.ADD_CONTACT_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.ADD_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully added',
                success: action.bool
            };
        case ACTION_TYPE_CONTACT.ADD_COMPANY_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.SET_CONTACT_INPUT_TO_EDIT:
            return {
                ...state,
                contactData: action.contactData,
            }
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_SUCCESS:
            return {
                ...state,
                success: action.bool,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully updated',
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.SET_COMPANY_INPUT_TO_EDIT:
            return {
                ...state,
                companyData: action.companyData,
            }
        case ACTION_TYPE_CONTACT.UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully updated',
                success: action.bool,
            };
        case ACTION_TYPE_CONTACT.UPDATE_COMPANY_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                // contactList: action.contactList,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully deleted'
            };
        case ACTION_TYPE_CONTACT.DELETE_CONTACT_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully deleted'
            };
        case ACTION_TYPE_CONTACT.DELETE_COMPANY_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.DELETE_COMPANY_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.DELETE_COMPANY_CONTACT_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_TERM:
            return {
                ...state,
                searchTerm : action.term,
            }
        case ACTION_TYPE_CONTACT.SEARCH_CONTACT_SUCCESS:
            return {
                ...state,
                contactList: action.response.contacts,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.SEARCH_CONTACT_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.SEARCH_COMPANY_SUCCESS:
            return {
                ...state,
                companyList: action.response.companies,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.SEARCH_COMPANY_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_RELATIONSHIP_NAME:
            return {
                ...state,
                relationshipName: action.name,
            }
        case ACTION_TYPE_CONTACT.ADD_CONTACT_RELATIONSHIP_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully added',
                success: action.bool,
            };
        case ACTION_TYPE_CONTACT.ADD_CONTACT_RELATIONSHIP_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_RELATIONSHIP_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully updated',
                success: action.bool,
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_RELATIONSHIP_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_RELATIONSHIP_SUCCESS:
            return {
                ...state,
                relationShipList: action.relationshipList.relationships,
                loading: false,
                success: false,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_RELATIONSHIP_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.SET_RELATIONSHIP_TO_EDIT:
            return {
                ...state,
                relationship: action.relationship,
                relationshipName: action.relationship.name,
                permissionName: action.relationship && action.relationship.permission ?
                    action.relationship.permission : [],
            };
        case ACTION_TYPE_CONTACT.GET_PERMISSION_FOR_RELATIONSHIP_SUCCESS:
            return {
                ...state,
                permissionTypeList: action.permissionList.permissions,
                loading: false,
            }
        case ACTION_TYPE_CONTACT.GET_PERMISSION_FOR_RELATIONSHIP_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            }
        case ACTION_TYPE_CONTACT.SET_PERMISSION_FOR_RELATIONSHIP_SUCCESS:
            return {
                ...state,
                success: action.response,
                openSuccessContactSnackBar: true,
                successMsg: "Successfully assigned",
                loading: false,
            }
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_PERMISSION_NAME:
            return {
                ...state,
                permissionName: action.permissionName,
                loading: false,
            }
        case ACTION_TYPE_CONTACT.SET_PERMISSION_FOR_RELATIONSHIP_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            }
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_CONTACT_FILE:
            const newContactFiles = {...state.contactFileData};
            newContactFiles[action.id] = action.value;
            return {
                ...state,
                contactFileData:newContactFiles,
            };
        case ACTION_TYPE_CONTACT.SET_CONTACT_FILE_TO_EDIT:
            return {
                ...state,
               contactFileData: action.files
            };
        case ACTION_TYPE_CONTACT.ADD_CONTACT_FILE_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
                openSuccessContactFileSnackBar: true,
                successMsg: 'Successfully added'
            };
        case ACTION_TYPE_CONTACT.ADD_CONTACT_FILE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_FILE_SUCCESS:
            return {
                ...state,
                response: action.response,
                loading: false,
                openSuccessContactFileSnackBar: true,
                successMsg: 'Successfully updated'
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_FILE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.DELETE_CONTACT_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactFileSnackBar: true,
                successMsg: 'Successfully Deleted'
            };
        case ACTION_TYPE_CONTACT.DELETE_CONTACT_FILE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_FILE_SUCCESS:
            return {
                ...state,
                contactFileList: action.contactFilesList.contactDocuments,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_FILE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
            };
        case ACTION_TYPE_CONTACT.SET_CONTACT_DATA_TO_EMPTY:
            return {
                ...state,
                contactData:{
                    emailPhone:"",
                    name:"",
                    contactType: "",
                    phone:"",
                    dob:0,
                    address:[],
                },
                companyData:{
                    companyId:"",
                    companyName:"",
                    email:"",
                    phone:"",
                    address:[],
                    contact:[],
                    companyTypeId: "",
                },
                relationshipName: "",
                companyTypeName:"",
            }
        case ACTION_TYPE_CONTACT.ADD_CONTACT_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully added',
                success: action.bool,
            };
        case ACTION_TYPE_CONTACT.ADD_CONTACT_COMPANY_TYPE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                openSuccessContactSnackBar: true,
                successMsg: 'Successfully updated',
                success: action.bool,
            };
        case ACTION_TYPE_CONTACT.UPDATE_CONTACT_COMPANY_TYPE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            };
        case ACTION_TYPE_CONTACT.FETCH_CONTACT_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                companyTypeList: action.companyTypeList.companyTypes,
                loading: false,
                success: false,
            };
        case ACTION_TYPE_CONTACT.HANDLE_CHANGE_COMPANY_TYPE_NAME:
            return {
                ...state,
                companyTypeName: action.name,
            }
        case ACTION_TYPE_CONTACT.SET_COMPANY_TYPE_TO_EDIT:
            return {
                ...state,
                companyTypeName: action.companyType.companyTypeName,
                companyType: action.companyType,
            }
        case ACTION_TYPE_CONTACT.SEARCH_CONTACT_COMPANY_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                companyTypeList: action.companyTypeList.companyTypes,
            }
        case ACTION_TYPE_CONTACT.SEARCH_CONTACT_COMPANY_TYPE_FAILURE:
            return {
                ...state,
                errorMsg: action.error,
                loading: false,
                openErrorContactSnackBar: true,
            }
        case ACTION_TYPE_CONTACT.SET_ACCOUNT_FOR_CONTACT_SUCCESS:
            console.log("success response", action.response)
            return {
                ...state,
                loading: false,
                response: action.response,
                openSuccessContactSnackBar: true,
                successMsg: `Account set successfully, password is send to ${action.response.contact ? action.response.contact.emailPhone: 
                    action.response.company ? action.response.company.email : null}`
            }
        case ACTION_TYPE_CONTACT.SET_ACCOUNT_FOR_CONTACT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
                openErrorContactSnackBar: true,

            }
        default:
            return state;
    }
};

export default contactState;