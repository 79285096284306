import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Button, CircularProgress, MenuItem, MenuList, Popover, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TablePagination from "@material-ui/core/TablePagination";
import MySnackbarContentWrapper from "../components/Snackbar";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import {ButtonContent} from "semantic-ui-react";
import ButtonComponent from "./ButtonComponent";

class CompanyTypeScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            companyType : {},
            companyTypeName:"",
            clicked: false,
        }
    }
    componentDidMount() {
        this.props.fetchCompanyType();
    }

    render() {
        const contactState = this.props.propsState && this.props.propsState.contactState;
        const loading = contactState && contactState.loading;
        const companyTypeList =contactState && contactState.companyTypeList;
        const companyTypeName = contactState && contactState.companyTypeName;
        const value = companyTypeName !== "";
        const companyTypeObj = contactState && contactState.companyType;
        return(
            <React.Fragment>
                { contactState && contactState.loading ? (
                    <div
                        className={
                            contactState && contactState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <div>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeOpenModalToAddCompanyType()
                                }}>
                                <AddIcon/> Add Company Type
                            </button>
                        </div>
                        <div className="search">
                            <div className="searchIcon">
                                <SearchIcon style={{color: '#9c9c9c'}}/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                className="inputBase"
                                onChange={(e)=>{
                                    e.preventDefault();
                                    if(e.target.value === "" || e.target.value === " " ){
                                        this.props.fetchCompanyType();
                                    }else{
                                        this.props.searchCompanyType(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header-second">
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Company Type
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {companyTypeList && companyTypeList.slice(
                                    this.props.page * this.props.rowsPerPage,
                                    this.props.page * this.props.rowsPerPage +
                                    this.props.rowsPerPage
                                ).map(list => (
                                <TableBody>
                                    <TableRow >
                                        <TableCell className="first-tableBody">
                                            {list.companyTypeName}
                                        </TableCell>
                                        <TableCell>
                                            <MoreHorizIcon
                                                id="Popover1"
                                                style={{cursor: 'pointer'}}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        popoverElement: e.currentTarget,
                                                        openActionMenu: true,
                                                        companyType: list,
                                                    })
                                                }}
                                            />
                                            <Popover
                                                open={this.state.openActionMenu}
                                                anchorEl={this.state.popoverElement}
                                                onClose={(e) => {
                                                    e.preventDefault();
                                                    this.setState({openActionMenu: false})
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                style={{boxShadow: 'none'}}
                                            >
                                                <MenuList id="popover-list" style={{
                                                    color: '#4d4d4d',
                                                    padding: '1rem !important',
                                                    cursor: 'pointer'
                                                }}>
                                                    <MenuItem
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.props.handleChangeOpenModalToEditCompanyType();
                                                            this.props.setCompanyTypeToEdit(this.state.companyType)
                                                            this.setState({openActionMenu: false,}
                                                            );
                                                        }}>
                                                        <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                    </MenuItem>
                                                </MenuList>
                                            </Popover>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                ))}
                            </Table>
                            </TableContainer>
                            {companyTypeList && companyTypeList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            companyTypeList.length > 0
                                                ? companyTypeList.length
                                                : 100
                                        }
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={this.props.handleChangePageCompanyType}
                                        onChangeRowsPerPage={this.props.handleChangeRowsPerPageCompanyType}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>
                {/*add company type*/}
                <Dialog
                    open={this.props.openModalToAddCompanyType}
                    onClose={this.props.handleChangeCloseModalToAddCompanyType}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToAddCompanyType}>
                            Add Company Type
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Company Type</label>
                                    <input
                                        type='text'
                                        id="relationShip"
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.setState({
                                                companyTypeName: e.target.value,
                                            })
                                            this.props.handleChangeCompanyTypeName(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        onClick={e => {
                                            e.preventDefault();
                                            const companyType = {
                                                companyTypeName: companyTypeName
                                            }
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.addCompanyType(companyType);
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        value={value}
                                        buttonName="Add"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*edit company type*/}
                <Dialog
                    open={this.props.openModalToEditCompanyType}
                    onClose={this.props.handleChangeCloseModalToEditCompanyType}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToEditCompanyType}>
                            Update Company Type
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Company Type</label>
                                    <input
                                        type='text'
                                        id="relationShip"
                                        className="input"
                                        autoComplete='off'
                                        value={companyTypeName}
                                        onChange={(e) => {
                                            this.props.handleChangeCompanyTypeName(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        style={{float:"right"}}
                                        onClick={e => {
                                            e.preventDefault();
                                            const companyType = {
                                                companyTypeId: companyTypeObj.companyTypeId,
                                                companyTypeName: companyTypeName
                                            }
                                            this.props.editCompanyType(companyType);
                                        }}
                                        value={value}
                                        loading={loading}
                                        buttonName="Update"
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={contactState.openSuccessContactSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="success"
                        message={contactState && contactState.successMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={contactState.openErrorContactSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={contactState.errorMsg}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
};
const style = {
    card: {
        background: "#ffffff",
        border: "1px solid rgb(224, 224, 224)",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginLeft: 0,
        marginBottom: "1rem",
    },
}

export default CompanyTypeScreen;