import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LocalDb from '../api/LocalStorage';
import PageNotFound from '../screens/PageNotFound';

const PrivateRoute = ({ component: Component, ...rest }) =>{

    // getCorporateClientId()

    return     (
       
        <Route {...rest} render={props => {
            if(window.location.pathname){
                localStorage.setItem('route', window.location.pathname)
            }else{
                localStorage.setItem('route', '/');
            }
          
             const returnValidatedComponent = () => {
                let clientId = LocalDb.getClinetId()
                // console.log("get client id in private route", clientId)
                if(LocalDb.getAccountType() === "CORPORATE_CLIENT" &&  window.location.pathname === "/profile") { 
                       return <Component {...props} />
                   }

                   else if(LocalDb.getAccountType() === "CORPORATE_CLIENT" && window.location.pathname === "/client"){
                    return <Component {...props} />
                    }
                else if(LocalDb.getAccountType() === "CORPORATE_CLIENT" && !window.location.pathname.includes(`/client/details/${clientId}`)){
                    return <Redirect to='/notfound' from component={PageNotFound}/>
                 }
              
                else {
                        return <Component {...props} />
                        // return <Redirect to='/notfound' from component={PageNotFound}/>
                    }
             }

            return (
                LocalDb.getSessions() ?
                returnValidatedComponent()
                    : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
        }} />
    );
}


export default PrivateRoute;