import { connect } from 'react-redux';
import BulkCheckScreen from "../../screens/BulkChecksScreen"
import {checkPrintRequestMade} from "../../actions/CheckPrintAction";
import {CheckPrintAPI} from "../../api";
import {setCheckPrintFailure, setCheckPrintSuccess} from "../../actions/ClientAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return{
        setCheckPrint : (checkPrintData) => {
            dispatch(checkPrintRequestMade(true))
            CheckPrintAPI.setCheckPrint(checkPrintData, (response, error) => {
                if (error) {
                    if (error.msg) {
                        dispatch(setCheckPrintFailure(error.msg))
                    } else {
                        dispatch(setCheckPrintFailure(error));
                    }
                } else {
                    dispatch(setCheckPrintSuccess(response));
                }
            })
        },
    }
};

export const PrintBulkCheckScreenContainer = connect(mapStateToProps, mapDispatchToProps)(BulkCheckScreen);