import {connect} from 'react-redux';
import {CaseTemplateEditFormScreen} from "../../screens";
import {
    addCaseTemplateFailure,
    addCaseTemplateSuccess,
    caseTemplateRequestMade,
    closeSnackBarForCaseTemplate,
    fetchWorkflowFailure,
    fetchWorkflowSuccess,
    handleChangeCaseTemplate,
    setCaseTemplateToEdit
} from "../../actions";
import {CaseTemplateAPI, WorkflowAPI} from "../../api";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchWorkflowList: () => {
            dispatch(caseTemplateRequestMade(true));
            WorkflowAPI.fetchWorkflowList((response, error) => {
                if (response) {
                    dispatch(fetchWorkflowSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchWorkflowFailure(error.msg))
                    } else {
                        dispatch(fetchWorkflowFailure(error));
                    }
                }
            })
        },
        handleChangeCaseTemplate: (id, value) => dispatch(handleChangeCaseTemplate(id, value)),
        editCaseTemplate: (actionFormData, props) => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.editCaseTemplate(actionFormData, (response, error) => {
                if (response) {
                    dispatch(addCaseTemplateSuccess(true));
                    props.history.replace('/casetemplate');
                } else {
                    if (error.msg) {
                        dispatch(addCaseTemplateFailure(error.msg));
                    } else {
                        dispatch(addCaseTemplateFailure(error));
                    }
                }
            });
        },
        getCaseTemplateInfo: templateId => {
            dispatch(caseTemplateRequestMade(true));
            CaseTemplateAPI.fetchSingleCaseTemplate(templateId, (response, error) => {
                if (response) {
                    dispatch(setCaseTemplateToEdit(response));
                } else {
                    if (error.msg) {
                        dispatch(addCaseTemplateFailure(error.msg));
                    } else {
                        dispatch(addCaseTemplateFailure(error));
                    }
                }
            })
        },
        setCaseTemplateData: (caseTemplateFormData) => {
            dispatch(setCaseTemplateToEdit(caseTemplateFormData))
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForCaseTemplate(true)),
    };
};


export const CaseTemplateEditFormContainer = connect(mapStateToProps, mapDispatchToProps)(CaseTemplateEditFormScreen);