import {ACTION_TYPE_DOCUMENTS} from "../constants/Type";

const initialState = {
    loading: false,
    documentsHub: [],
    errorMsg :"",
    successMsg: "",
    openErrorSnackBar: false,
    openSuccessSnackBar: false,
    fileName: "",
    fromDate: 0,
    toDate: 0,
    docType: "",
    success: false,
};

const documentState = (state=initialState, action) =>{
    switch (action.type) {
        case ACTION_TYPE_DOCUMENTS.REQUEST_FOR_DOCUMENT:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_DOCUMENTS.CLOSE_SNACKBAR:
            return {
                ...state,
                loading: false,
                openErrorSnackBar: false,
                openSuccessSnackBar: false,
            }
        case ACTION_TYPE_DOCUMENTS.CLEAR_FILTER_MODAL:
            return {
                ...state,
                loading: false,
                fileName: "",
                fromDate: 0,
                toDate: 0,
            }
        case ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_FILE_NAME:
            return {
                ...state,
                loading: false,
                fileName: action.fileName,
            };
        case ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_START_DATE:
            return {
                ...state,
                loading: false,
                fromDate: action.fromDate,
            };
        case ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_END_DATE:
            return {
                ...state,
                loading: false,
                toDate: action.toDate,
            };
        case ACTION_TYPE_DOCUMENTS.HANDLE_CHANGE_DOCUMENT_TYPE:
            return {
                ...state,
                loading: false,
                docType: action.docType,
            };
        case ACTION_TYPE_DOCUMENTS.FETCH_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documentsHub: action.response.documentsHub,
            };
        case ACTION_TYPE_DOCUMENTS.FETCH_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
            };
        case ACTION_TYPE_DOCUMENTS.FILTER_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                documentsHub: action.response.documentsHub,
                success: true,
            };
        case ACTION_TYPE_DOCUMENTS.SET_FILTER_SUCCESS_TO_FALSE:
            return {
                ...state,
                success: false,
            }
        case ACTION_TYPE_DOCUMENTS.FILTER_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
                openErrorSnackBar: true,
            };
        default:
            return state;
    }
};
export default documentState;