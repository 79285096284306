import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class WorkflowFormAPI extends API {
    fetchActionList = callback => {
        fetch(APIEndPoints.FETCH_ACTION, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling action api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    addNewAction = (actionData, callback) => {
        let body = JSON.stringify(actionData);
        fetch(APIEndPoints.ADD_ACTION, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add action api", error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchSingleWorkflow = (id,callback) => {
        var constUrl = APIEndPoints.FETCH_SINGLE_WORKFLOW + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                console.log(error);
                callback(null, "Service error, please try again.");
            });
    };

    addNewWorkflow = (actionData, callback) => {
        let body = JSON.stringify(actionData);
        fetch(APIEndPoints.ADD_WORKFLOW, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add workflow api", error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteAction = (actionId, callback) => {
        const urlString = APIEndPoints.DELETE_ACTION + actionId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };

    editWorkflow = ( workflowFormData,callback) => {
        fetch( APIEndPoints.EDIT_WORKFLOW ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(workflowFormData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
}

export default new WorkflowFormAPI();