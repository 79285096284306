import React from "react";
import { Popover } from "antd";
import { DocumentImage } from "./DocumentImage";
import { getBankType } from "./Helper";

const ChangedLogPopover = ({ refValue, formatter, isTrnxDeleted }) => {
  const parsedRefValue = refValue && JSON.parse(refValue);

  const getDateTime = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
  };
  const getScheduleTransactionType = (type) => {
    let scheduleType;
    if (type === 1) scheduleType = "One Time";
    else if (type === 2) scheduleType = "Daily";
    else if (type === 3) scheduleType = "Weekly";
    else if (type === 4) scheduleType = "Monthly";
    else if (type === 5) scheduleType = "Quarterly";
    else if (type === 6) scheduleType = "Yearly";
    else scheduleType = "nth Day";
    return scheduleType;
  };
  const getTransactionMedium = (medium) => {
    let transactionMedium;
    if (medium === 1) transactionMedium = "Check";
    else if (medium === 2) transactionMedium = "Cash";
    else if (medium === 3) transactionMedium = "Wire Transfer";
    else if (medium === 4) transactionMedium = "ACH";
    else if (medium === 5) transactionMedium = "Card";
    else if (medium === 6) transactionMedium = "Ledger";
    else transactionMedium = "Other Medium";
    return transactionMedium;
  };
  const getDocument = (url) => {
    return (
      <a href={url} target="_blank" className="actionsLink">
        <DocumentImage documentURL={url} />
      </a>
    );
  };

  // function ti check the date values
  const checkDateForParse = (timestamp) => {
    if (timestamp === 0) {
      return "-";
    } else {
      return getDateTime(timestamp);
    }
  };
  const refValueArray = [
    {
      name: "Amount",
      oldValue:
        parsedRefValue &&
        parsedRefValue.amount &&
        parsedRefValue.amount[0] &&
        formatter.format(parsedRefValue.amount[0] / 100),
      newValue:
        parsedRefValue &&
        parsedRefValue.amount &&
        parsedRefValue.amount[1] &&
        formatter.format(parsedRefValue.amount[1] / 100),
    },
    {
      name: "Description",
      oldValue:
        parsedRefValue &&
        parsedRefValue.description &&
        parsedRefValue.description[0],
      newValue:
        parsedRefValue &&
        parsedRefValue.description &&
        parsedRefValue.description[1],
    },
    {
      name: "Document",
      oldValue:
        parsedRefValue && parsedRefValue.document && parsedRefValue.document[0]
          ? getDocument(parsedRefValue.document[0])
          : "-",
      newValue:
        parsedRefValue && parsedRefValue.document && parsedRefValue.document[1]
          ? getDocument(parsedRefValue.document[1])
          : "-",
    },
    {
      name: "Notes",
      oldValue:
        parsedRefValue && parsedRefValue.note && parsedRefValue.note[0]
          ? parsedRefValue.note[0]
          : "-",
      newValue:
        parsedRefValue && parsedRefValue.note && parsedRefValue.note[1]
          ? parsedRefValue.note[1]
          : "-",
    },
    {
      name: "Schedule Date",
      oldValue:
        parsedRefValue &&
        parsedRefValue.scheduled_date &&
        checkDateForParse(parsedRefValue.scheduled_date[0]),

      newValue:
        parsedRefValue &&
        parsedRefValue.scheduled_date &&
        checkDateForParse(parsedRefValue.scheduled_date[1]),
    },
    {
      name: "End Date",
      oldValue:
        parsedRefValue &&
        parsedRefValue.end_date &&
        checkDateForParse(parsedRefValue.end_date[0]),

      newValue:
        parsedRefValue &&
        parsedRefValue.end_date &&
        checkDateForParse(parsedRefValue.end_date[1]),
    },
    {
      name: "Schedule Type",
      oldValue:
        parsedRefValue &&
        parsedRefValue.schedule_type &&
        parsedRefValue.schedule_type[0] &&
        getScheduleTransactionType(parsedRefValue.schedule_type[0]),
      newValue:
        parsedRefValue &&
        parsedRefValue.schedule_type &&
        parsedRefValue.schedule_type[1] &&
        getScheduleTransactionType(parsedRefValue.schedule_type[1]),
    },
    {
      name: "Transaction Medium",
      oldValue:
        parsedRefValue &&
        parsedRefValue.transaction_medium &&
        parsedRefValue.transaction_medium[0] &&
        getTransactionMedium(parsedRefValue.transaction_medium[0]),
      newValue:
        parsedRefValue &&
        parsedRefValue.transaction_medium &&
        parsedRefValue.transaction_medium[1] &&
        getTransactionMedium(parsedRefValue.transaction_medium[1]),
    },
    {
      name: "Vendor Name",
      oldValue:
        parsedRefValue &&
        parsedRefValue.vendor_name &&
        parsedRefValue.vendor_name[0],
      newValue:
        parsedRefValue &&
        parsedRefValue.vendor_name &&
        parsedRefValue.vendor_name[1],
    },
    // {
    //   name: "Vendor Type",
    //       oldValue:
    //       parsedRefValue &&
    //       parsedRefValue.vendor_name &&
    //       parsedRefValue.vendor_name[0],
    //     newValue:
    //       parsedRefValue &&
    //       parsedRefValue.vendor_name &&
    //       parsedRefValue.vendor_name[1],
    // },
    {
      name: "Contact Number",
      oldValue:
        parsedRefValue &&
        parsedRefValue.vendor_contact_number &&
        parsedRefValue.vendor_contact_number[0],
      newValue:
        parsedRefValue &&
        parsedRefValue.vendor_contact_number &&
        parsedRefValue.vendor_contact_number[1],
    },
    {
      name: "Bank Name",

      oldValue:
        parsedRefValue &&
        parsedRefValue.bank_name &&
        parsedRefValue.bank_name[0]
          ? parsedRefValue.bank_name[0]
          : "-",
      newValue:
        parsedRefValue &&
        parsedRefValue.bank_name &&
        parsedRefValue.bank_name[1]
          ? parsedRefValue.bank_name[1]
          : "-",
    },
    {
      name: "Bank Account Type",

      oldValue:
        parsedRefValue &&
        parsedRefValue.bank_account_type &&
        parsedRefValue.bank_account_type[0]
          ? getBankType(parsedRefValue.bank_account_type[0])
          : "-",
      newValue:
        parsedRefValue &&
        parsedRefValue.bank_account_type &&
        parsedRefValue.bank_account_type[1]
          ? getBankType(parsedRefValue.bank_account_type[1])
          : "-",
    },
    {
      name: "Account Holder Name",
      oldValue:
        parsedRefValue &&
        parsedRefValue.account_name &&
        parsedRefValue.account_name[0]
          ? parsedRefValue.account_name[0]
          : "-",
      newValue:
        parsedRefValue &&
        parsedRefValue.account_name &&
        parsedRefValue.account_name[1]
          ? parsedRefValue.account_name[1]
          : "-",
    },
    {
      name: "Routing NUmber",

      oldValue:
        parsedRefValue &&
        parsedRefValue.routing_number &&
        parsedRefValue.routing_number[0]
          ? parsedRefValue.routing_number[0]
          : "-",
      newValue:
        parsedRefValue &&
        parsedRefValue.routing_number &&
        parsedRefValue.routing_number[1]
          ? parsedRefValue.routing_number[1]
          : "-",
    },
    // {
    //   name: "Withdraw Vendor",
    //    oldValue:
    //   parsedRefValue &&
    //   parsedRefValue.routing_number &&
    //   parsedRefValue.routing_number[0],
    // newValue:
    //   parsedRefValue &&
    //   parsedRefValue.routing_number &&
    //   parsedRefValue.routing_number[1],
    // },
    // {
    //   name: "Address",
    //   oldValue:
    //   parsedRefValue &&
    //   parsedRefValue.routing_number &&
    //   parsedRefValue.routing_number[0],
    // newValue:
    //   parsedRefValue &&
    //   parsedRefValue.routing_number &&
    //   parsedRefValue.routing_number[1],
    // },

    // for client update
    {
      name: "First Name",
      oldValue:
        parsedRefValue &&
        parsedRefValue.first_name &&
        parsedRefValue.first_name[0],
      newValue:
        parsedRefValue &&
        parsedRefValue.first_name &&
        parsedRefValue.first_name[1],
    },
    {
      name: "Last Name",
      oldValue:
        parsedRefValue &&
        parsedRefValue.last_name &&
        parsedRefValue.last_name[0],
      newValue:
        parsedRefValue &&
        parsedRefValue.last_name &&
        parsedRefValue.last_name[1],
    },

    {
      name: "Gender",
      oldValue:
        parsedRefValue && parsedRefValue.gender && parsedRefValue.gender[0],
      newValue:
        parsedRefValue && parsedRefValue.gender && parsedRefValue.gender[1],
    },
    {
      name: "DOB",
      oldValue: parsedRefValue && parsedRefValue.dob && parsedRefValue.dob[0],
      newValue: parsedRefValue && parsedRefValue.dob && parsedRefValue.dob[1],
    },
    {
      name: "Country",
      oldValue:
        parsedRefValue && parsedRefValue.county && parsedRefValue.county[0],
      newValue:
        parsedRefValue && parsedRefValue.county && parsedRefValue.county[1],
    },
    {
      name: "Client No.",
      oldValue:
        parsedRefValue &&
        parsedRefValue.client_no &&
        parsedRefValue.client_no[0],
      newValue:
        parsedRefValue &&
        parsedRefValue.client_no &&
        parsedRefValue.client_no[1],
    },
  ];
  const test = [
    {
      id: 1,
      oldValue: "new test",
      newValue: "123",
    },
  ];
  const filteredRefValueArray =
    // refValueArray &&
    !isTrnxDeleted
      ? refValueArray.filter((list) => list.oldValue !== list.newValue)
      : test;

  const content = (
    <div>
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-4">
          <span className="log-popover-title">Old value</span>
        </div>
        <div className="col-lg-4">
          <span className="log-popover-title">New value</span>
        </div>
      </div>
      <div
        style={{
          maxHeight: "190px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {filteredRefValueArray &&
          filteredRefValueArray.map((list) => (
            <div className="row log-popover-data-padding" hidden={list.hide}>
              <div className="col-lg-3 log-popover-data">{list.name}</div>
              <div className="col-lg-4">
                <span>{list.oldValue}</span>
              </div>
              <div className="col-lg-4">
                <span>{list.newValue}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
  return (
    <Popover
      getPopupContainer={(node) => node.parentNode}
      overlayStyle={{ width: 500 }}
      trigger="click"
      content={content}
    >
      <span
        hidden={parsedRefValue === undefined}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {filteredRefValueArray && filteredRefValueArray.length <= 0
          ? " "
          : "Changed Logs "}
      </span>
    </Popover>
  );
};

export default ChangedLogPopover;
