import { ACTION_TYPE_CORPORATE_CLIENT } from "../constants/Type";

export const corporateClientRequestMade = (bool) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.CORPORATE_CLIENT_REQUEST_MADE,
    bool,
  };
};

export const fetchCorporateInfoSuccess = (corporate) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.FETCH_CORPORATE_INFO_SUCCESS,
    corporate,
  };
};

export const fetchCorporateInfoFailure = (error) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.FETCH_CORPORATE_INFO_FAILURE,
    error,
  };
};

export const addCorporateClientSuccess = (bool) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.ADD_CORPORATE_CLIENT_SUCCESS,
    bool,
  };
};

export const addCorporateClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.ADD_CORPORATE_CLIENT_FAILURE,
    error,
  };
};

export const handleChangeCorporateClient = (id, value) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.HANDLE_CHANGE_CORPORATE_CLIENT,
    id,
    value,
  };
};

export const handleChangeCorporateClientEdit = (id, value) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.HANDLE_CHANGE_CORPORATE_CLIENT_EDIT,
    id,
    value,
  };
};

export const assignClientToCorporateSuccess = (bool) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.ASSIGN_CLIENT_TO_CORPORATE_SUCCESS,
    bool,
  };
};

export const assignClientToCorporateFailure = (error) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.ASSIGN_CLIENT_TO_CORPORATE_FAILURE,
    error,
  };
};

export const deleteCorporateClientSuccess = (corporateClientId) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.DELETE_CORPORATE_CLIENT_SUCCESS,
    corporateClientId,
  };
};

export const deleteCorporateClientFailure = (error) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.DELETE_CORPORATE_CLIENT_FAILURE,
    error,
  };
};

export const setCorporateClientToEdit = (corporateClientData) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.SET_CORPORATE_CLIENT_TO_EDIT,
    corporateClientData,
  };
};

export const closeSnackBarForCorporateClient = (bool) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.CLOSE_SNACK_BAR_CORPORATE_CLIENT_PAGE,
    bool,
  };
};

export const searchClientBySsnSuccess = (client) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.SEARCH_CLIENT_SUCCESS,
    client,
  };
};

export const searchClientBySsnFail = (error) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.SEARCH_CLIENT_FAILURE,
    error,
  };
};

export const fetchClientSuccessInCorporateClient = (client) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.CLIENT_FETCH_SUCCESS,
    client,
  };
};

export const fetchClientFailureInCorporateClient = (error) => {
  return {
    type: ACTION_TYPE_CORPORATE_CLIENT.CLIENT_FETCH_FAILURE,
    error,
  };
};
