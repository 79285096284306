import { connect } from "react-redux";
import { ClientScreen } from "../../screens";
import {
  globalSearchFailure,
  globalSearchSuccess,
  requestForDashboard,
} from "../../actions/DashboardAction";
import DashboardAPI from "../../api/DashboardAPI";
import GlobalSearchComponent from "../GlobalSearchComponent";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchDocument: (term, props) => {
      dispatch(requestForDashboard(true));
      DashboardAPI.searchGlobalData(term, (response, error) => {
        if (response) {
          dispatch(globalSearchSuccess(response));
        } else {
          if (error.msg) {
            dispatch(globalSearchFailure(error.msg));
          } else {
            dispatch(globalSearchFailure(false));
          }
        }
      });
    },
  };
};

export const GlobalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSearchComponent);
