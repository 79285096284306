import { connect } from "react-redux";
import { DashboardScreen } from "../../screens";
import { ClientAPI, DashboardAPI, TransactionAPI, VendorAPI } from "../../api";
import {
  fetchCaseDataForDashboardFailure,
  fetchCaseDataForDashboardSuccess,
  fetchOtherStatForDashboardFailure,
  fetchOtherStatForDashboardSuccess,
  fetchTransactionDataForDashboardFailure,
  fetchTransactionDataForDashboardSuccess,
  requestForDashboard,
} from "../../actions/DashboardAction";
import {
  fetchSubmitTransactionFailure,
  fetchSubmitTransactionSuccess,
  searchTransactionByMediumAndStatusAndDateFailure,
  searchTransactionByMediumAndStatusAndDateSuccess,
  transactionRequestMade,
} from "../../actions/TransactionAction";
import {
  clientRequestMade,
  corporateRequestMade,
  fetchClientFailure,
  fetchClientSuccess,
  fetchCorporateFailure,
  fetchCorporateSuccess,
  fetchVendorFailure,
  fetchVendorSuccess,
  vendorRequestMade,
} from "../../actions";
import CorporateAPI from "../../api/CorporateAPI";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDataForDashboard: () => {
      dispatch(requestForDashboard(true));
      DashboardAPI.fetchDataForDashboard((response, error) => {
        if (response) {
          dispatch(fetchCaseDataForDashboardSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCaseDataForDashboardFailure(error.msg));
          } else {
            dispatch(fetchCaseDataForDashboardFailure(error));
          }
        }
      });
    },
    searchTransactionByAll: (medium, status, type, from, to, vendor) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByAll(
        medium,
        status,
        type,
        from,
        to,
        vendor,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchTransactionByMediumAndStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchTransactionByMediumAndStatusAndDateFailure(error));
            }
          } else {
            dispatch(
              searchTransactionByMediumAndStatusAndDateSuccess(response)
            );
          }
        }
      );
    },
    fetchTransactionList: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchTransactionList((response, error) => {
        if (response) {
          dispatch(searchTransactionByMediumAndStatusAndDateSuccess(response));
        } else {
          if (error.msg) {
            dispatch(
              searchTransactionByMediumAndStatusAndDateFailure(error.msg)
            );
          } else {
            dispatch(searchTransactionByMediumAndStatusAndDateFailure(error));
          }
        }
      });
    },
    fetchTransactionDataForDashboard: () => {
      dispatch(requestForDashboard(true));
      DashboardAPI.fetchTransactionDataForDashboard((response, error) => {
        if (response) {
          dispatch(fetchTransactionDataForDashboardSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchTransactionDataForDashboardFailure(error.msg));
          } else {
            dispatch(fetchTransactionDataForDashboardFailure(error));
          }
        }
      });
    },
    fetchClientList: () => {
      dispatch(clientRequestMade(true));
      ClientAPI.fetchClientList((response, error) => {
        if (response) {
          dispatch(fetchClientSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchClientFailure(error.msg));
          } else {
            dispatch(fetchClientFailure(error));
          }
        }
      });
    },
    fetchCorporate: () => {
      dispatch(corporateRequestMade(true));
      CorporateAPI.fetchCorporateList((response, error) => {
        if (response) {
          dispatch(fetchCorporateSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchCorporateFailure(error.msg));
          } else {
            dispatch(fetchCorporateFailure(error));
          }
        }
      });
    },
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    fetchSubmitTransactionList: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchSubmitTransactionList((response, error) => {
        if (response) {
          dispatch(fetchSubmitTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchSubmitTransactionFailure(error.msg));
          } else {
            dispatch(fetchSubmitTransactionFailure(error));
          }
        }
      });
    },

    fetchOtherStatForDashboard: () => {
      dispatch(requestForDashboard(true));
      DashboardAPI.fetchOtherStatusForDashboard((response, error) => {
        if (response) {
          dispatch(fetchOtherStatForDashboardSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchOtherStatForDashboardFailure(error.msg));
          } else {
            dispatch(fetchOtherStatForDashboardFailure(error));
          }
        }
      });
    },
  };
};

export const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardScreen);
