import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import MySnackbarContentWrapper from "../components/Snackbar";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TablePagination from "@material-ui/core/TablePagination";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Select, Tag, Tabs, Popover } from "antd";
// import moment from "moment";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import DialogTitleComponent from "./DialogTitleComponent";
import { HelmetComponent } from "../components/Helmet";
import PaperComponent from "./PaperComponent";
import { Avatar } from "antd";
import NotesPopover from "../components/NotesPopover";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import {
  FilterMediumData,
  FilterUPTypeData,
} from "../components/FilterOptionList";
import {
  getRangeType,
  transactionMediumHelper,
  transactionTypeHelper,
} from "./TransactionHelper";
import CheckboxFilterPopover from "../components/CheckboxFilterPopover";
import ButtonComponent from "./ButtonComponent";

const { TabPane } = Tabs;
const { Option } = Select;

class IndividualClientUpcomingTxnScreen extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    var pageURL = window.location.href;
    this.state = {
      page: 0,
      rowsPerPage: 10,
      openModalToFilterTransaction: false,
      openDialogueToProcess: false,
      setValue: "",
      upcomingTrans: {},
      clientId: pageURL.substr(pageURL.lastIndexOf("/") + 1),
      isAllMediumChecked: true,
      isAllUPTypeCheked: true,
      isMediumCheck: FilterMediumData.map((medium) => medium.name),
      isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      order: "desc",
      orderBy: "transactionTimestamp",
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,
      configName: "",
    };
  }

  componentDidMount() {
    this.props.getClientUpcomingTrans(this.state.clientId);
    this.props.fetchSingleClient(this.state.clientId, this.props);
  }

  handleAllMediumCheckbox = () => {
    this.setState({ isAllMediumChecked: !this.state.isAllMediumChecked });
    this.setState({ isMediumCheck: FilterMediumData.map((li) => li.name) });
    if (this.state.isAllMediumChecked) {
      this.setState({ isMediumCheck: [] });
    }
  };

  handleAllUPTypeCheckbox = () => {
    this.setState({ isAllUPTypeCheked: !this.state.isAllUPTypeCheked });
    this.setState({ isUPTypeCheck: FilterUPTypeData.map((li) => li.name) });
    if (this.state.isAllUPTypeCheked) {
      this.setState({ isUPTypeCheck: [] });
    }
  };

  handleSelectedMediumChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isMediumCheck: [...this.state.isMediumCheck, value] });
    if (!checked) {
      this.setState({
        isMediumCheck: this.state.isMediumCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  handleSelectedUPTypeChange = (e) => {
    const { value, checked } = e.target;
    this.setState({ isUPTypeCheck: [...this.state.isUPTypeCheck, value] });
    if (!checked) {
      this.setState({
        isUPTypeCheck: this.state.isUPTypeCheck.filter(
          (item) => item !== value
        ),
      });
    }
  };

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };
  render() {
    const upcomingTransactionState =
      this.props && this.props.upcomingTransactionState;
    const clientUpcomingTransaction =
      upcomingTransactionState &&
      upcomingTransactionState.clientUpcomingTransaction;
    const loading =
      upcomingTransactionState && upcomingTransactionState.loading;
    const openModalToSkipTransaction =
      upcomingTransactionState &&
      upcomingTransactionState.openModalToSkipTransaction;
    const remarkToSkip =
      upcomingTransactionState && upcomingTransactionState.remarkToSkip;

    const clientState = this.props && this.props.clientState;
    const clientData = clientState && clientState.clientData;

    const selectedClientName = clientData.firstName;
    const transactionState = this.props && this.props.transactionState;
    const startDate = transactionState && transactionState.from;
    const endDate = transactionState && transactionState.to;
    // const mediumType = transactionState && transactionState.mediumType;
    const vendorToFilter = transactionState && transactionState.vendorToFilter;
    // const transactionType = transactionState && transactionState.transType;

    const vendorList = this.props && this.props.vendorState.vendorAccount;
    const skipTransactionValue = remarkToSkip !== "";

    // new filter
    const UPDate =
      upcomingTransactionState && upcomingTransactionState.filterDate;
    const UPAmount =
      upcomingTransactionState && upcomingTransactionState.filterAmount;
    const UPUser =
      upcomingTransactionState && upcomingTransactionState.filterUser;
    const UPVendor =
      upcomingTransactionState && upcomingTransactionState.filterVendor;
    const UPMedium =
      upcomingTransactionState && upcomingTransactionState.filterMedium;
    const UPType =
      upcomingTransactionState && upcomingTransactionState.filterType;
    const UPSchDate =
      upcomingTransactionState && upcomingTransactionState.filterSchedule;
    const compareType =
      upcomingTransactionState && upcomingTransactionState.compareType;
    const configlist = transactionState && transactionState.configlist;

    const handleSkipTransaction = () => {
      const dataToSkip = {
        scheduleId: this.state.upcomingTrans.scheduleTransactionId,
        skipTimestamp: this.state.upcomingTrans.upcomingTxnDate,
        remark: remarkToSkip,
      };
      this.props.skipUpcomingTransaction(
        dataToSkip,
        this.state.clientId,
        this.props
      );
    };

    const handleInitialCheckboxValue = () => {
      this.setState({
        isAllMediumChecked: true,
        isAllUPTypeCheked: true,
        isMediumCheck: FilterMediumData.map((medium) => medium.name),
        isUPTypeCheck: FilterUPTypeData.map((type) => type.name),
      });
    };
    const handleCloseDialogueToProcess = () => {
      this.setState({
        openDialogueToProcess: false,
      });
    };
    const handleOpenDialogueToProcess = () => {
      this.setState({
        openDialogueToProcess: true,
      });
    };

    const handleOkTransaction = (upcomingTrans) => {
      const dataToProceed = {
        scheduleId: upcomingTrans.scheduleTransactionId,
        clientId: upcomingTrans.client.clientId,
        vendorId: upcomingTrans.vendorAccount.vendorId,
        amount: upcomingTrans.amount,
        note: upcomingTrans.note ? upcomingTrans.note : "",
        transactionType: upcomingTrans.transactionType,
        transactionMedium: upcomingTrans.transactionMedium,
        document: upcomingTrans.document ? upcomingTrans.document : "",
        bankDetailId: upcomingTrans.bankDetailId
          ? upcomingTrans.bankDetailId
          : "",
        description: upcomingTrans.description ? upcomingTrans.description : "",
        upcomingTxnDate: upcomingTrans.upcomingTxnDate,
        isProcessed: true,
        isLedger: upcomingTrans.isLedger,
      };
      this.props.processUpcomingTransaction(dataToProceed, this.props);
      this.setState({
        openDialogueToProcess: false,
      });
    };

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };

    const handleOpenTransactionFilterModal = () => {
      handleInitialCheckboxValue();
      this.setState({
        openModalToFilterTransaction: true,
      });
      this.props.handleChangeDateFrom(null);
      this.props.handleChangeDateTo(null);
      this.props.handleChangeMedium("UNKNOWN_TRANSACTION_MEDIUM");
      this.props.handleChangeVendor("");
    };

    const handleCloseTransactionFilterModal = () => {
      this.setState({
        openModalToFilterTransaction: false,
      });
    };
    // const filteredValue =
    //   startDate !== 0 ||
    //   endDate !== 0 ||
    //   mediumType !== "UNKNOWN_TRANSACTION_MEDIUM" ||
    //   transactionType !== "UNKNOWN_TRANSACTION_TYPE" ||
    //   vendorToFilter !== "";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    // const getPickerDate = (dateToConvert) => {
    //   let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
    //     "fr-CA"
    //   );
    //   return moment(dateConverted, "YYYY-MM-DD");
    // };

    function descendingComparator(a, b, orderBy) {
      if (orderBy === "balance") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "cases") {
        let first = b.client[orderBy] ? b.client[orderBy].length : 0;
        let second = a.client[orderBy] ? a.client[orderBy].length : 0;
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return null;
      } else if (orderBy === "refId") {
        return a[orderBy]
          .toString()
          .localeCompare(b[orderBy].toString(), "eng", {
            numeric: true,
          });
      } else if (orderBy === "firstName") {
        let first = a.Client && a.Client[orderBy] ? a.Client[orderBy] : "z";
        let second = b.Client && b.Client[orderBy] ? b.Client[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "firstNameUP") {
        let first = a.client && a.client.firstName ? a.client.firstName : "z";
        let second = b.client && b.client.firstName ? b.client.firstName : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "vendorName") {
        let first =
          a.vendorAccount && a.vendorAccount[orderBy]
            ? a.vendorAccount[orderBy]
            : "z";
        let second =
          b.vendorAccount && b.vendorAccount[orderBy]
            ? b.vendorAccount[orderBy]
            : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "checkPrintDate") {
        let first =
          a.checkPrint && a.checkPrint[orderBy] ? a.checkPrint[orderBy] : "z";
        let second =
          b.checkPrint && b.checkPrint[orderBy] ? b.checkPrint[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "scheduleDate ") {
        let first =
          a.transactionDetails && a.transactionDetails[orderBy]
            ? a.transactionDetails[orderBy]
            : "0";
        let second =
          b.transactionDetails && b.transactionDetails[orderBy]
            ? b.transactionDetails[orderBy]
            : "0";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "scheduledDate") {
        let first =
          a.scheduleDetail && a.scheduleDetail[orderBy]
            ? a.scheduleDetail[orderBy]
            : "0";
        let second =
          b.scheduleDetail && b.scheduleDetail[orderBy]
            ? b.scheduleDetail[orderBy]
            : "0";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return null;
      }
    }

    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }

    const sortedUpcomingTrans = getComparator(
      clientUpcomingTransaction,
      this.state.order,
      this.state.orderBy
    );

    const filterStatusUP =
      UPDate !== null ||
      UPAmount !== "" ||
      UPUser !== "" ||
      UPVendor !== "" ||
      UPMedium !== "" ||
      UPType !== "" ||
      UPSchDate !== "";

    const configStatus = this.state.configName !== "" && filterStatusUP;

    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
      });
    };

    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        type: 11,
        transactionDate: UPDate === null ? "" : UPDate,
        balance: UPAmount,
        clientName: UPUser,
        vendorName: UPVendor,
        transactionMedium: UPMedium,
        transactionType: UPType,
        scheduleDate: UPSchDate === null ? "" : UPSchDate,
        compareType: compareType,
        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };

      this.props.saveInConfigfor(data);
      this.props.clearUpTransFilter();
    };
    const handleUseConfigFilter = (config) => {
      if (config.scheduleFilter.amount) {
        this.props.handleUpTransFilter(
          "filterAmount",
          config.scheduleFilter.amount
        );
      }
      if (config.scheduleFilter.clientName) {
        this.props.handleUpTransFilter(
          "filterUser",
          config.scheduleFilter.clientName
        );
      }
      if (config.scheduleFilter.scheduleDate !== "0") {
        this.props.handleUpTransFilter(
          "filterSchedule",
          config.scheduleFilter.scheduleDate
        );
      }
      if (config.scheduleFilter.transactionDate) {
        this.props.handleUpTransFilter(
          "filterDate",
          config.scheduleFilter.transactionDate
        );
      }
      if (config.scheduleFilter.transactionMedium) {
        this.props.handleUpTransFilter(
          "filterMedium",
          config.scheduleFilter.transactionMedium
        );
      }
      if (config.scheduleFilter.transactionType) {
        this.props.handleUpTransFilter(
          "filterType",
          config.scheduleFilter.transactionType
        );
      }
      if (config.scheduleFilter.vendorName) {
        this.props.handleUpTransFilter(
          "filterVendor",
          config.scheduleFilter.vendorName
        );
      }
      if (config.scheduleFilter.comparator) {
        this.props.handleUpTransFilter(
          "compareType",
          getRangeType(config.scheduleFilter.comparator)
        );
      }

      this.props.searchUpcomingTransactionByClient(
        // config.scheduleFilter.clientName
        //   ? config.scheduleFilter.clientName
        //   : "",
        this.state.clientId,
        config.scheduleFilter.vendorName
          ? config.scheduleFilter.vendorName
          : "",
        config.scheduleFilter.transactionMedium
          ? config.scheduleFilter.transactionMedium
          : "",
        config.scheduleFilter.transactionType
          ? config.scheduleFilter.transactionType
          : "",

        config.scheduleFilter.transactionDate
          ? config.scheduleFilter.transactionDate
          : "0",
        config.scheduleFilter.scheduleDate
          ? config.scheduleFilter.scheduleDate
          : "0",
        config.scheduleFilter.amount ? config.scheduleFilter.amount : "",
        config.scheduleFilter.comparator
          ? getRangeType(config.scheduleFilter.comparator)
          : ""
      );
    };
    return (
      <React.Fragment>
        <HelmetComponent title="Upcoming Transaction" />
        {loading ? (
          <div className={loading ? "loader" : "loader hidden"} id="loader">
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs
          aria-label="breadcrumb"
          className="breadcrumb"
          style={style.breadcrumb}
        >
          <Link
            color="inherit"
            to="/dashboard"
            className="link"
            style={style.link}
          >
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link
            color="inherit"
            to="/transaction/upcoming"
            className="link"
            style={style.link}
            onClick={() => {
              localStorage.setItem("transTabKey", "upcomingTransaction");
            }}
          >
            Upcoming Transaction
          </Link>
          {clientUpcomingTransaction ? (
            <Typography color="inherit" className="link">
              {clientUpcomingTransaction &&
                clientUpcomingTransaction.length > 0 &&
                clientUpcomingTransaction[0] &&
                clientUpcomingTransaction[0].client.firstName +
                  " " +
                  clientUpcomingTransaction[0].client.lastName}
            </Typography>
          ) : clientData ? (
            <Typography color="inherit" className="link">
              {clientData.firstName + " " + clientData.lastName}
            </Typography>
          ) : null}
        </Breadcrumbs>

        <div
          style={{
            paddingTop: "58px",
            margin: "0 3%",
          }}
        >
          <Tabs
            defaultActiveKey="upcoming"
            onChange={(key) => {
              this.props.history.push(`/transaction/${key}`);
            }}
            className="fixed-tab"
          >
            <TabPane tab="Transactions" key="list" />
            <TabPane tab="Schedule Transaction" key="schedule" />
            <TabPane tab="Upcoming Transaction" key="upcoming" />
            <TabPane tab="Annual Transaction" key="annual" />
            <TabPane tab="Ledger Transaction" key="ledger" />
            <TabPane tab="Deleted Transaction" key="delete" />
          </Tabs>
        </div>
        <div style={style.container}>
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            <div>
              <button
                className="filterButton"
                onClick={() => {
                  this.props.getClientUpcomingTrans(this.state.clientId);
                  this.props.clearUpTransFilter();
                }}
              >
                <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <button
                style={style.filterButton}
                onClick={() => {
                  this.props.fetchVendor();
                  handleOpenTransactionFilterModal();
                }}
              >
                <FilterListIcon style={{ color: "#fff", marginTop: "-2px" }} />
              </button>
            </div>
            <div style={{ display: "flex" }}>
              <div className="search">
                <div style={style.searchIcon}>
                  <SearchIcon style={{ color: "#9c9c9c" }} />
                </div>
                <InputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  style={style.inputBase}
                  onChange={(e) => {
                    this.setState({
                      page: 0,
                    });
                    e.preventDefault();
                    this.props.fetchClientUpcomingTransactionList(
                      this.state.clientId,
                      0,
                      0,
                      "CHECK&medium=CASH&medium=WIRE_TRANSFER&medium=ACH&medium=CARD&medium=LEDGER&medium=OTHER_MEDIUM",
                      e.target.value,
                      "",
                      "DISBURSEMENT&transactionType=DEPOSIT"
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="tableHeader">#</TableCell>
                    <TableCell
                      className="first-tableHeader"
                      style={{ width: "230px" }}
                      key="upcomingTxnDate"
                      sortDirection={
                        this.state.orderBy === "upcomingTxnDate"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "upcomingTxnDate"}
                        direction={
                          this.state.orderBy === "upcomingTxnDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("upcomingTxnDate")}
                      >
                        Transaction Date/Time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeaderAmount"
                      key="amount"
                      sortDirection={
                        this.state.orderBy === "amount"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "amount"}
                        direction={
                          this.state.orderBy === "amount"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("amount")}
                      >
                        Amount
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="vendorName"
                      sortDirection={
                        this.state.orderBy === "vendorName"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "vendorName"}
                        direction={
                          this.state.orderBy === "vendorName"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("vendorName")}
                      >
                        Vendor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionMedium"
                      sortDirection={
                        this.state.orderBy === "transactionMedium"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionMedium"}
                        direction={
                          this.state.orderBy === "transactionMedium"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionMedium")}
                      >
                        Medium
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionType"
                      sortDirection={
                        this.state.orderBy === "transactionType"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionType"}
                        direction={
                          this.state.orderBy === "transactionType"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionType")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="scheduledDate"
                      sortDirection={
                        this.state.orderBy === "scheduledDate"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "scheduledDate"}
                        direction={
                          this.state.orderBy === "scheduledDate"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("scheduledDate")}
                      >
                        Scheduled Date/By/Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="tableHeader"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="sticky-search-bar">
                    <TableCell className="first-tableBody" />
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={UPDate === null ? "" : UPDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleUpTransFilter(
                              "filterDate",
                              e.target.value
                            );

                            this.props.searchUpcomingTransactionByClient(
                              selectedClientName,
                              UPVendor,
                              UPMedium,
                              UPType,

                              e.target.value,
                              UPSchDate,
                              UPAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={UPAmount}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleUpTransFilter(
                              "filterAmount",
                              e.target.value
                            );

                            this.props.searchUpcomingTransactionByClient(
                              this.state.clientId,
                              UPVendor,
                              UPMedium,
                              UPType,

                              UPDate,
                              UPSchDate,
                              e.target.value,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={UPVendor}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleUpTransFilter(
                              "filterVendor",
                              e.target.value
                            );

                            this.props.searchUpcomingTransactionByClient(
                              this.state.clientId,
                              e.target.value,
                              UPMedium,
                              UPType,

                              UPDate,
                              UPSchDate,
                              UPAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={UPMedium}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleUpTransFilter("filterMedium", e);
                            this.props.searchUpcomingTransactionByClient(
                              this.state.clientId,
                              UPVendor,
                              e,
                              UPType,

                              UPDate,
                              UPSchDate,
                              UPAmount,
                              compareType
                            );
                          }}
                        >
                          <Option value="CHECK">Check</Option>
                          <Option value="CASH">Cash</Option>
                          <Option value="WIRE_TRANSFER">Wire Transfer</Option>
                          <Option value="ACH">ACH</Option>
                          <Option value="CARD">Card</Option>
                          <Option value="LEDGER">Ledger</Option>
                          <Option value="OTHER_MEDIUM">Other medium</Option>
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={UPType}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleUpTransFilter("filterType", e);
                            this.props.searchUpcomingTransactionByClient(
                              this.state.clientId,
                              UPVendor,
                              UPMedium,
                              e,

                              UPDate,
                              UPSchDate,
                              UPAmount,
                              compareType
                            );
                          }}
                        >
                          <Option value="DISBURSEMENT">Disbursement</Option>
                          <Option value="DEPOSIT">Deposit</Option>
                          <Option value="FEE">Fee</Option>
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={UPSchDate === null ? "" : UPSchDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleUpTransFilter(
                              "filterSchedule",
                              e.target.value
                            );

                            this.props.searchUpcomingTransactionByClient(
                              this.state.clientId,
                              UPVendor,
                              UPMedium,
                              UPType,

                              UPDate,

                              e.target.value,
                              UPAmount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell style={{ paddingLeft: "0", cursor: "pointer" }}>
                      <Popover
                        placement="bottom"
                        trigger="click"
                        content={
                          <>
                            <div
                              role="presentation"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  OpenSaveFilterInConfigmenu: true,

                                  openConfigMenu: false,
                                });
                              }}
                              className="cursorP"
                            >
                              Save In Config
                            </div>

                            <div
                              role="presentation"
                              style={{
                                padding: "0.2rem",
                              }}
                              onClick={(e) => {
                                e.preventDefault();

                                this.props.getConfigList("4");
                                this.setState({
                                  OpenViewFilterInConfig: true,

                                  openConfigMenu: false,
                                });
                              }}
                              className="cursorP"
                            >
                              View Config
                            </div>
                          </>
                        }
                      >
                        <MoreHorizIcon
                          onClick={(e) => {
                            e.preventDefault();

                            this.setState({
                              popoverElement: e.currentTarget,

                              openConfigMenu: true,
                            });
                          }}
                        />
                      </Popover>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {sortedUpcomingTrans &&
                  sortedUpcomingTrans
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list, index) => (
                      <TableBody>
                        <TableRow
                          style={
                            (list.transactionType === "DISBURSEMENT" ||
                              list.transactionType === "FEE") &&
                            list.isFundAvailable === undefined
                              ? {
                                  cursor: "pointer",
                                  background: "#fff1f0",
                                }
                              : { cursor: "pointer" }
                          }
                        >
                          <TableCell>
                            {" "}
                            {(this.state.page + 1 - 1) *
                              this.state.rowsPerPage +
                              (index + 1)}{" "}
                          </TableCell>
                          <TableCell className="first-tableBody">
                            {new Intl.DateTimeFormat("default", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(list.upcomingTxnDate)}
                          </TableCell>
                          <TableCell className="tableAmountBody">
                            {list.amount && formatter.format(list.amount / 100)}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.vendorAccount.vendorName}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.transactionMedium}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.transactionType}
                          </TableCell>
                          <TableCell className="">
                            {list.scheduleDetail ? (
                              <div>
                                <div>
                                  {list.createdAt &&
                                    new Intl.DateTimeFormat("en-US", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                    }).format(
                                      list.createdAt ? list.createdAt : "-"
                                    )}
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  {list.scheduleDetail.scheduleByName ? (
                                    <>
                                      <Avatar
                                        style={{
                                          width: "28px",
                                          height: "28px",
                                          marginRight: "8px",
                                        }}
                                        src={
                                          list.scheduleDetail.profileUrl
                                            ? list.scheduleDetail.profileUrl
                                            : "/assets/images/profile.svg"
                                        }
                                      />
                                      <span>
                                        {list.scheduleDetail.scheduleByName}
                                      </span>
                                      <span> / </span>
                                      <span>
                                        {list.scheduleDetail.scheduleType &&
                                          list.scheduleDetail.scheduleType.replace(
                                            "_",
                                            " "
                                          )}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            {list.isSkipped === undefined &&
                            list.allowProcessNow === true ? (
                              <div style={{ display: "flex" }}>
                                <Button
                                  style={style.skipNow}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      upcomingTrans: list,
                                      isProcess: false,
                                    });
                                    this.props.handleChangeToOpenSkipModal(
                                      true
                                    );
                                    this.props.handleChangeRemarkToSkip("");
                                  }}
                                >
                                  <span style={{ fontSize: "10px" }}>Skip</span>
                                </Button>
                                <Button
                                  style={style.processNow}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      upcomingTrans: list,
                                      isProcess: true,
                                    });
                                    handleOpenDialogueToProcess();
                                  }}
                                >
                                  <span style={{ fontSize: "10px" }}>
                                    Process Now
                                  </span>
                                </Button>
                              </div>
                            ) : list.isSkipped === undefined &&
                              list.allowProcessNow === undefined ? (
                              <Button
                                style={style.skipNow}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.setState({
                                    upcomingTrans: list,
                                    isProcess: false,
                                  });
                                  this.props.handleChangeToOpenSkipModal(true);
                                  this.props.handleChangeRemarkToSkip("");
                                }}
                              >
                                <span style={{ fontSize: "10px" }}>Skip</span>
                              </Button>
                            ) : list.isSkipped === true ? (
                              <>
                                <Tag color="orange">Skipped</Tag>
                                {list.remark ? (
                                  <NotesPopover
                                    content={list.remark}
                                    isRemark
                                  />
                                ) : null}
                              </>
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {sortedUpcomingTrans && sortedUpcomingTrans.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedUpcomingTrans.length > 0
                      ? sortedUpcomingTrans.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>
        {/*FILTER FOR UPCOMING TRANSACTION*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openModalToFilterTransaction}
          maxWidth="lg"
          onClose={handleCloseTransactionFilterModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseTransactionFilterModal}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeDateFrom(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeDateTo(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeDateFrom(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeDateTo(date);
              }}
            />
            <label style={style.label}> Medium </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterMediumData}
              isItemCheck={this.state.isMediumCheck}
              isAllCheck={this.state.isAllMediumChecked}
              handleAllCheckbox={this.handleAllMediumCheckbox}
              handleSelectedChange={this.handleSelectedMediumChange}
            />
            <label style={style.label}> Type </label>
            <CheckboxFilterPopover
              forMappingFilterData={FilterUPTypeData}
              isItemCheck={this.state.isUPTypeCheck}
              isAllCheck={this.state.isAllUPTypeCheked}
              handleAllCheckbox={this.handleAllUPTypeCheckbox}
              handleSelectedChange={this.handleSelectedUPTypeChange}
            />
            <label style={style.label}> Vendor </label>
            <div className="select-input h40">
              <Select
                style={style.inputField}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(node) => node.parentNode}
                // style={{ width: 200 }}
                onChange={(e) => {
                  this.props.handleChangeVendor(e);
                }}
              >
                {vendorList &&
                  vendorList.map((list) => (
                    <Option value={list.vendorId}>{list.vendorName}</Option>
                  ))}
              </Select>
            </div>
            <div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        page: 0,
                      });
                      this.props.fetchClientUpcomingTransactionList(
                        this.state.clientId,
                        startDate,
                        endDate,
                        // mediumType,
                        transactionMediumHelper(this.state.isMediumCheck),
                        "",
                        vendorToFilter,
                        // transactionType
                        transactionTypeHelper(
                          this.state.isUPTypeCheck,
                          "transactionType"
                        )
                      );
                      handleCloseTransactionFilterModal();
                    }}
                    // disabled={!filteredValue}
                    // className={
                    //   filteredValue ? "addButton" : "disabledAddButton"
                    // }
                    className="addButton"
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/*PROCESS NOW*/}
        <Dialog
          open={this.state.openDialogueToProcess}
          onClose={handleCloseDialogueToProcess}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to proceed transaction?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueToProcess}
                    style={style.cancel}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleOkTransaction(this.state.upcomingTrans);
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*SKIP UPCOMING TRANSACTION*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={openModalToSkipTransaction}
          maxWidth="lg"
          onClose={() => {
            this.props.handleChangeToOpenSkipModal(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              this.props.handleChangeToOpenSkipModal(false);
            }}
          >
            Skip Transaction
          </DialogTitleComponent>
          <DialogContent dividers className="filterDialog">
            <div>
              <div className="row">
                <div className="col-12 mt-4">
                  <label htmlFor="amount" style={style.columnHeading}>
                    Remark
                    <span style={{ color: "red" }}> *</span>{" "}
                  </label>
                  <input
                    id="remark"
                    name="remark"
                    autoComplete="off"
                    className="inputField"
                    onChange={(e) => {
                      this.setState({
                        isBlocking: true,
                      });
                      this.props.handleChangeRemarkToSkip(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSkipTransaction();
                    }}
                    disabled={!skipTransactionValue}
                    className={
                      skipTransactionValue ? "addButton" : "disabledAddButton"
                    }
                  >
                    Skip
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.upcomingTransactionState.openErrorProcessSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleChangeUpcomingTransSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeUpcomingTransSnackBar}
            variant="error"
            message={this.props.upcomingTransactionState.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.upcomingTransactionState.openProcessSnackBar}
          autoHideDuration={4000}
          onClose={this.props.handleChangeUpcomingTransSnackBar}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleChangeUpcomingTransSnackBar}
            variant="success"
            message="Successfully proceed"
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    margin: "10px 3% 1% 2%",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "23%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    marginTop: "20px",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "180px",
    height: "40px",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
    marginTopL: "48px",
  },
  icon: {
    position: "absolute",
    top: "56px",
    right: "30px",
    color: " rgb(200, 197, 197)",
    fontSize: "32px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },

  filterButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
    // marginRight: "12px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableHeaderAmount: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "right",
    height: "3rem",
    top: 0,
    position: "inherit",
    paddingRight: "48px",
  },
  tableBodyAmount: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "right",
    height: "4.5rem",
    paddingRight: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  flex: {
    paddingBottom: "1rem",
    // paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  exportButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "100px",
    height: "35px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "190px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
    height: "40px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  processNow: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "40%",
    marginRight: "12px",
  },
  skipNow: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "20%",
    marginRight: "12px",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
};

export default IndividualClientUpcomingTxnScreen;
