import {ACTION_TYPE_CASE, ACTION_TYPE_REGISTER_CASE} from "../constants/Type";

export const registerCaseRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.REGISTER_CASE_REQUEST_MADE,
        bool
    };
};

export const fetchClientSuccessInRegisterCase = client => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.CLIENT_FETCH_SUCCESS,
        client
    };
};

export const fetchClientFailureInRegisterCase = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.CLIENT_FETCH_FAILURE,
        error
    };
};

export const addClientSuccess = response => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.CLIENT_ADD_SUCCESS,
        response
    };
};

export const addClientFailure = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.CLIENT_ADD_FAILURE,
        error
    };
};
export const clearClientFormData = () => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.CLEAR_CLIENT_FORM_DATA,
    };
};


export const handleChangeClientInRegisterCase = (id, value) => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.HANDLE_CHANGE_CLIENT_IN_REGISTER_CASE,
        id,
        value
    };
};

export const handleChangeClient = (id, value) => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.HANDLE_CHANGE_CLIENT,
        id,
        value
    };
};

export const handleChangeCaseInRegisterCase = (id, value) => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.HANDLE_CHANGE_CASE_IN_REGISTER_CASE,
        id,
        value
    };
};

export const registerCaseSuccess = bool => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.REGISTER_CASE_SUCCESS,
        bool
    };
};

export const registerCaseFailure = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.REGISTER_CASE_FAILURE,
        error
    };
};
export const setAddSucessFullToFalse = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.SET_ADD_SUCESS_FALSE,
        error
    };
};

export const searchClientSuccess = client => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.SEARCH_CLIENT_BY_SSN_SUCCESS,
        client
    };
};

export const searchClientFail = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.SEARCH_CLIENT_BY_SSN_FAILURE,
        error
    };
};

export const closeSnackBarForRegisterCase = (bool) => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.CLOSE_SNACK_BAR_REGISTER_CASE_PAGE,
        bool
    };
};


export const fetchClientCaseContactsSuccess = clientCaseContact => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.FETCH_CLIENT_CASE_CONTACT_SUCCESS,
        clientCaseContact
    };
};

export const fetchClientCaseContactsFailure = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.FETCH_CLIENT_CASE_CONTACT_FAILURE,
        error
    };
}

export const addClientFormDataSuccess = response => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.ADD_CLIENT_FORM_DATA_SUCCESS,
        response
    };
};

export const addClientFormDataFailure = error => {
    return {
        type: ACTION_TYPE_REGISTER_CASE.ADD_CLIENT_FORM_DATA_FAILURE,
        error
    };
}

