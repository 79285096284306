import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  Button,
  CircularProgress,
  DialogActions,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Typography from "@material-ui/core/Typography";
import PeopleIcon from "@material-ui/icons/People";
import PaperComponent from "./PaperComponent";
import { Checkbox, Select, Tag } from "antd";
import MySnackbarContentWrapper from "../components/Snackbar";
import DialogTitleComponent from "./DialogTitleComponent";
import Helpers from "../components/Helper";
import OpenAddressFields from "../components/DynamicAddressField";
import { HelmetComponent } from "../components/Helmet";
import ButtonComponent from "./ButtonComponent";
import { is } from "@babel/types";
const { Option, OptGroup } = Select;

class VendorScreen extends Component {
  componentDidMount() {
    this.props.fetchVendor();
    this.props.fetchVendorTypeList("");
  }
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      id: '',
      page: 0,
      rowsPerPage: 10,
      sortType: "asc",
      vendorList: "",
      vendorId: "",
      openVendorDetailDialog: false,
      openDeleteVendorDialogue: false,
      openModalToAddVendor: false,
      openModalToUpdateVendor: false,
      openActionMenu: false,
      clicked: false,
      setValue: "",
      errors: {
        vendorName: "",
        vendorType: "",
        address: "",
        contactNumber: "",
        accountHolderName: "",
        bankAccountNumberInVendor: "",
        routingNumberInVendor: "",
        // primaryAddress: "",
        // city: "",
        // zipCode: "",
        // countryState: "",
      },
      address: [],
      addressId: "",
      openDeleteModal: false,
      editAddress: [],
      updatedAddress: [],
    };
  }
  render() {
    const { errors } = this.state;
    const vendors = this.props.vendorState.vendors.vendorAccount;
    const vendorState = this.props && this.props.vendorState;
    const vendorList = vendorState && vendorState.vendorAccount;
    const vendorData = vendorState && vendorState.vendorData;
    const isDefault = vendorState && vendorState.isDefault;
    const loading = vendorState && vendorState.loading;

    const defaultVendorType =
      vendorList &&
      vendorList.filter((list) => list.vendorType && list.vendorType.isDefault);
    const isDefaultVendorTypeTaken =
      defaultVendorType && defaultVendorType.length > 0;
    const value =
      vendorData.vendorName !== "" &&
      vendorData.vendorType !== "" &&
      vendorData.vendorContactNumber !== "" &&
      vendorData.address &&
      vendorData.address.length > 0 &&
      vendorData.address[0].address1 !== "" &&
      vendorData.address[0].city !== "" &&
      vendorData.address[0].state !== "" &&
      vendorData.address[0].zip5 !== "" &&
      vendorData.address[0].isDefault !== "";

    //vendor type list from setting state
    const settingState = this.props && this.props.settingState;
    const vendorTypeList = settingState && settingState.vendorTypeList;
    const vendorTypeWithoutDefaultVendorList =
      vendorTypeList && vendorTypeList.filter((list) => !list.isDefault);
    const vendorTypeToMap = isDefaultVendorTypeTaken
      ? vendorTypeWithoutDefaultVendorList
      : vendorTypeList;

    //*****************address data******************** //
    const showInputForField = () => {
      let a = createAddressObject();

      if (this.state.address.length === 0) {
        this.setState({
          id: a.addressId
        })
      }
      this.setState({
        address: [...this.state.address, a],
      });

    }; // This is for address data field, and it is called in "Add Vendor" button onclick functionality.
    const createAddressObject = () => {
      let randomId = String(Math.random())
      return {
        addressId: randomId,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip5: "",
        // isDefault: true
      };
    };
    const filterAddressData = () => {
      let addressForm = [];
      this.state.address.map((single) => {
        const data = {
          addressId: single.addressId,
          address1: single.address1,
          address2: single.address2,
          city: single.city,
          state: single.state,
          zip5: single.zip5,
          isDefault: single.isDefault
        };
        return addressForm.push(data);
      });
      this.props.handleChangeVendor("address", addressForm);
    };
    const filterEditAddressData = (id, value) => {
      let resultA = this.state.updatedAddress.filter((elm) =>
        this.state.address
          .map((elm) => JSON.stringify(elm))
          .includes(JSON.stringify(elm))
      );
      let resultB = this.state.address
        .filter(
          (elm) =>
            !this.state.updatedAddress
              .map((elm) => JSON.stringify(elm))
              .includes(JSON.stringify(elm))
        )
        .map((single) => {
          return {
            addressId: single.addressId,
            address1: single.address1,
            address2: single.address2,
            city: single.city,
            state: single.state,
            zip5: single.zip5,
            isDefault: single.isDefault
          };
        });

      let addressForm = [...resultA, ...resultB];

      addressForm.map(data => {

        if (data.addressId === id) {
          data.isDefault = true;
        } else {
          data.isDefault = false;
        }

        // if (data.addressId !== id) {
        //   delete data.isDefault
        // }  
        return addressForm
      })
      this.props.handleChangeVendor("address", addressForm, "isDefault");
    };

    const handleOpenDeleteDialogue = (addressId) => {
      this.setState({
        openDeleteModal: true,
        addressId: addressId,
      });
    };
    const handleCloseDeleteDialogue = () => {
      this.setState({
        openDeleteModal: false,
        addressId: "",
      });
    };
    const handleOkDeleteDialogue = () => {
      closeFieldSection(this.state.addressId);
      this.setState({
        openDeleteModal: false,
        addressId: "",
      });
    };
    const closeFieldSection = (key) => {
      var removedActionData = this.state.address.filter(
        (single) => single.addressId !== key
      );
      this.setState(
        {
          address: removedActionData,
        },
        () => {
          filterAddressData();
        }
      );
    };

    //OTHERS
    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0
      });
      if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
    };

    // ..vendor details
    const handleOpenDialogueForVendorDetails = () => {
      this.setState({
        openVendorDetailDialog: true,
      });
    };
    const handleCloseDialogueForVendorDetails = () => {
      this.setState({
        openVendorDetailDialog: false,
      });
    };

    // ..delete vendor
    const handleCloseDialogueForVendor = () => {
      this.setState({
        openDeleteVendorDialogue: false,
      });
    };
    const handleOpenDialogueForVendor = (vendorId) => {
      this.setState({
        openDeleteVendorDialogue: true,
        vendorId: vendorId,
      });
    };
    const handleOkDeleteVendorDialogue = () => {
      this.props.deleteVendor(this.state.vendorList.vendorId, this.props);
      this.setState({
        openDeleteVendorDialogue: false,
        vendorId: "",
      });
    };

    // ... add vendor
    const handleOpenDialogueForAddingVendor = () => {
      this.setState({
        openModalToAddVendor: true,
        address: [],
        errors: {
          vendorName: "",
          vendorType: "",
          address: "",
          contactNumber: "",
          accountName: "",
          bankAccountNumber: "",
          routingNumber: "",
        },
      });
      this.props.clearVendorInputData();
    };
    const handleCloseDialogueForAddingVendor = () => {
      this.setState({
        openModalToAddVendor: false,
        address: [],
        errors: {
          vendorName: "",
          vendorType: "",
          address: "",
          contactNumber: "",
          accountName: "",
          bankAccountNumber: "",
          routingNumber: "",
        },
      });
    };

    const editFunctionalityForId = () => {
      const arrayData = vendorState.vendorData.address;
      if (arrayData) {
        this.setState({
          id: arrayData[0].addressId
        })
      }
    }

    // ...update vendor
    const handleOpenDialogueForUpdatingVendor = () => {
      this.setState({
        openModalToUpdateVendor: true,
        errors: {
          vendorName: "",
          vendorType: "",
          address: "",
          contactNumber: "",
          accountName: "",
          bankAccountNumber: "",
          routingNumber: "",
        },
      });
    };
    const handleCloseDialogueForUpdatingVendor = () => {
      this.setState({
        address: [],
        updatedAddress: [],
        openModalToUpdateVendor: false,
        errors: {
          vendorName: "",
          vendorType: "",
          address: "",
          contactNumber: "",
          accountName: "",
          bankAccountNumber: "",
          routingNumber: "",
        },
      });
    };

    return (
      <React.Fragment>
        <HelmetComponent title="Vendor" />
        {this.props.vendorState.loading ? (
          <div
            className={
              this.props.vendorState.loading ? "loader" : "loader hidden"
            }
            id="loader"
          >
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        {this.props.vendorState.addVendorSuccess === true
          ? this.setState(
            {
              openModalToAddVendor: false,
              openModalToUpdateVendor: false,
            },
            () => {
              if (this.state.setValue === "add") {
                this.props.setAddNewVendorSuccessToFalse();
              } else if (this.state.setValue === "edit") {
                this.props.setUpdateNewVendorSuccessToFalse();
              }
            }
          )
          : null}
        <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
          <Link color="inherit" to="/dashboard" style={style.link}>
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Typography color="inherit" className="link">
            Vendor Account
          </Typography>
        </Breadcrumbs>

        <div style={style.container}>
          <div className={"d-flex flex-row-reverse"} style={style.flex}>
            <div>
              <button
                style={style.settingButton}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    sortType: this.state.sortType === "asc" ? "dsc" : "asc",
                  });
                }}
              >
                <img
                  alt={"setting"}
                  src={"assets/images/sort.png"}
                  style={{ width: "18px", height: "18px" }}
                />
              </button>
            </div>
            <div>
              <button
                style={style.addButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleOpenDialogueForAddingVendor();
                  showInputForField(); //If we don't call, then we cannot see the address row field
                }}
              >
                <AddIcon /> Add Vendor
              </button>
            </div>
            <div className="search">
              <div style={style.searchIcon}>
                <SearchIcon style={{ color: "#9c9c9c" }} />
              </div>
              <InputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                style={style.inputBase}
                onChange={(e) => {
                  e.preventDefault();
                  this.props.searchVendor(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table size="small" stickyHeader aria-label="sticky table" ref={this.tableRef}>
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="first-tableHeader">Name</TableCell>
                    <TableCell className="tableHeader">Type</TableCell>
                    <TableCell className="tableHeader">
                      Contact Number
                    </TableCell>
                    <TableCell className="tableHeader">
                      Account Holder Name
                    </TableCell>
                    {/* <TableCell className="tableHeader">
                                            Routing Number
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Bank Account Number
                                        </TableCell> */}
                    <TableCell />
                  </TableRow>
                </TableHead>
                {vendorList &&
                  vendorList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                    )
                    .map((vendor) => (
                      <TableBody key={vendor.vendorId}>
                        <TableRow>
                          <TableCell
                            className="first-tableBody"
                            style={{ width: "18%" }}
                          >
                            <button
                              className="tableButton"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    vendorList: vendor,
                                  },
                                  () => {
                                    this.props.getVendorInfo(
                                      this.state.vendorList.vendorId
                                    );
                                  }
                                );
                                handleOpenDialogueForVendorDetails();
                              }}
                            >
                              {vendor.vendorName}
                              {vendor.vendorType &&
                                vendor.vendorType.isDefault ? (
                                <Tag
                                  color="blue"
                                  style={{
                                    marginLeft: "12px",
                                  }}
                                >
                                  Default
                                </Tag>
                              ) : (
                                ""
                              )}
                            </button>
                          </TableCell>
                          <TableCell className="tableBody">
                            <button
                              className="tableButton"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    vendorList: vendor,
                                  },
                                  () => {
                                    this.props.getVendorInfo(
                                      this.state.vendorList.vendorId
                                    );
                                  }
                                );
                                handleOpenDialogueForVendorDetails();
                              }}
                            >
                              {vendor &&
                                vendor.vendorType &&
                                vendor.vendorType.vendorTypeName
                                ? vendor.vendorType.vendorTypeName
                                : "-"}
                            </button>
                          </TableCell>
                          <TableCell className="tableBody">
                            <button
                              className="tableButton"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    vendorList: vendor,
                                  },
                                  () => {
                                    this.props.getVendorInfo(
                                      this.state.vendorList.vendorId
                                    );
                                  }
                                );
                                handleOpenDialogueForVendorDetails();
                              }}
                            >
                              {vendor.vendorContactNumber}
                            </button>
                          </TableCell>
                          <TableCell
                            className="tableBody"
                            style={{ width: "20%" }}
                          >
                            <button
                              className="tableButton"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    vendorList: vendor,
                                  },
                                  () => {
                                    this.props.getVendorInfo(
                                      this.state.vendorList.vendorId
                                    );
                                  }
                                );
                                handleOpenDialogueForVendorDetails();
                              }}
                            >
                              {vendor.accountName ? vendor.accountName : "-"}
                            </button>
                          </TableCell>
                          <TableCell className="tableBody">
                            <MoreHorizIcon
                              id="Popover1"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState(
                                  {
                                    popoverElement: e.currentTarget,
                                    openActionMenu: true,
                                    vendorList: vendor,
                                    vendorId: vendor.vendorId,
                                  },
                                  () => {
                                    this.props.setVendorToEdit(
                                      this.state.vendorList
                                    );
                                  }
                                );
                              }}
                            />
                            <Popover
                              open={this.state.openActionMenu}
                              anchorEl={this.state.popoverElement}
                              onClose={(e) => {
                                e.preventDefault();
                                this.setState({ openActionMenu: false });
                              }}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ boxShadow: "none" }}
                            >
                              <div>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "0.5rem",
                                    cursor: "pointer",
                                  }}
                                  hidden={
                                    this.state.vendorList &&
                                      this.state.vendorList.vendorType &&
                                      this.state.vendorList.vendorType.isDefault
                                      ? true
                                      : false
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push({
                                      pathname:
                                        "/vendor/" +
                                        this.state.vendorId +
                                        "/clients",
                                    });
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <PeopleIcon style={{ paddingBottom: 0 }} />{" "}
                                  Clients
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "0.5rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setVendorToEdit(
                                      this.state.vendorList
                                    );
                                    editFunctionalityForId();
                                    handleOpenDialogueForUpdatingVendor();
                                    if (
                                      vendorData &&
                                      vendorData.address !== undefined
                                    ) {
                                      this.setState({
                                        // id: vendorData.address.addressId,
                                        address: vendorData.address,
                                        editAddress: vendorData.address,
                                        updatedAddress: vendorData.address,
                                      });
                                    } else {
                                      this.setState({
                                        // address: [],
                                        // editAddress: [],
                                      });
                                    }
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <EditOutlinedIcon /> Edit
                                </MenuList>
                                <MenuList
                                  style={{
                                    color: "#4d4d4d",
                                    padding: "0.5rem",
                                    cursor: "pointer",
                                    display: "none",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDialogueForVendor();
                                    this.setState({ openActionMenu: false });
                                  }}
                                >
                                  <DeleteOutlinedIcon
                                    style={{ paddingBottom: 0 }}
                                  />{" "}
                                  Delete
                                </MenuList>
                              </div>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
              </TableContainer>
              {vendorList && vendorList.length > 0 ? (
                <div style={{ justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                  <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={vendorList.length > 0 ? vendorList.length : 100}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <div style={{     
                  display: "flex", 
                  width: "100%",
                  justifyContent:"center"
                  }}>
                  <h3
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "1rem",
                      color: "#606060",
                    }}
                  >
                    {" "}
                    No data to show
                  </h3>
                </div>
              )}
            {/* </TableContainer> */}
          </div>
        </div>
        {/*Delete vendor*/}
        <Dialog
          open={this.state.openDeleteVendorDialogue}
          onClose={handleCloseDialogueForVendor}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to delete vendor?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "1rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseDialogueForVendor}
                    style={style.cancelButton}
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();

                      handleOkDeleteVendorDialogue();
                    }}
                    style={style.deleteClientButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*Vendor details*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openVendorDetailDialog}
          onClose={handleCloseDialogueForVendorDetails}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForVendorDetails}
          >
            Vendor Details
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              textAlign: "left",
              // marginLeft: '1rem'
            }}
          >
            <DialogContentText>
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  paddingTop: "0.4rem",
                  width: "700px",
                }}
              >
                <span className="col-4">
                  <label htmlFor="ssn" style={style.columnHeading}>
                    Vendor Name
                  </label>
                  <label style={style.clientData}>
                    {vendors && vendors.vendorName}
                  </label>
                </span>
                <span className="col-4">
                  <label htmlFor="firstName" style={style.columnHeading}>
                    Vendor Type
                  </label>
                  <label style={style.clientData}>
                    {vendors &&
                      vendors.vendorType &&
                      vendors.vendorType.vendorTypeName
                      ? vendors.vendorType.vendorTypeName
                      : "-"}
                  </label>
                </span>
                <span className="col-4" style={{ position: "initial" }}>
                  <label htmlFor="gender" style={style.columnHeading}>
                    Contact Number
                  </label>
                  <label style={style.clientData}>
                    {" "}
                    {vendors && vendors.vendorContactNumber}{" "}
                  </label>
                </span>
              </span>
              {vendors && vendors.isWithdrawal ? (
                <div class="mt-2">
                  <CheckBoxIcon
                    style={{
                      marginLeft: "-3px",
                      color: "#8BC34A",
                      width: "19px",
                    }}
                  />{" "}
                  <span
                    style={{
                      fontSize: "15px",
                      paddingTop: "44px",
                      color: "#625f5f",
                    }}
                  >
                    Withdraw Vendor
                  </span>
                </div>
              ) : null}
              {(vendors && vendors.accountName) ||
                (vendors && vendors.routingNumber) ||
                (vendors && vendors.accountNumber) ? (
                <div className="mt-4">
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#cc6724",
                    }}
                  >
                    Bank Details
                  </span>
                </div>
              ) : (
                ""
              )}
              <span
                className="row"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0.5rem",
                  width: "700px",
                }}
              >
                {vendors && vendors.bankName ?
                  <span className="col-4 mt-3">
                    <label htmlFor="dob" style={style.columnHeading}>
                      Bank Name
                    </label>
                    <label style={style.clientData}>
                      {" "}
                      {vendors && vendors.bankName}
                    </label>
                  </span> : ""}
                {vendors && vendors.bankAccountType ?
                  <span className="col-4 mt-3">
                    <label htmlFor="dob" style={style.columnHeading}>
                      Bank Account Type
                    </label>
                    <label style={style.clientData}>
                      {" "}
                      {vendors && vendors.bankAccountType
                        && vendors.bankAccountType.replace("_", " ")}
                    </label>
                  </span> : ""}
                {vendors && vendors.accountName ? (
                  <span className="col-4 mt-3">
                    <label htmlFor="maritalStatus" style={style.columnHeading}>
                      Account Holder Name
                    </label>
                    <label style={style.clientData}>
                      {" "}
                      {vendors && vendors.accountName}{" "}
                    </label>
                  </span>
                ) : (
                  ""
                )}
                {vendors && vendors.accountNumber ? (
                  <span className="col-4 mt-3">
                    <label htmlFor="dob" style={style.columnHeading}>
                      Account Number
                    </label>
                    <label style={style.clientData}>
                      {" "}
                      {vendors && vendors.accountNumber}
                    </label>
                  </span>
                ) : (
                  ""
                )}
                {vendors && vendors.routingNumber ? (
                  <span className="col-4 mt-3">
                    <label htmlFor="dob" style={style.columnHeading}>
                      Routing Number
                    </label>
                    <label style={style.clientData}>
                      {" "}
                      {vendors && vendors.routingNumber}
                    </label>
                  </span>
                ) : (
                  ""
                )}
              </span>
              <span
                className="row"
                hidden
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "2rem",
                  paddingTop: "1.5rem",
                  width: "700px",
                }}
              >
                <span className={"col-4"}></span>
                <span className={"col-4"}></span>
                <span className={"col-4"}>
                  <Button
                    autoFocus
                    style={style.cancelButton}
                    onClick={() => {
                      handleCloseDialogueForVendorDetails();
                    }}
                  >
                    cancel
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {/*Add Vendor*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openModalToAddVendor}
          onClose={handleCloseDialogueForAddingVendor}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={() => {
              handleCloseDialogueForAddingVendor();
            }}
          // style={{width:"68rem"}}
          >
            Add Vendor
          </DialogTitleComponent>
          <DialogContent dividers className="dialogContentWidth850">
            <div className="row mt-2" >
              <div className="col-lg-4">
                <label htmlFor="name" style={style.columnHeading}>
                  Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="name"
                  id="vendorName"
                  name="vendorName"
                  autoComplete="off"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.vendorName.length > 0 && (
                  <span className="error">{errors.vendorName}</span>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="type" style={style.columnHeading}>
                  Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                {vendorTypeToMap && vendorTypeToMap.length === 1 ? (
                  <input
                    className="inputField"
                    value={
                      vendorTypeToMap &&
                      vendorTypeToMap[0] &&
                      vendorTypeToMap[0].vendorTypeName
                    }
                    disabled
                  />
                ) : (
                  <div>
                    <div className="select-input selects h40">
                      <Select
                        showSearch
                        name="clientId"
                        className="drop"
                        getPopupContainer={(node) => node.parentNode}
                        style={{ width: 200 }}
                        onSearch={(value) => {
                          this.props.fetchVendorTypeList(value);
                        }}
                        onInputKeyDown={(e) => {
                          this.setState({
                            showDropdown: true,
                          });
                        }}
                        onChange={(e) => {
                          this.setState({
                            isBlocking: true,
                          });
                          this.props.handleChangeVendor("vendorTypeId", e);
                        }}
                        onSelect={() => {
                          this.setState({
                            showDropdown: false,
                          });
                        }}
                        onBlur={() => {
                          this.setState({
                            showDropdown: false,
                          });
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        defaultOpen={false}
                        dropdownStyle={{
                          maxHeight: "100px",
                          overflowY: "scroll",
                        }}
                      >
                        {vendorTypeToMap &&
                          vendorTypeToMap.map((res) => (
                            <>
                              <Option
                                key={res.vendorTypeId}
                                value={res.vendorTypeId}
                              >
                                {res.isDefault
                                  ? res.vendorTypeName + " ( Default )"
                                  : res.vendorTypeName}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </div>
                    {errors.vendorType.length > 0 && (
                      <span className="error">{errors.vendorType}</span>
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="vendorContactNumber"
                  style={style.columnHeading}
                >
                  Contact Number
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  name="contactNumber"
                  id="vendorContactNumber"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.contactNumber.length > 0 && (
                  <span className="error">{errors.contactNumber}</span>
                )}
              </div>
            </div>
            <div className="mt-4 mb-2">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "550",
                  color: "rgb(98, 95, 95)",
                }}
              >
                Bank Details
              </span>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="accountName" style={style.columnHeading}>
                  Bank Name
                </label>
                <input
                  autoComplete="off"
                  id="bankName"
                  name="bankName"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="accountName" style={style.columnHeading}>
                  Bank Account Type
                </label>
                <div className="select-input selects h40">
                  <Select
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.props.handleChangeVendor("bankAccountType", e);
                    }}
                  >
                    <Option value="CHECKING_ACCOUNT">Checking Account</Option>
                    <Option value="SAVING_ACCOUNT">Savings Account</Option>
                  </Select>
                </div>
              </div>
              <div className="col-lg-4">
                <label htmlFor="accountName" style={style.columnHeading}>
                  Account Holder Name
                </label>
                <input
                  type="accountName"
                  autoComplete="off"
                  id="accountName"
                  name="accountHolderName"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.accountHolderName &&
                  errors.accountHolderName.length > 0 && (
                    <span className="error">{errors.accountHolderName}</span>
                  )}
              </div>
              <div className="col-lg-4 mt-3">
                <label htmlFor="accountNumber" style={style.columnHeading}>
                  Account Number
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  id="accountNumber"
                  name="bankAccountNumberInVendor"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.bankAccountNumberInVendor &&
                  errors.bankAccountNumberInVendor.length > 0 && (
                    <span className="error">
                      {errors.bankAccountNumberInVendor}
                    </span>
                  )}
              </div>
              <div className="col-lg-4 mt-3">
                <label htmlFor="routingNumber" style={style.columnHeading}>
                  Routing Number
                </label>
                <input
                  type="number"
                  autoComplete="off"
                  id="routingNumber"
                  name="routingNumberInVendor"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                    Helpers.handleValidationOnChange(e, errors);
                  }}
                />
                {errors.routingNumberInVendor &&
                  errors.routingNumberInVendor.length > 0 && (
                    <span className="error">
                      {errors.routingNumberInVendor}
                    </span>
                  )}
              </div>
            </div>
            <div className="row" style={{ marginTop: "2rem" }}>
              <div className="col-4" style={{ position: "inherit" }}>
                <Checkbox
                  id="isWithdrawal"
                  onChange={(e) => {
                    e.preventDefault();
                    this.props.handleChangeVendor(
                      e.target.id,
                      e.target.checked
                    );
                    this.setState({ isBlocking: true });
                  }}
                  style={style.columnHeading}
                >
                  Withdraw Vendor
                </Checkbox>
              </div>
            </div>
            {!(this.state.address.length > 0) ? (
              <div
                style={{ cursor: "pointer", marginTop: "1.5rem" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    isBlocking: false,
                  });
                  showInputForField();
                }}
              >
                <img
                  src="/assets/images/add-button-inside-circle.svg"
                  alt="Add Button"
                  style={{ width: "22px", height: "22px", cursor: "pointer" }}
                />
                <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                  Add Address
                </span>
              </div>
            ) : (
              <div style={{ marginTop: "2rem" }}>
                <OpenAddressFields
                  fieldArray={this.state.address}
                  filterAddressData={filterAddressData}
                  handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                  handleChange={this.handleChange}
                  addNewVendor={this.addNewVendor}
                  id={this.state.id}
                  setId={(id) => {
                    this.setState({
                      id: id
                    })
                  }
                  }
                />
                <div
                  style={{
                    display: "block",
                    marginTop: "1rem",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: false });
                      showInputForField(); //If we don't call, then we cannot add more rows of address field (Add Vendor)
                    }}
                  >
                    <img
                      src="/assets/images/add-button-inside-circle.svg"
                      alt="Add Button"
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    />
                    <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                      Add More
                    </span>
                  </span>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
            <ButtonComponent
              onClick={(e) => {
                e.preventDefault();
                this.setState({ isBlocking: false });
                if (!this.state.clicked) {
                  this.setState({
                    clicked: true,
                    setValue: "add",
                  });
                  if (vendorTypeToMap && vendorTypeToMap.length === 1) {
                    vendorData.vendorTypeId =
                      vendorTypeToMap &&
                      vendorTypeToMap[0] &&
                      vendorTypeToMap[0].vendorTypeId;
                  }
                  if (vendorData.bankAccountType === "") {
                    delete vendorData.bankAccountType;
                  }

                  // const finalData = [...vendorData];
                  vendorData.address.map((item) => {
                    if (item.addressId === this.state.id) {
                      item.isDefault = true;
                    }
                    // if (item.addressId !== this.state.id) {
                    //   delete item.isDefault
                    // }
                    return vendorData
                  })
                  this.props.addNewVendor(vendorData, vendorData, this.props);
                  setTimeout(() => {
                    this.setState({
                      clicked: false,
                    });
                  }, 3000);
                }
              }}
              value={value}
              loading={loading}
              buttonName="Add"
            />
          </DialogActions>
        </Dialog>
        {/*Update Vendor*/}
        <Dialog
          id="viewScheduleTransactionDetails"
          maxWidth="lg"
          open={this.state.openModalToUpdateVendor}
          onClose={handleCloseDialogueForUpdatingVendor}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseDialogueForUpdatingVendor}
          >
            Update Vendor
          </DialogTitleComponent>
          <DialogContent dividers className="dialogContentWidth850">
            <div className="row mt-2">
              <div className="col-lg-4">
                <label htmlFor="name" style={style.columnHeading}>
                  Name
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="name"
                  id="vendorName"
                  autoComplete="off"
                  style={style.inputField}
                  name="vendorName"
                  value={vendorData && vendorData.vendorName}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
                {errors.vendorName.length > 0 && (
                  <span className="error">{errors.vendorName}</span>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="type" style={style.columnHeading}>
                  Type
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div
                  className="select-input selects h40"
                // style={{marginTop: "12px"}}
                >
                  <Select
                    showSearch
                    name="clientId"
                    className="drop"
                    disabled={isDefault}
                    value={
                      isDefault
                        ? vendorData.vendorType
                        : vendorData.vendorTypeId
                    }
                    getPopupContainer={(node) => node.parentNode}
                    style={{ width: 200 }}
                    onSearch={(value) => {
                      this.props.fetchVendorTypeList(value);
                    }}
                    onInputKeyDown={(e) => {
                      this.setState({
                        showDropdown: true,
                      });
                    }}
                    onChange={(e) => {
                      this.setState({
                        isBlocking: true,
                      });
                      this.props.handleChangeVendor("vendorTypeId", e);
                    }}
                    onSelect={() => {
                      this.setState({
                        showDropdown: false,
                      });
                    }}
                    onBlur={() => {
                      this.setState({
                        showDropdown: false,
                      });
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultOpen={false}
                    dropdownStyle={{ maxHeight: "100px", overflowY: "scroll" }}
                  >
                    {vendorTypeToMap &&
                      vendorTypeToMap.map((res) => (
                        <>
                          <Option
                            key={res.vendorTypeId}
                            value={res.vendorTypeId}
                          >
                            {res.isDefault
                              ? res.vendorTypeName + " ( Default )"
                              : res.vendorTypeName}
                          </Option>
                          {/* {isDefaultVendorTypeTaken ? (
                            !res.isDefault ? (
                              <Option
                                key={res.vendorTypeId}
                                value={res.vendorTypeId}
                              >
                                {res.isDefault
                                  ? res.vendorTypeName + " ( Default )"
                                  : res.vendorTypeName}
                              </Option>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )} */}
                        </>
                      ))}
                  </Select>
                </div>
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="vendorContactNumber"
                  style={style.columnHeading}
                >
                  Contact Number
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  value={vendorData && vendorData.vendorContactNumber}
                  type="text"
                  autoComplete="off"
                  name="contactNumber"
                  id="vendorContactNumber"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
                {errors.contactNumber.length > 0 && (
                  <span className="error">{errors.contactNumber}</span>
                )}
              </div>
            </div>
            <div className="mt-4 mb-2">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "550",
                  color: "rgb(98, 95, 95)",
                }}
              >
                Bank Details
              </span>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="accountName" style={style.columnHeading}>
                  Bank Name
                </label>
                <input
                  autoComplete="off"
                  id="bankName"
                  name="bankName"
                  value={vendorData && vendorData.bankName}
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label htmlFor="accountName" style={style.columnHeading}>
                  Bank Account Type
                </label>
                <div className="select-input selects h40">
                  <Select
                    value={vendorData && vendorData.bankAccountType}
                    getPopupContainer={(node) => node.parentNode}
                    onChange={(e) => {
                      this.props.handleChangeVendor("bankAccountType", e);
                    }}
                  >
                    <Option value="CHECKING_ACCOUNT">Checking Account</Option>
                    <Option value="SAVING_ACCOUNT">Savings Account</Option>
                  </Select>
                </div>
              </div>
              <div className="col-lg-4">
                <label htmlFor="accountName" style={style.columnHeading}>
                  Account Holder Name
                </label>
                <input
                  value={vendorData && vendorData.accountName}
                  type="accountName"
                  autoComplete="off"
                  id="accountName"
                  name="accountHolderName"
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
                {errors.accountHolderName &&
                  errors.accountHolderName.length > 0 && (
                    <span className="error">{errors.accountHolderName}</span>
                  )}
              </div>
              <div className="col-lg-4 mt-3">
                <label htmlFor="accountNumber" style={style.columnHeading}>
                  Account Number
                </label>
                {/*<span style={{color:"red"}}> *</span></label>*/}
                <input
                  type="number"
                  autoComplete="off"
                  id="accountNumber"
                  value={vendorData && vendorData.accountNumber}
                  style={style.inputField}
                  name="bankAccountNumberInVendor"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
                {errors.bankAccountNumberInVendor &&
                  errors.bankAccountNumberInVendor.length > 0 && (
                    <span className="error">
                      {errors.bankAccountNumberInVendor}
                    </span>
                  )}
              </div>
              <div className="col-lg-4 mt-3">
                <label htmlFor="routingNumber" style={style.columnHeading}>
                  Routing Number
                </label>
                {/*<span style={{color:"red"}}> *</span></label>*/}
                <input
                  type="number"
                  autoComplete="off"
                  id="routingNumber"
                  name="routingNumberInVendor"
                  value={vendorData && vendorData.routingNumber}
                  style={style.inputField}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({ isBlocking: true });
                    Helpers.handleValidationOnChange(e, errors);
                    this.props.handleChangeVendor(e.target.id, e.target.value);
                  }}
                />
                {errors.routingNumberInVendor &&
                  errors.routingNumberInVendor.length > 0 && (
                    <span className="error">
                      {errors.routingNumberInVendor}
                    </span>
                  )}
              </div>
            </div>
            {isDefault ? null : (
              <div className="row" style={{ marginTop: "2rem" }}>
                <div className="col-4" style={{ position: "inherit" }}>
                  <Checkbox
                    checked={!!(vendorData && vendorData.isWithdrawal)}
                    id="isWithdrawal"
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeVendor(
                        e.target.id,
                        e.target.checked
                      );
                      this.setState({ isBlocking: true });
                    }}
                    style={style.columnHeading}
                  >
                    Withdraw Vendor
                  </Checkbox>
                </div>
              </div>
            )}
            {!(this.state.address && this.state.address.length > 0) ? (
              <div
                style={{ cursor: "pointer", marginTop: "1.5rem" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    isBlocking: false,
                  });
                  showInputForField();
                }}
              >
                <img
                  src="/assets/images/add-button-inside-circle.svg"
                  alt="Add Button"
                  style={{ width: "22px", height: "22px", cursor: "pointer" }}
                />
                <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                  Add Address
                </span>
              </div>
            ) : (
              <div style={{ marginTop: "2rem" }}>
                <OpenAddressFields
                  isEdit={true}
                  fieldArray={this.state.address}
                  updateFiledArray={this.state.updatedAddress}
                  filterEditAddressData={filterEditAddressData}
                  handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                  vendors={vendors}
                  id={this.state.id}
                  setId={(id) => {
                    this.setState({
                      id: id
                    })
                  }
                  }
                />
                <div
                  hidden={isDefault}
                  style={{
                    display: "block",
                    marginTop: "1rem",
                  }}
                >
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ isBlocking: false });
                      showInputForField(); // If we don't call, we cannot add more row of address field by clicking Add More button (Update Vendor).
                    }}
                  >
                    <img
                      src="/assets/images/add-button-inside-circle.svg"
                      alt="Add Button"
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                    />
                    <span style={{ fontSize: "14px", paddingLeft: "4px" }}>
                      Add More
                    </span>
                  </span>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions style={{ padding: "10px 20px 10px 0px" }}>
            <ButtonComponent
              onClick={(e) => {
                e.preventDefault();
                this.setState({ isBlocking: false });
                const vendorData = this.props.vendorState.vendorData;
                delete vendorData.vendorType;
                if (!this.state.clicked) {
                  this.setState({
                    clicked: true,
                    setValue: "edit",
                  });
                  if (vendorData.bankAccountType === "") {
                    delete vendorData.bankAccountType;
                  }
                  this.props.editVendor(vendorData, this.props);
                  setTimeout(() => {
                    this.setState({
                      clicked: false,
                      // address: [],
                    });
                  }, 3000);
                }
              }}
              value={value}
              loading={loading}
              buttonName="Update"
            />
          </DialogActions>
        </Dialog>
        {/*DELETE VENDOR ADDRESS*/}
        <Dialog
          open={this.state.openDeleteModal}
          onClose={handleCloseDeleteDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              style={style.dialogHeaderForAddUser}
              id="draggable-dialog-title"
            />
            <DialogContent className="dialogContentForDeleteModal">
              <DialogContentText>
                <h3 style={{ color: "#8BC83F", padding: "0.8rem" }}>
                  Confirmation
                </h3>
                Do you want to delete address?
                <div
                  className="row"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom: "1rem",
                    paddingTop: "1.6rem",
                  }}
                >
                  <div className={"col-6"}>
                    <Button
                      autoFocus
                      onClick={handleCloseDeleteDialogue}
                      style={style.cancelButton}
                    >
                      cancel
                    </Button>
                  </div>
                  <div className={"col-6"}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleOkDeleteDialogue();
                      }}
                      style={style.deleteCaseTemplateButton}
                    >
                      ok
                    </Button>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.vendorState.openVendorFormSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="error"
            message={this.props.vendorState.errorMsgForVendor}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.props.vendorState.openAddVendorSuccessSnackBar}
          autoHideDuration={6000}
          onClose={this.props.handleSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleSnackBarClose}
            variant="success"
            message={this.props.vendorState.successMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    marginLeft: "3%",
    marginRight: "3%",
    marginBottom: "1.5%",
    marginTop: "48px",
  },
  cancel: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "15%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "15%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  card: {
    background: "#ffffff",
    // outline:'1px solid #E9E9E9',
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "4px",
    paddingLeft: "15px",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    // fontWeight: "600",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    textTransform: "capitalize",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    // width: '190px',
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "100%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
};

export default VendorScreen;
