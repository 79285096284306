import React, { useEffect, useRef } from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExportButton from "../../components/ExportButton";
// import PaperComponent from "../PaperComponent";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import TableOuterTotalDiv from "./TableOuterTotalDiv";
import { Avatar, Popover, Tag } from "antd";
import EditOutlined from "@material-ui/icons/EditOutlined";
import { Select, Tooltip } from "antd";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotesPopover from "../../components/NotesPopover";
import { formatter } from "../../utils/currencyConvertor";

import TablePaginationComponent from "../../components/TablePaginationComponent";

const DeletedTransactionTable = ({
  page,
  rowsPerPage,
  setPage,

  setRowPerPage,

  fetchDeletedTransaction,
  deletedTransData,
}) => {
  useEffect(() => {
    fetchDeletedTransaction();
  }, []);

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        
        <div style={{ display: "flex" }}>
          <div className="search">
            <div style={style.searchIcon}>
              <SearchIcon style={{ color: "#9c9c9c" }} />
            </div>
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              style={style.inputBase}
              onChange={(e) => {
                e.preventDefault();
                 setPage(e, 0);
                if (
                  e.target.value === "" ||
                  e.target.value === " "
                ) {
                  fetchDeletedTarnsaction();
                } else {
                  this.props.searchTransaction(
                    e.target.value,
                    this.props
                  );
                  this.props.handleChangeTerm(e.target.value);
                }
              }}
            />
          </div>
        </div>
      </div> */}
      <div className={"row table-card"}>
        <TableContainer className="fixed-table-header">
          <Table
            size="small"
            stickyHeader
            aria-label="sticky table"
            // ref={tableRef}
          >
            <TableHead style={{ padding: "1rem" }}>
              <TableRow>
                <TableCell className="first-tableHeader">#</TableCell>
                <TableCell
                  // className="first-tableHeader"
                  className="tableHeader"
                >
                  Transaction Date
                </TableCell>
                {/* <TableCell className="tableHeader" /> */}
                <TableCell
                  // className="tableHeader"
                  className="first-tableHeader"
                >
                  Description
                </TableCell>
                {/* <TableCell className="tableHeader" /> */}
                <TableCell className="tableHeader">Debits</TableCell>
                <TableCell className="tableHeader">Credits</TableCell>
                <TableCell className="tableHeader">Balance</TableCell>

                <TableCell className="tableHeaderRefId">
                  Ref / Check No.
                </TableCell>
                <TableCell className="tableHeader">User</TableCell>
                <TableCell className="tableHeader">Vendor</TableCell>
                <TableCell className="tableHeader">Medium</TableCell>
                <TableCell className="tableHeader">Type</TableCell>
                <TableCell className="tableHeader">Status</TableCell>
                <TableCell className="tableHeader">
                  ACH Submitted Date
                </TableCell>
                <TableCell className="tableHeader">Check Print Date</TableCell>
                <TableCell className="tableHeader">Posted Date</TableCell>
                <TableCell className="tableHeader">
                  Scheduled Date/By/Type
                </TableCell>
              </TableRow>
            </TableHead>
            {deletedTransData &&
              deletedTransData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((list, index) => (
                  <TableBody key={list.deletedTransactionId}>
                    <TableRow>
                      <TableCell className="first-tableBody">
                        {(page + 1 - 1) * rowsPerPage + (index + 1)}
                      </TableCell>
                      <TableCell className="tableBody">
                        {new Intl.DateTimeFormat("default", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        }).format(list.transaction.transactionTimestamp || "-")}
                      </TableCell>
                      <TableCell className="first-tableBody">
                        {list.transaction.description}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          height: "4.5rem",
                          // paddingLeft: "47px",
                          paddingRight: 0,
                        }}
                        // className="tableAmountBody"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleOpenDialogue();
                        //   this.setState(
                        //     {
                        //       trans: list,
                        //     },
                        //     () => {
                        //       this.props.getTransactionInfo(
                        //         this.state.trans.transactionId
                        //       );
                        //     }
                        //   );
                        // }}
                      >
                        {list.transaction.amount
                          ? list.transaction.amount &&
                            list.transaction.amount.charAt(0) === "-"
                            ? formatter
                                .format(list.transaction.amount / 100)
                                .substring(1)
                            : "-"
                          : formatter.format(0)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          height: "4.5rem",
                          // paddingLeft: "47px",
                          paddingRight: 0,
                        }}
                        // className="tableAmountBody"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleOpenDialogue();
                        //   this.setState(
                        //     {
                        //       trans: list,
                        //     },
                        //     () => {
                        //       this.props.getTransactionInfo(
                        //         this.state.trans.transactionId
                        //       );
                        //     }
                        //   );
                        // }}
                      >
                        {list.transaction.amount
                          ? list.transaction.amount.charAt(0) === "-"
                            ? "-"
                            : formatter.format(list.transaction.amount / 100)
                          : formatter.format(0)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          height: "4.5rem",
                          // paddingLeft: "47px",
                          paddingRight: 0,
                        }}
                        // className="tableAmountBody"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleOpenDialogue();
                        //   this.setState(
                        //     {
                        //       trans: list,
                        //     },
                        //     () => {
                        //       this.props.getTransactionInfo(
                        //         this.state.trans.transactionId
                        //       );
                        //     }
                        //   );
                        // }}
                      >
                        {list.transaction.balance
                          ? formatter.format(list.transaction.balance / 100)
                          : formatter.format(0)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          height: "4.5rem",
                          paddingLeft: "47px",
                          paddingRight: 0,
                        }}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleOpenDialogue();
                        //   this.setState(
                        //     {
                        //       trans: list,
                        //     },
                        //     () => {
                        //       this.props.getTransactionInfo(
                        //         this.state.trans.transactionId
                        //       );
                        //     }
                        //   );
                        // }}
                      >
                        {list.transaction.refId}
                        <span>
                          {list.transaction.checkNo
                            ? " / " + list.transaction.checkNo + " "
                            : ""}
                        </span>
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          height: "4.5rem",
                        }}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleOpenDialogue();
                        //   this.setState(
                        //     {
                        //       trans: list,
                        //     },
                        //     () => {
                        //       this.props.getTransactionInfo(
                        //         this.state.trans.transactionId
                        //       );
                        //     }
                        //   );
                        // }}
                      >
                        {list.transaction.Client === {}
                          ? "-"
                          : list.transaction.Client.lastName
                          ? list.transaction.Client.firstName +
                            " " +
                            list.transaction.Client.lastName
                          : list.transaction.Client.firstName}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                        }}
                      >
                        {list.transaction.vendorAccount.vendorName
                          ? list.transaction.vendorAccount.vendorName
                          : "-"}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                        }}
                      >
                        {list.transaction.transactionMedium &&
                          list.transaction.transactionMedium.replace("_", " ")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                        }}
                      >
                        {list.transaction.transactionType.replace("_", " ")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                          cursor: "auto",
                          width: "10%",
                        }}
                      >
                        <>
                          <Tag
                            style={style.colors}
                            color={
                              list.transaction.transactionStatus ===
                              "TRANSACTION_APPROVED"
                                ? "green"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_BLOCKED"
                                ? "yellow"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_REVERSED"
                                ? "red"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_CREATED"
                                ? "purple"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_SUBMITTED"
                                ? "blue"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_RETURNED"
                                ? "geekblue"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_POSTED"
                                ? "cyan"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_SKIPPED"
                                ? "orange"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_ON_HOLD"
                                ? "volcano"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_CANCELLED"
                                ? "gold"
                                : list.transaction.transactionStatus ===
                                  "TRANSACTION_CLOSED"
                                ? "magenta"
                                : "lime"
                            }
                          >
                            {list.transaction.transactionStatus &&
                              list.transaction.transactionStatus
                                .replace("TRANSACTION_", " ")
                                .replace("_", " ")}
                          </Tag>
                        </>
                      </TableCell>
                      <TableCell className="first-tableBody">
                        {list.transaction.achLog &&
                        list.transaction.achLog.achSubmittedDate &&
                        list.transaction.achLog.achSubmittedDate
                          ? new Intl.DateTimeFormat("default", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(
                              list.transaction.achLog.achSubmittedDate
                                ? list.transaction.achLog.achSubmittedDate
                                : "-"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                          width: "225px",
                        }}
                      >
                        {list.transaction.checkPrint &&
                        list.transaction.checkPrint.checkPrintDate &&
                        list.transaction.checkPrint.checkPrintDate
                          ? new Intl.DateTimeFormat("default", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(
                              list.transaction.checkPrint.checkPrintDate
                                ? list.transaction.checkPrint.checkPrintDate
                                : "-"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell
                        // className="first-tableBody"
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                          width: "225px",
                        }}
                      >
                        {list.transaction.DialogContentpostedAt
                          ? new Intl.DateTimeFormat("default", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(
                              list.transaction.postedAt
                                ? list.transaction.postedAt
                                : "-"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "15px",
                          color: "#626262",
                          textAlign: "left",
                          paddingLeft: "18px",
                          height: "4.5rem",
                          width: "225px",
                        }}
                      >
                        {list.transaction.transactionDetails ? (
                          <div>
                            <div>
                              {list.transaction.transactionDetails &&
                                list.transaction.transactionDetails
                                  .scheduleDate &&
                                new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(
                                  list.transaction.transactionDetails &&
                                    list.transaction.transactionDetails
                                      .scheduleDate
                                    ? list.transaction.transactionDetails &&
                                        list.transaction.transactionDetails
                                          .scheduleDate
                                    : "-"
                                )}
                            </div>
                            <div style={{ marginTop: "5px" }}>
                              {list.transaction.transactionDetails &&
                              list.transaction.transactionDetails.scheduleBy ? (
                                <>
                                  <Avatar
                                    style={{
                                      width: "28px",
                                      height: "28px",
                                      marginRight: "8px",
                                    }}
                                    src={
                                      list.transaction.transactionDetails
                                        .profilePic
                                        ? list.transaction.transactionDetails
                                            .profilePic
                                        : "/assets/images/profile.svg"
                                    }
                                  />
                                  <span>
                                    {
                                      list.transaction.transactionDetails
                                        .scheduleBy
                                    }
                                  </span>
                                  <span> / </span>
                                  <span>
                                    {list.transaction.transactionDetails
                                      .scheduleType &&
                                      list.transaction.transactionDetails.scheduleType.replace(
                                        "_",
                                        " "
                                      )}
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
          </Table>
        </TableContainer>
        <TablePaginationComponent
          tableList={deletedTransData}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowPerPage={setRowPerPage}
        />
      </div>
    </>
  );
};
const style = {
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  editDesc: {
    marginLeft: "118px",
    top: "-10px",
    position: "relative",
    padding: "20px",
  },
  flex: {
    // paddingBottom: "1rem",
    // paddingTop: "4.5rem",
    paddingLeft: "1rem",
    outline: "none",
    overflow: "auto",
    // marginBottom: "5px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginRight: '2rem !important',
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
};
export default DeletedTransactionTable;
