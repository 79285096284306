import { connect } from "react-redux";
import SubmitTransactionScreen from "../../screens/SubmitTransactionScreen";
import {
  closeTransactionSnackBar,
  fetchApprovedTransactionFailure,
  fetchApprovedTransactionSuccess,
  handleChangeDateFrom,
  handleChangeDateTo,
  handleChangeMedium,
  handleChangeTerm,
  handleChangeTransactionRemark,
  handleChangeTransType,
  handleChangeVendor,
  openSnackBar,
  searchSubmittedTransactionSuccess,
  searchTransactionByMediumAndStatusAndDateFailure,
  searchTransactionByMediumAndStatusAndDateSuccess,
  searchTransactionFailure,
  transactionRequestMade,
  updateSubmitTransactionFailure,
  updateSubmitTransactionSuccess,
} from "../../actions/TransactionAction";
import { TransactionAPI, VendorAPI } from "../../api";
import {
  fetchVendorFailure,
  fetchVendorSuccess,
  vendorRequestMade,
} from "../../actions";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApprovedTransactionList: () => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.fetchApprovedTransactionList((response, error) => {
        if (response) {
          dispatch(fetchApprovedTransactionSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchApprovedTransactionFailure(error.msg));
          } else {
            dispatch(fetchApprovedTransactionFailure(error));
          }
        }
      });
    },
    updateSubmitTransactionList: (transData, props) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.updateTransactionStatus(transData, (response, error) => {
        if (response) {
          dispatch(updateSubmitTransactionSuccess(true));
          props.fetchApprovedTransactionList();
        } else {
          if (error.msg) {
            dispatch(updateSubmitTransactionFailure(error.msg));
          } else {
            dispatch(updateSubmitTransactionFailure(error));
          }
        }
      });
    },
    handleTransactionSnackBarClose: () =>
      dispatch(closeTransactionSnackBar(true)),
    handleChangeRemark: (remark) =>
      dispatch(handleChangeTransactionRemark(remark)),
    searchTransactionByAll: (medium, status, type, from, to, vendor) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransactionByAll(
        medium,
        status,
        type,
        from === null ? 0 : from,
        to === null ? 0 : to,
        vendor,
        (response, error) => {
          if (error) {
            if (error.msg) {
              dispatch(
                searchTransactionByMediumAndStatusAndDateFailure(error.msg)
              );
            } else {
              dispatch(searchTransactionByMediumAndStatusAndDateFailure(error));
            }
          } else {
            console.log(response, "response");
            dispatch(searchSubmittedTransactionSuccess(response));
          }
        }
      );
    },
    // for filter
    searchTransaction: (value) => {
      dispatch(transactionRequestMade(true));
      TransactionAPI.searchTransaction(value, (response, error) => {
        if (error) {
          if (error.msg) {
            dispatch(searchTransactionFailure(error.msg));
          } else {
            dispatch(searchTransactionFailure(error));
          }
        } else {
          dispatch(searchSubmittedTransactionSuccess(response));
        }
      });
    },
    fetchVendor: () => {
      dispatch(vendorRequestMade(true));
      VendorAPI.fetchVendorList((response, error) => {
        if (response) {
          dispatch(fetchVendorSuccess(response));
        } else {
          if (error.msg) {
            dispatch(fetchVendorFailure(error.msg));
          } else {
            dispatch(fetchVendorFailure(error));
          }
        }
      });
    },
    handleChangeDateFrom: (from) => dispatch(handleChangeDateFrom(from)),
    handleChangeDateTo: (to) => dispatch(handleChangeDateTo(to)),
    handleChangeMedium: (medium) => dispatch(handleChangeMedium(medium)),
    handleChangeTerm: (term) => dispatch(handleChangeTerm(term)),
    handleChangeVendor: (vendor) => dispatch(handleChangeVendor(vendor)),
    handleChangeTransType: (transType) =>
      dispatch(handleChangeTransType(transType)),
    clearSnackBar: () => dispatch(openSnackBar()),
  };
};

export const SubmitTransactionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitTransactionScreen);
