import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import '../styles/styles.css'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import DateString from "../utils/DateConverter";

class ClientFilesDetailScreen extends Component {
    constructor(props) {
        super(props);
        var pageURL = window.location.href;
        this.state ={
            page: 0,
            rowsPerPage: 10,
            clientId: pageURL.substr(pageURL.lastIndexOf('/') + 1),
        }
        this.props.fetchFileListByClient(this.state.clientId, this.props)
    }
    render() {
        const fileResponse = this.props.state.clientState && this.props.state.clientState.fileResponse;
        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
        };

        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10)
            });
        };
        return (
            <React.Fragment>
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link color="inherit" to="/client" className="links" >
                        Client
                    </Link>
                    <Typography color="inherit" className="link">
                        Files Details
                    </Typography>
                </Breadcrumbs>
                <div style={{margin: "80px 3% 2%"}}>
                    <div className={"row"} style={style.card}>
                        <TableContainer>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Files
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {fileResponse && fileResponse.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                ).map(
                                    res =>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="first-tableBody" >
                                                <a href={res.actionForms.map(
                                                    forms => forms.actionFormData[0].data
                                                )} target="_blank" className="actionsLink">
                                                    {res.actionForms.map(
                                                        forms => forms.fieldType === "PHOTO" ?
                                                            <img src={res.actionForms.map(
                                                                forms => forms.actionFormData[0].data
                                                            )} alt="pdf" style={{width: '30px', marginRight: '12px'}}/>
                                                            :
                                                            res.actionForms.map(
                                                                forms => forms.actionFormData[0].data &&
                                                                forms.actionFormData[0].data.split(".").pop() === "pdf" ?
                                                                    <img src="/assets/images/pdf.svg" alt="pdf"
                                                                         style={{width: '30px', marginRight: '12px'}}/>:
                                                                    <img src="/assets/images/text_logo.png" alt="txt"
                                                                         style={{width: '30px', marginRight: '12px'}}/>)
                                                            )}
                                                    {res.actionForms.map(
                                                        forms => forms.actionFormData[0].fileName ?
                                                            forms.actionFormData[0].fileName
                                                            : null
                                                    )}
                                                </a>
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                {DateString(res.createdAt)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                            {fileResponse && fileResponse.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            fileResponse.length > 0
                                                ? fileResponse.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        </TableContainer>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const style = {
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginBottom: "1rem",
    },
}

export default ClientFilesDetailScreen;