import APIEndPoints from "../constants/APIConstants";
import {API} from "./index";
class DynamicActionAPI extends API {
    addNewAction = (dynamicActionData, callback) => {
        let body = JSON.stringify(dynamicActionData);
        fetch(APIEndPoints.ADD_DYNAMIC_ACTION_DATA, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };
    fetchDynamicActionData = (caseId,workflowId,actionId,callback) => {
        var constUrl = APIEndPoints.FETCH_DYNAMIC_ACTION_DATA + actionId + '&&workflowId=' + workflowId + '&&caseId=' + caseId;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                console.log(error);
                callback(null, "Service error, please try again.");
            });
    };
    editDynamicActionData = ( dynamicActionData,callback) => {
        fetch( APIEndPoints.UPDATE_DYNAMIC_ACTION_DATA ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(dynamicActionData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    markAsDoneDynamicActionData = ( dynamicActionData,callback) => {
        fetch( APIEndPoints.MARK_DYNAMIC_ACTION_DATA ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(dynamicActionData)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
    uploadImageFile = (profilePicture, callback) => {
        fetch(APIEndPoints.UPLOAD_IMAGE_FILE, {
            method: "POST",
            headers: this.authHeadersForMultipartFormData,
            body: profilePicture,
            mimeType:'multipart/form-data'
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    }
    uploadDocumentFile = (documents, callback) => {
        fetch(APIEndPoints.UPLOAD_DOCUMENT_FILE, {
            method: "POST",
            headers: this.authHeadersForMultipartFormData,
            body: documents,
            mimeType:'multipart/form-data'
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    }
    generateLink = (content, callback) => {
        fetch(APIEndPoints.GENERATE_LINK, {
            method: "POST",
            headers: this.authHeaders,
            body:JSON.stringify(content),
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    }

    exportActionData = (caseId,workflowId,actionId,callback) => {
        var constUrl = APIEndPoints.EXPORT_ACTION_DATA + actionId + '&caseId=' + caseId + '&workflowId=' + workflowId;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                console.log(error);
                callback(null, "Service error, please try again.");
            });
    };

    //for case conditional action form
    getConditionalCaseFormByRefId = (caseId, workflowId, refId, callback) => {
        var constUrl = APIEndPoints.GET_CONDITIONAL_FORM_BY_REF_ID_IN_CASE + caseId + "&workflowId="
            + workflowId + "&refId=" +refId;
        // var constUrl = APIEndPoints.GET_CONDITIONAL_FORM_BY_REF_ID_IN_CASE + refId
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        })
            .catch(error => {
                callback(null, "Service error, please try again.");
            });
    };

    setConditionalCaseForm = (conditionalCaseForm,callback) => {
        fetch( APIEndPoints.SET_CONDITIONAL_FORM_BY_REF_ID_IN_CASE ,{
            method: 'PATCH',
            headers: this.authHeaders,
            body:JSON.stringify(conditionalCaseForm)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
}

export default new DynamicActionAPI();