import React from "react";
import {
    convertDate,
    getFirstDayOfMonth,
    getLastDayOfMonth, getNewDate,
    getWeekFirstDay,
    getWeekLastDay
} from "../../utils/DateConverter";

const SelectiveDateForFilter = (props) =>{
    return(
        <div className="row mt-3">
            <div className="col-lg-6">
                <button
                    className="suggestion-date"
                    onClick={() => {
                        var d = new Date();
                        props.handleChangeFromDate(d.setHours(0,0,0,0));
                        // props.handleChangeFromDate(
                        //     new Date(d.setDate(d.getDate())).getTime()
                        // );
                        props.handleChangeToDate(d.setHours(23,59,59));
                    }}
                >
                    Today
                </button>
            </div>
            <div className="col-lg-6 ">
                <button
                    className="suggestion-date"
                    onClick={() => {
                        props.handleChangeFromDate(
                            new Date(getWeekFirstDay()).setHours(0,0,0,0)
                        );
                        props.handleChangeToDate(
                            new Date(getWeekLastDay()).setHours(23,59,59)
                        );
                        // props.handleChangeFromDate(
                        //     new Date(getWeekFirstDay()).getTime()
                        // );
                        // props.handleChangeToDate(
                        //     new Date(getWeekLastDay()).getTime()
                        // );
                    }}
                >
                    This week
                </button>
            </div>
            <div className="col-lg-6 mt-2">
                <button
                    className="suggestion-date"
                    onClick={() => {
                        props.handleChangeFromDate(
                            new Date(convertDate(getFirstDayOfMonth())).setHours(0,0,0,0)
                        );
                        props.handleChangeToDate(
                            new Date(convertDate(getLastDayOfMonth())).setHours(23,59,59)
                        );
                        // props.handleChangeFromDate(
                        //     new Date(convertDate(getFirstDayOfMonth())).getTime()
                        // );
                        // props.handleChangeToDate(
                        //     new Date(convertDate(getLastDayOfMonth())).getTime()
                        // );
                    }}
                >
                    This month
                </button>
            </div>
            <div className="col-lg-6 mt-2">
                <button
                    className="suggestion-date"
                    onClick={() => {
                        props.handleChangeFromDate(
                            new Date(new Date(getNewDate().getFullYear(), 0, 1)).setHours(0,0,0,0)
                        );
                        props.handleChangeToDate(
                            new Date(new Date(getNewDate().getFullYear(), 11, 31)).setHours(23,59,59)
                        );
                        // props.handleChangeFromDate(
                        //     new Date(new Date(getNewDate().getFullYear(), 0, 1)).getTime()
                        // );
                        // props.handleChangeToDate(
                        //     new Date(new Date(getNewDate().getFullYear(), 11, 31)).getTime()
                        // );
                    }}
                >
                    This year
                </button>
            </div>
        </div>
    )
};
export default SelectiveDateForFilter;