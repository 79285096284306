import { connect } from 'react-redux';
import CreateTransactionFormScreen from "../../screens/CreateTransactionFormScreen";
import {
    clientRequestMade,
    fetchScheduleTransactionFailure,
    fetchScheduleTransactionSuccess,
    fetchSingleClientFailure,
    fetchSingleClientSuccess,
    fetchVendorFailure,
    fetchVendorSuccess,
    scheduleTransactionRequestMade,
    searchClientListFailure,
    searchClientListSuccess,
    vendorRequestMade
} from "../../actions";
import {ClientAPI, DynamicActionAPI, TransactionAPI, VendorAPI} from "../../api";
import ScheduleTransactionAPI from "../../api/ScheduleTransactionAPI";
import {
    createTransactionFailure,
    createTransactionSuccess,
    handleChangeTransData
} from "../../actions/TransactionAction";
import {clearFileData, uploadDocFileFailure, uploadDocFileSuccess} from "../../actions/ScheduleTransactionAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
  return{
      fetchVendor: () => {
          dispatch(vendorRequestMade(true));
          VendorAPI.fetchVendorList((response, error) => {
              if (response) {
                  dispatch(fetchVendorSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(fetchVendorFailure(error.msg))
                  } else {
                      dispatch(fetchVendorFailure(error));
                  }
              }
          })
      },
      fetchScheduleTransactionList: () => {
          dispatch(scheduleTransactionRequestMade(true));
          ScheduleTransactionAPI.fetchScheduleTransactionList((response, error) => {
              if (response) {
                  dispatch(fetchScheduleTransactionSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(fetchScheduleTransactionFailure(error.msg))
                  } else {
                      dispatch(fetchScheduleTransactionFailure(error));
                  }
              }
          });
      },
      fetchSingleClient: (clientId , props) =>{
          dispatch(clientRequestMade(true));
          ClientAPI.fetchSingleClient(clientId, (response, error) => {
              if (response) {
                  dispatch(fetchSingleClientSuccess(response));
              } else {
                  if (error.msg) {
                      dispatch(fetchSingleClientFailure(error.msg))
                  } else {
                      dispatch(fetchSingleClientFailure(error));
                  }
              }
          })
      },
      uploadDocumentFile: (selectedFiles, props) => {
          dispatch(scheduleTransactionRequestMade(true));
          let formdata = new FormData();
          formdata.append("doc", selectedFiles.document);
          formdata.append("mimeType", selectedFiles.type);
          DynamicActionAPI.uploadDocumentFile(formdata, (response, error) => {
              if (response) {
                  dispatch(uploadDocFileSuccess(response))
                  props.handleChangeTransData("document", response.fileUrl)
              } else {
                  if (error.msg) {
                      dispatch(uploadDocFileFailure(error.msg));
                  } else {
                      dispatch(uploadDocFileFailure(error));
                  }
              }
          });
      },
      searchClientList: (value) => {
          ScheduleTransactionAPI.searchClientList(value, (response, error) => {
              if (error) {
                  if (error.msg) {
                      dispatch(searchClientListFailure(error.msg))
                  } else {
                      dispatch(searchClientListFailure(error));
                  }
              } else {
                  dispatch(searchClientListSuccess(response));
              }
          })
      },
      handleChangeTransData : (id, value) => {
          dispatch(handleChangeTransData(id, value));
      },
      createTransData: (value, props) => {
          TransactionAPI.createTransaction(value, (response, error) => {
              if (error) {
                  if (error.msg) {
                      dispatch(createTransactionFailure(error.msg))
                  } else {
                      dispatch(createTransactionFailure(error));
                  }
              } else {
                  dispatch(createTransactionSuccess(response));
                  props.history.push('/transaction');
              }
          })
      },
      clearFileData: () => {
          dispatch(clearFileData());
      }
  }
};

export const CreateTransContainer = connect(mapStateToProps, mapDispatchToProps)(CreateTransactionFormScreen);