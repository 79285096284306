import {ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE, ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE} from "../constants/Type";

export const dynamicActionClientRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.DYNAMIC_ACTION_CLIENT_PAGE_REQUEST_MADE,
        bool
    };
};

export const validateUrlSuccess = response => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.VALIDATE_URL_LINK_KEY_SUCCESS,
        response
    };
};

export const validateUrlFailure = error => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.VALIDATE_URL_LINK_KEY_FAILURE,
        error
    };
};

export const setPreviousValueTrue = bool => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.SET_PREVIOUS_VALUE_TRUE,
        bool
    };
};

export const handleChangeClientActionData = actionFormData => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_CLIENT_ACTION_DATA,
        actionFormData
    };
};

export const sendDynamicActionDataSuccess = bool => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.SEND_ACTION_DATA_SUCCESS,
        bool
    };
};
export const sendDynamicActionDataFailure = error => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.SEND_ACTION_DATA_FAILURE,
        error
    };
};

export const uploadFileByClientSuccess = file => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.UPLOAD_FILE_SUCCESS,
        file
    };
};
export const uploadFileByClientFailure = error => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.UPLOAD_FILE_FAILURE,
        error
    };
};

export const closeDynamicActionClientSnackBar = bool => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.CLOSE_SNACK_BAR_DYNAMIC_ACTION_CLIENT_PAGE,
        bool
    };
};

export const handleChangeFileNameByClient = (id , fileName) =>{
    return{
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_FILE_NAME_BY_CLIENT,
        id,
        fileName,
    }
}

export const handleChangeSelectedValueByClient = (id , selectedValue) =>{
    return{
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_SELECTED_VALUE_BY_CLIENT,
        id,
        selectedValue,
    }
}
export const handleChangeCheckBoxValueByClient = (id , isSelected, data) =>{
    return{
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_CHECKBOX_VALUE_BY_CLIENT,
        id,
        isSelected,
        data
    }
}
export const handleChangeContactValueByClient= (id , contactData, selectedContact) =>{
    return{
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_CONTACT_VALUE_BY_CLIENT,
        id,
        contactData,
        selectedContact
    }
}
export const handleChangeDateOfBirthByClient = (id , dateValue) =>{
    return{
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_DOB_BY_CLIENT,
        id,
        dateValue
    }
}

export const handleChangeInputByClient = (actionId, inputValue) => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CHANGE_INPUT_BY_CLIENT,
        actionId, inputValue
    };
};


// ******CONDITIONAL FORM******
export const getConditionalFormByClientSuccess = (response,refId,actionFormId,optionName,prevRefId, prevActionFormId,
                                                   prevOptionName, step) => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_SUCCESS,
        response,refId,actionFormId,optionName,prevRefId, prevActionFormId,
        prevOptionName, step
    };
};

export const getConditionalFormByClientFailure = error => {
    return {
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_FAILURE,
        error
    };
};

export const handleCloseClientConditionalForm = () => {
    return{
        type: ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE.HANDLE_CLOSE_CLIENT_CONDITIONAL_FORM,
    }
}


