import React, {Component} from "react";
import {Calendar, momentLocalizer} from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import './calendarStyle.css';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import {Button, CircularProgress} from "@material-ui/core";
import {LocalDb} from "../api";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import {HelmetComponent} from "../components/Helmet";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import {getFormattedDateTimeValue} from "../utils/DateConverter";
import {Select} from "antd";

const {Option} = Select;

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

class CalendarViewScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogue : false,
            openModalToAddEvent : false,
            end: '',
            start: '',
            setValue: '',
            currentView: 'month',
        }
    }

    componentDidMount() {
        this.props.fetchDataListForCalendar();
        this.props.fetchHolidaysList();
    }

    render() {
        const caseData = this.props.state.caseState && this.props.state.caseState.caseData;
        const workflowName = this.props.state.caseState && this.props.state.caseState.workflowName;
        const settingsState = this.props && this.props.state.settingState;
        const calendarType = settingsState && settingsState.calendarType;
        const holidayName = settingsState && settingsState.eventName;
        const value = holidayName !== '';
        const holidayDetails = settingsState && settingsState.holidayDetails;
        const agentAssigns = this.props.state.calendarState && this.props.state.calendarState.calendarData;
        const userInfoFromDB = LocalDb.getSessions();
        const userRole = userInfoFromDB &&
            userInfoFromDB.loginResponse.loginUser.user &&
            userInfoFromDB.loginResponse.loginUser.user.userRole;

           
        const DateTimeString = (dateToConvert) => {
            return new Date(parseInt(dateToConvert));
        }
        const allHolidayEventsList = holidayDetails ? holidayDetails.map(item => {
            const list = {};
            if( this.state.currentView === "month"){
                list.title = new Intl.DateTimeFormat('default', {
                    hour: '2-digit',
                    minute: '2-digit',
                }).format(item.startDate) !== new Intl.DateTimeFormat('default', {
                    hour: '2-digit',
                    minute: '2-digit',
                }).format(item.endDate) ? `
            ${new Intl.DateTimeFormat('default', {
                        hour: '2-digit',
                        minute: '2-digit',
                    }).format(item.startDate)} -
                    ${new Intl.DateTimeFormat('default', {
                        hour: '2-digit',
                        minute: '2-digit',
                    }).format(item.endDate)} ${  item.eventName}`
                    : ` ${  item.eventName}`;
            }else{
                list.title = `${  item.eventName}`;
            }
            list.start = DateTimeString(item.startDate);
            list.end = DateTimeString(item.endDate);
            list.id = item.calendarSettingId;
            list.hexColor = '#98ca69'
            return list
        }): [{
            title: '',
            hexColor : '#EAF6FE',
            start: Date.now(),
            end: Date.now(),
            allDay : true,
        }];
        const eventList = agentAssigns ? agentAssigns.map(item => {
            const list = {};
            list.title = ` ${ item.user.fullName} (${item.workFlowName})`;
            list.start = DateTimeString(item.scheduledDate);
            list.end = DateTimeString(item.scheduledDate);
            list.id = item.caseId;
            list.hexColor = '#69abca'
            list.workflowName = item.workFlowName;
            return list
        }): [{
            title: '',
            start: Date.now(),
            end: Date.now(),
            hexColor : '#EAF6FE',
            allDay : true,
        }];
        const eventListForAgent = agentAssigns ? agentAssigns.map(item => {
            const list = {};
            list.title = `${  item.caseName} - ${item.workFlowName}`;
            list.start = DateTimeString(item.scheduledDate);
            list.end = DateTimeString(item.scheduledDate);
            list.allDay = true;
            list.hexColor = '#69abca'
            return list
        }): [{
            title: '',
            start: Date.now(),
            end: Date.now(),
            hexColor : '#EAF6FE',
            allDay : true,
        }]
        const allEvents = [...eventList, ...allHolidayEventsList];
        const allAgentEvents = [...eventListForAgent, ...allHolidayEventsList];
        const eventStyleGetter = (event, start, end, isSelected) => {
            var backgroundColor = this.state.currentView === "agenda" ? "#ffffff" :event.hexColor;
            var color =  this.state.currentView === "agenda" ? "#000000" : "#ffffff";
            var style = {
                backgroundColor: backgroundColor,
                color: color,
                borderRadius:'5px',
                minHeight: '5%'
            };
            return {
                style: style
            };
        }
        const handleOpenDialogue = () =>{
            this.props.clearModalData();
            this.setState({
                openDialogue : true
            })
        }
        const handleCloseDialogue = () =>{
            this.setState({
                openDialogue : false
            })
        }
        const handleOpenModalToAddEvent = ({start, end}) =>{
            this.setState({
                openModalToAddEvent: true,
                start: new Date(start).setHours(0, 0, 0),
                end: new Date(end).setHours(23, 59, 59),
            })
        }
        const handleCloseModalToAddEvent = () => {
            this.setState({
                openModalToAddEvent: false,
            })
        }
      
        return(
        <>
            <HelmetComponent title="Calendar"/>
            {this.props.state.caseState.loading ? (
            <div
                className={
                    this.props.state.caseState.loading ? "loader" : "loader hidden"
                }
                id="loader"
            >
                <CircularProgress style={{color: "#8BC83F"}}/>
            </div>
        ) : null}
            {
                settingsState && settingsState.holidayAddSuccess === true ?
                    this.setState({
                        openModalToAddEvent: false,
                    }, () => {
                        this.props.setToFalse();
                    }) : null
            }
            <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                <Link color="inherit" to="/dashboard" style={style.link}>
                    <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                </Link>
                <Link color="inherit" to="/case" style={style.link}>
                    Case
                </Link>
                <Typography color="inherit" className="link">
                   Calendar
                </Typography>
            </Breadcrumbs>
            {this.props.state.calendarState.loading ?
                <div className={this.props.state.calendarState.loading ? 'loader' : 'loader hidden'} id="loader">
                    <CircularProgress style={{color: '#8BC83F'}}/>
                </div> : null
            }
            <div className="search-block">
                <div className="search">
                    <div className="searchIcon">
                        <SearchIcon style={{color: '#9c9c9c'}}/>
                    </div>
                    <InputBase
                        placeholder="Search…"
                        inputProps={{'aria-label': 'search'}}
                        className="inputBase"
                        onChange={e => {
                            e.preventDefault();
                            if (e.target.value === "" || e.target.value === " ") {
                                this.props.fetchDataListForCalendar();
                            } else {
                                this.props.searchCalendarData(e.target.value, this.props);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="calendar-wrapper">
                <Calendar
                    localizer={localizer}
                    onSelectEvent={event => {
                        if(event.workflowName){
                            this.props.getWorkflowName(event.workflowName)
                            this.props.getCaseInfo(event.id)
                            handleOpenDialogue()
                        }
                    }}
                    timeslot={30}
                    selectable
                    onSelectSlot={handleOpenModalToAddEvent}
                    events={userRole === "AGENT" ? allAgentEvents : allEvents}
                    startAccessor="start"
                    endAccessor="end"
                    onView={(e) => {
                        this.setState({
                            currentView : e,
                        })
                    }}
                    popup
                    eventPropGetter={eventStyleGetter}
                    messages = {{agenda: 'Scheduled Task'}}
                />

            </div>
            <Dialog
                id='viewScheduleTransactionDetails'
                maxWidth="lg"
                open={this.state.openDialogue}
                onClose={handleCloseDialogue}
            >
                <DialogTitleComponent
                    id="draggable-dialog-title"
                    onClose={handleCloseDialogue}>
                   Case details
                </DialogTitleComponent>
                <DialogContent
                    dividers
                    style={{

                        background: 'white',
                        textAlign: 'left',
                        padding: '24px',
                    }}>
                    <DialogContentText>
                          <span className="row span-row">
                                <span className="col-6" >
                                    <label htmlFor="caseId" className="columnHeading">Case Id</label>
                                     <label  className="data">
                                         {caseData && caseData.caseId}
                                     </label>
                                </span>
                                <span className="col-4">
                                        <label htmlFor="caseId" className="columnHeading">Workflow Name</label>
                                     <label  className="data">
                                           {caseData && caseData.workflow && caseData.workflow.map(work =>
                                               <div>
                                                   <span style={workflowName === work.workflowName
                                                       ? {backgroundColor: '#d0ed7b'} :
                                                       {backgroundColor: 'none'}}>{ work.workflowName}</span>
                                               </div>
                                           )}
                                     </label>
                                </span>
                          </span>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/*add holiday*/}
            <Dialog
                open={this.state.openModalToAddEvent}
                onClose={handleCloseModalToAddEvent}
                PaperComponent={PaperComponent}
                aria-labelledby="#draggable-dialog-title"
                maxWidth="xl"
            >
                <div>
                    <DialogTitleComponent
                        id="draggable-dialog-title"
                        onClose={handleCloseModalToAddEvent}>
                        Add Holiday/ Event
                    </DialogTitleComponent>
                    <DialogContent dividers
                                   style={{
                                       paddingTop: "0px",
                                       borderBottom: "none"}}
                                   className="dialogContent"
                    >
                        <div>
                            <label className="label">
                                Calendar Type
                                <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="select-input h40">
                                <Select
                                    className="inputField"
                                    showSearch={false}
                                    getPopupContainer={(node) => node.parentNode}
                                    onChange={(e) => {
                                        this.props.handleChangeCalendarType(e);
                                    }}
                                >
                                    <Option value="HOLIDAY">Holiday</Option>
                                    <Option value="EVENT">Event</Option>
                                </Select>
                            </div>
                        </div>
                        <div style={{marginTop: "12px"}}>
                            <label className="label">Holiday/ Event Name
                                <span style={{color: "red"}}> *</span>
                            </label>
                            <input
                                type='text'
                                id="holiday"
                                className="input"
                                autoComplete='off'
                                onChange={(e) => {
                                    this.props.handleChangeHolidayName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <label className="label">Start Date
                                    <span style={{color: "red"}}> *</span>
                                </label>
                                <div className="select-input" style={style.searchDate}>
                                    <KeyBoardDatePickerComponent
                                        dateTimeFormat
                                        isCalendarDate
                                        dateValue={this.state.start && getFormattedDateTimeValue(this.state.start)}
                                        onChangeDate={(date) => {
                                            this.setState({
                                                start: date,
                                            })
                                        }}
                                    />
                                    {/*<DatePicker*/}
                                    {/*    className="rangeBase"*/}
                                    {/*    dropdownClassName="calendar-holiday-start-datepicker"*/}
                                    {/*    placeholder="YYYY-MM-DD HH:mm:ss"*/}
                                    {/*    // use12Hours = {true}*/}
                                    {/*    value={this.state.start && getDateFromPicker(this.state.start)}*/}
                                    {/*    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}*/}
                                    {/*    getPopupContainer={trigger => trigger.parentNode}*/}
                                    {/*    onChange={handleChangeStartDate}*/}
                                    {/*    showToday={false}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                            <div className="col-lg-12" style={{marginTop: '12px'}}>
                                <label className="label">End Date
                                    <span style={{color: "red"}}> *</span>
                                </label>
                                <div className="select-input" style={style.searchDate}>
                                    <KeyBoardDatePickerComponent
                                        dateTimeFormat
                                        isCalendarDate
                                        dateValue={this.state.end &&
                                            getFormattedDateTimeValue(this.state.end)}
                                        onChangeDate={(date) => {
                                            this.setState({
                                                end: date,
                                            })
                                            // this.props.handleChangeStartDate(date);
                                        }}
                                    />
                                    {/*<DatePicker*/}
                                    {/*    className="rangeBase"*/}
                                    {/*    dropdownClassName="calendar-holiday-start-datepicker"*/}
                                    {/*    placeholder="YYYY-MM-DD HH:mm:ss"*/}
                                    {/*    value={this.state.end && getDateFromPicker(this.state.end)}*/}
                                    {/*    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}*/}
                                    {/*    getPopupContainer={trigger => trigger.parentNode}*/}
                                    {/*    onChange={handleChangeEndDate}*/}
                                    {/*    showToday={false}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6"/>
                            <div className="col-6" style={{paddingTop: "14px"}}>
                                <Button
                                    style={{float: "right"}}
                                    disabled={!value}
                                    className= {value ? "addButton" : "disabledAddButton"}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const data ={
                                            eventName : holidayName,
                                            calendarType: calendarType,
                                            startDate: this.state.start,
                                            endDate: this.state.end,
                                        }
                                        this.props.addHolidays(data, this.props)
                                    }}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        </>

            )
    }

}
const style = {
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex: 1
    },
    link: {
        color: '#8BC83F'
    },
    searchDate: {
        position: 'relative',
        backgroundColor: "#F4F5F9",
        marginRight: '1.2rem',
        marginTop: '10px',
        width: '100%',
        height: '40px',
    },
}
export default CalendarViewScreen;