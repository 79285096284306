import { connect } from 'react-redux';
import { WorkflowFormScreen } from "../../screens";
import {
    addWorkflowFailure,
    addWorkflowSuccess,
    closeSnackBarForWorkflowForm,
    fetchActionFailure,
    fetchActionSuccess,
    workflowFormRequestMade
} from "../../actions";
import { WorkflowFormAPI } from "../../api";
import { handleChangeWorkflow } from "../../actions/WorkflowFormAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchActionList: () => {
            dispatch(workflowFormRequestMade(true));
            WorkflowFormAPI.fetchActionList((response, error) => {
                if (response) {
                    dispatch(fetchActionSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchActionFailure(error.msg))
                    } else {
                        dispatch(fetchActionFailure(error));
                    }
                }
            })
        },
        handleChangeWorkflow: (id, value) => dispatch(handleChangeWorkflow(id, value)),
        addNewWorkflow: (workflowData, props) => {
            dispatch(workflowFormRequestMade(true));
            WorkflowFormAPI.addNewWorkflow(workflowData, (response, error) => {
                if (response) {
                    dispatch(addWorkflowSuccess(true));
                    props.history.replace('/workflow');
                } else {
                    if (error.msg) {
                        dispatch(addWorkflowFailure(error.msg));
                    } else {
                        dispatch(addWorkflowFailure(error));
                    }
                }
            });
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForWorkflowForm(true)),
    };
};


export const WorkflowFormContainer = connect(mapStateToProps, mapDispatchToProps)(WorkflowFormScreen);