import { connect } from "react-redux";
import ProfileScreen from "../../screens/ProfileScreen";
import { ClientAPI, LocalDb, ProfileAPI } from "../../api";
import {
  addClientSuccess,
  assignProfilePictureFailure,
  assignProfilePictureSuccess,
  changePasswordFailure,
  changePasswordSuccess,
  closeSnackBarForProfile,
  fetchProfileSucess,
  handleChange,
  handleChangeForChangePassword,
  profileRequestMade,
  updateClientFailure,
  updateProfileFailure,
  updateProfilePictureFailure,
  updateProfilePictureSuccess,
  updateProfileSuccess,
} from "../../actions";
import CorporateAPI from "../../api/CorporateAPI";
import { exportUserData } from "../../actions/ProfileAction";
import {
  handleChangeEnableNotifications,
  saveEnabledNotificationFailure,
  saveEnabledNotificationSuccess,
  clientRequestMade,
  getAlertByAccountSuccess,
  getAlertByAccountFailure,
  handleChangeHolidayAlert,
  handleChangeUpcomingAlert,
  closeSnackBarForClient,
} from "../../actions/ClientAction";

const mapStateToProps = (state) => {
  return {
    state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (id, value) => dispatch(handleChange(id, value)),
    handleChangeForPassword: (id, value) =>
      dispatch(handleChangeForChangePassword(id, value)),
    changePassword: (accountData, props) => {
      dispatch(profileRequestMade(true));
      ProfileAPI.changePassword(accountData, (response, error) => {
        if (response) {
          dispatch(changePasswordSuccess(true));
        } else {
          if (error.msg) {
            dispatch(changePasswordFailure(error.msg));
          } else {
            dispatch(changePasswordFailure(error));
          }
        }
      });
    },
    updateProfile: (profileData, props) => {
      dispatch(profileRequestMade(true));
      if (LocalDb.getSessions().loginResponse.loginUser.user) {
        ProfileAPI.updateProfile(profileData, (response, error) => {
          if (response) {
            var previous = LocalDb.getSessions();
            previous.loginResponse.loginUser.user = response.user;
            LocalDb.setSession(previous, (err) => {
              if (err === false) {
                props.fetchProfile(profileData.userId);
                dispatch(updateProfileSuccess(response));
              }
            });
          } else {
            if (error.msg) {
              dispatch(updateProfileFailure(error.msg));
            } else {
              dispatch(updateProfileFailure(error));
            }
          }
        });
      } else if (LocalDb.getSessions().loginResponse.loginUser.corporate) {
        CorporateAPI.editCorporate(profileData, (response, error) => {
          if (response) {
            var previous = LocalDb.getSessions();
            previous.loginResponse.loginUser.corporate = response.corporate;
            LocalDb.setSession(previous, (err) => {
              if (err === false) {
                props.fetchProfile(profileData.corporateId);
                dispatch(updateProfileSuccess(response));
              }
            });
          } else {
            if (error.msg) {
              dispatch(updateProfileFailure(error.msg));
            } else {
              dispatch(updateProfileFailure(error));
            }
          }
        });
      } else {
        ClientAPI.editClient(profileData, (response, error) => {
          if (response) {
            var previous = LocalDb.getSessions();
            previous.loginResponse.loginUser.client = response.client;
            LocalDb.setSession(previous, (err) => {
              if (err === false) {
                props.fetchProfile(profileData.clientId);
                dispatch(updateProfileSuccess(response));
              }
            });
          } else {
            if (error.msg) {
              dispatch(updateProfileFailure(error.msg));
            } else {
              dispatch(updateProfileFailure(error));
            }
          }
        });
      }
    },
    fetchProfile: (userId) => {
      dispatch(profileRequestMade(true));
      ProfileAPI.fetchProfile(userId, (response, error) => {
        if (response) {
          dispatch(fetchProfileSucess(response));
        } else {
          if (error.msg) {
            dispatch(updateProfileFailure(error.msg));
          } else {
            dispatch(updateProfileFailure(error));
          }
        }
      });
    },

    exportUserData: () => {
      dispatch(exportUserData());
    },

    updateProfilePicture: (selectedFiles, props) => {
      dispatch(profileRequestMade(true));
      let formdata = new FormData();
      formdata.append("image", selectedFiles.file);
      formdata.append("mimeType", selectedFiles.type);
      ProfileAPI.updateProfilePicture(formdata, (response, error) => {
        if (response) {
          var documents = [
            {
              url: response.fileUrl,
              documentType: selectedFiles.type,
              description: selectedFiles.type,
            },
          ];
          dispatch(updateProfilePictureSuccess(documents));
          props.assignProfilePicture(response.fileUrl);
        } else {
          if (error.msg) {
            dispatch(updateProfilePictureFailure(error.msg));
          } else {
            dispatch(updateProfilePictureFailure(error));
          }
        }
      });
    },
    assignProfilePicture: (profilePictureUrl) => {
      dispatch(profileRequestMade(true));
      ProfileAPI.assignProfilePicture(profilePictureUrl, (response, error) => {
        if (response) {
          dispatch(assignProfilePictureSuccess(response));
        } else {
          if (error.msg) {
            dispatch(assignProfilePictureFailure(error.msg));
          } else {
            dispatch(assignProfilePictureFailure(error));
          }
        }
      });
    },
    handleSnackBarCloseForProfile: () =>
      dispatch(closeSnackBarForProfile(true)),

    // CLIENT NOTIFICATION SETTING
    handleChangeEnableNotification: (id, value) => {
      dispatch(handleChangeEnableNotifications(id, value));
    },
    saveNotifications: (enabledData, props) => {
      dispatch(clientRequestMade(true));
      ClientAPI.saveEnabledNotification(enabledData, (response, error) => {
        if (response) {
          dispatch(saveEnabledNotificationSuccess(response));
          props.getAlertInfo(enabledData.accountId);
        } else {
          if (error.msg) {
            dispatch(saveEnabledNotificationFailure(error.msg));
            props.getAlertInfo(enabledData.accountId);
          } else {
            dispatch(saveEnabledNotificationFailure(error));
            props.getAlertInfo(enabledData.accountId);
          }
        }
      });
    },
    getAlertInfo: (accountId) => {
      dispatch(clientRequestMade(true));
      ProfileAPI.getAlertInfo(accountId, (response, error) => {
        if (response) {
          dispatch(getAlertByAccountSuccess(response));
        } else {
          if (error.msg) {
            dispatch(getAlertByAccountFailure(error.msg));
          } else {
            dispatch(getAlertByAccountFailure(error));
          }
        }
      });
    },
    handleChangeHolidayAlert: bool => {
      dispatch(handleChangeHolidayAlert(bool));
    },
    handleChangeUpcomingAlert: bool => {
      dispatch(handleChangeUpcomingAlert(bool));
    },
    closeSnackBarForClient: bool => {
      dispatch(closeSnackBarForClient(bool));
    },
  };
};

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileScreen);
