import { connect } from 'react-redux';
import { ActionFormScreen } from "../../screens";
import {
    actionRequestMade,
    addActionFailure,
    addActionSuccess,
    closeSnackBarForAction,
    handleChangeAction
} from "../../actions";
import { ActionAPI } from "../../api";
import {
    getConditionalFormFailure,
    getConditionalFormSuccess,
    handleChangeFirstConditionalForm,
    searchExistingOptionValueFailure,
    searchExistingOptionValueSuccess
} from "../../actions/ActionTemplateAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleChangeAction: (id, value) => dispatch(handleChangeAction(id, value)),
        addNewAction: (actionData, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.addNewAction(actionData, (response, error) => {
                if (response) {
                    dispatch(addActionSuccess(true));
                    props.history.replace('/action');
                } else {
                    if (error.msg) {
                        dispatch(addActionFailure(error.msg));
                    } else {
                        dispatch(addActionFailure(error));
                    }
                }
            });
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForAction(true)),
        searchExistingOptionValue: (term, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.searchOptionValue(term, (response, error) => {
                if (response) {
                    dispatch(searchExistingOptionValueSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(searchExistingOptionValueFailure(error.msg));
                    } else {
                        dispatch(searchExistingOptionValueFailure(error));
                    }
                }
            });
        },
        handleChangeFirstConditionalForm: (conditionalForm) =>
            dispatch(handleChangeFirstConditionalForm(conditionalForm)),

        getConditionalFormByRefId: (id, props) => {
            dispatch(actionRequestMade(true));
            ActionAPI.getConditionalFormByRefId(id, (response, error) => {
                if (response) {
                    dispatch(getConditionalFormSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(getConditionalFormFailure(error.msg));
                    } else {
                        dispatch(getConditionalFormFailure(error));
                    }
                }
            });
        },

    };
};


export const ActionFormContainer = connect(mapStateToProps, mapDispatchToProps)(ActionFormScreen);