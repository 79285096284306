import {ACTION_TYPE_SET_PASSWORD} from "../constants/Type";

export const setPasswordSuccess = (bool) => {
    return {
        type: ACTION_TYPE_SET_PASSWORD.SET_CLIENT_PASSWORD_SUCCESS,
        bool
    };
};
export const setPasswordFailure = (error) => {
    return {
        type: ACTION_TYPE_SET_PASSWORD.SET_CLIENT_PASSWORD_FAILURE,
        error
    };
};
export const handleChangeEmail = (id, value) => {
    return {
        type: ACTION_TYPE_SET_PASSWORD.HANDLE_CHANGE_EMAIL,
        id,
        value
    };
};