import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import {Tabs} from "antd";
import CompanyScreen from "./CompanyScreen";
import IndividualContactScreen from "./IndividualContactScreen";
import {HelmetComponent} from "../components/Helmet";
const { TabPane } = Tabs;

class ContactScreen extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state ={
            openModalToAddCompany: false,
            openModalToAddContact: false,
            openModalToEditContact:false,
            openModalToDeleteContact: false,
            openModalToEditCompany:false,
            openModalToDeleteCompany: false,
            openModalToSetContactAccount: false,
            openModalToSetCompanyAccount: false,
            openActionMenu: false,
            popoverElement: null,
            page: 0,
            rowsPerPage: 10,
            pageInCompany: 0,
            rowsPerPageInCompany: 10,
            contacts: [],
            contactTabKey: localStorage.getItem("contactTabKey"),
            setValue:"",
        }
    }

    componentWillUnmount() {
        localStorage.setItem("contactTabKey", "1");
    }

    render() {

        function callback(key) {
            localStorage.setItem("contactTabKey", key);
        }

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage,
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleChangePageInCompany = (event, newPage) => {
            this.setState({
                pageInCompany: newPage,
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleChangeRowsPerPageInCompany = event => {
            this.setState({
                rowsPerPageInCompany: parseInt(event.target.value, 10),
                page: 0
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };
        const handleChangeOpenModalToAddCompany = () => {
            this.setState({
                openModalToAddCompany: true,
            }, () =>{
                this.props.setContactDataToEmpty();
            });
        };
        const handleChangeCloseModalToAddCompany = () => {
            this.setState({
                openModalToAddCompany: false,
            });
        };
        const handleChangeOpenModalToAddContact = () => {
            this.setState({
                openModalToAddContact: true,
            },() =>{
                this.props.setContactDataToEmpty();
            });
        };
        const handleChangeCloseModalToAddContact = () => {
            this.setState({
                openModalToAddContact: false,
            });
        };

        //to edit contact
        const handleChangeOpenModalToEditContact = () =>{
            this.setState({
                openModalToEditContact: true,
            })
        }
        const handleChangeCloseModalToEditContact = () =>{
            this.setState({
                openModalToEditContact: false,
            })
        }

        //to delete contact
        const handleChangeOpenModalToDeleteContact = () =>{
            this.setState({
                openModalToDeleteContact: true,
            })
        }
        const handleChangeCloseModalToDeleteContact = () =>{
            this.setState({
                openModalToDeleteContact: false,
            })
        }

        //to edit company
        const handleChangeOpenModalToEditCompany = () =>{
            this.setState({
                openModalToEditCompany: true,
            })
        }
        const handleChangeCloseModalToEditCompany = () =>{
            this.setState({
                openModalToEditCompany: false,
            })
        }

        //to delete company
        const handleChangeOpenModalToDeleteCompany = () =>{
            this.setState({
                openModalToDeleteCompany: true,
            })
        }
        const handleChangeCloseModalToDeleteCompany = () =>{
            this.setState({
                openModalToDeleteCompany: false,
            })
        }

        //to set contact account
        const handleChangeOpenModalToSetContactAccount= () =>{
            this.setState({
                openModalToSetContactAccount: true,
            })
        }
        const handleChangeCloseModalToSetContactAccount = () =>{
            this.setState({
                openModalToSetContactAccount: false,
            })
        }

        //to set company account
        const handleChangeOpenModalToSetCompanyAccount = () =>{
            this.setState({
                openModalToSetCompanyAccount: true,
            })
        }
        const handleChangeCloseModalToSetCompanyAccount = () =>{
            this.setState({
                openModalToSetCompanyAccount: false,
            })
        }

        //for individual contact
        const handleChangeContactInput = (id, value) => {
            this.props.handleChangeContactInput(id, value);
        }
        const addIndividualContact = (contactData) =>{
            this.setState({
                setValue: "add"
            })
            this.props.addContact(contactData, this.props);
        }
        const editIndividualContact = (contactData) =>{
            this.props.editContact(contactData, this.props);
        }
        const setContactInputToEdit = (contactData) =>{
            this.props.setContactInputToEdit(contactData);
        }
        const deleteIndividualContact = (contactId) => {
            this.props.deleteContact(contactId, this.props);
        }
        const searchIndividualContact = (term) => {
            this.props.searchIndividualContact(term, this.props);
        }

        const setContactsAccount = (accountDetails) => {
            this.props.setContactsAccount(accountDetails, this.props);
        }

        //for company
        const handleChangeCompanyInput = (id, value) => {
            this.props.handleChangeCompanyInput(id, value);
        }
        const addCompany = (companyData) =>{
            this.setState({
                setValue: "add"
            })
            this.props.addCompany(companyData, this.props);
        }
        const editCompany = (companyData) =>{
            this.props.editCompany(companyData, this.props);
        }
        const setCompanyInputToEdit = (companyData) =>{
            this.props.setCompanyInputToEdit(companyData);
        }
        const deleteCompany = (companyId) => {
            this.props.deleteCompany(companyId, this.props);
        }
        const deleteCompanyContact = (companyId, contactId) => {
            this.props.deleteCompanyContact(companyId,contactId, this.props);
        }
        const searchCompany = (term) => {
            this.props.searchCompanyContact(term, this.props);
        }
        const searchContact = (term) => {
            this.props.searchIndividualContact(term, this.props);
        }
        return(
            <React.Fragment>
                <HelmetComponent title="Contact"/>
                { this.props.state.contactState.success === true ?
                        this.setState({
                            openModalToAddContact: false,
                            openModalToEditContact: false,
                            openModalToAddCompany: false,
                            openModalToEditCompany: false,
                        }, () =>{
                            if(this.state.setValue === "add"){
                                this.props.setAddContactToFalse();
                                this.props.setAddCompanyToFalse();
                            }else{
                                this.props.setUpdateContactToFalse();
                                this.props.setUpdateCompanyToFalse();
                            }
                        }): null
                }
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Typography color="inherit" className="link">
                        Contact
                    </Typography>
                </Breadcrumbs>
                <div style={{marginTop: "48px", marginRight: "2px"}}>
                    <Tabs defaultActiveKey={this.state.contactTabKey} onChange={callback} className="fixed-tab tabClass">
                        <TabPane tab="Individual" key="1" className="secondTab">
                            <IndividualContactScreen
                                propsState={this.props.state}
                                property={this.props}
                                handleChangePage={handleChangePage}
                                page={this.state.page}
                                tableRef={this.tableRef}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                rowsPerPage={this.state.rowsPerPage}
                                handleChangeOpenModalToAddContact={handleChangeOpenModalToAddContact}
                                handleChangeCloseModalToAddContact = {handleChangeCloseModalToAddContact}
                                openModalToAddContact={this.state.openModalToAddContact}
                                handleChangeOpenModalToEditContact={handleChangeOpenModalToEditContact}
                                handleChangeCloseModalToEditContact={handleChangeCloseModalToEditContact}
                                openModalToEditContact={this.state.openModalToEditContact}
                                handleChangeOpenModalToDeleteContact={handleChangeOpenModalToDeleteContact}
                                handleChangeCloseModalToDeleteContact={handleChangeCloseModalToDeleteContact}
                                openModalToDeleteContact={this.state.openModalToDeleteContact}
                                handleChangeOpenModalToSetContactAccount={handleChangeOpenModalToSetContactAccount}
                                handleChangeCloseModalToSetContactAccount={handleChangeCloseModalToSetContactAccount}
                                openModalToSetContactAccount={this.state.openModalToSetContactAccount}
                                handleChangeContactInput={handleChangeContactInput}
                                addIndividualContact={addIndividualContact}
                                setContactInputToEdit={setContactInputToEdit}
                                editIndividualContact={editIndividualContact}
                                deleteIndividualContact={deleteIndividualContact}
                                fetchContactList={this.props.fetchContactList}
                                closeSnackBar={this.props.closeContactSnackBar}
                                searchIndividualContact={searchIndividualContact}
                                setContactsAccount={setContactsAccount}
                                errors={this.state.errors}
                            />
                        </TabPane>
                        <TabPane tab="Company" key="2">
                            <CompanyScreen
                                propsState={this.props.state}
                                property={this.props}
                                handleChangeCloseModalToAddCompany={handleChangeCloseModalToAddCompany}
                                handleChangeOpenModalToAddCompany = {handleChangeOpenModalToAddCompany}
                                openModalToAddCompany={this.state.openModalToAddCompany}
                                fetchCompanyList={this.props.fetchCompanyList}
                                handleChangeCompanyInput={handleChangeCompanyInput}
                                addCompany={addCompany}
                                editCompany={editCompany}
                                setCompanyInputToEdit={setCompanyInputToEdit}
                                deleteCompany={deleteCompany}
                                deleteCompanyContact={deleteCompanyContact}
                                handleChangeOpenModalToEditCompany={handleChangeOpenModalToEditCompany}
                                handleChangeCloseModalToEditCompany={handleChangeCloseModalToEditCompany}
                                openModalToEditCompany={this.state.openModalToEditCompany}
                                handleChangeOpenModalToDeleteCompany={handleChangeOpenModalToDeleteCompany}
                                handleChangeCloseModalToDeleteCompany={handleChangeCloseModalToDeleteCompany}
                                openModalToDeleteCompany={this.state.openModalToDeleteCompany}
                                handleChangeOpenModalToSetCompanyAccount={handleChangeOpenModalToSetCompanyAccount}
                                handleChangeCloseModalToSetCompanyAccount={handleChangeCloseModalToSetCompanyAccount}
                                openModalToSetCompanyAccount={this.state.openModalToSetCompanyAccount}
                                handleChangePage={handleChangePageInCompany}
                                page={this.state.pageInCompany}
                                handleChangeRowsPerPage={handleChangeRowsPerPageInCompany}
                                rowsPerPage={this.state.rowsPerPageInCompany}
                                tableRef={this.tableRef}
                                closeSnackBar={this.props.closeContactSnackBar}
                                searchCompany={searchCompany}
                                searchContact={searchContact}
                                fetchCompanyType={this.props.fetchCompanyType}
                                setContactsAccount={setContactsAccount}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </React.Fragment>
        )
    }
}
export default ContactScreen;