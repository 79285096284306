import { API } from "./index";
import APIEndPoints from "../constants/APIConstants";

class CaseTemplateAPI extends API {
  fetchCaseTemplateList = (callback) => {
    fetch(APIEndPoints.FETCH_CASE_TEMPLATE, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  addNewCaseTemplate = (caseTemplateData, callback) => {
    let body = JSON.stringify(caseTemplateData);
    fetch(APIEndPoints.ADD_CASE_TEMPLATE, {
      method: "POST",
      headers: this.authHeaders,
      body: body,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  deleteCaseTemplate = (templateId, callback) => {
    const urlString = APIEndPoints.DELETE_CASE_TEMPLATE + templateId;
    fetch(urlString, {
      method: "DELETE",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  fetchSingleCaseTemplate = (id, callback) => {
    var constUrl = APIEndPoints.FETCH_SINGLE_CASE_TEMPLATE + id;
    fetch(constUrl, {
      method: "GET",
      headers: this.authHeaders,
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };

  editCaseTemplate = (caseTemplateFormData, callback) => {
    fetch(APIEndPoints.EDIT_CASE_TEMPLATE, {
      method: "PATCH",
      headers: this.authHeaders,
      body: JSON.stringify(caseTemplateFormData),
    })
      .then((response) => {
        this.processResponse(response, callback);
      })
      .catch((error) => {
        callback(null, "Service error, please try again.");
      });
  };
}

export default new CaseTemplateAPI();
