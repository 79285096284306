import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class WorkflowAPI extends API {
    fetchWorkflowList = callback => {
        fetch(APIEndPoints.FETCH_WORKFLOW, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling workflow api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteWorkflow = (workflowId, callback) => {
        const urlString = APIEndPoints.DELETE_WORKFLOW + workflowId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        })
    };
}

export default new WorkflowAPI();