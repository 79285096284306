import { ACTION_TYPE_VENDOR} from "../constants/Type";

export const vendorRequestMade = bool => {
    return{
        type: ACTION_TYPE_VENDOR.VENDOR_REQUEST_MADE,
        bool,
    }
}
export const handleChangeVendor = (id, value, label) => {
    return{
        type: ACTION_TYPE_VENDOR.HANDLE_CHANGE_VENDOR_INPUT,
        id,
        value,
        label
    }
}
export const addNewVendorSuccess = bool => {
    return{
        type: ACTION_TYPE_VENDOR.ADD_VENDOR_SUCCESS,
        bool,
    }
}

export const addNewVendorFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.ADD_VENDOR_FAILURE,
        error,
    }
}
export const deleteVendorSuccess = vendorId => {
    return {
        type: ACTION_TYPE_VENDOR.DELETE_VENDOR_SUCCESS,
        vendorId,
    }
}
export const deleteVendorFailure = error =>{
    return{
        type: ACTION_TYPE_VENDOR.DELETE_VENDOR_FAILURE,
        error
    }
}
export const fetchVendorSuccess = vendorAccount => {
    return{
        type: ACTION_TYPE_VENDOR.FETCH_VENDOR_SUCCESS,
        vendorAccount,
    }
}
export const fetchVendorFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.FETCH_VENDOR_FAILURE,
        error,
    }
}
export const closeVendorSnackBar = (bool) => {
    return {
        type: ACTION_TYPE_VENDOR.CLOSE_SNACK_BAR_VENDOR_PAGE,
        bool
    };
};

export const searchVendorSuccess = (vendorList) =>{
    return{
        type: ACTION_TYPE_VENDOR.SEARCH_VENDOR_SUCCESS,
        vendorList
    }
}

export const searchVendorFailure = (error) => {
    return{
        type: ACTION_TYPE_VENDOR.SEARCH_VENDOR_FAILURE,
        error
    }
}

export const setVendorToEdit = (vendors) => {
    return{
        type: ACTION_TYPE_VENDOR.SET_VENDOR_TO_EDIT,
        vendors
    }
}
export const updateVendorSuccess = (bool) =>{
    return{
        type: ACTION_TYPE_VENDOR.UPDATE_VENDOR_SUCCESS,
        bool,
    }
}

export const updateVendorFailure = (error) =>{
    return{
        type: ACTION_TYPE_VENDOR.UPDATE_VENDOR_FAILURE,
        error
    }
}
export const clearVendorInputData = () =>{
    return{
        type: ACTION_TYPE_VENDOR.CLEAR_VENDOR_INPUT_DATA,
    }
}

export const filterDataArray = () =>{
    return{
        type: ACTION_TYPE_VENDOR.FILTER_DATA_ARRAY,
    }
}

// VENDOR CLIENT
export const fetchVendorClientSuccess = vendorClient=> {
    return{
        type: ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENTS_LIST_SUCCESS,
        vendorClient,
    }
}
export const fetchVendorClientFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENTS_LIST_FAILURE,
        error,
    }
}

export const fetchVendorClientByIdSuccess = clientVendor => {
    return{
        type: ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENT_BY_CLIENT_ID_SUCCESS,
        clientVendor,
    }
}
export const fetchVendorClientByIdFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.FETCH_VENDOR_CLIENT_BY_CLIENT_ID_FAILURE,
        error,
    }
}

export const handleChangeVendorClient = (id, value) => {
    return{
        type: ACTION_TYPE_VENDOR.HANDLE_CHANGE_VENDOR_CLIENT_DATA,
        id,
        value,
    }
}

export const addVendorClientSuccess = response=> {
    return{
        type: ACTION_TYPE_VENDOR.ADD_VENDOR_CLIENT_SUCCESS,
        response,
    }
}
export const addVendorClientFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.ADD_VENDOR_CLIENT_FAILURE,
        error,
    }
}

export const updateVendorClientSuccess = response=> {
    return{
        type: ACTION_TYPE_VENDOR.UPDATE_VENDOR_CLIENT_SUCCESS,
        response,
    }
}
export const updateVendorClientFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.UPDATE_VENDOR_CLIENT_FAILURE,
        error,
    }
}

export const deleteVendorClientSuccess = response=> {
    return{
        type: ACTION_TYPE_VENDOR.DELETE_VENDOR_SUCCESS,
        response,
    }
}
export const deleteVendorClientFailure = error => {
    return{
        type: ACTION_TYPE_VENDOR.DELETE_VENDOR_FAILURE,
        error,
    }
}
export const setVendorClientToEdit = (vendorClient) => {
    return{
        type: ACTION_TYPE_VENDOR.SET_VENDOR_CLIENT_TO_EDIT,
        vendorClient,
    }
}

export const handleChangeAddressData = (id, value) => {
    return{
        type: ACTION_TYPE_VENDOR.HANDLE_CHANGE_ADDRESS_DATA,
        id, value,
    }
}
export const handleChangeAddressAddress = () => {
    return{
        type: ACTION_TYPE_VENDOR.HANDLE_CHANGE_ADDRESS_ARRAY,
    }
}