import { ACTION_TYPE_PROFILE } from "../constants/Type";

export const profileRequestMade = (bool) => {
    return {
        type: ACTION_TYPE_PROFILE.PROFILE_REQUEST_MADE,
        bool
    };
};

export const exportUserData = () => {
    return{
        type: ACTION_TYPE_PROFILE.FETCH_USER_DATA,
    }
}

export const fetchProfileSucess = (profile) => {
    return {
        type: ACTION_TYPE_PROFILE.PROFILE_FETCH_SUCCESS,
        profile
    };
};

export const handleChange = (id, value) => {
    return {
        type: ACTION_TYPE_PROFILE.HANDLE_CHANGE,
        id, value
    };
};

export const handleChangeForChangePassword = (id, value) => {
    return {
        type: ACTION_TYPE_PROFILE.HANDLE_CHANGE_FOR_CHANGE_PASSWORD,
        id, value
    };
};

export const changePasswordSuccess = (bool) => {
    return {
        type: ACTION_TYPE_PROFILE.CHANGE_PASSWORD_SUCCESS,
        bool
    };
};

export const changePasswordFailure = (error) => {
    return {
        type: ACTION_TYPE_PROFILE.CHANGE_PASSWORD_FAILURE,
        error
    };
};

export const updateProfileSuccess = profileData => {
    return {
        type: ACTION_TYPE_PROFILE.UPDATE_PROFILE_SUCCESS,
        profileData
    };
};

export const updateProfileFailure = (error) => {
    return {
        type: ACTION_TYPE_PROFILE.UPDATE_PROFILE_FAILURE,
        error
    }
};

export const updateProfilePictureSuccess = profilePicture => {
    return {
        type: ACTION_TYPE_PROFILE.UPDATE_PROFILE_PICTURE_SUCCESS,
        profilePicture
    };
};

export const updateProfilePictureFailure = (error) => {
    return {
        type: ACTION_TYPE_PROFILE.UPDATE_PROFILE_PICTURE_FAILURE,
        error
    }
};

export const assignProfilePictureSuccess = (userProfile) => {
    return {
        type: ACTION_TYPE_PROFILE.ASSIGN_PROFILE_PICTURE_SUCCESS,
        userProfile
    }
};

export const assignProfilePictureFailure = (error) => {
    return {
        type: ACTION_TYPE_PROFILE.ASSIGN_PROFILE_PICTURE_FAILURE,
        error
    }
};

export const closeSnackBarForProfile = (bool) => {
    return {
        type: ACTION_TYPE_PROFILE.CLOSE_SNACK_BAR_PROFILE_PAGE,
        bool
    };
};
