import { ACTION_TYPE_CORPORATE_CLIENT } from "../constants/Type";

const initialState = {
  loading: false,
  openCorporateClientSnackBar: false,
  errorMsgForCorporateClient: "",
  openCorporateClientFormSnackBar: false,
  clientData: {
    ssn: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    isEnableTransaction: false,
    maritalStatus: "",
  },
  corporateClientData: {},
  openSuccessSnackBar: false,
  openErrorSnackBar: false,
};

const corporateClientState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_CORPORATE_CLIENT.FETCH_CORPORATE_INFO_SUCCESS:
      return {
        ...state,
        corporate: action.corporate.clients,
        loading: false,
        openCorporateClientSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.FETCH_CORPORATE_INFO_FAILURE:
      return {
        ...state,
        errorMsgForCorporateClient: action.error,
        loading: false,
        openCorporateClientSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.CORPORATE_CLIENT_REQUEST_MADE:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.DELETE_CORPORATE_CLIENT_SUCCESS:
      const oldCorporateClientList = state.corporate;
      let newCorporateClient = [];
      for (let i = 0; i < oldCorporateClientList.length; i++) {
        if (oldCorporateClientList[i].clientId !== action.clientId) {
          newCorporateClient.push(oldCorporateClientList[i]);
        }
      }
      return {
        ...state,
        newCorporateClient: newCorporateClient,
        loading: false,
        openCorporateClientSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.DELETE_CORPORATE_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForCorporateClient: action.error,
        loading: false,
        openCorporateClientSnackBar: true,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.CLOSE_SNACK_BAR_CORPORATE_CLIENT_PAGE:
      return {
        ...state,
        openCorporateClientSnackBar: false,
        openCorporateClientFormSnackBar: false,
        openSuccessSnackBar: false,
        openErrorSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.HANDLE_CHANGE_CORPORATE_CLIENT:
      const newClientForm = { ...state.clientData };
      newClientForm[action.id] = action.value;
      return {
        ...state,
        clientData: newClientForm,
        loading: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.ADD_CORPORATE_CLIENT_SUCCESS:
      return {
        ...state,
        addClientSuccess: action.bool,
        // corporateData:
        loading: false,
        openCorporateClientFormSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.ADD_CORPORATE_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForCorporateClient: action.error,
        loading: false,
        openCorporateClientFormSnackBar: true,
        openErrorSnackBar: true,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.SET_CORPORATE_CLIENT_TO_EDIT:
      return {
        ...state,
        clientData: action.corporateClientData,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.HANDLE_CHANGE_CORPORATE_CLIENT_EDIT:
      const newClientEditForm = { ...state.corporateClientData };
      newClientEditForm[action.id] = action.value;
      return {
        ...state,
        corporateClientData: newClientEditForm,
        loading: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.ASSIGN_CLIENT_TO_CORPORATE_SUCCESS:
      return {
        ...state,
        assignClientSuccess: action.bool,
        loading: false,
        openCorporateClientFormSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.ASSIGN_CLIENT_TO_CORPORATE_FAILURE:
      return {
        ...state,
        errorMsgForCorporateClient: action.error,
        loading: false,
        openCorporateClientFormSnackBar: true,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.SEARCH_CLIENT_SUCCESS:
      return {
        ...state,
        searchedClient: action.client.clients,
        loading: false,
        openCorporateClientFormSnackBar: false,
      };

    case ACTION_TYPE_CORPORATE_CLIENT.SEARCH_CLIENT_FAILURE:
      return {
        ...state,
        errorMsgForCorporateClient: action.error,
        loading: false,
        searchedClient: null,
        openCorporateClientFormSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        client: action.client.clients,
        loading: false,
        openCorporateClientFormSnackBar: false,
      };
    case ACTION_TYPE_CORPORATE_CLIENT.CLIENT_FETCH_FAILURE:
      return {
        ...state,
        errorMsgForCorporateClient: action.error,
        loading: false,
        openCorporateClientFormSnackBar: false,
      };
    default:
      return state;
  }
};

export default corporateClientState;
