import { ACTION_TYPE_SETTING } from "../constants/Type";

const initialState = {
  loading: false,
  holidayDetails: [],
  error: "",
  open: false,
  success: false,
  eventName: "",
  startDate: null,
  endDate: null,
  newStartDate: "",
  calendarType: "",
  openCalendarSettingSnackBar: false,
  openSettingErrorSnackBar: false,
  openSettingSuccessSnackBar: false,
  feePercentage: "",
  minMonthlyFee: 0,
  maxMonthlyFee: 0,
  annualFee: "",
  spendDownThreshold: "",
  achDelayThreshold: "",
  achUploadTime: "",
  timeFormat: "",
  openPaymentSettingSuccessSnackBar: false,
  icsFile: "",
  openCalendarSettingSuccessSnackbar: false,
  openCalendarSettingFailureSnackbar: false,
  assignedClientOrAgent: [],
  eventId: "",
  assignEvent: [],
  eligibilityData: {
    maxLimit: "",
    familyType: "",
    memberNo: "",
  },
  eligibilityList: [],
  holidayAddSuccess: false,
  descriptionList: [],
  descriptionData: {
    descriptionId: "",
    description: "",
  },
  vendorTypeList: [],
  vendorTypeData: {
    vendorTypeId: "",
    vendorTypeName: "",
    isDefault: "",
  },
  // checking account
  openCheckingAccountFormModal: false,
  checkingAccountList: [],
  checkingAccountData: {
    immediateDestination: "",
    disbursementId: "",
    depositId: "",
    accountNumber: "",
    destinationName: "",
    originName: "",
    isBalanced: false
  },
  checkingAccountAddress:{
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip5: "",
  },
  checkingAccountIsUpdate: false,
  isDefault: false,
};
const settingState = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE_SETTING.REQUEST_IN_CALENDAR_SETTING:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPE_SETTING.CLOSE_CALENDAR_SETTING_SNACKBAR:
      return {
        ...state,
        openCalendarSettingSnackBar: false,
        openSettingErrorSnackBar: false,
        openSettingSuccessSnackBar: false,
        openPaymentSettingSuccessSnackBar: false,
        openSettingUpdate: false,
        openSettingDelete: false,
        openCalendarSettingSuccessSnackbar: false,
        openCalendarSettingFailureSnackbar: false,
      };
    case ACTION_TYPE_SETTING.FETCH_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        holidayDetails: action.response.calendarSettings,
        success: false,
      };
    case ACTION_TYPE_SETTING.FETCH_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_HOLIDAY_NAME:
      return {
        ...state,
        eventName: action.holidayName,
      };
      case ACTION_TYPE_SETTING.HANDLE_CHANGE_INITIAL_START_DATE:
        return {
          ...state,
          newStartDate: action.date,
          // startDate: action.date,
        };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_CALENDAR_TYPE:
      return {
        ...state,
        calendarType: action.calendarType,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case ACTION_TYPE_SETTING.ADD_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        holidayAddSuccess: action.bool,
        openCalendarSettingSnackBar: true,
        openSettingErrorSnackBar: false,
        error: "",
      };
    case ACTION_TYPE_SETTING.MAKE_SUCCESS_TO_FALSE:
      return {
        ...state,
        holidayAddSuccess: false,
      };
    case ACTION_TYPE_SETTING.ADD_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.UPDATE_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        openSettingUpdate: true,
        success: action.response,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_SETTING.UPDATE_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.DELETE_HOLIDAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        openSettingDelete: true,
      };
    case ACTION_TYPE_SETTING.DELETE_HOLIDAYS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.SET_HOLIDAY_TO_EDIT:
      return {
        ...state,
        startDate: action.holidayList.startDate,
        endDate: action.holidayList.endDate,
        eventName: action.holidayList.eventName,
        calendarType: action.holidayList.calendarType,
        error: action.error,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_FEE_PERCENTAGE:
      return {
        ...state,
        feePercentage: action.fee,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_MIN_MONTHLY_FEE:
      return {
        ...state,
        minMonthlyFee: action.minMonthlyFee,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_MAX_MONTHLY_FEE:
      return {
        ...state,
        maxMonthlyFee: action.maxMonthlyFee,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_ANNUAL_FEE:
      return {
        ...state,
        annualFee: action.annualFee,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_SPEND_DOWN:
      return {
        ...state,
        spendDownThreshold: action.spendDownThreshold,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_ACH_DELAY_THRESHOLD:
      return {
        ...state,
        achDelayThreshold: action.achDelayThreshold,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_ACH_UPLOAD_TIME:
      return {
        ...state,
        achUploadTime: action.achUploadTime,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_TIME_FORMAT:
      return {
        ...state,
        timeFormat: action.timeFormat,
      };
    case ACTION_TYPE_SETTING.ADD_FEE_PERCENTAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        openPaymentSettingSuccessSnackBar: true,
      };
    case ACTION_TYPE_SETTING.ADD_FEE_PERCENTAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.FETCH_FEE_PERCENTAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        feePercentage: action.response.paymentSetting.feePercentage,
        minMonthlyFee: action.response.paymentSetting.minMonthlyFee,
        maxMonthlyFee: action.response.paymentSetting.maxMonthlyFee,
        annualFee: action.response.paymentSetting.annualFee,
        achDelayThreshold: action.response.paymentSetting.achDelayThreshold,
        achUploadTime: action.response.paymentSetting.achUploadTime
          ? action.response.paymentSetting.achUploadTime
          : "",
        timeFormat: action.response.paymentSetting.timeFormat
          ? action.response.paymentSetting.timeFormat
          : "AM",
      };
    case ACTION_TYPE_SETTING.FETCH_FEE_PERCENTAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_SETTING.HANDLE_OPEN_ADD_HOLIDAY_MODAL:
      return {
        ...state,
        open: action.bool,
      };
    case ACTION_TYPE_SETTING.HANDLE_CLOSE_ADD_HOLIDAY_MODAL:
      return {
        ...state,
        open: action.bool,
      };

    //  IMPORT HOLIDAY CALENDAR
    case ACTION_TYPE_SETTING.IMPORT_HOLIDAY_CALENDAR_SUCCESS:
      return {
        ...state,
        loading: false,
        holidayDetails: action.response.calendarSettings,
        openSettingSuccessSnackBar: true,
        success: true,
        successMsg: "Successfully Imported",
      };
    case ACTION_TYPE_SETTING.IMPORT_HOLIDAY_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_ICS_FILE:
      return {
        ...state,
        icsFile: action.icsFile,
      };
    case ACTION_TYPE_SETTING.SET_IMPORT_HOLIDAY_CALENDAR_SUCCESS:
      return {
        ...state,
        success: action.bool,
      };
    //ASSIGN EVENTS TO CLIENTS OR AGENT
    case ACTION_TYPE_SETTING.GET_ASSIGNED_CLIENTS_OR_AGENTS_SUCCESS:
      const eventObject = {
        eventId: action.response.calendarSetting.eventId,
        assignEvent:
          action.response.calendarSetting.user &&
          action.response.calendarSetting.user.map((user) => user.accountId),
      };
      return {
        ...state,
        loading: false,
        assignedClientOrAgent: action.response.calendarSetting,
        eventId: eventObject.eventName,
        assignEvent: eventObject.assignEvent,
        clientOrAgentAccount: action.response.calendarSetting.user,
      };
    case ACTION_TYPE_SETTING.GET_ASSIGNED_CLIENTS_OR_AGENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_SETTING.CLEAR_MODAL_HOLIDAY_DATA:
      return {
        ...state,
        eventName: "",
        endDate: null,
        startDate: null,
        calendarType: "",
      };

    case ACTION_TYPE_SETTING.SEARCH_CLIENTS_OR_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientOrAgentAccount: action.response.users,
      };
    case ACTION_TYPE_SETTING.SEARCH_CLIENTS_OR_AGENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ACTION_TYPE_SETTING.ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Assigned",
      };
    case ACTION_TYPE_SETTING.ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_ASSIGNED_EVENTS:
      return {
        ...state,
        loading: false,
        assignEvent: action.assignedEvents,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_EVENT_ID:
      return {
        ...state,
        loading: false,
        eventId: action.eventId,
      };
    //ELIGIBILITY SETTING
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_ELIGIBILITY_INPUT:
      const newForm = { ...state.eligibilityData };
      newForm[action.id] = action.value;
      return {
        ...state,
        eligibilityData: newForm,
        loading: false,
      };
    case ACTION_TYPE_SETTING.CLEAR_ELIGIBILITY_DATA:
      return {
        ...state,
        eligibilityData: {
          maxLimit: "",
          familyType: "",
          memberNo: "",
        },
        loading: false,
      };
    case ACTION_TYPE_SETTING.SET_ELIGIBILITY_DATA_TO_EDIT:
      return {
        ...state,
        loading: false,
        eligibilityData: action.eligibilityData,
      };
    case ACTION_TYPE_SETTING.FETCH_ELIGIBILITY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        eligibilityList: action.response.eligibilitySettings,
      };
    case ACTION_TYPE_SETTING.FETCH_ELIGIBILITY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_SETTING.ADD_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Added",
      };
    case ACTION_TYPE_SETTING.ADD_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.UPDATE_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_SETTING.UPDATE_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    //DESCRIPTION SETTING
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_DESCRIPTION:
      const newDescriptionData = { ...state.descriptionData };
      newDescriptionData[action.id] = action.value;
      return {
        ...state,
        descriptionData: newDescriptionData,
        loading: false,
      };
    case ACTION_TYPE_SETTING.CLEAR_DESCRIPTION_DATA:
      return {
        ...state,
        descriptionData: {
          descriptionId: "",
          description: "",
        },
        loading: false,
      };
    case ACTION_TYPE_SETTING.SET_DESCRIPTION_DATA_TO_EDIT:
      return {
        ...state,
        loading: false,
        descriptionData: action.descriptionData,
      };
    case ACTION_TYPE_SETTING.FETCH_DESCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        descriptionList: action.response.transactionDescriptions,
      };
    case ACTION_TYPE_SETTING.FETCH_DESCRIPTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_REQUEST:
       return {
         ...state,
         loading: true,
       };
       case ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_SUCCESS:
        return {
          ...state,
          loading: false,
          isDefault: true,
        };   
        case ACTION_TYPE_SETTING.DEFAULT_DESCRIPTION_FAILURE:
          return {
            ...state,
            loading: false,
          };
    case ACTION_TYPE_SETTING.ADD_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Added",
      };
    case ACTION_TYPE_SETTING.ADD_DESCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.UPDATE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_SETTING.UPDATE_DESCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.DELETE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Deleted",
      };
    case ACTION_TYPE_SETTING.DELETE_DESCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    // VENDOR SETTING
    case ACTION_TYPE_SETTING.FETCH_VENDOR_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorTypeList: action.vendorTypeList.vendorTypes,
      };
    case ACTION_TYPE_SETTING.FETCH_VENDOR_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_SETTING.CHECK_VENDOR_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        defaultExist: !!action.response.success,
        // descriptionList: action.response
      };
    case ACTION_TYPE_SETTING.CHECK_VENDOR_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_SETTING.SET_VENDOR_TYPE_TO_EDIT:
      return {
        ...state,
        vendorTypeData: action.vendorTypeData,
      };
    case ACTION_TYPE_SETTING.ADD_VENDOR_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Added",
      };
    case ACTION_TYPE_SETTING.ADD_VENDOR_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.UPDATE_VENDOR_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.bool,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_SETTING.UPDATE_VENDOR_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_VENDOR_TYPE_INPUT:
      const newVendorTypeData = { ...state.vendorTypeData };
      newVendorTypeData[action.id] = action.value;
      return {
        ...state,
        vendorTypeData: newVendorTypeData,
        loading: false,
      };
    case ACTION_TYPE_SETTING.CLEAR_VENDOR_TYPE_FORM:
      return {
        ...state,
        vendorTypeData: {
          vendorTypeName: "",
        },
        loading: false,
      };
    // CHECKING ACCOUNT SETTING
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_CHECKING_ACCOUNT_DATA:
      const newCheckingAccountData = { ...state.checkingAccountData };
      newCheckingAccountData[action.id] = action.value;
      return {
        ...state,
        checkingAccountData: newCheckingAccountData,
        loading: false,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_CHECKING_ACCOUNT_ADDRESS_DATA:
      const newCheckingAccountAddress = { ...state.checkingAccountAddress };
      newCheckingAccountAddress[action.id] = action.value;
      return {
        ...state,
        checkingAccountAddress: newCheckingAccountAddress,
        loading: false,
      };
    case ACTION_TYPE_SETTING.CLEAR_CHECKING_ACCOUNT_FORM:
      return {
        ...state,
        checkingAccountData: {
          checkingAccountId: "",
          immediateDestination: "",
          disbursementId: "",
          depositId: "",
          accountNumber: "",
          destinationName: "",
          originName: "",
        },
        loading: false,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_TO_OPEN_CHECKING_ACCOUNT_FORM_MODAL:
      return {
        ...state,
        openCheckingAccountFormModal: action.bool,
      };
    case ACTION_TYPE_SETTING.SET_CHECKING_ACCOUNT_DATA_TO_EDIT:
      return {
        ...state,
        checkingAccountData: action.checkingAccountData,
        laoding: false,
      };
    case ACTION_TYPE_SETTING.FETCH_CHECKING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkingAccountData: action.checkingAccountList.checkingAccount,
        checkingAccountAddress: action.checkingAccountList && action.checkingAccountList.checkingAccount
            && action.checkingAccountList.checkingAccount.address,
        openCheckingAccountFormModal: !!action.checkingAccountList.checkingAccount,
      };
    case ACTION_TYPE_SETTING.FETCH_CHECKING_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ACTION_TYPE_SETTING.ADD_CHECKING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Added",
      };
    case ACTION_TYPE_SETTING.ADD_CHECKING_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.UPDATE_CHECKING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
        openSettingSuccessSnackBar: true,
        successMsg: "Successfully Updated",
      };
    case ACTION_TYPE_SETTING.UPDATE_CHECKING_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        openSettingErrorSnackBar: true,
      };
    case ACTION_TYPE_SETTING.HANDLE_CHANGE_IS_UPDATE:
      return {
        ...state,
        checkingAccountIsUpdate: action.isUpdate,
      };
    default:
      return state;
  }
};
export default settingState;
