import React, {Component} from "react";
import {Select} from "antd";
import {Button} from "@material-ui/core";
import {handleChangeFamilyType} from "../actions/ClientAction";
const {Option} = Select;

class EligibilityCalculationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showWorkflowField: false,
            showAction : false,
            caseId: "",
            workflowData: [],
            workflowId:"",
            actionId: "",
            disable: false,
        }
    }

    render() {
        const caseDetails = this.props.caseDetails;
        const isInsideClientDetail = this.props.isInsideClientDetail;
        const calculateEligibility = this.props.calculateEligibility;
        const handleChangeEligibilityData = this.props.handleChangeEligibilityData;
        const eligibilityData = this.props.eligibilityData;
        const value = eligibilityData.caseId !== "" &&
                      eligibilityData.familyType !== "" &&
                      eligibilityData.memberNo !== "";
        const requestData = {
            clientId: this.props.clientId,
            caseId: eligibilityData.caseId,
            eligibilitySetting:{
                familyType: eligibilityData.familyType,
                memberNo: eligibilityData.memberNo,
            }
        }

        return (
            <React.Fragment>
                {
                    caseDetails && caseDetails.length > 0 ?
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <label className="label">Family Type <span style={{color: "red"}}>*</span></label>
                                    <Select className="h40"
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                if(e === "SINGLE_FAMILY"){
                                                    handleChangeEligibilityData("memberNo", 1);
                                                    this.setState({
                                                        disable: true
                                                    })
                                                }else if(e === "MARRIED_FAMILY"){
                                                    handleChangeEligibilityData("memberNo", 2);
                                                    this.setState({
                                                        disable: true
                                                    })
                                                }else{
                                                    handleChangeEligibilityData("memberNo", "");
                                                    this.setState({
                                                        disable: false
                                                    })
                                                }
                                                handleChangeEligibilityData("familyType", e);
                                                // this.props.handleChangeFamilyType(e);
                                            }}
                                    >
                                        <Option value="SINGLE_FAMILY">Single</Option>
                                        <Option value="MARRIED_FAMILY">Married</Option>
                                        <Option value="FAMILY">Family</Option>
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <label className="label">Members <span style={{color: "red"}}>*</span></label>
                                    <Select className="h40"
                                            value={eligibilityData.memberNo}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={(e) => {
                                                handleChangeEligibilityData("memberNo", e);
                                                // this.props.handleChangeMembers(e);
                                            }}
                                            disabled={this.state.disable}
                                    >
                                        <Option value="2">2</Option>
                                        <Option value="3">3</Option>
                                        <Option value="4">4</Option>
                                        <Option value="5">5</Option>
                                    </Select>
                                </div>
                            </div>
                            <>
                                <div className="row">
                                    <div className={!isInsideClientDetail? "col-12  select-input h40" : "col-6  select-input h40"}>
                                        <label className="label">Cases <span style={{color: "red"}}>*</span></label>
                                        <Select
                                            style={{width: 200}}
                                            onChange={(e) => {
                                                this.setState({
                                                    showWorkflowField: true,
                                                    caseId: e,
                                                    workFlowId: "",
                                                })
                                                handleChangeEligibilityData("caseId",e);
                                                this.props.clearWorkflow();
                                            }}
                                            getPopupContainer={node => node.parentNode}
                                            defaultOpen={false}
                                        >
                                            {caseDetails && caseDetails.map(res =>
                                                <>
                                                    <Option key={res.caseId} value={res.caseId}>
                                                        {res.caseId}
                                                    </Option>
                                                </>
                                            )}
                                        </Select>
                                    </div>
                                </div>
                            </>
                            {
                                isInsideClientDetail?
                                    <div className="row" style={{marginTop: "12px"}}>
                                        <div className="col-6"/>
                                        <div className="col-6">
                                            <Button
                                                disabled={!value}
                                                style={{float: "right"}}
                                                className={value ? "addButton" : "disabledAddButton"}
                                                onClick={() => {
                                                    calculateEligibility(requestData)
                                                }}
                                            >
                                                Calculate
                                            </Button>
                                        </div>

                                    </div>:
                                    null
                            }
                        </> :
                        <div className="onNote">
                             No cases for this client.
                        </div>
                }
            </React.Fragment>
        )
    }
};

export default EligibilityCalculationComponent;