import APIEndPoints from "../constants/APIConstants";
import {API} from "./index";
class DashboardAPI extends API {
    fetchDataForDashboard = callback => {
        const constURL = APIEndPoints.GET_STATUS_FOR_DASHBOARD;
        fetch(constURL, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback)
        }).catch(() => {
            // console.log(error)
            callback(null, "Service error, please try again.")
        });
    };
    fetchTransactionDataForDashboard = callback => {
        const constURL = APIEndPoints.GET_TRANSACTION_STATUS;
        fetch(constURL, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback)
        }).catch(() => {
            callback(null, "Service error, please try again.")
        });
    };
    fetchOtherStatusForDashboard = callback => {
        const constURL = APIEndPoints.GET_OTHER_STATUS_FOR_DASHBOARD;
        fetch(constURL, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback)
        }).catch(() => {
            callback(null, "Service error, please try again.")
        });
    };
    searchGlobalData = (term, callback) => {
        fetch(APIEndPoints.GLOBAL_SEARCH + term, {
            method: 'GET',
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        })
    };
}

export default new DashboardAPI();