import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class DocumentsAPI extends API {
    fetchDocumentsList = callback => {
        fetch(APIEndPoints.GET_DOCUMENTS, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log(error);
            callback(null, "Service error, please try again.");
        });
    };
    searchDocuments= (term,from, to, callback) => {
        const urlString = APIEndPoints.SEARCH_DOCUMENT + term + "&from=" +from + "&to=" + to;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };
}
export default new DocumentsAPI();