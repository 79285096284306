//login
export const ACTION_TYPE_LOGIN = {
  HANDLE_LOGIN_CHANGE: "HANDLE_LOGIN_CHANGE",
  LOGIN_REQUEST_MADE: "LOGIN_REQUEST_MADE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  CLOSE_SNACK_BAR_LOGIN_PAGE: "CLOSE_SNACK_BAR_LOGIN_PAGE",
};
//user
export const ACTION_TYPE_USER = {
  USER_REQUEST_MADE: "USER_REQUEST_MADE",
  USER_FETCH_SUCCESS: "USER_FETCH_SUCCESS",
  USER_FETCH_FAILURE: "USER_FETCH_FAILURE",
  CLOSE_SNACK_BAR_USER_PAGE: "CLOSE_SNACK_BAR_USER_PAGE",
  HANDLE_CHANGE_USER: "HANDLE_CHANGE_USER",
  HANDLE_CHANGE_IN_CHECKBOX_FOR_USER: "HANDLE_CHANGE_IN_CHECKBOX_FOR_USER",
  HANDLE_CHANGE_FOR_USER_IMAGE: "HANDLE_CHANGE_FOR_USER_IMAGE",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  SET_FILTERED_USER_ROLE: "SET_FILTERED_USER_ROLE",
  UPDATE_USER_ROLE_SUCCESS: "UPDATE_USER_ROLE_SUCCESS",
  UPDATE_USER_ROLE_FAILURE: "UPDATE_USER_ROLE_FAILURE",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  HANDLE_CHANGE_FOR_RESET_PASSWORD: "HANDLE_CHANGE_FOR_RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  SET_USER_DATA_TO_EMPTY: "SET_USER_DATA_TO_EMPTY",
  SET_DATA_TO_DISPLAY_EMPTY: "SET_DATA_TO_DISPLAY_EMPTY",
};
//case
export const ACTION_TYPE_CASE = {
  CASE_FETCH_SUCCESS: "CASE_FETCH_SUCCESS",
  CASE_FETCH_FAILURE: "CASE_FETCH_FAILURE",

  // pagination
  CASE_PAGINATED_FETCH_SUCCESS: "CASE_PAGINATED_FETCH_SUCCESS",
  CASE_PAGINATED_FETCH_FAILURE: "CASE_PAGINATED_FETCH_FAILURE",
  CLEAR_NEXT_URL: "CLEAR_NEXT_URL",

  CLOSE_SNACK_BAR_CASE_PAGE: "CLOSE_SNACK_BAR_CASE_PAGE",
  CASE_REQUEST_MADE: "CASE_REQUEST_MADE",
  DELETE_CASE_SUCCESS: "DELETE_CASE_SUCCESS",
  DELETE_CASE_FAILURE: "DELETE_CASE_FAILURE",
  HANDLE_CHANGE_CASE: "HANDLE_CHANGE_CASE",
  HANDLE_CHANGE_DATE: "HANDLE_CHANGE_DATE",
  SET_CASE_TO_EDIT: "SET_CASE_TO_EDIT",
  FETCH_CLIENT_INFO: "FETCH_CLIENT_INFO",
  UPDATE_CASE_SUCCESS: "UPDATE_CASE_SUCCESS",
  UPDATE_CASE_FAILURE: "UPDATE_CASE_FAILURE",
  HANDLE_CHANGE_WORKFLOW_AGENT: "HANDLE_CHANGE_WORKFLOW_AGENT",
  HANDLE_CHANGE_CASE_AGENT: "HANDLE_CHANGE_CASE_AGENT",

  SCHEDULE_DATE_BY_AGENT_SUCCESS: "SCHEDULE_DATE_BY_AGENT_SUCCESS",
  SCHEDULE_DATE_BY_AGENT_FAILURE: "SCHEDULE_DATE_BY_AGENT_FAILURE",

  SEARCH_CASE_SUCCESS: "SEARCH_CASE_SUCCESS",
  SEARCH_CASE_FAILURE: "SEARCH_CASE_FAILURE",

  DETAIL_FOR_CALENDAR_REQUEST_MADE: "DETAIL_FOR_CALENDAR_REQUEST_MADE",

  FETCH_DATA_FOR_CALENDAR_SUCCESS: "FETCH_LIST_FOR_CALENDAR_SUCCESS",
  FETCH_DATA_FOR_CALENDAR_FAILURE: "FETCH_LIST_FOR_CALENDAR_FAILURE",

  FETCH_CALENDAR_BY_CLIENT_ID_SUCCESS: "FETCH_CALENDAR_BY_CLIENT_ID_SUCCESS",
  FETCH_CALENDAR_BY_CLIENT_ID_FAILURE: "FETCH_CALENDAR_BY_CLIENT_ID_FAILURE",

  FETCH_CALENDAR_BY_AGENT_ID_SUCCESS: "FETCH_CALENDAR_BY_AGENT_ID_SUCCESS",
  FETCH_CALENDAR_BY_AGENT_ID_FAILURE: "FETCH_CALENDAR_BY_AGENT_ID_FAILURE",

  SEARCH_CALENDAR_DATA_SUCCESS: "SEARCH_CALENDAR_DATA_SUCCESS",
  SEARCH_CALENDAR_DATA_FAILURE: "SEARCH_CALENDAR_DATA_FAILURE",
  SEARCH_CALENDAR_DATA_BY_CLIENT_ID_SUCCESS:
    "SEARCH_CALENDAR_DATA_BY_CLIENT_ID_SUCCESS",
  SEARCH_CALENDAR_DATA_BY_CLIENT_ID_FAILURE:
    "SEARCH_CALENDAR_DATA_BY_CLIENT_ID_FAILURE",
  SEARCH_CALENDAR_DATA_BY_AGENT_ID_SUCCESS:
    "SEARCH_CALENDAR_DATA_BY_AGENT_ID_SUCCESS",
  SEARCH_CALENDAR_DATA_BY_AGENT_ID_FAILURE:
    "SEARCH_CALENDAR_DATA_BY_AGENT_ID_FAILURE",

  HANDLE_CHANGE_DATE_ONLY: "HANDLE_CHANGE_DATE_ONLY",
  HANDLE_CLICK: "HANDLE_CLICK",
  GET_WORKFLOW: "GET_WORKFLOW",

  FETCH_FILE_DETAILS_FOR_CASE_SUCCESS: "FETCH_FILE_DETAILS_FOR_CASE",
  FETCH_FILE_DETAILS_FOR_CASE_FAILURE: "FETCH_FILE_DETAILS_FOR_CASE_FAILURE",

  FETCH_CASE_CONTACT_SUCCESS: "FETCH_CASE_CONTACT_SUCCESS",
  FETCH_CASE_CONTACT_FAILURE: "FETCH_CASE_CONTACT_FAILURE",

  HANDLE_CHANGE_CASE_CONTACT_INPUT: "HANDLE_CHANGE_CASE_CONTACT_INPUT",
  HANDLE_CHANGE_CASE_COMPANY_INPUT: "HANDLE_CHANGE_CASE_COMPANY_INPUT",
  HANDLE_CHANGE_EXISTING_CONTACT: "HANDLE_CHANGE_EXISTING_CONTACT",
  HANDLE_CHANGE_RELATIONSHIP: "HANDLE_CHANGE_RELATIONSHIP",

  ADD_CASE_INDIVIDUAL_CONTACT_SUCCESS: "ADD_CASE_INDIVIDUAL_CONTACT_SUCCESS",
  ADD_CASE_INDIVIDUAL_CONTACT_FAILURE: "ADD_CASE_INDIVIDUAL_CONTACT_FAILURE",

  ADD_CASE_COMPANY_CONTACT_SUCCESS: "ADD_CASE_COMPANY_CONTACT_SUCCESS",
  ADD_CASE_COMPANY_CONTACT_FAILURE: "ADD_CASE_COMPANY_CONTACT_FAILURE",

  ADD_CASE_EXISTING_CONTACT_SUCCESS: "ADD_CASE_EXISTING_CONTACT_SUCCESS",
  ADD_CASE_EXISTING_CONTACT_FAILURE: "ADD_CASE_EXISTING_CONTACT_FAILURE",

  SEARCH_EXISTING_CONTACT_SUCCESS: "SEARCH_EXISTING_CONTACT_SUCCESS",
  SEARCH_EXISTING_CONTACT_FAILURE: "SEARCH_EXISTING_CONTACT_FAILURE",

  SET_INDIVIDUAL_CASE_CONTACT_TO_EMPTY: "SET_INDIVIDUAL_CASE_CONTACT_TO_EMPTY",
  SET_CASE_COMPANY_CONTACT_TO_EMPTY: "SET_CASE_COMPANY_CONTACT_TO_EMPTY",

  //FILTER AND EXPORT
  HANDLE_CHANGE_CASE_START_DATE: "HANDLE_CHANGE_CASE_START_DATE",
  HANDLE_CHANGE_CASE_END_DATE: "HANDLE_CHANGE_CASE_END_DATE",
  HANDLE_CHANGE_CASE_STATUS: "HANDLE_CHANGE_CASE_STATUS",
  CLEAR_FILTER_DATA: "CLEAR_FILTER_DATA",

  EXPORT_CASE_LIST_SUCCESS: "EXPORT_CASE_LIST_SUCCESS",
  EXPORT_CASE_LIST_FAILURE: "EXPORT_CASE_LIST_FAILURE",

  SEARCH_CLIENT_FAILURE: "SEARCH_CLIENT_FAILURE",
  SEARCH_CLIENT_SUCCESS: "SEARCH_CLIENT_SUCCESS",
};
//client
export const ACTION_TYPE_CLIENT = {
  HANDLE_CHANGE_DESC_DATA: "HANDLE_CHANGE_DESC_DATA",
  UPDATE_DESCRIPTION_SUCCESS: "UPDATE_DESCRIPTION_SUCCESS",
  CLEAR_CLIENT_LIST: "CLEAR_CLIENT_LIST",
  UPDATE_DESCRIPTION_FAILURE: "UPDATE_DESCRIPTION_FAILURE",
  HANDLE_CHANGE_VENDOR_CLIENT_DATA_VENDOR_DATA:
    "HANDLE_CHANGE_VENDOR_CLIENT_DATA_VENDOR_DATA",
  CLIENT_FETCH_SUCCESS: "CLIENT_FETCH_SUCCESS",
  CLIENT_WITH_BALANCE_FETCH_SUCCESS: "CLIENT_WITH_BALANCE_FETCH_SUCCESS",
  CLIENT_FETCH_FAILURE: "CLIENT_FETCH_FAILURE",
  CLOSE_SNACK_BAR_CLIENT_PAGE: "CLOSE_SNACK_BAR_CLIENT_PAGE",
  CLIENT_REQUEST_MADE: "CLIENT_REQUEST_MADE",
  DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE: "DELETE_CLIENT_FAILURE",
  HANDLE_CHANGE_CLIENT: "HANDLE_CHANGE_CLIENT",
  SET_CLIENT_TO_EDIT: "SET_CLIENT_TO_EDIT",
  CLIENT_ADD_SUCCESS_IN_CLIENT_UPDATE: "CLIENT_ADD_SUCCESS_IN_CLIENT_UPDATE",
  CLIENT_ADD_FAILURE_IN_CLIENT_UPDATE: "CLIENT_ADD_FAILURE_IN_CLIENT_UPDATE",
  SET_OPEN_CLIENT_FORM_MODAL: "SET_OPEN_CLIENT_FORM_MODAL",
  SEARCH_CLIENT_SUCCESS: "SEARCH_CLIENT_SUCCESS",
  SEARCH_CLIENT_FAILURE: "SEARCH_CLIENT_FAILURE",
  SEARCH_CLIENT_WITH_BALANCE_FAILURE: "SEARCH_CLIENT_WITH_BALANCE_FAILURE",
  SEARCH_CLIENT_WITH_BALANCE_SUCCESS: "SEARCH_CLIENT_WITH_BALANCE_SUCCESS",
  HANDLE_CHANGE_EMAIL: "HANDLE_CHANGE_EMAIL",
  FETCH_SINGLE_CLIENT_SUCCESS: "FETCH_SINGLE_CLIENT_SUCCESS",
  FETCH_SINGLE_CLIENT_FAILURE: "FETCH_SINGLE_CLIENT_FAILURE",
  CLEAR_SINGLE_CLIENT_DATA: "CLEAR_SINGLE_CLIENT_DATA",
  HANDLE_CHANGE_OPEN_CLIENT_FORM_MAODAL:
    "HANDLE_CHANGE_OPEN_CLIENT_FORM_MAODAL",

  ADD_CLIENT_SUCCESS: "ADD_CLIENT_SUCCESS",
  ADD_CLIENT_FAILURE: "ADD_CLIENT_FAILURE",

  DECLINE_CLIENT_TRANS_CHECK_SUCCESS: "DECLINE_CLIENT_TRANS_CHECK_SUCCESS",
  DECLINE_CLIENT_TRANS_CHECK_FAILURE: "DECLINE_CLIENT_TRANS_CHECK_FAILURE",

  CLEAR_CLIENT_DATA_INPUT: "CLEAR_CLIENT_DATA_INPUT",
  SET_ADD_CLIENT_SUCCESS_TO_TRUE_OR_FALSE:
    "SET_ADD_CLIENT_SUCCESS_TO_TRUE_OR_FALSE",

  GET_CONFIG_SUCCESS: "GET_CONFIG_SUCCESS",
  GET_CONFIG_FAILURE: "GET_CONFIG_FAILURE",

  SAVE_CONFIG_SUCCESS: "SAVE_CONFIG_SUCCESS",
  SAVE_CONFIG_FAILURE: "SAVE_CONFIG_FAILURE",
  HANDLE_CHANGE_CLIENT_NAME: "HANDLE_CHANGE_CLIENT_NAME",
  CLEAR_FILTER_DATA: "CLEAR_FILTER_DATA",
  HANDLE_EDIT_BLOCKED_TRANS_DATA: "HANDLE_EDIT_BLOCKED_TRANS_DATA",
  UPLOAD_BLOCKED_TRANSACTION_SUCCESS: "UPLOAD_BLOCKED_TRANSACTION_SUCCESS",
  UPLOAD_BLOCKED_TRANSACTION_FAILURE: "UPLOAD_BLOCKED_TRANSACTION_FAILURE",
  CLEAR_BLOCKED_TRANSACTION: "CLEAR_BLOCKED_TRANSACTION",

  UPLOAD_PROFILE_PICTURE_SUCCESS: "UPLOAD_PROFILE_PICTURE_SUCCESS",
  UPLOAD_PROFILE_PICTURE_FAILURE: "UPLOAD_PROFILE_PICTURE_FAILURE",

  UPDATE_CLIENT_PROFILE_PICTURE_SUCCESS:
    "UPDATE_CLIENT_PROFILE_PICTURE_SUCCESS",
  UPDATE_CLIENT_PROFILE_PICTURE_FAILURE:
    "UPDATE_CLIENT_PROFILE_PICTURE_FAILURE",

  FETCH_CASE_BY_CLIENT_ID_SUCCESS: "FETCH_CASE_BY_CLIENT_ID_SUCCESS",
  FETCH_CASE_BY_CLIENT_ID_FAILURE: "FETCH_CASE_BY_CLIENT_ID_FAILURE",

  FETCH_FILE_DETAILS_BY_CLIENT_SUCCESS: "FETCH_FILE_DETAILS_BY_CLIENT_SUCCESS",
  FETCH_FILE_DETAILS_BY_CLIENT_FAILURE: "FETCH_FILE_DETAILS_BY_CLIENT_SUCCESS",

  DELETE_CASE_BY_CLIENT_SUCCESS: "DELETE_CASE_BY_CLIENT_SUCCESS",
  DELETE_CASE_BY_CLIENT_FAILURE: "DELETE_CASE_BY_CLIENT_FAILURE",

  // CLIENT'S NOTIFICATION SETTING
  HANDLE_CHANGE_TO_OPEN_SET_NOTIFICATION_MODAL:
    "HANDLE_CHANGE_TO_OPEN_SET_NOTIFICATION_MODAL",
  HANDLE_CHANGE_ENABLE_NOTIFICATIONS: "HANDLE_CHANGE_ENABLE_NOTIFICATIONS",
  HANDLE_CHANGE_UPCOMING_ALERT: "HANDLE_CHANGE_UPCOMING_ALERT",
  HANDLE_CHANGE_HOLIDAY_ALERT: "HANDLE_CHANGE_HOLIDAY_ALERT",
  SAVE_NOTIFICATIONS_SETTING_SUCCESS: "SAVE_NOTIFICATIONS_SETTING_SUCCESS",
  SAVE_NOTIFICATIONS_SETTING_FAILURE: "SAVE_NOTIFICATIONS_SETTING_FAILURE",
  GET_ALERT_BY_ACCOUNT_ID_SUCCESS: "GET_ALERT_BY_ACCOUNT_ID_SUCCESS",
  GET_ALERT_BY_ACCOUNT_ID_FAILURE: "GET_ALERT_BY_ACCOUNT_ID_FAILURE",

  // client filter
  HANDLE_CHANGE_FILTER_NAME: "HANDLE_CHANGE_FILTER_NAME",
  HANDLE_CHANGE_FILTER_CLIENTNO: "HANDLE_CHANGE_FILTER_CLIENTNO",
  HANDLE_CHANGE_FILTER_CASECOUNT: "HANDLE_CHANGE_FILTER_CASECOUNT",
  HANDLE_CHANGE_FILTER_DATE: "HANDLE_CHANGE_FILTER_DATE",
  HANDLE_CHANGE_USER_STATUS: "HANDLE_CHANGE_USER_STATUS",
  HANDLE_CHANGE_FETCH_ALL_STATUS: "HANDLE_CHANGE_FETCH_ALL_STATUS",
  HANDLE_CHANGE_FILTER_BALANCE: "HANDLE_CHANGE_FILTER_BALANCE",
  HANDLE_CHANGE_FILTER_TERM: "HANDLE_CHANGE_FILTER_TERM",
  // CLIENT'S BANK DETAILS
  FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_SUCCESS:
    "FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_SUCCESS",
  FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_FAILURE:
    "FETCH_CLIENT_BANK_DETAILS_BY_BANK_ID_FAILURE",

  HANDLE_CHANGE_ACCOUNT_NAME: "HANDLE_CHANGE_ACCOUNT_NAME",
  HANDLE_CHANGE_ACCOUNT_NUMBER: "HANDLE_CHANGE_ACCOUNT_NUMBER",
  HANDLE_CHANGE_ROUTING_NUMBER: "HANDLE_CHANGE_ROUTING_NUMBER",
  HANDLE_CHANGE_BANK_NAME: "HANDLE_CHANGE_BANK_NAME",
  HANDLE_CHANGE_BANK_ACCOUNT_TYPE: "HANDLE_CHANGE_BANK_ACCOUNT_TYPE",
  CLEAR_BANK_DETAIL: "CLEAR_BANK_DETAIL",

  SET_BANK_DETAIL_TO_EDIT: "SET_BANK_DETAIL_TO_EDIT",

  ADD_CLIENT_BANK_DETAILS_SUCCESS: "ADD_CLIENT_BANK_DETAILS_SUCCESS",
  ADD_CLIENT_BANK_DETAILS_FAILURE: "ADD_CLIENT_BANK_DETAILS_FAILURE",

  UPDATE_CLIENT_BANK_DETAILS_SUCCESS: "UPDATE_CLIENT_BANK_DETAILS_SUCCESS",
  UPDATE_CLIENT_BANK_DETAILS_FAILURE: "UPDATE_CLIENT_BANK_DETAILS_FAILURE",

  DELETE_CLIENT_BANK_DETAILS_SUCCESS: "DELETE_CLIENT_BANK_DETAILS_SUCCESS",
  DELETE_CLIENT_BANK_DETAILS_FAILURE: "DELETE_CLIENT_BANK_DETAILS_FAILURE",

  CLIENT_PROFILE_PICTURE_UPDATE_REQUEST:
    "CLIENT_PROFILE_PICTURE_UPDATE_REQUEST",

  //PAYMENTS SETTING
  HANDLE_CHANGE_CLIENT_FEE_PERCENT: "HANDLE_CHANGE_CLIENT_FEE_PERCENT",
  HANDLE_CHANGE_CLIENT_MONTHLY_FEE: "HANDLE_CHANGE_CLIENT_MONTHLY_FEE",
  HANDLE_CHANGE_CLIENT_ANNUAL_FEE: "HANDLE_CHANGE_CLIENT_ANNUAL_FEE",
  HANDLE_CHANGE_CLIENT_SPEND_DOWN: "HANDLE_CHANGE_CLIENT_SPEND_DOWN",
  HANDLE_CHANGE_ADMISSION_DATE: "HANDLE_CHANGE_ADMISSION_DATE",
  HANDLE_CHANGE_CLIENT_ACH_DELAY_THRESHOLD:
    "HANDLE_CHANGE_CLIENT_ACH_DELAY_THRESHOLD",
  HANDLE_CHANGE_CLIENT_INSTALLMENT: "HANDLE_CHANGE_CLIENT_INSTALLMENT",
  ADD_PAYMENT_DETAILS_SUCCESS: "ADD_PAYMENT_DETAILS_SUCCESS",
  ADD_PAYMENT_DETAILS_FAILURE: "ADD_PAYMENT_DETAILS_FAILURE",
  FETCH_PAYMENT_DETAILS_SUCCESS: "FETCH_PAYMENT_DETAILS_SUCCESS",
  FETCH_PAYMENT_DETAILS_FAILURE: "FETCH_PAYMENT_DETAILS_FAILURE",
  CLEAR_PAYMENT_VALUES: "CLEAR_PAYMENT_VALUES",

  //CLIENT ON HOLD
  CLIENT_ON_HOLD_SUCCESS: "CLIENT_ON_HOLD_SUCCESS",
  CLIENT_ON_HOLD_FAILURE: "CLIENT_ON_HOLD_FAILURE",
  SET_UPLOAD_SUCCESS_TO_FALSE: "SET_UPLOAD_SUCCESS_TO_FALSE",
  HANDLE_CHANGE_ADDED_CLIENT_ID: "HANDLE_CHANGE_ADDED_CLIENT_ID",

  GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_SUCCESS:
    "GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_SUCCESS",
  GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_FAILURE:
    "GET_ASSIGNED_EVENT_TO_CLIENTS_CALENDAR_FAILURE",

  //CLIENT ON DISCHARGE
  CLIENT_ON_DISCHARGE_SUCCESS: "CLIENT_ON_DISCHARGE_SUCCESS",
  CLIENT_ON_DISCHARGE_FAILURE: "CLIENT_ON_DISCHARGE_FAILURE",

  //ELIGIBILITY CALCULATION
  ELIGIBILITY_CALCULATION_SUCCESS: "ELIGIBILITY_CALCULATION_SUCCESS",
  ELIGIBILITY_CALCULATION_FAILURE: "ELIGIBILITY_CALCULATION_FAILURE",
  HANDLE_CHANGES_ELIGIBILITY_SETTING: "HANDLE_CHANGES_ELIGIBILITY_SETTING",
  CLEAR_ELIGIBILITY_SETTING: "CLEAR_ELIGIBILITY_SETTING",
  HANDLE_CHANGE_IDS_VALUE: "HANDLE_CHANGE_IDS_VALUE",
  HANDLE_CHANGE_WORKFLOW: "HANDLE_CHANGE_WORKFLOW",
  HANDLE_CHANGE_ACTION: "HANDLE_CHANGE_ACTION",
  CLEAR_WORKFLOW: "CLEAR_WORKFLOW",
  CLEAR_ACTIONS: "CLEAR_ACTIONS",

  // CLIENT DETAIL PAGE
  HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_CLIENT:
    "HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_CLIENT",
  ADD_NOTES_SUCCESS_FOR_CLIENT: "ADD_NOTES_SUCCESS_FOR_CLIENT",
  ADD_NOTES_FAILURE_FOR_CLIENT: "ADD_NOTES_FAILURE_FOR_CLIENT",
  HANDLE_CHANGE_NOTES_FOR_CLIENT: "HANDLE_CHANGE_NOTES_FOR_CLIENT",
  FETCH_CLIENT_SUCCESS: "FETCH_CLIENT_SUCCESS",
  FETCH_CLIENT_FAILURE: "FETCH_CLIENT_FAILURE",
  // ADD_NOTES_SUCCESS_FOR_CLIENT: "ADD_NOTES_SUCCESS_FOR_CLIENT",
  FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS:
    "FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS",
};
//workflow
export const ACTION_TYPE_WORKFLOW = {
  WORKFLOW_FETCH_SUCCESS: "WORKFLOW_FETCH_SUCCESS",
  WORKFLOW_FETCH_FAILURE: "WORKFLOW_FETCH_FAILURE",
  CLOSE_SNACK_BAR_WORKFLOW_PAGE: "CLOSE_SNACK_BAR_WORKFLOW_PAGE",
  WORKFLOW_REQUEST_MADE: "WORKFLOW_REQUEST_MADE",
  DELETE_WORKFLOW_SUCCESS: "DELETE_WORKFLOW_SUCCESS",
  DELETE_WORKFLOW_FAILURE: "DELETE_WORKFLOW_FAILURE",
};
export const ACTION_TYPE_WORKFLOW_FORM = {
  WORKFLOW_FORM_REQUEST_MADE: "WORKFLOW_FORM_REQUEST_MADE",
  ACTION_FETCH_SUCCESS: "ACTION_FETCH_SUCCESS",
  ACTION_FETCH_FAILURE: "ACTION_FETCH_FAILURE",
  CLOSE_SNACK_BAR_WORKFLOW_FORM_PAGE: "CLOSE_SNACK_BAR_WORKFLOW_FORM_PAGE",
  WORKFLOW_ADD_SUCCESS: "WORKFLOW_ADD_SUCCESS",
  WORKFLOW_ADD_FAILURE: "WORKFLOW_ADD_FAILURE",
  HANDLE_CHANGE_WORKFLOW: "HANDLE_CHANGE_WORKFLOW",
  ADD_ACTION_SUCCESS: "ADD_ACTION_SUCCESS",
  ADD_ACTION_FAILURE: "ADD_ACTION_FAILURE",
  HANDLE_CHANGE_ACTION: "HANDLE_CHANGE_ACTION",
  DELETE_ACTION_SUCCESS: "DELETE_ACTION_SUCCESS",
  DELETE_ACTION_FAILURE: "DELETE_ACTION_FAILURE",
  SET_WORKFLOW_TO_EDIT: "SET_WORKFLOW_TO_EDIT",
};
//profile
export const ACTION_TYPE_PROFILE = {
  PROFILE_REQUEST_MADE: "PROFILE_REQUEST_MADE",
  PROFILE_FETCH_SUCCESS: "PROFILE_FETCH_SUCCESS",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  HANDLE_CHANGE: "HANDLE_CHANGE",
  HANDLE_CHANGE_FOR_CHANGE_PASSWORD: "HANDLE_CHANGE_FOR_CHANGE_PASSWORD",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
  UPDATE_PROFILE_PICTURE_FAILURE: "UPDATE_PROFILE_PICTURE_FAILURE",
  ASSIGN_PROFILE_PICTURE_SUCCESS: "ASSIGN_PROFILE_PICTURE_SUCCESS",
  ASSIGN_PROFILE_PICTURE_FAILURE: "ASSIGN_PROFILE_PICTURE_FAILURE",
  CLOSE_SNACK_BAR_PROFILE_PAGE: "CLOSE_SNACK_BAR_PROFILE_PAGE",
  FETCH_USER_DATA: "FETCH_USER_DATA",
};
//case template
export const ACTION_TYPE_CASE_TEMPLATE = {
  CASE_TEMPLATE_FETCH_SUCCESS: "CASE_TEMPLATE_FETCH_SUCCESS",
  CASE_TEMPLATE_FETCH_FAILURE: "CASE_TEMPLATE_FETCH_FAILURE",
  CLOSE_SNACK_BAR_CASE_TEMPLATE_PAGE: "CLOSE_SNACK_BAR_CASE_TEMPLATE_PAGE",
  CASE_TEMPLATE_REQUEST_MADE: "CASE_TEMPLATE_REQUEST_MADE",
  ADD_CASE_TEMPLATE_SUCCESS: "ADD_CASE_TEMPLATE_SUCCESS",
  ADD_CASE_TEMPLATE_FAILURE: "ADD_CASE_TEMPLATE_FAILURE",
  HANDLE_CHANGE_CASE_TEMPLATE: "HANDLE_CHANGE_CASE_TEMPLATE",
  DELETE_CASE_TEMPLATE_SUCCESS: "DELETE_CASE_TEMPLATE_SUCCESS",
  DELETE_CASE_TEMPLATE_FAILURE: "DELETE_CASE_TEMPLATE_FAILURE",
  SET_CASE_TEMPLATE_TO_EDIT: "SET_CASE_TEMPLATE_TO_EDIT",
};
//registerCase
export const ACTION_TYPE_REGISTER_CASE = {
  REGISTER_CASE_REQUEST_MADE: "REGISTER_CASE_REQUEST_MADE",
  CLOSE_SNACK_BAR_REGISTER_CASE_PAGE: "CLOSE_SNACK_BAR_REGISTER_CASE_PAGE",
  HANDLE_CHANGE_CASE_IN_REGISTER_CASE: "HANDLE_CHANGE_CASE_IN_REGISTER_CASE",
  REGISTER_CASE_SUCCESS: "REGISTER_CASE_SUCCESS",
  REGISTER_CASE_FAILURE: "REGISTER_CASE_FAILURE",
  HANDLE_CHANGE_CLIENT_IN_REGISTER_CASE:
    "HANDLE_CHANGE_CLIENT_IN_REGISTER_CASE",
  HANDLE_CHANGE_CLIENT: "HANDLE_CHANGE_CLIENT",
  CLIENT_ADD_SUCCESS: "CLIENT_ADD_SUCCESS",
  CLIENT_ADD_FAILURE: "CLIENT_ADD_FAILURE",
  CLIENT_FETCH_SUCCESS: "CLIENT_FETCH_SUCCESS",
  CLIENT_FETCH_FAILURE: "CLIENT_FETCH_FAILURE",
  SEARCH_CLIENT_BY_SSN_SUCCESS: "SEARCH_CLIENT_BY_SSN_SUCCESS",
  SEARCH_CLIENT_BY_SSN_FAILURE: "SEARCH_CLIENT_BY_SSN_FAILURE",
  SET_ADD_SUCESS_FALSE: "SET_ADD_SUCESS_FALSE",

  //CLIENT'S CASE CONTACT
  FETCH_CLIENT_CASE_CONTACT_SUCCESS: "FETCH_CLIENT_CASE_CONTACT_SUCCESS",
  FETCH_CLIENT_CASE_CONTACT_FAILURE: "FETCH_CLIENT_CASE_CONTACT_FAILURE",

  CLEAR_CLIENT_FORM_DATA: "CLEAR_CLIENT_FORM_DATA",
  ADD_CLIENT_FORM_DATA_SUCCESS: "ADD_CLIENT_FORM_DATA_SUCCESS",
  ADD_CLIENT_FORM_DATA_FAILURE: "ADD_CLIENT_FORM_DATA_FAILURE",
};
//reset password
export const ACTION_TYPE_RESET_PASSWORD = {
  HANDLE_CHANGE_FOR_RESET_PASSWORD_REQUEST:
    "HANDLE_CHANGE_FOR_RESET_PASSWORD_REQUEST",
  REQUEST_MADE_FOR_REQUEST_PASSWORD_RESET:
    "REQUEST_MADE_FOR_REQUEST_PASSWORD_RESET",
  FETCH_USER_INFO_SUCCESS: "FETCH_USER_INFO_SUCCESS",
  RESET_FIRST_PASSWORD_FAILURE: "RESET_FIRST_PASSWORD_FAILURE",
  RESET_FIRST_PASSWORD_SUCCESS: "RESET_FIRST_PASSWORD_SUCCESS",
  CLOSE_SNACK_BAR_RESET_PASSWORD_PAGE: "CLOSE_SNACK_BAR_RESET_PASSWORD_PAGE",
};
//action
export const ACTION_TYPE_FOR_ACTION_PAGE = {
  ACTION_REQUEST_MADE: "ACTION_REQUEST_MADE",
  ACTION_FETCH_SUCCESS: "ACTION_FETCH_SUCCESS",
  ACTION_FETCH_FAILURE: "ACTION_FETCH_FAILURE",
  CLOSE_SNACK_BAR_ACTION_PAGE: "CLOSE_SNACK_BAR_ACTION_PAGE",
  DELETE_ACTION_SUCCESS: "DELETE_ACTION_SUCCESS",
  DELETE_ACTION_FAILURE: "DELETE_ACTION_FAILURE",
  ADD_ACTION_SUCCESS: "ADD_ACTION_SUCCESS",
  ADD_ACTION_FAILURE: "ADD_ACTION_FAILURE",
  HANDLE_CHANGE_ACTION: "HANDLE_CHANGE_ACTION",
  SET_ACTION_TO_EDIT: "SET_ACTION_TO_EDIT",
  SEARCH_EXISTING_OPTION_SUCCESS: "SEARCH_EXISTING_OPTION_SUCCESS",
  SEARCH_EXISTING_OPTION_FAILURE: "SEARCH_EXISTING_OPTION_FAILURE",

  GET_CONDITIONAL_FORM_BY_REFERENCE_ID_SUCCESS:
    "GET_CONDITIONAL_FORM_BY_REFERENCE_ID_SUCCESS",
  GET_CONDITIONAL_FORM_BY_REFERENCE_ID_FAILURE:
    "GET_CONDITIONAL_FORM_BY_REFERENCE_ID_FAILURE",

  SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_SUCCESS:
    "SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_SUCCESS",
  SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_FAILURE:
    "SET_CONDITIONAL_ACTION_FORM_BY_REFERENCE_ID_FAILURE",

  HANDLE_CHANGE_FIRST_CONDITIONAL_FORM: "HANDLE_CHANGE_FIRST_CONDITIONAL_FORM",
  SET_CHECKED_VALUE: "SET_CHECKED_VALUE",
  SET_PREVIOUS_ID_VALUE: "SET_PREVIOUS_ID_VALUE",

  SET_EDITED_CONDITIONAL_FORM: "SET_EDITED_CONDITIONAL_FORM",
};
//action
export const ACTION_TYPE_FOR_DYNAMIC_ACTION_PAGE = {
  DYNAMIC_ACTION_DATA_REQUEST_MADE: "DYNAMIC_ACTION_DATA_REQUEST_MADE",
  FETCH_ACTION_INFO_SUCCESS: "FETCH_ACTION_INFO_SUCCESS",
  ADD_DATA_SUCCESS: "ADD_DATA_SUCCESS",
  ADD_DATA_FAILURE: "ADD_DATA_FAILURE",
  HANDLE_CHANGE_DYNAMIC_ACTION_DATA: "HANDLE_CHANGE_DYNAMIC_ACTION_DATA",
  SET_DYNAMIC_ACTION_DATA_TO_EDIT: "SET_DYNAMIC_ACTION_DATA_TO_EDIT",
  MARK_DYNAMIC_ACTION_DATA_SUCCESS: "MARK_DYNAMIC_ACTION_DATA_SUCCESS",
  MARK_DYNAMIC_ACTION_DATA_FAILURE: "MARK_DYNAMIC_ACTION_DATA_FAILURE",
  CLOSE_SNACK_BAR_DYNAMIC_ACTION_PAGE: "CLOSE_SNACK_BAR_DYNAMIC_ACTION_PAGE",
  SET_PREVIOUS_VALUE_TO_TRUE: "SET_PREVIOUS_VALUE_TO_TRUE",
  UPLOAD_PHOTO_SUCCESS: "UPLOAD_PHOTO_SUCCESS",
  UPLOAD_PHOTO_FAILURE: "UPLOAD_PHOTO_FAILURE",

  HANDLE_CHANGE_CLIENT_EMAIL: "HANDLE_CHANGE_CLIENT_EMAIL",
  HANDLE_CHANGE_IMAGE: "HANDLE_CHANGE_IMAGE",
  GENERATE_LINK_SUCCESS: "GENERATE_LINK_SUCCESS",
  GENERATE_LINK_FAILURE: "GENERATE_LINK_FAILURE",

  //new handle change
  HANDLE_CHANGE_ACTION_INPUT_DATA: "HANDLE_CHANGE_ACTION_INPUT_DATA",

  HANDLE_CHANGE_FILE_NAME: "HANDLE_CHANGE_FILE_NAME",
  HANDLE_CHANGE_SELECTED_VALUE: "HANDLE_CHANGE_SELECTED_VALUE",
  HANDLE_CHANGE_CHECKBOX_VALUE: "HANDLE_CHANGE_CHECKBOX_VALUE",
  HANDLE_CHANGE_CONTACT_VALUE: "HANDLE_CHANGE_CONTACT_VALUE",
  HANDLE_CHANGE_DOB: "HANDLE_CHANGE_DOB",

  EXPORT_ACTION_DATA_SUCCESS: "EXPORT_ACTION_DATA_SUCCESS",
  EXPORT_ACTION_DATA_FAILURE: "EXPORT_ACTION_DATA_FAILURE",

  //CONDITIONAL DATA
  GET_CONDITIONAL_DATA_BY_REF_ID_SUCCESS:
    "GET_CONDITIONAL_DATA_BY_REF_ID_SUCCESS",
  GET_CONDITIONAL_DATA_BY_REF_ID_FAILURE:
    "GET_CONDITIONAL_DATA_BY_REF_ID_FAILURE",

  SET_CONDITIONAL_DATA_FOR_CASE_SUCCESS:
    "SET_CONDITIONAL_DATA_FOR_CASE_SUCCESS",
  SET_CONDITIONAL_DATA_FOR_CASE_FAILURE:
    "SET_CONDITIONAL_DATA_FOR_CASE_FAILURE",

  SET_CONDITIONAL_FORM_DATA_REQUEST: "SET_CONDITIONAL_FORM_DATA_REQUEST",

  UPLOAD_CONDITIONAL_PHOTO_SUCCESS: "UPLOAD_CONDITIONAL_PHOTO_SUCCESS",
  UPLOAD_CONDITIONAL_PHOTO_FAILURE: "UPLOAD_CONDITIONAL_PHOTO_FAILURE",

  HANDLE_CHANGE_CONDITIONAL_FORM_DATA: "HANDLE_CHANGE_CONDITIONAL_FORM_DATA",
  HANDLE_CHANGE_CONDITIONAL_FORM_CHECKBOX_DATA:
    "HANDLE_CHANGE_CONDITIONAL_FORM_CHECKBOX_DATA",
  HANDLE_CHANGE_CONDITIONAL_FORM_FILE_NAME:
    "HANDLE_CHANGE_CONDITIONAL_FORM_FILE_NAME",
  HANDLE_CHANGE_CONDITIONAL_CONTACT_VALUE:
    "HANDLE_CHANGE_CONDITIONAL_CONTACT_VALUE",

  HANDLE_SET_SELECTED_OPTION: "HANDLE_SET_SELECTED_OPTION",

  SET_CONDITIONAL_FORM_DATA_TO_EMPTY: "SET_CONDITIONAL_FORM_DATA_TO_EMPTY",
  SET_CONDITIONAL_SUCCESS: "SET_CONDITIONAL_SUCCESS",

  HANDLE_CHANGE_DATA: "HANDLE_CHANGE_DATA",
};
//action for client dynamic action
export const ACTION_TYPE_FOR_DYNAMIC_ACTION_CLIENT_PAGE = {
  DYNAMIC_ACTION_CLIENT_PAGE_REQUEST_MADE:
    "DYNAMIC_ACTION_CLIENT_PAGE_REQUEST_MADE",

  VALIDATE_URL_LINK_KEY_SUCCESS: "VALIDATE_URL_LINK_KEY_SUCCESS",
  VALIDATE_URL_LINK_KEY_FAILURE: "VALIDATE_URL_LINK_KEY_FAILURE",

  SET_PREVIOUS_VALUE_TRUE: "SET_PREVIOUS_VALUE_TRUE",
  HANDLE_CHANGE_CLIENT_ACTION_DATA: "HANDLE_CHANGE_CLIENT_ACTION_DATA",
  CLOSE_SNACK_BAR_DYNAMIC_ACTION_CLIENT_PAGE:
    "CLOSE_SNACK_BAR_DYNAMIC_ACTION_CLIENT_PAGE",

  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",

  SEND_ACTION_DATA_SUCCESS: "SEND_ACTION_DATA_SUCCESS",
  SEND_ACTION_DATA_FAILURE: "SEND_ACTION_DATA_FAILURE",

  HANDLE_CHANGE_FILE_NAME_BY_CLIENT: "HANDLE_CHANGE_FILE_NAME_BY_CLIENT",
  HANDLE_CHANGE_SELECTED_VALUE_BY_CLIENT:
    "HANDLE_CHANGE_SELECTED_VALUE_BY_CLIENT",
  HANDLE_CHANGE_CHECKBOX_VALUE_BY_CLIENT:
    "HANDLE_CHANGE_CHECKBOX_VALUE_BY_CLIENT",
  HANDLE_CHANGE_CONTACT_VALUE_BY_CLIENT:
    "HANDLE_CHANGE_CONTACT_VALUE_BY_CLIENT",
  HANDLE_CHANGE_DOB_BY_CLIENT: "HANDLE_CHANGE_DOB_BY_CLIENT",

  HANDLE_CHANGE_INPUT_BY_CLIENT: "HANDLE_CHANGE_INPUT_BY_CLIENT",

  //CONDITIONAL DATA FOR CLIENT
  GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_SUCCESS:
    "GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_SUCCESS",
  GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_FAILURE:
    "GET_CONDITIONAL_DATA_BY_REF_ID_BY_CLIENT_FAILURE",
  HANDLE_CLOSE_CLIENT_CONDITIONAL_FORM: "HANDLE_CLOSE_CLIENT_CONDITIONAL_FORM",

  SET_CONDITIONAL_DATA_FOR_CASE_BY_CLIENT_SUCCESS:
    "SET_CONDITIONAL_DATA_FOR_CASE_BY_CLIENT_SUCCESS",
  SET_CONDITIONAL_DATA_FOR_CASE_BY_CLIENT_FAILURE:
    "SET_CONDITIONAL_DATA_FOR_CASE_BY_CLIENT_FAILURE",

  SET_CONDITIONAL_FORM_DATA_BY_CLIENT_REQUEST:
    "SET_CONDITIONAL_FORM_DATA_BY_CLIENT_REQUEST",

  UPLOAD_CONDITIONAL_PHOTO_SUCCESS: "UPLOAD_CONDITIONAL_PHOTO_SUCCESS",
  UPLOAD_CONDITIONAL_PHOTO_FAILURE: "UPLOAD_CONDITIONAL_PHOTO_FAILURE",

  // HANDLE_CHANGE_CONDITIONAL_FORM_DATA: "HANDLE_CHANGE_CONDITIONAL_FORM_DATA",
  // HANDLE_CHANGE_CONDITIONAL_FORM_CHECKBOX_DATA: "HANDLE_CHANGE_CONDITIONAL_FORM_CHECKBOX_DATA",
  // HANDLE_CHANGE_CONDITIONAL_FORM_FILE_NAME: "HANDLE_CHANGE_CONDITIONAL_FORM_FILE_NAME",
  // HANDLE_CHANGE_CONDITIONAL_CONTACT_VALUE: "HANDLE_CHANGE_CONDITIONAL_CONTACT_VALUE",
  //
  // HANDLE_SET_SELECTED_OPTION: "HANDLE_SET_SELECTED_OPTION",
  //
  // SET_CONDITIONAL_FORM_DATA_TO_EMPTY: "SET_CONDITIONAL_FORM_DATA_TO_EMPTY",
  // SET_CONDITIONAL_SUCCESS: "SET_CONDITIONAL_SUCCESS",
  //
  // HANDLE_CHANGE_DATA: "HANDLE_CHANGE_DATA",
};
//corporate
export const ACTION_TYPE_CORPORATE = {
  CORPORATE_FETCH_SUCCESS: "CORPORATE_FETCH_SUCCESS",
  CORPORATE_FETCH_FAILURE: "CORPORATE_FETCH_FAILURE",
  CLOSE_SNACK_BAR_CORPORATE_PAGE: "CLOSE_SNACK_BAR_CORPORATE_PAGE",
  CORPORATE_REQUEST_MADE: "CORPORATE_REQUEST_MADE",
  DELETE_CORPORATE_SUCCESS: "DELETE_CORPORATE_SUCCESS",
  DELETE_CORPORATE_FAILURE: "DELETE_CORPORATE_FAILURE",
  HANDLE_CHANGE_CORPORATE_TO_ADD: "HANDLE_CHANGE_CORPORATE_TO_ADD",
  HANDLE_CHANGE_CORPORATE_TO_EDIT: "HANDLE_CHANGE_CORPORATE_TO_EDIT",
  ADD_CORPORATE_SUCCESS: "ADD_CORPORATE_SUCCESS",
  ADD_CORPORATE_FAILURE: "ADD_CORPORATE_FAILURE",
  SET_CORPORATE_TO_EDIT: "SET_CORPORATE_TO_EDIT",
  SET_CORPORATE_TO_ADD_NULL: "SET_CORPORATE_TO_ADD_NULL",
  SEARCH_CORPORATE_SUCCESS: "SEARCH_CORPORATE_SUCCESS",
  SEARCH_CORPORATE_FAILURE: "SEARCH_CORPORATE_FAILURE",
};
//corporate client
export const ACTION_TYPE_CORPORATE_CLIENT = {
  FETCH_CORPORATE_INFO_SUCCESS: "FETCH_CORPORATE_INFO_SUCCESS",
  FETCH_CORPORATE_INFO_FAILURE: "FETCH_CORPORATE_INFO_FAILURE",
  CLOSE_SNACK_BAR_CORPORATE_CLIENT_PAGE:
    "CLOSE_SNACK_BAR_CORPORATE_CLIENT_PAGE",
  CORPORATE_CLIENT_REQUEST_MADE: "CORPORATE_CLIENT_REQUEST_MADE",
  DELETE_CORPORATE_CLIENT_SUCCESS: "DELETE_CORPORATE_CLIENT_SUCCESS",
  DELETE_CORPORATE_CLIENT_FAILURE: "DELETE_CORPORATE_CLIENT_FAILURE",

  HANDLE_CHANGE_CORPORATE_CLIENT: "HANDLE_CHANGE_CORPORATE_CLIENT",
  ADD_CORPORATE_CLIENT_SUCCESS: "ADD_CORPORATE_CLIENT_SUCCESS",
  ADD_CORPORATE_CLIENT_FAILURE: "ADD_CORPORATE_CLIENT_FAILURE",

  ASSIGN_CLIENT_TO_CORPORATE_SUCCESS: "ASSIGN_CLIENT_TO_CORPORATE_SUCCESS",
  ASSIGN_CLIENT_TO_CORPORATE_FAILURE: "ASSIGN_CLIENT_TO_CORPORATE_FAILURE",

  SEARCH_CLIENT_SUCCESS: "SEARCH_CLIENT_SUCCESS",
  SEARCH_CLIENT_FAILURE: "SEARCH_CLIENT_FAILURE",

  CLIENT_FETCH_SUCCESS: "CLIENT_FETCH_SUCCESS",
  CLIENT_FETCH_FAILURE: "CLIENT_FETCH_FAILURE",

  SET_CORPORATE_CLIENT_TO_EDIT: "SET_CORPORATE_CLIENT_TO_EDIT",
  HANDLE_CHANGE_CORPORATE_CLIENT_EDIT: "HANDLE_CHANGE_CORPORATE_CLIENT_EDIT",
};
export const ACTION_TYPE_SET_PASSWORD = {
  SET_CLIENT_PASSWORD_SUCCESS: "SET_CLIENT_PASSWORD_SUCCESS",
  SET_CLIENT_PASSWORD_FAILURE: "SET_CLIENT_PASSWORD_FAILURE",

  HANDLE_CHANGE_EMAIL: "HANDLE_CHANGE_EMAIL",
};
// schedule transaction
export const ACTION_TYPE_SCHEDULE_TRANSACTION = {
  SCHEDULE_TRANSACTION_REQUEST_MADE: "SCHEDULE_TRANSACTION_REQUEST_MADE",
  FETCH_SCHEDULE_TRANSACTION_SUCCESS: "FETCH_SCHEDULE_TRANSACTION_SUCCESS",
  FETCH_SCHEDULE_TRANSACTION_FAILURE: "FETCH_SCHEDULE_TRANSACTION_FAILURE",

  FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS:
    "FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS",
  FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE:
    "FETCH_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE",

  SET_SCHEDULE_TRANSACTION_TO_EDIT: "SET_SCHEDULE_TRANSACTION_TO_EDIT",

  ADD_SCHEDULE_TRANSACTION_SUCCESS: "ADD_SCHEDULE_TRANSACTION_SUCCESS",
  ADD_SCHEDULE_TRANSACTION_FAILURE: "ADD_SCHEDULE_TRANSACTION_FAILURE",

  REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS:
    "REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_SUCCESS",
  REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE:
    "REQUEST_SCHEDULE_TRANSACTION_BY_CLIENT_FAILURE",

  HANDLE_CHANGE_SCHEDULE_DETAILS: "HANDLE_CHANGE_SCHEDULE_DETAILS",
  HANDLE_CHANGE_OTHER_SCHEDULE_TRANSACTION:
    "HANDLE_CHANGE_OTHER_SCHEDULE_TRANSACTION",
  HANDLE_CHANGE_CLIENT_NAME: "HANDLE_CHANGE_CLIENT_NAME",
  HANDLE_CHANGE_SCHEDULE_TRANSACTION_ID:
    "HANDLE_CHANGE_SCHEDULE_TRANSACTION_ID",
  HANDLE_CHANGE_STATUS: "HANDLE_CHANGE_STATUS",
  HANDLE_CHANGE_SCHEDULE_TYPE: "HANDLE_CHANGE_SCHEDULE_TYPE",
  HANDLE_CHANGE_VENDOR_TO_FILTER: "HANDLE_CHANGE_VENDOR_TO_FILTER",
  HANDLE_CHANGE_TRANSACTION_MEDIUM: "HANDLE_CHANGE_TRANSACTION_MEDIUM",
  HANDLE_CHANGE_TRANSACTION_TYPE: "HANDLE_CHANGE_TRANSACTION_TYPE",
  HANDLE_CHANGE_SCHEDULE_TERM: "HANDLE_CHANGE_SCHEDULE_TERM",

  HANDLE_CHANGE_FROM_DATE: "HANDLE_CHANGE_FROM_DATE",
  HANDLE_CHANGE_TO_DATE: "HANDLE_CHANGE_TO_DATE",
  HANDLE_CHANGE_MEDIUM: "HANDLE_CHANGE_MEDIUM",
  HANDLE_CHANGE_STATUS_TO_FILTER: "HANDLE_CHANGE_STATUS_TO_FILTER",
  HANDLE_CHANGE_BANK_DETAIL_NAME: "HANDLE_CHANGE_BANK_DETAIL_NAME",

  HANDLE_CHANGE_SCHEDULE_TRANSACTION_INPUT_DATA:
    "HANDLE_CHANGE_SCHEDULE_TRANSACTION_INPUT_DATA",
  CLEAR_SCHEDULE_TRANSACTION_INPUT_DATA:
    "CLEAR_SCHEDULE_TRANSACTION_INPUT_DATA",
  SET_SCHEDULE_TRANSACTION_INPUT_DATA_TO_EDIT:
    "SET_SCHEDULE_TRANSACTION_INPUT_DATA_TO_EDIT",

  UPLOAD_DOCUMENT_FILE_SUCCESS: "UPLOAD_DOCUMENT_FILE_SUCCESS",
  UPLOAD_DOCUMENT_FILE_FAILURE: "UPLOAD_DOCUMENT_FILE_FAILURE",
  HANDLE_SHOW_FILE_UPLOAD_FAILURE_MESSAGE:
    "HANDLE_SHOW_FILE_UPLOAD_FAILURE_MESSAGE",
  CLEAR_FILE_DATA: "CLEAR_FILE_DATA",

  SET_OPEN_SCHEDULE_FORM: "SET_OPEN_SCHEDULE_FORM",

  //SEARCH SCHEDULE TRANSACTION
  SEARCH_SCHEDULE_TRANSACTION_SUCCESS: "SEARCH_SCHEDULE_TRANSACTION_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_FAILURE: "SEARCH_SCHEDULE_TRANSACTION_FAILURE",

  SEARCH_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_DATE_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_DATE_FAILURE",
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE",
  SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE",
  //combination
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE",
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE",
  SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE",
  SEARCH_SCHEDULE_TRANSACTION_BY_ALL_SUCCESS:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS",
  SEARCH_SCHEDULE_TRANSACTION_BY_ALL_FAILURE:
    "SEARCH_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE",

  CLEAR_SCHEDULE_TRANSACTION_FORM: "CLEAR_SCHEDULE_TRANSACTION_FORM",

  //SEARCH BY CLIENT ID
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_FAILURE",

  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_DATE_FAILURE",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_FAILURE",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_FAILURE",

  // COMBINATION
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_STATUS_AND_DATE_FAILURE",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS",
  SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE:
    "SEARCH_CLIENT_SCHEDULE_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE",

  SEARCH_CLIENT_LIST_SUCCESS: "SEARCH_CLIENT_LIST_SUCCESS",
  SEARCH_CLIENT_LIST_FAILURE: "SEARCH_CLIENT_LIST_FAILURE",

  DELETE_SCHEDULE_TRANSACTION_SUCCESS: "DELETE_SCHEDULE_TRANSACTION_SUCCESS",
  DELETE_SCHEDULE_TRANSACTION_FAILURE: "DELETE_SCHEDULE_TRANSACTION_FAILURE",

  UPDATE_SCHEDULE_TRANSACTION_SUCCESS: "UPDATE_SCHEDULE_TRANSACTION_SUCCESS",
  UPDATE_SCHEDULE_TRANSACTION_FAILURE: "UPDATE_SCHEDULE_TRANSACTION_FAILURE",

  FETCH_INDIVIDUAL_CLIENT_SCHEDULE_TRANSACTION_SUCCESS:
    "FETCH_INDIVIDUAL_CLIENT_SCHEDULE_TRANSACTION_SUCCESS",
  FETCH_INDIVIDUAL_CLIENT_SCHEDULE_TRANSACTION_FAILURE:
    "FETCH_INDIVIDUAL_CLIENT_SCHEDULE_TRANSACTION_FAILURE",

  CLOSE_SNACK_BAR_SCHEDULE_PAGE: "CLOSE_SNACK_BAR_SCHEDULE_PAGE",
  SET_OPEN_MODAL_TO_SCHEDULE_TRANSACTION:
    "SET_OPEN_MODAL_TO_SCHEDULE_TRANSACTION",

  // Export
  EXPORT_SCHEDULE_TRANSACTION_SUCCESS: "EXPORT_SCHEDULE_TRANSACTION_SUCCESS",
  EXPORT_SCHEDULE_TRANSACTION_FAILURE: "EXPORT_SCHEDULE_TRANSACTION_FAILURE",

  // NEW FILTER
  HANDLE_SCH_TRANS_FILTER: "HANDLE_SCH_TRANS_FILTER",
  CLEAR_SCH_TRANS_FILTER: "CLEAR_SCH_TRANS_FILTER",
  // CONFIG
  SAVE_SCH_TRANS_CONFIG_SUCCESS: "SAVE_SCH_TRANS_CONFIG_SUCCESS",
  SAVE_SCH_TRANS_CONFIG_FAILURE: "SAVE_SCH_TRANS_CONFIG_FAILURE",
  GET_SCH_TRANS_CONFIG_SUCCESS: "GET_SCH_TRANS_CONFIG_SUCCESS",
  GET_SCH_TRANS_CONFIG_FAILURE: "GET_SCH_TRANS_CONFIG_FAILURE",
};

//Vendor
export const ACTION_TYPE_VENDOR = {
  VENDOR_REQUEST_MADE: "VENDOR_REQUEST_MADE",
  FETCH_VENDOR_SUCCESS: "FETCH_VENDOR_SUCCESS",
  FETCH_VENDOR_FAILURE: "FETCH_VENDOR_FAILURE",

  ADD_VENDOR_SUCCESS: "ADD_VENDOR_SUCCESS",
  ADD_VENDOR_FAILURE: "ADD_VENDOR_FAILURE",
  HANDLE_CHANGE_VENDOR_INPUT: "HANDLE_CHANGE_VENDOR_INPUT",

  SEARCH_VENDOR_SUCCESS: "SEARCH_VENDOR_SUCCESS",
  SEARCH_VENDOR_FAILURE: "SEARCH_VENDOR_FAILURE",

  DELETE_VENDOR_SUCCESS: "DELETE_VENDOR_SUCCESS",
  DELETE_VENDOR_FAILURE: "DELETE_VENDOR_FAILURE",

  UPDATE_VENDOR_SUCCESS: "UPDATE_VENDOR_SUCCESS",
  UPDATE_VENDOR_FAILURE: "UPDATE_VENDOR_FAILURE",

  SET_VENDOR_TO_EDIT: "SET_VENDOR_TO_EDIT",
  CLOSE_SNACK_BAR_VENDOR_PAGE: "CLOSE_SNACK_BAR_VENDOR_PAGE",

  CLEAR_VENDOR_INPUT_DATA: "CLEAR_VENDOR_INPUT_DATA",
  FILTER_DATA_ARRAY: "FILTER_DATA_ARRAY",
  //ADDRESS
  HANDLE_CHANGE_ADDRESS_DATA: "HANDLE_CHANGE_ADDRESS_DATA",
  HANDLE_CHANGE_ADDRESS_ARRAY: "HANDLE_CHANGE_ADDRESS_ARRAY",

  //vendor client
  HANDLE_CHANGE_VENDOR_CLIENT_DATA: "HANDLE_CHANGE_VENDOR_CLIENT_DATA",
  FETCH_VENDOR_CLIENTS_LIST_SUCCESS: "FETCH_VENDOR_CLIENTS_LIST_SUCCESS",
  FETCH_VENDOR_CLIENTS_LIST_FAILURE: "FETCH_VENDOR_CLIENTS_LIST_FAILURE",
  ADD_VENDOR_CLIENT_SUCCESS: "ADD_VENDOR_CLIENT_SUCCESS",
  ADD_VENDOR_CLIENT_FAILURE: "ADD_VENDOR_CLIENT_FAILURE",
  UPDATE_VENDOR_CLIENT_SUCCESS: "UPDATE_VENDOR_CLIENT_SUCCESS",
  UPDATE_VENDOR_CLIENT_FAILURE: "UPDATE_VENDOR_CLIENT_FAILURE",
  DELETE_VENDOR_CLIENT_SUCCESS: "DELETE_VENDOR_CLIENT_SUCCESS",
  DELETE_VENDOR_CLIENT_FAILURE: "DELETE_VENDOR_CLIENT_FAILURE",
  SET_VENDOR_CLIENT_TO_EDIT: "SET_VENDOR_CLIENT_TO_EDIT",

  FETCH_VENDOR_CLIENT_BY_CLIENT_ID_SUCCESS:
    "FETCH_VENDOR_CLIENT_BY_CLIENT_ID_SUCCESS",
  FETCH_VENDOR_CLIENT_BY_CLIENT_ID_FAILURE:
    "FETCH_VENDOR_CLIENT_BY_CLIENT_ID_FAILURE",
};
//Card
export const ACTION_TYPE_CARD = {
  CARD_REQUEST_MADE: "CARD_REQUEST_MADE",
  FETCH_CARD_SUCCESS: "FETCH_CARD_SUCCESS",
  FETCH_CARD_FAILURE: "FETCH_CARD_FAILURE",

  ORDER_CARD_SUCCESS: "ORDER_CARD_SUCCESS",
  ORDER_CARD_FAILURE: "ORDER_CARD_FAILURE",
  HANDLE_CHANGE_CARD_INPUT: "HANDLE_CHANGE_CARD_INPUT",
  HANDLE_CHANGE_ORDER_STATUS: "HANDLE_CHANGE_ORDER_STATUS",
  HANDLE_CHANGE_CARD_STATUS: "HANDLE_CHANGE_CARD_STATUS",

  SEARCH_CARD_SUCCESS: "SEARCH_CARD_SUCCESS",
  SEARCH_CARD_FAILURE: "SEARCH_CARD_FAILURE",

  SET_CARD_TO_VIEW: "SET_CARD_TO_VIEW",
  UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
  UPDATE_ORDER_STATUS_FAILURE: "UPDATE_ORDER_STATUS_FAILURE",

  UPDATE_CARD_STATUS_SUCCESS: "UPDATE_CARD_STATUS_SUCCESS",
  UPDATE_CARD_STATUS_FAILURE: "UPDATE_CARD_STATUS_FAILURE",

  CLOSE_SNACK_BAR_CARD_PAGE: "CLOSE_SNACK_BAR_CARD_PAGE",
  SET_ORDER_CARD_TO_EMPTY: "SET_ORDER_CARD_TO_EMPTY",
};
export const ACTION_TYPE_TRANSACTION = {
  TRANSACTION_REQUEST_MADE: "TRANSACTION_REQUEST_MADE",
  FETCH_TRANSACTION_SUCCESS: "FETCH_TRANSACTION_SUCCESS",
  FETCH_TRANSACTION_FAILURE: "FETCH_TRANSACTION_FAILURE",

  FETCH_DELETED_TRANSACTION_SUCCESS: "FETCH_DELETED_TRANSACTION_SUCCESS",
  FETCH_DELETED_TRANSACTION_FAILURE: "FETCH_DELETED_TRANSACTION_FAILURE",

  DELETE_TRANSACTION_SUCCESS: "DELETE_TRANSACTION_SUCCESS",
  DELETE_TRANSACTION_FAILURE: "DELETE_TRANSACTION_FAILURE",

  SET_TRANSACTION_ID_TO_VIEW_SUCCESS: "SET_TRANSACTION_ID_TO_VIEW_SUCCESS",
  SET_TRANSACTION_ID_TO_VIEW_FAILURE: "SET_TRANSACTION_ID_TO_VIEW_FAILURE",

  HANDLE_CHANGE_TRANS_DATA: "HANDLE_CHANGE_TRANS_DATA",
  HANDLE_CHANGE_DESC_DATA: "HANDLE_CHANGE_DESC_DATA",
  CREATE_TRANSACTION_SUCCESS: "CREATE_TRANSACTION_SUCCESS",
  CREATE_TRANSACTION_FAILURE: "CREATE_TRANSACTION_FAILURE",

  // FOR CREATING CLIENT TRANSACTION
  CREATE_CLIENT_TRANSACTION_SUCCESS: "CREATE_CLIENT_TRANSACTION_SUCCESS",
  CREATE_CLIENT_TRANSACTION_FAILURE: "CREATE_CLIENT_TRANSACTION_FAILURE",

  GET_AMOUNT_DETAIL_BY_CLIENT_ID_SUCCESS:
    "GET_AMOUNT_DETAIL_BY_CLIENT_ID_SUCCESS",
  GET_AMOUNT_DETAIL_BY_CLIENT_ID_FAILURE:
    "GET_AMOUNT_DETAIL_BY_CLIENT_ID_FAILURE",
  CLEAR_AMOUNT_DETAIL: "CLEAR_AMOUNT_DETAIL",
  // UPDATE BLOCKED TRANSACTION
  HANDLE_EDIT_BLOCKED_TRANS_DATA: "HANDLE_EDIT_BLOCKED_TRANS_DATA",
  UPLOAD_BLOCKED_TRANSACTION_SUCCESS: "UPLOAD_BLOCKED_TRANSACTION_SUCCESS",
  UPLOAD_BLOCKED_TRANSACTION_FAILURE: "UPLOAD_BLOCKED_TRANSACTION_FAILURE",
  CLEAR_BLOCKED_TRANSACTION: "CLEAR_BLOCKED_TRANSACTION",

  // set client data transaction to edit
  SET_CLIENT_TRANSACTION_DATA_TO_EDIT: "SET_CLIENT_TRANSACTION_DATA_TO_EDIT",
  //NOTES
  HANDLE_CHANGE_TO_OPEN_NOTE_MODAL: "HANDLE_CHANGE_TO_OPEN_NOTE_MODAL",
  HANDLE_CHANGE_NOTES: "HANDLE_CHANGE_NOTES",
  SET_NOTES_TO_EDIT: "SET_NOTES_TO_EDIT",
  ADD_NOTES_FAILURE: "ADD_NOTES_FAILURE",
  ADD_NOTES_SUCCESS: "ADD_NOTES_SUCCESS",
  UPDATE_NOTES_SUCCESS: "UPDATE_NOTES_SUCCESS",
  UPDATE_NOTES_FAILURE: "UPDATE_NOTES_FAILURE",

  //LEDGER NOTES
  HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_LEDGER:
    "HANDLE_CHANGE_TO_OPEN_NOTE_MODAL_FOR_LEDGER",

  UPDATE_DESCRIPTION_SUCCESS: "UPDATE_DESCRIPTION_SUCCESS",
  UPDATE_DESCRIPTION_ERROR: "UPDATE_DESCRIPTION_ERROR",

  //CREATE TRANSACTION
  SET_OPEN_CREATE_TRANSACTION_MODAL: "SET_OPEN_CREATE_TRANSACTION_MODAL",
  CLEAR_CREATE_TRANSACTION_MODAL: "CLEAR_CREATE_TRANSACTION_MODAL",
  CLEAR_WITHDRAWAL_TRANSACTION_MODAL: "CLEAR_WITHDRAWAL_TRANSACTION_MODAL",
  // EDIT TRANSACTION
  SET_TRANSACTION_INPUT_DATA_TO_EDIT: "SET_TRANSACTION_INPUT_DATA_TO_EDIT",
  DECLINE_TRANS_CHECK_SUCCESS: "DECLINE_TRANS_CHECK_SUCCESS",
  DECLINE_TRANS_CHECK_FAILURE: "DECLINE_TRANS_CHECK_FAILURE",
  // EXPORT VOD
  TRANSACTION_VOD_REQUEST: "TRANSACTION_VOD_REQUEST",
  EXPORT_TRANSACTION_VOD_SUCCESS: "EXPORT_TRANSACTION_VOD_SUCCESS",
  EXPORT_TRANSACTION_VOD_FAILURE: "EXPORT_TRANSACTION_VOD_FAILURE",

  //EXPORT PDF (OVERALL TRANSACTION)
  EXPORT_TRANSACTION_SUCCESS: "EXPORT_TRANSACTION_SUCCESS",
  EXPORT_TRANSACTION_FAILURE: "EXPORT_TRANSACTION_FAILURE",
  EXPORT_TRANSACTION_BY_TERM_SUCCESS: "EXPORT_TRANSACTION_BY_TERM_SUCCESS",
  EXPORT_TRANSACTION_BY_TERM_FAILURE: "EXPORT_TRANSACTION_BY_TERM_FAILURE",
  EXPORT_TRANSACTION_BY_DATE_SUCCESS: "EXPORT_TRANSACTION_BY_DATE_SUCCESS",
  EXPORT_TRANSACTION_BY_DATE_FAILURE: "EXPORT_TRANSACTION_BY_DATE_FAILURE",
  EXPORT_TRANSACTION_BY_MEDIUM_SUCCESS: "EXPORT_TRANSACTION_BY_MEDIUM_SUCCESS",
  EXPORT_TRANSACTION_BY_MEDIUM_FAILURE: "EXPORT_TRANSACTION_BY_MEDIUM_FAILURE",
  EXPORT_TRANSACTION_BY_STATUS_SUCCESS: "EXPORT_TRANSACTION_BY_STATUS_SUCCESS",
  EXPORT_TRANSACTION_BY_STATUS_FAILURE: "EXPORT_TRANSACTION_BY_STATUS_FAILURE",

  EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS:
    "EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS",
  EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE:
    "EXPORT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE",
  EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS:
    "EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS",
  EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE:
    "EXPORT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE",
  EXPORT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS:
    "EXPORT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS",
  EXPORT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE:
    "EXPORT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE",
  EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS:
    "EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS",
  EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE:
    "EXPORT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE",

  //EXPORT PDF (INDIVIDUAL CLIENT TRANSACTION)
  EXPORT_SINGLE_CLIENT_TRANSACTION_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_FAILURE",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_FAILURE",

  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_TERM_FAILURE",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_FAILURE",

  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_STATUS_AND_MEDIUM_FAILURE",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_MEDIUM_FAILURE",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_FAILURE",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_SUCCESS",
  EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE:
    "EXPORT_SINGLE_CLIENT_TRANSACTION_BY_DATE_AND_STATUS_AND_MEDIUM_FAILURE",

  //TRANSACTION SEARCH
  SEARCH_TRANSACTION_SUCCESS: "SEARCH_TRANSACTION_SUCCESS",
  SEARCH_TRANSACTION_FAILURE: "SEARCH_TRANSACTION_FAILURE",
  SEARCH_TRANSACTION_BY_DATE_SUCCESS: "SEARCH_TRANSACTION_BY_DATE_SUCCESS",
  SEARCH_TRANSACTION_BY_DATE_FAILURE: "SEARCH_TRANSACTION_BY_DATE_FAILURE",
  SEARCH_TRANSACTION_BY_MEDIUM_SUCCESS: "SEARCH_TRANSACTION_BY_MEDIUM_SUCCESS",
  SEARCH_TRANSACTION_BY_MEDIUM_FAILURE: "SEARCH_TRANSACTION_BY_MEDIUM_FAILURE",
  SEARCH_TRANSACTION_BY_STATUS_SUCCESS: "SEARCH_TRANSACTION_BY_STATUS_SUCCESS",
  SEARCH_TRANSACTION_BY_STATUS_FAILURE: "SEARCH_TRANSACTION_BY_STATUS_FAILURE",
  //different conditions for filter
  SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
    "SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS",
  SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
    "SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE",
  SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
    "SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS",
  SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
    "SEARCH_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE",
  SEARCH_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
    "SEARCH_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS",
  SEARCH_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
    "SEARCH_TRANSACTION_BY_STATUS_AND_DATE_FAILURE",
  SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS:
    "SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS",
  SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE:
    "SEARCH_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE",

  SEARCH_SUBMITTED_TRANSACTION_SUCCESS: "SEARCH_SUBMITTED_TRANSACTION_SUCCESS",

  //INDIVIDUAL CLIENT SEARCH
  SEARCH_CLIENT_TRANSACTION_SUCCESS: "SEARCH_CLIENT_TRANSACTION_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_FAILURE: "SEARCH_CLIENT_TRANSACTION_FAILURE",
  SEARCH_CLIENT_TRANSACTION_BY_DATE_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_DATE_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_DATE_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_DATE_FAILURE",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_FAILURE",
  SEARCH_CLIENT_TRANSACTION_BY_STATUS_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_STATUS_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_STATUS_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_STATUS_FAILURE",
  //different conditions for filter
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_FAILURE",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE",
  SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_STATUS_AND_DATE_FAILURE",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_SUCCESS",
  SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE:
    "SEARCH_CLIENT_TRANSACTION_BY_MEDIUM_AND_STATUS_AND_DATE_FAILURE",

  OPEN_SNACK_BAR: "OPEN_SNACK_BAR",

  // FILTER TRANSACTION
  TRANSACTION_FILTER: "TRANSACTION_FILTER",
  CLEAR_TRANSACTIONS_FILTER: "CLEAR_TRANSACTIONS_FILTER",
  CLEAR_TRANSACTIONS_MAIN_FILTER: "CLEAR_TRANSACTIONS_MAIN_FILTER",
  SAVE_TRANSACTION_CONFIG_SUCCESS: "SAVE_TRANSACTION_CONFIG_SUCCESS",
  SAVE_TRANSACTION_CONFIG_FAILURE: "SAVE_TRANSACTION_CONFIG_FAILURE",
  GET_TRANSACTION_CONFIG_SUCCESS: "GET_TRANSACTION_CONFIG_SUCCESS",
  GET_TRANSACTION_CONFIG_FAILURE: "GET_TRANSACTION_CONFIG_FAILURE",

  // REVERSE TRANSACTION
  HANDLE_CHANGE_REVERSED_DATE: "HANDLE_CHANGE_REVERSED_DATE",

  //DOWNLOAD ACH DOC

  DOWNLOAD_ACH_DOCUMENT_SUCCESS: "DOWNLOAD_ACH_DOCUMENT_SUCCESS",
  DOWNLOAD_ACH_DOCUMENT_FAILURE: "DOWNLOAD_ACH_DOCUMENT_FAILURE",

  //HANDLE CHANGES
  HANDLE_CHANGE_DATE_FROM: "HANDLE_CHANGE_DATE_FROM",
  HANDLE_CHANGE_DATE_TO: "HANDLE_CHANGE_DATE_TO",
  HANDLE_CHANGE_MEDIUM: "HANDLE_CHANGE_MEDIUM",
  HANDLE_CHANGE_STATUS: "HANDLE_CHANGE_STATUS",
  HANDLE_CHANGE_TERM: "HANDLE_CHANGE_TERM",
  HANDLE_CHANGE_TYPE: "HANDLE_CHANGE_TYPE",
  HANDLE_CHANGE_VENDOR: "HANDLE_CHANGE_VENDOR",
  HANDLE_CHANGE_EXPORT_TYPE: "HANDLE_CHANGE_EXPORT_TYPE",

  CLOSE_SNACK_BAR: "CLOSE_SNACK_BAR",
  CLOSE_CLIENT_TRANSACTION_SNACK_BAR: "CLOSE_CLIENT_TRANSACTION_SNACK_BAR",

  FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS:
    "FETCH_INDIVIDUAL_CLIENT_TRANSACTION_SUCCESS",
  FETCH_INDIVIDUAL_CLIENT_TRANSACTION_FAILURE:
    "FETCH_INDIVIDUAL_CLIENT_TRANSACTION_FAILURE",

  //SUBMIT TRANSACTION
  FETCH_SUBMIT_TRANSACTION_SUCCESS: "FETCH_SUBMIT_TRANSACTION_SUCCESS",
  FETCH_SUBMIT_TRANSACTION_FAILURE: "FETCH_SUBMIT_TRANSACTION_FAILURE",
  UPDATE_SUBMIT_TRANSACTION_SUCCESS: "UPDATE_SUBMIT_TRANSACTION_SUCCESS",
  UPDATE_SUBMIT_TRANSACTION_FAILURE: "UPDATE_SUBMIT_TRANSACTION_FAILURE",
  HANDLE_CHANGE_SUBMIT_TRANS_STATUS: "HANDLE_CHANGE_SUBMIT_TRANS_STATUS",
  HANDLE_CHANGE_SUBMIT_TRANS_REMARK: "HANDLE_CHANGE_SUBMIT_TRANS_REMARK",
  HANDLE_CHANGE_SELECTED_DATA: "HANDLE_CHANGE_SELECTED_DATA",
  CLEAR_CHECKED_AND_UNCHECKED_VALUE: "CLEAR_CHECKED_AND_UNCHECKED_VALUE",
  CLEAR_UPDATE_MODAL_DATA: "CLEAR_UPDATE_MODAL_DATA",

  FETCH_APPROVED_TRANSACTION_SUCCESS: "FETCH_APPROVED_TRANSACTION_SUCCESS",
  FETCH_APPROVED_TRANSACTION_FAILURE: "FETCH_APPROVED_TRANSACTION_FAILURE",

  FETCH_CREATED_TRANSACTION_SUCCESS: "FETCH_CREATED_TRANSACTION_SUCCESS",
  FETCH_CREATED_TRANSACTION_FAILURE: "FETCH_CREATED_TRANSACTION_FAILURE",
  UPDATE_TRANSACTION_SUCCESS: "UPDATE_TRANSACTION_SUCCESS",
  UPDATE_TRANSACTION_FAILURE: "UPDATE_TRANSACTION_FAILURE",

  //IMPORT IN TRANSACTION
  IMPORT_TRANSACTION_SUCCESS: "IMPORT_TRANSACTION_SUCCESS",
  IMPORT_TRANSACTION_FAILURE: "IMPORT_TRANSACTION_FAILURE",

  GET_BANK_DETAILS_SUCCESS: "GET_BANK_DETAILS_SUCCESS",
  GET_BANK_DETAILS_FAILURE: "GET_BANK_DETAILS_FAILURE",

  HANDLE_CHANGE_CSV_FILE: "HANDLE_CHANGE_CSV_FILE",

  //CHANGE TRANSACTION STATUS
  HANDLE_CHANGE_TRANSACTION_STATUS: "HANDLE_CHANGE_TRANSACTION_STATUS",

  //LEDGER TRANSACTION
  FETCH_LEDGER_TRANSACTION_SUCCESS: "FETCH_LEDGER_TRANSACTION_SUCCESS",
  FETCH_LEDGER_TRANSACTION_FAILURE: "FETCH_LEDGER_TRANSACTION_FAILURE",
  EXPORT_LEDGER_TRANSACTION_SUCCESS: "EXPORT_LEDGER_TRANSACTION_SUCCESS",
  EXPORT_LEDGER_TRANSACTION_FAILURE: "EXPORT_LEDGER_TRANSACTION_FAILURE",
  FETCH_CLIENT_LEDGER_TRANSACTION_SUCCESS:
    "FETCH_CLIENT_LEDGER_TRANSACTION_SUCCESS",
  FETCH_CLIENT_LEDGER_TRANSACTION_FAILURE:
    "FETCH_CLIENT_LEDGER_TRANSACTION_FAILURE",
  EXPORT_CLIENT_LEDGER_TRANSACTION_FAILURE:
    "EXPORT_CLIENT_LEDGER_TRANSACTION_FAILURE",
  EXPORT_CLIENT_LEDGER_TRANSACTION_SUCCESS:
    "EXPORT_CLIENT_LEDGER_TRANSACTION_SUCCESS",
  HANDLE_OPEN_CREATE_WITHDRAWAL_VENDOR_TRANSACTION:
    "HANDLE_OPEN_CREATE_WITHDRAWAL_VENDOR_TRANSACTION",
  CREATE_WITHDRAWAL_VENDOR_TRANSACTION_SUCCESS:
    "CREATE_WITHDRAWAL_VENDOR_TRANSACTION_SUCCESS",
  CREATE_WITHDRAWAL_VENDOR_TRANSACTION_FAILURE:
    "CREATE_WITHDRAWAL_VENDOR_TRANSACTION_FAILURE",
  FETCH_WITHDRAWAL_VENDOR_LIST_SUCCESS: "FETCH_WITHDRAWAL_VENDOR_LIST_SUCCESS",
  FETCH_WITHDRAWAL_VENDOR_LIST_FAILURE: "FETCH_WITHDRAWAL_VENDOR_LIST_FAILURE",

  UPDATE_LEDGER_TRANSACTION_STATUS_SUCCESS:
    "UPDATE_LEDGER_TRANSACTION_STATUS_SUCCESS",
  UPDATE_LEDGER_TRANSACTION_STATUS_FAILURE:
    "UPDATE_LEDGER_TRANSACTION_STATUS_FAILURE",
  SET_OPEN_MODAL_TO_CHANGE_LEDGER_TXN_STATUS:
    "SET_OPEN_MODAL_TO_CHANGE_LEDGER_TXN_STATUS",
};

export const ACTION_TYPE_ACTIVITY_LOG = {
  FETCH_ACTIVITY_LOG_SUCCESS: "FETCH_ACTIVITY_LOG_SUCCESS",
  FETCH_ACTIVITY_LOG_FAILURE: "FETCH_ACTIVITY_LOG_FAILURE",

  FETCH_ACTIVITY_LOG_BY_ID_SUCCESS: "FETCH_ACTIVITY_LOG_BY_ID_SUCCESS",
  FETCH_ACTIVITY_LOG_BY_ID_FAILURE: "FETCH_ACTIVITY_LOG_BY_ID_FAILURE",

  FETCH_ACH_ACTIVITY_LOG_SUCCESS: "FETCH_ACH_ACTIVITY_LOG_SUCCESS",
  FETCH_ACH_ACTIVITY_LOG_FAILURE: "FETCH_ACH_ACTIVITY_LOG_FAILURE",

  ACTIVITY_LOG_REQUEST_MADE: "ACTIVITY_LOG_REQUEST_MADE",
  HANDLE_CLOSE_LOG_SNACKBAR: "HANDLE_CLOSE_LOG_SNACKBAR",
};

export const ACTION_TYPE_UPCOMING_TRANSACTION = {
  UP_COMING_TRANSACTION_REQUEST: "UP_COMING_TRANSACTION_REQUEST",
  CLOSE_SNACK_BAR: "CLOSE_SNACK_BAR",

  FETCH_UP_COMING_TRANSACTION_SUCCESS: "FETCH_UP_COMING_TRANSACTION_SUCCESS",
  FETCH_UP_COMING_TRANSACTION_FAILURE: "FETCH_UP_COMING_TRANSACTION_FAILURE",

  FETCH_CLIENT_UP_COMING_TRANSACTION_SUCCESS:
    "FETCH_CLIENT_UP_COMING_TRANSACTION_SUCCESS",
  FETCH_CLIENT_UP_COMING_TRANSACTION_FAILURE:
    "FETCH_CLIENT_UP_COMING_TRANSACTION_FAILURE",

  FETCH_ANNUAL_UP_COMING_TRANSACTION_SUCCESS:
    "FETCH_ANNUAL_UP_COMING_TRANSACTION_SUCCESS",
  FETCH_ANNUAL_UP_COMING_TRANSACTION_FAILURE:
    "FETCH_ANNUAL_UP_COMING_TRANSACTION_FAILURE",

  PROCESS_TRANSACTION_SUCCESS: "PROCESS_TRANSACTION_SUCCESS",
  PROCESS_TRANSACTION_FAILURE: "PROCESS_TRANSACTION_FAILURE",

  SEARCH_UPCOMING_TRANSACTION_BY_TERM_SUCCESS:
    "SEARCH_UPCOMING_TRANSACTION_BY_TERM_SUCCESS",
  SEARCH_UPCOMING_TRANSACTION_BY_TERM_FAILURE:
    "SEARCH_UPCOMING_TRANSACTION_BY_TERM_FAILURE",

  SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_SUCCESS:
    "SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_SUCCESS",
  SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_FAILURE:
    "SEARCH_ANNUAL_UPCOMING_TRANSACTION_BY_TERM_FAILURE",

  SEARCH_UPCOMING_TRANSACTION_BY_DATE_SUCCESS:
    "SEARCH_UPCOMING_TRANSACTION_BY_DATE_SUCCESS",
  SEARCH_UPCOMING_TRANSACTION_BY_DATE_FAILURE:
    "SEARCH_UPCOMING_TRANSACTION_BY_DATE_FAILURE",

  SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_SUCCESS:
    "SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_SUCCESS",
  SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_FAILURE:
    "SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_FAILURE",

  SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS:
    "SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_SUCCESS",
  SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE:
    "SEARCH_UPCOMING_TRANSACTION_BY_MEDIUM_AND_DATE_FAILURE",

  HANDLE_CHANGE_OPEN_MODAL_TO_SKIP_TRANSACTION:
    "HANDLE_CHANGE_OPEN_MODAL_TO_SKIP_TRANSACTION",
  HANDLE_CHANGE_REMARK_TO_SKIP: "HANDLE_CHANGE_REMARK_TO_SKIP",

  // NEW FILTER
  HANDLE_UP_TRANS_FILTER: "HANDLE_UP_TRANS_FILTER",
  CLEAR_UP_TRANS_FILTER: "CLEAR_UP_TRANS_FILTER",
};

export const ACTION_TYPE_SETTING = {
  REQUEST_IN_CALENDAR_SETTING: "REQUEST_IN_CALENDAR_SETTING",
  CLOSE_CALENDAR_SETTING_SNACKBAR: "CLOSE_CALENDAR_SETTING_SNACKBAR",
  MAKE_SUCCESS_TO_FALSE: "MAKE_SUCCESS_TO_FALSE",

  HANDLE_CHANGE_HOLIDAY_NAME: "HANDLE_CHANGE_HOLIDAY_NAME",
  HANDLE_CHANGE_START_DATE: "HANDLE_CHANGE_START_DATE",
  HANDLE_CHANGE_END_DATE: "HANDLE_CHANGE_END_DATE",
  HANDLE_CHANGE_INITIAL_START_DATE: "HANDLE_CHANGE_INITIAL_START_DATE",
  HANDLE_CHANGE_CALENDAR_TYPE: "HANDLE_CHANGE_CALENDAR_TYPE",

  FETCH_HOLIDAY_SUCCESS: "FETCH_HOLIDAY_SUCCESS",
  FETCH_HOLIDAY_FAILURE: "FETCH_HOLIDAY_FAILURE",

  ADD_HOLIDAYS_SUCCESS: "ADD_HOLIDAYS_SUCCESS",
  ADD_HOLIDAYS_FAILURE: "ADD_HOLIDAYS_FAILURE",

  SET_HOLIDAY_TO_EDIT: "SET_HOLIDAY_TO_EDIT",
  UPDATE_HOLIDAYS_SUCCESS: "UPDATE_HOLIDAYS_SUCCESS",
  UPDATE_HOLIDAYS_FAILURE: "UPDATE_HOLIDAYS_FAILURE",

  DELETE_HOLIDAYS_SUCCESS: "DELETE_HOLIDAYS_SUCCESS",
  DELETE_HOLIDAYS_FAILURE: "DELETE_HOLIDAYS_FAILURE",

  HANDLE_OPEN_ADD_HOLIDAY_MODAL: "HANDLE_OPEN_ADD_HOLIDAY_MODAL",
  HANDLE_CLOSE_ADD_HOLIDAY_MODAL: "HANDLE_CLOSE_ADD_HOLIDAY_MODAL",

  // PAYMENT SETTING
  HANDLE_CHANGE_FEE_PERCENTAGE: "HANDLE_CHANGE_FEE_PERCENTAGE",
  HANDLE_CHANGE_MIN_MONTHLY_FEE: "HANDLE_CHANGE_MIN_MONTHLY_FEE",
  HANDLE_CHANGE_MAX_MONTHLY_FEE: "HANDLE_CHANGE_MAX_MONTHLY_FEE",
  HANDLE_CHANGE_ANNUAL_FEE: "HANDLE_CHANGE_ANNUAL_FEE",
  HANDLE_CHANGE_SPEND_DOWN: "HANDLE_CHANGE_SPEND_DOWN",
  HANDLE_CHANGE_ACH_DELAY_THRESHOLD: "HANDLE_CHANGE_ACH_DELAY_THRESHOLD",
  HANDLE_CHANGE_ACH_UPLOAD_TIME: "HANDLE_CHANGE_ACH_UPLOAD_TIME",
  HANDLE_CHANGE_TIME_FORMAT: "HANDLE_CHANGE_TIME_FORMAT",
  ADD_FEE_PERCENTAGE_SUCCESS: "ADD_FEE_PERCENTAGE_SUCCESS",
  ADD_FEE_PERCENTAGE_FAILURE: "ADD_FEE_PERCENTAGE_FAILURE",
  FETCH_FEE_PERCENTAGE_SUCCESS: "FETCH_FEE_PERCENTAGE_SUCCESS",
  FETCH_FEE_PERCENTAGE_FAILURE: "FETCH_FEE_PERCENTAGE_FAILURE",

  IMPORT_HOLIDAY_CALENDAR_SUCCESS: "IMPORT_HOLIDAY_CALENDAR_SUCCESS",
  IMPORT_HOLIDAY_CALENDAR_FAILURE: "IMPORT_HOLIDAY_CALENDAR_FAILURE",
  HANDLE_CHANGE_ICS_FILE: "HANDLE_CHANGE_ICS_FILE",
  SET_IMPORT_HOLIDAY_CALENDAR_SUCCESS: "SET_IMPORT_HOLIDAY_CALENDAR_SUCCESS",

  CLEAR_MODAL_HOLIDAY_DATA: "CLEAR_MODAL_HOLIDAY_DATA",

  //ASSIGN EVENTS
  SEARCH_CLIENTS_OR_AGENTS_SUCCESS: "SEARCH_CLIENTS_OR_AGENTS_SUCCESS",
  SEARCH_CLIENTS_OR_AGENTS_FAILURE: "SEARCH_CLIENTS_OR_AGENTS_FAILURE",

  GET_ASSIGNED_CLIENTS_OR_AGENTS_SUCCESS:
    "GET_ASSIGNED_CLIENTS_OR_AGENTS_SUCCESS",
  GET_ASSIGNED_CLIENTS_OR_AGENTS_FAILURE:
    "GET_ASSIGNED_CLIENTS_OR_AGENTS_FAILURE",

  ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_SUCCESS:
    "ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_SUCCESS",
  ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_FAILURE:
    "ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS_FAILURE",

  HANDLE_CHANGE_ASSIGNED_EVENTS: "HANDLE_CHANGE_ASSIGNED_EVENTS",
  HANDLE_CHANGE_EVENT_ID: "HANDLE_CHANGE_EVENT_ID",

  //Eligibility Setting
  FETCH_ELIGIBILITY_DATA_SUCCESS: "FETCH_ELIGIBILITY_DATA_SUCCESS",
  FETCH_ELIGIBILITY_DATA_FAILURE: "FETCH_ELIGIBILITY_DATA_FAILURE",

  ADD_ELIGIBILITY_SUCCESS: "ADD_ELIGIBILITY_SUCCESS",
  ADD_ELIGIBILITY_FAILURE: "ADD_ELIGIBILITY_FAILURE",

  UPDATE_ELIGIBILITY_SUCCESS: "UPDATE_ELIGIBILITY_SUCCESS",
  UPDATE_ELIGIBILITY_FAILURE: "UPDATE_ELIGIBILITY_FAILURE",

  SET_ELIGIBILITY_DATA_TO_EDIT: "SET_ELIGIBILITY_DATA_TO_EDIT",
  HANDLE_CHANGE_ELIGIBILITY_INPUT: "HANDLE_CHANGE_ELIGIBILITY_INPUT",

  CLEAR_ELIGIBILITY_DATA: "CLEAR_ELIGIBILITY_DATA",

  // DESCRIPTION SETTING
  FETCH_DESCRIPTION_LIST_SUCCESS: "FETCH_DESCRIPTION_LIST_SUCCESS",
  FETCH_DESCRIPTION_LIST_FAILURE: "FETCH_DESCRIPTION_LIST_FAILURE",
  ADD_DESCRIPTION_SUCCESS: "ADD_DESCRIPTION_SUCCESS",
  ADD_DESCRIPTION_FAILURE: "ADD_DESCRIPTION_FAILURE",
  UPDATE_DESCRIPTION_SUCCESS: "UPDATE_DESCRIPTION_SUCCESS",
  UPDATE_DESCRIPTION_FAILURE: "UPDATE_DESCRIPTION_FAILURE",
  DELETE_DESCRIPTION_SUCCESS: "DELETE_DESCRIPTION_SUCCESS",
  DELETE_DESCRIPTION_FAILURE: "DELETE_DESCRIPTION_FAILURE",
  SET_DESCRIPTION_DATA_TO_EDIT: "SET_DESCRIPTION_DATA_TO_EDIT",
  HANDLE_CHANGE_DESCRIPTION: "HANDLE_CHANGE_DESCRIPTION",
  CLEAR_DESCRIPTION_DATA: "CLEAR_DESCRIPTION_DATA",
  DEFAULT_DESCRIPTION_REQUEST: "DEFAULT_DESCRIPTION_REQUEST",
  DEFAULT_DESCRIPTION_SUCCESS: "DEFAULT_DESCRIPTION_SUCCESS",
  DEFAULT_DESCRIPTION_FAILURE: "DEFAULT_DESCRIPTION_FAILURE",

  // VENDOR SETTING
  FETCH_VENDOR_TYPE_LIST_SUCCESS: "FETCH_VENDOR_TYPE_LIST_SUCCESS",
  FETCH_VENDOR_TYPE_LIST_FAILURE: "FETCH_VENDOR_TYPE_LIST_FAILURE",
  CHECK_VENDOR_TYPE_LIST_SUCCESS: "CHECK_VENDOR_TYPE_LIST_SUCCESS",
  CHECK_VENDOR_TYPE_LIST_FAILURE: "CHECK_VENDOR_TYPE_LIST_FAILURE",
  ADD_VENDOR_TYPE_SUCCESS: "ADD_VENDOR_TYPE_SUCCESS",
  ADD_VENDOR_TYPE_FAILURE: "ADD_VENDOR_TYPE_FAILURE",
  UPDATE_VENDOR_TYPE_SUCCESS: "UPDATE_VENDOR_TYPE_SUCCESS",
  UPDATE_VENDOR_TYPE_FAILURE: "UPDATE_VENDOR_TYPE_FAILURE",
  SET_VENDOR_TYPE_TO_EDIT: "SET_VENDOR_TYPE_TO_EDIT",
  CLEAR_VENDOR_TYPE_FORM: "CLEAR_VENDOR_TYPE_FORM",
  HANDLE_CHANGE_VENDOR_TYPE_INPUT: "HANDLE_CHANGE_VENDOR_TYPE_INPUT",

  // CHECKING ACCOUNT
  FETCH_CHECKING_ACCOUNT_SUCCESS: "FETCH_CHECKING_ACCOUNT_SUCCESS",
  FETCH_CHECKING_ACCOUNT_FAILURE: "FETCH_CHECKING_ACCOUNT_FAILURE",
  ADD_CHECKING_ACCOUNT_SUCCESS: "ADD_CHECKING_ACCOUNT_SUCCESS",
  ADD_CHECKING_ACCOUNT_FAILURE: "ADD_CHECKING_ACCOUNT_FAILURE",
  UPDATE_CHECKING_ACCOUNT_SUCCESS: "UPDATE_CHECKING_ACCOUNT_SUCCESS",
  UPDATE_CHECKING_ACCOUNT_FAILURE: "UPDATE_CHECKING_ACCOUNT_FAILURE",
  HANDLE_CHANGE_TO_OPEN_CHECKING_ACCOUNT_FORM_MODAL:
    "HANDLE_CHANGE_TO_OPEN_CHECKING_ACCOUNT_FORM_MODAL",
  HANDLE_CHANGE_CHECKING_ACCOUNT_DATA: "HANDLE_CHANGE_CHECKING_ACCOUNT_DATA",
  HANDLE_CHANGE_CHECKING_ACCOUNT_ADDRESS_DATA:
    "HANDLE_CHANGE_CHECKING_ACCOUNT_ADDRESS_DATA",
  CLEAR_CHECKING_ACCOUNT_FORM: "CLEAR_CHECKING_ACCOUNT_FORM",
  SET_CHECKING_ACCOUNT_DATA_TO_EDIT: "SET_CHECKING_ACCOUNT_DATA_TO_EDIT",
  HANDLE_CHANGE_IS_UPDATE: "HANDLE_CHANGE_IS_UPDATE",
};

export const ACTION_TYPE_CHECK_PRINT = {
  REQUEST_CHECK_PRINT: "REQUEST_CHECK_PRINT",
  CLOSE_CHECK_PRINT_SNACKBAR: "CLOSE_CHECK_PRINT_SNACKBAR",
  CLOSE_FILTER_MODAL: "CLOSE_FILTER_MODAL",

  EXPORT_CHECK_REQUEST_MADE: "EXPORT_CHECK_REQUEST_MADE",
  EXPORT_CHECK_SUCCESS: "EXPORT_CHECK_SUCCESS",
  EXPORT_CHECK_FAILURE: "EXPORT_CHECK_FAILURE",
  FETCH_CHECK_PRINT_SUCCESS: "FETCH_CHECK_PRINT_SUCCESS",
  FETCH_CHECK_PRINT_FAILURE: "FETCH_CHECK_PRINT_FAILURE",

  HANDLE_CHANGE_AMOUNT: "HANDLE_CHANGE_AMOUNT",
  HANDLE_CHANGE_SELECT_BANK: "HANDLE_CHANGE_SELECT_BANK",
  HANDLE_CHANGE_PAY_TO: "HANDLE_CHANGE_PAY_TO",
  HANDLE_CHANGE_DATE: "HANDLE_CHANGE_DATE",
  HANDLE_CHANGE_MEMO: "HANDLE_CHANGE_MEMO",
  HANDLE_CHANGE_START_DATE: "HANDLE_CHANGE_START_DATE",
  HANDLE_CHANGE_END_DATE: "HANDLE_CHANGE_END_DATE",
  HANDLE_CHANGE_CLIENT: "HANDLE_CHANGE_CLIENT",
  HANDLE_CHANGE_ATTACHEMENT: "HANDLE_CHANGE_ATTACHEMENT",
  HANDLE_CHANGE_DESCRIPTION: "HANDLE_CHANGE_DESCRIPTION",
  HANDLE_CHANGE_ADDRESS1: "HANDLE_CHANGE_ADDRESS1",
  HANDLE_CHANGE_ADDRESS2: "HANDLE_CHANGE_ADDRESS2",
  HANDLE_CHANGE_CITY: "HANDLE_CHANGE_CITY",
  HANDLE_CHANGE_STATE: "HANDLE_CHANGE_STATE",
  HANDLE_CHANGE_ZIPCODE: "HANDLE_CHANGE_ZIPCODE",

  FILTER_CHECK_PRINT_BY_DATE_SUCCESS: "FILTER_CHECK_PRINT_BY_DATE_SUCCESS",
  FILTER_CHECK_PRINT_BY_DATE_FAILURE: "FILTER_CHECK_PRINT_BY_DATE_FAILURE",

  FILTER_CHECK_PRINT_BY_BANK_SUCCESS: "FILTER_CHECK_PRINT_BY_BANK_SUCCESS",
  FILTER_CHECK_PRINT_BY_BANK_FAILURE: "FILTER_CHECK_PRINT_BY_BANK_FAILURE",

  FILTER_CHECK_PRINT_BY_AMOUNT_SUCCESS: "FILTER_CHECK_PRINT_BY_AMOUNT_SUCCESS",
  FILTER_CHECK_PRINT_BY_AMOUNT_FAILURE: "FILTER_CHECK_PRINT_BY_AMOUNT_FAILURE",

  FILTER_CHECK_PRINT_BY_BANK_AND_DATE_SUCCESS:
    "FILTER_CHECK_PRINT_BY_BANK_AND_DATE_SUCCESS",
  FILTER_CHECK_PRINT_BY_BANK_AND_DATE_FAILURE:
    "FILTER_CHECK_PRINT_BY_BANK_AND_DATE_FAILURE",

  FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_SUCCESS:
    "FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_SUCCESS",
  FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_FAILURE:
    "FILTER_CHECK_PRINT_BY_BANK_AND_AMOUNT_FAILURE",

  FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_SUCCESS:
    "FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_SUCCESS",
  FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_FAILURE:
    "FILTER_CHECK_PRINT_BY_DATE_AND_AMOUNT_FAILURE",

  FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_SUCCESS:
    "FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_SUCCESS",
  FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_FAILURE:
    "FILTER_CHECK_PRINT_BY_BANK_AND_DATE_AND_AMOUNT_FAILURE",

  PRINT_MANUAL_CHECK_SUCCESS: "PRINT_MANUAL_CHECK_SUCCESS",
  PRINT_MANUAL_CHECK_FAILURE: "PRINT_MANUAL_CHECK_FAILURE",

  FETCH_BULK_CHECK_PRINT_SUCCESS: "FETCH_BULK_CHECK_PRINT_SUCCESS",
  FETCH_BULK_CHECK_PRINT_FAILURE: "FETCH_BULK_CHECK_PRINT_FAILURE",

  PRINT_BULK_CHECK_SUCCESS: "PRINT_BULK_CHECK_SUCCESS",
  PRINT_BULK_CHECK_FAILURE: "PRINT_BULK_CHECK_FAILURE",

  HANDLE_CHANGE_DATA: "HANDLE_CHANGE_DATA",
  CLEAR_CHECKED_VALUE: "CLEAR_CHECKED_VALUE",
  SET_MANUAL_CHECK_DATA_TO_EMPTY: "SET_MANUAL_CHECK_DATA_TO_EMPTY",

  SET_CHECK_PRINT_SUCCESS: "SET_CHECK_PRINT_SUCCESS",
  SET_CHECK_PRINT_FAILURE: "SET_CHECK_PRINT_FAILURE",

  DECLINE_CHECK_SUCCESS: "DECLINE_CHECK_SUCCESS",
  DECLINE_CHECK_FAILURE: "DECLINE_CHECK_FAILURE",

  // new filter
  CHECK_PRINT_FILTER: "CHECK_PRINT_FILTER",
  CLEAR_CHECK_PRINT_FILTER: "CLEAR_CHECK_PRINT_FILTER",
  SAVE_CHECK_PRINT_CONFIG_SUCCESS: "SAVE_CHECK_PRINT_CONFIG_SUCCESS",
  SAVE_CHECK_PRINT_CONFIG_FAILURE: "SAVE_CHECK_PRINT_CONFIG_FAILURE",
  GET_CHECK_PRINT_CONFIG_SUCCESS: "GET_CHECK_PRINT_CONFIG_SUCCESS",
  GET_CHECK_PRINT_CONFIG_FAILURE: "GET_CHECK_PRINT_CONFIG_FAILURE",
};

export const ACTION_TYPE_SHIPPING_LABEL = {
  REQUEST_SHIPPING_LABEL: "REQUEST_SHIPPING_LABEL",
  CLOSE_SHIPPING_SNACKBAR: "CLOSE_SHIPPING_SNACKBAR",
  CLOSE_MODAL: "CLOSE_MODAL",

  FETCH_SHIPPING_LIST_SUCCESS: "FETCH_SHIPPING_LIST_SUCCESS",
  FETCH_SHIPPING_LIST_FAILURE: "FETCH_SHIPPING_LIST_FAILURE",

  FETCH_SHIPPING_LIST_BY_ID_SUCCESS: "FETCH_SHIPPING_LIST_BY_ID_SUCCESS",
  FETCH_SHIPPING_LIST_BY_ID_FAILURE: "FETCH_SHIPPING_LIST_BY_ID_FAILURE",

  ADD_ADDRESS_SUCCESS: "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_FAILURE: "ADD_ADDRESS_FAILURE",

  UPDATE_SHIPPING_ADDRESS_SUCCESS: "UPDATE_SHIPPING_ADDRESS_SUCCESS",
  UPDATE_SHIPPING_ADDRESS_FAILURE: "UPDATE_SHIPPING_ADDRESS_FAILURE",

  DELETE_SHIPPING_SUCCESS: "DELETE_SHIPPING_SUCCESS",
  DELETE_SHIPPING_FAILURE: "DELETE_SHIPPING_FAILURE",

  HANDLE_CHANGE_SHIPPING_VALUE: "HANDLE_CHANGE_SHIPPING_VALUE",
  SET_SHIPPING_TO_EDIT: "SET_SHIPPING_TO_EDIT",

  FETCH_SHIPPING_LABEL_SUCCESS: "FETCH_SHIPPING_LABEL_SUCCESS",
  FETCH_SHIPPING_LABEL_FAILURE: "FETCH_SHIPPING_LABEL_FAILURE",
  PRINT_SHIPPING_LABEL_SUCCESS: "PRINT_SHIPPING_LABEL_SUCCESS",
  PRINT_SHIPPING_LABEL_FAILURE: "PRINT_SHIPPING_LABEL_FAILURE",

  HANDLE_CHANGE_SHIPPING_FORM_VALUE: "HANDLE_CHANGE_SHIPPING_FORM_VALUE",

  SET_ADD_SHIPPING_ADDRESS_TO_FALSE: "SET_ADD_SHIPPING_ADDRESS_TO_FALSE",
  SET_UPDATE_SHIPPING_ADDRESS_TO_FALSE: "SET_UPDATE_SHIPPING_ADDRESS_TO_FALSE",
  SET_SHIPPING_ADDRESS_TO_EMPTY: "SET_SHIPPING_ADDRESS_TO_EMPTY",

  SET_ADD_SHIPPING_LABEL_TO_FALSE: "SET_ADD_SHIPPING_LABEL_TO_FALSE",
  // SET_UPDATE_SHIPPING_LABEL_TO_FALSE: "SET_UPDATE_SHIPPING_LABEL_TO_FALSE",
  SET_SHIPPING_LABEL_TO_EMPTY: "SET_SHIPPING_LABEL_TO_EMPTY",

  TRACK_SHIPMENT_SUCCESS: "TRACK_SHIPMENT_SUCCESS",
  TRACK_SHIPMENT_FAILURE: "TRACK_SHIPMENT_FAILURE",

  HANDLE_CLEAR_TRACK_REPORT: "HANDLE_CLEAR_TRACK_REPORT",

  SEARCH_SHIPPING_ADDRESS_SUCCESS: "SEARCH_SHIPPING_ADDRESS_SUCCESS",
  SEARCH_SHIPPING_ADDRESS_FAILURE: "SEARCH_SHIPPING_ADDRESS_FAILURE",
};

//contact
export const ACTION_TYPE_CONTACT = {
  REQUEST_CONTACT: "REQUEST_CONTACT",
  CLOSE_CONTACT_SNACKBAR: "CLOSE_CONTACT_SNACKBAR",
  CLOSE_MODAL: "CLOSE_MODAL",

  HANDLE_CHANGE_CONTACT_INPUT: "HANDLE_CHANGE_CONTACT_INPUT",

  FETCH_CONTACT_LIST_SUCCESS: "FETCH_CONTACT_LIST_SUCCESS",
  FETCH_CONTACT_LIST_FAILURE: "FETCH_CONTACT_LIST_FAILURE",

  FETCH_CONTACT_LIST_BY_ID_SUCCESS: "FETCH_CONTACT_LIST_BY_ID_SUCCESS",
  FETCH_CONTACT_LIST_BY_ID_FAILURE: "FETCH_CONTACT_LIST_BY_ID_FAILURE",

  ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
  ADD_CONTACT_FAILURE: "ADD_CONTACT_FAILURE",

  SET_CONTACT_INPUT_TO_EDIT: "SET_CONTACT_INPUT_TO_EDIT",
  UPDATE_CONTACT_SUCCESS: "UPDATE_CONTACT_SUCCESS",
  UPDATE_CONTACT_FAILURE: "UPDATE_CONTACT_FAILURE",

  DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
  DELETE_CONTACT_FAILURE: "DELETE_CONTACT_FAILURE",

  SEARCH_CONTACT_SUCCESS: "SEARCH_CONTACT_SUCCESS",
  SEARCH_CONTACT_FAILURE: "SEARCH_CONTACT_FAILURE",

  SET_CONTACT_DATA_TO_EMPTY: "SET_CONTACT_DATA_TO_EMPTY",

  //COMPANY
  HANDLE_CHANGE_COMPANY_INPUT: "HANDLE_CHANGE_COMPANY_INPUT",

  FETCH_COMPANY_LIST_SUCCESS: "FETCH_COMPANY_LIST_SUCCESS",
  FETCH_COMPANY_LIST_FAILURE: "FETCH_COMPANY_LIST_FAILURE",

  FETCH_COMPANY_LIST_BY_ID_SUCCESS: "FETCH_COMPANY_LIST_BY_ID_SUCCESS",
  FETCH_COMPANY_LIST_BY_ID_FAILURE: "FETCH_COMPANY_LIST_BY_ID_FAILURE",

  ADD_COMPANY_SUCCESS: "ADD_COMPANY_SUCCESS",
  ADD_COMPANY_FAILURE: "ADD_COMPANY_FAILURE",

  SET_COMPANY_INPUT_TO_EDIT: "SET_COMPANY_INPUT_TO_EDIT",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAILURE: "UPDATE_COMPANY_FAILURE",

  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAILURE: "DELETE_COMPANY_FAILURE",

  SEARCH_COMPANY_SUCCESS: "SEARCH_COMPANY_SUCCESS",
  SEARCH_COMPANY_FAILURE: "SEARCH_COMPANY_FAILURE",

  HANDLE_CHANGE_TERM: "HANDLE_CHANGE_TERM",

  DELETE_COMPANY_CONTACT_SUCCESS: "DELETE_COMPANY_CONTACT_SUCCESS",
  DELETE_COMPANY_CONTACT_FAILURE: "DELETE_COMPANY_CONTACT_FAILURE",

  SET_ACCOUNT_FOR_CONTACT_SUCCESS: "SET_ACCOUNT_FOR_CONTACT_SUCCESS",
  SET_ACCOUNT_FOR_CONTACT_FAILURE: "SET_ACCOUNT_FOR_CONTACT_FAILURE",

  //RELATIONSHIP
  HANDLE_CHANGE_RELATIONSHIP_NAME: "HANDLE_CHANGE_RELATIONSHIP_NAME",
  ADD_CONTACT_RELATIONSHIP_SUCCESS: "ADD_CONTACT_RELATIONSHIP_SUCCESS",
  ADD_CONTACT_RELATIONSHIP_FAILURE: "ADD_CONTACT_RELATIONSHIP_FAILURE",
  UPDATE_CONTACT_RELATIONSHIP_SUCCESS: "UPDATE_CONTACT_RELATIONSHIP_SUCCESS",
  UPDATE_CONTACT_RELATIONSHIP_FAILURE: "UPDATE_CONTACT_RELATIONSHIP_FAILURE",
  FETCH_CONTACT_RELATIONSHIP_SUCCESS: "FETCH_CONTACT_RELATIONSHIP_SUCCESS",
  FETCH_CONTACT_RELATIONSHIP_FAILURE: "FETCH_CONTACT_RELATIONSHIP_FAILURE",
  SET_RELATIONSHIP_TO_EDIT: "SET_RELATIONSHIP_TO_EDIT",

  GET_PERMISSION_FOR_RELATIONSHIP_SUCCESS:
    "GET_PERMISSION_FOR_RELATIONSHIP_SUCCESS",
  GET_PERMISSION_FOR_RELATIONSHIP_FAILURE:
    "GET_PERMISSION_FOR_RELATIONSHIP_FAILURE",

  HANDLE_CHANGE_PERMISSION_NAME: "HANDLE_CHANGE_PERMISSION_NAME",
  SET_PERMISSION_FOR_RELATIONSHIP_SUCCESS:
    "SET_PERMISSION_FOR_RELATIONSHIP_SUCCESS",
  SET_PERMISSION_FOR_RELATIONSHIP_FAILURE:
    "SET_PERMISSION_FOR_RELATIONSHIP_FAILURE",

  //CONTACT FILES
  FETCH_CONTACT_FILE_SUCCESS: "FETCH_CONTACT_FILE_SUCCESS",
  FETCH_CONTACT_FILE_FAILURE: "FETCH_CONTACT_FILE_FAILURE",

  ADD_CONTACT_FILE_SUCCESS: "ADD_CONTACT_FILE_SUCCESS",
  ADD_CONTACT_FILE_FAILURE: "ADD_CONTACT_FILE_FAILURE",

  UPDATE_CONTACT_FILE_SUCCESS: "UPDATE_CONTACT_FILE_SUCCESS",
  UPDATE_CONTACT_FILE_FAILURE: "UPDATE_CONTACT_FILE_FAILURE",

  DELETE_CONTACT_FILE_SUCCESS: "DELETE_CONTACT_FILE_SUCCESS",
  DELETE_CONTACT_FILE_FAILURE: "DELETE_CONTACT_FILE_FAILURE",

  HANDLE_CHANGE_CONTACT_FILE: "HANDLE_CHANGE_CONTACT_FILE",
  SET_CONTACT_FILE_TO_EDIT: "SET_CONTACT_FILE_TO_EDIT",

  //COMPANY TYPE
  HANDLE_CHANGE_COMPANY_TYPE_NAME: "HANDLE_CHANGE_COMPANY_TYPE_NAME",

  ADD_CONTACT_COMPANY_TYPE_SUCCESS: "ADD_CONTACT_COMPANY_TYPE_SUCCESS",
  ADD_CONTACT_COMPANY_TYPE_FAILURE: "ADD_CONTACT_COMPANY_TYPE_FAILURE",

  UPDATE_CONTACT_COMPANY_TYPE_SUCCESS: "UPDATE_CONTACT_COMPANY_TYPE_SUCCESS",
  UPDATE_CONTACT_COMPANY_TYPE_FAILURE: "UPDATE_CONTACT_COMPANY_TYPE_FAILURE",

  FETCH_CONTACT_COMPANY_TYPE_SUCCESS: "FETCH_CONTACT_COMPANY_TYPE_SUCCESS",
  FETCH_CONTACT_COMPANY_TYPE_FAILURE: "FETCH_CONTACT_COMPANY_TYPE_FAILURE",

  SEARCH_CONTACT_COMPANY_TYPE_SUCCESS: "SEARCH_CONTACT_COMPANY_TYPE_SUCCESS",
  SEARCH_CONTACT_COMPANY_TYPE_FAILURE: "SEARCH_CONTACT_COMPANY_TYPE_FAILURE",

  SET_COMPANY_TYPE_TO_EDIT: "SET_COMPANY_TYPE_TO_EDIT",
};

//DASHBOARD
export const ACTION_TYPE_DASHBOARD = {
  REQUEST_MADE_FOR_DASHBOARD: "REQUEST_MADE_FOR_DASHBOARD",
  GET_CASE_STATUS_FOR_DASHBOARD_SUCCESS:
    "GET_CASE_STATUS_FOR_DASHBOARD_SUCCESS",
  GET_CASE_STATUS_FOR_DASHBOARD_FAILURE:
    "GET_CASE_STATUS_FOR_DASHBOARD_FAILURE",
  GET_TRANSACTION_STATUS_FOR_DASHBOARD_SUCCESS:
    "GET_TRANSACTION_STATUS_FOR_DASHBOARD_SUCCESS",
  GET_TRANSACTION_STATUS_FOR_DASHBOARD_FAILURE:
    "GET_TRANSACTION_STATUS_FOR_DASHBOARD_FAILURE",
  GET_OTHER_STATUS_FOR_DASHBOARD_SUCCESS:
    "GET_OTHER_STATUS_FOR_DASHBOARD_SUCCESS",
  GET_OTHER_STATUS_FOR_DASHBOARD_FAILURE:
    "GET_OTHER_STATUS_FOR_DASHBOARD_FAILURE",
  GLOBAL_SEARCH_SUCCESS: "GLOBAL_SEARCH_SUCCESS",
  GLOBAL_SEARCH_FAILURE: "GLOBAL_SEARCH_FAILURE",
};

//EMAIL TEMPLATE
export const ACTION_TYPE_EMAIL_TEMPLATE = {
  REQUEST_MADE_IN_EMAIL_TEMPLATE: "REQUEST_MADE_IN_EMAIL_TEMPLATE",
  CLOSE_SNACK_BAR: "CLOSE_SNACK_BAR",
  SET_EMAIL_TEMPLATE_TO_EDIT: "SET_EMAIL_TEMPLATE_TO_EDIT",
  SET_ASSIGNED_EMAIL_TEMPLATE_TO_EDIT: "SET_ASSIGNED_EMAIL_TEMPLATE_TO_EDIT",
  HANDLE_CHANGE_HTML_PART: "HANDLE_CHANGE_HTML_PART",
  CLEAR_TEMPLATE_MODAL: "CLEAR_TEMPLATE_MODAL",

  POST_EMAIL_TEMPLATE_SUCCESS: "POST_EMAIL_TEMPLATE_SUCCESS",
  POST_EMAIL_TEMPLATE_FAILURE: "POST_EMAIL_TEMPLATE_FAILURE",
  UPDATE_EMAIL_TEMPLATE_SUCCESS: "UPDATE_EMAIL_TEMPLATE_SUCCESS",
  UPDATE_EMAIL_TEMPLATE_FAILURE: "UPDATE_EMAIL_TEMPLATE_FAILURE",
  FETCH_EMAIL_TEMPLATE_SUCCESS: "FETCH_EMAIL_TEMPLATE_SUCCESS",
  FETCH_EMAIL_TEMPLATE_FAILURE: "FETCH_EMAIL_TEMPLATE_FAILURE",
  DELETE_EMAIL_TEMPLATE_SUCCESS: "DELETE_EMAIL_TEMPLATE_SUCCESS",
  DELETE_EMAIL_TEMPLATE_FAILURE: "DELETE_EMAIL_TEMPLATE_FAILURE",
  HANDLE_CHANGE_EMAIL_TEMPLATE_DATA: "HANDLE_CHANGE_EMAIL_TEMPLATE_DATA",

  HANDLE_CHANGE_ASSIGN_TEMPLATE_DATA: "HANDLE_CHANGE_ASSIGN_TEMPLATE_DATA",
  ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS:
    "ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS",
  ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE:
    "ASSIGN_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE",
  UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS:
    "UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_SUCCESS",
  UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE:
    "UPDATE_EMAIL_TYPE_TO_EMAIL_TEMPLATE_FAILURE",
  FETCH_EMAIL_TYPE_SUCCESS: "FETCH_EMAIL_TYPE_SUCCESS",
  FETCH_EMAIL_TYPE_FAILURE: "FETCH_EMAIL_TYPE_FAILURE",
};

//Note implementation
export const ACTION_TYPE_NOTE = {
  REQUEST_FOR_NOTE: "REQUEST_FOR_NOTE",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
  HANDLE_CHANGE_NOTE_DESCRIPTION: "HANDLE_CHANGE_NOTE_DESCRIPTION",
  SET_NOTE_DESCRIPTION_TO_EDIT: "SET_NOTE_DESCRIPTION_TO_EDIT",
  CLEAR_NOTE_DESCRIPTION: "CLEAR_NOTE_DESCRIPTION",
  ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
  ADD_NOTE_FAILURE: "ADD_NOTE_FAILURE",
  UPDATE_NOTE_SUCCESS: "UPDATE_NOTE_SUCCESS",
  UPDATE_NOTE_FAILURE: "UPDATE_NOTE_FAILURE",
  DELETE_NOTE_SUCCESS: "DELETE_NOTE_SUCCESS",
  DELETE_NOTE_FAILURE: "DELETE_NOTE_FAILURE",
  GET_NOTE_SUCCESS: "GET_NOTE_SUCCESS",
  GET_NOTE_FAILURE: "GET_NOTE_FAILURE",
  GET_NOTE_BY_ID_SUCCESS: "GET_NOTE_BY_ID_SUCCESS",
  GET_NOTE_BY_ID_FAILURE: "GET_NOTE_BY_ID_FAILURE",
};

//DOCUMENT HUB
export const ACTION_TYPE_DOCUMENTS = {
  REQUEST_FOR_DOCUMENT: "REQUEST_FOR_DOCUMENT",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
  CLEAR_FILTER_MODAL: "CLEAR_FILTER_MODAL",

  FETCH_DOCUMENT_SUCCESS: "FETCH_DOCUMENT_SUCCESS",
  FETCH_DOCUMENT_FAILURE: "FETCH_DOCUMENT_FAILURE",

  FILTER_DOCUMENT_SUCCESS: "FILTER_DOCUMENT_SUCCESS",
  FILTER_DOCUMENT_FAILURE: "FILTER_DOCUMENT_FAILURE",
  SET_FILTER_SUCCESS_TO_FALSE: "SET_FILTER_SUCCESS_TO_FALSE",

  HANDLE_CHANGE_DOCUMENT_FILE_NAME: "HANDLE_CHANGE_DOCUMENT_FILE_NAME",
  HANDLE_CHANGE_DOCUMENT_START_DATE: "HANDLE_CHANGE_DOCUMENT_START_DATE",
  HANDLE_CHANGE_DOCUMENT_END_DATE: "HANDLE_CHANGE_DOCUMENT_END_DATE",
  HANDLE_CHANGE_DOCUMENT_TYPE: "HANDLE_CHANGE_DOCUMENT_TYPE",
};
