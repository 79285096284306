import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitleComponent from "../screens/DialogTitleComponent";
import DialogContent from "@material-ui/core/DialogContent";
import {Avatar} from "antd";
import DateConverter from "../utils/DateConverter";

const DialogueForLogInfo =  ({activityState, handleCloseSnackBar, activityLogValue, singleActionState}) => {
  return (
     <Dialog
         id='viewScheduleTransactionDetails'
         maxWidth="lg"
         open={activityState.openModal}
         onClose={handleCloseSnackBar}
     >
         <DialogTitleComponent
             id="draggable-dialog-title"
             onClose={handleCloseSnackBar}>
             Changelog Info
         </DialogTitleComponent>
         <DialogContent
             dividers
             style={{width: "726px",maxHeight: "450px", paddingTop: "25px"}}>
             {
                 activityLogValue && activityLogValue.length > 0
                     ? activityLogValue.map(logValue =>
                         <div className="changeLogOuterDiv">
                             <div>
                                 <Avatar
                                     className="changeLogAvatar"
                                     src={logValue.profilePic ? logValue.profilePic
                                         : "/assets/images/profile.svg"}/>
                             </div>
                             <div style={{marginLeft: "25px", fontSize: "16px"}}>
                                        <span style={{fontWeight: 600,}}>
                                            {logValue.userName ? logValue.userName : "User"}
                                        </span> changed {" "}
                                 <span style={{fontWeight: 600,}}>
                                            {singleActionState.fieldName}
                                        </span>  as {" "}
                                 <span>
                                            "{
                                     singleActionState.fieldType === "PHOTO"  ||
                                     singleActionState.fieldType === "DOCUMENT" ?
                                         <a href={logValue.refValue} target="_blank"
                                            style={{textDecoration: "underline"}}>{logValue.refValue}</a>
                                         : singleActionState.fieldType === "DOB" ?
                                         DateConverter(logValue.refValue) : logValue.refValue
                                 }"
                                        </span>
                                 <div style={{fontSize: "14px"}}>
                                     {new Intl.DateTimeFormat('en-US', {
                                         year: 'numeric',
                                         month: '2-digit',
                                         day: '2-digit',
                                         hour: '2-digit',
                                         minute: '2-digit',
                                         second: '2-digit'
                                     }).format(logValue.createdAt)}
                                 </div>
                             </div>
                         </div>
                     ) :
                     <div className="emptyResponse" style={{marginTop: "20px", marginBottom: "25px"}}>
                         <div>
                             <img src="/assets/images/activityLog1.svg"/>
                         </div>
                         <div>
                             No changes till date !
                         </div>
                     </div>
             }
         </DialogContent>
     </Dialog>
  )
};
export default DialogueForLogInfo;