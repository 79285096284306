import React, { Component } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import Typography from "@material-ui/core/Typography";
import {Tabs} from "antd";
import EmailTemplateTable from "./EmailTemplateTable";
import {CircularProgress, Snackbar} from "@material-ui/core";
import MySnackbarContentWrapper from "../components/Snackbar";
import EmailTypeTable from "./EmailTypeTable";
import {HelmetComponent} from "../components/Helmet";
const { TabPane } = Tabs;

class EmailTemplateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadCrumbName: "Email Template",
            tabValue: false,
            openModalToAddEmailTemplate: false,
            openModalToUpdateEmailTemplate: false,
            openPreviewModal: false,
            openModalToAddEmailType: false,
            openModalToUpdateEmailType: false,
            openModalToDeleteEmailTemplate: false,
            actionType : "",
        }
    }
    render() {
        function callback(key) {
            localStorage.setItem("tabKey", key);
        }

        const handleOpenToAddEmailTemplateModal = () => {
            this.props.clearTemplateModal()
            this.setState({
                openModalToAddEmailTemplate: true,
            })
        }
        const handleCloseToAddEmailTemplateModal = () => {
            this.setState({
                openModalToAddEmailTemplate: false,
            })
        }

        const handleOpenToEditEmailTemplateModal = () => {
            this.setState({
                openModalToUpdateEmailTemplate: true,
            })
        }
        const handleCloseToEditEmailTemplateModal = () => {
            this.setState({
                openModalToUpdateEmailTemplate: false,
            })
        }

        const handleOpenPreviewModal = () => {
            this.setState({
                openPreviewModal: true,
            })
        }
        const handleClosePreviewModal = () => {
            this.setState({
                openPreviewModal: false,
            })
        }

        const handleOpenToDeleteEmailTemplateModal = () => {
            this.setState({
                openModalToDeleteEmailTemplate: true,
            })
        }
        const handleCloseToDeleteEmailTemplateModal = () => {
            this.setState({
                openModalToDeleteEmailTemplate: false,
            })
        }

        const handleChangeEmailTemplateData = (id, value) => {
            this.props.handleChangeEmailTemplateData(id, value)
        }
        const handleChangeHtmlPart = (htmlPart) => {
            this.props.handleChangeHtmlPart(htmlPart)
        }
        const addEmailTemplate = (emailTemplateData) => {
            this.setState({
                actionType: "addEmailTemplate"
            })
            this.props.addEmailTemplate(emailTemplateData, this.props);
        }

        const updateEmailTemplate = (emailTemplateData) => {
            this.setState({
                actionType: "updateEmailTemplate"
            })
            this.props.updateEmailTemplate(emailTemplateData, this.props);
        }

        const setEmailTemplateToEdit = (templateData) =>{
            this.props.setEmailTemplateToEdit(templateData);
        }

        const deleteEmailTemplate = (emailTemplatedId) => {
            this.props.deleteEmailTemplate(emailTemplatedId, this.props)
        }



        const emailTemplateState = this.props.state.emailTemplateState && this.props.state.emailTemplateState;
        const emailTemplatesData = emailTemplateState && emailTemplateState.emailTemplatesData;
        const emailTemplateList = emailTemplateState && emailTemplateState.emailTemplateList;
        const htmlPart = emailTemplateState && emailTemplateState.htmlPart;
        const loading = emailTemplateState && emailTemplateState.loading;


        // ASSIGN EMAIL TYPE
        const handleOpenToAddEmailTypeModal = () => {
            this.props.clearTemplateModal()
            this.setState({
                openModalToAddEmailType: true,
            })
        }
        const handleCloseToAddEmailTypeModal = () => {
            this.setState({
                openModalToAddEmailType: false,
            })
        }

        const handleOpenToEditEmailTypeModal = () => {
            this.setState({
                openModalToUpdateEmailType: true,
            })
        }
        const handleCloseToEditEmailTypeModal = () => {
            this.setState({
                openModalToUpdateEmailType: false,
            })
        }

        const handleChangeAssignTemplateData = (id, value) => {
            this.props.handleChangeAssignTemplateData(id, value)
        }

        const assignEmailType= (templateTypeData) => {
            this.setState({
                actionType : "assignEmailTemplate"
            })
            this.props.assignEmailType(templateTypeData, this.props);
        }

        const updateEmailType = (templateTypeData) => {
            this.setState({
                actionType : "updateEmailTemplate"
            })
            this.props.updateEmailType(templateTypeData, this.props);
        }

        const setAssignedEmailTemplateToEdit = (templateAssign) =>{
            this.props.setAssignedEmailTemplateToEdit(templateAssign);
        }

        return (
            <React.Fragment>
                <HelmetComponent title="Email Template"/>
                {emailTemplateState && emailTemplateState.loading ? (
                    <div
                        className={
                            emailTemplateState && emailTemplateState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                {
                    emailTemplateState && emailTemplateState.successResponse === true ?
                        this.setState({
                            openModalToUpdateEmailTemplate: false,
                            openModalToAddEmailTemplate: false,
                            openModalToAddEmailType: false,
                            openModalToUpdateEmailType: false,
                        },() => {
                            if(this.state.actionType === "updateEmailTemplate"){
                                this.props.setUpdateEmailTemplateSuccessToFalse()
                            }else if(this.state.actionType === "addEmailTemplate"){
                                this.props.setAddEmailTemplateSuccessToFalse()
                            }else if(this.state.actionType === "assignEmailTemplate"){
                                this.props.setAssignEmailTemplateSuccessToFalse()
                            }else if(this.state.actionType === "updateEmailTemplate"){
                                this.props.setUpdateAssignedEmailTemplateSuccessToFalse()
                            }
                        }): null
                }
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{ color: '#8BC83F', paddingBottom: '2px' }} />Home
                    </Link>
                    <Typography color="inherit" className="link">
                        {this.state.breadCrumbName}
                    </Typography>
                </Breadcrumbs>
                <div style={{ paddingTop: '48px', margin: "0 3%"}}>
                    <Tabs defaultActiveKey={this.state.tabValue}
                          onChange={(event) => {
                              callback(event);
                            if(event === "1"){
                                this.setState({
                                    breadCrumbName: "Email Template",
                                })
                            }else if(event === "2"){
                                this.setState({
                                    breadCrumbName: "Assign Email Template",
                                })
                            }
                    }}
                    >
                        <TabPane tab="Email Template" key="1" >
                            <EmailTemplateTable
                                fetchEmailTemplate={this.props.fetchEmailTemplate}
                                handleChangeEmailTemplateData={handleChangeEmailTemplateData}
                                addEmailTemplate={addEmailTemplate}
                                updateEmailTemplate={updateEmailTemplate}
                                setEmailTemplateToEdit={setEmailTemplateToEdit}
                                deleteEmailTemplate={deleteEmailTemplate}
                                handleChangeHtmlPart={handleChangeHtmlPart}
                                emailTemplatesData={emailTemplatesData}
                                htmlPart={htmlPart}
                                loading={loading}
                                emailTemplateList={emailTemplateList}
                                clearTemplateModal={this.props.clearTemplateModal}
                                handleOpenToAddEmailTemplateModal={handleOpenToAddEmailTemplateModal}
                                handleCloseToAddEmailTemplateModal={handleCloseToAddEmailTemplateModal}
                                handleOpenToEditEmailTemplateModal={handleOpenToEditEmailTemplateModal}
                                handleCloseToEditEmailTemplateModal={handleCloseToEditEmailTemplateModal}
                                handleOpenPreviewModal={handleOpenPreviewModal}
                                handleClosePreviewModal={handleClosePreviewModal}
                                openModalToAddEmailTemplate={this.state.openModalToAddEmailTemplate}
                                openModalToUpdateEmailTemplate={this.state.openModalToUpdateEmailTemplate}
                                openPreviewModal={this.state.openPreviewModal}
                                handleOpenToDeleteEmailTemplateModal={handleOpenToDeleteEmailTemplateModal}
                                openModalToDeleteEmailTemplate={this.state.openModalToDeleteEmailTemplate}
                                handleCloseToDeleteEmailTemplateModal={handleCloseToDeleteEmailTemplateModal}
                            />
                        </TabPane>
                        <TabPane tab="Assign Email Template" key="2">
                            <EmailTypeTable
                                fetchEmailTemplate={this.props.fetchEmailTemplate}
                                loading={loading}
                                handleChangeAssignTemplateData={handleChangeAssignTemplateData}
                                handleOpenToAddEmailTypeModal={handleOpenToAddEmailTypeModal}
                                handleCloseToAddEmailTypeModal={handleCloseToAddEmailTypeModal}
                                handleOpenToEditEmailTypeModal={handleOpenToEditEmailTypeModal}
                                handleCloseToEditEmailTypeModal={handleCloseToEditEmailTypeModal}
                                openModalToAddEmailType={this.state.openModalToAddEmailType}
                                openModalToUpdateEmailType={this.state.openModalToUpdateEmailType}
                                fetchAssignedTemplate={this.props.fetchAssignedTemplate}
                                assignEmailType={assignEmailType}
                                updateEmailType={updateEmailType}
                                setEmailTemplateToEdit={setEmailTemplateToEdit}
                                setAssignedEmailTemplateToEdit={setAssignedEmailTemplateToEdit}
                                emaileTemplateState={emailTemplateState}
                            />
                        </TabPane>
                    </Tabs>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={emailTemplateState && emailTemplateState.openErrorSnackBar}
                    autoHideDuration={5000}
                    onClose={this.props.closeSnackbarForEmailTemplate}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackbarForEmailTemplate}
                        variant="error"
                        message={emailTemplateState && emailTemplateState.error}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={emailTemplateState && emailTemplateState.openSuccessSnackBar}
                    autoHideDuration={5000}
                    onClose={this.props.closeSnackbarForEmailTemplate}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackbarForEmailTemplate}
                        variant="success"
                        message={emailTemplateState && emailTemplateState.successMsg}
                    />
                </Snackbar>
            </React.Fragment>
        );
    };
};

export default EmailTemplateScreen;
