import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Button, CircularProgress, MenuItem, MenuList, Popover, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TablePagination from "@material-ui/core/TablePagination";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import PaperComponent from "./PaperComponent";
import DialogTitleComponent from "./DialogTitleComponent";
import ButtonComponent from "./ButtonComponent";
import {Checkbox} from "@material-ui/core";
import InfoPopover from "./InfoPopoverComponent";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Tag} from "antd";

class VendorTypeScreen extends Component{
    constructor(props) {
        super(props);
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            vendorType : {},
            vendorTypeName:"",
            clicked: false,
            isUpdate: false,
            isDefault: false,
        }
    }

    componentDidMount() {
        this.props.fetchVendorTypeData("");
    }

    render() {
        const settingState = this.props.settingState;
        const vendorTypeList = settingState && settingState.vendorTypeList;
        const defaultExist = settingState && settingState.defaultExist;
        const vendorTypeData = settingState && settingState.vendorTypeData;
        const value = vendorTypeData && vendorTypeData.vendorTypeName !== "";
        const loading = this.props.loading;
        return(
            <React.Fragment>
                { loading ? (
                    <div
                        className={
                            loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <div>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        isUpdate: false,
                                    })
                                    this.props.handleOpenModalToAddVendorType();
                                    this.props.checkVendorType();
                                    this.props.clearVendorTypeData();
                                }}>
                                <AddIcon/> Add Vendor Type
                            </button>
                        </div>
                        <div className="search">
                            <div className="searchIcon">
                                <SearchIcon style={{color: '#9c9c9c'}}/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                inputProps={{'aria-label': 'search'}}
                                className="inputBase"
                                onChange={(e)=>{
                                    e.preventDefault();
                                    if(e.target.value === "" || e.target.value === " " ){
                                        this.props.fetchVendorTypeData("");
                                    }else{
                                        this.props.fetchVendorTypeData(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header-second">
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Vendor Type
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {vendorTypeList && vendorTypeList.slice(
                                    this.props.page * this.props.rowsPerPage,
                                    this.props.page * this.props.rowsPerPage +
                                    this.props.rowsPerPage
                                ).map(list => (
                                    <TableBody>
                                        <TableRow >
                                            <TableCell className="first-tableBody">
                                                {list.vendorTypeName}
                                                {list.isDefault ? <Tag color="blue" style={{
                                                    marginLeft: '12px',
                                                }}>Default</Tag> : ""}
                                            </TableCell>
                                            <TableCell>
                                                <MoreHorizIcon
                                                    id="Popover1"
                                                    style={{cursor: 'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            popoverElement: e.currentTarget,
                                                            openActionMenu: true,
                                                            vendorType: list,
                                                            isDefault: list.isDefault,
                                                        })
                                                    }}
                                                />
                                                <Popover
                                                    open={this.state.openActionMenu}
                                                    anchorEl={this.state.popoverElement}
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        this.setState({openActionMenu: false})
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    style={{boxShadow: 'none'}}
                                                >
                                                    <MenuList id="popover-list" style={{
                                                        color: '#4d4d4d',
                                                        padding: '1rem !important',
                                                        cursor: 'pointer'
                                                    }}>
                                                        <MenuItem
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.handleOpenModalToAddVendorType();
                                                                this.props.checkVendorType();
                                                                this.props.setVendorTypeToEdit(this.state.vendorType)
                                                                this.setState({
                                                                    openActionMenu: false,
                                                                    isUpdate: true
                                                                });
                                                            }}>
                                                            <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                        </MenuItem>
                                                        <MenuItem
                                                            hidden={this.state.isDefault}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.handleOpenModalToDeleteVendorType();
                                                                this.props.setVendorTypeToEdit(this.state.vendorType)
                                                                this.setState({
                                                                    openActionMenu: false,
                                                                    isUpdate: true
                                                                });
                                                            }}>
                                                            <DeleteOutlinedIcon style={{paddingBottom: 0}}/> Delete
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}
                            </Table>
                            </TableContainer>
                            {vendorTypeList && vendorTypeList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)" }}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            vendorTypeList.length > 0
                                                ? vendorTypeList.length
                                                : 100
                                        }
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={this.props.handleChangePageVendorType}
                                        onChangeRowsPerPage={this.props.handleChangeRowsPerPageVendorType}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>
                {/*add vendor type*/}
                <Dialog
                    open={this.props.openModalToAddVendor}
                    onClose={this.props.handleCloseModalToAddVendorType}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleCloseModalToAddVendorType}>
                            {this.state.isUpdate ? "Update Vendor Type" : "Add Vendor Type"}
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Vendor Type</label>
                                    <input
                                        type='text'
                                        value={vendorTypeData.vendorTypeName}
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.props.handleChangeVendorTypeInput("vendorTypeName", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row" hidden={defaultExist}>
                                <div className="col-12">
                                   <Checkbox
                                       style={{paddingLeft: '0px', paddingRight: '5px'}}
                                       onChange={(e) => {
                                           e.preventDefault();
                                           this.setState({isBlocking: true});
                                           this.props.handleChangeVendorTypeInput('isDefault', e.target.checked);
                                       }}
                                   />
                                    <span>
                                        <span style={{fontSize: '15px'}}>Default</span>
                                          <InfoPopover
                                              content={
                                                  <div>
                                                      Default type for Everfund vendor.
                                                  </div>}
                                          />
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        onClick={e => {
                                            e.preventDefault();
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                const vendorReqBody = {
                                                    vendorTypeName: vendorTypeData.vendorTypeName,
                                                }
                                                if(vendorTypeData.isDefault){
                                                    vendorReqBody.isDefault = true
                                                }
                                                if(this.state.isUpdate){
                                                    vendorReqBody.vendorTypeId = vendorTypeData.vendorTypeId;
                                                    this.props.updateVendorType(vendorReqBody);
                                                }else{
                                                    this.props.addVendorType(vendorReqBody);
                                                }
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        value={value}
                                        buttonName={this.state.isUpdate ? "Update" : "Add"}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*Delete vendor type*/}
                <Dialog
                    open={this.props.openModalToDeleteVendor}
                    onClose={this.props.handleCloseModalToDeleteVendorType}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title"  style={{cursor: "move"}}>
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation</h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px'
                        }}>
                        <DialogContentText>
                            Do you want to delete this vendor type?

                            <span className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '0.5rem',
                                paddingTop: '1.6rem'
                            }}>
                                <span className={"col-6"}>
                                    <Button autoFocus
                                            onClick={this.props.handleCloseModalToDeleteVendorType}
                                            className="cancelButton">
                                        cancel
                                    </Button>
                                </span>

                                <span className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        this.props.handleCloseModalToDeleteVendorType();
                                        const vendorReqBody = {
                                            vendorTypeName: vendorTypeData.vendorTypeName,
                                            vendorTypeId: vendorTypeData.vendorTypeId,
                                            isDeleted: true,
                                        }
                                        this.props.updateVendorType(vendorReqBody);
                                    }}
                                            className="deleteOkButton">
                                        ok
                                    </Button>
                                </span>
                            </span>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
};
const style = {
    card: {
        background: "#ffffff",
        border: "1px solid rgb(224, 224, 224)",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginLeft: 0,
        marginBottom: "1rem",
    },
}

export default VendorTypeScreen;