import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  MenuItem,
  MenuList,
  Popover,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import TablePagination from "@material-ui/core/TablePagination";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Select, Tabs } from "antd";
// import moment from "moment";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import DialogTitleComponent from "./DialogTitleComponent";
import { HelmetComponent } from "../components/Helmet";
import PaperComponent from "./PaperComponent";
import ExportButton from "../components/ExportButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import NotesPopover from "../components/NotesPopover";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ButtonComponent from "./ButtonComponent";
import KeyBoardDatePickerComponent from "./DatePicker/DatePickerComponent";
import { getFormattedDateValue } from "../utils/DateConverter";
import SelectiveDateForFilter from "./scheduleTransaction/SelectiveDateForFilter";
import TransactionTag from "./Transaction/TransactionTag";
import {
  descendingComparatorTrans,
  getRangeType,
  showGreaterThanSign,
  transactionStatusHelper,
} from "./TransactionHelper";
import { allTypeArray, FilterStatusData } from "../components/FilterOptionList";
import MySnackbarContentWrapper from "../components/Snackbar";

const { Option } = Select;
const { TabPane } = Tabs;

class IndividualClientLedgerTxnScreen extends Component {
  constructor(props) {
    super(props);
    var pageURL = window.location.href;
    this.tableRef = React.createRef();
    this.state = {
      page: 0,
      rowsPerPage: 10,
      setValue: "",
      clientId: pageURL.substr(pageURL.lastIndexOf("/") + 1),
      openLedgerTransactionFilterModal: false,
      openActionMenu: false,
      popoverElement: null,
      selectedStatus: "",
      order: "desc",
      orderBy: "transactionTimestamp",
      openConfigMenu: false,
      OpenSaveFilterInConfigmenu: false,
      OpenViewFilterInConfig: false,
      configName: "",
      openRangeBar: false,
    };
  }

  componentDidMount() {
    this.props.fetchCleintLedgerTransactionList(
      this.state.clientId,
      0,
      0,
      "UNKNOWN_TRANSACTION_STATUS",
      "UNKNOWN_TRANSACTION_TYPE",
      ""
    );
    this.props.fetchSingleClient(this.state.clientId);
    this.props.fetchDescriptionList();
    this.props.clearTransactionFilter();
  }

  createSortHandler = (property) => (event) => {
    this.onRequestSort(event, property);
  };
  onRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    if (isAsc) {
      this.setState({
        order: "desc",
      });
    } else {
      this.setState({
        order: "asc",
      });
    }
    this.setState({
      orderBy: property,
    });
  };

  render() {
    const transactionState = this.props && this.props.transactionState;
    const clientLedgerTransaction =
      transactionState && transactionState.clientLedgerTransaction;
    const totalAmount = transactionState && transactionState.totalAmount;
    const totalTransaction =
      transactionState && transactionState.totalTransaction;
    const loading = transactionState && transactionState.loading;
    const startDate = transactionState && transactionState.from;
    const endDate = transactionState && transactionState.to;
    const status = transactionState && transactionState.transactionStatus;
    const transactionType = transactionState && transactionState.transType;
    const clientState = this.props && this.props.clientState;
    const clientData = clientState && clientState.clientData;

    const settingState = this.props && this.props.settingState;
    const descriptionList = settingState && settingState.descriptionList;

    // new filter states
    const transDate = transactionState && transactionState.filterDate;
    const desc = transactionState && transactionState.filterDesc;
    const amount = transactionState && transactionState.filterAmount;
    const checkNo = transactionState && transactionState.filterCheckNo;
    const user = transactionState && transactionState.filterUser;
    const type = transactionState && transactionState.filterType;
    const stat = transactionState && transactionState.filtetStatus;
    const vendor = transactionState && transactionState.filterVendor;
    const medium = transactionState && transactionState.filterMedium;
    const configlist = transactionState && transactionState.configlist;
    const compareType = transactionState && transactionState.compareType;

    const handleChangePage = (event, newPage) => {
      this.setState({
        page: newPage,
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
      });
      if (this.tableRef.current) {
        this.tableRef.current.scrollIntoView();
      }
    };
    const handleOpenLedgerTransactionFilterModal = () => {
      this.setState(
        {
          openLedgerTransactionFilterModal: true,
        },
        () => {
          this.props.handleChangeDateFrom(null);
          this.props.handleChangeDateTo(null);
          this.props.handleChangeStatus("UNKNOWN_TRANSACTION_STATUS");
          this.props.handleChangeTransType("UNKNOWN_TRANSACTION_TYPE");
        }
      );
    };
    const handleCloseLedgerTransactionFilterModal = () => {
      this.setState({
        openLedgerTransactionFilterModal: false,
      });
    };
    const filteredLedgerValue =
      startDate !== 0 ||
      endDate !== 0 ||
      status !== "UNKNOWN_TRANSACTION_STATUS" ||
      transactionType !== "UNKNOWN_TRANSACTION_TYPE";
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    // const getPickerDate = (dateToConvert) => {
    //   let dateConverted = new Date(parseInt(dateToConvert)).toLocaleDateString(
    //     "fr-CA"
    //   );
    //   return moment(dateConverted, "YYYY-MM-DD");
    // };

    const clientNameList =
      clientLedgerTransaction &&
      clientLedgerTransaction.map((name) =>
        name.Client.lastName === undefined
          ? name.Client.firstName
          : name.Client.firstName + " " + name.Client.lastName
      );

    const vendorName =
      clientLedgerTransaction &&
      clientLedgerTransaction[0] &&
      clientLedgerTransaction[0].vendorAccount.vendorName;

    const clientName =
      clientNameList && clientNameList[0]
        ? clientNameList[0]
        : clientData
        ? clientData.firstName + " " + clientData.lastName
        : "";

    const openLedgerTxnUpdateModal =
      transactionState && transactionState.openLedgerTxnUpdateModal;
    const setOpenLedgerTxnUpdate = (bool) => {
      this.props.setOpenLedgerTxnUpdate(bool);
    };
    const transStatus = transactionState && transactionState.transStatus;
    const declineRemark = transactionState && transactionState.declineRemark;
    const handleUpdateLedgerStatus = () => {
      const updateStausData = {
        transaction: [
          {
            transactionId: this.state.transactionId,
            transactionStatus: transStatus,
            remark: declineRemark,
          },
        ],
      };
      this.props.updateLedgerTransaction(
        updateStausData,
        this.state.clientId,
        this.props
      );
    };
    const handleChangeCloseConfigModal = () => {
      this.setState({
        OpenSaveFilterInConfigmenu: false,
        configName: "",
      });
    };
    const handleChangeCloseViewConfigModal = () => {
      this.setState({
        OpenViewFilterInConfig: false,
      });
    };

    const filterStatus =
      transDate !== null ||
      desc !== "" ||
      amount !== "" ||
      checkNo !== "" ||
      user !== "" ||
      medium !== "" ||
      type !== "" ||
      stat !== "" ||
      vendor !== "";

    const configStatus = this.state.configName !== "" && filterStatus;

    function descendingComparator(a, b, orderBy) {
      if (orderBy === "balance") {
        let Abalance = a[orderBy] ? a[orderBy] : "0";
        let Bbalance = b[orderBy] ? b[orderBy] : "0";

        return Abalance - Bbalance;
      } else if (orderBy === "refId") {
        return a[orderBy]
          .toString()
          .localeCompare(b[orderBy].toString(), "eng", {
            numeric: true,
          });
      } else if (orderBy === "firstName") {
        let first = a.Client && a.Client[orderBy] ? a.Client[orderBy] : "z";
        let second = b.Client && b.Client[orderBy] ? b.Client[orderBy] : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else if (orderBy === "vendorName") {
        let first =
          a.vendorAccount && a.vendorAccount[orderBy]
            ? a.vendorAccount[orderBy]
            : "z";
        let second =
          b.vendorAccount && b.vendorAccount[orderBy]
            ? b.vendorAccount[orderBy]
            : "z";
        if (second < first) {
          return -1;
        }
        if (second > first) {
          return 1;
        }
        return null;
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return null;
      }
    }

    function getComparator(transactionList, order, orderBy) {
      if (order === "asc") {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => -descendingComparator(a, b, orderBy));

        return list;
      } else {
        const list =
          transactionList &&
          transactionList.length > 0 &&
          transactionList.sort((a, b) => descendingComparator(a, b, orderBy));

        return list;
      }
    }
    const sortedClientLedTransactionList = getComparator(
      clientLedgerTransaction,
      this.state.order,
      this.state.orderBy
    );

    const handleFilterSaveConfig = () => {
      const data = {
        configName: this.state.configName,
        description: desc,
        balance: amount,
        checkNo: checkNo,
        userName: user,
        vendorName: vendor,
        transactionMedium: medium,
        transactionType: type,
        transactionStatus: stat,
        transactionDate: transDate === null ? "" : transDate,
        configType: 10,
        compareType: compareType,
        sortBy: this.state.order === "asc" ? "ASC" : "DESC",
      };
      this.props.fetchCleintLedgerTransactionList(
        this.state.clientId,
        0,
        0,
        "UNKNOWN_TRANSACTION_STATUS",
        "UNKNOWN_TRANSACTION_TYPE",
        ""
      );
      this.props.saveInConfig(data);
      this.props.clearTransactionFilter();
    };

    const handleUseConfigFilter = (config) => {
      this.props.clearTransactionFilter();
      if (config.transactionFilter.balance) {
        this.props.handleTransactionFilter(
          "filterAmount",
          config.transactionFilter.balance
        );
      }
      if (config.transactionFilter.checkNo) {
        this.props.handleTransactionFilter(
          "filterCheckNo",
          config.transactionFilter.checkNo
        );
      }
      if (config.transactionFilter.transactionMedium) {
        this.props.handleTransactionFilter(
          "filterMedium",
          config.transactionFilter.transactionMedium
        );
      }
      if (config.transactionFilter.description) {
        this.props.handleTransactionFilter(
          "filterDesc",
          config.transactionFilter.description
        );
      }
      if (config.transactionFilter.transactionDate !== "0") {
        this.props.handleTransactionFilter(
          "filterDate",
          config.transactionFilter.transactionDate
        );
      }
      if (config.transactionFilter.transactionStatus) {
        this.props.handleTransactionFilter(
          "filtetStatus",
          config.transactionFilter.transactionStatus
        );
      }
      if (config.transactionFilter.transactionType) {
        this.props.handleTransactionFilter(
          "filterType",
          config.transactionFilter.transactionType
        );
      }
      if (config.transactionFilter.userName) {
        this.props.handleTransactionFilter(
          "filterUser",
          config.transactionFilter.userName
        );
      }
      if (config.transactionFilter.vendorName) {
        this.props.handleTransactionFilter(
          "filterVendor",
          config.transactionFilter.vendorName
        );
      }
      if (config.transactionFilter.comparator) {
        this.props.handleTransactionFilter(
          "compareType",
          getRangeType(config.transactionFilter.comparator)
        );
      }

      this.props.filterLedgerClientByAll(
        this.state.clientId,
        config.transactionFilter.description
          ? config.transactionFilter.description
          : "",
        config.transactionFilter.checkNo
          ? config.transactionFilter.checkNo
          : "",
        config.transactionFilter.userName
          ? config.transactionFilter.userName
          : "",
        config.transactionFilter.vendorName
          ? config.transactionFilter.vendorName
          : "",
        config.transactionFilter.transactionMedium
          ? config.transactionFilter.transactionMedium
          : "",
        config.transactionFilter.transactionType
          ? config.transactionFilter.transactionType
          : "",
        config.transactionFilter.transactionStatus
          ? transactionStatusHelper([
              config.transactionFilter.transactionStatus,
            ])
          : "",
        config.transactionFilter.transactionDate
          ? config.transactionFilter.transactionDate
          : "0",
        config.transactionFilter.balance
          ? config.transactionFilter.balance
          : "",
        config.transactionFilter.comparator
          ? getRangeType(config.transactionFilter.comparator)
          : "0"
      );
    };
    return (
      <React.Fragment>
        <HelmetComponent title="Ledger Transaction" />
        {loading ? (
          <div className={loading ? "loader" : "loader hidden"} id="loader">
            <CircularProgress style={{ color: "#8BC83F" }} />
          </div>
        ) : null}
        <Breadcrumbs
          aria-label="breadcrumb"
          className="breadcrumb"
          style={style.breadcrumb}
        >
          <Link
            color="inherit"
            to="/dashboard"
            className="link"
            style={style.link}
          >
            <HomeIcon style={{ color: "#8BC83F", paddingBottom: "2px" }} />
            Home
          </Link>
          <Link
            color="inherit"
            to="/transaction/ledger"
            className="link"
            style={style.link}
            onClick={() => {
              localStorage.setItem("transTabKey", "ledgerTransaction");
            }}
          >
            Ledger Transaction
          </Link>
          <Typography color="inherit" className="link">
            {clientName !== " " ? clientName : vendorName}
          </Typography>
        </Breadcrumbs>

        <div
          style={{
            paddingTop: "58px",
            margin: "0 3%",
          }}
        >
          <Tabs
            defaultActiveKey="ledger"
            onChange={(key) => {
              this.props.history.push(`/transaction/${key}`);
            }}
            className="fixed-tab"
          >
            <TabPane tab="Transactions" key="list" />
            <TabPane tab="Schedule Transaction" key="schedule" />
            <TabPane tab="Upcoming Transaction" key="upcoming" />
            <TabPane tab="Annual Transaction" key="annual" />
            <TabPane tab="Ledger Transaction" key="ledger" />
            <TabPane tab="Deleted Transaction" key="delete" />
          </Tabs>
        </div>
        <div style={style.container}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ marginBottom: "8px" }}>
                <span style={{ marginRight: "4px", fontSize: "16px" }}>
                  Total Balance :
                </span>
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {totalAmount ? formatter.format(totalAmount / 100) : "$0.00"}
                </span>
              </div>
              <div style={{ marginBottom: "18px" }}>
                <span style={{ marginRight: "4px", fontSize: "16px" }}>
                  Total Transaction :
                </span>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {totalTransaction ? totalTransaction : "0"}
                </span>
              </div>
            </div>
            <div className={"d-flex flex-row-reverse"} style={style.flex}>
              {/*refresh*/}
              <div>
                <button
                  className="filterButton"
                  onClick={() => {
                    this.props.fetchCleintLedgerTransactionList(
                      this.state.clientId,
                      0,
                      0,
                      "UNKNOWN_TRANSACTION_STATUS",
                      "UNKNOWN_TRANSACTION_TYPE",
                      ""
                    );
                    this.props.clearTransactionFilter();
                  }}
                >
                  <RefreshIcon style={{ color: "#fff", marginTop: "-2px" }} />
                </button>
              </div>
              {/*filter*/}
              <div style={{ display: "flex" }}>
                <button
                  className="filterButton"
                  onClick={handleOpenLedgerTransactionFilterModal}
                >
                  <FilterListIcon
                    style={{ color: "#fff", marginTop: "-2px" }}
                  />
                </button>
              </div>
              {/*export*/}
              <div>
                {filterStatus ? (
                  <ExportButton
                    onClickPDF={(e) => {
                      e.preventDefault();
                      this.props.exportClientLedgerTransactionListByColumnFilter(
                        this.state.clientId,
                        desc,
                        checkNo,
                        user,
                        vendor,
                        medium,
                        type,
                        stat,
                        transDate,
                        amount,
                        compareType,
                        "PDF_EXPORT"
                      );
                    }}
                    onClickCSV={(e) => {
                      e.preventDefault();
                      this.props.exportClientLedgerTransactionListByColumnFilter(
                        this.state.clientId,
                        desc,
                        checkNo,
                        user,
                        vendor,
                        medium,
                        type,
                        stat,
                        transDate,
                        amount,
                        compareType,
                        "CSV_EXPORT"
                      );
                    }}
                  />
                ) : (
                  <ExportButton
                    onClickPDF={(e) => {
                      e.preventDefault();
                      this.props.exportClientLedgerTransactionList(
                        this.state.clientId,
                        startDate,
                        endDate,
                        status,
                        transactionType,
                        "",
                        "PDF_EXPORT"
                      );
                    }}
                    onClickCSV={(e) => {
                      e.preventDefault();
                      this.props.exportClientLedgerTransactionList(
                        this.state.clientId,
                        startDate,
                        endDate,
                        status,
                        transactionType,
                        "",
                        "CSV_EXPORT"
                      );
                    }}
                  />
                )}
              </div>
              <div className="search">
                <div style={style.searchIcon}>
                  <SearchIcon style={{ color: "#9c9c9c" }} />
                </div>
                <InputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  style={style.inputBase}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      pageLedgerTrans: 0,
                    });
                    this.props.fetchCleintLedgerTransactionList(
                      this.state.clientId,
                      0,
                      0,
                      "UNKNOWN_TRANSACTION_STATUS",
                      "UNKNOWN_TRANSACTION_TYPE",
                      e.target.value
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className={"row"} style={style.card}>
            <TableContainer className="fixed-table-header">
              <Table
                size="small"
                stickyHeader
                aria-label="sticky table"
                ref={this.tableRef}
              >
                <TableHead style={{ padding: "1rem" }}>
                  <TableRow>
                    <TableCell className="tableHeader">#</TableCell>
                    <TableCell
                      className="first-tableHeader"
                      key="transactionTimestamp"
                      sortDirection={
                        this.state.orderBy === "transactionTimestamp"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionTimestamp"}
                        direction={
                          this.state.orderBy === "transactionTimestamp"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionTimestamp")}
                      >
                        Transaction Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="description"
                      sortDirection={
                        this.state.orderBy === "description"
                          ? this.state.order
                          : false
                      }
                    >
                      <TableSortLabel
                        active={this.state.orderBy === "description"}
                        direction={
                          this.state.orderBy === "description"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("description")}
                      >
                        Description
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className="tableHeaderAmount">Debits</TableCell>
                    <TableCell className="tableHeaderAmount">Credits</TableCell>
                    <TableCell
                      className="tableHeader"
                      key="balance"
                      sortDirection={
                        this.state.orderBy === "balance"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "balance"}
                        direction={
                          this.state.orderBy === "balance"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("balance")}
                      >
                        Balance
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeaderRefId"
                      key="refId"
                      sortDirection={
                        this.state.orderBy === "refId"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "refId"}
                        direction={
                          this.state.orderBy === "refId"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("refId")}
                      >
                        Ref / Check No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionType"
                      sortDirection={
                        this.state.orderBy === "transactionType"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionType"}
                        direction={
                          this.state.orderBy === "transactionType"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionType")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className="tableHeader"
                      key="transactionStatus"
                      sortDirection={
                        this.state.orderBy === "transactionStatus"
                          ? this.state.order
                          : false
                      }
                    >
                      {" "}
                      <TableSortLabel
                        active={this.state.orderBy === "transactionStatus"}
                        direction={
                          this.state.orderBy === "transactionStatus"
                            ? this.state.order
                            : "asc"
                        }
                        className="override-mui-table"
                        onClick={this.createSortHandler("transactionStatus")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="sticky-search-bar">
                    <TableCell className="first-tableBody" />
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={transDate}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterDate",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.filterLedgerClientByAll(
                              this.state.clientId,
                              desc,
                              checkNo,
                              user,
                              vendor,
                              medium,
                              type,
                              stat,
                              e.target.value,
                              amount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={desc}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter("filterDesc", e);
                            this.setState({
                              page: 0,
                            });
                            this.props.filterLedgerClientByAll(
                              this.state.clientId,
                              e,
                              checkNo,
                              user,
                              vendor,
                              medium,
                              type,
                              stat,
                              transDate,
                              amount,
                              compareType
                            );
                          }}
                        >
                          {descriptionList &&
                            descriptionList.map((list) => (
                              <Option value={list.description}>
                                {list.description}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell className="tableBody">
                      <div
                        className="sm-search"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span className="show-range-sign">
                          {showGreaterThanSign(compareType)}
                        </span>
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={amount}
                          className="show-range"
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterAmount",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.filterLedgerClientByAll(
                              this.state.clientId,
                              desc,
                              checkNo,
                              user,
                              vendor,
                              medium,
                              type,
                              stat,
                              transDate,
                              e.target.value,
                              compareType
                            );
                          }}
                        />
                        <ExpandMoreIcon
                          id="Popover1"
                          className="expand-more-icon"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            this.setState({
                              popoverElement: e.currentTarget,
                              // client: client.client,
                              openRangeBar: true,
                            });
                          }}
                        />
                        <Popover
                          open={this.state.openRangeBar}
                          anchorEl={this.state.popoverElement}
                          onClose={(e) => {
                            e.preventDefault();
                            this.setState({ openRangeBar: false });
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          style={{ boxShadow: "none" }}
                        >
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                  page: 0,
                                });
                                this.props.handleTransactionFilter(
                                  "compareType",
                                  "2"
                                );
                                this.props.filterLedgerClientByAll(
                                  this.state.clientId,
                                  desc,
                                  checkNo,
                                  user,
                                  vendor,
                                  medium,
                                  type,
                                  stat,
                                  transDate,
                                  amount,
                                  "2"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Lesser than {`(<)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();

                                this.setState({
                                  openRangeBar: false,
                                  page: 0,
                                });
                                this.props.handleTransactionFilter(
                                  "compareType",
                                  "1"
                                );
                                this.props.filterLedgerClientByAll(
                                  this.state.clientId,
                                  desc,
                                  checkNo,
                                  user,
                                  vendor,
                                  medium,
                                  type,
                                  stat,
                                  transDate,
                                  amount,
                                  "1"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>
                                Greater than {`(>)`}
                              </span>
                            </span>
                          </MenuList>
                          <MenuList
                            style={{
                              color: "#4d4d4d",
                              padding: "1rem !important",
                              cursor: "pointer",
                            }}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  openRangeBar: false,
                                  page: 0,
                                });
                                this.props.handleTransactionFilter(
                                  "compareType",
                                  "0"
                                );
                                this.props.filterLedgerClientByAll(
                                  this.state.clientId,
                                  desc,
                                  checkNo,
                                  user,
                                  vendor,
                                  medium,
                                  type,
                                  stat,
                                  transDate,
                                  amount,
                                  "0"
                                );
                              }}
                              className="iconImage"
                            >
                              <span style={{ marginLeft: "6px" }}>None</span>
                            </span>
                          </MenuList>
                        </Popover>
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <InputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                          value={checkNo}
                          onChange={(e) => {
                            e.preventDefault();
                            this.props.handleTransactionFilter(
                              "filterCheckNo",
                              e.target.value
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.filterLedgerClientByAll(
                              this.state.clientId,
                              desc,
                              e.target.value,
                              user,
                              vendor,
                              medium,
                              type,
                              stat,
                              transDate,
                              amount,
                              compareType
                            );
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={type}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter("filterType", e);
                            this.setState({
                              page: 0,
                            });
                            this.props.filterLedgerClientByAll(
                              this.state.clientId,
                              desc,
                              checkNo,
                              user,
                              vendor,
                              medium,
                              e,
                              stat,
                              transDate,
                              amount,
                              compareType
                            );
                          }}
                        >
                          {allTypeArray &&
                            allTypeArray.map((list) => (
                              <Option value={list.val}>{list.name}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>

                    <TableCell className="tableBody">
                      <div className="sm-search">
                        <Select
                          className="dropdown"
                          dropdownStyle={{
                            maxHeight: "auto",
                            overflowY: "scroll",
                          }}
                          placeholder="Search…"
                          value={stat}
                          getPopupContainer={(node) => node.parentNode}
                          onChange={(e) => {
                            this.props.handleTransactionFilter(
                              "filtetStatus",
                              e
                            );
                            this.setState({
                              page: 0,
                            });
                            this.props.filterLedgerClientByAll(
                              this.state.clientId,
                              desc,
                              checkNo,
                              user,
                              vendor,
                              medium,
                              type,
                              transactionStatusHelper([e]),
                              transDate,
                              amount,
                              compareType
                            );
                          }}
                        >
                          {FilterStatusData &&
                            FilterStatusData.map((list) => (
                              <Option value={list.name}>{list.name}</Option>
                            ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell style={{ paddingLeft: "0", cursor: "pointer" }}>
                      <MoreHorizIcon
                        id="Popover1"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          this.setState({
                            popoverElement: e.currentTarget,

                            openConfigMenu: true,
                          });
                        }}
                      />

                      <Popover
                        open={this.state.openConfigMenu}
                        anchorEl={this.state.popoverElement}
                        onClose={(e) => {
                          e.preventDefault();
                          this.setState({ openConfigMenu: false });
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        style={{ boxShadow: "none" }}
                      >
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                OpenSaveFilterInConfigmenu: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              Save In Config
                            </span>
                          </span>
                        </MenuList>
                        <MenuList
                          style={{
                            color: "#4d4d4d",
                            padding: "1rem !important",
                            cursor: "pointer",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              e.preventDefault();

                              this.props.getConfigList("10");
                              this.setState({
                                OpenViewFilterInConfig: true,
                                openConfigMenu: false,
                              });
                            }}
                            className="iconImage"
                          >
                            <span style={{ marginLeft: "6px" }}>
                              View Config
                            </span>
                          </span>
                        </MenuList>
                      </Popover>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {sortedClientLedTransactionList &&
                  sortedClientLedTransactionList
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    )
                    .map((list, index) => (
                      <TableBody key={list.transactionId}>
                        <TableRow
                          style={
                            list.transactionStatus === "TRANSACTION_ON_HOLD"
                              ? { background: "#fff1f0" }
                              : null
                          }
                        >
                          <TableCell>
                            {" "}
                            {(this.state.page + 1 - 1) *
                              this.state.rowsPerPage +
                              (index + 1)}
                          </TableCell>
                          <TableCell className="first-tableBody">
                            {new Intl.DateTimeFormat("default", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(
                              list.transactionTimestamp
                                ? list.transactionTimestamp
                                : list.createdAt
                            )}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.description ? list.description : "-"}
                          </TableCell>
                          <TableCell className="tableAmountBody">
                            {list.amount
                              ? list.amount && list.amount.charAt(0) === "-"
                                ? formatter
                                    .format(list.amount / 100)
                                    .substring(1)
                                : "-"
                              : formatter.format(0)}
                          </TableCell>
                          <TableCell className="tableAmountBody">
                            {list.amount
                              ? list.amount.charAt(0) === "-"
                                ? "-"
                                : formatter.format(list.amount / 100)
                              : formatter.format(0)}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.balance
                              ? formatter.format(list.balance / 100)
                              : formatter.format(0)}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              height: "4.5rem",
                              paddingLeft: "47px",
                              paddingRight: 0,
                            }}
                          >
                            {list.refId}
                          </TableCell>
                          <TableCell className="tableBody">
                            {list.transactionType ? list.transactionType : "-"}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "15px",
                              color: "#626262",
                              textAlign: "left",
                              paddingLeft: "18px",
                              height: "4.5rem",
                              cursor: "auto",
                            }}
                          >
                            <>
                              <TransactionTag list={list} />
                              {list.remark !== undefined ? (
                                <NotesPopover content={list.remark} isRemark />
                              ) : null}
                            </>
                          </TableCell>
                          <TableCell>
                            {list.transactionStatus ===
                              "TRANSACTION_SUBMITTED" ||
                            list.transactionStatus ===
                              "TRANSACTION_APPROVED" ? (
                              <div>
                                <>
                                  <MoreHorizIcon
                                    id="Popover1"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        popoverElement: e.currentTarget,
                                        openActionMenu: true,
                                        transactionId: list.transactionId,
                                        selectedStatus: list.transactionStatus,
                                      });
                                    }}
                                  />
                                  <Popover
                                    open={this.state.openActionMenu}
                                    anchorEl={this.state.popoverElement}
                                    onClose={(e) => {
                                      e.preventDefault();
                                      this.setState({ openActionMenu: false });
                                    }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    style={{ boxShadow: "none" }}
                                  >
                                    <MenuList
                                      id="popover-list"
                                      style={{
                                        color: "#4d4d4d",
                                        padding: "1rem !important",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          this.setState({
                                            openActionMenu: false,
                                            setValue: "changeStatus",
                                          });
                                          setOpenLedgerTxnUpdate(true);
                                        }}
                                      >
                                        Update Status
                                      </MenuItem>
                                    </MenuList>
                                  </Popover>
                                </>
                              </div>
                            ) : (
                              <div />
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
              </Table>
            </TableContainer>
            {sortedClientLedTransactionList &&
            sortedClientLedTransactionList.length > 0 ? (
              <div
                style={{
                  justifyContent: "flex-start",
                  width: "100%",
                  borderTop: "0.1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <TablePagination
                  labelRowsPerPage={false}
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={
                    sortedClientLedTransactionList.length > 0
                      ? sortedClientLedTransactionList.length
                      : 100
                  }
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{
                    "aria-label": "previous page",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page",
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "1rem",
                    color: "#606060",
                  }}
                >
                  {" "}
                  No data to show
                </h3>
              </div>
            )}
            {/* </TableContainer> */}
          </div>
        </div>
        {/****** FILTER FOR LEDGER TRANSACTION *******/}
        <Dialog
          id="viewScheduleTransactionDetails"
          open={this.state.openLedgerTransactionFilterModal}
          maxWidth="lg"
          onClose={handleCloseLedgerTransactionFilterModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleCloseLedgerTransactionFilterModal}
          >
            Filter
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              paddingLeft: "1.5rem !important",
              paddingRight: "1.5rem !important",
              width: "450px",
              paddingTop: "5px",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <label style={style.label}>Start Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={startDate && getFormattedDateValue(startDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeDateFrom(
                        new Date(data).setHours(0, 0, 0)
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <label style={style.label}>End Date</label>
                <div className="select-input" style={style.searchDate}>
                  <KeyBoardDatePickerComponent
                    dateValue={endDate && getFormattedDateValue(endDate)}
                    isFilterFiled
                    onChangeDate={(data) => {
                      this.props.handleChangeDateTo(
                        new Date(data).setHours(23, 29, 29)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <SelectiveDateForFilter
              handleChangeFromDate={(date) => {
                this.props.handleChangeDateFrom(date);
              }}
              handleChangeToDate={(date) => {
                this.props.handleChangeDateTo(date);
              }}
            />
            <label style={style.label}> Status </label>
            <div className="select-input h40">
              <Select
                className="inputField"
                showSearch={false}
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  this.props.handleChangeStatus(e);
                }}
              >
                <Option value="TRANSACTION_CREATED">Created</Option>
                <Option value="TRANSACTION_APPROVED">Approved</Option>
                <Option value="TRANSACTION_SUBMITTED">Submitted</Option>
                <Option value="TRANSACTION_POSTED">Posted</Option>
                <Option value="TRANSACTION_DECLINED">Declined</Option>
                <Option value="TRANSACTION_SKIPPED">Skipped</Option>
                <Option value="TRANSACTION_ON_HOLD">On Hold</Option>
              </Select>
            </div>
            <label style={style.label}> Type </label>
            <div className="select-input h40">
              <Select
                className="inputField"
                showSearch={false}
                getPopupContainer={(node) => node.parentNode}
                onChange={(e) => {
                  this.props.handleChangeTransType(e);
                }}
              >
                <Option value="FEE">Fee</Option>
                <Option value="ANNUAL_FEE">Annual Fee</Option>
                <Option value="REFUND">Refund</Option>
                <Option value="WITHDRAWAL">Withdrawal</Option>
              </Select>
            </div>
            <div>
              <div className="row">
                <div className="col-6" />
                <div className="col-6" style={{ paddingTop: "15px" }}>
                  <Button
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        pageLedgerTrans: 0,
                      });
                      this.props.fetchCleintLedgerTransactionList(
                        this.state.clientId,
                        startDate,
                        endDate,
                        status,
                        transactionType,
                        ""
                      );
                      handleCloseLedgerTransactionFilterModal();
                    }}
                    disabled={!filteredLedgerValue}
                    className={
                      filteredLedgerValue ? "addButton" : "disabledAddButton"
                    }
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/******* CHANGE STATUS MODAL *********/}
        <Dialog
          open={openLedgerTxnUpdateModal}
          onClose={() => {
            setOpenLedgerTxnUpdate(false);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={() => {
                setOpenLedgerTxnUpdate(false);
              }}
            >
              Update Status
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div className="row">
                <div className="col-lg-12" hidden={this.state.isDecline}>
                  <label className="label">
                    Status <span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="select-input selects h40">
                    <Select
                      showSearch={false}
                      getPopupContainer={(node) => node.parentNode}
                      onChange={(e) => {
                        this.setState({ value: e });
                        this.props.handleChangeTransStatus(e);
                      }}
                    >
                      <Option hidden />
                      <Option
                        value="TRANSACTION_SUBMITTED"
                        hidden={
                          this.state.selectedStatus === "TRANSACTION_SUBMITTED"
                        }
                      >
                        Submitted
                      </Option>
                      <Option value="TRANSACTION_POSTED">Posted</Option>
                      <Option value="TRANSACTION_CLOSED">Close</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label className="label">
                    Remark <span style={{ color: "red" }}> *</span>
                  </label>
                  <input
                    type="text"
                    id="remark"
                    className="input"
                    onChange={(e) => {
                      e.preventDefault();
                      this.props.handleChangeRemark(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    noValue
                    buttonName="Update"
                    loading={this.props.transactionState.loading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdateLedgerStatus();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>
        {/*modal to save in config*/}
        <Dialog
          open={this.state.OpenSaveFilterInConfigmenu}
          onClose={handleChangeCloseConfigModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
          maxWidth="xl"
        >
          <div>
            <DialogTitleComponent
              id="draggable-dialog-title"
              onClose={handleChangeCloseConfigModal}
            >
              Save Filter
            </DialogTitleComponent>
            <DialogContent dividers className="dialogContent">
              <div>
                <label className="label">
                  Name <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="remark"
                  className="input"
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      configName: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="row"
                style={{
                  padding: "1.5rem 0px 0.5rem",
                }}
              >
                <div className={"col-6"} />
                <div className={"col-6"} style={{ paddingLeft: "126px" }}>
                  <ButtonComponent
                    value={configStatus}
                    buttonName="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseConfigModal();
                      handleFilterSaveConfig();
                    }}
                  />
                </div>
              </div>
            </DialogContent>
          </div>
        </Dialog>

        {/*view config modal */}
        <Dialog
          maxWidth="lg"
          open={this.state.OpenViewFilterInConfig}
          onClose={handleChangeCloseViewConfigModal}
        >
          <DialogTitleComponent
            id="draggable-dialog-title"
            onClose={handleChangeCloseViewConfigModal}
          >
            Saved Configs
          </DialogTitleComponent>
          <DialogContent
            dividers
            style={{
              width: "326px",
              height: "250px",
              paddingTop: "25px",
            }}
          >
            {configlist && configlist.length > 0 ? (
              configlist.map((config) => (
                <div
                  className="changeConfigOuterDiv"
                  style={{ cursor: "pointer" }}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleChangeCloseViewConfigModal();
                      handleUseConfigFilter(config.configFilters);
                    }}
                  >
                    {config.configName}
                  </span>
                </div>
              ))
            ) : (
              <div
                className="emptyResponse"
                style={{ marginTop: "20px", marginBottom: "25px" }}
              >
                <div>No saved config !</div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={transactionState.openTransactionSuccessSnackbar}
          autoHideDuration={4000}
          onClose={this.props.handleTransactionSnackBarClose}
        >
          <MySnackbarContentWrapper
            onClose={this.props.handleTransactionSnackBarClose}
            variant="success"
            message={transactionState.successMsg}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const style = {
  container: {
    margin: "5px 3% 1% 2%",
  },
  addUserButton: {
    background: "#8BC83F",
    color: "white",
    width: "23%",
    padding: "0.5rem",
    border: "1px solid #8BC83F",
    marginLeft: "12px",
  },
  dialogHeaderForAddUser: {
    cursor: "move",
    background: "#8BC83F",
    color: "white",
    paddingTop: "0px",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  label: {
    color: "#606060",
    marginBottom: "0",
    fontSize: "16px",
    width: "100%",
    marginTop: "20px",
  },
  input: {
    height: "40px",
    backgroundColor: "#F4F5F9",
    border: "none",
  },
  rowContent: {
    paddingBottom: "22px",
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  addUserDisableButton: {
    background: "#b1d681",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #b1d681",
  },
  searchDate: {
    position: "relative",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    marginTop: "10px",
    width: "180px",
    height: "40px",
  },
  card: {
    background: "#ffffff",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "1rem",
    marginTopL: "48px",
  },
  icon: {
    position: "absolute",
    top: "56px",
    right: "30px",
    color: " rgb(200, 197, 197)",
    fontSize: "32px",
  },
  inputField: {
    border: "0",
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    fontSize: "14x",
    height: "40px",
    width: "100%",
    paddingTop: "0px",
    marginTop: "16px",
    paddingLeft: "15px",
  },

  filterButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "35px",
    height: "35px",
    marginRight: "12px",
  },
  breadcrumb: {
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "2.5%",
    color: "#8BC83F",
    fontWeight: "bold",
    position: "fixed",
    width: "100%",
    background: "white",
    zIndex: 1,
  },
  link: {
    color: "#8BC83F",
  },
  columnHeading: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
  },
  clientData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    width: "100%",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
  },
  expandMoreIcon: {
    position: "relative",
    right: "-88%",
    top: "-32px",
    color: "#C8C5C5",
    fontSize: "35px",
  },
  deleteClientButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.6rem",
  },
  tableHeader: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    height: "3rem",
    top: 0,
    position: "inherit",
  },
  tableHeaderAmount: {
    color: "#8BC83F",
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "right",
    height: "3rem",
    top: 0,
    position: "inherit",
    paddingRight: "48px",
  },
  tableBodyAmount: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "right",
    height: "4.5rem",
    paddingRight: "48px",
  },
  tableBody: {
    fontSize: "15px",
    color: "#626262",
    textAlign: "left",
    height: "4.5rem",
  },
  flex: {
    paddingBottom: "1rem",
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  settingButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    marginLeft: "1rem",
    width: "35px",
    height: "35px",
  },
  exportButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    padding: "4px",
    fontSize: "16px",
    borderRadius: "2px",
    width: "100px",
    height: "35px",
  },
  rangeBase: {
    backgroundColor: "#F4F5F9",
    color: "rgb(130, 132, 129)",
    width: "270px",
    border: "none",
    borderRadius: "8px",
  },
  addButton: {
    border: "none",
    background: "#8BC83F",
    color: "white",
    width: "190px",
    height: "35px",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "10px",
    paddingRight: "14px",
    fontSize: "16px",
    borderRadius: "2px",
  },
  progressBar: {
    marginTop: "0px",
    height: "0.6rem",
  },
  progressData: {
    marginBottom: "0",
    paddingBottom: "0",
  },
  progressDiv: {
    paddingTop: "8px",
    width: "100%",
  },
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
  },
  cancelButton: {
    border: "1px solid #8BC83F",
    color: "#8BC83F",
    width: "23%",
    padding: "0.5rem",
  },
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "#F4F5F9",
    marginRight: "1.2rem",
    width: "270px",
    height: "40px",
  },
  searchIcon: {
    width: 7,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "2rem !important",
    marginLeft: "1rem",
  },
  inputBase: {
    width: "270px",
  },
  extraData: {
    color: "#625F5F",
    marginBottom: "0px",
    fontSize: "16px",
    textAlign: "left !important",
    marginTop: "10px",
    fontWeight: "550",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatarName: {
    fontSize: "12px",
    padding: "0.8rem",
    color: "#626262",
    marginLeft: "2rem",
  },
};

export default IndividualClientLedgerTxnScreen;
