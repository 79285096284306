import React from "react";

export const DocumentImage = ({ documentURL }) => (
  <>
    {documentURL === "" ? null : documentURL &&
      documentURL.split(".").pop() === "pdf" ? (
      <img
        src="/assets/images/pdf.svg"
        alt="pdf"
        style={{ width: "35px", marginRight: "12px" }}
      />
    ) : documentURL && documentURL.split(".").pop() === "txt" ? (
      <img
        src="/assets/images/text_logo.png"
        alt="txt"
        style={{ width: "35px", marginRight: "12px" }}
      />
    ) : documentURL && documentURL.split(".").pop() === "csv" ? (
      <img
        src="/assets/images/csv.png"
        alt="txt"
        style={{ width: "35px", marginRight: "12px", marginLeft: "4px" }}
      />
    ) : documentURL && documentURL.split(".").pop() === "" ? (
      <img
        src="/assets/images/excelIconImage.png"
        alt="txt"
        style={{ width: "35px", marginRight: "12px" }}
      />
    ) : documentURL && documentURL.split(".").pop() === "doc" ? (
      <img
        src="/assets/images/docs.png"
        alt="txt"
        style={{ width: "25px", marginRight: "12px", marginLeft: "6px" }}
      />
    ) : (documentURL && documentURL.split(".").pop() === "jpg") ||
      documentURL.split(".").pop() === "png" ||
      documentURL.split(".").pop() === "jpeg" ? (
      <img
        src={documentURL}
        alt="txt"
        style={{ width: "25px", marginRight: "12px", marginLeft: "6px" }}
      />
    ) : documentURL && documentURL.split(".").pop() === "docx" ? (
      <img
        src="/assets/images/docx.png"
        alt="txt"
        style={{
          width: "40px",
          marginRight: "8px",
          marginLeft: "-5px",
        }}
      />
    ) : (
      <img
        src="/assets/images/docs.png"
        alt="txt"
        style={{ width: "25px", marginRight: "12px", marginLeft: "6px" }}
        // style={{ width: "35px", marginRight: "12px" }}
      />
    )}
  </>
);
