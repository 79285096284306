import {connect} from 'react-redux';
import ClientDocumentScreen from '../../screens/ClientDocumentScreen';
import { ContactApi, DynamicActionAPI } from '../../api';
import { addContactFileFailure, addContactFileSuccess, contactRequestMade, fetchContactFileFailure, fetchContactFileSuccess, handleChangeContactFiles, closeSnackBarForContact } from '../../actions/ContactAction';
import { scheduleTransactionRequestMade } from '../../actions';
import { uploadDocFileFailure, uploadDocFileSuccess, closeScheduleTransactionSnackBar } from '../../actions/ScheduleTransactionAction';

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return{
        fetchContactFilesList: (clientId, props) =>{
            dispatch(contactRequestMade(true));
            ContactApi.fetchContactFiles(clientId,(response, error) => {
                if (response) {
                    dispatch(fetchContactFileSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchContactFileFailure(error.msg))
                    } else {
                        dispatch(fetchContactFileFailure(error));
                    }
                }
            })
        },
        handleChangeContactFile: (id, value) => {dispatch(handleChangeContactFiles(id, value))},
        uploadDocumentFile: (selectedFiles, props) => {
            dispatch(scheduleTransactionRequestMade(true));
            let formData = new FormData();
            formData.append("doc", selectedFiles.document);
            formData.append("mimeType", selectedFiles.type);
            DynamicActionAPI.uploadDocumentFile(formData, (response, error) => {
                if (response) {
                    dispatch(uploadDocFileSuccess(response))
                    props.handleChangeContactFile("documentUrl", response.fileUrl)
                } else {
                    if (error.msg) {
                        dispatch(uploadDocFileFailure(error.msg));
                    } else {
                        dispatch(uploadDocFileFailure(error));
                    }
                }
            });
        },
        closeScheduleTransactionSnackBar: () => {dispatch(closeScheduleTransactionSnackBar())},
        closeContactSnackBar: () => {dispatch(closeSnackBarForContact())},
        addContactFiles: (contactFiles, props) => {
            dispatch(contactRequestMade(true));
            ContactApi.addContactFiles(contactFiles, (response, error) => {
                if (response) {
                    dispatch(addContactFileSuccess(response));
                    props.fetchContactFilesList(contactFiles.refId);
                } else {
                    if (error.msg) {
                        dispatch(addContactFileFailure(error.msg));
                    } else {
                        dispatch(addContactFileFailure(error));
                    }
                }
            });
        },
    }
}

export const ClientDocumentPageContainer =
    connect(mapStateToProps, mapDispatchToProps) (ClientDocumentScreen);