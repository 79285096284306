import { connect } from 'react-redux';
import { WorkflowEditFormScreen } from "../../screens";
import {
    addWorkflowFailure,
    addWorkflowSuccess,
    closeSnackBarForWorkflowForm,
    fetchActionFailure,
    fetchActionSuccess,
    setWorkflowToEdit,
    workflowFormRequestMade,
    workflowRequestMade
} from "../../actions";
import { WorkflowFormAPI } from "../../api";
import { handleChangeWorkflow } from "../../actions/WorkflowFormAction";

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchActionList: () => {
            dispatch(workflowFormRequestMade(true));
            WorkflowFormAPI.fetchActionList((response, error) => {
                if (response) {
                    dispatch(fetchActionSuccess(response));
                } else {
                    if (error.msg) {
                        dispatch(fetchActionFailure(error.msg))
                    } else {
                        dispatch(fetchActionFailure(error));
                    }
                }
            })
        },
        handleChangeWorkflow: (id, value) => dispatch(handleChangeWorkflow(id, value)),
        editWorkflow: (workflowFormData, props) => {
            dispatch(workflowRequestMade(true));
            WorkflowFormAPI.editWorkflow(workflowFormData, (response, error) => {
                if (response) {
                    dispatch(addWorkflowSuccess(true));
                    props.history.replace('/workflow');
                } else {
                    if (error.msg) {
                        dispatch(addWorkflowFailure(error.msg));
                    } else {
                        dispatch(addWorkflowFailure(error));
                    }
                }
            });
        },
        getWorkflowInfo: workflowId => {
            dispatch(workflowRequestMade(true));
            WorkflowFormAPI.fetchSingleWorkflow(workflowId, (response, error) => {
                if (response) {
                    dispatch(setWorkflowToEdit(response));
                } else {
                    if (error.msg) {
                        dispatch(addWorkflowFailure(error.msg));
                    } else {
                        dispatch(addWorkflowFailure(error));
                    }
                }
            })
        },
        setWorkflowData: (workflowFormData) => {
            dispatch(setWorkflowToEdit(workflowFormData))
        },
        handleSnackBarClose: () => dispatch(closeSnackBarForWorkflowForm(true)),
    };
};


export const WorkflowEditFormContainer = connect(mapStateToProps, mapDispatchToProps)(WorkflowEditFormScreen);