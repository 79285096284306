import React, {Component} from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import {
    Button,
    CircularProgress,
    MenuList,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {Popup} from 'semantic-ui-react'
import Popover from "@material-ui/core/Popover";
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import HomeIcon from '@material-ui/icons/Home';
import MySnackbarContentWrapper from "../components/Snackbar";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import PaperComponent from "./PaperComponent";
import {HelmetComponent} from "../components/Helmet";

class CaseTemplateScreen extends Component {
    componentDidMount() {
        this.props.fetchCaseTemplateList();
    }

    componentWillUnmount() {
        this.props.handleSnackBarClose();
    }

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            openActionMenu: false,
            popoverElement: null,
            openDeleteCaseTemplateDialogue: false,
            page: 0,
            rowsPerPage: 10,
            sortType: 'asc',
            singleCaseTemplate: '',
            caseTemplateId: '',
            workflowNameList: []
        }
    }

    render() {

        const handleCloseDialogueForDeleteCaseTemplate = () => {
            this.setState({
                openDeleteCaseTemplateDialogue: false
            })
        };

        const handleOpenDialogueForDeleteCaseTemplate = (caseTemplateId) => {
            this.setState({
                openDeleteCaseTemplateDialogue: true,
                caseTemplateId: caseTemplateId
            })
        };

        const handleOkDeleteCaseTemplateDialogue = () => {
            this.props.deleteCaseTemplate(this.state.singleCaseTemplate.caseTemplateId, this.props);
            this.setState({
                openDeleteCaseTemplateDialogue: false,
                templateId: ''
            })
        };

        const handleChangePage = (event, newPage) => {
            this.setState({
                page: newPage
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };

        const handleChangeRowsPerPage = event => {
            this.setState({
                rowsPerPage: parseInt(event.target.value, 10),
                page: 0
            });
            if(this.tableRef.current) {this.tableRef.current.scrollIntoView()}
        };

        const caseTemplateState = this.props.state.caseTemplateState;
        const caseTemplateList = caseTemplateState && caseTemplateState.caseTemplate;
        const sortedCaseTemplateList = caseTemplateList ? caseTemplateList.sort((a, b) => {
            const data = (this.state.sortType === 'asc') ? 1 : -1;
            return data * a.templateName.localeCompare(b.templateName)
        }) : null;
   
        return (
            <React.Fragment>
                <HelmetComponent title="Case Template"/>
                {this.props.state.caseTemplateState.loading ? (
                    <div
                        className={
                            this.props.state.caseTemplateState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}

                <Breadcrumbs aria-label="breadcrumb" style={style.breadcrumb}>
                    <Link color="inherit" to="/dashboard" style={style.link}>
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Typography color="inherit" className="link">
                        Case Template
                    </Typography>
                </Breadcrumbs>

                <div style={style.container}>
                    <div className={"d-flex flex-row-reverse"} style={style.flex}>
                        <div>
                            <button style={style.settingButton}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                            sortType: this.state.sortType === 'asc' ? 'dsc' : 'asc'
                                        })
                                    }}>
                                <img alt={'setting'} src={'assets/images/sort.png'}
                                     style={{width: '18px', height: '18px'}}/>
                            </button>
                        </div>
                        <div>
                            <button style={style.addButton} onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push({
                                    pathname: "/casetemplate/create"
                                })
                            }}>
                                <AddIcon/>Create Case Template
                            </button>
                        </div>

                    </div>

                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header">
                            <Table size="small" stickyHeader aria-label="a dense table" ref={this.tableRef}>
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell style={style.tableHeader}>
                                            Case Template Title
                                        </TableCell>
                                        <TableCell style={{
                                            color: "#8BC83F",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                            textAlign: 'center',
                                            height: '48px', paddingLeft: 0
                                        }}>
                                            Workflow Counts
                                        </TableCell>
                                        <TableCell style={{
                                            color: "#8BC83F",
                                            fontWeight: "bold",
                                            fontSize: "15px",
                                            textAlign: 'center',
                                            width: '130px',
                                            height: '3rem',
                                        }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                {sortedCaseTemplateList &&
                                sortedCaseTemplateList.slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                    this.state.rowsPerPage
                                ).map(singleCaseTemplate => {
                                    return (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell
                                                    style={style.tableBody}>
                                                    <Button
                                                            style={{justifyContent: 'left',textTransform:'none',fontSize:"15px",color: "#626262", padding:0}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    singleCaseTemplate: singleCaseTemplate
                                                                },() => {
                                                                    this.props.history.push({
                                                                        pathname: "/casetemplate/edit/" + this.state.singleCaseTemplate.caseTemplateId,
                                                                        dataToPass: this.state.singleCaseTemplate.caseTemplateId
                                                                    })
                                                                });
                                                            }}
                                                        > {singleCaseTemplate.templateName}</Button>
                                                </TableCell>
                                                <TableCell style={{
                                                    fontSize: "15px",
                                                    color: "#626262",
                                                    textAlign: 'center',
                                                    height: '4rem',
                                                    paddingLeft: 0
                                                }}>
                                                    <Popup
                                                        content={this.state.workflowNameList.map(singleWorkflowName => {
                                                                return (
                                                                    <li style={{
                                                                        listStyleImage: 'url(assets/images/dot.svg)',
                                                                        fontSize: '15px'
                                                                    }}>
                                                                        {singleWorkflowName.workflowName}
                                                                    </li>
                                                                )
                                                            }
                                                        )}
                                                        on='click'
                                                        pinned
                                                        position='right center'
                                                        trigger={<Button
                                                            style={{justifyContent: 'center',
                                                                borderBottom:'2px solid #8BC83F',
                                                                borderRadius:'2px',
                                                                paddingLeft:'4px',
                                                                paddingRight:'4px',
                                                                minWidth:'22px',
                                                                marginLeft:'0px'}}
                                                            disabled={!singleCaseTemplate.workflow}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    singleCaseTemplate: singleCaseTemplate,
                                                                    workflowNameList: singleCaseTemplate.workflow
                                                                });
                                                            }}
                                                        >{singleCaseTemplate.workflow ? singleCaseTemplate.workflow.length : 0}</Button>
                                                        }/>
                                                </TableCell>
                                                <TableCell style={{
                                                    fontSize: "15px",
                                                    color: "#626262",
                                                    textAlign: 'center',
                                                    width: '130px'
                                                }}>
                                                    <MoreHorizIcon
                                                        id="Popover1"
                                                        style={{cursor: 'pointer'}}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                popoverElement: e.currentTarget,
                                                                singleCaseTemplate: singleCaseTemplate,
                                                                openActionMenu: true
                                                            })
                                                        }}
                                                    />
                                                    <Popover
                                                        open={this.state.openActionMenu}
                                                        anchorEl={this.state.popoverElement}
                                                        onClose={(e) => {
                                                            e.preventDefault();
                                                            this.setState({openActionMenu: false})
                                                        }}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        style={{boxShadow: 'none'}}
                                                    >
                                                        <MenuList style={{
                                                            color: '#4d4d4d',
                                                            padding: '1rem !important',
                                                            cursor: 'pointer'
                                                        }}
                                                                  onClick={e => {
                                                                      e.preventDefault();
                                                                      this.props.history.push({
                                                                          pathname: "/casetemplate/edit/" + this.state.singleCaseTemplate.caseTemplateId,
                                                                          dataToPass: this.state.singleCaseTemplate.caseTemplateId
                                                                      })
                                                                  }}>
                                                            <EditOutlinedIcon/> Edit
                                                        </MenuList>
                                                        <MenuList style={{
                                                            color: '#4d4d4d',
                                                            padding: '1rem !important',
                                                            cursor: 'pointer'
                                                        }}
                                                                  onClick={e => {
                                                                      e.preventDefault();
                                                                      this.setState({openActionMenu: false});
                                                                      handleOpenDialogueForDeleteCaseTemplate()
                                                                  }}>
                                                            <DeleteOutlinedIcon style={{paddingBottom: 0}}/> Delete
                                                        </MenuList>
                                                    </Popover>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                })}
                            </Table>
                            </TableContainer>
                            {sortedCaseTemplateList &&
                            sortedCaseTemplateList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            sortedCaseTemplateList.length > 0
                                                ? sortedCaseTemplateList.length
                                                : 100
                                        }
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>

                {/*Delete Case Template*/}
                <Dialog
                    open={this.state.openDeleteCaseTemplateDialogue}
                    onClose={handleCloseDialogueForDeleteCaseTemplate}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title" style={{cursor: "move"}}>
                        <h3 style={{color: '#8BC83F', padding: '0.8rem', textAlign: 'center'}}>
                            Confirmation</h3>
                    </DialogTitle>
                    <DialogContent
                        style={{
                            background: 'white',
                            textAlign: 'center',
                            width: '450px'
                        }}>
                        <DialogContentText>
                            Do you want to delete case template?

                            <div className="row" style={{
                                paddingLeft: '10px',
                                paddingRight: "10px",
                                paddingBottom: '0.5rem',
                                paddingTop: '1.6rem'
                            }}>
                                <div className={"col-6"}>
                                    <Button autoFocus onClick={handleCloseDialogueForDeleteCaseTemplate}
                                            style={style.cancelButton}>
                                        cancel
                                    </Button>
                                </div>
                                <div className={"col-6"}>
                                    <Button onClick={(e) => {
                                        e.preventDefault();
                                        handleOkDeleteCaseTemplateDialogue()
                                    }} style={style.deleteCaseTemplateButton}>
                                        ok
                                    </Button>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.props.state.caseTemplateState.openCaseTemplateSnackBar}
                    autoHideDuration={6000}
                    onClose={this.props.handleSnackBarClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.handleSnackBarClose}
                        variant="error"
                        message={this.props.state.caseTemplateState.errorMsgForCaseTemplate}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

const style = {
    container: {
        marginLeft: "3%",
        marginRight: "3%",
        marginBottom: "2%",
        marginTop: "48px"
    },
    card: {
        background: "#ffffff",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginLeft: 0,
        marginRight: 0,
        marginBottom: "1rem"
    },
    breadcrumb: {
        fontSize: "14px",
        borderBottom: "1px solid #dddddd",
        paddingTop: "12px",
        paddingBottom: "12px",
        paddingLeft: "2.5%",
        color: "#8BC83F",
        fontWeight: "bold",
        position: 'fixed',
        width: '100%',
        background: 'white',
        zIndex:1
    },
    link: {
        color: '#8BC83F'
    },
    tableHeader: {
        color: "#8BC83F",
        fontWeight: "bold",
        fontSize: "15px",
        textAlign: 'left',
        height: '3rem',
        paddingLeft: '50px',
    },
    tableBody: {
        fontSize: "15px",
        color: "#626262",
        textAlign: 'left',
        paddingLeft: '50px',
        width: "342px",
    },
    flex: {
        paddingBottom: "1rem",
        paddingTop: "1rem",
        paddingLeft: "1rem"
    },
    settingButton: {
        border: 'none',
        background: '#8BC83F',
        color: 'white',
        padding: '4px',
        fontSize: '16px',
        borderRadius: '2px',
        marginLeft: '1rem',
        width: '35px',
        height: '35px',
    },
    addButton: {
        border: 'none',
        background: '#8BC83F',
        color: 'white',
        width: '200px',
        height: '35px',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '4px',
        paddingRight: '4px',
        fontSize: '16px',
        borderRadius: '2px'
    },
    avatarName: {
        fontSize: "12px",
        padding: "0.8rem",
        color: "#626262",
        marginLeft: '2rem'
    },
    progressBar: {
        marginTop: '0px',
        height: '0.6rem',
    },
    progressData: {
        marginBottom: '0',
        paddingBottom: '0'
    },
    progressDiv: {
        paddingTop: '29px'
    },
    deleteCaseTemplateButton: {
        background: '#8BC83F',
        color: 'white',
        width: '100%',
        padding: '0.5rem'
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
};

export default CaseTemplateScreen;
