import {ACTION_TYPE_DASHBOARD} from "../constants/Type";

const initialState = {
    loading: false,
    successMsg: "",
    errorMsg: "",
    openSnackBarForSuccess:false,
    openSnackBarForFailure:false,
    dashboardData:{},
    transactionStat: {},
    globalNavigation:[],
    dashboardStatus: {},
};

const dashBoardState = (state=initialState, action) =>{
    switch (action.type) {
        case ACTION_TYPE_DASHBOARD.REQUEST_MADE_FOR_DASHBOARD:
            return {
                ...state,
              loading: true,
            };
        case ACTION_TYPE_DASHBOARD.GET_CASE_STATUS_FOR_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboardData: action.response.caseStats
            }
        case ACTION_TYPE_DASHBOARD.GET_CASE_STATUS_FOR_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
                openSnackBarForFailure: true,
            }
        case ACTION_TYPE_DASHBOARD.GET_TRANSACTION_STATUS_FOR_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                transactionStat: action.response.transactionStat
            }
        case ACTION_TYPE_DASHBOARD.GET_TRANSACTION_STATUS_FOR_DASHBOARD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: action.error,
                openSnackBarForFailure: true,
            }
        case ACTION_TYPE_DASHBOARD.GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                globalNavigation: action.response.globalNavigation,
            }
        case ACTION_TYPE_DASHBOARD.GLOBAL_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                globalNavigation: [],
                errorMsg: action.error,
            }
        case ACTION_TYPE_DASHBOARD.GET_OTHER_STATUS_FOR_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboardStatus: action.response.dashboardStat,
            }
            case ACTION_TYPE_DASHBOARD.GET_OTHER_STATUS_FOR_DASHBOARD_FAILURE:
                return {
                    ...state,
                    loading: false,
                    errorMsg: action.error,
                }
        default:
            return state;
    }
};
export default dashBoardState;
