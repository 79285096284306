import { ACTION_TYPE_LOGIN } from "../constants/Type";
import { LocalDb } from "../api";

const session = LocalDb.getSessions();
const user = session ? session.loginResponse.loginUser.user ? session.loginResponse.loginUser.user:
    session.loginResponse.loginUser.corporate? session.loginResponse.loginUser.corporate:
        session.loginResponse.loginUser.client ? session.loginResponse.loginUser.client :
            session.loginResponse.loginUser.contact ? session.loginResponse.loginUser.contact:
                session.loginResponse.loginUser.company : null ;

const initialState = {
    loggedIn: false,
    user: user ? user : null,
    userData: {
        email: '',
        password: ''
    },
    token: null,
    localErrorEmail: "",
    localErrorPassword: "",
    errorMessageSnackBar: "",
    loading: false,
    openSnackBar: false
};

const loginState = (state = initialState, action) => {
    switch (action.type) {

        case ACTION_TYPE_LOGIN.LOGIN_REQUEST_MADE:
            if(action.bool === true) {
                return {
                    ...state,
                    loading: true,
                };
            }else{
                return {
                    ...state,
                    loading: false,
                };
            }
           
        case ACTION_TYPE_LOGIN.CLOSE_SNACK_BAR_LOGIN_PAGE:
            return {
                ...state,
                openSnackBar: false,
            };

        case ACTION_TYPE_LOGIN.LOGIN_SUCCESS:
            console.log("action.userData.loginUser",  action.userData.loginUser)
            return {
                ...state,
                loggedIn: true,
                user: action.userData.loginUser.user ?
                        action.userData.loginUser.user :
                        action.userData.loginUser.corporate?
                            action.userData.loginUser.corporate:
                            action.userData.loginUser.client?
                                action.userData.loginUser.client:
                                action.userData.loginUser.contact ?
                                    action.userData.loginUser.contact :
                                    action.userData.loginUser.company
                ,
                token: action.userData.token,
                loading: false,
            };
        case ACTION_TYPE_LOGIN.HANDLE_LOGIN_CHANGE:
            const newForm = { ...state.userData };
            newForm[action.id] = action.value;
            return {
                ...state,
                userData: newForm
            };
        case ACTION_TYPE_LOGIN.LOGIN_FAILURE:
            if (action.form === "email") {
                return {
                    ...state,
                    loggingIn: false,
                    localErrorEmail: action.error,
                    loading: false
                };
            }else {
                return {
                    ...state,
                    loggingIn: false,
                    errorMessageSnackBar: action.error,
                    openSnackBar: true,
                    loading: false
                };
            }
        default:
            return state;
    }
};

export default loginState;