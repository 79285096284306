import { ACTION_TYPE_WORKFLOW_FORM } from "../constants/Type";

const initialState = {
    loading: false,
    openWorkflowFormSnackBar: false,
    errorMsgForWorkflowForm: '',
    workflowData: {
        workflowName: '',
        action: [
            {
                actionId: ''
            }
        ]
    }
};

const workflowFormState = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPE_WORKFLOW_FORM.ACTION_FETCH_SUCCESS:
            return {
                ...state,
                action: action.action.actions,
                loading: false,
                openWorkflowFormSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.ACTION_FETCH_FAILURE:
            return {
                ...state,
                errorMsgForWorkflowForm: action.error,
                loading: false,
                openWorkflowFormSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW_FORM.WORKFLOW_FORM_REQUEST_MADE:
            return {
                ...state,
                loading: true,
            };
        case ACTION_TYPE_WORKFLOW_FORM.HANDLE_CHANGE_ACTION:
            const newForm = { ...state.actionData };
            newForm[action.id] = action.value;
            return {
                ...state,
                actionData: newForm,
                loading: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.ADD_ACTION_SUCCESS:
            return {
                ...state,
                addActionSuccess: action.bool,
                loading: false,
                openWorkflowFormSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.ADD_ACTION_FAILURE:
            return {
                ...state,
                errorMsgForWorkflowForm: action.error,
                loading: false,
                openWorkflowFormSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW_FORM.HANDLE_CHANGE_WORKFLOW:

            const newWorkflowForm = { ...state.workflowData };
            newWorkflowForm[action.id] = action.value;
            return {
                ...state,
                workflowData: newWorkflowForm,
                loading: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.WORKFLOW_ADD_SUCCESS:
            return {
                ...state,
                addWorkflowSuccess: action.bool,
                loading: false,
                openWorkflowFormSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.WORKFLOW_ADD_FAILURE:
            return {
                ...state,
                errorMsgForWorkflowForm: action.error,
                loading: false,
                openWorkflowFormSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW_FORM.DELETE_ACTION_SUCCESS:
            const oldActionList = state.action;
            let newActions = [];
            for (let i = 0; i < oldActionList.length; i++) {
                if (oldActionList[i].actionId !== action.actionId) {
                    newActions.push(oldActionList[i]);
                }
            }
            return {
                ...state,
                newAction: newActions,
                loading: false,
                openWorkflowFormSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.DELETE_ACTION_FAILURE:
            return {
                ...state,
                deleteFailMsg: action.error,
                loading: false,
                openWorkflowFormSnackBar: true
            };
        case ACTION_TYPE_WORKFLOW_FORM.SET_WORKFLOW_TO_EDIT:
            return {
                ...state,
                workflowData: action.workflowData.workflow,
                loading: false,
                openActionSnackBar: false
            };
        case ACTION_TYPE_WORKFLOW_FORM.CLOSE_SNACK_BAR_WORKFLOW_FORM_PAGE:
            return {
                ...state,
                openWorkflowFormSnackBar: false,
            };
        default:
            return state;
    }
};

export default workflowFormState;