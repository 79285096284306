import {API} from "./index";
import APIEndPoints from "../constants/APIConstants";

class UserAPI extends API {
    fetchUserList = callback => {
        fetch(APIEndPoints.FETCH_USER, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling user api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchUserById = (id, callback) => {
        var constUrl = APIEndPoints.FETCH_USER + "/" + id;
        fetch(constUrl, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling user api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    addNewUser = (userData, callback) => {
        let body = JSON.stringify(userData);
        fetch(APIEndPoints.ADD_USER, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
                console.log("Error in calling add User api", error);
                callback(null, "Service error, please try again.");
            });
    };

    updateUserRole = (userData, callback) => {
        let body = JSON.stringify(userData);
        fetch(APIEndPoints.UPDATE_USER_ROLE, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling patching user api", error);
            callback(null, "Service error, please try again.");
        });
    };

    deleteUser = (userId, callback) => {
        const urlString = APIEndPoints.DELETE_USER + userId;
        fetch(urlString, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        })
    };

    resetPasswordBySuperAdmin = (email, callback) => {
        const  urlString = APIEndPoints.RESET_PASSWORD_BY_SUPERADMIN + email;
        fetch(urlString, {
            method: 'GET',
            headers: this.authHeaders,
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                callback(null, "Service error, please try again.");
            });
    };
}

export default new UserAPI();