import React, { Component } from "react";
import AddIcon from "@material-ui/icons/Add";
import {
    Button, CircularProgress, MenuItem, MenuList, Popover, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import TablePagination from "@material-ui/core/TablePagination";
import MySnackbarContentWrapper from "../components/Snackbar";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PaperComponent from "./PaperComponent";
import {Select, Tag} from "antd";
import DialogTitleComponent from "./DialogTitleComponent";
import ButtonComponent from "./ButtonComponent";
const {Option} = Select;

class RelationshipScreen extends Component{
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state={
            openActionMenu: false,
            popoverElement: null,
            relationData:{},
            clicked: false,
        }
    }
    componentDidMount() {
        this.props.fetchRelationshipList();
        this.props.getRelationshipPermission();
    }

    render() {
        const contactState = this.props.propsState && this.props.propsState.contactState;
        const relationshipName = contactState && contactState.relationshipName;
        const relationShipList = contactState && contactState.relationShipList;
        const relationship = contactState && contactState.relationship;
        const permissionList = contactState && contactState.permissionTypeList;
        const permissionName = contactState && contactState.permissionName;
        console.log("permissionName",permissionName)
        const value = relationshipName !== "";
        const permissionValue = permissionName && permissionName.length > 0;
        return(
            <React.Fragment>
                { contactState && contactState.loading ? (
                    <div
                        className={
                            contactState && contactState.loading ? "loader" : "loader hidden"
                        }
                        id="loader"
                    >
                        <CircularProgress style={{color: "#8BC83F"}}/>
                    </div>
                ) : null}
                <div>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.handleChangeOpenModalToAddRelationship()
                                }}>
                                <AddIcon/> Add Relationship
                            </button>
                        </div>
                    </div>
                    <div className={"row"} style={style.card}>
                        <TableContainer className="fixed-table-header-second">
                            <Table size="small" stickyHeader aria-label="sticky table" ref={this.tableRef}>
                                <TableHead style={{padding: "1rem"}}>
                                    <TableRow>
                                        <TableCell className="first-tableHeader">
                                            Relationship Name
                                        </TableCell>
                                        <TableCell className="tableHeader">
                                            Permissions
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {relationShipList && relationShipList.slice(
                                    this.props.page * this.props.rowsPerPage,
                                    this.props.page * this.props.rowsPerPage +
                                    this.props.rowsPerPage
                                ).map(list => (
                                    <TableBody key={list.relationshipId}>
                                        <TableRow >
                                            <TableCell className="first-tableBody">
                                                {list.name}
                                            </TableCell>
                                            <TableCell className="tableBody">
                                                    {list.permission ? list.permission.map(permissionList =>
                                                        <Tag style={style.colors}>{permissionList.permissionName}
                                                        </Tag>): "-"}
                                            </TableCell>
                                            <TableCell>
                                                <MoreHorizIcon
                                                    id="Popover1"
                                                    style={{cursor: 'pointer'}}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            popoverElement: e.currentTarget,
                                                            openActionMenu: true,
                                                            relationData: list,
                                                        })
                                                    }}
                                                />
                                                <Popover
                                                    open={this.state.openActionMenu}
                                                    anchorEl={this.state.popoverElement}
                                                    onClose={(e) => {
                                                        e.preventDefault();
                                                        this.setState({openActionMenu: false})
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    style={{boxShadow: 'none'}}
                                                >
                                                    <MenuList id="popover-list" style={{
                                                        color: '#4d4d4d',
                                                        padding: '1rem !important',
                                                        cursor: 'pointer'
                                                    }}>
                                                        <MenuItem
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.handleOpenToAssignPermission();
                                                                this.props.setRelationshipToEdit(this.state.relationData)
                                                                this.setState({openActionMenu: false,}
                                                                );
                                                            }}>
                                                            <AssignmentTurnedInIcon style={{paddingBottom: 0}}/> Assign Permission
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.handleChangeOpenModalToEditRelationship();
                                                                this.props.setRelationshipToEdit(this.state.relationData)
                                                                this.setState({openActionMenu: false,}
                                                                );
                                                            }}>
                                                            <EditOutlinedIcon style={{paddingBottom: 0}}/> Edit
                                                        </MenuItem>
                                                    </MenuList>
                                                </Popover>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>))}
                            </Table>
                            </TableContainer>
                            {relationShipList && relationShipList.length > 0 ? (
                                <div style={{justifyContent: "flex-start", width: "100%", borderTop: "0.1px solid rgba(224, 224, 224, 1)"}}>
                                    <TablePagination
                                        labelRowsPerPage={false}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={
                                            relationShipList.length > 0
                                                ? relationShipList.length
                                                : 100
                                        }
                                        rowsPerPage={this.props.rowsPerPage}
                                        page={this.props.page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={this.props.handleChangePage}
                                        onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                                    />
                                </div>
                            ) : (
                                <div style={{     
                                    display: "flex", 
                                    width: "100%",
                                    justifyContent:"center"
                                    }}>
                                    <h3
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            padding: "1rem",
                                            color: "#606060"
                                        }}
                                    >
                                        {" "}
                                        No data to show
                                    </h3>
                                </div>
                            )}
                        {/* </TableContainer> */}
                    </div>
                </div>
                {/*add relationship*/}
                <Dialog
                    open={this.props.openModalToAddRelationship}
                    onClose={this.props.handleChangeCloseModalToAddRelationship}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToAddRelationship}>
                            Add Relationship
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Relationship</label>
                                    <input
                                        type='text'
                                        id="relationShip"
                                        className="input"
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.props.handleChangeRelationshipName(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        onClick={e => {
                                            e.preventDefault();
                                            const relationName = {
                                                name: relationshipName
                                            }
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.addRelationship(relationName);
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        value={value}
                                        loading={contactState.loading}
                                        buttonName="Add"
                                    />
                                    {/*<Button*/}
                                    {/*    style={{float: "right"}}*/}
                                    {/*    onClick={e => {*/}
                                    {/*        e.preventDefault();*/}
                                    {/*        const relationName = {*/}
                                    {/*            name: relationshipName*/}
                                    {/*        }*/}
                                    {/*        if(!this.state.clicked){*/}
                                    {/*            this.setState({*/}
                                    {/*                clicked: true*/}
                                    {/*            });*/}
                                    {/*            this.props.addRelationship(relationName);*/}
                                    {/*            setTimeout( () => {*/}
                                    {/*                this.setState({*/}
                                    {/*                    clicked: false*/}
                                    {/*                })*/}
                                    {/*            }, 3000);*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    disabled={!value || contactState.loading}*/}
                                    {/*    className={value && !contactState.loading ? "addButton" : "disabledAddButton"}*/}
                                    {/*>*/}
                                    {/*    <CircularProgress style={{*/}
                                    {/*        color: '#a7aaaa',*/}
                                    {/*        width: '15px',*/}
                                    {/*        height: '15px',*/}
                                    {/*        marginRight: '10px'*/}
                                    {/*    }}*/}
                                    {/*    hidden={!contactState.loading}/>*/}
                                    {/*    Add*/}
                                    {/*</Button>*/}
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*edit relationship*/}
                <Dialog
                    open={this.props.openModalToEditRelationship}
                    onClose={this.props.handleChangeCloseModalToEditRelationship}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleChangeCloseModalToEditRelationship}>
                            Update Relationship
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Relationship</label>
                                    <input
                                        type='text'
                                        id="relationShip"
                                        className="input"
                                        value={relationshipName}
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.props.handleChangeRelationshipName(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "8px"}}>
                                    <ButtonComponent
                                        onClick={e => {
                                            e.preventDefault();
                                            const relationData = {
                                                relationshipId : relationship.relationshipId,
                                                name: relationshipName
                                            }
                                            if(!this.state.clicked){
                                                this.setState({
                                                    clicked: true
                                                });
                                                this.props.editRelationship(relationData)
                                                setTimeout( () => {
                                                    this.setState({
                                                        clicked: false
                                                    })
                                                }, 3000);
                                            }
                                        }}
                                        value={value}
                                        loading={contactState.loading}
                                        buttonName="Update"
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                {/*assign permission*/}
                <Dialog
                    open={this.props.openModalToAssignPermission}
                    onClose={this.props.handleCloseToAssignPermission}
                    PaperComponent={PaperComponent}
                    aria-labelledby="#draggable-dialog-title"
                    maxWidth="xl"
                >
                    <div>
                        <DialogTitleComponent
                            id="draggable-dialog-title"
                            onClose={this.props.handleCloseToAssignPermission}>
                            Assign Permission
                        </DialogTitleComponent>
                        <DialogContent dividers className="dialogContentBig" style={{paddingTop: "5px", height: "183px"}}>
                            <div className="row">
                                <div className="col-12">
                                    <label className="label">Permission Type</label>
                                    <div className="select-input selects h40" style={{marginTop: "6px"}}>
                                        <Select
                                            className="drop"
                                            mode="tags"
                                            showSearch={false}
                                            value={permissionName && permissionName.map(list => list.permissionId)}
                                            getPopupContainer={node => node.parentNode}
                                            onChange={e => {
                                                this.setState({isBlocking: true});
                                                const permissions = e.map(el => {
                                                    return {
                                                        permissionId: el
                                                    }
                                                })
                                                this.props.handleChangePermissionName(permissions)
                                            }}
                                            dropdownStyle={{height:"70px", overflowY: "scroll"}}
                                        >
                                            {
                                                permissionList && permissionList.map(list =>
                                                    <Option value={list.permissionId} key={list.permissionId}>
                                                        {list.permissionName}
                                                    </Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col-6"}/>
                                <div className={"col-6"} style={{paddingTop: "30px"}}>
                                    <Button
                                        style={{float: "right"}}
                                        onClick={e => {
                                            e.preventDefault();
                                            const permissionData ={
                                                refId: relationship.relationshipId,
                                                permissionAssign: permissionName,
                                            }
                                            this.props.setRelationshipPermissionList(permissionData)
                                        }}
                                        disabled={!permissionValue}
                                        className={permissionValue ? "addButton" : "disabledAddButton"}
                                    >
                                        Assign
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={contactState.openSuccessContactSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="success"
                        message={contactState && contactState.successMsg}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={contactState.openErrorContactSnackBar}
                    autoHideDuration={3000}
                    onClose={this.props.closeSnackBar}
                >
                    <MySnackbarContentWrapper
                        onClose={this.props.closeSnackBar}
                        variant="error"
                        message={contactState.errorMsg}
                    />
                </Snackbar>
            </React.Fragment>
        )
    }
}
const style = {
    card: {
        background: "#ffffff",
        border: "1px solid rgb(224, 224, 224)",
        boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
        marginRight: 0,
        marginLeft: 0,
        marginBottom: "1rem",
    },
}
export default RelationshipScreen;
