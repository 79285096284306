import { connect } from "react-redux";
import { closeSnackBarForRegisterCase ,addClientSuccess} from "../../../actions";
import Payment from "../../../screens/registerCase/PaymentScreen";

const mapStateToProps = (state) => {
    return {
        state
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // handleChangeClientInRegisterCase: (id, value) => dispatch(handleChangeClientInRegisterCase(id, value)),
        // addClient: (clientData) => {
        //     dispatch(registerCaseRequestMade(true));
        //     RegisterCaseAPI.addNewClient(clientData, (response, error) => {
        //         if (response) {
        //             dispatch(addClientSuccess(true));
        //         } else {
        //             if (error.msg) {
        //                 dispatch(addClientFailure(error.msg));
        //             } else {
        //                 dispatch(addClientFailure(error));
        //             }
        //         }
        //     });
        // },
        setClientContent: (response) => {
            dispatch(addClientSuccess(response));
        },
        handleRegisterCaseSnackBarClose: () => dispatch(closeSnackBarForRegisterCase(true)),
    };
};
export const PaymentContainer = connect(mapStateToProps, mapDispatchToProps)(Payment);