import React, {Component} from "react";
import '../styles/styles.css';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button} from "@material-ui/core";
class PaymentSettingScreen extends Component {
    constructor(props) {
        super(props);
        this.state ={
            openDialogueForFee: false,
        }
    }
    render() {
        const handleCloseDialogueForFee = () => {
            this.setState({
                openDialogueForFee: false
            })
        };
        const handleOpenDialogueForFee = () => {
            this.setState({
                openDialogueForFee: true,
            })
        };
        return(
            <React.Fragment>
                <Breadcrumbs aria-label="breadcrumb" className="bc">
                    <Link color="inherit" to="/dashboard" className="links">
                        <HomeIcon style={{color: '#8BC83F', paddingBottom: '2px'}}/>Home
                    </Link>
                    <Link className="links">
                        Payment Setting
                    </Link>
                </Breadcrumbs>
                <div style={{margin: "80px 3% 2%"}}>
                    <div className={"d-flex flex-row-reverse div-flex"}>
                        <div>
                            <button
                                className="addButton"
                                style={{width: '200px'}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenDialogueForFee()
                                }}>
                                <AddIcon/>  Fee Percentage
                            </button>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.openDialogueForFee}
                    onClose={handleCloseDialogueForFee}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <div style={{background: "#8BC83F", borderRadius: "18px"}}>
                        <DialogTitle
                            style={style.dialogHeaderForAddUser}
                            id="draggable-dialog-title"
                        />
                        <DialogContent
                            style={{
                                background: "white",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                paddingLeft: "1.5rem !important",
                                paddingRight: "1.5rem !important",
                                width: '450px'
                            }}
                        >
                            <h3
                                style={{
                                    textAlign: "center",
                                    color: "#8BC83F",
                                    padding: "0.6rem"
                                }}
                            >
                                Add Fee Percentage
                            </h3>
                            <label style={style.label}>Fee Percentage</label>
                            <input
                                type='number'
                                id="fee"
                                style={style.input}
                                onChange={(e) => {
                                    e.preventDefault();
                                }}
                            />
                            <div
                                className="row"
                                style={{
                                    padding: '1.5rem 0px 1.5rem'
                                }}
                            >
                                <div className={"col-6"}>
                                    <Button
                                        onClick={e => {
                                            e.preventDefault();
                                            handleCloseDialogueForFee();
                                        }}
                                        style={style.cancelButton}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className={"col-6"}>
                                    <Button
                                        onClick={e => {
                                            e.preventDefault();;
                                        }}
                                        style={style.addUserButton}
                                        // style={style.addUserButton}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}
const style = {
    // card: {
    //     background: "#ffffff",
    //     boxShadow:
    //         "rgba(0, 0, 0, 0) 0px 0px 1px 0px, rgba(0, 0, 0, 0.19) 0px 1px 4px 0px",
    //     marginRight: 0,
    //     marginBottom: "1rem",
    // },
    addUserButton: {
        background: "#8BC83F",
        color: "white",
        width: "100%",
        padding: "0.5rem",
        border: "1px solid #8BC83F"
    },
    dialogHeaderForAddUser: {
        cursor: "move",
        background: "#8BC83F",
        color: "white",
        paddingTop: "0px",
        paddingRight: "1rem",
        paddingLeft: "1rem"
    },
    label: {
        color: "#606060",
        marginBottom: "0",
        fontSize: "16px",
        width: "100%"
    },
    input: {
        border: '0',
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        fontSize: '14x',
        height: '40px',
        width: '100%',
        paddingTop: '0px',
        marginTop: '4px',
        marginBottom:'12px',
        paddingLeft: '15px'
    },
    cancelButton: {
        border: "1px solid #8BC83F",
        color: "#8BC83F",
        width: '100%',
        padding: '0.5rem'
    },
    searchDate: {
        position: 'relative',
        backgroundColor: "#F4F5F9",
        marginRight: '1.2rem',
        marginTop: '10px',
        width: '180px',
        height: '40px',
    },
    rangeBase: {
        backgroundColor: '#F4F5F9',
        color: 'rgb(130, 132, 129)',
        width: '270px',
        border: 'none',
        borderRadius: '8px',
    },
}
export default PaymentSettingScreen;