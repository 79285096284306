import APIEndPoints from '../constants/APIConstants';
import API from "./API";

class LoginAPI extends API {
     onLogin = (userData, callback) => {
        let bodyParams = JSON.stringify(userData);
        fetch(APIEndPoints.LOGIN, {
            method: 'POST',
            headers: this.headers,
            body: bodyParams
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, "Service error, please try again.");
        })
    };

    logout = (callback) => {
        fetch(APIEndPoints.LOGOUT, {
            method: 'DELETE',
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, error);
        });
    };

    getUserInfo = (emailPhone,callback) => {
        const  urlString = APIEndPoints.GET_USER_INFO + emailPhone;
        fetch(urlString, {
            method: 'GET',
            headers: this.headers,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, error);
        })
    };

    resetPassword = (userInfo,callback) => {
        const urlString = APIEndPoints.RESET_PASSWORD;
        fetch(urlString, {
            method: 'PATCH',
            headers: this.headers,
            body: JSON.stringify(userInfo)
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            callback(null, error);
        })
    };
}

export default new LoginAPI();