import APIEndPoints from '../constants/APIConstants';
import API from "./API";

class SettingsApi extends API {
    fetchHolidayDetails = callback => {
        fetch(APIEndPoints.FETCH_CALENDAR_HOLIDAY, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    addHolidays = (holidayData, callback) => {
        let body = JSON.stringify(holidayData);
        console.log('holidayData', holidayData);
        fetch(APIEndPoints.ADD_HOLIDAYS, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    updateHolidays = (holidayData, callback) => {
        let body = JSON.stringify(holidayData);
        fetch(APIEndPoints.UPDATE_HOLIDAYS, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    deleteHolidays = (id, callback) => {
        fetch(APIEndPoints.DELETE_HOLIDAYS + id, {
            method: "DELETE",
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };


    addFeePercentage = (data, callback) => {
        let body = JSON.stringify(data);
        fetch(APIEndPoints.ADD_FEE_PERCENTAGE, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };

    fetchFeePercentage = callback => {
        fetch(APIEndPoints.FETCH_FEE_PERCENTAGE, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    getRelationshipPermission = callback => {
        fetch(APIEndPoints.GET_PERMISSION_FOR_RELATIONSHIP, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    setRelationshipPermission = (data, callback) => {
        let body = JSON.stringify(data);
        fetch(APIEndPoints.ASSIGN_PERMISSION_FOR_RELATIONSHIP, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    uploadISVFileToImport = (icsFile,calendarType, callback) => {
        fetch(APIEndPoints.IMPORT_HOLIDAY_CALENDAR + calendarType, {
            method: "POST",
            headers: this.authHeadersForMultipartFormData,
            body: icsFile,
            mimeType:'multipart/form-data'
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling update picture api", error);
            callback(null, "Service error, please try again.");
        });
    };

    //assign events to clients/agents
    searchClientOrAgentAccountSuccess = (value, callback) => {
        const urlString = APIEndPoints.SEARCH_CLIENTS_OR_AGENTS + value;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        })
            .then(response => {
                this.processResponse(response, callback);
            })
            .catch(error => {
                console.log("Error in calling search api.", error);
                callback(null, "Service error, please try again.");
            });
    };

    assignEventToClientsOrAgent = (data, callback) => {
        let body = JSON.stringify(data);
        fetch(APIEndPoints.ASSIGN_EVENTS_TO_CLIENTS_OR_AGENTS, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };

    getEventAssignedClientsOrAgent = (id, callback)  => {
        fetch(APIEndPoints.GET_ASSIGNED_CLIENTS_OR_AGENTS + id, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    //ELIGIBILITY SETTING
    fetchEligibilityData = callback => {
        fetch(APIEndPoints.FETCH_ELIGIBILITY_DATA, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    addEligibilityData = (eligibilityData, callback) => {
        let body = JSON.stringify(eligibilityData);
        fetch(APIEndPoints.ADD_ELIGIBILITY, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    updateEligibilityData = (eligibilityData, callback) => {
        let body = JSON.stringify(eligibilityData);
        fetch(APIEndPoints.EDIT_ELIGIBILITY, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };

    //DESCRIPTION SETTING
    fetchDescription = callback => {
        fetch(APIEndPoints.DESCRIPTION_API, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    setDefaultDescription = (descriptionId, callback) => {
        const urlString = APIEndPoints.DEFAULT_DESCRIPTION + descriptionId ;
        fetch(urlString, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    addNewDescription = (descriptionData, callback) => {
        let body = JSON.stringify(descriptionData);
        fetch(APIEndPoints.DESCRIPTION_API, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    updateDescription = (descriptionData, callback) => {
        let body = JSON.stringify(descriptionData);
        fetch(APIEndPoints.DESCRIPTION_API, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    deleteDescription = (descriptionId, callback) => {
        fetch(APIEndPoints.DESCRIPTION_API + "/" + descriptionId, {
            method: "DELETE",
            headers: this.authHeaders,
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };

    //VENDOR TYPE SETTING
    addNewVendorType = (descriptionData, callback) => {
        let body = JSON.stringify(descriptionData);
        fetch(APIEndPoints.VENDOR_TYPE_API, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    updateVendorType = (descriptionData, callback) => {
        let body = JSON.stringify(descriptionData);
        fetch(APIEndPoints.VENDOR_TYPE_API, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    fetchVendorType =(searchTerm, callback) => {
        fetch(APIEndPoints.FETCH_VENDOR_TYPE + searchTerm || "", {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    checkVendorType = callback => {
        fetch(APIEndPoints.CHECK_VENDOR_TYPE, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };

    // APIs for CHECKING ACCOUNTS SETTING
    fetchCheckingAccount =(callback) => {
        fetch(APIEndPoints.FETCH_CHECKING_ACCOUNT_LIST, {
            method: "GET",
            headers: this.authHeaders
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling case api.", error);
            callback(null, "Service error, please try again.");
        });
    };
    addNewCheckingAccount = (checkingAccountData, callback) => {
        let body = JSON.stringify(checkingAccountData);
        fetch(APIEndPoints.ADD_CHECKING_ACCOUNT, {
            method: "POST",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };
    updateCheckingAccount = (checkingAccountData, callback) => {
        let body = JSON.stringify(checkingAccountData);
        fetch(APIEndPoints.ADD_CHECKING_ACCOUNT, {
            method: "PATCH",
            headers: this.authHeaders,
            body: body
        }).then(response => {
            this.processResponse(response, callback);
        }).catch(error => {
            console.log("Error in calling add User api", error);
            callback(null, "Service error, please try again.");
        });
    };

}
export default new SettingsApi();