import React, { Component } from "react";
import "../App.css";
import "../styles/styles.css";
import Dialog from "@material-ui/core/Dialog";
import PaperComponent from "./PaperComponent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";
import printJS from "print-js";
import PrintIcon from "@material-ui/icons/Print";
import { Tooltip } from "antd";
import CancelIcon from "@material-ui/icons/Cancel";

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConformModal: false,
    };
  }
  render() {
    const res =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.response;
    const isReprint =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.isReprint;
    const isBulkCheck = !!(
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.response.checkPrintReq
    );
    const checkPrintRes =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.response.checkPrint;

    const checkPrintIdForManual = isReprint
      ? res.checkPrintId
      : checkPrintRes && checkPrintRes.checkPrintId;
    const transactionIdForManual = isReprint
      ? res.transactionId
      : checkPrintRes && checkPrintRes.transactionId;
    const checkPrintManualData = {
      checkPrint: [
        {
          checkPrintId: checkPrintIdForManual,
          transactionId: transactionIdForManual,
          isPrinted: true,
        },
      ],
    };
    const checkPrintList =
      res && res.checkPrintReq && res.checkPrintReq.checkPrint;
    const checkPrintBulkData = {
      checkPrint:
        checkPrintList &&
        checkPrintList.map((list) => {
          return {
            checkPrintId: list.checkPrintId,
            transactionId: list.transactionId,
            isPrinted: true,
          };
        }),
    };
    const handleOpenModal = () => {
      this.setState({
        openConformModal: true,
      });
    };
    const handleCloseModal = () => {
      this.setState({
        openConformModal: false,
      });
    };
    function printJobComplete() {
      window.history.back();
    }
    return (
      <div>
        <style>{`@media print {.modal-element{display: none;}}`}</style>
        <div className="hrmlBody">
          <div>
            <Tooltip placement="bottom" title="Print">
              <button
                className="floatDiv"
                style={{
                  right: "112px",
                }}
                onClick={() => {
                  handleOpenModal();
                }}
              >
                <PrintIcon
                  style={{
                    width: "26px",
                    height: "30px",
                    color: "#ffffff",
                  }}
                />
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip placement="bottom" title="Cancel">
              <button
                className="floatDiv"
                onClick={() => {
                  window.history.back();
                }}
              >
                <CancelIcon
                  style={{
                    width: "26px",
                    height: "30px",
                    color: "#ffffff",
                  }}
                />
              </button>
            </Tooltip>
          </div>
          <div>
            <iframe
              id="iFramePdf"
              src={res.url + "#toolbar=0"}
              style={{
                height: "100vh",
                width: "100%",
              }}
            />
          </div>
        </div>
        <Dialog
          className="modal-element"
          open={this.state.openConformModal}
          onClose={handleCloseModal}
          PaperComponent={PaperComponent}
          aria-labelledby="#draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ cursor: "move" }}>
            <h3
              style={{
                color: "#8BC83F",
                padding: "0.8rem",
                textAlign: "center",
              }}
            >
              Confirmation
            </h3>
          </DialogTitle>
          <DialogContent
            style={{
              background: "white",
              textAlign: "center",
              width: "450px",
            }}
          >
            <DialogContentText>
              Do you want to print check?
              <span
                className="row"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0.5rem",
                  paddingTop: "1.6rem",
                }}
              >
                <span className={"col-6"}>
                  <Button
                    autoFocus
                    onClick={handleCloseModal}
                    className="cancelButton"
                  >
                    cancel
                  </Button>
                </span>

                <span className={"col-6"}>
                  <Button
                    onClick={(e) => {
                      if (isBulkCheck && !isReprint) {
                        console.log({ checkPrintBulkData });
                      } else {
                        console.log({ checkPrintManualData });
                      }
                      e.preventDefault();
                      handleCloseModal();
                      printJS({
                        printable: res.url,
                        onPrintDialogClose: printJobComplete,
                      });
                      this.props.setCheckPrint(
                        isBulkCheck && !isReprint
                          ? checkPrintBulkData
                          : checkPrintManualData
                      );
                    }}
                    style={style.deleteCaseTemplateButton}
                  >
                    ok
                  </Button>
                </span>
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const style = {
  deleteCaseTemplateButton: {
    background: "#8BC83F",
    color: "white",
    width: "100%",
    padding: "0.5rem",
    fontSize: "14px",
  },
};
export default ComponentToPrint;
